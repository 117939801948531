import React from "react";
import ChangeItemWithContextMenu from "../ChangeItemWithContextMenu";
import style from "./style.module.css";

export interface IAutoImportFramesEnabledChangeItemProps {
  key: string;
  user?: string;
  timeAgo: React.ReactNode;
  data: {
    newPageNames: string[];
    removedPageNames: string[];
  };
  onAutomationSettingsClick?: () => void;
  borderedUi?: boolean;
}

export default function AutoImportFramesEnabledChangeItem(props: IAutoImportFramesEnabledChangeItemProps) {
  return (
    <div>
      <ChangeItemWithContextMenu
        key={props.key}
        user={props.user}
        timeAgo={props.timeAgo}
        contextMenuOptions={
          props.onAutomationSettingsClick ? [{ displayName: "Automation Settings", value: "automation-settings" }] : []
        }
        onContextMenuOptionClick={(value: string) => {
          if (value === "automation-settings") {
            props.onAutomationSettingsClick && props.onAutomationSettingsClick();
          }
        }}
        borderedUi={props.borderedUi}
      >
        {!(props.data.newPageNames?.length || props.data.removedPageNames?.length) && (
          <div className={`${style.text} ${props.borderedUi ? style.borderedText : ""}`}>
            Turned on automatic syncing of new frames but with no pages selected in the linked Figma file
          </div>
        )}
        {props.data.newPageNames && props.data.newPageNames.length > 0 && (
          <div className={`${style.text} ${props.borderedUi ? style.borderedText : ""}`}>
            Turned on automatic syncing of new frames from{" "}
            {props.data.newPageNames.length > 1 &&
              props.data.newPageNames.slice(0, -1).map((name) => (
                <span>
                  <span className={style.inlineFrameName}>{name}</span>
                  {props.data.newPageNames.length > 2 ? ", " : " "}
                </span>
              ))}
            {props.data.newPageNames.length > 1 && "and "}
            <span className={style.inlineFrameName}>
              {props.data.newPageNames[props.data.newPageNames.length - 1]}
            </span>{" "}
            in the linked Figma file
          </div>
        )}
        {props.data.newPageNames.length > 0 && props.data.removedPageNames.length > 0 && (
          <div className={style.divider} />
        )}
        {props.data.removedPageNames && props.data.removedPageNames.length > 0 && (
          <div className={`${style.text} ${props.borderedUi ? style.borderedText : ""}`}>
            Turned off automatic syncing of new frames from{" "}
            {props.data.removedPageNames.length > 1 &&
              props.data.removedPageNames.slice(0, -1).map((name) => (
                <span>
                  <span className={style.inlineFrameName}>{name}</span>
                  {props.data.removedPageNames.length > 2 ? ", " : " "}
                </span>
              ))}
            {props.data.removedPageNames.length > 1 && "and "}
            <span className={style.inlineFrameName}>
              {props.data.removedPageNames[props.data.removedPageNames.length - 1]}
            </span>{" "}
            in the linked Figma file
          </div>
        )}
      </ChangeItemWithContextMenu>
    </div>
  );
}
