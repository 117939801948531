import classnames from "classnames";
import React from "react";
import style from "./style.module.css";

interface CircularProgressProps {
  className?: string;
  progress: number;
  size: number;
  thickness: number;
}

const CircularProgress = ({ className, progress, size, thickness }: CircularProgressProps) => {
  const cleanProgress = Math.max(0, Math.min(100, progress));
  const inlineCircleStyle = {
    width: "100%",
    height: "100%",
    fill: "none",
    stroke: "#7F7F7F",
    opacity: "30%",
    ["strokeWidth"]: `${thickness}`,
  };
  const radiusProgress = Math.PI * (size - thickness) * (progress / 100);

  const progressCircleStyles = {
    strokeDasharray: `${radiusProgress}px ${Math.PI * (size - thickness) - radiusProgress}px`,
    stroke: "#56A642",
    opacity: radiusProgress === 0 ? "0%" : "100%",
  };

  const svgStyles = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <div className={classnames([style.progressBar, className])}>
      <svg style={svgStyles}>
        <circle cx={size / 2} cy={size / 2} r={size / 2 - thickness / 2} style={inlineCircleStyle}></circle>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - thickness / 2}
          style={{ ...inlineCircleStyle, ...progressCircleStyles }}
        ></circle>
      </svg>
    </div>
  );
};
export default CircularProgress;
