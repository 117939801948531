import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useState } from "react";

import style from "./style.module.css";

interface ComponentFilterDropdownProps {
  filterValue: string;
  onSelectFilter: (val: string) => void;
  onRemoveFilter: () => void;
}

const ComponentFilterDropdown = (props: ComponentFilterDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const options = {
    Attached: "Component attached",
    Unattached: "No component attached",
  };

  return (
    <div className={classNames(style.wrapper)}>
      <div
        className={style.dropdownToggle}
        onClick={() => setShowDropdown(!showDropdown)}
        data-testid="component-dropdown-toggle"
      >
        <ImportContactsIcon
          className={classNames({
            [style.icon]: true,
            [style.activeIcon]: props.filterValue,
          })}
        />
        {props.filterValue ? (
          <span className={style.text}>{options[props.filterValue]}</span>
        ) : (
          <span className={style.placeholder}>Components</span>
        )}
        <KeyboardArrowDownIcon className={style.downIcon} />
        {showDropdown && (
          <Dropdown
            selected={props.filterValue}
            options={{
              Attached: "Component attached",
              Unattached: "No component attached",
            }}
            onRemoveFilter={props.onRemoveFilter}
            onSubmitFilter={props.onSelectFilter}
          />
        )}
      </div>
    </div>
  );
};

const Dropdown = ({
  selected,
  options,
  onRemoveFilter,
  onSubmitFilter,
}: {
  selected?: string;
  options: { [key: string]: string };
  onRemoveFilter: () => void;
  onSubmitFilter: (val: string) => void;
}) => {
  return (
    <div className={style.dropdown}>
      {Object.entries(options).map(([key, value]) => (
        <div
          className={classNames({
            [style.componentSelectorDropdown]: true,
            [style.componentSelectorDropdownActive]: selected === key,
          })}
          onClick={() => onSubmitFilter(key)}
        >
          {selected === key && <CheckIcon className={style.checkIcon} />}
          <span>{value}</span>
        </div>
      ))}
      <div className={style.removeFilter} onClick={onRemoveFilter}>
        <DeleteIcon className={style.removeFilterIcon} />
        Remove Filter
      </div>
    </div>
  );
};

export default ComponentFilterDropdown;
