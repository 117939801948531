import classNames from "classnames";
import React from "react";
import DashboardCustomize from "../icons/DashboardCustomize";

import style from "./TemplateCompName.module.css";

interface IProps {
  name: string;
  onClick?: () => void;
  className?: string;
  classNameIcon?: string;
  classNameText?: string;
}

export const TemplateCompName = (props: IProps) => {
  return (
    <div onClick={props.onClick} className={classNames([style.container, props.className])}>
      <div>
        <span>
          <DashboardCustomize className={classNames([style.icon, props.classNameIcon])} />{" "}
          <span className={classNames([style.text, props.classNameText])}>Template: {props.name}</span>
        </span>
      </div>
    </div>
  );
};
