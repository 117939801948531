import useSegment from "@/hooks/useSegment";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useFigmaAuth } from "@/store/FigmaAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserJobs } from "../../../shared/types/User";
import logger from "../../../shared/utils/logger";
import globe from "../../assets/globe.png";
import laptop from "../../assets/laptop.png";
import painting from "../../assets/painting.png";
import shaking_hands from "../../assets/shaking_hands.png";
import ButtonPrimary from "../../components/button/buttonprimary";
import http, { API } from "../../http";
import { clearOnboardingRedirectValue, getOnboardingRedirectValue } from "./lib";
import style from "./style.module.css";
import useOnboardingFormState, {
  DittoUseCaseQuestion,
  OnboardingType,
  UserOnboardingPage,
  WorkspaceOnboardingPage,
} from "./useOnboardingFormState";

const Onboarding = () => {
  const history = useHistory();
  const { onboardingState: onboardingStateParam } = useParams<{
    onboardingState?: OnboardingType;
  }>();
  const { user, refreshUser } = useAuthenticatedAuth();
  const segment = useSegment();
  const { isFigmaAuthenticated, handleFigmaAuthWithRedirect, refreshFigmaAuth } = useFigmaAuth();
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();

  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [existingWSName, setExistingWSName] = useState<string>("");
  const [finishBtnLoading, setFinishBtnLoading] = useState<boolean>(false);

  const {
    state: onboardingState,
    pageNumber,
    setOnboardingPage,
    setNewWSName,
    toggleDittoUseCaseAnswer,
    setAcceptTnC,
    setReferralSource,
    setRole,
    setInviteEmails,
    setNoFigmaAccount,
    removeOnboardingState,
  } = useOnboardingFormState(onboardingStateParam);

  const identify = async () => {
    if (user.sub) {
      window?.analytics?.identify(user.sub, {
        displayName: user.name,
        email: user.email,
        workspaceID: user.workspaceId,
        isWorkspaceCreator: workspaceInfo?.creatorId === user.sub,
      });
    }
  };

  const redirectToOnboardingState = async () => {
    const stateToRedirectTo = user.onboardingState;
    setIsRedirect(false);
    if (stateToRedirectTo === "user" || stateToRedirectTo === "workspace") {
      history.push("/onboarding/" + stateToRedirectTo);

      stateToRedirectTo === "user"
        ? setOnboardingPage(UserOnboardingPage.FIRST)
        : setOnboardingPage(WorkspaceOnboardingPage.FIRST);
    } else {
      history.push("/projects");
    }
  };

  const fetchWSName = async () => {
    if (user) {
      const workspaceID = user.workspaceId;
      // get existing workspace info
      try {
        const { url } = API.api.get.workspace;
        const { data } = await http.get(url(workspaceID));
        const { name } = data.workspaceInfo;
        setExistingWSName(name);
      } catch (e) {
        setExistingWSName("");
        console.log("in onboarding.jsx: ", e.message);
      }
    }
  };

  useEffect(() => {
    fetchWSName();

    // Figma plugin sends users to /onboarding (ie. onboardingStateParam is undefined) and then redirected
    if (onboardingStateParam === undefined) {
      setIsRedirect(true);
      redirectToOnboardingState();
    } else {
      identify();
      segment.track({ event: "Onboarding Started" });
    }
  }, [user]);

  const handleChangeNewWSName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewWSName(e.target.value);
  };

  const handleBackPage = () => {
    segment.track({ event: "Back button clicked during onboarding" });

    switch (onboardingState.page) {
      case WorkspaceOnboardingPage.SECOND:
        setOnboardingPage(WorkspaceOnboardingPage.FIRST);
        break;
      case WorkspaceOnboardingPage.THIRD:
        setOnboardingPage(WorkspaceOnboardingPage.SECOND);
        break;

      case UserOnboardingPage.SECOND:
        setOnboardingPage(UserOnboardingPage.FIRST);

      default:
        break;
    }
  };

  const handleNextPage = () => {
    segment.track({ event: "Onboarding: Next Page Clicked" });

    switch (onboardingState.page) {
      case WorkspaceOnboardingPage.FIRST:
        setOnboardingPage(WorkspaceOnboardingPage.SECOND);
        break;
      case WorkspaceOnboardingPage.SECOND:
        setOnboardingPage(WorkspaceOnboardingPage.THIRD);
        break;

      case UserOnboardingPage.FIRST:
        setOnboardingPage(UserOnboardingPage.SECOND);
        break;

      default:
        break;
    }
  };

  const handleRoleDropdownChange = (e) => {
    setRole(e.target.value);
  };

  function handleReferralSourceChange(e: React.ChangeEvent<HTMLInputElement>) {
    setReferralSource(e.target.value);
  }

  const handleEmailInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (index > onboardingState.inviteEmails.length - 1 || index < 0) return;
    const newEmails = [...onboardingState.inviteEmails];
    newEmails[index] = e.target.value;
    setInviteEmails(newEmails);
  };

  const handleAddEmail = () => {
    setInviteEmails([...onboardingState.inviteEmails, ""]);
  };

  const onCheckLegal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptTnC(e.target.checked);
  };

  const onCheckNoFigmaAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoFigmaAccount(e.target.checked);
  };

  const updateWorkspaceName = async () => {
    if (onboardingState.newWSName === "") return;
    await http.put(API.workspace.put.name.url, {
      name: onboardingState.newWSName,
    });
    updateWorkspaceInfo({ ...workspaceInfo, name: onboardingState.newWSName });
  };

  const finishOnboarding = async () => {
    setFinishBtnLoading(true);
    segment.track({ event: "Onboarding: Finished" });

    // take the dittoUseCase object and turn it into an array of strings like ["components", "development"]
    const Q2Answer = Object.entries(onboardingState.dittoUseCase).reduce(
      (arr, [key, value]) => (value ? [...arr, key] : arr),
      [] as string[]
    );

    let onboardingResponseBody = {
      referralSource: onboardingState.referralSource,
      Q2: Q2Answer,
      has_figma: !onboardingState.noFigmaAccount,
      role: onboardingState.role,
    };

    try {
      const filteredEmails = onboardingState.inviteEmails.filter((email) => email !== "");
      if (filteredEmails.length > 0) {
        await http.post(API.invite.post.create.url, {
          emails: filteredEmails,
          role: "editor",
          permissionGroups: ["editor"],
          type: "workspace",
          message: "Join me on Ditto!",
        });
      }
    } catch (err) {
      logger.error("Error inviting users to workspace: ", {}, err);
    }

    // kick off all the workspace updates in parallel
    await Promise.all([updateWorkspaceName(), http.post("/onboardingFinished", onboardingResponseBody)]);

    try {
      segment.track({ event: "Onboarding: Successful WS Update" });
      // do below to update user object with newest additions from onboarding survey (ex: no figma account)
      await refreshUser();
      if (!onboardingState.noFigmaAccount) {
        refreshFigmaAuth();
      }

      const onboardingRedirectValue = getOnboardingRedirectValue();
      if (onboardingRedirectValue) {
        clearOnboardingRedirectValue();
        history.replace(onboardingRedirectValue);
      } else {
        history.replace("/projects");
      }

      // clear out the localstorage onboarding state
      removeOnboardingState();

      setFinishBtnLoading(false);
    } catch (err) {
      console.log("Error updating user, or updating onboarding info in onboarding.jsx: ", err);
      segment.track({ event: "Onboarding: Error with WS Update" });
      window.location.assign(`${process.env.BASE_URL}/projects`);
      setFinishBtnLoading(false);
    }
  };

  const onLastPage =
    onboardingState.page === WorkspaceOnboardingPage.THIRD || onboardingState.page === UserOnboardingPage.SECOND;

  const ctaText = () => {
    if (finishBtnLoading) return "Finishing...";
    if (onLastPage) return "Finish!  🎊";
    return "Continue ->";
  };

  const ctaDisabled = () => {
    if (finishBtnLoading) return true;

    const useCaseSelected = Object.values(onboardingState.dittoUseCase).some((value) => value);

    switch (onboardingState.page) {
      case WorkspaceOnboardingPage.FIRST:
        return !(
          onboardingState.newWSName !== "" &&
          useCaseSelected &&
          onboardingState.role !== "NONE" &&
          onboardingState.acceptTnC
        );
      case WorkspaceOnboardingPage.SECOND:
        return !isFigmaAuthenticated;
      case WorkspaceOnboardingPage.THIRD:
        return false;

      case UserOnboardingPage.FIRST:
        return !(onboardingState.acceptTnC && onboardingState.role !== "NONE" && useCaseSelected);
      case UserOnboardingPage.SECOND:
        return !(isFigmaAuthenticated || onboardingState.noFigmaAccount);

      default:
        return true;
    }
  };

  const ctaFunction = onLastPage ? finishOnboarding : handleNextPage;

  if (isRedirect) {
    return (
      <div className={style.container}>
        <div className={style.narrow}>
          <div className={style.box}>
            <ConnectSection
              isFigmaAuthenticated={isFigmaAuthenticated}
              handleFigmaAuthWithRedirect={handleFigmaAuthWithRedirect}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.container}>
      <div className={style.narrow}>
        <h1>
          Welcome to Ditto
          {user.given_name ? ", " + user.given_name : ""}! Let's get you set up 🎉
        </h1>
        <div className={style.box}>
          {onboardingState.page === WorkspaceOnboardingPage.FIRST && (
            <>
              <div className={style.nameWorkspaceSection}>
                <h2>Let's name your workspace ✍️</h2>
                <p>
                  This is typically the name of your company. Future teammates will see this when they join the
                  workspace.
                </p>
                <input
                  type="text"
                  placeholder="Workspace Name"
                  onChange={handleChangeNewWSName}
                  value={onboardingState.newWSName}
                  data-testid="name-workspace"
                ></input>
              </div>

              <DittoUseCase q2={onboardingState.dittoUseCase} toggleQ2Answer={toggleDittoUseCaseAnswer} />

              <HowDidYouHearAboutDitto handleReferralSourceChange={handleReferralSourceChange} />

              <WhatIsYourRole role={onboardingState.role} handleRoleDropdownChange={handleRoleDropdownChange} />

              <Legal onCheckLegal={onCheckLegal} legal={onboardingState.acceptTnC} />
            </>
          )}

          {onboardingState.page === WorkspaceOnboardingPage.SECOND && (
            <ConnectSection
              isFigmaAuthenticated={isFigmaAuthenticated}
              handleFigmaAuthWithRedirect={handleFigmaAuthWithRedirect}
            />
          )}

          {onboardingState.page === WorkspaceOnboardingPage.THIRD && (
            <div className={style.inviteSection}>
              <h2>Invite your teammates 🤝</h2>
              <p>Bring on writers, designers, and developers from your team to use Ditto cross-functionality.</p>
              <div className={style.emails}>
                {onboardingState.inviteEmails.map((email, index) => (
                  <input
                    key={index}
                    value={email}
                    placeholder="name@email.com"
                    onChange={handleEmailInputChange(index)}
                  />
                ))}
              </div>
              <div className={style.addEmail} onClick={handleAddEmail}>
                <AddIcon className={style.addIcon} />
              </div>
            </div>
          )}

          {onboardingState.page === UserOnboardingPage.FIRST && (
            <>
              <h2>
                You're joining the workspace <span className={style.ws}>{existingWSName}</span>.
              </h2>

              <DittoUseCase q2={onboardingState.dittoUseCase} toggleQ2Answer={toggleDittoUseCaseAnswer} />

              <HowDidYouHearAboutDitto handleReferralSourceChange={handleReferralSourceChange} />

              <WhatIsYourRole role={onboardingState.role} handleRoleDropdownChange={handleRoleDropdownChange} />

              <Legal onCheckLegal={onCheckLegal} legal={onboardingState.acceptTnC} />
            </>
          )}

          {onboardingState.page === UserOnboardingPage.SECOND && (
            <div>
              <ConnectSection
                isFigmaAuthenticated={isFigmaAuthenticated}
                handleFigmaAuthWithRedirect={handleFigmaAuthWithRedirect}
              />

              {!isFigmaAuthenticated && (
                <div className={style.noFigmaAccount}>
                  <div className={style.checkboxWrapper}>
                    <input type="checkbox" checked={onboardingState.noFigmaAccount} onChange={onCheckNoFigmaAccount} />
                  </div>
                  <span> Or, skip if you don't have a Figma account</span>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={style.dots}>
          <span
            className={classnames(style.dot, {
              [style.active]: pageNumber === 1,
            })}
          ></span>

          <span
            className={classnames(style.dot, {
              [style.active]: pageNumber === 2,
            })}
          ></span>

          {onboardingStateParam === "workspace" && (
            <span
              className={classnames(style.dot, {
                [style.active]: pageNumber === 3,
              })}
            ></span>
          )}
        </div>

        <div className={style.cta}>
          {pageNumber !== 1 && (
            <span className={style.back}>
              <a onClick={handleBackPage}>{"<- Back"}</a>
            </span>
          )}

          <ButtonPrimary
            data-testid="onboarding-workspace-continue"
            text={ctaText()}
            onClick={ctaFunction}
            disabled={ctaDisabled()}
          />
        </div>
      </div>
    </div>
  );
};

const DittoUseCase = ({
  q2,
  toggleQ2Answer,
}: {
  q2: DittoUseCaseQuestion;
  toggleQ2Answer: (key: keyof DittoUseCaseQuestion) => () => void;
}) => {
  return (
    <div className={style.section}>
      <h2>What would you like for Ditto to help with?</h2>
      <p>Please select all that apply.</p>
      <div className={style.dittoUseCase}>
        <div
          className={classnames(style.item, {
            [style.checked]: q2.components,
          })}
          onClick={toggleQ2Answer("components")}
        >
          <div className={style.checkboxWrapper}>
            <input data-testid="use-case-resusable-text" type="checkbox" readOnly checked={q2.components} />
          </div>
          <span>Creating a reusable text component library</span>
          <img src={globe} />
        </div>
        <div
          className={classnames(style.item, {
            [style.checked]: q2.development,
          })}
          onClick={toggleQ2Answer("development")}
        >
          <div className={style.checkboxWrapper}>
            <input data-testid="use-case-edit-manage-strings" type="checkbox" readOnly checked={q2.development} />
          </div>
          <span>Edit and manage strings in development</span>
          <img src={laptop} />
        </div>
        <div
          className={classnames(style.item, {
            [style.checked]: q2.textReview,
          })}
          onClick={toggleQ2Answer("textReview")}
        >
          <div className={style.checkboxWrapper}>
            <input data-testid="use-case-text-review" type="checkbox" readOnly checked={q2.textReview} />
          </div>
          <span>Manage text review across stakeholders</span>
          <img src={shaking_hands} />
        </div>
        <div
          className={classnames(style.item, {
            [style.checked]: q2.design,
          })}
          onClick={toggleQ2Answer("design")}
        >
          <div className={style.checkboxWrapper}>
            <input data-testid="use-case-design-files" type="checkbox" readOnly checked={q2.design} />
          </div>
          <span>Manage text edits in design files</span>
          <img src={painting} />
        </div>
      </div>
    </div>
  );
};

function capitalize(str: string) {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const HowDidYouHearAboutDitto = ({
  handleReferralSourceChange,
}: {
  handleReferralSourceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className={style.section}>
      <h2>How did you hear about Ditto?</h2>
      <input
        onChange={handleReferralSourceChange}
        placeholder="From a friend, a community, social media, etc."
        className={style.input}
      />
    </div>
  );
};

const WhatIsYourRole = ({
  role,
  handleRoleDropdownChange,
}: {
  role: string;
  handleRoleDropdownChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  return (
    <div className={style.section}>
      <h2>What best describes what you do?</h2>
      <select id="role" value={role} onChange={handleRoleDropdownChange} className={style.dropdown}>
        {UserJobs.map((job) => (
          <option key={job} value={job}>
            {job === "NONE" ? "Select one" : capitalize(job)}
          </option>
        ))}
      </select>
    </div>
  );
};

const Legal = ({
  legal,
  onCheckLegal,
}: {
  legal: boolean;
  onCheckLegal: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className={style.legal}>
      <label>
        <input data-testid="legal-agree" type="checkbox" checked={legal} onChange={onCheckLegal} />
        <span>
          {" "}
          I agree to Ditto's{" "}
          <a target="_blank" href="https://www.dittowords.com/legal/terms-of-service">
            Terms of Service
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://www.dittowords.com/legal/privacy-policy">
            Privacy Policy
          </a>
        </span>
      </label>
    </div>
  );
};

const ConnectSection = ({
  isFigmaAuthenticated,
  handleFigmaAuthWithRedirect,
}: {
  isFigmaAuthenticated: boolean;
  handleFigmaAuthWithRedirect: () => void;
}) => {
  return (
    <div className={style.connectSection}>
      <h2>Connect your Figma account 🔒</h2>
      <p>To upload and sync Figma files, you'll need to connect your Figma account.</p>
      {isFigmaAuthenticated ? (
        <ButtonPrimary text="Connected!" disabled />
      ) : (
        <ButtonPrimary data-testid="connect-to-figma" text="Connect" onClick={() => handleFigmaAuthWithRedirect()} />
      )}
    </div>
  );
};

export default Onboarding;
