import React from "react";
import style from "./style.module.css";

interface Props {
  text: string;
}

const BetaBadge = ({ text }: Props) => <span className={style.betaBadge}>{text}</span>;

export default BetaBadge;
