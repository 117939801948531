import { useWorkspace } from "@/store/workspaceContext";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { userHasSomeResourceAccess } from "@shared/frontend/userPermissionContext";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayToast from "../../../../components/OverlayToast";
import { useOverlayToast } from "../../../../components/OverlayToast/useOverlayToast";
import APIKeyModal from "../../../../components/account-devtools/APIKeyModal";
import Spinner from "../../../../components/spinner/spinner";
import http, { API } from "../../../../http";
import style from "./style.module.css";

const APIKeysTab = () => {
  const { workspaceInfo } = useWorkspace();
  const [showCreateKeyModal, setShowCreateKeyModal] = useState(false);
  const [apiKeysLoading, setApiKeysLoading] = useState(false);
  const [newToken, setNewToken] = useState<any>();
  const [apiKeys, setApiKeys] = useState<APIKeyProps[]>([]);
  const { overlayToastProps, showToast } = useOverlayToast();

  const devToolsEnabled = workspaceInfo?.devTools;
  const canEditComponentsConfig = userHasSomeResourceAccess("component_folder", "edit");
  const canEditProjectsConfig = userHasSomeResourceAccess("project_folder", "edit");

  const createNewKey = async (name: string) => {
    const { url, body } = API.workspace.post.apiToken;
    try {
      const response = await http.post(url, body({ name, connection: null }));
      setNewToken(response.data);
      setApiKeys([...apiKeys, { ...response.data, token: obfuscateToken(response.data.token) }]);
      showToast("API token was created", 3000);
    } catch (error) {
      console.error("Unable to create key, try again later.");
    }
  };

  const revokeKey = async (id: string) => {
    const { url } = API.workspace.delete.apiToken;
    try {
      await http.delete(url(id));
      setApiKeys(apiKeys.filter((key) => key._id.toString() !== id));
      showToast("API token was revoked", 3000);
    } catch (error) {
      console.error("Unable to revoke key, try again later.");
      showToast("Unable to revoke key, try again later.", 3000);
    }
  };

  const fetchWorkspaceAPIKeys = async () => {
    setApiKeysLoading(true);
    const { url } = API.workspace.get.apiTokens;
    try {
      const response = await http.get(url);
      setApiKeys(response.data);
    } catch (error) {
      console.error("Something went wrong, try again later.");
    } finally {
      setApiKeysLoading(false);
    }
  };

  useEffect(function fetchWorkspaceAPIKeysOnMount() {
    fetchWorkspaceAPIKeys();
  }, []);

  if (apiKeysLoading) {
    return <Spinner />;
  }

  return (
    <div className={style.apiKeysTab}>
      <div
        className={classNames([style.container, style.apikeys], {
          [style.disabled]: !devToolsEnabled,
        })}
      >
        <div className={style.top}>
          <div className={style.title}>API Keys</div>
          <div className={classNames([style.subtitle, style.subtitleConstrainedWidth])}>
            API keys allow you to authenticate API requests, either directly or through our CLI.
          </div>
          <Button
            data-testid="create-api-key-button"
            className={style.createBtn}
            variant="outline-primary"
            disabled={!devToolsEnabled || (!canEditComponentsConfig && !canEditProjectsConfig)}
            onClick={() => {
              setShowCreateKeyModal(true);
            }}
          >
            <AddIcon className={style.icon} /> Create API Key
          </Button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>NAME</th>
              <th>TOKEN</th>
              <th>LAST USED</th>
              <th>CREATED</th>
              <th>CREATOR</th>
            </tr>

            {/* TODO: make sure styling works properly with lots of keys! */}
            {apiKeys.map((props, idx) => (
              <APIKey {...props} revokeKey={revokeKey} key={idx} />
            ))}

            {apiKeys.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <span className={style.quiet}>No workspace API keys.</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showCreateKeyModal && (
        <APIKeyModal
          createNewKey={createNewKey}
          newToken={newToken}
          hideModal={() => {
            setShowCreateKeyModal(false);
            setNewToken(undefined);
          }}
        />
      )}
      <OverlayToast {...overlayToastProps} />
    </div>
  );
};

interface APIKeyProps {
  _id: string;
  name: string;
  token: string;
  creatorId: string;
  lastUsedAt?: string;
  createdAt: string;
  revokeKey: (id: string) => void;
}

const APIKey = (props: APIKeyProps) => {
  const { _id, name, token, lastUsedAt, createdAt, creatorId, revokeKey } = props;

  const { users } = useWorkspace();

  const findUserNameInWorkspaceUsers = (id: string) => {
    const user = users.find((u) => u._id.toString() === id);
    if (user) {
      return user.name;
    } else {
      return "Unknown";
    }
  };

  return (
    <tr>
      <td>{name}</td>
      <td className={style.token}>{token}</td>
      <td>{formatDate(lastUsedAt)}</td>
      <td>{formatDate(createdAt)}</td>
      <td>{findUserNameInWorkspaceUsers(creatorId)}</td>
      <td className={style.revokeCell}>
        <Dropdown alignRight>
          <Dropdown.Toggle className={style.dropdownToggle}>
            <MoreHorizIcon data-testid="more-icon" className={style.moreIcon} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={style.dropdownMenu}>
            <Dropdown.Item data-testid="revoke-button" className={style.revokeButton} onClick={() => revokeKey(_id)}>
              Revoke key
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

const obfuscateToken = (token: string) => {
  return token.slice(0, 4) + "..." + token.slice(-4);
};

function formatDate(input?: string): string {
  if (!input) {
    return "Never";
  }
  let date = new Date(input);
  let now = new Date();

  if (date.getFullYear() !== now.getFullYear()) {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
}

export default APIKeysTab;
