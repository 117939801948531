import { User } from "@datadog/browser-core";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

const getEnvironmentSpecificTracingURLs = () => {
  const staging = [
    /^(https?:\/\/)?kooky-backend.dittowords.com*/,
    /^(wss?:\/\/)?kooky-sockets.dittowords.com*/,
    /^(https?:\/\/)?kooky.dittowords.com*/,
    /^(https?:\/\/)?staging-backend.dittowords.com*/,
    /^(wss?:\/\/)?staging-sockets.dittowords.com*/,
    /^(https?:\/\/)?staging.dittowords.com*/,
  ];

  const production = [
    /^(https:\/\/)?backend.dittowords.com*/,
    /^(wss:\/\/)?sockets.dittowords.com*/,
    /^(https:\/\/)?app.dittowords.com*/,
  ];

  switch (process.env.ENV) {
    case "development":
    case "staging":
    case "kooky":
      return staging;
    default:
      return production;
  }
};

if (process.env.DATADOG_RUM_APPLICATION_ID && process.env.DATADOG_RUM_CLIENT_TOKEN) {
  datadogRum.init({
    allowFallbackToLocalStorage: true,
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    site: "us3.datadoghq.com",
    service: "frontend",
    env: process.env.SENTRY_ENV || "undefined",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.DD_VERSION || "0.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    telemetrySampleRate: 100,
    enableExperimentalFeatures: ["clickmap"],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: getEnvironmentSpecificTracingURLs(),
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: "allow",
  });
}

if (process.env.DATADOG_FRONTEND_CLIENT_TOKEN) {
  console.log("Initing Logs");
  datadogLogs.init({
    clientToken: process.env.DATADOG_FRONTEND_CLIENT_TOKEN || "",
    site: "us3.datadoghq.com",
    service: "frontend",
    env: process.env.SENTRY_ENV || "undefined",
    version: process.env.DD_VERSION || "0.0.0",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    forwardConsoleLogs: ["warn", "error"],
    forwardReports: "all",
  });
}

export function setDatadogUser(user: User) {
  datadogLogs.setUser(user);
  datadogRum.setUser(user);
}

export function clearDatadogUser() {
  datadogLogs.clearUser();
  datadogRum.clearUser();
}
