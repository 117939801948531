import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import React from "react";
import TimeAgo from "react-timeago";

import { CommentThreadProps } from "./commentthread";
import style from "./style.module.css";

interface FirstCommentProps {
  comment: {
    _id: string;
    text: string;
    displayJsx: React.ReactNode[];
    user_id: string;
    user_name: string;
    createdAt: string;
    updatedAt: string;
  };
  suggestionButtonsDisabled: boolean;
  is_resolved: boolean;
  isDisabled: boolean;
  suggestion?: CommentThreadProps["suggestion"];
  resolveCommentThread: (options?: { suggestionAccepted?: boolean }) => void;
  onAcceptSuggestion: CommentThreadProps["onAcceptSuggestion"];
  onRejectSuggestion: CommentThreadProps["onRejectSuggestion"];
  comp_id?: CommentThreadProps["comp_id"];
  ws_comp_id?: CommentThreadProps["ws_comp_id"];
  thread_id: CommentThreadProps["thread_id"];
}

export const FirstComment = (props: FirstCommentProps) => {
  const { comment } = props;
  if (!comment) {
    throw new Error(`Comment threads must have at least one comment`);
  }

  const {
    suggestion,
    suggestionButtonsDisabled,
    is_resolved,
    isDisabled,
    resolveCommentThread,
    onAcceptSuggestion,
    onRejectSuggestion,
    comp_id,
    ws_comp_id,
    thread_id,
  } = props;

  return (
    <div>
      <div className={style.firstCommentHeader}>
        <div className={style.nameAndTime}>
          <span>
            {comment.user_name},{" "}
            <TimeAgo key={comment.createdAt.toString()} date={comment.createdAt} minPeriod={30} className="time" />
          </span>
        </div>
        {!suggestion && !isDisabled && (
          <div className={style.resolve} onClick={() => resolveCommentThread()}>
            <CheckIcon className={style.icon} />
            <span>{is_resolved ? "Resolved" : "Resolve"}</span>
          </div>
        )}
        {suggestion && !is_resolved && (
          <div className={style.suggestionButtons}>
            <CheckIcon
              className={classNames({
                [style.suggestionAccept]: true,
                [style.disabled]: isDisabled || suggestionButtonsDisabled,
              })}
              onClick={() => {
                if (suggestionButtonsDisabled) return;
                resolveCommentThread({ suggestionAccepted: true });
                onAcceptSuggestion(thread_id, suggestion, {
                  compId: comp_id,
                  wsCompId: ws_comp_id,
                });
              }}
            />
            <CloseIcon
              className={classNames({
                [style.suggestionReject]: true,
                [style.disabled]: suggestionButtonsDisabled,
              })}
              onClick={() => {
                if (suggestionButtonsDisabled) return;
                resolveCommentThread({ suggestionAccepted: false });
                onRejectSuggestion(thread_id, suggestion, {
                  compId: comp_id,
                  wsCompId: ws_comp_id,
                });
              }}
            />
          </div>
        )}
      </div>
      <div
        className={classNames({
          [style.commentText]: true,
          [style.suggestionText]: Boolean(suggestion),
        })}
      >
        {comment.displayJsx}
      </div>
    </div>
  );
};
