import { IFVariable } from "@shared/types/Variable";
import { useEffect, useMemo, useState } from "react";
import { VALID_URL } from "../../../../defs";
import { VariableInstanceData } from "../../types";
const URL_REGEX = new RegExp(VALID_URL);

const getDefaultDataByType = (type: string) => {
  const m: { [key in IFVariable["type"]]: IFVariable["data"] } = {
    number: { example: "" },
    string: { example: "" },
    hyperlink: { text: "", url: "" },
    list: [""],
    map: { __empty__: "" },
  };

  if (!m[type]) {
    throw new Error(`Invalid variable type: ${type}`);
  }

  return m[type];
};

export interface EditVariableProps {
  variable: IFVariable;
  canEdit: boolean;
  isSaving: boolean;
  handleUpdateVariable: (variable: IFVariable) => void;
  handleDeleteVariable: (variable: IFVariable) => void;
  handleMoveVariable: (variable: IFVariable) => void;
  instanceData: VariableInstanceData;
}
const useEditVariable = ({ variable, canEdit, isSaving, handleUpdateVariable }: EditVariableProps) => {
  const [updatedVariable, setUpdatedVariable] = useState<IFVariable>(structuredClone(variable));
  const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false);
  const [urlError, setURLError] = useState<boolean>(false);
  const canDelete = variable.instances.length === 0;

  const isValidNumber = (updatedVariable: IFVariable) =>
    (updatedVariable && updatedVariable.type === "string") ||
    (updatedVariable.type === "number" && updatedVariable.data && updatedVariable.data.example);

  const isValidHyperlink = (updatedVariable: IFVariable) =>
    updatedVariable &&
    updatedVariable.type === "hyperlink" &&
    updatedVariable.data &&
    updatedVariable.data.url &&
    URL_REGEX.test(updatedVariable.data.url) &&
    updatedVariable.data.text;

  const isValidList = (updatedVariable: IFVariable) =>
    updatedVariable &&
    updatedVariable.type === "list" &&
    updatedVariable.data &&
    updatedVariable.data.length > 0 &&
    updatedVariable.data.every((e) => e !== "") &&
    updatedVariable.data.length === new Set(updatedVariable.data).size;

  const isValidMap = (updatedVariable: IFVariable) =>
    updatedVariable &&
    updatedVariable.type === "map" &&
    updatedVariable.data &&
    Object.keys(updatedVariable.data).length > 0 &&
    Object.keys(updatedVariable.data).every((e) => e !== "") &&
    Object.keys(updatedVariable.data).every((e) => e.indexOf("__duplicate") == -1) &&
    Object.values(updatedVariable.data).every((e) => e !== "");

  const canSave = useMemo(() => {
    if (isSaving || !canEdit) return false;
    if (JSON.stringify(updatedVariable) === JSON.stringify(variable)) {
      return false;
    }
    if (isValidNumber(updatedVariable)) {
      return true;
    } else if (isValidHyperlink(updatedVariable)) {
      return true;
    } else if (isValidList(updatedVariable)) {
      return true;
    } else if (isValidMap(updatedVariable)) {
      return true;
    }
    return false;
  }, [updatedVariable, isSaving, canEdit]);

  useEffect(
    function synchronizeUpdateVariableOnPropChange() {
      setUpdatedVariable(structuredClone(variable));
    },
    [variable]
  );

  const resetChanges = () => {
    setUpdatedVariable(structuredClone(variable));
    setURLError(false);
  };

  const handleUpdateName = (newName) => {
    handleUpdateVariable({ ...variable, name: newName });
  };

  const validateURL = () => {
    const url =
      updatedVariable && updatedVariable.type === "hyperlink" && updatedVariable.data && updatedVariable.data.url
        ? updatedVariable.data.url
        : "";
    if (url.length > 0 && !URL_REGEX.test(url)) {
      setURLError(true);
    } else {
      setURLError(false);
    }
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value;
    setURLError(false);
    setUpdatedVariable((prev: any) => ({
      ...prev,
      type: newType,
      data: getDefaultDataByType(newType),
    }));
  };

  const onUpdateExample = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newExample = e.target.value;
    setUpdatedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, example: newExample },
    }));
  };

  const onUpdateFallback = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFallback = e.target.value;
    setUpdatedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, fallback: newFallback },
    }));
  };

  const onUpdateText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setUpdatedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, text: newText },
    }));
  };

  const onHyperlinkBlur = () => validateURL();

  const onUpdateHyperlink = (e) => {
    const newURL = e.target.value;
    setUpdatedVariable((prev: any) => ({
      ...prev,
      data: { ...prev.data, url: newURL },
    }));
  };

  const onSave = () => {
    handleUpdateVariable(updatedVariable);
  };

  return {
    canSave,
    canDelete,
    updatedVariable,
    showEditNameModal,
    urlError,
    setShowEditNameModal,
    handleUpdateName,
    resetChanges,
    onTypeChange,
    onUpdateExample,
    onUpdateFallback,
    onUpdateText,
    onUpdateHyperlink,
    onHyperlinkBlur,
    setUpdatedVariable,
    onSave,
  };
};

export { useEditVariable };
