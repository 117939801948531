import classNames from "classnames";
import { Ditto } from "ditto-react";
import style from "./card.module.css";

export interface Props {
  img: string;
  textDittoComponentId: string;
  onClick?: () => void;
  className?: string;
}

export default function Card(props: Props) {
  return (
    <div className={classNames(style.container, props.className)} onClick={props.onClick}>
      <img src={props.img} alt="card" className={style.img} />
      <div className={style.text}>
        <Ditto componentId={props.textDittoComponentId} />
      </div>
    </div>
  );
}
