import classNames from "classnames";
import React from "react";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  containerClassName?: string;
  headerClassName?: string;
  descriptionClassName?: string;
  header: string | React.ReactNode;
  description: string | React.ReactNode;
}

/**
 * Use this component to render an empty state message in a panel.
 * It renders a header and a description, which can each be strings (which get styled automatically) or pre-styled React nodes.
 * The content will be horizontally centered and about a third of a the way down vertically, unless overridden by containerClassName.
 */
export function PanelEmptyState(props: IProps) {
  return (
    <div className={classNames(style.PanelEmptyStateWrapper, props.containerClassName)} data-testid="panel-empty-state">
      {typeof props.header === "string" ? (
        <Text color="primary" size="large" weight="strong" className={props.headerClassName}>
          {props.header}
        </Text>
      ) : (
        <>{props.header}</>
      )}
      {typeof props.description === "string" ? (
        <Text color="secondary" className={props.descriptionClassName}>
          {props.description}
        </Text>
      ) : (
        <>{props.description}</>
      )}
    </div>
  );
}

export default PanelEmptyState;
