import { generateTextItemMarkup } from "@shared/frontend/text";
import React, { useMemo } from "react";

import style from "./VariableText.module.css";

const VariableSpan = (props) => {
  const { variables, text } = props;
  return useMemo(
    () => (
      <>
        {generateTextItemMarkup(text, variables, {
          highlightBrackets: props.highlightBrackets,
          classNameVariable: style.variableContainer,
          classNameBracket: style.bracketContainer,
        })}
      </>
    ),
    [text, variables]
  );
};

export default VariableSpan;
