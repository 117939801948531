import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";

import EnableLinkingImg from "../../../../assets/enable_linking.png";
import LinkFigmaImage from "../../../../assets/pluginLinkText.gif";

import style from "./style.module.css";

interface Props {
  onHide: () => void;
  linkingEnabled: boolean;
}

const EnableLinking = () => (
  <div className={style.container}>
    <p>You don’t currently have linking enabled for any of the groups in your project.</p>
    <div className={style.img}>
      <img src={EnableLinkingImg} />
    </div>

    <p>
      When you enable linking for a group, it can then be connected to a Figma frame or accessed by our developer
      integrations.{" "}
    </p>
    <p>Once a group has linking enabled, you can use components, variables, variants, and other Ditto features. </p>
    <div className={style.link}>
      <a href="https://dittowords.com/docs/drafting-basics" target="_blank">
        Learn more about linking
      </a>
    </div>
  </div>
);

const LinkFigma = () => (
  <div className={style.container}>
    <p>You can link this Ditto project to a Figma file to sync changes between the two.</p>
    <p>
      To do this, open our{" "}
      <a
        className={style.figmaLink}
        href="https://www.figma.com/community/plugin/798826066406007173/Ditto"
        target="__blank"
      >
        Figma plugin
      </a>{" "}
      in a file that hasn’t been linked to Ditto!
    </p>
    <div>
      <img src={LinkFigmaImage} className={style.linkFigmaImage} />
    </div>

    <div className={style.link}>
      <a href="https://www.dittowords.com/docs/linking-draft-groups-to-figma" target="_blank">
        Learn more about linking to Figma
      </a>
    </div>
  </div>
);
const LinkingHelpModal = ({ onHide, linkingEnabled }: Props) => (
  <BootstrapModal
    show={true}
    className={style.modal}
    dialogClassName={style.dialog}
    backdropClassName={style.backdrop}
    onHide={onHide}
    centered
  >
    <BootstrapModal.Header className={style.header}>
      <BootstrapModal.Title className={style.title}>
        {linkingEnabled ? "Linking your project to Figma" : "Enabling linking in your project"}
      </BootstrapModal.Title>
      <CloseIcon className={style.close} onClick={onHide} />
    </BootstrapModal.Header>
    <BootstrapModal.Body className={style.body}>
      {linkingEnabled ? <LinkFigma /> : <EnableLinking />}
    </BootstrapModal.Body>
  </BootstrapModal>
);

export default LinkingHelpModal;
