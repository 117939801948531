import CodeIcon from "@mui/icons-material/Code";
import FlagIcon from "@mui/icons-material/Flag";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import React, { useContext, useEffect, useMemo } from "react";
import Select from "react-select";
import { CsvImportContext } from "../CsvImportContext";

import classNames from "classnames";
import style from "./CsvSettings.module.css";

function AttributesRow(props: {
  icon: React.ReactNode;
  label: string;
  options: { value: string; label: string }[];
  onChange: (value: { value: string }) => void;
  warningText?: React.ReactNode;
  showWarningText?: boolean;
  value: {
    value: string;
    label: string;
  };
  selectTestId?: string;
}) {
  return (
    <tr>
      <td className={style.attributeLabel}>
        {props.icon}
        {props.label}
      </td>
      <td className={style.attributeSelectContainer} data-testid={props.selectTestId}>
        <Select
          id={`select-${props.label.toLowerCase().replace(" ", "-")}`}
          className={classNames({
            [style.selectInput]: true,
            [style.selectInputWarning]: props.showWarningText,
          })}
          isSearchable
          options={props.options}
          defaultValue={props.options[0]}
          onChange={props.onChange}
          value={props.value}
          components={{
            MenuList: (menuListProps) => <div className={style.menuList}>{menuListProps.children}</div>,
          }}
        />
        {props.showWarningText && props.warningText}
      </td>
    </tr>
  );
}

interface IColumnOption {
  value: string;
  label: string;
  index: number;
}

function getColumnValue(column: string, index: number) {
  return `${column}-${index}`;
}

interface CsvSettingsProps {}
function CsvSettings(props: CsvSettingsProps) {
  const {
    columns,
    selectedTextColumnIdx,
    setSelectedTextColumnIdx,
    selectedNameColumnIdxs,
    setSelectedNameColumnIdxs,
    selectedNotesColumnIdx,
    setSelectedNotesColumnIdx,
    selectedTagsColumnIdx,
    setSelectedTagsColumnIdx,
    selectedStatusColumnIdx,
    setSelectedStatusColumnIdx,
    selectedComponentIdColumnIdx,
    setSelectedComponentIdColumnIdx,
    body,
    selectedTextColumnWarning,
    selectedStatusColumnHasError,
    selectedComponentIdColumnError,
    selectedNameColumnsWarning,
    setSelectValueColors,
    columnHighlights,
    validateTextColumn,
  } = useContext(CsvImportContext);

  // Needed if the user presses back from the preview step
  useEffect(function initializeSelectedInputColors() {
    setSelectValueColors();
  }, []);

  const options = useMemo((): IColumnOption[] => {
    const columnNamesToCount = new Map<string, number>();
    columns.forEach((c) => {
      const count = columnNamesToCount.get(c) || 0;
      columnNamesToCount.set(c, count + 1);
    });

    return columns.map((column, index) => {
      // If the column is empty, we'll just use "Column" + index as the label
      if (!column) {
        return {
          value: getColumnValue("column", index),
          label: `Column ${index + 1}`,
          index,
        };
      }

      const count = columnNamesToCount.get(column) || 0;
      // Dedupe column names
      const label = column + (count > 1 ? ` (${index + 1})` : "");
      return { value: getColumnValue(column, index), label, index };
    });
  }, [columns]);

  const optionsWithNone = useMemo(() => [{ value: "None", label: "None", index: -1 }, ...options], [options]);

  const selectedText = options.find((option) => option.index === (selectedTextColumnIdx || 0) - 1);
  const onSelectText = (value: IColumnOption) => {
    if (!value) {
      setSelectedTextColumnIdx(null);
      return;
    }

    const idx = options.findIndex((option) => option.value === value.value && option.index === value.index) + 1; // compensate for header row

    setSelectedTextColumnIdx(idx);
    validateTextColumn(idx);
  };

  const selectedNameValues = selectedNameColumnIdxs?.map((idx) => {
    return options.find((option) => option.index === idx - 1);
  });
  const onSelectName = (values: IColumnOption[]) => {
    if (!values) {
      setSelectedNameColumnIdxs(null);
      return;
    }

    setSelectedNameColumnIdxs(
      values.map(
        (v) => options.findIndex((option) => option.value === v.value && option.index === v.index) + 1 // compensate for header row
      )
    );
  };

  return (
    <div className={style.csvSettings}>
      <p className={style.instructions}>
        Each row in your CSV file will get imported as its own <b>Ditto component</b>. Let us know how each component
        should get created.
      </p>
      <p className={style.columnSelectLabel}>
        Which CSV column should we use for the <b>text value</b> of a component?
      </p>
      <Select
        id="select-text"
        isSearchable
        value={selectedText}
        options={options}
        placeholder="Select CSV column..."
        className={classNames([style.columnSelectInput, style.selectInput])}
        onChange={onSelectText}
      />
      {selectedTextColumnWarning && (
        <div className={classNames([style.warning, style.textError])}>{selectedTextColumnWarning}</div>
      )}
      <p className={style.columnSelectLabel}>
        Which CSV column(s) should we use for the <b>name</b> of a component?{" "}
      </p>
      <Select
        isSearchable
        value={selectedNameValues}
        options={options}
        isOptionDisabled={() => selectedNameColumnIdxs && selectedNameColumnIdxs.length >= 5}
        placeholder="Add CSV column(s)..."
        className={classNames({
          [style.columnSelectInput]: true,
          [style.selectInput]: true,
        })}
        isMulti
        components={{
          MultiValueContainer: (props: { children: React.ReactNode; data: IColumnOption }) => {
            const backgroundColor = columnHighlights[props.data.index + 1];
            return (
              <div className={style.multiValue}>
                <div style={{ backgroundColor }}>
                  <span>{props.data.label}</span>
                </div>
              </div>
            );
          },
          MultiValueRemove: () => null,
        }}
        styles={{
          multiValue: (base) => {
            return {
              ...base,
              paddingRight: "3px",
            };
          },
        }}
        onChange={onSelectName}
      />
      {selectedNameColumnsWarning && (
        <div className={classNames([style.warning, style.nameError])}>{selectedNameColumnsWarning}</div>
      )}
      {!selectedNameColumnsWarning && selectedNameColumnIdxs && selectedNameColumnIdxs.length >= 1 && (
        <div className={style.nameExamples}>
          Examples:{" "}
          {body[0] &&
            selectedNameColumnIdxs
              .map((idx) => body[0][idx])
              .filter((x) => x !== "")
              .join("/")}
          {body[1] &&
            `, ${selectedNameColumnIdxs
              .map((idx) => body[1][idx])
              .filter((x) => x !== "")
              .join("/")}`}
        </div>
      )}
      <p>If any other CSV columns map to other attributes of a component, select them below.</p>
      <table className={style.attributesTable}>
        <thead>
          <tr>
            <th>Component Attribute</th>
            <th>CSV Column</th>
          </tr>
        </thead>
        <tbody>
          <AttributesRow
            icon={<InfoIcon />}
            label="Notes"
            options={optionsWithNone}
            onChange={(value) => {
              const idx = optionsWithNone.findIndex((option) => option.value === value.value);
              setSelectedNotesColumnIdx(idx);
            }}
            value={optionsWithNone[selectedNotesColumnIdx || 0]}
          />
          <AttributesRow
            icon={<LocalOfferIcon />}
            label="Tags"
            options={optionsWithNone}
            onChange={(value) => {
              const idx = optionsWithNone.findIndex((option) => option.value === value.value);
              setSelectedTagsColumnIdx(idx);
            }}
            value={optionsWithNone[selectedTagsColumnIdx || 0]}
          />
          <AttributesRow
            icon={<FlagIcon />}
            label="Status"
            options={optionsWithNone}
            onChange={(value) => {
              const idx = optionsWithNone.findIndex((option) => option.value === value.value);

              if (value.value === "None") {
                setSelectedStatusColumnIdx(null);
              } else {
                setSelectedStatusColumnIdx(idx);
              }
            }}
            warningText={
              <div className={style.error}>
                Please choose a column that contains only Ditto's four statuses.{" "}
                <a target="_blank" href="https://www.dittowords.com/docs/importing-csv-files">
                  Learn more
                </a>
              </div>
            }
            showWarningText={selectedStatusColumnHasError}
            value={optionsWithNone[selectedStatusColumnIdx || 0]}
          />
          <AttributesRow
            icon={<CodeIcon />}
            selectTestId="component-id-dropdown"
            label="Component ID"
            options={optionsWithNone}
            onChange={(value) => {
              const idx = optionsWithNone.findIndex((option) => option.value === value.value);
              if (value.value === "None") {
                setSelectedComponentIdColumnIdx(null);
              } else {
                setSelectedComponentIdColumnIdx(idx);
              }
            }}
            warningText={<div className={style.error}>{selectedComponentIdColumnError}</div>}
            showWarningText={!!selectedComponentIdColumnError}
            value={optionsWithNone[selectedComponentIdColumnIdx || 0]}
          />
        </tbody>
      </table>
    </div>
  );
}

export default CsvSettings;
