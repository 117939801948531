import OverlayToast from "@/components/OverlayToast";
import http, { API } from "@/http";
import { getProjectGroups } from "@/http/project";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useProjectContext } from "@/views/Project/state/useProjectState";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { ConfirmFrameRemovalResult, IAddPageAction } from "@shared/types/jobs/ConfirmFrameRemoval";
import { JobNames } from "@shared/types/jobs/JobNames";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import logger from "@shared/utils/logger";
import { memo, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { GROUP_REDUCER_TYPES } from "../../state/groupStateActions";
import { Group, isGroupLinked } from "../../state/types";
import { ALL_PAGE_ID } from "../../state/usePageState";
import useDebouncedCallback from "../GroupDraft/state/useDebouncedCallback";

interface IProjectWebsocketsHandlerProps {
  updateMultiComps: (ids: string[]) => void;
  showAutoAttachmentToast: () => void;
}

function ProjectWebsocketsHandler(props: IProjectWebsocketsHandlerProps) {
  const history = useHistory();
  const [notifToastHidden, setNotifToastHidden] = useState(true);
  const [notifToastText, setNotifToastText] = useState("");
  const {
    doc: [project, setProject],
    setSetupSuggestionsForAllGroups,
    projectId,
    groupState: [, groupStateDispatch],
    docComponents: [, setDocComponents],
    updateDocHistory: [, setUpdateDocHistory],
    multiSelectedIds: [multiSelectedIds],
    selectedComp: [selectedComp],
    queuePostResyncExecution,
  } = useProjectContext();
  const { getTokenSilently } = useAuthenticatedAuth();
  const textItemIdsToUpdate = useRef<string[]>([]);

  useDittoEventListener(DittoEvents.backgroundJobUpdated, (event) => {
    if (event.status === "completed" && event.jobName === JobNames.ConfirmFrameRemoval && event.returnValue) {
      try {
        const result = ConfirmFrameRemovalResult.parse(event.returnValue);

        const isCurrentProject = result.projectId === project?._id.toString();
        if (!isCurrentProject) return;

        const groupUpdateActions = result.actions.filter((action) => action.type === "group-update");

        queuePostResyncExecution(() => {
          if (groupUpdateActions.length) {
            const actionsByGroupId = {};
            groupUpdateActions.forEach((action) => {
              actionsByGroupId[(action as any).groupId] ??= [];
              actionsByGroupId[(action as any).groupId].push(action);
            });

            groupStateDispatch({
              type: GROUP_REDUCER_TYPES.UPDATE_GROUPS,
              updater: (groups) =>
                groups.map((group) => {
                  const actions = actionsByGroupId[group._id] || [];
                  if (!(isGroupLinked(group) && actions.length)) return group;

                  actions.forEach((action) => {
                    // frame was moved to a new page
                    if (action.newPageId) group.integrations.figma.page_id = action.newPageId;

                    // frame id was updated
                    if (action.newFrameId) group.integrations.figma.frame_id = action.newFrameId;
                    // frame was deleted
                    else if (action.newFrameId === null) group.integrations.figma = {} as any;

                    if (action.newFrameName) group.integrations.figma.frame_name = action.newFrameName;
                  });

                  return group;
                }),
            });
            setUpdateDocHistory(true);
          }
        }, 4000);

        const pageAddActions: IAddPageAction[] = result.actions.filter(
          (action) => action.type === "add-page"
        ) as IAddPageAction[];
        if (pageAddActions.length) {
          setProject((project) => {
            if (!project) return project;

            const newPages = pageAddActions.map((action) => ({
              figma_id: action.page.figma_id,
              name: action.page.name,
            }));

            return {
              ...project,
              integrations: {
                ...project.integrations,
                figma: {
                  ...project.integrations.figma,
                  selected_pages: [...(project.integrations.figma.selected_pages || []), ...newPages],
                },
              },
            };
          });
        }
      } catch (e) {
        logger.warn("Invalid ConfirmFrameRemovalResult", {
          context: {
            value: event.returnValue,
            error: e?.message,
          },
        });
      }
    }
  });

  useDittoEventListener(DittoEvents.autoAttachComponentsStarted, (event) => {
    if (event.projectId !== projectId) return;
    setUpdateDocHistory(true);
  });

  useDittoEventListener(
    DittoEvents.autoAttachComponentsFinished,
    (event) => {
      if (event.projectId !== projectId) return;
      setUpdateDocHistory(true);

      const textItemsAttachedSet = new Set(event.textItemsAttached);

      const selectionWasAutoAttached =
        (multiSelectedIds || []).some((id) => textItemsAttachedSet.has(id)) ||
        textItemsAttachedSet.has(selectedComp?._id || "");
      if (selectionWasAutoAttached) {
        props.showAutoAttachmentToast();
      }
    },
    [multiSelectedIds, selectedComp]
  );

  // Used to prevent stale closure when listening for websocket events
  const multiSelectedIdsRef = useRef(multiSelectedIds);
  useEffect(
    function trackMultiSelectedIds() {
      multiSelectedIdsRef.current = multiSelectedIds;
    },
    [multiSelectedIds]
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    async function sendDocSubscribeMsg() {
      const subscribeToDocMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_DOC_SUBSCRIPTION,
        token: await getTokenSilently(),
        docId: projectId,
      };
      sendMessage(JSON.stringify(subscribeToDocMsg));
    }

    if (readyState === 1) {
      sendDocSubscribeMsg();
    }
  }, [readyState]);

  useDittoEventListener(DittoEvents.groupsImported, (data) => {
    const [request] = getProjectGroups({
      projectId,
      groupIds: Object.values(data.newFrameIdsMap),
    });

    request.then(({ data }) => {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.ADD_GROUPS,
        groups: data.groups as unknown as Group[],
      });
      setProject((project) => {
        if (!project) return project;

        return {
          ...project,
          integrations: data.integrations,
        };
      });
    });
  });

  useDittoEventListener(DittoEvents.newBlock, (data) => {
    groupStateDispatch({
      type: GROUP_REDUCER_TYPES.ADD_NEW_BLOCK_TO_GROUP,
      groupId: data.groupId,
      blockId: data.blockId,
      name: data.newName,
    });

    setUpdateDocHistory(true);
  });

  useDittoEventListener(DittoEvents.updateBlock, (data) => {
    groupStateDispatch({
      type: GROUP_REDUCER_TYPES.UPDATE_BLOCK_NAME,
      blockId: data.blockId,
      groupId: data.groupId,
      newName: data.newName,
    });
  });

  useDittoEventListener(DittoEvents.groupUpdated, (data) => {
    const [request] = getProjectGroups({
      projectId,
      groupIds: [data.groupId],
    });

    request.then(({ data }) => {
      const group = data.groups[0];
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REFRESH_GROUP,
        groupId: group._id,
        group: group as unknown as Group,
      });
    });
  });

  useEffect(
    function processMessage() {
      if (!lastMessage) return;

      const data = JSON.parse(lastMessage.data);

      if (data.messageType === WEBSOCKET_EVENTS.SUGGESTIONS) {
        processGroupSuggestionsWebsocketEvent(data);
      } else if (data.messageType === WEBSOCKET_EVENTS.TEXT_ITEMS_UPDATED) {
        textItemIdsToUpdate.current.push(...data.textItemIds);
        processTextItemsIdsToUpdateDebounced();
      } else if (data.messageType === WEBSOCKET_EVENTS.UPSERT_DOC_COMMENT) {
        processUpsertDocCommentWebsocketEvent(data);
      } else if (data.messageType === WEBSOCKET_EVENTS.NOTIFICATION) {
        processNotificationWebsocketEvent(data);
      } else if (data.messageType === WEBSOCKET_EVENTS.DRAFT_GROUP_UPDATED) {
        processDraftGroupUpdatedWebsocket(data);
      } else if (data.messageType === WEBSOCKET_EVENTS.FIGMA_FILE_LINKED) {
        processFigmaFileLinkedWebsocket(data);
      } else if (data.messageType === WEBSOCKET_EVENTS.CONNECT_PROJECT_TO_SLACK) {
        processConnectProjectToSlackWebsocketEvent(data);
      }
    },
    [lastMessage]
  );

  useDittoEventListener(
    DittoEvents.componentAttachedToTextItems,
    (data) => {
      textItemIdsToUpdate.current.push(...data.textItemIds);
      processTextItemsIdsToUpdateDebounced();
    },
    []
  );

  const showNotificationToast = (toastText) => {
    setNotifToastText(toastText);
    setNotifToastHidden(false);

    setTimeout(() => {
      setNotifToastHidden(true);
    }, 5000);
  };

  function processGroupSuggestionsWebsocketEvent(data) {
    const jobId = data.data.jobId;
    http.get(`/jobs/setupSuggestions/${jobId}`).then((result) => {
      setSetupSuggestionsForAllGroups(result.data.data);
    });
  }

  // there are multiple websocket events that could fire in sequence which call
  // this function, so a little bit of debouncing is necessary to as a precaution
  const processTextItemsIdsToUpdateDebounced = useDebouncedCallback(processTextItemsIdsToUpdate, 100);

  function processTextItemsIdsToUpdate() {
    const { url, body } = API.comp.post.infoByBatch;
    const textItemIds = [...textItemIdsToUpdate.current];
    textItemIdsToUpdate.current = [];
    if (textItemIds.length === 0) return;
    http
      .post(url, body({ ids: textItemIds }))
      .then(({ data: textItems }) => {
        groupStateDispatch({
          type: GROUP_REDUCER_TYPES.UPDATE_COMPONENTS_IN_GROUPS,
          comps: textItems,
        });

        // // Need to ensure that the docComponents array gets updated,
        // // which the edit panel relies upon for rendering
        // // the currently selected text item
        setDocComponents((prev) => [...prev]);

        // Tell activity panel to update
        setUpdateDocHistory(true);

        // if the new components are currently selected, refresh selected comp data
        if (textItemIds.some((id) => multiSelectedIdsRef.current.includes(id))) {
          props.updateMultiComps(multiSelectedIdsRef.current);
        }
      })
      .catch((error) => {
        logger.error("Error updating front-end text items from TEXT_ITEM_UPDATED event", {}, error);
      });
  }

  function processUpsertDocCommentWebsocketEvent(data) {
    groupStateDispatch({
      type: GROUP_REDUCER_TYPES.UPDATE_COMPONENTS_IN_GROUPS,
      comps: [data.component],
    });

    // Tell activity panel to update
    setUpdateDocHistory(true);
  }

  function processNotificationWebsocketEvent(data) {
    // get the newest unread notification
    const { actorUserName, action, compId, docId } = data.notifications.unreadNotifications[0];
    const toastText = (
      <div onClick={() => setNotifToastHidden(false)}>
        {`${actorUserName} ${action}.`}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => {
            // We have to do this because the selectedComp state on the project page is a mess as it tries to parse
            // url state and query params to determine the selected component
            // Ideally we wouldn't need to do this, but for now to fix a bug where clicking on a notification
            // doesn't select the component, we have to do this
            window.location.replace(`/projects/${docId}/page/${ALL_PAGE_ID}/${compId}`);
          }}
        >
          {"   "}
          View
        </strong>
      </div>
    );

    if (compId) showNotificationToast(toastText);
  }

  function processDraftGroupUpdatedWebsocket(data) {
    if (data.projectId !== projectId) return;

    const { url, body } = API.comp.post.infoByBatch;
    if (data.textItemIds.length === 0) return;
    http.post(url, body({ ids: data.textItemIds })).then(({ data: textItems }) => {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.UPDATE_GROUP,
        groupId: data.groupId,
        groupName: data.name,
        textItems: textItems,
        linkingEnabled: data.linkingEnabled,
      });
    });
  }

  function processFigmaFileLinkedWebsocket(data) {
    const { linkedProject } = data;

    if (linkedProject._id !== projectId) return;

    setProject((originalProject) => {
      if (!originalProject) {
        throw new Error("Can't update project figma data because the project hasn't loaded");
      }

      return {
        ...originalProject,
        integrations: linkedProject.integrations,
      };
    });

    const [request] = getProjectGroups({ projectId });
    request.then(({ data: { groups } }) => {
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
        // our front-end types and back-end types are not well-reconciled at the moment :|
        groups: groups as any[],
      });
    });
  }

  function processConnectProjectToSlackWebsocketEvent(data) {
    const channelInfo = data.channelInfo;
    if (!channelInfo) return;

    setProject((project) => {
      if (!project) return project;

      return {
        ...project,
        integrations: {
          ...project.integrations,
          slack: {
            ...project.integrations.slack,
            ...channelInfo,
          },
        },
      };
    });
  }

  return (
    <div id="PROJECT_RENDER_HANDLER">
      <OverlayToast text={notifToastText} hidden={notifToastHidden} />
    </div>
  );
}

export default memo(ProjectWebsocketsHandler);
