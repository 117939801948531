import { ToastNotification } from "@/components/notification-toast";
import http, { API } from "@/http";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { BillingContext } from "@/store/billingContext";
import { useWorkspace } from "@/store/workspaceContext";
import { userHasBillingRole, userHasPermission } from "@shared/frontend/userPermissionContext";
import { IFUser } from "@shared/types/User";
import { useContext, useEffect, useState } from "react";

export default function useTeamMembersPanel(
  setNotification: React.Dispatch<React.SetStateAction<ToastNotification | null>>
) {
  const { fetchWorkspaceUsers, workspaceInfo, users } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { validateBilling } = useContext(BillingContext);

  const [invites, setInvites] = useState<{ email: string; permissions: string[] }[]>([]);
  const [isInviteSending, setIsInviteSending] = useState(false);
  const [userToRemove, setUserToRemove] = useState<IFUser | null>(null);
  const [userToManagePermissions, setUserToManagePermissions] = useState<IFUser | null>(null);

  const canManagePermissions = userHasPermission("permissions:edit");
  const canInviteCommenter = workspaceInfo?.plan !== "free";
  const canRemoveUser = userHasPermission("users:delete");
  const canEditAdmins = userHasPermission("users:edit:admin");
  const canEditEditors = userHasPermission("users:edit:editor");
  const canEditCommenters = userHasPermission("users:edit:commenter");
  const canEditInvites = userHasBillingRole("editor");
  const permissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  useEffect(function initialize() {
    fetchInvites();
  }, []);

  const fetchInvites = async () => {
    try {
      const { url } = API.invite.get.index;
      const { data } = await http.get(url);
      const { invites } = data;
      setInvites(
        invites.map(({ email, permissionGroups }) => ({
          email,
          permissions: permissionGroups,
        }))
      );
    } catch (error) {
      console.error("Error Fetching Invites", error);
    }
  };

  const inviteUser = async ({ emails, role, type }) => {
    try {
      setIsInviteSending(true);
      const { url, body } = API.invite.post.create;
      const { data } = await http.post(
        url,
        body({
          emails,
          role,
          permissionGroups: [role],
          type,
          docID: undefined,
          docName: undefined,
          message: undefined,
        })
      );
      const { invites } = data;

      setInvites(
        invites.map(({ email, permissionGroups }) => ({
          email,
          permissions: permissionGroups,
        }))
      );
      setNotification({
        type: "success",
        message: emails.length === 1 ? "Invite sent! 🎉" : "Invites sent! 🎉",
        time: 5000,
      });
    } catch (error) {
      console.error("Error Inviting Users", error);
      setNotification({
        type: "error",
        message: `There was an error inviting users.`,
        time: 5000,
      });
    }
    setIsInviteSending(false);
  };

  const updateInvites = async (invites: { email: string; role: string; permissionGroups: string[] }[]) => {
    setInvites(
      invites.map(({ email, permissionGroups }) => ({
        email,
        permissions: permissionGroups,
      }))
    );
  };

  const resendInvite = async (email: string) => {
    try {
      const { url, body } = API.invite.post.resend;
      await http.post(
        url,
        body({
          email,
        })
      );
      setNotification({
        type: "success",
        message: "Invite resent! 🎉",
        time: 5000,
      });
    } catch (error) {
      console.error("Error resending invite", error);
      setNotification({
        type: "error",
        message: `There was an error resending the invite.`,
        time: 5000,
      });
    }
  };

  const revokeInvite = async (email: string) => {
    try {
      const { url, body } = API.invite.delete.revoke;
      await http.delete(url, {
        data: body({
          email,
        }),
      });
      const updatedInvites = invites.filter((invite) => invite.email !== email);
      setInvites([...updatedInvites]);
      setNotification({
        type: "success",
        message: "Invite successfully revoked",
        time: 5000,
      });
    } catch (error) {
      console.error("Error removing user", error);
      setNotification({
        type: "error",
        message: `There was an error revoking the invite.`,
        time: 5000,
      });
    }
  };

  const canEditUser = (userToEdit: IFUser) => {
    if (user._id.toString() === userToEdit?._id?.toString()) {
      return false;
    }

    return (
      (canEditAdmins && userToEdit.role === "admin") ||
      (canEditEditors && userToEdit.role === "editor") ||
      (canEditCommenters && userToEdit.role === "commenter")
    );
  };

  const removeUser = async (selected_user: IFUser) => {
    try {
      const { url, body } = API.workspace.put.usersRemove;
      await http.put(
        url,
        body({
          email: selected_user.email,
          userId: selected_user.userId,
        })
      );
      validateBilling();
      fetchWorkspaceUsers();
      setUserToRemove(null);
      setNotification({
        type: "success",
        message: `${selected_user.name} has been removed.`,
        time: 5000,
      });
    } catch (error) {
      console.error("Error removing user", error);
      setNotification({
        type: "error",
        message: `There was an error removing the user.`,
        time: 5000,
      });
    }
  };

  const updateUserRole = async (selected_user: IFUser, role: string) => {
    try {
      if (selected_user.permissionGroups[0] === role) return;
      const updatedRole = role;
      const { url, body } = API.workspace.put.usersRole;
      await http.put(
        url,
        body({
          userId: selected_user.userId,
          role: updatedRole,
        })
      );
      validateBilling();
      fetchWorkspaceUsers();
      setUserToRemove(null);
      setNotification({
        type: "success",
        message: `${selected_user.name} has been updated.`,
        time: 5000,
      });
    } catch (error) {
      setNotification({
        type: "error",
        message: `There was an error updating ${selected_user.name}.`,
        time: 5000,
      });
    }
  };

  const permissionOptions = [
    { name: "Admin", value: "admin", isDisabled: !canRemoveUser },
    { name: "Can edit", value: "editor", isDisabled: false },
    {
      name: "Can comment",
      value: "commenter",
      isDisabled: !canInviteCommenter,
    },
  ];

  return {
    activeUserCount: users.length,
    canEditInvites,
    canEditUser,
    canManagePermissions,
    canRemoveUser,
    inviteUser,
    invites,
    isInviteSending,
    permissionGroupsEnabled,
    permissionOptions,
    removeUser,
    resendInvite,
    revokeInvite,
    setUserToManagePermissions,
    setUserToRemove,
    updateInvites,
    updateUserRole,
    userToManagePermissions,
    userToRemove,
    users,
  };
}
