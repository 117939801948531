import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import style from "./DraggableWSComp.module.css";
import { DraggableData, DraggableTypes } from "./lib";

export const DraggableWSComp = ({
  id,
  name,
  selectedComps,
  children,
}: {
  id: string;
  name: string;
  selectedComps?: { _id: string; name: string }[];
  children: React.ReactNode;
}) => {
  const isInGroup = name.split("/").length > 1;
  const isMulti = selectedComps && selectedComps.length > 1;

  const dragData: DraggableData = {
    type: isMulti ? DraggableTypes.MULTI_COMPONENT : DraggableTypes.COMPONENT,
    name: isMulti ? `${selectedComps?.length} components` : name,
    isInGroup: isMulti ? true : isInGroup,
    dragCompId: id,
    selectedComps: selectedComps && selectedComps?.length > 0 ? selectedComps : [{ _id: id, name }],
  };

  const { active, attributes, listeners, setNodeRef } = useDraggable({
    id,
    data: dragData,
  });

  const lowlight = active?.data?.current?.selectedComps?.map((c) => c._id)?.includes(id);

  return (
    <div
      className={classNames(style.draggableComp, {
        [style.lowlight]: lowlight,
      })}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {children}
    </div>
  );
};
