import ObjectId from "bson-objectid";
import { generateBaseRichTextObject } from "../../../../shared/utils/richText";
import { Group, GroupLinkable, GroupLinked, GroupUnlinkable, isGroupLinked } from "./types";

export interface CategorizedGroupState {
  groupsLinked: GroupLinked[];
  groupsUnlinked: (GroupLinkable | GroupUnlinkable)[];
}

export const createDefaultDraftGroup = (projectId: string, workspaceId: string): GroupUnlinkable => ({
  _id: new ObjectId().toString(),
  blocks: [],
  comps: [
    {
      _id: new ObjectId().toString(),
      assignee: null,
      text: "",
      rich_text: generateBaseRichTextObject(""),
      workspace_ID: workspaceId,
      doc_ID: projectId,
      status: "NONE",
      tags: [],
      apiID: null,
      variables: [],
      plurals: [],
      notes: null,
      in_graveyard: false,
      graveyard_apiID: null,
      has_conflict: false,
      ws_comp: null,
      comment_threads: [],
      variants: [],
      integrations: {},
      updatedAt: new Date(),
      lastSync: null,
      text_last_modified_at: new Date(),
      characterLimit: null,
    },
  ],
  integrations: { figma: {} },
  linking_enabled: false,
  name: "",
  hidden: false,
  pinned: false,
  nonblocks_collapsed: false,
  unsaved: true,
});

export const getCategorizedGroups = (groups: Group[]): CategorizedGroupState => {
  const result: CategorizedGroupState = {
    groupsLinked: [],
    groupsUnlinked: [],
  };

  groups.forEach((group) =>
    isGroupLinked(group) ? result.groupsLinked.push(group) : result.groupsUnlinked.push(group)
  );

  return result;
};
