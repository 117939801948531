import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

export interface IProgressSegment {
  color: string; // Limiting the colors to a set few
  percentage: number;
}

export interface IProgressCircleProps {
  className?: string;
  segments: IProgressSegment[];
  totalPercentageColor?: string;
  percentageDisplayed?: number;
  hidePercentage?: boolean;
}

export default function ProgressCircle(props: IProgressCircleProps) {
  const size = 16;
  const strokeWidth = Math.round(size * 0.21875);
  const cx = size / 2;
  const cy = size / 2;
  const r = size / 2 - strokeWidth / 2;
  const totalProgress = props.segments.reduce((sum, segment) => sum + segment.percentage, 0);
  const totalProgressPercentage = totalProgress / 100;
  const circumference = Math.PI * (r * 2);
  const totalProgressLength = totalProgressPercentage * circumference;

  const backgroundColor = "#E5E5E5";

  return (
    <div className={classNames(style.progressCircleContainer, props.className)}>
      <svg width={size + 2} height={size + 2}>
        <circle cx={cx} cy={cy} r={r} fill="none" stroke={backgroundColor} strokeWidth={strokeWidth} />
        {props.segments.map((segment, index) => {
          const segmentLength = (segment.percentage / totalProgress || 0) * totalProgressLength;
          const currentOffset =
            (props.segments.slice(0, index).reduce((sum, segment) => {
              sum += segment.percentage;
              return sum;
            }, 0) / totalProgress || 0) *
            totalProgressPercentage *
            360;

          return (
            <circle
              key={index}
              cx={cx}
              cy={cy}
              r={r}
              fill="none"
              stroke={segment.color}
              strokeWidth={strokeWidth}
              strokeDasharray={segmentLength + " " + circumference}
              transform={`rotate(${currentOffset - 90} ${cx} ${cy})`}
            />
          );
        })}
      </svg>
      {!props.hidePercentage && (
        <span
          style={{
            color: props.totalPercentageColor ?? props.segments[props.segments.length - 1].color,
          }}
          className={style.totalProgressPercentage}
        >
          {props.percentageDisplayed || props.segments[props.segments.length - 1].percentage}%
        </span>
      )}
    </div>
  );
}
