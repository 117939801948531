import AutoNameButton from "@/components/AutoNameButton";
import { UseAutoNameSuggestionReturnValue } from "@/hooks/useAutoNameSuggestion";
import { useWorkspace } from "@/store/workspaceContext";
import UndoIcon from "@mui/icons-material/Undo";
import { PropagationWrapper } from "@shared/frontend/PropagationWrapper";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { ActualComponentInterface } from "@shared/types/TextItem";
import { default as classNames, default as classnames } from "classnames";
import debounce from "lodash.debounce";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { PopulatedCreateSuggestion } from "../../../../shared/types/ComponentSuggestions";
import FolderSelect from "../../FolderSelect";
import useFolderSelect, { Folder } from "../../FolderSelect/useFolderSelect";
import TextItem from "../../TextItem";
import { useCategoryDropdownMenu } from "../../shared/CategoryDropdownMenu";
import SuggestionPagination from "../SuggestionPagination";
import style from "./style.module.css";

interface ComponentCreateSuggestionProps {
  compMap: { [key: string]: ActualComponentInterface };
  isEnabled: boolean;
  isSelected: boolean;
  multiSelectedSuggestions: PopulatedCreateSuggestion<string>[];
  handleMultiSelect: (suggestion_id: string) => void;
  index: number;
  suggestion: PopulatedCreateSuggestion<string>;
  handleSelected: (index: number) => void;
  handleIgnoreSuggestion: (id: string, type: string, ignored: boolean) => void;
  pageThroughDupes: (id: string | null) => void;
  createAndAttachSuggestion: (args: {
    name: string;
    comp_ids: string[];
    componentFolderId: string;
    suggestionId?: string;
  }) => void;
  checkComponentNameExists: (componentName: string) => Promise<boolean>;
  setCompName: (name: string) => void;
  setCompFolder: (folder_id: string | null) => void;
  setError: (error: string) => void;
  error: string;
  compName: string;
  generateSuggestionFor: UseAutoNameSuggestionReturnValue["generateSuggestionFor"];
  saveAnalyticsFor: UseAutoNameSuggestionReturnValue["saveAnalyticsFor"];
}

const ComponentCreateSuggestion = (props: ComponentCreateSuggestionProps) => {
  const {
    compMap,
    isEnabled,
    isSelected,
    multiSelectedSuggestions,
    handleMultiSelect,
    index,
    suggestion,
    handleSelected,
    handleIgnoreSuggestion,
    pageThroughDupes,
    createAndAttachSuggestion,
    checkComponentNameExists,
    setCompName,
    setCompFolder,
    compName,
    error,
    setError,
    generateSuggestionFor,
    saveAnalyticsFor,
  } = props;

  const workspace = useWorkspace();

  const { ignored, comp_ids } = suggestion;

  const isMultiSelected = multiSelectedSuggestions.some((s) => s._id === suggestion._id);

  const anyMultiSelected = multiSelectedSuggestions.length > 0;

  // if a component is multiSelected, it should look always look like it's selected regardless of r
  const showExpandedView = isSelected || isMultiSelected;

  const [suggestionIndex, setSuggestionIndex] = useState<number>(0);
  const [isActiveHover, setIsActiveHover] = useState<boolean>(false);
  const categoryDropdownMenu = useCategoryDropdownMenu({
    name: compName,
    setName: setCompName,
  });

  const hasEditAccessToDefaultComponentFolder = userHasResourcePermission(
    "component_folder:edit",
    "component_folder_no_id"
  );

  const onFolderChange = (folder: Folder) => {
    setCompFolder(folder._id || null);
  };

  const {
    selectedFolder,
    setSelectedFolder,
    folders,
    isLoading: isFolderSelectLoading,
  } = useFolderSelect({
    disableDefaultFolder: !hasEditAccessToDefaultComponentFolder,
    onFolderSelectCallback: onFolderChange,
  });

  const shouldShowFolderSelector = folders.length > 1 || (folders.length === 1 && folders[0]._id !== "");

  const debouncedNameValidation = debounce(async (query) => {
    if (query.slice(-1) === "/") {
      setError("Please enter a component name.");
    } else if (await checkComponentNameExists(query)) {
      setError("Component names must be unique.");
    } else setError("");
  }, 150);

  const onCompNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let temp = e.target.value;
    setCompName(temp);
    categoryDropdownMenu.updateCategoryMenuOffsetWidth();
    debouncedNameValidation(temp);
  };

  const decrementIndex = (e) => {
    e.stopPropagation();
    if (suggestionIndex > 0) {
      let temp = suggestionIndex - 1;
      setSuggestionIndex(temp);

      pageThroughDupes(compMap[comp_ids[temp]]?._id);
    }
  };

  const incrementIndex = (e) => {
    e.stopPropagation();
    if (suggestionIndex < comp_ids.length - 1) {
      let temp = suggestionIndex + 1;
      setSuggestionIndex(temp);
      pageThroughDupes(compMap[comp_ids[temp]]?._id);
    }
  };

  const onIgnoreSuggestion = (e) => {
    e.stopPropagation();
    handleIgnoreSuggestion(suggestion._id, "creatable", !suggestion.ignored);
  };

  const onIgnoreSuggestionBtnMouseEnter = () => {
    setIsActiveHover(true);
  };

  const onIgnoreSuggestionBtnMouseLeave = () => {
    setIsActiveHover(false);
  };

  const onSelect = () => {
    if (ignored) return;
    handleSelected(index);

    if (!isSelected && compMap[comp_ids[0]]) pageThroughDupes(compMap[comp_ids[0]]?._id);
    else {
      setSuggestionIndex(0);
      pageThroughDupes(null);
    }
  };

  const onCreate = () => {
    if (compName === "") {
      setError("Please enter a component name.");
      return;
    }

    saveAnalyticsFor(suggestion.text);

    createAndAttachSuggestion({
      name: compName,
      comp_ids,
      componentFolderId: selectedFolder?._id,
      suggestionId: suggestion._id,
    });
  };

  const onCheckClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    // the check should always expand the suggestion, but not close it
    if (!isSelected) {
      handleSelected(index);
      if (compMap[comp_ids[0]]) pageThroughDupes(compMap[comp_ids[0]]?._id);
    }
    handleMultiSelect(suggestion._id);
  };

  // selecting another suggestion should reset the suggestion index for this one
  useEffect(
    function handleDeselectedExternally() {
      if (!isSelected) {
        setSuggestionIndex(0);
      }
    },
    [isSelected]
  );

  const selectedComp = compMap[comp_ids[suggestionIndex]];

  const [isLoadingNameSuggestion, setIsLoadingNameSuggestion] = useState(false);

  return (
    <div
      className={classnames({
        [style.compSuggestion]: true,
        [style.isSelected]: showExpandedView,
        [style.isMultiSelected]: isMultiSelected,
        [style.isIgnored]: ignored && !isActiveHover,
        [style.activeIgnored]: ignored && isActiveHover,
      })}
      onClick={onSelect}
    >
      <div
        className={classnames({
          [style.suggestionInfo]: true,
          [style.isSelected]: showExpandedView,
        })}
      >
        {!isSelected ? (
          <div className={style.instanceInfo}>
            <div className={style.numberBadge}>{comp_ids.length}</div>
            {` instance${comp_ids.length > 1 ? "s" : ""} in project`}
          </div>
        ) : (
          <SuggestionPagination
            className={style.pagination}
            isSelected={isSelected}
            index={suggestionIndex}
            numItems={comp_ids.length}
            incrementIndex={incrementIndex}
            decrementIndex={decrementIndex}
            itemName={{ singular: "instance", plural: "instances" }}
          />
        )}
        {!ignored && (
          <PropagationWrapper>
            <input
              type="checkbox"
              checked={isMultiSelected}
              onChange={onCheckClick}
              className={classNames(style.multiSelectCheckbox, {
                [style.checked]: isMultiSelected,
              })}
            />
          </PropagationWrapper>
        )}
      </div>

      {selectedComp && (
        <div className={style.suggestionInstance}>
          <div className={style.wsCompItem}>
            <div className={style.top}>
              <div className={style.text}>
                <TextItem textItem={selectedComp} frameVariant={null} />
              </div>
            </div>
            {(selectedComp.notes || selectedComp.tags.length > 0) && (
              <div className={style.bottom}>
                {selectedComp.notes && (
                  <div
                    className={classnames({
                      [style.marginBottom]: selectedComp.tags.length > 0,
                    })}
                  >
                    {selectedComp.notes}
                  </div>
                )}
                {selectedComp.tags.length > 0 && (
                  <div className={style.tags}>
                    {selectedComp.tags.map((tag, index) => (
                      <div key={index} className={style.tag}>
                        {tag}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      <PropagationWrapper>
        {showExpandedView && (
          <div className={style.compNameArea}>
            <span className={style.label}>New component name</span>
            <div className={style.componentNameInputWrapper}>
              {compName.length === 0 && workspace.workspaceInfo?.aiFeatures.automatedComponentNaming && (
                <div className={style.autoNameButtonWrapper}>
                  <AutoNameButton
                    onClick={async function () {
                      setIsLoadingNameSuggestion(true);
                      const newName = await generateSuggestionFor(suggestion.text);
                      setCompName(newName);
                      setIsLoadingNameSuggestion(false);
                    }}
                    isLoadingNameSuggestion={isLoadingNameSuggestion}
                  />
                </div>
              )}
              <input
                placeholder="Ex: Onboarding/Modal/CTA"
                ref={categoryDropdownMenu.inputRef}
                value={compName}
                onChange={onCompNameChange}
                className={classNames({
                  [style.compNameInput]: true,
                  [style.errorInput]: Boolean(error),
                })}
              />
            </div>
            <div className={style.hiddenTextWrapper}>
              <div id="hidden-input-value" className={style.hiddenInputValue}>
                {categoryDropdownMenu.categoryDisplay}
              </div>
            </div>
            {/* <CategoryDropdownMenu {...categoryDropdownMenu} /> */}
            {error && <div className={style.errorMsg}>{error}</div>}
            {shouldShowFolderSelector && (
              <FolderSelect
                setSelectedFolder={setSelectedFolder}
                folders={folders}
                selectedFolder={selectedFolder}
                text="Creating in"
                fontSize="12px"
                className={style.folderSelect}
                dropdownClassName={style.folderDropdown}
              />
            )}
          </div>
        )}
      </PropagationWrapper>
      <div className={style.attachBtn}>
        {/* 
          the logic here is weird, but basically: we don't want to show the 
          ignore button if any of the suggestions are multi-selected, *except*  
          for the un-ignore button, which *always* shows for ignored suggestions.
         */}

        {ignored && (
          <IgnoreSuggestionBtn
            ignored={ignored}
            isEnabled={isEnabled}
            onIgnoreSuggestion={onIgnoreSuggestion}
            onIgnoreSuggestionBtnMouseEnter={onIgnoreSuggestionBtnMouseEnter}
            onIgnoreSuggestionBtnMouseLeave={onIgnoreSuggestionBtnMouseLeave}
          />
        )}

        {/*
          we do still want to show the Review button when there are multi-selections
         */}
        {!ignored && !showExpandedView && (
          <Button onClick={onSelect} disabled={!isEnabled}>
            Review
          </Button>
        )}

        {/* 
          only show the ignore & create+attach buttons when we are in expanded view
          and there are no multi-selections
        */}
        {!ignored && showExpandedView && !anyMultiSelected && (
          <PropagationWrapper>
            <IgnoreSuggestionBtn
              ignored={ignored}
              isEnabled={isEnabled}
              onIgnoreSuggestion={onIgnoreSuggestion}
              onIgnoreSuggestionBtnMouseEnter={onIgnoreSuggestionBtnMouseEnter}
              onIgnoreSuggestionBtnMouseLeave={onIgnoreSuggestionBtnMouseLeave}
            />
            <Button onClick={onCreate} disabled={!isEnabled}>
              Create and attach to all
            </Button>
          </PropagationWrapper>
        )}
      </div>
    </div>
  );
};

const IgnoreSuggestionBtn = ({
  ignored,
  isEnabled,
  onIgnoreSuggestion,
  onIgnoreSuggestionBtnMouseEnter,
  onIgnoreSuggestionBtnMouseLeave,
}: {
  ignored: boolean;
  isEnabled: boolean;
  onIgnoreSuggestion: (e: any) => void;
  onIgnoreSuggestionBtnMouseEnter: () => void;
  onIgnoreSuggestionBtnMouseLeave: () => void;
}) => (
  <span
    className={classnames({
      [style.ignoreSuggestionBtn]: true,
      [style.disabled]: !isEnabled,
    })}
    onClick={onIgnoreSuggestion}
    onMouseEnter={onIgnoreSuggestionBtnMouseEnter}
    onMouseLeave={onIgnoreSuggestionBtnMouseLeave}
  >
    {ignored ? (
      <>
        <UndoIcon className={style.undoIcon} /> Unignore
      </>
    ) : (
      "Ignore"
    )}
  </span>
);

export default ComponentCreateSuggestion;
