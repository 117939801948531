import useSegment from "@/hooks/useSegment";
import * as SegmentEvents from "@shared/segment-event-names";
import { Ditto } from "ditto-react";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";

export interface IUpsellModalProps {
  actionDittoComponentId: string;
  contentDittoComponentId: string;
  onHide?: () => void;
  titleDittoComponentId: string;
  fullscreen?: boolean;
}

export default function UpsellModal(props: IUpsellModalProps) {
  const segment = useSegment();

  const trackClick = () => {
    segment.track({ event: SegmentEvents.CONTACT_SALES_CLICKED });
  };

  const content = () => (
    <div data-testid="upsell-modal">
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          <Ditto componentId={props.titleDittoComponentId} />
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <Ditto componentId={props.contentDittoComponentId} />
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <div>
          <a
            href="mailto:enterprise@dittowords.com"
            target="_blank"
            rel="noreferrer"
            onClick={trackClick}
            className={style.actionLink}
          >
            <Ditto componentId={props.actionDittoComponentId} />
          </a>
        </div>
      </BootstrapModal.Footer>
    </div>
  );

  return (
    <div>
      {(props.fullscreen === undefined || props.fullscreen) && (
        <BootstrapModal
          show={true}
          contentClassName={style.modal}
          dialogClassName={style.dialog}
          onHide={props.onHide}
          centered
        >
          {content()}
        </BootstrapModal>
      )}
      {props.fullscreen === false && <div className={style.modal}>{content()}</div>}
    </div>
  );
}
