import { pollingBackgroundJobRequest } from "@/http/lib/clientHelpers";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import ErrorIcon from "@mui/icons-material/Error";
import Info from "@mui/icons-material/Info";
import parseFigmaFileURL from "@shared/common/parseFigmaFileURL";
import * as SegmentEvents from "@shared/segment-event-names";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import useSegment from "../../../../hooks/useSegment";
import style from "./style.module.css";

interface Props {
  projectId: string;
  handleActivateProject: (fileLink: string) => Promise<void>;
}

const ActivateSampleProjectModal = (props: Props) => {
  const segment = useSegment();
  const { user } = useAuthenticatedAuth();
  const [fileLink, setFileLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [sampleImportErrorMesage, setSampleImportErrorMessage] = useState<string | null>(null);

  // This is a hack to allow the navbar to show above the modal backdrop.
  // Bootstrap makes this difficult to do because it creates two elements that blocks input
  // The first is the backdrop which adds the dark overlay (we can target this)
  // The second is a div that blocks user input, which we can't target (I assume it is used to track onclick events off of the modal)
  // Anyways, TLDR bootstrap is a pain in the ass
  useEffect(function allowNavToShow() {
    const navBar = document.querySelector('[class^="Navbar-module__navBar"]') as HTMLElement;
    if (navBar) {
      navBar.style.zIndex = "2000";
    }

    return () => {
      if (navBar) {
        navBar.style.zIndex = "";
      }
    };
  }, []);

  const showConnectMessage = user.isFigmaAuthenticated === false;

  const handleActivateProject = async () => {
    setIsLoading(true);
    try {
      const { fileId } = parseFigmaFileURL(fileLink);
      if (!fileId) {
        throw new Error("Please enter a valid Figma link.");
      }

      await props.handleActivateProject(fileId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileLink(e.target.value);
    setError("");
    setSampleImportErrorMessage(null);
  };

  const disableButton =
    showConnectMessage || fileLink.length === 0 || Boolean(error) || Boolean(sampleImportErrorMesage) || isLoading;

  const importFile = async () => {
    segment.track({ event: SegmentEvents.SAMPLE_PROJECT_ACTIVATED });

    const { fileId, branchId } = parseFigmaFileURL(fileLink);
    if (fileId) {
      setIsLoading(true);
      const response = await pollingBackgroundJobRequest<any>({
        url: `/jobs/figmaGetPages`,
        requestBody: {
          figmaFileId: fileId,
          figmaBranchId: branchId,
          onImport: true,
        },
      });
      setIsLoading(false);
      if (!response.isOriginalSampleProject && !response.error && response.status === "is-sample-project") {
        handleActivateProject();
        return;
      }

      if (response?.errorStatus === 403) {
        setSampleImportErrorMessage(
          "We were unable to connect to this Figma file. Make sure you have permission to access it."
        );
        return;
      }
    }
    setSampleImportErrorMessage(
      "Looks like this isn't a valid link! Make sure you've duplicated the sample project file above and pasted that link here."
    );
  };

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={() => {}}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>Activate your Ditto sample project 🚀</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div className={style.text}>Follow the instructions below to finish setting up your Ditto sample project.</div>
        {showConnectMessage && (
          <div className={style.errorContainer}>
            <ErrorIcon className={style.errorIcon} />
            <div className={style.errorMessage}>
              Looks like you haven’t connected your Figma account. Before continuing, head to your{" "}
              <a
                className={style.errorLink}
                href={`/account/user?reauthorize_figma=true&redirect_project_id=${props.projectId}`}
              >
                Account Settings
              </a>{" "}
              to do that.
            </div>
          </div>
        )}

        <div className={style.step}>
          <div>
            <div className={style.stepNumber}>1</div>
          </div>

          <div className={style.rightSide}>
            <span className={style.stepHeader}>Duplicate the following Figma file.</span>
            <span className={style.subhead}>
              Tip: Save it to a place that’s accessible by your entire team, not just your "Drafts.” This will ensure
              this sample project can also be accessed by everyone!
            </span>
            <a
              className={style.button}
              href="https://www.figma.com/community/file/1299742238867182384/dittopay-signup-flow-sample-project"
              target="_blank"
            >
              Open file in Figma{`->`}
            </a>
          </div>
        </div>

        <div className={style.step}>
          <div>
            <div className={style.stepNumber}>2</div>
          </div>

          <div className={style.rightSide}>
            <span className={style.stepHeader}>Paste the link to your newly-duplicated Figma file below. </span>
            <span className={style.subhead}>Tip: in Figma, click the blue “Share” button, then “Copy link.” </span>
            <input
              className={style.input}
              value={fileLink}
              onChange={handleInputChange}
              placeholder="https://www.figma.com/file/example"
            />
          </div>
        </div>

        {sampleImportErrorMesage && (
          <div className={style.sampleImportMessage}>
            <Info className={style.icon} />
            {sampleImportErrorMesage}
          </div>
        )}

        <div className={style.bottomRow}>
          {error && <div className={style.error}>{error}</div>}
          <ButtonPrimary text="Go" loading={isLoading} onClick={importFile} disabled={disableButton} />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default ActivateSampleProjectModal;
