import { useWorkspace } from "@/store/workspaceContext";
import { getIsComponentMatch, getIsQueryMatch, getIsStatusMatch } from "@/views/Project/state/useGroupRenderState";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import EditIcon from "@mui/icons-material/Edit";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotesIcon from "@mui/icons-material/Notes";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import StarIcon from "@mui/icons-material/Star";
import UndoIcon from "@mui/icons-material/Undo";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { removeFigmaArtifactsFromGroupName } from "@shared/lib/groups";
import * as SegmentEvents from "@shared/segment-event-names";
import { default as classNames, default as classnames } from "classnames";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Dropdown from "react-bootstrap/Dropdown";
import Masonry from "react-masonry-css";
import { useParams } from "react-router-dom";
import { Element } from "react-scroll";
import ReactTooltip from "react-tooltip";
import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import useDebouncedCallback from "../../../util/useDebouncedCallback";
import pointEmoji from "../../assets/pointer_emoji.png";
import { FlaggedFeaturesContext } from "../../flagged-feature";
import { useScroll } from "../../hooks/useScroll";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { isGroupLinkable, isGroupLinked } from "../../views/Project/state/types";
import { UNSELECT_ALL_PROPS } from "../../views/Project/state/unselectAll";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import EditableName from "../EditableName";
import PushPinIcon from "../PushPinIcon";
import ApiID from "../api-id";
import Blocks from "../blocks/blocks.jsx";
import Comp from "../comp";
import EnlargeImageModal from "../enlarge-image-modal/enlarge-image-modal";
import SelectVariantModal from "../select-variant-modal";
import ConfirmationModal from "../shared/confirmation-modal";
import VariantModal from "../variant-modal";
import FigmaComponentInfo from "./FigmaComponentInfo";
import FigmaFrameName from "./components/FigmaFrameName";
import GroupImagePreview from "./components/GroupImagePreview";
import SuggestionsBanner from "./components/SuggestionsBanner";
import TextPreviewToggle from "./components/TextPreviewToggle";
import style from "./style.module.css";
import useVariantTabs from "./useVariantTabs";

const masonryBreakpoints = {
  default: 3,
  1100: 3,
  786: 2,
  650: 1,
};

export const GroupContext = createContext({});

const PREVIEW_MODE_KEY = "ditto_preview_groups";
const VARIANT_TAB_MAX_WIDTH = 150;

const CompGroup = ({
  tagState,
  pinned,
  index,
  pageIndex,
  groupIndex,
  scrollToId,
  name,
  groupId,
  blocks,
  imgUrl,
  comps,
  doc_ID,
  appliedVariantId,
  selectComp,
  selectAllCompsInGroup,
  selectedId,
  suggestedCompId,
  setPanelState,
  setCommentState,
  query,
  assignee,
  devIDFilter,
  componentFilter,
  multiSelectedIds,
  multiOn,
  status,
  isLast,
  figmaId,
  artboardsLoadingFinished,
  nonblocks_collapsed,
  updateFrames,
  numCompsArray,
  doc_name,
  figmaFileId,
  figmaAccessToken,
  apiID,
  allFrameApiIDs,
  handleHistoryUpdate,
  displayApiIds,
  frameVariants,
  workspaceVariants,
  handleSelectFrameVariant,
  handleAddNewVariants,
  handleDeleteFrameVariant,
  onToggleHidden,
  hiddenOpen,
  setGroupMeta,
  group,
  devModeEnabled,
  groupStateDispatch,
  className,
  setCreateTextItemPanelStatus,
  showAddTextPlaceholder,
  blockMoveActions,
  isSetupPreview,
  showSetupFromAnotherGroup,
  pollingForPreviewUpdates,
  previewsLastUpdatedAt,
  resyncLoading,
  variants,
  activeVariantIndex,
  setActiveVariantIndex,
  isSampleProject,
}) => {
  const segment = useSegment();

  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const { isProjectLocked } = useContext(FlaggedFeaturesContext);
  const { workspaceInfo } = useWorkspace();
  const {
    setupSuggestions,
    groupPreviewStateById: [groupPreviewStateById, setGroupPreviewStateById],
    search: {
      variant: [variantFilter],
    },
    setActiveVariantIndexForGroup,
  } = useProjectContext();

  const groupPreviewState = groupPreviewStateById.get(groupId) || "TEXT";
  const isDesignPreviewState = groupPreviewState === "DESIGN";

  const onToggleGroupPreviewState = () => {
    const valueUpdated = groupPreviewState === "TEXT" ? "DESIGN" : "TEXT";

    try {
      const defaultPreviewViewsStr = window.localStorage.getItem(PREVIEW_MODE_KEY);
      let defaultPreviewViews = {};
      if (defaultPreviewViewsStr) {
        defaultPreviewViews = JSON.parse(defaultPreviewViewsStr);
      }
      defaultPreviewViews[group._id] = valueUpdated;
      window.localStorage.setItem(PREVIEW_MODE_KEY, JSON.stringify(defaultPreviewViews));

      window?.analytics?.track("Text Preview Toggle", {
        pageIndex,
        groupIndex,
        groupId,
        state: valueUpdated,
      });
      if (valueUpdated === "TEXT") {
        scrollToElement();
      }
    } catch (error) {
      console.error("Error doing 'onToggleGroupPreviewView'", error);
    } finally {
      setGroupPreviewStateById((m) => new Map(m).set(groupId, valueUpdated));
    }
  };

  const { scrollToId: scrollToTextBox } = useScroll({
    containerId: `projectContainer`,
    duration: 500,
    timeout: 100,
  });

  function scrollToElement() {
    setTimeout(() => {
      if (!selectedId) return;
      if (comps.length > 0) {
        // in some cases the comps array is populated with strings instead of objects
        if (typeof comps[0] === "string" && !comps.includes(selectedId)) return;
        if (!comps.some((c) => c._id == selectedId)) return;
      }
      scrollToTextBox(selectedId);
    }, 0);
  }

  useEffect(
    function getDefaultGroupView() {
      try {
        const defaultPreviewViewsStr = window.localStorage.getItem(PREVIEW_MODE_KEY);
        if (!defaultPreviewViewsStr) return;

        const defaultPreviewViews = JSON.parse(defaultPreviewViewsStr);
        const defaultPreviewForGroup = defaultPreviewViews[groupId];

        if (defaultPreviewForGroup && ["DESIGN", "TEXT"].includes(defaultPreviewForGroup))
          setGroupPreviewStateById((m) => new Map(m).set(groupId, defaultPreviewForGroup));
      } catch (error) {
        console.error("Error doing 'getDefaultGroupView'", error);
      }
    },
    [groupId]
  );

  const isLinked = isGroupLinked(group);

  const setupSuggestionsExist = useMemo(
    () =>
      Object.keys(setupSuggestions?.blockSuggestions).length > 0 ||
      setupSuggestions?.hideSuggestions.length > 0 ||
      setupSuggestions?.wsComponentSuggestions.length > 0,
    [setupSuggestions?.hideSuggestions, setupSuggestions?.showSuggestions, setupSuggestions?.wsComponentSuggestions]
  );

  const canBeReverted = useMemo(() => {
    if (!isGroupLinkable(group)) {
      return false;
    }

    if (group.blocks.length) {
      return false;
    }

    if (frameVariants?.length) {
      return false;
    }

    const hasDisqualifyingMetadata = group.comps.some(
      (textItem) =>
        textItem.ws_comp ||
        textItem.hidden ||
        textItem.status !== "NONE" ||
        textItem.tags?.length > 0 ||
        textItem.variables?.length > 0 ||
        textItem.plurals?.length > 0 ||
        textItem.variants?.length > 0 ||
        textItem.notes
    );

    return !hasDisqualifyingMetadata;
  }, [group, frameVariants]);

  const [reverting, setReverting] = useState(false);

  // TODO: switch this to get project id from the project
  // context once we're no longer using the old /doc page
  const { id: projectId } = useParams();

  const [showRevertToDraftConfirmationModal, setShowRevertToDraftConfirmationModal] = useState(false);

  const groupName = removeFigmaArtifactsFromGroupName(name);
  const figmaFrameName = group.integrations.figma?.frame_name;

  const onGroupNameSave = async (updatedName) => {
    await API.project.upsertGroup({
      projectId,
      groupId,
      data: { name: updatedName },
    });

    updateFrames([groupId]);
  };

  const isDragDisabled =
    pinned || status !== "Any" || tagState.selected.size > 0 || query.length !== 0 || !artboardsLoadingFinished;
  const [blockToRename, setBlockToRename] = useState();
  const [framePreviewOpen, setFramePreviewOpen] = useState(false);
  const [showAddVariantModal, setShowAddVariantModal] = useState(false);
  const [showSelectVariantModal, setShowSelectVariantModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    variantTabs,
    groupContainerRef,
    activeTabIndex,
    getVariantIndexById,
    activeVariant,
    hoveredVariantId,
    setHoveredVariantId,
  } = useVariantTabs({
    activeVariantIndex,
    frameVariants,
  });

  const filtersOff = useMemo(
    () =>
      query === "" &&
      assignee == null &&
      status === "Any" &&
      devIDFilter === null &&
      componentFilter === null &&
      tagState.selected.size == 0,
    [query, assignee, status, tagState.selected, devIDFilter, componentFilter]
  );

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const manualApiIdEditsAllowed =
    isEditEnabled && !showSetupFromAnotherGroup && !workspaceInfo?.config?.projects?.apiIdPreventManualEdits;

  const appliedVariant = useMemo(() => {
    if (!appliedVariantId) return null;

    const name = workspaceVariants[appliedVariantId];
    if (name) return { name, exists: true };

    return { exists: false };
  }, [workspaceVariants, appliedVariantId]);

  const isInFilters = (component) => {
    if (!component) {
      return false;
    }

    if (filtersOff) {
      return true;
    }

    //check if in search query
    if (!(query === "" || getIsQueryMatch(component, query, variantFilter?.id || null))) {
      return false;
    }

    // check explicit dev ID filter
    if (devIDFilter && !component.apiID.toLowerCase().includes(devIDFilter.toLowerCase())) {
      return false;
    }

    if (!getIsComponentMatch(component, componentFilter)) {
      return false;
    }

    // check status
    if (!getIsStatusMatch(component, status, variantFilter?.id || null)) {
      return false;
    }

    // check tags
    if (
      tagState.selected.size > 0 &&
      !Array.from(tagState.selected.values()).every((tag) => component.tags.includes(tag))
    ) {
      return false;
    }

    // check assignee
    if (assignee && component.assignee !== assignee) {
      return false;
    }

    return true;
  };

  const filteredResultsNotHidden = comps.filter((comp) => !comp?.is_hidden && isInFilters(comp));
  const filteredResultsBlocks = blocks.flatMap((block) =>
    block.comps.filter((comp) => !comp?.is_hidden && isInFilters(comp))
  );
  const filteredTextItemsAll = filteredResultsNotHidden.concat(filteredResultsBlocks);

  const filteredResultsHidden = comps.filter((comp) => comp?.is_hidden && isInFilters(comp));

  const num_hidden = comps.filter((comp) => comp?.is_hidden).length;
  const num_comps_filtered = comps.filter((comp) => isInFilters(comp)).length;
  const num_filtered =
    comps.filter((comp) => isInFilters(comp)).length +
    blocks.filter((block) => {
      return block.comps.filter((comp) => isInFilters(comp)).length > 0;
    }).length;
  const num_filtered_hidden = comps.filter((comp) => isInFilters(comp) && comp?.is_hidden).length;

  const isSuggested = (id) => id === suggestedCompId;

  const isSelected = (id) => {
    if (multiOn) {
      return multiSelectedIds.includes(id);
    } else {
      return id == selectedId;
    }
  };

  const createNewBlock = async () => {
    blockMoveActions.current = true;
    const { url, body } = API.doc.post.newBlock;
    const { data } = await http.post(url, body({ doc_ID: doc_ID, groupId }));
    updateFrames([data.group_id]);
    setBlockToRename(index + "-" + data.blockIndex);
    segment.track({
      event: SegmentEvents.NEW_BLOCK_BUTTON_IN_PROJECT_CLICKED,
    });
    blockMoveActions.current = false;
  };

  const revertToDraft = async () => {
    await API.project.upsertGroup({
      projectId,
      groupId,
      data: { linking_enabled: false },
    });

    groupStateDispatch({
      type: "DISABLE_LINKING_FOR_GROUP",
      groupId,
    });
  };

  const onRevertToDraftClick = async () => {
    if (devModeEnabled) {
      setShowRevertToDraftConfirmationModal(true);
      return;
    }

    setReverting(true);
    await revertToDraft();
    setReverting(false);
  };

  const nonBlocksCollapsedState = useRef(group.nonblocks_collapsed);
  const setNonBlocksCollapsedOnBackend = useDebouncedCallback(
    () => {
      const { url, body } = API.doc.post.setNonblocksCollapsed;
      return http.post(
        url,
        body({
          projectId: doc_ID,
          groupId,
          isCollapsed: nonBlocksCollapsedState.current,
        })
      );
    },
    500,
    [doc_ID, groupId]
  );
  const onOtherTextItemsClick = async (isCollapsed) => {
    groupStateDispatch({
      type: "UPDATE_GROUP_NON_BLOCKS_COLLAPSED",
      groupId,
      isCollapsed,
    });

    nonBlocksCollapsedState.current = isCollapsed;
    setNonBlocksCollapsedOnBackend();
  };

  const openFramePreviewModal = () => {
    segment.track({
      event: "Frame Preview Opened",
      properties: {
        location: "canvas",
      },
    });
    setFramePreviewOpen(true);
  };
  const hideFramePreviewModal = () => {
    setFramePreviewOpen(false);
  };

  const editFrameApiId = async (newApiId) => {
    try {
      const { url, body } = API.doc.post.editFrameApiId;
      const { data } = await http.post(
        url(doc_ID),
        body({
          newApiId: newApiId,
          groupId,
          from: "web_app",
        })
      );
      // figma_artboard_ID is integrations.figma.frame_id
      updateFrames([data.group_id]);
      handleHistoryUpdate(true);
      return "success";
    } catch (error) {
      console.log("Error in editing frame apiID: ", error.message);
      return "Unable to save edit";
    }
  };

  const selectVariant = (variant) => {
    segment.track({
      event: "Frame Variant Selected",
      properties: {
        docName: doc_name,
        docID: doc_ID,
        variant: {
          id: variant.id,
          name: variant.name,
        },
      },
    });

    handleSelectFrameVariant(variant, groupId);
  };

  const [newVariantId, setNewVariantId] = useState(null);

  const onAddNewVariants = async (frameId, variants, folderId, applyToAllGroups, status) => {
    const addedVariants = await handleAddNewVariants(frameId, variants, folderId, applyToAllGroups, status);

    const [first] = addedVariants;
    setNewVariantId(first.id);
  };

  /**
   * This is extremely hacky, but the problem is that `frameVariants` gets updated via setState
   * in the handleAddNewVariants call. We can't set the index right after that call, because the
   * state hasn't updated yet. So we set newVariantId, wait till there's a match, then set the
   * active index based on that.
   */
  useEffect(() => {
    if (frameVariants && newVariantId) {
      const newVariantIndex = frameVariants.findIndex((variant) => variant.id === newVariantId);
      if (newVariantIndex !== -1) {
        const newVariant = frameVariants[newVariantIndex];
        handleSelectActiveVariant(newVariant.id, groupIndex);
        setNewVariantId(null);
      }
    }
  }, [frameVariants, newVariantId]);

  const handleSelectActiveVariant = (variantId) => {
    handleSelectFrameVariant(
      frameVariants?.find((variant) => variant.id === variantId),
      groupId
    );
  };

  const onDeleteVariant = async (variantId) => {
    handleDeleteFrameVariant(groupId, variantId);
  };

  const groupContainsNoVariantText = useMemo(() => {
    let groupContainsNoVariantText = false;

    if (activeVariant && activeVariant.id !== "__base__") {
      let allComps = [...comps];
      blocks.forEach((block) => (allComps = allComps.concat(block.comps)));
      groupContainsNoVariantText = !allComps.some((comp) =>
        comp.variants.some((variant) => variant.variantId === activeVariant.id)
      );
    }

    return groupContainsNoVariantText;
  }, [activeVariant]);

  useEffect(() => {
    setGroupMeta((groupMeta) => ({
      ...groupMeta,
      [groupId]: {
        ...groupMeta[groupId],
        activeVariant,
      },
    }));
  }, [activeVariant]);

  const shouldShowSelectAll = useMemo(() => {
    return ((blocks && blocks.length > 0) || (comps && comps.length > 0)) && filtersOff && !showSetupFromAnotherGroup;
  }, [blocks, comps, filtersOff, showSetupFromAnotherGroup]);

  const onSelectAllClicked = (e) => {
    selectAllCompsInGroup(groupId, activeVariant, hiddenOpen);
  };

  const onHeaderNameClicked = (e) => {
    if (shouldShowSelectAll) {
      onSelectAllClicked(e);
    }
  };

  const isSetupPreviewGroup = useMemo(
    () => isSetupPreview && showSetupFromAnotherGroup && setupSuggestionsExist,
    [isSetupPreview, showSetupFromAnotherGroup, setupSuggestionsExist]
  );

  const showPreviewStateToggle =
    // group is linked
    isLinked &&
    // setup suggestions not enabled
    !showSetupFromAnotherGroup &&
    // no active variant
    (!activeVariant || activeVariant.id === "__base__");

  const previewsExpired = useMemo(() => {
    const now = new Date();
    const lastUpdate = new Date(previewsLastUpdatedAt);

    // based off of Figma link expiration of 30 days
    // (minus one day to ensure we don't try to use expired previews)
    const expirationThreshold = 1000 * 60 * 60 * 24 * 29;

    return now.getTime() - lastUpdate.getTime() > expirationThreshold;
  }, [previewsLastUpdatedAt]);

  const previewStateToggleDisabled =
    showPreviewStateToggle && (!Boolean(group.integrations?.figma?.previews) || previewsExpired);

  const previewStateToggleLoading = showPreviewStateToggle && pollingForPreviewUpdates;

  const showPreviewState =
    // toggle flipped to the design state
    groupPreviewState === "DESIGN" &&
    // group is linked
    isLinked &&
    // setup suggestions not enabled
    !showSetupFromAnotherGroup &&
    // no active variant
    (!activeVariant || activeVariant.id === "__base__");

  useEffect(
    // if the group is in a state where the preview toggle is not visible or is disabled,
    // but the group is still in a "design preview" state, revert it back to the text state
    // so that it can remain usable while the previews load in
    function enforceCorrectPreviewState() {
      const isValidState = showPreviewStateToggle && !previewStateToggleDisabled;
      const isTextState = groupPreviewState === "TEXT";
      if (isValidState || isTextState) {
        return;
      }

      setGroupPreviewStateById((m) => new Map(m).set(groupId, "TEXT"));
    },
    [showPreviewStateToggle, previewStateToggleDisabled, groupPreviewState]
  );

  // preload the design preview image for snappy UX when switching to the
  // design state for the first time
  useEffect(
    function preloadDesignImages() {
      if (!group.integrations.figma?.previews?.fullsize) {
        return;
      }

      new Image().src = group.integrations.figma.previews.fullsize;
    },
    [group.integrations.figma?.previews]
  );

  const GroupHeader = (
    <div className={classnames(style.groupHeader)}>
      <div className={style.groupName} onClick={onHeaderNameClicked}>
        <div
          className={classnames({
            [style.groupAndPage]: true,
            [style.groupAndPageClickable]: shouldShowSelectAll,
          })}
        >
          {showSetupFromAnotherGroup ? (
            groupName
          ) : (
            <EditableName
              isEditEnabled={isEditEnabled && !resyncLoading}
              value={groupName}
              onSave={onGroupNameSave}
              className={classNames({
                [style.groupNameText]: true,
                [style.previewToggleVisible]: showPreviewStateToggle,
              })}
            />
          )}
          <FigmaComponentInfo frameName={figmaFrameName} />
        </div>
        {displayApiIds && apiID && (
          <ApiID
            id={apiID}
            canEdit={manualApiIdEditsAllowed}
            onEdit={editFrameApiId}
            isDuplicateId={(text) => allFrameApiIDs.includes(text)}
          />
        )}
        {figmaAccessToken && imgUrl && artboardsLoadingFinished && (
          <>
            <ImageSearchIcon
              className={classnames({
                [style.icon]: true,
                [style.previewButton]: true,
                [style.hasApiID]: apiID,
              })}
              onClick={openFramePreviewModal}
              data-tip
              data-for={`frame-preview-${figmaId}`}
            />
            <ReactTooltip id={`frame-preview-${figmaId}`} place="bottom" effect="solid">
              Open frame preview
            </ReactTooltip>
          </>
        )}
      </div>
      <div className={style.groupActions}>
        {shouldShowSelectAll && (
          <div className={style.copyStructure} onClick={onSelectAllClicked}>
            <SelectAllIcon className={style.icon} /> Select All
          </div>
        )}
        {isEditEnabled &&
          filtersOff &&
          activeTabIndex === 0 &&
          !showSetupFromAnotherGroup &&
          !resyncLoading &&
          !isDesignPreviewState && (
            <div className={style.createNewBlock} onClick={() => createNewBlock()}>
              <AddCircleIcon className={style.icon} /> New Block
            </div>
          )}
        {isEditEnabled &&
          // Only show on groups that aren't connected to figma
          !figmaId &&
          filtersOff &&
          activeTabIndex === 0 && (
            <div className={style.addTextItem} onClick={() => setCreateTextItemPanelStatus({ show: true, groupId })}>
              <EditIcon className={style.icon} /> Add Text Item
            </div>
          )}
      </div>
      {pinned && <PushPinIcon className={classnames([style.pin, style.headerIcon])} />}
      {showPreviewStateToggle && (
        <div className={style.toggleWrapper}>
          <TextPreviewToggle
            value={groupPreviewState}
            onToggle={onToggleGroupPreviewState}
            loading={previewStateToggleLoading}
            disabled={previewStateToggleDisabled}
          />
        </div>
      )}
    </div>
  );

  if (
    num_filtered <= 0 &&
    // Don't hide groups that have no comps
    comps.length
  ) {
    return <span />;
  }

  return (
    <GroupContext.Provider value={{ groupId, isLinked }}>
      <div
        data-testid={`comp-group-${figmaId}`}
        className={classnames(className, {
          [style.compgroup]: true,
          [style.lastCompGroup]: isLast,
          [style.compGroupIsPreview]: isSetupPreviewGroup,
        })}
        ref={groupContainerRef}
      >
        {isSetupPreviewGroup && (
          <div
            className={classnames(style.previewSetupSuggestionsTitle, {
              [style.previewSetupSuggestionsTitleWithVariants]: frameVariants && frameVariants.length > 0,
            })}
          >
            <AutoAwesomeIcon className={style.previewSetupSuggestionsTitleIcon} />
            Previewing setup suggestions
          </div>
        )}
        {framePreviewOpen && imgUrl && figmaAccessToken && (
          <EnlargeImageModal
            imageUrl={imgUrl}
            artboardName={name}
            onHide={hideFramePreviewModal}
            figmaFrameURL={"https://www.figma.com/file/" + figmaFileId + "/?node-id=" + encodeURI(figmaId)}
            docName={doc_name}
            canHighlight={false}
            appliedVariant={appliedVariant}
          />
        )}
        {showAddVariantModal && (
          <VariantModal
            doc_ID={doc_ID}
            frame_name={name}
            frame_ID={groupId}
            docVariants={variants?.docVariants || []}
            frameVariants={frameVariants ? frameVariants : []}
            workspaceVariants={workspaceVariants}
            onHide={() => setShowAddVariantModal(false)}
            handleAddNewVariants={onAddNewVariants}
            isSampleProject={isSampleProject}
          />
        )}
        {showSelectVariantModal && (
          <SelectVariantModal
            frame_name={name}
            variants={
              variantTabs.length > 2 && Array.isArray(frameVariants)
                ? frameVariants.filter((variant) => variant.id !== variantTabs[2].id)
                : frameVariants || []
            }
            isProjectModal={false}
            onHide={() => setShowSelectVariantModal(false)}
            handleViewVariant={selectVariant}
          />
        )}
        <div
          className={classnames({
            [style.variantHeader]: !isSetupPreviewGroup,
            [style.variantHeaderOfPreview]: isSetupPreviewGroup,
          })}
          {...UNSELECT_ALL_PROPS}
        >
          <div
            className={classnames({
              [style.variantTabs]: true,
              [style.variantTabsSetupSuggestionsPreview]: isSetupPreviewGroup,
            })}
          >
            {variantTabs.map((tab, index) => (
              <div
                key={index}
                className={classnames({
                  [style.variantTab]: true,
                  [style.activeTab]: index === activeTabIndex,
                  [style.hoverTab]: hoveredVariantId === tab.id,
                  [style.varaintTabDisabled]: showSetupFromAnotherGroup,
                  [style.baseTab]: tab.id === "__base__",
                })}
                onClick={() => {
                  if (showSetupFromAnotherGroup) return;
                  if (tab.id !== "__collapsed__") {
                    checkDetailPanelChanges(() => {
                      selectVariant(tab);
                    });
                  } else setShowSelectVariantModal(true);
                }}
              >
                {((index === 0 && !appliedVariantId) || appliedVariantId === tab.id) && (
                  <StarIcon className={style.tabIcon} />
                )}
                <div className={style.variantText} data-testid={`variant-${tab.name}`}>
                  {tab.name}
                </div>
                {!["__collapsed__", "__base__"].includes(tab.id) && index === activeTabIndex && isEditEnabled && (
                  <div className={style.variantToggle}>
                    <Dropdown alignRight>
                      <Dropdown.Toggle>
                        <MoreVertIcon className={style.icon} onClick={() => setShowDropdown(true)} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => onDeleteVariant(activeVariant.id)}>Delete Variant</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
                {index !== activeTabIndex && index !== activeTabIndex - 1 && index !== variantTabs.length - 1 && (
                  <div className={style.tabDivider} />
                )}
              </div>
            ))}
          </div>
          {isEditEnabled && !showSetupFromAnotherGroup && (
            <div className={style.addVariantBtn} onClick={() => setShowAddVariantModal(true)}>
              <CallSplitIcon className={style.icon} />
              <span>Add Variant</span>
            </div>
          )}
          {canBeReverted && (
            <div className={style.revertToDraft} onClick={onRevertToDraftClick} disabled={reverting}>
              <UndoIcon className={classNames(style.icon, style.revertToDraftIcon)} />
              {reverting ? <span className={style.revertingText}>Reverting</span> : <span>Revert to Draft</span>}
            </div>
          )}
        </div>
        <div
          className={classnames({
            [style.variantBodyIsPreview]: isSetupPreviewGroup,
          })}
        >
          <div
            className={classnames({
              [style.variantBody]: true,
              [style.variantGroup]: variantTabs.length > 0,
              [style.variantBodyPreview]: isSetupPreviewGroup,
            })}
          >
            <Element name={groupId} id={groupId}>
              {GroupHeader}
              {!group.integrations.figma?.frame_name_synced_with_group_name && (
                <FigmaFrameName frameName={group.integrations.figma?.frame_name} />
              )}
            </Element>
            {
              // Don't show while searching/filtering
              filtersOff && isEditEnabled && <SuggestionsBanner groupId={groupId} />
            }
            {showPreviewState ? (
              <GroupImagePreview
                group={group}
                groupIndex={index}
                textItems={filteredTextItemsAll}
                suggestedCompId={suggestedCompId}
                scrollToId={scrollToId}
                selectedId={selectedId || multiSelectedIds[0]}
                searchFiltersOn={!filtersOff}
                isSelected={isSelected}
                selectComp={selectComp}
                previewsLastUpdatedAt={previewsLastUpdatedAt}
                loading={pollingForPreviewUpdates}
                appliedVariant={appliedVariant}
              />
            ) : (
              <>
                {blocks && blocks.length > 0 && (
                  <Blocks
                    isSelected={isSelected}
                    selectComp={selectComp}
                    blocks={blocks}
                    frameIndex={index}
                    figmaId={figmaId}
                    doc_ID={doc_ID}
                    updateDoc={updateFrames}
                    blockToRename={blockToRename}
                    setBlockToRename={setBlockToRename}
                    isInFilters={isInFilters}
                    filtersOff={filtersOff}
                    num_comps_filtered={num_comps_filtered}
                    setPanelState={setPanelState}
                    setCommentState={setCommentState}
                    displayApiIds={displayApiIds}
                    handleHistoryUpdate={handleHistoryUpdate}
                    frameVariant={activeVariant}
                    groupId={groupId}
                    suggestedCompId={suggestedCompId}
                    showSetupFromAnotherGroup={showSetupFromAnotherGroup}
                    resyncLoading={resyncLoading}
                    hoveredVariantId={hoveredVariantId}
                    setHoveredVariantId={setHoveredVariantId}
                    handleSelectActiveVariant={handleSelectActiveVariant}
                    frameVariants={frameVariants}
                  />
                )}
                {blocks && blocks.length > 0 && filtersOff && (
                  <div className={style.compsHeader} onClick={() => onOtherTextItemsClick(!nonblocks_collapsed)}>
                    <div className={style.numTexts}>
                      <NotesIcon className={style.icon} />
                      {isEditEnabled ? numCompsArray[index] + " other " : " Other "}
                      text item{numCompsArray[index] !== 1 && <span>s</span>}
                    </div>
                    {nonblocks_collapsed ? (
                      <div className={style.toggle}>
                        <UnfoldMoreIcon className={style.icon} />
                      </div>
                    ) : (
                      <div className={style.toggle}>
                        <UnfoldLessIcon className={style.icon} />
                      </div>
                    )}
                  </div>
                )}
                {!filtersOff && num_comps_filtered > 0 && blocks && blocks.length > 0 && (
                  <div className={style.compsHeader}>
                    <div className={style.numTexts}>
                      <NotesIcon className={style.icon} />
                      Text items
                    </div>
                  </div>
                )}
                {(!nonblocks_collapsed || !filtersOff || !blocks || blocks.length == 0) && (
                  <div
                    className={classnames({
                      [style.nonBlocks]: !blocks || blocks.length == 0,
                    })}
                  >
                    <Droppable
                      droppableId={"compsection" + index}
                      type={"COMP-" + index}
                      dragDisabled={!isEditEnabled || resyncLoading}
                      renderClone={(provided, snapshot, rubric) => {
                        const textItem = filteredResultsNotHidden[rubric.source.index];

                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Comp
                              isWsCompInstance={Boolean(textItem?.ws_comp)}
                              component={{
                                ...textItem,
                                blockIndex: null,
                                frameIndex: index,
                              }}
                              selectComp={selectComp}
                              is_ws_comp={textItem?.ws_comp !== null}
                              ws_comp_id={textItem?.ws_comp}
                              is_selected={isSelected(textItem?._id)}
                              is_suggested={isSuggested(textItem?._id)}
                              key={textItem?._id}
                              frameIndex={index}
                              setPanelState={setPanelState}
                              setCommentState={setCommentState}
                              apiID={textItem?.apiID}
                              frameVariant={activeVariant ?? { id: "__base__" }}
                              frameVariants={frameVariants}
                              hoveredVariantId={hoveredVariantId}
                              setHoveredVariantId={setHoveredVariantId}
                              handleSelectActiveVariant={handleSelectActiveVariant}
                              groupId={groupId}
                              compType={textItem?.ws_comp?.type}
                            />
                          </div>
                        );
                      }}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {filteredResultsNotHidden.length > 0 || !filtersOff ? (
                            <Masonry
                              breakpointCols={masonryBreakpoints}
                              className={style.masonryGrid}
                              columnClassName={style.masonryGridCol}
                            >
                              {filteredResultsNotHidden.map(function (component, compIndex) {
                                return (
                                  <Draggable
                                    draggableId={component._id}
                                    isDragDisabled={
                                      !isEditEnabled ||
                                      !filtersOff ||
                                      !blocks ||
                                      blocks.length == 0 ||
                                      showSetupFromAnotherGroup ||
                                      resyncLoading
                                    }
                                    key={component._id}
                                    index={compIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={classnames({
                                          [style.stopTransition]: !snapshot.isDragging,
                                        })}
                                      >
                                        <Comp
                                          isWsCompInstance={Boolean(component.ws_comp)}
                                          component={{
                                            ...component,
                                            blockIndex: null,
                                            frameIndex: index,
                                          }}
                                          selectComp={selectComp}
                                          is_ws_comp={Boolean(component.ws_comp)}
                                          ws_comp_id={component.ws_comp?._id}
                                          is_selected={isSelected(component._id)}
                                          is_suggested={isSuggested(component._id)}
                                          key={component._id}
                                          frameIndex={index}
                                          setPanelState={setPanelState}
                                          setCommentState={setCommentState}
                                          apiID={component.apiID}
                                          displayApiIds={displayApiIds}
                                          frameVariant={activeVariant ?? { id: "__base__" }}
                                          frameVariants={frameVariants}
                                          hoveredVariantId={hoveredVariantId}
                                          setHoveredVariantId={setHoveredVariantId}
                                          handleSelectActiveVariant={handleSelectActiveVariant}
                                          groupId={groupId}
                                          compType={component.ws_comp?.type}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {showAddTextPlaceholder && (
                                <div className={style.addTextItemPlaceholder}>New Text Item</div>
                              )}
                            </Masonry>
                          ) : (
                            <div className={style.emptyCompsSection}>
                              Currently no text
                              {blocks && blocks.length > 0 && <span> not in blocks</span>} in frame.
                              {provided.placeholder}
                            </div>
                          )}
                          <div className={style.noShow}>
                            {/* Wrapping div is because react-beautiful-dnd requires placeholder */}
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Droppable>
                    {groupContainsNoVariantText && isEditEnabled && (
                      <div className={style.variantHelpWrapper}>
                        <div className={style.variantHelp}>
                          <img className={style.emoji} src={pointEmoji} />
                          Click into any of the base text items to add text to this variant.
                          <a href="https://www.dittowords.com/docs/variants" target="_blank">
                            Help with variants
                          </a>
                        </div>
                      </div>
                    )}
                    {num_hidden !== 0 && num_filtered_hidden > 0 && activeTabIndex === 0 && (
                      <div className={style.hidden}>
                        {(isEditEnabled || isProjectLocked) && (
                          <div className={style.hiddenToggle} onClick={() => onToggleHidden(groupId)}>
                            <div className={style.hiddenLabel}>
                              <VisibilityOffIcon className={style.icon} />
                              <span className={style.label}>Hidden</span>
                              {!hiddenOpen && <KeyboardArrowDownIcon className={style.toggle} />}
                              {hiddenOpen && <KeyboardArrowUpIcon className={style.toggle} />}
                            </div>
                          </div>
                        )}
                        {hiddenOpen && (
                          <Masonry
                            breakpointCols={masonryBreakpoints}
                            className={classnames(style.masonryGrid, style.hiddenComps)}
                            columnClassName={style.masonryGridCol}
                          >
                            {filteredResultsHidden.map(function (component, compIndex) {
                              return (
                                <div key={component._id}>
                                  <Comp
                                    isWsCompInstance={Boolean(component.ws_comp)}
                                    component={{
                                      ...component,
                                      blockIndex: null,
                                      frameIndex: index,
                                    }}
                                    listView={false}
                                    is_ws_comp={Boolean(component.ws_comp)}
                                    ws_comp_id={component.ws_comp?._id}
                                    selectComp={selectComp}
                                    is_selected={isSelected(component._id)}
                                    key={component._id}
                                    setPanelState={setPanelState}
                                    setCommentState={setCommentState}
                                    displayApiIds={displayApiIds}
                                    frameVariant={activeVariant ?? { id: "__base__" }}
                                    frameVariants={frameVariants}
                                    hoveredVariantId={hoveredVariantId}
                                    setHoveredVariantId={setHoveredVariantId}
                                    handleSelectActiveVariant={handleSelectActiveVariant}
                                    groupId={groupId}
                                    compType={component.ws_comp?.type}
                                  />
                                </div>
                              );
                            })}
                          </Masonry>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showRevertToDraftConfirmationModal && (
        <ConfirmationModal
          title="Are you sure you want to revert this frame to draft?"
          body={
            <>
              <p>
                This project has developer mode turned on, which means that any groups with linking enabled may be used
                in development.
              </p>
              <p>
                Reverting this linkable group back to a draft group means it will no longer be accessible via our
                developer integrations.
              </p>
            </>
          }
          actionPrimary="Yes, revert to draft"
          actionSecondary="No, keep as linkable"
          onPrimary={() => revertToDraft()}
          onSecondary={() => setShowRevertToDraftConfirmationModal(false)}
        />
      )}
    </GroupContext.Provider>
  );
};

export default CompGroup;
