import React, { createContext, useEffect, useMemo, useState } from "react";
import * as httpWsComp from "../../../http/ws_comp_typed";

interface CsvImportContextType {
  setData: React.Dispatch<React.SetStateAction<string[][]>>;
  columns: string[];
  dataWithRowNumbers: (string | number)[][];
  header: (string | number)[];
  body: (string | number)[][];
  columnHighlights: {
    [columnIdx: number]: string;
  };
  selectedTextColumnIdx: number | null;
  setSelectedTextColumnIdx: React.Dispatch<React.SetStateAction<number | null>>;
  selectedNameColumnIdxs: number[] | null;
  setSelectedNameColumnIdxs: React.Dispatch<React.SetStateAction<number[] | null>>;
  selectedNotesColumnIdx: number | null;
  setSelectedNotesColumnIdx: React.Dispatch<React.SetStateAction<number | null>>;
  selectedTagsColumnIdx: number | null;
  setSelectedTagsColumnIdx: React.Dispatch<React.SetStateAction<number | null>>;
  selectedStatusColumnIdx: number | null;
  setSelectedStatusColumnIdx: React.Dispatch<React.SetStateAction<number | null>>;
  selectedComponentIdColumnIdx: number | null;
  setSelectedComponentIdColumnIdx: React.Dispatch<React.SetStateAction<number | null>>;
  selectedTextColumnWarning: string | null;
  selectedStatusColumnHasError: boolean;
  selectedComponentIdColumnError: null | string;
  selectedNameColumnsWarning: string | null;
  generateImports: (folderId?: string | null) => Promise<any>;
  resetSelected: () => void;
  setSelectValueColors: () => void;
  validateTextColumn: (idx: number) => void;
}

const CsvImportContext = createContext<CsvImportContextType>({} as CsvImportContextType);

const HIGHLIGHT_COLORS = [
  "#FBE7D5",
  "#E8F4F0",
  "#EAEEF6",
  "#FAEDCC",
  "#F7EEF2",
  "#E6EEF5",
  "#EDE8F2",
  "#ECEBEA",
  "#EFE5E1",
  "#F1F0F0",
];

const CsvImportProvider: React.FC<{ data: string[][]; children: React.ReactElement }> = (props) => {
  const [data, setData] = useState<string[][]>(props.data);
  const [columnHighlights, setColumnHighlights] = useState<{
    [columnIdx: number]: string;
  }>({});

  const dataWithRowNumbers = useMemo(() => {
    const dataWithRowNumbers = data.map((row, index) => {
      // Add 1 to index to make it 1-based
      return [index + 1, ...row];
    });

    return dataWithRowNumbers;
  }, [data]);

  const header = dataWithRowNumbers[0] || [];
  const columns = data[0] || [];
  const body = dataWithRowNumbers.slice(1);

  const [selectedTextColumnIdx, setSelectedTextColumnIdx] = useState<number | null>(null);

  const [selectedNameColumnIdxs, setSelectedNameColumnIdxs] = useState<number[] | null>(null);

  const [selectedNotesColumnIdx, setSelectedNotesColumnIdx] = useState<number | null>(null);
  const [selectedTagsColumnIdx, setSelectedTagsColumnIdx] = useState<number | null>(null);
  const [selectedStatusColumnIdx, setSelectedStatusColumnIdx] = useState<number | null>(null);
  const [selectedComponentIdColumnIdx, setSelectedComponentIdColumnIdx] = useState<number | null>(null);

  useEffect(
    function updateColumnHighlights() {
      const newColumnHighlights: { [columnIdx: number]: string } = {};

      if (selectedTextColumnIdx) {
        newColumnHighlights[selectedTextColumnIdx] = HIGHLIGHT_COLORS[selectedTextColumnIdx % HIGHLIGHT_COLORS.length];
      }

      if (selectedNameColumnIdxs) {
        selectedNameColumnIdxs.forEach((columnIdx) => {
          newColumnHighlights[columnIdx] = HIGHLIGHT_COLORS[columnIdx % HIGHLIGHT_COLORS.length];
        });
      }

      if (selectedNotesColumnIdx) {
        newColumnHighlights[selectedNotesColumnIdx] =
          HIGHLIGHT_COLORS[selectedNotesColumnIdx % HIGHLIGHT_COLORS.length];
      }

      if (selectedTagsColumnIdx) {
        newColumnHighlights[selectedTagsColumnIdx] = HIGHLIGHT_COLORS[selectedTagsColumnIdx % HIGHLIGHT_COLORS.length];
      }

      if (selectedStatusColumnIdx) {
        newColumnHighlights[selectedStatusColumnIdx] =
          HIGHLIGHT_COLORS[selectedStatusColumnIdx % HIGHLIGHT_COLORS.length];
      }

      if (selectedComponentIdColumnIdx) {
        newColumnHighlights[selectedComponentIdColumnIdx] =
          HIGHLIGHT_COLORS[selectedComponentIdColumnIdx % HIGHLIGHT_COLORS.length];
      }

      setColumnHighlights(newColumnHighlights);
    },
    [
      selectedTextColumnIdx,
      selectedNameColumnIdxs,
      selectedNotesColumnIdx,
      selectedTagsColumnIdx,
      selectedStatusColumnIdx,
      selectedComponentIdColumnIdx,
    ]
  );

  /**
   * react-select doesn't support classname assigning for subcomponents
   * https://react-select.com/styles#the-classnames-prop
   * This is mainly relevant for the multiValue classes, which are the values selected value boxes inside the name selector
   * As a result, this useEffect manually assigns background colors. Once we upgrade we should be able to remove this code.
   */
  function setSelectValueColors() {
    // Text selector
    if (selectedTextColumnIdx) {
      // Need the || because on initial render, react-select doesn't add an empty <span> which is an issue when a user presses back from the preview page
      const textSelector =
        document.getElementById("select-text")?.children[1] || document.getElementById("select-text")?.children[0];
      textSelector?.setAttribute("style", `background-color: ${columnHighlights[selectedTextColumnIdx]}`);
    }

    // Attributes
    // Need the || because on initial render, react-select doesn't add an empty <span> which is an issue when a user presses back from the preview page
    const notesSelector =
      document.getElementById("select-notes")?.children[1] || document.getElementById("select-notes")?.children[0];

    if (selectedNotesColumnIdx) {
      notesSelector?.setAttribute("style", `background-color: ${columnHighlights[selectedNotesColumnIdx]}`);
    } else {
      notesSelector?.setAttribute("style", `background-color: none`);
    }

    // Need the || because on initial render, react-select doesn't add an empty <span> which is an issue when a user presses back from the preview page
    const tagsSelector =
      document.getElementById("select-tags")?.children[1] || document.getElementById("select-tags")?.children[0];

    if (selectedTagsColumnIdx) {
      tagsSelector?.setAttribute("style", `background-color: ${columnHighlights[selectedTagsColumnIdx]}`);
    } else {
      tagsSelector?.setAttribute("style", `background-color: none`);
    }

    // Need the || because on initial render, react-select doesn't add an empty <span> which is an issue when a user presses back from the preview page
    const statusSelector =
      document.getElementById("select-status")?.children[1] || document.getElementById("select-status")?.children[0];

    if (selectedStatusColumnIdx) {
      statusSelector?.setAttribute("style", `background-color: ${columnHighlights[selectedStatusColumnIdx]}`);
    } else {
      statusSelector?.setAttribute("style", `background-color: none`);
    }

    // Need the || because on initial render, react-select doesn't add an empty <span> which is an issue when a user presses back from the preview page
    const componentIdSelector =
      document.getElementById("select-component-id")?.children[1] ||
      document.getElementById("select-component-id")?.children[0];

    if (selectedComponentIdColumnIdx) {
      componentIdSelector?.setAttribute("style", `background-color: ${columnHighlights[selectedComponentIdColumnIdx]}`);
    } else {
      componentIdSelector?.setAttribute("style", `background-color: none`);
    }
  }
  useEffect(setSelectValueColors, [columnHighlights]);

  const [selectedTextColumnWarning, setSelectedTextColumnWarning] = useState<string | null>(null);

  function validateTextColumn(idx: number) {
    const textColumn = body.map((row) => row[idx]);
    const invalidTexts = textColumn.filter((text) => {
      const isEmpty = text === "";
      if (isEmpty) {
        return true;
      }

      // accept
      return false;
    });

    if (invalidTexts.length > 0) {
      console.warn("Invalid texts found: ", invalidTexts);
      setSelectedTextColumnWarning("This column has empty cells that will be skipped.");
    } else {
      setSelectedTextColumnWarning(null);
    }
  }

  const [selectedStatusColumnHasError, setSelectedStatusColumnHasError] = useState<boolean>(false);

  useEffect(
    function validateStatusColumn() {
      if (!selectedStatusColumnIdx) {
        setSelectedStatusColumnHasError(false);
        return;
      }

      const validStatuses = ["", "NONE", "WORK IN PROGRESS", "WIP", "READY FOR REVIEW", "REVIEW", "FINAL"];
      const statusColumn = body.map((row) => row[selectedStatusColumnIdx]);
      const invalidStatuses = statusColumn.filter(
        (status) => !validStatuses.includes((status as string).toUpperCase())
      );

      if (invalidStatuses.length > 0) {
        console.warn("Invalid statuses found: ", invalidStatuses);
        setSelectedStatusColumnHasError(true);
      } else {
        setSelectedStatusColumnHasError(false);
      }
    },
    [selectedStatusColumnIdx]
  );

  const [selectedComponentIdColumnError, setSelectedComponentIdColumnError] = useState<string | null>(null);

  useEffect(
    function validateComponentIdColumn() {
      if (!selectedComponentIdColumnIdx) {
        setSelectedComponentIdColumnError(null);
        return;
      }

      const componentIdColumn = body.map((row) => row[selectedComponentIdColumnIdx]);
      const componentIds = new Set<string>();
      for (const componentId of componentIdColumn) {
        if (typeof componentId !== "string") continue;

        const hasWhitespace = componentId.includes(" ");
        if (hasWhitespace) {
          return setSelectedComponentIdColumnError("Please choose a column without values that have whitespace.");
        }

        const isEmpty = componentId === "";
        if (isEmpty) {
          return setSelectedComponentIdColumnError("Please choose a column without empty values.");
        }

        const alreadySeen = componentIds.has(componentId);
        if (alreadySeen) {
          return setSelectedComponentIdColumnError("Please choose a column without duplicate values.");
        }

        // add to set for future checks
        componentIds.add(componentId);
      }

      setSelectedComponentIdColumnError(null);
    },
    [selectedComponentIdColumnIdx]
  );

  const [selectedNameColumnsWarning, setSelectedNameColumnsWarning] = useState<string | null>(null);

  useEffect(
    function validateNameColumns() {
      if (!selectedNameColumnIdxs || selectedNameColumnIdxs.length === 0) {
        setSelectedNameColumnsWarning(null);
        return;
      }

      const nameColumns = body.map((row) => {
        return selectedNameColumnIdxs
          .map((idx) => row[idx])
          .filter((t) => t !== "")
          .join(" ");
      });

      const invalidNames = nameColumns.filter((name: string) => {
        if (name === "") {
          return true;
        }

        // accept
        return false;
      });

      if (invalidNames.length > 0) {
        console.warn("Invalid names found: ", invalidNames);
        if (selectedNameColumnIdxs.length === 1) {
          setSelectedNameColumnsWarning("This column has empty cells that will be skipped.");
        } else {
          setSelectedNameColumnsWarning("These columns have empty cells that will be skipped.");
        }
      } else {
        setSelectedNameColumnsWarning(null);
      }
    },
    [selectedNameColumnIdxs]
  );

  async function generateImports(folderId?: string | null) {
    if (!selectedTextColumnIdx || !selectedNameColumnIdxs) {
      return;
    }

    const [request] = httpWsComp.importCsvData({
      data: data.slice(1),
      params: {
        // Decrement each one by 1 to account for the row number column
        text: selectedTextColumnIdx - 1,
        name: selectedNameColumnIdxs.map((idx) => idx - 1),
        ...(selectedNotesColumnIdx && { notes: selectedNotesColumnIdx - 1 }),
        ...(selectedTagsColumnIdx && { tags: selectedTagsColumnIdx - 1 }),
        ...(selectedStatusColumnIdx && { status: selectedStatusColumnIdx - 1 }),
        ...(selectedComponentIdColumnIdx && {
          componentId: selectedComponentIdColumnIdx - 1,
        }),
        folderId: folderId ?? null,
      },
    });

    const results = await request;
    return results.data;
  }

  function resetSelected() {
    setSelectedTextColumnIdx(null);
    setSelectedNameColumnIdxs(null);
    setSelectedNotesColumnIdx(null);
    setSelectedTagsColumnIdx(null);
    setSelectedStatusColumnIdx(null);
    setSelectedComponentIdColumnIdx(null);
    setSelectedTextColumnWarning(null);
    setSelectedStatusColumnHasError(false);
    setSelectedComponentIdColumnError(null);
    setSelectedNameColumnsWarning(null);
  }

  const value = {
    setData,
    columns,
    header,
    body,
    columnHighlights,
    dataWithRowNumbers,
    selectedTextColumnIdx,
    setSelectedTextColumnIdx,
    selectedNameColumnIdxs,
    setSelectedNameColumnIdxs,
    selectedNotesColumnIdx,
    setSelectedNotesColumnIdx,
    selectedTagsColumnIdx,
    setSelectedTagsColumnIdx,
    selectedStatusColumnIdx,
    setSelectedStatusColumnIdx,
    selectedComponentIdColumnIdx,
    setSelectedComponentIdColumnIdx,
    selectedTextColumnWarning,
    selectedStatusColumnHasError,
    selectedComponentIdColumnError,
    selectedNameColumnsWarning,
    setSelectValueColors,
    generateImports,
    resetSelected,
    validateTextColumn,
  };

  return <CsvImportContext.Provider value={value}>{props.children}</CsvImportContext.Provider>;
};

export { CsvImportContext, CsvImportProvider };
