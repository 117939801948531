import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import Check from "@mui/icons-material/Check";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";
import React, { ComponentType, useMemo, useState } from "react";
import Checkbox from "../../atoms/Checkbox";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Toggle from "../../atoms/Toggle";
import style from "./index.module.css";

interface IProps<OptionType> {
  options: OptionType[];

  /**
   * For type reasons, this is always an array of OptionType, even if the combobox is not multi-select.
   * If it's a single select, it'll be either an empty array or an array with your selected option.
   */
  selectedOptions: OptionType[];
  onChange: (value: OptionType[]) => void;

  TriggerComponent: ComponentType<{
    selectedOptions: OptionType[];
  }>;
  OptionComponent: ComponentType<{
    option: OptionType;
    query: string;
    selected: boolean;
  }>;

  inputPlaceholder?: string;
  onRemoveFilter?: () => void;
  removeFilterText?: string;
  showTextInput?: boolean; // defaults to true
  className?: string;
  style?: React.CSSProperties;
  multiple?: boolean;
  asToggle?: boolean;
  forceToggledDisplay?: boolean;
  triggerLeadingIcon?: React.ReactNode;
  closeOnSelect?: boolean;
}

interface BaseOptionType {
  value: string;
  label: string;
}

// Note: TypeScript is dope, and will automatically infer the generic OptionType based on the `options` prop. This way,
// consumers can pass arbitrary objects in for options, and use any information in those object to render the Trigger
// and Option components.
//
// e.g.
// const options = [
//   { value: "1234", label: "User 1", picture: "https://example.com/user1.png" },
//   { value: "5678", label: "User 2", picture: "https://example.com/user2.png" },
// ]
//
// ...
//
// function OptionComponent({option}) {
//   return <div>
//     <img src={props.option.picture} alt={props.option.label} />
//     <Text>{props.option.label}</Text>
//   </div>
// }

/**
 * A combobox component that pops down from a trigger button. Can be used to select one or more options from a list,
 * and render the selected options inside the trigger button. Both the trigger button and the selected options are
 * supplied as components, so they can be customized to fit your needs.
 */
export function DropdownCombobox<OptionType extends BaseOptionType>(props: IProps<OptionType>) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState(props.options);

  const selectedOptionsValuesSet = useMemo(
    () => new Set(props.selectedOptions.map((option) => option.value)),
    [props.selectedOptions]
  );

  const showTextInput = props.showTextInput ?? true;

  const value = props.multiple ? props.selectedOptions : props.selectedOptions[0] ?? null;

  function onSelect(selectedOptions: OptionType[] | OptionType) {
    if (!Array.isArray(selectedOptions)) {
      props.onChange([selectedOptions]);
      setIsOpen(false);
    } else {
      props.onChange(selectedOptions);
    }

    if (props.closeOnSelect) setIsOpen(false);

    setQuery("");
  }

  function onPopoverOpenChange(open: boolean) {
    if (open) {
      // sort selected options to be first when reopening popover
      setOptions(
        [
          ...props.selectedOptions,
          ...props.options.filter((option) => !selectedOptionsValuesSet.has(option.value)),
        ].filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))
      );
    }
    setIsOpen(open);
  }

  return (
    <Popover.Root defaultOpen open={isOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Trigger asChild>
        {props.asToggle ? (
          <Toggle
            leadingIcon={props.triggerLeadingIcon}
            pressed={isOpen}
            forceToggledDisplay={props.forceToggledDisplay}
            onPressedChange={setIsOpen}
            iconSize="xs"
          >
            <props.TriggerComponent selectedOptions={props.selectedOptions} />
          </Toggle>
        ) : (
          <button className={classNames(style.trigger, { [style.open]: isOpen })} onClick={() => setIsOpen((p) => !p)}>
            <props.TriggerComponent selectedOptions={props.selectedOptions} />
            <Icon Icon={isOpen ? <ExpandLess /> : <ExpandMore />} size="xs" color="secondary" />
          </button>
        )}
      </Popover.Trigger>

      <Popover.Content align="start" sideOffset={6} className={style.comboboxWrapper}>
        <Combobox
          immediate
          multiple={props.multiple}
          // @ts-ignore -- our onSelect handler must take care of either an array or a single value.
          onChange={onSelect}
          // @ts-ignore -- our selectedOptions prop is always an array, but Combobox requires either an array or a
          // single value, depending on the value of `multiple`.
          value={value}
          by="value"
        >
          <ComboboxInput
            onChange={(e) => setQuery(e.target.value)}
            placeholder={props.inputPlaceholder || "Search..."}
            className={classNames(style.input, { [style.hidden]: !showTextInput })}
          />
          <ComboboxOptions static={true} modal={false} className={style.options}>
            {options.map((option) => (
              <ComboboxOption key={option.value} value={option} className={style.option}>
                {({ selected }) => (
                  <>
                    {props.multiple && (
                      <Checkbox
                        checked={selected}
                        onChange={() => {}}
                        className={style.checkbox}
                        size="xs"
                        color="invert"
                      />
                    )}

                    {!props.multiple && (
                      <Icon
                        Icon={<Check />}
                        size="xs"
                        className={classNames(style.selectedIcon, {
                          [style.hidden]: !selected,
                        })}
                      />
                    )}
                    <props.OptionComponent option={option} selected={selected} query={query} />
                  </>
                )}
              </ComboboxOption>
            ))}

            {options.length === 0 && (
              <div key="no-results" className={classNames(style.option, style.noResults)}>
                <Text color="tertiary" weight="light" size="small">
                  No results
                </Text>
              </div>
            )}
          </ComboboxOptions>

          {props.onRemoveFilter && (
            <div className={style.removeFilterArea}>
              <button className={style.removeFilterButton} onClick={props.onRemoveFilter}>
                <Text size="small" color="secondary" weight="light">
                  {props.removeFilterText ?? "Remove filter"}
                </Text>
              </button>
            </div>
          )}
        </Combobox>
      </Popover.Content>
    </Popover.Root>
  );
}

export default DropdownCombobox;
