import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { memo, useEffect, useRef } from "react";
import useWebSocket from "react-use-websocket";

function WebsocketSubscriber() {
  const { getTokenSilently } = useAuthenticatedAuth();

  // Used to keep track of the ping interval to keep the websocket alive
  const pingIntervalId = useRef<undefined | number>(undefined);

  const { sendMessage, readyState, lastMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    async function sendSubscribeMsg() {
      const subscribeMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_CONNECTION,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeMsg));
    }

    if (readyState === 1) {
      sendSubscribeMsg();
      // Keep the websocket alive
      if (pingIntervalId.current) {
        clearInterval(pingIntervalId.current);
      }
      pingIntervalId.current = setInterval(
        () => sendMessage(JSON.stringify({ messageType: WEBSOCKET_EVENTS.PING })),
        20000
      ) as unknown as number;
    }

    return () => clearInterval(pingIntervalId.current);
  }, [readyState]);

  return <></>;
}

export default memo(WebsocketSubscriber);
