import isEqual from "lodash.isequal";
import { ActualComponentSchema, RichText } from "../types/TextItem";

interface ITextPatternMatchResult {
  value: string;
  start: number;
  end: number;
}

interface IOptions {
  /**
   * Optional list of patterns that matches should be tested against.
   * If a given match does not match all patterns provided (if any),
   * then it will be skipped.
   */
  shouldMatchPatterns?: RegExp[];
  /**
   * Optional list of patterns that matches should be tested again.
   * If a given match matches any of the patterns provided (if any),
   * then it will be skipped.
   */
  shouldNotMatchPatterns?: RegExp[];
  /**
   * Optional function to transform valid match values before they are returned.
   * @param value the match value
   * @returns the transformed value
   */
  valueTransformer?: (value: string) => string;
}

/**
 * Returns a function to execute a callback for each `pattern` found in `text`.
 * If an array of patterns is provided instead, a callback is executed for each match
 * of the first pattern, and each subsequent pattern is used as a filter - matches
 * will only be returned if they match all subsequent patterns.
 * The callback is passed an object that includes:
 * - `value`: the value matched, with the `valueTransformer` applied (if provided)
 * - `start`: the index of opening start of the value in `text`
 * - `end`: the index of the end of the value in `text`
 */
export function createTextPatternIterator(pattern: RegExp, options: IOptions = {}) {
  return (text: string, callback: (arg: ITextPatternMatchResult) => void) => {
    let matches: RegExpExecArray | null = null;

    while ((matches = pattern.exec(text)) !== null) {
      const [match] = matches;

      // If there's no match at all here, that means there are no
      // matches in the rest of the string, so we can `break` rather
      // than `continue`.
      if (!match) {
        break;
      }

      if (options.shouldMatchPatterns && !options.shouldMatchPatterns.every((e) => e.test(match))) {
        continue;
      }

      if (options.shouldNotMatchPatterns && options.shouldNotMatchPatterns.some((e) => e.test(match))) {
        continue;
      }

      let value = match;
      if (options.valueTransformer) {
        value = options.valueTransformer(value);
      }

      callback({
        value,
        start: matches.index,
        end: matches.index + matches[0].length,
      });
    }
  };
}

const BRACKET_REGEX = /((?:\[)((?:[^\[\]]+))(?:\]))/g;

export const forEachBracketPair = createTextPatternIterator(BRACKET_REGEX, {
  // Don't match bracket pairs that have variables inside them
  shouldNotMatchPatterns: [/\{\{([a-z0-9_]+)\}\}/gi],
});

export const isDiffRichText = (richTextA: RichText | undefined | null, richTextB: RichText | undefined | null) => {
  return !isEqual(richTextA, richTextB);
};

export function getTextItemChanged(textItemA: ActualComponentSchema, textItemB: ActualComponentSchema) {
  if (!(textItemA && textItemB)) return true;

  const notesChanged = textItemA.notes !== textItemB.notes;
  if (notesChanged) {
    return true;
  }

  const assigneeChanged = textItemA.assignee?.toString() !== textItemB.assignee?.toString();
  if (assigneeChanged) {
    return true;
  }

  const statusChanged = textItemA.status !== textItemB.status;
  if (statusChanged) {
    return true;
  }

  const isHiddenChanged = textItemA.is_hidden !== textItemB.is_hidden;
  if (isHiddenChanged) {
    return true;
  }

  const isCharacterLimitChanged = textItemA.characterLimit !== textItemB.characterLimit;
  if (isCharacterLimitChanged) {
    return true;
  }

  // need to check for object equality
  const wsCompChanged = !isEqual(textItemA.ws_comp, textItemB.ws_comp);
  if (wsCompChanged) {
    return true;
  }

  const tagsChanged = textItemA.tags?.join(",") !== textItemB.tags?.join(",");
  if (tagsChanged) {
    return true;
  }

  const apiIDChanged = textItemA.apiID !== textItemB.apiID;
  if (apiIDChanged) {
    return true;
  }

  const baseTextChanged = textItemA.text !== textItemB.text;
  if (baseTextChanged) {
    return true;
  }

  const baseRichTextChanged = isDiffRichText(textItemA.rich_text, textItemB.rich_text);
  if (baseRichTextChanged) {
    return true;
  }

  const pluralCountChanged = textItemA.plurals?.length !== textItemB.plurals?.length;
  if (pluralCountChanged) {
    return true;
  }

  const pluralTextChanged = textItemA.plurals?.some((plural, index) => {
    const previousPlural = textItemB.plurals?.[index];
    return plural.text !== previousPlural?.text || isDiffRichText(plural.rich_text, previousPlural?.rich_text);
  });
  if (pluralTextChanged) {
    return true;
  }

  return false;
}
