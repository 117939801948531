import { IFProjectsApiIdGenerationConfig } from "@shared/types/Workspace";
import React from "react";

const getReplacementLabel = (replacementName: string, replacementValue: string) => {
  if (replacementValue === "") {
    return `${replacementName} removed`;
  }
  return `${replacementName} replaced with '${replacementValue}'`;
};

const templatePartsMap = {
  groupName: "group name",
  blockName: "block name",
  text: "text",
  projectName: "project name",
};

const oxfordCommaJoin = (parts: string[]) => {
  if (parts.length === 1) {
    return parts[0];
  }

  if (parts.length === 2) {
    return parts.join(" and ");
  }

  const lastPart = parts.pop();
  return parts.join(", ") + ", and " + lastPart;
};

const convertTemplateToString = (template: string) => {
  const parts = template
    .replace(/\s/g, "")
    .split(/\{\{(.*?)\}\}/)
    .filter(Boolean);

  return oxfordCommaJoin(parts.map((part) => templatePartsMap[part] ?? part));
};

const apiIdCasingAdjustmentLabelMap = {
  camel: "Camel case",
  pascal: "Pascal case",
  lower: "Lowercase",
  upper: "Uppercase",
};

interface Props {
  config: IFProjectsApiIdGenerationConfig | undefined;
}

export const ProjectsIDRulesList = (props: Props) => {
  const { config } = props;
  if (!config) {
    return <React.Fragment />;
  }

  const { spaceReplacement, acceptedCharsPattern, template, separator, maxLength, casingAdjustment } = config;

  const casingLabel = casingAdjustment && apiIdCasingAdjustmentLabelMap[casingAdjustment];

  return (
    <ul>
      <li>{getReplacementLabel("Spaces", spaceReplacement)}</li>
      <li>Separating template parts with {separator}</li>
      <li>Using {convertTemplateToString(template)} </li>
      <li>Maximum ID length: {maxLength}</li>
      {acceptedCharsPattern && <li>Accepted characters (regex): '{acceptedCharsPattern}'</li>}
      {casingLabel && <li>{casingLabel}</li>}
    </ul>
  );
};
