import EditOutlined from "@mui/icons-material/EditOutlined";
import * as Tooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import ReactTimeago from "react-timeago";
import { getNoSecondsFormatter } from "../../../../shared/utils/timeAgoFormatters";
import EditedText from "../../atoms/EditedText";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  changeTime: string;
  textBefore: string;
  textAfter: string;
}

export function ChangeIndicator(props: IProps) {
  const [pinnedOpen, setPinnedOpen] = useState(false);
  const [expanded, setExpanded] = useState<"unset" | "open" | "closed">("unset");
  const containerRef = useRef<HTMLDivElement>(null);
  const container = containerRef.current;
  const diffContentRef = useRef<HTMLDivElement>(null);
  const diffContent = diffContentRef.current;

  const expandedState = pinnedOpen ? "open" : expanded;

  function handleClick() {
    setPinnedOpen(!pinnedOpen);
  }

  useEffect(
    function addMouseOverListener() {
      function handleMouseEnter() {
        setExpanded("open");
      }

      function handleMouseLeave() {
        setExpanded("closed");
      }

      container?.addEventListener("mouseenter", handleMouseEnter);
      container?.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        container?.removeEventListener("mouseenter", handleMouseEnter);
        container?.removeEventListener("mouseleave", handleMouseLeave);
      };
    },
    [container]
  );

  useEffect(
    function calculateDiffWrapperDimensions() {
      if (diffContent) {
        const { width, height } = diffContent.getBoundingClientRect();
        container?.style.setProperty("--diff-content-width", `${width}px`);
        container?.style.setProperty("--diff-content-height", `${height}px`);
      }
    },
    [diffContent, container]
  );

  return (
    <Tooltip.Provider>
      <Tooltip.Root open defaultOpen>
        <Tooltip.Trigger asChild>
          <div className={style.anchor} />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content align="start" side="top" asChild>
            <div
              className={classNames(style.changeIndicator, props.className)}
              data-state={expandedState}
              ref={containerRef}
              onClick={handleClick}
            >
              <div className={style.iconWrapper}>
                <Icon Icon={<EditOutlined />} color="invert" size="xxs" />
              </div>

              <div className={style.diffWrapper} data-state={expandedState}>
                <div className={style.diffContent} ref={diffContentRef}>
                  <Text inline className={style.diffHeader} size="micro" color="secondary" weight="light">
                    Changed{" "}
                    <ReactTimeago
                      date={props.changeTime}
                      minPeriod={30}
                      formatter={getNoSecondsFormatter("less than a minute ago")}
                    />
                  </Text>
                  <EditedText
                    className={style.diff}
                    size="micro"
                    textBefore={props.textBefore}
                    textAfter={props.textAfter}
                  />
                </div>
              </div>
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default ChangeIndicator;
