import { ITipTapParagraph, ITipTapRichText, ITipTapVariable } from "@shared/types/TextItem";

/**
 * Serializes a variable node into a text representation and extracts its variable ID.
 */
function serializeVariableNode(variable: ITipTapVariable): { text: string; variableId: string } {
  return {
    text: `{{${variable.attrs.text}}}`,
    variableId: variable.attrs.variableId,
  };
}

/**
 * Serializes a paragraph node, combining text and variable nodes into a single string
 * and collecting all variable IDs used within the paragraph.
 */
function serializeParagaphNode(paragraph: ITipTapParagraph): { text: string; variableIds: string[] } {
  let text = "";
  let variableIds = new Set<string>();

  if (!paragraph.content) {
    return {
      text: "",
      variableIds: [],
    };
  }

  for (const node of paragraph.content) {
    if (node.type === "text") {
      text += node.text;
    } else if (node.type === "variable") {
      const serializedVariable = serializeVariableNode(node);
      text += serializedVariable.text;
      variableIds.add(serializedVariable.variableId);
    }
  }
  return {
    text,
    variableIds: Array.from(variableIds),
  };
}

/**
 * Serializes a TipTap rich text structure into a plain text string and collects
 * all variable IDs used throughout the rich text content.
 */
export function serializeTipTapRichText(richText: ITipTapRichText): { text: string; variableIds: string[] } {
  let stringContent: string[] = [];
  const variableIdsUsed = new Set<string>();
  for (const paragraph of richText.content) {
    const serializedParagraph = serializeParagaphNode(paragraph);
    stringContent.push(serializedParagraph.text);
    serializedParagraph.variableIds.forEach((variableId) => variableIdsUsed.add(variableId));
  }
  const text = stringContent.join("\n");

  return {
    text,
    variableIds: Array.from(variableIdsUsed),
  };
}
