import SampleBadge from "@/components/SampleBadge";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import { userHasPermission, userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import classnames from "classnames";
import React, { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Dropdown from "react-bootstrap/Dropdown";
import useLocalStorage from "react-use/lib/useLocalStorage";
import Tooltip from "../../../../../shared/frontend/Tooltip";
import EditableName from "../../../../components/EditableName";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import PopulatedState from "../../../../components/PopulatedState/PopulatedState";
import CommonFolderCard from "../../../../components/card/CommonFolderCard";
import NewFolderCard from "../../../../components/card/newFolderCard";
import SearchAndFilters from "../../../../components/searchAndFilters/searchAndFilters";
import useSegment from "../../../../hooks/useSegment";
import VariantItem from "../VariantItem";
import VariantListPlaceholder from "../VariantListPlaceholder";
import style from "./style.module.css";

const HIDE_VARIANTS_HELP = "hide-variants-help";

const VariantList = ({
  selectedVariantId,
  onVariantClick,
  loadingVariants,
  unfilteredVariants,
  variants,
  variantFolders,
  showFolders,
  foldersAllowed,
  handleFolderClick,
  uniqueApiIDs,
  uniqueVariantNames,
  query,
  setQuery,
  openCreateVariantFolderModal,
  openMoveVariantFolderModal,
  handleQueryChange,
  handleDeleteVariants,
  handleRenameVariant,
  handleRenameVariantApiID,
  handleRenameVariantFolder,
  handleDeleteVariantFolder,
  selectedFolder,
  toggleMultiSelected,
  multiSelectedVariants,
}) => {
  const segment = useSegment();
  const [hideVariantsHelp, setHideVariantsHelp] = useLocalStorage(HIDE_VARIANTS_HELP, false);
  const { user } = useAuthenticatedAuth();

  const [editingFolderName, setEditingFolderName] = useState(false);

  const showCreateFolderButton = userHasPermission("variant_folder:create");
  const canEdit = userHasResourcePermission("variant_folder:edit");

  // we don't want to show the variables "Getting Started" card if the user has
  // any variable folders, with the exception of the sample folder.
  const nonSampleVariantFolderExists = variantFolders.filter((vf) => !vf.isSample).length > 0;

  const createSampleBanner = () => {
    return (
      <div className={style.sampleBanner}>
        <div className={style.sampleBannerTitle}>
          You’re viewing <SampleBadge className={style.sampleBadge}>sample</SampleBadge> Ditto variants.
        </div>
        <div className={style.sampleBannerText}>
          You won’t be able to use any of these variants in your projects, but when you’re ready, you can create your
          own.{" "}
          <a
            className={style.sampleBannerLink}
            href="https://dittowords.com/docs/variants"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about variants
          </a>
        </div>
      </div>
    );
  };

  const createNoSearchResultsScreen = () => {
    if (query && variants.length === 0) {
      return (
        <div className={classnames("col-12", [style.noResultsMessage])}>
          <div>Sorry, we couldn't find any results for your search! Try adjusting your search query.</div>
        </div>
      );
    }
  };

  const createGettingStartedScreen = () => {
    if (!selectedFolder && !nonSampleVariantFolderExists) {
      return (
        <EmptyState
          learnMoreLink="https://www.dittowords.com/docs/variants"
          headline="Your team doesn’t have any variants yet! Create new variants using the button in the top right. ✨"
          title="Getting Started with Variants ⚡️"
          description={
            <div>
              <p>
                Variants in Ditto allow teams to write and preview text variations for frames in their projects. You can
                use variants for localization, state-based text, and explorations.
              </p>
              <p>Here, you can manage your team’s variants and create new variants.</p>
            </div>
          }
          loomEmbed={
            <div>
              <iframe
                src="https://www.loom.com/embed/ca0cdc3ea0344035a776bd84fefd9a28"
                frameBorder="0"
                allowFullScreen
                style={{
                  width: "100%",
                  borderRadius: 8,
                  aspectRatio: "3/2",
                  maxHeight: 300,
                }}
              />
            </div>
          }
        />
      );
    }
  };

  return (
    <div className={style.wrapper}>
      <SearchAndFilters
        query={query}
        setQuery={setQuery}
        onChange={handleQueryChange}
        devID={undefined}
        setDevID={undefined}
        name={"your workspace"}
        loading={false}
        docSearch={false}
        fullWidth={false}
        inheritWidth={true}
        customInputs={<></>}
        normalFiltersHidden={true}
        clearSelectedTags={() => {}}
        selected_tags={[]}
        unselected_tags={null}
      />
      <div className={style.variantList} id="variantContainer">
        <div className={classnames("container", style.container)}>
          {!loadingVariants &&
            !query &&
            unfilteredVariants.filter((v) => !v.isSample).length === 0 &&
            createGettingStartedScreen()}
          {!hideVariantsHelp &&
            !query &&
            unfilteredVariants.filter((v) => !v.isSample).length > 0 &&
            !selectedFolder?.isSample &&
            !loadingVariants && (
              <div className={style.variantsHelpWrapper}>
                <PopulatedState
                  learnMoreLink="https://www.dittowords.com/docs/variants"
                  onClose={() => setHideVariantsHelp(true)}
                  title="What are variants? 💡️"
                  description={
                    <>
                      <p>
                        Variants in Ditto allow teams to write and preview text variations for frames in their projects.
                        You can use variants for localization, state-based text, and explorations.
                      </p>
                      <p>Here, you can manage your team’s variants and create new variants.</p>
                    </>
                  }
                />
              </div>
            )}
          <div className={classnames("row", style.row)}>
            <div className="col-12">
              <DragDropContext>
                {showFolders && !query && (
                  <div className={style.folderSection}>
                    <h2>Folders</h2>
                    <div className={classnames("row", style.allProjects)}>
                      {showCreateFolderButton && (
                        <NewFolderCard
                          responsiveSize="large"
                          disabled={!foldersAllowed}
                          openModal={openCreateVariantFolderModal}
                          learnMoreLink="https://www.dittowords.com/docs/variant-folders"
                          onUpsellClicked={() =>
                            segment.track({
                              event: SegmentEvents.UPSELL_CLICKED,
                              properties: {
                                type: "Folders",
                                location: "Variant Library",
                                workspaceId: user?.workspaceId,
                              },
                            })
                          }
                        />
                      )}
                      {variantFolders.map((folder, index) => (
                        <CommonFolderCard
                          responsiveSize="large"
                          key={index}
                          folder={folder}
                          count={folder.variantCount || 0}
                          content="variant"
                          onClick={() => handleFolderClick(folder?._id)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {showFolders && !selectedFolder?.name && <h2>Variants</h2>}
                {!showFolders && selectedFolder?.name && (
                  <div className={style.variantFolderTitle}>
                    <FolderOpenOutlined className={style.icon} />
                    <EditableName
                      isEditEnabled={canEdit && !selectedFolder.isSample}
                      className={style.folderName}
                      value={selectedFolder.name}
                      onSave={(name) => {
                        handleRenameVariantFolder(name, selectedFolder._id);
                        setEditingFolderName(false);
                      }}
                      editModeEnabled={[editingFolderName, setEditingFolderName]}
                      uniqueNames={variantFolders
                        .map((folder) => folder.name)
                        .filter((name) => name !== selectedFolder.name)}
                    />
                    {canEdit && (
                      <Dropdown>
                        <Dropdown.Toggle>
                          <MoreVert className={style.more_icon} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Tooltip
                            hideOnClick={false}
                            className={style.tooltip}
                            content={<div className={style.body}>Renaming is disabled for sample data.</div>}
                            placement="top"
                            theme="dark"
                            disabled={!selectedFolder.isSample}
                          >
                            <div>
                              <Dropdown.Item
                                disabled={selectedFolder.isSample}
                                onClick={() => setEditingFolderName(true)}
                              >
                                Rename Folder
                              </Dropdown.Item>
                            </div>
                          </Tooltip>
                          <Tooltip
                            hideOnClick={false}
                            className={style.tooltip}
                            content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                            placement="top"
                            theme="dark"
                            disabled={!selectedFolder.isSample}
                          >
                            <div>
                              <Dropdown.Item disabled={selectedFolder.isSample} onClick={handleDeleteVariantFolder}>
                                Delete Folder
                              </Dropdown.Item>
                            </div>
                          </Tooltip>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                )}
                {selectedFolder?.isSample && createSampleBanner()}
                {!showFolders && !selectedFolder && <h2>Variants</h2>}

                {!loadingVariants &&
                  variants?.length > 0 &&
                  variants.map((variant, index) => (
                    <VariantItem
                      onClick={() => onVariantClick(variant._id)}
                      key={index}
                      variant={variant}
                      uniqueApiIDs={uniqueApiIDs}
                      uniqueVariantNames={uniqueVariantNames}
                      canMoveFolders={foldersAllowed}
                      handleRenameVariant={handleRenameVariant}
                      handleDeleteVariant={() => handleDeleteVariants([variant._id])}
                      handleRenameVariantApiID={handleRenameVariantApiID}
                      handleMoveOption={openMoveVariantFolderModal}
                      toggleSelected={() => toggleMultiSelected(variant)}
                      isSingleSelected={variant._id === selectedVariantId}
                      isMultiSelected={Boolean(multiSelectedVariants.find((v) => v._id === variant._id))}
                    />
                  ))}

                {!showFolders && !query && selectedFolder?.name && variants?.length === 0 && (
                  <div className={style.helpText}>
                    <h2>No variants in folder</h2>
                    <p>
                      Storing your variants in{" "}
                      <a href="https://www.dittowords.com/docs/variant-folders" target="_blank">
                        folders
                      </a>{" "}
                      can help keep your variant library organized and ensure you're able to quickly find the text you
                      need.
                    </p>
                    <br />
                    <p>You can add variants to this folder by:</p>
                    <ul>
                      <li>Clicking the "New Variant" button on the top right</li>
                      <li>Selecting variants and clicking the "Move to folder" button</li>
                    </ul>
                  </div>
                )}
              </DragDropContext>
            </div>
            {createNoSearchResultsScreen()}
          </div>
        </div>
        {loadingVariants && <VariantListPlaceholder />}
      </div>
    </div>
  );
};

export default VariantList;
