import EditableName from "@/components/EditableName";
import OverlayToast from "@/components/OverlayToast";
import { useOverlayToast } from "@/components/OverlayToast/useOverlayToast";
import http, { API } from "@/http";
import { useWorkspace } from "@/store/workspaceContext";
import { userHasPermission } from "@shared/frontend/userPermissionContext";
import logger from "@shared/utils/logger";
import classnames from "classnames";
import { Ditto, DittoVariableData, DittoVariableTypeGuards } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface Props {
  workspaceName: string;
  workspacePlan: string;
}

const WorkspaceName = ({ children }) => <span>{children}</span>;

export default function GeneralPanel(props: Props) {
  const { updateWorkspaceInfo } = useWorkspace();
  const canEditAdmin = userHasPermission("users:edit:admin");
  const { showToast, overlayToastProps } = useOverlayToast();

  async function onSaveWorkspaceName(updatedName: string) {
    updateWorkspaceInfo((prev) => ({ ...prev, name: updatedName }));

    const { url, body } = API.workspace.put.name;
    await http
      .put(url, body({ name: updatedName }))
      .then(() => {
        showToast(`Workspace name successfully changed to "${updatedName}"`, 5000);
      })
      .catch((err) => {
        logger.error("Error renaming workspace:", {}, err);
        showToast("Failed to rename workspace", 5000);
      });
  }

  return (
    <Panel titleDittoComponentId="general">
      <div className={style.container}>
        <table className={style.table}>
          <tbody>
            <tr className={style.divider}>
              <td className={style.title}>
                <Ditto componentId="workspace-name" />
              </td>
              <td data-testid="workspace-name" className={style.value}>
                {canEditAdmin ? (
                  <EditableName
                    isEditEnabled={canEditAdmin}
                    onSave={onSaveWorkspaceName}
                    value={props.workspaceName}
                    TextComponent={WorkspaceName}
                  />
                ) : (
                  <WorkspaceName>{props.workspaceName}</WorkspaceName>
                )}
              </td>
            </tr>
            <tr>
              <td className={classnames([style.title, style.workspacePlanTitle])}>
                <Ditto componentId="workspace-plan" />
              </td>
              <td data-testid="workspace-plan" className={style.workspacePlanValue}>
                <div className={classnames([style.value, style.capitalized, style.plan])}>
                  {props.workspacePlan === "free" ? "Starter" : props.workspacePlan}
                </div>
                <span className={style.planSubheading}>
                  <Ditto
                    componentId={`plan-subheading-${props.workspacePlan}`}
                    richText
                    variables={{
                      mailto: (variableData?: DittoVariableData) => {
                        if (variableData && DittoVariableTypeGuards.isHyperlink(variableData)) {
                          return <a href={variableData.url}>{variableData.text}</a>;
                        }
                      },
                    }}
                  ></Ditto>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <OverlayToast {...overlayToastProps} />
    </Panel>
  );
}
