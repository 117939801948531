import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import CategoryDropdownMenu, { useCategoryDropdownMenu } from "../shared/CategoryDropdownMenu";
import { GrayWarning } from "../shared/GrayWarning";
import style from "./style.module.css";

const EditCompNameModal = (props) => {
  const { name, onHide, fetchAllComps, fetchHistory, compId, componentType, postRenameCallback } = props;
  const { workspaceInfo } = useWorkspace();
  const apiIdGenerateOnRename = workspaceInfo.config.components.apiIdGenerateOnRename;

  const [nameInput, setNameInput] = useState("");
  const [compNames, setCompNames] = useState([]);
  const [loadingRename, setLoadingRename] = useState(false);

  const categoryDropdownMenu = useCategoryDropdownMenu({
    name: nameInput,
    setName: setNameInput,
  });

  const nameInputChange = (e) => {
    setNameInput(e.target.value);
  };

  const nameComp = async () => {
    setLoadingRename(true);
    const trimmed = nameInput.trim();
    try {
      const { url, body } = API.ws_comp.post.rename;
      await http.post(
        url(compId),
        body({
          name: trimmed,
          oldName: name,
        })
      );
      // optional property to allow parent component to control how data
      // is refreshed after a rename
      if (postRenameCallback) {
        await postRenameCallback(compId, trimmed);
      } else {
        await fetchAllComps();
      }

      setLoadingRename(false);
      fetchHistory();
      onHide();
    } catch (e) {
      console.log(e);
      setLoadingRename(false);
    }
  };

  const fetchCompNames = async () => {
    try {
      const { url } = API.ws_comp.get.names;
      const response = await http.get(url);
      const { data: names } = response;
      if (!names) {
        throw Error(`Error doing request to ${url} because ${response.statusText}`);
      }
      const just_names = names.map((obj) => obj.name);
      setCompNames(just_names);
    } catch (error) {
      console.log("in editcomponentnamemodal.jsx: ", error.message);
    }
  };

  useEffect(() => {
    fetchCompNames();
  }, []);

  useEffect(() => {
    if (name) {
      setNameInput(name);
    }
  }, [name]);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            {componentType === "template" ? "Template Component name" : "Component name"}
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <input value={nameInput} onChange={nameInputChange} ref={categoryDropdownMenu.inputRef} />
          {nameInput !== name && compNames.includes(nameInput) && (
            <div className={style.error}>
              This name already exists in your component library! Component names must be unique.
            </div>
          )}
          {nameInput[0] === "/" && <div className={style.error}>Component names cannot start with a slash.</div>}
          {nameInput.match(/^\s/) && <div className={style.error}>Component names cannot start with whitespace.</div>}
          {apiIdGenerateOnRename && nameInput !== name && (
            <GrayWarning>
              The Developer ID of this component will also be updated according to{" "}
              <a href="/developers/configure-dev-ids" target="_blank">
                your settings
              </a>
              .
            </GrayWarning>
          )}
          <CategoryDropdownMenu {...categoryDropdownMenu} />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <ButtonPrimary
            text={loadingRename ? "Renaming..." : "Rename"}
            onClick={() => nameComp()}
            disabled={
              compNames.includes(nameInput.trim()) ||
              nameInput.trim().length == 0 ||
              loadingRename ||
              nameInput[0] === "/" ||
              nameInput.match(/^\s/)
            }
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default EditCompNameModal;
