import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import React from "react";
import ButtonPrimary from "../../../button/buttonprimary";
import LockedFolderIcon from "../../../icons/LockedFolderIcon";
import ErrorMessage from "../ErrorMessage";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import style from "./style.module.css";

interface TitleProps {
  folder: { _id: string; name: string; invite_only: boolean } | null;
}
const Title = ({ folder }: TitleProps) => (
  <div className={style.title}>
    <DescriptionOutlinedIcon />
    Draft a new Ditto project{folder ? " in " : ""}
    {folder && (
      <div className={style.folderIconWrapper}>
        {!folder.invite_only ? <FolderIcon className={style.icon} /> : <LockedFolderIcon className={style.icon} />}
        <div>{folder.name}</div>
      </div>
    )}
  </div>
);

interface BodyProps {
  draftName: string;
  importError: string;
  handleDraftNameChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onHide: () => void;
  goToAccountUser: () => void;
  createDraftProject: (e: React.FormEvent) => void;
}
const Body = ({
  draftName,
  handleDraftNameChange,
  importError,
  onHide,
  goToAccountUser,
  createDraftProject,
}: BodyProps) => (
  <div className={style.container}>
    <label>Project Title</label>
    <form onSubmit={createDraftProject}>
      <input
        autoFocus
        placeholder="Insert a name for your project"
        value={draftName}
        onChange={handleDraftNameChange}
      />
    </form>
    {importError && (
      <ErrorMessage
        error={importError}
        onHide={onHide}
        goToAccountUser={goToAccountUser}
        showAdvancedBranchingModal={() => null}
      />
    )}
  </div>
);

interface FooterProps {
  draftName: string;
  goBackToStart: () => void;
  createDraftProject: (e: React.FormEvent) => void;
  importError: string;
  isLoading: boolean;
}
const Footer = ({ draftName, goBackToStart, createDraftProject, importError, isLoading }: FooterProps) => (
  <div className={style.buttonWrapper}>
    <div className={style.backBtn} onClick={goBackToStart}>
      {"<-"} Back
    </div>
    <ButtonPrimary
      text={"Create Project ->"}
      disabled={draftName.length === 0 || Boolean(importError) || isLoading}
      onClick={createDraftProject}
    />
  </div>
);
const NewDraftProject = ({ children }: { children: React.ReactNode }) => <>{children}</>;
NewDraftProject.Title = Title;
NewDraftProject.Body = Body;
NewDraftProject.Footer = Footer;
export default NewDraftProject;
