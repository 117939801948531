import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import FlagIcon from "@mui/icons-material/Flag";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SearchIcon from "@mui/icons-material/Search";
import Dropdown from "react-bootstrap/Dropdown";
import useDebouncedCallback from "../../../util/useDebouncedCallback";
import spinner from "../../assets/small-spinner.gif";
import AssigneeDropdown from "../AssigneeDropdown";
import ComponentFilterDropdown from "../ComponentFilterDropdown";
import DevIDFilterDropdown from "../DevIDFilterDropdown";
import StatusSelect from "../StatusSelect";
import TagsFilterDropdown from "../TagsFilterDropdown";
import { VariantFilterDropdown } from "./VariantFilterDropdown";
import style from "./style.module.css";

/**
 *
 * @param {*} param0
 * @returns
 */
const SearchAndFilters = ({
  query,
  setQuery,
  assignee,
  setAssignee,
  /**
   * Optional callback for the search input that is executed at once when the user
   * starts typing, independent of the specified `debounce` prop.
   */
  onInitialChange,
  /**
   * Callback for the search input that is executed at a debounced interval
   * specified by the `debounce` prop.
   */
  onChange,
  /**
   * Interval at which to debounce the provided `onChange` function. Defaults to 50ms.
   */
  debounce = 50,
  name,
  docSearch,
  loading,
  status,
  chooseStatus,
  clearSelectedTags,
  selectTag,
  fullWidth,
  inheritWidth,
  filteredCompIds,
  multiSelectedIds,
  setMultiSelected,
  customInputs,
  scrollToId,
  searchDisabled,
  normalFiltersHidden,
  devID,
  setDevID,
  variantFilter,
  setVariantFilter,
  componentFilter,
  setComponentFilter,
  variantFilterConfig,
  tagState,
}) => {
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const [showAssigneeFilter, setShowAssigneeFilter] = useState(queryParams.get("assignee") !== null);
  const [showTagsFilter, setShowTagsFilter] = useState(false);
  const [showDevIDFilter, setShowDevIDFilter] = useState(false);
  const [showComponentFilter, setShowComponentFilter] = useState(false);
  const [showVariantFilter, setShowVariantFilter] = useState(false);
  const [tagsOn, setTagsOn] = useState(false);
  const [localQuery, setLocalQuery] = useState("");
  useEffect(() => setLocalQuery(query), [query]);

  const isCurrentlyDebounced = useRef(false);

  const callParentOnChange = useDebouncedCallback((value) => {
    onChange(value);
    isCurrentlyDebounced.current = false;
  }, debounce);

  const onQueryChange = (e) => {
    const value = e.target.value;

    if (!isCurrentlyDebounced.current) {
      isCurrentlyDebounced.current = true;
      if (onInitialChange) {
        onInitialChange(value);
      }
    }

    setLocalQuery(value);
    callParentOnChange(value);
  };

  useEffect(() => {
    // TODO: need to verify that this maintains correct behavior when clicking on change items
    if (scrollToId) {
      setTagsOn(false);
      clearSelectedTags();
    }
  }, [scrollToId]);

  const clearQuery = () => {
    if (onInitialChange) {
      onInitialChange("");
    }
    setQuery("");
    onChange("");
  };

  const selectFiltered = () => {
    setMultiSelected(filteredCompIds);
  };

  //compare 2 arrays to see if they have the same contents
  const sameArrayContents = (a, b) => {
    if (a.length !== b.length) {
      return false;
    }
    const a_sorted = a.sort();
    const b_sorted = b.sort();
    return a_sorted.every((elem, index) => elem === b_sorted[index]);
  };

  const handleStatusChange = (newStatus) => {
    chooseStatus(newStatus);
  };

  return (
    <div
      className={classnames({
        [style.searchAndFilters]: true,
        [style.inheritWidth]: inheritWidth,
        [style.fullWidth]: fullWidth,
      })}
    >
      <div className={style.topRow}>
        <div
          className={classnames(style.searchWrap, "search-bar", {
            [style.textSearchOnly]: !!normalFiltersHidden,
          })}
        >
          <SearchIcon className={style.searchIcon} />
          <input
            data-testid="search-input"
            className={classnames(style.input, "search-input-proj")}
            placeholder={"Search... "}
            aria-label="Search"
            value={localQuery}
            onChange={onQueryChange}
            disabled={searchDisabled}
          />
          {loading && <img className={style.loadingSearch} src={spinner} />}
          {query.length > 0 && <CloseIcon onClick={() => clearQuery()} className={style.clearSearch} />}

          {docSearch &&
            !(
              query === "" &&
              (status === "Any" || !status) &&
              tagState.selected.size === 0 &&
              assignee === null &&
              devID === null
            ) &&
            filteredCompIds.length > 0 && (
              <div
                onClick={() => selectFiltered()}
                className={classnames({
                  [style.selectFiltered]: true,
                  [style.on]: sameArrayContents(multiSelectedIds, filteredCompIds),
                })}
              >
                Select
                {sameArrayContents(multiSelectedIds, filteredCompIds) && "ed"} all {filteredCompIds.length} results
              </div>
            )}
        </div>

        {showStatusFilter && (
          <div className={style.statusFilter}>
            <StatusSelect
              className={style.statusSelect}
              handleStatusChange={handleStatusChange}
              onRemoveFilter={() => {
                setShowStatusFilter(false);
                handleStatusChange("Any");
              }}
              status={status}
              styleAsDropdown={true}
            />
          </div>
        )}

        {showAssigneeFilter && (
          <AssigneeDropdown
            className={style.assigneeFilter}
            onRemoveFilter={() => {
              setShowAssigneeFilter(false);
              setAssignee(null);
            }}
            selectedUserId={assignee}
            setSelectedUserId={setAssignee}
          />
        )}

        {(showTagsFilter || tagState?.selected.size > 0) && (
          <TagsFilterDropdown
            className={style.tagsFilter}
            onRemoveFilter={() => {
              setShowTagsFilter(false);
              clearSelectedTags();
            }}
            selectTag={selectTag}
            tagState={tagState}
          />
        )}

        {showDevIDFilter && (
          <DevIDFilterDropdown
            className={style.devIDFilter}
            filterValue={devID}
            setFilter={setDevID}
            onRemoveFilter={() => {
              setShowDevIDFilter(false);
              setDevID(null);
            }}
          />
        )}

        {showComponentFilter && (
          <ComponentFilterDropdown
            filterValue={componentFilter}
            onSelectFilter={(val) => {
              setComponentFilter(val);
            }}
            onRemoveFilter={() => {
              setComponentFilter(null);
              setShowComponentFilter(false);
            }}
          />
        )}

        {showVariantFilter && (
          <VariantFilterDropdown
            className={style.variantFilter}
            config={variantFilterConfig}
            value={variantFilter}
            onChange={setVariantFilter}
            onRemoveFilter={() => {
              setShowVariantFilter(false);
              setVariantFilter(null);
            }}
          />
        )}

        {(!showStatusFilter ||
          !showAssigneeFilter ||
          !showTagsFilter ||
          !showDevIDFilter ||
          !showComponentFilter ||
          !showVariantFilter) &&
          !normalFiltersHidden && (
            <div className={style.filtersDropdown}>
              <Dropdown>
                <Dropdown.Toggle className={style.toggle} as={FiltersDropdownToggle}>
                  Filter
                </Dropdown.Toggle>
                <Dropdown.Menu className={style.menu}>
                  {!showStatusFilter && (
                    <Dropdown.Item className={style.item} onClick={() => setShowStatusFilter(true)}>
                      <FlagIcon className={style.icon} /> Status
                    </Dropdown.Item>
                  )}
                  {!showAssigneeFilter && (
                    <Dropdown.Item className={style.item} onClick={() => setShowAssigneeFilter(true)}>
                      <AccountCircleIcon className={style.icon} /> Assignee
                    </Dropdown.Item>
                  )}
                  {!showTagsFilter && (
                    <Dropdown.Item className={style.item} onClick={() => setShowTagsFilter(true)}>
                      <LocalOfferIcon className={style.icon} />
                      Tags
                    </Dropdown.Item>
                  )}
                  {!showDevIDFilter && (
                    <Dropdown.Item className={style.item} onClick={() => setShowDevIDFilter(true)}>
                      <CodeIcon className={style.icon} />
                      Developer ID
                    </Dropdown.Item>
                  )}
                  {setComponentFilter && !showComponentFilter && (
                    <Dropdown.Item
                      className={style.item}
                      onClick={() => setShowComponentFilter(true)}
                      data-testid="components-filter-dropdown-item"
                    >
                      <ImportContactsIcon className={style.icon} />
                      Components
                    </Dropdown.Item>
                  )}
                  {/* 
                    The check for `setVariantFilter` is just to ensure that the option
                    doesn't show unless there's full support for variant filtering passed
                    from the parent  
                  */}
                  {!showVariantFilter && setVariantFilter && (
                    <Dropdown.Item className={style.item} onClick={() => setShowVariantFilter(true)}>
                      <CallSplitIcon className={style.icon} style={{ transform: "rotate(90deg)" }} />
                      {variantFilter?.label || "Variant"}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

        {customInputs}
      </div>
    </div>
  );
};

const FiltersDropdownToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    className={style.filtersToggle}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    data-testid="filtersToggle"
  >
    <AddIcon className={style.plus} />
    Filter
  </div>
));
FiltersDropdownToggle.displayName = "FiltersDropdownToggle";

export default SearchAndFilters;
