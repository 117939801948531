import { useMemo } from "react";

/**
 * Returns a function that accepts an array of new variables -
 * that function can be called to compute whether or not there
 * is a change between the two variable arrays.
 * @param {*} originalVariables
 * @returns
 */
export const useGetVariablesHaveChanged = (originalVariables) => {
  // Used for O(1) lookups
  const originalVariablesMap = useMemo(
    () => (originalVariables || []).reduce((agg, variable) => ({ ...agg, [variable.variable_id]: true }), {}),
    [originalVariables]
  );

  return (newVariables) => {
    return (
      (newVariables || []).length !== (originalVariables || []).length ||
      (newVariables || []).some((variable) => !originalVariablesMap[variable.variable_id])
    );
  };
};
