import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import { IFWebhookLog } from "@shared/types/WebhookLog";
import { IWebhookConfig } from "@shared/types/Workspace";
import logger from "@shared/utils/logger";
import ObjectId from "bson-objectid";
import classNames from "classnames";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import CreatableSelect from "react-select/creatable";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import FileDownloadIcon from "../../assets/FileDownload";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ModalBase, { ModalBody, ModalFooter } from "../shared/ModalBase";
import ConfirmationModal from "../shared/confirmation-modal";
import style from "./WebhookModal.module.css";

import { WEBHOOK_DOCUMENTATION_LINK, webhookToggleLabels } from "@shared/lib/webhook";
import "../../views/Project/components/react-toggle-doc.module.css";
import { WebhookDeliveryHistory } from "./WebhookDeliveryHistory";

const supportedEvents = Object.entries(webhookToggleLabels).map(([id, label]) => ({ id, label }));

const ROOT_VALUE = "__ROOT__";

const getInitialFilterState = (webhook: IWebhookConfig): IState["filters"] => {
  if (!webhook.filters.componentFolderIds) {
    return {
      componentFolderIds: null,
    };
  }

  return {
    componentFolderIds: webhook.filters.componentFolderIds.map((id) =>
      // `null` in the componentFolderIds array corresponds to "components not in a folder";
      // react-select doesn't support null values in the options array, so we need to use a
      // constant to represent it instead.
      id === null ? ROOT_VALUE : id
    ),
  };
};

const getInitialTestRequestState = (webhook: IWebhookConfig): IState["testRequestState"] => {
  // Webhooks are automatically disabled by the webhook job if they fail to deliver too
  // many consecutive payloads within a certain timeframe. If a webhook is disabled, the
  // user must validate the URL with a test request before they can re-enable it.
  if (!webhook.enabled) {
    return {
      attempted: true,
      loading: false,
      success: false,
      error:
        "This webhook has been disabled due to repeated failed deliveries. Please validate a URL to re-enable this webhook.",
    };
  }

  return { attempted: true, loading: false, success: true };
};

const getInitialStateValue = (webhook: IWebhookConfig | null): IState => {
  if (!webhook) {
    return {
      name: "",
      url: "",
      deliveryHistory: { loaded: true, history: [] },
      events: new Set(),
      filters: {
        componentFolderIds: null,
      },
      testRequestState: { attempted: false },
    };
  }

  return {
    name: webhook.name,
    url: webhook.url,
    deliveryHistory: { loaded: false, history: [] },
    events: new Set(webhook.events),
    filters: getInitialFilterState(webhook),
    testRequestState: getInitialTestRequestState(webhook),
  };
};

export interface IProps {
  /**
   * If null, the modal will be in "create" mode. Otherwise, it will be in "edit" mode.
   */
  webhook: IWebhookConfig | null;
  /**
   * The folders that the user can select from to filter events by.
   */
  componentFolders: ComponentFolder[];
  /**
   * Optionally override the initial screen shown in the modal
   */
  initialScreen?: "main" | "deliveryHistory";
  /**
   * @param url the url to send the test request to
   * @returns `true` if a success response was received from sending the test request
   */
  onSendTestRequest: (webhookId: string, url: string) => Promise<IFWebhookLog>;
  /**
   *
   * @param url the url to send the test request to
   * @returns `true` if the url doesn't conflict with other webhooks in the workspace
   */
  onValidateUniqueUrl: (webhookId: string, url: string) => Promise<boolean>;
  onSave: (isNew: boolean, config: IWebhookConfig) => void | Promise<void>;
  onDelete: (webhookId: string) => void | Promise<void>;
  onExportDeliveryHistory: (webhookId: string) => void;
  loadDeliveryHistory: (webhookId: string) => Promise<IFWebhookLog[]>;
  onHide: () => void;
}

interface IState {
  name: string;
  url: string;
  deliveryHistory: { loaded: boolean; history: IFWebhookLog[]; error?: string };
  events: Set<string>;
  filters: IWebhookConfig["filters"];
  testRequestState:
    | { attempted: false } // haven't attempted a test request yet
    | { attempted: true; loading: true } // sent the test request, waiting for response
    | { attempted: true; loading: false; success: boolean; error?: string }; // received a response from the test request
}

export type ComponentFolder = {
  _id: string;
  name: string;
  workspace_id: string;
};

export const WebhookModal = (props: IProps) => {
  // we need to have a webhook _id ahead of time for saving test request webhook logs
  const webhookId = React.useRef(props.webhook?._id || new ObjectId().toHexString());

  const [state, setState] = React.useState<IState>(getInitialStateValue(props.webhook));

  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = React.useState(false);

  const [screen, setScreen] = React.useState<"main" | "deliveryHistory">(props.initialScreen || "main");

  React.useEffect(
    function loadRequestLogs() {
      if (!props.webhook) return;
      props
        .loadDeliveryHistory(props.webhook._id)
        .then((history) =>
          setState((s) => ({
            ...s,
            deliveryHistory: { loaded: true, history },
          }))
        )
        .catch((e) =>
          logger.error(
            "Failed to load delivery history",
            {
              context: {
                webhook: JSON.stringify(props.webhook),
              },
            },
            e
          )
        );
    },
    [props.webhook]
  );

  const getTitle = () => {
    if (screen === "deliveryHistory") {
      return "View delivery history";
    }
    if (!props.webhook) {
      return "Create Webhook";
    }
    if (!props.webhook.enabled) {
      return "Re-enable Webhook";
    }
    return "Edit Webhook";
  };
  const title = getTitle();

  const getSaveButtonText = () => {
    if (props.webhook && !props.webhook.enabled) {
      return "Re-enable";
    }
    return "Save";
  };
  const saveButtonText = getSaveButtonText();

  const getConfirmDeletionText = () => {
    let text = "Are you sure you want to delete this webhook?";
    if (props.webhook && props.webhook.enabled) {
      text += " It will immediately stop receiving all events.";
    }
    return text;
  };
  const confirmDeletionText = getConfirmDeletionText();

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState({ ...state, name: value });
  };

  const onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, "");
    setState({ ...state, url: value, testRequestState: { attempted: false } });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    const eventName = e.target.dataset.eventName;
    if (!eventName) throw new Error("Missing data-event-name attribute");

    setState((s) => {
      const events = new Set(s.events);
      if (checked) {
        events.add(eventName);
      } else {
        events.delete(eventName);
      }
      return { ...s, events };
    });
  };

  const onValidateWithTestRequestClick = async () => {
    if (!state.url) return;

    setState((s) => ({
      ...s,
      testRequestState: { attempted: true, loading: true },
    }));

    const urlIsUnique = await props.onValidateUniqueUrl(webhookId.current, state.url);
    if (!urlIsUnique) {
      return setState((s) => ({
        ...s,
        testRequestState: {
          attempted: true,
          loading: false,
          success: false,
          error: "This URL is already in use for another webhook.",
        },
      }));
    }

    const log = await props.onSendTestRequest(webhookId.current, state.url);
    if (!log.success) {
      return setState((s) => {
        return {
          ...s,
          deliveryHistory: {
            ...s.deliveryHistory,
            history: [log, ...s.deliveryHistory.history],
          },
          testRequestState: { attempted: true, loading: false, success: false },
        };
      });
    }

    setState((s) => ({
      ...s,
      deliveryHistory: {
        ...s.deliveryHistory,
        history: [log, ...s.deliveryHistory.history],
      },
      testRequestState: {
        attempted: true,
        loading: false,
        success: true,
      },
    }));
  };

  const onCancelClick = () => props.onHide();

  const onSaveClick = async () => {
    const isNewWebhook = !props.webhook;
    await props.onSave(isNewWebhook, {
      _id: webhookId.current,
      name: state.name,
      url: state.url,
      enabled: true,
      filters: {
        componentFolderIds: state.filters.componentFolderIds?.map((id) => (id === ROOT_VALUE ? null : id)) || null,
      },
      events: Array.from(state.events),
    });
    props.onHide();
  };

  const testRequestLoading = state.testRequestState.attempted && state.testRequestState.loading;

  const testRequestErrorMessage =
    (state.testRequestState.attempted &&
      !state.testRequestState.loading &&
      !state.testRequestState.success &&
      (state.testRequestState.error || "Request failed. Please ensure your server responds with a 200 status code.")) ||
    null;

  const testRequestFailed = Boolean(testRequestErrorMessage);

  const testRequestSucceeded =
    Boolean(state.url) &&
    state.testRequestState.attempted &&
    !state.testRequestState.loading &&
    state.testRequestState.success;

  const sendTestRequestButtonEnabled =
    Boolean(state.url) &&
    (!state.testRequestState.attempted || (state.testRequestState.attempted && !state.testRequestState.loading));

  const viewDeliveryHistoryButtonEnabled = state.deliveryHistory.history.length > 0;

  const canSaveWebhook = Boolean(testRequestSucceeded && state.name && state.url && state.events.size > 0);

  const onViewDeliveryHistoryClick = () => {
    if (!viewDeliveryHistoryButtonEnabled) return;
    setScreen("deliveryHistory");
  };

  const onBackToWebhookSettingsClick = () => {
    setScreen("main");
  };

  const onDeleteClick = () => {
    if (!props.webhook) return;
    setShowConfirmDeletionModal(true);
  };

  const onConfirmDeleteHide = () => {
    setShowConfirmDeletionModal(false);
  };

  const onConfirmDeleteClick = async () => {
    await props.onDelete(webhookId.current);
    props.onHide();
  };

  const onFolderSelection = (data: { label: string; value: string }[] | null) => {
    let componentFolderIds = data ? Array.from(new Set(data.map((d) => d.value))) : null;

    // ensure the component filter array is never null, otherwise ALL
    // events will be filtered out
    if (Array.isArray(componentFolderIds) && !componentFolderIds.length) {
      componentFolderIds = null;
    }

    setState((s) => ({
      ...s,
      filters: { ...s.filters, componentFolderIds },
    }));
  };

  const onExportDeliveryHistoryClick = () => {
    props.onExportDeliveryHistory(webhookId.current);
  };

  const { options, value } = React.useMemo<{
    options: { label: string; value: string }[];
    value: { label: string; value: string }[];
  }>(() => {
    const selectedFolderIds = new Set(state.filters.componentFolderIds);

    const options = [
      {
        label: "Components not in a folder",
        value: ROOT_VALUE,
      },
      ...props.componentFolders.map((f) => ({
        label: f.name,
        value: f._id,
      })),
    ];

    const value = options.filter((o) => selectedFolderIds.has(o.value));

    return {
      options,
      value,
    };
  }, [props.componentFolders, state.filters]);

  const getSaveButtonTooltipMessage = () => {
    if (!state.url) return "Please enter a URL before saving";

    if (!testRequestSucceeded) return "Please validate the URL with a test request before saving";

    if (!state.name) return "Please enter a name for the webhook before saving";

    if (!state.events.size) return "Please select at least one trigger before saving";

    return "";
  };

  return (
    <>
      <ModalBase onHide={props.onHide} title={title} className={style.container} titleClassName={style.containerTitle}>
        <ModalBody className={style.body}>
          {screen === "main" && (
            <div className={style.mainScreen}>
              <div className={style.section}>
                <label className={style.label}>
                  <span className={style.labelText}>Name</span>
                  <input
                    data-testid="webhook-name-input"
                    type="text"
                    value={state.name}
                    onChange={onNameChange}
                    placeholder="My Webhook"
                  />
                </label>
                <div className={style.urlField}>
                  <label className={style.label}>
                    <span className={style.labelText}>URL</span>
                    <div className={style.inputWrapper}>
                      <input
                        data-testid="webhook-url-input"
                        type="text"
                        value={state.url}
                        onChange={onUrlChange}
                        placeholder="https://myserveraddress.com"
                        className={classNames({
                          [style.error]: testRequestFailed,
                          [style.success]: testRequestSucceeded || testRequestLoading,
                        })}
                      />
                      {testRequestSucceeded && (
                        <div className={style.status}>
                          <CheckIcon className={style.icon} data-testid="webhook-test-success" /> Valid
                        </div>
                      )}
                      {testRequestLoading && (
                        <div className={style.loader}>
                          <Spinner animation="border" size={"sm"} />
                        </div>
                      )}
                    </div>
                    {testRequestErrorMessage && <p className={style.error}>{testRequestErrorMessage}</p>}
                    <div className={style.urlActions}>
                      <button
                        data-testid="webhook-validate-button"
                        className={style.sendTestRequest}
                        disabled={!sendTestRequestButtonEnabled}
                        onClick={onValidateWithTestRequestClick}
                      >
                        <SendIcon className={style.icon} /> <span>Validate with test request</span>
                      </button>
                      <button
                        className={style.viewDeliveryHistory}
                        disabled={!viewDeliveryHistoryButtonEnabled}
                        onClick={onViewDeliveryHistoryClick}
                      >
                        <HistoryIcon className={style.icon} /> <span>View delivery history</span>
                      </button>
                    </div>
                  </label>
                </div>
              </div>
              <hr />
              <div className={style.section}>
                <p className={style.labelText}>
                  Triggers
                  <a href={`${WEBHOOK_DOCUMENTATION_LINK}#event-reference`} target="_blank">
                    <OpenInNewIcon className={style.icon} />
                  </a>
                </p>
                <p className={style.specText}>
                  Specify the events that will trigger a payload to be sent for this webhook.
                </p>
                <div className={style.eventsContainer}>
                  {supportedEvents
                    .filter((event) => event.id !== "TestEvent")
                    .map((event) => (
                      <label key={event.id} className={style.eventContainer}>
                        <Toggle
                          data-testid={`webhook-event-${event.id}`}
                          className={style.toggle}
                          icons={false}
                          checked={state.events.has(event.id)}
                          onChange={onCheckboxChange}
                          data-event-name={event.id}
                        />
                        <span className={style.eventName}>{event.label}</span>
                      </label>
                    ))}
                </div>
              </div>
              {props.componentFolders.length > 0 && (
                <>
                  <hr />
                  <div className={style.section}>
                    <div className={style.filterByFolderContainer}>
                      <p className={style.labelText}>Filter by Folder (Optional)</p>
                      <p className={style.specText}>
                        Restrict events to only be triggered by specific component folders.
                      </p>
                      <CreatableSelect
                        isMulti
                        isClearable
                        placeholder={"Select component folder(s)..."}
                        onChange={onFolderSelection}
                        className={style.componentFolderSelector}
                        options={options}
                        value={value}
                        isValidNewOption={() => false}
                        components={{
                          Option: FolderOption,
                          MultiValueLabel: MultiValueLabel,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {screen === "deliveryHistory" && (
            <div className={style.deliveryHistoryScreen}>
              <div className={style.innerContainer}>
                <ButtonSecondary
                  text={
                    <span>
                      <ArrowBackIcon className={style.icon} /> Back to webhook settings
                    </span>
                  }
                  onClick={onBackToWebhookSettingsClick}
                  className={style.backToWebhookButton}
                />
                <div className={style.webhookNameContainer}>
                  <label>Webhook</label>
                  <input type="text" disabled value={state.name} />
                </div>
              </div>
              <WebhookDeliveryHistory history={state.deliveryHistory.history} loading={!state.deliveryHistory.loaded} />
            </div>
          )}
        </ModalBody>
        <ModalFooter
          className={classNames({
            [style.footer]: true,
            [style.withDeleteButton]: !!props.webhook,
            [style.deliveryHistoryScreen]: screen == "deliveryHistory",
          })}
        >
          {screen === "main" && (
            <>
              {props.webhook && (
                <ButtonSecondary
                  data-testid="webhook-delete-button"
                  text={
                    <>
                      <DeleteIcon className={style.icon} /> Delete Webhook
                    </>
                  }
                  onClick={onDeleteClick}
                  className={style.deleteButton}
                />
              )}
              <div className={style.footerRightButtons}>
                <ButtonSecondary text="Cancel" onClick={onCancelClick} className={style.cancelButton} />
                <div data-tip data-for="webhook-save-button" data-testid="webhook-save-button">
                  <ButtonPrimary
                    text={saveButtonText}
                    disabled={!canSaveWebhook}
                    onClick={onSaveClick}
                    className={style.submitButton}
                  />
                </div>
                {!canSaveWebhook && (
                  <ReactTooltip id="webhook-save-button" place="bottom" effect="solid">
                    {getSaveButtonTooltipMessage()}
                  </ReactTooltip>
                )}
              </div>
            </>
          )}
          {screen === "deliveryHistory" && Boolean(props.webhook) && (
            <ButtonSecondary
              text={
                <span>
                  <FileDownloadIcon className={style.icon} /> Export for past 7 days
                </span>
              }
              onClick={onExportDeliveryHistoryClick}
              className={style.exportDeliveryHistoryButton}
            />
          )}
        </ModalFooter>
      </ModalBase>
      {showConfirmDeletionModal && (
        <ConfirmationModal
          isLayered
          title="Delete webhook"
          body={confirmDeletionText}
          onPrimary={onConfirmDeleteClick}
          actionPrimary="Delete"
          onSecondary={onConfirmDeleteHide}
          actionSecondary="Cancel"
        />
      )}
    </>
  );
};

function FolderOption(props: any) {
  const {
    data: { label, description },
    innerProps: { onClick, onMouseOver },
  } = props;

  return (
    <div onClick={onClick} onMouseOver={onMouseOver} className={style.folderOption}>
      <div className={style.name}>{label}</div>
      <span className={style.description}>{description}</span>
    </div>
  );
}

function MultiValueLabel(props: { children: React.ReactNode }) {
  return <div className={style.multiValueLabel}>{props.children}</div>;
}
