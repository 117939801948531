import { textItemFamilyAtom } from "@/stores/Project";
import { ChangeItemBadgeData } from "@ds/molecules/ChangeItemCountBadge";
import { GetterWithPeek } from "@shared/frontend/stores/useAtomEffect";
import { ENTRY_TYPES, IChangeItem } from "@shared/types/ActualChange";

/**
 * This function calculates the data for rendering a badge on a change item.
 * @param get The get method as provided by useAtomEffect
 * @param setBadge The change item component's set state method
 * @param changeItem The change item we're rendering
 */
export const initializeBadge = async (
  get: GetterWithPeek,
  setBadge: React.Dispatch<React.SetStateAction<ChangeItemBadgeData | undefined>>,
  changeItem: IChangeItem
) => {
  switch (changeItem.entry_type) {
    case ENTRY_TYPES.EDIT:
      const textItem = await get.peek(textItemFamilyAtom(changeItem.comp_id));
      const count = textItem?.integrations?.figmaV2?.instances?.length;
      if (count) {
        setBadge({ type: "instance", count });
      } else {
        setBadge(undefined);
      }
      break;
    case ENTRY_TYPES.DUPES_STATUS:
      setBadge({ type: "textItem", count: changeItem.dupe_comp_ids.length });
      break;
    default:
      setBadge(undefined);
  }
};
