import AutorenewIcon from "@mui/icons-material/Autorenew";
import PhotoIcon from "@mui/icons-material/Photo";
import * as SegmentEvents from "@shared/segment-event-names";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import TimeAgo from "react-timeago";
import { routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import CompactLabel from "../CompactLabel";
import FigmaFrameVariantWarning from "../FigmaFrameVariantWarning";
import HighlightAltIcon from "../HighlightAltIcon";
import ImagePreviewSpinner from "../shared/ImagePreviewSpinner";
import style from "./style.module.css";

const FigmaFramePreview = ({
  setImgError,
  imgError,
  imageLoaded,
  imageInfo,
  setImageLoaded,
  setEnlargeImgOpen,
  selectedFigmaIds,
  showHighlight,
  setShowHighlight,
  canHighlight,
  setCanHighlight,
  pollingForPreviewUpdates,
  appliedVariant,
}) => {
  const segment = useSegment();

  const [highlightBoxStyles, setHighlightBoxStyles] = useState([]);
  const [imageRenderDimensions, setImageRenderDimensions] = useState(null);

  const imgElement = useRef(null);
  const MODAL_RL_PADDING = 0; // right-left padding, corresponds to value in style.css
  const IMAGE_WIDTH = 300;

  const onWindowResize = (e) => {
    if (imgElement.current) {
      setImageRenderDimensions({
        width: imgElement.current.clientWidth,
        height: imgElement.current.clientHeight,
      });
    }
  };

  useEffect(
    function loadImage() {
      if (!imageInfo || !imgElement?.current) return;
      setImageLoaded(false);
      var downloadingImage = new Image();
      downloadingImage.onload = function ({ target }) {
        if (imgElement.current) {
          imgElement.current.src = this.src;
          setTimeout(() => onWindowResize({}), 0);
        }
      };
      downloadingImage.src = imageInfo.previews.fullsize;
    },
    [imageInfo?.previewsLastUpdatedAt]
  );

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(() => {
    if (setShowHighlight !== null && imageInfo && imageInfo.frameDimensions && imageInfo.textDimensions) {
      const { frameDimensions, textDimensions } = imageInfo;
      setCanHighlight(true);
      let boxStyles = [];
      selectedFigmaIds.forEach((selectedFigmaId) => {
        let textNodeDimensions = textDimensions[selectedFigmaId];
        if (frameDimensions && textNodeDimensions && imageRenderDimensions) {
          let tempScaleFactor = frameDimensions.width / imageRenderDimensions.width;

          const boxStyle = {
            wrapper: {
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: Math.abs(frameDimensions.x - textNodeDimensions.x) / tempScaleFactor + MODAL_RL_PADDING + "px",
              top: Math.abs(frameDimensions.y - textNodeDimensions.y) / tempScaleFactor + "px",
              width: textNodeDimensions.width / tempScaleFactor + "px",
              height: textNodeDimensions.height / tempScaleFactor + "px",
              textAlign: "left",
              justifyContent: "flex-start",
            },
            box: {
              position: "absolute",
              borderRadius: "2px",
              width: textNodeDimensions.width / tempScaleFactor + "px",
              height: textNodeDimensions.height / tempScaleFactor + "px",

              border: "1px solid red",
            },
          };
          boxStyles.push(boxStyle);
        }
        setHighlightBoxStyles(boxStyles);
      });
    }
  }, [imageInfo, imageRenderDimensions, selectedFigmaIds]);

  const handleImgLoaded = () => {
    onWindowResize();
    setImageLoaded(true);
  };
  const handleImgLoadingError = async (e) => {
    e.persist();
    try {
      const imgResponse = await fetch(imageInfo.previews.fullsize);
      if (!imgResponse.ok) {
        e.target.style.display = "none";
        setImgError({ exists: true, status: imgResponse.status });
      } else {
        setImgError({ exists: true });
      }
    } catch (e) {
      console.warn("Failed to fetch image preview: ", imageInfo.previews.fullsize, e);
      setImgError({ exists: true });
    }
  };
  const handleExpandImage = () => {
    segment.track({ event: SegmentEvents.IMAGE_PREVIEW_CLICKED });
    setEnlargeImgOpen(true);
  };
  const toggleShowHighlight = () => {
    segment.track({
      event: "Image preview highlight clicked",
      properties: {
        location: "side panel",
        show_highlight: !showHighlight,
      },
    });
    setShowHighlight(!showHighlight);
  };

  const getNumberOfDays = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const FigmaPreviewFetching = () => (
    <div className={style.imagePreviewContainer}>
      <div className={style.framePreviewTitle}>
        <h4>Figma Frame Preview</h4>
        <div className={style.right}>{pollingForPreviewUpdates && <ImagePreviewSpinner />}</div>
      </div>
      <div className={style.imgPlaceholderText}>Hang tight while we fetch this image preview.</div>
    </div>
  );
  if (imgError.exists) {
    return (
      <div className={style.imagePreviewContainer}>
        <h4>Figma Frame Preview</h4>
        {imgError.status === "no_fig_token" && (
          <div className={style.imgPlaceholderText}>
            Sorry, we couldn't fetch the image preview. Please check that you've{" "}
            <a href={routes.nonNavRoutes.account.path + "/user"}>connected your Figma account.</a>
          </div>
        )}
        {imgError.status === 500 && (
          <div className={style.imgPlaceholderText}>
            Sorry, this image was too large for Figma's servers to send over.
          </div>
        )}
        {imgError.status === 404 && <div className={style.imgPlaceholderText}>Error loading image preview.</div>}
        {imgError.status === 429 && (
          <div className={style.imgPlaceholderText}>
            Sorry, we couldn't fetch the image preview because we've hit Figma's server limitation.
          </div>
        )}
        {imgError.status === 403 && (
          <div className={style.imgPlaceholderText}>We&apos;re still fetching this image preview.</div>
        )}
        {imgError.status === 400 && (
          <div className={style.imgPlaceholderText}>
            Sorry, we couldn't fetch the image preview. Please check that you've connected your Figma account.
          </div>
        )}
      </div>
    );
  }
  if (!imageInfo || !imageInfo.previews) {
    return <FigmaPreviewFetching />;
  }

  const syncMoreThanOneDayOld = getNumberOfDays(imageInfo.previewsLastUpdatedAt, new Date()) > 0;

  const formattedImageInfoLastUpdatedAt =
    typeof imageInfo.previewsLastUpdatedAt === "string"
      ? new Date(imageInfo.previewsLastUpdatedAt)
      : imageInfo.previewsLastUpdatedAt;

  return (
    <div className={style.imagePreviewContainer}>
      <div className={style.framePreviewTitle}>
        <CompactLabel text="Figma Frame Preview" Icon={PhotoIcon} />
        <div className={style.right}>
          {canHighlight && (
            <span
              className={classnames({
                [style.highlightButton]: true,
                [style.highlightSelected]: showHighlight,
              })}
              onClick={toggleShowHighlight}
            >
              <HighlightAltIcon />
              <span>Highlight</span>
            </span>
          )}
        </div>
      </div>
      <div className={style.lastSyncInfo}>
        Fetched{" "}
        {!syncMoreThanOneDayOld ? (
          <TimeAgo
            date={imageInfo.previewsLastUpdatedAt}
            minPeriod={60}
            key={imageInfo.previewsLastUpdatedAt?.toString()}
          />
        ) : (
          <>
            {`${formattedImageInfoLastUpdatedAt.toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} at ${formattedImageInfoLastUpdatedAt.toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
            })}`}
          </>
        )}
        {pollingForPreviewUpdates && <AutorenewIcon className={style.spinning} />}
      </div>
      {appliedVariant && (
        <FigmaFrameVariantWarning exists={appliedVariant.exists} variantName={appliedVariant.name} isEnlarge={false} />
      )}
      <div className={style.imageWrapper}>
        <img
          ref={imgElement}
          className={style.imagePreview}
          width={IMAGE_WIDTH}
          alt="figma frame preview"
          src={imageInfo.previews.fullsize}
          onClick={handleExpandImage}
          onLoad={handleImgLoaded}
          onError={handleImgLoadingError}
        />
        {canHighlight &&
          showHighlight &&
          highlightBoxStyles.map(({ wrapper, box }, index) => (
            <div key={index} style={wrapper}>
              <div className={style.highlightBox} style={box} onClick={handleExpandImage}></div>
            </div>
          ))}
        {!imageLoaded && <div className={style.imgPlaceholderText}>Loading image preview...</div>}
      </div>
    </div>
  );
};

export default FigmaFramePreview;
