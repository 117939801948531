import classNames from "classnames";
import React from "react";
import ToastBase, { IProps as IToastBaseProps } from "../../molecules/ToastBase";
import style from "./index.module.css";

interface IProps extends IToastBaseProps {
  innerClassName?: string;
}

export function Toast(props: IProps) {
  return (
    <div style={props.style} className={classNames(style.ToastWrapper, props.className)} data-testid="toast-component">
      {!props.content && <ToastBase {...props} className={props.innerClassName} />}
      {props.content && <>{props.content}</>}
    </div>
  );
}

export default Toast;
