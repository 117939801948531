import CloseIcon from "@mui/icons-material/Close";
import * as SegmentEvents from "@shared/segment-event-names";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import AutoAwesomeIcon from "../../../../../shared/frontend/AutoAwesomeIcon";
import useSegment from "../../../../hooks/useSegment";
import { SetupSuggestionsForGroup, turnOffSetupSuggestionsForGroup } from "../../../../http/setupSuggestions";
import { isGroupLinked } from "../../../../views/Project/state/types";
import { useProjectContext } from "../../../../views/Project/state/useProjectState";
import style from "./style.module.css";

function SuggestionsBanner(props: { groupId: string }) {
  const segment = useSegment();

  const projectState = useProjectContext();
  const {
    setupSuggestionsForAllGroups,
    setSetupSuggestionsForAllGroups,
    setSetupSuggestions,
    setShowSetupSuggestionsPreview,
    setIsShowingSetupSuggestionsFlow,
    projectId,
    isShowingSetupSuggestionsFlow,
    selectedGroupId: [_, setSelectedGroupId],
    groupState: [groupState],
  } = projectState;

  const [closeHovered, setClosedHovered] = useState<Boolean>(false);

  const suggestionsCount = useMemo(() => {
    if (!setupSuggestionsForAllGroups || !setupSuggestionsForAllGroups[props.groupId]) {
      return 0;
    }

    const suggestionsForGroup = setupSuggestionsForAllGroups[props.groupId];

    return (
      Object.values(suggestionsForGroup.blockSuggestions).reduce((acc, cur) => {
        return (acc += cur.textItems.length);
      }, 0) +
      suggestionsForGroup.hideSuggestions.length +
      suggestionsForGroup.wsComponentSuggestions.length
    );
  }, [setupSuggestionsForAllGroups]);

  const onClickReview = () => {
    const suggestionsForGroup = setupSuggestionsForAllGroups[props.groupId];

    const blockSuggestions = Object.entries(suggestionsForGroup.blockSuggestions).reduce(
      (acc, [blockId, blockData]) => {
        acc[blockId] = {
          selected: true,
          ...blockData,
        };

        return acc;
      },
      {}
    );

    const setupSuggestions: SetupSuggestionsForGroup = {
      groupId: props.groupId,
      projectId,
      blockSuggestions: { ...blockSuggestions },
      hideSuggestionsSelected: true,
      hideSuggestions: suggestionsForGroup.hideSuggestions,
      wsComponentSuggestions: suggestionsForGroup.wsComponentSuggestions.map((s) => ({
        ...s,
        selected: true,
      })),
    };
    setSetupSuggestions(setupSuggestions);
    setShowSetupSuggestionsPreview(true);
    setIsShowingSetupSuggestionsFlow(true);
    setSelectedGroupId(props.groupId);

    segment.track({
      event: SegmentEvents.POST_IMPORT_SUGGESTION_REVIEW_GROUP_SUGGESTIONS,
      properties: {
        groupId: props.groupId,
      },
    });
  };

  const onClose = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const [request] = turnOffSetupSuggestionsForGroup(props.groupId, projectId);
    const res = await request;
    if (res.status === 200) {
      setSetupSuggestionsForAllGroups((prev) => {
        const newState = structuredClone(prev);
        delete newState[props.groupId];
        return newState;
      });
    }

    segment.track({
      event: "Post-import suggestion — Ignore suggestions via banner",
      properties: {
        groupId: props.groupId,
      },
    });
  };

  const handleMouseEnterClose = () => {
    setClosedHovered(true);
  };

  const handleMouseLeaveClose = () => {
    setClosedHovered(false);
  };

  const currentGroupIsConnected = useMemo(() => {
    const currentGroup = groupState.groups.find((g) => g._id === props.groupId);

    if (!currentGroup) {
      return false;
    }

    return isGroupLinked(currentGroup);
  }, [props.groupId, groupState.groups]);

  if (
    setupSuggestionsForAllGroups[props.groupId] &&
    suggestionsCount > 0 &&
    !isShowingSetupSuggestionsFlow &&
    // No draft groups!
    currentGroupIsConnected
  ) {
    return (
      <div className={style.suggestionsBannerContainer} onClick={onClickReview}>
        <AutoAwesomeIcon className={style.autoAwesomeIcon} fill="#584d28" />
        We found {suggestionsCount} suggestion{suggestionsCount > 1 ? "s" : ""} for auto-organizing this frame!
        <div className={style.actions}>
          <span
            className={classNames({
              [style.reviewButton]: true,
              [style.reviewButtonBaseFontColor]: closeHovered,
            })}
          >
            Review
          </span>
          <CloseIcon
            className={style.close}
            onClick={(e) => onClose(e)}
            onMouseEnter={handleMouseEnterClose}
            onMouseLeave={handleMouseLeaveClose}
          />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default SuggestionsBanner;
