const ROOT = "/figma/auth";

export default {
  post: {
    request: {
      url: ROOT,
      body: ({ figmaCorrelationKey, refreshToken }) => ({
        figmaCorrelationKey,
        refreshToken,
      }),
    },
    cancel: {
      url: ({ figmaCorrelationKey }) => `${ROOT}/${figmaCorrelationKey}`,
      body: ({ figmaCorrelationKey, reason }) => ({
        figmaCorrelationKey,
        reason,
      }),
    },
  },
};
