import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

const VariableListPlaceholder = () => {
  return (
    <div className={style.wrapper}>
      {Array(4)
        .fill("")
        .map((_item, index) => (
          <div className={style.variableItem} key={index}>
            <div className={style.titleSection}>
              <Skeleton width={`40%`} />
              <Skeleton width={`40%`} />
            </div>
            <div className={style.infoSection}>
              <Skeleton width={`75%`} />
              <Skeleton width={`75%`} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default VariableListPlaceholder;
