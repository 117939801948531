import classNames from "classnames";
import React from "react";
import style from "./CompIgnored.module.css";

interface IProps {
  name: string;
  text: string;
  className?: string;
}

export default function CompIgnored(props: IProps) {
  const { name, text, className } = props;

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.name}>{name}</div>
      <div className={style.text}>{text}</div>
    </div>
  );
}
