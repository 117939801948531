import { useUnauthenticatedAuth } from "@/store/UnauthenticatedAuthContext";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { EMAIL_REGEX } from "../../../shared/utils/email";
import { getFigmaCorrelationKey } from "../../figma/lib";
import { fetchEnterpriseConfigByEmailAddress } from "../../http/enterprise";
import ButtonPrimary from "../button/buttonprimary";
import LoginWrapper from "./LoginWrapper";
import { getAppState } from "./lib";
import style from "./style.module.css";

const BackToLogin = () => (
  <Link className={style.backToLogin} to="/">
    {"<- Back to login"}
  </Link>
);

type EmailError = "invalid-domain";

interface LoginArgs {
  /**
   * Can be a specific connection string that corresponds to a SAML
   * configuration; defaults to "Username-Password-Authentication"
   */
  connection: string | "Username-Password-Authentication";
  appState: ReturnType<typeof getAppState>;
  hasFigmaCorrelationKey: boolean;
  /**
   * This used to be the only way by which users logging in via a SAML connection
   * would be put in the correct enterprise workspaces; that is no longer the case.
   * In /user/signup, the correct workspace for a SAML user is determined by their
   * identities as provided by Auth0, but this field is still needed for users who
   * are logging in with username/password authentication.
   */
  workspaceID: string;
  enterpriseKey?: string;
}

const LoginSaml = () => {
  const [error, setError] = useState<EmailError | null>(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { isAuthenticated, loginWithRedirect } = useUnauthenticatedAuth();
  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const continueButtonDisabled = !EMAIL_REGEX.test(email) || error || loading;

  const hasFigmaCorrelationKey = useMemo(() => !!getFigmaCorrelationKey(), []);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);

    setEmail(e.target.value);
  };

  // show loader
  // make request
  const onContinue = async () => {
    setLoading(true);
    const [request] = fetchEnterpriseConfigByEmailAddress(email);

    try {
      const { data: company } = await request;
      const loginArgs: LoginArgs = {
        connection: company.auth.saml || "Username-Password-Authentication",
        appState: getAppState(location),
        workspaceID: company.workspace_id,
        hasFigmaCorrelationKey,
        enterpriseKey: company.key,
      };

      return loginWithRedirect(loginArgs);
    } catch (e) {
      console.error(e);
      return setError("invalid-domain");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginWrapper
      title="Sign in to Ditto with SAML SSO"
      bodyClassName={style.samlBody}
      floatingMessage={<BackToLogin />}
    >
      <div>
        <h2>Work Email</h2>
        <label>
          <input
            type="text"
            className={classNames({
              [style.other]: true,
              [style.errorInput]: !!error,
            })}
            placeholder="email@company.com"
            value={email}
            onChange={onEmailChange}
          ></input>
        </label>
        {error && (
          <p className={style.errorText}>
            Unable to log in with SAML SSO. Go back and log in with Google or Azure AD, or{" "}
            <a target="_blank" href="mailto:support@dittowords.com">
              contact us
            </a>{" "}
            for help.
          </p>
        )}
        <div className={style.continueButtonContainer}>
          <ButtonPrimary
            text={loading ? "Loading..." : "Continue"}
            onClick={onContinue}
            disabled={Boolean(continueButtonDisabled)}
            loading={loading}
          />
        </div>
      </div>
    </LoginWrapper>
  );
};

export default LoginSaml;
