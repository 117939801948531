import { locationAtom } from "@/stores/ProjectSelection";
import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { forceShowAllTextItemsInBlockAtomFamily, unwrappedProjectAtom } from "./Project";

const SEARCH_PARAM = "search";

export const searchAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "";
    }

    const search = location.searchParams.get(SEARCH_PARAM);
    return search ? search : "";
  },
  async (get, set, newSearch: string) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newSearch.length > 0) {
      newSearchParams.set(SEARCH_PARAM, newSearch);
    } else {
      newSearchParams.delete(SEARCH_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

export const isSearchingAtom = atom((get) => get(searchAtom).length > 0);

// we want an atom that represents all of the active filters on the project page
// if there's already a filter active from the URL params, that filter should be visible in the filter bar
// we should be able to show a filter on the bar without adding it to the URL

export const PROJECT_FILTERS = {
  status: {
    value: "status",
    label: "Status",
  },
  assignee: {
    value: "assignee",
    label: "Assignee",
  },
  tags: {
    value: "tags",
    label: "Tags",
  },
  apiID: {
    value: "apiID",
    label: "Developer ID",
  },
  variant: {
    value: "variant",
    label: "Variant",
  },
  page: {
    value: "page",
    label: "Page",
  },
} as const;

export type FilterKey = keyof typeof PROJECT_FILTERS;

// Atom family for storing the values of the filters in the URL. This lets us abstract away the logic of storing
// and syncing the values of the filters in the URL.
export const selectedFiltersAtomFamily = atomFamily((key: FilterKey) =>
  atomWithURLStorage(key, locationAtom, {
    onSet: (get, set) => {
      const project = get(unwrappedProjectAtom);
      const blockIds = project.blocks.map((block) => block._id);

      // Reset all of the forceShowAllTextItemsInBlock atoms to false, since the filters have changed
      for (const blockId of blockIds) {
        const forceShowAllTextItemsInBlockAtom = forceShowAllTextItemsInBlockAtomFamily(blockId || "");
        const forceShowAllTextItemsInBlock = get(forceShowAllTextItemsInBlockAtom);
        if (forceShowAllTextItemsInBlock) {
          set(forceShowAllTextItemsInBlockAtom, false);
        }
      }
    },
  })
);

// Atom which reduces the selected filters into a list of FilterKey values, and allows filters to be added or removed
export const selectedFiltersListAtom = atom(
  // The getter loops over all our possible filter keys and checks if each one has a value stored in the atom family
  // (i.e., if the key has any value in the URL)
  (get) => {
    const selectedFilterKeys: FilterKey[] = [];
    for (const key of Object.keys(PROJECT_FILTERS) as FilterKey[]) {
      const filterAtom = selectedFiltersAtomFamily(key);
      const filterValue = get(filterAtom);

      if (filterValue !== null && filterValue !== undefined) {
        selectedFilterKeys.push(key);
      }
    }
    return selectedFilterKeys;
  },
  // The setter takes an array of filter keys. We loop over *all* possible filter keys -- if the setter has the key
  // present, we make sure that the atom family at least has a default value. If the setter doesn't have the key
  // present, we remove the key from the atom family (removing it from the URL)
  (get, set, newSelectedFilterKeys: FilterKey[]) => {
    const selectedFilterKeysSet = new Set(newSelectedFilterKeys);
    const allFilterKeys = Object.keys(PROJECT_FILTERS) as FilterKey[];

    for (const key of allFilterKeys) {
      const filterAtom = selectedFiltersAtomFamily(key);

      if (!selectedFilterKeysSet.has(key)) {
        set(filterAtom, null);
      }

      const filterValue = get(filterAtom);
      if (selectedFilterKeysSet.has(key) && (filterValue === null || filterValue === undefined)) {
        set(filterAtom, []);
      }
    }
  }
);

const DESIGN_PREVIEW_PARAM = "designPreview";
export const designPreviewURLAtom = atomWithURLStorage(DESIGN_PREVIEW_PARAM, locationAtom);
export const designPreviewToggledAtom = atom(
  (get) => {
    const urlValue = get(designPreviewURLAtom);
    return urlValue?.[0] === "true";
  },
  (_get, set, newValue: boolean) => {
    if (newValue) {
      set(designPreviewURLAtom, ["true"]);
    } else {
      set(designPreviewURLAtom, null);
    }
  }
);
