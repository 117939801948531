import React, { createContext, useContext, useEffect, useState } from "react";
import { ApiTokenResponse } from "../../../../shared/types/http/workspace";
import http, { API } from "../../../http";

interface CrowdinConnectionContextType {
  newCrowdinToken: string | null;
  currentCrowdinConnection: ApiTokenResponse | null;
  crowdinTokenUsed: boolean;
  createNewCrowdinKey: () => Promise<void>;
  removeCrowdinKey: () => Promise<void>;
  checkForExistingCrowdinToken: () => void;
}

const CrowdinConnectionContext = createContext<CrowdinConnectionContextType | undefined>(undefined);

export const useCrowdinConnection = () => {
  const context = useContext(CrowdinConnectionContext);
  if (!context) {
    throw new Error("useCrowdinConnection must be used within a CrowdinConnectionProvider");
  }
  return context;
};

export function CrowdinConnectionProvider({ children }: { children: React.ReactNode }) {
  const [newCrowdinToken, setNewCrowdinToken] = useState<string | null>(null);
  const [currentCrowdinConnection, setCurrentCrowdinConnection] = useState<ApiTokenResponse | null>(null);

  const crowdinTokenUsed = Boolean(currentCrowdinConnection && currentCrowdinConnection.lastUsedAt);

  const createNewCrowdinKey = async () => {
    const { url, body } = API.workspace.post.apiToken;
    try {
      const response = await http.post(url, body({ name: "crowdin", connection: "crowdin" }));
      setNewCrowdinToken(response.data.token);
      setCurrentCrowdinConnection(response.data);
    } catch (error) {
      console.error("Unable to create Crowdin key." + error);
    }
  };

  const removeCrowdinKey = async () => {
    if (!currentCrowdinConnection) {
      console.error("No Crowdin key to remove.");
      return;
    }

    const { url } = API.workspace.delete.apiToken;
    await http.delete(url(currentCrowdinConnection._id));
    setNewCrowdinToken(null);
    setCurrentCrowdinConnection(null);
  };

  const checkForExistingCrowdinToken = () => {
    const { url } = API.workspace.get.connectionApiTokens;
    http
      .get(url)
      .then((response) => {
        const crowdinKeys: ApiTokenResponse[] = response.data.filter(function filterForCrowdinToken(t) {
          return t.connection === "crowdin";
        });
        if (crowdinKeys.length === 0) {
          setCurrentCrowdinConnection(null);
          return;
        }
        setCurrentCrowdinConnection(crowdinKeys[0]);
      })
      .catch((error) => {
        console.error("Something went wrong, try again later." + error);
      });
  };

  useEffect(checkForExistingCrowdinToken, []);

  const value = {
    newCrowdinToken,
    currentCrowdinConnection,
    crowdinTokenUsed,
    createNewCrowdinKey,
    removeCrowdinKey,
    checkForExistingCrowdinToken,
  };

  return <CrowdinConnectionContext.Provider value={value}>{children}</CrowdinConnectionContext.Provider>;
}
