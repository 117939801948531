import * as SegmentEvents from "@shared/segment-event-names";
// TODO: Rename variables to match JS standard.
import NotifIndicator from "@/components/NotifIndicator";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { NotificationContext } from "@/store/notificationContext";
import { default as Close, default as CloseIcon } from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ReorderIcon from "@mui/icons-material/Reorder";
import Tooltip from "@shared/frontend/Tooltip";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { getTextItemChanged } from "@shared/lib/text";
import logger from "@shared/utils/logger";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import AutoAwesomeIcon from "../../../../shared/frontend/AutoAwesomeIcon";
import ComponentSuggestions from "../../../components/ComponentSuggestions";
import ErrorBoundary from "../../../components/ErrorBoundary";
import SetupSuggestions from "../../../components/SetupSuggestions";
import CommentEditorEditPanel from "../../../components/comment-editor/CommentEditorEditPanel";
import CompVariants from "../../../components/comp-variants";
import CompHistory from "../../../components/comphistory/comphistory";
import DocHistory from "../../../components/dochistory/dochistory";
import EditComp from "../../../components/editcomp/editcomp";
import EditMultiComp from "../../../components/editmulticomp/editmulticomp";
import EditWSComp from "../../../components/editwscomp/editwscomp";
import EnlargeImageModal from "../../../components/enlarge-image-modal/enlarge-image-modal";
import FigmaFramePreview from "../../../components/figma-frame-preview/figma-frame-preview";
import LockedFolderIcon from "../../../components/icons/LockedFolderIcon";
import { GrayWarning } from "../../../components/shared/GrayWarning";
import { PANELS, routes } from "../../../defs";
import useCompSuggestionNudge from "../../../hooks/useCompSuggestionNudgeWebapp";
import useSegment from "../../../hooks/useSegment";
import http, { API } from "../../../http";
import { UnsavedChangesContext } from "../../../store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "../../../store/webappPermissionContext";
import { GROUP_REDUCER_TYPES } from "../state/groupStateActions";
import { useProjectContext } from "../state/useProjectState";
import { ComponentSuggestionsWebsocketListener } from "./ComponentSuggestionListener";
import CreateTextItem from "./CreateTextItem/CreateTextItem";
import style from "./ProjectDetail.module.css";
import ProjectLockedDescription from "./ProjectLockedDescription";
import { ProjectSummarySection } from "./ProjectSummarySection";

const EditPanelTab = ({ testid, selected, onClick, text, children }) => (
  <div
    data-testid={testid}
    className={classnames({
      [style.option]: true,
      [style.selected]: selected,
    })}
    onClick={onClick}
  >
    {text}
    {children && <>{children}</>}
  </div>
);

const ProjectDetail = (
  {
    groups = {},
    currentComp,
    selectedId,
    selectedVariant,
    handleSelectFrameVariant,
    prev_resync_time,
    curr_resync_time,
    doc_ID,
    doc_name,
    folder,
    doc_date_time_created,
    devModeEnabled,
    handleDocUpdate,
    multiOn,
    multiSelectedIds,
    multiSelectedComps,
    multiSelectedVariants,
    isMultiSelectOnSameFrame,
    setMultiSelected,
    setMultiSelectedComps,
    setSingleSelected,
    frameInfo,
    forceShowToast,
    updateDocHistory,
    setUpdateDocHistory,
    showHighlightNew,
    setShowHighlightNew,
    hidingAvailable,
    panelState,
    setPanelState,
    commentState,
    setCommentState,
    resyncLoading,
    tagSuggestions,
    getWorkspaceTags,
    unselectAll,
    setShowMultiAttachCompToast,
    workspaceUsers,
    framePreviewsMap,
    pollingForPreviewUpdates,
    handleOpenFramePage,
    handleOpenBlockPage,
    frameVariants,
    workspaceVariants,
    handleSuggestionSelectComp,
    suggestedCompId,
    setCustomToast,
    multiSelectGroupIds,
    createTextItemPanelStatus,
    setCreateTextItemPanelStatus,
    setSelectedComp,
    projectBranchInfo,
    selectAllCompsInGroup,
    setSyncSettingsModalVisible,
    refetchMultiComps,
  } = {
    groups: {},
    doc_name: "Untitled",
    multiOn: false,
    multiSelectedIds: [],
    isMultiSelectOnSameFrame: false,
    prev_resync_time: undefined,
    selectedId: null,
  }
) => {
  const segment = useSegment();
  const {
    doc: [doc],
    groupState: [, dispatch],
    selectedDraftGroupId: [selectedDraftGroupId],
    compHistory: [compHistory, setCompHistory],
    compHistoryLoading: [compHistoryLoading, setCompHistoryLoading],
    docHistory: [docHistory, setDocHistory],
    docHistoryLoading: [docHistoryLoading, setDocHistoryLoading],
    docHistoryIndex: [docHistoryIndex, setDocHistoryIndex],
    allDocHistoryFetched: [allDocHistFetched, setAllDocHistFetched],
    resetDocHistory: [resetDocHistory, setResetDocHistory],
    fetchNewHistory,
    fetchCommentHistory,
    formatCommentThreads,
    commentHistory: [commentHistory, setCommentHistory],
    commentHistoryLoading: [commentHistoryLoading, setCommentHistoryLoading],
    commentHistoryIndex: [commentHistoryIndex, setCommentHistoryIndex],
    allCommentHistoryFetched: [allCommentHistFetched, setAllCommentHistFetched],
    resetCommentHistory: [resetCommentHistory, setResetCommentHistory],
    selectedComp: [selectedComp],
    selectedGroupId: [selectedGroupId],
    quickReplyCommentState: [quickReplyCommentState, setQuickReplyCommentState],
    scrollToId: [, setScrollToId],
    activityFilter: [activityFilter, setActivityFilter],
    findTextItemInProject,
    setPageByTextItemId,
    handleScrollToTextItem,
    setIsShowingSetupSuggestionsFlow,
    isShowingSetupSuggestionsFlow,
    setSetupSuggestions,
    setupSuggestions,
    groupPreviewStateById: [groupPreviewStateById],
    setupSuggestionsForAllGroups,
    projectSummary: [projectSummary, refreshProjectSummary],
  } = useProjectContext();
  const { fetchNotifications } = useContext(NotificationContext);
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const history = useHistory();

  const { user } = useAuthenticatedAuth();

  const [comp, setComp] = useState(null);

  const compSuggestionNudge = useCompSuggestionNudge(doc_ID);

  const [origComp, setOrigComp] = useState(null);
  const [duplicateComps, setDuplicateComps] = useState([]);
  const [duplicatesContainsVariants, setDuplicatesContainsVariants] = useState(false);
  const [commentFilter, setCommentFilter] = useState("All");
  const [hasDocNotifs, setHasDocNotifs] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [enlargeImgOpen, setEnlargeImgOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgError, setImgError] = useState({ exists: false, status: null });
  const [showHighlight, setShowHighlight] = useState(true);
  const [canHighlight, setCanHighlight] = useState(false);
  const [suggestionsCount, setSuggestionCount] = useState(0);
  const [showSuggestionsBanner, setShowSuggestionsBanner] = useState(false);
  const docHistoryScrollTopRef = useRef(0);
  const commentHistoryScrollTopRef = useRef(0);
  const componentSuggestionsJobId = useRef(null);
  const multiSelectedCompsFigmaIds = useMemo(
    () => multiSelectedComps.map((comp) => comp.figma_node_ID),
    [multiSelectedComps]
  );

  const isMergedBranch = useMemo(() => {
    const isLocked = doc.is_locked;
    const isBranch = Boolean(doc?.integrations?.figma?.branch_id);
    return isLocked && isBranch;
  }, [doc]);

  const isLockedProject = useMemo(() => {
    const isLocked = doc.is_locked;
    return isLocked;
  }, [doc]);

  const shouldShowLockedDescription = useMemo(() => {
    return doc?.is_locked && projectBranchInfo && !isMergedBranch;
  }, [doc.is_locked, projectBranchInfo, isMergedBranch]);

  const allCompsInFrameSelected = useMemo(() => {
    const selectedGroup = groups.find((group) => group._id === selectedGroupId);
    // Check if all comps are selected
    if (!selectedGroup) return false;
    const comps = [
      ...selectedGroup.comps.flatMap((comp) => comp._id),
      ...selectedGroup.blocks.flatMap((block) => block.comps.flatMap((comp) => comp._id)),
    ];
    return comps.every((comp) => multiSelectedIds.includes(comp));
  }, [groups, selectedGroupId, multiSelectedIds, isMultiSelectOnSameFrame]);

  const DOC_HISTORY_FETCH_SIZE = 20;

  const onCopySetupClicked = () => {
    segment.track({ event: SegmentEvents.COPY_SETUP_FROM_ANOTHER_GROUP });
    setSetupSuggestions({
      groupId: selectedGroupId || "",
      baseGroupId: "",
      fileId: "",
      blockSuggestions: [],
      hideSuggestions: [],
      hideSuggestionsSelected: true,
      wsComponentSuggestions: [],
    });
    setIsShowingSetupSuggestionsFlow(true);
    unselectAll();
  };

  // progressive fetching of doc history
  useEffect(() => {
    fetchMoreDocHistory();
    fetchMoreCommentHistory();
    fetchDocNotifications();
  }, []);

  useEffect(() => {
    if (isEditEnabled) {
      fetchSuggestions();
    }
  }, [isEditEnabled]);

  useEffect(() => {
    if (resetDocHistory) {
      fetchMoreDocHistory();
      setResetDocHistory(false);
    }
  }, [resetDocHistory]);

  useEffect(() => {
    if (resetCommentHistory) {
      fetchMoreCommentHistory();
      setResetCommentHistory(false);
    }
  }, [resetCommentHistory]);

  /**
   * Switch back to the edit panel when the base variant
   * of a text item is selected
   */
  useEffect(() => {
    if (panelState === PANELS.doc.variants && selectedVariant && selectedVariant.id !== "__base__") {
      checkDetailPanelChanges(() => {
        setPanelState(PANELS.doc.edit);
      });
    }
  }, [selectedVariant]);

  const previousSelectedTextItemData = useRef({
    id: selectedComp?._id || null,
    linkingEnabled: false,
  });

  useEffect(() => {
    const currentSelectedId = selectedComp?._id || null;

    let currentGroup, currentTextItem;
    for (const group of groups) {
      for (const textItem of group.comps) {
        if (textItem._id.toString() === currentSelectedId) {
          currentGroup = group;
          currentTextItem = textItem;
          break;
        }
      }
      for (const block of group.blocks) {
        // For some reason it is possible for a block to be null
        // https://making-ditto.slack.com/archives/C0328T320TE/p1683814961908279
        if (!block) continue;

        for (const textItem of block.comps) {
          if (textItem._id.toString() === currentSelectedId) {
            currentGroup = group;
            currentTextItem = textItem;
            break;
          }
        }
      }
    }

    const currentSelectedLinkingEnabled = currentGroup?.linking_enabled || false;

    const previousSelectedId = previousSelectedTextItemData.current?.id || null;
    const previousSelectedLinkingEnabled = Boolean(previousSelectedTextItemData.current?.linkingEnabled);

    // Cache selected text item data so that it can be compared against
    // whatever the data changes to when this effect gets triggered again.
    previousSelectedTextItemData.current = {
      id: currentSelectedId || null,
      linkingEnabled: Boolean(currentGroup?.linking_enabled),
    };

    // This avoids a bug where the edit panel displays an empty value
    // when the user selects a text item inside of an unlinkable group
    // and then clicks "Enable Linking" on that group.
    if (currentTextItem) {
      setComp(currentTextItem);
      setOrigComp(currentTextItem);
    }

    if (!currentSelectedId) {
      return;
    }

    if (commentState.isSelected) {
      setCommentState({ ...commentState, isSelected: false });
      // A commentThread is selected so to show it we have to show the Activity panel
      setPanelState(PANELS.doc.activity);
      return;
    }

    const getNewPanelState = (previousState) => {
      if (!currentGroup) {
        console.warn("Failed to find group for selected text item");
        return previousState;
      }

      const newTextItemWasSelected = currentSelectedId && currentSelectedId !== previousSelectedId;

      if (newTextItemWasSelected) {
        const textItemInLinkableGroup = currentGroup.linking_enabled;
        return textItemInLinkableGroup ? PANELS.doc.edit : PANELS.doc.activity;
      }

      const selectedTextItemHadLinkingEnabled =
        currentSelectedId === previousSelectedId &&
        previousSelectedLinkingEnabled === false &&
        currentSelectedLinkingEnabled === true;

      if (selectedTextItemHadLinkingEnabled) {
        return PANELS.doc.edit;
      }

      return previousState;
    };

    setPanelState(getNewPanelState);
  }, [selectedComp, setComp, setOrigComp, groups]);

  // I want to rewrite everything T.T
  // Have to do this because the useEffects below are saving
  // stale references of selectedId which messes up
  // fetchCompInfo() and updateHistoryForCurrentlySelectedTextItem()
  const latestSelectedId = useRef(selectedId);

  useEffect(
    function trackSelectedId() {
      latestSelectedId.current = selectedId;
    },
    [selectedId]
  );

  useEffect(() => {
    if (resyncLoading || !selectedId) {
      return;
    }

    fetchCompInfo();
  }, [resyncLoading, latestSelectedId.current, groups]);

  useEffect(() => {
    if (updateDocHistory) {
      updateHistory(true, true);
      setUpdateDocHistory(false);

      fetchCompInfo();
      fetchDocNotifications();
    }
  }, [updateDocHistory, latestSelectedId.current]);

  useEffect(() => {
    if (panelState !== PANELS.doc.suggestions && activityFilter === "Components") {
      setActivityFilter("Activity");
    }
    if (panelState === PANELS.doc.suggestions) {
      setHasSuggestions(false);
      setShowSuggestionsBanner(false);
    }
  }, [panelState]);

  const onDismissSuggestionBanner = () => {
    setShowSuggestionsBanner(false);
    localStorage.setItem("showSuggestionBanner", "false");
  };

  const onShowSuggestions = () => {
    segment.track({
      event: "Show Suggestions Banner Clicked",
      properties: {
        numSuggestions: suggestionsCount,
      },
    });

    setTimeout(() => {
      onDismissSuggestionBanner();
      setActivityFilter("Components");
      setPanelState(PANELS.doc.suggestions);
    }, 200);
  };
  const loadMoreDocHistory = () => {
    fetchMoreDocHistory();
  };

  const loadMoreCommentHistory = () => {
    fetchMoreCommentHistory();
  };

  const fetchMoreDocHistory = async () => {
    if (allDocHistFetched) {
      return;
    }

    const commentThreads = await fetchCommentHistory();
    if (!commentThreads) {
      return;
    }

    const commentThreadsUnresolved = commentThreads.filter((thread) => !thread.is_resolved);

    const skip = docHistoryIndex * DOC_HISTORY_FETCH_SIZE;
    const limit = DOC_HISTORY_FETCH_SIZE;
    const { url } = API.changes.get.docPage;

    try {
      const {
        data: { info },
      } = await http.get(url(doc_ID, skip, limit));
      const newHistoryItems = formatCommentThreads(info, commentThreadsUnresolved);

      // concat new history items onto existing
      if (docHistoryIndex === 0) {
        setDocHistory(newHistoryItems);
      } else {
        setDocHistory(docHistory.concat(newHistoryItems));
      }
      setDocHistoryLoading(false);
      if (info === 0 || info.length < DOC_HISTORY_FETCH_SIZE) {
        setAllDocHistFetched(true);
      } else {
        setDocHistoryIndex(docHistoryIndex + 1);
      }
    } catch (e) {
      setDocHistoryLoading(false);
      console.error("Error fetching doc history in docdetail.jsx: ", JSON.stringify(e.response));
    }
  };

  const fetchMoreCommentHistory = async () => {
    if (allCommentHistFetched) {
      return;
    }

    const commentThreads = await fetchCommentHistory();

    const skip = commentHistoryIndex * DOC_HISTORY_FETCH_SIZE;
    const limit = DOC_HISTORY_FETCH_SIZE;
    const { url } = API.changes.get.docCommentsPage;

    try {
      const { data: comments } = await http.get(url(doc_ID, skip, limit));
      const newHistoryItems = formatCommentThreads(comments, commentThreads);

      // concat new history items onto existing
      if (commentHistoryIndex === 0) {
        setCommentHistory(newHistoryItems);
      } else {
        setCommentHistory(commentHistory.concat(newHistoryItems));
      }

      setCommentHistoryLoading(false);
      if (comments.length < DOC_HISTORY_FETCH_SIZE) {
        setAllCommentHistFetched(true);
      } else {
        setCommentHistoryIndex(commentHistoryIndex + 1);
      }
    } catch (e) {
      setCommentHistoryLoading(false);
      console.error("Error fetching comment history in ProjectDetail.jsx: ", JSON.stringify(e.response));
    }
  };

  const refreshCommentHistory = async () => {
    try {
      // get the comment threads associated w this doc
      const commentThreads = await fetchCommentHistory();

      // get the comment change items for this doc
      const { url } = API.changes.get.docCommentsPage;
      const { data: comments } = await http.get(url(doc_ID, 0, DOC_HISTORY_FETCH_SIZE));

      // format them together
      const newHistoryItems = formatCommentThreads(comments, commentThreads);

      setCommentHistory(newHistoryItems || []);
      setCommentHistoryLoading(false);
      setAllCommentHistFetched(false);
      setCommentHistoryIndex(0);
      setResetCommentHistory(false);
    } catch (e) {
      setCommentHistoryLoading(false);
      console.error("Error fetching history in docdetail.jsx: ", e.message);
    }
  };

  const lookupOrRefetchSelectedComponent = async () => {
    // If the selected id hasn't changed, then we want to reload
    // the data from the backend to refresh the current selected component -
    // this is specifically used when creating and attachin a new component
    // inline
    if ((comp?._id === selectedId && origComp?._id === selectedId) || !currentComp) {
      const { url } = API.comp.get.info;
      const response = await http.get(url(selectedId));

      return response.data;
    }

    return currentComp;
  };

  // find all comp instances with duplicate text in the project
  const findDuplicates = (baseComp) => {
    try {
      const dupeCompIds = [];
      let dupesContainVariants = false;

      groups.forEach((group) => {
        (group?.blocks || []).forEach((block) => {
          (block?.comps || [])
            .filter((comp) => !comp.ws_comp)
            .forEach((comp) => {
              const isValidDuplicate = comp.text === baseComp.text && !comp.is_hidden;
              if (isValidDuplicate) {
                dupeCompIds.push(comp._id);
                if (comp.variants.length > 0) {
                  dupesContainVariants = true;
                }
              }
            });
        });
        (group?.comps || [])
          .filter((comp) => !comp.ws_comp)
          .forEach((comp) => {
            const isValidDuplicate = comp.text === baseComp.text && !comp.is_hidden;
            if (isValidDuplicate) {
              dupeCompIds.push(comp._id);
              if (comp.variants.length > 0) {
                dupesContainVariants = true;
              }
            }
          });
      });
      setDuplicateComps(dupeCompIds);
      setDuplicatesContainsVariants(dupesContainVariants);
    } catch (error) {
      console.error("error finding duplicates", error);
    }
  };

  const fetchCompInfo = async (shouldUpdateCompHistory = true) => {
    if (!latestSelectedId.current) {
      return;
    }

    try {
      const compRefreshed = await lookupOrRefetchSelectedComponent();
      const compChanged = getTextItemChanged(origComp, compRefreshed);

      if (compChanged) {
        const compToSet = { ...compRefreshed, _meta: origComp._meta };
        setComp(compToSet);
        setOrigComp(compToSet);
        dispatch({
          type: GROUP_REDUCER_TYPES.UPDATE_COMPONENTS_IN_GROUPS,
          comps: [compToSet],
        });
      }

      findDuplicates(compRefreshed);

      if (shouldUpdateCompHistory) {
        updateHistoryForCurrentlySelectedTextItem();
      }
    } catch (e) {
      console.error("Error fetching comp info in docdetail.jsx: ", e.message);
    }
  };

  const updateHistoryForCurrentlySelectedTextItem = async () => {
    if (!latestSelectedId.current) {
      return;
    }

    try {
      const { url } = API.comp.get.historyAndComments;
      const { data: compHistory } = await http.get(url(latestSelectedId.current));

      setCompHistory(compHistory || []);
      setCompHistoryLoading(false);
    } catch (e) {
      setCompHistoryLoading(false);
      console.error("Error fetching history in docdetail.jsx: ", e.message);
    }
  };

  const fetchSuggestions = async () => {
    try {
      const { url } = API.jobs.post.componentSuggestions;
      const { data } = await http.post(url, {
        projectId: doc_ID,
      });

      componentSuggestionsJobId.current = data.id;
    } catch (err) {
      logger.error("Error creating component suggestions job", {}, err);
    }
  };

  const fetchDocNotifications = async () => {
    const { url } = API.user.get.notifsHasUnread;
    const { data: docNotifs } = await http.get(url(doc_ID));

    if (docNotifs.length > 0) {
      setHasDocNotifs(true);
    } else {
      setHasDocNotifs(false);
    }
  };

  const updateHistory = async (updateCompHist = true, updateDocHist = true) => {
    refreshProjectSummary();

    if (updateCompHist) {
      updateHistoryForCurrentlySelectedTextItem();
    }
    if (updateDocHist) {
      fetchNewHistory();
    }
    refreshCommentHistory();
  };

  const hideImageModal = () => {
    setEnlargeImgOpen(false);
  };

  const handleCommentChangeClick = (comp_id, comment_thread_id, showHistoryPanel = true) => {
    checkDetailPanelChanges(() => {
      setQuickReplyCommentState({
        enabled: true,
        initialThreadId: comment_thread_id,
        goBackState: {
          activityFilter,
        },
      });

      if (showHistoryPanel) {
        setCommentState({ isSelected: true, thread_id: comment_thread_id });
        setSingleSelected(comp_id, true, false);

        const newPanelState = selectedDraftGroupId ? PANELS.doc.activity : PANELS.doc.edit;

        setPanelState(newPanelState);
      } else {
        setCommentState({ isSelected: true, thread_id: comment_thread_id });
        setSingleSelected(comp_id, false, false);
        setPanelState(PANELS.doc.inline_reply);
      }
    });
  };

  //returns if user is participating in commentThread
  const isCommentYours = (commentThread) => {
    // for backwards compat: old thread that doesnt have subscribed_users populated
    if (!commentThread.subscribed_users || commentThread.subscribed_users.length === 0) {
      for (const comment of commentThread.comments) {
        if (comment.user_id === user.sub) return true;
      }
      return false;
    }
    if (commentThread.subscribed_users.includes(user.sub)) return true;
    return false;
  };

  const goToFolder = () => history.push(routes.nonNavRoutes.folder.getPath(folder._id));

  const appliedVariant = useMemo(() => {
    if (!frameInfo?.appliedVariantId) return null;
    const name = workspaceVariants[frameInfo?.appliedVariantId];
    if (name)
      return {
        name,
        exists: true,
      };
    return {
      exists: false,
    };
  }, [frameInfo, workspaceVariants]);

  const hasTextItemSelection = Boolean(selectedId || multiSelectedIds.length > 0);

  const showEditingMultipleText =
    hasTextItemSelection && multiOn && panelState !== PANELS.doc.inline_reply && !selectedDraftGroupId;

  const showTabs =
    (hasTextItemSelection && !multiOn && panelState !== PANELS.doc.inline_reply) ||
    (multiSelectedIds.length > 0 && selectedDraftGroupId);

  const showEditPanelTab = !selectedDraftGroupId;

  const showProjectDetailsPanel =
    (!hasTextItemSelection && panelState !== PANELS.doc.suggestions) || panelState === PANELS.doc.inline_reply;

  const showActivity = Boolean(
    (!selectedId && multiSelectedIds.length == 0 && panelState !== PANELS.doc.suggestions) ||
      panelState === "INLINE-REPLY" ||
      (multiSelectedIds.length > 0 && selectedDraftGroupId)
  );

  const activityTabText = showActivity ? "Project Activity" : "Activity";

  useEffect(() => {
    const showingProjectActivity = showActivity && !selectedId;
    if (!showingProjectActivity) {
      return;
    }

    updateHistory(false, true);
  }, [showActivity, selectedId]);

  const showSingleEditPanel = Boolean(
    origComp && selectedId && panelState === PANELS.doc.edit && !multiOn && !selectedDraftGroupId
  );

  const showMultiEditPanel = Boolean(
    multiSelectedIds.length > 0 && panelState == PANELS.doc.edit && multiOn && !selectedDraftGroupId
  );

  const projectHasLinkableGroups = useMemo(() => {
    return !isMergedBranch && groups.some((group) => group.linking_enabled);
  }, [groups, isMergedBranch]);

  const getShowTextItemHistoryPanel = (textItem) =>
    Boolean(textItem && selectedId && panelState == PANELS.doc.activity);

  const getShowTextItemVariantsPanel = (textItem) =>
    Boolean(textItem && selectedId && panelState == PANELS.doc.variants && !selectedDraftGroupId);

  const getShowVariantsTab = (textItem) => {
    const variantValueMap = frameVariants[selectedGroupId]?.reduce((acc, v) => ({ ...acc, [v.id]: true }), {});

    if (!variantValueMap) {
      return false;
    }

    const textItemAndGroupHaveSomeVariant = textItem?.variants?.some((v) => variantValueMap[v.variantId]);

    const baseVariantIsSelected = (selectedVariant && selectedVariant.id === "__base__") || !selectedVariant;

    return textItemAndGroupHaveSomeVariant && baseVariantIsSelected && !selectedDraftGroupId;
  };

  const showFigmaFramePreview = Boolean(
    (currentComp?.figma_node_ID || multiSelectedComps.some((textItem) => textItem?.figma_node_ID)) &&
      groupPreviewStateById.get(selectedGroupId) !== "DESIGN"
  );

  useEffect(() => {
    // any unsaved changes should already be checked by whatever is
    // modifying selectedId
    if ((selectedId || multiSelectedIds.length > 0) && selectedDraftGroupId) {
      setPanelState(PANELS.doc.activity);
    }
  }, [selectedDraftGroupId, selectedId, multiSelectedIds]);

  const onSaveComment = async (commentText, mentionedUsers, resetEditorState) => {
    const { url, body } = API.comments.post.postCompThread;
    let requestBody = body({
      doc_id: doc_ID,
      doc_name: doc_name,
      first_comment: commentText,
      figma_node_id: origComp.figma_node_ID,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
      comp_id: selectedId,
      from: "web_app",
    });
    await http.post(url, requestBody);
    resetEditorState();
  };

  // This function is solely in charge of updating the text of the text item and
  // updating the text item in state. Resolution of the comment thread is handled
  // internally by the <CommentThread /> component.
  const onResolveSuggestion = async (isAccepted, _threadId, suggestionData, { compId }) => {
    if (!isAccepted) {
      return;
    }

    // A unique paradigm on the project page is the fact that while editing a component-attached text item,
    // if you leave a comment, the comment is left on the text item itself, not the component. For this reason,
    // when a suggestion for a component-attached text item is accepted from the project page, the suggestion
    // commentthread will only have data for the text item, not the component.
    //
    // When a suggestion is accepted, we need to find the text item in state so that we can check whether or not
    // it has a component attached. If it has a component attached, then a component update should be performed
    // en lieu of a text item update.
    const textItem = findTextItemInProject(compId);
    if (!textItem) {
      return;
    }

    if (textItem.ws_comp && textItem.ws_comp.type !== "template") {
      const { url, body } = API.ws_comp.post.edit;
      const { data: response } = await http.post(
        url,
        body({
          ws_comp_id: textItem.ws_comp._id,
          doc_id: null,
          newCompInfo: {
            text: suggestionData.text_after,
            rich_text: suggestionData.rich_text_after,
            variables: suggestionData.variables,
          },
          from: "web_app",
        })
      );
      handleDocUpdate(response.instances);
    } else {
      if (textItem.ws_comp?.type === "template") {
        await API.ws_comp.actions.detach(compId, textItem.ws_comp._id);
      }

      const { url, body } = API.comp.post.update;
      await http.post(
        url(compId),
        body({
          type: "edit-from-suggestion",
          newCompInfo: {
            text: suggestionData.text_after,
            rich_text: suggestionData.rich_text_after,
            variables: suggestionData.variables || [],
          },
          originalText: textItem.text,
          editAll: false,
          changeInfo: {
            doc_name: doc_name,
            doc_id: doc_ID,
          },
        })
      );
      fetchCompInfo();
    }
  };

  const onSaveSuggestion = async ({ text, richText, variables }) => {
    const { url, body } = API.comments.post.postCompThread;
    await http.post(
      url,
      body({
        doc_id: doc_ID,
        doc_name,
        suggestion: {
          text_before: origComp.text,
          text_after: text,
          rich_text_before: origComp.rich_text,
          rich_text_after: richText,
          variables,
        },
        figma_node_id: origComp.figma_node_ID,
        mentionedUserIds: [],
        comp_id: selectedId,
        from: "web_app",
      })
    );
  };

  const unresolvedCommentThreads = useMemo(
    () => (compHistory || []).filter((ch) => ch.comments && !ch.is_resolved),
    [compHistory]
  );

  // This is necessary to avoid losing state when the edit panel unmounts
  // when switching between editcomp / editwscomp or switching between
  // tabs.
  const controlledQuickReplyState = useState({ loaded: false });
  const [, setControlledQuickReplyState] = controlledQuickReplyState;
  useEffect(() => {
    setControlledQuickReplyState({ loaded: false });
  }, [quickReplyCommentState, setControlledQuickReplyState]);

  const commentEditor = (
    <CommentEditorEditPanel
      doc_name={doc_name}
      className={style.commentEditorEditPanel}
      commentThreadUI={{
        before: (commentThread) => {
          const isSuggestion = Boolean(commentThread.suggestion);

          const isForComponent = !!selectedComp.ws_comp;
          const isForTemplateComponent = selectedComp?.ws_comp?.type === "template";

          const isResolved = commentThread.is_resolved;
          const showWarning = isSuggestion && isForComponent && !isResolved;

          return (
            <>
              {showWarning && (
                <GrayWarning className={style.componentSuggestionWarning}>
                  {isForTemplateComponent
                    ? "Accepting a suggestion for a template component will detach it."
                    : "Accepting a suggestion for a component will also sync to all of the component's instances."}
                </GrayWarning>
              )}
            </>
          );
        },
      }}
      quickReplyMode={quickReplyCommentState}
      quickReplyState={controlledQuickReplyState}
      onQuickReplyNavigation={(commentThread) => {
        const textItem = findTextItemInProject(commentThread.comp_id);
        if (!textItem) {
          return;
        }
        setSelectedComp(textItem);
        setPageByTextItemId(commentThread.comp_id);
        handleScrollToTextItem(commentThread.comp_id);
      }}
      onQuickReplyBackArrowClick={(goBackState) => {
        unselectAll();
        setQuickReplyCommentState({ enabled: false });
        setPanelState(null);
        if (goBackState?.activityFilter && setActivityFilter) {
          setActivityFilter(goBackState.activityFilter);
        }
      }}
      onSeeAllActivity={() => setPanelState(PANELS.doc.activity)}
      latestCommentThread={unresolvedCommentThreads[0]}
      loadCommentThreads={async (selectedThreadId) => {
        const { url } = API.comments.get.unresolvedProjectComments;
        const { data: commentThreads } = await http.get(url(doc_ID), {
          params: { selectedThreadId },
        });
        return commentThreads;
      }}
      commentThreadFunctions={{
        forceShowToast,
        handleHistoryUpdate: updateHistory,
        handleInlineReplyClick: (_threadId) => null,
        handleScrollTo: (_textItemId) => null,
        handleDocUpdateParent: () => null,
        handleCommentChangeClick: () => null,
        updateCommentThread: () => null,
        fetchCompInfo: () => null,
        onAcceptSuggestion: (...args) => onResolveSuggestion(true, ...args),
        onRejectSuggestion: (...args) => onResolveSuggestion(false, ...args),
      }}
      commentEditor={{
        placeholderText: "Leave a comment or @mention",
        saveContentCallback: onSaveComment,
      }}
      onExitQuickReplyMode={() => setQuickReplyCommentState({ enabled: false })}
      possibleMentions={workspaceUsers}
      isCommentingDisabled={isMergedBranch}
    />
  );

  // determine which type of doc history we should be loading in
  // TODO: Extract this into a hook (or a few hooks)
  let docHistoryState = useMemo(() => {
    let state = {};

    if (activityFilter === "Comments") {
      const getCommentChangeItems = () => {
        if (commentFilter === "Only Yours") {
          return commentHistory.filter(
            (changeItem) =>
              changeItem.entry_type === "inline-comment" &&
              isCommentYours(changeItem.comment_thread) &&
              !changeItem.comment_thread.is_resolved
          );
        }
        if (commentFilter === "Resolved") {
          return commentHistory.filter(
            (changeItem) =>
              changeItem.entry_type === "inline-comment" &&
              changeItem.comment_thread &&
              changeItem.comment_thread.is_resolved
          );
        }

        return commentHistory.filter(
          (changeItem) => changeItem.entry_type === "inline-comment" && !changeItem.comment_thread.is_resolved
        );
      };
      state.historyItems = getCommentChangeItems();

      state.loadHistory = loadMoreCommentHistory;
      state.index = commentHistoryIndex;
      state.allFetched = allCommentHistFetched;
      state.reset = resetCommentHistory;
      state.isLoading = commentHistoryLoading;
      state.emptyText = "No comments.";
    } else {
      state.loadHistory = loadMoreDocHistory;
      state.index = docHistoryIndex;
      state.allFetched = allDocHistFetched;
      state.reset = resetDocHistory;
      state.isLoading = docHistoryLoading;
      state.historyItems = docHistory;
      state.emptyText = "No changes.";
    }

    return state;
  }, [
    activityFilter,
    commentFilter,
    docHistory,
    docHistoryLoading,
    docHistoryIndex,
    allDocHistFetched,
    resetDocHistory,
    commentHistory,
    commentHistoryLoading,
    commentHistoryIndex,
    allCommentHistFetched,
    resetCommentHistory,
  ]);

  const showBaseGroupSelect = useMemo(
    () => !setupSuggestions || (setupSuggestions && setupSuggestions.baseGroupId !== undefined),
    [setupSuggestions]
  );

  const setupSuggestionsCount = useMemo(() => {
    if (!setupSuggestionsForAllGroups || !setupSuggestionsForAllGroups[selectedGroupId]) {
      return 0;
    }

    const suggestionsForGroup = setupSuggestionsForAllGroups[selectedGroupId];

    return (
      Object.values(suggestionsForGroup.blockSuggestions).reduce((acc, cur) => {
        return acc + cur.textItems.length;
      }, 0) +
      suggestionsForGroup.hideSuggestions.length +
      suggestionsForGroup.wsComponentSuggestions.length
    );
  }, [setupSuggestionsForAllGroups, selectedGroupId]);

  if (createTextItemPanelStatus.show) {
    return (
      <div className={style.sidebar}>
        <CreateTextItem
          tagSuggestions={tagSuggestions}
          projectId={doc_ID}
          showToast={forceShowToast}
          groupId={createTextItemPanelStatus.groupId}
          setCreateTextItemPanelStatus={setCreateTextItemPanelStatus}
          setUpdateDocHistory={setUpdateDocHistory}
          setSelectedComp={setSelectedComp}
        />
      </div>
    );
  }

  const numComments = unresolvedCommentThreads?.reduce((acc, thread) => acc + thread.comments?.length || 0, 0);

  if (isShowingSetupSuggestionsFlow) {
    return (
      <div className={style.sidebar}>
        <div className={style.titleSection}>
          <div className={classnames(style.option, style.selected)}>
            {showBaseGroupSelect ? (
              <>
                <ExitToAppIcon className={style.icon} /> Copy Setup from Group
              </>
            ) : (
              <>
                <AutoAwesomeIcon className={style.icon} />
                <span>Reviewing {setupSuggestionsCount} Setup Suggestions</span>
                <Close
                  className={style.setupSuggestionsClose}
                  onClick={() => {
                    // Reset setup suggestions
                    setIsShowingSetupSuggestionsFlow(false);
                    setSetupSuggestions({
                      groupId: "",
                      projectId: "",
                      blockSuggestions: {},
                      hideSuggestions: [],
                      hideSuggestionsSelected: true,
                      wsComponentSuggestions: [],
                    });
                    segment.track({ event: "Post-import suggestion - Cancel" });
                  }}
                />
              </>
            )}
          </div>
        </div>
        <SetupSuggestions
          selectAllCompsInGroup={selectAllCompsInGroup}
          originalGroupId={selectedGroupId}
        ></SetupSuggestions>
        <FigmaFramePreview
          setImgError={setImgError}
          imgError={imgError}
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
          imageInfo={framePreviewsMap[frameInfo.frameId] || null}
          setEnlargeImgOpen={setEnlargeImgOpen}
          selectedFigmaIds={comp && comp.figma_node_ID ? [comp.figma_node_ID] : []}
          showHighlight={showHighlight}
          setShowHighlight={setShowHighlight}
          setCanHighlight={setCanHighlight}
          canHighlight={canHighlight}
          pollingForPreviewUpdates={pollingForPreviewUpdates}
          appliedVariant={appliedVariant}
        />
        {comp && enlargeImgOpen && framePreviewsMap[frameInfo.frameId] && (
          <EnlargeImageModal
            imageUrl={framePreviewsMap[frameInfo.frameId].previews.fullsize}
            imageInfo={framePreviewsMap[frameInfo.frameId]}
            selectedFigmaIds={[comp.figma_node_ID]}
            artboardName={frameInfo.frameName}
            onHide={hideImageModal}
            figmaFrameURL={
              "https://www.figma.com/file/" + frameInfo.fileID + "/?node-id=" + encodeURI(frameInfo.frameId)
            }
            docName={doc_name}
            showHighlight={showHighlight}
            setShowHighlight={setShowHighlight}
            canHighlight={canHighlight}
            appliedVariant={appliedVariant}
          />
        )}
      </div>
    );
  }

  const onSingleAttachComponent = () => {
    refreshProjectSummary();
  };

  const onMultiAttachComponent = () => {
    refreshProjectSummary();
  };

  const onDetachComponent = (_textItemId, _componentId) => {
    refreshProjectSummary();
  };

  return (
    <div className={style.sidebar}>
      <ComponentSuggestionsWebsocketListener
        setHasSuggestions={setHasSuggestions}
        setSuggestionCount={setSuggestionCount}
        setShowSuggestionsBanner={setShowSuggestionsBanner}
        setNumDuplicateTexts={compSuggestionNudge.actions.setNumDuplicateTexts}
        setNumSuggestions={compSuggestionNudge.actions.setNumSuggestions}
        startDelay={compSuggestionNudge.actions.startDelay}
        componentSuggestionsJobId={componentSuggestionsJobId}
      />

      <div className={style.titleSection}>
        {((!selectedId && multiSelectedIds.length === 0) || panelState === PANELS.doc.inline_reply) && (
          <>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: activityFilter === "Activity",
              })}
              onClick={() => {
                segment.track({
                  event: "Activity Filter Changed",
                  properties: {
                    filter: "Activity",
                  },
                });
                checkDetailPanelChanges(() => {
                  unselectAll();
                  // need to allow unselect to occur before switching tabs
                  setTimeout(() => {
                    setActivityFilter("Activity");
                    setPanelState(null);
                    handleSuggestionSelectComp(null);
                  }, 100);
                });
              }}
            >
              <ReorderIcon className={style.icon} /> Activity
            </div>
            <div
              className={classnames({
                [style.option]: true,
                [style.selected]: activityFilter === "Comments",
              })}
              onClick={() => {
                segment.track({
                  event: "Activity Filter Changed",
                  properties: {
                    filter: "Comments",
                  },
                });
                checkDetailPanelChanges(() => {
                  unselectAll();
                  // need to allow unselect to occur before switching tabs
                  setTimeout(() => {
                    setActivityFilter("Comments");
                    setPanelState(null);
                    handleSuggestionSelectComp(null);
                  }, 100);
                });
              }}
            >
              {hasDocNotifs && <div className={style.notifIndicator}></div>}
              <ModeCommentIcon className={style.icon} /> Comments
            </div>
            {isEditEnabled && projectHasLinkableGroups && (
              <div
                className={classnames({
                  [style.option]: true,
                  [style.selected]: activityFilter === "Components",
                })}
                onClick={() => {
                  segment.track({
                    event: "Activity Filter Changed",
                    properties: {
                      filter: "Components",
                    },
                  });
                  checkDetailPanelChanges(() => {
                    unselectAll();
                    setTimeout(() => {
                      compSuggestionNudge.actions.dismissNudge();
                      setActivityFilter("Components");
                      setPanelState(PANELS.doc.suggestions);
                    }, 200);
                  });
                }}
              >
                {hasSuggestions && <NotifIndicator />}
                <AutoAwesomeIcon className={style.icon} />
                <Tooltip
                  className={style.tooltip}
                  maxWidth={380}
                  content={
                    <div className={style.container} onClick={(e) => e.stopPropagation()}>
                      <div className={style.title}>
                        We found {compSuggestionNudge.state.numDuplicateTexts} duplicate text items in this project! ✨
                        <CloseIcon className={style.icon} onClick={() => compSuggestionNudge.actions.dismissNudge()} />
                      </div>
                      <div className={style.body}>
                        Componentize duplicates to instantly keep them in sync, reduce errors, and save time.
                      </div>
                    </div>
                  }
                  visible={compSuggestionNudge.state.showNudge && !doc.isSample}
                  placement="bottom"
                >
                  <span>Components</span>
                </Tooltip>
              </div>
            )}
          </>
        )}
        {showEditingMultipleText && (
          <div className={classnames(style.option, style.selected)} data-testid="project-details-edit-multiple-title">
            {`Edit ${multiSelectedIds.length} Selected${
              allCompsInFrameSelected && isMultiSelectOnSameFrame ? " in Frame" : ""
            }`}
          </div>
        )}
        {showTabs && (
          <div className={style.singleSelect}>
            {showEditPanelTab && (
              <EditPanelTab
                text="Edit"
                selected={panelState === PANELS.doc.edit}
                onClick={() => {
                  checkDetailPanelChanges(() => {
                    setPanelState(PANELS.doc.edit);
                  });
                }}
              />
            )}
            <EditPanelTab
              testid="activity-tab"
              text={activityTabText}
              selected={panelState === PANELS.doc.activity}
              onClick={() => {
                checkDetailPanelChanges(() => {
                  setPanelState(PANELS.doc.activity);
                });
              }}
            >
              {numComments > 0 && (
                <div className={style.commentCount}>
                  <ModeCommentIcon className={style.icon} />
                  {numComments}
                </div>
              )}
            </EditPanelTab>
            {getShowVariantsTab(comp) && (
              <EditPanelTab
                text="Variants"
                selected={panelState === PANELS.doc.variants}
                onClick={() => {
                  checkDetailPanelChanges(() => {
                    setPanelState(PANELS.doc.variants);
                  });
                }}
              />
            )}
          </div>
        )}
      </div>
      {activityFilter === "Activity" && showActivity && showSuggestionsBanner && !doc.isSample && (
        <div className={style.wsCompSuggestions}>
          <div className={style.infoSection}>
            <CloseIcon className={style.closeIcon} onClick={onDismissSuggestionBanner} />
            <div className={style.infoIconWrapper}>
              <AutoAwesomeIcon className={style.icon} />
            </div>
            <div className={style.infoMessage}>
              <h3>Save time and increase consistency</h3>
              We found <strong>{suggestionsCount}</strong> suggestion
              {suggestionsCount > 1 ? "s" : ""} for Components to create and use in this project!
              <div className={style.showSuggestionsBtn} onClick={onShowSuggestions}>
                Show me
              </div>
            </div>
          </div>
        </div>
      )}
      {activityFilter === "Comments" && (
        <div className={style.commentDropdownWrapper}>
          Displaying
          <Dropdown className={style.filter}>
            <Dropdown.Toggle
              className={classnames({
                [style.filterLabel]: true,
                [style.allFilterLabel]: commentFilter === "All",
                [style.onlyYoursFilterLabel]: commentFilter === "Only Yours",
                [style.resolvedFilterLabel]: commentFilter === "Resolved",
              })}
            >
              {commentFilter}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className={classnames({
                  [style.selectedFilter]: commentFilter === "All",
                })}
                onClick={() => {
                  segment.track({
                    event: "Comment Filter Changed",
                    properties: {
                      filter: "All",
                    },
                  });
                  setCommentFilter("All");
                }}
              >
                All
              </Dropdown.Item>
              <Dropdown.Item
                className={classnames({
                  [style.selectedFilter]: commentFilter === "Only Yours",
                })}
                onClick={() => {
                  segment.track({
                    event: "Comment Filter Changed",
                    properties: {
                      filter: "Only Yours",
                    },
                  });
                  setCommentFilter("Only Yours");
                }}
              >
                Only Yours
              </Dropdown.Item>
              <Dropdown.Item
                className={classnames({
                  [style.selectedFilter]: commentFilter === "Resolved",
                })}
                onClick={() => {
                  segment.track({
                    event: "Comment Filter Changed",
                    properties: {
                      filter: "Resolved",
                    },
                  });
                  setCommentFilter("Resolved");
                }}
              >
                Resolved
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <div data-testid="doc-details" className={style.notTitle}>
        {showActivity && (
          <>
            <DocHistory
              emptyText={docHistoryState.emptyText}
              onResolveSuggestion={onResolveSuggestion}
              docHistory={docHistoryState.historyItems}
              docHistoryLoading={docHistoryState.isLoading}
              docHistoryIndex={docHistoryState.index}
              allDocHistFetched={docHistoryState.allFetched}
              resetDocHistory={docHistoryState.reset}
              loadMoreDocHistory={docHistoryState.loadHistory}
              setMultiSelected={setMultiSelected}
              setSingleSelected={setSingleSelected}
              handleCommentChangeClick={handleCommentChangeClick}
              prev_resync_time={prev_resync_time}
              curr_resync_time={curr_resync_time}
              showHighlightNew={showHighlightNew}
              setShowHighlightNew={setShowHighlightNew}
              setCompHistory={setCompHistory}
              handleDocUpdate={handleDocUpdate}
              handleHistoryUpdate={updateHistory}
              forceShowToast={forceShowToast}
              fetchCompInfo={fetchCompInfo}
              workspaceUsers={workspaceUsers}
              commentState={commentState}
              handleOpenFramePage={handleOpenFramePage}
              handleOpenBlockPage={handleOpenBlockPage}
              setScrollToId={setScrollToId}
              setPanelState={setPanelState}
              isLockedProject={isMergedBranch}
              devModeEnabled={devModeEnabled}
              commentHistoryScrollTopRef={commentHistoryScrollTopRef}
              docHistoryScrollTopRef={docHistoryScrollTopRef}
              activityFilter={activityFilter}
              setSyncSettingsModalVisible={setSyncSettingsModalVisible}
            />
          </>
        )}
        {panelState === PANELS.doc.suggestions && (
          <ComponentSuggestions
            doc_ID={doc_ID}
            resyncLoading={resyncLoading}
            groups={groups}
            handleSuggestionSelectComp={handleSuggestionSelectComp}
            suggestedCompId={suggestedCompId}
            handleDocUpdate={handleDocUpdate}
            handleHistoryUpdate={updateHistory}
            setCustomToast={setCustomToast}
            inSampleProject={doc.isSample}
          />
        )}
        {showSingleEditPanel && (
          <span>
            {origComp.ws_comp ? (
              <ErrorBoundary componentName="EditWSComp" type="Selection">
                <UserPermissionProvider resourceId={origComp.ws_comp.folder_id} resourceType={"component_folder"}>
                  <EditWSComp
                    onSingleAttach={onSingleAttachComponent}
                    onMultiAttach={onMultiAttachComponent}
                    onDetach={onDetachComponent}
                    key={comp.ws_comp?._id}
                    onSaveSuggestion={onSaveSuggestion}
                    commentEditor={commentEditor}
                    isDraftComp={false}
                    selectedId={comp.ws_comp?._id}
                    selectedCompName={comp.ws_comp?.name}
                    selectedVariant={selectedVariant}
                    origComp={origComp}
                    instance={{
                      _id: comp._id,
                      doc_name,
                    }}
                    handleDocUpdate={handleDocUpdate}
                    fetchCompInfo={fetchCompInfo}
                    tagSuggestions={tagSuggestions}
                    getWorkspaceTags={getWorkspaceTags}
                    doc_ID={doc_ID}
                    setOrigComp={setOrigComp}
                    handleHistoryUpdate={updateHistory}
                    setPanelState={setPanelState}
                    unselectAll={unselectAll}
                    frameVariants={frameVariants}
                    selectedGroupId={selectedGroupId}
                    isLockedProject={isLockedProject}
                    isMergedBranch={isMergedBranch}
                    componentType={comp.ws_comp?.type}
                  />
                </UserPermissionProvider>
              </ErrorBoundary>
            ) : comp ? (
              <ErrorBoundary componentName="EditComp" type="Selection">
                <EditComp
                  onSingleAttachComponent={onSingleAttachComponent}
                  onMultiAttachComponent={onMultiAttachComponent}
                  key={selectedId}
                  onSaveSuggestion={onSaveSuggestion}
                  commentEditor={commentEditor}
                  origComp={origComp}
                  setOrigComp={setOrigComp}
                  selectedId={selectedId}
                  selectedVariant={selectedVariant}
                  comp={comp}
                  setComp={setComp}
                  duplicateComps={duplicateComps}
                  duplicatesContainsVariants={duplicatesContainsVariants}
                  doc_ID={doc_ID}
                  doc_name={doc_name}
                  handleDocUpdate={handleDocUpdate}
                  fetchCompInfo={fetchCompInfo}
                  handleHistoryUpdate={updateHistory}
                  forceShowToast={forceShowToast}
                  setPanelState={setPanelState}
                  hidingAvailable={hidingAvailable}
                  tagSuggestions={tagSuggestions}
                  getWorkspaceTags={getWorkspaceTags}
                  unselectAll={unselectAll}
                  frameVariants={frameVariants}
                  selectedGroupId={selectedGroupId}
                  isDeveloperModeEnabled={doc.developer_mode_enabled}
                  compHistory={compHistory}
                  setShowMultiAttachCompToast={setShowMultiAttachCompToast}
                  isMergedBranch={isMergedBranch}
                  isLockedProject={isLockedProject}
                  inSampleProject={doc.isSample}
                />
              </ErrorBoundary>
            ) : (
              <React.Fragment />
            )}
            {showFigmaFramePreview && (
              <>
                <FigmaFramePreview
                  setImgError={setImgError}
                  imgError={imgError}
                  imageLoaded={imageLoaded}
                  setImageLoaded={setImageLoaded}
                  imageInfo={framePreviewsMap[frameInfo.frameId] || null}
                  setEnlargeImgOpen={setEnlargeImgOpen}
                  selectedFigmaIds={comp && comp.figma_node_ID ? [comp.figma_node_ID] : []}
                  showHighlight={showHighlight}
                  setShowHighlight={setShowHighlight}
                  setCanHighlight={setCanHighlight}
                  canHighlight={canHighlight}
                  pollingForPreviewUpdates={pollingForPreviewUpdates}
                  appliedVariant={appliedVariant}
                />
                {enlargeImgOpen && framePreviewsMap[frameInfo.frameId] && (
                  <EnlargeImageModal
                    imageUrl={framePreviewsMap[frameInfo.frameId].previews.fullsize}
                    imageInfo={framePreviewsMap[frameInfo.frameId]}
                    selectedFigmaIds={[comp.figma_node_ID]}
                    artboardName={frameInfo.frameName}
                    onHide={hideImageModal}
                    figmaFrameURL={
                      "https://www.figma.com/file/" + frameInfo.fileID + "/?node-id=" + encodeURI(frameInfo.frameId)
                    }
                    docName={doc_name}
                    showHighlight={showHighlight}
                    setShowHighlight={setShowHighlight}
                    canHighlight={canHighlight}
                    appliedVariant={appliedVariant}
                  />
                )}
              </>
            )}
          </span>
        )}
        {showMultiEditPanel && (
          <>
            <EditMultiComp
              onSingleAttachComponent={onSingleAttachComponent}
              onMultiAttachComponent={onMultiAttachComponent}
              doc_ID={doc_ID}
              doc_name={doc_name}
              selectedVariant={selectedVariant}
              multiSelectedIds={multiSelectedIds}
              multiSelectedComps={multiSelectedComps}
              multiSelectedVariants={multiSelectedVariants}
              handleDocUpdate={handleDocUpdate}
              handleHistoryUpdate={updateHistory}
              setMultiSelectedComps={setMultiSelectedComps}
              forceShowToast={forceShowToast}
              hidingAvailable={hidingAvailable}
              tagSuggestions={tagSuggestions}
              getWorkspaceTags={getWorkspaceTags}
              unselectAll={unselectAll}
              setShowMultiAttachCompToast={setShowMultiAttachCompToast}
              frameVariants={frameVariants}
              multiSelectGroupIds={multiSelectGroupIds}
              groupStateDispatch={dispatch}
              isLockedProject={isLockedProject}
              allCompsInFrameSelected={allCompsInFrameSelected}
              isMultiSelectOnSameFrame={isMultiSelectOnSameFrame}
              onCopySetupClicked={onCopySetupClicked}
              refetchMultiComps={refetchMultiComps}
            />
            {showFigmaFramePreview && isMultiSelectOnSameFrame && (
              <>
                <FigmaFramePreview
                  setImgError={setImgError}
                  imgError={imgError}
                  imageLoaded={imageLoaded}
                  setImageLoaded={setImageLoaded}
                  imageInfo={framePreviewsMap[frameInfo.frameId] || null}
                  setEnlargeImgOpen={setEnlargeImgOpen}
                  selectedFigmaIds={multiSelectedCompsFigmaIds}
                  showHighlight={showHighlight}
                  setShowHighlight={setShowHighlight}
                  setCanHighlight={setCanHighlight}
                  canHighlight={canHighlight}
                  pollingForPreviewUpdates={pollingForPreviewUpdates}
                  appliedVariant={appliedVariant}
                />
                {enlargeImgOpen && framePreviewsMap[frameInfo.frameId] && (
                  <EnlargeImageModal
                    imageUrl={framePreviewsMap[frameInfo.frameId].previews.fullsize}
                    imageInfo={framePreviewsMap[frameInfo.frameId]}
                    selectedFigmaIds={multiSelectedCompsFigmaIds}
                    artboardName={frameInfo.frameName}
                    onHide={hideImageModal}
                    figmaFrameURL={
                      "https://www.figma.com/file/" + frameInfo.fileID + "/?node-id=" + encodeURI(frameInfo.frameId)
                    }
                    docName={doc_name}
                    showHighlight={showHighlight}
                    setShowHighlight={setShowHighlight}
                    canHighlight={canHighlight}
                    appliedVariant={appliedVariant}
                  />
                )}
              </>
            )}
          </>
        )}
        {getShowTextItemHistoryPanel(comp) && (
          <CompHistory
            showSuggestionComponentWarning
            onResolveSuggestion={onResolveSuggestion}
            selectedId={selectedId}
            selectedFigmaId={comp.figma_node_ID}
            compHistoryLoading={compHistoryLoading}
            dateTimeCreated={doc_date_time_created}
            history={compHistory}
            setCompHistory={setCompHistory}
            handleDocUpdate={handleDocUpdate}
            handleHistoryUpdate={updateHistory}
            origComp={origComp}
            doc_ID={doc_ID}
            doc_name={doc_name}
            commentStateThreadId={commentState.thread_id}
            setCommentState={setCommentState}
            forceShowToast={forceShowToast}
            fetchCompInfo={fetchCompInfo}
            fetchNotifications={() => {
              fetchNotifications();
              fetchDocNotifications();
            }}
            workspaceUsers={workspaceUsers}
            selectedVariant={selectedVariant}
            setPanelState={setPanelState}
            isDraftHistory={Boolean(selectedDraftGroupId)}
            isLockedProject={isLockedProject}
            setSyncSettingsModalVisible={setSyncSettingsModalVisible}
          />
        )}

        {getShowTextItemVariantsPanel(comp) && (
          <CompVariants
            comp={comp}
            handleSelectFrameVariant={handleSelectFrameVariant}
            frameVariants={frameVariants[selectedGroupId]}
          />
        )}
      </div>

      {showProjectDetailsPanel && (
        <div className={style.categoriesWrap}>
          <div className={style.categoriesHeader}>Project Details</div>
          {shouldShowLockedDescription && (
            <ProjectLockedDescription
              isLocked={!Boolean(doc?.integrations?.figma?.branch_id)}
              mainProjectId={projectBranchInfo?.mainProjectInfo?._id}
              branchProjectId={projectBranchInfo?.branchProjectInfo?._id}
            />
          )}
          <ProjectSummarySection summary={projectSummary} />
          {folder && (
            <div className={classnames([style.folderIconWrapper], [style.pointer])} onClick={goToFolder}>
              {folder?.invite_only ? (
                <LockedFolderIcon className={style.icon} />
              ) : (
                <FolderOpenOutlinedIcon className={style.icon} />
              )}
              <div>{folder.name}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ProjectDetail.propTypes = {
  // TODO: we should use an actual Map not an Object for this data
  groups: PropTypes.arrayOf(PropTypes.object),
  selectedId: PropTypes.string,
  prev_resync_time: PropTypes.string,
  curr_resync_time: PropTypes.string.isRequired,
  doc_ID: PropTypes.string.isRequired,
  doc_name: PropTypes.string,
  doc_date_time_created: PropTypes.string.isRequired,
  handleDocUpdate: PropTypes.func.isRequired,
  multiOn: PropTypes.bool,
  multiSelectedIds: PropTypes.arrayOf(PropTypes.string),
  isMultiSelectOnSameFrame: PropTypes.bool,
};

export default ProjectDetail;
