import classnames from "classnames";
import React from "react";

import SampleBadge from "@/components/SampleBadge";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import ReactTooltip from "react-tooltip";
import ViewOnlyBadge from "../../../../components/ViewOnlyBadge";
import style from "./style.module.css";

const TitleBar = (
  { title, icon, onClickCallback, alignLeft, selectedFolder, onNewVariantClick, onHomeClick } = {
    button: null,
  }
) => {
  const canEdit = userHasResourcePermission("variant_folder:edit");

  return (
    <Navbar className={style.navbar}>
      <span
        className={classnames([style.title], {
          [style.alignLeft]: !!alignLeft,
        })}
      >
        <div>{title}</div>
        {icon === "INFO" && <InfoOutlinedIcon className={style.help_icon} onClick={() => onClickCallback()} />}
      </span>
      {selectedFolder && selectedFolder.name && (
        <div className={style.breadcrumbNav}>
          <div className={classNames(style.breadcrumb, style.clickable)} onClick={onHomeClick}>
            Home
          </div>
          <span className={style.breadcrumbDivider}>/</span>
          <div className={style.breadcrumb}>
            <FolderOpenOutlined />
            {selectedFolder.name}
          </div>
          {selectedFolder.isSample && <SampleBadge>Sample</SampleBadge>}
        </div>
      )}
      {!canEdit && (
        <ViewOnlyBadge tooltipText="You have use-only access to this variant folder. This means you can view variants and use them in projects or components, but not edit them. To get full edit rights, ask an admin." />
      )}
      {canEdit && (
        <Button
          data-testid="create-variant-button"
          data-tip
          data-for="create-variant"
          disabled={selectedFolder?.isSample}
          className={style.barButton}
          variant="primary"
          onClick={onNewVariantClick}
        >
          {"+ New Variant"}
        </Button>
      )}
      {selectedFolder?.isSample && (
        <ReactTooltip id="create-variant" place="bottom" effect="solid">
          Creating new variants is disabled in this sample folder.
        </ReactTooltip>
      )}
    </Navbar>
  );
};

TitleBar.propTypes = {
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default TitleBar;
