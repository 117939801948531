import React from "react";
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33338 2.73324H7.36671C6.28055 2.73324 5.40004 3.61374 5.40004 4.6999C5.40004 5.78606 6.28055 6.66657 7.36671 6.66657H9.33338V2.73324ZM9.33338 1.3999H10.6667H12.6334C14.456 1.3999 15.9333 2.87736 15.9333 4.6999C15.9333 5.77542 15.4189 6.73078 14.6225 7.33324C15.4189 7.9357 15.9333 8.89106 15.9333 9.96658C15.9333 11.7891 14.456 13.2666 12.6334 13.2666C11.8965 13.2666 11.216 13.025 10.6667 12.6168V13.2666V15.2333C10.6667 17.0558 9.18924 18.5333 7.36671 18.5333C5.54416 18.5333 4.06671 17.0558 4.06671 15.2333C4.06671 14.1577 4.58122 13.2024 5.37754 12.5999C4.58122 11.9974 4.06671 11.0421 4.06671 9.96658C4.06671 8.89106 4.58123 7.9357 5.37756 7.33325C4.58123 6.73078 4.06671 5.77542 4.06671 4.6999C4.06671 2.87736 5.54416 1.3999 7.36671 1.3999H9.33338ZM10.6667 2.73324V6.66657H12.6334C13.7196 6.66657 14.6 5.78606 14.6 4.6999C14.6 3.61374 13.7196 2.73324 12.6334 2.73324H10.6667ZM7.36671 11.9332H9.33338V9.97165V9.96658V9.96152V7.99992H7.36671C6.28055 7.99992 5.40004 8.88042 5.40004 9.96658C5.40004 11.0501 6.27634 11.929 7.35891 11.9332L7.36671 11.9332ZM5.40004 15.2333C5.40004 14.1497 6.27634 13.2708 7.35891 13.2666L7.36671 13.2666H9.33338V15.2333C9.33338 16.3194 8.45287 17.1999 7.36671 17.1999C6.28055 17.1999 5.40004 16.3194 5.40004 15.2333ZM10.6667 9.96266C10.6688 8.8783 11.5485 7.99992 12.6334 7.99992C13.7196 7.99992 14.6 8.88042 14.6 9.96658C14.6 11.0527 13.7196 11.9333 12.6334 11.9333C11.5485 11.9333 10.6688 11.0549 10.6667 9.9705V9.96266Z"
      fill="#1A1A1A"
    />
  </svg>
);
