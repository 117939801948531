const ROOT = "/folder";

export default {
  get: {
    index: {
      url: ROOT,
    },
    invites: {
      url: (folderId) => `${ROOT}/${folderId}/invites`,
    },
    byId: {
      url: (folderId) => `${ROOT}/${folderId}`,
    },
  },
  post: {
    create: {
      url: ROOT,
      body: ({ name, inviteOnly }) => ({ name, inviteOnly }),
    },
    invite: {
      url: (folderId) => `${ROOT}/${folderId}/invite`,
      body: ({ invitations }) => ({ invitations }),
    },
    removeUser: {
      url: (folderId) => `${ROOT}/${folderId}/invite/remove`,
      body: ({ type, user }) => ({ type, user }),
    },
  },
  put: {
    add: {
      url: (folderId, docId) => `${ROOT}/add/${folderId}/${docId}`,
      body: ({ folderId }) => ({ folderId }),
    },
    inviteOnly: {
      url: (folderId) => `${ROOT}/inviteOnly/${folderId}`,
    },
    rename: {
      url: (folderId) => `${ROOT}/rename/${folderId}`,
      body: ({ name }) => ({ name }),
    },
    remove: {
      url: (folderId, docId) => `${ROOT}/remove/${folderId}/${docId}`,
    },
  },
  delete: {
    delete: {
      url: (folderId) => `${ROOT}/${folderId}`,
    },
  },
};
