import React from "react";

import style from "./style.module.css";

interface Props {}

const PermissionRequiredFolder = (_props: Props) => {
  return (
    <div className={style.noPermissionsContainer}>
      <div className={style.centeredContent}>
        <h1 className={style.title}>Sorry, you don't have permission to view this project.</h1>
        <h2 className={style.subtitle}>
          This project is in a folder that you don't have access to. Reach out to an admin of this workspace or a member
          of the folder to get access.
        </h2>
      </div>
    </div>
  );
};

export default PermissionRequiredFolder;
