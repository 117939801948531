import CallSplitIcon from "@mui/icons-material/CallSplit";
import TemplateBadge from "@shared/frontend/templates/TemplateBadge";
import { ICommentThread } from "@shared/types/CommentThread";
import { ActualComponentInterface, VariantSchema } from "@shared/types/TextItem";
import classnames from "classnames";
import React, { useContext } from "react";
import { Element } from "react-scroll";
import { PANELS } from "../../defs";
import useRenderedLinks from "../../hooks/useRenderedLinks";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { INTERCOM_ATTR } from "../../utils/constants";
import CardBubbles from "../CardBubbles/CardBubbles";
import CompStatusBar from "../CompStatusBar/CompStatusBar";
import PluralsBadge from "../PluralsBadge";
import TextItem from "../TextItem";
import ApiID from "../api-id";
import { GroupContext } from "../compgroup";
import Tag from "../tag/tag";
import VariantContainer from "./VariantContainer";
import style from "./style.module.css";
// NOTE -- OPTIONAL component FIELDS:
// text, count, tags, notes, is_draft, is_error, comp_id, is_selected

interface Props {
  noId?: boolean;
  isDraft?: boolean;
  component: {
    _id: string;
    text: string;
    variants: VariantSchema[];
    notes?: string | null;
    comment_threads?: string[] | ICommentThread[];
    status: string;
    count?: number;
    tags?: string[];
    plurals?: unknown[];
    assignee?: string | null;
  };
  is_selected: boolean;
  is_suggested: boolean;
  disableCopyApiID?: boolean;
  onClick?: (e: React.SyntheticEvent, id: string) => void;
  selectComp: (
    component: ActualComponentInterface<string>,
    frameVariant?: any,
    e?: React.SyntheticEvent,
    groupId?: string
  ) => void;
  is_ws_comp: boolean;
  ws_comp_id: string | null;
  compName: string | null;
  setPanelState: (panel: string) => void;
  setCommentState: (state: any) => void;
  apiID: string | null;
  displayApiIds: boolean;
  frameVariant?: any;
  frameVariants?: any;
  hoveredVariantId?: string;
  setHoveredVariantId?: (variantId: string | null) => void;
  handleSelectActiveVariant?: (variantId: string | null) => void;
  groupId?: string;
  importVariantPreview?: any;
  containerStyle?: any;
  compType?: "standard" | "template";
  isWsCompInstance?: boolean;
  className?: string;
  hideCompName?: boolean;
  isDragging?: boolean;
  showAllStatuses?: boolean;
}
type GroupContext = {
  isLinked: boolean;
  groupId: string;
};
const Comp = ({
  isDraft = false,
  component,
  is_selected,
  is_suggested,
  disableCopyApiID,
  /**
   * If provided, the `onClick` callback will be called when a
   * component is clicked INSTEAD of `selectComp`.
   */
  onClick,
  selectComp,
  is_ws_comp,
  ws_comp_id,
  compName,
  setPanelState,
  setCommentState,
  apiID,
  displayApiIds,
  frameVariant,
  frameVariants,
  hoveredVariantId,
  setHoveredVariantId,
  handleSelectActiveVariant,
  groupId,
  importVariantPreview,
  containerStyle,
  compType = "standard",
  isWsCompInstance,
  className,
  hideCompName,
  noId = false,
  isDragging = false,
  showAllStatuses,
}: Props) => {
  const groupContext = useContext(GroupContext) as GroupContext;
  const inUnlinkedGroup = groupContext && groupContext.isLinked === false;
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);

  const formattedNotes = useRenderedLinks(component?.notes || "");

  const openComments = () => {
    checkDetailPanelChanges(() => {
      if (!is_selected) {
        selectComp(component as ActualComponentInterface<string>, frameVariant);
      }
      setCommentState({ isSelected: true, thread_id: "" });
      setTimeout(() => {
        // I hate this with every fiber of my being, but we're working with what we've got
        const panelState = is_ws_comp && !isWsCompInstance ? PANELS.comp_library.activity : PANELS.doc.activity;
        setPanelState(panelState);
      }, 0);
    });
  };

  const currentVariantInstance = component.variants?.find((v) => v.variantId === frameVariant?.id);

  const emptyVariant =
    component.variants &&
    frameVariant &&
    frameVariant.id !== "__base__" &&
    // we consider a variant "empty" if it's never been saved at all, or if it has been saved with empty text.
    (!currentVariantInstance || !currentVariantInstance?.text || currentVariantInstance?.text === "");

  // if isWsCompInstance, use that id for our element for auto-scroll, instead of ws_comp_id
  const id = noId ? undefined : isWsCompInstance || !ws_comp_id ? component._id : ws_comp_id;

  // at least one of the component or its variants has a status other than NONE
  const frameVariantIds = frameVariants ? frameVariants.map((fv: any) => fv.id) : [];
  const filteredVariants = component.variants?.filter((v) =>
    frameVariantIds?.length > 0 ? frameVariantIds?.includes(v.variantId) : true
  );

  // render the CompStatusBar if
  //     1. we're not rendering in the ws comp import modal
  //     2. the component or any of its variants have a status other than NONE
  const shouldRenderStatusBar =
    !importVariantPreview &&
    (component.status !== "NONE" || filteredVariants?.some((v) => v.status && v.status !== "NONE"));

  return (
    <Element id={id} name={id} style={containerStyle}>
      <div
        data-selected={is_selected}
        data-componentid={id}
        data-testid={`comp-${(component.text || "").replace(/\s/g, "-")}`}
        className={classnames(className, {
          componentTile: true,
          [style.comp]: true,
          "single-component": true,
          [style.selected]: is_selected,
          [style.suggested]: is_suggested,
          [style.inUnlinkedGroup]: inUnlinkedGroup,
          [style.wsComp]: is_ws_comp,
          [style.wsCompDraft]: isDraft,
          [style.wsCompTemplateInstance]: isWsCompInstance && compType === "template",
          [style.isDragging]: isDragging,
        })}
        data-tour={is_ws_comp ? INTERCOM_ATTR.SINGLE_TEXT_ITEM : undefined}
      >
        {is_suggested && (
          <div
            className={style.suggestedOverlay}
            onClick={(e) => {
              if (onClick) {
                return onClick(e, ws_comp_id || component._id);
              }

              selectComp(component as ActualComponentInterface<string>, frameVariant, e, groupId);
            }}
          />
        )}
        <CardBubbles
          cardIsSelected={is_selected}
          assignee={component.assignee}
          comment_threads={component.comment_threads}
          onAssigneeClick={(e) => selectComp(component as ActualComponentInterface<string>, frameVariant, e, groupId)}
          onCommentThreadClick={openComments}
        />
        <div
          className={style.cardInfoContainer}
          onClick={(e) => {
            if (onClick) {
              return onClick(e, ws_comp_id || component._id);
            }
            selectComp(component as ActualComponentInterface<string>, frameVariant, e, groupId);
          }}
        >
          {!hideCompName && compName && (
            <div className={style.compName}>
              <div className={style.flexBetween}>
                {compName}
                {compName && component.variants && component.variants.length > 0 && (
                  <div
                    className={classnames({
                      [style.flexCentered]: true,
                      [style.variantsPaddingRight]:
                        component && component.comment_threads && component.comment_threads.length > 0,
                    })}
                  >
                    <CallSplitIcon className={classnames([style.icon, style.splitIconRotate])} />{" "}
                    {component.variants.length}
                  </div>
                )}
              </div>
            </div>
          )}

          {shouldRenderStatusBar && (
            <CompStatusBar
              component={component}
              activeVariantId={frameVariant?.id || "__base__"}
              hoveredVariantId={hoveredVariantId}
              setHoveredVariantId={setHoveredVariantId}
              handleSelectActiveVariant={handleSelectActiveVariant}
              className={style.statusBar}
              frameVariants={frameVariants}
              showAllStatuses={showAllStatuses}
            />
          )}

          <TemplateBadge
            selected={is_selected}
            type={compType}
            className={isWsCompInstance ? style.wsCompTemplateInstanceBadge : undefined}
          />
          {!importVariantPreview && (
            <div
              className={classnames({
                [style.compText]: true,
              })}
            >
              <div
                className={classnames({
                  [style.text]: true,
                  [style.emptyVariant]: emptyVariant,
                })}
              >
                <TextItem
                  textItem={component}
                  frameVariant={frameVariant}
                  highlightBrackets={compType === "template"}
                />
                <PluralsBadge comp={component} variantId={frameVariant?.id} />
              </div>
              {component.count && component.count > 1 && <div className={style.count}>{component.count}</div>}
            </div>
          )}
          {importVariantPreview && (
            <>
              <VariantContainer
                highlighted={false}
                name="Base"
                text={
                  <TextItem
                    textItem={component}
                    frameVariant={frameVariant}
                    highlightBrackets={compType === "template"}
                  />
                }
                selected={is_selected}
                status={component.status}
              />
              <VariantContainer
                highlighted
                name={importVariantPreview.name}
                text={importVariantPreview.text}
                richText={importVariantPreview.rich_text}
                selected={is_selected}
                plurals={importVariantPreview.plurals}
                status={importVariantPreview.status || component.status}
              />
            </>
          )}
          {((component.notes && component.notes.length > 0) ||
            (component.tags && component.tags.length > 0) ||
            (displayApiIds && apiID)) && (
            <div className={style.compOther}>
              {component.notes && component.notes.length > 0 && <div>{formattedNotes}</div>}
              {component.tags && component.tags.length > 0 && (
                <div className={style.tags}>
                  {component.tags.map((text, index) => (
                    <Tag text={text} is_selected={is_selected} key={index} count={undefined} onClick={undefined} />
                  ))}
                </div>
              )}
              {displayApiIds && apiID && (
                <div className={style.apiID}>
                  <ApiID id={apiID} disableCopy={disableCopyApiID} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Element>
  );
};

export default Comp;
