import { useWorkspace } from "@/store/workspaceContext";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import React, { createContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import useLocation from "react-use/lib/useLocation";
import billingImg from "../assets/blurryditto2.png";
import { BillingModalWrapper } from "../components/account-billing/BillingModal/BilllingModalWrapper";
import { GetBillingStatusState, useBillingStatus } from "../components/account-billing/BillingModal/useBillingStatus";
import { GetSeatsState, useSeats } from "../components/account-billing/BillingModal/useSeats";
import PaywallModal from "../components/paywallmodal/paywallmodal";
import { Downgrade } from "../http/billing";
import { useAuthenticatedAuth } from "./AuthenticatedAuthContext";
import style from "./style.module.css";

function BillingWebsocketHandler(props: {
  setComponentLimitProps: React.Dispatch<React.SetStateAction<ComponentLimits>>;
}) {
  const { getTokenSilently } = useAuthenticatedAuth();
  const workspaceContext = useWorkspace();
  const { lastMessage, sendMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  // Websocket setup
  useEffect(() => {
    async function sendWsCompsSubscribeMsg() {
      const subscribeToWsCompsMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_WS_COMPS_SUBSCRIPTION,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeToWsCompsMsg));
    }

    if (readyState === 1) {
      sendWsCompsSubscribeMsg();
      // Keep the websocket alive
    }
  }, [readyState]);

  // Websocket message handler
  useEffect(() => {
    const plan = workspaceContext?.workspaceInfo?.plan;
    if (!lastMessage || !plan) return;
    const data = JSON.parse(lastMessage.data);
    if (data.messageType === WEBSOCKET_EVENTS.COMPONENT_COUNT_UPDATED) {
      const { count: componentCount } = data;
      const componentsLimit = plan === "free" ? 20 : 200;
      // growth and enterprise workspaces have no limit
      const isOverComponentLimit =
        plan === "growth" || plan === "enterprise" || plan === "trial" ? false : componentCount >= componentsLimit;
      const percentageUsed = Math.min(100, (componentCount / componentsLimit) * 100);
      props.setComponentLimitProps({
        componentCount,
        componentsLimit,
        isOverComponentLimit,
        percentageUsed,
        plan,
      });
    }
  }, [lastMessage, workspaceContext]);
  return <></>;
}

export type PlanWillCancel = ["free", number] | ["team", number] | undefined;
interface ComponentLimits {
  componentCount: number;
  componentsLimit: number;
  isOverComponentLimit: boolean;
  percentageUsed: number;
  plan: string;
}

interface BillingContext {
  componentLimits: ComponentLimits;
  planWillCancel: PlanWillCancel;
  seatsState: GetSeatsState;
  billingStatus: GetBillingStatusState;
  showBillingModal: () => void;
  hideBillingModal: () => void;
  refreshSeats: () => Promise<void>;
  validateBilling: () => Promise<void>;
}

export const BillingContext = createContext({
  componentLimits: {
    componentCount: 0,
    componentsLimit: 20,
    isOverComponentLimit: false,
    percentageUsed: 0,
    plan: "free",
  },
  seatsState: { loading: true },
  billingStatus: { loading: true },
  showBillingModal: () => {},
  hideBillingModal: () => {},
  refreshSeats: async () => {},
  validateBilling: async () => {},
} as BillingContext);

export const useBillingContext = () => React.useContext(BillingContext);
export const BillingProvider = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useAuthenticatedAuth();
  const [seatsState, fetchSeats] = useSeats();
  const [billingStatus, fetchBillingStatus] = useBillingStatus();
  const [showModal, setShowModal] = useState(false);
  const [componentLimitProps, setComponentLimitProps] = useState({
    componentCount: 0,
    componentsLimit: 20,
    isOverComponentLimit: false,
    percentageUsed: 0,
    plan: "free",
  });

  const [planWillCancel, setPlanWillCancel] = useState<PlanWillCancel>();

  const workspaceContext = useWorkspace();
  const devToolsEnabled = workspaceContext?.workspaceInfo?.devTools;
  const email = user.email;
  const name = user.name;

  // Check for Plan Cancellation
  useEffect(() => {
    if (!seatsState.loading && seatsState.success) {
      const downgradePlan: "free" | "team" | undefined = (
        (seatsState.data.renewalDateEditorYearly as Downgrade) ||
        (seatsState.data.renewalDateEditorMonthly as Downgrade)
      )?.plan;
      if (downgradePlan) {
        setPlanWillCancel([
          downgradePlan,
          (seatsState.data.renewalDateEditorYearly?.date || seatsState.data.renewalDateEditorMonthly?.date)!,
        ]);
      }
    }
  }, [billingStatus, seatsState]);

  useEffect(() => {
    refreshSeats();
  }, [billingStatus]);

  const showBillingModal = () => {
    setShowModal(true);
  };

  const hideBillingModal = () => {
    setShowModal(false);
  };

  const refreshSeats = async () => {
    await fetchSeats();
  };

  const validateBilling = async () => {
    await fetchBillingStatus();
  };

  const store = {
    componentLimits: {
      ...componentLimitProps,
    },
    seatsState,
    billingStatus,
    planWillCancel,
    showBillingModal,
    hideBillingModal,
    refreshSeats,
    validateBilling,
  };

  const modalJsx = (
    <>
      {showModal && (
        <BillingModalWrapper
          name={name}
          email={email}
          componentsInWorkspace={componentLimitProps.componentCount}
          onHide={hideBillingModal}
          seatsState={seatsState}
          billingStatus={billingStatus}
          planWillCancel={planWillCancel}
          devToolsEnabled={devToolsEnabled}
        />
      )}
    </>
  );

  if (
    !billingStatus.loading &&
    billingStatus.success &&
    billingStatus.data.showPaywall &&
    workspaceContext.workspaceInfo &&
    !pathname?.includes("account")
  ) {
    return (
      <BillingContext.Provider value={store}>
        <img className={style.paywallBackground} src={billingImg} />
        <PaywallModal />
        {modalJsx}
        <BillingWebsocketHandler setComponentLimitProps={setComponentLimitProps} />
      </BillingContext.Provider>
    );
  }

  return (
    <BillingContext.Provider value={store}>
      {children}
      {modalJsx}
      <BillingWebsocketHandler setComponentLimitProps={setComponentLimitProps} />
    </BillingContext.Provider>
  );
};
