import * as DittoEvents from "@shared/ditto-events";
import { JobNames } from "@shared/types/jobs/JobNames";
import { useDittoEventListener } from "../../../../shared/ditto-events/frontend";
import { ZComponentSuggestionJobResult } from "../../../../shared/types/jobs/ComponentSuggestions";
import logger from "../../../../shared/utils/logger";
import http from "../../../http";

interface ComponentSuggestionListenerProps {
  setHasSuggestions: React.Dispatch<React.SetStateAction<boolean>>;
  setSuggestionCount: React.Dispatch<React.SetStateAction<number>>;
  setShowSuggestionsBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setNumDuplicateTexts: React.Dispatch<React.SetStateAction<number>>;
  setNumSuggestions: React.Dispatch<React.SetStateAction<number>>;
  startDelay: (delay: number) => void;
  componentSuggestionsJobId: React.MutableRefObject<string | null>;
}

export function ComponentSuggestionsWebsocketListener(props: ComponentSuggestionListenerProps) {
  const {
    setHasSuggestions,
    setSuggestionCount,
    setShowSuggestionsBanner,
    setNumDuplicateTexts,
    setNumSuggestions,
    startDelay,
    componentSuggestionsJobId,
  } = props;

  useDittoEventListener(
    DittoEvents.backgroundJobUpdated,
    async function handleBackgroundJobUpdated(event) {
      if (event.id === componentSuggestionsJobId.current && event.jobName === JobNames.ComponentSuggestionsJob) {
        if (event.status === "completed") {
          try {
            // actually fetch the result from the backend
            const jobResults = await http.get(`/jobs/response/${JobNames.ComponentSuggestionsJob}/${event.id}`);

            const { creatableSuggestions, attachableSuggestions } = ZComponentSuggestionJobResult.parse(
              jobResults.data.result
            );

            const numSuggestions =
              creatableSuggestions.filter((s) => !s.ignored).length +
              attachableSuggestions.filter((s) => !s.ignored).length;
            setHasSuggestions(numSuggestions > 0);
            setSuggestionCount(numSuggestions);
            const showBanner = numSuggestions > 0 && localStorage.getItem("showSuggestionBanner") !== "false";
            setShowSuggestionsBanner(showBanner);

            const setOfDuplicateInstances = new Set();
            creatableSuggestions.forEach((sugg) =>
              sugg.comp_ids.forEach((comp_id) => setOfDuplicateInstances.add(comp_id))
            );
            attachableSuggestions.forEach((sugg) =>
              sugg.comp_ids.forEach((comp_id) => setOfDuplicateInstances.add(comp_id))
            );

            setNumDuplicateTexts(setOfDuplicateInstances.size);
            setNumSuggestions(numSuggestions);
            startDelay(3000);
          } catch (error) {
            logger.error(
              "Unalbe to fetch component suggestions",
              { tags: { jobId: event.id, jobName: event.jobName } },
              error
            );
          }
          componentSuggestionsJobId.current = null;
        } else if (event.status === "failed") {
          logger.error("Component Suggestions Job Failed", { other: { event } }, new Error(event.failedReason));
          componentSuggestionsJobId.current = null;
        }
      }
    },
    []
  );

  return <></>;
}
