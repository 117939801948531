import * as SegmentEvents from "@shared/segment-event-names";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createAndDownloadFile } from "../../../shared/frontend/lib";
import { ALL_VARIANTS_FOLDER_ID } from "../../../shared/frontend/useVariantSelect";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";

const showBaseOnlyOption = (jsonFormat) => ["flat", "icu"].includes(jsonFormat);

export const useExport = ({ doc_ID, doc_name, developerModeEnabled, componentFolder }) => {
  const segment = useSegment();

  const modalTitle = useMemo(() => {
    let title;
    switch (doc_ID) {
      case "component_library":
        title = "Export component library";
        break;
      case "variable_library":
        title = "Export variables";
        break;
      default:
        title = "Export project";
        break;
    }
    return title;
  }, [doc_ID]);

  const libraryURL = useMemo(() => {
    let url;
    switch (doc_ID) {
      case "component_library":
        url = API.api.get.componentLibraryExport.url;
        break;
      case "variable_library":
        url = API.api.get.variableLibraryExport.url;
        break;
      default:
        url = API.api.get.projectExport.url(doc_ID);
        break;
    }
    return url;
  }, [doc_ID]);

  const isVariableExport = useMemo(() => doc_ID === "variable_library", [doc_ID]);
  const VALID_JSON_FORMATS = ["full", "flat", "structured", "nested", "icu"];

  const [isExporting, setIsExporting] = useState(false);
  const [format, setFormat] = useState("JSON");
  const [projectData, setProjectData] = useState({});
  const [exportLoading, setExportLoading] = useState(false);
  const [blocksOnly, setBlocksOnly] = useState(false);
  const [includeRichText, setIncludeRichText] = useState(false);
  const [jsonFormat, setJsonFormat] = useState("flat");
  const [, setError] = useState({ exists: false, msg: "" });
  const [fields, setFields] = useState({
    status: true,
    tags: true,
    notes: true,
    api_id: true,
    frame_name: true,
    frame_link: true,
    block_name: true,
    component_name: true,
    component_api_id: true,
    variants: true,
    comments: true,
    text_item_link: true,
  });

  /**
   * The value that should be assigned to the name of selected variant.
   *
   * Only used for mobile exports.
   */
  const [nameOfMobileExportVariant, setNameOfMobileExportVariant] = useState("Base");

  // TODO: refactor the other export types to use this as well
  /**
   * Ref object for storing the latest-generated preview data for
   * each type of export format, used when clicking the "Export" button
   * of the modal.
   *
   * Currently only used for the two mobile export formats.
   */
  const exportDataReference = useRef({
    android: "",
    iosStrings: "",
    iosStringsdict: "",
  });

  /**
   * Save a reference to some data generated for an export type so that it
   * can be used when the "Export" button is clicked in the export modal.
   */
  const storeExportDataReference = (exportType, data) => {
    exportDataReference.current[exportType] = data;
  };

  const isProjectExport = doc_ID !== "component_library" && doc_ID !== "variable_library";

  const isComponentExport = doc_ID === "component_library";

  const getCurrentJSONFormat = () => {
    if (doc_ID === "variable_library") {
      return projectData.normalizedJSON;
    }

    if (jsonFormat === "flat" && projectData.flatJSON) {
      return projectData.flatJSON;
    }

    if (jsonFormat === "structured" && projectData.structuredJSON) {
      return projectData.structuredJSON;
    }

    if (jsonFormat === "nested" && projectData.nestedJSON) {
      return projectData.nestedJSON;
    }

    if (jsonFormat === "icu" && projectData.icuJSON) {
      return projectData.icuJSON;
    }

    return projectData.flatJSON;
  };

  const projectJSON = useMemo(getCurrentJSONFormat, [jsonFormat, projectData, developerModeEnabled]);

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
    setNameOfMobileExportVariant("Base");
  };
  const handleBlocksOnlyChange = (e) => {
    setBlocksOnly(e.target.checked);
  };

  const handleIncludeRichTextChange = (e) => {
    setIncludeRichText(e.target.checked);
  };

  const handleCheckboxField = (fieldName) => (e) => setFields({ ...fields, [fieldName]: e.target.checked });

  const csvFields = useMemo(
    () =>
      Object.keys(fields)
        .filter((field) => fields[field])
        .join(","),
    [fields]
  );

  const exportToCSVFile = async () => {
    try {
      setExportLoading(true);
      const { data: csv } = await http.get(
        `${libraryURL}?csv=true` +
          `&blocksOnly=${blocksOnly}` +
          `&fields=${csvFields}` +
          `&componentFolderId=${componentFolder?._id || ""}`
      );

      const fileName = doc_name.replace(/\./g, "_");

      createAndDownloadFile(fileName, csv, "text/csv;");

      setIsExporting(false);
    } catch (e) {
      console.error("Error doing export to CSV: ", e.message);
      setError({
        exists: true,
        msg: "Sorry, we had trouble exporting this project to a .csv file.",
      });
      setIsExporting(false);
    }
  };

  function exportToAndroidXmlFile() {
    const fileName = `${doc_name.replace(/\./g, "_")}${
      nameOfMobileExportVariant === "Base" ? "" : "-" + nameOfMobileExportVariant
    }.xml`;

    createAndDownloadFile(fileName, exportDataReference.current.android, "text/xml;");

    setIsExporting(false);
  }

  function exportToIosStringsFile() {
    const fileName = `${doc_name.replace(/\./g, "_")}${
      nameOfMobileExportVariant === "Base" ? "" : "-" + nameOfMobileExportVariant
    }.strings`;

    createAndDownloadFile(fileName, exportDataReference.current.iosStrings, "text/plain;");

    setIsExporting(false);
  }

  function exportToIosStringsdictFile() {
    const fileName = `${doc_name.replace(/\./g, "_")}${
      nameOfMobileExportVariant === "Base" ? "" : "-" + nameOfMobileExportVariant
    }.stringsdict`;

    createAndDownloadFile(fileName, exportDataReference.current.iosStringsdict, "text/xml;");

    setIsExporting(false);
  }

  function exportToJsonFile() {
    try {
      const fileName = `${doc_name.replace(/\./g, "_")}${
        variantOptionSelected.name === "All Variants" ? "" : "-" + variantOptionSelected.name
      }.json`;

      if (Object.keys(projectJSON).length === 0) {
        fetchProjectExport();
      }

      createAndDownloadFile(fileName, JSON.stringify(projectJSON, null, 2), "data:application/json;");

      setIsExporting(false);
    } catch (e) {
      console.error("Error doing export to JSON: ", e.message);
      setError({
        exists: true,
        msg: "Sorry, we had trouble exporting this project to a JSON file.",
      });
      setIsExporting(false);
    }
  }

  const variantOptionAllVariants = {
    label: "All Variants",
    value: ALL_VARIANTS_FOLDER_ID,
  };
  const variantOptionBaseOnly = { label: "Base Only", value: "base-only" };

  const defaultVariantOptionSelected = {
    id: variantOptionAllVariants.value,
    name: variantOptionAllVariants.label,
  };

  const [variantOptionSelected, setVariantOptionSelected] = useState(defaultVariantOptionSelected);

  const fetchProjectExport = async () => {
    try {
      setExportLoading(true);

      let url = `${libraryURL}`;

      let params = {
        blocksOnly,
        includeRichText,
      };

      if (
        variantOptionSelected &&
        variantOptionSelected.id !== ALL_VARIANTS_FOLDER_ID &&
        variantOptionSelected.id !== "base-only"
      ) {
        params.variant = variantOptionSelected.id;
      }

      if (componentFolder) {
        params.componentFolderId = componentFolder._id;
      }

      if (componentFolder?.isSample) {
        params.includeSampleData = true;
      }

      const { data } = await http.get(url, { params });
      setProjectData(data);

      setExportLoading(false);
    } catch (e) {
      console.error("Caught error in export-modal.jsx: ", e.message);
    }
    setExportLoading(false);
  };

  const handleExportProject = async () => {
    segment.track({
      event: SegmentEvents.PROJECT_EXPORT_CLICKED,
      properties: {
        format: format,
        settings: JSON.stringify(fields),
        project_id: doc_ID,
      },
    });
    setIsExporting(true);
    setError({ exists: false, msg: "" });

    if (format === "CSV") {
      exportToCSVFile();
    } else if (format === "JSON") {
      exportToJsonFile();
    } else if (format === "android") {
      exportToAndroidXmlFile();
    } else if (format === "ios-strings") {
      exportToIosStringsFile();
    } else if (format === "ios-stringsdict") {
      exportToIosStringsdictFile();
    } else {
      console.error(`Invalid selected: ${format}`);
      setError({ exists: true, msg: "Please choose a format." });
    }
  };

  const handleSelectJSONFormat = (format) => () => {
    if (VALID_JSON_FORMATS.includes(format)) {
      setJsonFormat(format);
    }
  };

  const isJsonFormatChecked = (val) => jsonFormat === val;

  useEffect(() => {
    if (format === "JSON") {
      fetchProjectExport();
    }
  }, [format, blocksOnly, includeRichText, variantOptionSelected]);

  const variantSelectOptions = showBaseOnlyOption(jsonFormat) ? [variantOptionBaseOnly] : [variantOptionAllVariants];

  useEffect(() => {
    setVariantOptionSelected((variantOption) => {
      if (!variantOption) return variantOption;

      const shouldShowBaseOnlyOption = showBaseOnlyOption(jsonFormat);

      if (!shouldShowBaseOnlyOption && variantOption.id === "base-only") {
        return {
          id: variantOptionAllVariants.value,
          name: variantOptionAllVariants.label,
        };
      }

      if (shouldShowBaseOnlyOption && variantOption.id === ALL_VARIANTS_FOLDER_ID) {
        return {
          id: variantOptionBaseOnly.value,
          name: variantOptionBaseOnly.label,
        };
      }

      return variantOption;
    });
  }, [jsonFormat, setVariantOptionSelected]);

  const handleVariantOptionSelected = useCallback(
    (variantOption) => {
      return setVariantOptionSelected(variantOption);
    },
    [setVariantOptionSelected]
  );

  return {
    modalTitle,
    includeRichText,
    blocksOnly,
    exportLoading,
    fields,
    format,
    isExporting,
    isProjectExport,
    isComponentExport,
    projectJSON,
    variantSelectOptions,
    isJsonFormatChecked,
    variantOptionSelected,
    handleIncludeRichTextChange,
    handleBlocksOnlyChange,
    handleCheckboxField,
    handleExportProject,
    handleFormatChange,
    handleSelectJSONFormat,
    handleVariantOptionSelected,
    isVariableExport,
    storeExportDataReference,
    setNameOfMobileExportVariant,
  };
};
