import { authorizeSlackAccess } from "@/http/slack";
import { useWorkspace } from "@/store/workspaceContext";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import style from "./style.module.css";

interface SlackAuthHandlerProps {
  initializeConnection?: boolean;
  redirectUri: string;
}

const SlackAuthHandler = ({ initializeConnection, redirectUri }: SlackAuthHandlerProps) => {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const alreadyConnected = useRef(false);
  const { docId } = useParams<{ docId?: string }>();
  const history = useHistory();

  useEffect(
    function connectSlackOnMount() {
      // `workspaceInfo` doesn't have a stable reference, which means this useEffect
      // will run a lot, so we need to use a ref to ensure we only connect once
      if (alreadyConnected.current) return;

      // the app mounts and renders a couple of times with workspaceInfo being `null` :(
      // we want to wait to connect to Slack until it has a value
      if (!workspaceInfo) return;

      alreadyConnected.current = true;

      const queryParams = new URLSearchParams(window.location.search);

      if (queryParams.get("error")) {
        window.close();
        return;
      }

      const code = queryParams.get("code");

      if (!code) {
        return;
      }

      (async () => {
        try {
          const [request] = authorizeSlackAccess({
            code: code,
            initializeConnection,
            projectId: docId,
            redirectUri,
          });
          await request;
          setFinishedLoading(true);
        } catch (error) {
          setErrorMessage("Failed to connect to Slack: " + error);
          console.error("Failed to connect to Slack:", error);
        }
      })();
    },
    [workspaceInfo, setFinishedLoading, setErrorMessage]
  );

  useEffect(
    function closeWindowOnConnectionComplete() {
      if (!finishedLoading) return;
      if (initializeConnection) {
        updateWorkspaceInfo((workspaceInfo) => ({
          ...workspaceInfo,
          integrations: {
            ...workspaceInfo.integrations,
            slack: {
              ...workspaceInfo.integrations.slack,
              authenticated: true,
            },
          },
        }));
        history.push("/integrations");
      } else window.close();
    },
    [finishedLoading]
  );

  return finishedLoading ? (
    <div>
      <p>Connected!</p>
    </div>
  ) : (
    <div>
      {" "}
      <p>Connecting to Slack...</p>
      {errorMessage && (
        <span className={style.error}>
          {errorMessage} <br /> Please try to connect again, or contact us if this error continues.{" "}
        </span>
      )}
    </div>
  );
};

export default SlackAuthHandler;
