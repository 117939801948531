import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classnames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { VariableType } from "../../types";
import style from "./style.module.css";

interface Props {
  type: VariableType;
  chooseType: (input: VariableType) => void;
}
const VariableTypeFilter = ({ type, chooseType }: Props) => (
  <div className={style.topRow}>
    <Dropdown>
      <Dropdown.Toggle>
        <div
          className={classnames({
            [style.filter]: true,
            [style.selectType]: true,
            [style.on]: type !== "Any",
          })}
        >
          Type:
          <span className={style.chosen}>{type}</span>
          <KeyboardArrowDownIcon className={style.icon} />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "Any",
          })}
          onClick={() => chooseType("Any")}
        >
          Any
        </Dropdown.Item>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "String",
          })}
          onClick={() => chooseType("String")}
        >
          String
        </Dropdown.Item>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "Number",
          })}
          onClick={() => chooseType("Number")}
        >
          Number
        </Dropdown.Item>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "Hyperlink",
          })}
          onClick={() => chooseType("Hyperlink")}
        >
          Hyperlink
        </Dropdown.Item>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "List",
          })}
          onClick={() => chooseType("List")}
        >
          List
        </Dropdown.Item>
        <Dropdown.Item
          className={classnames({
            [style.selected]: type === "Map",
          })}
          onClick={() => chooseType("Map")}
        >
          Map
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default VariableTypeFilter;
