import { IPermissionGroup } from "@shared/types/PermissionGroups";
import {
  ADMIN_DEFAULT_PERMISSION_GROUP_NAME,
  COMMENTER_DEFAULT_PERMISSION_GROUP_NAME,
  EDITOR_DEFAULT_PERMISSION_GROUP_NAME,
} from "@shared/types/User";
// Default Permission Groups

export interface DefaultPermissionGroup extends Omit<IPermissionGroup, "workspace_id" | "_id"> {
  _id: string;
  built_in: true;
}

// these MUST end with `_no_id` for the logic in the UserPermissionContext and getUser middleware to work
export const PROJECTS_NOT_IN_FOLDERS_ID = "project_folder_no_id";
export const COMPONENTS_NOT_IN_FOLDERS_ID = "component_folder_no_id";
export const VARIANTS_NOT_IN_FOLDERS_ID = "variant_folder_no_id";
export const VARIABLES_NOT_IN_FOLDERS_ID = "variable_folder_no_id";

export const DEFAULT_PERMISSION_GROUP_NAMES: string[] = [
  ADMIN_DEFAULT_PERMISSION_GROUP_NAME,
  EDITOR_DEFAULT_PERMISSION_GROUP_NAME,
  COMMENTER_DEFAULT_PERMISSION_GROUP_NAME,
];

export const ADMIN_DEFAULT_PERMISSION_GROUP: DefaultPermissionGroup = {
  _id: ADMIN_DEFAULT_PERMISSION_GROUP_NAME,
  name: "Admin",
  built_in: true,
  permissions: {
    resources: [],
    general: [
      { action: "users:edit:admin" },
      { action: "users:edit:editor" },
      { action: "users:edit:commenter" },
      { action: "users:delete" },
      { action: "billing:edit" },
      { action: "users:edit:admin" },
      { action: "users:edit:editor" },
      { action: "users:edit:commenter" },
      { action: "project_folder:create" },
      { action: "project_folder:edit_all" },
      { action: "project_folder:comment_all" },
      { action: "component_folder:create" },
      { action: "component_folder:edit_all" },
      { action: "component_folder:comment_all" },
      { action: "variant_folder:create" },
      { action: "variant_folder:edit_all" },
      { action: "variant_folder:comment_all" },
      { action: "variable_folder:create" },
      { action: "variable_folder:edit_all" },
      { action: "variable_folder:comment_all" },
      { action: "permissions:edit" },
    ],
  },
};

export const EDITOR_DEFAULT_PERMISSION_GROUP: DefaultPermissionGroup = {
  _id: EDITOR_DEFAULT_PERMISSION_GROUP_NAME,
  name: "Editor",
  built_in: true,
  permissions: {
    resources: [],
    general: [
      { action: "users:edit:editor" },
      { action: "users:edit:commenter" },
      { action: "project_folder:create" },
      { action: "project_folder:edit_all" },
      { action: "project_folder:comment_all" },
      { action: "component_folder:create" },
      { action: "component_folder:edit_all" },
      { action: "component_folder:comment_all" },
      { action: "variant_folder:create" },
      { action: "variant_folder:edit_all" },
      { action: "variant_folder:comment_all" },
      { action: "variable_folder:create" },
      { action: "variable_folder:edit_all" },
      { action: "variable_folder:comment_all" },
    ],
  },
};

export const COMMENTER_DEFAULT_PERMISSION_GROUP: DefaultPermissionGroup = {
  _id: COMMENTER_DEFAULT_PERMISSION_GROUP_NAME,
  name: "Commenter",
  built_in: true,
  permissions: {
    resources: [],
    general: [
      { action: "project:comment:all" },
      { action: "project_folder:comment_all" },
      { action: "component_folder:comment_all" },
      { action: "variant_folder:comment_all" },
      { action: "variable_folder:comment_all" },
    ],
  },
};

export const DEFAULT_PERMISSION_GROUPS = [
  ADMIN_DEFAULT_PERMISSION_GROUP,
  EDITOR_DEFAULT_PERMISSION_GROUP,
  COMMENTER_DEFAULT_PERMISSION_GROUP,
];
