import { useCompSuggestionNudge } from "@shared/frontend/useCompSuggestionNudge";
import useUserFlags from "./useUserFlags";

const useCompSuggestionNudgeWebapp = (projectId: string) => {
  const { getUserFlag, setUserFlag } = useUserFlags();

  const { state, actions } = useCompSuggestionNudge(getUserFlag, setUserFlag)(projectId);

  return { state, actions };
};

export default useCompSuggestionNudgeWebapp;
