import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classnames from "classnames";
import React from "react";

import style from "./style.module.css";

const ComponentPagination = ({
  pageNumber,
  pageTotal,
  frameTotal,
  pageFrameLimit,
  setPageNumber,
  searchFilterEnabled,
}) => {
  const previousPage = () => {
    if (pageNumber > 0) setPageNumber((prev) => prev - 1);
  };
  const nextPage = () => {
    if (pageNumber < pageTotal - 1) setPageNumber((prev) => prev + 1);
  };
  const currFrameRangeStart = pageNumber * pageFrameLimit + 1;
  const currFrameRangeEnd = frameTotal === 0 ? 0 : Math.min(currFrameRangeStart + pageFrameLimit - 1, frameTotal);

  if (pageTotal <= 1) return null;
  return (
    <div className={style.paginationWrapper}>
      <div className={style.paginationLabel}>
        {!searchFilterEnabled && <div className={style.paginationIcon} />}
        <span>
          Displaying Frames <strong>{`${currFrameRangeStart}-${currFrameRangeEnd}`}</strong>
          {" of "}
          <strong>{frameTotal}</strong>
        </span>
      </div>
      <div className={style.paginationBtnWrapper}>
        <div
          className={classnames({
            [style.paginationBtn]: true,
            [style.disabled]: pageNumber === 0,
          })}
          onClick={previousPage}
        >
          <ChevronLeftIcon className={style.pageinationArrow} />
        </div>
        <div
          className={classnames({
            [style.paginationBtn]: true,
            [style.disabled]: pageNumber === pageTotal - 1,
          })}
          onClick={nextPage}
        >
          <ChevronRightIcon className={style.pageinationArrow} />
        </div>
      </div>
    </div>
  );
};

export default ComponentPagination;
