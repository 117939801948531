import React from "react";
const GridViewRoundedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M200-520q-24.538 0-42.268-17.73-17.731-17.731-17.731-42.269V-760q0-24.538 17.731-42.268 17.73-17.731 42.268-17.731h180.001q24.538 0 42.269 17.731Q440-784.538 440-760v180.001q0 24.538-17.73 42.269Q404.539-520 380.001-520H200Zm0 379.999q-24.538 0-42.268-17.731-17.731-17.73-17.731-42.268v-180.001q0-24.538 17.731-42.269Q175.462-440 200-440h180.001q24.538 0 42.269 17.73Q440-404.539 440-380.001V-200q0 24.538-17.73 42.268-17.731 17.731-42.269 17.731H200ZM579.999-520q-24.538 0-42.269-17.73Q520-555.461 520-579.999V-760q0-24.538 17.73-42.268 17.731-17.731 42.269-17.731H760q24.538 0 42.268 17.731 17.731 17.73 17.731 42.268v180.001q0 24.538-17.731 42.269Q784.538-520 760-520H579.999Zm0 379.999q-24.538 0-42.269-17.731Q520-175.462 520-200v-180.001q0-24.538 17.73-42.269Q555.461-440 579.999-440H760q24.538 0 42.268 17.73 17.731 17.731 17.731 42.269V-200q0 24.538-17.731 42.268-17.73 17.731-42.268 17.731H579.999Z" />
    </svg>
  );
};

export default GridViewRoundedIcon;
