import axios, { AxiosResponse } from "axios";
import Stripe from "stripe";
import http from "./lib/client";

const url = (path: string) => `/billing${path}`;

export interface ValidateBillingStatusResponse {
  plan: string;
  daysOnTrial?: number;
  showPaywall: boolean;
  numEditorUsers: number;
  numEditorSeats: number;
  numCommenterUsers: number;
  numCommenterSeats: number;
  devTools: boolean;
  daysOnDevToolsTrial?: number;
}

const validateBillingStatus = (): [Promise<AxiosResponse<ValidateBillingStatusResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<ValidateBillingStatusResponse>(url("/validate_billing_status"), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export interface Renew {
  date: number;
}

export interface Downgrade {
  date: number;
  plan: "free" | "team";
}

export type RenewalStatus = Downgrade | Renew | undefined;
export interface GetSeatsResponse {
  plan: string;
  numMonthlyEditorSeats: number;
  numYearlyEditorSeats: number;
  numMonthlyCommenterSeats: number;
  numYearlyCommenterSeats: number;
  yearlyDevTools: boolean;
  monthlyDevTools: boolean;
  renewalDateEditorMonthly?: RenewalStatus;
  renewalDateEditorYearly?: RenewalStatus;
  renewalDateCommenterMonthly?: RenewalStatus;
  renewalDateCommenterYearly?: RenewalStatus;
  renewalDateDevToolsMonthly?: RenewalStatus;
  renewalDateDevToolsYearly?: RenewalStatus;
  yearlySubscription?: Stripe.Subscription;
  monthlySubscription?: Stripe.Subscription;
}

export interface GetSeatsResponseOld {
  plan: string;
  numMonthlyEditorSeats: number;
  numYearlyEditorSeats: number;
  numMonthlyCommenterSeats: number;
  numYearlyCommenterSeats: number;
  devTools: boolean;
  renewalDateEditorMonthly?: number;
  renewalDateEditorYearly?: number;
  renewalDateCommenterMonthly?: number;
  renewalDateCommenterYearly?: number;
  renewalDateDevToolsMonthly?: number;
  renewalDateDevToolsYearly?: number;
  editorMonthlySeatPrice: number;
  commenterMonthlySeatPrice: number;
}

const getSeats = (): [Promise<AxiosResponse<GetSeatsResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<GetSeatsResponse>(url("/seats"), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

const getSeatsOld = (): [Promise<AxiosResponse<GetSeatsResponseOld>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<GetSeatsResponseOld>(url("/seats"), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

interface SeatCount {
  editors: number;
  commenters: number;
  devTools: boolean;
}

interface NewCustomerParams {
  email: string;
  name: string;
  paymentMethodId: string;
}

interface UpdateSeatsParams {
  monthly?: SeatCount;
  yearly?: SeatCount;
  plan: "free" | "trial" | "team" | "growth" | "enterprise";
  newCustomerParams?: NewCustomerParams;
}

export interface UpdateSubscriptionsResponse {
  plan: string;
  numMonthlyEditorSeats: number;
  numYearlyEditorSeats: number;
  numMonthlyCommenterSeats: number;
  numYearlyCommenterSeats: number;
  renewalDateEditorMonthly?: number;
  renewalDateEditorYearly?: number;
  renewalDateCommenterMonthly?: number;
  renewalDateCommenterYearly?: number;
}

const updateSubscriptions = (
  body: UpdateSeatsParams
): [Promise<AxiosResponse<UpdateSubscriptionsResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.put<UpdateSubscriptionsResponse>(url("/subscriptions"), body, {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

const previewUpcomingInvoice = (
  body: Stripe.InvoiceRetrieveUpcomingParams
): [Promise<AxiosResponse<Stripe.Invoice>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.put<Stripe.Invoice>(url("/previewUpcomingInvoice"), body, {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

interface StartDevToolsTrialResponse {}

const startDevToolsTrial = (): [Promise<AxiosResponse<StartDevToolsTrialResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.post<StartDevToolsTrialResponse>(
    url("/start_dev_tools_trial"),
    {},
    {
      cancelToken: source.token,
    }
  );

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export default {
  validateBillingStatus,
  updateSubscriptions,
  getSeats,
  getSeatsOld,
  previewUpcomingInvoice,
  startDevToolsTrial,
};
