import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { WEBSOCKET_EVENTS } from "../../../shared/common/constants";
import { WEBSOCKET_URL } from "../../../shared/types/websocket";
import { useChecklist } from "../HelpCenter/useChecklist";

export function useHelpCenter() {
  const [checklistState, fetchOnboardingChecklist] = useChecklist();
  const [isOpen, setIsOpen] = useState(false);
  const { getTokenSilently, user } = useAuthenticatedAuth();
  const variant = "base";

  const ref = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  useEffect(function addClickOutsideListener() {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;

      if (ref.current && !ref.current.contains(target) && buttonRef.current && !buttonRef.current.contains(target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  // Websocket setup
  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    async function sendWsCompsSubscribeMsg() {
      const subscribeToWsCompsMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_WS_COMPS_SUBSCRIPTION,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeToWsCompsMsg));
    }

    if (readyState === 1) {
      sendWsCompsSubscribeMsg();
      // Keep the websocket alive
    }
  }, [readyState]);

  // Websocket message handler
  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);
    if (data.messageType === WEBSOCKET_EVENTS.ONBOARDING_WORKSPACE_CHECKLIST_UPDATED) {
      const { key, workspaceId } = data;
      if (workspaceId === user.workspaceId) {
        fetchOnboardingChecklist();
      }
    }
  }, [lastMessage, user]);

  return {
    checklistState,
    isOpen,
    setIsOpen,
    ref: ref,
    buttonRef: buttonRef,
    variant,
  };
}
