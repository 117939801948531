import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import LockedFolderIcon from "../icons/LockedFolderIcon";
import FolderAccessList from "../permissions/FolderAccessList";
import InviteForm from "../permissions/InviteForm";
import style from "./style.module.css";
import { InviteFolderUserProps, useInviteFolderUsers } from "./useInviteFolderUsers";

const InviteFolderUsers = (props: InviteFolderUserProps) => {
  const { folder, currentUser } = props;
  const { hasNotInvitedUsers, usersWithAccess, invitedEmails, onUserRemoved, handleSendInvite, onComplete } =
    useInviteFolderUsers(props);
  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={onComplete}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          Add teammates to
          <div className={style.folderIconWrapper}>
            <LockedFolderIcon className={style.icon} />
            <div>{folder.name}</div>
          </div>
        </BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onComplete} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <InviteForm
          folderId={folder._id}
          currentUser={currentUser}
          invitedEmails={invitedEmails}
          handleSendInvite={handleSendInvite}
        />
        <div className={style.accessList}>
          <FolderAccessList
            currentUser={currentUser}
            usersWithAccess={usersWithAccess}
            onUserRemoved={onUserRemoved}
            allowSelfRemoval={false}
          />
        </div>
        <div className={style.footer}>
          {hasNotInvitedUsers && (
            <div className={style.skipBtn} onClick={onComplete}>
              Skip for now
            </div>
          )}
          <ButtonPrimary text="Finish" disabled={hasNotInvitedUsers} onClick={onComplete} />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default InviteFolderUsers;
