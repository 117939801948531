import MarkEmailUnread from "@/components/icons/MarkEmailUnreadIcon";
import FolderOutlined from "@mui/icons-material/FolderOutlined";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import React from "react";
import { IFProjectFolderSummary } from "../../../shared/types/http/Homepage";
import GridViewIcon from "../icons/GridViewIcon";
import style from "./style.module.css";

// Function to calculate the difference in days between two dates
function differenceInDays(date1: Date, date2?: Date) {
  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2?.getTime() || date1.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date1_ms - date2_ms;

  // Convert the difference back to days
  return Math.round(difference_ms / (1000 * 60 * 60 * 24));
}

export interface ProjectFolderCardProps {
  folder: IFProjectFolderSummary;
  className?: string;
  onClick?: () => void;
}

export default function ProjectFolderCard(props: ProjectFolderCardProps) {
  const { folder } = props;
  const daysSinceInvited = folder.invitedAt ? differenceInDays(new Date(), new Date(folder.invitedAt)) : 0;

  return (
    <div className={classNames(style.cardContainer, props.className)}>
      <div className={style.card} onClick={props.onClick}>
        <div className={style.folderIcons}>
          <FolderOutlined />
          <GridViewIcon />
        </div>
        <span className={style.folderName}>{folder.name}</span>
        <span className={style.projectCount}>
          <DittoComponent
            componentId="project-count"
            variables={{ count: folder.projectCount }}
            count={folder.projectCount}
          />
        </span>
      </div>
      <div className={style.footer}>
        {folder.invitedBy && (
          <span className={style.invited}>
            <MarkEmailUnread className={style.invitedIcon} />
            <DittoComponent
              componentId="invited-by-with-time-ago"
              variables={{
                numDays: daysSinceInvited,
                fullName: folder.invitedBy || "Your Teammate",
              }}
              count={daysSinceInvited}
            />
          </span>
        )}
      </div>
    </div>
  );
}
