import React from "react";
import http, { API } from "../../http";

// This function is used to create a deep link to the sample project data
// the link /project/sample is setup in the app.jsx file to render this component
// which will look up if the workspace has a sample project and if they do sends to the
// sample project, if not just sends them to the project page instead.
export function SampleProjectRedirect() {
  const redirect = async () => {
    const { url: docListUrl } = API.doc.get.list;
    const { data: documents } = await http.get(docListUrl);

    const sampleProject = documents.find((doc) => doc.isSample);
    if (sampleProject) {
      window.location.href = `/projects/${sampleProject._id}`;
    } else {
      window.location.href = "/projects";
    }
  };
  redirect();
  return <React.Fragment />;
}
