import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  picture: string | null | undefined;
  userName: string;
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Round image avatar; falls back to user initials if image is undefined.
 */
export function Avatar(props: IProps) {
  const size = props.size ?? "md";

  return (
    <div
      className={classNames(
        style.avatar,
        {
          [style[size]]: true,
        },
        props.className
      )}
      style={props.style}
      data-testid="user-icon"
    >
      {props.picture ? (
        <img src={props.picture} alt="user-icon" />
      ) : (
        <div className={style.userInitials}>{getInitials(props.userName)}</div>
      )}
    </div>
  );
}

function getInitials(name: string) {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0]}${lastName ? lastName[0] : ""}`;
}

export default Avatar;
