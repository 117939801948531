import { scroller } from "react-scroll";

const useScroll = ({
  containerId,
  offset,
  duration,
  timeout,
}: {
  containerId: string;
  offset?: number;
  duration?: number;
  timeout?: number;
}) => {
  const scrollOffset = offset || -100;
  const scrollDuration = duration || 500;
  const scrollTimeout = timeout || 50;

  const scrollToId = (id) => {
    let timeOut = scrollTimeout;
    const el = document.getElementById(id);
    if (!el) {
      timeOut = scrollTimeout + 50;
    }
    setTimeout(() => {
      if (el) {
        scroller.scrollTo(id, {
          duration: scrollDuration,
          smooth: true,
          containerId,
          offset: scrollOffset,
        });
      }
    }, timeOut);
  };
  return {
    scrollToId,
  };
};

export { useScroll };
