import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import classnames from "classnames";
import React from "react";
import style from "./pagination.module.css";

interface Props {
  page: number;
  pageSize: number;
  total: number;
  onPaginateBack?: () => void;
  onPaginateForward?: () => void;
}

export default function Pagination(props: Props) {
  const start = (props.page - 1) * props.pageSize + 1;
  const end = Math.min(start + props.pageSize - 1, start + (props.total - start));

  return (
    <div className={style.pagination}>
      <div
        className={classnames(style.paginationButton, {
          [style.disabled]: start === 1,
        })}
        onClick={props.onPaginateBack}
      >
        <ChevronLeft className={style.icon} />
      </div>
      <div className={style.paginationText}>
        {start}-{end} of {props.total}
      </div>
      <div
        className={classnames(style.paginationButton, {
          [style.disabled]: end === props.total,
        })}
        onClick={props.onPaginateForward}
      >
        <ChevronRight className={style.icon} />
      </div>
    </div>
  );
}
