import { IChangeItem, ZChangeItem } from "../types/ActualChange";
import logger from "./logger";
/**
 * Iterates over a list of objects, extracts and returns the valid IChangeItems
 * and logs an error for any invalid items.
 * @param items - An array of objects that we think are IChangeItems
 * @returns An array of the valid IChangeItems, with any invalid items removed
 */
export const validateChangeItems = (items: any[]): IChangeItem[] => {
  const validItems: IChangeItem[] = [];

  // Iterate over the response data and only keep the valid items
  for (const item of items) {
    const parsedItem = ZChangeItem.safeParse(item);
    if (parsedItem.success) {
      validItems.push(item);
    } else {
      // Log an error for any bad data we receive so we can investigate
      logger.error(
        `Received invalid project activity of type ${item.entry_type}`,
        { context: { item } },
        parsedItem.error
      );
    }
  }

  return validItems;
};
