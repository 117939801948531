import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import CompGroup from "../../../../components/compgroup/";
import SearchAndFilters from "../../../../components/searchAndFilters/searchAndFilters";

import { insertAt, removeFrom } from "../../../../../shared/utils/array";
import spinner from "../../../../assets/small-spinner.gif";
import http, { API } from "../../../../http";
import { isGroupUnlinkable } from "../../state/types";
import useDraftState, { DRAFT_BUTTON_GROUP_ID } from "../../state/useDraftState";
import { useProjectContext } from "../../state/useProjectState";

import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { useMemo } from "react";
import NewGroupCard from "../../../../components/card/NewGroupCard";
import SampleProjectBanner from "../../SampleProjectBanner";
import { UNSELECT_ALL_PROPS } from "../../state/unselectAll";
import { getProjectHasFigmaConnection } from "../../state/useResyncState";
import GroupDraft from "../GroupDraft";
import TextItemPagination from "./TextItemPagination";
import style from "./style.module.css";

const TextItemList = ({
  selectComp,
  selectAllCompsInGroup,
  selectedId,
  suggestedCompId,
  unselectAll,
  doc_name,
  doc_ID,
  variants,
  handleSelectFrameVariant,
  handleAddNewVariants,
  handleDeleteFrameVariant,
  multiOn,
  multiSelectedIds,
  groupState,
  groupStateDispatch,
  selectTag,
  clearSelectedTags,
  reorderFrames,
  updateFrames,
  setPanelState,
  setCommentState,
  setHidingAvailable,
  setMultiSelected,
  pagesByFrame,
  setUpdateDocHistory,
  figmaFileId,
  figmaAccessToken,
  allFrameApiIDs,
  displayApiIds,
  groupMeta,
  setGroupMeta,
  framePage,
  setFramePage,
  paginationFrameLimit,
  setSuggestedCompId,
  onDraftTextItemSelectionUpdate,
  setCreateTextItemPanelStatus,
  createTextItemPanelStatus,
  resyncLoading,
  pollingForPreviewUpdates,
  previewsLastUpdatedAt,
  isSampleProject,
  job,
  setJob,
  handleViewTour,
}) => {
  const blockMoveActions = useRef(false);
  const projectContext = useProjectContext();
  const draftState = useDraftState();

  const {
    framePreviewsMap,
    groupRenderState: {
      status: groupRenderStatus,
      groups: { filteredComps: filteredCompIds, searchFilteredGroups: groupsToRender },
    },
    selectedDraftGroupId: [selectedDraftGroupId],
    doc: [doc],
    groupIndicesByGroupId,
    registerFocusFunction,
    registerEditorReference,
    onDraftEditorDestroy,
    unsavedGroupChanges,
    setGroupHasUnsavedChanges,
    scrollToId: [scrollToId, setScrollToId],
    showSetupSuggestionsPreview,
    isShowingSetupSuggestionsFlow: showSetupFromAnotherGroup,
    groupWithSetupSuggestionsApplied,
    getActiveVariantIndexForGroup,
    setActiveVariantIndexForGroup,
    updateActiveVariantIndexForAllGroups,
  } = projectContext;

  const {
    query: [query, setQuery],
    assignee: [assignee, setAssignee],
    statusFilter: [statusFilter, setStatusFilter],
    tagState: [tagState],
    devID: [devID, setDevID],
    componentFilter: [componentFilter, setComponentFilter],
    variant: [variantFilter, setVariantFilter],
    searchFilterEnabled,
  } = projectContext.search;

  /**
   *
   * @param {{id:string;label:string}} variantFilter
   */
  const handleUpdateVariantFilter = (variantFilter) => {
    updateActiveVariantIndexForAllGroups(variantFilter?.id || null);

    setVariantFilter(variantFilter);
  };

  /**
   * This is a array where each element corresponds to the
   * group in state that has the same index. The element itself
   * is the number of components in the corresponding group
   * that are NOT in blocks.
   *
   * Used exclusively to render the "X other text items" text.
   */
  const [numCompsArray, setNumCompsArray] = useState([]);

  const collapsedContainsSuggestedComp = (comps) => {
    return comps.includes(suggestedCompId);
  };
  useEffect(() => {
    if (scrollToId !== null) {
      setStatusFilter("Any");
      setQuery("");
      setScrollToId(null);
    }
  }, [scrollToId]);

  const onToggleHidden = (groupId) =>
    setGroupMeta((groupMeta) => {
      const meta = groupMeta[groupId] || {};

      return {
        ...groupMeta,
        [groupId]: {
          hiddenOpen: !meta.hiddenOpen,
        },
      };
    });

  const NUM_FRAMES = groupsToRender.length;
  const NUM_PAGES = Math.ceil(NUM_FRAMES / paginationFrameLimit);

  const groupsToRenderPaginated = useMemo(
    () => groupsToRender.slice(framePage * paginationFrameLimit, (framePage + 1) * paginationFrameLimit),
    [groupsToRender, framePage, paginationFrameLimit]
  );

  useEffect(() => {
    if (framePage > NUM_FRAMES) setFramePage(0);
  }, [framePage]);

  useEffect(() => {
    if (groupState.finished || groupState.groups.length > 0) {
      var initialArray = new Array(groupState.groups.length).fill(0);
      for (const [index, artboard] of groupState.groups.entries()) {
        initialArray[index] = artboard.comps.length;
      }
      setNumCompsArray(initialArray);
    }
  }, [groupState]);

  useEffect(() => {
    if (statusFilter !== "Any") {
      unselectAll();
    }
  }, [statusFilter, tagState.selected]);

  const handleQueryChange = (value) => {
    unselectAll();
    setQuery(value);
    setFramePage(0);
  };

  const updateNumComps = (frameIndex) => {
    let newArray = [...numCompsArray];
    newArray[frameIndex] = groupState.groups[frameIndex].comps.length;
    setNumCompsArray(newArray);
  };

  const reorderBlockFrontend = (frameIndex, blockIndex, startIndex, endIndex) => {
    const newBlockList = reorder(groupState.groups[frameIndex].blocks[blockIndex].comps, startIndex, endIndex);
    var newArtboards = groupState.groups;
    newArtboards[frameIndex].blocks[blockIndex].comps = newBlockList;
    groupStateDispatch({ type: "REPLACE_GROUPS", groups: newArtboards });
  };

  // for reordering comps within a block
  const reorderBlock = async (result) => {
    const { destination, source, type } = result;
    const frameIndex = type.split("-")[1];
    const blockIndex = destination.droppableId.split(".")[1];
    const startIndex = source.index;
    const endIndex = destination.index;

    // update frontend before update backend
    reorderBlockFrontend(frameIndex, blockIndex, startIndex, endIndex);

    blockMoveActions.current = true;

    const textItem = (groupState.groups[frameIndex].blocks[blockIndex] || [])?.comps[0];

    const groupId = textItem?._meta?.groupId || null;

    const { url, body } = API.doc.post.reorderBlock;
    await http.post(
      url,
      body({
        doc_ID,
        groupId,
        blockIndex,
        startIndex,
        endIndex,
      })
    );
    blockMoveActions.current = false;
  };

  const addToBlockFrontend = (frameIndex, blockIndex, compIndex, compID) => {
    const currentCompIndex = groupState.groups[frameIndex].comps.findIndex((compObj) => compObj._id === compID);
    // update frontend
    let newGroups = groupState.groups;
    const compsList = newGroups[frameIndex].comps;
    // save comp being moved
    const compBeingMoved = compsList[currentCompIndex];
    // remove from comps list
    removeFrom(newGroups[frameIndex].comps, currentCompIndex, 1);
    // add to new Block
    insertAt(newGroups[frameIndex].blocks[blockIndex].comps, compIndex, compBeingMoved);
    groupStateDispatch({ type: "REPLACE_GROUPS", groups: newGroups });
    if (selectedId === compID) {
      setHidingAvailable(false);
    }
  };

  const getGroupId = (groupIndex) => {
    const group = groupState.groups[groupIndex];
    const groupId = group._id;

    if (!groupId) {
      throw new Error("Group identifier could not be found at index " + groupIndex);
    }

    return groupId;
  };

  const getBlockId = (groupIndex, blockIndex) => {
    const block = groupState.groups[groupIndex].blocks[blockIndex];
    const blockId = block?._id;

    if (!blockId) {
      throw new Error("Block identifier could not be found at index " + blockIndex);
    }

    return blockId;
  };

  const addToBlock = async (frameIndex, blockIndex, compIndex, compID) => {
    const groupId = getGroupId(frameIndex);
    const blockId = getBlockId(frameIndex, blockIndex);

    addToBlockFrontend(frameIndex, blockIndex, compIndex, compID);
    blockMoveActions.current = true;

    const { url, body } = API.doc.post.addToBlock;
    await http.post(
      url,
      body({
        doc_ID,
        groupId,
        blockId,
        compIndex,
        compID,
      })
    );
    blockMoveActions.current = false;
  };

  const removeFromBlockFrontend = (frameIndex, blockIndex, compID, compIndex) => {
    let newGroups = groupState.groups;
    const compBeingMoved = newGroups[frameIndex].blocks[blockIndex].comps[compIndex];
    removeFrom(newGroups[frameIndex].blocks[blockIndex].comps, compIndex, 1);
    insertAt(newGroups[frameIndex].comps, newGroups[frameIndex].comps.length, compBeingMoved);
    groupStateDispatch({ type: "REPLACE_GROUPS", groups: newGroups });
    if (selectedId === compID) {
      setHidingAvailable(true);
    }
  };

  const removeFromBlock = async (frameIndex, blockIndex, compID, compIndex) => {
    const groupId = getGroupId(frameIndex);

    removeFromBlockFrontend(frameIndex, blockIndex, compID, compIndex);

    blockMoveActions.current = true;
    const { url, body } = API.doc.post.removeFromBlock;
    await http.post(url, body({ doc_ID, groupId, blockIndex, compID }));
    blockMoveActions.current = false;
  };

  const moveBetweenBlocksFrontend = (
    frameIndex,
    origBlockIndex,
    origCompIndex,
    newBlockIndex,
    newCompIndex,
    compID
  ) => {
    let newGroups = groupState.groups;
    if (
      newGroups &&
      newGroups[frameIndex] &&
      newGroups[frameIndex].blocks &&
      newGroups[frameIndex].blocks[origBlockIndex] &&
      newGroups[frameIndex].blocks[origBlockIndex].comps
    ) {
      const compBeingMoved = newGroups[frameIndex].blocks[origBlockIndex].comps[origCompIndex];
      removeFrom(newGroups[frameIndex].blocks[origBlockIndex].comps, origCompIndex, 1);
      insertAt(newGroups[frameIndex].blocks[newBlockIndex].comps, newCompIndex, compBeingMoved);
      groupStateDispatch({ type: "REPLACE_GROUPS", groups: newGroups });
    }
  };

  const moveBetweenBlocks = async (frameIndex, origBlockIndex, origCompIndex, newBlockIndex, newCompIndex, compID) => {
    const groupId = getGroupId(frameIndex);

    moveBetweenBlocksFrontend(frameIndex, origBlockIndex, origCompIndex, newBlockIndex, newCompIndex, compID);

    blockMoveActions.current = true;
    const { url, body } = API.doc.post.moveBetweenBlocks;
    await http.post(
      url,
      body({
        doc_ID,
        groupId,
        origBlockIndex,
        newBlockIndex,
        newCompIndex,
        compID,
      })
    );
    blockMoveActions.current = false;
  };

  // for if a comp is moving from one block to another or from the comps to a block
  const moveBlockedComp = (result) => {
    const { destination, source, type, draggableId } = result;
    const frameIndex = type.split("-")[1];
    const compID = draggableId;

    // comps to block
    if (source.droppableId.includes("compsection")) {
      const blockIndex = destination.droppableId.split(".")[1];
      const compIndex = destination.index;
      addToBlock(frameIndex, blockIndex, compIndex, compID);
    }
    //block to comps
    else if (destination.droppableId.includes("compsection")) {
      const blockIndex = source.droppableId.split(".")[1];
      const compIndex = source.index;
      removeFromBlock(frameIndex, blockIndex, compID, compIndex);
    }
    //block to block
    else {
      const origBlockIndex = source.droppableId.split(".")[1];
      const origCompIndex = source.index;
      const newBlockIndex = destination.droppableId.split(".")[1];
      const newCompIndex = destination.index;
      moveBetweenBlocks(frameIndex, origBlockIndex, origCompIndex, newBlockIndex, newCompIndex, compID);
    }
  };

  function onDragEnd(result) {
    if (blockMoveActions.current) return;
    const { destination, source, type } = result;
    if (!destination) {
      return;
    }

    if (type === "FRAME") {
      if (destination.index === source.index) {
        return;
      }
      let reordered = reorder(groupState.groups, source.index, destination.index);
      reorderFrames(reordered);
      return;
    } else if (type.includes("COMP")) {
      if (destination.droppableId === source.droppableId) {
        //TODO: check if can disable dragging and dropping at all within compsection
        if (destination.index === source.index || source.droppableId.includes("compsection")) {
          return;
        }
        reorderBlock(result);
      } else {
        moveBlockedComp(result);
      }
      //update comps count
      const frameIndex = type.split("-")[1];
      updateNumComps(frameIndex);
    }
  }

  const reorder = (oldList, startIndex, endIndex) => {
    const result = Array.from(oldList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const hasLinkableDraftGroups = groupsToRenderPaginated.some((group) => group.linking_enabled);

  const hasNonQueryFiltersApplied = Boolean(
    statusFilter !== "Any" || tagState.selected.size || assignee !== null || variantFilter || componentFilter
  );

  const hasAnyFilterApplied = hasNonQueryFiltersApplied || query;

  const showCreateNewDraftGroupButton = groupRenderStatus === "draft" && !hasAnyFilterApplied;

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const effectiveGroupsToRenderPaginated = useMemo(() => {
    if (!showSetupSuggestionsPreview || !showSetupFromAnotherGroup) {
      return groupsToRenderPaginated;
    } else {
      return groupsToRenderPaginated.map((group) => {
        if (groupWithSetupSuggestionsApplied && group._id === groupWithSetupSuggestionsApplied._id) {
          return groupWithSetupSuggestionsApplied;
        } else {
          return { ...group, isSetupPreview: false };
        }
      });
    }
  }, [
    showSetupSuggestionsPreview,
    showSetupFromAnotherGroup,
    groupsToRenderPaginated,
    groupWithSetupSuggestionsApplied,
  ]);

  return (
    <div id="scrollArea" className={style.compList} onClick={() => setSuggestedCompId(null)}>
      <SearchAndFilters
        query={query}
        setQuery={setQuery}
        devID={devID}
        setDevID={setDevID}
        onChange={handleQueryChange}
        name={doc_name}
        loading={false}
        docSearch={true}
        status={hasLinkableDraftGroups ? statusFilter : null}
        chooseStatus={setStatusFilter}
        assignee={assignee}
        setAssignee={setAssignee}
        tagState={tagState}
        clearSelectedTags={clearSelectedTags}
        selectTag={selectTag}
        fullWidth={false}
        filteredCompIds={filteredCompIds}
        multiSelectedIds={multiSelectedIds}
        setMultiSelected={setMultiSelected}
        scrollToId={scrollToId}
        normalFiltersHidden={!hasLinkableDraftGroups}
        variantFilter={variantFilter}
        variantFilterConfig={{ type: "project", projectId: doc_ID }}
        componentFilter={componentFilter}
        setComponentFilter={setComponentFilter}
        setVariantFilter={handleUpdateVariantFilter}
      />
      <div id="projectContainer" className={style.componentList} {...UNSELECT_ALL_PROPS}>
        {isSampleProject && <SampleProjectBanner job={job} setJob={setJob} handleViewTour={handleViewTour} />}
        <div className={classnames("container", style.container)} {...UNSELECT_ALL_PROPS}>
          {isEditEnabled && showCreateNewDraftGroupButton && (
            <NewGroupCard
              text="Create new draft frame"
              disabled={resyncLoading}
              action={() => draftState.onNewGroup(DRAFT_BUTTON_GROUP_ID)}
            />
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={doc_name} type="FRAME">
              {(provided) => (
                <div className="row" ref={provided.innerRef} {...provided.droppableProps}>
                  {effectiveGroupsToRenderPaginated.map((group, groupIndex) => {
                    if (isGroupUnlinkable(group)) {
                      const isSelected = selectedDraftGroupId === group._id.toString();

                      return (
                        <div key={group._id} className={style.groupUnlinkableContainer} {...UNSELECT_ALL_PROPS}>
                          <GroupDraft
                            key={group._id}
                            group={group}
                            className={classnames({
                              [style.groupUnlinkable]: true,
                              [style.groupUnlinkableInactive]: !isSelected,
                            })}
                            onDestroy={onDraftEditorDestroy}
                            registerFocusFunction={registerFocusFunction}
                            registerEditorReference={registerEditorReference}
                            onSelectionUpdate={onDraftTextItemSelectionUpdate}
                            developerModeEnabled={doc?.developer_mode_enabled || false}
                            isReadOnly={!isEditEnabled}
                            highlight={query}
                            hasUnsavedChanges={unsavedGroupChanges[group._id]}
                            setHasUnsavedChanges={setGroupHasUnsavedChanges}
                            {...draftState}
                          />
                          {isEditEnabled && isSelected && (
                            <div
                              className={style.newDraftGroupButton}
                              onClick={() => draftState.onNewGroup(selectedDraftGroupId)}
                            >
                              <div>Click here to create a new frame</div>
                            </div>
                          )}
                        </div>
                      );
                    }

                    const frameId = group.integrations.figma.frame_id;
                    const framePreviews = framePreviewsMap[frameId]?.previews;

                    const imgUrl = framePreviews?.fullsize || null;

                    const activeVariantIndex = getActiveVariantIndexForGroup(group._id);

                    return (
                      <CompGroup
                        key={group._id}
                        activeVariantIndex={activeVariantIndex}
                        setActiveVariantIndex={(index) => setActiveVariantIndexForGroup(group._id, index)}
                        // This index property requires the index of the group relative to all
                        // groups that are loaded in state, not just groups that are currently
                        // being rendered
                        index={groupIndicesByGroupId.get(group._id.toString())}
                        pageIndex={framePage}
                        groupIndex={groupIndex}
                        scrollToId={scrollToId}
                        setGroupMeta={setGroupMeta}
                        onToggleHidden={onToggleHidden}
                        hiddenOpen={(groupMeta[group._id] || {}).hiddenOpen}
                        pinned={group.pinned}
                        groupId={group._id}
                        name={group.name}
                        blocks={group.blocks ? group.blocks : []}
                        imgUrl={imgUrl}
                        comps={group.comps}
                        appliedVariantId={group.integrations.figma.applied_variant_id}
                        variants={variants}
                        frameVariants={variants.frameVariants[group._id]}
                        workspaceVariants={variants.workspaceVariants}
                        handleSelectFrameVariant={handleSelectFrameVariant}
                        handleAddNewVariants={handleAddNewVariants}
                        handleDeleteFrameVariant={handleDeleteFrameVariant}
                        doc_ID={doc_ID}
                        selectComp={selectComp}
                        selectAllCompsInGroup={selectAllCompsInGroup}
                        selectedId={selectedId}
                        suggestedCompId={suggestedCompId}
                        handleHistoryUpdate={setUpdateDocHistory}
                        query={query}
                        assignee={assignee}
                        devIDFilter={devID}
                        componentFilter={componentFilter}
                        multiOn={multiOn}
                        multiSelectedIds={multiSelectedIds}
                        status={statusFilter}
                        tagState={tagState}
                        setPanelState={setPanelState}
                        setCommentState={setCommentState}
                        isLast={groupState.groups.length === group.idx + 1}
                        figmaId={group.integrations.figma.frame_id}
                        artboardsLoadingFinished={groupState.finished}
                        nonblocks_collapsed={
                          !collapsedContainsSuggestedComp(group.comps.map((c) => c._id)) && group.nonblocks_collapsed
                        }
                        numCompsArray={numCompsArray}
                        updateFrames={updateFrames}
                        pageName={
                          pagesByFrame && pagesByFrame[group.integrations.figma.frame_id]
                            ? pagesByFrame[group.integrations.figma.frame_id].pageName
                            : ""
                        }
                        doc_name={doc_name}
                        figmaFileId={figmaFileId}
                        figmaAccessToken={figmaAccessToken}
                        apiID={group.apiID}
                        allFrameApiIDs={allFrameApiIDs}
                        displayApiIds={displayApiIds}
                        group={group}
                        devModeEnabled={doc?.developer_mode_enabled || false}
                        groupStateDispatch={groupStateDispatch}
                        setCreateTextItemPanelStatus={setCreateTextItemPanelStatus}
                        showAddTextPlaceholder={
                          createTextItemPanelStatus.show && createTextItemPanelStatus.groupId === group._id
                        }
                        blockMoveActions={blockMoveActions}
                        isSetupPreview={group.isSetupPreview}
                        showSetupFromAnotherGroup={showSetupFromAnotherGroup}
                        pollingForPreviewUpdates={pollingForPreviewUpdates}
                        previewsLastUpdatedAt={previewsLastUpdatedAt}
                        resyncLoading={resyncLoading}
                        isSampleProject={doc.isSample}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {groupState.fetching && (
            <div className={style.loading}>
              <img src={spinner} /> Getting more groups...
            </div>
          )}
          {groupState.finished && groupRenderStatus === "linked" && (
            <div className={style.loading}>
              {!groupsToRenderPaginated.length && (
                <span>
                  <b>No groups to display</b>
                  <br />
                  You can add more groups to your project by clicking "Manage" in the groups navigation
                  {getProjectHasFigmaConnection(doc) && " or via our Figma plugin"}.
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <TextItemPagination
        pageNumber={framePage}
        setPageNumber={setFramePage}
        pageTotal={NUM_PAGES}
        frameTotal={NUM_FRAMES}
        pageFrameLimit={paginationFrameLimit}
        searchFilterEnabled={searchFilterEnabled}
      />
    </div>
  );
};

export default TextItemList;
