import AutorenewIcon from "@mui/icons-material/Autorenew";
import classNames from "classnames";
import React from "react";

import style from "./ImagePreviewSpinner.module.css";

type SupportedDataProperties = "data-for" | "data-tip";

interface BaseProps {
  className?: string;
}

type DataProps = {
  [key in SupportedDataProperties]?: string | boolean;
};

type Props = BaseProps & DataProps;

const ImagePreviewSpinner = (props: Props) => {
  return (
    <AutorenewIcon
      data-tip={props["data-tip"]}
      data-for={props["data-for"]}
      className={classNames([style.spinning, props.className])}
    />
  );
};

export default ImagePreviewSpinner;
