import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import classnames from "classnames";
import React, { useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

enum ModalType {
  MAIN_FILE = "MAIN_BRANCH",
  BRANCH_FILE_NO_MAIN = "BRANCH_FILE_NO_MAIN",
  BRANCH_FILE_WITH_MAIN = "BRANCH_FILE_WITH_MAIN",
}
type ProjectInfo = {
  _id: string;
  name: string;
  figmaURL: string;
} | null;

type ProjectBranchInfo = {
  isBranch: boolean;
  branchProjectInfo: ProjectInfo;
  mainProjectInfo: ProjectInfo;
};
interface Props {
  isSaving: boolean;
  projectId: string;
  projectBranchInfo: ProjectBranchInfo | null;
  onHide: () => void;
  handleMergeBranch: (shouldMerge: boolean) => void;
}

const MergeBranchModal: React.FC<Props> = ({ isSaving, projectId, projectBranchInfo, onHide, handleMergeBranch }) => {
  const [mergeChecked, setMergeChecked] = useState<boolean>(false);
  const [discardChecked, setDiscardChecked] = useState<boolean>(false);

  const onDiscardChange = (e: React.SyntheticEvent) => {
    if (mergeChecked) setMergeChecked(false);
    setDiscardChecked((prev) => !prev);
  };

  const onMergeChange = (e: React.SyntheticEvent) => {
    if (discardChecked) setDiscardChecked(false);
    setMergeChecked((prev) => !prev);
  };

  const onSubmit = () => {
    handleMergeBranch(mergeChecked);
  };

  const mergeModalType = useMemo(() => {
    if (projectBranchInfo?.isBranch) {
      if (projectBranchInfo.mainProjectInfo) {
        return ModalType.BRANCH_FILE_WITH_MAIN;
      } else {
        return ModalType.BRANCH_FILE_NO_MAIN;
      }
    } else {
      return ModalType.MAIN_FILE;
    }
  }, [projectBranchInfo]);

  const inputCopy = useMemo(() => {
    switch (mergeModalType) {
      case ModalType.BRANCH_FILE_WITH_MAIN:
        return {
          description: (
            <p>
              We’ve detected that{" "}
              <a href={projectBranchInfo?.branchProjectInfo?.figmaURL} target="_blank">
                the Figma branch file
              </a>{" "}
              this project is connected to no longer exists, meaning it was either merged or deleted.
            </p>
          ),
          mergedCopy: (
            <p>
              Changes made in this project will be merged into{" "}
              <a className={style.link} href={`/projects/${projectBranchInfo?.mainProjectInfo?._id}`} target="_blank">
                the Ditto project
              </a>{" "}
              that’s connected to the main branch of this Figma file. This current project will be archived.
            </p>
          ),
          deletedCopy: (
            <p>
              Changes made in this project will be deleted and{" "}
              <a className={style.link} href={`/projects/${projectBranchInfo?.mainProjectInfo?._id}`} target="_blank">
                the Ditto project
              </a>{" "}
              connected to the main branch will be unlocked.
            </p>
          ),
        };
      case ModalType.MAIN_FILE:
        return {
          description: (
            <p>
              We’ve detected that a branch of this Figma file (
              <a className={style.link} href={`/projects/${projectBranchInfo?.branchProjectInfo?._id}`} target="_blank">
                {projectBranchInfo?.branchProjectInfo?.name}
              </a>
              ) no longer exists, meaning it has been been merged or discarded.
            </p>
          ),
          mergedCopy: (
            <p>
              The{" "}
              <a className={style.link} href={`/projects/${projectBranchInfo?.branchProjectInfo?._id}`} target="_blank">
                Ditto project
              </a>{" "}
              connected to the branch will be archived, and this project will be unlocked and updated with that branch’s
              changes.
            </p>
          ),
          deletedCopy: (
            <p>
              The{" "}
              <a className={style.link} href={`/projects/${projectBranchInfo?.branchProjectInfo?._id}`} target="_blank">
                Ditto project
              </a>{" "}
              connected to the branch will be deleted, and this project will be unlocked.
            </p>
          ),
        };
      default:
        return {
          description: (
            <p>
              We’ve detected that{" "}
              <a href={projectBranchInfo?.branchProjectInfo?.figmaURL} target="_blank">
                the Figma branch file
              </a>{" "}
              this project is connected to no longer exists, meaning it was either merged or deleted.
            </p>
          ),
          mergedCopy: <p>This Ditto project will be re-linked to the main branch of the Figma file.</p>,
          deletedCopy: (
            <p>
              All changes made in this project will be deleted, and this project will start from scratch with the main
              branch.
            </p>
          ),
        };
    }
  }, [mergeModalType]);

  return (
    <BootstrapModal show={true} centered onHide={() => {}} className={classnames({ [style.modalLoading]: isSaving })}>
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          <span className={style.title}>Merge changes from Figma branch?</span>
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.modalBody}>
        <div>
          {inputCopy.description}
          <p>Please select which has occurred below, and we’ll update your project accordingly.</p>
          <div>
            <div className={style.formGroup}>
              <input type="radio" name="merge" checked={mergeChecked} onChange={onMergeChange} disabled={isSaving} />
              <div>
                <label htmlFor="merge">The Figma branch has been merged.</label>
                {inputCopy.mergedCopy}
              </div>
            </div>
            <div className={style.formGroup}>
              <input
                type="radio"
                name="discard"
                checked={discardChecked}
                onChange={onDiscardChange}
                disabled={isSaving}
              />
              <div>
                <label htmlFor="discard">The Figma branch has been deleted.</label>
                {inputCopy.deletedCopy}
              </div>
            </div>
          </div>
          {(mergeChecked || discardChecked) && (
            <p className={style.confirmWarning}>
              <ReportProblemOutlinedIcon className={style.icon} />
              Make sure you have permission to access{" "}
              <a href={projectBranchInfo?.branchProjectInfo?.figmaURL} target="_blank">
                the Figma branch
              </a>{" "}
              before confirming. These changes cannot be undone.
            </p>
          )}
        </div>
        <div className={style.footer}>
          <ButtonPrimary
            buttonStyles={{}}
            text={!isSaving ? "Confirm" : "Updating project..."}
            disabled={isSaving || (!mergeChecked && !discardChecked)}
            onClick={onSubmit}
          />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default MergeBranchModal;
