// Parse through the value of the TextInput
// to get the text and variables for each plural form
export const parsePluralInput = (inputValue, defaultText) => {
  const baseText =
    inputValue?.current?.[0]?.value?.text !== null ? inputValue.current[0].value.text || "" : defaultText || "";
  const baseRichText = inputValue?.current?.[0]?.value?.richText;
  const baseVariables = inputValue?.current?.[0]?.value?.variables || [];
  const pluralsOptions = inputValue?.current.map(({ form, value: { text, variables, richText } }) => ({
    form,
    text,
    rich_text: richText,
    variables,
  }));

  let allVariables = [];
  const plurals = [];
  // iterate over plurals if they exist, to concat all their unique variable instances into a single
  // variable array for storage, and only submit the plurals with their variable_ids rather than the full instance
  if (pluralsOptions[0]?.form) {
    const variableMap = {};
    baseVariables.forEach((variable) => {
      variableMap[variable.variable_id] = variable;
    });
    pluralsOptions.forEach((plural) => {
      const variableIds = [];
      plural.variables.forEach((variable) => {
        variableMap[variable.variable_id] = variable;
        variableIds.push(variable.variable_id);
      });
      plurals.push({
        ...plural,
        variables: variableIds,
      });
    });
    allVariables = Object.values(variableMap);
  } else {
    allVariables = baseVariables;
  }
  return {
    allVariables,
    baseText,
    baseRichText,
    plurals,
  };
};
