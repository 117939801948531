import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import ErrorIcon from "@mui/icons-material/Error";
import classnames from "classnames";
import { matchSorter } from "match-sorter";
import React, { useMemo, useRef } from "react";
import ReactTags from "react-tag-autocomplete";
import { EMAIL_REGEX } from "../../../../shared/utils/email";
import EmailTag from "./EmailTag";
import UserSuggestion from "./UserSuggestion";
import style from "./style.module.css";
import { InviteUsersInputProps } from "./types";

const WHITE_SPACE_REGEX = new RegExp(/\s/g);
const InviteUsersInput = (props: InviteUsersInputProps) => {
  const { disabled, currentUserRole, existingInvitedEmails, invitedUserList, userList, onUserInvited, onUserRemoved } =
    props;

  const {
    workspaceInfo: { plan },
  } = useWorkspace();

  const { user } = useAuthenticatedAuth();

  const reactTags = useRef();
  const userInviteTags = useMemo(() => {
    return invitedUserList.map(({ email, type, name, role, isAlreadyInvited }, index) => ({
      id: index,
      type,
      name,
      email,
      role,
      permissionGroups: [role],
      isValid: EMAIL_REGEX.test(email),
      canInvite: currentUserRole !== "commenter" || role !== "editor",
      isAlreadyInvited,
    }));
  }, [invitedUserList, currentUserRole]);

  const currentUserSuggestions = useMemo(() => {
    return userList
      .map((listUser) => ({
        _id: listUser._id,
        type: "existing",
        name: listUser.name,
        email: listUser.email,
        role: listUser.role,
      }))
      .filter(({ email }) => {
        return invitedUserList.findIndex((userInvite) => userInvite.email === email) === -1 && email !== user.email;
      });
  }, [userList, invitedUserList, currentUserRole]);

  const suggestionsTransform = (query, suggestions) => {
    const searchQuery = query.toLowerCase();
    const isValidEmail = !WHITE_SPACE_REGEX.test(searchQuery) && EMAIL_REGEX.test(searchQuery);
    const canInviteEmail =
      isValidEmail &&
      !userList.some((user) => user.email.toLocaleLowerCase() === searchQuery) &&
      !invitedUserList.some((user) => user?.email?.toLocaleLowerCase() === searchQuery) &&
      !existingInvitedEmails[searchQuery];

    let allSuggestions = suggestions;

    if (canInviteEmail) {
      const adminInvite = {
        _id: "__new__",
        type: "new",
        role: "admin",
        permissionGroups: ["admin"],
        name: searchQuery,
        email: searchQuery,
      };
      const editorInvite = {
        _id: "__new__",
        type: "new",
        role: "editor",
        permissionGroups: ["editor"],
        name: searchQuery,
        email: searchQuery,
      };
      const commenterInvite = {
        _id: "__new__",
        type: "new",
        role: "commenter",
        permissionGroups: ["commenter"],
        name: searchQuery,
        email: searchQuery,
      };
      allSuggestions = [adminInvite, editorInvite, commenterInvite, ...suggestions];
      allSuggestions = allSuggestions.filter(({ role }) => {
        const isFreeTierCommenterInvite = plan === "free" && role === "commenter";
        if (isFreeTierCommenterInvite) return false;

        const canInviteRole =
          currentUserRole === "admin" ||
          (currentUserRole === "editor" && role !== "admin") ||
          (currentUserRole === "commenter" && role === "commenter");

        return canInviteRole;
      });
    }

    return matchSorter(allSuggestions, searchQuery, {
      keys: ["email", "name"],
      sorter: (rankedItems) => {
        return rankedItems.sort((a, b) => {
          // @ts-ignore
          if (a.item.type === b.item.type) return 0;
          // @ts-ignore
          else if (a.item?.type === "existing") return -1;
          return 1;
        });
      },
    });
  };

  const invalidEmailPresent = useMemo(() => userInviteTags.some((invite) => !invite.isValid), [userInviteTags]);

  const invalidCommenterUserInvites = useMemo(
    () => userInviteTags.some((invite) => !invite.canInvite),
    [userInviteTags]
  );

  const emailAlreadyInvited = useMemo(() => userInviteTags.some((invite) => invite.isAlreadyInvited), [userInviteTags]);

  return (
    <div className={style.container}>
      <div
        className={classnames(style.wrapper, {
          [style.disableTagInput]: disabled,
        })}
      >
        <ReactTags
          ref={reactTags}
          tags={userInviteTags}
          onDelete={onUserRemoved}
          onAddition={onUserInvited}
          addOnBlur={true}
          allowNew={true}
          placeholderText="Search for a teammate, or invite one via email"
          delimiters={["Enter", "Tab"]}
          suggestions={currentUserSuggestions}
          suggestionsTransform={suggestionsTransform}
          minQueryLength={1}
          maxSuggestionsLength={1000}
          tagComponent={EmailTag}
          suggestionComponent={UserSuggestion}
        />
      </div>
      {invalidEmailPresent && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          Please select a valid name or email.
        </div>
      )}
      {invalidCommenterUserInvites && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          As a commenter, you aren’t able to add editors to this folder.
        </div>
      )}
      {emailAlreadyInvited && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          User has already joined a different Ditto workspace. Try a different email.
        </div>
      )}
    </div>
  );
};

export default InviteUsersInput;
