import { addToMapSet, treeItemSort } from "@/views/Components/components/comp-library-nav/lib";
import { LibraryNavState } from "@/views/Components/components/comp-library-nav/libraryNavState";
import { IComponentRenameMap } from "@shared/ditto-events";
import { getBlockTreeItem, getComponentTreeItemWithRenameMap, getGroupTreeItem } from "@shared/lib/components";
import { IFTreeItem } from "@shared/types/http/Component";

/**
 * Given a map of component renames, this function modifies an existing tree state to account for any changes.
 * We handle:
 * - moves between groups/blocks
 * - deletion of blocks/groups
 * - simple renames of components
 */
export function componentsRenamedAction(treeState: LibraryNavState, renamesMap: IComponentRenameMap) {
  const { treeItems } = treeState;

  const {
    componentsToRemoveByGroupName,
    componentsToAddByGroupName,
    componentsToRenameByGroupName,
    componentsToRemoveByGroupBlockName,
    componentsToAddByGroupBlockName,
    componentsToRenameByGroupBlockName,
  } = getMutationMaps(renamesMap);

  // As we progress through the loops below, we'll remove each group/block entry from the maps as we go.
  // After the loop, we'll create any remaining entries as new tree nodes.
  const rootAddSet = componentsToAddByGroupName.get("__root__");
  componentsToAddByGroupName.delete("__root__");
  const rootRemoveSet = componentsToRemoveByGroupName.get("__root__");
  componentsToRemoveByGroupName.delete("__root__");
  const rootRenameSet = componentsToRenameByGroupName.get("__root__");
  componentsToRenameByGroupName.delete("__root__");

  let newTreeItems = treeItems
    .map((item) => {
      // First loop is through the root level -- groups and ungrouped components.
      if (item.type === "group") {
        const groupAddSet = componentsToAddByGroupName.get(item.name);
        const groupRemoveSet = componentsToRemoveByGroupName.get(item.name);
        const groupRenameSet = componentsToRenameByGroupName.get(item.name);

        // This loop is through the second level -- blocks and components in groups.
        const newGroupChildren = item.children
          ?.map((child) => {
            // if the group child is a block, we want to handle any updates to its children
            if (child.type === "block") {
              const groupBlockNameKey = `${item.name}/${child.name}`;
              const blockAddSet = componentsToAddByGroupBlockName.get(groupBlockNameKey);
              const blockRemoveSet = componentsToRemoveByGroupBlockName.get(groupBlockNameKey);
              const blockRenameSet = componentsToRenameByGroupBlockName.get(groupBlockNameKey);

              // The last loop is through the third level -- components in blocks.
              const newBlockChildren = child.children
                ?.map((grandchild) => {
                  if (blockRenameSet?.has(grandchild.id)) {
                    const newChild = {
                      ...grandchild,
                      name: renamesMap[grandchild.id].newNames.componentName,
                    };
                    return newChild;
                  }

                  if (blockRemoveSet?.has(grandchild.id)) {
                    return undefined;
                  }

                  // base case -- no changes to component
                  return grandchild;
                })
                .filter(Boolean) as IFTreeItem[];
              const blockChildrenToAdd = Array.from(blockAddSet ?? []).map((componentId) =>
                getComponentTreeItemWithRenameMap(componentId, renamesMap)
              );
              newBlockChildren?.push(...blockChildrenToAdd);

              // last step: remove the group/block entry from the sets
              componentsToAddByGroupBlockName.delete(groupBlockNameKey);
              componentsToRemoveByGroupBlockName.delete(groupBlockNameKey);
              componentsToRenameByGroupBlockName.delete(groupBlockNameKey);

              if (!newBlockChildren?.length) return undefined;

              return {
                ...child,
                children: newBlockChildren.sort(treeItemSort),
              };
            }

            // otherwise, the child is a component
            if (groupRenameSet?.has(child.id)) {
              const newChild = {
                ...child,
                name: renamesMap[child.id].newNames.componentName,
              };
              return newChild;
            }

            if (groupRemoveSet?.has(child.id)) {
              return undefined;
            }

            // base case -- no changes to block/component
            return child;
          })
          .filter(Boolean) as IFTreeItem[];

        const groupChildrenToAdd = Array.from(groupAddSet ?? []).map((componentId) =>
          getComponentTreeItemWithRenameMap(componentId, renamesMap)
        );

        newGroupChildren?.push(...groupChildrenToAdd);

        // last step: remove the group entry from the sets
        componentsToAddByGroupName.delete(item.name);
        componentsToRemoveByGroupName.delete(item.name);
        componentsToRenameByGroupName.delete(item.name);

        // If the group has no more children, we don't want to render it at all
        if (!newGroupChildren?.length) return undefined;

        return {
          ...item,
          children: newGroupChildren.sort(treeItemSort),
        };
      } else if (item.type === "component") {
        if (rootRenameSet?.has(item.id)) {
          const newItem = {
            ...item,
            name: renamesMap[item.id].newNames.componentName,
          };
          return newItem;
        }

        if (rootRemoveSet?.has(item.id)) {
          return undefined;
        }

        // base case -- no changes to component
        return item;
      } else return item;
    })
    .filter(Boolean) as IFTreeItem[]; // remove any undefined items

  const rootChildrenToAdd = Array.from(rootAddSet ?? []).map((componentId) =>
    getComponentTreeItemWithRenameMap(componentId, renamesMap)
  );

  newTreeItems?.push(...rootChildrenToAdd);

  // last step: add any new groups/blocks to the sets
  // we've been clearing out the maps as we go, so we'll create any remaining entries as new tree nodes

  // add new groups
  componentsToAddByGroupName.forEach((componentIds, groupName) => {
    const componentChildren = Array.from(componentIds)
      .map((componentId) => getComponentTreeItemWithRenameMap(componentId, renamesMap))
      .sort(treeItemSort);

    newTreeItems.push(getGroupTreeItem(groupName, componentChildren));
  });

  // add new blocks
  componentsToAddByGroupBlockName.forEach((componentIds, groupBlockName) => {
    const [groupName, blockName] = groupBlockName.split("/");

    // we know we're always gonna be creating a new block, so define it here

    const newBlock = getBlockTreeItem(
      blockName,
      groupName,
      Array.from(componentIds).map((componentId) => getComponentTreeItemWithRenameMap(componentId, renamesMap))
    );

    // iterate through the top-level tree items to see if there's already a group with this name
    let foundGroup = false;
    newTreeItems = newTreeItems.map((item) => {
      if (item.type === "group" && item.name === groupName) {
        foundGroup = true;

        const newGroup = {
          ...item,
          children: [...(item.children || []), newBlock].sort(treeItemSort),
        };

        return newGroup;
      } else {
        return item;
      }
    });

    // we didn't find a group with this name, so we'll create a new one
    if (!foundGroup) {
      newTreeItems.push(getGroupTreeItem(groupName, [newBlock]));
    }
  });

  // one final sort, just in case (this is only over the top-level, it shouldn't be terribly expensive)
  return { ...treeState, treeItems: newTreeItems.sort(treeItemSort) };
}

/**
 * This function is only exported for testing; you probably don't wanna be using it!
 *
 * This function generates lists of component IDs that need to be removed, added, or renamed. These lists are then mapped
 * to their respective parent groups (or blocks) by name.
 *
 * For example, if "group-1/component1" is renamed to "group-2/component1", then the following maps will be generated:
 * - componentsToRemoveByGroupName : { "group-1" -> ["component1"] }
 * - componentsToAddByGroupName    : { "group-2" -> ["component1"] }
 * - componentsToRenameByGroupName : { } (we only consider it a pure "rename" if it didn't change parents at all)
 *
 * The same applies for blocks, except that the key is "groupname/blockname" instead of just "groupname":
 *
 * rename: group-1/block1 -> group-2/block1
 * - componentsToRemoveByGroupBlockName : { "group-1/block1" -> ["component1"] }
 * - componentsToAddByGroupBlockName    : { "group-2/block1" -> ["component1"] }
 */
export function getMutationMaps(renamesMap: IComponentRenameMap) {
  // We want to build maps from old group name to component IDs and new group name to component IDs
  // so that, as we iterate through the tree, we can easily find any relevant updates.
  const componentsToRemoveByGroupName = new Map<string, Set<string>>();
  const componentsToAddByGroupName = new Map<string, Set<string>>();
  const componentsToRenameByGroupName = new Map<string, Set<string>>();

  const componentsToRemoveByGroupBlockName = new Map<string, Set<string>>();
  const componentsToAddByGroupBlockName = new Map<string, Set<string>>();
  const componentsToRenameByGroupBlockName = new Map<string, Set<string>>();

  // possible cases:
  //
  // - group/block -> group/block
  // - group/block -> group
  // - group/block -> no group
  //
  // - group -> group/block
  // - group -> group
  // - group -> no group
  //
  // - no group -> group/block
  // - no group -> group
  //
  // but actually, all "no group" options above can be thought of as just a group with name "__root__"
  //
  // so our only cases are:
  //
  // - group/block -> group/block
  // - group/block -> group
  //
  // - group -> group/block
  // - group -> group

  Object.entries(renamesMap).forEach(([componentId, { oldNames, newNames }]) => {
    const oldGroupName = oldNames.groupName || "__root__";
    const newGroupName = newNames.groupName || "__root__";
    // at this point, both group names are non-empty strings -- either an actual name or __root__
    // blocks can either be a real name or an empty string

    // if both group and block names are the same, we're just renaming a component without changing its parent
    if (oldGroupName === newGroupName && oldNames.blockName === newNames.blockName) {
      if (oldNames.blockName) {
        const key = `${oldGroupName}/${oldNames.blockName}`;
        addToMapSet(componentsToRenameByGroupBlockName, key, componentId);
      } else {
        const key = oldGroupName;
        addToMapSet(componentsToRenameByGroupName, key, componentId);
      }
    }
    // otherwise, we're changing the parent group or block of the component
    else {
      // source was a block
      if (oldNames.blockName) {
        const key = `${oldGroupName}/${oldNames.blockName}`;
        addToMapSet(componentsToRemoveByGroupBlockName, key, componentId);
      }
      // source was just a group
      else {
        const key = oldGroupName;
        addToMapSet(componentsToRemoveByGroupName, key, componentId);
      }

      // destination is a block
      if (newNames.blockName) {
        const key = `${newGroupName}/${newNames.blockName}`;
        addToMapSet(componentsToAddByGroupBlockName, key, componentId);
      }
      // destination is a group
      else {
        const key = newGroupName;
        addToMapSet(componentsToAddByGroupName, key, componentId);
      }
    }
  });

  return {
    componentsToRemoveByGroupName,
    componentsToAddByGroupName,
    componentsToRenameByGroupName,
    componentsToRemoveByGroupBlockName,
    componentsToAddByGroupBlockName,
    componentsToRenameByGroupBlockName,
  };
}
