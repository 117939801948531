import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

const VariantListPlaceholder = () => {
  return (
    <div className={style.wrapper}>
      {Array(2)
        .fill("")
        .map((_item, index) => (
          <div key={index} className={style.variantItem}>
            <div className={style.header} style={{ backgroundColor: "#eee", height: "25px" }}></div>

            <div className={style.table}>
              <div className={style.row}>
                <Skeleton width={"100%"} />
              </div>
              <div className={style.row}>
                <Skeleton />
              </div>
              <div className={style.row}>
                <Skeleton />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default VariantListPlaceholder;
