import { loadStripe as _loadStripe } from "@stripe/stripe-js";

Object.entries({
  REACT_APP_STRIPE_PK: process.env.REACT_APP_STRIPE_PK,
  REACT_APP_STRIPE_EDITOR_MONTHLY_OLD: process.env.REACT_APP_STRIPE_EDITOR_MONTHLY_OLD,
  REACT_APP_STRIPE_EDITOR_MONTHLY_NEW: process.env.REACT_APP_STRIPE_EDITOR_MONTHLY_NEW,
  REACT_APP_STRIPE_EDITOR_YEARLY_OLD: process.env.REACT_APP_STRIPE_EDITOR_YEARLY_OLD,
  REACT_APP_STRIPE_EDITOR_YEARLY_NEW: process.env.REACT_APP_STRIPE_EDITOR_YEARLY_NEW,
  REACT_APP_STRIPE_COMMENTER_MONTHLY_OLD: process.env.REACT_APP_STRIPE_COMMENTER_MONTHLY_OLD,
  REACT_APP_STRIPE_COMMENTER_MONTHLY_NEW: process.env.REACT_APP_STRIPE_COMMENTER_MONTHLY_NEW,
  REACT_APP_STRIPE_COMMENTER_YEARLY_NEW: process.env.REACT_APP_STRIPE_COMMENTER_YEARLY_NEW,
  REACT_APP_STRIPE_GROWTH_EDITOR_MONTHLY: process.env.REACT_APP_STRIPE_GROWTH_EDITOR_MONTHLY,
  REACT_APP_STRIPE_GROWTH_EDITOR_YEARLY: process.env.REACT_APP_STRIPE_GROWTH_EDITOR_YEARLY,
  REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_MONTHLY: process.env.REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_MONTHLY,
  REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_YEARLY: process.env.REACT_APP_STRIPE_GROWTH_DEV_INTEGRATIONS_YEARLY,
  REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_MONTHLY: process.env.REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_MONTHLY,
  REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_YEARLY: process.env.REACT_APP_STRIPE_TEAM_DEV_INTEGRATIONS_YEARLY,
}).forEach(([key, value]) => {
  if (!value) {
    throw new Error(`Couldn't load ${key.replace("REACT_APP_", "")} from your environment`);
  }
});

export const loadStripe = () => _loadStripe(process.env.REACT_APP_STRIPE_PK!);
