import { useWorkspace } from "@/store/workspaceContext";
import ErrorIcon from "@mui/icons-material/Error";
import { EMAIL_REGEX } from "@shared/utils/email";
import classnames from "classnames";
import { matchSorter } from "match-sorter";
import React, { useMemo, useRef } from "react";
import ReactTags from "react-tag-autocomplete";
import EmailTag from "./EmailTag";
import UserSuggestion from "./UserSuggestion";
import style from "./style.module.css";
import { AddMembersInputProps } from "./types";

const WHITE_SPACE_REGEX = new RegExp(/\s/g);
const AddMembersInput = (props: AddMembersInputProps) => {
  const {
    disabled,
    existingInvitedEmails,
    invitedUserList,
    onUserInvited,
    onUserRemoved,
    currentUserEmail,
    currentUsers,
    workspaceContext,
  } = props;

  const {
    workspaceInfo: { plan },
    users: workspaceUsers,
  } = useWorkspace();

  const reactTags = useRef();
  const userInviteTags = useMemo(() => {
    return invitedUserList.map(({ email, type, name, isAlreadyInvited, isLastAdmin }, index) => ({
      id: index,
      type,
      name,
      email,
      isValid: EMAIL_REGEX.test(email),
      isAlreadyInvited,
      isLastAdmin,
    }));
  }, [invitedUserList]);

  const currentUserSuggestions = useMemo(() => {
    if (workspaceContext) return [];

    return workspaceUsers
      .map((user) => ({
        _id: user._id,
        type: "existing",
        name: user.name,
        email: user.email,
      }))
      .filter(({ email }) => {
        return (
          invitedUserList.findIndex((userInvite) => userInvite.email === email) === -1 &&
          email !== currentUserEmail &&
          currentUsers.findIndex((user) => user.email === email) === -1
        );
      });
  }, [workspaceUsers, invitedUserList, currentUsers]);

  const suggestionsTransform = (query, suggestions) => {
    const searchQuery = query.toLowerCase();
    const isValidEmail = !WHITE_SPACE_REGEX.test(searchQuery) && EMAIL_REGEX.test(searchQuery);
    const canInviteEmail =
      isValidEmail &&
      !workspaceUsers.some((user) => user.email.toLocaleLowerCase() === searchQuery) &&
      !invitedUserList.some((user) => user?.email?.toLocaleLowerCase() === searchQuery) &&
      !existingInvitedEmails[searchQuery];

    let allSuggestions = suggestions;

    if (canInviteEmail) {
      const newUserInvite = {
        _id: "__new__",
        type: "new",
        name: searchQuery,
        email: searchQuery,
      };

      allSuggestions = [newUserInvite, ...suggestions];
    }

    return matchSorter(allSuggestions, searchQuery, {
      keys: ["email", "name"],
      sorter: (rankedItems) => {
        return rankedItems.sort((a, b) => {
          // @ts-ignore
          if (a.item.type === b.item.type) return 0;
          // @ts-ignore
          else if (a.item?.type === "existing") return -1;
          return 1;
        });
      },
    });
  };

  const invalidEmailPresent = useMemo(() => userInviteTags.some((invite) => !invite.isValid), [userInviteTags]);

  const emailAlreadyInvited = useMemo(() => userInviteTags.some((invite) => invite.isAlreadyInvited), [userInviteTags]);

  const userIsLastAdmin = useMemo(() => userInviteTags.some((invite) => invite.isLastAdmin), [userInviteTags]);

  const placeholderText = workspaceContext
    ? "Invite teammates via email"
    : "Search for a teammate, or invite one via email";

  return (
    <div className={classnames(style.container, props.className)}>
      <div
        className={classnames(style.wrapper, {
          [style.disableTagInput]: disabled,
        })}
      >
        <ReactTags
          ref={reactTags}
          tags={userInviteTags}
          onDelete={onUserRemoved}
          onAddition={onUserInvited}
          addOnBlur={true}
          allowNew={true}
          placeholderText={placeholderText}
          delimiters={["Enter", "Tab"]}
          suggestions={currentUserSuggestions}
          suggestionsTransform={suggestionsTransform}
          minQueryLength={1}
          maxSuggestionsLength={1000}
          tagComponent={EmailTag}
          suggestionComponent={UserSuggestion}
        />
      </div>
      {invalidEmailPresent && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          Please select a valid name or email.
        </div>
      )}
      {emailAlreadyInvited && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          User has already joined a different Ditto workspace. Try a different email.
        </div>
      )}
      {userIsLastAdmin && (
        <div className={style.errorMsg}>
          <ErrorIcon className={style.errorIcon} />
          You cannot remove the last admin from the workspace.
        </div>
      )}
    </div>
  );
};

export default AddMembersInput;
