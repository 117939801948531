import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import CardSection from "../cardsection/cardsection";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import style from "./style.module.css";

const UpdatePayment = ({ onHide, billing_email, old_payment_method }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState("");
  const [emailInput, setEmailInput] = useState(billing_email);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const stripePaymentMethodHandler = async (result) => {
    if (result.error) {
      console.error(result.error);
      setPaymentError(result.error.message);
      setPaymentLoading(false);
      setTimeout(function () {
        setPaymentError("");
      }, 5000);
    } else {
      // Otherwise send paymentMethod.id to your server
      const { url, body } = API.api.post.updatePaymentMethod;
      await http.post(
        url,
        body({
          oldMethod: old_payment_method,
          newMethod: result.paymentMethod.id,
        })
      );
      setPaymentLoading(false);
      refreshPage();
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    setPaymentLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: emailInput,
      },
    });
    stripePaymentMethodHandler(result);
  };

  const refreshPage = () => {
    location.reload();
  };

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Updating your payment method</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={() => onHide()} />
        </BootstrapModal.Header>
        <div>
          <BootstrapModal.Body className={style.body}>
            <h4>Billing Email</h4>
            <input
              type="text"
              placeholder="Billing Email"
              aria-label="Billing Email"
              value={emailInput}
              onChange={handleEmailChange}
            />
            <CardSection />
            <p className={style.paymentError}>{paymentError}</p>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className={style.footer}>
            <ButtonPrimary
              onClick={handleSubmit}
              text={paymentLoading ? "Processing..." : "Update Card"}
              disabled={paymentLoading}
            />
          </BootstrapModal.Footer>
        </div>
      </BootstrapModal>
    </div>
  );
};

export default UpdatePayment;
