import {
  deferredProjectNameAtom,
  editableProjectNameAtom,
  resetEditableProjectNameAtom,
  saveProjectNameActionAtom,
} from "@/stores/Project";
import InlineEditableName from "@ds/molecules/InlineEditableName";
import { useAtomValue, useSetAtom } from "jotai";
import { Suspense, useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

function NavBar() {
  // logic to show the inline editable icon when hovering anywhere in the navbar
  const [hovering, setHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(function addHoverListeners() {
    function handleMouseEnter() {
      setHovering(true);
    }

    function handleMouseLeave() {
      setHovering(false);
    }

    const containerEl = containerRef.current;

    containerEl?.addEventListener("mouseenter", handleMouseEnter);
    containerEl?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      containerEl?.removeEventListener("mouseenter", handleMouseEnter);
      containerEl?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div className={style.navBarContainer} ref={containerRef}>
      <Suspense fallback={<Skeleton height={17} width={120} baseColor="var(--bg-secondary)" />}>
        <ProjectName hovering={hovering} />
      </Suspense>
    </div>
  );
}

function ProjectName(props: { hovering: boolean }) {
  const setProjectName = useSetAtom(editableProjectNameAtom);
  const projectName = useAtomValue(deferredProjectNameAtom);
  const resetProjectName = useSetAtom(resetEditableProjectNameAtom);
  const saveProjectNameAction = useSetAtom(saveProjectNameActionAtom);

  function onReset() {
    resetProjectName();
  }

  return (
    <InlineEditableName
      name={projectName}
      onChange={setProjectName}
      onSave={saveProjectNameAction}
      onReset={onReset}
      onBlur={onReset}
      variant="header"
      forceHoverState={props.hovering}
    />
  );
}

export default NavBar;
