import ArrowIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import QuickReplyIcon from "../../assets/QuickReplyIcon";

import useControlledState from "@shared/frontend/lib/useControlledState";
import CommentThread, { CommentThreadFunctionProps, CommentThreadOtherProps } from "../commentthread/commentthread";
import CommentEditor, { CommentEditorProps, Mention } from "./comment-editor";

import style from "./CommentEditorEditPanel.module.css";

interface LabelProps {
  Icon: any;
  text: React.ReactNode;
}

const Label = (props: LabelProps) => {
  const { Icon, text } = props;
  return (
    <div className={style.labelWrapper}>
      <Icon className={style.labelIcon} /> <span className={style.labelText}>{text}</span>
    </div>
  );
};

interface CommentNavigatorProps {
  loading?: boolean;
  comments: {}[];
  commentIndex: number;
  onClose: () => void;
  onPreviousComment: () => void;
  onNextComment: () => void;
  onBackArrowClick: () => void;
}

const CommentNavigator = (props: CommentNavigatorProps) => {
  const { loading, comments, commentIndex, onClose, onPreviousComment, onNextComment, onBackArrowClick } = props;

  return (
    <div className={style.commentNavigator}>
      <ArrowIcon onClick={onBackArrowClick} />
      <div>
        <ChevronLeftIcon
          onClick={onPreviousComment}
          className={classNames({
            [style.chevronLeft]: true,
            [style.disabled]: commentIndex === 0,
          })}
        />
        {loading && <span>Loading..</span>}
        {!loading && (
          <span>
            {commentIndex + 1} of {comments.length} Comments
          </span>
        )}
        <ChevronRightIcon
          onClick={onNextComment}
          className={classNames({
            [style.chevronRight]: true,
            [style.disabled]: commentIndex === comments.length - 1,
          })}
        />
      </div>
      <CloseIcon onClick={onClose} />
    </div>
  );
};

type CommentThread = Omit<CommentThreadOtherProps, "workspaceUsers">;

const placeholderCommentThread: CommentThread = {
  _id: "placeholder",
  comp_id: "",
  is_resolved: false,
  createdAt: new Date().toString(),
  updatedAt: new Date().toString(),
  default_replies_open: false,
  text: "Loading..",
  user_id: "placeholder",
  user_name: "Ditto",
  thread_id: "placeholder",
  should_autofocus: false,
  isDisabled: false,
  comments: [
    {
      _id: "placeholder",
      createdAt: new Date().toString(),
      updatedAt: new Date().toString(),
      user_id: "placeholder",
      user_name: "Ditto",
      text: "Loading..",
    },
  ],
  doc_name: "",
  isActiveComment: false,
  isInlineComment: false,
};

type QuickReplyState =
  | { loaded: false }
  | { loaded: true; success: false; error: string }
  | {
      loaded: true;
      success: true;
      state: { selectedIndex: number; commentThreads: CommentThread[] };
    };

export interface CommentEditorEditPanelProps {
  doc_name?: string;
  quickReplyState?: React.StatePair<QuickReplyState> | undefined;
  quickReplyMode: {
    enabled: boolean;
    initialThreadId?: string;
    goBackState: Record<string, string>;
  };
  onExitQuickReplyMode: () => void;
  onQuickReplyNavigation: (
    commentThread: CommentThread,
    selectedIndex: number,
    newCommentThreads: CommentThread[]
  ) => void;
  onQuickReplyBackArrowClick: (goBackState?: Record<string, string>) => void;

  latestCommentThread: CommentThread | null;

  commentEditor: Omit<CommentEditorProps, "mentions" | "shouldAutofocus" | "className">;
  loadCommentThreads: (selectedThreadId?: string) => Promise<CommentThread[]>;
  commentThreadFunctions: CommentThreadFunctionProps;
  commentThreadUI?: {
    before?: (commentThread: CommentThread) => React.ReactNode;
  };

  possibleMentions: Mention[];
  isCommentingDisabled: boolean;
  onSeeAllActivity: () => void;
  classes?: {
    wrapper?: string;
    modes?: {
      commentWrapper?: string;
      commentLatestWrapper?: string;
      quickReplyWrapper?: string;
    };
  };
  className?: string;
}

const Comment = (props: CommentEditorEditPanelProps) => {
  const { commentEditor, possibleMentions, isCommentingDisabled } = props;
  return (
    <div className={classNames([style.section, style.sectionPadding])}>
      <Label Icon={ModeCommentIcon} text="Comment" />
      {!isCommentingDisabled && (
        <CommentEditor {...commentEditor} mentions={possibleMentions} className={style.commentThreadEditor} />
      )}
    </div>
  );
};

const CommentLatest = (props: CommentEditorEditPanelProps) => {
  const {
    commentEditor,
    doc_name,
    latestCommentThread,
    commentThreadFunctions,
    possibleMentions,
    onSeeAllActivity,
    commentThreadUI,
    isCommentingDisabled,
  } = props;

  return (
    <div className={style.sectionPadding}>
      {!isCommentingDisabled && (
        <div className={style.section}>
          <Label Icon={ModeCommentIcon} text="Comment" />
          <CommentEditor {...commentEditor} mentions={possibleMentions} className={style.commentThreadEditor} />
        </div>
      )}
      {latestCommentThread && (
        <div className={style.section}>
          <Label Icon={AutoAwesomeIcon} text="Most recent" />
          {commentThreadUI?.before?.(latestCommentThread)}
          <CommentThread
            {...latestCommentThread}
            thread_id={latestCommentThread.thread_id || latestCommentThread._id}
            {...commentThreadFunctions}
            workspaceUsers={possibleMentions}
            className={style.commentThread}
            classNameCommentEditor={style.commentThreadEditor}
            isDisabled={isCommentingDisabled}
            doc_name={doc_name}
          />
          <div className={style.seeAllActivityContainer}>
            <button className={style.seeAllActivity} onClick={onSeeAllActivity}>
              See all activity {"->"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * Resolve a comment thread given an array of threads and an index. If a comment
 * thread does not exist at the initially provided index, recursively attempt to select the previous
 * comment thread in the array until one can be found, throwing an error if the index
 * goes out of bounds before a valid thread is found.
 * @param commentThreads
 * @param currentIndex
 * @returns
 */
const findValidCommentThreadIndex = (commentThreads, currentIndex) => {
  /**
   * Base case: if we've traversed all the way down the list of comment
   * threads and are now of bounds, return the first one.
   */
  if (currentIndex < 0) {
    throw new Error("A valid comment thread could not be found.");
  }

  /**
   * Recursive case: check to see if the comment thread at the current index
   * exists. If it doesn't, recurse with the index decremented by 1.
   */
  const thread = commentThreads[currentIndex];
  if (!thread) {
    return findValidCommentThreadIndex(commentThreads, currentIndex - 1);
  }

  /**
   * Base case: the comment thread at the current index exists, so return it.
   */
  return currentIndex;
};

const QuickReply = (props: CommentEditorEditPanelProps) => {
  const {
    commentThreadFunctions,
    possibleMentions,
    onSeeAllActivity,
    loadCommentThreads,
    quickReplyMode,
    onExitQuickReplyMode,
    onQuickReplyNavigation,
    commentThreadUI,
    doc_name,
  } = props;

  const [commentThreadState, setCommentThreadState] = useControlledState(props.quickReplyState, {
    loaded: false,
  });

  const updateCommentThreadInState = (threadData: Omit<Partial<CommentThread>, "_id"> & { _id: string }) =>
    setCommentThreadState((s) => {
      if (!(s.loaded && s.success)) {
        return s;
      }

      const { commentThreads } = s.state;

      return {
        ...s,
        state: {
          ...s.state,
          commentThreads: commentThreads.map((c) => (c._id === threadData._id ? { ...c, ...threadData } : c)),
        },
      };
    });

  const navigateToIndex = (index: number) => {
    setCommentThreadState((s) => {
      if (!(s.loaded && s.success)) {
        return s;
      }

      const { commentThreads, selectedIndex } = s.state;

      let newCommentThreads = [...commentThreads];
      let newSelectedIndex = index;

      // if the currently selected comment thread has been resolved, remove it
      // from the list when navigating to a new comment thread
      const selectedCommentThread = newCommentThreads[selectedIndex];
      if (selectedCommentThread.is_resolved) {
        newCommentThreads.splice(selectedIndex, 1);
        if (newSelectedIndex > selectedIndex) {
          newSelectedIndex--;
        }
      }

      // ensure that the index is in bounds after removing the resolved comment
      while (newSelectedIndex > newCommentThreads.length - 1) {
        newSelectedIndex--;
      }

      // side effect: notify parent component via a callback prop that navigation
      // to a new comment thread has occurred.
      if (newCommentThreads[newSelectedIndex]) {
        onQuickReplyNavigation(newCommentThreads[newSelectedIndex], newSelectedIndex, newCommentThreads);
      }

      return {
        ...s,
        state: {
          selectedIndex: newSelectedIndex,
          commentThreads: newCommentThreads,
        },
      };
    });
  };

  useEffect(() => {
    if (commentThreadState.loaded) {
      return;
    }

    loadCommentThreads(quickReplyMode.initialThreadId)
      .then((commentThreads) => {
        let indexToSelect = commentThreads.findIndex((ct) => ct._id === quickReplyMode.initialThreadId);
        if (indexToSelect === -1) {
          indexToSelect = 0;
        }

        setCommentThreadState({
          loaded: true,
          success: true,
          state: {
            selectedIndex: indexToSelect,
            commentThreads,
          },
        });
      })
      .catch((e) =>
        setCommentThreadState({
          loaded: true,
          success: false,
          error: e.message,
        })
      );
  }, [commentThreadState]);

  if (commentThreadState.loaded && !commentThreadState.success) {
    return <div>error: {commentThreadState.error}</div>;
  }

  const commentThreads =
    (commentThreadState.loaded && commentThreadState.success && commentThreadState.state.commentThreads) || [];

  const selectedIndex =
    (commentThreadState.loaded && commentThreadState.success && commentThreadState.state.selectedIndex) || 0;

  const onNextComment = () => {
    if (selectedIndex >= commentThreads.length - 1) {
      return;
    }

    navigateToIndex(selectedIndex + 1);
  };

  const onPreviousComment = () => {
    if (selectedIndex <= 0) {
      return;
    }

    navigateToIndex(selectedIndex - 1);
  };

  const onPostReply = ({ _id, comments }: { _id: string; comments: CommentThread["comments"] }) => {
    updateCommentThreadInState({ _id, comments });
  };

  const onResolve = ({
    _id,
    is_resolved,
    suggestion,
  }: {
    _id: string;
    is_resolved: boolean;
    suggestion?: { accepted: boolean | null };
  }) => {
    updateCommentThreadInState({ _id, is_resolved, suggestion });
  };

  const onQuickReplyBackArrowClick = () => {
    props.onQuickReplyBackArrowClick(props.quickReplyMode.goBackState);
  };

  return (
    <div className={style.section}>
      {!commentThreadState.loaded && <div className={style.quickReplyLoadingOverlay} />}
      <CommentNavigator
        loading={!commentThreadState.loaded}
        comments={commentThreads}
        commentIndex={selectedIndex}
        onClose={onExitQuickReplyMode}
        onNextComment={onNextComment}
        onPreviousComment={onPreviousComment}
        onBackArrowClick={onQuickReplyBackArrowClick}
      />
      <div className={style.sectionPadding}>
        <Label Icon={QuickReplyIcon} text="Quick Reply" />
        {commentThreadUI?.before?.(commentThreads[selectedIndex] || placeholderCommentThread)}
        <CommentThread
          {...(commentThreads[selectedIndex] || placeholderCommentThread)}
          {...commentThreadFunctions}
          doc_name={doc_name}
          should_autofocus
          thread_id={commentThreads[selectedIndex]?._id}
          workspaceUsers={possibleMentions}
          className={style.commentThread}
          classNameCommentEditor={style.commentThreadEditor}
          onPostReply={onPostReply}
          onResolve={onResolve}
        />
        <div className={style.seeAllActivityContainer}>
          <button className={style.seeAllActivity} onClick={onSeeAllActivity}>
            See all activity {"->"}
          </button>
        </div>
      </div>
    </div>
  );
};

const CommentEditorEditPanel = (props: CommentEditorEditPanelProps) => {
  const { latestCommentThread, quickReplyMode, className } = props;

  const mode = useMemo(() => {
    if (quickReplyMode.enabled) {
      return "quick-reply";
    }

    if (latestCommentThread) {
      return "comment-latest";
    }

    return "comment";
  }, [quickReplyMode, latestCommentThread]);

  return (
    <div className={className}>
      {mode === "comment" && <Comment {...props} />}
      {mode === "comment-latest" && <CommentLatest {...props} />}
      {mode === "quick-reply" && <QuickReply {...props} />}
    </div>
  );
};

export default CommentEditorEditPanel;
