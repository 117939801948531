import { Editor, Extension } from "@tiptap/core";
import { Plugin, PluginKey, Selection } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { ProsemirrorNode } from "../lib";
import { BLOCK_NAME } from "../nodes/DraftBlockNode";

const CLASS_FOR_FOCUS = "has-focus";
const CLASS_FOR_FOCUS_MULTIPLE = "has-focus-multiple";
const CLASS_FOR_DRAG_HANDLE = "has-drag-handle";

/**
 * Given a document and a selection in that document, implements one of two behaviors
 * according to whether or not a range is selected:
 * - If a range is selected, adds a class to all blocks in the document that the selection
 * range extends across
 * - If a range is not selected, adds two classes to the block in the document where the cursor is..
 * >- a class for indicating the block is selected
 * >- a class for indicating the block should display a drag handle
 */
function generateFocusDecorations(doc: ProsemirrorNode, selection: Selection, editorHasFocus: boolean) {
  if (!editorHasFocus) {
    return;
  }

  const { from: selectionStart, to: selectionEnd } = selection;

  const decorations: Decoration[] = [];

  // used after traversing the document to apply styling decorations
  const blockPositions: { from: number; to: number }[] = [];

  // traverse through all nodes in the document
  doc.descendants((node, nodeStartPosition) => {
    // if the current node is not a block,
    // return early and don't recurse into it
    if (node.type.name !== BLOCK_NAME) {
      return false;
    }

    const selectionAroundNode = nodeStartPosition >= selectionStart && nodeStartPosition <= selectionEnd - 1;

    const selectionInNode =
      selectionStart >= nodeStartPosition && selectionStart <= nodeStartPosition + node.nodeSize - 1;

    const nodeIsSelected = selectionAroundNode || selectionInNode;
    if (!nodeIsSelected) {
      return false;
    }

    const from = nodeStartPosition;
    const to = nodeStartPosition + node.nodeSize;

    blockPositions.push({ from, to });
    return false;
  });

  const multipleBlocksSelected = blockPositions.length > 1;
  if (!multipleBlocksSelected && blockPositions[0]) {
    const [{ from, to }] = blockPositions;
    decorations.push(Decoration.node(from, to, { class: CLASS_FOR_DRAG_HANDLE }));
  }

  // we apply decorations in a loop after the document traversal
  // instead of inline because there are certain of aspects of decoration
  // that pertain to how many blocks are currently selected, which is
  // only known after the fact.
  blockPositions.forEach(({ from, to }) => {
    let decoratorClass = CLASS_FOR_FOCUS;

    if (multipleBlocksSelected) {
      decoratorClass += ` ${CLASS_FOR_FOCUS_MULTIPLE}`;
    }

    decorations.push(Decoration.node(from, to, { class: decoratorClass }));
  });

  return DecorationSet.create(doc, decorations);
}

const createPlugin = (editor: Editor) =>
  new Plugin({
    key: new PluginKey("focus"),
    props: {
      decorations: function (state) {
        return generateFocusDecorations(state.tr.doc, state.tr.selection, editor.view.hasFocus());
      },
    },
  });

const Focus = Extension.create<FocusOptions>({
  name: "focus",
  addProseMirrorPlugins() {
    return [createPlugin(this.editor)];
  },
});

export default Focus;
