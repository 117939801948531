import ExpandMore from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import { useNativeProps } from "../../useNativeProps";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;

  leadingIcon?: React.ReactNode;
  size?: "base" | "small";
  iconSize?: "base" | "small" | "xs" | "xxs" | "xxxs";
  state: "default" | "disabled" | "error";
  inline?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const SelectTrigger = React.forwardRef<HTMLButtonElement, IProps>(function SelectTrigger(props, ref) {
  const inline = props.inline ?? false;
  const iconSize = props.iconSize ?? "xs";

  const nativeProps = useNativeProps<HTMLButtonElement, IProps>(props, {
    inline: true,
    leadingIcon: true,
    state: true,
    iconSize: true,
    size: true,
  });

  return (
    <button
      {...nativeProps}
      style={props.style}
      className={classNames(style.selectTrigger, props.className, {
        [style[props.state]]: true,
        [style.inline]: inline,
        [style[`size-${props.size}`]]: props.size,
      })}
      data-testid="select-trigger"
      ref={ref}
    >
      {props.leadingIcon && <Icon Icon={props.leadingIcon} size={iconSize} />}
      <span className={style.childrenWrapper}>{props.children}</span>
      <Icon Icon={<ExpandMore />} size="xs" color="secondary" />
    </button>
  );
});

export default SelectTrigger;
