import * as DittoEvents from "@shared/ditto-events";
import React, { useState } from "react";
import { useDittoEventListener } from "../../../shared/ditto-events/frontend";
import style from "./style.module.css";

function NewWebAppVersion() {
  const [isThereNewWebAppVersion, setIsThereNewWebAppVersion] = useState(false);

  // Move this logic into its own hook
  useDittoEventListener(
    DittoEvents.newAppVersionReleased,
    function onNewAppVersion(data) {
      if (data.app === DittoEvents.NEW_APP_VERSION_TYPES.WEB) {
        setIsThereNewWebAppVersion(true);
      }
    },
    []
  );

  if (!isThereNewWebAppVersion) {
    return <></>;
  }

  return (
    <div className={style.container}>
      <div className={style.toast}>
        <div className={style.title}>
          <span>
            <b>Update Available</b>. Please reload to access the latest version of Ditto.
          </span>
        </div>
        <div className={style.description}>
          <div
            className={style.reloadBtn}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </div>
        </div>
      </div>
    </div>
  );
}

export { NewWebAppVersion };
