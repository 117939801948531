import { updateGroupName } from "@/http/project";
import { useWorkspace } from "@/store/workspaceContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { useCallback, useEffect, useRef } from "react";
import useSegment from "../../../hooks/useSegment";
import http, { API } from "../../../http";
import { isRichTextFullyBold, removeBoldFromRichText } from "../../../utils/rich_text";
import { Props as GroupDraftProps } from "../components/GroupDraft";
import { createDefaultDraftGroup } from "./getCategorizedGroups";
import { useProjectContext } from "./useProjectState";

export const DRAFT_BUTTON_GROUP_ID = "__DRAFT_BUTTON_GROUP_ID__";

type GroupDraftFunctionKeys = {
  [key in keyof GroupDraftProps]: GroupDraftProps[key] extends Function ? key : never;
}[keyof GroupDraftProps];

export type GroupDraftFunctionProps = Pick<GroupDraftProps, NonNullable<GroupDraftFunctionKeys>>;

const useDraftState = () => {
  const {
    projectId,
    selectedDraftGroupId: [, setSelectedDraftGroupId],
    groupState: [{ groups }, dispatch],
    compHistory: [_compHistory, setCompHistory],
    compHistoryLoading: [_compHistoryLoading, setCompHistoryLoading],
    groupIndicesByGroupId,
    selectedId,
    selectedComp: [selectedComp, setSelectedComp],
    selectedGroupId: [, setSelectedGroupId],
    compBeingEdited: [_compBeingEdited],
    setGroupChangesHaveBeenSaved,
  } = useProjectContext();

  const { workspaceInfo } = useWorkspace();
  const segment = useSegment();
  const workspaceId = workspaceInfo?._id?.toString() || "";

  const onBlur: GroupDraftFunctionProps["onBlur"] = useCallback(() => null, []);

  const onFocus: GroupDraftFunctionProps["onFocus"] = useCallback(
    (groupId) => {
      setSelectedDraftGroupId(groupId);
    },
    [setSelectedDraftGroupId]
  );

  const onEnableLinkingClick: GroupDraftFunctionProps["onEnableLinkingClick"] = useCallback(
    async (groupId, groupName, textItems) => {
      let textItemsWithText = textItems.filter((textItem) => !!textItem.text.trim());

      for (const textItem of textItemsWithText) {
        textItem.rich_text = isRichTextFullyBold(textItem.rich_text)
          ? removeBoldFromRichText(textItem.rich_text)
          : textItem.rich_text;
      }

      const { updated } = await API.project.upsertGroup({
        projectId,
        groupId,
        data: {
          name: groupName,
          text_items: textItemsWithText,
          linking_enabled: true,
        },
      });

      // If API ID updates occurred as part of the process of enabling linking,
      // update the text items state to reflect the new API ID values.
      if (updated?.apiIdsByTextItemId) {
        const textItemsById = new Map(textItemsWithText.map((t) => [t._id, t]));

        Object.entries(updated.apiIdsByTextItemId).forEach(([textItemId, apiId]) => {
          let t = textItemsById.get(textItemId);
          if (!t) return;

          t.apiID = apiId;
        });
      }

      dispatch({
        type: "ENABLE_LINKING_FOR_GROUP",
        groupId,
        textItems: textItemsWithText,
      });

      segment.track({ event: SegmentEvents.ENABLE_LINKING_BUTTON_CLICKED });

      const selectedTextItemInUpdate = textItemsWithText.find((textItem) => textItem._id.toString() === selectedId);
      if (selectedTextItemInUpdate) {
        setSelectedDraftGroupId(null);
        setSelectedComp((c) => JSON.parse(JSON.stringify(c)));
        setSelectedGroupId(groupId);
      }
    },
    [dispatch, selectedId, setSelectedComp]
  );

  /**
   * This will just create a new group in the state at the correct index;
   * that new group will be created for real when it is edited for the first time.
   */
  const onNewGroup = useCallback(
    (groupId) => {
      const getIndex = () => {
        if (groupId === DRAFT_BUTTON_GROUP_ID) {
          return 0;
        }

        const index = groups.findIndex((group) => group._id.toString() === groupId);

        if (index === -1) {
          return 0;
        }

        return index + 1;
      };

      const newGroupIndex = getIndex();
      const newGroup = createDefaultDraftGroup(projectId, workspaceId);

      const newGroups = [...groups.slice(0, newGroupIndex), newGroup, ...groups.slice(newGroupIndex, groups.length)];

      dispatch({
        type: "REPLACE_GROUPS",
        groups: newGroups,
      });

      setSelectedDraftGroupId(newGroup._id);
    },
    [projectId, workspaceId, groups, dispatch, setSelectedDraftGroupId]
  );

  const onSaveGroupName: GroupDraftFunctionProps["onSaveGroupName"] = useCallback(
    async (groupId, groupName) => {
      const groupIndex = groupIndicesByGroupId.get(groupId);
      updateGroupName({
        newName: groupName,
        projectId,
        groupId,
        groupIndex,
        onSuccess(upsertResult) {
          if (upsertResult.created && upsertResult.created.apiID) {
            dispatch({
              type: "UPDATE_GROUP_APIID",
              groupId,
              apiId: upsertResult.created.apiID,
            });
          }
        },
      });

      dispatch({
        type: "UPDATE_GROUP_NAME",
        groupId,
        groupName,
      });
    },
    [projectId, groupIndicesByGroupId, dispatch]
  );

  const selectedIdReference = useRef<string>("");
  useEffect(() => {
    if (selectedId) {
      selectedIdReference.current = selectedId;
    }
  }, [selectedId]);

  const onSaveTextItems: GroupDraftFunctionProps["onSaveTextItems"] = useCallback(
    async (groupId, textItems) => {
      const groupIndex = groupIndicesByGroupId.get(groupId);
      if (groupIndex === undefined) {
        throw new Error("Could not find group to save text items for: " + groupId);
      }

      // Save the updated group information to the database.
      //
      // In the event that the group is _new_, groupIndex will be used
      // to determine where in `actualdocument.groups` the group should be
      // inserted, but is not necessary otherwise. If the group is new and
      // and index is not provided, the group will be inserted at the front of
      // the array.
      const { created, updated } = await API.project.upsertGroup({
        projectId,
        groupId,
        data: { text_items: textItems, group_index: groupIndex },
      });

      dispatch({
        type: "UPDATE_GROUP_TEXT_ITEMS",
        groupId,
        groupApiId: created?.apiID,
        textItems,
      });

      if (updated?.commentThreadUpdateMap) {
        const { commentThreadUpdateMap } = updated;
        dispatch({
          type: "UPDATE_DRAFT_TEXT_ITEM_COMMENT_THREADS",
          groupId,
          commentThreadUpdateMap,
        });
      }

      setGroupChangesHaveBeenSaved(groupId);

      // Refresh the activity panel for the currently selected
      // text item
      const { url } = API.comp.get.historyAndComments;

      // Sometimes the selectedIdReference isn't set yet,
      // so we will try again after a short delay.
      // We could only refresh if selectedIdReference.current is set,
      // but that would mean that the user wouldn't get the latest history.
      // Probably best to comeback to this later.
      if (!selectedIdReference.current) {
        setTimeout(refreshCompHistory, 100);
      } else {
        refreshCompHistory();
      }

      async function refreshCompHistory() {
        if (!selectedIdReference.current) {
          return;
        }

        const { data: compHistory } = await http.get(url(selectedIdReference.current));
        setCompHistory(compHistory || []);
        setCompHistoryLoading(false);
      }
    },
    [projectId, groupIndicesByGroupId, dispatch, setGroupChangesHaveBeenSaved]
  );

  return {
    onBlur,
    onFocus,
    onEnableLinkingClick,
    onNewGroup,
    onSaveGroupName,
    onSaveTextItems,
  };
};

export default useDraftState;
