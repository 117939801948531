import classNames from "classnames";
import React from "react";
import style from "./LoadingSpinner.module.css";

interface LoadingSpinnerProps {
  text?: string;
  className?: string;
  color?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const color = props.color || "#9E9E9F";

  return (
    <div className={classNames(style.container, props.className)} style={{ color }}>
      <div className={style.svgWrapper}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="5" stroke="url(#paint0_linear_4093_127)" strokeWidth="2" />
          <defs>
            <linearGradient id="paint0_linear_4093_127" x1="0" y1="0" x2="12" y2="12" gradientUnits="userSpaceOnUse">
              <stop stopColor={color} />
              <stop offset="1" stopColor={color} stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      {props.text && <div className={style.text}>{props.text}</div>}
    </div>
  );
};

export default LoadingSpinner;
