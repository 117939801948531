import Lock from "@mui/icons-material/LockOutlined";
import Tooltip from "@shared/frontend/Tooltip";
import classNames from "classnames";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import style from "./TabbedMenu.module.css";

export interface TabData {
  path: string;
  title: React.ReactNode;
  icon: React.FC;
  content: React.FC;

  /**
   * Also match other paths, useful for matching the root path
   */
  alsoMatch?: string[];
  activeIcon?: React.FC;
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
}
export interface TabbedMenuProps {
  tabs: TabData[];
  otherContent?: React.ReactNode;
}

const TabbedMenu = (props: TabbedMenuProps) => {
  return (
    <div className={style.tabbedMenu}>
      <div className={style.tabs}>
        {props.tabs.map((tab) => (
          <Tab {...tab} key={tab.path} />
        ))}
      </div>
      {props.otherContent}
    </div>
  );
};

const Tab = (props: TabData) => {
  let pathsToMatch = [props.path];
  if (props.alsoMatch) pathsToMatch = pathsToMatch.concat(props.alsoMatch);
  const match = useRouteMatch({ path: pathsToMatch, exact: true });
  const Icon = match && props.activeIcon ? props.activeIcon : props.icon;

  return (
    <Tooltip
      disabled={!props.disabled}
      className={style.tooltip}
      content={<div className={style.tooltipBody}>{props.disabledMessage || "Disabled"}</div>}
      theme="dark"
      placement="top"
    >
      <div data-testid={"tab-" + props.title}>
        <Link
          to={props.path}
          className={classNames(style.tab, {
            [style.active]: match,
            [style.disabled]: props.disabled,
          })}
        >
          <div className={style.icon}>
            <Icon />
          </div>
          <span className={style.title}>{props.title}</span>
          {props.disabled && <Lock className={classNames(style.icon, style.lockIcon)} />}
        </Link>
      </div>
    </Tooltip>
  );
};

export default TabbedMenu;
