import { selectedTextItemActivityFamilyAtom } from "@/stores/Activity";
import { onlySelectedTextItemIdAtom } from "@/stores/ProjectSelection";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChangeItemList from "@ds/organisms/ChangeItemList";
import { useAtomValue } from "jotai";
import { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";
import TextItemActivityItem from "./TextItemActivityItem";

function TextItemActivity() {
  const selectedTextId = useAtomValue(onlySelectedTextItemIdAtom);
  const changeItems = useAtomValue(selectedTextItemActivityFamilyAtom(selectedTextId));

  return (
    <Scrollbar className={style.scrollArea}>
      <ChangeItemList changeItems={changeItems} ChangeItemComponent={TextItemActivityItem} />
    </Scrollbar>
  );
}

function TextItemActivityWithSuspense() {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" />}>
      <TextItemActivity />
    </Suspense>
  );
}

export default TextItemActivityWithSuspense;
