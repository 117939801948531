import { AuthenticatedAuthState, UnauthenticatedAuthState } from "@/hooks/auth";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

const LogoutHandler = ({
  logout,
}: {
  logout: AuthenticatedAuthState["logout"] | UnauthenticatedAuthState["logout"];
}) => {
  useEffect(
    () =>
      logout({
        returnTo: `${process.env.BASE_URL}/login`,
      }),
    [logout]
  );

  return <React.Fragment />;
};

const Logout = ({ authState }: { authState: AuthenticatedAuthState | UnauthenticatedAuthState }) => {
  const { loading, isAuthenticated } = authState;

  if (loading) {
    return <React.Fragment />;
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <LogoutHandler logout={authState.logout} />;
};

export default Logout;
