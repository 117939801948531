import { getAssignments } from "@/http/homepage";
import { useEffect, useState } from "react";
import { Props as AssignmentProps } from "./assignment";

export default function useAssignedToYouPanel() {
  const [loading, setLoading] = useState(true);
  const [assignments, setAssignments] = useState<AssignmentProps[]>([]);

  useEffect(() => {
    async function fetchAssignmentData() {
      const [request] = getAssignments();
      const { data } = await request;
      setAssignments(data);
      setLoading(false);
    }
    fetchAssignmentData();
  }, []);

  return {
    loading,
    assignments,
  };
}
