/*
  All of the blocks for a frame
*/
import classnames from "classnames";
import React from "react";
import { Component } from "../../types";
import CompLibraryBlock from "../comp-library-block";
import style from "./style.module.css";

interface Props {
  blocks: {
    block_name: string;
    block_text: Component[];
  }[];
  blockName: string;
  displayApiIds: boolean;
  onComponentClick: () => void;
  selectComp: (input: string | Component) => void;
  isSelected: (compId: Component | string) => boolean;
  handleRenameBlock: (input: { groupName: string; blockNameOld: string; blockNameNew: string }) => void;
  handleDeleteBlock: (dragName: string, compIdsToDelete: string[]) => void;
  setCommentState: (input: { isSelected: boolean; thread_id: string }) => void;
  setPanelState: (updatedState: string) => void;
  selectedComps: { _id: string; name: string }[];
}
const CompLibraryBlocks = ({
  blocks,
  blockName,
  displayApiIds,
  onComponentClick,
  selectComp,
  isSelected,
  handleRenameBlock,
  handleDeleteBlock,
  setCommentState,
  setPanelState,
  selectedComps,
}: Props) => (
  <div
    className={classnames({
      [style.blocks]: true,
    })}
  >
    {blocks.map(({ block_name, block_text }, index) => (
      <CompLibraryBlock
        parentName={blockName}
        dragName={`${blockName}/${block_name}`}
        displayApiIds={displayApiIds}
        name={block_name}
        comps={block_text}
        key={index}
        onComponentClick={onComponentClick}
        selectComp={selectComp}
        isSelected={isSelected}
        handleRenameBlock={handleRenameBlock}
        handleDeleteBlock={handleDeleteBlock}
        setCommentState={setCommentState}
        setPanelState={setPanelState}
        selectedComps={selectedComps}
      />
    ))}
  </div>
);

export default CompLibraryBlocks;
