import * as httpDittoProject from "@/http/dittoProject";
import { projectActivityAtom, updateSelectedTextItemFamilyActionAtom } from "@/stores/Activity";
import {
  handleFigmaTextNodesUnlinkedActionAtom,
  handleNewFigmaSyncActionsAtom,
  handleUpdateBlocksActionAtom,
  newTextItemsCreatedActionAtom,
  textItemsUpdatedActionAtom,
} from "@/stores/Project";
import { previewJobCompletedActionAtom, previewJobStartedActionAtom } from "@/stores/ProjectDesignPreviews";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { IChangeItem } from "@shared/types/ActualChange";
import { JobNames } from "@shared/types/jobs/JobNames";
import logger from "@shared/utils/logger";
import { validateChangeItems } from "@shared/utils/validateChangeItems";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import { WEBSOCKET_EVENTS } from "../../../../../shared/common/constants";
import { WEBSOCKET_URL } from "../../../../../shared/types/websocket";
import { useAuthenticatedAuth } from "../../../../store/AuthenticatedAuthContext";

interface WebsocketsHandlerProps {
  projectId: string;
}

// Only general project Websockets should be handled here.
// Otherwise, place in a domain specific websocket handler component.
export function WebsocketsHandler(props: WebsocketsHandlerProps) {
  const setProjectActivity = useSetAtom(projectActivityAtom);
  const updateSelectedTextItemActivity = useSetAtom(updateSelectedTextItemFamilyActionAtom);
  const previewJobCompletedAction = useSetAtom(previewJobCompletedActionAtom);
  const previewJobStartedAction = useSetAtom(previewJobStartedActionAtom);

  const { sendMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const { getTokenSilently } = useAuthenticatedAuth();

  useEffect(() => {
    async function sendDocSubscribeMsg() {
      if (!props.projectId) {
        return;
      }

      const subscribeToDocMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_DOC_SUBSCRIPTION,
        token: await getTokenSilently(),
        docId: props.projectId,
      };
      sendMessage(JSON.stringify(subscribeToDocMsg));
    }

    if (readyState === 1) {
      sendDocSubscribeMsg();
    }
  }, [readyState, props.projectId, sendMessage, getTokenSilently]);

  useDittoEventListener(
    DittoEvents.projectActualChangesCreated,
    async function handleProjectActualChangesCreated(data) {
      const [request] = httpDittoProject.getActivityByItemIds({
        // Casting because these are incorrectly inferred as ObjectIds,
        // even though they've been serialized to strings
        itemIds: data.changeItemIds as unknown as string[],
        projectId: data.projectId as unknown as string,
      });
      const response = await request;
      const validItems = validateChangeItems(response.data);
      setProjectActivity((prev: IChangeItem[]) => {
        return [...validItems, ...prev];
      });
      updateSelectedTextItemActivity(validItems);
    }
  );

  // MARK: - Blocks updated

  const handleUpdateBlocksAction = useSetAtom(handleUpdateBlocksActionAtom);

  useDittoEventListener(DittoEvents.projectBlocksUpdated, async function handleBlocksUpdated(data) {
    handleUpdateBlocksAction(data);
  });

  // MARK: - Text Items created

  const newTextItemsCreatedAction = useSetAtom(newTextItemsCreatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsCreated, async function handleTextItemsCreated(data) {
    newTextItemsCreatedAction(data);
  });

  // MARK: - Text Items updated
  const textItemsUpdatedAction = useSetAtom(textItemsUpdatedActionAtom);

  useDittoEventListener(DittoEvents.textItemsUpdated, async function handleTextItemsUpdated(data) {
    textItemsUpdatedAction(data);
  });

  // MARK: Unlinking Figma Nodes

  const handleFigmaTextNodesUnlinkedAction = useSetAtom(handleFigmaTextNodesUnlinkedActionAtom);

  useDittoEventListener(DittoEvents.figmaTextNodesUnlinked, async function handleUnlinkNodes(data) {
    handleFigmaTextNodesUnlinkedAction(data);
  });

  // MARK: Background job updates
  useDittoEventListener(DittoEvents.backgroundJobUpdated, async function handleBackgroundJobUpdated(data) {
    if (data.projectId !== props.projectId) return;

    if (data.status === "active") {
      logger.info(`Job [${data.jobName}] started`, { context: { ...data } });

      if (data.jobName === JobNames.DittoProjectFigmaPreviewsJob) {
        previewJobStartedAction();
      }
    } else if (data.status === "completed") {
      logger.info(`Job [${data.jobName}] completed`, { context: { ...data } });

      if (data.jobName === JobNames.DittoProjectFigmaPreviewsJob) {
        previewJobCompletedAction();
      }
    }
  });

  const handleNewFigmaSyncActions = useSetAtom(handleNewFigmaSyncActionsAtom);

  useDittoEventListener(DittoEvents.newFigmaSyncActions, async function newFigmaSyncActionsHandler(data) {
    if (data.projectId !== props.projectId) {
      return;
    }

    handleNewFigmaSyncActions(data.actions);
  });

  return null;
}
