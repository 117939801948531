import { useFigmaAuth } from "@/store/FigmaAuthContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as SegmentEvents from "@shared/segment-event-names";
import ClassNames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import { CommitSuggestions } from "../../../shared/types/http/Doc";
import ExpandableSuggestions from "../../ExpandableSuggestions";
import useSegment from "../../hooks/useSegment";
import {
  SetupSuggestionsForGroup,
  commitSuggestions,
  fetchSetupSuggestionForGroups,
  turnOffSetupSuggestionsForGroup,
} from "../../http/setupSuggestions";
import { isGroupLinked } from "../../views/Project/state/types";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import style from "./style.module.css";
// MARK: - Helper Views

const SuggestionRow = ({
  icon,
  title,
  count,
  selected,
  handleSelected,
}: {
  icon: JSX.Element;
  title: string;
  count: number;
  selected: boolean;
  handleSelected: (selected: boolean) => void;
}) => {
  const handleOnCheckboxChange = (e) => {
    handleSelected(e.target.checked);
  };

  return (
    <div className={style.suggestionRow}>
      <input type="checkbox" checked={selected} className={style.selectedCheckbox} onChange={handleOnCheckboxChange} />
      <div className={style.suggestionCard}>
        <div className={style.suggestionTitleWrapper}>
          {icon}
          <div className={style.suggestionTitle}>{title}</div>
        </div>
        <div className={style.suggestionCount}>{count}</div>
      </div>
    </div>
  );
};

// MARK: - Main Component

interface SetupSuggestionsProps {
  originalGroupId: string;
  selectAllCompsInGroup: (groupId: string, frameId?: string) => void;
}

const SetupSuggestions = ({ selectAllCompsInGroup, originalGroupId }: SetupSuggestionsProps) => {
  const segment = useSegment();

  // MARK - Hooks
  const {
    doc: [doc],
    groupState: [groupState],
    setupSuggestions,
    setSetupSuggestions,
    setSetupSuggestionsForAllGroups,
    showSetupSuggestionsPreview,
    setShowSetupSuggestionsPreview,
    setIsShowingSetupSuggestionsFlow,
    groupWithSetupSuggestionsApplied,
    noSuggestionsSelected,
  } = useProjectContext();
  const [selectedGroup, setSelectedGroup] = useState<GroupOption>();
  const [previousGroup, setPreviousGroup] = useState<GroupOption>();
  const [errorJSX, setErrorJSX] = useState<JSX.Element>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id: docId } = useParams<{
    id?: string;
  }>();
  const { isFigmaAuthenticated } = useFigmaAuth();
  const originalGroupIdRef = useRef(originalGroupId);

  const currentGroup = useMemo(
    () => groupState.groups.find((group) => group._id === originalGroupIdRef.current),
    [groupState.groups, originalGroupIdRef.current]
  );

  const currentGroupId: string | undefined = useMemo(() => currentGroup?._id, [currentGroup]);

  const formattedGroups = useMemo(() => {
    return groupState.groups.flatMap((group) => {
      if (isGroupLinked(group) && group._id.toString() !== currentGroup?._id) {
        const frameId = group.integrations.figma.frame_id;
        if (frameId) {
          return {
            frameId,
            label: group.name,
            value: group._id,
          };
        }
      }
      return [];
    });
  }, [groupState.groups, currentGroup]);

  const hasHideSuggestions = useMemo(
    () => setupSuggestions && setupSuggestions.hideSuggestions.length > 0,
    [setupSuggestions]
  );

  const hasBlocksOrHidingSuggestions = useMemo(
    () => (setupSuggestions && Object.keys(setupSuggestions.blockSuggestions).length > 0) || hasHideSuggestions,
    [setupSuggestions, hasHideSuggestions]
  );

  const hasWsCompSuggestions = useMemo(() => {
    if (isLoading || !setupSuggestions) return false;

    return setupSuggestions.wsComponentSuggestions.length > 0;
  }, [isLoading, setupSuggestions?.wsComponentSuggestions]);

  const hasSetupSuggestions = useMemo(
    () => Boolean(!isLoading && (hasBlocksOrHidingSuggestions || hasWsCompSuggestions)),
    [hasBlocksOrHidingSuggestions, hasWsCompSuggestions, isLoading]
  );

  const blocksAndHidingCount = useMemo(() => {
    if (!setupSuggestions) {
      return 0;
    }
    return (
      Object.values(setupSuggestions.blockSuggestions).reduce((acc, cur) => {
        return (acc += cur.textItems.length);
      }, 0) + setupSuggestions.hideSuggestions.length
    );
  }, [setupSuggestions]);

  const wsCompSuggestionsAllSelected = useMemo(
    () =>
      Boolean(
        setupSuggestions?.wsComponentSuggestions.every((wsCompSuggestion) => {
          return wsCompSuggestion.selected;
        })
      ),
    [setupSuggestions?.wsComponentSuggestions]
  );

  const wsCompSuggestionCount: number = useMemo(
    () => setupSuggestions?.wsComponentSuggestions.length || 0,
    [setupSuggestions?.wsComponentSuggestions]
  );

  type GroupOption = (typeof formattedGroups)[number];

  const fetchSuggestions = async (baseFrameId: string, frameId: string, projectId: string) => {
    try {
      const [request, cancelRequest] = fetchSetupSuggestionForGroups({
        baseGroupId: baseFrameId,
        targetGroupId: frameId,
        projectId,
      });

      const result = await request;
      const data = result.data;
      // Add selected to ws component suggestions
      const newSetupSuggestions: SetupSuggestionsForGroup = {
        blockSuggestions: Object.entries(data.blockSuggestions).reduce<SetupSuggestionsForGroup["blockSuggestions"]>(
          (acc, [blockId, blockData]) => {
            acc[blockId] = {
              selected: true,
              textItems: blockData.textItems,
              blockName: blockData.blockName,
            };
            return acc;
          },
          {}
        ),
        hideSuggestionsSelected: true,
        hideSuggestions: data.hideSuggestions,
        wsComponentSuggestions: data.wsComponentSuggestions.map((suggestion) => ({
          ...suggestion,
          selected: true,
        })),
        groupId: data.targetGroupId,
        baseGroupId: data.baseGroupId,
        projectId: data.projectId,
      };
      setSetupSuggestions(newSetupSuggestions);
      if (
        Object.keys(newSetupSuggestions.blockSuggestions).length === 0 &&
        newSetupSuggestions.hideSuggestions.length === 0 &&
        newSetupSuggestions.wsComponentSuggestions.length == 0
      ) {
        console.log("setting error jsx");
        setErrorJSX(
          <>We weren't able to generate any suggestions for items to copy over. Please select another group.</>
        );
      } else {
        setErrorJSX(undefined);
      }

      setIsLoading(false);

      return () => {
        cancelRequest();
      };
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (isFigmaAuthenticated) {
        setErrorJSX(
          <>
            Error generating suggestions.{" "}
            <span className={style.errorLink} onClick={() => fetchSuggestions(baseFrameId, frameId, projectId)}>
              Click here
            </span>{" "}
            to try again.
          </>
        );
      } else {
        setErrorJSX(
          <>
            Error generating suggestions. Please{" "}
            <Link to={`/account/user?reauthorize_figma=true`}>connect your figma account</Link> to continue.
          </>
        );
      }
    }
  };

  // MARK - Event Handlers

  const handleOnFrameChange = async (selectedOption: GroupOption) => {
    if (!selectedOption) {
      setSetupSuggestions({
        groupId: setupSuggestions?.groupId || "",
        baseGroupId: "",
        projectId: "",
        blockSuggestions: {},
        hideSuggestions: [],
        hideSuggestionsSelected: true,
        wsComponentSuggestions: [],
      });
      setSelectedGroup(undefined);
      return;
    }
    if (selectedGroup !== selectedOption) {
      setPreviousGroup(selectedGroup);
      setSelectedGroup(selectedOption);
    }
  };

  const resetSetupSuggestionsState = () => {
    setIsSubmitting(false);
    setIsShowingSetupSuggestionsFlow(false);
    selectAllCompsInGroup(originalGroupIdRef.current);
    setSetupSuggestions({
      groupId: "",
      projectId: "",
      blockSuggestions: {},
      hideSuggestions: [],
      hideSuggestionsSelected: true,
      wsComponentSuggestions: [],
    });
    setSelectedGroup(undefined);
  };

  const baseGroupIdExists = setupSuggestions?.baseGroupId !== undefined;

  const onSubmitButtonClick = async () => {
    try {
      if (!groupWithSetupSuggestionsApplied || !currentGroup || !isGroupLinked(currentGroup) || !docId) return; // Need an error here

      const frameSuggestions: CommitSuggestions = [
        {
          targetGroupId: currentGroup._id,
          blockSuggestions: groupWithSetupSuggestionsApplied.blocks.reduce<Record<string, string[]>>((acc, block) => {
            acc[block.name] = block.comps.map((c) => c._id);
            return acc;
          }, {}),
          hideSuggestions: setupSuggestions?.hideSuggestionsSelected ? setupSuggestions.hideSuggestions : [],
          wsComponentSuggestions:
            setupSuggestions?.wsComponentSuggestions
              .filter((wsCompSuggestion) => wsCompSuggestion.selected)
              .map((wsCompSuggestion) => ({
                id: wsCompSuggestion.id,
                wsComponentId: wsCompSuggestion.wsComponent.wsComp || "",
              })) || [],
        },
      ];
      setIsSubmitting(true);

      const [request, cancelRequest] = commitSuggestions(frameSuggestions, docId);
      await request;
      segment.track({
        event: baseGroupIdExists
          ? "Copy Setup from Another Group — Suggestions Applied"
          : "Post-import suggestion — Suggestions Applied",
        properties: {
          num_component_suggestions_selected:
            setupSuggestions?.wsComponentSuggestions.filter((wscs) => wscs.selected).length || 0,
          num_component_suggestions_total: setupSuggestions?.wsComponentSuggestions.length || 0,
          num_block_suggestions_total: setupSuggestions?.blockSuggestions.length || 0,
          num_block_suggestions_selected:
            Object.values(setupSuggestions?.blockSuggestions || {}).filter((bs) => bs.selected)?.length || 0,
          num_hide_suggestions_total: setupSuggestions?.hideSuggestions.length || 0,
          hide_suggestions_selected: setupSuggestions?.hideSuggestionsSelected || false,
        },
      });
      // Remove suggestions from state
      setSetupSuggestionsForAllGroups((prev) => {
        const newState = structuredClone(prev);
        delete newState[currentGroupId || ""];
        return newState;
      });
      resetSetupSuggestionsState();
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setErrorJSX(
        <>
          Error submitting suggestions.{" "}
          <div className={style.errorLink} onClick={() => onSubmitButtonClick()}>
            Click here
          </div>{" "}
          to try again.
        </>
      );
    }
  };

  const onPreviewButtonClick = () => {
    segment.track({
      event: SegmentEvents.COPY_SETUP_FROM_ANOTHER_GROUP_PREVIEW_TOGGLED,
      properties: {
        value: !showSetupSuggestionsPreview,
      },
    });
    setShowSetupSuggestionsPreview(!showSetupSuggestionsPreview);
  };

  const onSelectButtonClick = () => {
    if (!docId || !currentGroupId || !selectedGroup) {
      return; // todo get an error
    }
    segment.track({ event: "Copy Setup from Another Group - Group Selected" });
    setIsLoading(true);
    setPreviousGroup(selectedGroup);

    fetchSuggestions(selectedGroup.value, currentGroupId, docId);
  };

  const onCancelButtonClick = async () => {
    // Remove suggestion from state if cancelled, but only from the generate all use case
    // Copy from use case will not delete
    if (currentGroupId && docId && !baseGroupIdExists) {
      const [request] = turnOffSetupSuggestionsForGroup(currentGroupId, docId);
      const res = await request;

      if (res.status === 200) {
        // Remove suggestions from state
        setSetupSuggestionsForAllGroups((prev) => {
          const newState = structuredClone(prev);
          delete newState[currentGroupId || ""];
          return newState;
        });
      }
      segment.track({ event: "Post-import suggestion - Ignore" });
    } else {
      segment.track({ event: "Copy Setup from Another Group - Canceled" });
    }
    resetSetupSuggestionsState();
  };

  const updateBlockSelected = async (blockId: string, selected: boolean) => {
    if (!setupSuggestions) return;

    const newSetupSuggestions = structuredClone(setupSuggestions);

    newSetupSuggestions.blockSuggestions[blockId].selected = selected;

    setSetupSuggestions(newSetupSuggestions);
  };

  const updateHideSuggestions = (selected: boolean) => {
    if (!setupSuggestions) return;
    setSetupSuggestions({
      ...setupSuggestions,
      hideSuggestionsSelected: selected,
    });
  };

  const toggleWsSuggestion = (idx: number) => {
    if (!setupSuggestions) return;

    const newSetupSuggestions = structuredClone(setupSuggestions);

    newSetupSuggestions.wsComponentSuggestions[idx].selected = !setupSuggestions.wsComponentSuggestions[idx].selected;

    setSetupSuggestions(newSetupSuggestions);
  };

  const toggleAllWsSuggestions = () => {
    if (!setupSuggestions) return;

    const newSetupSuggestions = structuredClone(setupSuggestions);

    newSetupSuggestions.wsComponentSuggestions.forEach((wsComponentSuggestion) => {
      wsComponentSuggestion.selected = !wsCompSuggestionsAllSelected;
    });

    setSetupSuggestions(newSetupSuggestions);
  };

  const showBaseGroupSelect = useMemo(
    () => !setupSuggestions || (setupSuggestions && setupSuggestions.baseGroupId !== undefined),
    [setupSuggestions]
  );
  return (
    <div className={style.content}>
      {showBaseGroupSelect && (
        <>
          <div className={style.subtitle}>
            Group to Copy From
            <HelpOutlineIcon className={style.subtitleIcon} data-tip data-for="link-help" />
            <ReactTooltip
              id="link-help"
              place="bottom"
              effect="solid"
              className={style.tooltip}
              multiline={true}
              delayHide={100}
              clickable={true}
            >
              The group in this project that you'd like to copy setup items (Blocks, Hiding) from.{" "}
              <a href="https://www.dittowords.com/docs/setup-suggestions" target="_blank">
                Learn more
              </a>
            </ReactTooltip>
          </div>
          <Select
            className={style.dropdown}
            styles={{
              menu: (baseStyles) => ({ ...baseStyles, maxHeight: 150 }),
              menuList: (baseStyles) => ({ ...baseStyles, maxHeight: 150 }),
            }}
            cacheOptions
            defaultOptions
            placeholder="Select a group..."
            options={formattedGroups}
            isClearable
            onChange={handleOnFrameChange}
          />
          <div
            className={ClassNames({
              [style.buttons]: true,
              [style.buttonsTopMargin]: true,
            })}
          >
            {!hasSetupSuggestions && <ButtonSecondary text="Cancel" onClick={onCancelButtonClick} />}

            <ButtonPrimary
              text={isLoading ? "Loading..." : "Select"}
              loading={isLoading}
              loadingPosition="left"
              onClick={onSelectButtonClick}
              disabled={isLoading || previousGroup === selectedGroup || isSubmitting}
            />
          </div>
          {hasSetupSuggestions && (
            <div className={style.suggestionsHeader}>Select the items you want to copy over below.</div>
          )}
        </>
      )}
      {hasSetupSuggestions && (
        <div className={style.suggestions}>
          {hasWsCompSuggestions && (
            <div className={style.wsCompSuggestions}>
              <div className={style.suggestionsTitle}>
                Components <div className={style.titleCount}>{wsCompSuggestionCount}</div>
              </div>
              <div className={style.suggestionRow}>
                <ExpandableSuggestions
                  suggestions={
                    setupSuggestions?.wsComponentSuggestions.map((compSuggestion) => ({
                      name: compSuggestion.wsComponentName,
                      text: compSuggestion.wsComponent.rich_text,
                      matchPct: Math.trunc(compSuggestion.confidence * 100),
                      selected: compSuggestion.selected,
                      onSelect: (idx) => {
                        toggleWsSuggestion(idx);
                      },
                    })) || []
                  }
                  selected={wsCompSuggestionsAllSelected}
                  onSelect={toggleAllWsSuggestions}
                />
              </div>
            </div>
          )}
          {hasBlocksOrHidingSuggestions && (
            <>
              <div className={style.suggestionsTitle}>
                Blocks and Hiding <div className={style.titleCount}>{blocksAndHidingCount}</div>
              </div>
              {setupSuggestions &&
                Object.entries(setupSuggestions.blockSuggestions).map(([blockId, suggestion]) => (
                  <div key={blockId} className={style.suggestionSection}>
                    <SuggestionRow
                      {...{
                        icon: <ViewStreamIcon className={style.suggestionIcon}></ViewStreamIcon>,
                        title: suggestion.blockName,
                        count: suggestion.textItems.length,
                        selected: suggestion.selected,
                        handleSelected: (selected: boolean) => {
                          updateBlockSelected(blockId, selected);
                        },
                      }}
                    ></SuggestionRow>
                  </div>
                ))}
              {hasHideSuggestions && setupSuggestions && (
                <div className={style.suggestionSection}>
                  <SuggestionRow
                    {...{
                      icon: <VisibilityOffIcon className={style.suggestionIcon}></VisibilityOffIcon>,
                      title: "Hidden",
                      count: setupSuggestions.hideSuggestions.length,
                      selected: setupSuggestions.hideSuggestionsSelected,
                      handleSelected: (selected: boolean) => {
                        updateHideSuggestions(selected);
                      },
                    }}
                  ></SuggestionRow>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {hasSetupSuggestions && (
        <div
          className={ClassNames({
            [style.buttons]: true,
            [style.buttonsTopMargin]: !hasSetupSuggestions,
          })}
        >
          <div
            className={ClassNames({
              [style.previewWrapper]: true,
              [style.toggleEnabled]: showSetupSuggestionsPreview,
              [style.toggleDisabled]: !showSetupSuggestionsPreview,
            })}
          >
            <Toggle
              className={style.toggle}
              checked={showSetupSuggestionsPreview}
              icons={false}
              onChange={onPreviewButtonClick}
              disabled={noSuggestionsSelected}
            />{" "}
            Preview
          </div>
          <ButtonSecondary text={baseGroupIdExists ? "Cancel" : "Ignore"} onClick={onCancelButtonClick} />
          <ButtonPrimary
            text="Apply"
            onClick={onSubmitButtonClick}
            disabled={!hasSetupSuggestions || isSubmitting || noSuggestionsSelected}
          />
        </div>
      )}
      {errorJSX && <div className={style.noSuggestions}>{errorJSX}</div>}
    </div>
  );
};

export default SetupSuggestions;
