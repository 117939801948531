import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";

interface Props {
  children: React.ReactNode[];
  onOpen?: () => void;
  onClose?: () => void;
}
const IgnoredSuggestionsDrawer = (props: Props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    isOpen ? props.onClose?.() : props.onOpen?.();
    setIsOpen((prev) => !prev);
  };

  useEffect(
    function closeDrawerCloseOnNoIgnoredSuggestions() {
      if (children.length === 0) setIsOpen(false);
    },
    [children]
  );

  if (children.length === 0) return <React.Fragment />;

  return (
    <div className={style.container} id="ignore-suggestions-drawer">
      <div
        className={classNames({
          [style.header]: true,
          [style.closed]: !isOpen,
        })}
        onClick={toggleOpen}
      >
        <div>{isOpen ? <ArrowDropUpIcon className={style.icon} /> : <ArrowDropDownIcon className={style.icon} />}</div>
        <div className={style.title}>
          <span>Ignored Suggestions</span>
          <div className={style.badge}>{children.length}</div>
        </div>
      </div>
      <div
        className={classNames({
          [style.content]: true,
          [style.openDrawer]: isOpen,
          [style.closedDrawer]: !isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default IgnoredSuggestionsDrawer;
