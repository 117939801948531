import React, { useState } from "react";
import ModalFooter from "react-bootstrap/ModalFooter";
import { userHasPermission } from "../../../../../shared/frontend/userPermissionContext";
import FolderSelectCreatable from "../../../../components/FolderSelect/Creatable";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ModalBase, { ModalBody } from "../../../../components/shared/ModalBase";
import style from "./style.module.css";

const MoveToFolderModal = ({ variables, variableFolders, onHide, onSubmit, selectedFolderId }) => {
  const [selectedFolder, setSelectedFolder] = useState<{
    _id: string;
    name: string;
  } | null>(null);
  const title = variables.length > 1 ? "Move Variables" : "Move Variable";
  const buttonText = variables.length > 1 ? "Move Variables" : "Move Variable";
  const modalText =
    variables.length > 1 ? (
      <span>
        Please select the folder where you want to move these <b>{variables.length}</b> variables.
      </span>
    ) : (
      <span>Please select the folder where you want to move this variables.</span>
    );

  const onChange = (option: { __isNew__?: boolean; value: string; label: string }) => {
    if (!option) {
      return;
    }

    if (option.__isNew__) {
      setSelectedFolder({
        _id: "__new__",
        name: option.label,
      });
      return;
    }

    setSelectedFolder({
      _id: option.value,
      name: option.label,
    });
  };

  const canCreateFolder = userHasPermission("variable_folder:create");

  return (
    <ModalBase onHide={onHide} title={title}>
      <ModalBody>
        <div className={style.text}>{modalText}</div>
        <FolderSelectCreatable
          canCreateFolder={canCreateFolder}
          folders={[{ name: "None", _id: "none" }, ...variableFolders]}
          onChange={onChange}
          currentFolderId={selectedFolderId || ""}
          value={selectedFolder ? { value: selectedFolder._id, label: selectedFolder.name } : null}
        />
      </ModalBody>
      <ModalFooter className={style.buttons}>
        <ButtonPrimary
          onClick={() => {
            onSubmit(selectedFolder);
          }}
          text={buttonText}
          disabled={!selectedFolder}
        />
      </ModalFooter>
    </ModalBase>
  );
};
export default MoveToFolderModal;
