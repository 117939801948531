import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  hasMore?: boolean;
  isLoadingMore?: boolean;
  fetchMore?: () => void | Promise<void>;
}

export function LoadMore(props: React.PropsWithChildren<IProps>) {
  if (!props.hasMore) {
    return <React.Fragment />;
  }

  // TODO: Implement loading state
  if (props.isLoadingMore) {
    return <React.Fragment />;
  }

  return (
    <>
      {/** Optional content to conditionally render before the Load More UI
       * only if it's being rendered, like a divider */}
      {props.children}
      <div
        className={classNames(style.LoadMoreWrapper, props.className)}
        data-testid="load-more"
        onClick={props.fetchMore}
      >
        <div>Load more</div>
        <ArrowDownwardIcon className={style.icon} />
      </div>
    </>
  );
}

export default LoadMore;
