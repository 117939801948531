import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import style from "./style.module.css";

interface Props {
  isLocked: boolean;
  mainProjectId: string;
  branchProjectId: string;
}
const ProjectLockedDescription = ({ isLocked, mainProjectId, branchProjectId }: Props) => {
  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <div className={style.iconWrapper}>
          <LockIcon className={style.icon} />
        </div>
        <div className={style.description}>
          {isLocked && (
            <div>
              This project is <strong>currently locked</strong> because a branch of this Figma file is being worked on
              in Ditto.
            </div>
          )}
          {!isLocked && (
            <div>
              This project is <strong>archived</strong> because this Figma branch has been merged.
            </div>
          )}
          <a href={`/projects/${isLocked ? branchProjectId : mainProjectId}`} className={style.linkBtn}>
            {isLocked ? `Open branch project` : `View branch project`}
            {"->"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectLockedDescription;
