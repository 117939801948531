import ErrorFallback from "@/components/ErrorFallback";
import TrustedSignIn from "@/components/TrustedSignIn";
import style from "@/components/app/style.module.css";
import LoginSaml from "@/components/login/LoginSaml";
import Login from "@/components/login/login";
import CustomLogin from "@/components/login/login-custom";
import { routes } from "@/defs";
import { useUnauthenticatedAuth } from "@/store/UnauthenticatedAuthContext";
import history from "@/utils/history";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import logger from "@shared/utils/logger";
import React, { useCallback } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { getURLParams } from "../../../util/url";
import { PREFERRED_LOGIN_PAGE_KEY } from "../../hooks/auth/index";
import AuthCallback from "../auth-callback/auth-callback";
import JoinWorkspace from "../join-workspace/join-workspace";
import Logout from "./Logout";

const loginPath = process.env.IS_LOCAL === "true" ? "/trusted" : routes.nonNavRoutes.login.path;

export default function UnauthenticatedApp() {
  const authState = useUnauthenticatedAuth();
  const onError = useCallback((error: Error) => {
    logger.error(error.message, {}, error);
    window?.analytics?.track("App Crash", {
      ts: Date.now() - 5000,
      datadogRUMInternalContext: datadogRum.getInternalContext(Date.now()),
    });
  }, []);

  const beforeCapture = useCallback((scope) => {
    scope.setTag("componentName", "App");
  }, []);

  const preferredLoginPage = localStorage.getItem(PREFERRED_LOGIN_PAGE_KEY);
  // prevent redirect to SAML page when user is trying direct access to different enterprise OR if url has error param (e.g. auth0 error)
  const preventRedirect =
    window.location.pathname.startsWith("/signin/") || getURLParams(window.location.href).get("error");

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} onError={onError} beforeCapture={beforeCapture}>
      <Container fluid className={style.container_fluid}>
        <Router history={history}>
          <Helmet>
            <title>Ditto</title>
          </Helmet>
          <Switch>
            {/* Redirect for when user has logged in previously with enterprise SAML */}
            {!!preferredLoginPage && !preventRedirect && (
              <Route exact path={[routes.nonNavRoutes.login.path, routes.nonNavRoutes.loginSaml.path]}>
                <Redirect to={`/signin/${preferredLoginPage}`} />
              </Route>
            )}
            <Route exact path={routes.nonNavRoutes.login.path}>
              <Login />
            </Route>
            <Route exact path={routes.nonNavRoutes.loginSaml.path}>
              <LoginSaml />
            </Route>
            <Route path={routes.nonNavRoutes.custom_login.path}>
              <CustomLogin />
            </Route>
            <Route path="/trusted">
              <TrustedSignIn />
            </Route>
            {/* Common Routes */}
            <Route
              exact
              path={[routes.nonNavRoutes.join_workspace.path, routes.nonNavRoutes.legacy_join_workspace.path]}
            >
              <JoinWorkspace authState={authState} />
            </Route>
            <Route exact path={routes.nonNavRoutes.authCallback.path}>
              <AuthCallback />
            </Route>
            <Route exact path={routes.nonNavRoutes.logout.path}>
              <Logout authState={authState} />
            </Route>
            {/* Catch All Redirect */}
            <Route path="*">
              <Redirect to={loginPath} />
            </Route>
          </Switch>
        </Router>
      </Container>
    </Sentry.ErrorBoundary>
  );
}
