import React from "react";

import style from "./style.module.css";

interface Props {
  style?: React.CSSProperties;
}

const DeveloperModeDisabled = (props: Props) => {
  return (
    <div className={style.exportPanelEnableDeveloperMode} style={props.style}>
      <span>
        <a href="https://www.dittowords.com/docs/ditto-developer-mode" target="_blank">
          Developer mode
        </a>{" "}
        must be enabled
        <br /> to use this export format.
      </span>
    </div>
  );
};

export default DeveloperModeDisabled;
