import { Extension } from "@tiptap/core";

/**
 * Extension to accomplish the following:
 * - When the user presses "Shift-Enter", insert a new line
 */
export const ShiftEnterExtension = Extension.create({
  name: "shiftEnterExtension",
  addKeyboardShortcuts() {
    return {
      "Shift-Enter": () =>
        // This mimics default "Enter" behavior on tiptap
        // See https://github.com/ueberdosis/tiptap/blob/53673fbfe0c2f41ed2d2caae0b554ecbf3d03803/packages/core/src/extensions/keymap.ts#L56
        this.editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock(),
        ]),
    };
  },
});
