import TextItem from "@ds/molecules/TextItem";
import { memo, Suspense } from "react";
import TextItemWrapper from "../TextItemWrapper";

interface ITextItemRowProps {
  textItemId: string;
  activeVariantId: string;
  activeVariantName: string;
  highlightedPhrase?: string;
  className?: string;
}

const TextItemRow = memo(function TextItemRow(props: ITextItemRowProps) {
  const { textItemId } = props;

  return (
    <Suspense
      key={textItemId}
      fallback={
        <TextItem
          key={textItemId}
          defaultValue={{
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "Loading...",
                  },
                ],
              },
            ],
          }}
        />
      }
    >
      <TextItemWrapper {...props} />
    </Suspense>
  );
});

export default TextItemRow;
