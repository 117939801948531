import { mostRecentTextItemCommentFamilyAtom, postCommentActionAtom } from "@/stores/Comments";
import { detailsPanelEditStateAtom, onlySelectedTextItemIdAtom } from "@/stores/ProjectSelection";
import { deferredUsersAtom } from "@/stores/Workspace";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import CommentEditor from "@ds/organisms/CommentEditor";
import { IUser } from "@shared/types/User";
import { useAtomValue, useSetAtom } from "jotai";
import { CommentThreadWrapper } from "../../CommentThreadWrapper";
import style from "./style.module.css";

function Comments() {
  const users = useAtomValue(deferredUsersAtom);
  const selectedTextItemId = useAtomValue(onlySelectedTextItemIdAtom);
  const setSelectedDetailsTab = useSetAtom(detailsPanelEditStateAtom);
  const postCommentAction = useSetAtom(postCommentActionAtom);
  const mostRecentThreadId = useAtomValue(mostRecentTextItemCommentFamilyAtom(selectedTextItemId));

  const handlePostComment = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    if (!selectedTextItemId) {
      return;
    }

    await postCommentAction({
      textItemId: selectedTextItemId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const handleShowActivity = () => {
    setSelectedDetailsTab("ACTIVITY");
  };

  if (!selectedTextItemId) {
    return <></>;
  }

  return (
    <div className={style.commentsContainer}>
      <Text size="small" color="primary" weight="strong">
        Comment
      </Text>
      <CommentEditor
        placeholderText="Add a comment..."
        submitButtonText="Add comment"
        saveContentCallback={handlePostComment}
        mentionableUsers={users}
      />
      {!!mostRecentThreadId && (
        <>
          <div className={style.mostRecentHeader}>
            <Text size="small" color="secondary">
              Most recent
            </Text>
            <Button size="micro" level="subtle" onClick={handleShowActivity}>
              See all activity
            </Button>
          </div>
          <CommentThreadWrapper commentThreadId={mostRecentThreadId} />
        </>
      )}
    </div>
  );
}

export default Comments;
