const ROOT = "/variant-folder";

export default {
  get: {
    folders: {
      url: ROOT,
    },
  },
  post: {
    createFolder: {
      url: ROOT,
      body: (name: string, variant_ids: string[]) => ({
        name,
        variant_ids,
      }),
    },
    moveVariantsToFolder: {
      url: (folderId: string | null) => `${ROOT}/${folderId || "none"}/variants`,
      body: (variant_ids: string[]) => ({ variant_ids }),
    },
  },
  put: {
    updateFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
      body: (name: string) => ({ name }),
    },
  },
  delete: {
    deleteFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
    },
  },
};
