import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import Callout from "../../molecules/Callout";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * Name of the variant.
   */
  variantName: string;

  /**
   * Callback function for adding a variant to the text item.
   */
  onAdd?: () => void;
}

export function TextItemVariantNotPresent(props: IProps) {
  return (
    <div
      style={props.style}
      className={classNames(style.TextItemVariantNotPresentWrapper, style.inline, props.className)}
      data-testid="text-item-variant-not-present"
    >
      <div className={style.TextItemVariantMetadata}>
        <div className={style.TextItemVariantHeader}>
          <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
          <Text size="small" weight="strong">{`${props.variantName} variant`}</Text>
        </div>
        <Callout
          className={style.noVariantCallout}
          content={
            <Text size="small" inline>
              No&nbsp;
              <Text weight="strong" size="small">
                {props.variantName}
              </Text>
              &nbsp;variant present on this text item.
            </Text>
          }
          variant="info"
        />
      </div>
      <div className={style.addVariantButtonWrapper}>
        <Button className={style.addVariantButton} expansion="block" level="outline" onClick={props.onAdd}>
          Add variant
        </Button>
      </div>
    </div>
  );
}

export default TextItemVariantNotPresent;
