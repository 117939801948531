import classNames from "classnames";
import React from "react";

import { ComponentType } from "../../types/Component";
import DashboardCustomize from "../icons/DashboardCustomize";

import style from "./TemplateBadge.module.css";

interface Props {
  type: ComponentType;
  className?: string;
  classNameSelected?: string;
  selected?: boolean;
  style?: React.CSSProperties;
}

const TemplateBadge = (props: Props) => {
  if (props.type !== "template") {
    return <React.Fragment />;
  }

  return (
    <div
      className={classNames(style.container, props.className, {
        [style.selected]: props.selected,
        [props.classNameSelected || ""]: props.selected,
      })}
      style={props.style}
    >
      <div className={style.badge}>
        <div className={style.badgeInner}>
          <DashboardCustomize /> <span>Template</span>
        </div>
      </div>
    </div>
  );
};

export default TemplateBadge;
