/*
  A single block of text in a frame
*/
import * as httpDoc from "@/http/docNew";
import { useWorkspace } from "@/store/workspaceContext";
import { useProjectContext } from "@/views/Project/state/useProjectState";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { removeDiacritics } from "@shared/utils/removeDiacritics";
import classnames from "classnames";
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Element } from "react-scroll";
import spinner from "../../assets/small-spinner.gif";
import http, { API } from "../../http";
import ApiID from "../api-id";
import CompInBlock from "../compInBlock/compInBlock";
import style from "./style.module.css";

const Block = ({
  id,
  name,
  comps,
  uniqueBlockNames,
  apiID,
  selectComp,
  isSelected,
  index,
  numBlocks,
  frameIndex,
  figmaId,
  doc_ID,
  updateDoc,
  blockToRename,
  setBlockToRename,
  isInFilters,
  filtersOff,
  setPanelState,
  setCommentState,
  displayApiIds,
  handleHistoryUpdate,
  frameVariant,
  frameVariants,
  handleChangeBlockOrder,
  isOrderChanging,
  groupId,
  suggestedCompId,
  showSetupFromAnotherGroup,
  resyncLoading,
  hoveredVariantId,
  setHoveredVariantId,
  handleSelectActiveVariant,
}) => {
  const [isRenaming, setIsRenaming] = useState(blockToRename === frameIndex + "-" + index);
  const [isLoading, setIsLoading] = useState(false);
  const [blockNameInput, setBlockNameInput] = useState(name || "");
  const [error, setError] = useState(null);
  const numCompsFiltered = comps.filter((comp) => isInFilters(comp)).length;
  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const { workspaceInfo } = useWorkspace();

  const manualApiIdEditsAllowed = isEditEnabled && !workspaceInfo?.config?.projects?.apiIdPreventManualEdits;

  const projectContext = useProjectContext();

  const onApiIdEdit = async (newApiId) => {
    try {
      await httpDoc.updateBlockApiId({
        projectId: projectContext.projectId,
        blockId: id,
        newApiId,
      });
      return "success";
    } catch (e) {
      console.error(e);
      return "failure";
    }
  };

  const onBlur = () => {
    saveRename();
  };
  const startBlockRenaming = () => {
    setIsRenaming(true);
  };

  const handleBlockNameChange = (e) => {
    const value = e.target.value;
    const text = removeDiacritics(value).replace(/[^a-zA-Z0-9_-\s]/g, "");
    setBlockNameInput(text);
  };

  const checkPressEnter = (e) => {
    if (e.key === "Enter") {
      saveRename();
    }
  };

  const isSuggested = (id) => id === suggestedCompId;

  const saveRename = async () => {
    try {
      setIsLoading(true);
      setBlockToRename(null);
      if (blockNameInput === "") {
        setBlockNameInput(name || "");
        setIsLoading(false);
        setBlockToRename(null);
        return;
      }
      if (name !== blockNameInput && uniqueBlockNames.includes(blockNameInput)) {
        setError("Block names must be unique");
        setIsLoading(false);
        setTimeout(() => {
          setIsRenaming(false);
          setBlockNameInput(name || "");
          setError(null);
        }, 2000);
        return;
      } else if (name !== blockNameInput) {
        const newBlockName = blockNameInput;
        const { url, body } = API.doc.post.renameBlock;
        await http.post(
          url,
          body({
            doc_ID: doc_ID,
            groupId,
            blockIndex: index,
            blockName: newBlockName,
          })
        );

        // Update block name in frontend
        updateDoc([groupId]);
        setIsRenaming(false);
        handleHistoryUpdate(true);
      } else {
        setIsRenaming(false);
      }
    } catch (err) {
      console.error(err);
    }
    setError(null);
    setIsLoading(false);
  };

  const moveBlockUp = () => {
    if (index > 0) handleChangeBlockOrder(index, index - 1);
  };
  const moveBlockDown = () => {
    if (index < numBlocks - 1) handleChangeBlockOrder(index, index + 1);
  };

  const deleteBlock = async () => {
    const { url, body } = API.doc.post.removeBlock;
    const payload = {
      doc_ID: doc_ID,
      groupId,
      blockIndex: index,
    };
    try {
      await http.post(url, body(payload));
      updateDoc([groupId]);
    } catch (e) {
      console.error("Failed to remove block with body", JSON.stringify(payload), e);
    }
  };

  const firstCompHasBubbleMeta = Boolean(
    comps && comps[0] && (comps[0].assignee || comps[0].comment_threads?.length > 0)
  );

  if (numCompsFiltered > 0 || filtersOff) {
    return (
      <Element id={id} name={id}>
        <div id={id} className={style.block} data-name="block">
          {isRenaming ? (
            <div className={style.editBlockName}>
              <input
                autoFocus
                disabled={error || isLoading}
                className={classnames({ [style.errorInput]: error })}
                spellCheck="false"
                value={blockNameInput}
                onChange={handleBlockNameChange}
                onBlur={onBlur}
                onKeyPress={checkPressEnter}
                placeholder="Block Name"
              />
              {!error &&
                (!isLoading ? (
                  <DoneIcon
                    onMouseDown={() => saveRename()}
                    className={classnames({
                      [style.icon]: true,
                      [style.disabled]: (blockNameInput || "").length === 0,
                    })}
                  />
                ) : (
                  <img className={style.loading} src={spinner} />
                ))}
              {error && <div className={style.errorMsg}>{error}</div>}
            </div>
          ) : (
            <div className={style.blockName}>
              <div
                className={classnames({
                  [style.text]: true,
                  [style.hasApiID]: apiID,
                  [style.isSetupSuggestions]: showSetupFromAnotherGroup,
                  [style.apiIdDisabled]: !isEditEnabled,
                })}
              >
                {name}
              </div>
              {isEditEnabled && !showSetupFromAnotherGroup && !resyncLoading && (
                <div onClick={startBlockRenaming} className="rename-block-icon">
                  <EditIcon className={classnames([style.edit], [style.icon])} />
                </div>
              )}
              {displayApiIds && apiID && <ApiID id={apiID} canEdit={manualApiIdEditsAllowed} onEdit={onApiIdEdit} />}

              {isEditEnabled && filtersOff && !showSetupFromAnotherGroup && (
                <div
                  className={classnames(style.rightSideButtons, {
                    [style.shiftBlockDownDueToMetaBubbles]: firstCompHasBubbleMeta,
                  })}
                >
                  <div className={style.blockButtonWrapper}>
                    <div className={style.blockArrowButtons}>
                      <ArrowUpwardIcon
                        onClick={moveBlockUp}
                        className={classnames({
                          [style.icon]: true,
                          [style.disabled]: index <= 0 || isOrderChanging,
                        })}
                      />
                      <ArrowDownwardIcon
                        onClick={moveBlockDown}
                        className={classnames({
                          [style.icon]: true,
                          [style.disabled]: index >= numBlocks - 1 || isOrderChanging,
                        })}
                      />
                    </div>
                    {!resyncLoading && (
                      <CloseIcon onClick={deleteBlock} className={classnames([style.icon], [style.close])} />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {comps && comps.length > 0 ? (
            <Droppable
              droppableId={"block" + frameIndex + "." + index}
              type={"COMP-" + frameIndex}
              dragDisabled={!isEditEnabled || showSetupFromAnotherGroup}
            >
              {(provided) => (
                <div className={style.blockComps} ref={provided.innerRef} {...provided.droppableProps}>
                  {comps.map(function (component, idx) {
                    if (isInFilters(component)) {
                      return (
                        <CompInBlock
                          component={{
                            ...component,
                            blockIndex: index,
                            frameIndex: frameIndex,
                          }}
                          is_selected={isSelected(component._id)}
                          is_suggested={isSuggested(component._id)}
                          selectComp={selectComp}
                          key={component._id}
                          index={idx}
                          isLast={idx === comps.length - 1}
                          blockIndex={index}
                          frameIndex={frameIndex}
                          ws_comp={component.ws_comp}
                          apiID={component.apiID}
                          dragDisabled={!filtersOff || !isEditEnabled || showSetupFromAnotherGroup || resyncLoading}
                          setPanelState={setPanelState}
                          setCommentState={setCommentState}
                          displayApiIds={displayApiIds}
                          frameVariant={frameVariant ?? { id: "__base__" }}
                          frameVariants={frameVariants}
                          groupId={groupId}
                          hoveredVariantId={hoveredVariantId}
                          setHoveredVariantId={setHoveredVariantId}
                          handleSelectActiveVariant={handleSelectActiveVariant}
                        />
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ) : (
            <Droppable
              droppableId={"block" + frameIndex + "." + index}
              type={"COMP-" + frameIndex}
              dragDisabled={!isEditEnabled}
            >
              {(provided) => (
                <div className={style.emptyBlock} ref={provided.innerRef} {...provided.droppableProps}>
                  Drag and drop text into empty block
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
      </Element>
    );
  } else {
    return <span></span>;
  }
};

export default Block;
