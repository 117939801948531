import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import React, { useMemo } from "react";

import { User } from "../activity-column";

import style from "./SuggestionResolution.module.css";

interface SuggestionResolutionProps {
  suggestion: { accepted: boolean | null };
}

const SuggestionResolution = (props: SuggestionResolutionProps) => {
  const { suggestion } = props;

  const icon = useMemo(
    () =>
      suggestion?.accepted ? (
        <CheckIcon className={classNames([style.icon, style.accepted])} />
      ) : (
        <CloseIcon className={classNames([style.icon, style.rejected])} />
      ),
    [suggestion]
  );

  const text = useMemo(() => (suggestion?.accepted ? "Accepted" : "Rejected") + " this suggestion", [suggestion]);

  if (!suggestion) {
    return <React.Fragment />;
  }

  return (
    <span className={style.container}>
      {icon} {text}
    </span>
  );
};

export default SuggestionResolution;

interface ResolvedSuggestionActivityHeaderProps {
  user: string;
  date: Date;
}

export const ResolvedSuggestionActivityHeader = (props: ResolvedSuggestionActivityHeaderProps) => {
  const { user, date } = props;

  return (
    <div className={style.resolvedSuggestionHeader}>
      <User user={user} date={date} />
    </div>
  );
};
