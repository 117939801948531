import blurryPermissionGroups from "@/assets/blurry-permission-groups.png";
import UpsellModal from "@/components/UpsellModal";
import PermissionGroupsList from "@/components/permissions/PermissionGroupsList";
import { IPermissionGroupState } from "@/components/permissions/usePermissionGroups";
import { useWorkspace } from "@/store/workspaceContext";
import { RenderIfHasPermission } from "@shared/frontend/userPermissionContext";
import classNames from "classnames";
import React from "react";
import { Route } from "react-router-dom";
import sharedStyles from "../style.module.css";
import style from "./style.module.css";

interface PermissionGroupsTabProps {
  premissionGroupState: IPermissionGroupState;
}

export default function PermissionGroupsTab(props: PermissionGroupsTabProps) {
  const { workspaceInfo } = useWorkspace();
  const path = window.location.pathname.split("/")[1];

  return (
    <RenderIfHasPermission permission={"users:edit:editor"} NoPermissionComponent={() => <></>}>
      <div>
        {workspaceInfo && workspaceInfo.plan !== "enterprise" && (
          <div className={classNames([sharedStyles.container, style.container])}>
            <img className={style.image} src={blurryPermissionGroups} />
            <UpsellModal
              fullscreen={false}
              actionDittoComponentId="contact-sales"
              contentDittoComponentId="permission-group-paywall-content"
              titleDittoComponentId="permission-group-paywall-title"
            />
          </div>
        )}
        {workspaceInfo && workspaceInfo.plan === "enterprise" && (
          <div className={sharedStyles.container}>
            <Route path={[`/${path}/permissions/:permissionGroupId`, `/${path}/permissions`]}>
              <PermissionGroupsList premissionGroupState={props.premissionGroupState} />
            </Route>
          </div>
        )}
      </div>
    </RenderIfHasPermission>
  );
}
