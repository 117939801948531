import { useEffect, useRef } from "react";
import http, { API } from "../http";
import history from "../utils/history";
import { setOnboardingRedirectValue } from "../views/Onboarding/lib";

const FIGMA_CORRELATION_KEY = "figma_correlation_key";
const FIGMA_CORRELATION_KEY_EXPIRY = "figma_correlation_key_expires_at";

const FIGMA_CORRELATION_SECONDS_VALID = 60; // 1 min

/**
 * Ferries credentials associated with a Figma authentication request to the backend.
 * @param {string} figmaCorrelationKey
 * @param {string|null} refreshToken
 */
export const confirmFigmaAuthenticationRequest = (figmaCorrelationKey, refreshToken) => {
  const { url, body } = API.figmaAuth.post.request;
  return http.post(url, body({ figmaCorrelationKey, refreshToken }));
};

export const cancelFigmaAuthenticationRequest = (figmaCorrelationKey, reason = "other") => {
  const { url, body } = API.figmaAuth.post.cancel;

  return http.post(url({ figmaCorrelationKey }), body({ figmaCorrelationKey, reason }));
};

export const clearFigmaCorrelationKey = () => {
  localStorage.removeItem(FIGMA_CORRELATION_KEY);
  localStorage.removeItem(FIGMA_CORRELATION_KEY_EXPIRY);
};

export const getFigmaCorrelationKey = () => {
  const existingCorrelationKey = localStorage.getItem(FIGMA_CORRELATION_KEY);
  const existingCorrelationExpiry = localStorage.getItem(FIGMA_CORRELATION_KEY_EXPIRY);

  const correlationHasExpired =
    existingCorrelationKey && existingCorrelationExpiry && new Date() > new Date(existingCorrelationExpiry.current);

  const correlationHasNoExpiration = existingCorrelationKey && !existingCorrelationExpiry;

  if (correlationHasExpired || correlationHasNoExpiration) {
    clearFigmaCorrelationKey();
    cancelFigmaAuthenticationRequest(existingCorrelationKey.current);
    return null;
  }

  return existingCorrelationKey || null;
};

export const hasFigmaCorrelationInQueryString = () => {
  const params = new URLSearchParams(window.location.search);
  for (const [key] of params.entries()) {
    if (key === FIGMA_CORRELATION_KEY) {
      return true;
    }
  }

  return false;
};

/**
 * @returns `true` if a Figma correlation key is present in the query string
 * or in local storage, meaning that the app has been loaded in the context of
 * a user trying to authenticate in the Figma plugin.
 */
export const getIsFigmaAuthSession = () => Boolean(hasFigmaCorrelationInQueryString() || getFigmaCorrelationKey());

/**
 * Authentication in the Figma plugin piggybacks off of the authentication
 * in the web app. When login is attempted from the Figma plugin, a web page
 * is opened to the web app with a special parameter in the query string
 * used to correlate login attempts with the Figma client that is waiting
 * for a websocket response on the other side.
 *
 * We use local storage to persist the key between the several different
 * kinds of redirects that can happen for unauthenticated users.
 */
export const useFigmaCorrelationManager = () => {
  const existingCorrelationKey = useRef(localStorage.getItem(FIGMA_CORRELATION_KEY));

  const existingCorrelationExpiry = useRef(localStorage.getItem(FIGMA_CORRELATION_KEY_EXPIRY));

  useEffect(() => {
    // if there is an existing correlation key that is invalid because
    // it has expired, clear it out and cancel any authentication requests
    // associated with it
    try {
      const correlationHasExpired =
        existingCorrelationExpiry.current && new Date() > new Date(existingCorrelationExpiry.current);

      const correlationHasNoExpiration = existingCorrelationKey.current && !existingCorrelationExpiry.current;

      if (correlationHasExpired || correlationHasNoExpiration) {
        clearFigmaCorrelationKey();
        cancelFigmaAuthenticationRequest(existingCorrelationKey.current);
      }
    } catch (e) {
      // this is mostly to catch invalid dates being stored
      // in local storage
      console.error(e);
    }

    // if a new correlation key has been passed as a query parameter,
    // calculate an expiration date and store the key + the expiration
    // date in local storage
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of params.entries()) {
      if (key === FIGMA_CORRELATION_KEY) {
        const now = new Date();
        const expiration = new Date(now.getTime() + FIGMA_CORRELATION_SECONDS_VALID * 1000).toISOString();

        existingCorrelationKey.current = value;
        existingCorrelationExpiry.current = expiration;

        localStorage.setItem(FIGMA_CORRELATION_KEY, existingCorrelationKey.current);

        localStorage.setItem(FIGMA_CORRELATION_KEY_EXPIRY, existingCorrelationExpiry.current);
      }
    }
  }, []);
};

/**
 *
 * @param {boolean} isOnAuthCallbackPage
 * @param {*} onboardingState
 * @param {string|null} figmaCorrelationKey
 * @returns
 */
export const handleFigmaAuthRequestRedirect = (isOnAuthCallbackPage, onboardingState, figmaCorrelationKey) => {
  // the auth callback page has its own redirect logic
  if (isOnAuthCallbackPage) {
    return;
  }

  // there's no ongoing Figma auth request
  if (!figmaCorrelationKey) {
    return;
  }

  // the onboarding page has its own redirect logic
  if (window.location.pathname.includes("onboarding")) {
    return;
  }

  // the user needs to finish onboarding
  if (onboardingState !== "finished") {
    setOnboardingRedirectValue("/figma-authentication");
    history.replace(`/onboarding/${onboardingState}`);
    return;
  }

  // the app mounted directly on the `/figma-authentication` page
  // (this prevents an infinite loop)
  if (window.location.pathname.includes("figma-authentication")) {
    return;
  }

  // redirect to the auth success page
  history.replace("/figma-authentication");
};
