import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";

import useControlledState from "@shared/frontend/lib/useControlledState";
import { removeDiacritics } from "@shared/utils/removeDiacritics";
import ReactTooltip from "react-tooltip";
import style from "./style.module.css";

interface Props {
  value: string;
  onSave: (updatedValue: string) => void;
  isEditEnabled: boolean;
  toUpper?: boolean;
  errorPos?: "bottom" | "right";
  className?: string;
  TextComponent?: (props: { children: React.ReactNode }) => JSX.Element;
  editModeEnabled?: React.StatePair<boolean>;
  uniqueNames?: string[];
  saveOnBlur?: boolean;
  hoverWrapperClassName?: string;
  disabledTooltipContent?: string;
}

const EditableName = (props: Props) => {
  const [valueBeingEdited, setValueBeingEdited] = useState(props.value);
  const [editModeEnabled, setEditModeEnabled] = useControlledState(props.editModeEnabled, false);
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const canSave = valueBeingEdited && props.value !== valueBeingEdited && !error;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (props.toUpper) value = value.toUpperCase();

    if (props.uniqueNames && props.uniqueNames.includes(value) && value !== props.value) {
      setError("Name already exists");
    } else {
      setError("");
    }

    setValueBeingEdited(value);
  };

  const onEditClick = () => setEditModeEnabled(true);

  const onCheckClick = () => {
    props.onSave(valueBeingEdited);
    setEditModeEnabled(false);
  };

  useEffect(() => {
    if (!editModeEnabled) {
      setValueBeingEdited(props.value);
    }
  }, [props.value, editModeEnabled]);

  useEffect(function autoFocus() {
    if (editModeEnabled && inputRef.current) {
      inputRef.current.focus();
    }
  });

  useEffect(
    function handleClickOutside() {
      if (!editModeEnabled) {
        return;
      }

      function handleMouseDown(event: MouseEvent) {
        const target = event.target as HTMLElement;

        // traverse DOM tree to check if target is outside container
        // NOTE: native .contains() method isn't reliable here
        let targetOutsideContainer = true;
        let parent = target?.parentNode;
        while (parent) {
          if (parent === containerRef.current) {
            targetOutsideContainer = false;
            break;
          }
          parent = parent.parentNode;
        }

        if (targetOutsideContainer) {
          setEditModeEnabled(false);
        }
      }

      document.addEventListener("mousedown", handleMouseDown);
      return () => document.removeEventListener("mousedown", handleMouseDown);
    },
    [editModeEnabled, setEditModeEnabled]
  );

  const handleEditModeKeydown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter" && canSave) {
      props.onSave(removeDiacritics(valueBeingEdited));
      setEditModeEnabled(false);
      return;
    }

    if (e.key === "Escape") {
      setEditModeEnabled(false);
    }
  };

  const text = useMemo(() => {
    if (!props.TextComponent) {
      return <span>{props.value}</span>;
    }

    const { TextComponent } = props;

    return <TextComponent>{props.value}</TextComponent>;
  }, [props.TextComponent, props.value]);

  return (
    <div ref={containerRef} className={classNames(style.hoverWrapper, props.hoverWrapperClassName)}>
      <div className={classNames([style.container, props.className])} data-testid="editable-name">
        {props.isEditEnabled && editModeEnabled && (
          <>
            <input
              ref={inputRef}
              value={valueBeingEdited}
              onChange={onInputChange}
              onKeyDown={handleEditModeKeydown}
              className={classNames([style.input, { [style.errorInput]: !!error }])}
            />{" "}
            <CheckIcon
              className={classNames({
                [style.checkIcon]: true,
                [style.disabled]: !canSave,
              })}
              onMouseDown={onCheckClick}
            />
            {error && (
              <div
                className={classNames(style.error, {
                  [style.right]: props.errorPos === "right",
                })}
              >
                {error}
              </div>
            )}
          </>
        )}
        {props.isEditEnabled && !editModeEnabled && (
          <>
            {text} <EditIcon className={style.editIcon} onClick={onEditClick} />
          </>
        )}
        <span data-tip data-for="edit-disabled-tooltip">
          {!props.isEditEnabled && text}
          {props.disabledTooltipContent && (
            <ReactTooltip id="edit-disabled-tooltip" className={style.tooltip} place="bottom" effect="solid">
              {props.disabledTooltipContent}
            </ReactTooltip>
          )}
        </span>
      </div>
    </div>
  );
};

export default EditableName;
