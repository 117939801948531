import { AuthenticatedAuthState } from "@/hooks/auth";
import React, { createContext, useContext } from "react";

const AuthenticatedAuthContext = createContext({} as AuthenticatedAuthState);

export function useAuthenticatedAuth() {
  const context = useContext(AuthenticatedAuthContext);
  if (!context) {
    throw new Error(
      "useAuthenticatedAuth was null, are you sure you used it in a state that is guaranteed to only exist when the user is authenticated?"
    );
  }

  return context;
}

/**
 * This is a special hook that is used to get the AuthenticatedAuthState
 * in places where it could potentially be null. YOU PROBABLY DON'T WANT TO USE THIS
 * UNLESS YOU KNOW WHAT YOU'RE DOING. most likely you were looking for `useAuthenticatedAuth`.
 * @returns AuthenticatedAuthState | null
 */
export function UNSAFE_useAuthenticatedAuth(): AuthenticatedAuthState | null {
  return useContext(AuthenticatedAuthContext);
}

interface IAuthenticatedAuthProvider {
  children: React.ReactNode;
  authState: AuthenticatedAuthState;
}

export const AuthenticatedAuthProvider = ({ children, authState }: IAuthenticatedAuthProvider) => {
  return <AuthenticatedAuthContext.Provider value={authState}>{children}</AuthenticatedAuthContext.Provider>;
};
