import { Editor } from "@tiptap/core";
import { EditorContent, useEditor } from "@tiptap/react";
import { EditorView } from "prosemirror-view";
import React, { useEffect, useMemo, useRef } from "react";

import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { HocuspocusProvider } from "@hocuspocus/provider";
import Collaboration from "@tiptap/extension-collaboration";
import { useCookies } from "react-cookie";
import { Doc } from "yjs";
import { EditorExtensions, EditorNodes } from "./DraftEditorNodesAndExtensions";
import { getSelectedTextItems } from "./getSelectedTextItems";

interface Props {
  isDisabled: boolean;
  onUpdate: ({ editor }: { editor: Editor }) => void;
  onBlur: () => void;
  onFocus: () => void;
  onCreate: ({ editor }: { editor: Editor }) => void;
  onDestroy: () => void;
  onSelectionUpdate: (textItemIds: string[]) => void;
  hasUnsavedChanges?: boolean;
  projectId: string;
  groupId: string;
}

export const getSelectionHasChanged = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  if (arr1.length === 1) {
    return arr1[0] !== arr2[0];
  }

  let arr1Map = {};

  for (let i = 0; i < arr1.length; i++) {
    arr1Map[arr1[i]] = true;
  }

  for (let i = 0; i < arr2.length; i++) {
    if (!arr1Map[arr2[i]]) {
      return true;
    }
  }

  return false;
};

export default (props: Props) => {
  const { onCreate, onDestroy, onUpdate, onBlur, isDisabled, projectId, groupId } = props;

  const { getTokenSilently } = useAuthenticatedAuth();
  const [cookies] = useCookies(["impersonateUser"]);

  /**
   * Since `useEditor` doesn't automatically refresh its arguments on every render,
   * we need to cache `isDisabled` in a ref for it to function as expected in
   * `handleTextInput` when the value changes.
   * See https://github.com/ueberdosis/tiptap/issues/2403 for more details.
   */
  const isDisabledReference = useRef(isDisabled);
  useEffect(() => {
    isDisabledReference.current = isDisabled;
  }, [isDisabled]);

  const handleTextInput = (_view: EditorView, _from: number, _to: number, _text: string) => {
    return isDisabledReference.current;
  };

  const selectedTextItemIdsCache = useRef<string[]>([]);

  const hocuspocusProvider = useMemo(() => {
    let document: Doc | undefined = undefined;

    const hpProvider = new HocuspocusProvider({
      url: process.env.HOCUSPOCUS_URL || `ws://0.0.0.0:1234`,
      name: groupId,
      parameters: {
        projectId: projectId,
        impersonateUser: cookies.impersonateUser,
      },
      document,
      token: getTokenSilently(),
    });

    return hpProvider;
  }, []);

  const editor = useEditor(
    {
      extensions: [
        ...EditorNodes,
        ...EditorExtensions,
        Collaboration.configure({
          document: hocuspocusProvider.document,
        }),
      ],
      onCreate,
      onUpdate,
      onBlur,
      editorProps: { handleTextInput },
      onDestroy: () => {
        onDestroy();
        // This is needed here because if we don't destroy the instance then it will use cached data next time
        // the editor renders. This means that if we enable linking, add text items and then revert linking,
        // the provider won't query the backend for the updated data and we will lose text items. By destroying the provider
        // we guarantee that the provider will check for the latest data next time it is created for this group
        hocuspocusProvider.destroy();
      },
      // The selected ids need to be re-emitted on focus to avoid an edge case
      // where clicking into the draft group doesn't update the selected
      // text items if the spot clicked in the group is the same as was clicked
      // previously. This occurs because ProseMirror maintains its internal selection
      // state even between losing and regaining focus.
      onFocus: (args) => {
        props.onFocus();

        const textItemIds = getSelectedTextItems(args);
        const selectionIsSame = !getSelectionHasChanged(textItemIds, selectedTextItemIdsCache.current);

        if (selectionIsSame) {
          props.onSelectionUpdate(textItemIds);
        }
      },

      // Calling props.onSelectionUpdate is expensive due to the state changes
      // that propagate across the entire project page, and this callback executes
      // every time the user types a character into the editor. To avoid severe
      // performance drops:
      // - cache the selected text items in a local ref
      // - only call back if the text items have changed
      onSelectionUpdate: (args) => {
        if (!args.editor.isFocused) {
          return;
        }

        const textItemIds = getSelectedTextItems(args);

        const arr1 = textItemIds;
        const arr2 = selectedTextItemIdsCache.current;

        if (getSelectionHasChanged(arr1, arr2)) {
          props.onSelectionUpdate(textItemIds);

          selectedTextItemIdsCache.current = textItemIds;
        }
      },
    },
    []
  );

  return <EditorContent editor={editor} />;
};
