import { Ditto } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface Props {
  invoices: {
    period_end: number;
    amount_due: number;
  }[];
}
export default function InvoicingPanel(props: Props) {
  return (
    <Panel titleDittoComponentId="invoice-panel-title">
      <table className={style.table} data-testid="invoicing-panel">
        <tbody>
          <tr className={style.divider}>
            <td>
              <div className={style.header}>
                <Ditto componentId="date-header" />
              </div>
            </td>
            <td>
              <div className={style.headerValue}>
                <Ditto componentId="amount-header" />
              </div>
            </td>
          </tr>
          {props.invoices.map((invoice, index) => (
            <tr key={index} className={style.divider}>
              <td className={style.title}>
                {new Date(invoice.period_end * 1000).toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                })}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {new Date(invoice.period_end * 1000) > new Date() && <span className={style.upcoming}>Upcoming</span>}
              </td>
              <td className={style.value}>
                {(invoice.amount_due / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}
