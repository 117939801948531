import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ACCEPT } from ".";
import style from "./style.module.css";

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const unknownStyle = {
  borderColor: "#00e676",
};

// 5 megabytes
export const MAX_SIZE = 1024 * 1024 * 5;

function FileDropzone({ onDrop, onDropRejected }) {
  const [isDragUnknown, setIsDragUnknown] = useState(false);
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ACCEPT,
    maxSize: MAX_SIZE,
    maxFiles: 1,
    onDrop,
    onDropRejected,
    onDragEnter: (e) => {
      for (const item of e.dataTransfer.items) {
        if (!item.type) {
          setIsDragUnknown(true);
          return;
        }
        setIsDragUnknown(false);
      }
    },
    onDragLeave: () => setIsDragUnknown(false),
  });

  const dropzoneStyle = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isDragUnknown ? unknownStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, isDragUnknown]
  );

  return (
    <div>
      <div {...getRootProps({ style: dropzoneStyle, className: style.root })}>
        <label className={style.dropzoneLabel} data-testid="upload-file-label">
          <input {...getInputProps()} />
          <div className={style.dropzoneText}>
            <CloudUploadIcon className={classNames([style.icon, style.dropzoneIcon])} />
            <span>Drag and drop or click to browse</span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default FileDropzone;
