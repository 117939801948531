import { PluralSchema, RichText, VariantSchema } from "@shared/types/TextItem";
import { groupHasFigmaIntegration } from "@shared/utils/hasFigmaIntegration";

type TextItemStatus = "NONE" | "WIP" | "REVIEW" | "FINAL";
/**
 * TODO: All of these types and helpers need to be moved somewhere more shareable
 */
export interface TextItem {
  _id: string;
  assignee: string | null;
  workspace_ID: string;
  status: "NONE" | "WIP" | "REVIEW" | "FINAL" | null;
  integrations?: {
    figma?: {
      position: {
        x: number;
        y: number;
        width: number;
        height: number;
      };
    };
  };
  tags: string[];
  text: string;
  rich_text: RichText;
  variables: [];
  plurals: PluralSchema[];
  doc_ID: string;
  notes: string | null;
  in_graveyard: boolean;
  graveyard_apiID: string | null;
  has_conflict: boolean;
  ws_comp: string | null;
  comment_threads: { _id: string; comments: [] }[];
  variants: VariantSchema[];
  apiID: string | null;
  figma_node_ID?: string;
  is_hidden?: boolean;
  updatedAt: Date;
  lastSync: string | null;
  text_last_modified_at: Date;
  characterLimit: number | null;
}

// TODO: potentially worth guarding against groups that are populated vs groups that are unpopulated
// this type definition currently åssumes the latter
interface GroupBase {
  _id: string;
  name: string;
  apiID?: string;
  comps: TextItem[];
  blocks: BlockBase[];
  pinned: boolean;
  hidden?: boolean;
  nonblocks_collapsed: boolean;
  figma_artboard_ID?: string;

  // This flag will be set to true on groups that have been created on the
  // front-end and have not yet persisted on the backend
  unsaved?: boolean;
}

interface BlockBase {
  _id: string;
  name: string;
  comps: TextItem[];
}

export type Page = {
  _id: string;
  figma_id: string;
  name: string;
};
export interface GroupUnlinkable extends GroupBase {
  linking_enabled: false;
  integrations: { figma: {} };
}

export interface GroupLinkable extends GroupBase {
  linking_enabled: true;
  integrations: { figma: {} };
}

export interface GroupLinked extends GroupBase {
  linking_enabled: true;
  integrations: {
    figma: {
      frame_id: string;
      frame_name: string;
      page_id: string;
      previews: { fullsize: string };
      applied_variant_id?: string;
      frame_name_synced_with_group_name: boolean;
      position: {
        x: number;
        y: number;
        width: number;
        height: number;
      };
    };
  };
}

export type Group = GroupUnlinkable | GroupLinkable | GroupLinked;

export const isGroupUnlinkable = (g: Group): g is GroupUnlinkable => !g.linking_enabled;
export const isGroupLinkable = (g: Group): g is GroupLinkable =>
  g.linking_enabled && !groupHasFigmaIntegration(g.integrations);
export const isGroupLinked = (g: Group): g is GroupLinked => groupHasFigmaIntegration(g.integrations);
