import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import style from "./style.module.css";

const VariantDeleteModal = ({ name, onHide, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  const onConfirmDelete = async () => {
    setIsDeleting(true);
    const deleteSuccessful = await onDelete();
    setIsDeleting(false);
    if (deleteSuccessful) {
      onHide();
    } else {
      setError("Unable to delete variant at this time, please try again");
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={onHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          Delete Variant <span>{name}</span>
        </BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <p>Are you sure you want to delete this variant?</p>
        {error && <div className={style.errorMsg}>{error}</div>}
        <div className={style.form}>
          <ButtonSecondary text="Cancel" onClick={onHide} />
          <ButtonPrimary
            text={!isDeleting ? "Delete" : "Deleting..."}
            disabled={isDeleting || Boolean(error)}
            onClick={onConfirmDelete}
          />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};
export default VariantDeleteModal;
