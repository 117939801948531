import { useMemo, useState } from "react";

import { ITipTapRichText } from "@shared/types/TextItem";
import http, { API } from "../../../http/index";
import { Folder } from "../../FolderSelect/useFolderSelect";
import {
  ActualComponentInterface,
  ComponentInterface,
  FrameVariants,
  SelectedCompVariants,
  VariantFrameInfo,
} from "../types";

interface Props {
  currentComp?: ActualComponentInterface;
  selectedFolder: Folder;
  selectedCompId: string | null;
  isSwap: boolean;
  newCompName: string;
  multiSelectedIds: string[];
  selectedGroupId: string;
  multiSelectGroupIds: string[];
  selectedComp: ComponentInterface | null;
  duplicateComps: string[];
  frameVariants: FrameVariants;
  shouldComponentizeDuplicates: boolean;
  selectedCompVariants: SelectedCompVariants;
  handleDocUpdate: (compIds: string[]) => void;
  setShowMultiAttachCompToast: (show: boolean) => void;
  fetchCompInfo: () => void;
  onHide: () => void;
  unselectAll: () => void;
  // optional prop to notify when a component is attached to a single text item
  onSingleAttach?: () => void;
  // optional prop to notify when a component is attached to multiple text items
  onMultiAttach?: () => void;
}

interface ILoadingState {
  isLoading: boolean;
  error?: string;
}

interface UseComponentModalOutput {
  loadingState: ILoadingState;
  instanceIndex: number;
  incrementIndex: (e: React.SyntheticEvent) => void;
  decrementIndex: (e: React.SyntheticEvent) => void;
  attachComp: () => void;
  onCreate: () => void;
  newVariants: {
    variantId: string;
    text: string;
    rich_text: ITipTapRichText;
  }[];
  title: string;
  placeholder: string;
  isEdited: boolean;
  frameVariantsIds: string[];
  clearError: () => void;
}
export const useComponentModal = (props: Props): UseComponentModalOutput => {
  const {
    currentComp,
    selectedCompId,
    selectedFolder,
    isSwap,
    frameVariants,
    newCompName,
    selectedCompVariants,
    multiSelectedIds,
    selectedGroupId,
    multiSelectGroupIds,
    handleDocUpdate,
    setShowMultiAttachCompToast,
    shouldComponentizeDuplicates,
    selectedComp,
    duplicateComps,
    fetchCompInfo,
    onHide,
    unselectAll,
    onSingleAttach,
    onMultiAttach,
  } = props;

  const [loadingState, setLoadingState] = useState<ILoadingState>({
    isLoading: false,
  });
  const [instanceIndex, setInstanceIndex] = useState<number>(0);

  // For single select, add new variant to component being attached if it doesn't exist
  const newVariants =
    !multiSelectedIds && selectedCompVariants.notInUse.length > 0
      ? (currentComp?.variants || []).filter((v) =>
          selectedCompVariants.notInUse.some((variant) => variant._id === v.variantId.toString())
        )
      : [];

  const getTitle = () => {
    if (isSwap) {
      return "Swap component";
    }

    if (shouldComponentizeDuplicates) {
      return newCompName === "" ? "Create or attach component" : "Create and attach";
    }

    if (multiSelectedIds) {
      return "Attach component";
    }

    return `Create or attach component`;
  };

  const getPlaceholder = () => {
    if (isSwap) {
      return "Select an existing component...";
    }

    if (multiSelectedIds) {
      return "Attach an existing component...";
    }

    return "Create or attach an existing component...";
  };

  const title = getTitle();
  const placeholder = getPlaceholder();

  const displaySuccessToast = () => {
    setShowMultiAttachCompToast(true);
    setTimeout(function () {
      setShowMultiAttachCompToast(false);
    }, 3000);
  };

  const attachSingleComp = async (comp_id: string) => {
    setLoadingState({ isLoading: true });

    const isDraft = selectedComp?.instances?.length === 1 && !selectedComp.instances[0].hasOwnProperty("doc_ID");

    try {
      const { url, body } = API.ws_comp.post.attachComp;
      await http.post(
        url,
        body({
          comp_id: comp_id,
          ws_comp_id: selectedComp?._id,
          assigned_comp: selectedComp?.instances?.[0] || [],
          is_draft: isDraft,
          from: "web_app",
        })
      );
      handleDocUpdate([comp_id]);

      if (newVariants.length > 0) {
        const { url } = API.ws_comp.get.instances;
        const { data } = await http.get(url(selectedComp?._id));
        const instances = data.map((instance) => instance.id);
        handleDocUpdate(instances);
      }

      if (multiSelectedIds) {
        // is from multi-selecting one
        unselectAll();
        displaySuccessToast();
      } else {
        // is single select
        fetchCompInfo();
      }
      setLoadingState({ isLoading: false });
    } catch (e) {
      console.error(e);
      setLoadingState({ isLoading: false });
    }
  };

  const attachMultiComp = async () => {
    setLoadingState({ isLoading: true });

    const isDraft =
      selectedComp &&
      selectedComp.instances &&
      selectedComp.instances.length === 1 &&
      !selectedComp.instances[0].hasOwnProperty("doc_ID");

    const compIds = shouldComponentizeDuplicates ? duplicateComps : multiSelectedIds;
    try {
      const { url, body } = API.ws_comp.post.attachMultiComp;
      await http.post(
        url,
        body({
          comp_ids: compIds,
          ws_comp_id: selectedComp?._id,
          assigned_comp: selectedComp?.instances[0],
          is_draft: isDraft,
          from: "web_app",
          shouldComponentizeDuplicates,
        })
      );

      handleDocUpdate(compIds);
      if (shouldComponentizeDuplicates) {
        fetchCompInfo();
      } else {
        unselectAll();
      }
      displaySuccessToast();
      onHide();
      setLoadingState({ isLoading: false });
    } catch (e) {
      console.error(e);
      setLoadingState({ isLoading: false });
    }
  };

  const attachComp = async () => {
    if ((!multiSelectedIds || multiSelectedIds?.length === 0) && !shouldComponentizeDuplicates) {
      if (!currentComp) {
        console.error("Error in attaching Component, select ActualComponent is not populated.");
        return;
      }
      await attachSingleComp(currentComp._id);
      if (onSingleAttach) onSingleAttach();
    } else if (multiSelectedIds?.length === 1) {
      await attachSingleComp(multiSelectedIds[0]);
      if (onSingleAttach) onSingleAttach();
    } else {
      await attachMultiComp();
      if (onMultiAttach) onMultiAttach();
    }
  };

  const createMultiComp = async () => {
    if (!currentComp) {
      console.error("Error in creating multi Component, 'currentComp' is not defined.");
      return;
    }
    setLoadingState({ isLoading: true });
    if (!shouldComponentizeDuplicates) {
      console.error(
        "Error in creating multi Component, this endpoint is only for creating a component with duplicates."
      );
      setLoadingState({ isLoading: false });
    }

    const trimmed = newCompName.trim();
    try {
      const { url, body } = API.ws_comp.post.createMultiComp;
      const compId: string = currentComp._id;
      const comp_ids = [compId, ...duplicateComps.filter((c) => c !== currentComp._id)];

      await http.post(
        url,
        body({
          name: trimmed,
          comp_ids,
          project_id: currentComp.doc_ID,
          from: "web_app",
          folder_id: selectedFolder?._id || null,
        })
      );
      handleDocUpdate(comp_ids);
      fetchCompInfo();
      setLoadingState({ isLoading: false });
      onHide();
    } catch (e) {
      console.error(e);
      setLoadingState({ isLoading: false });
    }
  };

  const frameVariantsIds = useMemo(() => {
    if (Object.keys(frameVariants).length === 0) return [];

    const selectedFrameVariants: VariantFrameInfo[][] = [];
    if (!multiSelectGroupIds) {
      const selectedFrameVariant = frameVariants[selectedGroupId];
      selectedFrameVariant && selectedFrameVariants.push(selectedFrameVariant);
    }

    if (multiSelectGroupIds && multiSelectGroupIds.length > 0) {
      const frameVariantsEntries = Object.entries(frameVariants);
      frameVariantsEntries.forEach((entry) => {
        if (multiSelectGroupIds.includes(entry[0])) {
          selectedFrameVariants.push(entry[1]);
        }
      });
    }
    return selectedFrameVariants.flat().map((variant) => variant.id);
  }, [frameVariants]);

  const decrementIndex = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (instanceIndex > 0) {
      let temp = instanceIndex - 1;
      setInstanceIndex(temp);
    }
  };
  const incrementIndex = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (instanceIndex < multiSelectedIds.length - 1) {
      let temp = instanceIndex + 1;
      setInstanceIndex(temp);
    }
  };

  const onCreate = async () => {
    if (shouldComponentizeDuplicates) {
      await createMultiComp();
    } else {
      await createComp();
    }
  };
  const createComp = async () => {
    setLoadingState({ isLoading: true });
    if (!currentComp) {
      console.error("Error in creating Component, select ActualComponent is not populated.");
      setLoadingState({ isLoading: false });
      return;
    }

    const trimmed = newCompName.trim();
    try {
      const { url, body } = API.ws_comp.post.createComp;
      await http.post(
        url,
        body({
          name: trimmed,
          comp_id: currentComp._id,
          isCreateAndAttach: true,
          folder_id: selectedFolder?._id ? selectedFolder._id : null,
        })
      );
      const compId: string = currentComp._id;
      handleDocUpdate([compId]);
      fetchCompInfo();
      setLoadingState({ isLoading: false });
    } catch (e) {
      console.error(e);
      setLoadingState({
        isLoading: false,
        error: "This component already exists in a different folder.",
      });
    }
  };

  const isEdited = Boolean((currentComp && currentComp.ws_comp != selectedCompId) || multiSelectedIds);

  const clearError = () => {
    setLoadingState((l) => ({ ...l, error: undefined }));
  };

  return {
    loadingState,
    instanceIndex,
    newVariants,
    title,
    placeholder,
    isEdited,
    frameVariantsIds,
    incrementIndex,
    decrementIndex,
    attachComp,
    onCreate,
    clearError,
  };
};
