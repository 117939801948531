import React from "react";
import TimeAgo from "react-timeago";
import { useScroll } from "../../../../hooks/useScroll";

import spinner from "../../../../assets/small-spinner.gif";
import style from "./style.module.css";

import FolderIcon from "@mui/icons-material/FolderOutlined";
import classnames from "classnames";
import { VariableHistoryItem } from "../../types";

interface InlineVariableSpanProps {
  children: React.ReactNode;
  entry_type: string;
  className?: string;
}
const InlineVariableSpan = ({ children, entry_type, className }: InlineVariableSpanProps) => (
  <span
    className={classnames([style.variableSpan, className], {
      [style.cursorPointer]: entry_type !== "variable-deletion",
    })}
  >
    {children}
  </span>
);

const getActivityName = (entry_type: string) => {
  switch (entry_type) {
    case "variable-creation":
      return "Created";
    case "variable-update":
      return "Updated";
    case "variable-deletion":
      return "Deleted";
    case "variable-name-update":
      return "";
    default:
      return;
  }
};

export const FolderChangeItem = (props) => {
  const { onClick, text, folderName } = props;

  return (
    <div onClick={onClick}>
      <div className={style.folderTextContainer}>
        <div>{text}</div>{" "}
        <div className={style.folderText}>
          <FolderIcon className={style.folderIcon} /> {folderName}
        </div>
      </div>
    </div>
  );
};
const getActivityContent = (entry_type: string, data: any) => {
  switch (entry_type) {
    case "variable-creation":
    case "variable-deletion":
      return (
        <div className={style.variableSpanWrapper}>
          <InlineVariableSpan entry_type={entry_type}>{data.variable_name}</InlineVariableSpan>
        </div>
      );
    case "variable-update":
      return (
        <div className={style.variableSpanWrapper}>
          <InlineVariableSpan entry_type={entry_type}>{data.after.variable_name}</InlineVariableSpan>
        </div>
      );
    case "variable-name-update":
      return (
        <div className={style.variableNameUpdate}>
          <div className={style.variableSpanWrapper}>
            <InlineVariableSpan entry_type={entry_type} className={style.variableNameBefore}>
              {data.name_before}
            </InlineVariableSpan>{" "}
          </div>
          <div className={style.variableSpanWrapper}>
            <InlineVariableSpan entry_type={entry_type}>{data.name_after}</InlineVariableSpan>
          </div>
        </div>
      );
    case "variable-folder-creation":
      return (
        <FolderChangeItem
          key={data.date_time}
          onClick={() => {}}
          text="Created"
          folderName={data.variable_folder.name}
        />
      );
    case "variable-folder-deletion":
      return (
        <FolderChangeItem
          key={data.date_time}
          onClick={() => {}}
          text="Deleted"
          folderName={data.variable_folder.name}
        />
      );
    case "variable-folder-update":
      const { name: nameBefore } = data.variable_folder_before;
      const { name: nameAfter } = data.variable_folder_after;

      return (
        <div key={data.date_time} onClick={() => {}}>
          <div className={style.folderTextContainer}>
            <div>Renamed</div>
            <div className={style.folderText}>
              <FolderIcon className={style.folderIcon} /> {nameBefore}
            </div>{" "}
            <div>to </div>
            <div className={style.folderText}>
              <FolderIcon className={style.folderIcon} /> {nameAfter}
            </div>
          </div>
        </div>
      );
    case "variable-move-to-folder":
      const name = data?.variableName || "";
      const folderAfter = data?.folder?.after;
      const folderBefore = data?.folder?.before;

      const folderName = folderAfter?.name || folderBefore?.name || "";
      const preposition = folderAfter ? "to" : folderBefore ? "out of " : "";

      return (
        <FolderChangeItem
          key={data.date_time}
          onClick={() => {}}
          text={
            <div>
              Moved{" "}
              <div className={style.variableSpanWrapper}>
                <InlineVariableSpan entry_type={entry_type}>{name}</InlineVariableSpan>
              </div>{" "}
              {preposition}
            </div>
          }
          folderName={folderName}
        />
      );
    default:
      return;
  }
};

interface VariableActivityProps {
  loadingHistory: boolean;
  variableHistory: VariableHistoryItem[];
  handleSelectVariableId: (variableId: string) => void;
}
const VariableActivity = ({ loadingHistory, variableHistory, handleSelectVariableId }: VariableActivityProps) => {
  const { scrollToId } = useScroll({ containerId: "variableContainer" });

  return (
    <div className={style.overflowContainer}>
      {loadingHistory && (
        <div>
          <div className={style.loading} />
          <div className={style.loadMore}>
            <div>Loading</div>
            <img src={spinner} alt="loading spinner" />
          </div>
        </div>
      )}
      {!loadingHistory && (
        <div>
          {variableHistory.length === 0 && <div className={style.noChanges}>No changes.</div>}
          {variableHistory.map(({ data, user, entry_type, date_time }, index) => (
            <div
              key={index}
              className={style.version}
              onClick={() => {
                if (data?.variable_id) {
                  handleSelectVariableId(data.variable_id);
                  scrollToId(data.variable_id);
                }
              }}
            >
              <div className={style.meta}>
                {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
              </div>
              <div className={style.comp}>
                {getActivityName(entry_type)} {data && getActivityContent(entry_type, data)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default VariableActivity;
