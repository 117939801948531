import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { ICommentThread } from "@shared/types/CommentThread";
import { VariantSchema } from "@shared/types/TextItem";
import classnames from "classnames";
import Masonry from "react-masonry-css";
import Comp from "../../../../components/comp";
import style from "./style.module.css";

const masonryBreakpoints = {
  default: 3,
  1100: 3,
  850: 2,
  650: 1,
};

interface Folder {
  _id: string;
  name: string;
  component_ids: string[];
}

interface ActualComponent {
  _id: string;
  text: string;
  variants: VariantSchema[];
  notes?: string | null;
  comment_threads?: string[] | ICommentThread[];
  status: string;
  count?: number;
  tags?: string[];
  plurals?: unknown[];
}

interface Component {
  _id: string;
  apiID: string;
  instances: ActualComponent[];
  isDraft: boolean;
  name: string;
  type: "template" | "standard" | undefined;
}

interface Props {
  folder: Folder;
  components: Component[];
  displayApiIds: boolean;
  selectComponent: (component: Component) => void;
  isComponentSelected: (componentId: string) => boolean;
  setCommentState: (state: { isSelected: boolean; thread_id: string }) => void;
  setPanelState: (state: string) => void;
}

const CompLibraryFolderGroup = ({
  folder,
  components,
  displayApiIds,
  selectComponent,
  isComponentSelected,
  setCommentState,
  setPanelState,
}: Props) => {
  const onComponentClick = (component: Component) => {
    selectComponent(component);
  };

  return (
    <div className={style.compgroup}>
      <div className={classnames(style.groupname)}>
        <div className={style.folderIconWrapper}>
          <FolderOpenOutlinedIcon className={style.icon} />
        </div>
        <div className={style.frameName}>{folder.name}</div>
        <div className={style.count}>{components.length}</div>
      </div>
      <div>
        <Masonry
          breakpointCols={masonryBreakpoints}
          className={style.masonryGrid}
          columnClassName={style.masonryGridCol}
        >
          {components &&
            components.map((component) => (
              <div key={component._id}>
                <div className={style.component}>
                  <Comp
                    apiID={component.apiID}
                    displayApiIds={displayApiIds}
                    isDraft={component.isDraft}
                    component={component.instances[0]}
                    onClick={() => onComponentClick(component)}
                    selectComp={() => selectComponent(component)}
                    ws_comp_id={component._id}
                    is_selected={isComponentSelected(component._id)}
                    is_ws_comp={true}
                    compName={component.name}
                    compType={component.type}
                    setCommentState={setCommentState}
                    setPanelState={setPanelState}
                    is_suggested={false}
                    showAllStatuses
                  />
                </div>
                <span className={style.openMessage}>Open in folder -&gt;</span>
              </div>
            ))}
        </Masonry>
      </div>
    </div>
  );
};
export default CompLibraryFolderGroup;
