import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import * as SegmentEvents from "@shared/segment-event-names";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import useSegment from "../../hooks/useSegment";
import LockedFolderIcon from "../icons/LockedFolderIcon";
import FolderAccessList from "../permissions/FolderAccessList";
import InviteForm from "../permissions/InviteForm";
import InviteOnlyToggle from "../permissions/InviteOnlyToggle";
import ConfirmationModal from "../shared/confirmation-modal";
import style from "./style.module.css";
import { ShareFolderModalProps, useShareFolder } from "./useShareFolder";

const ShareFolderModal = (props: ShareFolderModalProps) => {
  const segment = useSegment();
  const { folder, currentUser, inviteOnlyFoldersEnabled, onHide } = props;
  const {
    isSaving,
    onInviteOnlyToggleClick,
    invitedEmails,
    tag,
    handleSendInvite,
    onUserRemoved,
    usersWithAccess,
    showConfirmInviteOnly,
    setShowConfirmInviteOnly,
  } = useShareFolder(props);

  const inviteFormTitle = folder.invite_only ? undefined : "Invite collaborators via email";

  return (
    <>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            Share
            <div className={style.folderIconWrapper}>
              {!folder.invite_only ? (
                <FolderIcon className={style.icon} />
              ) : (
                <LockedFolderIcon className={style.icon} />
              )}
              <div>{folder.name}</div>
            </div>
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          {!folder.invite_only && (
            <>
              <div className={style.subheading}>
                <PublicIcon className={style.publicIcon} />
                Anyone in this workspace can access this folder.
              </div>
              <InviteOnlyToggle
                value={folder.invite_only}
                onToggle={() => onInviteOnlyToggleClick({ askForConfirmation: true })}
                onTagClick={() => {
                  segment.track({
                    event: SegmentEvents.INVITE_ONLY_UPSELL_BADGE_CLICKED,
                  });
                }}
                tag={tag}
                disabled={!inviteOnlyFoldersEnabled}
                description="Toggle on to make this folder and all of its projects viewable only by invitation. This cannot be undone."
              />
            </>
          )}
          {folder.invite_only && (
            <div className={style.subheading}>
              <LockIcon className={style.publicIcon} />
              This folder is invite-only — only invited members can access it.
            </div>
          )}
          <div className={style.form}>
            <InviteForm
              title={inviteFormTitle}
              folderId={folder._id}
              currentUser={currentUser}
              invitedEmails={invitedEmails}
              handleSendInvite={handleSendInvite}
            />
          </div>
          {folder.invite_only && (
            <FolderAccessList
              currentUser={currentUser}
              usersWithAccess={usersWithAccess}
              onUserRemoved={onUserRemoved}
              allowSelfRemoval
            />
          )}
        </BootstrapModal.Body>
      </BootstrapModal>
      {showConfirmInviteOnly && (
        <ConfirmationModal
          isLayered
          title={
            <span>
              Change
              <span className={style.folderIconAndName}>
                <FolderIcon className={style.icon} />
                {folder.name}
              </span>{" "}
              to an invite-only folder?
            </span>
          }
          body="This folder and all of its projects will only be accessible by teammates who have been invited and admins of the workspace. This change cannot be undone."
          actionPrimary="Change to Invite-Only"
          actionSecondary="Cancel"
          onPrimary={() => onInviteOnlyToggleClick({ askForConfirmation: false })}
          onSecondary={() => setShowConfirmInviteOnly(false)}
        />
      )}
    </>
  );
};

export default ShareFolderModal;
