import z from "zod";
import { ZTipTapRichText } from "../TextItem";
import { ZCreatableId } from "../lib";

export const ZComponentSuggestionJobData = z.object({
  projectId: z.string(),
  workspaceId: z.string(),
  userId: z.string(),
});

export type ComponentSuggestionsJobData = z.infer<typeof ZComponentSuggestionJobData>;

export const ZCreatableSuggestion = z.object({
  _id: ZCreatableId,
  ignored: z.boolean(),
  ws_comp: ZCreatableId.nullable(),
  rich_text: ZTipTapRichText,
  text: z.string(),
  variables: z.array(z.any()),
  notes: z.string().nullable(),
  tags: z.array(z.string()),
  status: z.string(),
  comp_ids: z.array(ZCreatableId),
  nodes: z.array(z.string().nullable()),
});

export type ICreatableSuggestion = z.infer<typeof ZCreatableSuggestion>;

export const ZAttachableSuggestion = z.object({
  _id: ZCreatableId,
  ignored: z.boolean(),
  nodes: z.array(z.string().nullable()),
  comp_ids: z.array(ZCreatableId),
  ws_comp: ZCreatableId,
  text: z.string(),
  rich_text: ZTipTapRichText,
  // TODO: Fix this type
  variables: z.array(z.any()),
  status: z.string(),
  notes: z.string().nullable().optional(),
  tags: z.array(z.string()),
  rating: z.number(),
  wsComp: z.unknown(),
});

export type IAttachableSuggestion = z.infer<typeof ZAttachableSuggestion>;

export const ZComponentSuggestionJobResult = z.object({
  hasComponents: z.boolean(),
  creatableSuggestions: z.array(ZCreatableSuggestion),
  attachableSuggestions: z.array(ZAttachableSuggestion),
});
export type IComponentSuggestionsJobResult = z.infer<typeof ZComponentSuggestionJobResult>;
