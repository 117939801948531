import { ITipTapContentElement, ITipTapRichText } from "../../types/TextItem";
import { addTemplateHighlightMarks } from "./templates/TemplateHighlightNode";

interface IOptions {
  highlightBrackets?: boolean;
}

/**
 * - Removes empty text nodes, which are disallowed by ProseMirror
 * - If `highlightBrackets` is `true`, isolates bracket pairs in their own text nodes
 * and adds a mark to them
 */
const prepareRichTextNodes = (richText: ITipTapRichText, options: IOptions = {}) => {
  const content =
    richText.content?.map((paragraph) => {
      const nonEmptyTextNodes: ITipTapContentElement[] = [];

      paragraph.content?.forEach((textObj) => {
        // keep non-text node types and return early
        if (textObj.type !== "text") {
          nonEmptyTextNodes.push(textObj);
          return;
        }

        // if we're not highlighting brackets and the node is not empty,
        // keep it and return early
        if (!options.highlightBrackets && !!textObj.text) {
          nonEmptyTextNodes.push(textObj);
          return;
        }

        // if there's no text in the node, return early
        if (!textObj.text) return;

        nonEmptyTextNodes.push(...addTemplateHighlightMarks(textObj));
      });

      return { content: nonEmptyTextNodes, type: "paragraph" };
    }) || [];

  if (!content.length) {
    content.push({
      type: "paragraph",
      content: [],
    });
  }

  return {
    type: "doc",
    content,
  };
};

export default prepareRichTextNodes;
