import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "../style.module.css";

const arr = Array(5).fill(undefined);

const LoadingState = () => {
  return (
    <div className={style.loadingWrapper}>
      {arr.map((_item, index) => (
        <div key={index} className={style.compGroup}>
          <Skeleton width={`40%`} />
          <Skeleton width={`100%`} height={"60%"} />
        </div>
      ))}
    </div>
  );
};

export default React.memo(LoadingState);
