import React from "react";

import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";

interface Props {}

const PermissionRequiredComponent = (props: Props) => {
  const history = useHistory();
  return (
    <div className={style.componentContainer}>
      <Card className={style.card}>
        <h1 className={style.title}>Sorry, you don't have permission to view this component.</h1>
        <h2 className={style.subtitle}>Reach out to an admin of this workspace to get access.</h2>
        <span className={style.backLink} onClick={() => history.push("/components")}>
          {"<-"} Back to All Components
        </span>
      </Card>
    </div>
  );
};

export default PermissionRequiredComponent;
