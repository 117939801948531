import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";
import ReactTooltip from "react-tooltip";

import style from "./style.module.css";

interface Props {
  title: string;
  tooltip?: {
    id: string;
    text: string;
    onClick: () => void;
  };
}

const ExportModalLabel = (props: Props) => {
  const { title, tooltip } = props;

  return (
    <h2 className={style.iconTitle}>
      {title}
      {tooltip && (
        <>
          <HelpOutlineIcon className={style.helpIcon} data-tip data-for={tooltip.id} onClick={tooltip.onClick} />
          <ReactTooltip id={tooltip.id} place="right" effect="solid" className={style.customTooltip}>
            {tooltip.text}
          </ReactTooltip>
        </>
      )}
    </h2>
  );
};

export default ExportModalLabel;
