import React, { memo, useCallback, useMemo, useState } from "react";
import AddVariableModal from "../AddVariableModal";

import Button from "@ds/atoms/Button";
import Tooltip from "@ds/molecules/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import TollIcon from "@mui/icons-material/Toll";
import classNames from "classnames";
import RichTextVariableInput from "./RichTextVariableInput";
import { Label, LabelRight } from "./components";
import style from "./style.module.css";

/**
 *
 * @param {*} param0
 * @returns
 */
const VariableRichTextArea = ({
  placeholder,
  emptyEditorClass,
  value,
  isDisabled,
  isBaseText,
  isVariant,
  hideLabels = false,
  useNSLabels = false,
  showContentLength,
  showContentLengthOnFocusOnly,
  hideCharacterLimit,
  disableCharacterLimit,
  isPlural,
  handleTextChange,
  components,
  disableRichText,
  textLabelLeft,
  textInputClassName,
  accessEditorInstance,
  highlightBrackets,
  handleCharacterLimitChange,
  handleCharacterLimitSubmit,
  characterLimit,
  inSampleProject,
  onEnablePlurals,
  variablesDisabled = false,
  pluralsDisabled = false,
}) => {
  const [showVariableBtn, setShowVariableBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentLength, setContentLength] = useState(0);
  const [currentSelection, setCurrentSelection] = useState("");
  const [insertedVariable, setInsertedVariable] = useState(null);
  const [contentLengthVisible, setContentLengthVisible] = useState(!showContentLengthOnFocusOnly && showContentLength);

  const hideVariableModal = () => {
    setShowModal(false);
  };
  const showVariableModal = useCallback(() => {
    setShowModal(true);
    setTimeout(() => {
      setShowVariableBtn(true);
    }, 150);
  }, [setShowModal, setShowVariableBtn]);

  const onInsertVariable = (selectedVariable) => {
    window.analytics?.track("Variable Inserted", {
      type: selectedVariable.type,
    });
    setInsertedVariable(selectedVariable);
  };

  const onFocus = useCallback(() => {
    if (showContentLengthOnFocusOnly) setContentLengthVisible(true);
    setShowVariableBtn(true);
  }, [showContentLengthOnFocusOnly, setContentLengthVisible, setShowVariableBtn]);

  const onBlur = useCallback(() => {
    if (showContentLengthOnFocusOnly) setContentLengthVisible(false);
    setTimeout(() => {
      setShowVariableBtn(false);
    }, 100);
  }, [showContentLengthOnFocusOnly, setContentLengthVisible, setShowVariableBtn]);

  const memoizedAddIcon = useMemo(() => <AddIcon className={style.nsVariableIcon} />, []);

  const getLabelText = () => {
    if (isBaseText && isVariant) return "Base Text";
    if (isBaseText && !isVariant) return "Text";
    return "Variant Text";
  };

  return (
    <div className={style.container}>
      {!hideLabels && (
        <div className={style.titleWrapper}>
          <Label components={components} getLabelText={getLabelText} textLabelLeft={textLabelLeft} />
          <LabelRight
            key={value.characterLimit}
            components={components}
            showVariableButton={showVariableBtn || !isPlural}
            showVariableModal={showVariableModal}
            contentLength={contentLength}
            buttonText="+ Insert Variable"
            showContentLength={contentLengthVisible}
            characterLimit={value.characterLimit}
            handleCharacterLimitChange={handleCharacterLimitChange}
            handleCharacterLimitSubmit={handleCharacterLimitSubmit}
            disableCharacterLimit={disableCharacterLimit}
            hideCharacterLimit={hideCharacterLimit}
          />
        </div>
      )}
      {useNSLabels && (
        <div className={style.titleWrapper}>
          <Label components={components} getLabelText={getLabelText} textLabelLeft={textLabelLeft} />
        </div>
      )}
      <RichTextVariableInput
        highlightBrackets={highlightBrackets}
        placeholder={placeholder}
        emptyEditorClass={emptyEditorClass}
        isDisabled={isDisabled}
        handleTextChange={handleTextChange}
        contentLength={contentLength}
        setContentLength={setContentLength}
        setCurrentSelection={setCurrentSelection}
        onFocus={onFocus}
        onBlur={onBlur}
        initialValues={value}
        insertedVariable={insertedVariable}
        disableRichText={Boolean(disableRichText)}
        showVariableModal={showVariableModal}
        textInputClassName={textInputClassName}
        accessEditorInstance={accessEditorInstance}
        characterLimit={value.characterLimit}
      />

      {useNSLabels && (
        <div className={style.nsButtons}>
          <div>
            <Button
              className={style.nsVariableButton}
              disabled={variablesDisabled}
              level="subtle"
              size="micro"
              onClick={showVariableModal}
              leadingIcon={memoizedAddIcon}
              iconSize="xxs"
            >
              Insert variable
            </Button>

            {onEnablePlurals && (
              <div className={style.nsPluralButton}>
                <Tooltip content="Plurals are not yet available in this beta" type="invert" side="top" align="center">
                  <PluralsButton onEnablePlurals={onEnablePlurals} />
                </Tooltip>
              </div>
            )}
          </div>
          <Tooltip
            align="center"
            content="Character limits are not yet available in this beta"
            type="invert"
            side="left"
          >
            <CharacterLimitsWrapper
              characterLimit={characterLimit}
              handleCharacterLimitChange={handleCharacterLimitChange}
            />
          </Tooltip>
        </div>
      )}

      {showModal && (
        <AddVariableModal
          type="select"
          defaultExample={currentSelection}
          onHide={hideVariableModal}
          onInsertVariable={onInsertVariable}
          inSampleProject={inSampleProject}
          stopPropagationOnEscape={useNSLabels}
        />
      )}
    </div>
  );
};

const PluralsButton = memo(
  /**
   *
   * @param {{onEnablePlurals: () => void}} props
   * @returns
   */
  function PluralsButton(props) {
    const icon = useMemo(() => <TollIcon className={style.nsPluralIcon} />, []);
    return (
      <Button
        className={style.nsPluralButton}
        // disabled={pluralsDisabled}
        disabled={true}
        variant="text"
        // level="subtle"
        level="outline"
        size="micro"
        onClick={props.onEnablePlurals}
        leadingIcon={icon}
        iconSize="xxs"
      >
        Plurals
      </Button>
    );
  }
);

/**
 *
 * @param {{characterLimit?: number | "MIXED"; handleCharacterLimitChange: (number | null) => void;}} props
 * @returns
 */
const CharacterLimitsWrapper = memo(function CharacterLimitsWrapper(props) {
  return (
    <input
      className={classNames(style.characterLimitInput, style.nsCharacterLimitInput)}
      // disabled={disableCharacterLimit}
      disabled={true}
      placeholder={props.characterLimit === "MIXED" ? "Mixed character limits" : `Character Limit #`}
      value={props.characterLimit === "MIXED" ? "" : props.characterLimit ?? ""}
      onChange={(e) => {
        const parsed = parseInt(e.target.value);
        if (e.target.value === "") {
          props.handleCharacterLimitChange(null);
          return;
        }

        if (Number.isNaN(parsed)) {
          return;
        }

        props.handleCharacterLimitChange(parseInt(e.target.value));
      }}
    />
  );
});

export default VariableRichTextArea;
