// branch Figma file URL format: https://www.figma.com/file/30DFYuFjekVx45tMUK7Drj/branch/qJ7uUKaadfaHnkbiqF0uPm/Test-File
/**
 * Parse Figma file URL and returns the fileId and branchId found in the url. all fields can be undefined.
 * @param url The Figma file URL
 * @returns
 */
const BASE_REGEX = /https:\/\/[\w\.-]+\.?figma.com\/([\w-]+)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
const BRANCH_REGEX = /branch\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

export default function parseFigmaFileURL(url: string) {
  const fileId = BASE_REGEX.exec(url)?.[2] || undefined;
  const branchId = url.match(BRANCH_REGEX)?.[1] || undefined;
  return { fileId, branchId };
}
