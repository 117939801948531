import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

const ErrorFallback = ({ error, componentStack, resetError }) => {
  const refreshPage = () => window.location.reload();

  const onHide = () => {
    // you cant hide this
  };

  return (
    <div className={style.container}>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Body className={style.body}>
          <div className={style.title}>Sorry, something went wrong</div>
          <div className={style.error}>
            Please refresh and try again, or send us an email at{" "}
            <a href="mailto: support@dittowords.com">support@dittowords.com</a> if the issue persists.
          </div>
          <ButtonPrimary text="Refresh" onClick={refreshPage} />
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default ErrorFallback;
