const IW_HEIGHT_MIN = 200;
const IW_HEIGHT_WINDOW_PERCENT = 0.6;
const IW_HEIGHT_PADDING_PX = 2;
/**
 * Returns an object used to style the height of the div that wraps the preview image from
 * Figma. This is necessary in order ensure that the container is not too tall nor too short,
 * and doesn't contain any extraneous padding.
 * @param dimensions
 * @returns
 */
export function useImageWrapperStyles(dimensions: { width: number; height: number } | null): React.CSSProperties {
  if (!dimensions) return {};

  const heightConstraint = window.innerHeight * IW_HEIGHT_WINDOW_PERCENT;

  let height = dimensions.height + IW_HEIGHT_PADDING_PX;
  height = Math.min(height, heightConstraint);
  height = Math.max(height, IW_HEIGHT_MIN);

  return { height: `${height}px` };
}
