import React from "react";
import ModalBase from "../shared/ModalBase";

import ConfirmationModal from "../shared/confirmation-modal";
import ChooseAction from "./steps/choose-action";
import FileUpload from "./steps/file-upload";
import Preview from "./steps/preview";
import Success from "./steps/success";
import VariantSelect from "./steps/variant-select";
import { useState } from "./useState";

import classNames from "classnames";
import MappingModal from "./CsvImportMapping/MappingModal";
import style from "./style.module.css";

export default function WsCompImportModal(props) {
  const { onHide: _onHide } = props;

  const state = useState(props);
  const { step, file, format } = state.state;

  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);

  const onHide = () => (file && step !== "success" ? setShowConfirmationModal(true) : _onHide());

  let title: React.ReactNode = "Import Components";

  if (step === "success") {
    title = "Successfully imported! 🎉";
  } else if (step === "csv-mapping") {
    title = (
      <>
        <span className={style.stepTitle}>Import Components</span>
        <span className={style.stepInfo}>Step 1 of 2: Map Columns</span>
      </>
    );
  } else if (step === "preview" && format === "csv") {
    title = (
      <>
        <span className={style.stepTitle}>Import Components</span>
        <span className={style.stepInfo}>Step 2 of 2: Review</span>
      </>
    );
  }
  return (
    <>
      <ModalBase
        title={title}
        className={classNames({
          [style.modalOverride]: step !== "csv-mapping",
          [style.modalWide]: step === "preview",
          [style.modalSuperWide]: step === "csv-mapping",
        })}
        onHide={onHide}
      >
        {step === "file-upload" && <FileUpload {...state} />}
        {step === "choose-action" && <ChooseAction {...state} />}
        {step === "variant-select" && <VariantSelect {...state} />}
        {step === "preview" && <Preview {...state} />}
        {step === "success" && <Success onHide={onHide} {...state} />}
        {step === "csv-mapping" && <MappingModal dispatchImportState={state.dispatch} importState={state.state} />}
        {!state.isValidStep && <div>Invalid step: {step}</div>}
      </ModalBase>
      {showConfirmationModal && (
        <ConfirmationModal
          isLayered
          title="Are you sure you want to exit?"
          body="Your file will not be imported!"
          actionPrimary="Confirm"
          actionSecondary="Cancel"
          onPrimary={_onHide}
          onSecondary={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
}
