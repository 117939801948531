import React from "react";

import iconFigmaComponent from "../../assets/figcomp.svg";
import iconFigmaComponentSetInstance from "../../assets/figma_component_set_instance.svg";
import iconFigmaComponentInstance from "../../assets/figma_instance.svg";

import style from "./style.module.css";

interface IProps {
  frameName: string | null | undefined;
}

export default function FigmaComponentInfo(props: IProps) {
  if (!props.frameName) return <React.Fragment />;

  if (props.frameName.startsWith("[[M]]")) {
    return (
      <div className={style.figmaComponentText}>
        <img src={iconFigmaComponent} />
        Main Component
      </div>
    );
  }

  if (props.frameName.startsWith("[[I]]")) {
    return (
      <div className={style.figmaComponentText}>
        <img src={iconFigmaComponentInstance} />
        Instance
      </div>
    );
  }

  if (props.frameName.startsWith("[[V]]")) {
    return (
      <div className={style.figmaComponentText}>
        <img src={iconFigmaComponentSetInstance} />
        Variant
      </div>
    );
  }

  return <React.Fragment />;
}
