import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { DittoComponent } from "ditto-react";
import React from "react";
import style from "./style.module.css";

function LogoutPanel() {
  const { logout } = useAuthenticatedAuth();
  const redirectURL = `${process.env.BASE_URL}/login`;

  return (
    <div className={style.logoutPanel}>
      <div className={style.logoutButton} onClick={() => logout({ returnTo: redirectURL })}>
        <LogoutIcon className={style.logoutIcon} />
        <p className={style.logoutText}>
          <DittoComponent componentId="log-out" />
        </p>
      </div>
    </div>
  );
}

export default LogoutPanel;
