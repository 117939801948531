import React from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";

const statusColors = {
  NONE: "#D4D4D4",
  WIP: "#E13D1F",
  REVIEW: "#FCCE56",
  FINAL: "#70CA35",
  MIXED: "#737373",
} as const;

export function StatusIcon({ status }: { status: ActualComponentStatus | "MIXED" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill={status === "NONE" ? "#FFFFFF" : statusColors[status]}
    >
      <circle cx="5" cy="5" r="4.5" stroke={statusColors[status]} />
    </svg>
  );
}

export function ParagraphIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path d="M0 4.125H14V5.875H0V4.125ZM0 7.625H8.75V9.375H0V7.625Z" fill="currentColor" />
      <path d="M14 0.625H0V2.375H14V0.625Z" fill="currentColor" />
    </svg>
  );
}

export function FlagIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M8.56466 1.64706L8.23525 0H0.823486V14H2.47055V8.23529H7.08231L7.41172 9.88235H13.1764V1.64706H8.56466Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AvatarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 2.8C8.351 2.8 9.45 3.899 9.45 5.25C9.45 6.601 8.351 7.7 7 7.7C5.649 7.7 4.55 6.601 4.55 5.25C4.55 3.899 5.649 2.8 7 2.8ZM7 12.6C5.579 12.6 3.899 12.026 2.702 10.584C3.885 9.66 5.376 9.1 7 9.1C8.624 9.1 10.115 9.66 11.298 10.584C10.101 12.026 8.421 12.6 7 12.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function TagIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M13.587 6.706L7.287 0.406C7.035 0.154 6.685 0 6.3 0H1.4C0.63 0 0 0.63 0 1.4V6.3C0 6.685 0.154 7.035 0.413 7.294L6.713 13.594C6.965 13.846 7.315 14 7.7 14C8.085 14 8.435 13.846 8.687 13.587L13.587 8.687C13.846 8.435 14 8.085 14 7.7C14 7.315 13.839 6.958 13.587 6.706ZM2.45 3.5C1.869 3.5 1.4 3.031 1.4 2.45C1.4 1.869 1.869 1.4 2.45 1.4C3.031 1.4 3.5 1.869 3.5 2.45C3.5 3.031 3.031 3.5 2.45 3.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M6.3 3.5H7.7V4.9H6.3V3.5ZM6.3 6.3H7.7V10.5H6.3V6.3ZM7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6Z"
        fill="currentColor"
      />
    </svg>
  );
}
