import * as ConcurrentUserListLib from "@shared/common/concurrentUserList";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import * as dittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import React from "react";

interface IConcurrentUser {
  _id: string;
  name: string;
  picture: string | undefined | null;
}

export function useConcurrentUserState(
  pageKey: string,
  user: { _id: string; name: string; workspaceId: string } | null,
  // inject dependencies to allow shared hook usage between the web app and Figma plugin
  dependencies: {
    getToken: () => Promise<string | null>;
    sendWebsocketMessage: (data: object) => void;
  }
) {
  // we can't rely on some props being properly memoized, so this is a slight
  // hack to ensure we can access them correctly on unmount without including
  // them as an effect dependency and accidentally running the disconnect
  // logic prematurely
  const userRef = React.useRef(user);
  React.useEffect(() => {
    userRef.current = user;
  }, [user]);
  const pageKeyRef = React.useRef(pageKey);
  React.useEffect(() => {
    pageKeyRef.current = pageKey;
  }, [pageKey]);

  const [users, setUsers] = React.useState<IConcurrentUser[]>([]);

  const updateUsers = React.useMemo(() => {
    const existingUsersById = new Map(users.map((u) => [u._id, u]));

    return (newUsers: Omit<IConcurrentUser, "color">[]) => {
      setUsers(
        newUsers.map((newUser) => {
          const existingUser = existingUsersById.get(newUser._id);
          if (existingUser) {
            return existingUser;
          }

          return {
            _id: newUser._id,
            name: newUser.name,
            picture: newUser.picture,
          };
        })
      );
    };
  }, [users, setUsers]);

  React.useEffect(
    function handleConnect() {
      if (!(user && pageKey)) {
        return;
      }

      dependencies.getToken().then((token) => {
        if (!user) return;
        dependencies.sendWebsocketMessage({
          messageType: WEBSOCKET_EVENTS.CONCURRENT_USER_CONNECTED,
          token,
          pageKey: pageKey,
          user: {
            _id: user._id,
            name: user.name,
            workspaceId: user.workspaceId,
          },
        });
      });

      const interval = setInterval(() => {
        dependencies.getToken().then((token) => {
          if (!user) return;
          dependencies.sendWebsocketMessage({
            messageType: WEBSOCKET_EVENTS.CONCURRENT_USERS_KEEP_ALIVE,
            token,
            pageKey: pageKey,
            user: {
              _id: user._id,
              name: user.name,
              workspaceId: user.workspaceId,
            },
          });
        });
      }, ConcurrentUserListLib.KEEP_ALIVE_INTERVAL_MS);

      return () => {
        clearInterval(interval);
      };
    },
    [pageKey, user]
  );

  React.useEffect(function handleDisconnect() {
    return () => {
      dependencies.getToken().then((token) => {
        if (!userRef.current) return;
        dependencies.sendWebsocketMessage({
          messageType: WEBSOCKET_EVENTS.CONCURRENT_USER_DISCONNECTED,
          token,
          pageKey: pageKeyRef.current,
          user: {
            _id: userRef.current._id,
            workspaceId: userRef.current.workspaceId,
          },
        });
      });
    };
  }, []);

  useDittoEventListener(
    dittoEvents.concurrentUserListUpdate,
    (data) => {
      if (data.pageKey !== pageKey) {
        return;
      }

      // must coerce `data.users` as `any` for the sake of the plugin,
      // which cannot properly infer the type of `data` due to not
      // having strict null checking enabled
      const userIdSet = new Set<string>();
      const usersUpdated = (data.users as any).filter((u: { _id: string; name: string }) => {
        if (userIdSet.has(u._id)) {
          return false;
        }
        userIdSet.add(u._id);
        // don't display the current user in the list
        return u._id.toString() !== user?._id;
      });

      updateUsers(usersUpdated);
    },
    [pageKey, user]
  );

  return { users };
}
