import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { UserPermissionContext } from "@shared/frontend/userPermissionContext";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  HOMEPAGE_CUSTOMER_STORIES_CLICKED,
  HOMEPAGE_HELP_GUIDES_CLICKED,
  HOMEPAGE_IMPORT_FIGMA_FILE,
  HOMEPAGE_IMPORT_STRING_FILE,
  HOMEPAGE_INVITE_DEVELOPER,
  HOMEPAGE_SAMPLE_PROJ_CLICKED,
  HOMEPAGE_VIEW_ALL_PROJECTS_CLICKED,
  HOMEPAGE_VIEW_DEVELOPER_INTEGRATIONS_CLICKED,
} from "../../../shared/segment-event-names";
import WelcomeModal from "../../components/WelcomeModal";
import useSegment from "../../hooks/useSegment";
import useResourcesPanel from "../Settings/components/WorkspaceTab/ResourcesPanel/useResourcesPanel";
import AssignedToYouPanel from "./components/AssignedToYouPanel";
import useAssignedToYouPanel from "./components/AssignedToYouPanel/useAssignedToYouPanel";
import DiscussionPanel from "./components/DiscussionPanel";
import useDiscussionPanel from "./components/DiscussionPanel/useDiscussionPanel";
import GettingStartedPanel from "./components/GettingStartedPanel";
import RecentsPanel from "./components/RecentsPanel/RecentsPanel";
import useRecentsPanel from "./components/RecentsPanel/useRecentsPanel";
import ResourcesPanel from "./components/ResourcesPanel";
import WelcomeHeader from "./components/WelcomeHeader";
import style from "./style.module.css";
import { gettingStartedPanelCardsToShow } from "./utils";

export default function HomePage() {
  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { userHasBillingRole } = useContext(UserPermissionContext);
  const { loading: assignmentsLoading, assignments } = useAssignedToYouPanel();
  const {
    loading: discussionsLoading,
    notifications,
    page,
    pageSize,
    total,
    onPaginateBack,
    onPaginateForward,
    onMarkAsRead,
    onReply,
  } = useDiscussionPanel();
  const history = useHistory();
  const segment = useSegment();

  const recentsPanelProps = useRecentsPanel();

  const { resources } = useResourcesPanel();
  const componentsCount = resources.find((resource) => resource.resourceDittoComponentId === "components");
  const projectsCount = resources.find((resource) => resource.resourceDittoComponentId === "projects");

  const { displayImportDesignFile, displayImportStrings, displayViewAllProjectsButton, developerView } =
    gettingStartedPanelCardsToShow({
      user,
      recentsPanelProps,
      componentsCount,
      projectsCount,
      workspaceInfo,
      userHasBillingRole,
    });

  function onInviteDeveloper() {
    segment.track({ event: HOMEPAGE_INVITE_DEVELOPER });
    history.push("/settings/workspace?openInviteModal=true");
  }

  function onViewAllProjects() {
    segment.track({ event: HOMEPAGE_VIEW_ALL_PROJECTS_CLICKED });
    history.push("/projects");
  }

  function onViewDevIntegrations() {
    segment.track({ event: HOMEPAGE_VIEW_DEVELOPER_INTEGRATIONS_CLICKED });
    history.push("/developers");
  }

  function onImportStringFile() {
    segment.track({ event: HOMEPAGE_IMPORT_STRING_FILE });
    history.push("/components?openImportModal=true");
  }

  function onImportFigmaFile() {
    segment.track({ event: HOMEPAGE_IMPORT_FIGMA_FILE });
    history.push("/projects?openImportModal=true");
  }

  function onSampleProjectClicked() {
    segment.track({ event: HOMEPAGE_SAMPLE_PROJ_CLICKED });
    history.push("/projects/sample");
  }

  function onHelpGuidesClicked() {
    segment.track({ event: HOMEPAGE_HELP_GUIDES_CLICKED });
    window.open("https://www.dittowords.com/docs/introduction", "_blank")?.focus();
  }

  function onCustomerStoriesClicked() {
    segment.track({ event: HOMEPAGE_CUSTOMER_STORIES_CLICKED });
    window
      .open(`https://www.dittowords.com/blog?filter=case-studies&referrer=${process.env.BASE_URL}`, "_blank")
      ?.focus();
  }

  return (
    <div className={style.container}>
      <WelcomeModal />
      <WelcomeHeader firstName={user.name.split(" ")[0]} />
      {(displayImportDesignFile || displayImportStrings) && (
        <GettingStartedPanel
          displayStringFile={displayImportStrings}
          displayDesignFile={displayImportDesignFile}
          displayViewAllProjectsButton={displayViewAllProjectsButton}
          developerView={developerView}
          onViewAllProjects={onViewAllProjects}
          onImportFigmaFile={onImportFigmaFile}
          onImportStringFile={onImportStringFile}
          onInviteDeveloper={onInviteDeveloper}
          onViewDevIntegrations={onViewDevIntegrations}
        />
      )}
      <RecentsPanel {...recentsPanelProps} />
      <AssignedToYouPanel loading={assignmentsLoading} assignments={assignments} />
      <DiscussionPanel
        loading={discussionsLoading}
        notifications={notifications}
        page={page}
        pageSize={pageSize}
        total={total}
        onPaginateBack={onPaginateBack}
        onPaginateForward={onPaginateForward}
        onMarkAsRead={onMarkAsRead}
        onReply={onReply}
      />
      <ResourcesPanel
        onSampleProjectClicked={onSampleProjectClicked}
        onHelpGuidesClicked={onHelpGuidesClicked}
        onCustomerStoriesClicked={onCustomerStoriesClicked}
      />
    </div>
  );
}
