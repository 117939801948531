import { IFComponentsApiIdGenerationConfig } from "@shared/types/Workspace";
import React from "react";

const getReplacementLabel = (replacementName: string, replacementValue: string) => {
  if (replacementValue === "") {
    return `${replacementName} removed`;
  }
  return `${replacementName} replaced with '${replacementValue}'`;
};

const apiIdCasingAdjustmentLabelMap = {
  camel: "Camel case",
  pascal: "Pascal case",
  lower: "Lowercase",
  upper: "Uppercase",
};

interface Props {
  config: IFComponentsApiIdGenerationConfig | undefined;
}

export const ComponentsIDRulesList = (props: Props) => {
  const { config } = props;
  if (!config) {
    return <React.Fragment />;
  }

  const { spaceReplacement, slashReplacement, casingAdjustment, acceptedCharsPattern } = config;

  const casingLabel = casingAdjustment && apiIdCasingAdjustmentLabelMap[casingAdjustment];

  return (
    <ul>
      <li>{getReplacementLabel("Spaces", spaceReplacement)}</li>
      <li>{getReplacementLabel("Forward slashes (/)", slashReplacement)}</li>
      {acceptedCharsPattern && <li>Accepted characters (regex): '{acceptedCharsPattern}'</li>}
      {casingLabel && <li>{casingLabel}</li>}
    </ul>
  );
};
