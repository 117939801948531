import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { IUserPermissionGroups } from "@shared/types/User";
import { useEffect, useState } from "react";
import http, { API } from "../../http";

type FolderType = "component" | "project" | "variant";

export interface Folder {
  name: string;
  _id: string;
  component_ids: string[];
  workspace_id: string;
}

interface UseFolderSelectProps {
  useLocalStorage?: boolean;
  disableDefaultFolder?: boolean;
  onlyAllowFoldersWithEditAccess?: boolean;
  folderType?: FolderType;
  onFolderSelectCallback?: (folder: Folder) => void;
  useSampleData?: boolean;
}

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getAccessibleFolders = (folders: Folder[], userPermissions: IUserPermissionGroups, folderType: FolderType) => {
  if (userPermissions?.general.find((p) => p.action === `${folderType}_folder:edit_all`)) {
    return folders;
  }

  // get all folders that the user has edit access to
  const accessibleFolderIds = Object.values(userPermissions?.resources)
    .filter((r) => r.resource_type === "component_folder")
    .map((r) => r.resource_id);

  return folders.filter((f) => accessibleFolderIds.includes(f._id));
};

export interface IUseFolderSelect {
  selectedFolder: Folder;
  setSelectedFolder: (folder: Folder) => void;
  folders: Folder[];
  isLoading: boolean;
}

const useFolderSelect = (props: UseFolderSelectProps): IUseFolderSelect => {
  const folderType = props.folderType || "component";
  const localStorageKey = `selected_${folderType}_folder`;
  const DEFAULT_FOLDER = {
    name: `All ${folderType}s`,
    _id: "",
    component_ids: [],
    workspace_id: "",
  };

  const { user } = useAuthenticatedAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<Folder>(DEFAULT_FOLDER);
  const [folders, setFolders] = useState<Folder[]>([DEFAULT_FOLDER]);

  const fetchFolders = async () => {
    try {
      setIsLoading(true);
      let url = "";
      if (folderType === "component") {
        url = API.componentFolder.get.folders.url;
      } else if (folderType === "variant") {
        url = API.variantFolder.get.folders.url;
      }
      const { data } = await http.get(url);

      let filteredFolders = data;
      const useSampleData = props.useSampleData ?? false;

      filteredFolders = data.filter((f) => (useSampleData ? f.isSample : !f.isSample));

      if (props.onlyAllowFoldersWithEditAccess) {
        if (!user) return setFolders([]);

        const workspaceFolders: Folder[] = filteredFolders;
        const folders = getAccessibleFolders(workspaceFolders, user.permissionGroups, folderType);

        if (props.disableDefaultFolder || useSampleData) {
          setFolders(folders);
        } else {
          setFolders([DEFAULT_FOLDER, ...folders]);
        }
      } else if (props.disableDefaultFolder || useSampleData) {
        setFolders([...filteredFolders]);
        setSelectedFolder(filteredFolders[0]);
      } else {
        setFolders([DEFAULT_FOLDER, ...filteredFolders]);
      }

      // if the useLocalStorage flag is set, check if there is a local folder
      // and set it as the selected folder if it's a valid folder
      // don't use local storage for sample data cause there will only be one folder
      if (props.useLocalStorage && useSampleData) {
        const localFolder = localStorage.getItem(localStorageKey);
        if (localFolder) {
          const parsedLocalFolder = JSON.parse(localFolder);
          const matchingFolder = filteredFolders.find((f) => f._id === parsedLocalFolder._id);

          if (matchingFolder) {
            setSelectedFolder(matchingFolder);
          }
        }
      }
    } catch (error) {
      console.error(`Error getting ${folderType} folders:`, error);
    }
    setIsLoading(false);
  };

  useEffect(function fetchFoldersOnMount() {
    fetchFolders();
  }, []);

  const handleSetSelectedFolder = (folder: Folder) => {
    setSelectedFolder(folder);

    if (props.onFolderSelectCallback) {
      props.onFolderSelectCallback(folder);
    }

    if (props.useLocalStorage) {
      localStorage.setItem(localStorageKey, JSON.stringify(folder));
    }
  };

  return {
    selectedFolder,
    setSelectedFolder: handleSetSelectedFolder,
    folders,
    isLoading,
  };
};

export default useFolderSelect;
