import EyeSlashedIcon from "@mui/icons-material/VisibilityOff";
import React from "react";
import TimeAgo from "react-timeago";
import ReactTooltip from "react-tooltip";
import style from "./style.module.css";

interface Props {
  id: string;
  text: string;
  isHidden: boolean;
  updatedAt: Date | null;
  getTooltipPosition: (
    position: {
      left: number;
      top: number;
    },
    currentEvent: Event,
    currentTarget: EventTarget
  ) => { left: number; top: number };
}

const TextMismatchTooltip = (props: Props) => {
  const id = `text-box-${props.id}`;

  if (props.isHidden) {
    return (
      <ReactTooltip id={id} place="top" effect="solid" className={style.hiddenTooltipWrapper}>
        <EyeSlashedIcon className={style.icon} />
        <div className={style.tooltipText}>Hidden in Ditto</div>
      </ReactTooltip>
    );
  }

  return (
    <ReactTooltip
      id={id}
      place="top"
      effect="solid"
      className={style.tooltipWrapper}
      overridePosition={props.getTooltipPosition}
    >
      {props.updatedAt && (
        <div className={style.tooltipTimestamp}>
          Edited <TimeAgo date={props.updatedAt} key={props.updatedAt.toString()} minPeriod={60} />
        </div>
      )}
      <div className={style.tooltipText}>{props.text}</div>
    </ReactTooltip>
  );
};

export default TextMismatchTooltip;
