import { LibraryNavState } from "@/views/Components/components/comp-library-nav/libraryNavState";
import { IFTreeItem } from "@shared/types/http/Component";

export function componentsDeletedAction(treeState: LibraryNavState, deletedComponentIds: string[]) {
  const newTreeItems = treeState.treeItems
    .map((item) => {
      if (item.type === "group" && item.children) {
        const newChildren = item.children
          .map((child) => {
            if (child.type === "block" && child.children?.length) {
              const newBlockChildren = child.children.filter(
                (component) => !deletedComponentIds.includes(component.id)
              );
              // if the block is empty, remove it
              return newBlockChildren.length > 0 ? { ...child, children: newBlockChildren } : null;
            } else if (child.type === "component") {
              return deletedComponentIds.includes(child.id) ? null : child;
            }
            return child;
          })
          .filter(Boolean) as IFTreeItem[];

        // if the group is empty, remove it
        return newChildren.length > 0 ? { ...item, children: newChildren } : null;
      } else if (item.type === "component") {
        return deletedComponentIds.includes(item.id) ? null : item;
      }
      return item;
    })
    .filter(Boolean) as IFTreeItem[];

  return { ...treeState, treeItems: newTreeItems };
}
