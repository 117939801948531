import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import * as SegmentEvents from "@shared/segment-event-names";
import { FrameIdsByPageId } from "@shared/types/figma";
import classnames from "classnames";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../../../../components/button/buttonprimary";
import ConfirmationModal from "../../../../../../components/shared/confirmation-modal";
import useSegment from "../../../../../../hooks/useSegment";
import http from "../../../../../../http";
import { pollingBackgroundJobRequest } from "../../../../../../http/lib/clientHelpers";
import { ManageGroupsModalContext } from "../../ManageGroupsModalContext";
import PageFrameSelection from "../PageFrameSelection";
import style from "./style.module.css";

export type SelectedGroupsByPage = {
  [pageId: string]: {
    id: string;
    name: string;
    isSelected: boolean;
  }[];
};
interface Props {
  docId: string;
  isFigmaAuthenticated: boolean | null;
  figmaFileId: string | null;
  figmaBranchId: string | null;
  onHide: () => void;
  resync: (newlySelectedFramesByPageId: FrameIdsByPageId, newlyDeselectedFramesByPageId: FrameIdsByPageId) => void;
  forceShowToast: (input: { title: string; body: string; displayAccountLink: boolean; autoHide: boolean }) => void;
  handleGoBack: () => void;
}
const SelectFramesModal = ({
  docId,
  isFigmaAuthenticated,
  figmaFileId,
  figmaBranchId,
  onHide,
  resync,
  forceShowToast,
  handleGoBack,
}: Props) => {
  const segment = useSegment();
  const { groups } = useContext(ManageGroupsModalContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedGroupsByPage, setSelectedGroupsByPage] = useState<SelectedGroupsByPage>({});
  const [selectedFrames, setSelectedFrames] = useState<string[]>([]);
  const [previouslySelectedFrames, setPreviouslySelectedFrames] = useState<string[]>([]);
  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  const [originalFileData, setOriginalFileData] = useState<any>(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);
  const [discardAction, setDiscardAction] = useState<any>({});

  const numFramesToAdd = useMemo(() => {
    return selectedFrames.length - previouslySelectedFrames.length;
  }, [previouslySelectedFrames, selectedFrames]);

  const footerMsg = useMemo(() => {
    return numFramesToAdd === 0
      ? "No frames selected to add"
      : `${numFramesToAdd} frame${numFramesToAdd > 1 ? "s" : ""} added`;
  }, [numFramesToAdd]);

  useEffect(() => {
    loadFigmaData();
  }, []);

  const previousFrameClick = useRef<{ pageId: string; frameId: string; index: number } | null>(null);

  const handleSelectFrame = (args: { pageId: string; frameId: string; isShiftClick: boolean }) => {
    const { pageId, frameId, isShiftClick } = args;
    setSelectedGroupsByPage((prev) => {
      let temp = prev;
      let frameIndex = temp[pageId].findIndex((frame) => frame.id === frameId);

      if (isShiftClick && previousFrameClick.current?.pageId === pageId) {
        const startIndex =
          frameIndex < previousFrameClick.current.index ? frameIndex : previousFrameClick.current.index;
        const endIndex = frameIndex > previousFrameClick.current.index ? frameIndex : previousFrameClick.current.index;

        for (let i = startIndex; i <= endIndex; i++) {
          temp[pageId][i].isSelected = true;
        }

        const frameIdsToSelect = temp[pageId].slice(startIndex, endIndex + 1).map((frame) => frame.id);
        // We have to do this to make sure duplicated don't get added to selectedFrames
        const newFramesToSelect = frameIdsToSelect.filter((id) => !selectedFrames.includes(id));
        setSelectedFrames((prev) => prev.concat(newFramesToSelect));
      } else {
        temp[pageId][frameIndex].isSelected = !temp[pageId][frameIndex].isSelected;
        if (!selectedFrames.includes(frameId)) {
          setSelectedFrames((prev) => prev.concat(frameId));
        } else if (!temp[pageId][frameIndex].isSelected) {
          setSelectedFrames((prev) => prev.filter((frame) => frame !== frameId));
        }
      }

      // Set previousFrameClick if click was to select, otherwise set it to null
      if (temp[pageId][frameIndex].isSelected) {
        previousFrameClick.current = { pageId, frameId, index: frameIndex };
      } else {
        previousFrameClick.current = null;
      }

      return temp;
    });
  };

  const handleToggleAllSelectedForPage = (pageId: string) => {
    const anyFramesOnPageSelected =
      selectedGroupsByPage[pageId] &&
      selectedGroupsByPage[pageId]
        .filter((frame) => !previouslySelectedFrames.includes(frame.id))
        .some((frame) => frame.isSelected);
    setSelectedGroupsByPage((prev) => {
      const temp = { ...prev };
      temp[pageId]
        .filter((frame) => !previouslySelectedFrames.includes(frame.id))
        .forEach((frame) => (frame.isSelected = !anyFramesOnPageSelected));
      const frameIds = temp[pageId]
        .filter((frame) => !previouslySelectedFrames.includes(frame.id))
        .map((frame) => frame.id);
      if (anyFramesOnPageSelected) {
        setSelectedFrames((prev) => prev.filter((frame) => !frameIds.includes(frame)));
      } else {
        setSelectedFrames((prev) => prev.concat(frameIds));
      }
      return temp;
    });
  };

  const loadFigmaData = async () => {
    try {
      setIsLoading(true);

      const response = await pollingBackgroundJobRequest<any>({
        url: `/jobs/figmaGetPages`,
        requestBody: {
          figmaFileId,
          figmaBranchId,
          onImport: false,
        },
      });

      const selectedFrames = {};
      groups.forEach((group) => {
        selectedFrames[group.integrations.figma["frame_id"]] = true;
      });

      let temp = response.groupsByPage;
      Object.keys(temp).forEach((pageId) => {
        temp[pageId] = temp[pageId].map((frame) => {
          return { ...frame, isSelected: Boolean(selectedFrames[frame.id]) };
        });
      });
      let currentlySelectedFrames = Object.keys(selectedFrames);
      setSelectedFrames(currentlySelectedFrames);
      setPreviouslySelectedFrames(currentlySelectedFrames);
      setSelectedGroupsByPage(temp);

      setOriginalFileData(response);

      setIsLoading(false);
    } catch (error) {
      forceShowToast({
        title: "⚠️ Issue fetching Figma file pages",
        body: "Please ",
        displayAccountLink: true,
        autoHide: false,
      });
      console.error("in selectframemodal.jsx, error is: ", error);
    }
  };

  const saveChanges = async () => {
    try {
      setSavingChanges(true);
      setShowUnsavedChangesModal(false);
      const selectedPageIds: string[] = [];
      const selectedPageInfo: { figma_id: string; name: string }[] = [];

      Object.keys(selectedGroupsByPage).forEach((pageId) => {
        if (selectedGroupsByPage[pageId].filter((frame) => frame.isSelected).length > 0) {
          selectedPageIds.push(pageId);
        }
      });

      originalFileData.pages.forEach((page) => {
        if (selectedPageIds.includes(page.id)) {
          selectedPageInfo.push({ figma_id: page.id, name: page.name });
        }
      });

      await http.put(`/doc/updateSelectedPages/${docId}`, {
        pagesToAdd: selectedPageInfo,
        pagesToRemove: [],
      });

      const currentlySyncedGroupSet = new Set(
        groups.filter((g) => !g.hidden).map((g) => g.integrations.figma["frame_id"])
      );
      const newlySelectedFramesByPageId: FrameIdsByPageId = {};
      const newlyDeselectedFramesByPageId: FrameIdsByPageId = {};

      let newlySelectedFramesCount = 0;
      let newlyDeselectedFramesCount = 0;

      Object.entries(selectedGroupsByPage).forEach(([pageId, frames]) => {
        frames.forEach((f) => {
          const currentlySynced = currentlySyncedGroupSet.has(f.id);

          const isNewlySelectedFrame = !currentlySynced && f.isSelected;
          if (isNewlySelectedFrame) {
            newlySelectedFramesByPageId[pageId] ??= [];
            newlySelectedFramesByPageId[pageId].push(f.id);
            newlySelectedFramesCount++;
            return;
          }

          const isNewlyDeselectedFrame = currentlySynced && !f.isSelected;
          if (isNewlyDeselectedFrame) {
            newlyDeselectedFramesByPageId[pageId] ??= [];
            newlyDeselectedFramesByPageId[pageId].push(f.id);
            newlyDeselectedFramesCount++;
            return;
          }
        });
      });

      // wait for resync to finish before close
      await resync(newlySelectedFramesByPageId, newlyDeselectedFramesByPageId);
      segment.track({
        event: SegmentEvents.SAVED_CHANGES_TO_MANGEGROUPSMODAL,
        properties: {
          visibleFrameCount: selectedFrames.length,
          newlySelectedFramesCount,
          newlyDeselectedFramesCount,
        },
      });
      setSavingChanges(false);
      onHide();
    } catch (error) {
      setSavingChanges(false);
      console.error("Error saving changes in MangeGroupsModal: ", error);
    }
  };

  const onGoBackClick = () => {
    if (!savingChanges) {
      if (numFramesToAdd > 0) {
        setShowUnsavedChangesModal(true);
        setDiscardAction({ callback: handleGoBack });
      } else handleGoBack();
    }
  };

  const onDiscard = () => {
    setShowUnsavedChangesModal(false);
    discardAction.callback();
  };

  return (
    <>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={classnames([style.dialog, style.width60])}
        backdropClassName={style.backdrop}
        onHide={() => {}}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            <ChevronLeftIcon className={style.backIcon} onClick={onGoBackClick} />
            Add frames from Figma file
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.selectFramesBody}>
          <PageFrameSelection
            pages={originalFileData ? originalFileData.pages : []}
            previouslySelectedFrames={previouslySelectedFrames}
            groupsByPage={selectedGroupsByPage}
            handleSelectFrame={handleSelectFrame}
            isLoading={isLoading}
            isSaving={savingChanges}
            selectedGroupsByPage={selectedGroupsByPage}
            handleToggleAllSelectedForPage={handleToggleAllSelectedForPage}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={classnames([style.footer], [style.frameSelection])}>
          <p className={style.description}>{footerMsg}</p>
          <ButtonPrimary
            disabled={savingChanges || numFramesToAdd === 0}
            text={savingChanges ? "Saving..." : "Save"}
            onClick={saveChanges}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
      {showUnsavedChangesModal && (
        <ConfirmationModal
          title="You have unsaved changes!"
          body="Before navigating away, would you like to save your new frames?"
          actionPrimary="Save"
          actionSecondary="Discard"
          onPrimary={saveChanges}
          onSecondary={onDiscard}
          isLayered={true}
        />
      )}
    </>
  );
};

export default SelectFramesModal;
