import axios, { AxiosResponse } from "axios";
import http from "./lib/client";

interface ProjectRequest {
  type: "project";
  projectId: string;
  variantId?: string | null;
}

interface ComponentsRequest {
  type: "components";
  variantId?: string | null;
  folderId: string | null;
}

export type RequestParams = ProjectRequest | ComponentsRequest;

const url = (path: string) => `/export${path}`;

export default {
  android: (params: RequestParams): [Promise<AxiosResponse<{ data: string }>>, () => void] => {
    const source = axios.CancelToken.source();

    const request = http.get<{ data: string }>(url("/android"), {
      params,
      cancelToken: source.token,
    });

    const cancelRequest = () => source.cancel();

    return [request, cancelRequest];
  },
  iosStrings: (params: RequestParams): [Promise<AxiosResponse<{ data: string }>>, () => void] => {
    const source = axios.CancelToken.source();

    const request = http.get<{ data: string }>(url("/ios/strings"), {
      params,
      cancelToken: source.token,
    });

    const cancelRequest = () => source.cancel();

    return [request, cancelRequest];
  },
  iosStringsdict: (params: RequestParams): [Promise<AxiosResponse<{ data: string }>>, () => void] => {
    const source = axios.CancelToken.source();

    const request = http.get<{ data: string }>(url("/ios/stringsdict"), {
      params,
      cancelToken: source.token,
    });

    const cancelRequest = () => source.cancel();

    return [request, cancelRequest];
  },
};
