import { useMemo } from "react";

type IComponentTypeState = { type: "none" } | { type: "standard" } | { type: "template"; attached: boolean };

export const useComponentTypeState = (type: "standard" | "template", hasUnsavedChanges: boolean) =>
  useMemo<IComponentTypeState>(() => {
    // there's no concept of "attaching" or "detaching" for standard components
    if (type === "standard") {
      return { type };
    }

    // for template components, we want to "detach in state" whenever there are unsaved
    // changes
    if (type === "template") {
      return { type, attached: !hasUnsavedChanges };
    }

    return { type: "none" };
  }, [type, hasUnsavedChanges]);
