import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import classnames from "classnames";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

interface DevModalProps {
  isProject: boolean;
  onHide: () => void;
  doc_ID?: string;
  dev_mode_enabled: boolean;
  handleDevModeEnabled: () => void;
  turnOnTooltip: () => void;
}
const DevModal = ({
  isProject,
  onHide,
  doc_ID,
  dev_mode_enabled,
  handleDevModeEnabled,
  turnOnTooltip,
}: DevModalProps) => {
  const { workspaceInfo } = useWorkspace();
  const [generatingIDs, setGeneratingIDs] = useState<boolean>(false);

  const endpointURL = !isProject ? API.workspace.post.enableDevMode.url : API.doc.post.enableDevMode.url(doc_ID);

  const enableDevMode = async () => {
    try {
      setGeneratingIDs(true);
      await http.post(endpointURL, {});

      turnOnTooltip();
      await handleDevModeEnabled();
    } catch (e) {
      console.log("Caught error in dev-modal.jsx: ", e.message);
    }
    setGeneratingIDs(false);
  };

  const handleHide = () => {
    if (!generatingIDs) onHide();
  };

  if (!workspaceInfo?.devTools) {
    return (
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={classnames([style.dialog])}
        backdropClassName={style.backdrop}
        onHide={handleHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Enable workspace developer integrations</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className={style.subtitle}>Your workspace doesn't have developer integrations enabled.</div>
          <div className={style.description}>
            <p>
              In order to turn on Developer Mode for {isProject ? "this project" : "your component library"}, developer
              integrations need to be enabled for your workspace.
            </p>
          </div>
          <div className={style.btnWrapper}>
            <EnableLinkButton />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={classnames([style.dialog])}
      backdropClassName={style.backdrop}
      onHide={handleHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>Turning on Developer Mode</BootstrapModal.Title>
        {!generatingIDs && (
          <span data-testid="close-dev-mode-modal">
            <CloseIcon className={style.close} onClick={handleHide} />
          </span>
        )}
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div>
          <div className={style.subtitle}>What is Developer Mode?</div>
          <div className={style.description}>
            <p>
              Developer Mode allows developers to fetch up-to-date text from Ditto in development using our API. Once
              Developer Mode is on for {isProject ? "a project" : "your component library"}, you can generate and manage
              Developer IDs for text.{" "}
              <a href="https://www.dittowords.com/docs/ditto-developer-mode" target="_blank">
                Learn more
              </a>
            </p>
            {!dev_mode_enabled && (
              <p>
                Don't worry, you'll still be able to continue editing{" "}
                {isProject ? "this project" : "your component library"} after Developer Mode is turned on!
              </p>
            )}
            <div className={style.btnWrapper}>
              <ButtonPrimary
                data-testid="turn-on-developer-mode"
                disabled={generatingIDs || dev_mode_enabled}
                text={
                  generatingIDs
                    ? "Generating IDs..."
                    : !dev_mode_enabled
                    ? "Turn on Developer Mode"
                    : "Developer Mode Enabled 🎉"
                }
                onClick={enableDevMode}
              />
            </div>
            {dev_mode_enabled && (
              <p>
                You'll now be able to view and edit IDs in {isProject ? "this project" : "your component library"} or
                fetch {isProject ? "this project" : "your component library"} with our API. To learn more about our API,
                check out{" "}
                <a href="https://developer.dittowords.com/" target="_blank">
                  our developer documentation.
                </a>
              </p>
            )}
          </div>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

const EnableLinkButton = () => {
  const text = (
    <>
      Enable Developer Integrations <OpenInNewIcon className={style.icon} />
    </>
  );
  return <ButtonPrimary text={text} onClick={() => window.open("/developers")} />;
};

export default DevModal;
