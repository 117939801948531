import { useComponentFolders } from "@/components/webhooks/useComponentFolders";
import { useWorkspaceFeatureEnabled } from "@/flagged-feature";
import * as httpProject from "@/http/project";
import { SYNC_SETTINGS_SPECIAL_FOLDER_IDS } from "@shared/lib/project";
import { IFFeatureFlags } from "@shared/types/Project";
import { AxiosResponse } from "axios";
import type { IProps } from ".";
import { IState, getStateAutoAttachHasChanged, getStateAutoSyncHasChanged } from "./lib";

interface IArgs {
  projectId: string;
  figmaFileId?: string;
  pages: { id: string; name: string }[];
  featureFlags: IFFeatureFlags;
  hooks?: {
    postSave?: (state: IState) => void;
  };
}

export function useSyncSettingsModal(args: IArgs): Omit<IProps, "onHide"> {
  const componentFoldersAllowed = useWorkspaceFeatureEnabled("component-folders");
  const componentFolders = useComponentFolders();

  const initialState: IProps["initialState"] = {
    autoAttachComponentMatches: {
      enabled: args.featureFlags.autoAttachComponents.enabled || false,
      folderId:
        args.featureFlags.autoAttachComponents.enabled && args.featureFlags.autoAttachComponents.componentFolderId
          ? args.featureFlags.autoAttachComponents.componentFolderId
          : SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ALL_COMPONENTS,
    },
    autoImportFrames: {
      enabled: args.featureFlags.autoImportFrames.enabled || false,
      pageIds:
        args.featureFlags.autoImportFrames.enabled && args.featureFlags.autoImportFrames.selectedPages
          ? new Set(Object.keys(args.featureFlags.autoImportFrames.selectedPages))
          : new Set(),
    },
  };

  return {
    initialState,
    figmaFileId: args.figmaFileId,
    data: {
      foldersAvailable: componentFoldersAllowed,
      pages: args.pages,
      folders: componentFolders.loading ? undefined : componentFolders.data,
    },
    onSave: async (state) => {
      const requests: Promise<AxiosResponse<any>>[] = [];

      if (getStateAutoAttachHasChanged(initialState.autoAttachComponentMatches, state.autoAttachComponentMatches)) {
        const [request] = httpProject.updateProjectFeatureFlag({
          projectId: args.projectId,
          flagName: "autoAttachComponents",
          enabled: state.autoAttachComponentMatches.enabled,
          flagMetadata: { componentFolderId: state.autoAttachComponentMatches.folderId },
        });
        requests.push(request);
      }

      if (getStateAutoSyncHasChanged(initialState.autoImportFrames, state.autoImportFrames)) {
        let selectedPages = Array.from(state.autoImportFrames.pageIds).reduce(
          (acc, pageId) => ({ ...acc, [pageId]: true }),
          {}
        );
        if (!state.autoImportFrames.enabled) {
          selectedPages = {};
        }

        const [request] = httpProject.updateProjectFeatureFlag({
          projectId: args.projectId,
          flagName: "autoImportFrames",
          enabled: state.autoImportFrames.enabled,
          flagMetadata: {
            selectedPages,
          },
        });
        requests.push(request);
      }

      const result = await Promise.all(requests);

      if (args.hooks?.postSave) {
        args.hooks.postSave(state);
      }

      return result;
    },
  };
}

export function stateToFeatureFlags(state: IState): Pick<IFFeatureFlags, "autoAttachComponents" | "autoImportFrames"> {
  return {
    autoAttachComponents: {
      enabled: state.autoAttachComponentMatches.enabled,
      componentFolderId: state.autoAttachComponentMatches.folderId,
    },
    autoImportFrames: {
      enabled: state.autoImportFrames.enabled,
      selectedPages: Array.from(state.autoImportFrames.pageIds).reduce(
        (acc, pageId) => ({ ...acc, [pageId]: true }),
        {}
      ),
    },
  };
}
