import { DragPreview } from "@/components/DragAndDrop";
import { useComponentMergeSuggestionPanel } from "@/components/component-merge-suggestions/useComponentMergeSuggestionPanel";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { Toast } from "@ds/organisms/Toast";
import * as Sentry from "@sentry/react";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { generateSortKey } from "@shared/lib/components";
import { ENTRY_TYPES } from "@shared/types/ActualChange";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { createApiIdGenerator } from "@shared/utils/apiId";
import logger from "@shared/utils/logger";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { nestComponents } from "../../../util/nestComponents";
import useDebouncedCallback from "../../../util/useDebouncedCallback";
import { useCompLibraryDragAndDrop } from "../../components/DragAndDrop/useCompLibraryDragAndDrop";
import ErrorBoundary from "../../components/ErrorBoundary";
import OverlayToast from "../../components/OverlayToast";
import { useOverlayToast } from "../../components/OverlayToast/useOverlayToast";
import CompDetail from "../../components/compdetail/compdetail";
import CompResults from "../../components/compresults/compresults";
import useComponentFolder from "../../components/compresults/useComponentFolder";
import DraftCompModal from "../../components/draft-comp-modal";
import EditMultiWsComp from "../../components/editcomp/editmultiwscomp";
import NotificationToast from "../../components/notification-toast";
import ConfirmationModal from "../../components/shared/confirmation-modal";
import WsCompImportModal from "../../components/ws-comp-import-modal";
import { CsvImportProvider } from "../../components/ws-comp-import-modal/CsvImportMapping/CsvImportContext";
import { COMPONENT_LIBRARY_PANEL_STATES, PANELS } from "../../defs";
import useSearchState from "../../hooks/useSearchState";
import http, { API } from "../../http";
import * as httpWsComp from "../../http/ws_comp_typed";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import { WebappPermissionProvider as UserPermissionProvider } from "../../store/webappPermissionContext";
import ComponentLibraryWebsocketHandler from "./components/ComponentLibraryWebsocketHandler";
import ComponentSelectionModal from "./components/ComponentSelectionModal";
import MoveToFolderModal from "./components/MoveToFolderModal";
import CompLibraryNav from "./components/comp-library-nav";
import { LibraryNavContextProvider, useLibraryNavState } from "./components/comp-library-nav/libraryNavState";
import WsCompTitleBar from "./components/ws-comps-title-bar";
import {
  getComponentLoadParameters,
  getSelectedComponentParams,
  scrollLibraryToTop,
  scrollNavToTop,
  scrollToElementId,
  useToastHandler,
} from "./lib";
import style from "./style.module.css";
import { usePagination } from "./usePagination";
import { useSelectionState } from "./useSelectionState";

import { COMPONENT_LIBRARY_COMPONENTS_PER_PAGE } from "@shared/lib/components";

const SEARCH_QUERY_DEBOUNCE = 500;
export const IMPORT_MODAL_QUERY_STRING = "openImportModal";
export const DRAFTING_MODAL_QUERY_STRING = "openCreateModal";

export const WorkspaceComponentContext = React.createContext({});

export const useFetchData = () => useContext(WorkspaceComponentContext).fetchData;

const DISPLAY_DEVELOPER_IDS = "display-developer-ids";
const HIDE_COMPONENTS_HELP = "hide-component-help";

const DRAFT_PAGE_ID = "__DRAFT__";
const DRAFT_PAGE_NAME = "Drafted Frames";

const COMPONENTS_ROOT_REGEX = /\/components\/?$/;

const AllComps = () => {
  const { checkDetailPanelChanges, unsavedDetailChangesExist } = useContext(UnsavedChangesContext);
  const { treeState: libraryTreeState, dispatch: dispatchLibraryNavState } = useLibraryNavState();

  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [notification, setNotification] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingComponents, setIsLoadingComponents] = useState(true);
  const draftingModalOpen = new URLSearchParams(history.location.search).get(DRAFTING_MODAL_QUERY_STRING) || false;
  const [showComponentModal, setShowComponentModal] = useState(false);

  const [comps, setComps] = useState([]);
  const [nestedComps, setNestedComps] = useState([]);
  const [componentsByFolder, setComponentsByFolder] = useState({});
  const { overlayToastProps, showToast } = useOverlayToast();
  const hasShownAssigneeBanner = useRef(false);
  const [firstImportedComponentId, setFirstImportedComponentId] = useState(null);

  useDittoEventListener(
    DittoEvents.componentsUpdated,
    function onComponentsUpdated(data) {
      // only update components which already exist on the current page. NOTE: we intentionally
      // don't add components to the page or rearrange page boundaries because that could be confusing
      // for users.
      const componentsOnCurrentPage = new Set(allComponentsCache.current.map((c) => c._id));
      const componentsToUpdateOnCurrentPage = data.components.filter((c) => componentsOnCurrentPage.has(c._id));

      updateComponentsInMemory(componentsToUpdateOnCurrentPage, data.deletedComponentIds);
      componentMergeSuggestionState.regenerateSuggestions();
    },
    []
  );

  const [mergeComponentError, setMergeComponentError] = useState(null);
  const mergeSelectedComponents = async (targetComponentId) => {
    setMergeComponentError(null);
    const componentIds = selectionState.hasSingleSelection() ? [selectionState.selectedId] : selectionState.selectedIds;
    if (componentIds.filter((id) => id !== targetComponentId).length === 0) {
      setMergeComponentError("You cannot merge a component with itself");
    } else {
      const [request] = httpWsComp.mergeComponents({
        componentIds,
        targetId: targetComponentId,
      });
      try {
        await request;
        setShowComponentModal(false);
      } catch (error) {
        setMergeComponentError("Something unexpected went wrong");
      }
    }
  };
  const closeComponentSelectionModal = () => {
    setShowComponentModal(false);
    setMergeComponentError(null);
  };

  // Used for rendering the list of the components in the navigation
  const nestedCompsForNav = useMemo(
    () =>
      nestComponents(
        comps.map(({ _id, name }) => ({ _id, name })),
        COMPONENT_LIBRARY_COMPONENTS_PER_PAGE
      ),
    [comps]
  );

  const workspaceContext = useWorkspace();
  const { workspaceInfo } = workspaceContext;

  const { totalItems, setTotalItems, totalPages, pageNumber, setPageNumber, paginationEnabled, getNewPage } =
    usePagination({
      pageItemLimit: COMPONENT_LIBRARY_COMPONENTS_PER_PAGE,
      comps,
    });

  const onPaginationPageChange = (pageIndex) => {
    checkDetailPanelChanges(async () => {
      setIsLoadingComponents(true);
      setPageNumber(pageIndex);

      await updateComponentListAndCount({ page: pageIndex, debounce: true });

      selectionActions.deselectAll();
      scrollLibraryToTop();
    });
  };

  const apiIdGenerateOnRename = workspaceContext.workspaceInfo?.config?.components?.apiIdGenerateOnRename;
  const apiIdGenerationConfig = workspaceContext.workspaceInfo?.config?.components?.apiIdGeneration;

  // If allcomps is mounted, clicking notifications should use the in-page comp
  // selection logic rather than just navigating to a link.
  useEffect(() => {
    const notificationClickHandler = (wsCompId) => {
      handleSingleSelect(wsCompId);
    };

    workspaceContext.registerCallback(notificationClickHandler);

    return () => {
      workspaceContext.deregisterCallback(notificationClickHandler);
    };
  }, []);

  // Re-fetch library change history using current settings (number of pages loaded, etc.)
  // to show new changes as they appear
  const refreshLibraryHistory = async () => {
    try {
      let page = compLibraryHistory.page;
      const skip = 0;
      const limit = page === 0 ? compLibraryHistory.limit : compLibraryHistory.limit * page;
      const folder_id = selectedFolder?._id;

      const { activityHistory } = await fetchComponentLibraryHistory(folder_id, skip, limit);

      setCompLibraryHistory({
        ...compLibraryHistory,
        items: [...activityHistory],
        page: page,
      });
    } catch (error) {
      console.error("in allcomps.jsx while refreshing library history: " + error.message);
    }
  };

  // Re-fetch library change history from scratch -- page 0, 20 items, with whatever
  // folder is currently selected.
  const resetLibraryHistory = async (folderId = null) => {
    try {
      let page = 0;
      const skip = 20 * page;
      const limit = 20;

      page++;
      const { activityHistory, changeCount } = await fetchComponentLibraryHistory(folderId, skip, limit);

      setCompLibraryHistory({
        ...compLibraryHistory,
        items: [...activityHistory],
        page: page,
        allHistoryFetched: changeCount < 20,
      });
    } catch (error) {
      console.error("in allcomps.jsx while resetting library history: " + error.message);
    }
  };

  const {
    componentFolders,
    showComponentFolderModal,
    openComponentFolderModal,
    closeComponentFolderModal,
    handleCreateComponentFolder,
    selectedFolder,
    selectFolderAsync,
    handleRenameComponentFolder,
    handleUpdateComponentFolderApiId,
    deleteComponentFolder,
    moveComponentsToFolder,
    removeComponentsFromFolders,
    addComponentsToFolder,
    updateFoldersByComponents,
  } = useComponentFolder({ refreshLibraryHistory });

  const sampleFolderIdRef = useRef(null);
  useEffect(() => {
    const sampleFolder = componentFolders.find((f) => f.isSample);
    if (sampleFolder) {
      sampleFolderIdRef.current = sampleFolder._id;
    }
  }, [componentFolders]);

  const openDraftingModal = () => {
    history.push({
      pathname: location.pathname,
      search: `?${DRAFTING_MODAL_QUERY_STRING}=true`,
    });
  };

  const hideDraftModal = () => {
    history.push({
      pathname: location.pathname,
    });
  };

  const showWsCompImportModal = () =>
    history.push({
      pathname: location.pathname,
      search: `?${IMPORT_MODAL_QUERY_STRING}=true`,
    });
  const hideWsCompImportModal = () => {
    history.push({ pathname: location.pathname, search: "" });
    if (firstImportedComponentId) {
      refreshComponents(firstImportedComponentId);
      componentMergeSuggestionState.regenerateSuggestions();
    }
  };

  const importModalOpen = new URLSearchParams(history.location.search).get(IMPORT_MODAL_QUERY_STRING);

  // Escape hatch ref: sometimes `selectedFolder` is accessed in stale contexts
  // due to the mess of state we've created for ourselves on this page. Rather than
  // rewriting the entire page, we've opted in the short term to use this ref as
  // an escape hatch to have an up-to-date value of `selectedFolder` in those contexts.
  // This is an abomination and you should avoid relying on it.
  const selectedFolderRef = useRef(null);
  useEffect(() => {
    selectedFolderRef.current = selectedFolder;
  }, [selectedFolder]);

  const allComponentsCache = useRef([]);
  const appendToAllComponentsCache = (component) => {
    allComponentsCache.current.push(component);
  };
  const updateAllComponentsCache = (components) => {
    allComponentsCache.current = components;
  };

  const scrollToWsComp = (id) => {
    scrollToElementId(id);
    // TODO: implement scrolling in side nav
  };

  /**
   * Updates relevant state variables in the right ways based on the current value of the `allComponentsCache`.
   * @param {*} currentFolderId id of the folder currently being viewed (`null` for root)
   */
  const updateStateFromComponentsCache = (currentFolderId) => {
    const componentsByFolder = {};
    allComponentsCache.current.forEach((component) => {
      const folderId = component.folder_id || null;
      componentsByFolder[folderId] ??= [];
      componentsByFolder[folderId].push(component);
    });

    // due to the way it's consumed downstream, `componentsByFolder` should not
    // include an entry for the root components
    const { null: _, ...rest } = componentsByFolder;

    const folderId = currentFolderId || null;
    setComps(componentsByFolder[folderId] || []);
    setComponentsByFolder(rest);
  };

  /**
   * DON'T CALL THIS FUNCTION DIRECTLY. IT SHOULD ONLY BE USED IN updateComponentsInMemory.
   * @deprecated
   */
  const _deleteComponentsInMemory = (componentIds) => {
    const componentIdsToDeleteSet = componentIds.reduce((s, id) => s.add(id), new Set());

    const filter = (c) => !componentIdsToDeleteSet.has(c._id);

    /**
     * Update the two in-memory caches.
     */

    updateAllComponentsCache(allComponentsCache.current.filter(filter));

    const someComponentSelected = componentIds.some(selectionState.componentIsSelected);
    if (someComponentSelected) {
      selectionActions.deselectAll();
    }
  };

  /**
   * When any change is made to components that currently exist in the component library,
   * this function should be called to ensure they updated in the appropriate in-memory
   * caches and state fixtures.
   *
   * @param {Partial<Component>[]} components
   * @param {String[]} deletedComponentIds
   */
  const updateComponentsInMemory = (components, deletedComponentIds = [], filterOverrides = {}) => {
    const existingComponentIds = new Set(allComponentsCache.current.map((c) => c._id));
    const newComponents = components.filter((c) => !existingComponentIds.has(c._id));

    const componentsToUpdateMap = components.reduce((s, c) => s.set(c._id, c), new Map());

    /**
     * Spread existing components into `componentsToUpdateMap` to permit
     * updating with a partial data set
     */
    allComponentsCache.current.forEach((c) => {
      const cToUpdate = componentsToUpdateMap.get(c._id);
      if (cToUpdate) {
        componentsToUpdateMap.set(c._id, { ...c, ...cToUpdate });
      }
    });

    const update = (c) => componentsToUpdateMap.get(c._id) || c;

    /**
     * Update the two in-memory caches, making sure to sort them in case names,
     * groups, or blocks have changed.
     */
    newComponents.forEach((c) => appendToAllComponentsCache(c));
    updateAllComponentsCache(sortComponents(allComponentsCache.current.map(update)));

    _deleteComponentsInMemory(deletedComponentIds, filterOverrides);

    updateStateFromComponentsCache(selectedFolder?._id);

    selectionActions.recomputeAnchor();
    selectionActions.refreshSelection();
  };

  const componentPostSaveCallback = (result) => {
    refreshLibraryHistory();

    const updatedComponent = {
      _id: result.firstInstance.ws_comp,
      instances: [result.firstInstance],
    };

    if (result.variants) updatedComponent.variants = result.variants;

    updateComponentsInMemory([updatedComponent]);
  };

  const componentPostRenameCallback = (componentId, componentName) => {
    refreshLibraryHistory();
    updateComponentsInMemory([{ _id: componentId, name: componentName }]);
  };

  const listComponents = async (params) => {
    const [request] = httpWsComp.loadPagedComponents({
      limit: COMPONENT_LIBRARY_COMPONENTS_PER_PAGE,
      ...params,
    });
    const { data } = await request;
    return data;
  };

  const countComponents = async (params) => {
    const [request] = httpWsComp.loadComponentCount(params);
    const { data } = await request;
    return data.count;
  };

  const countComponentTags = async (params) => {
    // always count tags for components independent of folder when at the root
    const p = { ...params };
    if (!p.folder_id) delete p.folder_id;

    const [request] = httpWsComp.loadComponentTags(p);
    const { data } = await request;
    return data;
  };

  const _updateComponentListAndCount = async (explicitArgs) => {
    const theFolderId =
      explicitArgs?.folderId !== undefined ? explicitArgs.folderId || null : selectedFolder?._id || null;

    const { shouldResetSearch, ...requestArgs } = explicitArgs;

    const componentLoadParameters = getComponentLoadParameters(
      {
        ...(!shouldResetSearch && {
          tags: Array.from(tagState.selected),
          status: statusFilter,
          search: query,
          variantId: variantFilter?.id,
          assignee,
          componentDeveloperId: devIDFilter,
        }),
        ...requestArgs,
      },
      theFolderId,
      sampleFolderIdRef.current
    );

    if (!componentLoadParameters.selectedComponentId) {
      handleSelectionRouteChange(null, theFolderId);
    }

    // page will be set to 0 each time a filter changes because the only place
    // that we should pass an explicit arg for the page is when manually changing
    // the pagination page
    const page = explicitArgs?.page ?? 0;

    const componentsLoadedPromise = listComponents({ page, ...componentLoadParameters }).then(async (result) => {
      const { components, page: resolvedPage, folderId: resolvedFolderId } = result;

      updateAllComponentsCache(components);
      updateStateFromComponentsCache(resolvedFolderId);

      setIsLoadingComponents(false);
      setIsLoading(false);
      if (resolvedFolderId !== theFolderId) setImmediate(() => handleSelectFolder(resolvedFolderId));

      if (queryParams.get("assignee") && !hasShownAssigneeBanner.current) {
        hasShownAssigneeBanner.current = true;
        setTimeout(() => showToast("Showing components assigned to you", 5000), 500);
      }

      setPageNumber(resolvedPage);
    });

    countComponents(componentLoadParameters).then((count) => setTotalItems(count));

    countComponentTags(componentLoadParameters).then((tags) => {
      setTagState((s) => {
        return {
          ...s,
          counts: tags.reduce((acc, tag) => {
            acc[tag._id] = tag.total;
            return acc;
          }, {}),
        };
      });
    });

    return componentsLoadedPromise;
  };

  const _updateComponentListAndCountDebounced = useDebouncedCallback(_updateComponentListAndCount, 200);

  const updateComponentListAndCount = async (explicitArgs) => {
    return explicitArgs.debounce
      ? _updateComponentListAndCountDebounced(explicitArgs)
      : _updateComponentListAndCount(explicitArgs);
  };

  const goToGroupPage = async (groupName) => {
    const [request, _] = httpWsComp.getPageForGroup({ groupName, folderId: selectedFolder?._id });
    const response = await request;
    const { page } = response.data;

    await updateComponentListAndCount({ page });

    scrollToElementId(groupName, undefined, { block: "start" });
  };

  const goToGroupBlockPage = async (groupName, blockName) => {
    const [request, _] = httpWsComp.getPageForGroupBlock({ groupName, blockName, folderId: selectedFolder?._id });
    const response = await request;
    const { page } = response.data;

    await updateComponentListAndCount({ page });

    const elementId = `${groupName}/${blockName}`;
    scrollToElementId(elementId, undefined, { block: "start" });
  };

  const onLoadRan = useRef(false);

  /**
   * Called once when the page initially loads:
   * - loads the first page of component library history
   * - loads and indexes all components in the component library
   * - computes and sets the initial page that should be shown
   * - selects the component specified in the current route location, if any
   */
  const onLoad = async () => {
    let folderId = params.folder_id;
    if (folderId) {
      const asyncSelectedFolder = await selectFolderAsync(folderId);
      if (!asyncSelectedFolder) {
        folderId = null;
      }
    }

    const selectedComponentParams = getSelectedComponentParams(params, location.search);
    await updateComponentListAndCount({
      debounced: true,
      folderId,
      ...selectedComponentParams,
    });

    fetchLibraryHistoryNewPage(folderId);
    fetchTagSuggestions();

    onLoadRan.current = true;

    if (selectedComponentParams.selectedComponentId) {
      handleSingleSelect(selectedComponentParams.selectedComponentId);
      return;
    }

    if (selectedComponentParams.selectedComponentIds?.length) {
      handleManualMultiSelect(selectedComponentParams.selectedComponentIds);
      return;
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const [selectionState, selectionActions] = useSelectionState(allComponentsCache);

  // Escape hatch ref: sometimes `selectionStateRef` is accessed in stale contexts
  // due to the mess of state we've created for ourselves on this page. Rather than
  // rewriting the entire page, we've opted in the short term to use this ref as
  // an escape hatch to have an up-to-date value of `selectedFolder` in those contexts.
  // This is an abomination and you should avoid relying on it.
  const selectionStateRef = useRef(selectionState);
  useEffect(() => {
    selectionStateRef.current = selectionState;
  }, [selectionState]);

  const toastHandler = useToastHandler();

  const queryParams = new URLSearchParams(window.location.search);

  const {
    assignee: [assignee, setAssignee],
    variant: [variantFilter, setVariantFilter],
    query: [query, setQuery],
    statusFilter: [statusFilter, setStatusFilter],
    devID: [devIDFilter, setDevIDFilter],
    tagState: [tagState, setTagState],
    resetSearch,
  } = useSearchState({ assignee: queryParams.get("assignee") });

  const [tagSuggestions, setTagSuggestions] = useState([]);

  const { getTokenSilently, user } = useAuthenticatedAuth();
  const [showCompError, setShowCompError] = useState(false);
  const [displayApiIds, setDisplayApiIds] = useLocalStorage(DISPLAY_DEVELOPER_IDS, false);

  const [confirmDetachAndDelete, setConfirmDetachAndDelete] = useState({
    visible: false,
    componentIds: null,
  });

  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState({
    visible: false,
    componentIds: null,
  });

  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);

  const [panelState, setPanelState] = useState(PANELS.comp_library.edit); //edit, hist, inst

  const [compLibraryHistory, setCompLibraryHistory] = useState({
    items: [],
    skip: 20,
    page: 0,
    limit: 20,
    allHistoryFetched: false,
  });
  const [loadingHistory, setLoadingHistory] = useState(true);

  const [commentState, setCommentState] = useState({
    isSelected: false,
    thread_id: null,
  });

  const [quickReplyCommentState, setQuickReplyCommentState] = useState({
    enabled: false,
  });

  const isSearching = useMemo(() => {
    return (
      query.length !== 0 ||
      tagState.selected.size > 0 ||
      statusFilter !== "Any" ||
      assignee !== null ||
      !!variantFilter ||
      devIDFilter !== null
    );
  }, [query, tagState, statusFilter, assignee, variantFilter, devIDFilter]);

  const searchFilterEnabled = query !== "" || tagState.selected.size > 0 || statusFilter !== "Any" || assignee !== null;

  useEffect(() => {
    async function sendWsCompsSubscribeMsg() {
      const subscribeToWsCompsMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_WS_COMPS_SUBSCRIPTION,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeToWsCompsMsg));
    }

    if (readyState === 1) {
      sendWsCompsSubscribeMsg();
      // Keep the websocket alive
    }
  }, [readyState]);

  const fetchComponentWithInstancesById = async (componentId) => {
    try {
      const { url } = API.ws_comp.get.populatedInstances;
      const {
        data: { component },
      } = await http.get(url(componentId));
      return component;
    } catch (error) {
      throw error;
    }
  };

  async function handleUpsertWsCompsComment(data) {
    const { commentThread } = data;
    try {
      const refreshedComponent = await fetchComponentWithInstancesById(data.componentId);
      updateComponentsInMemory([refreshedComponent], []);
      setCompLibraryHistory((prevState) => {
        // This is a resolve/unresolve comment thread change.
        const foundThread = prevState.items.find((item) => item.comment_thread_id?._id === commentThread._id);

        if (!foundThread) {
          return prevState;
        }
        foundThread.comment_thread_id.is_resolved = commentThread.is_resolved;
        if (foundThread.comment_thread_id.comments.length !== commentThread.comments.length) {
          foundThread.comment_thread_id.comments.push(commentThread.comments[commentThread.comments.length - 1]);
        }
        return prevState;
      });
    } catch (error) {
      logger.error("Error updating components in memory", {}, error);
    }
  }

  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);

    if (data.messageType === WEBSOCKET_EVENTS.UPSERT_WS_COMPS_COMMENT) {
      handleUpsertWsCompsComment(data);
      return;
    }

    if (data.messageType === WEBSOCKET_EVENTS.NOTIFICATION) {
      const { actorUserName, action, wsCompId, commentThreadId } = data.notifications.unreadNotifications[0];
      toastHandler.show({
        text: `${commentThreadId ? "💬 " : ""}${actorUserName} ${action}.`,
        action: "View",
        visibleFor: 5000,
        onClickAction: () => handleSingleSelect(wsCompId),
        onClickClose: () => toastHandler.hide(),
      });
    }

    if (data.messageType === WEBSOCKET_EVENTS.COMPONENT_IMPORT) {
    }
    if (
      data.messageType === WEBSOCKET_EVENTS.COMPONENT_IMPORT &&
      data?.data?.success &&
      data.data.data?.userId === user._id
    ) {
      const { firstImportedId } = data.data.data;
      setFirstImportedComponentId(firstImportedId);
    }
  }, [lastMessage]);

  useEffect(
    function reconcilePanelStateWithLocation() {
      if (location.state && location.state.commentThreadId && location.state.wsCompId) {
        setCommentState({
          isSelected: true,
          thread_id: location.state.commentThreadId,
        });
      }
      if (location?.hash === "#instances") {
        setPanelState(PANELS.comp_library.instances);
      }
      if (location.state && location.state.wsCompId && location.state.showInstances) {
        setPanelState(PANELS.comp_library.instances);
      }
    },
    [location.state]
  );

  const resetCommentState = () => {
    let state = location.state;
    // if was redirected from notification area or
    // workspace history, clean the react router state
    if (state && state.commentThreadId && state.wsCompId) {
      delete state.commentThreadId;
      delete state.wsCompId;
      history.replace({
        ...location,
        state,
      });
    }

    setCommentState({
      isSelected: false,
      thread_id: null,
    });
  };

  /**
   * Determines the correct changes to be applied to the current path
   * according to an id or array of ids that a selection has been
   * executed upon.
   */
  const handleSelectionRouteChange = (idOrIdsToSelect, folder_id = selectedFolder?._id) => {
    let basePath = "/components";
    if (folder_id) {
      basePath = `/components/folder/${folder_id || selectedFolder?._id}`;
    }

    const selectedId = selectionState.hasSingleSelection() && selectionState.selectedId;

    const isSelectingMultipleComponents = Array.isArray(idOrIdsToSelect);
    const isDeselecting = idOrIdsToSelect === selectedId;

    if (!(isSelectingMultipleComponents || isDeselecting) && idOrIdsToSelect) {
      history.replace({
        pathname: `${basePath}/${idOrIdsToSelect}`,
      });

      return;
    }

    const alreadyAtRootOfPage = COMPONENTS_ROOT_REGEX.test(location.pathname);
    if (alreadyAtRootOfPage && !basePath.includes("folder")) {
      return;
    }

    const pathname = basePath + window.location.search;
    if (pathname !== history.location.pathname) history.push({ pathname });
  };

  /**
   * Handles the selection/deselection process for a single component.
   * If that component is in a folder, we will also select the folder.
   */
  const handleSingleSelect = (
    wsComp,
    options = {
      /**
       * If set to `false`, the route will not be updated according to the new selection.
       * Defaults to `true`. (currently only used when selecting a component id derived
       * from the route on page load)
       */
      handleRouteChange: true,
      /**
       * If set to `false`, quick reply comments will not be disabled as part of the selection.
       * Defaults to `true`. (currently only used when navigating within the comment editor)
       */
      disableQuickReplyComments: true,
      /**
       * If set to `true`, the component will remain selected after this function is called
       * even if it was already selected previously; normal behavior is to toggle selection.
       * Defaults to `false`.
       */
      preventDeselection: false,
      /**
       * If set to `true`, function will not attempt to scroll to the selected component.
       * Defaults to `false`.
       */
      disableScrolling: false,
      shouldResetSearch: false,
    }
  ) => {
    const {
      handleRouteChange = true,
      disableQuickReplyComments = true,
      preventDeselection = false,
      disableScrolling = false,
      shouldResetSearch = false,
    } = options;
    checkDetailPanelChanges(async () => {
      if (disableQuickReplyComments) {
        setQuickReplyCommentState({ enabled: false });
      }

      // reset the commentState in case we're clicking on another
      // component, as we want default tab to be "EDIT"
      resetCommentState();

      const newSelectedId = typeof wsComp === "string" ? wsComp : wsComp?._id;
      let selectedComponent = allComponentsCache.current.find((c) => c._id === newSelectedId);

      // need to re-fetch components if the selected component isn't on the current page
      // or if the selected component is in a different folder (relevant when applying
      // filters from the root)
      if (!selectedComponent || Boolean(selectedComponent.folder_id) !== Boolean(selectedFolder?._id)) {
        setIsLoadingComponents(true);

        if (shouldResetSearch) {
          resetSearch();
        }

        await updateComponentListAndCount({
          selectedComponentId: newSelectedId,
          folderId: selectedComponent?.folder_id || undefined,
          shouldResetSearch,
        });

        selectedComponent = allComponentsCache.current.find((c) => c._id === newSelectedId);

        setIsLoadingComponents(false);

        if (!selectedComponent) {
          const msg = `Couldn't select component ${newSelectedId}.`;
          logger.error(msg, {}, new Error(msg));
          return;
        }
      }

      const isAlreadySelected = selectionState.hasSingleSelection() && selectionState.selectedId === newSelectedId;

      // this needs to be a non-strict equals, because we want it to be true even
      // when selectedFolder?._id is undefined
      if (selectedComponent.folder_id != selectedFolderRef.current?._id) {
        await selectFolderAsync(selectedComponent?.folder_id ?? null);
      }

      const shouldChangeRoute = handleRouteChange && !(isAlreadySelected && preventDeselection);
      if (shouldChangeRoute) {
        handleSelectionRouteChange(newSelectedId, selectedComponent.folder_id);
      }

      if (!preventDeselection && isAlreadySelected) {
        selectionActions.deselectAll();
        return;
      }

      selectionActions.selectComponent(newSelectedId);

      if (!disableScrolling) {
        scrollToWsComp(newSelectedId);
      }
    });
  };

  /*
   * Meta Select
   *
   * Toggles selection on the clicked component, and
   * moves the selection anchor to its location.
   */
  const handleMetaSelect = (component, componentIndex, selectedIdsSet) => {
    selectedIdsSet.has(component._id) ? selectedIdsSet.delete(component._id) : selectedIdsSet.add(component._id);

    selectionState.range.moveAnchor(componentIndex);
  };

  /*
   * Shift Select
   *
   * Select a range of components from the current anchor position to the position
   * of the clicked component.
   */
  const handleShiftSelect = (component, componentIndex, selectedIdsSet) => {
    const components = allComponentsCache.current;

    const deselectOldRange = () => {
      /*
       * We reset the head whenever the anchor is moved,
       * which is why this check is needed.
       */
      if (!selectionState.range.hasValidHead()) {
        return;
      }

      const low = Math.min(selectionState.range.anchor.current, selectionState.range.head.current);

      const high = Math.max(selectionState.range.anchor.current, selectionState.range.head.current);

      for (let i = low; i <= high; i++) {
        const component = components[i];

        // The anchor should always remain selected in a range selection.
        if (i !== selectionState.range.anchor.current) {
          selectedIdsSet.delete(component._id);
        }
      }
    };

    const selectNewRange = () => {
      const low = Math.min(selectionState.range.anchor.current, componentIndex);
      const high = Math.max(selectionState.range.anchor.current, componentIndex);

      for (let i = low; i <= high; i++) {
        const component = components[i];

        if (!selectedIdsSet.has(component._id)) selectedIdsSet.add(component._id);
      }
    };

    deselectOldRange();
    selectNewRange();

    selectionState.range.moveHead(componentIndex);
  };

  /**
   * Handles manually selecting multiple components via specification of a list
   * of component ids, e.g. from a query string parameter in the URL.
   */
  const handleManualMultiSelect = (componentIds) => {
    // noop when no components being selected
    if (!componentIds.length) {
      return;
    }

    // treat as single select if only one
    if (componentIds.length === 1) {
      handleSingleSelect(componentIds[0]);
      return;
    }

    selectionActions.selectComponents(componentIds);

    const firstComponentId = componentIds[0];
    selectionState.range.moveAnchorToComponent(firstComponentId);
    scrollToWsComp(firstComponentId);
  };

  /**
   * Handles the selection process for multiple components in response to a user action,
   * typically clicking with one or more modifier keys held down.
   */
  const handleMultiSelect = (componentId, { shiftKey, metaKey, ctrlKey }) => {
    let component;
    let componentIndex;
    for (let i = 0; i < allComponentsCache.current.length; i++) {
      const c = allComponentsCache.current[i];
      if (c._id !== componentId) {
        continue;
      }
      component = c;
      componentIndex = i;
      break;
    }

    let selectedIds = selectionState.hasSingleSelection()
      ? [selectionState.selectedId]
      : selectionState.hasMultiSelection()
      ? selectionState.selectedIds
      : [];

    const selectedIdsSet = selectedIds.reduce((s, id) => s.add(id), new Set());

    if (metaKey || ctrlKey) {
      handleMetaSelect(component, componentIndex, selectedIdsSet);
    } else if (shiftKey) {
      handleShiftSelect(component, componentIndex, selectedIdsSet);
    } else {
      throw new Error("Invalid call to handleMultiSelect");
    }

    selectedIds = Array.from(selectedIdsSet);
    handleSelectionRouteChange(selectedIds);
    selectionActions.selectComponents(selectedIds);
  };

  const onComponentClick = (e, componentId) => {
    const { shiftKey, metaKey, ctrlKey } = e;

    /**
     * Not multiselect if the user is holding down the shift or meta keys and clicks
     * a component when nothing is already selected.
     */
    const multiSelect = (shiftKey || metaKey || ctrlKey) && selectionState.hasSelection();

    /**
     * If the user is holding down the shift or meta keys and clicks
     * the only component that is already selected, de-select everything.
     */ if (
      multiSelect &&
      ((selectionState.hasMultiSelection() &&
        selectionState.selectedIds.length === 1 &&
        selectionState.selectedIds[0] === componentId) ||
        (selectionState.hasSingleSelection() && selectionState.selectedId === componentId))
    ) {
      selectionActions.deselectAll();
      return;
    }

    // If a component is clicked and the panel is not set to a component
    // panel state, set it to edit
    if (!COMPONENT_LIBRARY_PANEL_STATES.component[panelState]) {
      setPanelState(PANELS.comp_library.edit);
    }

    multiSelect ? handleMultiSelect(componentId, e) : handleSingleSelect(componentId);
  };

  /**
   * Called when multiple components are selected and edits are saved.
   */
  const handleComponentUpdates = (componentIds, data) => {
    const componentIdSet = componentIds.reduce((s, id) => s.add(id), new Set());

    const components = allComponentsCache.current
      .filter((c) => componentIdSet.has(c._id))
      .map((c) => {
        const [instance] = c.instances;
        const tagSet = instance.tags.reduce((s, t) => s.add(t), new Set());
        data.tagsAdded.forEach((t) => tagSet.add(t));
        data.tagsDeleted.forEach((t) => tagSet.delete(t));
        const tags = Array.from(tagSet.values());

        return {
          ...c,
          instances: c.instances.map((instance) => ({
            ...instance,
            assignee: data.assigneeId !== undefined ? data.assigneeId : instance.assignee,
            status: data.status || instance.status,
            tags,
            characterLimit: data.characterLimit,
          })),
        };
      });

    updateComponentsInMemory(components);

    refreshLibraryHistory();
    fetchTagSuggestions();
  };

  const isSelected = useCallback((id) => selectionState.componentIsSelected(id), [selectionState]);

  const shouldHighlightComponentInNavigation = useCallback(
    (id) => {
      const component = allComponentsCache.current.find((c) => c._id === id);
      if (typeof component?.page !== "number") {
        return false;
      }

      return component.page === pageNumber;
    },
    [pageNumber]
  );

  /**
   * Fetches array of ActivityItems for comp history
   * @param {string} folderId Folder to filter the history by
   * @param {integer} skip How many items to skip
   * @param {integer} limit How many items to take
   * @returns {{array, integer}} Array of ActivityItems and the number of items
   * including all comments that got condensed into one item
   */
  async function fetchComponentLibraryHistory(folderId, skip, limit) {
    const { url } = API.changes.get.ws_comp_library;
    const { data } = await http.get(url(folderId ?? "", skip, limit));

    return data;
  }

  const fetchLibraryHistoryNewPage = async (folderId = null) => {
    try {
      let page = compLibraryHistory.page;
      const skip = compLibraryHistory.skip * page;
      const limit = compLibraryHistory.limit;
      const folder_id = folderId || selectedFolder?._id;

      page++;

      const { activityHistory, changeCount } = await fetchComponentLibraryHistory(folder_id, skip, limit);

      setCompLibraryHistory({
        ...compLibraryHistory,
        items: [...compLibraryHistory.items, ...activityHistory],
        page: page,
        allHistoryFetched: changeCount < 20,
      });
      setLoadingHistory(false);
    } catch (error) {
      setLoadingHistory(false);
      console.error("in allcomps.jsx while fetching page of library history: " + error.message);
    }
  };

  /**
   * This function is something of an abomination. Even though we don't really need to,
   * this is called all over the place whenever any component data changes to completely reload
   * the entire component library -- this isn't a great experience.
   *
   * We should aim to improve the UX by manipulating data in state as needed when actions occur instead of dropping
   * a nuke on all of the loaded components every time some data changes.
   */
  const refreshComponentsWithCurrentFilters = async (selectedComponentId) => {
    await updateComponentListAndCount({ page: pageNumber, selectedComponentId });

    const selectedId = selectionState.hasSingleSelection() && selectionState.selectedId;
    if (selectedId) {
      selectionActions.selectComponent(selectedId);
      scrollToWsComp(selectedId);
    }

    setIsLoadingComponents(false);
    setIsLoading(false);
  };

  const addNewComponentGroup = () =>
    setNestedComps((prev) => [
      {
        group_name: "Untitled Frame",
        blocks: [],
        other_text: [],
        new_group: true,
      },
      ...prev,
    ]);

  /**
   * Gets comps of a given group
   * @param {object} group
   * @returns {array} array of comps
   */
  const getAllCompsFromGroup = (group) => {
    const comps = [];

    for (let text of group.other_text) {
      comps.push(text._id);
    }

    for (let block of group.blocks) {
      for (let text of block.block_text) {
        comps.push(text._id);
      }
    }

    return comps;
  };

  /**
   * Makes a request to /ws_comp/rename/group
   * @param {string} newGroupName
   * @param {array} compIds
   * @returns {boolean} return true or false if request was ok
   */
  const updateCompGroups = async (newGroupName, compIds) => {
    try {
      const { url, body } = API.ws_comp.put.renameGroup;
      await http.put(
        url,
        body({
          compIds,
          newGroupName,
        })
      );
      return true;
    } catch (error) {
      console.error(`Error updating group comp name: ${error}`);
      return false;
    }
  };

  /**
   * Takes the group comps and updates them in bulk
   * @param {object} group
   */
  const updateExistingComponentGroup = async (group) => {
    const compIds = getAllCompsFromGroup(group);
    await updateCompGroups(group.group_name, compIds);
    await refreshComponentsWithCurrentFilters();
  };

  const updateNewComponentGroup = (name) => {
    // In order to not trigger prop-drilling and useEffects,
    // we will create a new array from nestedComps
    const newNestedComps = Array.from(nestedComps);

    // Locate the index
    const index = findIndexOfNewComponentGroup(newNestedComps);

    // update the name
    newNestedComps[index].group_name = name;

    // and ship it as a new nestedComps to trigger all useEffects
    // down the line
    setNestedComps(newNestedComps);
  };

  const findIndexOfNewComponentGroup = (newNestedComps) => {
    return newNestedComps.findIndex((nestedComp) => nestedComp.new_group === true);
  };

  const updateEmptyComponentGroup = (oldName, newName) => {
    // rename old comp
    const newComps = comps.map((comp) => {
      const newComp = { ...comp };
      if (newComp.name.includes(`${oldName}/`)) {
        newComp.name = newComp.name.replace(oldName, newName);
      }

      return newComp;
    });

    setComps(newComps);
  };

  useEffect(() => {
    if (showCompError) {
      setTimeout(function () {
        setShowCompError(false);
      }, 2000);
    }
  }, [showCompError]);

  /**
   * This executes immediately when the user starts typing into the
   * search input.
   */
  const handleInitialQueryChange = () => {
    setIsLoadingComponents(true);
  };

  /**
   * This executes at a debounced interval after the user starts typing
   * into the search input.
   */
  const handleQueryChange = async (search) => {
    setQuery(search);

    // the handleQueryChange function is already debounced
    await updateComponentListAndCount({ search });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();
  };

  const handleSetAssignee = async (assignee) => {
    setAssignee(assignee);
    setIsLoadingComponents(true);

    await updateComponentListAndCount({ assignee });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();
  };

  const handleSetDevIDFilter = async (newDevID) => {
    setDevIDFilter(newDevID);
    setIsLoadingComponents(true);

    await updateComponentListAndCount({ componentDeveloperId: newDevID });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();
  };

  const handleStatusChange = async (statusFilter) => {
    setStatusFilter(statusFilter);
    setIsLoadingComponents(true);

    await updateComponentListAndCount({ status: statusFilter });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();
  };

  const handleVariantChange = async (variantFilter) => {
    setVariantFilter(variantFilter);
    setIsLoadingComponents(true);

    await updateComponentListAndCount({ variantId: variantFilter?.id });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();
  };

  const handleSelectTag = async (tagName) => {
    setIsLoadingComponents(true);

    const selected = new Set(tagState.selected);
    selected.has(tagName) ? selected.delete(tagName) : selected.add(tagName);

    const tags = Array.from(selected.values());

    await updateComponentListAndCount({ tags });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();

    setTagState((s) => ({ ...s, selected }));
  };

  const handleClearSelectedTags = async () => {
    await updateComponentListAndCount({ tags: [] });

    selectionActions.deselectAll();
    scrollNavToTop();
    scrollLibraryToTop();

    setTagState((s) => ({ ...s, selected: new Set() }));
  };

  const onFolderClick = async (folder_id) => {
    setIsLoadingComponents(true);
    updateComponentListAndCount({ folderId: folder_id });
    handleSelectFolder(folder_id);
    selectionActions.deselectAll();
    scrollLibraryToTop();
    scrollNavToTop();
  };

  useEffect(() => {
    const folderId = params.folder_id?.split("?")[0];
    if (history.location.pathname === "/components" && selectedFolder?._id) {
      onFolderClick(null);
    } else if (folderId !== selectedFolder?._id) {
      onFolderClick(folderId);
    }
  }, [history.location.pathname]);

  const handleSelectFolder = async (folder_id) => {
    // select the folder in the ui
    const newSelectedFolder = await selectFolderAsync(folder_id);
    // load the correct library history
    resetLibraryHistory(newSelectedFolder?._id);
    // resolve the correct route
    handleSelectionRouteChange([], folder_id);
  };

  const handleCreateBlock = (groupName) => {
    let newBlockName = "Untitled Block";

    const group = nestedComps.find((g) => g.group_name === groupName);
    const blockNames = new Set(group.blocks.map((b) => b.block_name));

    // quick fix for a unique block name
    if (blockNames.has(newBlockName)) {
      newBlockName = `Untitled Block ${blockNames.size + 1}`;
    }

    if (selectionState.hasSelection()) {
      selectionActions.deselectAll();
      handleSelectionRouteChange(null);
    }

    setNestedComps((nestedComps) =>
      nestedComps.map((g) =>
        g.group_name === groupName
          ? {
              ...g,
              blocks: [
                ...g.blocks,
                {
                  block_name: newBlockName,
                  block_text: [],
                },
              ],
            }
          : g
      )
    );
  };

  const handleDeleteBlock = async (blockName, ws_comp_ids) => {
    setIsLoadingComponents(true);

    const { url, body } = API.ws_comp.delete.block;
    await http.delete(url, {
      data: body({
        ids: ws_comp_ids,
      }),
    });

    await updateComponentListAndCount({ page: pageNumber });
  };

  /**
   * Deletes a folder from the database and updates the state of the component library
   * @param {string} folder_id the id of the folder to delete
   */
  const handleDeleteComponentFolder = async (folder_id) => {
    setIsLoadingComponents(true);

    await deleteComponentFolder(folder_id);
    await updateComponentListAndCount({ folderId: null });

    handleSelectFolder(null);

    setShowDeleteFolderModal(false);
  };

  const handleRenameComp = async ({ _id, name, oldName }) => {
    try {
      const { url, body } = API.ws_comp.post.rename;
      const { data } = await http.post(
        url(_id),
        body({
          name,
          oldName,
        })
      );
      return data;
    } catch (error) {
      console.error(`Error updating comp name: ${{ _id, name, oldName }}`);
      return null;
    }
  };

  const handleRenameBlock = async (args) => {
    const { groupName, blockNameOld, blockNameNew } = args;

    /**
     * Map: component id -> { name, apiID }
     */
    const componentNameUpdateMap = new Map();

    const apiIdGenerator = createApiIdGenerator({
      config: apiIdGenerationConfig,
    });

    /**
     * Populate the update map by finding components in the cache that
     * are in the specified group in the specified block.
     */
    allComponentsCache.current.forEach((component) => {
      apiIdGenerator.add(component.apiID);

      const [componentGroupName, componentBlockName, ...restOfName] = component.name.split("/");

      const inGroup = componentGroupName === groupName;
      const inBlock = componentBlockName === blockNameOld;

      if (inGroup && inBlock) {
        const remaining = restOfName.length ? `/${restOfName.join("/")}` : "";
        const newName = `${componentGroupName}/${blockNameNew}${remaining}`;
        const newSortKey = generateSortKey(newName, selectedFolder?._id || null);
        const newApiId = apiIdGenerateOnRename ? apiIdGenerator.generate(newName, "component_") : component.apiID;

        componentNameUpdateMap.set(component._id, {
          name: newName,
          apiID: newApiId,
          sortKey: newSortKey,
        });
      }
    });

    const update = (c) => {
      const { name, apiID } = componentNameUpdateMap.get(c._id) || {};
      if (!name) {
        return c;
      }

      return {
        ...c,
        name,
        apiID,
      };
    };

    /**
     * If there are components that need to be updated, then update the cache
     * and state values used for rendering.
     */
    if (componentNameUpdateMap.size) {
      updateAllComponentsCache(allComponentsCache.current.map(update));

      setComps((components) => components.map(update));

      selectionActions.refreshSelection();
    }

    setNestedComps((nestedComps) =>
      nestedComps.map((g) =>
        g.group_name === groupName
          ? {
              ...g,
              blocks: g.blocks.map((b) => (b.block_name === blockNameOld ? { ...b, block_name: blockNameNew } : b)),
            }
          : g
      )
    );

    try {
      if (componentNameUpdateMap.size) {
        const { url, body } = API.ws_comp.put.renameBlocks;
        await http.put(
          url,
          body({
            componentEntries: Array.from(componentNameUpdateMap.entries()),
          })
        );
      }
      return { success: true };
    } catch (error) {
      console.error("Error renaming block", error);
      return { success: false };
    }
  };

  const handleCreateDraft = async ({
    name,
    text,
    assignee,
    richText,
    variables,
    notes,
    tags,
    folderId,
    type,
    characterLimit,
  }) => {
    const filter = getComponentLoadParameters(
      {
        tags: Array.from(tagState.selected),
        status: statusFilter,
        search: query,
        variantId: variantFilter?.id,
        assignee,
        componentDeveloperId: devIDFilter,
      },
      folderId,
      sampleFolderIdRef.current
    );

    try {
      const { url, body } = API.ws_comp.post.createDraft;
      const { data } = await http.post(
        url,
        body({
          notes,
          name,
          tags,
          text,
          assignee,
          richText,
          variables,
          folderId,
          type,
          characterLimit,
          filter,
        })
      );

      const newComponentIsOnCurrentPage = data.page === pageNumber;

      const goToNewComponent = () => {
        if (newComponentIsOnCurrentPage) {
          updateComponentsInMemory([newComp]);
        }

        if (folderId && folderId !== selectedFolder?._id) {
          setIsLoadingComponents(true);
          handleSelectFolder(folderId);
        }

        handleSingleSelect(newComp._id);
      };

      const newComp = data.comp;

      if (newComponentIsOnCurrentPage) {
        goToNewComponent();
        return true;
      }

      toastHandler.show({
        text: "New component added on another page",
        action: "View",
        visibleFor: 20_000,
        onClickAction: goToNewComponent,
        onClickClose: () => toastHandler.hide(),
      });

      return true;
    } catch (err) {
      console.error("error creating draft", err);
      return false;
    }
  };

  const handleDisplayApiIds = (show) => {
    setDisplayApiIds(show);
  };

  useEffect(() => {
    if (!isLoading && comps) {
      setNestedComps(nestComponents(comps, COMPONENTS_ROOT_REGEX));
    }
  }, [comps, isLoading]);

  // show notification toasts for 5s
  useEffect(() => {
    if (notification)
      setTimeout(() => {
        setNotification(null);
      }, 5000);
  }, [notification]);

  /**
   * Used to update the comp comments badge in the component
   * @param {string} ws_comp_id ID of the comp's comment count we want to update
   * @param {string} commentThreadId ID of the single comment_thread
   * @param {('add'|'remove')} action action to take
   */
  const updateCompResultComments = (ws_comp_id, commentThreadId, action) => {
    const foundComp = comps.find((comp) => comp._id === ws_comp_id);
    const otherComps = Array.from(comps).filter((comp) => comp._id !== ws_comp_id);
    if (action === "remove") {
      foundComp.comment_threads = foundComp.comment_threads.filter((thread) => thread !== commentThreadId);
    }
    if (action === "add") {
      foundComp.comment_threads.push(commentThreadId);
    }

    // Sort comps so we don't break the order
    const sortedComps = otherComps.concat([foundComp]).sort((a, b) => {
      const nameA = a.name.split("/").reverse()[0];
      const nameB = b.name.split("/").reverse()[0];

      if (nameA > nameB) return 1;
      if (nameB > nameA) return -1;
      return 0;
    });
    setComps(sortedComps);
  };

  // optionally pass an id to select and scroll to
  // after the components have been refreshed
  const refreshComponents = async (targetId = null) => {
    await refreshComponentsWithCurrentFilters(targetId);
    refreshLibraryHistory();

    if (targetId) {
      const component = allComponentsCache.current.find((c) => c._id === targetId);
      if (component?.folder_id) {
        handleSelectFolder(component.folder_id);
      }
    }
  };

  const fetchTagSuggestions = async () => {
    try {
      const { url } = API.workspace.get.tags;
      const { data: tags } = await http.get(url);

      setTagSuggestions(tags.filter((tag) => tag).map((tag, i) => ({ id: i, name: tag.toUpperCase() })));
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const someComponentsExist = allComponentsCache.current?.length > 0;
  const showNewGroupButton = nestedComps && someComponentsExist && !searchFilterEnabled;

  const deleteComp = async (ws_comp_id) => {
    try {
      const { url } = API.ws_comp.delete.ws_comp;
      await http.delete(url(ws_comp_id));
      updateComponentsInMemory([], [ws_comp_id]);
      handleSelectionRouteChange(null);
      refreshLibraryHistory();
    } catch (error) {
      console.error(error);
    }
  };

  const detachAndDeleteAll = async (componentIds) => {
    setIsLoadingComponents(true);

    const { url, body } = API.ws_comp.delete.detachAndDeleteAll;
    await http.delete(url, { data: body({ componentIds }) });

    // remove components from folder in frontend; already handled in backend by the
    // detachAndDeleteAll endpoint
    removeComponentsFromFolders(componentIds);

    await updateComponentListAndCount({ page: pageNumber });

    refreshLibraryHistory();
  };

  const onConfirmDetachAndDeleteAll = () => {
    detachAndDeleteAll(confirmDetachAndDelete.componentIds);
    setConfirmDetachAndDelete({ visible: false, componentIds: null });
  };

  const onMoveToFolder = async (data) => {
    setIsLoadingComponents(true);

    const { componentIds } = showMoveToFolderModal;

    let folderId;
    if (data.type === "new") {
      folderId = await handleCreateComponentFolder(data.name, componentIds);
      removeComponentsFromFolders(componentIds);
      addComponentsToFolder(folderId, componentIds);
    } else {
      if (data.type === "none") {
        folderId = null;
      } else if (data.type === "existing") {
        folderId = data.folderId;
      } else {
        throw new Error("Unsupported move to folder data type: " + data.type);
      }

      await moveComponentsToFolder(folderId, componentIds);
    }

    await updateComponentListAndCount({ folderId });

    await handleSelectFolder(folderId);

    setShowMoveToFolderModal({ visible: false, componentIds: null });
  };

  const onHideMoveToFolderModal = () => setShowMoveToFolderModal({ visible: false, componentIds: null });

  const handleChangeItemClick = (changeItem) => {
    if (
      [
        ENTRY_TYPES.COMPONENT_FOLDER_CREATION,
        ENTRY_TYPES.COMPONENT_FOLDER_API_ID_EDIT,
        ENTRY_TYPES.COMPONENT_FOLDER_UPDATE,
      ].includes(changeItem.entry_type)
    ) {
      const folderId = changeItem.data.component_folder_id;
      if (!folderId) return;

      setIsLoadingComponents(true);

      handleSelectFolder(folderId);
      updateComponentListAndCount({ folderId });
      return;
    }

    if (changeItem.entry_type === ENTRY_TYPES.COMPONENTS_MERGED) {
      const selectedComponentId = changeItem.data.targetComponent._id;
      if (!selectedComponentId) return;

      setIsLoadingComponents(true);

      updateComponentListAndCount({ selectedComponentId });
      handleSingleSelect(selectedComponentId);
      return;
    }
  };

  const [instanceDocs, setInstanceDocs] = useState(new Map());
  const fetchInstancesForCurrentSelection = async () => {
    const selectedId = selectionState.hasSingleSelection() && selectionState.selectedId;
    if (!selectedId) {
      setInstanceDocs(new Map());
      return;
    }

    try {
      const { url } = API.ws_comp.get.instances;
      const { data: instances } = await http.get(url(selectedId));
      if (!instances) return;

      // map of projects to instances of the selected component
      const uniqueDocs = new Map();
      instances
        .filter((instance) => instance.doc_ID)
        .forEach((instance) => {
          // each instance has a doc_ID attribue that's actually an object containing the doc_id and the doc_name
          const { _id, doc_name } = instance.doc_ID;
          const { page_id, page_name } = instance;
          const pageNameToRender = page_id === DRAFT_PAGE_ID ? DRAFT_PAGE_NAME : page_name;
          if (!uniqueDocs.has(_id)) {
            // each project has a mapping of pages to instances
            const compInstances = new Map();
            compInstances.set(pageNameToRender, [instance]);
            uniqueDocs.set(_id, {
              doc_id: _id,
              doc_name,
              count: 1,
              isSample: allComponentsCache.current.find((c) => c._id === selectedId)?.isSample,
              compInstances,
            });
          } else {
            // check if we have page in our project to pages mapping
            if (!uniqueDocs.get(_id).compInstances.has(pageNameToRender)) {
              uniqueDocs.get(_id).compInstances.set(pageNameToRender, [instance]);
            } else {
              uniqueDocs.get(_id).compInstances.get(pageNameToRender).push(instance);
            }
          }
        });
      setInstanceDocs(uniqueDocs);
    } catch (e) {
      console.info("in compdetail.jsx: ", e.message);
    }
  };

  const [showMergeSuccessToast, setShowMergeSuccessToast] = useState(false);
  const componentMergeSuggestionState = useComponentMergeSuggestionPanel({
    onSelectComponent: (componentId) => {
      handleSingleSelect(componentId, {
        preventDeselection: true,
        disableScrolling: true,
      });
      scrollToElementId(componentId);
    },
    onMergeSuggestion: (mergedComponent, deletedComponentIds) => {
      // TODO: is this the correct change to make?
      // !mergedComponentIsArray is used to prevent reloading issues when bulk merging
      const mergedComponentIsArray = Array.isArray(mergedComponent);
      !mergedComponentIsArray && updateComponentsInMemory(componentsInMemoryToBeUpdated, deletedComponentIds);

      // re-fetch instances
      fetchInstancesForCurrentSelection();
      // ensure that the target component is selected (should already be selected
      // due to the `onSelectComponent` logic above)
      !mergedComponentIsArray &&
        handleSingleSelect(mergedComponent._id, {
          preventDeselection: true,
          disableScrolling: true,
        });
      // show the edit panel
      setPanelState(PANELS.comp_library.edit);

      setShowMergeSuccessToast(true);
      setTimeout(() => setShowMergeSuccessToast(false), 3000);
    },
  });

  useEffect(
    function keyboardInputHandler() {
      const KEYS = {
        Escape: 27,
        A: 65,
      };

      const keyboardHandler = (event) => {
        const { keyCode, metaKey, ctrlKey } = event;

        const inputIsFocused = document.activeElement.tagName === "INPUT";

        const contentEditableIsFocused =
          document.activeElement.tagName === "DIV" && document.activeElement.contentEditable;

        const isEditingContent = inputIsFocused || contentEditableIsFocused;

        if (keyCode === KEYS.Escape) {
          const hasMergeSuggestionSelected =
            panelState === PANELS.comp_library.merge_suggestions &&
            componentMergeSuggestionState.hasSelectedSuggestion();

          if (hasMergeSuggestionSelected) {
            componentMergeSuggestionState.clearSelectedSuggestion();
            selectionActions.deselectAll();
            if (selectedFolder) {
              history.push(`/components/folder/${selectedFolder._id}`);
            } else {
              history.push("/components");
            }
            return;
          }

          return checkDetailPanelChanges(() => {
            setQuickReplyCommentState({ enabled: false });
            selectionActions.deselectAll();
            setConfirmDetachAndDelete({ visible: false, componentIds: null });
            setShowMoveToFolderModal({ visible: false, componentIds: null });
            if (selectedFolder) {
              history.push(`/components/folder/${selectedFolder._id}`);
            } else {
              history.push("/components");
            }
          });
        }

        if (keyCode === KEYS.A) {
          if ((metaKey || ctrlKey) && !isEditingContent) {
            checkDetailPanelChanges(() => {
              event.preventDefault();
              setQuickReplyCommentState({ enabled: false });
              selectionActions.selectComponents(allComponentsCache.current.map(({ _id }) => _id));
            });
          }
        }
      };

      document.addEventListener("keydown", keyboardHandler, false);

      return () => {
        document.removeEventListener("keydown", keyboardHandler, false);
      };
    },
    [unsavedDetailChangesExist, selectedFolder, componentMergeSuggestionState, panelState]
  );

  useEffect(function fetchTagSuggestionsOnMount() {
    fetchTagSuggestions();
  }, []);

  const selectedCompIds = selectionState.hasSingleSelection()
    ? [selectionState.selectedId]
    : selectionState.hasMultiSelection()
    ? selectionState.selectedIds
    : [];

  const selectedComps = useMemo(
    () =>
      selectedCompIds
        .map((id) => {
          const comp = comps.find((c) => c._id === id);
          if (!comp) return null;
          return {
            _id: comp._id,
            name: comp.name,
          };
        })
        .filter(Boolean),
    [selectedCompIds, comps]
  );

  const { activeDragData, isDragging, handleDragStart, handleDragEnd, mouseSensor, customCollisionDetection } =
    useCompLibraryDragAndDrop({
      updateComponentsInMemory,
      refreshLibraryHistory,
      moveComponentsToFolder,
      showToast: (data) => toastHandler.show(data),
      comps,
      selectComp: handleSingleSelect,
      selectedComps,
    });

  return (
    <UserPermissionProvider resourceId={params?.folder_id || selectedFolder?._id} resourceType={"component_folder"}>
      <LibraryNavContextProvider
        treeState={libraryTreeState}
        dispatch={dispatchLibraryNavState}
        selectionState={selectionState}
        selectComponents={selectionActions?.selectComponents}
        handleSingleSelect={handleSingleSelect}
        allComponentsCache={allComponentsCache}
        selectedFolder={selectedFolder}
        goToGroupPage={goToGroupPage}
        goToGroupBlockPage={goToGroupBlockPage}
      >
        <ComponentLibraryWebsocketHandler />

        <div className={style.componentLibraryWrapper}>
          {notification && <NotificationToast notification={notification} />}
          <OverlayToast text="🚫 Component not found" hidden={!showCompError} />
          <OverlayToast text="✅ Components merged successfully" hidden={!showMergeSuccessToast} />

          {toastHandler.state.visible && (
            <Toast {...toastHandler.state.props} onClickClose={() => toastHandler.hide()}>
              {toastHandler.state.props.text}
            </Toast>
          )}

          <OverlayToast {...overlayToastProps} />
          {isDragging && workspaceInfo.config.components.apiIdGenerateOnRename && (
            <OverlayToast
              text={<span>The Developer ID of this component will also be updated according to your settings.</span>}
            />
          )}
          <WorkspaceComponentContext.Provider
            value={{
              selectionState,
              refreshComponents,
              fetchData: refreshComponentsWithCurrentFilters,
              quickReplyCommentState: [quickReplyCommentState, setQuickReplyCommentState],
              handleSingleSelect,
              updateComponentsInMemory,
            }}
          >
            <WsCompTitleBar
              displayApiIds={displayApiIds}
              handleDisplayApiIds={handleDisplayApiIds}
              allComponentsCache={allComponentsCache}
              handleCreateDraft={handleCreateDraft}
              selectedFolder={selectedFolder}
              integrations={workspaceInfo?.integrations}
              onHomeClick={() => onFolderClick(null)}
              openDraftingModal={openDraftingModal}
              showWsCompImportModal={showWsCompImportModal}
            />
            <div className={style.docContainer}>
              <CompLibraryNav
                comps={comps}
                componentsByFolder={componentsByFolder}
                nestedComps={nestedCompsForNav}
                paginationEnabled={paginationEnabled}
                onComponentClick={onComponentClick}
                isSelected={isSelected}
                isCompDisplayed={shouldHighlightComponentInNavigation}
                selectedFolder={selectedFolder}
                onFolderClick={onFolderClick}
                componentFolders={componentFolders}
                searchFilterEnabled={searchFilterEnabled}
                isSearching={isSearching}
                totalComponents={totalItems}
                items={libraryTreeState}
              />
              <DndContext
                collisionDetection={customCollisionDetection}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                sensors={[mouseSensor]}
              >
                <CompResults
                  onPaginationPageChange={onPaginationPageChange}
                  totalPages={totalPages}
                  totalItems={totalItems}
                  pageNumber={pageNumber}
                  tagState={tagState}
                  displayApiIds={displayApiIds}
                  comps={comps}
                  someComponentsExist={someComponentsExist}
                  nestedComps={nestedComps}
                  isSelected={isSelected}
                  query={query}
                  setQuery={setQuery}
                  devIDFilter={devIDFilter}
                  setDevIDFilter={handleSetDevIDFilter}
                  assignee={assignee}
                  handleSetAssignee={handleSetAssignee}
                  queryDebounce={SEARCH_QUERY_DEBOUNCE}
                  handleQueryChange={handleQueryChange}
                  handleInitialQueryChange={handleInitialQueryChange}
                  handleSelectTag={handleSelectTag}
                  handleClearSelectedTags={handleClearSelectedTags}
                  handleCreateBlock={handleCreateBlock}
                  handleRenameBlock={handleRenameBlock}
                  handleDeleteBlock={handleDeleteBlock}
                  handleRenameComp={handleRenameComp}
                  moveComponentsToFolder={moveComponentsToFolder}
                  refreshComponentsWithCurrentFilters={refreshComponentsWithCurrentFilters}
                  variantFilter={variantFilter}
                  setVariantFilter={handleVariantChange}
                  status={statusFilter}
                  chooseStatus={handleStatusChange}
                  onComponentClick={onComponentClick}
                  selectComp={handleSingleSelect}
                  setCommentState={setCommentState}
                  setPanelState={setPanelState}
                  addNewComponentGroup={addNewComponentGroup}
                  updateNewComponentGroup={updateNewComponentGroup}
                  updateEmptyComponentGroup={updateEmptyComponentGroup}
                  updateExistingComponentGroup={updateExistingComponentGroup}
                  isLoading={isLoadingComponents}
                  showNewGroupButton={showNewGroupButton}
                  componentFolders={componentFolders}
                  showComponentFolderModal={showComponentFolderModal}
                  openComponentFolderModal={openComponentFolderModal}
                  closeComponentFolderModal={closeComponentFolderModal}
                  handleCreateComponentFolder={handleCreateComponentFolder}
                  onFolderClick={onFolderClick}
                  handleRenameComponentFolder={handleRenameComponentFolder}
                  handleUpdateComponentFolderApiId={handleUpdateComponentFolderApiId}
                  selectedFolder={selectedFolder}
                  showDeleteFolderModal={() => setShowDeleteFolderModal(true)}
                  isSearching={isSearching}
                  componentsByFolder={componentsByFolder}
                  openDraftingModal={openDraftingModal}
                  openWsCompImportModal={showWsCompImportModal}
                  selectedComps={selectedComps}
                />
                <DragOverlay dropAnimation={null}>
                  {activeDragData && <DragPreview dragComps={activeDragData.selectedComps} />}
                </DragOverlay>
              </DndContext>
              {(!selectionState.hasSelection() || selectionState.hasSingleSelection()) && (
                <ErrorBoundary componentName="CompDetail" type="Selection">
                  <CompDetail
                    componentPostRenameCallback={componentPostRenameCallback}
                    componentPostSaveCallback={componentPostSaveCallback}
                    instanceDocs={instanceDocs}
                    fetchInstances={fetchInstancesForCurrentSelection}
                    handleChangeItemClick={handleChangeItemClick}
                    componentMergeSuggestionState={componentMergeSuggestionState}
                    displayApiIds={true}
                    selectedId={selectionState.selectedId}
                    selectedWsComp={selectionState.selectedComponent || {}}
                    selectComp={handleSingleSelect}
                    selectedComp={selectionState.selectedComponent?.instances?.[0] || {}}
                    selectedCompName={selectionState.selectedComponent?.name || null}
                    fetchAllComps={refreshComponentsWithCurrentFilters}
                    allComponentsCache={allComponentsCache}
                    setSelectedId={selectionActions.selectComponent}
                    updateCompResultComments={updateCompResultComments}
                    commentState={commentState}
                    setCommentState={setCommentState}
                    setPanelState={setPanelState}
                    panelState={panelState}
                    compLibraryHistory={compLibraryHistory}
                    loadingHistory={loadingHistory}
                    allHistoryFetched={compLibraryHistory.allHistoryFetched}
                    fetchLibraryHistoryNewPage={fetchLibraryHistoryNewPage}
                    refreshLibraryHistory={refreshLibraryHistory}
                    setShowCompError={setShowCompError}
                    setLoadingHistory={setLoadingHistory}
                    // this callback is required, but since multi-select
                    // doesn't yet exist in the component library,
                    // we no-op
                    unselectAll={() => {
                      selectionActions.deselectAll();
                    }}
                    tagSuggestions={tagSuggestions}
                    fetchWorkspaceTags={fetchTagSuggestions}
                    handleMoveToFolder={(componentId) =>
                      setShowMoveToFolderModal({
                        visible: true,
                        componentIds: [componentId],
                      })
                    }
                    deleteComp={() => deleteComp(selectionState.selectedId)}
                    detachAllAndDelete={() => detachAndDeleteAll([selectionState.selectedId])}
                    mergeComponents={() => setShowComponentModal(true)}
                    selectedFolder={selectedFolder}
                  />
                </ErrorBoundary>
              )}
              {selectionState.hasMultiSelection() && (
                <EditMultiWsComp
                  actions={{
                    detachAndDeleteAll: (componentIds) =>
                      setConfirmDetachAndDelete({
                        visible: true,
                        componentIds,
                      }),
                    moveToFolder: (componentIds) => setShowMoveToFolderModal({ visible: true, componentIds }),
                    mergeComponents: (_componentIds) => setShowComponentModal(true),
                  }}
                  tagSuggestions={tagSuggestions}
                  selectionState={selectionState}
                  handleComponentUpdates={handleComponentUpdates}
                />
              )}
            </div>
            {confirmDetachAndDelete.visible && (
              <ConfirmationModal
                title={`Are you sure you want to delete ${confirmDetachAndDelete.componentIds.length} components?`}
                body="You will no longer be able to retrieve these components after deleting them. Each instance of these components will be detached and will no longer be linked to a component."
                actionPrimary={`Yes, delete ${confirmDetachAndDelete.componentIds.length} components`}
                actionSecondary="Cancel"
                onPrimary={onConfirmDetachAndDeleteAll}
                onSecondary={() => setConfirmDetachAndDelete(false)}
              />
            )}
            {showComponentModal && (
              <ComponentSelectionModal
                action="Merge"
                title="Merge components"
                warning={`Merging components will merge the component${
                  selectionState.hasMultiSelection() ? "s" : ""
                } selected in the library into the chosen component.`}
                error={mergeComponentError}
                placeholder={`Merge selected component${selectionState.hasMultiSelection() ? "s" : ""} into…`}
                onHide={closeComponentSelectionModal}
                onSubmit={(selectedCompId) => mergeSelectedComponents(selectedCompId)}
              />
            )}

            {showMoveToFolderModal.visible && (
              <MoveToFolderModal
                selectedFolderId={selectedFolder?._id || null}
                onMove={onMoveToFolder}
                onHide={onHideMoveToFolderModal}
                numberOfComponents={showMoveToFolderModal.componentIds.length}
              />
            )}
            {showDeleteFolderModal && selectedFolder && (
              <ConfirmationModal
                title={
                  <span>
                    Delete folder <span style={{ color: "#838487" }}>{selectedFolder.name}</span>?{" "}
                  </span>
                }
                body={
                  <p>
                    Deleting this folder will move{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {selectedFolder.component_ids.length}{" "}
                      {selectedFolder.component_ids.length === 1 ? "component" : "components"}
                    </span>{" "}
                    into your main component library.
                  </p>
                }
                actionPrimary="Delete"
                actionSecondary="Cancel"
                onPrimary={() => handleDeleteComponentFolder(selectedFolder._id)}
                onSecondary={() => setShowDeleteFolderModal(false)}
              />
            )}
          </WorkspaceComponentContext.Provider>
        </div>
      </LibraryNavContextProvider>
      {draftingModalOpen && (
        <DraftCompModal
          allComponentsCache={allComponentsCache}
          onHide={hideDraftModal}
          tagSuggestions={tagSuggestions}
          handleCreateDraft={handleCreateDraft}
          selectedFolder={selectedFolder}
        />
      )}

      {importModalOpen && (
        <CsvImportProvider data={[]}>
          <WsCompImportModal onHide={hideWsCompImportModal} selectedFolder={selectedFolder} />
        </CsvImportProvider>
      )}
    </UserPermissionProvider>
  );
};

/**
 * Sorts components on the FE based on their searchKey. We need to pass numeric: true to match the sorting happening on the BE
 * @param {{ sortKey: string }[]} components
 * @returns
 */
function sortComponents(components) {
  return components.sort((a, b) => a.sortKey.localeCompare(b.sortKey, undefined, { numeric: true }));
}

export default AllComps;
