type IEncoding = "text/csv;" | "text/xml;" | "text/plain;" | "data:application/json;";

export function createAndDownloadFile(name: string, data: string, encoding: IEncoding) {
  const dataBlob = new Blob([data], { type: `${encoding}charset=utf-8;` });
  const dataUri = URL.createObjectURL(dataBlob);

  const linkElement = document.createElement("a");

  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", name);
  linkElement.click();
}
