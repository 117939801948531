import ObjectID from "bson-objectid";
import React from "react";
import { IFVariable } from "../shared/types/Variable";

export const onAddMapEntry = (setUpdatedVariable: React.Dispatch<React.SetStateAction<IFVariable>>) => {
  // in order to add multiple empty entries, we can't just add an empty string
  // as a key, because that would overwrite the existing empty string key

  setUpdatedVariable((prev: any) => {
    const emptyKey = `__empty-${new ObjectID()}__`;

    return {
      ...prev,
      data: { ...prev.data, [emptyKey]: "" },
    };
  });
};

export const onRemoveMapEntry = (key: string, setUpdatedVariable: React.Dispatch<React.SetStateAction<IFVariable>>) => {
  setUpdatedVariable((prev: any) => ({
    ...prev,
    data: Object.keys(prev.data).reduce((acc, k) => {
      if (k !== key) {
        acc[k] = prev.data[k];
      }
      return acc;
    }, {} as any),
  }));
};

export const onMapEntryChange = (
  key: string,
  newEntry: { key: string; value: string },
  setUpdatedVariable: React.Dispatch<React.SetStateAction<IFVariable>>
) => {
  setUpdatedVariable((prev: any) => {
    let keyToSet = newEntry.key;

    if (key !== newEntry.key && Object.keys(prev.data).includes(newEntry.key)) {
      // if the new key already exists, don't overwrite the old entry
      keyToSet = `${newEntry.key}__duplicate-${new ObjectID()}__`;
    }

    return {
      ...prev,
      data: Object.keys(prev.data).reduce((acc, k) => {
        if (k === key) {
          acc[keyToSet] = newEntry.value;
        } else {
          acc[k] = prev.data[k];
        }
        return acc;
      }, {} as any),
    };
  });
};
