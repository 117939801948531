import { createVariableNode } from "../../common/richText/VariableNodeCreate";
import style from "./VariableNodeExtension.module.css";

const Variable = createVariableNode(
  {
    addCommands() {
      return {
        addVariable:
          (
            variableId: string,
            name: string,
            text: string | number | undefined | null,
            variableType: "number" | "string" | "hyperlink" | "list" | "map"
          ) =>
          ({ commands }) =>
            commands.insertContent(
              `<span data-type="${this.name}" 
                data-variable-id="${variableId}"
                data-name="${name}"
                data-text="${text}"
                data-variable-type="${variableType}"
             />`
            ),
      };
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isVariable = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
              if (node.type.name === this.name) {
                isVariable = true;
                tr.insertText("", pos, pos + node.nodeSize);

                return false;
              }
            });

            return isVariable;
          }),
      };
    },

    addAttributes() {
      return {
        variableId: {
          parseHTML: (element) => element.getAttribute("data-variable-id"),
          renderHTML: (attributes) => {
            return {
              "data-variable-id": attributes.variableId,
            };
          },
        },
        name: {
          parseHTML: (element) => element.getAttribute("data-name"),
          renderHTML: (attributes) => {
            return {
              "data-name": attributes.name,
            };
          },
        },
        text: {
          parseHTML: (element) => element.getAttribute("data-text"),
          renderHTML: (attributes) => {
            return {
              "data-text": attributes.text,
            };
          },
        },
        variableType: {
          parseHTML: (element) => element.getAttribute("data-variable-type"),
          renderHTML: (attributes) => {
            return {
              "data-variable-type": attributes.variableType,
            };
          },
        },
      };
    },
  },
  style.variableSpan
);

export default Variable;
