import { BillingContext } from "@/store/billingContext";
import { useWorkspace } from "@/store/workspaceContext";
import { useContext } from "react";
import { RoleInfo } from ".";

export default function useSeatsPanel() {
  const { seatsState } = useContext(BillingContext);
  const { users } = useWorkspace();

  if (seatsState.loading) {
    return {
      roles: [],
      loading: true,
      error: false,
    };
  } else if (!seatsState.success) {
    return {
      roles: [],
      loading: false,
      error: true,
    };
  }

  const { numEditorsUsed, numCommentersUsed } = users.reduce(
    (acc, user) => {
      if (user.role === "commenter") {
        acc.numCommentersUsed++;
      } else {
        acc.numEditorsUsed++;
      }
      return acc;
    },
    { numEditorsUsed: 0, numCommentersUsed: 0 }
  );

  const {
    renewalDateEditorYearly,
    renewalDateCommenterYearly,
    renewalDateEditorMonthly,
    renewalDateCommenterMonthly,
    numYearlyEditorSeats,
    numMonthlyEditorSeats,
    numYearlyCommenterSeats,
    numMonthlyCommenterSeats,
  } = seatsState.data;

  const numEditorSubs = numYearlyEditorSeats + numMonthlyEditorSeats;
  const numCommenterSubs = numYearlyCommenterSeats + numMonthlyCommenterSeats;

  const editorRenewals: RoleInfo["renewals"] = [];
  const commenterRenewals: RoleInfo["renewals"] = [];

  if (renewalDateEditorYearly) {
    editorRenewals.push({
      frequency: "yearly",
      seats: numYearlyEditorSeats,
      date: new Date(renewalDateEditorYearly.date * 1000),
    });
  }

  if (renewalDateEditorMonthly) {
    editorRenewals.push({
      frequency: "monthly",
      seats: numMonthlyEditorSeats,
      date: new Date(renewalDateEditorMonthly.date * 1000),
    });
  }

  if (renewalDateCommenterYearly) {
    commenterRenewals.push({
      frequency: "yearly",
      seats: numYearlyCommenterSeats,
      date: new Date(renewalDateCommenterYearly.date * 1000),
    });
  }

  if (renewalDateCommenterMonthly) {
    commenterRenewals.push({
      frequency: "monthly",
      seats: numMonthlyCommenterSeats,
      date: new Date(renewalDateCommenterMonthly.date * 1000),
    });
  }

  const roles = [
    {
      name: "Editor",
      usedSeats: numEditorsUsed,
      totalSeats: numEditorSubs,
      renewals: editorRenewals,
    },
    {
      name: "Commenter",
      usedSeats: numCommentersUsed,
      totalSeats: numCommenterSubs,
      renewals: commenterRenewals,
    },
  ];

  return {
    roles,
    loading: seatsState.loading,
    error: false,
  };
}
