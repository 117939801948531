import { textItemFamilyAtom } from "@/stores/Project";
import {
  draggableItemsForTextItemAtom,
  onTextItemCancelEditActionAtom,
  onTextItemClickActionAtomFamily,
  onTextItemTextChangeActionAtom,
  selectionTypeAtom,
  textItemIsSelectedAtom,
} from "@/stores/ProjectSelection";
import { hiddenFieldsAtom } from "@/stores/TextItemMetadata";
import { usersByIdAtom } from "@/stores/Workspace";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import TextItem, { TextItemComponentState, TextItemEditState } from "@ds/molecules/TextItem";
import { RESET } from "@shared/frontend/stores/symbols";
import { BASE_VARIANT_ID } from "@shared/types/FigmaVariables";
import { ITipTapRichText } from "@shared/types/TextItem";
import { useAtomValue, useSetAtom } from "jotai";

import useAutoScroll from "@/hooks/useAutoScroll";
import {
  inlineEditingAtom,
  isTextItemEditingInlineAtomFamily,
  reorderTextItemsActionAtom,
  updateTextActionAtom,
} from "@/stores/Editing";
import { designPreviewToggledAtom } from "@/stores/ProjectFiltering";
import { updateTextItemVariantActionAtom } from "@/stores/Variants";
import { ActualComponentStatus } from "@shared/types/TextItem";
import classNames from "classnames";
import { useAtomCallback } from "jotai/utils";
import { useCallback, useMemo } from "react";
import { DragStartEvent } from "react-aria";
import { z } from "zod";
import { textItemListScrollRefAtom } from "../TextItemList";
import style from "./style.module.css";

interface ITextItemWrapperProps {
  textItemId: string;
  highlightedPhrase?: string;
  activeVariantId: string;
  activeVariantName: string;
  className?: string;
}

function TextItemWrapper(props: ITextItemWrapperProps) {
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const usersById = useAtomValue(usersByIdAtom);
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.textItemId));
  const isEditingInline = useAtomValue(isTextItemEditingInlineAtomFamily(props.textItemId));
  const onTextItemTextChange = useSetAtom(onTextItemTextChangeActionAtom);
  const onTextItemCancelEdit = useSetAtom(onTextItemCancelEditActionAtom);
  const updateText = useSetAtom(updateTextActionAtom);
  const updateTextItemVariant = useSetAtom(updateTextItemVariantActionAtom);
  const setInlineEditingState = useSetAtom(inlineEditingAtom);
  const designPreviewToggled = useAtomValue(designPreviewToggledAtom);

  // hidden metadata fields
  const hiddenFields = useAtomValue(hiddenFieldsAtom);
  const isStatusHidden = hiddenFields?.status.isHidden;
  const isAssignedHidden = hiddenFields?.assigned.isHidden;
  const isTagsHidden = hiddenFields?.tags.isHidden;
  const isNotesHidden = hiddenFields?.notes.isHidden;
  const isCommentsHidden = hiddenFields?.comments.isHidden;
  const isInstancesHidden = hiddenFields?.instances.isHidden;

  const blockVariantSelected = useMemo(() => props.activeVariantId !== BASE_VARIANT_ID, [props.activeVariantId]);
  const textItemVariant = useMemo(() => {
    if (blockVariantSelected) {
      return textItem.variants.find((variant) => variant.variantId === props.activeVariantId);
    }
    return undefined;
  }, [blockVariantSelected, props.activeVariantId, textItem.variants]);

  // Flag for whether the text item has the selected block variant attached
  const variantNotPresent = useMemo(
    () => blockVariantSelected && !textItemVariant,
    [blockVariantSelected, textItemVariant]
  );

  const placeholderText = useMemo(
    () => (blockVariantSelected ? textItem.text : "Edit text"),
    [blockVariantSelected, textItem.text]
  );

  // Metadata to display on text item
  let richText: ITipTapRichText | undefined = useMemo(() => {
    let value = textItem.rich_text;
    if (!blockVariantSelected) return value;
    if (textItemVariant) {
      return textItemVariant.rich_text;
    }
    return undefined;
  }, [blockVariantSelected, textItem.rich_text, textItemVariant]);
  const status: ActualComponentStatus | undefined = useMemo(() => {
    if (!blockVariantSelected) return textItem.status;
    if (textItemVariant) {
      return textItemVariant.status;
    }
    return undefined;
  }, [blockVariantSelected, textItem.status, textItemVariant]);

  // Compute variant statuses for base text item
  const variantStatuses: ActualComponentStatus[] = useMemo(
    () => textItem.variants.map((variant) => variant.status ?? "NONE"),
    [textItem.variants]
  );

  // Hide the variants badge only if a block variant is selected, and the variant is present
  // Or if it's hidden by project "Hide fields" setting
  // We want to show the variants badge with "Variant not present" text, if the variant is not present
  const isVariantsHidden = useMemo(
    () => (blockVariantSelected && !variantNotPresent) || hiddenFields?.variants.isHidden,
    [blockVariantSelected, hiddenFields?.variants.isHidden, variantNotPresent]
  );

  // comments badge
  const numComments = useMemo(
    () => textItem.comment_threads.reduce((acc, thread) => acc + thread.comments.length, 0),
    [textItem.comment_threads]
  );

  const getDisplayState = useCallback(
    function getDisplayState(): TextItemComponentState {
      if (isSelected) return "focus";
      return "default";
    },
    [isSelected]
  );

  const getEditState = useCallback(
    function getEditState(): TextItemEditState {
      if (isEditingInline) return "editing";
      if (isSelected) return "editable";
      return "none";
    },
    [isEditingInline, isSelected]
  );

  const handleSaveInlineEdit = useCallback(
    function (richText: ITipTapRichText) {
      // Handle saving variant text edit if a block variant is selected
      if (blockVariantSelected) {
        updateTextItemVariant({ textItemId: textItem._id, update: { richText, variantId: props.activeVariantId } });
      } else {
        // Else, update base text item
        updateText(textItem._id, richText);
      }
      setInlineEditingState(RESET);
    },
    [
      blockVariantSelected,
      props.activeVariantId,
      textItem._id,
      updateText,
      updateTextItemVariant,
      setInlineEditingState,
    ]
  );

  const onClickCancel = useCallback(
    function _onClickCancel() {
      onTextItemCancelEdit(props.textItemId);
    },
    [onTextItemCancelEdit, props.textItemId]
  );

  const onTextChange = useCallback(
    function _onTextChange(richText: ITipTapRichText) {
      onTextItemTextChange({ id: props.textItemId, richText });
    },
    [onTextItemTextChange, props.textItemId]
  );

  // HANDLE DRAG AND DROP

  return (
    <TextItem
      defaultValue={richText}
      status={!isStatusHidden ? status : undefined}
      variantStatuses={!variantNotPresent ? variantStatuses : undefined}
      numComments={!isCommentsHidden ? numComments : undefined}
      assignee={!isAssignedHidden ? usersById[textItem.assignee ?? ""] : undefined}
      instanceCount={!isInstancesHidden ? textItem.integrations?.figmaV2?.instances?.length ?? 0 : undefined}
      tags={!isTagsHidden ? textItem.tags : undefined}
      notes={!isNotesHidden ? textItem.notes : undefined}
      state={getDisplayState()}
      editState={getEditState()}
      onClickCancel={onClickCancel}
      onClickSave={handleSaveInlineEdit}
      placeholder={placeholderText}
      level={designPreviewToggled ? "compact" : "default"}
      onTextChange={onTextChange}
      highlightedPhrase={props.highlightedPhrase}
      className={props.className}
      metadataCanWrap={designPreviewToggled}
      hideVariantsBadge={isVariantsHidden}
      variantNotPresent={variantNotPresent}
    />
  );
}

function TextItemDragWrapper(props: { children: React.ReactNode; textItemId: string }) {
  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForTextItemAtom(props.textItemId)), [props.textItemId])
  );
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.textItemId));
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.textItemId));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const scrollProps = useAutoScroll(textItemScrollContainer);
  const getSelectionType = useAtomCallback((get) => get(selectionTypeAtom));

  const handleDrop = useCallback(
    function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
      reorderTextItemsAction([
        {
          textItemIds: textItemIds,
          blockId: textItem.blockId,
          before: dragLocation === "above" ? props.textItemId : undefined,
          after: dragLocation === "below" ? props.textItemId : undefined,
        },
      ]);
    },
    [props.textItemId, reorderTextItemsAction, textItem.blockId]
  );

  /**
   * If the current text item is not selected, ensure that it is the only text item selected.
   */
  const handleDragStart = useCallback(
    function handleDragStart(e: DragStartEvent) {
      if (!isSelected) {
        onTextItemClick({ richText: textItem.rich_text, e });
      }
    },
    [isSelected, onTextItemClick, textItem.rich_text]
  );

  return (
    <DragAndDroppable
      className={style.dragWrapper}
      getDraggableItems={getDraggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={handleDragStart}
      {...scrollProps}
    >
      {(dragAndDropProps) => {
        const selectionType = getSelectionType();

        const dropping = dragAndDropProps.isDropTarget && selectionType === "text";
        const droppingAbove = dropping && dragAndDropProps.dragLocation === "above";
        const droppingBelow = dropping && dragAndDropProps.dragLocation === "below";

        return (
          <>
            {droppingAbove && (
              <div className={classNames(style.dropIndicatorWrapper, style.above)}>
                <div className={style.dropIndicator} />
              </div>
            )}
            <div
              className={classNames(style.draggableContent, {
                [style.droppingAbove]: droppingAbove,
                [style.droppingBelow]: droppingBelow,
              })}
              onClick={(e) => onTextItemClick({ richText: textItem.rich_text, e })}
            >
              {props.children}
            </div>
            {droppingBelow && (
              <div className={classNames(style.dropIndicatorWrapper, style.below)}>
                <div className={style.dropIndicator} />
              </div>
            )}
          </>
        );
      }}
    </DragAndDroppable>
  );
}

function TextItemWithDrag(props: ITextItemWrapperProps) {
  return (
    <TextItemDragWrapper {...props}>
      <TextItemWrapper {...props} />
    </TextItemDragWrapper>
  );
}

export default TextItemWithDrag;
