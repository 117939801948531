import ResizableWidthContainer from "@/components/ResizableWidthContainer/ResizableWidthContainer";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DragIcon from "@mui/icons-material/DragIndicator";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { removeFigmaArtifactsFromGroupName } from "@shared/lib/groups";
import { default as classNames, default as classnames } from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Dropdown from "react-bootstrap/Dropdown";
import { scroller } from "react-scroll";
import ReactTooltip from "react-tooltip";
import AutoAwesomeIcon from "../../../../../shared/frontend/AutoAwesomeIcon";
import PushPinIcon from "../../../../components/PushPinIcon";
import { UnsavedChangesContext } from "../../../../store/unsavedChangesContext";
import { isGroupLinked } from "../../state/types";
import { UNSELECT_ALL_PROPS } from "../../state/unselectAll";
import { ALL_PAGE, ALL_PAGE_ID, DRAFTED_GROUPS_PAGE, DRAFTED_GROUPS_PAGE_ID } from "../../state/usePageState";
import { useProjectContext } from "../../state/useProjectState";
import style from "./style.module.css";

const UNTITLED_GROUP = "Untitled Frame";
const isUntitledGroup = (name) => name === UNTITLED_GROUP || !name;

const GroupNavigation = (props) => {
  const {
    groupState,
    reorderFrames,
    setFramePinned,
    selectedPage,
    setSelectedPage,
    unselectAll,
    toggleFrameModal,
    framePage,
    setFramePage,
    handleOpenFramePage,
    paginationFrameLimit,
    filteredFrames,
    setSuggestedCompId,
    resyncLoading,
  } = props;

  const {
    setupSuggestions,
    isShowingSetupSuggestionsFlow,
    setupSuggestionsForAllGroups,
    doc: [project],
  } = useProjectContext();

  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const [frameSearchOpen, setFrameSearchOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [resyncStarted, setResyncStarted] = useState(false);
  const [shouldShowResizeCursor, setShouldShowResizeCursor] = useState(false);

  useEffect(() => {
    if (resyncLoading) {
      setResyncStarted(true);
    }
  }, [resyncStarted, resyncLoading]);

  const paginationEnabled = Object.keys(filteredFrames).length >= paginationFrameLimit;
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const checkInputFocus = () => {
    if (query.length === 0) {
      setFrameSearchOpen(false);
    }
  };

  const choosePage = (page) => {
    checkDetailPanelChanges(() => {
      setSelectedPage(page);
      setFramePage(0);
      unselectAll();
    });
  };

  const onShouldShowCursorChange = (shouldShowCursor) => {
    setShouldShowResizeCursor(shouldShowCursor);
  };

  function onDragEnd(result) {
    //this onDragEnd is only for reordering Frames
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }

    reorderFrames({
      groupId: draggableId,
      startIndex: source.index,
      endIndex: destination.index,
    });
    return;
  }

  const toggleFrameSearchOpen = () => {
    setFrameSearchOpen(!frameSearchOpen);
  };

  const pinnedFrames = selectedPage
    ? groupState.groups.filter((group) => {
        let isOnPage = selectedPage?.id === group.integrations.figma.page_id;

        if (!isGroupLinked(group)) {
          isOnPage = selectedPage?.id === DRAFTED_GROUPS_PAGE_ID;
        }

        if (selectedPage?.id === ALL_PAGE_ID) {
          isOnPage = true;
        }

        const isInQuery = !query || group.name?.toLowerCase().includes(query.toLowerCase());

        return group?.pinned && isInQuery && isOnPage && filteredFrames[group._id];
      })
    : [];

  const hasPinnedFrames = pinnedFrames.length > 0;
  const selectPaginatedFramePage = (frameId) => {
    if (shouldShowResizeCursor) return;
    handleOpenFramePage(frameId);
    // Custome implementation of <Element> to handle case where element doesn't yet
    // exist in the rendered content, happens when switching to a comp on a different page
    let timeOut = 0;
    if (!document.getElementById(frameId)) {
      timeOut = 50;
    }
    setTimeout(() => {
      scroller.scrollTo(frameId, {
        duration: 500,
        smooth: true,
        containerId: "projectContainer",
        offset: -100,
      });
    }, timeOut);
  };

  const isFrameDisplayed = (groupId, frameIndex) => {
    if (!paginationEnabled || !filteredFrames[groupId]) return false;
    const currFrameRangeStart = framePage * paginationFrameLimit;
    const currFrameRangeEnd = currFrameRangeStart + paginationFrameLimit;
    return currFrameRangeStart <= frameIndex && frameIndex < currFrameRangeEnd;
  };

  const onPinFrame = (e, frameId, frameIndex, isPinned) => {
    e.stopPropagation();
    if (isPinned) {
      selectPaginatedFramePage(frameId);
    } else {
      selectPaginatedFramePage(frameId);
    }
    setFramePinned(frameId, isPinned);
  };

  const projectIsLinkedOrHasGroups = useMemo(() => {
    return (
      !!project?.integrations.figma.file_id ||
      groupState.groups.some((group) => {
        const isEmptyDraftGroup =
          group.name === "" && (group.comps.length === 0 || (group.comps.length === 1 && group.comps[0].text === ""));

        return !group.unsaved && !isEmptyDraftGroup;
      })
    );
  }, [groupState.groups]);

  const hasSelectablePages = useMemo(
    () => project?.integrations.figma.selected_pages?.length > 0,
    [project?.integrations.figma.selected_pages]
  );

  return (
    <ResizableWidthContainer
      className={classNames(style.sidebar)}
      onClick={() => setSuggestedCompId(null)}
      onShouldShowCursorChange={onShouldShowCursorChange}
      persistKey="ditto-ProjectGroupNavigationSidebar"
    >
      <div className={style.titleSection}>
        <div className={style.topRow}>
          <div className={style.nameAndPageSelect}>
            <div className={style.option}>Frames</div>
            {isEditEnabled && (
              <div className={!resyncLoading ? style.manageLink : style.manageLinkDisabled}>
                {projectIsLinkedOrHasGroups && (
                  <a
                    data-tip
                    data-for="manage"
                    onClick={(e) => {
                      if (!resyncLoading) toggleFrameModal(e);
                    }}
                  >
                    Add or Remove
                  </a>
                )}
                {resyncLoading && (
                  <ReactTooltip
                    id={`manage`}
                    place="bottom"
                    effect="solid"
                    className={style.dittoTooltip}
                    getContent={() => `Disabled while your project is resyncing`}
                  ></ReactTooltip>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={style.pageSection} {...UNSELECT_ALL_PROPS}>
          <div className={style.option}>Page:</div>
          <Dropdown className={style.pageDropdown}>
            <Dropdown.Toggle>
              <div className={style.pageDropdownText}>
                <span>{selectedPage ? selectedPage.name : ""}</span>
                <KeyboardArrowDownIcon className={style.icon} />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={style.pageDropdownMenu}>
              <Dropdown.Item
                className={classnames(
                  style.pageName,
                  selectedPage?.id === ALL_PAGE.id ? style.selected : style.notSelected
                )}
                onClick={() => choosePage(ALL_PAGE)}
              >
                {ALL_PAGE.name}
              </Dropdown.Item>
              <Dropdown.Item
                className={classnames(
                  style.pageName,
                  selectedPage?.id === DRAFTED_GROUPS_PAGE.id ? style.selected : style.notSelected
                )}
                onClick={() => choosePage(DRAFTED_GROUPS_PAGE)}
              >
                {DRAFTED_GROUPS_PAGE.name}
              </Dropdown.Item>
              {hasSelectablePages && <hr className={style.draftedGroupPageDivider} />}
              {project?.integrations.figma.selected_pages?.map((rawPage, index) => {
                const pageObj = {
                  id: rawPage.figma_id,
                  name: rawPage.name,
                };
                return (
                  <Dropdown.Item
                    key={index}
                    className={classnames({
                      [style.pageName]: true,
                      [style.selected]: selectedPage && pageObj.id === selectedPage.id,
                      [style.notSelected]: selectedPage && pageObj.id !== selectedPage.id,
                    })}
                    onClick={() => choosePage(pageObj)}
                  >
                    {pageObj.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div
          className={classnames({
            [style.searchSection]: true,
            [style.topBorder]: !hasSelectablePages,
          })}
        >
          <div className={style.searchBox}>
            <SearchIcon onClick={() => toggleFrameSearchOpen()} className={style.icon} />
            <input
              autoFocus
              onBlur={() => checkInputFocus()}
              className={classnames(style.input)}
              placeholder="Search frames"
              value={query}
              onChange={handleQueryChange}
            />
          </div>
        </div>
      </div>
      {hasPinnedFrames &&
        (() => {
          let adjustedIndex = 0;
          let unpinnedIndex = pinnedFrames.length - 1;
          return (
            <div className={style.pinnedFrames}>
              {groupState.groups.map((group) => {
                const {
                  name,
                  _id,
                  integrations: {
                    figma: { page_id, frame_id },
                  },
                  pinned,
                } = group;

                let isOnPage = selectedPage?.id === page_id;

                if (!isGroupLinked(group)) {
                  isOnPage = selectedPage?.id === DRAFTED_GROUPS_PAGE_ID;
                }

                if (selectedPage?.id === ALL_PAGE_ID) {
                  isOnPage = true;
                }

                const isInQuery = !query || name?.toLowerCase().includes(query.toLowerCase());

                if (pinned && isInQuery && isOnPage && filteredFrames[_id]) {
                  const tempIndex = adjustedIndex++;
                  const realIndex = unpinnedIndex + tempIndex;

                  const formattedGroupName = removeFigmaArtifactsFromGroupName(name);

                  const isSetupSuggestionsFrame =
                    isShowingSetupSuggestionsFlow && frame_id === setupSuggestions?.groupId;

                  return (
                    <div
                      data-testid="frame-sidebar-item"
                      key={_id}
                      onClick={() => selectPaginatedFramePage(_id)}
                      className={classnames({
                        [style.frameItemWrapper]: true,
                        [style.setupSuggestionsFrame]: isSetupSuggestionsFrame,
                      })}
                    >
                      {isFrameDisplayed(_id, tempIndex) && <div className={style.frameDisplayed} />}
                      <div
                        className={classnames({
                          [style.frameItem]: true,
                          [style.pinnedFrame]: true,
                        })}
                      >
                        <div
                          className={classNames({
                            [style.frameName]: true,
                            [style.untitledGroupName]: isUntitledGroup(formattedGroupName),
                            [style.showResizeCursor]: shouldShowResizeCursor,
                          })}
                        >
                          {isGroupLinked(group) && <CropFreeIcon className={style.icon} />}
                          {formattedGroupName}
                        </div>
                        <div className={style.pinnedIcons}>
                          <PushPinIcon
                            className={style.icon}
                            onClick={(e) => onPinFrame(e, _id, realIndex - tempIndex, false)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                } else if (selectedPage && selectedPage.id === page_id) {
                  unpinnedIndex++;
                }
              })}
            </div>
          );
        })()}

      <div className={style.frameList} {...UNSELECT_ALL_PROPS}>
        <div className={style.regFrames}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="frameNav" type="FRAME">
              {(provided) => {
                let adjustedIndex = pinnedFrames.length;

                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedPage &&
                      groupState.groups.map((group, index) => {
                        const {
                          name,
                          _id,
                          pinned,
                          integrations: {
                            figma: { page_id, frame_id },
                          },
                          unsaved,
                        } = group;

                        let isOnPage = isGroupLinked(group)
                          ? selectedPage?.id === page_id
                          : selectedPage?.id === DRAFTED_GROUPS_PAGE_ID;

                        if (selectedPage?.id === ALL_PAGE_ID) {
                          isOnPage = true;
                        }

                        const isInQuery = !query || name?.toLowerCase().includes(query.toLowerCase());

                        const formattedGroupName = removeFigmaArtifactsFromGroupName(name);

                        const showGroup = isInQuery && isOnPage && !pinned && filteredFrames[_id];

                        if (showGroup) {
                          const tempIndex = adjustedIndex++;

                          const isSetupSuggestionsFrame =
                            isShowingSetupSuggestionsFlow && frame_id === setupSuggestions?.groupId;

                          const suggestionsForGroup = setupSuggestionsForAllGroups[_id];

                          const suggestionsCount = suggestionsForGroup
                            ? Object.values(suggestionsForGroup.blockSuggestions).reduce((acc, cur) => {
                                return (acc += cur.textItems.length);
                              }, 0) +
                              suggestionsForGroup.hideSuggestions.length +
                              suggestionsForGroup.wsComponentSuggestions.length
                            : 0;

                          return (
                            <Draggable
                              draggableId={_id}
                              index={index}
                              key={_id}
                              isDragDisabled={!isEditEnabled || query.length > 0 || shouldShowResizeCursor}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    data-testid="frame-sidebar-item"
                                    className={classnames({
                                      [style.frameItemWrapper]: true,
                                      [style.setupSuggestionsFrame]: isSetupSuggestionsFrame,
                                    })}
                                    onClick={() => selectPaginatedFramePage(_id)}
                                  >
                                    {isFrameDisplayed(_id, tempIndex) && <div className={style.frameDisplayed} />}
                                    <div
                                      className={classnames({
                                        [style.frameItem]: true,
                                        [style.pinnedFrame]: pinned,
                                        [style.showResizeCursor]: shouldShowResizeCursor,
                                      })}
                                      key={_id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {isEditEnabled && query.length == 0 && (
                                        <div className={style.hoverIcons}>
                                          <DragIcon
                                            className={classnames(style.icon, style.drag, {
                                              [style.showResizeCursor]: shouldShowResizeCursor,
                                            })}
                                          />
                                        </div>
                                      )}

                                      <div
                                        className={classNames({
                                          [style.frameName]: true,
                                          [style.untitledGroupName]: isUntitledGroup(formattedGroupName),
                                          [style.showResizeCursor]: shouldShowResizeCursor,
                                        })}
                                      >
                                        {isEditEnabled && isGroupLinked(group) && (
                                          <CropFreeIcon
                                            className={classNames(style.icon, {
                                              [style.showResizeCursor]: shouldShowResizeCursor,
                                            })}
                                          />
                                        )}
                                        <span>{formattedGroupName || UNTITLED_GROUP}</span>
                                      </div>
                                      {suggestionsCount > 0 && !query && isEditEnabled && isGroupLinked(group) && (
                                        <div className={style.suggestionsBadge}>
                                          <AutoAwesomeIcon fill="#584d28" />
                                          {suggestionsCount}
                                        </div>
                                      )}
                                      {isEditEnabled && !unsaved && (
                                        <div className={classnames([style.biggerClickArea], [style.hoverIcons])}>
                                          <PushPinIcon
                                            className={classnames([
                                              style.icon,
                                              style.pinIcon,
                                              {
                                                [style.showResizeCursor]: shouldShowResizeCursor,
                                              },
                                            ])}
                                            onClick={(e) => onPinFrame(e, _id, tempIndex, true)}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        }
                      })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </ResizableWidthContainer>
  );
};

export default GroupNavigation;
