import DoneIcon from "@mui/icons-material/Done";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import classnames from "classnames";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import spinner from "../../assets/small-spinner.gif";
import CommentOnlyBadge from "../CommentOnlyBadge";
import DeleteFolderModal from "../DeleteFolderModal";
import LockedFolderIcon from "../icons/LockedFolderIcon";
import style from "./style.module.css";

interface FolderNameProps {
  folder: {
    _id: string;
    name: string;
    invite_only: boolean;
  };
  uniqueFolderNames: string[];
  handleRenameFolder: (folderId: string, updatedName: string) => void;
  handleDeleteFolder: (folderId: string) => void;
  isEditEnabled: boolean;
}
const FolderName = ({
  folder,
  uniqueFolderNames,
  handleRenameFolder,
  handleDeleteFolder,
  isEditEnabled,
}: FolderNameProps) => {
  const { _id: folderId, name } = folder;

  const [nameInput, setNameInput] = useState<string>(name);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onRenameFolder = () => {
    setIsRenaming(true);
  };
  const checkPressEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      saveFolderName();
    }
  };
  const onBlur = () => {
    if (!isSaving) {
      setNameInput(name);
      setIsRenaming(false);
      setError(null);
    }
  };

  const isValidFolderName = (input: string) => {
    return name === input || !uniqueFolderNames.includes(input);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value.substring(0, 60);
    if (!isValidFolderName(text)) {
      setError("This folder name already exists");
    } else {
      setError(null);
    }
    setNameInput(text);
  };

  const saveFolderName = async () => {
    if (nameInput === name || error) return;
    setIsSaving(true);
    await handleRenameFolder(folderId, nameInput);
    setIsRenaming(false);
    setIsSaving(false);
  };

  const onHide = () => setShowModal(false);

  return (
    <div className={style.nameWrapper}>
      <div className={style.folderIconWrapper}>
        {folder?.invite_only ? (
          <LockedFolderIcon className={style.icon} />
        ) : (
          <FolderOpenOutlinedIcon className={style.icon} />
        )}
        {!isRenaming && <span>{name}</span>}
        {isRenaming && (
          <div className={style.nameInputWrapper}>
            <input
              autoFocus
              className={error && style.errorInput}
              spellCheck="false"
              value={nameInput}
              type="text"
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress={checkPressEnter}
              placeholder="Folder Name"
              disabled={isSaving}
            />
            {!isSaving ? (
              <DoneIcon
                onMouseDown={() => saveFolderName()}
                className={classnames({
                  [style.icon]: true,
                  [style.errorIcon]: Boolean(error),
                  [style.disabled]: Boolean(error) || nameInput.length === 0,
                })}
              />
            ) : (
              <img className={style.loading} src={spinner} />
            )}
          </div>
        )}
      </div>
      {isEditEnabled && !isRenaming && (
        <Dropdown className={style.dropdownContainer}>
          <Dropdown.Toggle>
            <MoreVertIcon className={style.dropDownIcon} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className={style.dropdownItem} onClick={onRenameFolder}>
              Rename Folder
            </Dropdown.Item>
            <Dropdown.Item className={style.dropdownItem} onClick={() => setShowModal(true)}>
              Delete Folder
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!isEditEnabled && (
        <CommentOnlyBadge tooltipText="You have comment-only access to this project folder. This means you can view text, change status, and leave comments. To get full edit rights, ask an admin." />
      )}
      {showModal && <DeleteFolderModal folder={folder} onHide={onHide} handleDeleteFolder={handleDeleteFolder} />}
    </div>
  );
};

export default FolderName;
