import { RecursivePartial } from "@shared/types/lib";
import {
  IFApiIdCasingAdjustment,
  IFApiIdReplacementCharacter,
  IFProjectsApiIdGenerationConfig,
} from "@shared/types/Workspace";
import { tryToParseRegex } from "@shared/utils/apiId/lib";
import classNames from "classnames";
import React, { useMemo, useReducer, useState } from "react";
import Form from "react-bootstrap/Form";

import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ModalBase, { ModalBody, ModalFooter } from "../shared/ModalBase";

import { createTextItemApiIdGenerator, TextItemMetadata } from "@shared/utils/apiId/createTextItemApiIdGenerator";
import { regexExpressionWrapper } from "@shared/utils/apiId/nameToApiId";
import { ApiIdDumbComponent } from "../api-id/ApiIdDumbComponent";
import ConfirmationModal from "../shared/confirmation-modal";
import {
  AcceptedCharsRule,
  CasingRule,
  IDTemplate,
  MaxIDLengthRule,
  PlaceholderSeparatorRule,
  SpaceReplacementRule,
} from "./IDRuleComponents";
import style from "./ProjectApiIdGenerationModal.module.css";

interface Props {
  initialConfig: IFProjectsApiIdGenerationConfig;
  onHide: () => void;
  onSave: (
    config: IFProjectsApiIdGenerationConfig,
    configHasChanged: boolean,
    updateExistingIds: boolean
  ) => void | Promise<void>;
}

interface State {
  loading: boolean;
  updateExistingIds: boolean;
  apiIdGeneration: IFProjectsApiIdGenerationConfig;
}

type StateAction = { type: "update"; state: RecursivePartial<State> } | { type: "toggleLoading" };

const useApiIdGenerationModalState = (props: Props) => {
  const [state, dispatch] = useReducer(
    (state: State, action: StateAction) => {
      switch (action.type) {
        case "update": {
          return {
            ...state,
            ...action.state,
            apiIdGeneration: {
              ...state.apiIdGeneration,
              ...action.state.apiIdGeneration,
            },
          };
        }
        case "toggleLoading":
          return { ...state, loading: !state.loading };
        default:
          throw new Error("Unsupported action");
      }
    },
    {
      loading: false,
      updateExistingIds: false,
      apiIdGeneration: props.initialConfig,
    }
  );

  return [state, dispatch] as const;
};

export const ProjectApiIdGenerationModal = (props: Props) => {
  const [state, dispatch] = useApiIdGenerationModalState(props);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const acceptedCharactersRegexIsValid = useMemo(
    () => tryToParseRegex(regexExpressionWrapper(state.apiIdGeneration.acceptedCharsPattern ?? "", [])) !== null,
    [state.apiIdGeneration.acceptedCharsPattern]
  );

  const configHasChanged = useMemo(
    () =>
      state.apiIdGeneration.acceptedCharsPattern !== props.initialConfig.acceptedCharsPattern ||
      state.apiIdGeneration.casingAdjustment !== props.initialConfig.casingAdjustment ||
      state.apiIdGeneration.spaceReplacement !== props.initialConfig.spaceReplacement ||
      state.apiIdGeneration.maxLength !== props.initialConfig.maxLength ||
      state.apiIdGeneration.template !== props.initialConfig.template ||
      state.apiIdGeneration.separator !== props.initialConfig.separator,
    [state.apiIdGeneration, props.initialConfig]
  );

  const templateHasParts = state.apiIdGeneration.template.match(/\{\{(.*?)\}\}/g);

  const canSave = templateHasParts && (configHasChanged || state.updateExistingIds) && acceptedCharactersRegexIsValid;

  const apiIdGenerator = useMemo(
    () => createTextItemApiIdGenerator({ config: state.apiIdGeneration }),
    [state.apiIdGeneration]
  );

  const apiId = useMemo(
    () =>
      apiIdGenerator.generate({
        _id: "",
        projectName: "Project",
        pageName: "Page",
        groupName: "Group",
        blockName: "Block",
        text: "Text",
      } as TextItemMetadata),
    [apiIdGenerator]
  );

  const handleOnHide = () => {
    if (configHasChanged || state.updateExistingIds) {
      setShowConfirmationModal(true);
    } else props.onHide();
  };

  const onTemplateChange = (template: string) =>
    dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          template,
        },
      },
    });

  const onReplacementClick =
    (replacementCharacter: IFApiIdReplacementCharacter) => (e: React.MouseEvent<HTMLButtonElement>) =>
      dispatch({
        type: "update",
        state: {
          apiIdGeneration: {
            spaceReplacement: replacementCharacter,
          },
        },
      });

  const onCasingClick = (casingAdjustment: IFApiIdCasingAdjustment) => (e: React.MouseEvent<HTMLButtonElement>) =>
    dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          casingAdjustment,
        },
      },
    });

  const onAcceptedCharsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedCharsPattern = e.target.value;
    return dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          acceptedCharsPattern,
        },
      },
    });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: "update",
      state: {
        updateExistingIds: e.target.checked,
      },
    });

  const onSaveClick = async () => {
    dispatch({ type: "toggleLoading" });
    await props.onSave(state.apiIdGeneration, configHasChanged, state.updateExistingIds);

    props.onHide();
  };

  const onSeparatorClick = (separatorCharacter: IFApiIdReplacementCharacter) =>
    dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          separator: separatorCharacter,
        },
      },
    });

  const onMaxLengthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // maxLength must be between 1 and 200
    const maxLength = Math.min(parseInt(e.target.value) || 1, 200);
    return dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          maxLength,
        },
      },
    });
  };

  return (
    <>
      {!showConfirmationModal && (
        <ModalBase
          onHide={handleOnHide}
          title="Text Item ID Rules"
          className={style.container}
          titleClassName={style.containerTitle}
        >
          <ModalBody className={style.body}>
            <div className={style.borderSection}>
              <h3>ID Template</h3>
              <IDTemplate template={state.apiIdGeneration.template} onTemplateChange={onTemplateChange} />
            </div>
            <div className={style.borderSection}>
              <h3>Configurations</h3>
              <AcceptedCharsRule
                baseTabIndex={0}
                pattern={state.apiIdGeneration.acceptedCharsPattern}
                patternIsValid={acceptedCharactersRegexIsValid}
                onPatternChange={onAcceptedCharsInputChange}
              />
              <SpaceReplacementRule
                activeReplacementCharacter={state.apiIdGeneration.spaceReplacement}
                baseTabIndex={0}
                onReplacementClick={onReplacementClick}
              />
              <CasingRule
                activeCasingValue={state.apiIdGeneration.casingAdjustment}
                baseTabIndex={0}
                onCasingClick={onCasingClick}
              />
              <PlaceholderSeparatorRule
                baseTabIndex={0}
                activeSeparator={state.apiIdGeneration.separator}
                onSeparatorClick={onSeparatorClick}
              />
              <MaxIDLengthRule
                baseTabIndex={0}
                activeMaxLength={state.apiIdGeneration.maxLength}
                onMaxLengthChange={onMaxLengthChange}
              />
            </div>

            <div className={style.ruleTestingContainer}>
              <section className={style.flexSection}>
                <label
                  style={{
                    fontWeight: "bold",
                    width: "unset",
                    marginRight: "16px",
                  }}
                >
                  Example
                </label>
                <ApiIdDumbComponent value={apiId} className={style.apiId} />
              </section>
            </div>
          </ModalBody>
          <ModalFooter className={style.footer}>
            <div className={style.footerInner}>
              <div className={style.footerCheckboxContainer}>
                <Form.Check
                  inline
                  type="checkbox"
                  id="custom-switch"
                  className={style.footerCheckbox}
                  checked={state.updateExistingIds}
                  onChange={onCheckboxChange}
                />
                <label htmlFor="custom-switch">Upon save, update all existing text item IDs using updated rules</label>
              </div>
              {state.updateExistingIds && (
                <p>
                  <strong>Note:</strong> This operation might take a while, especially if you have a lot of large
                  projects. Please don't close the page until it's done.
                </p>
              )}
              <div className={style.footerBottom}>
                <span
                  className={classNames({
                    [style.errorMessage]: true,
                    [style.hidden]: acceptedCharactersRegexIsValid,
                  })}
                >
                  Please fix errors prior to saving.
                </span>
                <div className={style.footerButtons}>
                  <ButtonSecondary text="Cancel" onClick={handleOnHide} className={style.cancelButton} />
                  <ButtonPrimary
                    text="Save"
                    onClick={onSaveClick}
                    disabled={!canSave || state.loading}
                    loading={state.loading}
                    className={style.submitButton}
                  />
                </div>
              </div>
            </div>
          </ModalFooter>
        </ModalBase>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Unsaved Changes"
          body="You have unsaved changes to your API ID configuration. Are you sure you want to close?"
          onPrimary={props.onHide}
          actionPrimary="Close"
          onSecondary={() => setShowConfirmationModal(false)}
          actionSecondary="Go Back"
        />
      )}
    </>
  );
};
