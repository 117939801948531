import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

interface IProps {
  lowerBound: number;
  upperBound: number;
  total: number;
  className?: string;
}

export const ComponentCount = (props: IProps) => {
  return (
    <span className={classNames(props.className)}>
      <span>
        {props.lowerBound}–{props.upperBound} of <span className={style.totalComponentCount}>{props.total}</span>
      </span>
    </span>
  );
};
