import AutorenewIcon from "@mui/icons-material/Autorenew";
import React from "react";
import TimeAgo from "react-timeago";
import { Group, TextItem } from "../../../../views/Project/state/types";
import FigmaFrameVariantWarning from "../../../FigmaFrameVariantWarning";
import Spinner from "../../../spinner/spinner";
import PreviewLoadError from "../PreviewLoadError";
import HighlightBox from "./HighlightBox";
import { useImageWrapperStyles } from "./lib";
import style from "./style.module.css";
import { FrameVariant, usePreview } from "./usePreview";

interface Props {
  group: Group;
  groupIndex: number;
  scrollToId: string | null;
  selectedId: string | null;
  suggestedCompId: string | null;
  previewsLastUpdatedAt: Date;
  searchFiltersOn: boolean;
  isSelected: (compId: string) => boolean;
  selectComp: (
    comp: string | TextItem,
    frameVariant?: FrameVariant,
    e?: React.SyntheticEvent,
    groupId?: string
  ) => void;
  loading: boolean;
  appliedVariant: { exists: boolean; name: string };
  textItems: TextItem[];
}
const GroupImagePreview = ({
  group,
  groupIndex,
  scrollToId,
  selectedId,
  suggestedCompId,
  previewsLastUpdatedAt,
  isSelected,
  selectComp,
  searchFiltersOn,
  loading,
  appliedVariant,
  textItems,
}: Props) => {
  const {
    imgElement,
    imageInfo,
    imageLoaded,
    imgError,
    syncMoreThanOneDayOld,
    highlightBoxStyles,
    onSelectComp,
    handleImgLoadingError,
    handleImgLoaded,
    imageRenderDimensions,
  } = usePreview({
    group,
    groupIndex,
    textItems,
    previewsLastUpdatedAt,
    scrollToId,
    selectedId,
    selectComp,
  });

  /**
   * If there is an error but we're currently loading, continue to render the rest
   * of the component. (the downside: we have to treat `imageInfo` as nullable everywhere
   * that we use it)
   */
  const showErrorState = (imgError.exists || !imageInfo) && !loading;
  if (showErrorState) {
    return <PreviewLoadError />;
  }

  const formattedImageInfoLastUpdatedAt =
    typeof imageInfo?.previewsLastUpdatedAt === "string"
      ? new Date(imageInfo.previewsLastUpdatedAt)
      : imageInfo?.previewsLastUpdatedAt;

  const imageWrapperStyles = useImageWrapperStyles(imageRenderDimensions);

  const displayImageWrapper = imageLoaded && !imgError.exists;

  return (
    <div className={style.imagePreviewContainer}>
      <div className={style.lastSyncInfo}>
        Fetched{" "}
        {!syncMoreThanOneDayOld ? (
          <TimeAgo
            date={imageInfo?.previewsLastUpdatedAt || new Date()}
            minPeriod={60}
            key={imageInfo?.previewsLastUpdatedAt.toString()}
          />
        ) : (
          <>
            {`${formattedImageInfoLastUpdatedAt?.toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })} at ${formattedImageInfoLastUpdatedAt?.toLocaleTimeString()}`}
          </>
        )}
        {loading && <AutorenewIcon className={style.spinning} />}
      </div>
      {appliedVariant && (
        <FigmaFrameVariantWarning
          exists={appliedVariant.exists}
          variantName={appliedVariant.name}
          isEnlarge={false}
          className={style.appliedVariantWarning}
        />
      )}
      {(!imageLoaded || (imgError.exists && loading)) && (
        <div className={style.previewLoading}>
          <Spinner />
          <span>Loading image preview..</span>
        </div>
      )}
      <div
        className={style.imageWrapper}
        id={`image-wrapper-${group._id}`}
        style={{
          ...imageWrapperStyles,
          display: displayImageWrapper ? "block" : "none",
        }}
      >
        <img
          ref={imgElement}
          className={style.imagePreview}
          src={imageInfo?.previews.fullsize}
          onLoad={handleImgLoaded}
          onError={handleImgLoadingError}
        />
        {highlightBoxStyles.map((highlightBox) => {
          const textItem = imageInfo?.compIdMap[highlightBox.compId];
          if (!textItem) {
            return <React.Fragment key={highlightBox.compId} />;
          }

          return (
            <HighlightBox
              key={highlightBox.compId}
              textItem={{
                text: textItem.text,
                is_hidden: textItem.is_hidden ?? false,
                lastSync: textItem.lastSync,
                updatedAt: textItem.updatedAt,
                variables: textItem.variables,
                text_last_modified_at: textItem.text_last_modified_at,
              }}
              isSelected={isSelected(highlightBox.compId)}
              onClick={(e) => onSelectComp(highlightBox.compId, e)}
              searchFiltersOn={searchFiltersOn}
              suggestedCompId={suggestedCompId}
              highlight={highlightBox}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupImagePreview;
