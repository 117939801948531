import { UserPermissionContext } from "@shared/frontend/userPermissionContext";
import classnames from "classnames";
import React, { createRef, useContext, useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import http, { API } from "../../http/index";

import { IFVariable } from "../../../shared/types/Variable";
import style from "./style.module.css";

const colourStyles = {
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#19191A",
      fontWeight: "bold",
    };
  },
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B7B7B8",
    };
  },
};

type SelectedVariableType =
  | IFVariable
  | null
  | {
      _id: "__new__";
      name: string;
      type: null;
      folder_id?: string;
      data: {};
    };

interface Props {
  selectedVariable: React.StatePair<SelectedVariableType | null>;
  placeholder?: string;
  isCreateOnly?: boolean;
  variableTypeSelectRef?: any;
  handleVariableNameError: (error: boolean) => void;
  isDisabled?: boolean;
  selectedVariableFolder?: any;
  foldersAllowed?: boolean;
  filterSampleData?: boolean;
}

export default function VariableSelectInput(props: Props) {
  const {
    selectedVariable: [selectedVariable, setSelectedVariable],
    placeholder = "Create or insert an existing variable...",
    isCreateOnly,
    variableTypeSelectRef,
    handleVariableNameError,
    isDisabled,
    selectedVariableFolder,
    foldersAllowed,
  } = props;

  const selectRef = createRef<HTMLInputElement>();
  const [variables, setVariables] = useState<IFVariable[]>([]);

  const [duplicateNameError, setDuplicateNameError] = useState(false);
  const [creatableSelectKey, setCreatableSelectKey] = useState("all-variables");

  const { userHasResourcePermission } = useContext(UserPermissionContext);

  const uniqueVariableNames = useMemo(() => {
    return variables.filter((variable) => !!variable).map((variable) => variable.name);
  }, [variables]);

  const variableOptions = useMemo(() => {
    return variables
      .filter((variable) => {
        if (!variable) {
          return false;
        } else if (selectedVariableFolder && variable.folder_id !== selectedVariableFolder._id) {
          return false;
        } else {
          return true;
        }
      })
      .map((variable) => ({
        label: variable.name,
        value: variable,
      }));
  }, [variables, selectedVariableFolder]);

  async function fetchVariables() {
    const { url } = API.variable.get.all;
    const response = await http.get(url);
    const { data: variables } = response;
    if (!variables) {
      throw Error(`Error doing request to ${url} because ${response.statusText}`);
    }

    const filteredVariables = variables.filter((variable) => {
      if (props.filterSampleData) return !variable.isSample;
      else return variable.isSample;
    });

    setVariables(filteredVariables);
  }

  useEffect(function fetchVariablesOnMount() {
    fetchVariables();
  }, []);

  useEffect(() => {
    if (selectedVariableFolder !== null && selectedVariable?.folder_id !== selectedVariableFolder._id) {
      setSelectedVariable(null);
      setCreatableSelectKey(selectedVariableFolder._id);
    }
  }, [selectedVariableFolder]);

  const onVariableChange = (e) => {
    if (e) {
      if (e.__isNew__) {
        if (variableTypeSelectRef?.current && selectedVariable?.type)
          variableTypeSelectRef.current.select.select.setValue(null);

        // we need to set the type to null so that the variable type select
        // prompts the user to select a type -- but this is not a valid type

        // react-select messed up and kept `Create "${o.label}"` as an option
        // remove Create "" automatically
        if (e.label.match(/^Create ".*"$/)) {
          const sanitizedName = e.label.substring(8, e.label.length - 1);
          setSelectedVariable({
            _id: "__new__",
            name: sanitizedName,
            type: null,
            data: {},
          });
        } else {
          setSelectedVariable({
            _id: "__new__",
            name: e.label,
            type: null,
            data: {},
          });
        }
      } else {
        setSelectedVariable(e.value);
      }
    } else {
      //cleared
      setSelectedVariable(null);
    }
  };

  const onInputChange = (e) => {
    const updatedValue = e.replaceAll(/[^A-Za-z0-9_]/g, "");
    return updatedValue;
  };

  const onVariableNameChange = (e) => {
    const updatedName = e.target.value.replaceAll(/[^A-Za-z0-9_]/g, "");

    setSelectedVariable((prev) => {
      if (!prev) {
        return {
          name: updatedName,
          _id: "__new__",
          type: null,
          data: {},
        };
      }

      return {
        ...prev,
        name: updatedName,
        _id: "__new__",
      };
    });
    if (uniqueVariableNames.includes(updatedName)) {
      setDuplicateNameError(true);
      handleVariableNameError(true);
    } else {
      setDuplicateNameError(false);
      handleVariableNameError(false);
    }
  };
  const validateAbilityCreateVariable = (inputValue) => {
    const hasEditAccessToFolder = userHasResourcePermission("variable_folder:edit", selectedVariableFolder?._id);

    return (
      (!foldersAllowed || hasEditAccessToFolder) &&
      !uniqueVariableNames.includes(inputValue) &&
      inputValue &&
      inputValue.length > 0
    );
  };

  useEffect(() => {
    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, [selectRef?.current]);

  if (isCreateOnly) {
    return (
      <>
        <input
          type="text"
          ref={selectRef}
          disabled={isDisabled}
          className={classnames({ [style.errorInput]: duplicateNameError })}
          value={selectedVariable ? selectedVariable.name : ""}
          onChange={onVariableNameChange}
          placeholder={placeholder}
        />
        {duplicateNameError && <div className={style.errorMsg}>Variable name already exists</div>}
      </>
    );
  }
  return (
    // The key on this creatable select is used when we want to clear out the select completely.
    // setSelectedVariable(null) doesn't reset the internal state of CreatableSelect. So we'd either need to
    // add a value property here which makes the state in this component more complex or using the key attribute
    // we can cause a rerender when we need to reset the select.
    <CreatableSelect
      key={creatableSelectKey}
      ref={selectRef}
      isClearable
      isDisabled={isDisabled}
      placeholder={placeholder}
      onChange={onVariableChange}
      onInputChange={onInputChange}
      options={!isCreateOnly ? variableOptions : []}
      className={style.dropdown}
      isValidNewOption={validateAbilityCreateVariable}
      styles={colourStyles}
    />
  );
}
