import CloseIcon from "@mui/icons-material/Close";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import CreatableSelect from "react-select/creatable";
import ButtonPrimary from "../button/buttonprimary";

import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { UserPermissionContext } from "@shared/frontend/userPermissionContext";
import { VARIANTS_NOT_IN_FOLDERS_ID } from "@shared/lib/PermissionGroups";
import http, { API } from "../../http/index";
import FolderSelect from "../FolderSelect";
import useFolderSelect from "../FolderSelect/useFolderSelect";
import StatusSelect from "../StatusSelect";
import VariableRichTextArea from "../VariableTextArea/VariableRichTextArea";
import { VariantOption } from "../shared/VariantSelectInput";
import style from "./style.module.css";

const initialVariantValue = { text: "", rich_text: null, variables: [] };

const ComponentNewVariantModal = ({
  ws_comp,
  onHide,
  setSelectedId,
  getAllVariants,
  fetchAllComps,
  refreshLibraryHistory,
  isInSampleFolder,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [compName, setCompName] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [availableVariants, setAvailableVariants] = useState([]);
  const [status, setStatus] = useState("DEFAULT");
  const { selectedFolder, setSelectedFolder, folders } = useFolderSelect({
    useSampleData: isInSampleFolder,
    folderType: "variant",
  });
  const valueBeingEdited = useRef();

  const { userHasResourcePermission } = useContext(UserPermissionContext);

  const baseCompValue = useMemo(
    () => ({
      text: ws_comp.instances[0].text,
      rich_text: ws_comp.instances[0].rich_text,
      variables: ws_comp.instances[0].variables,
    }),
    [ws_comp]
  );

  const canSave = useMemo(() => {
    return (
      selectedVariant && !loadingState && !(selectedVariant.id === "__new__" && ws_comp.isSample) // don't allow create new variant for a sample component
    );
  }, [selectedVariant, loadingState, ws_comp]);

  useEffect(() => {
    if (ws_comp) {
      setCompName(ws_comp.name.substr(ws_comp.name.lastIndexOf("/") + 1).trim());
    }
  }, [ws_comp]);

  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#B7B7B8",
      };
    },
  };

  const validateAbilityCreateVariant = (inputValue) => {
    const folderId = selectedFolder._id === "" ? VARIANTS_NOT_IN_FOLDERS_ID : selectedFolder._id;

    const hasEditAccessToFolder = userHasResourcePermission("variant_folder:edit", folderId);

    return (
      hasEditAccessToFolder &&
      !availableVariants.find((v) => v.name === inputValue) &&
      inputValue &&
      inputValue.length > 0
    );
  };

  const addVariant = async () => {
    try {
      setLoadingState(true);

      const statusToSend = status === "DEFAULT" ? ws_comp.status : status;

      const { url, body } = API.ws_comp.post.variant;
      const { data: selectedWsComp } = await http.post(
        url(ws_comp._id),
        body({
          variantId: selectedVariant.id,
          variantName: selectedVariant.name,
          variantText: valueBeingEdited.current.text,
          variantRichText: valueBeingEdited.current.richText,
          variantVariables: valueBeingEdited.current.variables,
          variantFolderId: selectedFolder?._id,
          variantStatus: statusToSend,
        })
      );

      await getAllVariants();
      refreshLibraryHistory();
      fetchAllComps();
      setSelectedId(ws_comp._id);
      onHide();
    } catch (error) {
      console.error("Error adding ws_comp variant", error);
      setLoadingState(true);
    }
  };

  const handleChange = (e) => {
    if (e) {
      if (e.__isNew__) {
        setSelectedVariant({
          id: "__new__",
          name: e.label,
        });
      } else {
        setSelectedVariant({
          id: e.value,
          name: e.label,
        });
      }
    } else {
      //cleared
      setSelectedVariant(null);
    }
  };

  const handleTextChange = (newInputValue, richText) => {
    valueBeingEdited.current = {
      ...newInputValue,
      richText,
    };
  };

  function handleStatusChange(status) {
    setStatus(status);
  }

  const fetchAvailableVariants = async () => {
    const { data } = await http.get(API.variant.get.getVariantsForWorkspace.url);
    const existingVariants = ws_comp.variants.reduce((acc, variant) => {
      acc[variant.variantId] = true;
      return acc;
    }, {});
    const filteredVariants = data.filter((variant) => {
      if (!isInSampleFolder) return !variant.isSample && !existingVariants[variant._id];
      return variant.isSample && !existingVariants[variant._id];
    });
    setAvailableVariants(filteredVariants);
  };

  useEffect(function fetchVariantsOnMount() {
    fetchAvailableVariants();
  }, []);

  const variantOptions = useMemo(() => {
    return availableVariants
      .filter((v) => selectedFolder?._id === v.folder_id || selectedFolder?._id === "")
      .map((v) => ({
        label: v.name,
        value: v._id,
        description: v.description,
      }));
  });

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            Add Variant for <span>{compName}</span>
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <FolderSelect
            folders={folders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            className={style.folderSelect}
          />
          <label className={style.label}>Variant</label>
          <CreatableSelect
            isClearable
            placeholder="Create or add an existing variant..."
            onChange={handleChange}
            onInputChange={() => {}}
            options={variantOptions}
            className={style.dropdown}
            isValidNewOption={validateAbilityCreateVariant}
            styles={colourStyles}
            components={{
              Option: VariantOption,
            }}
          />
          <div className={[style.marginTopSmall]}>
            <div className={[style.flexBetween]}>
              <p className={[style.modalVariantLabels]}>
                <strong>Base Text</strong>
              </p>
            </div>
            <VariableRichTextArea
              disableRichText={false}
              placeholder="No text."
              value={baseCompValue}
              isVariant={false}
              isBase={true}
              isPlural={false}
              isDisabled={true}
              hideLabels={true}
              handleTextChange={() => {}}
              inSampleProject={ws_comp?.isSample}
            />
          </div>
          <div className={[style.marginTopSmall]}>
            <VariableRichTextArea
              disableRichText={false}
              placeholder="No variant text."
              value={initialVariantValue}
              isDisabled={false}
              isBaseText={false}
              isVariant={false}
              isPlural={false}
              showContentLength={false}
              handleTextChange={(newInputValue, richText) => handleTextChange(newInputValue, richText)}
              components={{
                label: ({ children }) => <label>{children}</label>,
              }}
              inSampleProject={ws_comp?.isSample}
            />

            {VARIANT_STATUSES_ENABLED && (
              <StatusSelect showDefaultOption status={status} handleStatusChange={handleStatusChange} />
            )}
          </div>
          {ws_comp?.isSample && selectedVariant?.id === "__new__" && (
            <div className={style.sampleInfo}>
              <div className={style.sampleInfoIcon}>
                <InfoOutlined fontSize="inherit" />
              </div>
              &nbsp;Creating new variants is disabled for sample components.
            </div>
          )}
          <span className={style.form}>
            <ButtonPrimary
              text={loadingState ? "Adding variant..." : "Add Variant"}
              disabled={!canSave}
              onClick={addVariant}
            />
          </span>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default ComponentNewVariantModal;
