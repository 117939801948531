import { UseAutoNameSuggestionReturnValue, useAutoNameSuggestion } from "@/hooks/useAutoNameSuggestion";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { default as classNames, default as classnames } from "classnames";
import React, { useContext, useMemo } from "react";
import Button from "react-bootstrap/Button";
import { scroller } from "react-scroll";
import ReactTooltip from "react-tooltip";
import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import {
  PopulatedAttachSuggestion,
  PopulatedComponentSuggestion,
  PopulatedCreateSuggestion,
  isPopulatedAttachSuggestion,
  isPopulatedCreateSuggestion,
} from "../../../shared/types/ComponentSuggestions";
import { SuggestionTabs } from "../../defs";
import { BillingContext } from "../../store/billingContext";
import { Group } from "../../views/Project/state/types";
import FolderSelect, { hasValidFolderLoaded } from "../FolderSelect";
import ComponentLimitText from "../account-billing/ComponentLimitText";
import RoundedCount from "../rounded-count/rounded-count";
import ComponentAttachSuggestion from "./ComponentAttachSuggestion";
import ComponentCreateSuggestion from "./ComponentCreateSuggestion";
import ComponentPlaceHolder from "./ComponentPlaceholder";
import IgnoredSuggestionsDrawer from "./IgnoredSuggestionsDrawer";
import style from "./style.module.css";
import { CreateComp, useSuggestions } from "./useSuggestions";

export interface ComponentSuggestionsProps {
  groups: Group[];
  doc_ID: string;
  resyncLoading: boolean;
  suggestedCompId: string;
  handleSuggestionSelectComp: (comp: any) => void;
  handleDocUpdate: (compIds: string[]) => void;
  handleHistoryUpdate: () => void;
  setCustomToast: (toast: { show: boolean; message: string | null; icon: any }) => void;
  inSampleProject?: boolean;
}

const ComponentSuggestions = ({
  groups,
  doc_ID,
  resyncLoading,
  suggestedCompId,
  handleSuggestionSelectComp,
  handleDocUpdate,
  handleHistoryUpdate,
  setCustomToast,
  inSampleProject,
}: ComponentSuggestionsProps) => {
  const {
    isSaving,
    compMap,
    loadingSuggestions,
    hasComponents,
    suggestionTab,
    filteredCreateSuggestions,
    filteredAttachSuggestions,
    selectedIndex,
    changeSuggestionTab,
    onSuggestionSelected,
    attachSuggestion,
    createAndAttachSuggestion,
    pageThroughDupes,
    checkComponentNameExists,
    fetchComponentCategories,
    selectedFolder,
    setSelectedFolder,
    folders,
    handleIgnoreSuggestion,
    isEditEnabled,
    multiSelectedSuggestions,
    handleMultiSelect,
    handleMultiAttach,
    handleMultiSelectAll,
    handleMultiIgnore,
    handleMultiCreate,
    onChangeCompName,
    onChangeCompFolder,
    setCompError,
    createCompMap,
  } = useSuggestions({
    groups,
    doc_ID,
    resyncLoading,
    suggestedCompId,
    handleSuggestionSelectComp,
    handleDocUpdate,
    handleHistoryUpdate,
    setCustomToast,
  });

  const textsToAutoname = useMemo(() => {
    return filteredCreateSuggestions.notIgnored.map((suggestion) => suggestion.text);
  }, [filteredCreateSuggestions]);
  const { textToNameSuggestion, generateSuggestionFor, setStatusFor, saveAnalyticsFor } = useAutoNameSuggestion({
    textsToAutoname,
  });

  const {
    componentLimits: { isOverComponentLimit },
  } = useContext(BillingContext);

  const setCompNameWithNameSuggestion = (val: string, suggestion: PopulatedCreateSuggestion<string>) => {
    onChangeCompName(val, suggestion._id);

    const nameSuggestion = textToNameSuggestion.current[suggestion.text];
    const nameSuggestionStatus = nameSuggestion.status;
    if (nameSuggestionStatus === "not generated") {
      return;
    }

    // If text was cleared out reject the suggestion
    if (val.length <= 1) {
      setStatusFor(suggestion.text, "rejected");
    } else if (val !== nameSuggestion.suggestedName && nameSuggestionStatus === "accepted") {
      setStatusFor(suggestion.text, "edited");
    }
  };

  return (
    <div className={style.sectionWrapper}>
      <div className={style.tabs}>
        <div
          className={classnames({
            [style.tab]: true,
            [style.activeSuggestionTab]: suggestionTab === SuggestionTabs.create,
          })}
          onClick={() => changeSuggestionTab(SuggestionTabs.create)}
        >
          <div className={style.suggestionType}>Create</div>
          {!(resyncLoading || loadingSuggestions) && (
            <RoundedCount count={filteredCreateSuggestions.notIgnored.length} />
          )}
        </div>
        <div
          className={classnames({
            [style.tab]: true,
            [style.activeSuggestionTab]: suggestionTab === SuggestionTabs.attach,
          })}
          onClick={() => changeSuggestionTab(SuggestionTabs.attach)}
        >
          <div className={style.suggestionType}>Attach</div>
          {!(resyncLoading || loadingSuggestions) && (
            <RoundedCount count={filteredAttachSuggestions.notIgnored.length} />
          )}
        </div>
      </div>
      {inSampleProject && suggestionTab === SuggestionTabs.create && (
        <div className={style.sampleInfo}>
          <div className={style.sampleInfoIcon}>
            <InfoOutlined fontSize="inherit" />
          </div>
          Create component suggestions are disabled for the sample project.
        </div>
      )}
      <div
        id="suggestion-list"
        className={classNames({
          [style.scrollWrapper]: true,
          [style.disabledPanel]: suggestionTab === SuggestionTabs.create && inSampleProject,
        })}
      >
        {resyncLoading || loadingSuggestions ? (
          <ComponentPlaceHolder />
        ) : (
          <>
            {(suggestionTab === SuggestionTabs.create && filteredCreateSuggestions.notIgnored.length === 0) ||
            (suggestionTab === SuggestionTabs.attach && filteredAttachSuggestions.notIgnored.length === 0) ? (
              <>
                {suggestionTab === SuggestionTabs.attach && hasValidFolderLoaded(folders) && (
                  <div className={style.folderSelectWrapper}>
                    <FolderSelect
                      text="Suggestions from"
                      fontSize="14px"
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder}
                      folders={folders}
                    />
                  </div>
                )}
                <div className={style.emptyMsg}>
                  {suggestionTab === SuggestionTabs.create ? (
                    <>
                      <h2>No Suggestions</h2>
                      <p>
                        You can turn any text in this project into a Ditto component, or draft a new one in{" "}
                        <a target="_top" href={"/components"}>
                          your component library
                        </a>
                        .
                      </p>
                    </>
                  ) : (
                    <>
                      <h2>{!hasComponents ? "You don't have any components!" : "No Suggestions"}</h2>
                      {hasComponents && <p>We didn’t find any Ditto components similar to text in this project.</p>}
                      <p>
                        You can turn any text in this project into a Ditto component, or draft a new one in{" "}
                        <a target="_top" href={"/components"}>
                          your component library
                        </a>
                        .
                      </p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={style.infoWrapper}>
                  <div className={style.infoSection}>
                    <div className={style.infoIconWrapper}>
                      <AutoAwesomeIcon className={style.icon} />
                    </div>
                    {suggestionTab === SuggestionTabs.create && (
                      <div className={style.infoMessage}>
                        We’ve identified repeated text in your Figma file that might be useful as Ditto components in
                        your library
                      </div>
                    )}
                    {suggestionTab === SuggestionTabs.attach && (
                      <div className={style.infoMessage}>
                        We’ve identified Ditto components in your library most similar to text in this project that
                        might be useful to attach
                      </div>
                    )}
                  </div>
                  {isOverComponentLimit && !inSampleProject && (
                    <div className={style.componentLimitWrapper}>
                      <ComponentLimitText />
                    </div>
                  )}
                </div>
                {suggestionTab === SuggestionTabs.attach && hasValidFolderLoaded(folders) && (
                  <div className={style.folderSelectWrapper}>
                    <FolderSelect
                      text="Suggestions from"
                      fontSize="14px"
                      selectedFolder={selectedFolder}
                      setSelectedFolder={setSelectedFolder}
                      folders={folders}
                    />
                  </div>
                )}
                <div className={style.compSuggestions}>
                  {suggestionTab === SuggestionTabs.attach
                    ? filteredAttachSuggestions.notIgnored.map((suggestion, index) => (
                        <ComponentAttachSuggestion
                          key={`${suggestion._id}-${selectedFolder._id}`}
                          index={index}
                          compMap={compMap}
                          isEnabled={isEditEnabled && !isSaving}
                          isSelected={index === selectedIndex}
                          suggestion={suggestion}
                          handleIgnoreSuggestion={handleIgnoreSuggestion}
                          handleSelected={onSuggestionSelected}
                          handleMultiAttach={attachSuggestion}
                          pageThroughDupes={pageThroughDupes}
                          multiSelectedSuggestions={
                            multiSelectedSuggestions.filter((s) =>
                              isPopulatedAttachSuggestion(s)
                            ) as PopulatedAttachSuggestion<string>[]
                          }
                          handleMultiSelect={handleMultiSelect}
                        />
                      ))
                    : filteredCreateSuggestions.notIgnored.map((suggestion, index) => (
                        <ComponentCreateSuggestion
                          key={suggestion._id}
                          index={index}
                          compMap={compMap}
                          isEnabled={isEditEnabled && !isOverComponentLimit && !isSaving}
                          isSelected={index === selectedIndex}
                          suggestion={suggestion}
                          handleIgnoreSuggestion={handleIgnoreSuggestion}
                          handleSelected={onSuggestionSelected}
                          pageThroughDupes={pageThroughDupes}
                          multiSelectedSuggestions={
                            multiSelectedSuggestions.filter((s) =>
                              isPopulatedCreateSuggestion(s)
                            ) as PopulatedCreateSuggestion<string>[]
                          }
                          handleMultiSelect={handleMultiSelect}
                          createAndAttachSuggestion={createAndAttachSuggestion}
                          checkComponentNameExists={checkComponentNameExists}
                          setError={(val) => setCompError(val, suggestion._id)}
                          error={createCompMap[suggestion._id]?.error || ""}
                          setCompName={(val) => setCompNameWithNameSuggestion(val, suggestion)}
                          compName={createCompMap[suggestion._id]?.name || ""}
                          setCompFolder={(val) => onChangeCompFolder(val, suggestion._id)}
                          generateSuggestionFor={generateSuggestionFor}
                          saveAnalyticsFor={saveAnalyticsFor}
                        />
                      ))}
                </div>
              </>
            )}
          </>
        )}
        <IgnoredSuggestionsDrawer
          key={suggestionTab}
          onOpen={() => {
            try {
              scroller.scrollTo("ignore-suggestions-drawer", {
                duration: 500,
                smooth: true,
                containerId: "suggestion-list",
                offset: -100,
              });
            } catch {
              //
            }
          }}
        >
          {suggestionTab === SuggestionTabs.attach
            ? filteredAttachSuggestions.ignored.map((suggestion, index) => (
                <ComponentAttachSuggestion
                  key={`${suggestion._id}-${selectedFolder._id}`}
                  index={index}
                  compMap={compMap}
                  isEnabled={isEditEnabled && !isSaving}
                  isSelected={false}
                  suggestion={suggestion}
                  handleIgnoreSuggestion={handleIgnoreSuggestion}
                  handleSelected={onSuggestionSelected}
                  handleMultiAttach={attachSuggestion}
                  pageThroughDupes={pageThroughDupes}
                  multiSelectedSuggestions={
                    multiSelectedSuggestions.filter(
                      (s) => s.rating !== undefined
                    ) as PopulatedAttachSuggestion<string>[]
                  }
                  handleMultiSelect={handleMultiSelect}
                />
              ))
            : filteredCreateSuggestions.ignored.map((suggestion, index) => (
                <ComponentCreateSuggestion
                  key={suggestion._id}
                  index={index}
                  compMap={compMap}
                  isEnabled={isEditEnabled && !isOverComponentLimit && !isSaving}
                  isSelected={false}
                  suggestion={suggestion}
                  handleIgnoreSuggestion={handleIgnoreSuggestion}
                  handleSelected={onSuggestionSelected}
                  pageThroughDupes={pageThroughDupes}
                  multiSelectedSuggestions={
                    multiSelectedSuggestions.filter((s) =>
                      isPopulatedCreateSuggestion(s)
                    ) as PopulatedCreateSuggestion<string>[]
                  }
                  handleMultiSelect={handleMultiSelect}
                  createAndAttachSuggestion={createAndAttachSuggestion}
                  checkComponentNameExists={checkComponentNameExists}
                  setError={(val) => setCompError(val, suggestion._id)}
                  error={createCompMap[suggestion._id]?.error || ""}
                  setCompName={(val) => onChangeCompName(val, suggestion._id)}
                  compName={createCompMap[suggestion._id]?.name || ""}
                  setCompFolder={(val) => onChangeCompFolder(val, suggestion._id)}
                  generateSuggestionFor={generateSuggestionFor}
                  saveAnalyticsFor={saveAnalyticsFor}
                />
              ))}
        </IgnoredSuggestionsDrawer>
      </div>

      {multiSelectedSuggestions.length > 0 && (
        <MultiSelectBanner
          multiSelectedSuggestions={multiSelectedSuggestions}
          createableSuggestions={filteredCreateSuggestions.notIgnored}
          attachableSuggestions={filteredAttachSuggestions.notIgnored}
          handleSelectAll={handleMultiSelectAll}
          handleMultiAttach={handleMultiAttach}
          handleMultiIgnore={handleMultiIgnore}
          handleMultiCreate={handleMultiCreate}
          createCompMap={createCompMap}
          saveAnalyticsFor={saveAnalyticsFor}
        />
      )}
    </div>
  );
};

interface MultiSelectBannerProps {
  multiSelectedSuggestions: PopulatedComponentSuggestion<string>[];
  createableSuggestions: PopulatedComponentSuggestion<string>[];
  attachableSuggestions: PopulatedComponentSuggestion<string>[];
  handleSelectAll: () => void;
  handleMultiAttach: () => void;
  handleMultiIgnore: (compIds: string[], attachType: "creatable" | "attachable", shouldIgnore: boolean) => void;
  handleMultiCreate: () => Promise<boolean>;
  createCompMap: Record<string, CreateComp>;
  saveAnalyticsFor: UseAutoNameSuggestionReturnValue["saveAnalyticsFor"];
}
const MultiSelectBanner = ({
  multiSelectedSuggestions,
  createableSuggestions,
  attachableSuggestions,
  handleSelectAll,
  handleMultiAttach,
  handleMultiIgnore,
  handleMultiCreate,
  createCompMap,
  saveAnalyticsFor,
}: MultiSelectBannerProps) => {
  // only attachable suggestions have a `rating` field
  const isAttach = multiSelectedSuggestions.every((suggestion) => isPopulatedAttachSuggestion(suggestion));

  const selectAllText = isAttach
    ? multiSelectedSuggestions.length === attachableSuggestions.length
      ? "Unselect All"
      : "Select All"
    : multiSelectedSuggestions.length === createableSuggestions.length
    ? "Unselect All"
    : "Select All";

  const hasErrors = Object.entries(createCompMap).some(([id, comp]) => {
    const inSelection = multiSelectedSuggestions.some((suggestion) => suggestion._id === id);
    const hasError = comp.error !== "" && comp.error !== undefined;
    return inSelection && hasError;
  });

  const missingNames = Object.entries(createCompMap).some(([id, comp]) => {
    const inSelection = multiSelectedSuggestions.some((suggestion) => suggestion._id === id);
    const missingName = comp.name === "";
    return inSelection && missingName;
  });

  const handleAccept = async () => {
    if (isAttach) {
      handleMultiAttach();
    } else {
      // Save analytics before handleMultiCreate since handleMultiCreate
      // updates the suggestions list (will result in undefined error if done after)
      multiSelectedSuggestions.forEach((suggestion) => {
        saveAnalyticsFor(suggestion.text);
      });

      await handleMultiCreate();
    }
  };

  const handleIgnore = () => {
    const idsToIgnore = multiSelectedSuggestions.map((suggestion) => suggestion._id);
    const suggestionType = isAttach ? "attachable" : "creatable";

    handleMultiIgnore(idsToIgnore, suggestionType, true);
  };

  // we don't care about errors if we're attaching
  const disable = !isAttach && (hasErrors || missingNames);

  return (
    <div className={style.multiSelectBanner}>
      <div className={style.left}>
        <span className={style.selectedInfo}>{multiSelectedSuggestions.length} selected</span>
        <span className={style.selectAll} onClick={handleSelectAll}>
          {selectAllText}
        </span>
      </div>
      <div className={style.right}>
        <span className={style.ignore} onClick={handleIgnore}>
          Ignore
        </span>
        <div className={style.tooltipWrapper} data-tip data-for="invite-only-folders">
          <Button disabled={disable} className={style.accept} onClick={handleAccept}>
            Accept
          </Button>
          {disable && (
            <ReactTooltip id="invite-only-folders" effect="solid">
              <span style={{ fontWeight: "normal" }}>Please make sure all suggestions have valid component names.</span>
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default ComponentSuggestions;
