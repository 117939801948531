import { IFComponent } from "./Component";
import { ActualComponentInterface, ActualComponentStatus, ActualComponentVariableSchema, RichText } from "./TextItem";

// these types are based off the return value from the `GET ws_comp/suggestions/` endpoint
interface PopulatedSuggestionBase<IdType = string> {
  _id: IdType;
  ignored: boolean;
  rich_text: RichText;
  text: string;
  variables: ActualComponentVariableSchema[];
  notes: string | null;
  tags: string[];
  status: ActualComponentStatus;
  comp_ids: string[];
  nodes: string[];
  ws_comp: IdType | null;
  rating?: number;
}

export interface PopulatedAttachSuggestion<IdType> extends PopulatedSuggestionBase<IdType> {
  ws_comp: IdType;
  rating: number;
  wsComp: IFComponent & {
    instances: ActualComponentInterface[];
  };
}

export interface PopulatedCreateSuggestion<IdType> extends PopulatedSuggestionBase<IdType> {
  ws_comp: null;
}

export type PopulatedComponentSuggestion<IdType> =
  | PopulatedAttachSuggestion<IdType>
  | PopulatedCreateSuggestion<IdType>;

export function isPopulatedAttachSuggestion<IdType>(
  suggestion: PopulatedComponentSuggestion<IdType>
): suggestion is PopulatedAttachSuggestion<IdType> {
  return suggestion.ws_comp !== null && suggestion.rating !== undefined;
}
export function isPopulatedCreateSuggestion<IdType>(
  suggestion: PopulatedComponentSuggestion<IdType>
): suggestion is PopulatedCreateSuggestion<IdType> {
  return suggestion.ws_comp === null;
}
