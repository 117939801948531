// when request TOO quick, flashing loading UI looks bad -
// this ensures that the user always gets to see the loader
// and there's no abrupt transitions
const MINIMUM_LOAD_TIME = 1000;

export const timedPromise = (time = MINIMUM_LOAD_TIME) => new Promise((resolve) => setTimeout(resolve, time));

export function getNumberFormatted(componentsInserted: string | number | null): string {
  if (!componentsInserted) return "0";
  if (typeof componentsInserted === "number") return componentsInserted.toLocaleString();

  try {
    const num = parseInt(componentsInserted);
    return Number.isNaN(num) ? componentsInserted : num.toLocaleString();
  } catch {
    return componentsInserted;
  }
}
