import useLocalStorage from "react-use/lib/useLocalStorage";

/**
 * The useLocalStorage hook from react-use is great, but the value returned is
 * always of type T | undefined. This is dumb -- if you pass in an initial value,
 * you should get that value back, not undefined. This hook is a simple wrapper
 * around useLocalStorage that fixes this.
 */
export default function useBetterLocalStorage<T>(key: string, initialValue: T) {
  const [_value, _setValue, remove] = useLocalStorage<T>(key, initialValue);

  // setValue takes either a value, or a function that takes the current value
  // and returns a new value
  const setValue = (valOrFunc: T | ((t: T) => T)) => {
    if (typeof valOrFunc === "function") {
      return _setValue((valOrFunc as (t: T) => T)(_value ?? initialValue));
    } else {
      return _setValue(valOrFunc);
    }
  };

  return [_value ?? initialValue, setValue, remove] as const;
}
