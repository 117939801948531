import ButtonSecondary from "@/components/button/buttonsecondary";
import * as HttpConnections from "@/http/connections";
import { useWorkspace } from "@/store/workspaceContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import classNames from "classnames";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import spinner from "../../../../assets/small-spinner.gif";
import ConfirmationModal from "../../../../components/shared/confirmation-modal";
import { useLaunchDarklyContext } from "../../store/LaunchDarklyConnectionContext";
import { ABIntegrationModal } from "../ABIntegrationModal";
import styles from "./styles.module.css";

/**
 * Updates to this file should probably be mirrored in the Split.tsx file, as
 * they're mostly the same.
 */

interface ILaunchDarklyConnectionState {
  token: string;
  tokenEditable: boolean;
  projectId: string;
  error: { code: string; message: string } | null;
  saveLoading: boolean;
  configEditable: boolean;
  showRemoveConfirmationModal: boolean;
}

const useLaunchDarklyConnectionState = () => {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const context = useLaunchDarklyContext();

  const originalState = useMemo(
    () => ({
      token: workspaceInfo.integrations.launchDarkly?.tokenPreview || "",
      projectId: workspaceInfo.integrations.launchDarkly?.projectId || "",
    }),
    [workspaceInfo.integrations.launchDarkly]
  );

  const [data, setData] = useState<ILaunchDarklyConnectionState>({
    ...originalState,
    error: null,
    saveLoading: false,
    tokenEditable: !originalState.token,
    configEditable: false,
    showRemoveConfirmationModal: false,
  });

  const hasUnsavedChanges = data.token !== originalState.token || data.projectId !== originalState.projectId;

  const showChangeTokenButton = !!originalState.token && data.configEditable;

  const handleError = (code: string, message: string) => {
    context.dispatch({ type: "hideModal" });

    const messageCapitalized = message[0].toUpperCase() + message.slice(1);
    setData((prev) => ({
      ...prev,
      error: { code, message: messageCapitalized },
    }));
  };

  // TODO: Validate configuration using LaunchDarkly API before saving
  // https://linear.app/dittowords/issue/DIT-5617/add-api-key-validation
  const saveCurrentConfiguration = async () => {
    setData((prev) => ({ ...prev, saveLoading: true }));

    const [request] = HttpConnections.updateLaunchDarklyConfig({
      token: data.token,
      projectId: data.projectId || "default",
    });
    try {
      const response = await request;

      updateWorkspaceInfo((info) => {
        info.integrations.launchDarkly = {
          // token should never be used on the front-end, only stored encrypted in the database
          token: "",
          tokenPreview: response.data.tokenPreview,
          projectId: data.projectId || "default",
        };
        return { ...info };
      });

      setData((prev) => ({ ...prev, saveLoading: false }));
    } catch (e: any) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      setData((prev) => ({
        ...prev,
        saveLoading: false,
        error: { code, message },
      }));
      setData((prev) => ({ ...prev, saveLoading: false }));
    }
  };

  const onSetupConnection = async () => {
    await saveCurrentConfiguration();
    context.dispatch({ type: "showModal", connected: false });
  };

  const onManageConnection = () => {
    setData((prev) => ({ ...prev, error: null }));
    context.dispatch({ type: "showModal", connected: true });
  };

  const closeManagementModal = () => context.dispatch({ type: "hideModal" });

  const onApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const token = e.target.value;
    setData((prev) => ({ ...prev, token, error: null }));
  };

  const onProjectIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = e.target.value;
    setData((prev) => ({ ...prev, projectId, error: null }));
  };

  const onRemoveConnection = async () => {
    const [request] = HttpConnections.removeLaunchDarklyConfig();
    await request;

    updateWorkspaceInfo((info) => {
      info.integrations.launchDarkly = undefined;
      return { ...info };
    });
  };

  const onEditConfig = () => {
    setData((prev) => ({ ...prev, configEditable: true }));
  };

  const onCancelEditConfig = () => {
    setData((prev) => ({
      ...prev,
      configEditable: false,
      token: originalState.token,
      projectId: originalState.projectId,
      tokenEditable: false,
      error: null,
    }));
  };

  const onSaveChanges = async () => {
    setData((prev) => ({ ...prev, saveLoading: true }));
    // only update the fields that have changed
    const launchDarklyDataToUpdate = {
      token: data.token !== originalState.token ? data.token : undefined,
      projectId: data.projectId !== originalState.projectId ? data.projectId ?? "default" : undefined,
    };

    try {
      const [request] = HttpConnections.updateLaunchDarklyConfig(launchDarklyDataToUpdate);
      const response = await request;

      updateWorkspaceInfo((info) => {
        info.integrations.launchDarkly = {
          token: "",
          tokenPreview: response.data.tokenPreview,
          projectId: data.projectId,
        };
        return { ...info };
      });

      // set the token preview
      setData((prev) => ({
        ...prev,
        token: response.data.tokenPreview,
        saveLoading: false,
        tokenEditable: false,
      }));
    } catch (e) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      handleError(code, message);
      setData((prev) => ({ ...prev, saveLoading: false }));
    }
  };

  const tokenInputRef = React.useRef<HTMLInputElement>(null);

  const onCancelChangeTokenButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      tokenEditable: false,
      token: originalState.token,
    }));
  };

  const onChangeTokenButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    setData((prev) => ({ ...prev, tokenEditable: true, token: "" }));
  };

  useEffect(
    // unfortunately have to use an effect here to ensure we only attempt
    // to focus the input after the component has re-rendered with tokenEditable
    // `true`, otherwise the focus call will occur while the input itself is
    // still disabled
    function focusTokenInput() {
      if (!data.tokenEditable) return;
      if (!tokenInputRef.current) return;
      tokenInputRef.current!.focus();
    },
    [data.tokenEditable]
  );

  return [
    data,
    {
      onSetupConnection,
      onManageConnection,
      onApiKeyChange,
      onProjectIdChange,
      onRemoveConnection,
      closeManagementModal,
      handleError,
      onSaveChanges,
      onCancelChangeTokenButtonClick,
      onChangeTokenButtonClick,
      onEditConfig,
      onCancelEditConfig,
      showRemoveConfirmationModal: () =>
        setData((prev) => ({
          ...prev,
          showRemoveConfirmationModal: true,
        })),
      hideRemoveConfirmationModal: () => setData((prev) => ({ ...prev, showRemoveConfirmationModal: false })),
    },
    { hasUnsavedChanges, tokenInputRef, showChangeTokenButton },
  ] as const;
};

interface ILaunchDarklyConfigFormProps {
  isConnected: boolean;
  state: ReturnType<typeof useLaunchDarklyConnectionState>;
}

const LaunchDarklyConfigForm = (props: ILaunchDarklyConfigFormProps) => {
  const [data, actions, computed] = props.state;

  const configEditable = data.configEditable || !props.isConnected;

  return (
    <div className={styles.section}>
      <label className={styles.inputLabel}>
        <div className={styles.inputLabelRow}>
          <span className={styles.text}>LaunchDarkly Access Token</span>
          {computed.showChangeTokenButton && (
            <div className={styles.changeTokenContainer}>
              {data.tokenEditable && (
                <ButtonSecondary
                  text="Cancel"
                  onClick={actions.onCancelChangeTokenButtonClick}
                  className={styles.cancelChangeTokenButton}
                />
              )}
              {!data.tokenEditable && (
                <ButtonSecondary
                  text={
                    <>
                      <AutorenewIcon className={styles.icon} /> Change Token
                    </>
                  }
                  onClick={actions.onChangeTokenButtonClick}
                  className={styles.changeTokenButton}
                />
              )}
            </div>
          )}
        </div>
        <input
          className={styles.input}
          type="text"
          value={data.token}
          onChange={actions.onApiKeyChange}
          placeholder="Insert access token"
          disabled={!data.tokenEditable}
          ref={computed.tokenInputRef}
        />
      </label>
      <label className={styles.inputLabel}>
        <span className={styles.inputLabelText}>LaunchDarkly Project ID</span>
        <input
          className={styles.input}
          type="text"
          value={data.projectId}
          onChange={actions.onProjectIdChange}
          placeholder="default"
          disabled={!configEditable}
        />
      </label>
      {data.error?.code === "platform-error" && (
        <div className={styles.error}>
          We received an error message from the LaunchDarkly API:{" "}
          <div className={styles.errorFromPlatform}>{data.error.message}</div>
        </div>
      )}
      <div className={styles.spacer} />
    </div>
  );
};

const useLaunchDarklyManagementModalState = (
  handleError: (code: string, message: string) => void,
  setNumConnectedVariants: (num: number) => void,
  setNumSyncedFlags: (num: number) => void
) => {
  const [data, setData] = useState<
    | {
        loading: true;
      }
    | {
        loading: false;
        response: HttpConnections.ILoadLaunchDarklyDataResponse;
      }
  >({ loading: true });

  React.useEffect(function loadLaunchDarklyData() {
    const [request] = HttpConnections.loadLaunchDarklyData();
    request
      .then((response) => setData({ loading: false, response: response.data }))
      .catch((e) => {
        const code = e.response?.data?.code || "unknown";
        const message = e.response?.data?.data || "An unknown error occurred";
        handleError(code, message);
      });
  }, []);

  const onUpdateConnection = async (
    updatedConnectionData: {
      flagId: string;
      flagName: string;
      syncedVariants: string[];
    }[]
  ) => {
    try {
      const [request] = HttpConnections.launchDarklySync({
        data: updatedConnectionData,
      });
      await request;

      const syncedVariantsSet = new Set<string>();
      let numSyncedFlags = 0;
      updatedConnectionData.forEach((flag) => {
        const flagIsSynced = flag.syncedVariants.length > 0;
        if (!flagIsSynced) return;

        numSyncedFlags++;
        flag.syncedVariants.forEach((variant) => {
          syncedVariantsSet.add(variant);
        });
      });

      setNumSyncedFlags(numSyncedFlags);
      setNumConnectedVariants(syncedVariantsSet.size);
    } catch (e) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      handleError(code, message);
    }
  };

  return [data, { onUpdateConnection }] as const;
};

interface ILaunchDarklyManagementModalProps {
  onClose: () => void;
  onBack: () => void;
  onRemoveConnection: () => void;
  handleError: (error: string, message: string) => void;
  isExistingConnection: boolean;
  setNumConnectedVariants: (num: number) => void;
  setNumSyncedFlags: (num: number) => void;
}

const LaunchDarklyManagementModal = (props: ILaunchDarklyManagementModalProps) => {
  const [data, actions] = useLaunchDarklyManagementModalState(
    props.handleError,
    props.setNumConnectedVariants,
    props.setNumSyncedFlags
  );
  if (data.loading) {
    return <React.Fragment />;
  }

  return (
    <ABIntegrationModal
      platformName="LaunchDarkly"
      foreignVariantName="variation"
      data={data.response.data}
      variants={data.response.variants}
      onBack={props.onBack}
      onClose={props.onClose}
      onUpdateConnection={async (data) => {
        await actions.onUpdateConnection(data);
        props.onClose();
      }}
      onRemoveConnection={props.onRemoveConnection}
      isExistingConnection={props.isExistingConnection}
      noFlagsFoundMessage="No feature flags found in your LaunchDarkly project."
    />
  );
};

export function NotConnected() {
  const state = useLaunchDarklyConnectionState();
  const [data, actions] = state;

  const saveConnectionButtonDisabled = !data.token || data.saveLoading;

  return (
    <>
      <div className={styles.contentSubheader}>Instructions</div>
      <div>
        <ol>
          <li className={styles.contentText}>
            Create a LaunchDarkly access token from{" "}
            <a href="https://app.launchdarkly.com/settings/authorization" target="_blank">
              the Authorization page{" "}
            </a>{" "}
            and insert it below. Ensure that your token has both read and write permissions.
          </li>
          <li className={styles.contentText}>
            Copy your LaunchDarkly project ID from{" "}
            <a href="https://app.launchdarkly.com/settings/projects" target="_blank">
              the Projects page
            </a>{" "}
            and insert it below. If not provided, the default project will be used.
          </li>
          <li className={styles.contentText}>
            Click <b>Set Up Connection</b> to configure how Ditto variants should sync with LaunchDarkly variations.
          </li>
        </ol>
        <a className={styles.link} href="https://www.dittowords.com/docs/a-b-testing-integrations" target="_blank">
          Detailed instructions
        </a>
        <LaunchDarklyConfigForm state={state} isConnected={false} />
        <button
          className={classNames({
            [styles.blueFilledButton]: true,
            [styles.finalCta]: true,
            [styles.disabled]: saveConnectionButtonDisabled,
          })}
          disabled={saveConnectionButtonDisabled}
          onClick={actions.onSetupConnection}
        >
          {data.saveLoading ? "Setting Up..." : "Set Up Connection"}
        </button>
      </div>
    </>
  );
}

export function Connected() {
  const state = useLaunchDarklyConnectionState();
  const context = useLaunchDarklyContext();
  const [data, actions, computed] = state;

  const [numConnectedVariants, setNumConnectedVariants] = useState(0);
  const [numSyncedFlags, setNumSyncedFlags] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(function loadLaunchDarklyData() {
    setFetchLoading(true);
    const [request] = HttpConnections.loadLaunchDarklyData();
    request
      .then((response) => {
        const data = response.data;

        const syncedVariantsSet = new Set<string>();
        let numSyncedFlags = 0;
        data.data.forEach((flag) => {
          const flagIsSynced = flag.syncedVariants.length > 0;
          if (!flagIsSynced) return;

          numSyncedFlags++;
          flag.syncedVariants.forEach((variant) => {
            syncedVariantsSet.add(variant);
          });
        });

        setNumSyncedFlags(numSyncedFlags);
        setNumConnectedVariants(syncedVariantsSet.size);
        setFetchLoading(false);
      })
      .catch((e) => {
        const code = e.response?.data?.code || "unknown";
        const message = e.response?.data?.data || "An unknown error occurred";
        actions.handleError(code, message);
      });
  }, []);

  if (fetchLoading) {
    return (
      <div className={styles.loadingContainer}>
        <img src={spinner} alt="Loading..." />
      </div>
    );
  }

  return (
    <>
      <div className={styles.section}>
        <h3>Connected Variants</h3>
        <p>
          A total of{" "}
          <strong>
            {numConnectedVariants} variant
            {numConnectedVariants === 1 ? "" : "s"}
          </strong>{" "}
          {numConnectedVariants === 1 ? "is " : "are "}
          currently synced to{" "}
          <strong>
            {numSyncedFlags} flag{numSyncedFlags === 1 ? "" : "s"}
          </strong>{" "}
          in LaunchDarkly.
        </p>
        <button
          className={classNames({
            [styles.blueFilledButton]: true,
          })}
          onClick={actions.onManageConnection}
        >
          Manage
        </button>
      </div>
      <div className={styles.section}>
        <LaunchDarklyConfigForm state={state} isConnected />
        {data.configEditable ? (
          <>
            <button
              className={classNames({
                [styles.blueFilledButton]: true,
                [styles.disabled]: !computed.hasUnsavedChanges || data.saveLoading,
              })}
              disabled={!computed.hasUnsavedChanges || data.saveLoading}
              onClick={actions.onSaveChanges}
            >
              {data.saveLoading ? "Saving..." : "Save Changes"}
            </button>
            <ButtonSecondary text="Cancel" onClick={actions.onCancelEditConfig} className={styles.cancelEditConfig} />
          </>
        ) : (
          <button className={styles.blueFilledButton} onClick={actions.onEditConfig}>
            Edit Properties
          </button>
        )}
      </div>
      <div className={styles.section}>
        <button className={styles.removeConnectionButton} onClick={actions.showRemoveConfirmationModal}>
          Remove Connection
        </button>
      </div>
      {context.showManagementModal && (
        <>
          <div className={styles.loadingContainer}>
            <img src={spinner} alt="Loading..." />
          </div>
          <LaunchDarklyManagementModal
            handleError={actions.handleError}
            onClose={actions.closeManagementModal}
            onBack={actions.closeManagementModal}
            isExistingConnection={context.connected}
            onRemoveConnection={async () => {
              await actions.onRemoveConnection();
              context.dispatch({ type: "hideModal" });
            }}
            setNumConnectedVariants={setNumConnectedVariants}
            setNumSyncedFlags={setNumSyncedFlags}
          />
        </>
      )}
      {data.showRemoveConfirmationModal && (
        <ConfirmationModal
          title={"Remove Connection"}
          body={"Are you sure you want to remove this connection?"}
          actionPrimary={"Remove"}
          onPrimary={actions.onRemoveConnection}
          actionSecondary={"Cancel"}
          onSecondary={actions.hideRemoveConfirmationModal}
          isLayered={true}
        />
      )}
    </>
  );
}
