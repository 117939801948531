import { Ditto } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface Props {
  addFigmaConnection: () => void;
  revokeFigmaConnection: () => void;
  isFigmaAuthenticated: boolean;
  isFigmaEditor: boolean;
}

export default function ConnectionsPanel(props: Props) {
  return (
    <Panel titleDittoComponentId="connections-title">
      <table className={style.table}>
        <tbody>
          <tr>
            <td className={style.title}>
              <Ditto componentId="figma-connection-label" />
            </td>
            <td className={style.value}>
              {props.isFigmaEditor && props.isFigmaAuthenticated ? (
                <div>
                  <Ditto componentId="connected" />
                  <a className={style.revokeConnection} onClick={props.revokeFigmaConnection}>
                    <Ditto componentId="revoke-connection-button" />
                  </a>
                </div>
              ) : (
                <span className={style.addConnection} onClick={props.addFigmaConnection}>
                  <Ditto componentId="add-connection-button" />
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
  );
}
