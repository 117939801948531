import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import * as SegmentEvents from "@shared/segment-event-names";
import classNames from "classnames";
import csv from "csvtojson";
import parseJson from "json-parse-even-better-errors";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import useSegment from "../../../../hooks/useSegment";
import { BillingContext } from "../../../../store/billingContext";
import FolderSelect from "../../../FolderSelect";
import ComponentLimitText from "../../../account-billing/ComponentLimitText";
import ButtonPrimary from "../../../button/buttonprimary";
import { ModalBody, ModalFooter } from "../../../shared/ModalBase";
import { CsvImportContext } from "../../CsvImportMapping/CsvImportContext";
import { IStepProps } from "../../useState";
import { useImportCreateRequest } from "../choose-action/useImportCreateRequest";
import FileDropzone, { MAX_SIZE } from "./FileDropzone";
import { FileUploaded } from "./FileUploaded";
import { useImportRequest } from "./importRequest";
import style from "./style.module.css";

const MAX_SIZE_MB = Math.floor(MAX_SIZE / 1024 / 1024);
export const ACCEPT = {
  "application/json": [".json"],
  "text/plain": [".strings"],
  "text/xml": [".xml"],

  // .tsv files are close to .csv files and could be commonly
  // uploaded by mistake, so we want to provide an expliciterror message
  "text/tab-separated-values": [".tsv"],
  "text/csv": [".csv"],
};

const supportedFormats = [
  { label: "Flat JSON", value: "flat" },
  { label: "Nested JSON", value: "nested" },
  { label: "Structured JSON", value: "structured" },
  { label: "Android XML", value: "xml" },
  { label: "iOS Strings", value: "ios-strings" },

  // .tsv files are close to .csv files and could be commonly
  // uploaded by mistake, so we want to provide an explicit error message
  { label: "TSV", value: "tsv", hide: true },
  { label: "CSV", value: "csv" },
];

// regex to parse the json parsing error message, which is in the format of
// [error description] at position x while parsing near [json snippet]
const errorRegex = /([\s\S]*) while parsing\s?(?:near)?\s?([\s\S]*)/;

const errors = (extension, error) => ({
  "invalid-format": (
    <span>
      This is not a file format we currently support. Please make sure your data conforms to one of our{" "}
      <a href="https://www.dittowords.com/docs/importing-string-files" target="_blank" rel="noreferrer">
        supported file types
      </a>
      .
    </span>
  ),
  "empty-name": <span>The file you uploaded contains a component with an empty name.</span>,
  "invalid-file-type": (
    <span>This is not a file format we currently support. Please try again with a supported format.</span>
  ),
  "invalid-file-type-tsv": (
    <span>We don't currently support uploading .tsv files. Please try again with a supported format.</span>
  ),
  "too-many-files": <span>Please upload a single file at a time.</span>,
  "too-big": (
    <span>
      The file you uploaded is too big. Please upload a file that is smaller than <strong>{MAX_SIZE_MB} MB</strong>.
    </span>
  ),
  "no-valid-files":
    error?.data && error.data.description?.match(errorRegex) ? (
      <>
        <div className={style.errorTitleContainer}>
          <ErrorIcon className={style.errorIcon} />
          <span className={style.errorTitle}>Error parsing JSON:</span>
        </div>
        <span className={style.errorDescription}>{error.data.description?.match(errorRegex)[1]}</span>
      </>
    ) : (
      <span>The file you uploaded is not valid. Please check it for formatting errors and try again.</span>
    ),
  "invalid-file-extension": (
    <span>
      This is not a file format {extension ? `(.${extension}) ` : ""}we currently support. Please try again with a
      supported format.
    </span>
  ),
  "invalid-tags": <span>The file you uploaded contains an invalid "tags" property.</span>,
  "invalid-notes": <span>The file you uploaded contains an invalid "notes" property.</span>,
  "duplicate-components": (
    <span>
      Additional duplicate components were detected during import; this may happen if another file was imported at the
      same time. Please try importing again.
    </span>
  ),
  "job-in-progress": (
    <div className={style.importInProgressError}>
      <span>Another import is in progress. Please wait for it to complete before importing more components.</span>
      <Spinner animation="border" role="status" className={style.importInProgress} />
    </div>
  ),
  "default-import-format-unsupported": (
    <span>
      We don't support importing files in the "full" format. Please make sure your data conforms to one of our{" "}
      <a href="https://www.dittowords.com/docs/importing-string-jsons" target="_blank" rel="noreferrer">
        three supported file types
      </a>
      .
    </span>
  ),
  "invalid-csv": (
    <span>The CSV file you uploaded is missing data. Please try again with a file that has at least one column.</span>
  ),
  "invalid-xml-import": <span>We ran into problems processing your XML file. Please contact support.</span>,
  default: <span>Oops, something went wrong! Please try again later.</span>,
});

function getFileExtension(filePath) {
  // Regular expression to match the file extension
  const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

  // Executing the regex on the filePath
  const matches = regex.exec(filePath);

  // If there's a match and it has a group, return the group (which is the extension)
  return matches && matches[1] ? matches[1] : "";
}

const FileUpload = ({ state, dispatch }: IStepProps) => {
  const segment = useSegment();

  const {
    componentLimits: { isOverComponentLimit },
  } = useContext(BillingContext);
  const csvImport = useContext(CsvImportContext);

  const localFileRef = useRef<object | null>(state.file || null);
  const [localState, setLocalState] = useState<{
    file: (typeof state)["file"];
    error: string | { code: string; data?: { description?: string; position?: number } } | null;
    loadingFileUpload: boolean;
    loading: boolean;
  }>({
    file: state.file || null,
    error: state.error || null,
    loadingFileUpload: false,
    loading: false,
  });
  const { file, error, loading, loadingFileUpload } = localState;

  const nextButtonText = loading ? "Loading..." : "Next -->";

  useEffect(() => {
    setLocalState((prev) => ({ ...prev, error: state?.error }));
  }, [state.error]);

  const [fileExtension, setFileExtension] = useState("");

  const errorMap = useMemo(() => errors(fileExtension, error), [fileExtension, error]);

  const getErrorCode = () => {
    if (!error) {
      return "";
    }

    if (typeof error === "object") {
      return error.code;
    }

    return error;
  };

  const getErrorMessage = () => {
    if (!error) {
      return "";
    }

    const code = getErrorCode();
    return errorMap[code] || errorMap.default;
  };

  const errorMessage = getErrorMessage();

  const onFileUpload = ([acceptedFile]) => {
    localFileRef.current = acceptedFile;

    if (!acceptedFile) {
      setLocalState((s) => ({
        ...s,
        error: "invalid-file-type-tsv",
        loadingFileUpload: false,
      }));
      return;
    }
    // .tsv files are close to .csv files and could be commonly
    // uploaded by mistake, so we want to provide an expliciterror message
    if (/\.tsv$/.test(acceptedFile.path)) {
      setLocalState((s) => ({
        ...s,
        error: "invalid-file-type-tsv",
        loadingFileUpload: false,
      }));
      return;
    }

    setLocalState({
      file: acceptedFile,
      error: null,
      loadingFileUpload: true,
      loading: false,
    });

    setFileExtension(getFileExtension(acceptedFile.name));

    if (acceptedFile.type === "text/csv") {
      setLocalState((s) => ({
        ...s,
        error,
        loadingFileUpload: false,
      }));

      dispatch({
        type: "IMPORT_RESPONSE",
        data: {
          existingComponentsCount: 0,
          components: [],
          format: "csv",
          file: acceptedFile,
          totalComponentsCount: 0,
          backgroundJobEntryId: null,
        },
      });

      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result !== "string") {
          throw new Error("Failed to parse valid CSV");
        }

        csv({
          noheader: true,
          output: "csv",
        })
          .fromString(reader.result)
          .then((parsedCsv) => {
            csvImport.setData(
              // remove empty rows
              parsedCsv.filter((row) => !row.every((c) => c === ""))
            );
          });
      };
      reader.readAsText(acceptedFile);
    }

    segment.track({
      event: SegmentEvents.JSON_IMPORT_FILE_ACCEPTED,
      properties: {
        file_name: acceptedFile.name,
        file_size: acceptedFile.size,
        file_type: acceptedFile.type,
      },
    });
  };

  const selectedFolderId = state.folderSelectState?.selectedFolder?._id;
  const importResponse = useImportRequest({ file, folderId: selectedFolderId ?? null });
  const importCreateRequest = useImportCreateRequest();

  useEffect(() => {
    if (!importResponse) {
      return;
    }

    // handle the case where the file input gets cleared before the import is complete
    if (!localFileRef.current) {
      return setLocalState((s) => ({
        ...s,
        file: null,
        error: null,
        loadingFileUpload: false,
      }));
    }

    if (!importResponse.ok) {
      return setLocalState((s) => ({
        ...s,
        file: null,
        error: importResponse.data ? { code: importResponse.code!, data: importResponse.data } : importResponse.code!,
        loadingFileUpload: false,
      }));
    }

    const { components, format, totalComponentsCount, existingComponentsCount, backgroundJobEntryId } =
      importResponse.data;

    dispatch({
      type: "IMPORT_RESPONSE",
      data: {
        existingComponentsCount,
        components,
        format,
        totalComponentsCount,
        backgroundJobEntryId,
        // include the file so that if the user presses "Back",
        // it will still reflect as being uploaded
        file: file!,
      },
    });

    segment.track({
      event: "JSON Import: Upload processed",
      properties: {
        num_components: totalComponentsCount,
        num_existing_components: existingComponentsCount,
        format,
      },
    });

    setLocalState((s) => ({ ...s, loadingFileUpload: false }));
  }, [importResponse, setLocalState]);

  const nextDisabled =
    loading || loadingFileUpload || !file || getErrorCode() === "job-in-progress" || isOverComponentLimit;

  const onNext = async () => {
    if (nextDisabled) {
      return;
    }

    if (state.format === "csv") {
      // In case the user goes back and forth
      csvImport.resetSelected();

      dispatch({
        type: "SET_STEP",
        step: "csv-mapping",
      });

      return;
    }

    setLocalState((s) => ({ ...s, loading: true }));

    if (!importResponse?.ok) {
      setLocalState((s) => ({ ...s, error: importResponse?.code ?? importResponse?.error ?? "" }));
      return;
    }

    const needsChooseAction = importResponse.data.existingComponentsCount > 0;
    if (needsChooseAction) {
      return dispatch({
        type: "SET_STEP",
        step: "choose-action",
      });
    }

    const response = await importCreateRequest(state.backgroundJobEntryId!);
    if (!response.success) {
      return;
    }

    const { components, componentsIgnored, ignoredComponentsCount } = response.data;

    return dispatch({
      type: "IMPORT_CREATE_RESPONSE",
      step: "preview",
      data: {
        components,
        componentsIgnored,
        ignoredComponentsCount,
      },
    });
  };

  const onRemoveFile = () => {
    // noop if attempt is made to remove file while loading next step
    if (loading) return;

    localFileRef.current = null;

    setLocalState({ file: null, error: null, loading: false, loadingFileUpload: false });
    dispatch({ type: "RESET_UPLOAD" });
  };

  const dropzone = {
    onDrop: onFileUpload,
    onDropRejected: (files) => {
      const getError = () => {
        if (files.length > 1) {
          return "too-many-files";
        }

        const [{ file }] = files;

        if (!Object.keys(ACCEPT).includes(file.type)) {
          return "invalid-file-type";
        }

        if (file.size > MAX_SIZE) {
          return "too-big";
        }
      };

      const error = getError() || "default";
      setLocalState((s) => ({ ...s, error, loadingFileUpload: false }));

      segment.track({
        event: "JSON Import: File rejected",
        properties: { error },
      });
    },
  };

  const errorJson = error && typeof error === "object" && error.data?.description?.match(errorRegex);

  return (
    <>
      <ModalBody>
        {isOverComponentLimit && <ComponentLimitText />}
        <p>
          Import your product’s existing strings into Ditto as components. With components, you can reuse, edit, and
          search strings across projects.
        </p>
        <FolderSelect {...(state.folderSelectState as any)} className={style.folderSelect} text="Import to" />
        {!file && <FileDropzone {...dropzone} />}
        {file && <FileUploaded onRemoveFile={onRemoveFile} loading={loadingFileUpload} file={file} />}
        {errorMessage && <div className={style.errorContainer}>{errorMessage}</div>}
        {errorJson && (
          <div>
            <span className={style.jsonDataTitle}>JSON Data</span>
            <pre className={style.jsonData}>{parseJson.noExceptions(errorJson[2]) ?? errorJson[2]}</pre>
          </div>
        )}
        <div className={style.supportedFormats}>
          <p className={style.supportedFormatsText}>Supported formats:</p>
          <ul>
            {supportedFormats
              .filter((f) => !f.hide)
              .map(({ label, value }) => {
                const isUploadedFormat = value === state.format;

                return (
                  <li key={label}>
                    <div style={{ display: "inline-block" }}>
                      <span
                        className={classNames({
                          [style.format]: true,
                          [style.uploadedFormat]: isUploadedFormat,
                        })}
                      >
                        {label}{" "}
                        {isUploadedFormat && (
                          <DoneIcon className={classNames([style.icon, style.uploadedFormatIcon])} />
                        )}
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <a
          href="https://dittowords.com/docs/importing-string-jsons"
          className={style.footerLink}
          target="_blank"
          rel="noreferrer"
        >
          <span>How are components imported?</span>
          <OpenInNewIcon className={classNames([style.icon, style.footerLinkIcon])} />
        </a>
        <ButtonPrimary
          text={nextButtonText}
          onClick={onNext}
          disabled={nextDisabled}
          loading={loading}
          data-testid="next-modal-screen"
        />
      </ModalFooter>
    </>
  );
};

export default FileUpload;
