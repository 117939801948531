import { useDndContext } from "@dnd-kit/core";
import { getEventCoordinates } from "@dnd-kit/utilities";
import classNames from "classnames";
import { CSSProperties, useEffect, useState } from "react";
import style from "./DragPreview.module.css";

export const DragPreview = ({ dragComps }: { dragComps: { _id: string; name: string }[] }) => {
  if (dragComps.length === 1) {
    return <SingleDragPreview dragComp={dragComps[0]} />;
  }

  return <MultiDragPreview dragComps={dragComps} />;
};

export const SingleDragPreview = ({ dragComp }: { dragComp: { _id: string; name: string } }) => {
  const { active, activeNodeRect, activatorEvent } = useDndContext();

  let previewTransform = "";
  if (activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates || !activeNodeRect) {
      return null;
    }

    const offsetX = activatorCoordinates.x - activeNodeRect.left;
    const offsetY = activatorCoordinates.y - activeNodeRect.top;
    previewTransform = `translate(${offsetX}px, ${offsetY}px)`;
  }

  const activeNodeWidth = activeNodeRect ? activeNodeRect.width : 0;
  const activeNodeHeight = activeNodeRect ? activeNodeRect.height : 0;

  const initialStyles = {
    width: `${activeNodeWidth}px`,
    height: `${activeNodeHeight}px`,
    transform: "",
  };

  // 1. on first render, set the width and height to that of the node we're dragging
  //    and set the transform to be nothing
  const [previewStyle, setPreviewStyle] = useState<CSSProperties>(initialStyles);

  // 2. on second render, set the width and height to our desired ending size
  //    and set the transform to be the offset from the mouse to the node
  //   (this will animate the preview to the mouse)
  useEffect(function setActivePreviewStyles() {
    setPreviewStyle({
      maxWidth: "300px",
      height: "30px",
      transform: previewTransform,
    });
  }, []);

  return (
    <div
      style={{
        width: activeNodeWidth,
        height: activeNodeHeight,
      }}
      className={style.activeRect}
    >
      <div
        style={previewStyle}
        className={classNames(style.dragPreview, style.fade, {
          [style.active]: active,
        })}
      >
        <span className={style.nameText}>{dragComp.name}</span>
      </div>
    </div>
  );
};

export const MultiDragPreview = ({ dragComps }: { dragComps: { _id: string; name: string }[] }) => {
  const { activeNodeRect, activatorEvent } = useDndContext();

  let previewTransform = "";
  if (activatorEvent) {
    const activatorCoordinates = getEventCoordinates(activatorEvent);

    if (!activatorCoordinates || !activeNodeRect) {
      return null;
    }

    const offsetX = activatorCoordinates.x - activeNodeRect.left;
    const offsetY = activatorCoordinates.y - activeNodeRect.top;
    previewTransform = `translate(${offsetX}px, ${offsetY}px)`;
  }

  // only list up to 4 components -- if more, last element should be "and x more"
  const comps = dragComps.slice(0, 4);
  if (dragComps.length > 4) {
    comps.push({ _id: "1234", name: `...and ${dragComps.length - 4} more` });
  }

  return (
    <div className={style.activeRect}>
      <div style={{ transform: previewTransform }} className={classNames(style.dragPreview, style.multiDrag)}>
        <div className={style.dragCount}>{dragComps.length}</div>
        {comps.map((dragComp) => (
          <div key={dragComp._id} className={style.listName}>
            {dragComp.name}
          </div>
        ))}
      </div>
    </div>
  );
};
