import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import classNames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import style from "./style.module.css";

interface OptionBase {
  name: string;
  isDisabled?: boolean;
}

interface SelectMenuProps<Option extends OptionBase> {
  options: Option[];
  icon?: JSX.Element;
  renderOption?: (o: Option) => JSX.Element;
  selected?: string;
  onSelect: (Option) => void;
  rootOption?: string;
  onRootSelect?: () => void;
  className?: string;
  optionClassName?: string;
  optionContainerClassName?: string;
  align?: "right" | "left";
}

const SelectMenu = <Option extends OptionBase>(props: SelectMenuProps<Option>) => {
  const {
    options,
    icon,
    selected,
    onSelect,
    rootOption,
    onRootSelect,
    renderOption = (o) => <>{o.name}</>,
    className,
    optionClassName,
    optionContainerClassName,
    align = "right",
  } = props;

  const dropdownProps: React.ComponentProps<typeof Dropdown> = {
    ...(align === "right" ? { alignRight: true } : {}),
    className: classNames([style.container, className]),
  };

  return (
    <Dropdown {...dropdownProps}>
      <Dropdown.Toggle data-testid="select-menu">
        {icon ? (
          icon
        ) : (
          <>
            {selected}
            <ExpandMoreIcon className={style.icon} />
          </>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className={classNames([style.menu, optionContainerClassName])}>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            active={selected === option.name}
            disabled={option.isDisabled}
            className={classNames([style.option, optionClassName])}
            onClick={() => onSelect(option)}
          >
            {option.isDisabled && <LockIcon className={style.icon} />}
            {selected === option.name && <CheckIcon className={style.icon} />}
            {renderOption(option)}
          </Dropdown.Item>
        ))}
        {rootOption && (
          <>
            <Dropdown.Item className={style.rootOption} onClick={onRootSelect}>
              {rootOption}
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectMenu;
