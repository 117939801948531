import classNames from "classnames";
import React from "react";
import ReactTags from "react-tag-autocomplete";
import { IFPermissionGroupWithUsers } from "../../../../shared/types/User";
import style from "./style.module.css";

interface PermissionGroupsInputProps {
  className?: string;
  disabled?: boolean;
  permissionGroupOptions: IFPermissionGroupWithUsers[];
  currentGroups: IFPermissionGroupWithUsers[];
  setCurrentGroups: React.Dispatch<IFPermissionGroupWithUsers[]>;
}

type Tag = { id: string; name: string };

const PermissionGroupsInput = (props: PermissionGroupsInputProps) => {
  const { currentGroups, className, permissionGroupOptions, setCurrentGroups, disabled } = props;

  const onDelete = (index: number) => {
    const newGroups = currentGroups.filter((_, i) => i !== index);
    setCurrentGroups(newGroups);
  };

  const onAddition = (tag: Tag) => {
    const newGroup = permissionGroupOptions.find((group) => group._id === tag.id);
    if (!newGroup) return;

    const newGroups = currentGroups.concat(newGroup);
    setCurrentGroups(newGroups);
  };

  const tags = currentGroups.map((group) => ({
    id: group._id,
    name: group.name,
  }));

  const suggestions = permissionGroupOptions
    // don't show groups that are already selected
    .filter((group) => !currentGroups.find((g) => g._id === group._id))
    // don't show built-in groups if there is already a built-in group selected
    .filter((group) => {
      const alreadyHasBuiltIn = currentGroups.find((g) => g.built_in);
      return !(alreadyHasBuiltIn && group.built_in);
    })
    .map((group) => ({
      id: group._id,
      name: group.name,
    }));

  return (
    <div className={classNames(style.wrapper, className)}>
      <ReactTags
        autoresize
        tags={tags}
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
        tagComponent={PermissionGroupTag}
        suggestionComponent={GroupSuggestion}
        placeholderText="Search for a permission group..."
        minQueryLength={0}
        isDisabled={disabled}
        maxSuggestionsLength={1000}
      />
    </div>
  );
};

const PermissionGroupTag = (props: { tag: Tag; onDelete: () => void }) => {
  const { tag, onDelete } = props;

  return (
    <div id={tag.id} className={style.group} onClick={onDelete}>
      <span className={style.groupName}>{tag.name}</span>
    </div>
  );
};

const GroupSuggestion = (props: { item: { _id: string; name: string } }) => {
  const { item } = props;
  return (
    <div id={item._id} className={style.groupSuggestion}>
      <span className={style.groupName}>{item.name}</span>
    </div>
  );
};

export default PermissionGroupsInput;
