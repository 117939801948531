import { IComponentRename, IComponentRenameMap } from "@shared/ditto-events";
import {
  IBlockTreeItem,
  IComponentTreeItem,
  IFTreeItem,
  IGroupTreeItem,
  IToBeLoadedTreeItem,
} from "@shared/types/http/Component";
import { sha256 } from "js-sha256";
export const COMPONENT_LIBRARY_COMPONENTS_PER_PAGE = 150;

/**
 * Regex for matching against queries in the component modal; if matched,
 * will enable templates-only mode.
 */
export const COMPONENT_MODAL_TEMPLATE_MODE_REGEX = /^template:/i;

/**
 * @returns [groupName, blockName, componentName]
 */
export function getNameParts(name: string) {
  const layers = name.split("/");
  if (layers.length === 1) {
    return ["", "", layers[0]];
  }
  if (layers.length === 2) {
    return [layers[0], "", layers[1]];
  }
  return [layers[0], layers[1], layers[2]];
}

const getSegment = (str: string, fixedLength = 20) => {
  let segment = str.slice(0, fixedLength);
  if (segment.length === fixedLength) {
    return segment;
  }
  return segment + "Z".repeat(fixedLength - segment.length);
};

export function generateSortKey(componentName: string, componentFolderId: string | null) {
  const [groupName, blockName, baseName] = getNameParts(componentName);

  // TODO: after we test to confirm that this generally works, we'll refactor to use componentFolderName
  // instead of componentfolderId; this currently suffers from the same problems we have in prod with the
  // sortKey generation

  return (
    (componentFolderId ? "a" : "b") +
    getSegment(groupName || "") +
    getSegment(blockName || "") +
    getSegment(baseName || "")
  );
}

export function getRenameRecord(componentId: string, oldName: string, newName: string): IComponentRename {
  const oldNameParts = getNameParts(oldName);
  const newNameParts = getNameParts(newName);

  const oldNames = {
    groupName: oldNameParts[0],
    blockName: oldNameParts[1],
    componentName: oldNameParts[2],
  };
  const newNames = {
    groupName: newNameParts[0],
    blockName: newNameParts[1],
    componentName: newNameParts[2],
  };

  return {
    componentId,
    oldNames,
    newNames,
  };
}

// Takes a string, and returns a deterministic unique ID
export function getDeterministicUniqueId(inputString: string) {
  // Create a SHA-256 hash of the input (synchronous)
  const hash = sha256.create().update(inputString).array();

  // Take the first 18 bytes of the hash
  const truncatedHash = hash.slice(0, 18);

  // Encode the truncated hash in base64
  const base64Encoded = btoa(String.fromCharCode(...truncatedHash));
  return base64Encoded;
}

export type ComponentNames = {
  groupName: string;
  blockName: string;
  componentName: string;
};

export type ComponentPair = { _id: string; name: string };

export function getComponentNamesMap(componentPairs: ComponentPair[]): Record<string, ComponentNames> {
  const componentNamesMap: Record<string, ComponentNames> = {};

  componentPairs.forEach((pair) => {
    const [groupName, blockName, componentName] = getNameParts(pair.name);
    const componentId = pair._id;

    componentNamesMap[componentId] = {
      groupName,
      blockName,
      componentName,
    };
  });

  return componentNamesMap;
}

export function getGroupTreeItem(groupName: string, children?: IFTreeItem[]): IGroupTreeItem {
  const id = getDeterministicUniqueId(groupName);
  return {
    id,
    name: groupName,
    type: "group" as const,
    children: children || [getToBeLoadedTreeItem(id)],
  };
}

export function getBlockTreeItem(blockName: string, groupName: string, children?: IFTreeItem[]): IBlockTreeItem {
  const id = getDeterministicUniqueId(`${groupName}/${blockName}`);
  return {
    id,
    name: blockName,
    type: "block" as const,
    children: children || [getToBeLoadedTreeItem(id)],
  };
}

export function getComponentTreeItemWithRenameMap(
  componentId: string,
  componentRenameMap: IComponentRenameMap
): IComponentTreeItem {
  const renameRecord = componentRenameMap[componentId];
  return {
    id: componentId,
    name: renameRecord.newNames.componentName,
    type: "component" as const,
    selected: false,
  };
}

export function getComponentTreeItem(componentId: string, componentName: string): IComponentTreeItem {
  return {
    id: componentId,
    name: componentName,
    type: "component" as const,
    selected: false,
  };
}

export function getToBeLoadedTreeItem(parentId: string): IToBeLoadedTreeItem {
  return {
    id: `${parentId}-to-be-loaded`,
    name: "To Be Loaded",
    type: "to-be-loaded" as const,
  };
}

function getUniqueId() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function getLoadingSpinnerTreeItem() {
  return {
    id: getUniqueId(),
    name: "Loading...",
    type: "loading" as const,
    children: [],
  };
}
