import React from "react";
const HomeRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M180.001-200v-353.846q0-17.173 7.683-32.538 7.683-15.365 21.24-25.307l227.692-171.538q18.953-14.462 43.322-14.462 24.369 0 43.446 14.462l227.692 171.538q13.557 9.942 21.24 25.307 7.683 15.365 7.683 32.538V-200q0 24.538-17.731 42.268-17.73 17.731-42.268 17.731H592.307q-15.365 0-25.759-10.395-10.394-10.394-10.394-25.759v-195.384q0-15.366-10.395-25.76-10.394-10.394-25.759-10.394h-80q-15.365 0-25.759 10.394-10.395 10.394-10.395 25.76v195.384q0 15.365-10.394 25.759-10.394 10.395-25.759 10.395H240q-24.538 0-42.268-17.731-17.731-17.73-17.731-42.268Z" />
    </svg>
  );
};

export default HomeRounded;
