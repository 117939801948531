import { useEffect, useRef, useState } from "react";
import http, { API } from "../../http";
import { updateApiId } from "../../http/component_folder";

export interface ComponentFolderFromBackend {
  _id: string;
  name: string;
  workspace_id: string;
  apiID: string;
}

export interface ComponentFolder extends ComponentFolderFromBackend {
  component_ids: string[];
}

interface Props {
  refreshLibraryHistory: () => void;
}

type FoldersInUpdate = Omit<ComponentFolder, "component_ids"> & {
  component_ids?: string[];
};

const useComponentFolders = () => {
  const cache = useRef<ComponentFolder[]>([]);
  const [folders, setFolders] = useState<ComponentFolder[]>([]);

  /**
   * Updates component folders in state.
   * @param foldersOrFoldersCallback an array of folder objects or a callback that accepts the current folders
   * and returns a new array of folders.
   * @param updateCache a boolean indicating whether or not the cache should be updated, defaulting to `true`.
   * If `false`, the effect of the update is ephemeral, as the next time this function runs it will pull
   * folder data from the unaffected cache.
   */
  const updateComponentFolders = (
    foldersOrFoldersCallback: FoldersInUpdate[] | ((folders: ComponentFolder[]) => FoldersInUpdate[]),
    updateCache = true
  ) => {
    const existingFoldersMap = cache.current.reduce((m, f) => m.set(f._id, f), new Map<string, ComponentFolder>());

    const folders = Array.isArray(foldersOrFoldersCallback)
      ? foldersOrFoldersCallback
      : foldersOrFoldersCallback(cache.current);

    const componentFolders = folders.map((f) => ({
      component_ids: f.component_ids || existingFoldersMap.get(f._id)?.component_ids || [],
      ...f,
    }));

    setFolders(componentFolders);

    if (updateCache) {
      cache.current = componentFolders;
    }
  };

  const updateComponentFolderCounts = (components: { _id: string; folder_id: string | null }[]) => {
    const componentIdsByFolderId = components.reduce(
      (m, c) => m.set(c.folder_id, [...(m.get(c.folder_id) || []), c._id]),
      new Map<string | null, string[]>()
    );

    const updatedFolders = cache.current.map((f) => ({
      ...f,
      component_ids: componentIdsByFolderId.get(f._id) || [],
    }));

    cache.current = updatedFolders;
    setFolders(updatedFolders);
  };

  const findComponentFolder = (folderId: string) => cache.current.find((f) => f._id === folderId);

  return [
    folders,
    {
      updateComponentFolders,
      updateComponentFolderCounts,
      findComponentFolder,
    },
  ] as const;
};

export const useComponentFolder = (props: Props) => {
  const { refreshLibraryHistory } = props;

  const [showComponentFolderModal, setShowComponentFolderModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [componentFolders, { updateComponentFolders, updateComponentFolderCounts, findComponentFolder }] =
    useComponentFolders();

  const updateFoldersByComponents = (
    components: { _id: string; folder_id: string | null }[],
    callback: (folder: ComponentFolder) => boolean
  ) => {
    updateComponentFolderCounts(components);
    updateComponentFolders((folders) => folders.filter(callback), false);
  };

  const [selectedFolder, setSelectedFolder] = useState<ComponentFolder | null>(null);

  const cachedFolderId = useRef("");

  const fetchComponentFolders = async () => {
    try {
      const { url } = API.componentFolder.get.folders;
      const { data } = await http.get<ComponentFolder[]>(url);
      updateComponentFolders(data);
    } catch (error) {
      console.log("Error fetching component folders", error);
    }
    setLoaded(true);
  };

  useEffect(() => {
    fetchComponentFolders();
  }, []);

  const handleCreateComponentFolder = async (name: string, componentIds: string[] = []) => {
    const { url, body } = API.componentFolder.post.createFolder;
    const { data } = await http.post<ComponentFolder>(url, body(name, componentIds));

    updateComponentFolders((folders) => [...folders, data].sort((a, b) => a.name.localeCompare(b.name)));
    closeComponentFolderModal();

    refreshLibraryHistory();

    return data._id;
  };

  /**
   * Select one of the available folders by its id
   * @param folder_id id of the folder to select
   */
  const selectFolder = (folder_id: string | null) => {
    if (!loaded) {
      cachedFolderId.current = folder_id || "";
      return;
    }

    if (!folder_id) {
      setSelectedFolder(null);
      return;
    }

    const folder = findComponentFolder(folder_id) || null;
    setSelectedFolder(folder);
  };

  /**
   * Version of selectFolder that guarantees that the component folders have been
   * loaded before selecting the folder, then returns that folder
   */
  const selectFolderAsync = async (folder_id: string | null) => {
    if (!loaded) {
      await fetchComponentFolders();
    }
    if (!folder_id) {
      setSelectedFolder(null);
      return;
    }

    const folder = findComponentFolder(folder_id);
    setSelectedFolder(folder || null);

    return folder;
  };

  // if selectFolder is called before we've loaded the componentFolders, it
  // caches the folderId, then this effect calls selectFolder again once the folders are loaded
  useEffect(
    function selectCachedFolder() {
      if (cachedFolderId.current && loaded) {
        selectFolder(cachedFolderId.current);
        cachedFolderId.current = "";
      }
    },
    [loaded, cachedFolderId.current]
  );

  const openComponentFolderModal = () => setShowComponentFolderModal(true);
  const closeComponentFolderModal = () => setShowComponentFolderModal(false);

  const handleRenameComponentFolder = async (name: string, folder_id: string) => {
    // first, we update the folder in memory, to make the UI more responsive
    if (selectedFolder && selectedFolder._id === folder_id) {
      setSelectedFolder((prev) => {
        if (!prev) return null;
        return { ...prev, name };
      });
    }

    const { url, body } = API.componentFolder.put.updateFolder;
    const { data } = await http.put(url(folder_id), body(name));

    updateComponentFolders((folders) =>
      folders.map((folder) => {
        if (folder._id === folder_id) {
          return { ...folder, name: data.name };
        }
        return folder;
      })
    );

    // if we're renaming the currently selected folder, update it as well
    if (selectedFolder && selectedFolder._id === folder_id) {
      setSelectedFolder((prev) => (prev === null ? prev : { ...prev, name: data.name }));
    }

    refreshLibraryHistory();
  };

  const handleUpdateComponentFolderApiId = async (folderId: string, apiId: string) => {
    const [request] = updateApiId({
      folderId,
      apiId,
    });

    try {
      const { data } = await request;
      updateComponentFolders((folders) =>
        folders.map((folder) => {
          if (folder._id === folderId) {
            return { ...folder, apiID: data.apiID };
          }
          return folder;
        })
      );

      if (selectedFolder && selectedFolder._id === folderId) {
        setSelectedFolder((prev) => {
          if (!prev) return null;
          return { ...prev, apiID: data.apiID };
        });
      }

      refreshLibraryHistory();
      return "success";
    } catch (error) {
      return error.response.data.message;
    }
  };

  /**
   * Deletes a folder from both the backend and the frontend, and returns the
   * ids of the components that were in the folder
   */
  const deleteComponentFolder = async (folder_id: string) => {
    const { url } = API.componentFolder.delete.deleteFolder;
    await http.delete(url(folder_id));

    updateComponentFolders((folders) => folders.filter((folder) => folder._id !== folder_id));
  };

  const addComponentsToFolder = async (folder_id: string, component_ids: string[]) => {
    updateComponentFolders((folders) =>
      folders.map((folder) => {
        if (folder._id === folder_id) {
          return {
            ...folder,
            component_ids: [...folder.component_ids, ...component_ids],
          };
        }
        return folder;
      })
    );
  };

  /**
   * Remove the specified component ids from all folders
   */
  const removeComponentsFromFolders = async (component_ids: string[]) => {
    const set = new Set(component_ids);

    updateComponentFolders((folders) =>
      folders.map((folder) => ({
        ...folder,
        component_ids: folder.component_ids.filter((id) => !set.has(id)),
      }))
    );
  };

  /**
   * Adds a list of components to a folder in both the backend and the frontend
   */
  const moveComponentsToFolder = async (folder_id: string, component_ids: string[]) => {
    const { url, body } = API.componentFolder.post.moveComponentsToFolder;
    await http.post(url(folder_id), body(component_ids));

    // first, remove the components from whatever folder they're in
    removeComponentsFromFolders(component_ids);
    // then add them to the new folder
    addComponentsToFolder(folder_id, component_ids);
  };

  return {
    componentFolders,
    showComponentFolderModal,
    openComponentFolderModal,
    closeComponentFolderModal,
    handleCreateComponentFolder,
    selectedFolder,
    selectFolder,
    selectFolderAsync,
    handleRenameComponentFolder,
    handleUpdateComponentFolderApiId,
    deleteComponentFolder,
    moveComponentsToFolder,
    removeComponentsFromFolders,
    addComponentsToFolder,
    updateFoldersByComponents,
  };
};

export default useComponentFolder;
