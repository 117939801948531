import AutoFixHigh from "@shared/frontend/Editor/HighlightButtons/assets/auto-fix-high";
import classnames from "classnames";
import type { DittoComponentLibraryProps } from "ditto-react/dist/components/Ditto";
import React, { useRef, useState } from "react";
import AnimatedEllipsis from "../AnimatedEllipsis";
import KebabMenu from "../KebabMenu";
import style from "./style.module.css";

export type DisplayState = "COLLAPSED" | "PREVIEW" | "EXPANDED";

interface Item {
  id: string;
  text: string;
  count?: number;
}

interface AutomatedChangeItemProps {
  type: "COMPONENTS" | "FRAMES";
  headText: React.ReactNode;
  timeAgo: React.ReactNode;
  items: Item[];
  itemOnClick?: (item: Item) => void;
  truncationLimit: number;
  createdAt: string;
  inProgress: boolean;
  reverted?: boolean;
  automatedSettingsOnClick?: () => void;
  bulkRevertOnClick?: () => void;
  bulkRevertUnavailableWhyOnClick?: () => void;
  inPlugin?: boolean;
  components: {
    DittoComponent: (props: DittoComponentLibraryProps) => JSX.Element;
  };
}

function AutomatedChangeItem(props: AutomatedChangeItemProps) {
  const { DittoComponent } = props.components;

  const [displayState, setDisplayState] = useState<DisplayState>("COLLAPSED");
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [startedRevert, setStartedRevert] = useState(false);

  // We track if the user pressed show more because if they collapse
  // and re-expand, we don't want to show the "show more" button again
  const hasPressedShowMore = useRef(false);

  const bulkRevertTimeLeft = getBulkRevertTimeLeft(props.createdAt);
  const bulkRevertEnabled = !props.reverted && bulkRevertTimeLeft > 0;

  const componentsNotInProgress = !props.inProgress && props.type !== "FRAMES";

  const showAutomationSettingsOption = Boolean(props.automatedSettingsOnClick);
  const showBulkRevertOption = !props.reverted && componentsNotInProgress && props.items.length > 0;

  const showShowAllButton =
    !props.reverted && !props.inProgress && displayState === "COLLAPSED" && props.items.length > 0;

  return (
    <div
      className={classnames({
        [style.container]: true,
        [style.lightBackground]: displayState === "PREVIEW" || displayState === "EXPANDED",
        [style.smallerFontSize]: Boolean(props.inPlugin),
        [style.reverted]: !!props.reverted,
      })}
      onMouseLeave={() => setShowContextMenu(false)}
    >
      <div className={style.row}>
        <div className={style.dittoMagicButton}>
          <AutoFixHigh fill="#487EE6" height="14" width="14" />
          <span>Ditto</span>
        </div>
        {!props.inProgress && <div className={style.timeAgo}>{props.timeAgo}</div>}
        {(showAutomationSettingsOption || showBulkRevertOption) && (
          <div onClick={() => setShowContextMenu(!showContextMenu)}>
            <KebabMenu />
          </div>
        )}
        {showContextMenu && (
          <div
            className={style.contextMenu}
            onClick={() => setShowContextMenu(false)}
            onMouseLeave={() => setShowContextMenu(false)}
          >
            {showAutomationSettingsOption && (
              <div className={style.contextItem} onClick={props.automatedSettingsOnClick}>
                <DittoComponent componentId="automation-settings-button" />
              </div>
            )}
            {showBulkRevertOption && (
              <>
                {componentsNotInProgress && (
                  <div
                    className={classnames({
                      [style.row]: true,
                      [style.contextItem]: true,
                      [style.disabled]: startedRevert || bulkRevertTimeLeft <= 0,
                    })}
                    onClick={() => {
                      if (props.bulkRevertOnClick && !startedRevert && bulkRevertTimeLeft > 0) {
                        props.bulkRevertOnClick();
                        setStartedRevert(true);
                      }
                    }}
                  >
                    {bulkRevertEnabled && bulkRevertTimeLeft > 0 ? (
                      <div className={classnames({ [style.bulkRevert]: true })}>
                        <div>
                          {startedRevert ? (
                            <span className={style.reverting}>
                              <DittoComponent componentId="reverting" />
                              <AnimatedEllipsis />
                            </span>
                          ) : (
                            <DittoComponent componentId="bulk-revert" />
                          )}
                        </div>
                        <div className={style.timeLeft}>
                          <DittoComponent
                            componentId="bulk-time-left"
                            variables={{ bulkRevertTimeLeft }}
                            count={bulkRevertTimeLeft}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={style.row}>
                        <div className={style.bulkRevertUnavailable}>
                          <DittoComponent componentId="bulk-revert-unavailable" />
                        </div>
                        <div className={style.why} onClick={props.bulkRevertUnavailableWhyOnClick}>
                          <DittoComponent componentId="why" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={classnames({
          [style.content]: true,
          [style.contentFrame]: props.type === "FRAMES",
        })}
      >
        <div>
          {props.reverted && (
            <span className={classnames({ [style.info]: true, [style.black]: true })}>Reverted: </span>
          )}
        </div>
        <div
          onClick={() => {
            if (props.items.length === 0) return;

            if (displayState !== "COLLAPSED") {
              setDisplayState("COLLAPSED");
            } else {
              if (hasPressedShowMore.current) {
                setDisplayState("EXPANDED");
              } else {
                setDisplayState("PREVIEW");
              }
            }
          }}
          className={style.info}
        >
          <span className={classnames({ [style.info]: true, [style.disabled]: props.reverted })}>{props.headText}</span>
          {displayState !== "COLLAPSED" && props.items.length > 0 && <DownChevron />}
        </div>
        {showShowAllButton && (
          <div
            className={style.showAll}
            onClick={() => {
              if (hasPressedShowMore.current) {
                setDisplayState("EXPANDED");
              } else {
                setDisplayState("PREVIEW");
              }
            }}
          >
            <DittoComponent componentId="show-all" />
          </div>
        )}
        {!props.inProgress && displayState === "PREVIEW" && (
          <>
            {props.items.slice(0, 7).map((i) => (
              <Item
                type={props.type}
                truncationLimit={props.truncationLimit}
                i={i}
                key={i.text}
                onClick={props.itemOnClick}
              />
            ))}
            {props.items.length > 7 && (
              <div className={classnames([style.row, style.item])}>
                <div
                  className={style.showMore}
                  onClick={() => {
                    setDisplayState("EXPANDED");
                    hasPressedShowMore.current = true;
                  }}
                >
                  <DittoComponent componentId="show-more" variables={{ count: props.items.length - 7 }} />
                  <DownArrow />
                </div>
              </div>
            )}
          </>
        )}
        {!props.inProgress &&
          displayState === "EXPANDED" &&
          props.items.map((i) => (
            <Item type={props.type} i={i} truncationLimit={props.truncationLimit} onClick={props.itemOnClick} />
          ))}
      </div>
    </div>
  );
}

function Item(props: {
  i: Item;
  type: "COMPONENTS" | "FRAMES";
  truncationLimit: number;
  onClick?: (item: Item) => void;
}) {
  const truncatedText =
    props.i.text.length > props.truncationLimit ? `${props.i.text.slice(0, props.truncationLimit)}...` : props.i.text;
  return (
    <div
      className={classnames([style.row, style.item])}
      key={props.i.text}
      onClick={(e) => {
        props.onClick?.(props.i);
        e.stopPropagation();
      }}
    >
      {props.type === "FRAMES" && <FrameIcon />}
      <div className={style.itemText}>{truncatedText}</div>
      {(props.i.count || 0) > 0 && <div className={style.itemCount}>{props.i.count}</div>}
    </div>
  );
}

function DownArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M10 6L9.295 5.295L6.5 8.085V2H5.5V8.085L2.71 5.29L2 6L6 10L10 6Z" fill="#B7B7B8" />
    </svg>
  );
}

function FrameIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M1.42857 6.42857H0V10H3.57143V8.57143H1.42857V6.42857ZM0 3.57143H1.42857V1.42857H3.57143V0H0V3.57143ZM8.57143 8.57143H6.42857V10H10V6.42857H8.57143V8.57143ZM6.42857 0V1.42857H8.57143V3.57143H10V0H6.42857Z"
        fill="#B7B7B8"
      />
    </svg>
  );
}

function DownChevron() {
  return (
    <svg
      className={style.downChevron}
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
    >
      <path
        d="M0.94 0.0297852L4 3.08312L7.06 0.0297852L8 0.969785L4 4.96979L0 0.969785L0.94 0.0297852Z"
        fill="#B7B7B8"
      />
    </svg>
  );
}

function getBulkRevertTimeLeft(dateString) {
  const pastDate = new Date(dateString);
  const currentDate = new Date();

  // Difference in milliseconds
  const difference = currentDate.getTime() - pastDate.getTime();

  // Convert milliseconds to minutes
  const differenceInMinutes = difference / 60000;

  // Calculate minutes left until 10 minutes have passed
  const minutesLeft = Math.ceil(10 - differenceInMinutes);

  return minutesLeft > 0 ? minutesLeft : 0;
}

export { AutomatedChangeItem };
