import { DRAFTED_GROUPS_PAGE_NAME } from "@shared/types/ActualDocument";
import { useCallback, useMemo, useState } from "react";

export const DRAFTED_GROUPS_PAGE_ID = "__DRAFT__";
export const DRAFTED_GROUPS_PAGE: PageSelected = {
  id: DRAFTED_GROUPS_PAGE_ID,
  name: `✍️  ${DRAFTED_GROUPS_PAGE_NAME}`,
};

export const ALL_PAGE_ID = "__ALL__";
export const ALL_PAGE_NAME = "All Pages";
export const ALL_PAGE: PageSelected = {
  id: ALL_PAGE_ID,
  name: ALL_PAGE_NAME,
};

export const isDraftedGroupsPage = (page: PageSelected) => page.id === DRAFTED_GROUPS_PAGE_ID;

export type PageNotSelected = null;
export type PageSelected = {
  id: string;
  name: string;
};

export type SelectedPage = PageNotSelected | PageSelected;

export interface PageState {
  selectedPage: React.StatePair<SelectedPage>;
}

export interface PageStateArgs {
  initialValues?: {
    selectedPage?: PageSelected;
  };
}

const NULL_SELECTED_PAGE_WARNING = `An attempt was made to set \`selectedPage\` to null. Defaulting to ${DRAFTED_GROUPS_PAGE_NAME} page.`;

const usePageState = (args?: PageStateArgs) => {
  const [selectedPageValue, selectedPageDefaultSetter] = useState<SelectedPage>(
    args?.initialValues?.selectedPage || null
  );

  /**
   * Setting selectedPage to `null` or `undefined` is not allowed, and if passed,
   * the drafted groups page will always be selected as a fallback.
   *
   * This prevents strange bugs that occur when `selectedPage` is unexpectedly
   * unset, which among other things will cause the group navigation in the
   * left sidebar to completely disappear.
   */
  const setSelectedPage: typeof selectedPageDefaultSetter = useCallback(
    (arg) => {
      if (typeof arg === "function") {
        selectedPageDefaultSetter((prevState) => {
          const result = arg(prevState);
          if (!result) {
            console.warn(NULL_SELECTED_PAGE_WARNING);
            return DRAFTED_GROUPS_PAGE;
          }

          return result;
        });

        return;
      }

      if (!arg) {
        console.warn(NULL_SELECTED_PAGE_WARNING);
        selectedPageDefaultSetter(DRAFTED_GROUPS_PAGE);
        return;
      }

      selectedPageDefaultSetter(arg);
    },
    [selectedPageDefaultSetter]
  );

  const selectedPage: PageState["selectedPage"] = useMemo(
    () => [selectedPageValue, setSelectedPage],
    [selectedPageValue, setSelectedPage]
  );

  return { selectedPage };
};

export default usePageState;
