import { useWorkspace } from "@/store/workspaceContext";
import React from "react";
import ImportStringsPanel from "../ImportStringsPanel/ImportStringsPanel";
import UseCLIPanel from "../UseCLIPanel/UseCLIPanel";
import style from "./style.module.css";

const GettingStartedTab = () => {
  const { workspaceInfo } = useWorkspace();
  const devToolsEnabled = workspaceInfo?.devTools;
  return (
    <div className={style.gettingStarted}>
      <ImportStringsPanel />
      <UseCLIPanel devToolsEnabled={devToolsEnabled} />
    </div>
  );
};

export default GettingStartedTab;
