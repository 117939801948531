import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import React from "react";
import ErrorMsg from "./ErrorMsg";

interface Props {
  children: React.ReactNode;
  componentName: string;
  type: "Selection" | "Page" | "App";
}

const ErrorBoundary = ({ children, componentName, type }: Props) => {
  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();

  const onError = (error) => {
    console.error(error);
    if (type === "App") {
      window?.analytics?.track("App Crash", {
        componentName,
        user_name: user.name,
        plan: workspaceInfo?.plan,
        workspace_name: workspaceInfo?.name,
        workspace_id: workspaceInfo?._id,
        ts: Date.now() - 5000,
        datadogRUMInternalContext: datadogRum.getInternalContext(Date.now()),
      });
    } else {
      window?.analytics?.track("Component Crash", {
        type: "web-app",
        componentName,
        user_name: user.name,
        plan: workspaceInfo?.plan,
        workspace_name: workspaceInfo?.name,
        workspace_id: workspaceInfo?._id,
        ts: Date.now() - 5000,
        datadogRUMInternalContext: datadogRum.getInternalContext(Date.now()),
      });
    }
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => <ErrorMsg type={type} resetError={resetError} />}
      onError={onError}
      beforeCapture={(scope) => {
        scope.setTag("componentName", componentName);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
