/*
  A single block of text in a frame
*/

import { DraggableWSComp } from "@/components/DragAndDrop/";
import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { default as classNames, default as classnames } from "classnames";
import React, { useEffect, useState } from "react";
import { useComponentBlockDroppable } from "../../../../components/DragAndDrop/lib";
import EditableName from "../../../../components/EditableName";
import ConfirmationModal from "../../../../components/shared/confirmation-modal";
import { Component } from "../../types";
import CompInLibraryBlock from "../comp-in-library-block";
import style from "./style.module.css";

interface Props {
  name: string;
  displayApiIds: boolean;
  parentName: string;
  dragName: string;
  comps: Component[];
  onComponentClick: (comp: Component) => void;
  selectComp: (comp: Component) => void;
  isSelected: (comp: Component | string) => boolean;
  handleRenameBlock: (input: { groupName: string; blockNameOld: string; blockNameNew: string }) => void;
  handleDeleteBlock: (dragName: string, compIdsToDelete: string[]) => void;
  setCommentState: (input: { isSelected: boolean; thread_id: string }) => void;
  setPanelState: (input: string) => void;
  selectedComps: { _id: string; name: string }[];
}
const CompLibraryBlock = (props: Props) => {
  const {
    name,
    displayApiIds,
    parentName,
    dragName,
    comps,
    onComponentClick,
    selectComp,
    isSelected,
    handleRenameBlock,
    handleDeleteBlock,
    setCommentState,
    setPanelState,
    selectedComps,
  } = props;

  const [isRenaming, setIsRenaming] = useState<boolean>(() => props.name.startsWith("Untitled Block"));
  const [confirmGroupRename, setConfirmGroupRename] = useState<boolean>(false);
  const [confirmBlockDeletion, setConfirmBlockDeletion] = useState<boolean>(false);
  const [blockNameInput, setBlockNameInput] = useState<string>(name);
  const { workspaceInfo } = useWorkspace();

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  useEffect(() => {
    setBlockNameInput(name);
  }, [name]);

  const discardChanges = () => {
    setBlockNameInput(name);
    setIsRenaming(false);
    setConfirmGroupRename(false);
  };

  const confirmRename = (value: string) => {
    if (workspaceInfo.config.components.apiIdGenerateOnRename && value !== name && comps.length > 0) {
      setBlockNameInput(value);
      setConfirmGroupRename(true);
    } else {
      saveRename(value);
    }
  };

  const saveRename = async (value: string) => {
    if (!(value && value !== name)) {
      setIsRenaming(false);
      setConfirmGroupRename(false);
      return;
    }

    await handleRenameBlock({
      groupName: parentName,
      blockNameOld: dragName.split("/")[1],
      blockNameNew: value.trim(),
    });

    setIsRenaming(false);
    setConfirmGroupRename(false);
  };

  const onDeleteBlock = async () => {
    if (workspaceInfo.config.components.apiIdGenerateOnRename && !confirmBlockDeletion) {
      setConfirmBlockDeletion(true);
      return;
    }

    const compsToDelete = comps.map((comp) => comp._id);
    await handleDeleteBlock(dragName, compsToDelete);
  };

  const { isOver, setNodeRef } = useComponentBlockDroppable(parentName, name);

  // if (numCompsFiltered > 0 || filtersOff) {
  return (
    <div
      id={`${parentName}/${name}`}
      className={classNames(style.block, {
        [style.isOver]: isOver,
      })}
      ref={setNodeRef}
    >
      <div className={style.blockActions}>
        <EditableName
          isEditEnabled={isEditEnabled}
          editModeEnabled={[isRenaming, setIsRenaming]}
          onSave={(value) => confirmRename(value)}
          value={blockNameInput}
          className={style.blockName}
        />
        {isEditEnabled && (
          <div className={style.rightSideButtons}>
            <CloseIcon onClick={onDeleteBlock} className={classnames([style.icon], [style.close])} />
          </div>
        )}
      </div>

      <div
        className={classnames({
          [style.blockComps]: true,
          [style.emptyBlock]: comps && comps.length === 0,
        })}
      >
        {comps && comps.length > 0 ? (
          comps.map((comp, comp_index) => {
            if (comp.instances.length === 0) {
              return null;
            }
            comp.instances[0].comment_threads = comp.comment_threads;
            return (
              <DraggableWSComp id={comp._id} name={comp.name} selectedComps={selectedComps} key={comp._id}>
                <CompInLibraryBlock
                  key={comp._id}
                  displayApiIds={displayApiIds}
                  wsComp={comp}
                  component={comp.instances[0]}
                  compName={comp.name}
                  isDraft={comp?.isDraft}
                  is_selected={isSelected}
                  onClick={onComponentClick}
                  selectComp={selectComp}
                  index={comp_index}
                  is_ws_comp={true}
                  dragDisabled={!isEditEnabled}
                  ws_comp_id={comp._id}
                  isLast={comp_index === comps.length - 1}
                  setCommentState={setCommentState}
                  setPanelState={setPanelState}
                  compType={comp.type}
                  showAllStatuses
                />
              </DraggableWSComp>
            );
          })
        ) : (
          <span>Drag and drop components into empty block</span>
        )}
      </div>

      {confirmGroupRename && (
        <ConfirmationModal
          title="Are you sure you want to rename this block?"
          body={
            <span>
              Developer mode is turned on, and renaming this block will regenerate the Developer IDs of all components
              inside it according to the rules defined in{" "}
              <a href="/developers/configure-dev-ids" target="_blank">
                your settings
              </a>
              . Are you sure you want to do this?
            </span>
          }
          actionPrimary="Confirm"
          actionSecondary="Cancel"
          onPrimary={() => saveRename(blockNameInput)}
          onSecondary={() => discardChanges()}
        />
      )}
      {confirmBlockDeletion && (
        <ConfirmationModal
          title="Are you sure you want to delete this block?"
          body={
            <span>
              Developer mode is turned on, and deleting this block will regenerate the Developer IDs of all components
              inside it according to the rules defined in{" "}
              <a href="/developers/configure-dev-ids" target="_blank">
                your settings
              </a>
              . Are you sure you want to do this?
            </span>
          }
          actionPrimary="Confirm"
          actionSecondary="Cancel"
          onPrimary={() => {
            onDeleteBlock();
            setConfirmBlockDeletion(false);
          }}
          onSecondary={() => setConfirmBlockDeletion(false)}
        />
      )}
    </div>
  );
};

export default CompLibraryBlock;
