/*
  This is for Components that are in a text Block (i.e. a group) in the Component Library -- always full width
*/
import CardBubbles from "@/components/CardBubbles/CardBubbles";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import TemplateBadge from "@shared/frontend/templates/TemplateBadge";
import { ComponentType } from "@shared/types/Component";
import classnames from "classnames";
import React, { useContext, useMemo } from "react";
import { ActualComponentInterface } from "../../../../../shared/types/TextItem";
import CompStatusBar from "../../../../components/CompStatusBar/CompStatusBar";
import PluralsBadge from "../../../../components/PluralsBadge";
import TextItem from "../../../../components/TextItem";
import ApiID from "../../../../components/api-id";
import Tag from "../../../../components/tag/tag";
import { PANELS } from "../../../../defs";
import useRenderedLinks from "../../../../hooks/useRenderedLinks";
import { UnsavedChangesContext } from "../../../../store/unsavedChangesContext";
import { Component } from "../../types";
import style from "./style.module.css";

interface Props {
  component: ActualComponentInterface<string>;
  compName: string;
  displayApiIds: boolean;
  isDraft: boolean;
  is_selected: (comp: Component | string) => boolean;
  selectComp: (input: string | Component) => void;
  onClick: (comp: Component, ws_comp_id: string) => void;
  index: number;
  dragDisabled: boolean;
  is_ws_comp: boolean;
  ws_comp_id: string;
  isLast: boolean;
  wsComp: Component;
  setCommentState: (input: { isSelected: boolean; thread_id: string }) => void;
  setPanelState: (updatedState: string) => void;
  compType: ComponentType;
  showAllStatuses?: boolean;
}
const CompInLibraryBlock = ({
  component,
  compName,
  displayApiIds,
  isDraft,
  is_selected,
  selectComp,
  onClick,
  index,
  dragDisabled,
  is_ws_comp,
  ws_comp_id,
  isLast,
  wsComp,
  setCommentState,
  setPanelState,
  compType,
  showAllStatuses,
}: Props) => {
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const formattedNotes = useRenderedLinks(component?.notes || "");

  const openComments = () => {
    checkDetailPanelChanges(() => {
      if (!is_selected(ws_comp_id)) {
        selectComp(wsComp);
      }
      setCommentState({ isSelected: true, thread_id: "" });
      setTimeout(() => {
        setPanelState(PANELS.comp_library.activity);
      }, 0);
    });
  };

  const componentNameRendered = useMemo(() => compName.split("/").slice(2).join("/"), [compName]);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e, ws_comp_id);
      return;
    }

    selectComp(wsComp);
  };

  const showsCompMetadata =
    (component.notes && component.notes.length > 0) ||
    (component.tags && component.tags.length > 0) ||
    (wsComp.apiID && displayApiIds);

  return (
    <div
      data-testid={`comp-in-library-block-${compName}`}
      className={classnames({
        [style.comp]: true,
        "single-component": true,
        [style.selected]: is_selected(ws_comp_id),
        [style.wsComp]: is_ws_comp,
        [style.firstInBlock]: index === 0,
        [style.lastInBlock]: isLast,
        [style.onlyComp]: index === 0 && isLast,
      })}
      id={ws_comp_id}
    >
      <CardBubbles
        cardIsSelected={is_selected(ws_comp_id)}
        assignee={component.assignee}
        comment_threads={component.comment_threads}
        onCommentThreadClick={openComments}
        onAssigneeClick={handleClick}
      />
      <div className={classnames([style.compName, style.flexBetween])} onClick={handleClick}>
        {is_selected(ws_comp_id)}
        {componentNameRendered}
        {wsComp.instances.length > 0 && wsComp.instances[0].variants && wsComp.instances[0].variants.length > 0 && (
          <div
            className={classnames({
              [style.flexCentered]: true,
              [style.variantsPaddingRight]: wsComp && wsComp.comment_threads?.length > 0,
            })}
          >
            <CallSplitIcon className={style.splitIconRotate} />
            {wsComp.variants.length}
          </div>
        )}
      </div>

      <div className={style.flexSection} onClick={handleClick}>
        <CompStatusBar
          component={component}
          vertical
          className={classnames(style.compStatusBar, {
            [style.compStatusBarDraft]: isDraft,
          })}
          showAllStatuses={showAllStatuses}
        />
        <div
          className={classnames({
            [style.compMain]: !showsCompMetadata,
            [style.compMainWithMeta]: showsCompMetadata,
          })}
        >
          <TemplateBadge
            type={compType}
            selected={is_selected(ws_comp_id)}
            className={classnames({
              [style.wsCompDraft]: isDraft,
            })}
          />
          <div
            className={classnames({
              [style.compText]: true,
              [style.wsCompDraft]: isDraft,
            })}
          >
            <div className={style.columnWrapper}>
              <div className={style.text}>
                <TextItem textItem={component} highlightBrackets={compType === "template"} />
              </div>
              <PluralsBadge comp={component} variantId={null} />
            </div>
            {!dragDisabled &&
              !(
                (component.notes && component.notes.length > 0) ||
                (component.tags && component.tags.length > 0) ||
                displayApiIds
              ) && <DragIndicatorIcon className={style.drag} />}
          </div>
        </div>
        {showsCompMetadata && (
          <div className={style.compOther}>
            <div className={style.compOtherWrap}>
              {component.notes && component.notes.length > 0 && <div>{formattedNotes}</div>}
              {component.tags && component.tags.length > 0 && (
                <div className={style.tags}>
                  {component.tags.map((text, index) => (
                    <Tag
                      text={text}
                      is_selected={is_selected(ws_comp_id)}
                      key={index}
                      count={undefined}
                      onClick={() => {}}
                    />
                  ))}
                </div>
              )}
              {displayApiIds && wsComp.apiID && (
                <div style={{ display: "flex" }}>
                  <ApiID id={wsComp.apiID} />
                </div>
              )}
            </div>
            {!dragDisabled && <DragIndicatorIcon className={style.drag} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompInLibraryBlock;
