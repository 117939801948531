import z from "zod";
import { ZComponent } from "./Component";
import { SlackChannelInfo } from "./SlackNotifications";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";

export const ZWorkspacePlan = z.enum(["free", "trial", "team", "growth", "enterprise"]);
type IWorkspacePlan = z.infer<typeof ZWorkspacePlan>;
export type IFWorkspacePlan = FrontendSchema<IWorkspacePlan>;
export type IBWorkspacePlan = BackendSchema<IWorkspacePlan>;

export const ZFeatureFlags = z.enum(["advanced_branching", "dittoAi", "northStar"]);
export const ZFlaggedFeaturesSchema = z.record(ZFeatureFlags, z.boolean());
type IFlaggedFeaturesSchema = z.infer<typeof ZFlaggedFeaturesSchema>;
export type IFFlaggedFeaturesSchema = FrontendSchema<IFlaggedFeaturesSchema>;
export type IBFlaggedFeaturesSchema = BackendSchema<IFlaggedFeaturesSchema>;

export const ZNonStripeCustomer = z.object({
  billedEditors: z.number(),
  billedCommenters: z.number(),
});
type INonStripeCustomer = z.infer<typeof ZNonStripeCustomer>;
export type IFNonStripeCustomer = FrontendSchema<INonStripeCustomer>;
export type IBNonStripeCustomer = BackendSchema<INonStripeCustomer>;

export const ZApiIdReplacementCharacter = z.enum(["-", "_", ".", ""]);
type IApiIdReplacementCharacter = z.infer<typeof ZApiIdReplacementCharacter>;
export type IFApiIdReplacementCharacter = FrontendSchema<IApiIdReplacementCharacter>;
export type IBApiIdReplacementCharacter = BackendSchema<IApiIdReplacementCharacter>;

export const ZApiIdCasingAdjustment = z.union([
  z.literal("camel"),
  z.literal("pascal"),
  z.literal("lower"),
  z.literal("upper"),
  z.null(),
]);
export const ApiIdCasingAdjustmentEnum = ["camel", "pascal", "lower", "upper", null];
type IApiIdCasingAdjustment = z.infer<typeof ZApiIdCasingAdjustment>;
export type IFApiIdCasingAdjustment = FrontendSchema<IApiIdCasingAdjustment>;
export type IBApiIdCasingAdjustment = BackendSchema<IApiIdCasingAdjustment>;

export const ZComponentsApiIdGenerationConfig = z.object({
  spaceReplacement: ZApiIdReplacementCharacter,
  slashReplacement: ZApiIdReplacementCharacter,
  acceptedCharsPattern: z.string().nullable(),
  casingAdjustment: ZApiIdCasingAdjustment,
});
type IComponentsApiIdGenerationConfig = z.infer<typeof ZComponentsApiIdGenerationConfig>;
export type IFComponentsApiIdGenerationConfig = FrontendSchema<IComponentsApiIdGenerationConfig>;
export type IBComponentsApiIdGenerationConfig = BackendSchema<IComponentsApiIdGenerationConfig>;

export const ZProjectsApiIdGenerationConfig = z.object({
  optOutHumanReadable: z.boolean(),
  template: z.string(),
  spaceReplacement: ZApiIdReplacementCharacter,
  acceptedCharsPattern: z.string().nullable(),
  casingAdjustment: ZApiIdCasingAdjustment,
  separator: ZApiIdReplacementCharacter,
  maxLength: z.number(),
});

type IProjectsApiIdGenerationConfig = z.infer<typeof ZProjectsApiIdGenerationConfig>;
export type IFProjectsApiIdGenerationConfig = FrontendSchema<IProjectsApiIdGenerationConfig>;
export type IBProjectsApiIdGenerationConfig = BackendSchema<IProjectsApiIdGenerationConfig>;

export interface IWebhookConfig {
  _id: string;
  name: string;
  url: string;
  enabled: boolean;
  events: string[];
  filters: {
    componentFolderIds: (string | null)[] | null;
  };
}

// will likely change this to an enum in subsequent PRs
const ZWebhookEvent = z.string();

export const DEFAULT_WEBHOOK_FILTERS: IBWebhookFilters = {
  componentFolderIds: null,
};

const ZWebhookFilters = z.object({
  componentFolderIds: z
    .array(z.union([z.string(), z.null()]))
    .nullable()
    .optional(),
});
export type IWebhookFilters = z.infer<typeof ZWebhookFilters>;
export type IFWebhookFilters = FrontendSchema<IWebhookFilters>;
export type IBWebhookFilters = BackendSchema<IWebhookFilters>;

export const ZWorkspaceWebhookEndpoint = z.object({
  /**
   * Unique identifier for this webhook.
   */
  _id: ZObjectId,
  /**
   * Human-readable name for the webhook.
   */
  name: z.string(),
  /**
   * The URL to which the webhook will send payloads.
   */
  url: z.string(),
  /**
   * Whether or not this endpoint is enabled. This is a field that is not
   * directly exposed in the UI; it defaults to `true`, and it is only
   * ever set to `false` when we detect that the endpoint has been failing
   * too much and needs to be disabled.
   */
  enabled: z.boolean(),
  /**
   * The events that will trigger the webhook to send a payload
   * that corresponds to the event type.
   */
  events: z.array(ZWebhookEvent),
  /**
   * The user who created the webhook.
   */
  createdBy: z.object({
    userId: ZObjectId,
    userName: z.string(),
  }),
  /**
   * Optionally filters to apply to the webhook.
   */
  filters: ZWebhookFilters,
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IWorkspaceWebhookEndpoint = z.infer<typeof ZWorkspaceWebhookEndpoint>;
export type IFWorkspaceWebhookEndpoint = FrontendSchema<IWorkspaceWebhookEndpoint>;
export type IBWorkspaceWebhookEndpoint = BackendSchema<IWorkspaceWebhookEndpoint>;

export const ZWorkspaceWebhooks = z.object({
  /**
   * Used to sign all outbound payloads so that webhook
   * consumers can verify that a given payload is from Ditto,
   * rather than from a malicious actor.
   */
  signingKey: z.string(),
  /**
   * A censored version of the signing key that lives unencrypted in the database;
   * always updated at the same time as the signing key, and is used to display a
   * preview of the signing key for users.
   */
  signingKeyPreview: z.string(),
  /**
   * Individual webhook endpoints.
   */
  endpoints: z.array(ZWorkspaceWebhookEndpoint),
});

type IWorkspaceWebhooks = z.infer<typeof ZWorkspaceWebhooks>;
export type IFWorkspaceWebhooks = FrontendSchema<IWorkspaceWebhooks>;
export type IBWorkspaceWebhooks = BackendSchema<IWorkspaceWebhooks>;

export const DEFAULT_WEBHOOKS_CONFIG = {
  signingKey: "",
  signingKeyPreview: "",
  endpoints: [],
};
export const DEFAULT_WEBHOOKS_CONFIG_FRONTEND: IFWorkspaceWebhooks = DEFAULT_WEBHOOKS_CONFIG;
export const DEFAULT_WEBHOOKS_CONFIG_BACKEND: IBWorkspaceWebhooks = DEFAULT_WEBHOOKS_CONFIG;

export const ZWorkspaceConfiguration = z.object({
  webhooks: ZWorkspaceWebhooks,
  components: z.object({
    apiIdPreventManualEdits: z.boolean(),
    apiIdGenerateOnRename: z.boolean(),
    apiIdGeneration: ZComponentsApiIdGenerationConfig,
  }),
  projects: z.object({
    apiIdUpdateIdsWhenGroupBlockChange: z.boolean(),
    apiIdPreventManualEdits: z.boolean(),
    apiIdGeneration: ZProjectsApiIdGenerationConfig,
  }),
});
type IWorkspaceConfiguration = z.infer<typeof ZWorkspaceConfiguration>;
export type IFWorkspaceConfiguration = FrontendSchema<IWorkspaceConfiguration>;
export type IBWorkspaceConfiguration = BackendSchema<IWorkspaceConfiguration>;

export const ZLaunchDarklyIntegrationConfig = z.object({
  token: z.string(),
  tokenPreview: z.string(),
  projectId: z.string(),
});

export const ZSplitIntegrationConfig = z.object({
  token: z.string(),
  tokenPreview: z.string(),
  workspaceId: z.string(),
  environmentName: z.string(),
});

export const ZWorkspaceIntegrations = z.object({
  slack: z.object({
    accessToken: z.string().nullable(),
    component_library: SlackChannelInfo,
  }),
  launchDarkly: ZLaunchDarklyIntegrationConfig.optional(),
  split: ZSplitIntegrationConfig.optional(),
});

type IWorkspaceIntegrations = z.infer<typeof ZWorkspaceIntegrations>;
export type IFWorkspaceIntegrations = FrontendSchema<IWorkspaceIntegrations>;
export type IBWorkspaceIntegrations = BackendSchema<IWorkspaceIntegrations>;

export const ZConnectionWaitlistInfo = z.object({
  contentful: z.boolean(),
  acrolinx: z.boolean(),
  aem: z.boolean(),
  writer: z.boolean(),
  jira: z.boolean(),
  vercel: z.boolean(),
  split: z.boolean(),
  launchdarkly: z.boolean(),
});

type IConnectionWaitlistInfo = z.infer<typeof ZConnectionWaitlistInfo>;
export type IFConnectionWaitlistInfo = FrontendSchema<IConnectionWaitlistInfo>;
export type IBConnectionWaitlistInfo = BackendSchema<IConnectionWaitlistInfo>;

export const ZAIFeatures = z.object({
  aiEditor: z.boolean(),
  automatedComponentNaming: z.boolean(),
});

export const ZMetrics = z.object({
  firstApiFetch: z.date().nullable(),
  firstDeveloperJoined: z.date().nullable(),
  firstComponentFileImport: z.date().nullable(),
});

type IAIFeatures = z.infer<typeof ZAIFeatures>;
export type IFAIFeatures = FrontendSchema<IAIFeatures>;
export type IBAIFeatures = BackendSchema<IAIFeatures>;

export const ZWorkspace = z.object({
  _id: ZObjectId,
  name: z.string(),
  domain_name: z.string(),
  creatorId: z.string().optional().nullable(),
  plan: ZWorkspacePlan,
  planData: ZNonStripeCustomer.optional(),
  customer_id: z.string().nullable(), // null for free users
  github_install_id: z.string().optional(),
  invitedUserEmails: z.array(z.string()),
  flags: z.object({}).passthrough(),
  ws_trial: z.date().nullable(), // null if trial has never been started
  devToolsTrial: z.date().nullable(), // null if trial has never been started
  devTools: z.boolean(),
  devToolsGrandfathered: z.boolean(), // TODO: remove grandfathered access at end of 2022
  shareLinkIds: z.object({}).passthrough(),
  feature_flags: ZFlaggedFeaturesSchema,
  config: ZWorkspaceConfiguration,
  integrations: ZWorkspaceIntegrations,
  connectionWaitlist: ZConnectionWaitlistInfo,
  aiFeatures: ZAIFeatures,
  metrics: ZMetrics,
  createdAt: z.union([z.string(), z.date()]),
});

type IWorkspace = z.infer<typeof ZWorkspace>;
export type IFWorkspace = FrontendSchema<IWorkspace> & {
  integrations: { slack: { authenticated: boolean | null } };
};
export type IBWorkspace = BackendSchema<IWorkspace>;

const ZWebhookContext = z.object({
  workspace: ZWorkspace,
  component: ZComponent.nullable(),
});
export type IWebhookContext = z.infer<typeof ZWebhookContext>;
export type IFWebhookContext = FrontendSchema<IWebhookContext>;
export type IBWebhookContext = BackendSchema<IWebhookContext>;
