import classNames from "classnames";
import React, { useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import style from "./Creatable.module.css";

interface FolderSelectProps {
  folders: { _id: string; name: string }[];
  className?: string;
  value: { value: string; label: string } | null;
  onChange: (arg: { value: string; label: string; __isNew__: boolean }) => void;
  currentFolderId: string;
  canCreateFolder: boolean;
}

const FolderSelect = (props: FolderSelectProps) => {
  const { folders, value, onChange, className } = props;

  const options = useMemo(
    () =>
      folders
        .map((f) => ({
          label: f.name,
          value: f._id,
        }))
        // filter out the current folder from the options, including the "None"
        // option if we're already at the root of the component library
        .filter(
          (f) =>
            !(
              f.value === props.currentFolderId ||
              ((f.value === "__none__" || f.value === "none") && props.currentFolderId === "")
            )
        ),
    [folders]
  );

  const newFolderIsValid = (inputValue: string) => {
    return (
      props.canCreateFolder &&
      inputValue?.length > 0 &&
      !folders.some((f) => f.name.toLowerCase() === inputValue.toLowerCase())
    );
  };

  return (
    <div className={classNames([style.container, className])}>
      <CreatableSelect
        placeholder="Select or create a folder.."
        onChange={onChange}
        options={options}
        className={style.dropdown}
        isValidNewOption={newFolderIsValid}
        value={value}
        components={{ Option: FolderOption }}
      />
    </div>
  );
};

const FolderOption = (props: any) => {
  const { label, innerProps } = props;

  const { onClick, onMouseOver } = innerProps;
  return (
    <div className={style.option} onClick={onClick} onMouseOver={onMouseOver}>
      <div className={style.optionLabel}>{label}</div>
    </div>
  );
};
export default FolderSelect;
