import { Group, TextItem } from "./types";

export type GroupReducerAction =
  | {
      type: "STACK_GROUPS";
      groups: Group[];
    }
  | {
      type: "REPLACE_GROUPS";
      groups: Group[];
    }
  | {
      type: "FETCHING_GROUPS";
      fetching: boolean;
    }
  | {
      type: "GROUP_FETCHING_FINISHED";
      status: boolean;
    }
  | {
      type: "UPDATE_COMPONENTS_IN_GROUPS";
      comps: TextItem[];
    }
  | {
      type: "ADD_TEXT_ITEM_TO_GROUP";
      comp: TextItem;
      groupId: string;
    }
  | {
      type: "ADD_NEW_BLOCK_TO_GROUP";
      groupId: string;
      blockId: string;
      name: string;
    }
  | {
      type: "UPDATE_BLOCK_NAME";
      groupId: string;
      blockId: string;
      newName: string;
    }
  | {
      type: "DELETE_TEXT_ITEM_FROM_GROUP";
      textItemId: string;
      groupId: string;
    }
  | {
      type: "UPDATE_DRAFT_TEXT_ITEM_COMMENT_THREADS";
      groupId: string;
      commentThreadUpdateMap: {
        [textItemId: string]: TextItem["comment_threads"];
      };
    }
  | {
      type: "UPDATE_GROUP_NAME";
      groupId: string;
      groupName: string;
      groupApiId?: string;
    }
  | {
      type: "UPDATE_GROUP_TEXT_ITEMS";
      groupId: string;
      groupApiId?: string;
      textItems: TextItem[];
    }
  | {
      type: "UPDATE_GROUP_TEXT_ITEMS_CALLBACK";
      groupId: string;
      compsCallback?: (textItems: TextItem[]) => TextItem[];
      blocksCallback?: (blocks: Group["blocks"]) => Group["blocks"];
    }
  | {
      type: "UPDATE_GROUP_NON_BLOCKS_COLLAPSED";
      groupId: string;
      isCollapsed: boolean;
    }
  | {
      type: "REFRESH_GROUP";
      groupId: string;
      group: Group;
    }
  | {
      type: "UPDATE_GROUP";
      groupId: string;
      groupName: string;
      textItems: TextItem[];
      linkingEnabled: boolean;
    }
  | {
      type: "ENABLE_LINKING_FOR_GROUP";
      groupId: string;
      textItems: TextItem[];
    }
  | {
      type: "DISABLE_LINKING_FOR_GROUP";
      groupId: string;
    }
  | {
      type: "ADD_DEFAULT_UNLINKED_GROUP";
      projectId: string;
      workspaceId: string;
    }
  | {
      type: "UPDATE_GROUPS";
      updater: (groups: Group[]) => Group[];
    }
  | {
      type: "ADD_GROUPS";
      groups: Group[];
    }
  | {
      type: "RESET_GROUPS";
    }
  | {
      type: "UPDATE_GROUP_APIID";
      groupId: string;
      apiId: string;
    };

type GroupReducerTypes = { [key in GroupReducerAction["type"]]: key };

export const GROUP_REDUCER_TYPES: GroupReducerTypes = {
  ADD_DEFAULT_UNLINKED_GROUP: "ADD_DEFAULT_UNLINKED_GROUP",
  ADD_NEW_BLOCK_TO_GROUP: "ADD_NEW_BLOCK_TO_GROUP",
  ADD_TEXT_ITEM_TO_GROUP: "ADD_TEXT_ITEM_TO_GROUP",
  ADD_GROUPS: "ADD_GROUPS",
  DELETE_TEXT_ITEM_FROM_GROUP: "DELETE_TEXT_ITEM_FROM_GROUP",
  DISABLE_LINKING_FOR_GROUP: "DISABLE_LINKING_FOR_GROUP",
  ENABLE_LINKING_FOR_GROUP: "ENABLE_LINKING_FOR_GROUP",
  FETCHING_GROUPS: "FETCHING_GROUPS",
  GROUP_FETCHING_FINISHED: "GROUP_FETCHING_FINISHED",
  REFRESH_GROUP: "REFRESH_GROUP",
  REPLACE_GROUPS: "REPLACE_GROUPS",
  RESET_GROUPS: "RESET_GROUPS",
  STACK_GROUPS: "STACK_GROUPS",
  UPDATE_BLOCK_NAME: "UPDATE_BLOCK_NAME",
  UPDATE_COMPONENTS_IN_GROUPS: "UPDATE_COMPONENTS_IN_GROUPS",
  UPDATE_DRAFT_TEXT_ITEM_COMMENT_THREADS: "UPDATE_DRAFT_TEXT_ITEM_COMMENT_THREADS",
  UPDATE_GROUP: "UPDATE_GROUP",
  UPDATE_GROUPS: "UPDATE_GROUPS",
  UPDATE_GROUP_NAME: "UPDATE_GROUP_NAME",
  UPDATE_GROUP_NON_BLOCKS_COLLAPSED: "UPDATE_GROUP_NON_BLOCKS_COLLAPSED",
  UPDATE_GROUP_TEXT_ITEMS: "UPDATE_GROUP_TEXT_ITEMS",
  UPDATE_GROUP_TEXT_ITEMS_CALLBACK: "UPDATE_GROUP_TEXT_ITEMS_CALLBACK",
  UPDATE_GROUP_APIID: "UPDATE_GROUP_APIID",
};
