import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import Text, { TextSize } from "../../atoms/Text";
import style from "./index.module.css";

interface LabelWrapperProps {
  children: React.ReactNode;
  label: string;

  helpText?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  htmlFor?: string;
  labelPos?: "left" | "right";
  size?: "base" | "sm";
  className?: string;
  labelClassName?: string;
  labelFlexStart?: boolean;
  labelHeight?: number;

  // Flag to conditionally render Label wrapper around text. Defaults to true.
  withLabel?: boolean;

  weight?: "base" | "medium" | "strong";
  color?: "primary" | "secondary" | "tertiary" | "placeholder" | "invert" | "code" | "action" | "purple" | "positive";
  fontSize?: TextSize;
}

/**
 * Renders arbitrary children wrapped in a flex row, with label, icon, and help text.
 */
export function LabelWrapper(props: LabelWrapperProps) {
  const size = props.size ?? "base";
  const labelPos = props.labelPos ?? "left";

  return (
    <div className={classNames(style.LabelWrapper, props.className)} data-testid="input-row">
      {labelPos === "left" && <LabelAndHelpText size={size} {...props} />}

      {props.children}

      {labelPos === "right" && <LabelAndHelpText size={size} {...props} />}
    </div>
  );
}

function LabelAndHelpText(props: Partial<LabelWrapperProps>) {
  const size = props.size ?? "base";
  const withLabel = props.withLabel ?? true;
  const fontSize = props.fontSize ?? "small";

  const textComp = (
    <Text weight={props.weight} color={props.color} size={fontSize}>
      {props.label}
    </Text>
  );

  return (
    <div
      className={classNames(style.textWrapper, {
        [style.labelFlexStart]: props.labelFlexStart,
      })}
    >
      {withLabel && (
        <Label
          className={classNames(style.label, props.className)}
          size={size}
          style={{ height: props.labelHeight }}
          trailingIcon={props.trailingIcon}
          leadingIcon={props.leadingIcon}
          htmlFor={props.htmlFor}
        >
          {textComp}
        </Label>
      )}
      {!withLabel && textComp}
      {props.helpText && <Text className={style.helpText}>{props.helpText}</Text>}
    </div>
  );
}

export default LabelWrapper;
