import classNames from "classnames";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

import Highlighter from "../Highlighter";

import style from "./style.module.css";

interface Props {
  loading: boolean;
  content: string | null;
  className?: string;
  style?: React.CSSProperties;
  language?: string;
  disabled?: boolean;
}

const ExportPreview = (props: Props) => {
  const { content, loading, className, language, disabled } = props;

  const showPreviewContent = content && !disabled;

  return (
    <div className={className}>
      <div className={style.previewHeader}>
        Preview
        {loading && <Spinner animation="border" className={style.exportPreviewSpinner} />}
      </div>
      <div
        className={classNames({
          [style.exportPreview]: true,
          [style.scrollDisabled]: loading,
        })}
      >
        {showPreviewContent && (
          <Highlighter className={style.exportPreviewContent} content={content} language={language} />
        )}
        <div
          className={classNames({
            [style.exportPreviewLoading]: true,
            [style.exportPreviewLoadingActive]: loading,
          })}
        />
      </div>
    </div>
  );
};

export default ExportPreview;
