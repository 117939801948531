import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocalOffer from "@mui/icons-material/LocalOffer";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ITagState } from "@/hooks/useSearchState";
import TagSelect from "./TagSelect";
import style from "./style.module.css";

interface Tag {
  _id: string;
  total: number;
}

interface TagsFilterDropdownProps {
  className?: string;
  tagState: ITagState;
  selectTag: (tagName: string) => void;
  onRemoveFilter: () => void;
}

const TagsFilterDropdown = (props: TagsFilterDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { className, selectTag, onRemoveFilter } = props;

  const toggleShowDropdown = () => {
    setShowDropdown((showDropdown) => !showDropdown);
  };

  useEffect(
    function deactiveDropdownOnClickOutside() {
      if (!showDropdown) {
        return;
      }

      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [showDropdown, setShowDropdown]
  );

  return (
    <div ref={containerRef} className={classNames(style.tagsFilterDropdown, className)}>
      <div className={style.dropdownToggle} onClick={toggleShowDropdown}>
        <LocalOffer className={style.icon} />
        {props.tagState.selected.size > 0 ? (
          <div className={style.selectedTags}>
            {Object.entries(props.tagState.counts)
              .filter(([tag]) => props.tagState.selected.has(tag))
              .map(([tag]) => (
                <div key={tag} className={style.selectedTag}>
                  {tag}
                </div>
              ))}
          </div>
        ) : (
          <span>Tags</span>
        )}
        <KeyboardArrowDownIcon className={style.downIcon} />
      </div>
      {showDropdown && (
        <div className={style.tagSelectMenu}>
          <TagSelect tagState={props.tagState} selectTag={selectTag} onRemoveFilter={onRemoveFilter} />
        </div>
      )}
    </div>
  );
};

export default TagsFilterDropdown;
