export const TextItem = {
  getGroupId: (comp) => (comp._meta ? comp._meta.groupId : null),
  getGroupIndex: (comp) => (comp._meta ? comp._meta.groupIndex : comp.frameIndex),
  isInBlock: (comp) =>
    comp._meta && comp.blockIndex === undefined ? !!comp._meta.blockId : typeof comp.blockIndex === "number",
};

export const TextItems = {
  allSameFrame: (comps) => {
    const frames = comps.map((comp) => [TextItem.getGroupIndex(comp), comp]);
    const framesUnique = [...new Map(frames).values()];
    return framesUnique.length === 1;
  },
  includesWsComp: (comps) => comps.some((comp) => !!comp.ws_comp),
  includeBlock: (comps) => comps.some(TextItem.isInBlock),
  includeHidden: (comps) => comps.some((comp) => comp.is_hidden),
  includeVariant: (comps) => comps.some((comp) => comp.variants && comp.variants.length > 0),
  allWsComps: (comps) => comps.every((comp) => !!comp.ws_comp),
  allHidden: (comps) => comps.every((comp) => comp.is_hidden),
  allVisible: (comps) => comps.every((comp) => !comp.is_hidden),
  includeNonBaseVariant: (comps) => {
    const variants = comps.map((comp) => (comp.selectedVariant ? comp.selectedVariant.id : "__base__"));
    const variantsUnique = new Set(variants);

    const includesMultipleVariants = variantsUnique.size > 1;
    const includesBaseVariant = variantsUnique.has("__base__");

    return !includesBaseVariant || includesMultipleVariants;
  },
};

export const computeComponentData = (comps) =>
  Object.keys(TextItems).reduce((acc, key) => ({ ...acc, [key]: TextItems[key](comps) }), {});
