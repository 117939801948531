export const onVariableListChange = (newEnum, index, setSelectedVariable) => {
  const updatedValue = newEnum.target.value;
  setSelectedVariable((prev) => {
    const new_data = prev.data;
    new_data[index] = updatedValue;
    return {
      ...prev,
      data: new_data,
    };
  });
};

export const onAddListRow = (setSelectedVariable) => {
  setSelectedVariable((prev) => ({
    ...prev,
    data: prev.data.concat([""]),
  }));
};

export const onDeleteListRow = (index, setSelectedVariable) => {
  setSelectedVariable((prev) => {
    const new_data = prev.data;
    new_data.splice(index, 1);
    return {
      ...prev,
      data: new_data,
    };
  });
};
