import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import AdvancedBranchingToggleMessage from "../AdvancedBranchingToggleMessage";
import style from "./style.module.css";

interface Props {
  error: string;
  onHide: () => void;
  goToAccountUser: () => void;
  showAdvancedBranchingModal: () => void;
}
const ErrorMessage = (props: Props) => {
  const { error, onHide, goToAccountUser, showAdvancedBranchingModal } = props;
  if (!error || error === "none") return null;

  var text = "Sorry, we had trouble importing your file -- please try again. If the problem persists, let us know.";

  switch (error) {
    case "invalid_url":
      text =
        "Looks like this isn't a valid Figma file link! Please make sure this is the full URL of a file your Figma account has access to.";
      break;
    case "branch_file_deleted":
      text = "We couldn't import this Figma file branch because it has been merged or deleted.";
      break;
    case "unauthenticated":
      text =
        "Looks like you haven’t connected your Figma account, or you don't have access to this Figma file. Please connect Figma in your ";
      break;
    case "main_branch_imported":
      text =
        "We couldn't import this Figma file branch because the main branch is already in your workspace.\nYou'll need to delete that existing Ditto project to import this branch.";
      break;
    case "other_branch_imported":
      text =
        "We couldn’t import this Figma file branch because another branch of this file is already in your workspace.\nWe currently only support syncing to one branch at a time.";
      break;
    case "other_branch_imported_v2":
      text =
        "We couldn’t import this Figma file branch because another branch of this file has already been imported into your workspace. You’ll be able to import this branch once that branch has been merged in Figma.";
      break;
    case "branch_exists":
      text =
        "We couldn’t import this Figma file because a branch of this file is already in your workspace.\n\nWe currently only support syncing to one branch at a time. To import this main branch, you'll need to either delete the existing Ditto project or merge the branch in Figma.";
      break;
    case "file_exists":
      text = "Looks like this file is already in your workspace!";
      break;
    case "file_exists_diff_ws":
      text =
        "Looks like this file has already been linked in another workspace! Sign into that workspace to view the file, or try a different file.";
      break;
    case "rate_limit":
      text =
        "Uh oh! We've made too many requests to Figma in the last few minutes. Please wait a few minutes and try again.";
      break;
    case "large_images":
      text =
        "Uh oh! Figma wasn't able to fetch this file because of the number or size of images it contains. Please reduce the number or size of the images in your file and try importing it again.";
      break;
    case "fetch_frames_error":
      text = "Sorry, we had trouble fetching the frames in your Figma file. Please try again.";
    case "enable_adv_branching_error":
      text = "Sorry, we had trouble enabling Advanced Branching for your workspace. Please try again.";
      break;
    case "sample_project_already_exists":
      text =
        "Looks like you're trying to import Ditto's sample project. Good news — it's already been set up in your workspace!";
      break;
    case "sample_project_not_imported":
      break;
    case "imported_wrong_sample_project":
      text =
        "It looks like you're importing the base file for Ditto's sample project. Please create a duplicate in your own team's workspace, and import that instead!";
      break;
  }

  // use AdvancedBranchingToggleMessage
  if (error === "main_branch_imported") {
    return <AdvancedBranchingToggleMessage showAdvancedBranchingModal={showAdvancedBranchingModal} />;
  }
  return (
    <div className={style.error}>
      <ErrorIcon className={style.icon} onClick={onHide} />
      <div className={style.errorMessage}>
        {text}
        {error === "other_branch_imported_v2" && (
          <div className={style.branchErrorLink}>
            <a href="https://www.dittowords.com/docs/figma-branching" target="_blank">
              Learn more
            </a>
          </div>
        )}
        {error === "unauthenticated" && (
          <a className={style.link} onClick={goToAccountUser}>
            Account Settings
          </a>
        )}
      </div>
    </div>
  );
};

export default ErrorMessage;
