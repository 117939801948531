import useLocalStorage from "@/hooks/useBetterLocalStorage";

export type OnboardingType = "user" | "workspace";

export enum WorkspaceOnboardingPage {
  FIRST = "WS_FIRST",
  SECOND = "WS_SECOND",
  THIRD = "WS_THIRD",
}

export enum UserOnboardingPage {
  FIRST = "USER_FIRST",
  SECOND = "USER_SECOND",
}

export type OnboardingPage = WorkspaceOnboardingPage | UserOnboardingPage;

export interface DittoUseCaseQuestion {
  components: boolean;
  development: boolean;
  textReview: boolean;
  design: boolean;
}

export interface OnboardingState {
  onboardingType: OnboardingType | undefined;
  page: OnboardingPage;
  newWSName: string;
  dittoUseCase: DittoUseCaseQuestion;
  acceptTnC: boolean;
  referralSource: string;
  role: string;
  inviteEmails: string[];
  noFigmaAccount: boolean;
}

/**
 * This hook manages the state of the onboarding form. It stores all this state
 * in local storage, so that when the user navigates away to connect to Figma,
 * they can come back and still have their form filled out.
 */
export default function useOnboardingFormState(onboardingStateParam: OnboardingType | undefined) {
  const [onboardingState, setOnboardingState, removeOnboardingState] = useLocalStorage<OnboardingState>(
    "onboardingState",
    {
      onboardingType: onboardingStateParam,
      page: onboardingStateParam === "workspace" ? WorkspaceOnboardingPage.FIRST : UserOnboardingPage.FIRST,
      newWSName: "",
      dittoUseCase: {
        components: false,
        development: false,
        textReview: false,
        design: false,
      },
      acceptTnC: false,
      referralSource: "",
      role: "NONE",
      inviteEmails: ["", "", ""],
      noFigmaAccount: false,
    }
  );

  let pageNumber = 1;
  switch (onboardingState.page) {
    case WorkspaceOnboardingPage.SECOND:
      pageNumber = 2;
      break;
    case WorkspaceOnboardingPage.THIRD:
      pageNumber = 3;
      break;
    case UserOnboardingPage.SECOND:
      pageNumber = 2;
      break;
    default:
      break;
  }

  const setOnboardingPage = (page: OnboardingPage) => {
    setOnboardingState((prev) => ({
      ...prev,
      page,
    }));
  };

  const setNewWSName = (newWSName: string) => {
    setOnboardingState((prev) => ({
      ...prev,
      newWSName,
    }));
  };

  const setDittoUseCase = (dittoUseCase: DittoUseCaseQuestion) => {
    setOnboardingState((prev) => ({
      ...prev,
      dittoUseCase,
    }));
  };

  const toggleDittoUseCaseAnswer = (name: keyof DittoUseCaseQuestion) => () => {
    setOnboardingState((prev) => ({
      ...prev,
      dittoUseCase: {
        ...prev.dittoUseCase,
        [name]: !prev.dittoUseCase[name],
      },
    }));
  };

  const setAcceptTnC = (acceptTnC: boolean) => {
    setOnboardingState((prev) => ({
      ...prev,
      acceptTnC,
    }));
  };

  const setReferralSource = (referralSource: string) => {
    setOnboardingState((prev) => ({
      ...prev,
      referralSource,
    }));
  };

  const setRole = (role: string) => {
    setOnboardingState((prev) => ({
      ...prev,
      role,
    }));
  };

  const setInviteEmails = (inviteEmails: string[]) => {
    setOnboardingState((prev) => ({
      ...prev,
      inviteEmails,
    }));
  };

  const setNoFigmaAccount = (noFigmaAccount: boolean) => {
    setOnboardingState((prev) => ({
      ...prev,
      noFigmaAccount,
    }));
  };

  return {
    state: onboardingState,
    pageNumber,
    setOnboardingPage,
    setNewWSName,
    setDittoUseCase,
    toggleDittoUseCaseAnswer,
    setAcceptTnC,
    setReferralSource,
    setRole,
    setInviteEmails,
    setNoFigmaAccount,
    removeOnboardingState,
  };
}
