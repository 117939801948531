import classNames from "classnames";
import React from "react";
import Toggle from "react-toggle";

import style from "./style.module.css";

interface Props {
  onToggle: () => void;
  onTagClick?: () => void;
  value: boolean;
  description: string;
  disabled?: boolean;
  tag?: { label: string; url: string };
}

const InviteOnlyToggle = ({ onToggle, onTagClick, value, description, disabled, tag }: Props) => (
  <div className={classNames(style.container, { [style.disabled]: disabled })}>
    <div className={style.title}>
      <div className={style.label}>
        <span>Make invite-only</span>
        {tag && (
          <a href={tag.url} target="_blank" className={style.tag} onClick={onTagClick}>
            {tag.label}
          </a>
        )}
      </div>
      <Toggle disabled={disabled} checked={value} icons={false} onChange={onToggle} />
    </div>
    <div className={style.description}>{description}</div>
  </div>
);

export default InviteOnlyToggle;
