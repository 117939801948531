import { Getter } from "jotai";
import { atomEffect } from "jotai-effect";
import { useAtomValue } from "jotai/react";
import { useMemo } from "react";

type EffectFn = Parameters<typeof atomEffect>[0];

export type GetterWithPeek = Getter & { peek: Getter };

/**
 * Use this hook when you want to run a useEffect that gets atom values
 * but should not include those atoms as dependencies (should not re-run on changes to those atoms).
 *
 * The effect function should always be wrapped in a useCallback.
 *
 * https://jotai.org/docs/recipes/use-atom-effect
 */
export default function useAtomEffect(effectFn: EffectFn) {
  useAtomValue(useMemo(() => atomEffect(effectFn), [effectFn]));
}
