import { detailsPanelEditStateAtom, selectedTextItemsCountAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import Tabs from "@ds/molecules/Tabs";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import style from "./style.module.css";

function EditTabBar() {
  const [selectedTab, setSelectedTab] = useAtom(detailsPanelEditStateAtom);
  const selectedTextItemsCount = useAtomValue(selectedTextItemsCountAtom);
  const selectionType = useAtomValue(selectionTypeAtom);

  const tabs = useMemo(
    function generateTabs() {
      if (selectedTextItemsCount > 1) {
        return [
          {
            id: "EDIT",
            label: "Edit",
          },
          // TODO: Add support for multiselection with variants: https://linear.app/dittowords/issue/DIT-8361/support-multiselection-with-variants
        ];
      }
      if (selectionType === "block") {
        return [
          {
            id: "EDIT",
            label: "Edit",
          },
          // Temporarily removed until implemented: https://linear.app/dittowords/issue/DIT-7924/implement-block-change-history
          // {
          //   id: "ACTIVITY",
          //   label: "Activity",
          // },
          // Variants not supported for block selection
        ];
      }
      return [
        {
          id: "EDIT",
          label: "Edit",
        },
        {
          id: "ACTIVITY",
          label: "Activity",
        },
        {
          id: "VARIANTS",
          label: "Variants",
        },
      ];
    },
    [selectedTextItemsCount, selectionType]
  );

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }
    return "EDIT";
  }, [selectedTab, tabs]);

  return (
    <div className={style.editTabBarContainer}>
      <Tabs
        value={selectedTabValue}
        onValueChange={setSelectedTab}
        tabs={tabs}
        className={style.tabBar}
        listClassName={style.tabList}
        triggerClassName={style.tab}
      />
      <div className={style.spacer} />
    </div>
  );
}

export default EditTabBar;
