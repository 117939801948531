import { useCallback, useEffect, useState } from "react";

export type StatePair<T> = [T | undefined, React.Dispatch<React.SetStateAction<T>>];

/**
 * Hook to optionally allow a parent to control whether the component is being edited.
 * If the parent does not provide a state pair, the component will manage its own state.
 */
export function useControlledState<T>(controlledState?: StatePair<T>, defaultState?: T) {
  // pull the controlled value out of the parent state pair
  const [controlledValue, controlledSetter] = controlledState || [];

  // internal state of the component, initialized to the controlled value if it exists
  const [_internalState, _setInternalState] = useState<T>(() => {
    if (controlledValue !== undefined) {
      return controlledValue;
    }
    if (defaultState !== undefined) {
      return defaultState;
    }
    throw new Error("Controlled value or default state must be provided");
  });

  // Keep the internal state in sync with the parent state
  useEffect(() => {
    if (controlledValue !== undefined) {
      _setInternalState(controlledValue);
    }
  }, [controlledValue, _setInternalState]);

  // If we're a controlled component, setIsBeingEdited will call the setter that
  // we provided; otherwise, call our internal setter
  const setterToUse = useCallback(
    (value: React.SetStateAction<T>) => {
      if (controlledSetter) return controlledSetter(value);
      else return _setInternalState(value);
    },
    [controlledSetter]
  );

  // return the internal state, and whichever setter we're using
  return [_internalState as T, setterToUse] as [T, React.Dispatch<React.SetStateAction<T>>];
}

export default useControlledState;
