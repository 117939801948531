import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import lightbulbEmoji from "../../assets/lightbulb_emoji.png";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

const VariantDropdown = ({ variants, onViewVariant }) => {
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(-1);

  return (
    <div className={style.dropDownBody}>
      {variants.length === 0 && (
        <div className={style.infoAlert}>
          <img className={style.emoji} src={lightbulbEmoji} />
          <div>
            This project doesn’t have any variants yet! You can use variants to manage localization, user segments, and
            more.{" "}
            <a href="https://www.dittowords.com/docs/variants" target="_blank">
              How to use variants
            </a>
          </div>
        </div>
      )}
      <p>Select a variant to view in this project.</p>
      <select
        value={selectedVariantIndex}
        disabled={variants.length === 0}
        onChange={(e) => setSelectedVariantIndex(e.target.value)}
      >
        <option disabled selected hidden value={-1}>
          Select a variant
        </option>
        {variants
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((variant, index) => (
            <option key={index} value={index}>
              {variant.name}
            </option>
          ))}
      </select>
      <div className={style.form}>
        <ButtonPrimary
          disabled={selectedVariantIndex === -1}
          text={"View Variant"}
          onClick={() => onViewVariant(variants[selectedVariantIndex])}
        />
      </div>
    </div>
  );
};

const SelectVariantModal = ({ frame_name, variants, onHide, handleViewVariant, isProjectModal }) => {
  const onViewVariant = (variant) => {
    handleViewVariant(variant);
    onHide();
  };

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            {!isProjectModal ? (
              <div>
                View Variant for <span className={style.frameName}>{frame_name}</span>
              </div>
            ) : (
              <div>View Variant for Project</div>
            )}
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <VariantDropdown variants={variants} onViewVariant={onViewVariant} />
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default SelectVariantModal;
