import SvgIcon from "@mui/material/SvgIcon";
import classnames from "classnames";
import { Ditto } from "ditto-react";
import React from "react";
import style from "./panel.module.css";

interface Props {
  badgeText?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  buttonIcon?: typeof SvgIcon;
  buttonType?: "primary" | "secondary";
  titleDittoComponentId: string;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
}

export default function Panel(props: Props) {
  const onClick = () => {
    if (!props.buttonDisabled) {
      props.onClick?.();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.text}>
          <div className={style.title}>
            <Ditto componentId={props.titleDittoComponentId} />
            {props.badgeText && <div className={style.badge}>{props.badgeText}</div>}
          </div>
          {props.subtitle && <div className={style.subtitle}>{props.subtitle}</div>}
        </div>
        {(props.buttonText || props.buttonIcon) && (
          <div
            data-testid="panel-cta"
            className={classnames({
              [style.button]: !props.buttonType || props.buttonType === "primary",
              [style.secondaryButton]: props.buttonType === "secondary",
              [style.disabled]: props.buttonDisabled,
            })}
            role="button"
            onClick={onClick}
            aria-pressed="false"
          >
            {props.buttonIcon && <props.buttonIcon className={style.icon} />}
            {props.buttonText && props.buttonText}
          </div>
        )}
      </div>
      <div>{props.children}</div>
    </div>
  );
}
