type Unit = "second" | "minute" | "hour" | "day" | "week" | "month" | "year";

const formatString = (value: number, unit: string, suffix: string) =>
  [value, unit + (value !== 1 ? "s" : ""), suffix].join(" ");

const getAbbreviatedUnit = (unit: Unit) => {
  switch (unit) {
    case "second":
      return "sec";
    case "minute":
      return "min";
    case "hour":
    case "day":
    case "week":
    case "month":
    case "year":
      return unit;
  }
};

/**
 * Instead of ticking every second, renders a fixed message for items that are less than a minute old.
 * @param message The string to display if the timestamp is within the past minute, such as "Less than a minute ago"
 * @param abbreviate If true, will use shorter units, like "min" instead of "minute"
 * @returns a Formatter function that can be used with TimeAgo
 *
 * Ex: <TimeAgo date={date} formatter={getNoSecondsFormatter("Less than a minute ago")} />
 * Ex: <TimeAgo date={date} formatter={getNoSecondsFormatter("< 1 min ago", true)} />
 */
export const getNoSecondsFormatter = (message: string, abbreviate?: boolean) => {
  return (value: number, unit: Unit, suffix: string) => {
    if (unit === "second") {
      return message;
    }

    const formattedUnit = abbreviate ? getAbbreviatedUnit(unit) : unit;
    return formatString(value, formattedUnit, suffix);
  };
};

/**
 * A Formatter function to provide to TimeAgo
 * Uses shorter units, like "min" instead of "minute"
 *
 * Ex: <TimeAgo date={date} formatter={abbreviatedFormatter} />
 */
export const abbreviatedFormatter = (value: number, unit: Unit, suffix: string) => {
  return formatString(value, getAbbreviatedUnit(unit), suffix);
};
