import classNames from "classnames";
import React from "react";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  text?: string;
  size?: "base" | "small" | "micro";
}

export function LoadingMessage(props: IProps) {
  return (
    <div style={props.style} className={classNames(style.LoadingMessageWrapper, props.className)} data-testid="loading">
      <LoadingSpinner size={props.size} />
      <Text size={props.size} color="tertiary" weight="light">
        {props.text ?? "Loading..."}
      </Text>
    </div>
  );
}

export default LoadingMessage;
