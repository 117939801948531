import FileCopyIcon from "@mui/icons-material/FileCopy";
import React, { useState } from "react";
import { useCrowdinConnection } from "../../store/CrowdinConnectionContext";
import styles from "./styles.module.css";

export function NotConnected() {
  const crowdinConnection = useCrowdinConnection();

  return (
    <>
      <div className={styles.contentSubheader}>Instructions</div>
      <div className={styles.instructionsBody}>
        <ol>
          <li className={styles.contentText}>Click “Add Connection” to add the Ditto app to your Crowdin workspace.</li>
          <li className={styles.contentText}>
            When prompted to provide an API key in the Crowdin interface, copy and paste the key below.
          </li>
        </ol>
        <a className={styles.link} href="#">
          Detailed instructions
        </a>
        <div className={styles.buttons}>
          {crowdinConnection.currentCrowdinConnection ? (
            <button
              className={styles.removeConnectionButton}
              onClick={async () => {
                if (!crowdinConnection.currentCrowdinConnection) {
                  console.error("No Crowdin key to remove.");
                  return;
                }
                await crowdinConnection.removeCrowdinKey();
              }}
            >
              Remove Connection
            </button>
          ) : (
            <button className={styles.blueFilledButton} onClick={crowdinConnection.createNewCrowdinKey}>
              Add Connection
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export function Connected() {
  const crowdinConnection = useCrowdinConnection();

  const [hasCopied, setHasCopied] = useState(false);

  return (
    <>
      <div className={styles.buttons}>
        <button
          className={styles.removeConnectionButton}
          onClick={async () => {
            if (!crowdinConnection.currentCrowdinConnection) {
              console.error("No Crowdin key to remove.");
              return;
            }
            await crowdinConnection.removeCrowdinKey();
          }}
        >
          Remove Connection
        </button>
      </div>
      {crowdinConnection.newCrowdinToken && (
        <div className={styles.keyContainer}>
          <div className={styles.key}>{crowdinConnection.newCrowdinToken} </div>
          <button
            className={styles.copyButton}
            onClick={() => {
              if (!crowdinConnection.newCrowdinToken) {
                return;
              }

              setHasCopied(true);
              navigator.clipboard.writeText(crowdinConnection.newCrowdinToken);
            }}
          >
            <FileCopyIcon className={styles.fileCopyIcon} />
            {hasCopied ? "Copied" : "Copy"}
          </button>
        </div>
      )}
    </>
  );
}
