import React from "react";

export default function (props: { fill?: string }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_199_473)">
        <path
          d="M7 8.5H2V9.5H7V8.5ZM10 4.5H2V5.5H10V4.5ZM2 7.5H10V6.5H2V7.5ZM2 2.5V3.5H10V2.5H2Z"
          fill={props.fill || "#787878"}
        />
      </g>
      <defs>
        <clipPath id="clip0_199_473">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
