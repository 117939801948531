import CloseIcon from "@mui/icons-material/Close";
import OpenInNew from "@mui/icons-material/OpenInNew";
import React from "react";
import style from "./style.module.css";

interface IProps {
  title: string;
  description: React.ReactElement;
  learnMoreLink: string;
  onClose: () => void;
}

const PopulatedState = (props: IProps) => {
  const { title, description, learnMoreLink, onClose } = props;
  return (
    <div className={style.populatedState}>
      <a className={style.learnMore} href={learnMoreLink} target="_blank" rel="noopener noreferrer">
        <OpenInNew className={style.learnMoreIcon} />
        Learn More
      </a>
      <div className={style.close}>
        | <CloseIcon className={style.closeIcon} onClick={onClose} />
      </div>
      <p className={style.populatedStateTitle}>{title}</p>
      {description}
    </div>
  );
};

export default PopulatedState;
