import React from "react";

/**
 * This context really only exists to allow the <Unconnected /> version of the
 * LaunchDarkly config modal to communicate some basic property changes to the <Connected />
 * version of the same modal, such as mounting it and immediately opening the management
 * modal.
 */

interface IState {
  showManagementModal: boolean;
  connected: boolean;
}

interface IContext extends IState {
  dispatch: React.Dispatch<IAction>;
}

type IAction =
  | {
      type: "showModal";
      connected: boolean;
    }
  | { type: "hideModal" };

const LaunchDarklyContext = React.createContext({} as IContext);

export const useLaunchDarklyContext = () => React.useContext(LaunchDarklyContext);

const reducer: (state: IState, action: IAction) => IState = (state, action) => {
  switch (action.type) {
    case "showModal": {
      return {
        ...state,
        showManagementModal: true,
        connected: action.connected,
      };
    }
    case "hideModal": {
      return { ...state, showManagementModal: false };
    }
  }
};

const initialValue: IState = {
  connected: false,
  showManagementModal: false,
};

export const LaunchDarklyConnectionProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const value = React.useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return <LaunchDarklyContext.Provider value={value}>{props.children}</LaunchDarklyContext.Provider>;
};
