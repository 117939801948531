import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ButtonSecondary from "../../components/button/buttonsecondary";
import style from "./style.module.css";

interface Props {
  type: "Selection" | "Page" | "App";
  resetError: () => void;
}
const ErrorMsg = ({ type, resetError }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const [currPath, setCurrPath] = useState<string>(location.pathname);
  const refreshPage = () => window.location.reload();

  const returnHome = () => {
    history.push({
      pathname: "/",
    });
    resetError();
  };
  useEffect(() => {
    if (currPath !== location.pathname) {
      resetError();
      setCurrPath(location.pathname);
    }
  }, [currPath, location.pathname]);

  return (
    <div className={style.container}>
      <div className={style.body}>
        {type === "App" && (
          <>
            <div className={style.title}>Sorry, something went wrong</div>
            <div className={style.error}>
              Please relaunch the plugin to try again, or send us an email at{" "}
              <a className={style.emailLink} href="mailto: support@dittowords.com">
                support@dittowords.com
              </a>{" "}
              if the issue persists.
            </div>
            <ButtonSecondary text="Refresh" disabled={false} onClick={refreshPage} />
          </>
        )}
        {type === "Page" && (
          <>
            <div className={style.title}>Sorry, something went wrong</div>
            <div className={style.error}>
              Please return to the home page to try again, or send us an email at{" "}
              <a className={style.emailLink} href="mailto: support@dittowords.com">
                support@dittowords.com
              </a>{" "}
              if the issue persists.
            </div>
            <ButtonSecondary text="Return Home" disabled={false} onClick={returnHome} />
          </>
        )}
        {type === "Selection" && (
          <>
            <div className={style.title}>Sorry, something went wrong</div>
            <div className={style.error}>
              Please try reselecting the item. Send us an email at{" "}
              <a className={style.emailLink} href="mailto: support@dittowords.com">
                support@dittowords.com
              </a>{" "}
              if the issue persists.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorMsg;
