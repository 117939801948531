import classnames from "classnames";
import { Ditto, DittoVariableData, DittoVariableTypeGuards } from "ditto-react";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { WorkspacePlan } from "../../../store/workspaceContext";
import ButtonPrimary from "../../button/buttonprimary";
import ButtonSecondary from "../../button/buttonsecondary";
import style from "./DevToolsModal_NEW.module.css";

/**
 * Modal for enabling developer integrations
 * Assumption: this modal is not accessible DURING a trial
 */
export interface DevToolsModalProps {
  plan: WorkspacePlan;
  devTrialStartDate: string; // empty string for no trial
  onHide: () => void;
  onEnableDevTools: () => Promise<void>;
  handleStartTrial: () => Promise<void>;
}

const DAYS_IN_MS = 1000 * 60 * 60 * 24;

const DevToolsModal = (props: DevToolsModalProps) => {
  const { plan, devTrialStartDate, onHide } = props;

  const [enableIsLoading, setEnableIsLoading] = useState(false);
  const [trialLoading, setTrialLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const daysSinceTrialStarted = devTrialStartDate
    ? Math.floor((new Date().getTime() - new Date(devTrialStartDate).getTime()) / DAYS_IN_MS)
    : 0;

  const trialEndDate = devTrialStartDate
    ? new Date(new Date(devTrialStartDate).getTime() + 14 * DAYS_IN_MS).toLocaleDateString()
    : "";

  const devToolsPrice = () => {
    if (plan === "free") return "free";
    if (plan === "team") return "$80/month";
    if (plan === "growth") return "$200/month";
  };

  async function handleEnableDevTools() {
    setEnableIsLoading(true);
    try {
      await props.onEnableDevTools();
    } catch (error) {
      setError(error.message);
    } finally {
      setEnableIsLoading(false);
    }
  }

  async function handleStartTrial() {
    setTrialLoading(true);
    try {
      await props.handleStartTrial();
    } catch (error) {
      console.error(error.response.data.message);
      setError(error.response.data.message);
    } finally {
      setTrialLoading(false);
    }
  }

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={classnames([style.dialog])}
      backdropClassName={style.backdrop}
      onHide={onHide}
      centered
    >
      <div className={style.body}>
        <div className={style.text}>
          <h1 className={style.title}>
            <Ditto componentId="enable-developer-integrations" />
          </h1>
          <div className={style.description}>
            <Ditto componentId="developer-tooling-integration" richText />
            {plan === "enterprise" && (
              <Ditto
                componentId="developer-tooling-enterprise"
                richText
                variables={{
                  enterpriseContactUs: (variableData: DittoVariableData) => {
                    if (DittoVariableTypeGuards.isHyperlink(variableData)) {
                      return (
                        <a href={variableData.url} target="_blank">
                          {variableData.text}
                        </a>
                      );
                    }
                  },
                }}
              />
            )}
          </div>
        </div>
        <div>
          {/* Only need to show upgrade/trial info for non-enterprise users */}
          {plan !== "enterprise" && (
            <div className={style.btnWrapper}>
              <ButtonPrimary
                className={style.enableButton}
                disabled={enableIsLoading}
                text={enableIsLoading ? "Enabling..." : `Enable for ${devToolsPrice()}`}
                onClick={handleEnableDevTools}
              />
              {!devTrialStartDate && plan !== "free" && (
                <ButtonSecondary
                  className={style.trialButton}
                  disabled={trialLoading}
                  text={trialLoading ? "Starting trial..." : <Ditto componentId="free-trial-offer" />}
                  onClick={handleStartTrial}
                />
              )}
              {daysSinceTrialStarted > 14 && (
                <div className={style.trial_link}>
                  <Ditto componentId="trial-end" variables={{ date: trialEndDate }} />
                </div>
              )}
            </div>
          )}
        </div>

        {error && <div className={style.error}>Couldn't enable developer tools: {error}</div>}
      </div>
    </BootstrapModal>
  );
};

export default DevToolsModal;
