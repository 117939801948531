import Delete from "@mui/icons-material/Delete";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Select, { MenuProps, SelectInstance, components } from "react-select";
import style from "./style.module.css";

export interface User {
  id: string;
  name: string;
}

interface UserSelectProps {
  disabled?: boolean;
  fontSize?: string;
  className?: string;

  /**
   * If defined, the Select dropdown will be controlled by this prop; otehrwise,
   * it will be controlled by react-select's internal state.
   */
  menuIsOpen?: boolean;
  placeholder?: string;
  setSelectedUserId: (userId: string | null) => void;
  selectedUserId: string | null;
  users: User[];
  onRemoveFilter?: () => void;
}

const UserSelect = React.forwardRef((props: UserSelectProps, ref: React.Ref<SelectInstance>) => {
  const {
    className,
    disabled,
    fontSize,
    menuIsOpen,
    placeholder,
    setSelectedUserId,
    selectedUserId,
    users,
    onRemoveFilter,
  } = props;
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  // Set the selected user when the selectedUserId prop changes
  // We need to do this because the assigned user is stored only as an id
  useEffect(() => {
    if (users) {
      setSelectedUser(() => {
        if (selectedUserId === "MIXED") {
          return { id: "MIXED", name: "Mixed assignees" };
        }

        const user = users.find((user) => user.id === selectedUserId);
        return user || null;
      });
    }
  }, [selectedUserId, users]);

  return (
    <div className={classNames(style.wrapper, className)}>
      <Select
        className="user-select"
        classNamePrefix="user-select"
        components={{ Menu }}
        isDisabled={disabled}
        isClearable
        menuIsOpen={menuIsOpen}
        onChange={(option) => setSelectedUserId(option?.value || null)}
        onRemoveFilter={onRemoveFilter}
        options={users.map((user) => ({
          value: user.id,
          label: user.name,
        }))}
        placeholder={placeholder || "Search users..."}
        ref={ref}
        styles={customStyles(fontSize, !!onRemoveFilter)}
        value={selectedUser ? { value: selectedUser.id, label: selectedUser.name } : null}
      />
    </div>
  );
});
UserSelect.displayName = "UserSelect";

const Menu = (props: MenuProps) => {
  return (
    <components.Menu {...props}>
      {props.children}
      {props.selectProps?.onRemoveFilter && (
        <div className={style.removeFilter} onClick={props.selectProps?.onRemoveFilter}>
          <Delete className={style.removeFilterIcon} />
          Remove Filter
        </div>
      )}
    </components.Menu>
  );
};

const customStyles = (fontSize?: string, hasRemoveFilter?: boolean) => {
  const textStyles = {
    fontFamily: "Inter",
    fontSize: fontSize || "14px",
    fontWeight: "500",
  };

  return {
    clearIndicator: () => ({
      cursor: "pointer",
      "& svg": {
        height: "14px",
        marginRight: "6px",
        width: "14px",
        color: "#aaa",
      },
      "&:hover svg": {
        color: "#666",
      },
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : "4px",
      borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : "4px",
      borderColor: "#D9DBDE",
      boxShadow: "none",
      cursor: "cursor",
      minHeight: "0",
      ":hover": {
        borderColor: "#D9DBDE",
      },
    }),
    dropdownIndicator: () => ({ display: "none" }),
    indicatorsContainer: () => ({
      display: "flex",
      paddingBottom: "2px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    input: (provided: any) => ({
      ...provided,
      ...textStyles,
      fontWeight: "normal",
      margin: "2px 0",
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)",
      margin: "0",
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderBottomLeftRadius: hasRemoveFilter ? "none" : "4px",
      borderBottomRightRadius: hasRemoveFilter ? "none" : "4px",
      border: "1px solid #D9DBDE",
      borderTop: "none",
      maxHeight: "90px",
      padding: "0",
    }),
    noOptionsMessage: (provided: any) => ({
      ...provided,
      ...textStyles,
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      ...textStyles,
      backgroundColor: state.isFocused ? "#F9F9F9" : "white",
      borderBottom: "1px solid #D9DBDE",
      borderTop: "none",
      color: "#19191A",
      cursor: "pointer",
      fontWeight: "600",
      overflow: "hidden",
      padding: "4px 10px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      ":hover": {
        backgroundColor: "#F5F5F5",
      },
      ":active": {
        backgroundColor: "#F5F5F5",
      },
      ":focus": {
        backgroundColor: "#F5F5F5",
      },
      ":last-child": {
        borderBottom: "none",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      ...textStyles,
      color: "#19191A33",
      margin: "0",
      transform: "translateY(calc(-50% - 1px))",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      ...textStyles,
      fontWeight: "600",
      margin: "0",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "2px 10px",
      cursor: "text",
    }),
  };
};

export default UserSelect;
