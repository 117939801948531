import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";

import style from "./style.module.css";

interface Props {
  children: React.ReactNode;
  title?: string;
  error?: string | null;
  bodyClassName?: string;
  floatingMessage?: React.ReactNode;
}

const LoginWrapper = (props: Props) => {
  const { title, children, error, floatingMessage, bodyClassName } = props;

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  return (
    <div className={style.container}>
      <div ref={setContainerElement} className={style.modalWrapper}>
        {containerElement && (
          <BootstrapModal
            show
            className={style.modal}
            dialogClassName={style.dialog}
            backdropClassName={style.backdrop}
            onHide={() => null} // can't be hidden
            container={containerElement}
            centered
          >
            {title && (
              <BootstrapModal.Header className={style.header}>
                <BootstrapModal.Title className={style.title}>{title}</BootstrapModal.Title>
              </BootstrapModal.Header>
            )}
            <BootstrapModal.Body className={bodyClassName || style.body}>
              {children}
              {floatingMessage && <div className={style.floatingMessage}>{floatingMessage}</div>}
              {error && (
                <div className={style.error}>
                  <>
                    {error !== "saml_connection_required" && (
                      <div>
                        Sorry, we couldn&apos;t log you in. Please try again, or email us at{" "}
                        <a href="mailto:support@dittowords.com" target="_blank">
                          support@dittowords.com
                        </a>
                        .
                      </div>
                    )}
                    {error === "saml_connection_required" && (
                      <div>
                        Your organization requires logging in via SAML SSO. For help, contact your workspace admin or
                        email us at{" "}
                        <a href="mailto:support@dittowords.com" target="_blank">
                          support@dittowords.com
                        </a>
                        .
                      </div>
                    )}
                  </>
                </div>
              )}
            </BootstrapModal.Body>
          </BootstrapModal>
        )}
      </div>
    </div>
  );
};

export default LoginWrapper;
