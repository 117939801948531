import React, { createContext, useEffect, useRef, useState } from "react";
import UnsavedChangesModal from "../components/unsavedchangesmodal/unsavedchangesmodal";

/**
 * UnsavedChangesContext
 *
 * Usage:
 *
 * If you are performing an action that might discard some changes:
 *    1. Check unsavedDetailChangesExist
 *    2. If they do, call showUnsavedChangesModal()
 *
 * If you have changes in state that could conceivably be discarded
 *    1. Consider whether any of the existing flags (canSaveEdits, canPostComments)
 *       reflect your use case. If they do, use them; if not, add another.
 *    2. Call setCanSaveEdit(true) whenever you're in a modified-but-unsaved state,
 *       and set it back to false whenever you're not.
 *    3. Check against canSaveEdit if you need it
 *    4. Use setModalParams to customize the text and callback functions of the modal
 */

interface UnsavedChangesContext {
  canSaveEdits: React.StatePair<boolean>;
  canSaveComment: React.StatePair<boolean>;
  unsavedDetailChangesExist: boolean;
  showUnsavedChangesModal: (callback?: () => void) => void;
  checkDetailPanelChanges: (callback: () => void) => void;
  setModalParams: (params: ShowModalParams) => void;
}

interface ShowModalParams {
  modalText?: Partial<ModalText>;
  saveCallback?: () => void;
  discardCallback?: () => void;
}

interface ModalText {
  /**
   * The title text for the "Unsaved Changes" modal
   * Defaults to "You have unsaved changes!"
   */
  title: string;

  /**
   * The body text for the "Unsaved Changes" modal
   * Defaults to "Before navigating away, would you like to save your edits?"
   */
  body: string;

  /**
   * The text for the button that saves the changes
   */
  actionPrimary: string;
  actionSecondary: string;
}

const defaultModalText: ModalText = {
  title: "You have unsaved changes!",
  body: "Before navigating away, would you like to save your edits?",
  actionPrimary: "Save",
  actionSecondary: "Discard",
};

export const UnsavedChangesContext = createContext({} as UnsavedChangesContext);
export const UnsavedChangesContextMockProvider = ({ children }: { children: React.ReactNode }) => (
  <UnsavedChangesContext.Provider
    value={{
      canSaveEdits: [true, () => null],
      canSaveComment: [true, () => null],
      unsavedDetailChangesExist: true,
      checkDetailPanelChanges: () => null,
      showUnsavedChangesModal: () => null,
      setModalParams: () => null,
    }}
  >
    {children}
  </UnsavedChangesContext.Provider>
);

export const UnsavedChangesProvider = ({ children }) => {
  // Modal state
  const [showModal, setShowModal] = useState(false);
  const modalText = useRef<ModalText>(defaultModalText);

  // Callbacks to be executed by the modal actions, and after it's destroyed
  // Note: These callbacks are responsible for resetting any state that has been
  // changed with the setter methods!
  const saveCallback = useRef(() => {});
  const discardCallback = useRef(() => {});

  // Callback to be run after the modal stops showing
  const afterModalCallback = useRef(() => {});
  useEffect(() => {
    // we only run our cleanup if showModal is true, because we want to execute
    // the callback after we have started
    return () => {
      if (showModal) {
        afterModalCallback.current();
      }
    };
  }, [showModal]);

  // State for access through context
  const canSaveEdits = useState(false);
  const canSaveComment = useState(false);

  // can *anything* be saved?
  const [unsavedDetailChangesExist, setUnsavedDetailChangesExist] = useState(false);

  // keep in sync with both of our DetailPanel-centric states
  useEffect(() => {
    setUnsavedDetailChangesExist(canSaveEdits[0] || canSaveComment[0]);
  }, [canSaveEdits[0], canSaveComment[0]]);

  // Calls to setModalParams should generally be wrapped in a useEffect
  const setModalParams = (params: ShowModalParams) => {
    modalText.current = { ...defaultModalText, ...(params.modalText || {}) };

    if (params.saveCallback) saveCallback.current = params.saveCallback;
    if (params.discardCallback) discardCallback.current = params.discardCallback;
  };

  // Callback function will be called immediately if there are no unsaved changes,
  // or after the modal closes if there are changes.
  const checkDetailPanelChanges = (callback: () => void) => {
    if (!unsavedDetailChangesExist) {
      if (callback) callback();
    } else {
      setShowModal(true);
      if (callback) afterModalCallback.current = callback;
    }

    return unsavedDetailChangesExist;
  };

  const showUnsavedChangesModal = (callback?: () => void) => {
    if (callback) afterModalCallback.current = callback;
    setShowModal(true);
  };

  // Values to expose to consumers of the context
  const store = {
    canSaveEdits,
    canSaveComment,
    unsavedDetailChangesExist,
    checkDetailPanelChanges,
    showUnsavedChangesModal,
    setModalParams,
  };

  // Modal handlers
  const saveFunction = () => {
    setShowModal(false);
    saveCallback.current();
  };

  const discardFunction = () => {
    setShowModal(false);
    discardCallback.current();
  };

  // note: after modal is dismissed, it is very important that all canSave states are
  // reset back to false! this should always be the result of BOTH callbacks!
  return (
    <UnsavedChangesContext.Provider value={store}>
      {children}
      {showModal && (
        <UnsavedChangesModal
          modalText={modalText.current}
          saveFunction={saveFunction}
          discardFunction={discardFunction}
        />
      )}
    </UnsavedChangesContext.Provider>
  );
};
