const includesFigmaArtifacts = (groupName: string) =>
  groupName.startsWith("[[M]]") || groupName.startsWith("[[I]]") || groupName.startsWith("[[V]]");

export const removeFigmaArtifactsFromGroupName = (groupName: string | null | undefined) => {
  if (!groupName) return "";
  const sanitizedGroupName = groupName.replace("✍️ ", "");
  if (!includesFigmaArtifacts(sanitizedGroupName)) return sanitizedGroupName;

  return sanitizedGroupName.substring(5);
};
