import * as SegmentEvents from "@shared/segment-event-names";
import React from "react";
import { UserJobs } from "../../../../shared/types/User";
import ButtonPrimary from "../../../components/button/buttonprimary";

import SampleBadge from "@/components/SampleBadge";
import { useParams } from "react-router-dom";
import useSegment from "../../../hooks/useSegment";
import style from "./style.module.css";

interface SampleProjectBannerProps {
  job: string;
  setJob: (job: string) => void;
  handleViewTour: () => void;
}

const SampleProjectBanner = (props: SampleProjectBannerProps) => {
  const segment = useSegment();

  function handleJobChange(event: React.ChangeEvent<HTMLSelectElement>) {
    props.setJob(event.target.value);
  }

  const params = useParams<{ pageId: string }>();

  const handleViewTour = () => {
    segment.track({
      event: SegmentEvents.SAMPLE_PROJECT_VIEW_PRODUCT_TOUR_CLICKED,
      properties: {
        job: props.job,
      },
    });
    props.handleViewTour();
  };

  return (
    <div className={style.container}>
      <div className={style.sampleProjectBanner}>
        <h2 className={style.title}>
          Welcome! 👋 You're viewing a <SampleBadge className={style.sampleBadge}>sample</SampleBadge> Ditto project.
        </h2>
        {props.job !== "ENGINEER" && (
          <span>
            Explore this project to see what's possible in Ditto.{" "}
            <a href="/projects" className={style.sampleBannerLink}>
              Create your own project
            </a>{" "}
            whenever you're ready.
          </span>
        )}
        {props.job === "ENGINEER" && (
          <span>
            Explore this project to see what's possible in Ditto. Connect it to one of{" "}
            <a href="https://developer.dittowords.com/example-apps" className={style.sampleBannerLink} target="_blank">
              our example apps
            </a>{" "}
            to test out our API/CLI in an end-to-end integration.
          </span>
        )}
        {params.pageId !== "__DRAFT__" && (
          <div className={style.setRoleAndButton}>
            <div>My role is in</div>
            <select className={style.select} value={props.job} onChange={handleJobChange}>
              {UserJobs.map((job) => {
                let jobLabel = "Select one";
                switch (job) {
                  case "NONE":
                    jobLabel = "Select one";
                    break;
                  case "ENGINEER":
                    jobLabel = "engineering";
                    break;
                  case "WRITER":
                    jobLabel = "writing";
                    break;
                  default:
                    jobLabel = job.toLowerCase();
                    break;
                }
                return (
                  <option key={job} value={job}>
                    {jobLabel}
                  </option>
                );
              })}
            </select>
            <ButtonPrimary className={style.button} text={"View tour ->"} onClick={handleViewTour} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SampleProjectBanner;
