import classNames from "classnames";
import React from "react";
import { WorkspacePlan } from "../../store/workspaceContext";
import style from "./PlanBadge.module.css";

interface PlanBadgeProps {
  planType: WorkspacePlan;
  className?: string;
  onClick?: () => void;
}

const PlanBadge = (props: PlanBadgeProps) => {
  function planText() {
    switch (props.planType) {
      case "free":
        return "Starter";
      case "trial":
        return "2 Week Trial";
      case "team":
        return "Team";
      case "growth":
        return "Growth";
      case "enterprise":
        return "Enterprise";
      default:
        return "Starter";
    }
  }

  // Trial and Team have same style
  function planClass() {
    return props.planType === "trial" ? "team" : props.planType;
  }

  function onClick() {
    if (props.onClick) props.onClick();
  }

  return (
    <div onClick={onClick} className={classNames(style.planBadge, props.className, style[planClass()])}>
      {planText()}
    </div>
  );
};

export default PlanBadge;
