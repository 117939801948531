import classNames from "classnames";
import React, { forwardRef } from "react";
import style from "./NavLink.module.css";

interface SimpleLinkProps {
  onClick: () => void;
  Icon: React.FC;
  ActiveIcon?: React.FC;
  className?: string;
  text?: string;
  collapsed?: boolean;
  children?: React.ReactNode;
  active?: boolean;
  testId?: string;
}

/**
 * This component matches the exact visual design of the NavLink component, but doesn't interact with any routing logic.
 */
const SimpleLink = forwardRef(function (props: SimpleLinkProps, ref: React.ForwardedRef<HTMLDivElement | null>) {
  return (
    <div
      data-testid={props.testId}
      className={classNames(style.navLink, props.className, {
        [style.collapsed]: props.collapsed,
        [style.active]: props.active,
      })}
      ref={ref}
      onClick={props.onClick}
      data-intercom-target={`nav-link-${props.text}`}
    >
      <div className={style.icon}>{props.active && props.ActiveIcon ? <props.ActiveIcon /> : <props.Icon />}</div>
      {props.children}
      {props.text && (
        <span className={classNames(style.linkText, { [style.collapsed]: props.collapsed })}>{props.text}</span>
      )}
    </div>
  );
});

export default SimpleLink;
