import React, { useContext } from "react";
import { CsvImportContext } from "../CsvImportContext";
import style from "./CsvPreview.module.css";

interface CsvPreviewProps {}

function CsvPreview(props: CsvPreviewProps) {
  const { dataWithRowNumbers, header, body, columnHighlights } = useContext(CsvImportContext);

  return (
    <div className={style.csvPreview}>
      <p className={style.tableInfoMessage}>
        CSV File Preview - Showing {body.length > 1 ? body[body.length - 1][0] : 0} rows
      </p>
      <div className={style.csvPreviewTableContainer}>
        <table className={style.csvPreviewTable}>
          <thead>
            <tr>
              {dataWithRowNumbers.length > 0 &&
                dataWithRowNumbers[0].map((_cell, index) => (
                  // dont show 0
                  <td
                    key={"number" + index}
                    className={style.numberCell}
                    style={{
                      ...(columnHighlights[index] && {
                        backgroundColor: columnHighlights[index],
                      }),
                    }}
                  >
                    {index === 0 ? "" : index}
                  </td>
                ))}
            </tr>
            <tr>
              {header.map((cell, index) => (
                <th
                  key={index}
                  style={{
                    ...(columnHighlights[index] && {
                      backgroundColor: columnHighlights[index],
                    }),
                  }}
                >
                  {cell}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row, index) => (
              <tr key={index}>
                {row.map((cell, index) => (
                  <td
                    key={index}
                    style={{
                      ...(columnHighlights[index] && {
                        backgroundColor: columnHighlights[index],
                      }),
                    }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CsvPreview;
