import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import logger from "@shared/utils/logger";
import classNames from "classnames";
import React, { useMemo } from "react";
import { calculatePageArray } from "./lib";
import style from "./style.module.css";

interface IProps {
  pageIndex: number;
  pageCount: number;
  disabled?: boolean;
  onPageChange: (page: number) => void;

  className?: string;
  style?: React.CSSProperties;
  containerRef?: React.RefObject<HTMLDivElement>;
}

export function PaginationController(props: IProps) {
  if ((props.pageCount ?? 0) <= 1) {
    return <React.Fragment />;
  }

  return <PaginationControllerLoaded {...props} />;
}

function PaginationControllerLoaded(props: IProps) {
  const pageArray = useMemo(
    () => calculatePageArray(props.pageIndex, props.pageCount),
    [props.pageIndex, props.pageCount]
  );

  const onBackClick = () => {
    if (props.pageIndex === 0) return;

    const pageIndex = props.pageIndex - 1;
    props.onPageChange(pageIndex);
  };

  const onNextClick = () => {
    if (props.pageIndex === props.pageCount - 1) return;

    const pageIndex = props.pageIndex + 1;
    props.onPageChange(pageIndex);
  };

  const onPageClick = (pageValue: string) => {
    let pageIndex: number;
    try {
      pageIndex = parseInt(pageValue) - 1;
      if (pageIndex === props.pageIndex) return;
      props.onPageChange(pageIndex);
    } catch (e) {
      const message = "Failed to parse page during page click";
      logger.error(message, {}, new Error(message));
    }
  };

  return (
    <div className={classNames([style.container, props.className])} style={props.style} ref={props.containerRef}>
      <PageButton onClick={onBackClick} disabled={props.pageIndex === 0}>
        <ChevronLeftIcon /> Back
      </PageButton>
      {pageArray.map((pageValue, i) => {
        const pageIndex = parseInt(pageValue) - 1;
        const selected = pageIndex === props.pageIndex;
        const key = `${pageValue}-${i}`;

        return (
          <PageButton
            key={key}
            onClick={() => onPageClick(pageValue)}
            selected={selected}
            disabled={pageValue === "..."}
          >
            {pageValue}
          </PageButton>
        );
      })}
      <PageButton onClick={onNextClick} disabled={props.pageIndex === props.pageCount - 1}>
        Next <ChevronRightIcon />
      </PageButton>
    </div>
  );
}

function PageButton(props: { children: React.ReactNode; onClick: () => void; disabled?: boolean; selected?: boolean }) {
  const onClick = () => {
    if (props.disabled) return;
    props.onClick();
  };

  return (
    <button
      className={classNames({
        [style.pageButton]: true,
        [style.disabled]: props.disabled,
        [style.selected]: props.selected,
      })}
      onClick={onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
