import classnames from "classnames";
import { Ditto, useDittoComponent } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface Props {
  billingEmail: string;
  lastFour: string;
  paymentMethod: string;
  planName: string;
  onClick?: () => void;
}
export default function PaymentPanel(props: Props) {
  const buttonText = useDittoComponent({
    componentId: "update-billing-information",
  });

  const missingPaymentInformationText = useDittoComponent({
    componentId: "missing-payment-information",
  });

  return (
    <Panel
      buttonType="secondary"
      buttonText={buttonText || ""}
      titleDittoComponentId="payment-panel-title"
      onClick={props.onClick}
    >
      <table className={style.table} data-testid="payment-panel">
        <tbody>
          <tr className={style.divider}>
            <td className={style.title}>
              <Ditto componentId="payment-method" />
            </td>
            <td className={classnames([style.value, style.capitalized])}>
              {props.paymentMethod !== "" ? (
                <>
                  {props.paymentMethod}
                  <span className={style.notBold}> ending in </span>
                  {props.lastFour}
                </>
              ) : (
                <div className={style.red}>{missingPaymentInformationText}</div>
              )}
            </td>
          </tr>
          <tr className={style.divider}>
            <td className={style.title}>
              <Ditto componentId="billing-email" />
            </td>
            <td className={classnames({ [style.value]: true, [style.red]: !props.billingEmail })}>
              {props.billingEmail || missingPaymentInformationText}
            </td>
          </tr>
          <tr>
            <td className={style.title}>
              <Ditto componentId="plan-label" />
            </td>
            <td className={classnames([style.value, style.capitalized])}>
              <span>{props.planName}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
  );
}
