import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { IUserJob, UserJobs } from "@shared/types/User";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import logger from "../../../shared/utils/logger";
import WelcomeGraphic from "../../assets/welcome-graphic.png";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

interface Props {
  getStarted?: () => void;
}

const WelcomeModal = (props: Props) => {
  const segment = useSegment();

  const { user, refreshUser } = useAuthenticatedAuth();
  const [show, setShow] = useState(true);
  const [job, setJob] = useState<IUserJob>("NONE");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // User is populated asynchronously, so we need to prepopulate job once user has loaded
  useEffect(() => {
    setJob(user.job);
  }, [user.job]);

  const openSampleProject = async () => {
    try {
      let sampleProjectId: string | null;
      const { data } = await http.get("/api/projects/sample");
      sampleProjectId = data?._id;

      // create sample data in the workspace if it's not already there
      if (!sampleProjectId) {
        const res = await http.put("/workspace/createSampleData");
        sampleProjectId = res.data?.ids.projectId;
      }

      // navigate to the sample project
      if (sampleProjectId) {
        window.location.assign(`/projects/${sampleProjectId}/page/0:1?showActivateModal=true`);
      }
    } catch (error) {
      logger.error("Error opening sample project", {}, error);
      setError("Could not open sample project");
    }
  };

  const updateUserFinishedWelcome = async () => {
    try {
      // close the modal
      setShow(false);

      // update the user's info to indicate that they've seen the welcome modal
      const { url, body } = API.user.put.updateInfo;
      await http.put(url, body({ finishedDittoOverview: true, job }));

      // refresh the user's info in state to reflect this
      refreshUser();
    } catch (error) {
      setError("Could not update user info: " + error.response.data.message);
    }
  };

  function handleGetStartedClick() {
    segment.track({
      event: SegmentEvents.WELCOME_MODAL_EXPLORE_ON_OWN_SELECTED,
    });

    props.getStarted ? props.getStarted() : updateUserFinishedWelcome();
  }

  function handleJobChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    if (!UserJobs.includes(value as IUserJob)) {
      console.error(`User set invalid job: ${value}`);
      setError(`Invalid job: ${value}`);
    }
    setJob(value as IUserJob);
  }

  // close the welcome modal and navigate to the sample project
  async function handleLetsGoClick() {
    setLoading(true);

    if (job === "NONE") {
      setError("Please select a role");
      return;
    }

    segment.track({
      event: "Welcome Modal — Explore on Own Selected",
      properties: {
        job: job,
      },
    });

    // update user info before navigating so that the sample project is populated
    // with a user's selected job
    await updateUserFinishedWelcome();
    await openSampleProject();

    setLoading(false);
  }

  const welcomeText = !/(@|,)/.test(user.name) ? `, ${user.name}` : "";

  return (
    <BootstrapModal
      show={show && user.finishedDittoOverview === false}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={() => {}}
      centered
    >
      <BootstrapModal.Body className={style.body}>
        <div className={style.header}>
          <h1 className={style.title}>Welcome to Ditto{welcomeText}! 👋</h1>
          <span className={style.subtitle}>
            Ship clear and consistent copy as a team, faster. Ditto allows your team to build a single source of truth
            for product text, from draft to design to development.
          </span>
          <img src={WelcomeGraphic} className={style.graphic} />
        </div>
        <span className={style.subtitle}>Get started by exploring our fully set-up sample project.</span>
        <div className={style.setRoleAndButton}>
          <span>My role is in</span>
          <select className={style.select} value={job} onChange={handleJobChange}>
            {UserJobs.map((job) => {
              let jobLabel = "Select one";
              switch (job) {
                case "NONE":
                  jobLabel = "Select one";
                  break;
                case "ENGINEER":
                  jobLabel = "engineering";
                  break;
                case "WRITER":
                  jobLabel = "writing";
                  break;
                default:
                  jobLabel = job.toLowerCase();
                  break;
              }

              return (
                <option key={job} value={job}>
                  {jobLabel}
                </option>
              );
            })}
          </select>
          <ButtonPrimary
            className={style.button}
            disabled={loading || job === "NONE"}
            text="Let's go ->"
            onClick={handleLetsGoClick}
          />
        </div>
        <div className={style.footer}>
          {error && <span className={style.error}>{error}</span>}
          <span className={classNames(style.subtitle, style.getStarted)} onClick={handleGetStartedClick}>
            Or, get started on your own
          </span>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default WelcomeModal;
