import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import http, { API } from "../../http";
import BetaBadge from "../BetaBadge";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import richTextGif from "./rich_text.gif";
import style from "./style.module.css";

interface Props {
  richTextEnabled: boolean;
  projectId: string;
  onHide: () => void;
  handleRichTextEnabled: () => void;
}
const RichTextModal = ({ richTextEnabled, projectId, onHide, handleRichTextEnabled }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { url, body } = API.project.put.updateFeatureFlag;

  const toggleRichText = async () => {
    try {
      setIsLoading(true);
      setError(false);
      await http.put(url(projectId), body({ flagName: "rich_text", enabled: !richTextEnabled }));
      await handleRichTextEnabled();
      onHide();
    } catch (e) {
      setError(true);
      console.error("Caught error in RichTextModal:", e.message);
    }
    setIsLoading(false);
  };

  const handleHide = () => {
    if (!isLoading) onHide();
  };

  if (richTextEnabled) {
    return (
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={classnames([style.dialog])}
        backdropClassName={style.backdrop}
        onHide={handleHide}
        centered
      >
        <BootstrapModal.Header>
          <BootstrapModal.Title className={style.title}>Disable rich text in this project</BootstrapModal.Title>
          {!isLoading && <CloseIcon className={style.close} onClick={handleHide} />}
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div>
            {error && <div className={style.errorMsg}>There was an error disabling rich text. Please try again.</div>}
            <div className={style.description}>
              <p>
                Disabling rich text in this project will mean you can no longer add bold, italic, and underline styling
                to your text. Any rich text styling you apply in Ditto will also no longer apply to the text in Figma.
              </p>
              <p className={style.noMargin}>
                If your team has any feedback regarding Ditto’s rich text functionality, please{" "}
                <a className={style.emailLink} href="mailto:support@dittowords.com">
                  let us know
                </a>
              </p>
            </div>
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <div className={style.footerText}>
            <a href="https://www.dittowords.com/docs/rich-text" target="_blank">
              Learn more about rich text
            </a>
          </div>
          <div className={style.footerBtnWrapper}>
            <ButtonSecondary text="Go Back" onClick={onHide} disabled={isLoading} />
            <ButtonPrimary onClick={toggleRichText} text={!isLoading ? "Disable" : "Loading..."} disabled={isLoading} />
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  }

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={classnames([style.dialog])}
      backdropClassName={style.backdrop}
      onHide={handleHide}
      centered
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title className={style.title}>
          Would you like to enable rich text in this project?
        </BootstrapModal.Title>
        {!isLoading && <CloseIcon className={style.close} onClick={handleHide} />}
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div>
          <div className={style.subheading}>
            <BetaBadge text="This feature is currently in beta." />
          </div>
          {error && <div className={style.errorMsg}>There was an error enabling rich text. Please try again.</div>}
          <div className={style.description}>
            <p className={style.noMargin}>
              Enabling rich text in this project will allow you to add rich text styling.
            </p>
            <img src={richTextGif} alt="Demo of rich text" />
            <p>
              Any rich text styling you apply in Ditto will also apply to the text in Figma if the fonts are available.
            </p>
            <p className={style.noMargin}>
              If your team has any feedback regarding Ditto’s rich text functionality, please{" "}
              <a className={style.emailLink} href="mailto:support@dittowords.com">
                let us know
              </a>
              . You can always disable rich text in your project at any time.
            </p>
          </div>
        </div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <div className={style.footerText}>
          <a href="https://www.dittowords.com/docs/rich-text" target="_blank">
            Learn more about rich text
          </a>
        </div>
        <div className={style.footerBtnWrapper}>
          <ButtonSecondary text="Go Back" onClick={onHide} disabled={isLoading} />
          <ButtonPrimary onClick={toggleRichText} text={!isLoading ? "Enable" : "Loading..."} disabled={isLoading} />
        </div>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default RichTextModal;
