import isEqual from "lodash.isequal";
import { useMemo } from "react";

const BASE_VARIANT_ID = "__base__";
const getVariantVariableMap = (variant) => {
  if (variant?.plurals?.length) {
    return (variant?.plurals || []).reduce((agg, plural, index) => {
      agg[index] = (plural?.variables || []).reduce((acc, variable) => ({ ...acc, [variable]: true }), {});
      return agg;
    }, {});
  } else {
    return [(variant?.variables || []).reduce((acc, variable) => ({ ...acc, [variable.variable_id]: true }), {})];
  }
};
export const useHasTextChanges = (previousComponent, richTextEnabled) => {
  // Used for O(1) lookups
  // Creates an array containing the variable hashmaps of each plural case i.e variablesMap[pluralIndex][variable_id]
  // if plurals are not enabled, default first index to the base variables
  const originalVariantVariablesMap = useMemo(() => {
    const temp = {};
    temp[BASE_VARIANT_ID] = getVariantVariableMap(previousComponent);
    (previousComponent?.variants || []).map((variant) => {
      temp[variant.variantId] = getVariantVariableMap(variant);
    });
    return temp;
  }, [previousComponent, richTextEnabled]);

  const isDiffRichText = (oldText, newText) => {
    return !isEqual(oldText, newText);
  };

  const havePluralVariablesChanged = (newVariables, selectedVariantId, index) => {
    return (
      (newVariables || []).length !==
        Object.keys(originalVariantVariablesMap[selectedVariantId]?.[index] || {}).length ||
      (newVariables || []).some(
        (variable) => !originalVariantVariablesMap[selectedVariantId][index][variable.variable_id]
      )
    );
  };

  return (plurals, selectedVariantId) => {
    let previousText = previousComponent;

    if (selectedVariantId && selectedVariantId !== BASE_VARIANT_ID && previousText && previousText?.variants) {
      let found = false;
      for (var variant of previousText.variants) {
        if (variant?.variantId === selectedVariantId) {
          previousText = variant;
          found = true;
          break;
        }
      }
      if (!found) previousText = { text: "", variables: [], plurals: [] };
    }
    if (!plurals.length) return false;
    if (plurals.length === 1) {
      const {
        form,
        value: { text, richText, variables },
      } = plurals[0];
      // pluralization previously enabled
      if (previousText?.plurals?.length) {
        // pluralization disabled or form changed
        if (!form || form !== previousText.plurals[0].form) return true;
        // less plural cases than previously
        if (previousText.plurals.length !== plurals.length) return true;
        // check if text changed
        if (richTextEnabled) {
          return (
            text !== previousText?.text ||
            isDiffRichText(richText, previousText?.rich_text) ||
            havePluralVariablesChanged(variables, selectedVariantId, 0)
          );
        }
        return text !== previousText?.text || havePluralVariablesChanged(variables, selectedVariantId, 0);
      } else {
        if (plurals.length > 1) return true;
        if (plurals.length === 1 && form) return true;
        if (richTextEnabled) {
          return (
            text !== previousText?.text ||
            isDiffRichText(richText, previousText?.rich_text) ||
            havePluralVariablesChanged(variables, selectedVariantId, 0)
          );
        }
        return text !== previousText?.text || havePluralVariablesChanged(variables, selectedVariantId, 0);
      }
    }
    // difference between number of previousText and draft plurals
    else if (plurals.length !== previousText?.plurals?.length) {
      return true;
    }
    // same number of previousComponent and draft plurals, check to see if
    // form, or text has changed
    else {
      const allTextSame = plurals.every(({ form, value: { text, variables, richText } }, index) => {
        const plural = previousText.plurals[index];
        if (form !== plural.form) return false;

        if (
          richTextEnabled &&
          (text !== plural.text ||
            isDiffRichText(richText, plural.rich_text) ||
            havePluralVariablesChanged(variables, selectedVariantId, index))
        ) {
          return false;
        }
        if (text !== plural.text || havePluralVariablesChanged(variables, selectedVariantId, index)) {
          return false;
        }
        return true;
      });
      return !allTextSame;
    }
  };
};
