export const rolePermissionsMap = {
  admin: {
    static: [
      "users:edit:admin",
      "users:edit:editor",
      "users:delete",
      "billing:edit",
      "users:edit:admin",
      "users:edit:editor",
      "users:edit:commenter",
      "project_folder:create",
      "component_folder:create",
    ],
    dynamic: [],
  },
  editor: {
    static: ["users:edit:editor", "users:edit:commenter", "project_folder:create", "component_folder:create"],
    dynamic: [],
  },
  commenter: {
    static: [],
    dynamic: [],
  },
};

export const workspaceFeatureMap = {
  free: [],
  trial: [],
  team: [],
  growth: ["component-folders"],
  enterprise: ["component-folders"],
};
