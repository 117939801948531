import classNames from "classnames";
import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CopyButton from "../CopyButton/CopyButton";
import style from "./CodeGroup.module.css";

interface CodeBlock {
  filename: string;
  children: React.ReactNode;
}
interface CodeGroupProps {
  codeBlocks: CodeBlock[];
  copyCallback?: () => void;
  className?: string;
}

/**
 * Converts a React Node to a plain text string.
 *
 * @param {React.ReactNode} children - The React Node to be converted.
 * @returns {string} The plain text representation of the React Node.
 *
 * If the input is a string, it is returned as is.
 * If the input is an array, the function is recursively called on each element and the results are joined.
 * If the input is a valid React element, the function is recursively called on its children.
 * If the input is none of the above, an empty string is returned.
 */
const markupToPlainText = (children: React.ReactNode): string => {
  if (typeof children === "string") {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map(markupToPlainText).join("");
  }
  if (React.isValidElement(children)) {
    return markupToPlainText(children.props.children);
  }
  return "";
};

const CodeGroup = (props: CodeGroupProps) => {
  const defaultKey = props.codeBlocks[0].filename;

  return (
    <div className={classNames(style.codeGroup, props.className)}>
      <Tabs defaultActiveKey={defaultKey} transition={false}>
        {props.codeBlocks.map((block) => (
          <Tab title={block.filename} eventKey={block.filename} key={block.filename} tabClassName={style.tab}>
            <div className={style.content}>{block.children}</div>
            <CopyButton
              textToCopy={markupToPlainText(block.children)}
              className={style.copyButton}
              callback={props.copyCallback}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default CodeGroup;
