import SelectMenu from "@/components/select-menu/select-menu";
import MailOutline from "@mui/icons-material/MailOutline";
import MoreVert from "@mui/icons-material/MoreVert";
import classnames from "classnames";
import React, { useState } from "react";
import { InviteProps } from ".";
import PermissionTags from "../../permissionTags";
import style from "./style.module.css";

interface Props {
  divider?: boolean;
  invite: InviteProps;
  canEditInvites: boolean;
  onResendInviteClick: (invite: InviteProps) => void;
  onRevokeInviteClick: (invite: InviteProps) => void;
  permissionGroups: { _id: string; name: string }[];
}

export default function InviteRow(props: Props) {
  const [show, setShow] = useState(false);

  function capitalize(str: string | undefined) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <tr
        className={classnames({ [style.divider]: props.divider })}
        data-testid={`invite-row-${props.invite.email}`}
        onMouseLeave={() => setShow(false)}
        onMouseEnter={() => setShow(true)}
      >
        <td className={style.column}>
          <span className={style.email} data-testid="invite-email">
            {props.invite.email}
          </span>{" "}
          {props.canEditInvites && (
            <span className={style.resend} onClick={() => props.onResendInviteClick?.(props.invite)}>
              <MailOutline className={style.icon} /> Resend
            </span>
          )}
        </td>
        <td className={style.permissionsColumn}>
          <div className={style.lastColumn}>
            <PermissionTags permissions={props.invite.permissions} permissionGroups={props.permissionGroups} />
            {show && props.canEditInvites && (
              <SelectMenu
                options={[
                  {
                    name: "Revoke invite",
                    value: "revoke-invite",
                  },
                ]}
                icon={
                  <button className={style.optionsBtn}>
                    <MoreVert className={style.dotsIcon} />
                  </button>
                }
                selected={""}
                onSelect={() => {
                  props.onRevokeInviteClick?.(props.invite);
                }}
                optionClassName={style.option}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
}
