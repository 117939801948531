import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as SegmentEvents from "@shared/segment-event-names";
import classNames from "classnames";
import React from "react";
import useSegment from "../../../../hooks/useSegment";
import ButtonPrimary from "../../../button/buttonprimary";
import { ModalBody, ModalFooter } from "../../../shared/ModalBase";
import { useImportCreateRequest } from "../../steps/choose-action/useImportCreateRequest";
import { CsvImportContext } from "../CsvImportContext";
import CsvPreview from "../CsvPreview";
import CsvSettings from "../CsvSettings";
import style from "./MappingModal.module.css";

function MappingModal(props: { dispatchImportState?: (any) => void; importState?: any }) {
  const segment = useSegment();

  const {
    selectedTextColumnIdx,
    selectedNameColumnIdxs,
    selectedStatusColumnHasError,
    selectedComponentIdColumnError,
    generateImports,
    dataWithRowNumbers,
  } = React.useContext(CsvImportContext);

  const nextDisabled = React.useMemo(() => {
    const hasErrors = selectedStatusColumnHasError || !!selectedComponentIdColumnError;

    const hasAllRequiredSelections =
      selectedTextColumnIdx && selectedNameColumnIdxs && selectedNameColumnIdxs.length > 0;

    return !hasAllRequiredSelections || hasErrors;
  }, [selectedTextColumnIdx, selectedNameColumnIdxs, selectedStatusColumnHasError, selectedComponentIdColumnError]);

  const [importDataError, setImportDataError] = React.useState<string | null>(null);

  const importCreateRequest = useImportCreateRequest();

  const onNext = async () => {
    const selectedFolderId = props.importState?.folderSelectState.selectedFolder?._id;
    const importData = await generateImports(selectedFolderId);
    if (!props.dispatchImportState) return;
    segment.track({ event: SegmentEvents.CSV_IMPORT_MAPPED });

    props.dispatchImportState({
      type: "IMPORT_RESPONSE",
      data: importData,
    });

    const needsChooseAction = importData.existingComponentsCount > 0;
    if (needsChooseAction) {
      return props.dispatchImportState({
        type: "SET_STEP",
        step: "choose-action",
      });
    }

    const response = await importCreateRequest(importData.backgroundJobEntryId);
    if (!response.success) {
      return;
    }

    const { components, componentsIgnored, ignoredComponentsCount } = response.data;

    return props.dispatchImportState({
      type: "IMPORT_CREATE_RESPONSE",
      step: "preview",
      data: {
        components,
        componentsIgnored,
        ignoredComponentsCount,
      },
    });
  };

  const onBackClick = () => {
    if (!props.dispatchImportState) return;

    props.dispatchImportState({
      type: "SET_STEP",
      step: "file-upload",
    });
  };

  if (!dataWithRowNumbers.length && props.dispatchImportState) {
    props.dispatchImportState({
      type: "SET_STEP",
      step: "file-upload",
      error: "invalid-csv",
    });
  }

  return (
    <>
      <ModalBody>
        <div className={style.mappingModal}>
          <div className={style.previewSettingsContainer}>
            <div className={style.previewContainer}>
              <CsvPreview />
            </div>
            <div className={style.settingsContainer}>
              <CsvSettings />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={style.footer}>
        <span role="link" className={style.footerLink} onClick={onBackClick}>
          <ArrowBackIcon className={classNames([style.icon, style.footerLinkIcon])} />
          <span>Back</span>
        </span>
        {importDataError && <span className={style.error}>{importDataError}</span>}
        <ButtonPrimary text="Next -->" onClick={onNext} disabled={nextDisabled} data-testid="next-modal-screen" />
      </ModalFooter>
    </>
  );
}

export default MappingModal;
