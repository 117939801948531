import React from "react";
import style from "./style.module.css";

interface Props {
  categories: string[];
  onClick: (category: string) => void;
}

const ComponentCategoreisMenu = ({ categories, onClick }: Props) => {
  return (
    <div className={style.container}>
      <div className={style.categories}>
        {categories.map((category) => (
          <div key={category} className={style.category} onClick={() => onClick(category)}>
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComponentCategoreisMenu;
