import FolderSelect from "@/components/FolderSelect";
import ArrowOutward from "@mui/icons-material/ArrowOutward";
import CheckIcon from "@mui/icons-material/Check";
import { SYNC_SETTINGS_SPECIAL_FOLDER_IDS } from "@shared/lib/project";
import classNames from "classnames";
import { DittoComponent, useDittoComponent } from "ditto-react";
import React, { useState } from "react";
import "react-toggle/style.css";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import ModalBase, { ModalBody, ModalFooter } from "../../../../components/shared/ModalBase";
import { IState, getStateHasChanged } from "./lib";
import style from "./style.module.css";

export interface IProps {
  initialState?: IState;
  figmaFileId?: string;
  data: {
    pages: { id: string; name: string }[];
  } & (
    | {
        foldersAvailable: true;
        folders: { _id: string; name: string }[];
      }
    | {
        foldersAvailable: false;
        folders?: { _id: string; name: string }[];
      }
  );
  onSave: (state: IState) => void;
  onHide: () => void;
  toggleFrameModal?: () => void;
}

const defaultState: IState = {
  autoAttachComponentMatches: {
    enabled: false,
    folderId: SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ALL_COMPONENTS,
  },
  autoImportFrames: { enabled: false, pageIds: new Set() },
};

export function SyncSettingsContent(
  props: IProps & {
    state: IState;
    toggleFrameModal?: () => void;
    onToggleAutoAttachComponentMatches: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onToggleAutoImportFrames: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSelectedFolder: (folder: { _id: string; name: string }) => void;
    onPageSelect: (pageId: string) => () => void;
    figmaFileId?: string;
  }
) {
  const { state } = props;

  const textAllComponents = useDittoComponent({
    componentId: "component-folder-selection.all-components",
  });
  const textRoot = useDittoComponent({
    componentId: "component-folder-selection.root",
  });

  const folderOptions = [
    { _id: SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ALL_COMPONENTS, name: textAllComponents! },
    { _id: SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ROOT, name: textRoot! },
    ...(props.data.folders || []),
  ];
  const folderSelected = folderOptions.find((f) => f._id === state.autoAttachComponentMatches.folderId) || {
    _id: SYNC_SETTINGS_SPECIAL_FOLDER_IDS.LOADING,
    name: "Loading...",
  };

  const showFolderSelect = state.autoAttachComponentMatches.enabled && props.data.foldersAvailable;
  const showFileTooLargeWarning = !!state.autoImportFrames.tooLarge;
  const autoImportFramesEnabled = state.autoImportFrames.enabled && !state.autoImportFrames.tooLarge;

  const novelPageIdSelected = Array.from(state.autoImportFrames.pageIds.values()).some(
    (pageId) => !(props.initialState?.autoImportFrames.pageIds || new Set()).has(pageId)
  );

  const showAutoImportFramesInfo = autoImportFramesEnabled && novelPageIdSelected && !showFileTooLargeWarning;

  const showPageAvailabilityModal = autoImportFramesEnabled && !showAutoImportFramesInfo;

  const countPagesSelected = state.autoImportFrames.pageIds.size;

  return (
    <form className={style.content}>
      <fieldset>
        <div>
          <input
            data-testid="auto-attach-exact-component-matches-checkbox"
            name="autoAttachComponentMatches"
            id="autoAttachComponentMatches"
            type="checkbox"
            checked={state.autoAttachComponentMatches.enabled}
            onChange={props.onToggleAutoAttachComponentMatches}
            className={style.autoCheckbox}
          />
        </div>
        <div className={style.textContainer}>
          <label className={style.optionTitle} htmlFor="autoAttachComponentMatches">
            <DittoComponent componentId="project-page.sync-settings.auto-attach-exact-matches" />
          </label>
          <p>
            <DittoComponent componentId="project-page.sync-settings.auto-attach-exact-matches-description" />
          </p>
          {showFolderSelect && (
            <div className={style.folderSelectContainer}>
              <FolderSelect
                className={style.folderSelect}
                dropdownClassName={style.folderSelectDropdown}
                dropdownItemClassName={style.folderSelectDropdownItem}
                text={
                  <span className={style.folderSelectText}>
                    <DittoComponent componentId="project-page.sync-settings.attach-components-from" />
                  </span>
                }
                fontSize={"12px"}
                folders={folderOptions}
                selectedFolder={folderSelected}
                setSelectedFolder={props.onChangeSelectedFolder}
              />
            </div>
          )}
        </div>
      </fieldset>
      <fieldset>
        <div>
          <input
            data-testid="auto-import-frames-checkbox"
            name="autoImportFrames"
            id="autoImportFrames"
            type="checkbox"
            checked={autoImportFramesEnabled}
            onChange={props.onToggleAutoImportFrames}
            disabled={showFileTooLargeWarning || !props.figmaFileId}
            className={style.autoCheckbox}
          />
        </div>
        <div className={style.textContainer}>
          <label
            className={classNames({
              [style.optionTitle]: true,
              [style.disabled]: showFileTooLargeWarning,
            })}
            htmlFor="autoImportFrames"
          >
            <DittoComponent componentId="project-page.sync-settings.auto-sync-new-frames" />
          </label>
          <p>
            <DittoComponent componentId="project-page.sync-settings.auto-sync-new-frames-description-simple" />
          </p>
          {autoImportFramesEnabled && (
            <div className={style.pageSelectContainer}>
              <div className={style.header}>
                <p className={style.title}>Select pages</p>
                <p>{countPagesSelected} selected</p>
              </div>
              <ul>
                {props.data.pages.map((page, index) => {
                  const isSelected = state.autoImportFrames.pageIds.has(page.id);
                  return (
                    <li
                      data-testid={`page-${index}`}
                      key={page.id}
                      onClick={props.onPageSelect(page.id)}
                      className={classNames({ [style.selected]: isSelected })}
                    >
                      {page.name}
                      {isSelected && <CheckIcon className={style.icon} />}
                    </li>
                  );
                })}
              </ul>
              {showAutoImportFramesInfo && (
                <div className={style.warning}>
                  <DittoComponent componentId="project-page.sync-settings.notice.we-will-import-all-frames" />
                </div>
              )}
            </div>
          )}
          {showFileTooLargeWarning && (
            <div className={style.warning}>
              <DittoComponent componentId="project-page.sync-settings.notice.linked-file-too-large" />
            </div>
          )}
          {state.autoImportFrames.enabled && showPageAvailabilityModal && (
            <div className={style.info}>
              Other pages may also exist in the linked Figma file that aren’t shown. To make a page available to
              automatically sync new frames,{" "}
              <span className={style.infoLink} onClick={props.toggleFrameModal}>
                first add a frame from that page to Ditto
              </span>
              .
            </div>
          )}

          {!props.figmaFileId && (
            <div className={style.info}>
              <DittoComponent componentId="project-page.sync-settings.notice.no-figma-file" />
              <div className={style.link}>
                <a href="https://www.dittowords.com/docs/linking-draft-groups-to-figma" target="_blank">
                  Learn more <ArrowOutward className={style.arrowIcon} />
                </a>
              </div>
            </div>
          )}
        </div>
      </fieldset>
    </form>
  );
}

export default function SyncSettingsModal(props: IProps) {
  const initialState = props.initialState || defaultState;
  const [state, setState] = useState<IState>(initialState);
  const stateHasChanged = getStateHasChanged(initialState, state);

  // ephemeral state we don't care about surfacing outside of this modal
  const [internalState, setInternalState] = useState<{
    isSaving: boolean;
  }>({ isSaving: false });

  const ctaIsEnabled = stateHasChanged && !internalState.isSaving;
  const ctaOnClick = () => {
    if (!ctaIsEnabled) return;
    setInternalState((s) => ({ ...s, isSaving: true, canSave: false }));
    props.onSave(state);
  };

  const onToggleAutoAttachComponentMatches = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;
    setState((prev) => ({
      ...prev,
      autoAttachComponentMatches: { ...prev.autoAttachComponentMatches, enabled },
    }));
  };

  const onToggleAutoImportFrames = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (state.autoImportFrames.tooLarge) return;

    const enabled = e.target.checked;
    setState((prev) => ({
      ...prev,
      autoImportFrames: { ...prev.autoImportFrames, enabled },
    }));
  };

  const onChangeSelectedFolder = (folder: { _id: string; name: string }) => {
    setState((prev) => ({
      ...prev,
      autoAttachComponentMatches: {
        ...prev.autoAttachComponentMatches,
        folderId: folder._id,
      },
    }));
  };

  const onPageSelect = (pageId: string) => () => {
    setState((prev) => {
      const pageIds = new Set(prev.autoImportFrames.pageIds);
      if (pageIds.has(pageId)) pageIds.delete(pageId);
      else pageIds.add(pageId);

      return { ...prev, autoImportFrames: { ...prev.autoImportFrames, pageIds } };
    });
  };

  const actions = {
    onToggleAutoAttachComponentMatches,
    onToggleAutoImportFrames,
    onChangeSelectedFolder,
    onPageSelect,
  };

  const textTitle = useDittoComponent({ componentId: "project-page.sync-settings.title" });
  const textCancel = useDittoComponent({ componentId: "cancel-button" });

  const textSave = useDittoComponent({ componentId: "save-button" });
  const textSaving = useDittoComponent({ componentId: "save-button.loading" });
  const textCta = internalState.isSaving ? textSaving : textSave;

  return (
    <ModalBase onHide={props.onHide} title={textTitle} className={style.modal}>
      <ModalBody className={style.modalBody}>
        <SyncSettingsContent {...props} {...actions} state={state} />
      </ModalBody>
      <ModalFooter>
        <ButtonSecondary onClick={props.onHide} text={textCancel} />
        <ButtonPrimary
          data-testid="sync-settings-save-button"
          onClick={ctaOnClick}
          text={textCta}
          disabled={!ctaIsEnabled}
        />
      </ModalFooter>
    </ModalBase>
  );
}
