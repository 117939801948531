import { DEVELOPER_PAGE_IMPORT_TO_COMPONENT_LIBRARY_CLICKED } from "@shared/segment-event-names";
import { Ditto } from "ditto-react";
import React from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import useSegment from "../../../../hooks/useSegment";
import { IMPORT_MODAL_QUERY_STRING } from "../../../Components";
import style from "./ImportStringsPanel.module.css";

const ImportStringsPanel = () => {
  const segment = useSegment();
  const history = useHistory();

  const handleImportStringsClick = () => {
    history.push(`/components?${IMPORT_MODAL_QUERY_STRING}=true`);
    segment.track({
      event: DEVELOPER_PAGE_IMPORT_TO_COMPONENT_LIBRARY_CLICKED,
    });
  };

  return (
    <div className={style.importStringsPanel}>
      <div className={style.text}>
        <h1>
          <Ditto componentId="import-you-string-files" />
        </h1>
        <p>
          <Ditto componentId="import-file-description" />
        </p>
      </div>
      <ButtonPrimary
        data-testid="import-strings-button"
        text={<Ditto componentId="import-to-cl" />}
        onClick={handleImportStringsClick}
        className={style.importButton}
      />
    </div>
  );
};

export default ImportStringsPanel;
