import React from "react";
import ModalFooter from "react-bootstrap/ModalFooter";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import ModalBase, { ModalBody } from "../../../../components/shared/ModalBase";
import style from "./style.module.css";

const DeleteVariantFolderModal = ({ onHide, onSubmit, folderName, variantCount }) => {
  return (
    <ModalBase onHide={onHide} title={`Delete folder ${folderName}`}>
      <ModalBody>
        <div className={style.text}>
          Deleting this folder will move {variantCount} variant
          {variantCount != 1 ? "s" : ""} into your main library.
        </div>
      </ModalBody>
      <ModalFooter className={style.buttons}>
        <ButtonSecondary onClick={onHide} text={"Cancel"} />
        <ButtonPrimary
          onClick={() => {
            onSubmit();
          }}
          text={"Delete"}
        />
      </ModalFooter>
    </ModalBase>
  );
};

export default DeleteVariantFolderModal;
