import { revokeSlackAccess } from "@/http/slack";
import { useWorkspace } from "@/store/workspaceContext";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import styles from "./styles.module.css";

// This is the Client ID for our Slack app, found on the Basic Information page
const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID;
// These are the permissions our app asks the user for when they connect
const SLACK_SCOPES = ["channels:read", "chat:write", "chat:write.public", "groups:read"].join(",");

export function NotConnected() {
  const redirectUri = `https://${window.location.host}/integrations/slackRedirect`;

  const oAuthUrl =
    `https://slack.com/oauth/v2/authorize?scope=` +
    encodeURIComponent(SLACK_SCOPES) +
    `&client_id=` +
    SLACK_CLIENT_ID +
    `&redirect_uri=` +
    redirectUri;

  return (
    <div className={styles.connectArea}>
      <div className={styles.contentSubheader}>Instructions</div>
      <ol>
        <li className={styles.contentText}>
          Click the "Connect to Slack" button below to connect your Slack workspace.
        </li>
        <li className={styles.contentText}>
          Ditto will request permission to read your channels and post messages. Once you press "Allow", you will be
          redirected back to Ditto.
        </li>
      </ol>
      <ButtonPrimary
        className={styles.connectButton}
        onClick={() => {
          window.location.replace(oAuthUrl);
        }}
        text="Connect to Slack"
      />
    </div>
  );
}

export function Connected() {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const [revokeLoading, setRevokeLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleRemoveConnection = async () => {
    if (!workspaceInfo?.integrations.slack.authenticated) return;
    try {
      setRevokeLoading(true);
      const [request] = revokeSlackAccess({
        revokeConnection: true,
      });
      await request;
      updateWorkspaceInfo({
        ...workspaceInfo,
        integrations: {
          ...workspaceInfo.integrations,
          slack: {
            component_library: {
              ...workspaceInfo.integrations.slack.component_library,
              channel_id: null,
              channel_name: null,
            },
            authenticated: false,
            accessToken: null,
          },
        },
      });
      setRevokeLoading(false);
    } catch (e) {
      setRevokeLoading(false);
      setError("Error revoking Slack token: " + e.message);
      console.error("Error revoking Slack token: ", e);
    }
  };

  return (
    <div className={styles.revokeArea}>
      <div className={styles.contentSubheader}>Your Ditto workspace is connected to Slack!</div>
      <p className={styles.contentText}>
        You can connect a Ditto project or the Component Library to a Slack channel to receive notifications about
        changes, comments, and more.{" "}
      </p>
      <button className={styles.removeConnectionButton} onClick={handleRemoveConnection}>
        Remove Connection
        {revokeLoading && <Spinner animation="border" className={styles.loadingSpinner} />}
      </button>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
}
