import { ITagState } from "@/hooks/useSearchState";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";
import React from "react";
import Select, { MenuProps, OptionProps, SelectInstance, components } from "react-select";
import style from "./style.module.css";

interface Tag {
  tag: string;
  count: number;
}

interface Option {
  value: string;
  label: string;
  isSelected: boolean;
  total: number;
  onSelect: (index: number, alreadySelected: boolean) => void;
}

interface TagSelectProps {
  tagState: ITagState;
  selectTag: (tagName: string) => void;
  onRemoveFilter: () => void;
}

const getFormattedOptions = (tagState: ITagState, selectTag: (tagName: string) => void) => {
  const optionsSelected: Option[] = [];
  const optionsUnselected: Option[] = [];

  Object.entries(tagState.counts).forEach(([tag, count]) => {
    const isSelected = tagState.selected.has(tag);

    const target = isSelected ? optionsSelected : optionsUnselected;
    target.push({
      label: tag,
      value: tag,
      isSelected,
      total: count,
      onSelect: () => selectTag(tag),
    });
  });

  // TODO: is this sort necessary?
  // options.sort((a, b) => a.label.localeCompare(b.label));
  return optionsSelected.concat(optionsUnselected);
};

const TagSelect = React.forwardRef((props: TagSelectProps, ref: React.Ref<SelectInstance>) => {
  const { tagState, selectTag, onRemoveFilter } = props;

  const formattedOptions = getFormattedOptions(tagState, selectTag);

  return (
    <Select
      classNamePrefix="tag-select"
      components={{ Option, Menu }}
      onRemoveFilter={onRemoveFilter}
      isMulti
      menuIsOpen={true}
      options={formattedOptions}
      ref={ref}
      styles={customStyles}
    />
  );
});
TagSelect.displayName = "TagSelect";

const Option = (props: OptionProps) => {
  const {
    label,
    data: { isSelected, total, onSelect },
  } = props;

  return (
    <div className={style.optionWrapper} onClick={onSelect}>
      <input type="checkbox" checked={isSelected} readOnly className={style.checkbox} />
      <span className={classNames(style.tag, "tag")}>{label.toUpperCase()}</span>
      <span className={style.count}>({total})</span>
    </div>
  );
};

const Menu = (props: MenuProps) => {
  return (
    <components.Menu {...props}>
      {props.children}
      <div className={style.removeFilter} onClick={props.selectProps?.onRemoveFilter}>
        <DeleteIcon className={style.removeFilterIcon} />
        Remove Filter
      </div>
    </components.Menu>
  );
};

const textStyles = {
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
};

const customStyles = {
  clearIndicator: () => ({
    cursor: "pointer",
    "& svg": {
      height: "14px",
      marginRight: "6px",
      width: "14px",
      color: "#aaa",
    },
    "&:hover svg": {
      color: "#666",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : "4px",
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : "4px",
    borderColor: "#D9DBDE",
    boxShadow: "none",
    cursor: "cursor",
    minHeight: "0",
    ":hover": {
      borderColor: "#D9DBDE",
    },
  }),
  dropdownIndicator: () => ({ display: "none" }),
  indicatorsContainer: () => ({
    display: "flex",
    paddingBottom: "2px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  input: (provided: any) => ({
    ...provided,
    ...textStyles,
    fontWeight: "normal",
    margin: "2px 0",
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)",
    margin: "0",
  }),
  menuList: (provided: any) => ({
    ...provided,
    border: "1px solid #D9DBDE",
    borderTop: "none",
    maxHeight: "90px",
    padding: "0",
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    ...textStyles,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    ...textStyles,
    backgroundColor: state.isFocused ? "#F9F9F9" : "white",
    borderBottom: "1px solid #D9DBDE",
    borderTop: "none",
    color: "#19191A",
    cursor: "pointer",
    fontWeight: "600",
    overflow: "hidden",
    padding: "4px 10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ":hover": {
      backgroundColor: "#F5F5F5",
    },
    ":active": {
      backgroundColor: "#F5F5F5",
    },
    ":focus": {
      backgroundColor: "#F5F5F5",
    },
    ":last-child": {
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    ...textStyles,
    color: "#19191A33",
    margin: "0",
    transform: "translateY(calc(-50% - 1px))",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...textStyles,
    fontWeight: "600",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "2px 10px",
    cursor: "text",
  }),
};

export default TagSelect;
