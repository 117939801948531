import { IFProject } from "@shared/types/Project";
import { createHttpRequest } from "./lib/createHttpRequest";

const url = (path: string) => `/doc${path}`;

export const updateBlockApiId = createHttpRequest<{ projectId: string; blockId: string; newApiId: string }, void>({
  method: "put",
  getUrl: (args) => url(`/editBlockApiId/${args.projectId}`),
  getConfig: (args) => ({
    data: {
      blockId: args.blockId,
      newApiId: args.newApiId,
    },
  }),
});

export const duplicateProject = createHttpRequest<{ projectId: string }, IFProject>({
  method: "post",
  getUrl: (args) => url(`/duplicate/${args.projectId}`),
  getConfig: () => ({
    data: {},
  }),
});
