import React from "react";

export default function (props: { fill?: string }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_199_484)">
        <path
          d="M6 7.00002C6.83 7.00002 7.495 6.33002 7.495 5.50002L7.5 2.50002C7.5 1.67002 6.83 1.00002 6 1.00002C5.17 1.00002 4.5 1.67002 4.5 2.50002V5.50002C4.5 6.33002 5.17 7.00002 6 7.00002ZM8.65 5.50002C8.65 7.00002 7.38 8.05002 6 8.05002C4.62 8.05002 3.35 7.00002 3.35 5.50002H2.5C2.5 7.20502 3.86 8.61502 5.5 8.86001V10.5H6.5V8.86001C8.14 8.62002 9.5 7.21002 9.5 5.50002H8.65Z"
          fill={props.fill || "#787878"}
        />
      </g>
      <defs>
        <clipPath id="clip0_199_484">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
