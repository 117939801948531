import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React from "react";
import Badge from "../../atoms/Badge";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IVariantBadgeProps {
  className?: string;
  style?: React.CSSProperties;

  children?: React.ReactNode;
}

export function VariantBadge(props: IVariantBadgeProps) {
  return (
    <Badge
      className={classNames(style.VariantBadgeWrapper, props.className)}
      type="outline"
      borderRadius="lg"
      size="sm"
      style={props.style}
    >
      <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
      {props.children}
    </Badge>
  );
}

export function VariantNotPresentBadge() {
  return (
    <VariantBadge>
      <Text color="tertiary" size="micro">
        Variant not present
      </Text>
    </VariantBadge>
  );
}

export default VariantBadge;
