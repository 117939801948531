import classnames from "classnames";
import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import style from "./style.module.css";

const TitleBar = ({ title, icon, onClickCallback, alignLeft, button } = { button: null }) => (
  <Navbar className={style.navbar}>
    <span className={classnames([style.title], { [style.alignLeft]: !!alignLeft })}>
      <div>{title}</div>
      {icon === "INFO" && <InfoOutlinedIcon className={style.help_icon} onClick={() => onClickCallback()} />}
    </span>
    {button && (
      <Button className={style.barButton} variant="primary" onClick={button.onClick}>
        {button.title}
      </Button>
    )}
  </Navbar>
);

TitleBar.propTypes = {
  icon: PropTypes.string,
  onClickCallback: PropTypes.func,
  alignLeft: PropTypes.bool,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
};

export default TitleBar;
