import { useMemo, useRef } from "react";

const DEFAULT_DEBOUNCE_INTERVAL_MS = 800;

export interface DebouncedCallback<T> {
  (arg: T, interval?: number): void;
  clear: () => void;
}

/**
 * Returns a function that when called, sets a timer to execute
 * `callback` after `interval` ms. If the function is called again
 * before the timer finishes, the timer is cleared and a new timer is
 * set. This ensures that `callback` is only ever called every `interval` ms
 * at a maximum.
 *
 * To avoid unexpected results, the reference to `callback` should be cached
 * using `useMemo` or `useCallback`.
 */
const useDebouncedCallback = <T>(
  callback: (arg: T) => void,
  defaultInterval: number = DEFAULT_DEBOUNCE_INTERVAL_MS,
  additionalDependencies: unknown[] = []
): DebouncedCallback<T> => {
  const debounceTimeout = useRef<NodeJS.Timer | number | null>(null);

  return useMemo(() => {
    const fn = (arg: T, interval = defaultInterval) => {
      if (debounceTimeout.current) {
        // @ts-ignore
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => callback(arg), interval);
    };

    fn.clear = () =>
      // @ts-ignore
      debounceTimeout.current && clearTimeout(debounceTimeout.current);

    return fn;
  }, [callback, defaultInterval, ...additionalDependencies]);
};

export default useDebouncedCallback;
