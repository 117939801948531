import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import classNames from "classnames";
import React from "react";
import { CsvImportContext } from "../../CsvImportMapping/CsvImportContext";
import { UploadLoader } from "./UploadLoader";
import style from "./style.module.css";

export const FileUploaded = ({ file, loading, onRemoveFile }) => {
  const { dataWithRowNumbers } = React.useContext(CsvImportContext);

  const isTypeCsv = file.type === "text/csv";

  return (
    <div className={style.uploadedFileContainer}>
      <InsertDriveFile
        className={classNames({
          [style.icon]: true,
          [style.uploadedFileIcon]: true,
          [style.uploadedFileIconLoaded]: !loading,
        })}
      />
      <span
        className={classNames({
          [style.uploadedFileText]: true,
          [style.uploadedFileTextLoaded]: !loading,
        })}
      >
        {file.name}
      </span>
      {isTypeCsv && dataWithRowNumbers.length > 0 && <span data-testid="csv-loaded" />}
      {loading && <UploadLoader />}
      {!loading && (
        <div className={style.removeContainer}>
          <a href="#" className={style.remove} onClick={onRemoveFile}>
            Remove
          </a>
        </div>
      )}
    </div>
  );
};
