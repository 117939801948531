import FolderIcon from "@mui/icons-material/FolderOpenOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Info from "@mui/icons-material/Info";
import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import ReactTooltip from "react-tooltip";
import FigmaIcon from "../../../FigmaIcon";
import ButtonPrimary from "../../../button/buttonprimary";
import LockedFolderIcon from "../../../icons/LockedFolderIcon";
import AdvancedBranchingModal from "../AdvancedBranchingModal";
import ErrorMessage from "../ErrorMessage";
import style from "./style.module.css";

const FILE_ERRORS = [
  "file_exists",
  "branch_exists",
  "main_branch_imported",
  "other_branch_imported",
  "sample_project_already_exists",
];

interface TitleProps {
  folder: { _id: string; name: string; invite_only: boolean } | null;
}

const Title = ({ folder }: TitleProps) => (
  <div className={style.title}>
    <FigmaIcon />
    Import an existing Figma file{folder ? " in " : ""}
    {folder?.name && (
      <div className={style.folderIconWrapper}>
        {!folder.invite_only ? <FolderIcon className={style.icon} /> : <LockedFolderIcon className={style.icon} />}
        <div>{folder?.name}</div>
      </div>
    )}
  </div>
);

interface BodyProps {
  onHide: () => void;
  importError: string;
  linkInput: string;
  handleLinkChange: (e: React.FormEvent) => void;
  goToAccountUser: () => void;
  handleEnableAdvancedBranching: () => void;
  getPages: (e: React.FormEvent) => void;
  showSampleImportMessage: boolean;
}
const Body = (props: BodyProps) => {
  const {
    onHide,
    importError,
    linkInput,
    handleLinkChange,
    goToAccountUser,
    handleEnableAdvancedBranching,
    getPages,
    showSampleImportMessage,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const showAdvancedBranchingModal = () => setShowModal(true);
  const hideAdvancedBranchingModal = () => setShowModal(false);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      getPages(e);
      return false;
    }
    return true;
  };
  return (
    <div className={style.container}>
      <label>
        Figma File Link
        <HelpOutlineIcon className={style.help} data-tip data-for="link-help" onClick={onHide} />
        <ReactTooltip
          id="link-help"
          place="right"
          type="light"
          effect="solid"
          multiline={true}
          className={style.tooltip}
        >
          In Figma, you can find this link by clicking "Share". <br></br>If you're in Figma for the browser, you can
          also paste the link in the URL bar.
        </ReactTooltip>
      </label>
      <TextareaAutosize
        data-testid="figma-link-input"
        autoFocus
        placeholder="Paste your Figma file link here!"
        value={linkInput}
        onChange={handleLinkChange}
        onKeyDown={onKeyDown}
      />
      {importError && (
        <ErrorMessage
          error={importError}
          onHide={onHide}
          goToAccountUser={goToAccountUser}
          showAdvancedBranchingModal={showAdvancedBranchingModal}
        />
      )}
      {showSampleImportMessage && (
        <div className={style.sampleImportMessage}>
          <Info className={style.icon} />
          Looks like you're importing Ditto's sample Figma file. Once it's imported, we'll populate it with sample data
          so you can play around with a fully set-up Ditto project!
        </div>
      )}
      {showModal && (
        <AdvancedBranchingModal
          onHide={hideAdvancedBranchingModal}
          handleEnableAdvancedBranching={handleEnableAdvancedBranching}
        />
      )}
    </div>
  );
};

interface FooterProps {
  importError: string;
  isGetPagesLoading: boolean;
  goToSelectedDoc: () => void;
  getPages: (e: React.FormEvent) => void;
  goBackToStart: () => void;
  linkInput: string;
  showSampleImportMessage: boolean;
}
const Footer = ({
  importError,
  isGetPagesLoading,
  goToSelectedDoc,
  getPages,
  goBackToStart,
  linkInput,
  showSampleImportMessage,
}: FooterProps) => {
  function getButtonText() {
    if (isGetPagesLoading) {
      return "Fetching pages... ";
    }
    if (FILE_ERRORS.includes(importError)) {
      return "Go to Project ->";
    }
    if (showSampleImportMessage) return "Import";
    return "Next ->";
  }

  function getButtonAction() {
    if (FILE_ERRORS.includes(importError) || showSampleImportMessage) {
      return goToSelectedDoc;
    }
    return getPages;
  }

  return (
    <div className={style.footer}>
      <div className={style.buttonWrapper}>
        <div className={style.backBtn} onClick={goBackToStart}>
          {"<-"} Back
        </div>
        {!FILE_ERRORS.includes(importError) && (
          <ButtonPrimary
            data-testid="next-import-step-button"
            text={getButtonText()}
            disabled={linkInput.length === 0 || isGetPagesLoading || (Boolean(importError) && importError !== "none")}
            onClick={getButtonAction()}
          />
        )}
        {FILE_ERRORS.includes(importError) && <ButtonPrimary text="Go to Project ->" onClick={goToSelectedDoc} />}
      </div>
      <div className={style.pluginLink}>
        Or, start a project by using{" "}
        <a
          href="https://www.figma.com/community/plugin/798826066406007173/%E2%9C%8D%EF%B8%8F-Ditto-%7C-collaborate-on-copy"
          target="_blank"
        >
          our Figma plugin
        </a>{" "}
        in a file
      </div>
    </div>
  );
};

const SelectFigmaProject = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};
SelectFigmaProject.Title = Title;
SelectFigmaProject.Body = Body;
SelectFigmaProject.Footer = Footer;

export default SelectFigmaProject;
