import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React, { memo, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { isDiffRichText } from "../../../../shared/lib/text";
import { RichTextInputProps } from "../../../../shared/types/RichText";
import {
  ActualComponentStatus,
  ITextItemVariable,
  ITextItemVariableRichValue,
  ITextItemVariantUpdate,
  ITipTapRichText,
} from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import LabelWrapper from "../../molecules/LabelWrapper";
import StatusSelect from "../StatusSelect";
import { FlagIcon } from "../TextItemMetadata/icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;

  variantId: string;
  variantStatus?: ActualComponentStatus;
  variantRichText: ITipTapRichText;
  variantText: string;
  variantVariables: ITextItemVariable[];
  variantName: string;

  /**
   * Placeholder text for variant text input. Should be the base item's text.
   */
  placeholder?: string;

  /**
   * Style flag for whether variant metadata is inline (e.g. in edit panel: removing extra padding, top border)
   */
  inline?: boolean;

  /**
   * Callback function for updating the text item's variant.
   * @param update - Update object containing the variantId and the properties to update.
   */
  onSave: (update: ITextItemVariantUpdate) => void;

  /**
   * Wrapper for our TipTap RichTextInput component.
   */
  RichTextInput: (richTextInputProps: RichTextInputProps) => React.ReactNode;
}

export function TextItemVariant(props: IProps) {
  const [draftStatus, setDraftStatus] = useState<ActualComponentStatus>(props.variantStatus ?? "NONE");
  const [draftRichText, setDraftRichText] = useState<ITipTapRichText>(props.variantRichText);

  // Refresh stale state when variant id prop changes
  useLayoutEffect(() => {
    setDraftStatus(props.variantStatus ?? "NONE");
    setDraftRichText(props.variantRichText);
  }, [props.variantId, props.variantStatus, props.variantRichText]);

  // For change detection, compare directly with props
  const changes: ITextItemVariantUpdate | null = useMemo(() => {
    const hasStatusChange = draftStatus !== props.variantStatus;
    const hasRichTextChange = isDiffRichText(draftRichText, props.variantRichText);

    if (!hasStatusChange && !hasRichTextChange) return null;

    return {
      variantId: props.variantId,
      ...(hasStatusChange ? { status: draftStatus } : {}),
      ...(hasRichTextChange ? { richText: draftRichText } : {}),
    };
  }, [draftStatus, draftRichText, props.variantId, props.variantStatus, props.variantRichText]);

  const initialVariableRichValue: ITextItemVariableRichValue = useMemo(() => {
    return {
      rich_text: props.variantRichText,
      plurals: [],
      variables: props.variantVariables,
      text: props.variantText,
      characterLimit: null,
    };
  }, [props.variantRichText, props.variantVariables, props.variantText]);

  function onCancel() {
    setDraftStatus(props.variantStatus ?? "NONE");
    setDraftRichText(props.variantRichText);
  }

  function handleSave() {
    if (!changes) return;
    /* TODO: Add support for complex inputs: https://linear.app/dittowords/issue/DIT-8363/support-complex-metadata-on-variants */

    props.onSave(changes);
  }

  const handleRichTextChange = useCallback(function _handleRichTextChange(newRichText: ITipTapRichText) {
    setDraftRichText(newRichText);
  }, []);

  const handleSetCharacterLimit = useCallback(() => {}, []);
  const handleSetPlurals = useCallback(() => {}, []);
  const variantName = useMemo(() => `${props.variantName} variant`, [props.variantName]);

  return (
    <div
      style={props.style}
      className={classNames(
        style.TextItemVariantWrapper,
        {
          [style.inline]: props.inline,
        },
        props.className
      )}
      data-testid="text-item-variant"
    >
      <div className={style.TextItemVariantMetadata}>
        <div className={style.TextItemVariantHeader}>
          <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
          <Text size="small" weight="strong">
            {variantName}
          </Text>
        </div>
        <props.RichTextInput
          setBaseText={handleRichTextChange}
          setPlurals={handleSetPlurals}
          setCharacterLimit={handleSetCharacterLimit}
          initialVariableRichValue={initialVariableRichValue}
          characterLimit={null}
          pluralsDisabled={true}
          characterLimitDisabled={true}
          placeholder={props.placeholder}
          emptyEditorClass="emptyEditor"
        />
      </div>

      {/* Status select */}
      <LabelWrapper
        className={style.labelWrapper}
        labelFlexStart
        labelHeight={32}
        label="Status"
        leadingIcon={<FlagIcon />}
      >
        <StatusSelectWrapper status={draftStatus} setStatus={setDraftStatus} />
      </LabelWrapper>

      {/* CTA buttons */}
      {changes && (
        <div className={style.CTAButtons}>
          <Button size="small" level="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="small" level="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

const StatusSelectWrapper = memo(function StatusSelectWrapper(props: {
  status: ActualComponentStatus;
  setStatus: (status: ActualComponentStatus) => void;
}) {
  return (
    <div className={style.inputWidthWrapper}>
      <StatusSelect status={props.status} setStatus={props.setStatus} />
    </div>
  );
});

export default TextItemVariant;
