import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import MailIcon from "@mui/icons-material/MailOutline";
import { Ditto } from "ditto-react";
import React, { useContext } from "react";
import imgSplash from "../../../../assets/developer-page-splash.png";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import ReminderIcon from "../../../../components/icons/ReminderIcon";
import { BillingContext } from "../../../../store/billingContext";
import { getRemainingDaysInTrial } from "../../../../utils/trialDateMath";
import style from "./IntegrationsInfoPanel.module.css";

interface IntegrationsInfoPanelProps {
  openDevToolsModal: () => void;
  openInviteModal: () => void;
}

enum BUTTON_STATES {
  SHOW_ENABLE,
  SHOW_TRIAL,
  SHOW_NONE,
}

const IntegrationsInfoPanel = (props: IntegrationsInfoPanelProps) => {
  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();
  const { seatsState } = useContext(BillingContext);
  const viewerIsDeveloper = user.job === "ENGINEER";

  // devToolsEnabled is true for trial users; this is not
  let actuallyBillingDevTools = false;
  if (!seatsState.loading && seatsState.success) {
    actuallyBillingDevTools = seatsState.data.monthlyDevTools || seatsState.data.yearlyDevTools;
  }

  const remainingDaysInTrial = getRemainingDaysInTrial(workspaceInfo);

  function getButtonState() {
    if (remainingDaysInTrial >= 0) return BUTTON_STATES.SHOW_TRIAL;
    if (!workspaceInfo?.devTools) return BUTTON_STATES.SHOW_ENABLE;
    return BUTTON_STATES.SHOW_NONE;
  }

  const buttonState = getButtonState();

  return (
    <div className={style.integrationsInfoPanel} data-testid="integrations-info-panel">
      <div className={style.left}>
        <div className={style.text}>
          <div className={style.title}>
            {viewerIsDeveloper ? (
              <Ditto componentId="api.cli-integration-for-text-updating" />
            ) : (
              <Ditto componentId="use-developer-integrations" />
            )}
          </div>
          <p>
            {viewerIsDeveloper ? (
              <Ditto componentId="automated-integration-tools" />
            ) : (
              <Ditto componentId="devtools-info" />
            )}
          </p>
        </div>
        {buttonState !== BUTTON_STATES.SHOW_NONE && (
          <div className={style.buttons}>
            {buttonState === BUTTON_STATES.SHOW_TRIAL && (
              <div className={style.trialText}>
                <ReminderIcon className={style.icon} />
                <Ditto
                  componentId="trials-text"
                  variables={{ numDays: remainingDaysInTrial }}
                  count={remainingDaysInTrial}
                />
              </div>
            )}

            {buttonState === BUTTON_STATES.SHOW_ENABLE && (
              <ButtonPrimary
                className={style.enableButton}
                text={<Ditto componentId="enable" />}
                onClick={props.openDevToolsModal}
                data-testid="enable-dev-tools-button"
              />
            )}

            <ButtonSecondary
              className={style.inviteButton}
              text={
                <span>
                  <MailIcon className={style.icon} />
                  <Ditto componentId="dev-invitation" />
                </span>
              }
              onClick={props.openInviteModal}
            />
          </div>
        )}
      </div>
      <div className={style.right}>
        <img src={imgSplash} className={style.image} />
      </div>
    </div>
  );
};

export default IntegrationsInfoPanel;
