import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import React, { useState } from "react";
import { useScroll } from "../../../../hooks/useScroll";

import ResizableWidthContainer from "@/components/ResizableWidthContainer/ResizableWidthContainer";
import SkeletonLoader from "@/components/SkeletonLoader/SkeletonLoader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { SimpleTreeView as TreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { IFVariable } from "@shared/types/Variable";
import { IFVariableFolder } from "@shared/types/VariableFolder";
import classNames from "classnames";
import style from "./style.module.css";

interface Props {
  loadingVariables: boolean;
  variablesByFolder: Record<string, IFVariable[]>;
  selectedVariableId: string | null;
  handleSelectVariableId: (variableId: string) => void;
  variableFolders: IFVariableFolder[];
  selectedFolderId?: string | null;
  foldersAllowed: boolean;
  handleFolderClick: (folderId?: string, variableId?: string) => void;
  isSearching: boolean;
}
const VariableNav = ({
  loadingVariables,
  variablesByFolder,
  selectedVariableId,
  handleSelectVariableId,
  variableFolders,
  selectedFolderId,
  foldersAllowed,
  handleFolderClick,
  isSearching,
}: Props) => {
  const [shouldShowResizeCursor, setShouldShowResizeCursor] = useState(false);
  const { scrollToId } = useScroll({ containerId: "variableContainer" });

  const onShouldShowCursorChange = (shouldShowCursor: boolean) => {
    setShouldShowResizeCursor(shouldShowCursor);
  };

  function onSelectFolder(folderId?: string, variableId?: string) {
    if (shouldShowResizeCursor) return;
    handleFolderClick(folderId, variableId);
  }

  function onSelectVariable(variableId: string) {
    if (shouldShowResizeCursor) return;
    handleSelectVariableId(variableId);
    scrollToId(variableId);
  }

  function onBack() {
    handleFolderClick(undefined, undefined);
  }

  return (
    <ResizableWidthContainer
      className={style.sidebar}
      persistKey="ditto-VariableNavigationSidebar"
      onShouldShowCursorChange={onShouldShowCursorChange}
    >
      <div className={style.titleSection}>
        <div className={style.topRow}>
          <div className={style.option}>Navigation</div>
          {selectedFolderId && (
            <span className={style.backLink} onClick={onBack}>
              {"<-"} Back to All Variables
            </span>
          )}
        </div>
      </div>
      <div className={style.frameList}>
        {loadingVariables && <SkeletonLoader format="nav" />}
        {!loadingVariables && foldersAllowed && !selectedFolderId && isSearching && variableFolders.length > 0 && (
          <TreeView
            slots={{ collapseIcon: ArrowDropDownIcon, expandIcon: ArrowRightIcon }}
            defaultExpandedItems={variableFolders.map((f) => f._id)}
          >
            {Object.entries(variablesByFolder)
              .filter(([folder_id]) => folder_id !== "none")
              .map(([folder_id, variablesInFolder]) => (
                <TreeItem
                  key={folder_id}
                  itemId={folder_id}
                  label={
                    <ExpandableFolderNavLink
                      key={folder_id}
                      name={variableFolders.find((f) => f._id === folder_id)?.name}
                      selectFolder={() => {
                        const variableId = variablesByFolder[folder_id].find((v) => v._id === selectedVariableId)?._id;
                        onSelectFolder(folder_id, variableId);
                      }}
                    />
                  }
                >
                  {variablesInFolder &&
                    variablesInFolder.map((variable) => (
                      <TreeItem
                        key={variable._id}
                        itemId={variable._id}
                        label={
                          <div
                            key={variable._id}
                            className={style.navItemWrapper}
                            onClick={() => {
                              onSelectVariable(variable._id);
                            }}
                          >
                            {variable._id === selectedVariableId && <div className={style.selected} />}
                            <div className={style.navItem}>
                              <div className={style.name}>{variable.name}</div>
                            </div>
                          </div>
                        }
                        className={classNames({
                          [style.selectedVariable]: variable._id === selectedVariableId,
                          [style.treeItem]: true,
                        })}
                        id={`nav-${variable._id}`}
                        onClick={() => {}}
                      ></TreeItem>
                    ))}
                </TreeItem>
              ))}
          </TreeView>
        )}
        {!loadingVariables &&
          !isSearching &&
          !selectedFolderId &&
          variableFolders.map((folder) => (
            <FolderNavLink
              key={folder._id}
              name={folder.name}
              selectFolder={() => handleFolderClick(folder._id)}
              className={shouldShowResizeCursor ? style.showResizeCursor : ""}
            />
          ))}
        {!loadingVariables &&
          variablesByFolder[selectedFolderId || "none"] &&
          variablesByFolder[selectedFolderId || "none"].map(({ _id, name }) => (
            <div
              key={_id}
              className={classNames(style.navItemWrapper, {
                [style.showResizeCursor]: shouldShowResizeCursor,
              })}
              onClick={() => {
                onSelectVariable(_id);
              }}
            >
              {_id === selectedVariableId && <div className={style.selected} />}
              <div
                className={classNames(style.navItem, {
                  [style.showResizeCursor]: shouldShowResizeCursor,
                })}
              >
                <div className={style.name}>{name}</div>
              </div>
            </div>
          ))}
        {!loadingVariables && !variablesByFolder[selectedFolderId || "none"]?.length && (
          <div className={style.noResults}>No variables found.</div>
        )}
      </div>
    </ResizableWidthContainer>
  );
};

const FolderNavLink = ({ name, selectFolder, className }) => {
  return (
    <div className={classNames(style.navItem, className)} onClick={() => selectFolder()}>
      <div className={style.folderNavLink}>
        <FolderOpenOutlined className={style.folderIcon} />
        <span className={style.folderName}>{name}</span>
      </div>
    </div>
  );
};

const ExpandableFolderNavLink = ({ name, selectFolder }) => {
  return (
    <div className={style.expandedFolderNavLink} onClick={() => selectFolder()}>
      <div className={style.folderNavLink}>
        <FolderOpenOutlined className={style.folderIcon} />
        <span className={style.folderName}>{name}</span>
      </div>
    </div>
  );
};

export default VariableNav;
