import Launch from "@mui/icons-material/Launch";
import AutoAwesomeIcon from "@shared/frontend/AutoAwesomeIcon";
import { AIUsage } from "@shared/types/http/changes";
import { Ditto } from "ditto-react";
import React from "react";
import Toggle from "react-toggle";
import Panel from "../../panel";
import style from "./style.module.css";

interface AIFunctionalityPanelProps {
  usageData: AIUsage;
  isAiEditorEnabled: boolean;
  isAutomatedComponentNamingEnabled: boolean;
  toggleIsAiEditorEnabled: () => void;
  toggleIsAutomatedComponentNamingEnabled: () => void;
}

function UsageElement(props: { title: string; usageCount: number; description: string }) {
  return (
    <td>
      <div className={style.tdTitleContainer}>
        <AutoAwesomeIcon fill="#DFBD43" />
        <span className={style.tdTitle}>{props.title}</span>
        <Launch fill="#3579F6" />
        <span className={style.usedCount}>
          Used {props.usageCount} time
          {props.usageCount === 0 || props.usageCount > 1 ? "s" : ""}
        </span>
      </div>
      <div>{props.description}</div>
    </td>
  );
}

const AIFunctionalityPanel = (props: AIFunctionalityPanelProps) => {
  const {
    usageData,
    isAiEditorEnabled,
    isAutomatedComponentNamingEnabled,
    toggleIsAiEditorEnabled,
    toggleIsAutomatedComponentNamingEnabled,
  } = props;

  if (!usageData) {
    return <></>;
  }

  return (
    <Panel titleDittoComponentId="ai-functionality" subtitle={<Ditto componentId="ai-disclaimer" />}>
      <div className={style.container} data-testid="ai-functionality-panel">
        <table>
          <tbody>
            <tr>
              <UsageElement
                title="AI Editor"
                usageCount={usageData.aiEditor}
                description={
                  "Our AI Editor enables teams to improve and augment their text using AI. The integration only runs on text users directly highlight and choose to use the AI Editor on (never automatically). This toggle enables anyone in your workspace to access the AI Editor."
                }
              />
              <td className={style.toggle}>
                <div className={style.toggleContents}>
                  <Toggle icons={false} checked={isAiEditorEnabled} onChange={toggleIsAiEditorEnabled} />
                  <span>{isAiEditorEnabled ? "Enabled" : "Disabled"}</span>
                </div>
              </td>
            </tr>
            <tr>
              <UsageElement
                title="Automated Component Naming"
                usageCount={usageData.automatedComponentNaming}
                description={
                  "Automated component naming generates a suggested component name based on the text of the component itself. It will also suggest a component group based on the existing component groups in the workspace. This functionality only runs on an individual component when a user clicks “Auto-name”. This toggle enables anyone in your workspace to access the “Auto-name” button."
                }
              />
              <td className={style.toggle}>
                <div className={style.toggleContents}>
                  <Toggle
                    icons={false}
                    checked={isAutomatedComponentNamingEnabled}
                    onChange={toggleIsAutomatedComponentNamingEnabled}
                  />
                  <span>{isAutomatedComponentNamingEnabled ? "Enabled" : "Disabled"}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Panel>
  );
};

export default AIFunctionalityPanel;
