import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

const ComponentPlaceholder = () => {
  return (
    <div className={style.wrapper}>
      {Array(2)
        .fill("")
        .map((_item, index) => (
          <div key={index} className={style.wsCompItem}>
            <div className={style.wsCompTitle} style={{ backgroundColor: "#eee", height: "25px" }}></div>
            <div className={style.top}>
              <div>
                <Skeleton width={`40%`} />
              </div>
            </div>
          </div>
        ))}
      {Array(1)
        .fill("")
        .map((_item, index) => (
          <div key={index} className={style.wsCompItem}>
            <div className={style.wsCompTitle} style={{ backgroundColor: "#eee", height: "25px" }}></div>
            <div className={style.top}>
              <div>
                <Skeleton width={`40%`} />
              </div>
            </div>
            <div className={style.bottom} style={{ height: "30px" }}></div>
          </div>
        ))}
      {Array(1)
        .fill("")
        .map((_item, index) => (
          <div key={index} className={style.wsCompItem}>
            <div className={style.wsCompTitle} style={{ backgroundColor: "#eee", height: "25px" }}></div>
            <div className={style.top}>
              <div>
                <Skeleton width={`40%`} />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ComponentPlaceholder;
