import React, { useState } from "react";
import { ModalBody, ModalFooter } from "../../../shared/ModalBase";

import CallSplitIcon from "@mui/icons-material/CallSplit";
import classnames from "classnames";
import { DittoComponent } from "ditto-react";
import Masonry from "react-masonry-css";
import ButtonPrimary from "../../../button/buttonprimary";
import ButtonSecondary from "../../../button/buttonsecondary";
import Comp from "../../../comp";
import { IStepProps, Step } from "../../useState";
import CompIgnored from "./CompIgnored";
import { useImportConfirmRequest } from "./importConfirmRequest";
import style from "./style.module.css";

const MAX_PREVIEW_COMPONENTS = 100;

export default function Preview({ state, dispatch }: IStepProps) {
  const [tab, setTab] = useState("selected");

  const { format, variant, totalComponentsCount } = state;

  const tooManyComponents = (totalComponentsCount ?? 0) > MAX_PREVIEW_COMPONENTS;

  const resolvedIgnoredComponents = state.componentsIgnored ?? [];
  const resolvedComponents = state.components ?? [];

  const [selectionState, setSelectionState] = useState({
    // all components selected by default
    map: resolvedComponents.reduce(
      (acc, { apiID }) => ({
        ...acc,
        [apiID]: true,
      }),
      {}
    ),
    // store count separate so we don't have to iterate keys re-compute
    count: resolvedComponents.length,
  });

  const onToggleSelectedComponent = ({ apiID }) => {
    if (tooManyComponents) return;

    setSelectionState((s) => ({
      ...s,
      count: s.map[apiID] ? s.count - 1 : s.count + 1,
      map: {
        ...s.map,
        [apiID]: !s.map[apiID],
      },
    }));
  };

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const importConfirmRequest = useImportConfirmRequest();

  const hasValidComponents = (totalComponentsCount ?? 0) > 0;
  const hasIgnoredComponents = resolvedIgnoredComponents.length > 0;

  const importComponentsDisabled = loading || !hasValidComponents;

  const onImportComponents = async () => {
    setLoading(true);

    if (!(state.action && state.backgroundJobEntryId)) {
      throw new Error("Missing required fields for import request");
    }

    const response = await importConfirmRequest({
      variant: state.variant,
      backgroundJobEntryId: state.backgroundJobEntryId,
      action: state.action,
    });

    if (response.ok) {
      // noop: we are waiting for a websocket event to come down the
      // pipe to redirect us to the next step based on the import response
    } else {
      // if there is an error confirming the import request,
      // redirect to the first step in the import modal if duplicate import detected
      // or if there is another import job in progress for this workspace
      setLoading(false);
      const errorCode = response.code;
      if (errorCode === "duplicate-components" || errorCode === "job-in-progress") {
        return dispatch({
          type: "SET_STEP",
          step: "file-upload",
          error: errorCode,
        });
      }
      setError(response.error);
    }
  };

  const onBackClick = () => {
    const lastStep: Step = state.format === "csv" ? "csv-mapping" : "file-upload";
    dispatch({
      type: "SET_STEP",
      step: state.existingComponentsCount || 0 > 0 ? "choose-action" : lastStep,
    });
  };

  return (
    <>
      <ModalBody>
        {state.action === "VARIANT" && variant ? (
          <p className={classnames([style.importingAsVariant, style.headerText])}>
            Import strings as the variant{" "}
            <div className={style.variantStyle}>
              <span>
                <CallSplitIcon className={classnames([style.icon, style.splitIconRotate])} /> {variant.name}
              </span>
            </div>{" "}
            on existing components
          </p>
        ) : state.action === "CREATE" ? (
          <p className={style.headerText}>Create new components</p>
        ) : (
          <p className={style.headerText}>Update existing component text</p>
        )}
        {tooManyComponents ? (
          <p>You're importing a large number of components, so here's a preview of the first 100.</p>
        ) : (
          <p>Choose components to import into your library. Components can always be edited after they’re imported.</p>
        )}
        <div>
          <div className={style.tabs}>
            <span
              className={classnames({
                [style.tabActive]: tab === "selected",
                [style.tabName]: true,
              })}
              onClick={() => setTab("selected")}
            >
              <DittoComponent
                componentId="component-imports.x-components-selected"
                count={resolvedComponents.length}
                variables={{ componentCount: resolvedComponents.length }}
              />
            </span>
            {resolvedIgnoredComponents.length > 0 && (
              <span
                className={classnames({
                  [style.tabActive]: tab === "ignored",
                  [style.tabName]: true,
                })}
                onClick={() => setTab("ignored")}
              >
                <DittoComponent
                  componentId="component-imports.x-strings-ignored"
                  count={resolvedIgnoredComponents.length}
                  variables={{ componentCount: resolvedIgnoredComponents.length }}
                />
              </span>
            )}
          </div>
          {tab === "selected" && hasValidComponents && (
            <div className={style.workspaceComponentsContainer}>
              <Masonry breakpointCols={3} className={style.workspaceComponents}>
                {resolvedComponents.map((component, i) => (
                  <Comp
                    className={style.component}
                    key={component.apiID}
                    is_suggested={false}
                    ws_comp_id=""
                    setPanelState={() => null}
                    setCommentState={() => null}
                    displayApiIds
                    is_ws_comp
                    is_selected={selectionState.map[component.apiID]}
                    disableCopyApiID={true}
                    apiID={component.apiID}
                    isDraft={false}
                    component={{
                      ...component,
                      text: component.text || component.baseText,
                      rich_text: component.rich_text || component.baseRichText,
                      status: component.status || component.baseStatus || "NONE",
                      variants: [],
                    }}
                    importVariantPreview={
                      variant
                        ? {
                            name: variant.name,
                            text: component.variantText,
                            rich_text: component.variantRichText,
                            plurals: component.variantPlurals,
                            status: component.variantStatus,
                          }
                        : null
                    }
                    selectComp={() => null}
                    compName={component.name}
                    containerStyle={
                      // Left
                      i % 3 === 0
                        ? { marginRight: 10 }
                        : i % 3 === 1
                        ? // Center
                          { marginLeft: 10, marginRight: 10 }
                        : // Right
                          { marginLeft: 10 }
                    }
                  />
                ))}
              </Masonry>
              {tooManyComponents && (
                <div className={style.previewWarning}>
                  Preview display limited to {MAX_PREVIEW_COMPONENTS} components
                </div>
              )}
            </div>
          )}
          {tab === "ignored" && hasIgnoredComponents && (
            <div className={classnames(style.workspaceComponentsContainer, style.ignoredComponentsContainer)}>
              <Masonry breakpointCols={3} className={style.workspaceComponents}>
                {resolvedIgnoredComponents.map((component, i) => (
                  <CompIgnored
                    className={classnames(style.compIgnored, {
                      [style.column1]: i % 3 === 0,
                      [style.column2]: i % 3 === 1,
                      [style.column3]: i % 3 === 2,
                    })}
                    key={component.name}
                    text={component.text}
                    name={component.name}
                  />
                ))}
              </Masonry>
              {tooManyComponents && (
                <div className={style.previewWarning}>
                  Preview display limited to {MAX_PREVIEW_COMPONENTS} components
                </div>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter className={style.footer}>
        <ButtonSecondary
          text="Back"
          onClick={onBackClick}
          className={style.backButton}
          data-testid="back-choose-action"
        />
        <ButtonPrimary
          text={loading ? "Importing..." : "Import"}
          onClick={onImportComponents}
          disabled={importComponentsDisabled || selectionState.count === 0}
          loading={loading}
          data-testid="import-components-button"
        />
      </ModalFooter>
    </>
  );
}
