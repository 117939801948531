import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../components/button/buttonprimary";
import ButtonSecondary from "../../../components/button/buttonsecondary";
import http, { API } from "../../../http";
import { GROUP_REDUCER_TYPES } from "../state/groupStateActions";
import { useProjectContext } from "../state/useProjectState";
import style from "./DeleteTextItemModal.module.css";

interface DeleteTextItemModalProps {
  show: boolean;
  onHide: () => void;
  projectId: string;
  groupId: string;
  textItemId: string;
  unselectAll: () => void;
  isDeveloperModeEnabled: boolean;
}

const DeleteTextItemModal = ({
  show,
  onHide,
  projectId,
  groupId,
  textItemId,
  unselectAll,
  isDeveloperModeEnabled,
}: DeleteTextItemModalProps) => {
  const {
    groupState: [, groupStateDispatch],
    projectSummary: [, refreshProjectSummary],
  } = useProjectContext();

  const deleteTextItem = async () => {
    const { url } = API.project.delete.deleteTextItem;
    const reqUrl = url(projectId, groupId, textItemId);
    try {
      await http.delete(reqUrl);
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.DELETE_TEXT_ITEM_FROM_GROUP,
        groupId,
        textItemId,
      });
      refreshProjectSummary();
      unselectAll();
    } catch (e) {
      console.error(e);
    }
    onHide();
  };

  return (
    <BootstrapModal
      className={style.modal}
      show={show}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={() => {}}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          Are you sure you want to delete this text item?
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <p>
          If you delete this text item, any metadata, comments, and change history on the text item will be deleted as
          well.
        </p>
        {isDeveloperModeEnabled && (
          <p>
            Although <b>developer mode</b> is on in this project, this text item won’t be available via exports or
            developer integrations after being deleted.
          </p>
        )}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <ButtonPrimary text="Cancel" disabled={false} onClick={onHide} />
        <ButtonSecondary text="Delete" disabled={false} onClick={deleteTextItem} />
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default DeleteTextItemModal;
