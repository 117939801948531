import classnames from "classnames";
import React from "react";
import { useExport } from "./useExport";

import CloseIcon from "@mui/icons-material/Close";
import BootstrapModal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import ButtonPrimary from "../button/buttonprimary";
import VariantSelectInput from "../shared/VariantSelectInput";
import JsonSection from "./JsonSection";
import style from "./style.module.css";

import PanelAndroid from "./PanelAndroid";
import PaneliOS from "./PaneliOS";

import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import DeveloperModeDisabled from "./DeveloperModeDisabled";
import ExportPreview from "./ExportPreview";

const SUPPORTED_EXPORT_FORMATS = {
  JSON: {
    label: "JSON (.json)",
    options: ["blocks-only", "include-rich-text"],
    availableWithoutDeveloperMode: false,
  },
  CSV: {
    label: "CSV (.csv)",
    options: ["blocks-only"],
    availableWithoutDeveloperMode: true,
  },
  android: {
    label: "Android (.xml)",
    options: [],
    availableWithoutDeveloperMode: false,
  },
  ["ios-strings"]: {
    label: "iOS (.strings)",
    options: [],
    availableWithoutDeveloperMode: false,
  },
  ["ios-stringsdict"]: {
    label: "iOS Plurals (.stringsdict)",
    options: [],
    availableWithoutDeveloperMode: false,
  },
};

const ExportModal = ({ doc_ID, doc_name, onHide, developerModeEnabled, componentFolder }) => {
  const location = useLocation();

  const isRichTextFlagIsOn = useHasProjectFeatureFlag("rich_text");

  const {
    modalTitle,
    blocksOnly,
    includeRichText,
    exportLoading,
    fields,
    format,
    isExporting,
    isProjectExport,
    isComponentExport,
    projectJSON,
    variantSelectOptions,
    isJsonFormatChecked,
    variantOptionSelected,
    handleIncludeRichTextChange,
    handleBlocksOnlyChange,
    handleCheckboxField,
    handleExportProject,
    handleFormatChange,
    handleSelectJSONFormat,
    handleVariantOptionSelected,
    storeExportDataReference,
    isVariableExport,
    setNameOfMobileExportVariant,
  } = useExport({
    doc_ID,
    doc_name,
    developerModeEnabled,
    componentFolder,
  });

  const developerModeDisabled = isProjectExport && !developerModeEnabled;

  const currentFormatOptions = SUPPORTED_EXPORT_FORMATS[format];
  const currentFormatDisabled = developerModeDisabled && !currentFormatOptions?.availableWithoutDeveloperMode;

  const showExportOptions = currentFormatOptions?.options?.length > 0 && !currentFormatDisabled && !isVariableExport;

  const handleStatusCheckbox = handleCheckboxField("status");
  const handleTagsCheckbox = handleCheckboxField("tags");
  const handleNotesCheckbox = handleCheckboxField("notes");
  const handleComponentNameCheckbox = handleCheckboxField("component_name");
  const handleApiIdCheckbox = handleCheckboxField("api_id");
  const handleCompApiIdCheckbox = handleCheckboxField("component_api_id");
  const handleFrameCheckbox = handleCheckboxField("frame_name");
  const handleFrameLinkCheckbox = handleCheckboxField("frame_link");
  const handleBlocksCheckbox = handleCheckboxField("block_name");
  const handleVariantsCheckbox = handleCheckboxField("variants");
  const handleCommentsCheckbox = handleCheckboxField("comments");
  const handleProjectLinkCheckbox = handleCheckboxField("text_item_link");

  const handleFlatJSONCheckbox = handleSelectJSONFormat("flat");
  const handleStructuredJSONCheckbox = handleSelectJSONFormat("structured");
  const handleNestedJSONCheckbox = handleSelectJSONFormat("nested");
  const handleICUJSONCheckbox = handleSelectJSONFormat("icu");

  const exportButtonDisabled = isExporting || currentFormatDisabled;

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>{modalTitle}</BootstrapModal.Title>
          <CloseIcon data-testid="close-modal" className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          {componentFolder && (
            <div className={classnames([style.section, style.componentFolderMessage])}>
              You are currently exporting the components in <b>{componentFolder.name}</b>. To export all components,
              navigate out of this folder.
            </div>
          )}
          <div className={style.section}>
            <h2>File format</h2>
            <select value={format} onChange={handleFormatChange} disabled={isVariableExport}>
              {Object.entries(SUPPORTED_EXPORT_FORMATS).map(([value, { label }]) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          {showExportOptions && (
            <div className={style.section}>
              <h2>Export options</h2>
              {SUPPORTED_EXPORT_FORMATS[format].options.map((option) => {
                if (option === "blocks-only" && isProjectExport) {
                  return (
                    <label key={option}>
                      <input type="checkbox" checked={blocksOnly} onChange={handleBlocksOnlyChange} />
                      Only include text in blocks
                    </label>
                  );
                }
                if (option === "include-rich-text" && (isComponentExport || (isProjectExport && isRichTextFlagIsOn))) {
                  return (
                    <label key={option}>
                      <input type="checkbox" checked={includeRichText} onChange={handleIncludeRichTextChange} />
                      Include Rich Text
                    </label>
                  );
                }
                return <React.Fragment key={option} />;
              })}
            </div>
          )}
          {format === "JSON" && (
            <div className={style.clearfix}>
              {!developerModeEnabled && <DeveloperModeDisabled style={{ zIndex: 20 }} />}
              <>
                {!isVariableExport && (
                  <>
                    <JsonSection
                      title="JSON Format"
                      developerModeEnabled={developerModeEnabled}
                      tooltipId="format-help"
                      tooltip={
                        developerModeEnabled
                          ? "More info about JSON formats"
                          : `In order to access these JSON formats, turn on Developer Mode for your ${
                              isProjectExport ? "project" : "component library"
                            }`
                      }
                      tooltipOnClick={() => {
                        if (developerModeEnabled)
                          window.open("https://www.dittowords.com/docs/exporting-as-a-json", "_blank").focus();
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          disabled={!developerModeEnabled}
                          checked={isJsonFormatChecked("flat")}
                          onChange={handleFlatJSONCheckbox}
                        />
                        Flat JSON
                      </label>
                      <label>
                        <input
                          type="radio"
                          disabled={!developerModeEnabled}
                          checked={isJsonFormatChecked("structured")}
                          onChange={handleStructuredJSONCheckbox}
                        />
                        Structured JSON
                      </label>
                      {isComponentExport && (
                        <label>
                          <input
                            type="radio"
                            disabled={!developerModeEnabled}
                            checked={isJsonFormatChecked("nested")}
                            onChange={handleNestedJSONCheckbox}
                          />
                          Nested JSON
                        </label>
                      )}
                      <label>
                        <input
                          type="radio"
                          disabled={!developerModeEnabled}
                          checked={isJsonFormatChecked("icu")}
                          onChange={handleICUJSONCheckbox}
                        />
                        ICU JSON
                      </label>
                    </JsonSection>
                    <JsonSection
                      title="Variant"
                      developerModeEnabled={developerModeEnabled}
                      tooltipId="variant-help"
                      tooltip={
                        developerModeEnabled
                          ? "More info about JSON formats"
                          : `In order to access variant-specific exports, turn on Developer Mode for your ${
                              isProjectExport ? "project" : "component library"
                            }`
                      }
                      tooltipOnClick={() => {
                        if (developerModeEnabled)
                          window.open("https://www.dittowords.com/docs/exporting-as-a-json", "_blank").focus();
                      }}
                    >
                      <VariantSelectInput
                        appendToPassedOptions
                        disableCreateNew
                        options={variantSelectOptions}
                        onSelect={handleVariantOptionSelected}
                        value={variantOptionSelected}
                        placeholder="Select or search for a variant..."
                        projectId={doc_ID}
                      />
                    </JsonSection>
                  </>
                )}
                <div
                  className={classnames({
                    [style.jsonLoading]: exportLoading,
                  })}
                  data-testid="export-data"
                >
                  <ExportPreview
                    language="json"
                    loading={exportLoading}
                    content={JSON.stringify(projectJSON || "", null, 2)}
                    className={style.exportPreviewContainer}
                  />
                </div>
              </>
            </div>
          )}
          {format === "CSV" && (
            <div>
              <h2>Attributes to include</h2>
              <div>
                <label>
                  <input type="checkbox" checked={true} disabled={true} />
                  Text
                </label>
                {!isProjectExport && (
                  <>
                    <label>
                      <input type="checkbox" checked={true} disabled={true} />
                      Component Name
                    </label>
                    <label>
                      <input type="checkbox" checked={fields.api_id} onChange={handleApiIdCheckbox} />
                      Component ID
                    </label>
                  </>
                )}
                <label>
                  <input type="checkbox" checked={fields.status} onChange={handleStatusCheckbox} />
                  Status
                </label>
                <label>
                  <input type="checkbox" checked={fields.tags} onChange={handleTagsCheckbox} />
                  Tags
                </label>
                <label>
                  <input type="checkbox" checked={fields.notes} onChange={handleNotesCheckbox} />
                  Notes
                </label>
                {isProjectExport && (
                  <>
                    <label>
                      <input type="checkbox" checked={fields.component_name} onChange={handleComponentNameCheckbox} />
                      Component Name
                    </label>
                  </>
                )}
                <label>
                  <input type="checkbox" checked={fields.frame_name} onChange={handleFrameCheckbox} />
                  {isProjectExport ? "Frame Name" : "Group Name"}
                </label>
                {isProjectExport && (
                  <label>
                    <input type="checkbox" checked={fields.frame_link} onChange={handleFrameLinkCheckbox} />
                    Figma Frame Link
                  </label>
                )}
                <label>
                  <input type="checkbox" checked={fields.block_name} onChange={handleBlocksCheckbox} />
                  Block Name
                </label>
                <label>
                  <input type="checkbox" checked={fields.variants} onChange={handleVariantsCheckbox} />
                  Variants
                </label>
                {location.pathname.startsWith("/projects") && (
                  <div>
                    <label>
                      <input type="checkbox" checked={fields.comments} onChange={handleCommentsCheckbox} />
                      Comments
                    </label>
                    <label>
                      <input type="checkbox" checked={fields.text_item_link} onChange={handleProjectLinkCheckbox} />
                      Text Item Link
                    </label>
                  </div>
                )}
              </div>
            </div>
          )}
          {format === "android" && (
            <PanelAndroid
              projectId={doc_ID}
              storeVariantName={setNameOfMobileExportVariant}
              storeExportDataReference={storeExportDataReference}
              developerModeEnabled={developerModeEnabled}
              componentFolderId={componentFolder?._id || null}
            />
          )}
          {format === "ios-strings" && (
            <PaneliOS
              type="iosStrings"
              projectId={doc_ID}
              storeVariantName={setNameOfMobileExportVariant}
              storeExportDataReference={storeExportDataReference}
              developerModeEnabled={developerModeEnabled}
              componentFolderId={componentFolder?._id || null}
            />
          )}
          {format === "ios-stringsdict" && (
            <PaneliOS
              type="iosStringsdict"
              projectId={doc_ID}
              storeVariantName={setNameOfMobileExportVariant}
              storeExportDataReference={storeExportDataReference}
              developerModeEnabled={developerModeEnabled}
              componentFolderId={componentFolder?._id || null}
            />
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <div className={style.footerText}>
            {isProjectExport &&
              "Heads up! The export does not include hidden text or text in draft groups that don't have linking enabled."}
          </div>
          <ButtonPrimary
            text={isExporting ? "Exporting..." : "Export"}
            onClick={handleExportProject}
            disabled={exportButtonDisabled}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default ExportModal;
