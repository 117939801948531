import { ImportConfirmBody, ImportConfirmResponse } from "@shared/types/http/Component";
import http from "../../../../http";
import { timedPromise } from "../utils";

export const useImportConfirmRequest = () => {
  return async ({ variant, backgroundJobEntryId, action }: ImportConfirmBody) => {
    try {
      const [response] = await Promise.all([
        http.post<ImportConfirmResponse>("/ws_comp/import/confirm", {
          variant,
          backgroundJobEntryId,
          action,
        } as ImportConfirmBody),
        timedPromise(),
      ]);

      return response.data;
    } catch (error) {
      return {
        ok: false,
        error: error.response.data.message,
        code: error.response.data.code,
      };
    }
  };
};
