import { ITextItem, ZTextItemStatus, ZTipTapRichText } from "@shared/types/TextItem";
import { z } from "zod";
import { ZDittoProject, ZFramePreviewData } from "../DittoProject";
import { IFigmaTextNode } from "../FigmaTextNode";
import { BackendSchema, FrontendSchema, ZObjectId } from "../lib";

export const ZCreateDittoProject = z.object({
  projectName: z.string(),
  folderId: ZObjectId.optional(),
  fileId: z.string().optional(),
  branchId: z.string().optional(),
  dittoComponentKey: z.string().optional(),
});

export type ICreateDittoProject = z.infer<typeof ZCreateDittoProject>;

const ZDittoTextItemsData = z.array(
  z.object({
    _id: ZObjectId,
    sortKey: z.string(),
  })
);

const ZDittoBlockData = z.object({
  _id: ZObjectId.nullable(),
  name: z.string().nullable(),
  textItems: ZDittoTextItemsData,
  allTextItems: ZDittoTextItemsData,
  variantIds: z.array(ZObjectId).optional(),
});

export type IDittoBlockData = z.infer<typeof ZDittoBlockData>;
export type IBDittoBlockData = BackendSchema<IDittoBlockData>;
export type IFDittoBlockData = FrontendSchema<IDittoBlockData>;

const ZDittoProjectData = ZDittoProject.extend({
  blocks: z.array(ZDittoBlockData),
  hiddenBlocksCount: z.number(),
  hiddenRootTextItemsCount: z.number(),
});

export type IDittoProjectData = z.infer<typeof ZDittoProjectData>;
export type IBDittoProjectData = BackendSchema<IDittoProjectData>;
export type IFDittoProjectData = FrontendSchema<IDittoProjectData>;

export const ZNewTextItem = z.object({
  _id: z.string().optional(),
  richText: ZTipTapRichText,
  blockId: z.string().nullable().optional(),
  notes: z.string().optional(),
  tags: z.array(z.string()).optional(),
  status: ZTextItemStatus.optional(),
  assignee: z.string().optional(),
  instances: z
    .array(
      z.object({
        position: z.object({
          x: z.number(),
          y: z.number(),
          width: z.number(),
          height: z.number(),
        }),
        lastReconciledRichText: ZTipTapRichText.nullable().optional(),
        figmaNodeId: z.string(),
      })
    )
    .optional(),
  // eventually we probably will want to add variants, variables, plurals etc here as well?
});

export type INewTextItem = z.infer<typeof ZNewTextItem>;

export const ZCreateTextItemsProps = z.array(ZNewTextItem);
export type ICreateTextItemsProps = z.infer<typeof ZCreateTextItemsProps>;

// MARK: - Creating Blocks

export const ZNewBlock = z.object({
  _id: z.string().optional(),
  name: z.string().optional(),
  textItems: ZDittoTextItemsData.optional(),
});

export type INewBlock = z.infer<typeof ZNewBlock>;

export const ZCreateBlocksProps = z.array(ZNewBlock);
export type ICreateBlocksProps = z.infer<typeof ZCreateBlocksProps>;

// MARK: - Moving Text Items

export const ZMoveTextItemsAction = z.object({
  textItemIds: z.array(ZObjectId),
  blockId: z.string().optional().nullable(),
  before: z.string().optional(),
  after: z.string().optional(),
});

export type IMoveTextItemsAction = z.infer<typeof ZMoveTextItemsAction>;

export const ZMoveTextItemsProps = z.object({
  actions: z.array(ZMoveTextItemsAction),
});

export type IMoveTextItemsProps = z.infer<typeof ZMoveTextItemsProps>;

export const ZGetFramePreviewsData = z.object({
  framePreviewMap: z.record(z.string(), ZFramePreviewData),
  textItemIdsToTopLevelFrameNodeIds: z.record(z.string(), z.array(z.string())),
});

export type IGetFramePreviewsData = z.infer<typeof ZGetFramePreviewsData>;

export interface ITextItemsMapData {
  [textItemId: string]: ITextItem;
}

export interface ISyncedTextNodesMapData {
  [nodeId: string]: IFigmaTextNode;
}

export const errors = {
  NO_FIGMA_FILE_ASSOCIATED_WITH_PROJECT: "No Figma file associated with Ditto project",
};
