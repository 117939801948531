import React, { useEffect, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import style from "./style.module.css";

interface SelectProps {
  value: string;
  onValueChange: (value: string) => void;
  options: { [value: string]: React.ReactNode };

  filterOptionList?: (optionValue: string) => boolean;

  disabled?: boolean;
  optionSelectedClassName?: string;
  optionCy?: string;
  rootCy?: string;

  className?: string;
  selectorDropdownClassName?: string;

  getOptionClassName?: (value: string) => string;
}

const Select = (props: SelectProps) => {
  const { value, options, filterOptionList, onValueChange, disabled } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(
    function deactiveDropdownOnClick() {
      if (!dropdownActive) {
        return;
      }

      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setDropdownActive(false);
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [dropdownActive, setDropdownActive]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(props.className, style.selector, {
        [style.dropdownActive]: dropdownActive,
        [style.disabled]: disabled || false,
      })}
      onClick={() => !disabled && setDropdownActive(!dropdownActive)}
      onBlur={() => !disabled && setDropdownActive(false)}
      role="select"
      data-testid={props.rootCy}
    >
      <span className={classNames([style.selectedOption, props.optionSelectedClassName])}>
        <div className={style.labelWrapper}>{options[value]}</div>
        <KeyboardArrowDownIcon className={style.downArrowIcon} />
      </span>

      <div
        className={classNames(style.selectorDropdown, props.selectorDropdownClassName, {
          [style.dropdownActive]: dropdownActive,
        })}
      >
        {Object.entries(options)
          .filter(([value]) => (filterOptionList ? filterOptionList(value) : true))
          .map(([value, label], index) => (
            <div
              key={index}
              className={classNames([style.dropdownItem, props.getOptionClassName?.(value)])}
              onClick={() => onValueChange(value)}
              data-testid={`${props.optionCy}-${value}`}
            >
              {label}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Select;
