import { useCallback, useState } from "react";

interface UnsavedGroupChanges {
  [unlinkableGroupId: string]: true;
}

export interface UnsavedGroupChangesState {
  unsavedGroupChanges: UnsavedGroupChanges;
  setGroupHasUnsavedChanges: (groupId: string) => void;
  setGroupChangesHaveBeenSaved: (groupId: string) => void;
}

const useUnsavedGroupChangesState = (): UnsavedGroupChangesState => {
  const [unsavedGroupChanges, setUnsavedGroupChanges] = useState<UnsavedGroupChanges>({});

  const setGroupHasUnsavedChanges = useCallback(
    (groupId: string) =>
      setUnsavedGroupChanges((prevState) => {
        const newState = { ...prevState };
        newState[groupId] = true;
        return newState;
      }),
    [setUnsavedGroupChanges]
  );

  const setGroupChangesHaveBeenSaved = useCallback(
    (groupId: string) =>
      setUnsavedGroupChanges((prevState) => {
        const newState = { ...prevState };
        delete newState[groupId];
        return newState;
      }),
    [setUnsavedGroupChanges]
  );

  return {
    unsavedGroupChanges,
    setGroupHasUnsavedChanges,
    setGroupChangesHaveBeenSaved,
  };
};

export default useUnsavedGroupChangesState;
