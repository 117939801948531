import React from "react";
import Toggle from "react-toggle";
import DashboardCustomize from "../../assets/DashboardCustomize";

import classNames from "classnames";
import style from "./TemplateToggle.module.css";

interface Props {
  checked: boolean;
  onChange: () => void;
}

const TemplateToggle = (props: Props) => {
  return (
    <div
      className={classNames({
        [style.container]: true,
        [style.checked]: props.checked,
      })}
    >
      <div>
        <h3>
          <DashboardCustomize /> Template
        </h3>
        <p>
          Template components provide starter guidelines for writing text. They’ll get detached when you begin editing.{" "}
          <a href="https://www.dittowords.com/docs/templates" target="_blank" rel="nofollow">
            Learn more
          </a>
        </p>
      </div>
      <Toggle checked={props.checked} icons={false} onChange={props.onChange} />
    </div>
  );
};

export default TemplateToggle;
