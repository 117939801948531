import React from "react";
import ChangeItemWithContextMenu from "../ChangeItemWithContextMenu";
import style from "./style.module.css";

export interface IAutoAttachComponentsEnabledChangeItemProps {
  key: string;
  user?: string;
  timeAgo: React.ReactNode;
  data: { componentFolderName: string };
  onAutomationSettingsClick?: () => void;
  onClick?: () => void;
  borderedUi?: boolean;
}

export default function AutoAttachComponentsEnabledChangeItem(props: IAutoAttachComponentsEnabledChangeItemProps) {
  return (
    <ChangeItemWithContextMenu
      key={props.key}
      user={props.user}
      timeAgo={props.timeAgo}
      onClick={props.onClick}
      contextMenuOptions={
        props.onAutomationSettingsClick ? [{ displayName: "Automation Settings", value: "automation-settings" }] : []
      }
      onContextMenuOptionClick={(value: string) => {
        if (value === "automation-settings") {
          props.onAutomationSettingsClick && props.onAutomationSettingsClick();
        }
      }}
      borderedUi={props.borderedUi}
    >
      <div className={`${style.text} ${props.borderedUi ? style.borderedText : ""}`}>
        Turned on automatic attaching of exact component matches from{" "}
        <span className={style.folderName}>
          {props.data.componentFolderName
            ? // always sentence case the folder name aka caps first letter and then use their casing after that
              props.data.componentFolderName[0].toUpperCase() + props.data.componentFolderName.slice(1)
            : props.data.componentFolderName === undefined
            ? "All components"
            : "Components not in a folder"}{" "}
        </span>
        to text items in this project
      </div>
    </ChangeItemWithContextMenu>
  );
}
