import * as SegmentEvents from "@shared/segment-event-names";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";

interface FolderState {
  activeFolder: Folder | null;
  unauthorized: boolean;
}

interface Folder {
  doc_ids: string[];
  invite_only: boolean;
  name: string;
  workspace_id: string;
  __v: number;
  _id: string;
}

interface UseFolderProps {
  setProjects: (updatedState: any) => void;
  isAdminUser: boolean;
  inviteOnlyFoldersEnabled: boolean;
}

export const useFolder = ({ setProjects, isAdminUser, inviteOnlyFoldersEnabled }: UseFolderProps) => {
  const segment = useSegment();
  const { id: folderId } = useParams<{
    id?: string;
  }>();

  const [folderState, setFolderState] = useState<FolderState>({
    activeFolder: null,
    unauthorized: false,
  });

  const { activeFolder, unauthorized: unauthorizedFolderAccess } = folderState;

  const handleFolderIdChange = async () => {
    if (!folderId) {
      return setFolderState({
        activeFolder: null,
        unauthorized: false,
      });
    }

    try {
      const { url } = API.folder.get.byId;
      const { data: folder } = await http.get<Folder>(url(folderId));

      setFolderState((state) => ({
        activeFolder: state.activeFolder?._id == folder._id ? state.activeFolder : folder,
        unauthorized: false,
      }));
    } catch (e) {
      setFolderState({
        activeFolder: null,
        unauthorized: true,
      });

      const message =
        e.response?.data?.code === "unauthorized" ? "Folder path is unauthorized" : "Folder path does not exist";
      window?.analytics?.track(message);
    }
  };

  useEffect(() => {
    handleFolderIdChange();
  }, [folderId]);

  const history = useHistory();

  const [workspaceFolders, setWorkspaceFolders] = useState<Folder[]>([]);
  const [folderModalOpen, setFolderModalOpen] = useState<boolean>(false);
  const [folderConfirmationModalOpen, setFolderConfirmationModalOpen] = useState<boolean>(false);
  const [projectToMove, setProjectToMove] = useState<{
    folderId: string;
    projectId: string;
  } | null>(null);

  const [inviteFolderModalOpen, setInviteFolderModalOpen] = useState<boolean>(false);

  const [showShareFolderModal, setShowShareFolderModal] = useState<boolean>(false);

  const handleConfirmMoveProject = (confirm: boolean) => {
    if (confirm) {
      if (folderId) {
        handleRemoveFromFolder(projectToMove?.folderId, projectToMove?.projectId, false);
      } else {
        handleAddToFolder(projectToMove?.folderId, projectToMove?.projectId, false);
      }
    }
    setFolderConfirmationModalOpen(false);
    setProjectToMove(null);
  };
  const canMoveFolderProject = (folderId: string, projectId: string) => {
    if (workspaceFolders.find((folder) => folder._id === folderId && folder.invite_only)) {
      setProjectToMove({ folderId, projectId });
      setFolderConfirmationModalOpen(true);
      return false;
    }
    return true;
  };

  const handleAddToFolder = async (folderId, docId, shouldValidate = true) => {
    try {
      if (shouldValidate && !canMoveFolderProject(folderId, docId)) return;
      const { url, body } = API.folder.put.add;
      const { data: updatedFolder } = await http.put(
        url(folderId, docId),
        body({
          folderId,
        })
      );
      setWorkspaceFolders((prev) => {
        let folderIndex = prev.findIndex((folder) => folder._id === updatedFolder._id);
        prev[folderIndex].doc_ids.push(docId);
        return [...prev];
      });
      setProjects((prev) => {
        let docIndex = prev.findIndex((doc) => doc._id == docId);
        prev[docIndex].folder = {
          _id: folderId,
          name: updatedFolder.name,
        };

        return [...prev];
      });
      history.push("/projects");
    } catch (error) {
      console.error("Error in handleAddToFolder:");
      console.error(error);
    }
  };

  const handleCreateNewFolder = async (folderName, inviteOnly) => {
    try {
      const { url, body } = API.folder.post.create;
      const { data: folder } = await http.post(
        url,
        body({
          name: folderName,
          inviteOnly,
        })
      );
      setWorkspaceFolders((prev) => prev.concat(folder).sort((a, b) => a.name.localeCompare(b.name)));

      setFolderState((s) => ({ ...s, activeFolder: folder }));
      if (inviteOnlyFoldersEnabled && inviteOnly) {
        setInviteFolderModalOpen(true);
      } else {
        goToFolder(folder._id);
      }
    } catch (error) {
      console.error("Error in handleCreateNewFolder:");
      console.error(error);
    }
  };

  const removeFolderFromState = (folderId: string) => {
    setWorkspaceFolders((prev) => prev.filter((folder) => folder._id !== folderId));

    setFolderState((folderState) =>
      folderState.activeFolder?._id == folderId ? { activeFolder: null, unauthorized: false } : folderState
    );
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const { url } = API.folder.delete.delete;
      await http.delete(url(folderId));
      removeFolderFromState(folderId);
      history.push("/projects");
    } catch (error) {
      console.error("Error in handleDeleteFolder:");
      console.error(error);
    }
  };

  const handleUserRemovedSelf = (folderId: string) => {
    try {
      // admin users still have folder access after removing themselves
      if (isAdminUser) {
        return;
      }

      // don't show the folder to the user anymore
      removeFolderFromState(folderId);

      history.push("/projects");
    } catch (error) {
      console.error("Error in handleUserRemovedSelf:");
      console.error(error);
    }
  };

  const goToFolder = (folderId: string) => {
    history.push(routes.nonNavRoutes.folder.getPath(folderId));
  };

  const handleGetAllFolders = async (folderId) => {
    try {
      const { url } = API.folder.get.index;
      const { data: folderInfo } = await http.get(url);
      const { folders } = folderInfo;

      setWorkspaceFolders(folders || []);

      setFolderState((prevState) => {
        if (!prevState.activeFolder) {
          return prevState;
        }

        const updatedActiveFolder = folders.find((folder) => folder._id === prevState.activeFolder?._id);

        return updatedActiveFolder
          ? { activeFolder: updatedActiveFolder, unauthorized: false }
          : { activeFolder: null, unauthorized: false };
      });
    } catch (error) {
      console.error(`Something went wrong in handleGetAllFolders: ${folderId}`);
      console.error(error);
      throw error;
    }
  };

  const updateFolderInState = (f: Pick<Folder, "_id"> & Omit<Partial<Folder>, "_id">) => {
    if (!f?._id) {
      throw new Error(`Can't update folder in state without _id: ${f}`);
    }

    setWorkspaceFolders((folders) =>
      folders.map((folder) => {
        if (folder._id != f._id) {
          return folder;
        }

        return {
          ...folder,
          ...f,
        };
      })
    );

    setFolderState((prev) => {
      if (!(prev.activeFolder?._id == f._id)) {
        return prev;
      }

      return {
        activeFolder: { ...prev.activeFolder, ...f },
        unauthorized: false,
      };
    });
  };

  const handleRenameFolder = async (folderId, newFolderName) => {
    try {
      const { url, body } = API.folder.put.rename;
      await http.put(
        url(folderId),
        body({
          name: newFolderName,
        })
      );

      updateFolderInState({ _id: folderId, name: newFolderName });
    } catch (error) {
      console.error("Error in handleRenameFolder:");
      console.error(error);
    }
  };

  const handleFolderMadeInviteOnly = (f: { _id: string }) => updateFolderInState({ ...f, invite_only: true });

  const handleRemoveFromFolder = async (folderId, docId, shouldValidate = true) => {
    try {
      if (shouldValidate && !canMoveFolderProject(folderId, docId)) return;
      const { url } = API.folder.put.remove;
      const { data: updatedFolder } = await http.put(url(folderId, docId));
      setWorkspaceFolders((prev) => {
        let folderIndex = prev.findIndex((folder) => folder._id === updatedFolder._id);
        prev[folderIndex].doc_ids = prev[folderIndex].doc_ids.filter((id) => id != docId);
        return [...prev];
      });
      setProjects((prev) => {
        let docIndex = prev.findIndex((doc) => doc._id == docId);
        prev[docIndex].folder = null;
        return [...prev];
      });
    } catch (error) {
      console.error("Error in handleRemoveFromFolder:");
      console.error(error);
    }
  };

  const generatedFolderNameMap = (folders: { _id: string }[]) => {
    const result = {};
    folders.forEach((folder) => (result[folder._id] = folder));
    return result;
  };

  const workspaceFolderIdMap = useMemo(() => generatedFolderNameMap(workspaceFolders || []), [workspaceFolders]);

  const openFolderModal = () => {
    segment.track({ event: SegmentEvents.CREATE_FOLDER_MODAL_OPENED });
    setFolderModalOpen(true);
  };

  const closeFolderModal = () => {
    setFolderModalOpen(false);
  };

  const closeInviteFolderModal = () => {
    setInviteFolderModalOpen(false);
  };

  const openShareFolderModal = () => setShowShareFolderModal(true);
  const closeShareFolderModal = () => setShowShareFolderModal(false);

  return {
    activeFolder,
    folderId,
    workspaceFolders,
    workspaceFolderIdMap,
    folderModalOpen,
    inviteFolderModalOpen,
    folderConfirmationModalOpen,
    handleConfirmMoveProject,
    openFolderModal,
    closeFolderModal,
    showShareFolderModal,
    openShareFolderModal,
    closeShareFolderModal,
    closeInviteFolderModal,
    handleAddToFolder,
    handleCreateNewFolder,
    handleDeleteFolder,
    handleGetAllFolders,
    handleRemoveFromFolder,
    handleRenameFolder,
    handleFolderMadeInviteOnly,
    handleUserRemovedSelf,
    unauthorizedFolderAccess,
  };
};
