import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import Check from "@mui/icons-material/Check";
import FileCopy from "@mui/icons-material/FileCopy";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import style from "./style.module.css";

function ImpersonateBanner() {
  const [cookies, , removeCookie] = useCookies(["impersonateUser"]);
  const [bannerHidden, setBannerHidden] = useState<boolean>(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false);

  const { workspaceInfo } = useWorkspace();
  const { user } = useAuthenticatedAuth();

  if (!cookies.impersonateUser) return <></>;

  function removeImpersonation() {
    removeCookie("impersonateUser", {
      domain: process.env.IS_LOCAL ? "localhost" : ".dittowords.com",
    });
    window.location.reload();
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(cookies.impersonateUser);
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 2000);
  }

  return (
    <div
      className={classNames(style.impersonateBanner, {
        [style.hidden]: bannerHidden,
      })}
    >
      <div
        className={classNames(style.hideIcon, { [style.hidden]: bannerHidden })}
        onClick={() => setBannerHidden((prev) => !prev)}
      >
        <KeyboardArrowRightIcon />
      </div>
      <div className={style.main}>
        <div>
          You are impersonating <strong>{user?.name}</strong> in workspace <strong>{workspaceInfo?.name}</strong>
        </div>
        <div className={style.workspaceInfo}>
          Workspace ID: <span className={style.wsId}>{workspaceInfo?._id}</span>{" "}
          <FileCopy className={style.copyIcon} onClick={copyToClipboard} />
          {showCopiedMessage && (
            <span className={style.copiedMessage}>
              {" "}
              <Check className={style.copyCheckIcon} /> Copied!
            </span>
          )}
        </div>
        <div className={style.buttons}>
          <button className={style.stopButton} onClick={removeImpersonation}>
            Stop impersonating
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImpersonateBanner;
