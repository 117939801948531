import { LIB_NAV_ACTIONS, LibraryNavContext } from "@/views/Components/components/comp-library-nav/libraryNavState";
import { getComponentNamesMap } from "@shared/lib/components";
import { useContext } from "react";
import * as DittoEvents from "../../../../shared/ditto-events";
import { useDittoEventListener } from "../../../../shared/ditto-events/frontend";

export default function ComponentLibraryWebsocketHandler() {
  const { dispatch } = useContext(LibraryNavContext);

  useDittoEventListener(DittoEvents.componentsRenamed, (data) => {
    dispatch({ type: LIB_NAV_ACTIONS.componentsRenamed, data: data.renamesMap });
  });

  useDittoEventListener(DittoEvents.componentsCreated, (data) => {
    dispatch({ type: LIB_NAV_ACTIONS.componentsCreated, data: { componentMap: data.componentNamesMap } });
  });

  useDittoEventListener(DittoEvents.componentCreated, (data) => {
    const componentMap = getComponentNamesMap([{ _id: data.componentId.toString(), name: data.name }]);
    dispatch({ type: LIB_NAV_ACTIONS.componentsCreated, data: { componentMap } });
  });

  useDittoEventListener(DittoEvents.componentsUpdated, (data) => {
    if (data.deletedComponentIds.length) {
      // coercing to strings for type
      const deletedComponentIds = data.deletedComponentIds.map((id) => id.toString());
      dispatch({
        type: LIB_NAV_ACTIONS.componentsDeleted,
        data: { deletedComponentIds },
      });
    }
  });

  return null;
}
