import classNames from "classnames";
import React from "react";
import Toggle from "react-toggle";
import { ComponentApiIdGenerationModal } from "../../../../components/ApiIdGeneration/ComponentApiIdGenerationModal";
import { ProjectApiIdGenerationModal } from "../../../../components/ApiIdGeneration/ProjectApiIdGenerationModal";
import { ComponentsIDRulesList } from "../../../../components/account-devtools/ComponentsIDRulesList";
import { ProjectsIDRulesList } from "../../../../components/account-devtools/ProjectIDRulesList";
import style from "./style.module.css";
import { useConfigureDevIDsTab } from "./useConfigureDevIDsTab";

const ConfigureDevIDsTab = () => {
  const {
    componentApiIdGenerateOnRename,
    onChangeComponentApiIdGenerateOnRename,
    componentApiIdPreventManualEdits,
    onChangeComponentApiIdPreventManualEdits,
    projectApiIdOptOutHumanReadable,
    onChangeProjectApiIdOptOutHumanReadable,
    projectApiIdUpdateIdsWhenGroupBlockChange,
    onChangeProjectApiIdUpdateIdsWhenGroupBlockChange,
    projectApiIdPreventManualEdits,
    onChangeProjectApiIdPreventManualEdits,
    showComponentConfigModal,
    showProjectConfigModal,
    onEditComponentsConfigClick,
    onEditProjectsConfigClick,
    onConfigModalSave,
    componentApiIdGenerationConfig,
    projectApiIdGenerationConfig,
    canEditComponentsConfig,
    canEditProjectsConfig,
    devToolsEnabled,
    setShowComponentConfigModal,
    setShowProjectConfigModal,
  } = useConfigureDevIDsTab();

  return (
    <div className={style.configureDevIDsTab}>
      <div
        className={classNames([style.container, style.developerIdConfiguration], {
          [style.disabled]: !devToolsEnabled,
        })}
      >
        <div className={style.top}>
          <div className={style.title}>Developer IDs</div>
          <div className={style.subtitle}>
            IDs allow developers to reference individual strings in Ditto. Configure how these IDs are generated and
            formatted.{" "}
            <a href="https://www.dittowords.com/docs/ditto-developer-mode" target="_blank" className={style.grayLink}>
              More info
            </a>
          </div>
        </div>
        <div className={classNames([style.flexContainer, style.content])}>
          <div className={style.leftSideContent}>
            <div className={style.title}>Components</div>
            <div className={style.subtitle}>Configure the IDs of components in your component library.</div>
          </div>
          <div className={style.rightSideContent}>
            <div className={style.componentIdRules}>
              <button
                className={classNames({
                  [style.editButton]: true,
                  [style.disabled]: !canEditComponentsConfig,
                })}
                onClick={onEditComponentsConfigClick}
              >
                Edit
              </button>
              <div>
                <p className={style.boldParagraph}>Component ID Rules</p>
                <p>
                  Components in your workspace are generated based on the component name. Edit the rules to modify how
                  the ID is generated.
                </p>
                <ComponentsIDRulesList config={componentApiIdGenerationConfig} />
              </div>
            </div>
            <div className={style.apiIdGenerateOnRename}>
              <Toggle
                data-testid="automatically-update-component-ids"
                className={style.toggle}
                icons={false}
                checked={componentApiIdGenerateOnRename}
                onChange={onChangeComponentApiIdGenerateOnRename}
                disabled={!canEditComponentsConfig}
              />
              <div>
                <p className={style.boldParagraph}>Automatically update ID when component's name is changed</p>
                <p>
                  The ID will be updated according to the rules above. Changes to the ID will be shown in the
                  component's history.
                </p>
              </div>
            </div>
            <div className={style.apiIdGenerateOnRename}>
              <Toggle
                data-testid="prevent-component-id-edits"
                className={style.toggle}
                icons={false}
                checked={componentApiIdPreventManualEdits}
                onChange={onChangeComponentApiIdPreventManualEdits}
                disabled={!canEditComponentsConfig}
              />
              <div>
                <p className={style.boldParagraph}>Prevent manual edits to component IDs</p>
                <p>
                  IDs cannot be manually edited by users and will only be created or changed according to the component
                  ID rules.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames([style.flexContainer, style.content])}>
          <div className={style.leftSideContent}>
            <div className={style.title}>Projects</div>
            <div className={style.subtitle}>Configure the IDs of text items in your projects.</div>
          </div>
          <div className={style.rightSideContent}>
            <div className={style.componentIdRules}>
              <button
                className={classNames({
                  [style.editButton]: true,
                  [style.disabled]: !canEditProjectsConfig,
                })}
                onClick={onEditProjectsConfigClick}
                disabled={projectApiIdOptOutHumanReadable}
              >
                Edit
              </button>
              <div>
                <p className={style.boldParagraph}>Text Item ID Rules</p>
                <p>
                  When developer mode is turned on in a project, IDs for text items in your project will be generated
                  using these rules. Edit the rules to modify how the IDs are generated.
                </p>
                <ProjectsIDRulesList config={projectApiIdGenerationConfig} />
              </div>
            </div>
            <div className={style.optOutHumanReadable}>
              <Toggle
                data-testid="opt-out-human-readable-ids"
                className={style.toggle}
                icons={false}
                checked={projectApiIdOptOutHumanReadable}
                onChange={onChangeProjectApiIdOptOutHumanReadable}
                disabled={!canEditProjectsConfig}
              />
              <div>
                <p className={style.boldParagraph}>Opt out of human-readable IDs</p>
                <p>
                  When selected, Text Item ID rules will be disabled, and IDs will be generated using a random unique
                  identifier (i.e. “text_1234”).
                </p>
              </div>
            </div>
            <div className={style.apiIdGenerateOnRename}>
              <Toggle
                className={style.toggle}
                icons={false}
                checked={projectApiIdUpdateIdsWhenGroupBlockChange}
                onChange={onChangeProjectApiIdUpdateIdsWhenGroupBlockChange}
                disabled={!canEditProjectsConfig}
              />
              <div>
                <p className={style.boldParagraph}>
                  Automatically update group and block IDs when group name or block name is changed
                </p>
                <p>
                  The ID will be updated according to the rules above. Changes to the ID will be shown in the text
                  item's change history.
                </p>
              </div>
            </div>
            <div className={style.apiIdGenerateOnRename}>
              <Toggle
                data-testid="prevent-project-id-edits"
                className={style.toggle}
                icons={false}
                checked={projectApiIdPreventManualEdits}
                onChange={onChangeProjectApiIdPreventManualEdits}
                disabled={!canEditProjectsConfig}
              />
              <div>
                <p className={style.boldParagraph}>Prevent manual edits to IDs in projects (text item, block, group)</p>
                <p>
                  IDs cannot be manually edited by users and will only be created or changed according to the project ID
                  rules.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showComponentConfigModal && (
        <ComponentApiIdGenerationModal
          initialConfig={componentApiIdGenerationConfig}
          onHide={() => setShowComponentConfigModal(false)}
          onSave={onConfigModalSave("components")}
        />
      )}
      {showProjectConfigModal && (
        <ProjectApiIdGenerationModal
          initialConfig={projectApiIdGenerationConfig}
          onHide={() => setShowProjectConfigModal(false)}
          onSave={onConfigModalSave("projects")}
        />
      )}
    </div>
  );
};

export default ConfigureDevIDsTab;
