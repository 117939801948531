/*
  All of the blocks for a frame
*/

import classnames from "classnames";
import React, { useState } from "react";
import http, { API } from "../../http";
import Block from "../block/block";
import style from "./style.module.css";

const Blocks = ({
  blocks,
  selectComp,
  isSelected,
  frameIndex,
  figmaId,
  doc_ID,
  updateDoc,
  blockToRename,
  setBlockToRename,
  isInFilters,
  filtersOff,
  num_comps_filtered,
  setPanelState,
  setCommentState,
  displayApiIds,
  handleHistoryUpdate,
  frameVariant,
  frameVariants,
  groupId,
  suggestedCompId,
  showSetupFromAnotherGroup,
  resyncLoading,
  hoveredVariantId,
  setHoveredVariantId,
  handleSelectActiveVariant,
}) => {
  const [isOrderChanging, setIsOrderChanging] = useState(false);

  const handleChangeBlockOrder = async (oldIndex, newIndex) => {
    setIsOrderChanging(true);

    const { url, body } = API.doc.post.changeBlockOrder;
    const { data } = await http.post(
      url,
      body({
        doc_ID: doc_ID,
        groupId,
        startIndex: oldIndex,
        endIndex: newIndex,
      })
    );
    setIsOrderChanging(false);
    updateDoc([groupId]);
  };

  const numBlocksFiltered = blocks.filter((block) => {
    return block.comps.filter((comp) => isInFilters(comp)).length > 0;
  }).length;

  if (numBlocksFiltered > 0 || filtersOff) {
    return (
      <div
        className={classnames({
          [style.blocks]: true,
          [style.noComps]: num_comps_filtered === 0 && !filtersOff,
        })}
      >
        {blocks.map(({ name, comps, apiID, _id }, index) => {
          return (
            <Block
              id={_id}
              name={name}
              uniqueBlockNames={blocks.map((block) => block.name)}
              comps={comps}
              apiID={apiID}
              key={index}
              selectComp={selectComp}
              isSelected={isSelected}
              index={index}
              numBlocks={blocks.length}
              frameIndex={frameIndex}
              figmaId={figmaId}
              doc_ID={doc_ID}
              updateDoc={updateDoc}
              blockToRename={blockToRename}
              setBlockToRename={setBlockToRename}
              isInFilters={isInFilters}
              filtersOff={filtersOff}
              setPanelState={setPanelState}
              setCommentState={setCommentState}
              displayApiIds={displayApiIds}
              handleHistoryUpdate={handleHistoryUpdate}
              frameVariant={frameVariant}
              frameVariants={frameVariants}
              handleChangeBlockOrder={handleChangeBlockOrder}
              isOrderChanging={isOrderChanging}
              groupId={groupId}
              suggestedCompId={suggestedCompId}
              showSetupFromAnotherGroup={showSetupFromAnotherGroup}
              resyncLoading={resyncLoading}
              hoveredVariantId={hoveredVariantId}
              setHoveredVariantId={setHoveredVariantId}
              handleSelectActiveVariant={handleSelectActiveVariant}
            />
          );
        })}
      </div>
    );
  } else {
    return <span></span>;
  }
};

export default Blocks;
