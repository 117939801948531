import { IProps as IToastProps } from "@ds/molecules/ToastBase";
import { useRef, useState } from "react";
import http, { API } from "../../http";

interface LoadComponentsOptions {
  page: number;
  limit: number;
  search?: string;
  tags?: string[];
  status?: string;
  folderId?: string;
  fields?: object;
}

interface LoadComponentsResponse<C> {
  components: C[];
  count: number;
}

interface ComponentLibraryComponent {
  _id: string;
  name: string;
  text: string;
  status: string;
  tags: string[];
  folder_id: string | null;
  doc_ID: {
    _id: string;
    doc_name: string;
  };
}

type ComponentLibraryComponentWithPage = ComponentLibraryComponent & {
  page?: number;
};

/**
 * Loads components from the backend. Each component includes flattened data from its
 * first instance (text, status, and tags)
 * @param options
 * @returns
 */
export const loadComponents = async <C = ComponentLibraryComponent,>(
  options: LoadComponentsOptions
): Promise<LoadComponentsResponse<C> | null> => {
  const { tags, status, page, limit, search, fields } = options;
  const { url, body } = API.ws_comp.post.page;

  try {
    const {
      data: { components, count },
    } = await http.post(
      url,
      body({
        filter: {
          search,
          status,
          tags,
          // TODO: implement later
          // folder_id: null
        },
        fields,
      }),
      {
        params: {
          page,
          limit,
        },
      }
    );

    return { components, count };
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Given an array of components with page information, and the id of a component that has been selected,
 * return the page number that the selected component can be found on. Defaults to 0.
 */
export const getComponentPage = (components: { _id: string; page?: number }[], selectedComponentId: string | null) => {
  if (!selectedComponentId) {
    return 0;
  }

  const component = components.find((c) => c._id === selectedComponentId);
  if (!(component && typeof component.page === "number")) {
    return 0;
  }

  return component.page;
};

/**
 * Loads all components for the workspace.
 */
export const loadAllComponents = async (options: {
  pageSize?: number | null;
  folder_id?: string | null;
}): Promise<{ components: ComponentLibraryComponent[]; count: number }> => {
  const { url, body } = API.ws_comp.post.page;
  const { data } = await http.post(
    url,
    body({
      filter: {
        search: "",
        status: "Any",
        tags: null,
      },
      fields: { folder_id: options.folder_id ?? null },
    }),
    {
      params: {
        page: 0,
        limit: 5000,
        includeCount: false,
      },
    }
  );

  return data;
};

export const indexComponents = (
  components: ComponentLibraryComponent[],
  pageSize: number
): ComponentLibraryComponentWithPage[] => {
  const result: ComponentLibraryComponentWithPage[] = [...components];
  if (!pageSize) {
    return result;
  }

  // index each component with its page number, relative to its folder
  const folderIndices: { [folderId: string]: number } = {};
  result.forEach((component) => {
    const folderId = component.folder_id || "null";
    folderIndices[folderId] = folderIndices[folderId] === undefined ? 0 : folderIndices[folderId] + 1;

    component.page = Math.floor(folderIndices[folderId] / pageSize);
  });

  return result;
};

export const scrollLibraryToTop = () => {
  const libContainer = document.querySelector("#projectContainer");
  if (libContainer) {
    libContainer.scrollTop = 0;
  }
};

export const scrollNavToTop = () => {
  const navContainer = document.querySelector("#ws-library-nav");
  if (navContainer) {
    navContainer.scrollTop = 0;
  }
};

export const getSelectedPageInfo = (
  components: { _id: string; page: number }[],
  componentId: string | null | undefined
) => {
  if (!componentId) {
    return { id: undefined, page: 0 };
  }

  const component = components.find((c) => c._id === componentId);
  if (!component) {
    return { id: undefined, page: 0 };
  }

  return { id: component._id, page: component.page };
};

/**
 * Retry attempting to find and scroll to the specified `id` using the browser's native
 * `scrollIntoView` method. Configurable number of attempts.
 * @param id
 * @param attempts
 * @returns `true` if the element was found and scrolled to, `false` otherwise.
 */
export function scrollToElementId(id: string, attempts: number = 10, options = {}): Promise<boolean> {
  return new Promise((resolve) => {
    _scrollToElementId(id);

    function _scrollToElementId(id: string, attemptsRemaining = attempts) {
      const el = document.getElementById(id);

      if (!el && attemptsRemaining > 0) {
        setTimeout(() => _scrollToElementId(id, attemptsRemaining - 1), 100);
        return;
      }

      if (!el) {
        resolve(false);
        return;
      }

      setTimeout(() => {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          ...options,
        });
        resolve(true);
        // this 300ms delay is hacky, but is designed to give some buffer to allow time for the loading
        // UI to be removed from the screen before attempting to scroll the component container
      }, 300);
    }
  });
}

// if folder_id is null, `isSample` should be false:
// - if there are no other filter params, only return components at the root (isSample: false, folder_id: null)
// - if there are other filter params, return components at the root AND components in folders (with components in folders returned first) (isSample: false, folder_id: undefined)
// if folder_id is not null:
// - if it is the sample folder, `isSample` should be true
// - if it is not the sample folder, `isSample` should be false
export const getComponentLoadParameters = (
  baseParams: any,
  selectedFolderId: string | null | undefined,
  sampleFolderId: string | null
) => {
  // when sending these via query parameters to the backend, the distinction
  // between `null` and `undefined` is important, which is why we ensure
  // that we're setting correct defaults here (i.e. undefined instead of null
  // if a property shouldn't be filtered on)
  const baseParamsWithDefaults = {
    status: baseParams.status && baseParams.status !== "Any" ? baseParams.status : undefined,
    tags: baseParams.tags?.length ? baseParams.tags : undefined,
    variantId: baseParams.variantId || undefined,
    search: baseParams.search || undefined,
    assignee: baseParams.assignee || undefined,
    componentDeveloperId: baseParams.componentDeveloperId || undefined,
  };

  const hasNonFolderFilterParams = Object.values(baseParamsWithDefaults).some((v) => v !== undefined);

  // if this is included in baseParamsWithDefaults, the `hasNonFilterFilterParams` check will be true
  // when the component library loads with a folder selected, and that's not what we want since it will
  // cause components to be loaded that are in the root AND that are in folders
  let selectedComponentId: string | undefined = undefined;
  if (baseParams.selectedComponentId) {
    selectedComponentId = baseParams.selectedComponentId;
  } else if (baseParams.selectedComponentIds) {
    selectedComponentId = baseParams.selectedComponentIds[0];
  }

  if (!selectedFolderId && !hasNonFolderFilterParams) {
    return {
      ...baseParamsWithDefaults,
      isSample: false,
      folder_id: null,
      selectedComponentId,
    };
  }

  if (!selectedFolderId && hasNonFolderFilterParams) {
    return {
      ...baseParamsWithDefaults,
      isSample: false,
      folder_id: undefined,
      selectedComponentId,
    };
  }

  return {
    ...baseParamsWithDefaults,
    isSample: Boolean(selectedFolderId) && selectedFolderId === sampleFolderId,
    folder_id: selectedFolderId,
    selectedComponentId,
  };
};

export const COMPONENTS_MULTI_SELECT_QUERY_PARAM = "selected-components";

export const getSelectedComponentParams = (
  params: Record<string, string>,
  qs: string
): {
  selectedComponentId?: string;
  selectedComponentIds?: string[];
} => {
  if (params.id && isValidObjectId(params.id)) {
    return { selectedComponentId: params.id };
  }

  const search = new URLSearchParams(qs);
  const selectedComponentIds = (search.get(COMPONENTS_MULTI_SELECT_QUERY_PARAM) || "").split(",").filter(Boolean);

  if (selectedComponentIds?.length) {
    return { selectedComponentIds };
  }

  return {};
};

function isValidObjectId(id: string) {
  return /^[0-9a-fA-F]{24}$/.test(id);
}

export type ToastHandlerData = { text: string; visibleFor: number } & Omit<IToastProps, "children">;
export function useToastHandler() {
  const [state, setState] = useState<{ visible: false } | { visible: true; props: IToastProps }>({
    visible: false,
  });

  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  return {
    state,
    hide: () => {
      if (hideTimeoutRef.current !== null) clearTimeout(hideTimeoutRef.current);
      setState({ visible: false });
    },
    show: (data: ToastHandlerData) => {
      const { visibleFor, ...props } = data;

      if (hideTimeoutRef.current !== null) clearTimeout(hideTimeoutRef.current);
      setState({ visible: true, props });

      hideTimeoutRef.current = setTimeout(() => setState({ visible: false }), visibleFor);
    },
  };
}
