import { useDroppable } from "@dnd-kit/core";

export type DraggableData = {
  type: "COMPONENT" | "MULTI_COMPONENT";

  // *all* currently selected comps as dictated by library selection state
  // this is not always intuitive!!! be careful!
  selectedComps: { _id: string; name: string }[];
  name: string;
  isInGroup: boolean;

  // the ID of the component where the drag event actually started
  dragCompId: string;
};

type DraggableTypes = { [key in DraggableData["type"]]: key };

export const DraggableTypes: DraggableTypes = {
  COMPONENT: "COMPONENT",
  MULTI_COMPONENT: "MULTI_COMPONENT",
};

export type DroppableData =
  | {
      type: "COMPONENT_FOLDER";
      folderId: string;
      isSample: boolean;
    }
  | {
      type: "COMPONENT_GROUP";
      groupName: string;
      blockName: "";
    }
  | {
      type: "COMPONENT_BLOCK";
      groupName: string;
      blockName: string;
    }
  | {
      type: "COMPONENT_NOT_IN_GROUP";
    };

type DroppableTypes = { [key in DroppableData["type"]]: key };

export const DroppableTypes: DroppableTypes = {
  COMPONENT_FOLDER: "COMPONENT_FOLDER",
  COMPONENT_GROUP: "COMPONENT_GROUP",
  COMPONENT_BLOCK: "COMPONENT_BLOCK",
  COMPONENT_NOT_IN_GROUP: "COMPONENT_NOT_IN_GROUP",
};

// lower numbers are higher priority
export const DropTypePriority = {
  [DroppableTypes.COMPONENT_FOLDER]: 0,
  [DroppableTypes.COMPONENT_BLOCK]: 1,
  [DroppableTypes.COMPONENT_GROUP]: 2,
  [DroppableTypes.COMPONENT_NOT_IN_GROUP]: 3,
};

export const useComponentFolderDroppable = (folderId: string, isSample: boolean) =>
  useDroppable({
    id: `${folderId}`,
    data: {
      folderId,
      isSample,
      type: DroppableTypes.COMPONENT_FOLDER,
    } as DroppableData,
  });

export const useComponentGroupDroppable = (groupName: string) =>
  useDroppable({
    id: `${DroppableTypes.COMPONENT_GROUP}-${groupName}`,
    data: {
      groupName,
      blockName: "",
      type: DroppableTypes.COMPONENT_GROUP,
    } as DroppableData,
  });

export const useComponentBlockDroppable = (groupName: string, blockName: string) =>
  useDroppable({
    id: `${DroppableTypes.COMPONENT_BLOCK}-${groupName}-${blockName}`,
    data: {
      blockName,
      groupName,
      type: DroppableTypes.COMPONENT_BLOCK,
    } as DroppableData,
  });

export const useComponentNotInGroupDroppable = () =>
  useDroppable({
    id: DroppableTypes.COMPONENT_NOT_IN_GROUP,
    data: {
      type: DroppableTypes.COMPONENT_NOT_IN_GROUP,
    } as DroppableData,
  });
