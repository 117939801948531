import React from "react";
import style from "./style.module.css";

export const CompName = (props: { name: string }) => {
  const path = props.name.split("/");
  return (
    <div className={style.name}>
      {path.map((segment, index) => {
        if (index === 0) {
          return <span key={index}>{segment}</span>;
        } else {
          return (
            <span key={index}>
              <span className={style.slash}>/</span>
              {segment}
            </span>
          );
        }
      })}
    </div>
  );
};
