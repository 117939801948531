const navRoutes = {
  home: {
    name: "Home",
    path: "/",
  },
  developers: {
    name: "Developers",
    path: "/developers",
  },
  search: {
    name: "Search",
    path: "/search",
  },
  components: {
    name: "Components",
    path: "/components",
  },
  projects: {
    name: "Projects",
    path: "/projects",
    exact: true,
  },
  variants: {
    name: "Variants",
    path: "/variants",
  },
  variables: {
    name: "Variables",
    path: "/variables",
  },
  integrations: {
    name: "Integrations",
    path: "/integrations",
  },
  settings: {
    name: "Settings",
    path: "/account",
  },
};

export { navRoutes };
