import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import VariableText from "./VariableText";
import style from "./VariablesPanel.module.css";

const attributeMap = {
  example: "Example",
  fallback: "Fallback",
  url: "URL",
  text: "Text",
};

const getAttributeLabel = (attribute) => {
  const label = String(attributeMap[attribute]);
  if (!label) {
    throw new Error("Unsupported attribute: " + attribute);
  }
  return label;
};

function VariableAttributes(props) {
  const { data, type } = props.variable;

  if (type === "list") {
    return (
      <ul>
        <div>
          <span className={classNames(style.attributeName, style.valuesLabel)}>Values</span>
          <ul className={style.attributeList}>
            {data.map((e) => (
              <li key={e} className={style.attribute}>
                <VariableText text={e} />
              </li>
            ))}
          </ul>
        </div>
      </ul>
    );
  }

  if (type === "map") {
    return (
      <ul>
        <div>
          <span className={classNames(style.attributeName, style.valuesLabel)}>Key-Value Pairs</span>
          <ul className={style.attributeList}>
            {Object.entries(data).map(([key, value]) => (
              <li key={key} className={style.attribute}>
                <VariableText text={key} />
                <span className={style.mapArrow}>{"->"}</span>
                <VariableText text={value} />
              </li>
            ))}
          </ul>
        </div>
      </ul>
    );
  }

  return (
    <ul className={style.attributeList}>
      {Object.keys(data)
        .filter((key) => !!data[key] || data[key] === 0)
        .map((key) => (
          <li className={style.attribute} key={key}>
            <span className={style.attributeName}>{getAttributeLabel(key)}</span>
            <VariableText text={data[key]} />
          </li>
        ))}
    </ul>
  );
}

function Variable(props) {
  const { variable } = props;
  const history = useHistory();

  const onOpenVariable = () => history.push(`/variables/${variable.variable_id}`);

  return (
    <div className={style.variable}>
      <VariableText text={variable.name} className={style.variableName} />
      <VariableAttributes variable={variable} />
      <button className={style.openVariableButton} onClick={onOpenVariable}>
        <span>Open variable</span> <ArrowForwardIcon />
      </button>
    </div>
  );
}

export default function VariablesPanel(props) {
  const { variables, isVariant } = props;
  const [open, setOpen] = useState(true);

  if (!(variables && variables.length)) {
    return <React.Fragment />;
  }

  const onTitleClick = () => setOpen((open) => !open);
  const containerClass = open ? "" : style.collapsed;

  const title = isVariant ? "VARIANT VARIABLES" : "VARIABLES";

  return (
    <div className={classNames(style.container, containerClass, props.className)}>
      <h2 className={style.title} onClick={onTitleClick}>
        <ArrowRightIcon />
        {title}
      </h2>
      <div className={style.variablesContainer}>
        {variables
          .filter((variable) => !!variable)
          .map((variable, i) => {
            return (
              <React.Fragment key={variable.variable_id || i}>
                <Variable variable={variable} />
                {i !== variables.length - 1 && <hr />}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}
