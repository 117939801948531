import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { BillingContext } from "../../store/billingContext";
import style from "./style.module.css";

const ComponentLimitBar = () => {
  const {
    componentLimits: { componentCount, componentsLimit, isOverComponentLimit, percentageUsed, plan },
    showBillingModal,
  } = useContext(BillingContext);

  if (percentageUsed < 90 || plan === "growth" || plan === "enterprise" || plan === "trial") return <></>;

  return (
    <div className={style.componentLimitBox}>
      <div className={style.componentLimitHeaderWrapper}>
        <div className={style.componentLimitTextWrapper}>
          <span className={style.componentLimitTitle}>
            {isOverComponentLimit
              ? "Your workspace has reached its component limit!"
              : "Your workspace is almost at its component limit!"}
          </span>
          <span className={style.componentLimitText}>
            Our {plan === "free" ? "Starter" : "Team"} Plan is limited to {componentsLimit} components. Upgrade your
            workspace to create more components.
          </span>
        </div>
        <Button className={style.componentLimitButton} size="sm" onClick={showBillingModal}>
          Upgrade Plan
        </Button>
      </div>

      <div className={style.componentLimitProgressWrapper}>
        <div className={style.componentLimitProgressValue} style={{ width: percentageUsed + "%" }}></div>
      </div>
      <span className={style.componentLimitProgressLabel}>
        <span className={style.componentLimitProgressLabelEm}>{componentCount}</span>/{componentsLimit} Components
      </span>
    </div>
  );
};

export default ComponentLimitBar;
