import { generateSortKey } from "@shared/lib/components";

export type RenamePair = { name: string; sortKey: string; oldName: string };

/**
 * Our components use a naming convention of "Group/Block/Name" to organize them.
 * This function takes a component's current full name, along with a new target
 * group and block name, and returns the new name, or an empty string if the
 * component should not be renamed.
 */
export function computeNewCompName(currentName: string, newGroupName: string, newBlockName: string) {
  const nameParts = currentName.split("/");
  let currentGroupName = "";
  let currentBlockName = "";
  let nameWithoutGrouping = "";
  if (nameParts.length >= 3) {
    currentGroupName = nameParts[0];
    currentBlockName = nameParts[1];
    nameWithoutGrouping = nameParts.slice(2).join("/");
  } else if (nameParts.length == 2) {
    currentGroupName = nameParts[0];
    nameWithoutGrouping = nameParts.slice(1).join("/");
  } else {
    nameWithoutGrouping = nameParts[0];
  }

  if (currentGroupName === newGroupName && currentBlockName === newBlockName) {
    return "";
  }

  let newName = "";
  if (newBlockName) {
    newName = `${newGroupName}/${newBlockName}/${nameWithoutGrouping}`;
  } else if (newGroupName) {
    newName = `${newGroupName}/${nameWithoutGrouping}`;
  } else {
    newName = `${nameWithoutGrouping}`;
  }

  return newName;
}

export function computeRenames(
  selectedComps: { _id: string; name: string }[],
  newFolderId: string | null,
  newGroupName: string,
  newBlockName: string
) {
  const pairs: RenamePair[] = [];

  selectedComps.forEach((comp) => {
    const newName = computeNewCompName(comp.name, newGroupName, newBlockName);
    if (newName)
      pairs.push({
        name: newName,
        sortKey: generateSortKey(newName, newFolderId),
        oldName: comp.name,
      });
  });

  return pairs;
}
