import classnames from "classnames";
import React, { useState } from "react";
import style from "./style.module.css";
import warningIcon from "./warningIcon.png";

interface Props {
  onDismiss: () => void;
}
const ResyncWarningToast = ({ onDismiss }: Props) => {
  const [showLearnMore, setShowLearnMore] = useState<boolean>(false);
  const onLearnMoreClicked = () => setShowLearnMore(true);

  return (
    <div className={style.container}>
      <div
        className={classnames({
          [style.toast]: true,
        })}
      >
        <div className={style.title}>
          <img src={warningIcon} />
          Slow Figma response times detected
        </div>
        <div className={style.description}>
          <div className={style.dismissBtn} onClick={onDismiss}>
            Dismiss for this project
          </div>
          {!showLearnMore && (
            <div className={style.learnMore} onClick={onLearnMoreClicked}>
              Learn more
            </div>
          )}
        </div>
      </div>
      {showLearnMore && (
        <div
          className={classnames({
            [style.toast]: true,
            [style.learnMoreToast]: true,
          })}
        >
          <div className={style.title}>
            This can happen when a project in Ditto is linked to a large Figma file. For faster loading times, try
            resyncing from Ditto's Figma plugin.
          </div>
        </div>
      )}
    </div>
  );
};

export default ResyncWarningToast;
