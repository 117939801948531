import { EditorContent } from "@tiptap/react";
import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { IUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import getEditorText from "./getEditorText";
import style from "./index.module.css";
import useCommentEditor from "./useCommentEditor";

export type SaveCommentCallback = (
  contentToSave: string,
  mentionedUsersInfo: IUser[],
  resetEditorState: () => void
) => Promise<void>;

interface IProps {
  mentionableUsers: IUser[];
  placeholderText: string;
  submitButtonText: string;
  saveContentCallback: SaveCommentCallback;
  onCancel?: () => void;
  shouldAutofocus?: boolean;
  alwaysShowSubmitButtons?: boolean;
  className?: string;
}

export function CommentEditor({
  mentionableUsers,
  placeholderText,
  submitButtonText,
  saveContentCallback,
  onCancel,
  shouldAutofocus = false,
  alwaysShowSubmitButtons = false,
  className,
}: IProps) {
  const [isSaving, setIsSaving] = useState(false);

  const editor = useCommentEditor(mentionableUsers, placeholderText);

  useEffect(
    function shouldAutoFocus() {
      if (shouldAutofocus) {
        editor?.commands.focus();
      }
    },
    [shouldAutofocus, editor]
  );

  const resetEditorState = useCallback(() => {
    editor?.commands?.clearContent();
    editor?.commands?.clearNodes();
    editor?.commands?.focus();
    setIsSaving(false);
  }, [editor]);

  const handleCancel = useCallback(() => {
    resetEditorState();
    onCancel && onCancel();
  }, [onCancel, resetEditorState]);

  const { contentToSave, mentionedUsersInfo } = getEditorText(editor, mentionableUsers);

  const saveContent = useCallback(async () => {
    setIsSaving(true);
    await saveContentCallback(contentToSave, mentionedUsersInfo, resetEditorState);
  }, [contentToSave, mentionedUsersInfo, resetEditorState, saveContentCallback]);

  return (
    <div className={classnames(style.commentEditorWrapper, className)} data-testid="comment-box">
      <EditorContent editor={editor} className={style.commentEditorContent} placeholder={placeholderText} />
      {(contentToSave || alwaysShowSubmitButtons) && (
        <div className={style.submitButtonsContainer}>
          <Button size="small" level="secondary" data-testid="discard-comment-button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            size="small"
            level="primary"
            data-testid="post-comment-button"
            onClick={saveContent}
            disabled={isSaving || !contentToSave}
          >
            {submitButtonText}
          </Button>
        </div>
      )}
    </div>
  );
}

export default CommentEditor;
