const ROOT = "/doc";

export default {
  get: {
    initialInfo: {
      url: (id) => `${ROOT}/initialInfo/${id}`,
    },
    allTags: {
      url: (id, matchingTags = []) => `${ROOT}/alltags/${id}?matchingTags=${matchingTags}`,
    },
    allFrameApiIDs: {
      url: (id) => `${ROOT}/allFrameApiIDs/${id}`,
    },
    comments: {
      url: (id) => `${ROOT}/comments/${id}`,
    },
    list: {
      url: `${ROOT}/list`,
    },
    getname: {
      url: (selectedDocId) => `${ROOT}/getname/${selectedDocId}`,
    },
    pageInfo: {
      url: (id, skip, limit) => `${ROOT}/pageinfo/${id}?skip=${skip}&limit=${limit}`,
    },
  },
  post: {
    renameBlock: {
      url: `${ROOT}/renameBlock`,
      body: ({ doc_ID, groupId, blockIndex, blockName }) => ({
        doc_ID,
        groupId,
        blockIndex,
        blockName,
      }),
    },
    removeBlock: {
      url: `${ROOT}/removeBlock`,
      body: ({ doc_ID, groupId, blockIndex }) => ({
        doc_ID,
        groupId,
        blockIndex,
      }),
    },
    changeBlockOrder: {
      url: `${ROOT}/changeBlockOrder`,
      body: ({ doc_ID, groupId, startIndex, endIndex }) => ({
        doc_ID,
        groupId,
        startIndex,
        endIndex,
      }),
    },
    enableDevMode: {
      url: (docID) => `${ROOT}/enableDevMode/${docID}`,
    },
    newBlock: {
      url: `${ROOT}/newBlock`,
      body: ({ doc_ID, groupId }) => ({ doc_ID, groupId }),
    },
    setNonblocksCollapsed: {
      url: `${ROOT}/setNonblocksCollapsed`,
      body: ({ projectId, groupId, isCollapsed }) => ({
        projectId,
        groupId,
        isCollapsed,
      }),
    },
    editFrameApiId: {
      url: (doc_ID) => `${ROOT}/editFrameApiId/${doc_ID}`,
      body: ({ newApiId, groupId, from }) => ({
        newApiId,
        groupId,
        from,
      }),
    },
    reorderBlock: {
      url: `${ROOT}/reorderBlock`,
      body: ({ doc_ID, groupId, blockIndex, startIndex, endIndex }) => ({
        doc_ID,
        groupId,
        blockIndex,
        startIndex,
        endIndex,
      }),
    },
    addToBlock: {
      url: `${ROOT}/addToBlock`,
      body: ({ doc_ID, groupId, blockId, compIndex, compID }) => ({
        doc_ID,
        groupId,
        blockId,
        compIndex,
        compID,
      }),
    },
    removeFromBlock: {
      url: `${ROOT}/removeFromBlock`,
      body: ({ doc_ID, groupId, blockIndex, compID }) => ({
        doc_ID,
        groupId,
        blockIndex,
        compID,
      }),
    },
    moveBetweenBlocks: {
      url: `${ROOT}/moveBetweenBlocks`,
      body: ({ doc_ID, groupId, origBlockIndex, newBlockIndex, newCompIndex, compID }) => ({
        doc_ID,
        groupId,
        origBlockIndex,
        newBlockIndex,
        newCompIndex,
        compID,
      }),
    },
    setFramePinned: {
      url: (id) => `${ROOT}/setFramePinned/${id}`,
      body: ({ frameId, is_pinned }) => ({ frameId, is_pinned }),
    },
    discardBranch: {
      url: (docId) => `${ROOT}/discardBranch/${docId}`,
    },
    mergeBranch: {
      url: (docId) => `${ROOT}/mergeBranch/${docId}`,
    },
    setFileId: {
      url: (docId) => `${ROOT}/setFileId/${docId}`,
      body: ({ fileId }) => ({ fileId }),
    },
  },
  delete: {
    delete: {
      url: (doc_ID) => `${ROOT}/delete/${doc_ID}`,
    },
  },
  put: {
    reorderGroups: {
      url: (id) => `${ROOT}/reorderGroups/${id}`,
      body: ({ groupId, startIndex, endIndex }) => ({
        groupId,
        startIndex,
        endIndex,
      }),
    },
    latestFigmaSync: {
      url: (id) => `${ROOT}/latestFigmaSync/${id}`,
    },
    unlinkGroups: {
      url: `${ROOT}/unlinkGroups`,
      body: ({ project_id, deleted_group_ids, unlinked_group_ids }) => ({
        project_id,
        deleted_group_ids,
        unlinked_group_ids,
      }),
    },
  },
};
