/**
 * Normalizes smart quotes in the given text, replacing them with standard single and double quotes.
 *
 * @param text The input text with potential smart quotes.
 * @returns The text with smart quotes normalized to standard quotes.
 */
export function normalizeQuotes(text: string) {
  return text.replace(/[\u2018\u2019\u201A\u201B\u2032]/g, "'").replace(/[\u201C\u201D\u201E\u201F\u2033]/g, '"');
}

/**
 * Performs a case-insensitive search for the specified substring within the given text,
 * after normalizing smart quotes in both the text and the substring.
 *
 * @param text The input text to search within
 * @param includes The substring to search for
 * @returns `true` if the substring is found, `false` otherwise.
 */
export function insensitiveIncludes(text: string, includes: string) {
  return normalizeQuotes(text).toLowerCase().includes(normalizeQuotes(includes).toLowerCase());
}
