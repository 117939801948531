import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import React from "react";
import { VariableHistoryItem } from "../../types";
import VariableActivity from "../VariableActivity";
import style from "./style.module.css";

interface Props {
  allHistoryFetched: boolean;
  loadingHistory: boolean;
  variableHistory: VariableHistoryItem[];
  fetchVariableHistoryNewPage: () => void;
  handleSelectVariableId: (selectedVariableId: string) => void;
}
const VariableHistory = ({
  allHistoryFetched,
  loadingHistory,
  variableHistory,
  fetchVariableHistoryNewPage,
  handleSelectVariableId,
}: Props) => {
  return (
    <div>
      <VariableActivity
        loadingHistory={loadingHistory}
        variableHistory={variableHistory}
        handleSelectVariableId={handleSelectVariableId}
      />
      {!allHistoryFetched && !loadingHistory && (
        <div className={style.loadMore} onClick={() => fetchVariableHistoryNewPage()}>
          <div>Load more</div>
          <ArrowDownwardIcon className={style.icon} />
        </div>
      )}
    </div>
  );
};

export default VariableHistory;
