import { Node } from "@tiptap/core";
import { Editor, NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import ObjectId from "bson-objectid";
import classNames from "classnames";
import { Fragment, Schema } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";
import React from "react";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ModeCommentIcon from "@mui/icons-material/ModeComment";

import { ProsemirrorNode, getEmptyBlock } from "../lib";

import style from "../../style.module.css";
import { EditorNodeNames } from "../DraftEditorNodesAndExtensions";
import { BlockNodeAttributes } from "../types";

// Two requirements for interacting with tiptap's internal
// 'draggable' engine:
// - `draggable: true` on the custom node
// - `data-drag-handle` attribute on a DOM element in the rendered
// component
//
// Reference: https://tiptap.dev/guide/node-views/react#dragging
const DragHandle = () => (
  <div
    draggable="true"
    data-drag-handle
    className={classNames([style.dragHandle, "drag-handle"])}
    contentEditable={false}
  >
    <DragIndicatorIcon className={style.dragIcon} data-drag-handle />
  </div>
);

const CommentThreadsIcon = (props: { numberOfThreads: number }) => {
  const { numberOfThreads: numberOfThreads } = props;
  if (numberOfThreads === 0) {
    return <React.Fragment />;
  }

  return (
    <div className={style.commentThreadsIcon}>
      <ModeCommentIcon /> {numberOfThreads}
    </div>
  );
};

interface DraftBlockNodeViewProps {
  node: {
    attrs: BlockNodeAttributes;
  };
}

const BlockComponent = (props: DraftBlockNodeViewProps) => {
  const { commentThreads, textItemId, isReadOnly } = props.node.attrs;

  return (
    <NodeViewWrapper className={style.block} id={textItemId}>
      {!isReadOnly && <DragHandle />}
      <NodeViewContent />
      <CommentThreadsIcon numberOfThreads={commentThreads?.length || 0} />
    </NodeViewWrapper>
  );
};

interface KeyboardShortcutArgs {
  editor: Editor;
}

function appendTextNodeToLastParagraph(paragraphs: ProsemirrorNode[], node: ProsemirrorNode, editor: Editor) {
  if (paragraphs[paragraphs.length - 1]) {
    (paragraphs[paragraphs.length - 1].content as any) = paragraphs[paragraphs.length - 1].content.append(
      Fragment.fromArray([node])
    );
  } else {
    const newParagraphNode = editor.schema.nodes.paragraph.createAndFill(null, [node]);
    if (newParagraphNode) {
      paragraphs.push(newParagraphNode);
    }
  }
}

export function generateLinesOnEnter(
  textItemNode: ProsemirrorNode,
  textItemAbsolutePosition: number,
  cursorPosition: number,
  editor: Editor
) {
  const textItemId = textItemNode.attrs.textItemId;

  // Array of paragraph nodes that continue to exist on cursor's line before pressing enter
  const currentLineParagraphs: ProsemirrorNode[] = [];

  // Array of paragraph nodes that are destined for the new line
  const newLineParagraphs: ProsemirrorNode[] = [];

  // Iterate through all of the nodes inside of the textItemNode (which is a <div>).
  // This should only be <p> elements and text nodes.
  textItemNode.descendants((node, nodePosition) => {
    const nodePositionAbsolute = nodePosition + textItemAbsolutePosition;

    const cursorInNode = nodePositionAbsolute < cursorPosition && nodePositionAbsolute + node.nodeSize > cursorPosition;

    if (node.type.name === "paragraph") {
      // If a paragraph begins after the cursor position, we know it should be
      // included in the new line being created; we can push it onto the array
      // and return false since we don't need to recurse into it.
      const paragraphStartsAfterCursor = nodePositionAbsolute >= cursorPosition;
      if (paragraphStartsAfterCursor) {
        newLineParagraphs.push(node);
        return false;
      }

      // If a paragraph begins before the cursor position and the cursor is not in it,
      // then we know its content should remain as part of the currently selected text item.
      if (!cursorInNode) {
        currentLineParagraphs.push(node);
      }

      // only recurse into a paragraph if the cursor is in it (this enables the text node processing
      // code below)
      return cursorInNode;
    }

    // Even though there can be multiple text nodes that are descendants of a textItemNode,
    // this code will only run once because the cursor can only be in a single text node.
    const isSelectedTextNode = node.type.name === "text" && cursorInNode;
    if (isSelectedTextNode) {
      const text = node.text || "";

      let textAfterCursor = "";
      let textBeforeCursor = "";

      for (let c = 0; c < text.length; c++) {
        const character = text[c];
        const characterPosition = nodePositionAbsolute + c + 1;

        if (characterPosition >= cursorPosition) {
          textAfterCursor += character;
        } else {
          textBeforeCursor += character;
        }
      }

      const endOfLine = nodePositionAbsolute + node.nodeSize + 1;
      if (textAfterCursor || cursorPosition === endOfLine) {
        const nodeAfterCusrsor = editor.schema.text(textAfterCursor, node.marks);
        // Append to last seen new line paragraph
        appendTextNodeToLastParagraph(newLineParagraphs, nodeAfterCusrsor, editor);
      }

      if (textBeforeCursor) {
        const nodeBeforeCursor = editor.schema.text(textBeforeCursor, node.marks);
        // Append to last seen current line paragraph
        appendTextNodeToLastParagraph(currentLineParagraphs, nodeBeforeCursor, editor);
      }
    }
    // Cursor is after text node
    else if (node.type.name === "text" && (node.text || "").length + nodePositionAbsolute < cursorPosition) {
      // Append to last current line seen paragraph
      appendTextNodeToLastParagraph(currentLineParagraphs, node, editor);
    }
    // Cursor is before text node
    else if (node.type.name === "text" && nodePositionAbsolute > cursorPosition) {
      // Append to last seen new line paragraph
      appendTextNodeToLastParagraph(newLineParagraphs, node, editor);
    }
  });

  const nodeCurrentLine = editor.schema.nodes.draftBlock.createAndFill(
    { ...textItemNode.attrs },
    currentLineParagraphs
  );

  const nodeNewLine = editor.schema.nodes.draftBlock.createAndFill(
    {
      textItemId: new ObjectId().toString(),
      commentThreads: [],
      // `isReadOnly` is typically set on a per-group basis,
      // so we are fairly safe in inheriting the status of
      // the existing textItemNode here.
      isReadOnly: textItemNode.attrs.isReadOnly,
    },
    newLineParagraphs
  );
  return { textItemId, nodeCurrentLine, nodeNewLine };
}

const handleEnterKey = ({ editor }: KeyboardShortcutArgs) => {
  editor.schema = editor.schema as Schema<EditorNodeNames>;

  const isSelection = editor.state.selection.$anchor.pos !== editor.state.selection.$head.pos;

  // We should support more intuitive Enter key behavior for selections at some
  // point in the future; this early return is to simplify things.
  if (isSelection) {
    return editor.commands.insertContentAt(editor.state.selection.$anchor.after(BLOCK_DEPTH), getEmptyBlock());
  }

  const cursorPosition = editor.state.selection.$anchor.pos;

  const before = editor.state.selection.$anchor.before(BLOCK_DEPTH);
  const after = editor.state.selection.$anchor.after(BLOCK_DEPTH);

  const textItemNode = editor.state.doc.nodeAt(before);
  const textItemAbsolutePosition = editor.state.doc.resolve(before).pos;
  if (!textItemNode) {
    return false;
  }
  const { textItemId, nodeCurrentLine, nodeNewLine } = generateLinesOnEnter(
    textItemNode,
    textItemAbsolutePosition,
    cursorPosition,
    editor
  );

  const tr = editor.state.tr.replaceWith(before, after, [nodeCurrentLine!, nodeNewLine!]);

  let newSelectionPosition: number | undefined = undefined;

  // The `doc` property on the transaction contains the current state
  // of the document after the replacement has been executed; we use it
  // to find the correct position of the beginning of the new text item
  // that has been inserted.
  tr.doc.descendants((node, pos) => {
    if (node.attrs.textItemId !== textItemId) {
      return false;
    }

    newSelectionPosition = pos + node.nodeSize + 2;
    return false;
  });

  if (newSelectionPosition === undefined) {
    return false;
  }

  tr.setSelection(TextSelection.create(tr.doc, newSelectionPosition));

  // apply the replacement and selection change to the editor
  editor.view.dispatch(tr);

  return true;
};

const createNewLineInBlock = ({ editor }: KeyboardShortcutArgs) => {
  editor.commands.splitBlock();
  return true;
};

export const BLOCK_NAME = "draftBlock";
export type BLOCK_NAME = typeof BLOCK_NAME;

// This constant relies on the guarantee that
// block nodes are ALWAYS rendered at a depth of 1,
// i.e. their only ancestor is the root document.
// If that guarantee is broken, this code will cease
// to work.
//
// This guarantee is currently enforced by configuring the root level
// document node to only allow `draftBlocks` as children.
export const BLOCK_DEPTH = 1;

export default Node.create({
  name: BLOCK_NAME,
  group: "block",
  content: "paragraph+",
  draggable: true,
  // When content is copied and pasted into the editor,
  // or when the value of the editor is set in state,
  // this method determines the identifier by which
  // HTML tags should be parsed into DraftBlocks.
  // (deserialization)
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  addAttributes() {
    return {
      apiId: {
        parseHTML: (element) => element.getAttribute("data-api-id"),
        renderHTML: (attributes) => ({
          "data-api-id": attributes.apiId,
        }),
      },
      textItemId: {
        parseHTML: (element) => element.getAttribute("data-text-item-id"),
        renderHTML: (attributes) => ({
          "data-text-item-id": attributes.textItemId,
        }),
      },
      commentThreads: {
        parseHTML: (element) => element.getAttribute("data-comment-threads"),
        renderHTML: (attributes) => ({
          "data-comment-threads": JSON.stringify(attributes.commentThreads),
        }),
      },
      isReadOnly: {
        parseHTML: (element) => element.getAttribute("data-is-read-only"),
        renderHTML: (attributes) => ({
          "data-is-read-only": JSON.stringify(attributes.isReadOnly),
        }),
      },
    };
  },
  // This configuration controls how this Node
  // is outputted when the value of the editor is read
  // (serialization)
  renderHTML() {
    return ["div", 0];
  },
  addKeyboardShortcuts() {
    return {
      Enter: handleEnterKey,
      "Shift-Enter": createNewLineInBlock,
    };
  },
  addNodeView() {
    return ReactNodeViewRenderer(BlockComponent);
  },
});
