import classnames from "classnames";
import React, { MouseEvent } from "react";

import style from "./style.module.css";

interface ButtonSecondaryProps {
  text: React.ReactNode;
  disabled?: boolean;
  onClick?: (e?: MouseEvent) => void;
  className?: string;
}

const ButtonSecondary = (props: ButtonSecondaryProps) => {
  const { text, disabled, onClick, className, ...otherButtonProps } = props;
  return (
    <button
      className={classnames(style.secondary, style.button, className)}
      disabled={disabled}
      onClick={onClick}
      {...otherButtonProps}
    >
      {text}
    </button>
  );
};

export default ButtonSecondary;
