import http from ".";

export default {
  actions: {
    detach: async function (textItemId, componentId) {
      return await http.post("/ws_comp/detachComp", {
        ws_comp_id: componentId,
        comp_id: textItemId,
      });
    },
  },
  get: {
    categories: {
      url: "/ws_comp/categories",
    },

    searchSuggestions: {
      url: (compId, projectId) => `/ws_comp/suggestions/${projectId}/search?compId=${compId}`,
    },
    apiIDs: {
      url: "/ws_comp/apiIDs",
    },
    names: {
      url: "/ws_comp/names",
    },
    name: {
      url: (ws_comp_id) => `/ws_comp/name/${ws_comp_id}`,
    },
    instances: {
      url: (ws_comp_id) => `/ws_comp/instances/${ws_comp_id}`,
    },
    ws_comp: {
      url: (ws_comp_id) => `/ws_comp/${ws_comp_id}`,
    },
    variants: {
      url: (ws_comp_id) => `/ws_comp/variants/${ws_comp_id}`,
    },
    populatedInstances: {
      url: (componentId) => `/ws_comp/${componentId}/populated-instances`,
    },
  },
  delete: {
    ws_comp: {
      url: (ws_comp_id) => `/ws_comp/${ws_comp_id}`,
    },
    block: {
      url: "/ws_comp/block",
      body: ({ ids }) => ({ ids }),
    },
    variant: {
      url: (ws_comp_id, variant_id) => `/ws_comp/variant/${ws_comp_id}/${variant_id}`,
    },
    detachAndDeleteAll: {
      url: "/ws_comp/detachAndDelete",
      body: ({ componentIds }) => ({ componentIds }),
    },
  },
  post: {
    attachComp: {
      url: `/ws_comp/attachComp`,
      body: ({ comp_id, ws_comp_id, assigned_comp, is_draft, from }) => ({
        comp_id,
        ws_comp_id,
        assigned_comp,
        is_draft,
        from,
      }),
    },
    attachMultiComp: {
      url: `/ws_comp/attachMultiComp`,
      body: ({ comp_ids, ws_comp_id, assigned_comp, is_draft, from, shouldComponentizeDuplicates }) => ({
        comp_ids,
        ws_comp_id,
        assigned_comp,
        is_draft,
        from,
        shouldComponentizeDuplicates,
      }),
    },
    bulkAttachMultiComp: {
      url: `/ws_comp/bulkAttachMultiComp`,
      body: ({ attach_data }) => ({ attach_data }),
    },
    bulkCreateComps: {
      url: `/ws_comp/bulkCreateComps`,
      body: ({ compCreationData, projectId }) => ({
        compCreationData,
        projectId,
      }),
    },
    createDraft: {
      url: `/ws_comp/createDraft`,
      body: ({ name, text, assignee, richText, variables, tags, notes, folderId, type, characterLimit, filter }) => ({
        name,
        text,
        assignee,
        richText,
        variables,
        tags,
        notes,
        folderId,
        type,
        characterLimit,
        filter,
      }),
    },
    createMultiComp: {
      url: `/ws_comp/createMultiComp`,
      body: ({ name, comp_ids, project_id, from, folder_id }) => ({
        name,
        comp_ids,
        project_id,
        from,
        folder_id,
      }),
    },
    createComp: {
      url: `/ws_comp/createComp`,
      body: ({ name, comp_id, isCreateAndAttach, folder_id }) => ({
        name,
        comp_id,
        isCreateAndAttach,
        folder_id,
      }),
    },
    detachComp: {
      url: `/ws_comp/detachComp`,
      body: ({ ws_comp_id, comp_id }) => ({ ws_comp_id, comp_id }),
    },
    edit: {
      url: `/ws_comp/edit`,
      body: ({ ws_comp_id, doc_id, newCompInfo, from }) => ({
        ws_comp_id,
        doc_id,
        newCompInfo,
        from,
      }),
    },
    editApiId: {
      url: (ws_comp_id) => `/ws_comp/editApiId/${ws_comp_id}`,
      body: ({ doc_ID, newApiId, from }) => ({
        doc_ID,
        newApiId,
        from,
      }),
    },
    swapComp: {
      url: `/ws_comp/swapComp`,
      body: ({ ws_comp_id, assigned_comp, old_ws_comp_id, comp_id }) => ({
        ws_comp_id,
        assigned_comp,
        old_ws_comp_id,
        comp_id,
      }),
    },
    rename: {
      url: (ws_comp_id) => `/ws_comp/rename/${ws_comp_id}`,
      body: ({ name, oldName }) => ({ name, oldName }),
    },
    restore: {
      url: (comp_id) => `/ws_comp/restore/${comp_id}`,
      body: ({
        text_before,
        text_after,
        variables_before,
        variables_after,
        rich_text_before,
        rich_text_after,
        variantId,
      }) => ({
        text_before,
        text_after,
        variables_before,
        variables_after,
        rich_text_before,
        rich_text_after,
        variantId,
      }),
    },
    variant: {
      url: (ws_comp_id) => `/ws_comp/variant/${ws_comp_id}`,
      body: ({
        variantId,
        variantText,
        variantRichText,
        variantVariables,
        variantName,
        variantFolderId,
        variantStatus,
      }) => ({
        variantId,
        variantText,
        variantRichText,
        variantVariables,
        variantName,
        variantFolderId,
        variantStatus,
      }),
    },
    validateNameExists: {
      url: "/ws_comp/validateNameExists",
      body: ({ name }) => ({ name }),
    },
    page: {
      url: `/ws_comp/page`,
      /**
       *
       * @param {*} body
       * @returns
       */
      body: ({ filter, fields }) => ({ filter, fields }),
    },
  },
  put: {
    updateMultiple: {
      url: "/ws_comp",
      body: ({ docId, assignee, ids, status, tags_added, tags_deleted, characterLimit }) => ({
        docId,
        assignee,
        ids,
        status,
        tags_added,
        tags_deleted,
        characterLimit,
      }),
    },
    renameBlocks: {
      url: "/ws_comp/rename/blocks",
      body: ({ componentEntries }) => ({ componentEntries }),
    },
    renameGroup: {
      url: "/ws_comp/rename/group",
      body: ({ compIds, newGroupName }) => ({
        compIds,
        newGroupName,
      }),
    },
    ignoreSuggestion: {
      url: (projectId, suggestionId) => `/ws_comp/suggestions/${projectId}/ignore/${suggestionId}`,
      body: ({ ignore }) => ({ ignore }),
    },
    ignoreMultiSuggestions: {
      url: (projectId) => `/ws_comp/suggestions/${projectId}/multiIgnore`,
      body: ({ suggestionIds, ignore }) => ({ suggestionIds, ignore }),
    },
  },
  patch: {
    variant: {
      url: (ws_comp_id) => `/ws_comp/variant/${ws_comp_id}`,
      body: ({ variantId, updatedVariantInfo }) => ({
        variantId,
        updatedVariantInfo,
      }),
    },
  },
};
