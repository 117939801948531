import React, { useState } from "react";
import ModalFooter from "react-bootstrap/ModalFooter";
import { userHasPermission } from "../../../../../shared/frontend/userPermissionContext";
import FolderSelectCreatable from "../../../../components/FolderSelect/Creatable";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ModalBase, { ModalBody } from "../../../../components/shared/ModalBase";
import style from "./style.module.css";

const MoveToFolderModal = ({ variants, variantFolders, onHide, onSubmit, selectedFolderId }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const title = variants.length > 1 ? "Move Variants" : "Move Variant";
  const buttonText = variants.length > 1 ? "Move Variants" : "Move Variant";
  const modalText =
    variants.length > 1 ? (
      <span>
        Please select the folder where you want to move these <b>{variants.length}</b> variants.
      </span>
    ) : (
      <span>Please select the folder where you want to move this variant.</span>
    );

  const onChange = (option) => {
    if (!option) {
      return;
    }

    if (option.__isNew__) {
      setSelectedFolder({
        _id: "__new__",
        name: option.label,
      });
      return;
    }

    setSelectedFolder({
      _id: option.value,
      name: option.label,
    });
  };

  const canCreateFolder = userHasPermission("variant_folder:create");

  return (
    <ModalBase onHide={onHide} title={title}>
      <ModalBody>
        <div className={style.text}>{modalText}</div>
        <FolderSelectCreatable
          folders={[{ name: "None", _id: "none" }, ...variantFolders]}
          onChange={onChange}
          currentFolderId={selectedFolderId || ""}
          value={selectedFolder ? { value: selectedFolder._id, label: selectedFolder.name } : null}
          canCreateFolder={canCreateFolder}
        />
      </ModalBody>
      <ModalFooter className={style.buttons}>
        <ButtonPrimary
          onClick={() => {
            onSubmit(selectedFolder);
          }}
          text={buttonText}
          disabled={!selectedFolder}
        />
      </ModalFooter>
    </ModalBase>
  );
};

export default MoveToFolderModal;
