const ROOT = "/invite";

export default {
  get: {
    index: {
      url: ROOT,
    },
    folder: {
      url: (folderId) => `${ROOT}/folder/${folderId}`,
    },
  },
  post: {
    create: {
      url: ROOT,
      body: ({ emails, role, permissionGroups, type, docID, docName, message }) => ({
        emails,
        role,
        permissionGroups,
        type,
        docID,
        docName,
        message,
      }),
    },
    createNew: {
      url: `${ROOT}`,
      body: ({ users, permissionGroups, context, message }) => ({
        users,
        permissionGroups,
        context,
        message,
      }),
    },
    project: {
      url: (projectId) => `${ROOT}/project/${projectId}`,
      body: ({ invitations, message }) => ({ invitations, message }),
    },
    folder: {
      url: (folderId) => `${ROOT}/folder/${folderId}`,
      body: ({ invitations }) => ({ invitations }),
    },
    removeFolderUser: {
      url: (folderId) => `${ROOT}/folder/${folderId}/remove`,
      body: ({ type, user }) => ({ type, user }),
    },
    resend: {
      url: `${ROOT}/resend`,
      body: ({ email }) => ({ email }),
    },
  },
  delete: {
    revoke: {
      url: `${ROOT}/revoke`,
      body: ({ email }) => ({ email }),
    },
  },
};
