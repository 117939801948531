import { NotificationContext } from "@/store/notificationContext";
import { useWorkspace } from "@/store/workspaceContext";
import { ALL_PAGE_ID } from "@/views/Project/state/usePageState";
import * as SegmentEvents from "@shared/segment-event-names";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";

export function useNotificationsPanel() {
  const history = useHistory();
  const segment = useSegment();
  const { globalNotificationCallbacks } = useWorkspace();
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const { unreadNotifs, readNotifs, fetchNotifications } = useContext(NotificationContext);

  const goToDocComment = (projectId: string, compId?: string, commentThreadId?: string) => {
    // do single page redirect if the url has the project id
    if (history.location.pathname.includes(projectId)) {
      history.push({
        pathname: routes.nonNavRoutes.project.getPath(projectId),
        state: { commentThreadId, compId },
      });
      return;
    }

    // We have to do this because the selectedComp state on the project page is a mess as it tries to parse
    // url state and query params to determine the selected component
    // Ideally we wouldn't need to do this, but for now to fix a bug where clicking on a notification
    // doesn't select the component, we have to do this
    if (commentThreadId) {
      window.location.replace(
        `${routes.nonNavRoutes.project.getPath(projectId)}/page/${ALL_PAGE_ID}/${compId}/${commentThreadId}`
      );
    } else {
      window.location.replace(`${routes.nonNavRoutes.project.getPath(projectId)}/page/${ALL_PAGE_ID}/${compId}`);
    }
  };

  const goToLibraryComment = (wsCompId: string, commentThreadId?: string) => {
    if (globalNotificationCallbacks?.current?.length) {
      globalNotificationCallbacks.current.forEach((cb) => cb(wsCompId, commentThreadId));
    } else {
      history.push({
        pathname: `/components/${wsCompId}`,
        state: { commentThreadId, wsCompId },
      });
    }
  };

  const markAllNotifsAsRead = async () => {
    if (unreadNotifs.length === 0) return;
    const { url } = API.user.put.notifsMarkAllAsRead;
    await http.put(url);
    fetchNotifications();
    segment.track({ event: SegmentEvents.NOTIFICATIONS_MARKED_AS_READ });
  };

  const markNotifAsRead = async (notificationId: string) => {
    const { url } = API.user.put.notifMarkAsRead;
    await http.put(url(notificationId));
    fetchNotifications();
    segment.track({ event: "Single Notification Marked As Read" });
  };

  useEffect(function fetchNotificationsOnMount() {
    fetchNotifications();
  }, []);

  return {
    goToDocComment,
    goToLibraryComment,
    markAllNotifsAsRead,
    markNotifAsRead,
    checkDetailPanelChanges,
    readNotifs,
    unreadNotifs,
  };
}
