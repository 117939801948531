import React from "react";
import Badge from "../../atoms/Badge";
import { Tooltip } from "../Tooltip";
import style from "./index.module.css";

export type ChangeItemBadgeData = {
  type: "textItem" | "instance";
  count: number;
};

interface IProps {
  type: "textItem" | "instance";
  count: number;
}

export function ChangeItemCountBadge({ type, count }: IProps) {
  const getTooltipContent = () => {
    const item = type === "textItem" ? "text item" : "instance";
    const suffix = count === 1 ? "" : "s";
    return `${count} ${item}${suffix} updated`;
  };

  return (
    <Tooltip side="left" type="invert" content={getTooltipContent()}>
      <Badge
        className={style.ChangeItemCountBadge}
        color={type === "textItem" ? "neutral" : "blue"}
        borderRadius="md"
        size="micro"
        data-testid="change-item-count-badge"
      >
        {count}
      </Badge>
    </Tooltip>
  );
}

export default ChangeItemCountBadge;
