import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import style from "./style.module.css";

import classNames from "classnames";

interface Props {
  index: number;
  numItems: number;
  decrementIndex: (e: React.SyntheticEvent) => void;
  incrementIndex: (e: React.SyntheticEvent) => void;
}
const ComponentModalPagination = ({ index, numItems, decrementIndex, incrementIndex }: Props) => (
  <div
    className={classNames({
      [style.wrapper]: true,
    })}
  >
    <ChevronLeftIcon
      className={classNames({
        [style.icon]: true,
        [style.btnDisabled]: index === 0,
      })}
      onClick={decrementIndex}
    />
    <div>
      {index + 1} of {numItems}
    </div>
    <ChevronRightIcon
      className={classNames({
        [style.icon]: true,
        [style.btnDisabled]: index === numItems - 1,
      })}
      onClick={incrementIndex}
    />
  </div>
);

export default ComponentModalPagination;
