import { UnauthenticatedAuthState } from "@/hooks/auth";
import React, { createContext, useContext } from "react";

const UnauthenticatedAuthContext = createContext({} as UnauthenticatedAuthState);

export function useUnauthenticatedAuth() {
  const context = useContext(UnauthenticatedAuthContext);
  if (!context) {
    throw new Error(
      "useUnauthenticatedAuth was null, are you sure you used it in a state that is guarenteed to only exist when the user is unauthenticated?"
    );
  }

  return context;
}

interface IUnauthenticatedAuthProvider {
  children: React.ReactNode;
  authState: UnauthenticatedAuthState;
}

export const UnauthenticatedAuthProvider = ({ children, authState }: IUnauthenticatedAuthProvider) => {
  return <UnauthenticatedAuthContext.Provider value={authState}>{children}</UnauthenticatedAuthContext.Provider>;
};
