import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/react";
import Add from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { Option } from "../MultiCombobox";
import style from "./index.module.css";

export interface ComboboxOption {
  value: string;
  label: string;
  /**
   * Whether the option is a draft item created with the "Create item" combobox functionality.
   */
  isDraftItem?: boolean;
}

interface IBaseComboboxProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The options in the combobox dropdown.
   */
  options: ComboboxOption[];
  /**
   * The selected item from the options list.
   */
  selectedItem: ComboboxOption | null;
  /**
   * Callback function to set the selected item.
   */
  setSelectedItem: (item: ComboboxOption | null) => void;
  /**
   * Placeholder text for the combobox input.
   */
  placeholder?: string;
  /**
   * Text to display when creating a new item.
   */
  createNewText?: string;
  /**
   * Callback function that runs when the user creates a new item in the combobox.
   */
  onCreateNew: (value: string) => void;
}

export function BaseCombobox(props: IBaseComboboxProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [comboboxWidth, setComboboxWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const { selectedItem, setSelectedItem } = props;

  // Update query when selectedItem changes
  useEffect(() => {
    setQuery(selectedItem?.label || "");
  }, [selectedItem]);

  useEffect(function calculateWrapperDimensions() {
    if (!wrapperRef.current) return;
    const rect = wrapperRef.current.getBoundingClientRect();
    wrapperRef.current.style.setProperty("--input-wrapper-height", `${rect.height}px`);
    wrapperRef.current.style.setProperty("--input-wrapper-width", `${rect.width}px`);
  });

  useEffect(function recalculateOptionsWidth() {
    if (wrapperRef.current) {
      const integerWidth = Math.ceil(wrapperRef.current.offsetWidth);
      setComboboxWidth(integerWidth);
    }
  }, []);

  const filteredOptions = useMemo(
    () =>
      [
        // always list the selected item at the top of the list
        ...props.options.filter((item) => selectedItem?.value === item.value),
        ...props.options
          .filter(
            (item) =>
              selectedItem?.value !== item.value &&
              // filter items that don't match the query
              item.label.toLocaleLowerCase().includes(query.toLocaleLowerCase())
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      ] as ComboboxOption[],
    [query, props.options, selectedItem]
  );

  const showCreateNew = query !== "" && !filteredOptions.some((item) => item.label === query);
  const showOptions = isOpen && (filteredOptions.length > 0 || showCreateNew);

  function onChangeValue(value: ComboboxOption | null) {
    setSelectedItem(value);
    setQuery(value?.label || ""); // Immediately set the query to the new value's label
    setIsOpen(false);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    // if the user presses enter and there are no suggestions, create a new item
    if (event.key === "Enter" && !filteredOptions.some((item) => item.value === query)) {
      event.preventDefault(); // Prevent the default Combobox on enter behavior

      const existingItem = props.options.find((item) => item.value === query);
      if (existingItem) {
        // Prevent creating an option that already exists
        onChangeValue(existingItem);
      } else {
        onCreateNew(query);
      }
    }
  }

  /**
   * Callback function that runs when the user creates a new item in the combobox.
   * @param value - The value of the new item (the current text in the combobox input).
   */
  function onCreateNew(value: string) {
    props.onCreateNew(value);

    // Set isDraftItem flag to indicate this is an unsaved combobox option
    const newValue = { value, label: value, isDraftItem: true };
    onChangeValue(newValue);
  }

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.target.value);
      setIsOpen(true);
    },
    [setQuery]
  );

  function handleFocus() {
    if (!selectedItem) setQuery(""); // Clear the query when focusing
  }

  function handleBlur() {
    setIsOpen(false);
  }

  function handleInputClick() {
    if (filteredOptions.length > 0) setIsOpen(true);
  }

  return (
    <Combobox
      immediate
      by="value"
      value={selectedItem}
      onChange={onChangeValue}
      onClose={() => {
        setQuery(selectedItem?.label || "");
      }}
    >
      <div
        className={classNames(style.combobox, {
          // [style[`size-${props.size}`]]: props.size,
          [style.open]: isOpen,
        })}
        style={{ width: comboboxWidth ? `${comboboxWidth}px` : undefined }}
        ref={wrapperRef}
      >
        <div className={style.selectedOptions}>
          <ComboboxInput
            placeholder={props.placeholder}
            onKeyDown={handleKeyDown}
            onChange={onInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleInputClick}
            className={style.input}
            value={query}
            ref={inputRef}
          />
          {!isOpen && <Icon Icon={<KeyboardArrowDownIcon />} size="xs" className={style.dropdownIcon} />}
        </div>

        {showOptions && (
          <ComboboxOptions
            // set static to true in order to control combobox open/close behavior
            static={true}
            modal={false}
            anchor={false}
            style={{ width: comboboxWidth }}
            className={style.optionsList}
            ref={optionsListRef}
          >
            {filteredOptions.length > 0 && (
              <div className={style.filteredOptions}>
                {filteredOptions.map((option) => (
                  <Option
                    key={`option-$${option.value}`}
                    className={classNames(style.option)}
                    innerClassName={style.optionInner}
                    label={option.label}
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <Icon Icon={<CheckIcon />} size="xxs" className={classNames({ [style.hidden]: !selected })} />
                        <Text>{option.label}</Text>
                      </>
                    )}
                  </Option>
                ))}
              </div>
            )}

            {/* Create new option */}
            {showCreateNew && (
              <div className={style.createNewOptionWrapper}>
                <div
                  key="create-new"
                  className={classNames(style.option, style.createNew)}
                  onClick={() => onCreateNew(query)}
                >
                  <Icon Icon={<Add />} size="xs" className={style.createNewIcon} />
                  <Text className={style.createNewText}>
                    <span className={style.labelText}>{props.createNewText || "Create: "}</span>
                    <span className={style.queryText}>"{query}"</span>
                  </Text>
                </div>
              </div>
            )}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}

export default BaseCombobox;
