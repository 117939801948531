import React from "react";
const CategoryOutlineRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="m321.233-590.616 127.844-209.536q5.616-9.231 13.75-13.154 8.135-3.923 17.173-3.923 9.038 0 17.173 3.923 8.134 3.923 13.75 13.154l127.844 209.536q5.616 9.038 5.616 18.981 0 9.942-4.616 18.172-4.615 8.231-12.692 13.154t-18.846 4.923H351.771q-10.813 0-18.923-4.971-8.11-4.971-12.615-13.106-4.616-8.106-4.616-18.014t5.616-19.139ZM700-95.386q-68.846 0-116.73-47.884T535.386-260q0-68.846 47.884-116.73T700-424.614q68.846 0 116.73 47.884T864.614-260q0 68.846-47.884 116.73T700-95.386Zm-564.614-56.166v-216.999q0-15.371 10.398-25.717 10.397-10.346 25.768-10.346h216.999q15.371 0 25.717 10.398 10.346 10.397 10.346 25.768v216.999q0 15.371-10.398 25.717-10.397 10.346-25.768 10.346H171.449q-15.371 0-25.717-10.398-10.346-10.397-10.346-25.768Zm564.603-3.832q43.934 0 74.28-30.336 30.347-30.335 30.347-74.269 0-43.934-30.336-74.28-30.335-30.347-74.269-30.347-43.934 0-74.28 30.336-30.347 30.335-30.347 74.269 0 43.934 30.336 74.28 30.335 30.347 74.269 30.347Zm-504.605-20h169.232v-169.232H195.384v169.232Zm198.154-420h172.924L480-734.462l-86.462 139.078Zm86.462 0ZM364.616-344.616ZM700-260Z" />
    </svg>
  );
};

export default CategoryOutlineRounded;
