import FlagIcon from "@mui/icons-material/Flag";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CompactLabel from "../CompactLabel";

import style from "./style.module.css";

interface StatusProps {
  className?: string;
  disabled?: boolean;
  showDefaultOption?: boolean;
  status: string;
  styleAsDropdown?: boolean;
  handleStatusChange: (status: string) => void;
  onRemoveFilter?: () => void;
}

const StatusSelect = (props: StatusProps) => {
  const { status, handleStatusChange, disabled, className, onRemoveFilter } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const options = {
    DEFAULT: { label: "Set to current status of base text", showInList: false },
    NONE: { label: "None", shortLabel: "No Status", showInList: true },
    WIP: { label: "Work in Progress", shortLabel: "WIP", showInList: true },
    REVIEW: { label: "Ready for Review", shortLabel: "Review", showInList: true },
    FINAL: { label: "Final", showInList: true },
    MIXED: { label: "Mixed statuses", showInList: false },
  };

  if (props.showDefaultOption) {
    options.DEFAULT = { label: "Set to current status of base text", showInList: true };
  }

  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(
    function deactiveDropdownOnClick() {
      if (!dropdownActive) {
        return;
      }
      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setDropdownActive(false);
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [dropdownActive, setDropdownActive]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(style.editStatus, className, {
        [style.styleAsDropdown]: props.styleAsDropdown,
      })}
    >
      {!props.styleAsDropdown && <CompactLabel Icon={FlagIcon} text="Status" />}

      <div
        className={classNames(style.statusSelector, {
          [style.dropdownActive]: dropdownActive,
          [style.disabled]: disabled || false,
        })}
        onClick={() => !disabled && setDropdownActive(!dropdownActive)}
        onBlur={() => !disabled && setDropdownActive(false)}
        role="select"
        data-testid="status-select"
      >
        <span
          className={classNames({
            [style.selectedOption]: true,
            [style.noStatus]: status === "NONE" && props.styleAsDropdown,
            [style.wip]: status == "WIP",
            [style.review]: status == "REVIEW",
            [style.final]: status == "FINAL",
            [style.dev]: status == "DEV",
          })}
        >
          {props.styleAsDropdown ? (
            <div className={style.labelWrapper}>
              <FlagIcon className={style.icon} />
              {options[status]?.shortLabel || options[status]?.label || "Status"}
            </div>
          ) : (
            <div className={style.labelWrapper}>
              {status !== "DEFAULT" && <div className={style.dot} />}
              {options[status]?.label}
            </div>
          )}
          <KeyboardArrowDownIcon className={style.downArrowIcon} />
        </span>

        <div
          className={classNames(style.statusSelectorDropdown, {
            [style.dropdownActive]: dropdownActive,
          })}
        >
          {Object.entries(options)
            .filter(([, { showInList }]) => showInList)
            .map(([value, { label }], index) => (
              <div
                key={index}
                className={classNames({
                  [style.dropdownItem]: true,
                  [style.wip]: value == "WIP",
                  [style.review]: value == "REVIEW",
                  [style.final]: value == "FINAL",
                  [style.dev]: value == "DEV",
                })}
                onClick={() => handleStatusChange(value)}
                data-testid={`status-select-${value}`}
              >
                {value !== "DEFAULT" && <div className={style.dot} />}
                {label}
              </div>
            ))}
          {onRemoveFilter && (
            <div className={classNames(style.dropdownItem, style.removeFilter)} onClick={onRemoveFilter}>
              <DeleteIcon className={style.deleteIcon} />
              Remove Filter
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusSelect;
