import { LibraryNavState } from "@/views/Components/components/comp-library-nav/libraryNavState";
import { IComponentRename } from "@shared/ditto-events";
import {
  ComponentPair,
  getBlockTreeItem,
  getComponentTreeItem,
  getGroupTreeItem,
  getNameParts,
  getRenameRecord,
} from "@shared/lib/components";
import { IBlockTreeItem, IComponentTreeItem, IFTreeItem, IGroupTreeItem } from "@shared/types/http/Component";

// MARK: Utility functions

export function mapSetToObjList(map: Map<string, Set<string>>): Record<string, string[]> {
  const entries = Array.from(map.entries()).map(([key, value]) => [key, Array.from(value)]);
  return Object.fromEntries(entries);
}

export function getRenamesMapFromTuples(tuples: string[][]) {
  return tuples.reduce((acc, [componentId, oldName, newName]) => {
    acc[componentId] = getRenameRecord(componentId, oldName, newName);
    return acc;
  }, {} as Record<string, IComponentRename>);
}

const nodeTypeOrder = {
  loading: -1,
  block: 0,
  group: 1,
  component: 2,
};

export function treeItemSort(a: IFTreeItem, b: IFTreeItem) {
  if (a.type !== b.type) return nodeTypeOrder[a.type] - nodeTypeOrder[b.type];
  return a.name.localeCompare(b.name, undefined, { numeric: true });
}

export function addToMapSet<T>(map: Map<string, Set<T>>, key: string, value: T) {
  if (!map.has(key)) map.set(key, new Set<T>());
  map.get(key)!.add(value);
}

// MARK: Test Helpers
/**
 * given a list of full component names and ids, generates a tree state
 * component names must be provided IN ORDER they would appear in the tree, e.g.
 *   - "group1/block1/component1"
 *   - "group1/component2"
 *   - "component3"
 */
export function generateTreeItems(strings: ComponentPair[], addLoading = false) {
  const treeItems: (IComponentTreeItem | IGroupTreeItem)[] = [];

  strings.forEach((pair) => {
    const [groupName, blockName, componentName] = getNameParts(pair.name);

    const componentItem: IComponentTreeItem = getComponentTreeItem(pair._id, componentName);

    if (groupName) {
      const groupItem = treeItems.find((item) => item.name === groupName) as IGroupTreeItem | undefined;
      if (!groupItem) {
        const newGroupItem = getGroupTreeItem(groupName, []);
        if (blockName) {
          const newBlockItem: IBlockTreeItem = getBlockTreeItem(blockName, groupName, [componentItem]);
          // if (addLoading) newBlockItem.children!.push(getLoadingTreeItem());
          newGroupItem.children.push(newBlockItem);
        } else {
          newGroupItem.children.push(componentItem);
        }

        // if (addLoading) newGroupItem.children!.push(getLoadingTreeItem());
        treeItems.push(newGroupItem);
      } else {
        if (blockName) {
          const blockItem = groupItem.children!.find((item) => item.name === blockName) as IBlockTreeItem | undefined;
          if (!blockItem) {
            const newBlockItem = getBlockTreeItem(blockName, groupName, [componentItem]);
            groupItem.children.push(newBlockItem);
          } else {
            blockItem.children.push(componentItem);
          }
        } else {
          groupItem.children.push(componentItem);
        }
      }
    } else {
      treeItems.push(componentItem);
    }
    // if (addLoading) treeItems.push(getLoadingTreeItem());
  });

  return treeItems;
}

// NOTE: these must be listed in the proper order they would appear in the a real tree, i.e. blocks > groups > components
export const mockComponents = [
  { _id: "1", name: "group1/block1/component1" },
  { _id: "2", name: "group1/block1/component2" },
  { _id: "3", name: "group1/block2/component3" },
  { _id: "4", name: "group1/block2/component4" },
  { _id: "5", name: "group1/component5" },
  { _id: "6", name: "group2/block1/component6" },
  { _id: "7", name: "group2/block1/component7" },
  { _id: "8", name: "group2/component8" },
  { _id: "9", name: "component9" },
];

export const mockTreeItems = generateTreeItems(mockComponents);

export const mockTreeState: LibraryNavState = {
  treeItems: mockTreeItems,
};
