import type { WebSocket } from "ws";

export interface WebSocketClient {
  userId: string | null;
  workspaceId: string | null;
  ws: WebSocket;
  docSubscription: string | null;
  workspaceCompSubscription: string | null;
  figmaCorrelationKey: string | null;
  figmaCorrelationTimeout: Date | null;
}

export const WEBSOCKET_URL =
  process.env.NODE_ENV === "development"
    ? `ws://localhost:${process.env.PORT || 4000}`
    : process.env.WEBSOCKET_URL || "";
