import classNames from "classnames";
import React from "react";

import style from "./style.module.css";

interface Props {
  count: number;
  className?: string;
}

const RoundedCount = (props: Props) => {
  return <div className={classNames(style.count, props.className)}>{props.count}</div>;
};

export default RoundedCount;
