import React, { useEffect, useState } from "react";

interface IProps {
  intervalMs?: number;
}

const DEFAULT_INTERVAL = 300;

const getNextValue = (value: string) => {
  if (value === "...") {
    return "";
  }

  if (value === "") {
    return ".";
  }

  if (value === ".") {
    return "..";
  }

  return "...";
};

export default function AnimatedEllipsis(props: IProps) {
  const [value, setValue] = useState("...");

  useEffect(() => {
    const interval = setInterval(() => setValue((value) => getNextValue(value)), props.intervalMs || DEFAULT_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [props.intervalMs, setValue]);

  return <span>{value}</span>;
}
