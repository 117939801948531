import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import * as SegmentEvents from "@shared/segment-event-names";
import React, { useEffect, useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { INVITE_ONLY_UPSELL_TEXT } from "../../../util/folder";
import useSegment from "../../hooks/useSegment";
import ButtonPrimary from "../button/buttonprimary";
import InviteOnlyToggle from "../permissions/InviteOnlyToggle";
import style from "./style.module.css";

const MAX_FOLDER_NAME_LENGTH = 60;

interface Props {
  inviteOnlyFoldersEnabled: boolean;
  onHide: () => void;
  folderNames: string[];
  onCreateNewFolder: (folderName: string, inviteOnly: boolean) => Promise<void>;
}
const NewFolderModal = ({ inviteOnlyFoldersEnabled, onHide, folderNames, onCreateNewFolder }: Props) => {
  const segment = useSegment();

  const [name, setName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [inviteOnly, setInviteOnly] = useState<boolean>(false);
  const { workspaceInfo } = useWorkspace();

  const nameRef = useRef<any>();

  const tag = useMemo(
    () =>
      inviteOnlyFoldersEnabled
        ? undefined
        : {
            label: INVITE_ONLY_UPSELL_TEXT,
            url: "https://dittowords.com/pricing",
          },
    [inviteOnlyFoldersEnabled]
  );

  const isValidFolderName = (input: string) => {
    return !folderNames.includes(input);
  };

  const onInviteToggle = () => setInviteOnly((prev) => !prev);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value.substring(0, MAX_FOLDER_NAME_LENGTH);
    if (!isValidFolderName(text)) {
      setError("This folder name already exists");
    } else {
      setError(null);
    }
    setName(text);
  };

  const checkPressEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !error) {
      createNewFolder();
    }
  };

  const createNewFolder = async () => {
    setIsSaving(true);
    await onCreateNewFolder(name, inviteOnly);
    setIsSaving(false);
  };

  useEffect(() => {
    if (nameRef?.current) nameRef.current.focus();
  }, []);

  const isPermissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Create a New Folder</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div className={style.container}>
            <label className={style.label}>Folder name</label>
            <input
              type="text"
              ref={nameRef}
              placeholder="Enter a folder name"
              autoFocus
              className={error && style.errorInput}
              spellCheck="false"
              value={name}
              onChange={onChange}
              onKeyPress={checkPressEnter}
            />
            {error && <div className={style.errorMsg}>{error}</div>}
          </div>
          {!isPermissionGroupsEnabled && (
            <InviteOnlyToggle
              value={inviteOnly}
              onToggle={onInviteToggle}
              disabled={!inviteOnlyFoldersEnabled}
              tag={tag}
              onTagClick={() => {
                segment.track({
                  event: SegmentEvents.INVITE_ONLY_UPSELL_BADGE_CLICKED,
                });
              }}
              description="Toggle on to make this folder and all of its projects viewable only by invitation."
            />
          )}

          <span className={style.form}>
            <ButtonPrimary
              text={!isSaving ? "Create" : "Creating..."}
              disabled={!name || Boolean(error) || isSaving}
              onClick={createNewFolder}
            />
          </span>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default NewFolderModal;
