const ROOT = "/jobs";

export default {
  get: {
    previewId: {
      url: (jobId) => `${ROOT}/previews/${jobId}`,
    },
    pollResync: {
      url: (jobId) => `${ROOT}/resync/${jobId}`,
    },
    pollImport: {
      url: (jobId) => `${ROOT}/imports/${jobId}`,
    },
  },
  post: {
    componentSuggestions: {
      url: `${ROOT}/component-suggestions`,
      body: ({ projectId }) => ({ projectId }),
    },
    imports: {
      url: `${ROOT}/imports`,
      body: ({ folder_id, figma_file_ID, figma_branch_id, selected_pages, selected_frames, frameIdsByPageId }) => ({
        folder_id,
        figma_file_ID,
        figma_branch_id,
        selected_pages,
        selected_frames,
        frameIdsByPageId,
      }),
    },
  },
};
