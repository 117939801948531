import type { IntegrationMessagePayload } from "@shared/types/SlackNotifications";

const truncate = (text?: string) => {
  if (!text) return "";
  if (text.length > 100) {
    return text.substring(0, 100) + "...";
  }
  return text;
};

type Status = "NONE" | "WIP" | "REVIEW" | "FINAL" | null;
const statusInfo = (status: Status): { color: string; fullText: string } => {
  switch (status) {
    case "NONE":
      return { color: "#CCCCCC", fullText: "None" };
    case null:
      return { color: "#CCCCCC", fullText: "None" };
    case "WIP":
      return { color: "#bf2d0e", fullText: "Work in Progress" };
    case "REVIEW":
      return { color: "#b29221", fullText: "Ready for Review" };
    case "FINAL":
      return { color: "#418916", fullText: "Final" };
  }
};

const componentEdited = (
  textBefore: string,
  textAfter: string,
  user: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean,
  number?: number
): IntegrationMessagePayload => {
  const isMulti = number && number > 1;
  const text = `*${user}* edited ${
    isWsComp
      ? // we can't have multiple ws-comps edited at the same time
        `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
      : `${
          isMulti ? number + " text items" : "a text item"
        } in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`
  }`;

  const attachments = JSON.stringify([
    {
      color: "#C41111",
      text: `*Before:* "${textBefore}"`,
    },
    {
      color: "#418916",
      text: `*After:* "${textAfter}"`,
    },
  ]);

  return {
    text,
    attachments,
    type: "textEdited",
  };
};

const componentAssigned = (
  actorName: string,
  assigneeName: string,
  componentText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean,
  number?: number
): IntegrationMessagePayload => {
  const isMulti = number && number > 1;
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* assigned *${assigneeName}* to ${
          isWsComp
            ? isMulti
              ? `${number} components in the *<https://app.dittowords.com/components|Component Library>*`
              : `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
            : `${
                isMulti ? number + " text items" : "a text item"
              } in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`
        }`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#cccccc",
      // componentText will be an empty string if there were multiple components assigned
      text: `*Text Item:* ${truncate(componentText)}`,
    },
  ]);

  return {
    blocks,
    attachments: number || isWsComp ? undefined : attachments,
    type: "textAssigned",
  };
};

const componentUnassigned = (
  actorName: string,
  componentText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean,
  number?: number
): IntegrationMessagePayload => {
  const isMulti = number && number > 1;
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* unassigned ${
          isWsComp
            ? isMulti
              ? `${number} components in the *<https://app.dittowords.com/components|Component Library>*`
              : `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
            : `${
                isMulti ? number + " text items" : "a text item"
              } in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`
        }`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#cccccc",
      // componentText will be an empty string if there were multiple components assigned
      text: `*Text Item:* ${truncate(componentText)}`,
    },
  ]);

  return {
    blocks,
    attachments: number || isWsComp ? undefined : attachments,
    type: "textAssigned",
  };
};

const componentStatusChanged = (
  actorName: string,
  status: Status,
  componentText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean,
  number?: number
): IntegrationMessagePayload => {
  const isMulti = number && number > 1;
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* changed the status of a text item to *${
          statusInfo(status).fullText
        }* in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`,
      },
    },
  ]);

  const textPreviewAttachments = JSON.stringify([
    {
      color: statusInfo(status).color,
      text: `*Text Item:* ${truncate(componentText)}`,
    },
  ]);

  const multiTextItemAttachments = JSON.stringify([
    {
      color: statusInfo(status).color,
      text: `*${actorName}* changed the status of ${number} text items to *${
        statusInfo(status).fullText
      }* in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`,
    },
  ]);

  const wsCompAttachments = JSON.stringify([
    {
      color: statusInfo(status).color,
      text: `*${actorName}* changed the status of ${
        isMulti
          ? `${number} components in the *<https://app.dittowords.com/components|Component Library>*`
          : `*<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
      } to *${statusInfo(status).fullText}*`,
    },
  ]);

  if (isWsComp) return { attachments: wsCompAttachments, type: "statusChanged" };
  if (isMulti) return { attachments: multiTextItemAttachments, type: "statusChanged" };
  return { blocks, attachments: textPreviewAttachments, type: "statusChanged" };
};

const commentPosted = (
  actorName: string,
  comment: string,
  componentText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* commented on ${
          isWsComp
            ? `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*: "${comment}"`
            : `a text item in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*: "${comment}"`
        }`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#cccccc",
      text: `*Text Item:* ${truncate(componentText)}`,
    },
  ]);
  return {
    blocks,
    attachments: isWsComp ? undefined : attachments,
    type: "commentActivity",
  };
};

const replyPosted = (
  actorName: string,
  comment: string,
  componentText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* replied to a comment on ${
          isWsComp
            ? `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*: "${comment}"`
            : `a text item in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*: "${comment}"`
        }`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#cccccc",
      text: `*Text Item:* ${truncate(componentText)}`,
    },
  ]);
  return {
    blocks,
    attachments: isWsComp ? undefined : attachments,
    type: "commentActivity",
  };
};

const editSuggested = (
  actorName: string,
  currentText: string,
  suggestedText: string,
  targetId: string,
  targetName: string,
  isWsComp?: boolean
): IntegrationMessagePayload => {
  const text = `*${actorName}* suggested a change to ${
    isWsComp
      ? `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
      : `a text item in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`
  }`;

  const attachments = JSON.stringify([
    {
      color: "#C41111",
      text: `*Text Item:* "${currentText}"`,
    },
    {
      color: "#418916",
      text: `*Suggested Edit:* "${suggestedText}"`,
    },
  ]);

  return {
    text,
    attachments,
    type: "commentActivity",
  };
};

const componentAttached = (
  actorName: string,
  textBefore: string,
  textAfter: string,
  wsCompId: string,
  wsCompName: string,
  projectId: string,
  projectName: string
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* attached *<https://app.dittowords.com/components/${wsCompId}|*${wsCompName}*>* in *<https://app.dittowords.com/projects/${projectId}|*${projectName}*>*`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#C41111",
      text: `*Before:* "${textBefore}"`,
    },
    {
      color: "#418916",
      text: `*Component Text:* "${textAfter}"`,
    },
  ]);

  return { blocks, attachments, type: "componentAttached" };
};

const multiComponentsAttached = (
  actorName: string,
  wsCompId: string,
  wsCompName: string,
  projectId: string,
  projectName: string
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* attached *<https://app.dittowords.com/components/${wsCompId}|*${wsCompName}*>* to multiple text items in *<https://app.dittowords.com/projects/${projectId}|*${projectName}*>*`,
      },
    },
  ]);

  return { blocks, type: "componentAttached" };
};

const multiComponentsAttachedNoDoc = (params: {
  actorName: string;
  wsCompId: string;
  wsCompName: string;
}): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${params.actorName}* attached *<https://app.dittowords.com/components/${params.wsCompId}|*${params.wsCompName}*>* to multiple text items*>*`,
      },
    },
  ]);

  return { blocks, type: "componentAttached" };
};

const threadResolved = (
  actorName: string,
  threadResolved: boolean,
  targetId: string,
  targetName: string,
  isWsComp?: boolean
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* ${threadResolved ? "resolved" : "re-opened"} a thread on ${
          isWsComp
            ? `the component *<https://app.dittowords.com/components/${targetId}|*${targetName}*>*`
            : `a text item in *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`
        }`,
      },
    },
  ]);

  return { blocks, type: "commentActivity" };
};

const textItemAdded = (
  actorName: string,
  text: string,
  targetId: string,
  targetName: string
): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* added a draft text item to *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`,
      },
    },
  ]);

  const attachments = JSON.stringify([
    {
      color: "#cccccc",
      text: `*Text Item:* ${truncate(text)}`,
    },
  ]);

  return { blocks, attachments, type: "draftItems" };
};

const textItemDeleted = (actorName: string, targetId: string, targetName: string): IntegrationMessagePayload => {
  const blocks = JSON.stringify([
    {
      type: "section",
      text: {
        type: "mrkdwn",
        text: `*${actorName}* deleted a draft text item from *<https://app.dittowords.com/projects/${targetId}|*${targetName}*>*`,
      },
    },
  ]);

  return { blocks, type: "draftItems" };
};

export default {
  componentAssigned,
  componentUnassigned,
  componentEdited,
  componentStatusChanged,
  commentPosted,
  replyPosted,
  editSuggested,
  componentAttached,
  threadResolved,
  textItemAdded,
  textItemDeleted,
  multiComponentsAttached,
  multiComponentsAttachedNoDoc,
};
