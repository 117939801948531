const ROOT = "/api";
const STRIPE = `${ROOT}/stripe`;

import { CreateResyncJobRequestBody } from "@shared/types/http/jobs";

export default {
  get: {
    workspace: {
      url: (workspaceId: string) => `${ROOT}/workspace/${workspaceId}`,
    },
    upcomingInvoice: {
      url: `${STRIPE}/upcoming-invoice`,
    },
    listInvoices: {
      url: `${STRIPE}/list-invoices`,
    },
    listPaymentMethods: {
      url: `${STRIPE}/list-payment-methods`,
    },
    componentLibraryExport: {
      url: `${ROOT}/library/export`,
    },
    variableLibraryExport: {
      url: `${ROOT}/variables/export`,
    },
    projectExport: {
      url: (docID: string) => `${ROOT}/export/${docID}`,
    },
    checkFileIDExists: {
      url: (fileId: string, branchId?: string) => {
        if (branchId) return `${ROOT}/checkFileIDExists/${fileId}?figma_branch_id=${branchId}`;
        return `${ROOT}/checkFileIDExists/${fileId}`;
      },
    },
  },
  post: {
    resync: {
      url: (id: string) => `${ROOT}/resync/${id}`,
      body: ({
        figma_file_ID,
        newVisibleFramesByPageId,
        newHiddenFramesByPageId,
        fromFigma,
      }: CreateResyncJobRequestBody) => ({
        figma_file_ID,
        newVisibleFramesByPageId,
        newHiddenFramesByPageId,
        fromFigma,
      }),
    },
    updatePaymentMethod: {
      url: `${STRIPE}/update-payment-method`,
      body: ({ oldMethod, newMethod }) => ({ old: oldMethod, new: newMethod }),
    },
  },
};
