import { useUnauthenticatedAuth } from "@/store/UnauthenticatedAuthContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFEnterpriseConfig } from "@shared/types/EnterpriseConfig";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isValidMongoID } from "../../../util/mongodb";
import { getURLParams } from "../../../util/url";
import google from "../../assets/google.png";
import { APP_URLS, routes } from "../../defs";
import useSegment from "../../hooks/useSegment";
import { fetchEnterpriseConfigByKey } from "../../http/enterprise";
import LoginWrapper from "./LoginWrapper";
import style from "./style.module.css";

const CustomLogin = () => {
  const segment = useSegment();
  const { loginWithRedirect } = useUnauthenticatedAuth();
  const { company: companyKey, redirect_doc_id } = useParams<{
    company: string;
    redirect_doc_id: string;
  }>();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyInfo, setCompanyInfo] = useState<
    | { loaded: false }
    | { loaded: true; success: true; data: IFEnterpriseConfig }
    | { loaded: true; success: false; error: string }
  >({ loaded: false });
  const [error, setError] = useState<string | null>(null);

  useEffect(
    function loadEnterpriseConfig() {
      if (!companyKey) {
        return;
      }

      const [request] = fetchEnterpriseConfigByKey(companyKey);
      request
        .then((response) => {
          setCompanyInfo({ loaded: true, success: true, data: response.data });
        })
        .catch((e) => {
          console.error("Error fetching enterprise config", e);
          setCompanyInfo({
            loaded: true,
            success: false,
            error: "Something went wrong",
          });
        });
    },
    [companyKey]
  );

  useEffect(function handleUrlError() {
    const params = getURLParams(window.location.href);
    const parsedError = params.get("error");
    if (!parsedError) {
      return;
    }

    setError(parsedError);
    segment.track({
      event: SegmentEvents.LOGIN_ERROR,
      properties: { error: parsedError },
    });
  }, []);

  useEffect(
    function handleRedirect() {
      if (redirect_doc_id && isValidMongoID(redirect_doc_id)) {
        setRedirectUrl(routes.nonNavRoutes.project.getPath(redirect_doc_id));
      } else if (
        document.referrer.includes(APP_URLS.dev) ||
        document.referrer.includes(APP_URLS.staging) ||
        document.referrer.includes(APP_URLS.prod)
      ) {
        const referrerPath = new URL(document.referrer).pathname;
        setRedirectUrl(referrerPath);
      }

      setLoading(false);
    },
    [setRedirectUrl, setLoading]
  );

  if (!companyInfo.loaded) {
    return <React.Fragment />;
  }

  if (!companyInfo.success) {
    return (
      <LoginWrapper error={companyInfo.error}>
        <span>{companyInfo.error}</span>
        <div className={style.title}>Unable to find workspace</div>
        <br />
        <p className={style.legal}>
          Check with the person who gave you this link, or email us at{" "}
          <a rel="noreferrer" target="_blank" href="mailto:support@dittowords.com">
            support@dittowords.com
          </a>
          .
        </p>
      </LoginWrapper>
    );
  }

  const { data: company } = companyInfo;

  return (
    <LoginWrapper title="Sign in to Ditto" error={error}>
      {company.logo?.src ? (
        <img src={company.logo.src} style={company.logo.style} />
      ) : (
        <div className={style.companyName}>{company.name}</div>
      )}
      <p className={style.legal}>To join the workspace for your team, please use your work email.</p>
      {loading && <div>Loading...</div>}
      {!loading && redirectUrl && (
        <span>
          {company.auth.google && (
            <button
              className={style.loginButton}
              onClick={() =>
                loginWithRedirect({
                  connection: "google-oauth2",
                  appState: { targetUrl: redirectUrl },
                  workspaceID: company.workspace_id || null,
                })
              }
            >
              <img className={style.googleLogo} src={google} />
              Sign in with Google
            </button>
          )}
          {company.auth.azure && (
            <a
              className={style.loginLink}
              onClick={() =>
                loginWithRedirect({
                  connection: "azure-AD",
                  appState: { targetUrl: redirectUrl },
                  workspaceID: company.workspace_id || null,
                })
              }
            >
              Sign in with Azure AD
            </a>
          )}
          {company.auth.saml && (
            <button
              className={style.loginButton}
              onClick={() =>
                loginWithRedirect({
                  connection: company.auth.saml,
                  appState: { targetUrl: redirectUrl },
                  workspaceID: company.workspace_id || null,
                })
              }
            >
              Sign in with SAML SSO
            </button>
          )}
        </span>
      )}
      {!loading && !redirectUrl && (
        <span>
          {company.auth.google && (
            <button
              className={style.loginButton}
              onClick={() =>
                loginWithRedirect({
                  connection: "google-oauth2",
                  workspaceID: company.workspace_id || null,
                  enterpriseKey: company.key,
                })
              }
            >
              <img className={style.googleLogo} src={google} />
              Sign in with Google
            </button>
          )}
          {company.auth.azure && (
            <a
              className={style.loginLink}
              onClick={() =>
                loginWithRedirect({
                  connection: "azure-AD",
                  workspaceID: company.workspace_id || null,
                  enterpriseKey: company.key,
                })
              }
            >
              Sign in with Azure AD
            </a>
          )}
          {company.auth.saml && (
            <button
              className={style.loginButton}
              onClick={() =>
                loginWithRedirect({
                  connection: company.auth.saml,
                  workspaceID: company.workspace_id || null,
                  enterpriseKey: company.key,
                })
              }
            >
              Sign in with SAML SSO
            </button>
          )}
          {company.auth.email_password && (
            <a
              className={style.loginLink}
              onClick={() =>
                loginWithRedirect({
                  connection: "Username-Password-Authentication",
                  workspaceID: company.workspace_id || null,
                })
              }
            >
              Sign in with Email
            </a>
          )}
        </span>
      )}
    </LoginWrapper>
  );
};

export default CustomLogin;
