import { useWorkspace } from "@/store/workspaceContext";
import CloseIcon from "@mui/icons-material/Close";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import LightbulbOutline from "../../assets/LightbulbOutline";
import { BillingContext } from "../../store/billingContext";
import FolderSelect, { hasValidFolderLoaded } from "../FolderSelect";
import useFolderSelect from "../FolderSelect/useFolderSelect";
import UserSelect from "../UserSelect";
import VariableRichTextArea from "../VariableTextArea/VariableRichTextArea";
import ComponentLimitText from "../account-billing/ComponentLimitText";
import ButtonPrimary from "../button/buttonprimary";
import CategoryDropdownMenu, { useCategoryDropdownMenu } from "../shared/CategoryDropdownMenu";
import ConfirmationModal from "../shared/confirmation-modal";
import TagInput from "../tag-input/tag-input";
import TemplateToggle from "./TemplateToggle";
import style from "./style.module.css";

const initialVariableTextAreaValue = {
  text: "",
  variables: [],
};

const DraftCompModal = ({ onHide, tagSuggestions, handleCreateDraft, selectedFolder, allComponentsCache }) => {
  const editorRef = useRef(null);
  const {
    componentLimits: { isOverComponentLimit },
  } = useContext(BillingContext);
  const [name, setName] = useState("");
  const [draftValue, setDraftValue] = useState({ text: "", variables: [], characterLimit: null });
  const [richTextValue, setRichTextValue] = useState(undefined);

  const categoryDropdownMenu = useCategoryDropdownMenu({ name, setName });

  const [assignee, setAssignee] = useState(null);
  const [note, setNote] = useState("");
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("standard");
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const canSave = useMemo(() => {
    return name || note || tags.length > 0 || assignee || draftValue.text;
  }, [name, note, tags, assignee, draftValue, richTextValue]);

  const hasEditAccessToDefaultComponentFolder = userHasResourcePermission(
    "component_folder:edit",
    "component_folder_no_id"
  );

  const folderSelectState = useFolderSelect({
    onlyAllowFoldersWithEditAccess: true,
    disableDefaultFolder: !hasEditAccessToDefaultComponentFolder,
    useSampleData: false,
  });
  const folderSelectedIdCache = useRef(selectedFolder?._id);

  const { users } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  useEffect(
    function syncSelectedFolderId() {
      if (!(hasValidFolderLoaded(folderSelectState.folders) && folderSelectedIdCache.current)) {
        return;
      }

      const folder = folderSelectState.folders.find((f) => f._id === folderSelectedIdCache.current);

      folderSelectedIdCache.current = null;
      if (folder) {
        folderSelectState.setSelectedFolder(folder);
      }
    },
    [folderSelectState.folders, selectedFolder]
  );

  const isDuplicateName = useMemo(() => {
    return allComponentsCache.current.map((comp) => comp.name).includes(name);
  }, [name]);

  const onDeleteTag = (i) => {
    const curr_tags = tags.slice(0);
    curr_tags.splice(i, 1);
    setTags(curr_tags);
  };

  const onAddTag = (tag) => {
    const upper = tag.name.toUpperCase();
    const match = tags.filter((existing) => existing.name === upper);
    if (match.length === 0) {
      const curr_tags = [...tags, { name: upper }];
      setTags(curr_tags);
    }
  };

  const createComponent = async () => {
    setLoading(true);

    const { selectedFolder } = folderSelectState;
    const folderId = selectedFolder._id || null;

    const result = await handleCreateDraft({
      name: name.trim(),
      text: draftValue.text || "",
      assignee: assignee,
      richText: richTextValue,
      variables: draftValue.variables || [],
      tags: tags.map((tag) => tag.name),
      notes: note,
      folderId,
      type,
      characterLimit: draftValue.characterLimit || null,
    });
    if (result) {
      onHide();
    } else {
      setLoading(false);
      setError("There was an error creating the component. Please try again.");
    }
  };

  const buttonJustification = folderSelectState.folders.length > 1 ? "space-between" : "flex-end";

  const copy =
    type === "standard"
      ? {
          title: "Create Component",
          placeholders: {
            name: "Add component name",
            text: "No text.",
          },
        }
      : {
          title: "Create Template Component",
          placeholders: {
            name: "Ex: Modal / Error / Title",
            text: "Ex: We couldn't [desired action]",
          },
        };

  const onTemplateToggleClick = () => {
    setType((t) => (t === "template" ? "standard" : "template"));

    /**
     * This is a hack to get around the fact that the editor gets destroyed
     * and re-created every time the type changes, which causes it to lose
     * its existing text value.
     */
    const [d, r] = [{ ...draftValue }, { ...richTextValue }];
    setTimeout(() => {
      setDraftValue(d);
      setRichTextValue(r);
      if (editorRef.current && !editorRef.current.isDestroyed) {
        editorRef.current.commands.setContent(r, false);
      }
    }, 0);
  };

  const accessEditorInstance = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  const handleHide = () => {
    if (canSave) {
      setShowUnsavedChangesModal(true);
    } else {
      onHide();
    }
  };

  const handleCharacterLimitChange = (characterLimit) => {
    setDraftValue((v) => ({ ...v, characterLimit }));
  };

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={handleHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          <span className={style.title}>{copy.title}</span>
        </BootstrapModal.Title>
        {!loading && <CloseIcon className={style.close} onClick={handleHide} />}
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div>
          {isOverComponentLimit && <ComponentLimitText />}
          {error && <div className={style.error}>{error}</div>}
          <div className={style.input}>
            <label>Name</label>
            <input
              type="text"
              required
              placeholder={copy.placeholders.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
              ref={categoryDropdownMenu.inputRef}
            />
            {isDuplicateName && (
              <div className={style.error}>
                This name already exists in your component library! Component names must be unique.
              </div>
            )}
            <CategoryDropdownMenu {...categoryDropdownMenu} />
          </div>
          <div className={style.input}>
            <VariableRichTextArea
              highlightBrackets={type === "template"}
              disableRichText={false}
              placeholder={copy.placeholders.text}
              isDisabled={false}
              isVariant={false}
              isBaseText={true}
              showContentLength={true}
              value={initialVariableTextAreaValue}
              handleCharacterLimitChange={handleCharacterLimitChange}
              handleTextChange={(draftValue, richText) => {
                setDraftValue((p) => ({ ...p, ...draftValue }));
                setRichTextValue(richText);
              }}
              // modals require slight styling tweaks for consistency
              components={{
                label: ({ children }) => <label>{children}</label>,
              }}
              accessEditorInstance={accessEditorInstance}
            />
            {type === "template" && (
              <label className={style.bracketsLabel}>
                <LightbulbOutline /> <span>Use brackets [ ] to indicate content that should be filled in.</span>
              </label>
            )}
          </div>
          <div className={style.input}>
            <label>Assignee</label>
            <UserSelect
              className={style.userSelect}
              placeholder="No teammate assigned"
              selectedUserId={assignee}
              setSelectedUserId={setAssignee}
              users={formattedUserOptions}
            />
          </div>
          <div className={style.input}>
            <label>Notes</label>
            <input type="text" placeholder="Optional" onChange={(e) => setNote(e.target.value)} />
          </div>
          <div className={classnames([style.tagContainer], [style.input])}>
            <label>Tags</label>
            <TagInput
              tags={tags}
              disabled={false}
              inputAttributes={{ disabled: false }}
              onDeleteTag={onDeleteTag}
              onAddTag={onAddTag}
              tagSuggestions={tagSuggestions}
              placeholder={tags.length > 0 ? "Add new tag" : "Optional"}
            />
          </div>
          <TemplateToggle checked={type === "template"} onChange={onTemplateToggleClick} />
          <div
            className={style.submitBtn}
            style={{
              justifyContent: buttonJustification,
            }}
          >
            <FolderSelect
              className={style.folderSelect}
              dropdownClassName={style.folderDropdown}
              {...folderSelectState}
              text="Creating in"
              fontSize={14}
            />
            <ButtonPrimary
              className={style.createButton}
              disabled={loading || !name || !draftValue.text || isDuplicateName || isOverComponentLimit}
              data-testid="create-draft-component-button"
              text={!loading ? "Create" : "Saving..."}
              onClick={() => createComponent()}
            />
          </div>
        </div>
        {showUnsavedChangesModal && (
          <ConfirmationModal
            isLayered={true}
            title="Unsaved Changes"
            body="Are you sure you want to discard this component? You have unsaved changes."
            actionPrimary="Discard"
            actionSecondary="Cancel"
            onPrimary={onHide}
            onSecondary={() => setShowUnsavedChangesModal(false)}
          />
        )}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default DraftCompModal;
