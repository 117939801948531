import classNames from "classnames";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { UNIVERSAL_SEARCH_QUERY_PARAM } from "../CommandBar/CommandBar";
import style from "./NavLink.module.css";
import { navRoutes } from "./navRoutes_NEW";

type NavRouteKey = keyof typeof navRoutes;

interface NavLinkProps {
  to: NavRouteKey;
  collapsed?: boolean;
  className?: string;

  // this is just for testing purposes; the component should determine if it's active based on the current route.
  active?: boolean;
  shortcut?: (string | JSX.Element)[];
}

const NavLink = (props: NavLinkProps) => {
  const navRoute = navRoutes[props.to];
  // This custom match logic is used to handle universal search
  const showingUniversalSearch = window.location.search.includes(UNIVERSAL_SEARCH_QUERY_PARAM);
  const match =
    (useRouteMatch({ path: navRoute.path, exact: Boolean(navRoute.exact) }) && !showingUniversalSearch) ||
    (navRoute.path === `?${UNIVERSAL_SEARCH_QUERY_PARAM}=true` &&
      (showingUniversalSearch || useRouteMatch({ path: "/search", exact: false })));

  const Icon = match && navRoute.activeIcon ? navRoute.activeIcon : navRoute.icon;

  return (
    <Link
      to={navRoute.path}
      className={classNames(style.navLink, props.className, {
        [style.active]: match ?? props.active,
        [style.collapsed]: props.collapsed,
      })}
      data-intercom-target={`nav-link-${navRoute.name}`}
    >
      <div className={style.icon}>
        <Icon />
      </div>
      <div
        className={classNames(style.linkText, {
          [style.collapsed]: props.collapsed,
        })}
      >
        <span>{navRoute.name}</span>
        {props.shortcut && (
          <span className={style.shortcutWrapper}>
            {props.shortcut.map((shortcutValue, index) => {
              return (
                <span
                  key={index}
                  className={classNames(style.shortcut, {
                    [style.activeShortcut]: match ?? props.active,
                  })}
                >
                  {shortcutValue}
                </span>
              );
            })}
          </span>
        )}
      </div>
    </Link>
  );
};

export default NavLink;
