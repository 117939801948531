import { Auth0Client } from "@auth0/auth0-spa-js";

/**
 * The Auth0 client used by our app to interact with our API is
 * created through an asynchronous operation that only resolves a value
 * after interacting with Auth0's API.
 *
 * This class permits calling contexts (could be abstracted further
 * to support any arbitrary resolved value) to call `auth0Client.get()`
 * before the Auth0 client has finished resolving, and the calling context
 * will pause until `auth0Client.resolve(client)` has been called somewhere else.
 */
export default class ResolvedAuthClient {
  private client: Promise<Auth0Client>;
  private resolved: boolean;
  private resolveClient: (client: Auth0Client) => void;

  constructor() {
    let resolveClient;

    this.client = new Promise((resolve) => {
      resolveClient = resolve;
    });

    this.resolved = false;
    this.resolveClient = resolveClient;
  }

  public get(): Promise<Auth0Client> {
    return this.client;
  }

  /**
   * Resolves the specified Auth0Client, freeing any contexts
   * waiting for it to continue running.
   */
  public resolve(client: Auth0Client): void {
    if (this.resolved) {
      return;
    }

    this.resolved = true;
    this.resolveClient(client);
  }
}
