import {
  CommitStructureSuggestionsWIPRequestBody,
  CommitSuggestions,
  TurnOffSetupSuggestionsForGroupBody,
} from "@shared/types/http/Doc";
import axios, { AxiosResponse } from "axios";
import { BlockSuggestions, WsComponentSuggestion } from "../../services/setupSuggestions/types";
import http from "./lib/client";

const url = (path: string) => `/doc/setupSuggestions${path}`;

export interface SetupSuggestionsForGroupsResponse {
  targetGroupId: string;
  baseGroupId: string;
  projectId: string;
  blockSuggestions: BlockSuggestions;
  hideSuggestions: string[];
  wsComponentSuggestions: WsComponentSuggestion[];
}

export interface SetupSuggestionsForGroup {
  groupId: string;
  baseGroupId?: string;
  projectId: string;
  blockSuggestions: {
    [blockId: string]: {
      selected: boolean;
      textItems: string[];
      blockName: string;
    };
  };
  hideSuggestions: string[];
  hideSuggestionsSelected: boolean;
  wsComponentSuggestions: (WsComponentSuggestion & { selected: boolean })[];
}

export const fetchSetupSuggestionForGroups = (params: {
  baseGroupId?: string;
  targetGroupId: string;
  projectId: string;
}): [Promise<AxiosResponse<SetupSuggestionsForGroupsResponse>>, () => void] => {
  const { baseGroupId, targetGroupId, projectId } = params;
  const source = axios.CancelToken.source();

  const request = http.post<SetupSuggestionsForGroupsResponse>(
    url("/generate"),
    {
      baseGroupId,
      targetGroupId,
      projectId,
    },
    {
      cancelToken: source.token,
    }
  );

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export const commitSuggestions = (
  suggestions: CommitSuggestions,
  docId: string
): [Promise<AxiosResponse<{}>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.post<{}>(
    url("/commit"),
    CommitStructureSuggestionsWIPRequestBody.parse({
      suggestions: suggestions,
      docId,
    }),
    {
      cancelToken: source.token,
    }
  );

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export const turnOffSetupSuggestionsForGroup = (
  groupId: string,
  projectId: string
): [Promise<AxiosResponse<{}>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.post<{}>(
    url("/turnOffSetupSuggestionsForGroup"),
    TurnOffSetupSuggestionsForGroupBody.parse({
      groupId,
      projectId,
    }),
    {
      cancelToken: source.token,
    }
  );

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};
