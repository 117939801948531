import { getIsFigmaAuthSession } from "../../figma/lib";

/**
 * If the app is loaded as part of a user's attempt to log into the
 * Figma plugin, use the Auth0 client for the Figma plugin instead of
 * the Auth0 client for the web app.
 *
 * This allows the Figma plugin to get refresh tokens that are unassociated
 * with the refresh token chain issued to the user for the web app, which
 * allows the Figma plugin and the web app to refresh tokens in parallel
 * without one of them invalidating the other (while refresh token rotation)
 * is enabled.
 */
export default function getAuth0ClientId(): string {
  const isFigmaAuthSession = getIsFigmaAuthSession();

  return isFigmaAuthSession ? process.env.REACT_APP_AUTH0_CLIENT_ID_FIGMA! : process.env.REACT_APP_AUTH0_CLIENT_ID!;
}
