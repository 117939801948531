import AssignmentTurnedInOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";
import classNames from "classnames";
import { Ditto } from "ditto-react";
import React, { useLayoutEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Pagination from "../pagination";
import Panel from "../panel";
import Assignment, { Props as AssignmentProps } from "./assignment";
import style from "./style.module.css";

export interface Props {
  loading?: boolean;
  assignments: AssignmentProps[];
}

export default function AssignedToYouPanel(props: Props) {
  const [page, setPage] = useState(1);
  const pageSize = 5;

  const paginate = (array: any[], page: number, pageSize: number) => {
    const startIndex = (page - 1) * pageSize;
    return array.slice(startIndex, startIndex + pageSize);
  };

  const showPagination = props.assignments.length > pageSize;

  const sum = props.assignments.reduce((a, b) => a + b.statusCounts.reduce((c, d) => c + d.count, 0), 0);

  // If there's more than one page worth of assignments, we don't want the height of the panel
  // to change as the user paginates.
  const useFixedHeight = props.assignments.length > pageSize;
  const listRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(
    function getListHeightOnMount() {
      if (useFixedHeight && listRef.current) {
        listRef.current.style.height = `${listRef.current.clientHeight}px`;
      }
    },
    [props.assignments]
  );

  return (
    <Panel
      titleDittoComponentId="assigned-to-you-title"
      count={sum}
      icon={AssignmentTurnedInOutlined}
      containerClassName={classNames({ [style.paddingAdjustForNoPagination]: !showPagination })}
    >
      <div className={classNames({ [style.assignmentsList]: showPagination })} ref={listRef}>
        {paginate(props.assignments, page, pageSize).map((assignment) => (
          <Assignment {...assignment} key={assignment.projectId ?? "components"} />
        ))}
      </div>
      {!props.loading && props.assignments.length === 0 && (
        <div className={style.emptyState}>
          <div className={style.emptyStateText}>
            <Ditto componentId="assigment-empty-state" />
          </div>
        </div>
      )}

      {props.loading && (
        <div className={style.emptyState}>
          <Skeleton height={20} containerClassName={style.skeleton} />
          <Skeleton height={20} containerClassName={style.skeleton} />
          <Skeleton height={20} containerClassName={style.skeleton} />
        </div>
      )}

      {showPagination && (
        <Pagination
          page={page}
          pageSize={pageSize}
          total={props.assignments.length}
          onPaginateBack={() => setPage(page - 1)}
          onPaginateForward={() => setPage(page + 1)}
        />
      )}
    </Panel>
  );
}
