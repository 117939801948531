import { Editor } from "@tiptap/core";
import { Transaction } from "prosemirror-state";

import { BLOCK_NAME } from "./nodes/DraftBlockNode";

interface Args {
  editor: Editor;
  transaction: Transaction;
}

export const getSelectedTextItems = ({ transaction }: Args) => {
  const { doc, selection } = transaction;
  const { from: selectionStart, to: selectionEnd } = selection;

  // used to dispatch an event to update React state tracking the text
  // items that are currently selected
  const textItemIds: string[] = [];

  // traverse through all nodes in the document
  doc.descendants((node, nodeStartPosition) => {
    // if the current node is not a block,
    // return early and don't recurse into it
    if (node.type.name !== BLOCK_NAME) {
      return false;
    }

    const selectionAroundNode = nodeStartPosition >= selectionStart && nodeStartPosition <= selectionEnd - 1;

    const selectionInNode =
      selectionStart >= nodeStartPosition && selectionStart <= nodeStartPosition + node.nodeSize - 1;

    const nodeIsSelected = selectionAroundNode || selectionInNode;
    if (!nodeIsSelected) {
      return false;
    }

    if (node.attrs.textItemId) {
      textItemIds.push(node.attrs.textItemId);
    }

    return false;
  });

  return textItemIds;
};
