import http from "@/http";
import { useWorkspace } from "@/store/workspaceContext";
import { SUBERSCRIPT_ENABLED_WORKSPACES } from "@/utils/featureFlags";
import AiDialogComponent from "@shared/frontend/Editor/AiDialog";
import * as SegmentEvents from "@shared/segment-event-names";
import { ITipTapRichText } from "@shared/types/TextItem";
import { IFVariable as VariableType } from "@shared/types/Variable";
import { Action } from "@shared/types/writing";
import findSentenceBoundaries from "@shared/utils/findSentenceBoundaries";
import logger from "@shared/utils/logger";
import { forEachVariable, getVariablePlaceholder } from "@shared/utils/variableInterpolation";
import { BubbleMenu, Editor, EditorContent, PureEditorContent, useEditor } from "@tiptap/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import HighlightButtons from "../../../shared/frontend/Editor/HighlightButtons";
import { getExtensions } from "../../editor/Extensions";
import { variableTextToHtmlVariable } from "../../editor/VariableNode";
import useSegment from "../../hooks/useSegment";
import style from "./RichTextInput.module.css";

type AiDialog =
  | {
      action: Action;
      withoutVariablesStart: number;
      withoutVariablesEnd: number;
      withVariablesStart: number;
      withVariablesEnd: number;
      generatedText: string;
    }
  | { isLoading: true; action: Action }
  | null;

interface RichTextVariableInputProps {
  placeholder: string;
  emptyEditorClass?: string;
  initialValues: {
    text: string;
    variables: VariableType[];
    // Some legacy parents aren't passing in the entire TextItem object
    [key: string]: any;
  };
  isDisabled: boolean;
  handleTextChange: (
    newInputValue: {
      text: string;
      variables: VariableType[];
    },
    richText: ITipTapRichText | undefined
  ) => void;
  contentLength: number;
  setContentLength: (val: number) => void;
  setCurrentSelection: (val: string) => void;
  onFocus: () => void;
  onBlur: () => void;
  insertedVariable: VariableType | null;
  disableRichText?: boolean;
  showVariableModal: () => void;
  textInputClassName?: string;
  accessEditorInstance?: (editor: Editor) => void;
  highlightBrackets: boolean;
  characterLimit: number | null;
}

const RichTextVariableInput = ({
  placeholder,
  emptyEditorClass,
  initialValues,
  isDisabled,
  handleTextChange,
  contentLength,
  setContentLength,
  onFocus,
  onBlur,
  insertedVariable,
  disableRichText,
  showVariableModal,
  textInputClassName,
  accessEditorInstance,
  highlightBrackets,
  characterLimit,
  setCurrentSelection,
}: RichTextVariableInputProps) => {
  const segment = useSegment();
  const { workspaceInfo } = useWorkspace();
  const suberscriptEnabled = useMemo(() => {
    if (!workspaceInfo) {
      return false;
    }

    if (disableRichText) {
      return false;
    }

    return SUBERSCRIPT_ENABLED_WORKSPACES.includes(workspaceInfo._id);
  }, [workspaceInfo]);

  const [variables, setVariables] = useState<VariableType[]>([]);

  /**
   * This is hacky, but necessary due to our editor setup. We need to access
   * the last known value of `variables` in the `onUpdate` callback to check
   * if a variable node has been inserted that doesn't have a corresponding
   * variable in the `variables` array.
   *
   * We can't use `variables` directly because its value would be stale; we
   * could try to fix this by adding it as a dependency of the useEditor hook,
   * but that causing remounting and breaks other things :')
   */
  const variablesRef = useRef(variables);
  useEffect(() => {
    variablesRef.current = [...variables];
  }, [variables]);

  const [inputHasChanged, setInputHasChanged] = useState<false | number>(false);
  const currEditorContent = useRef<ITipTapRichText | null>(null);
  const [errorMsg, setErrorMsg] = useState(<></>);
  const [isBoldActive, setIsBoldActive] = useState<boolean>(false);
  const [isItalicActive, setIsItalicActive] = useState<boolean>(false);
  const [isUnderlineActive, setIsUnderlineActive] = useState<boolean>(false);
  const [isSuperscriptActive, setIsSuperscriptActive] = useState<boolean>(false);
  const [isSubscriptActive, setIsSubscriptActive] = useState<boolean>(false);

  // Need to do this since contentLength is used in a passed function to useEditor
  const contentLengthRef = useRef(contentLength);
  useEffect(
    function updateContentLengthRef() {
      contentLengthRef.current = contentLength;
    },
    [contentLength]
  );

  const extensions = useMemo(
    () =>
      getExtensions({
        isRichTextEnabled: !disableRichText,
        placeholder,
        highlightBrackets,
        emptyEditorClass,
      }),
    [disableRichText, placeholder, highlightBrackets, emptyEditorClass]
  );

  /**
   * This instance of the TipTap editor is destroyed and re-created everytime its dependency array changes.
   * In the initial render after the editor is destroyed, `editor.isDestroyed` will be `true`, and an
   * internal object core to TipTap's command behavior (`this.docView`) will be null.
   *
   * To avoid this, we have to always check to see if `editor.isDestroyed` is set to `false` before
   * executing any commands.
   *
   * https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-1499305197
   */
  const editor = useEditor(
    {
      extensions: extensions,
      editorProps: {
        attributes: {
          class: `${style.editorContainer}`,
          // This turns off rich text styling via css
          // We do this so the user can save when rich text is disabled while maintaining
          // the underlying styling
          "data-rich-text": disableRichText ? "disabled" : "enabled",
          "data-suberscript": !Boolean(disableRichText) && suberscriptEnabled ? "enabled" : "disabled",
          "data-testid": isDisabled ? "text-item-input-disabled" : "text-item-input",
        },
      },
      onUpdate: ({ editor: newEditor }) => {
        setErrorMsg(<></>);
        const previousContent = currEditorContent.current;
        let newContent = newEditor.getJSON() as ITipTapRichText;
        const isNewContentOnlySpaces = /^\s*$/.test(newEditor.getText());

        let hasNonBold = !newContent.content![0].content; // Empty content considered non-bold
        let hasOrphanedVariable = false;

        newContent.content?.forEach((paragraph, paragraphIndex) =>
          paragraph.content?.forEach((inlineNode, inlineNodeIndex) => {
            const isNonBold = !inlineNode.marks || !inlineNode.marks.map((mark) => mark.type).includes("bold");
            if (isNonBold) {
              hasNonBold = true;
            }

            const isVariable = inlineNode.type === "variable";
            if (isVariable) {
              /**
               * A variable is considered orphaned if a rich text variable node exists in the JSON
               * content of the editor but there's no corresponding variable in Ditto state.
               *
               * This most commonly happens by copying content that includes a variable rich text node
               * from a Ditto text editor and pasting it into another Ditto text editor which doesn't
               * include the original variable in state.
               */
              const isOrphaned = !variablesRef.current.some((v) => v.variable_id === inlineNode.attrs?.variableId);

              /**
               * If we find an orphaned variable, we just replace it with the corresponding text value.
               * It would be significantly more complicated to try to preserve the variable node and
               * figure out whether or not there exists a corresponding variable in the workspace that the
               * state could be updated with.
               */
              if (isOrphaned) {
                newContent.content![paragraphIndex].content![inlineNodeIndex] = {
                  type: "text",
                  text: inlineNode.attrs?.text,
                };
                hasOrphanedVariable = true;
              }
            }
          })
        );

        // Prevent scenerio where only bold whitespace is left
        if (isNewContentOnlySpaces) {
          currEditorContent.current = {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          };
          setContentLength(0);
          setInputHasChanged(Date.now());
          return;
        }
        // If the user is deleting characters and the deletion results in fully bold text
        // we just make the new text unbolded
        else if (!hasNonBold && newEditor.getText().length < contentLengthRef.current) {
          const newContentWithoutBold = { ...newContent };
          newContent.content!.forEach((paragraph, paragraphIdx) =>
            paragraph.content?.forEach((text, textIdx) => {
              if (paragraph.content && paragraph.content[textIdx]) {
                newContentWithoutBold.content![paragraphIdx].content![textIdx].marks = text.marks?.filter(
                  (m) => m.type !== "bold"
                );
              }
            })
          );
          newContent = newContentWithoutBold;
          newEditor.commands.setContent(newContent, false);
        } else if (!hasNonBold) {
          newEditor.commands.setContent(previousContent, false);
          setErrorMsg(
            <p className={style.errorMsg}>
              It looks like you're trying to bold your entire text item. We recommend making styling changes to the
              entire text directly in Figma. <a href="https://www.dittowords.com/docs/rich-text">Learn more</a>
            </p>
          );
          return;
        }

        currEditorContent.current = newContent;
        if (hasOrphanedVariable) {
          newEditor.commands.setContent(newContent, false);
        }
        setContentLength(calculateContentLength(newContent, 0));
        setInputHasChanged(Date.now());
      },
      onSelectionUpdate: ({ editor: newEditor }) => {
        setIsBoldActive(newEditor.isActive("bold"));
        setIsItalicActive(newEditor.isActive("italic"));
        setIsUnderlineActive(newEditor.isActive("underline"));
        setIsSuperscriptActive(newEditor.isActive("superscript"));
        setIsSubscriptActive(newEditor.isActive("subscript"));
        const { from, to } = newEditor.state.selection;
        setCurrentSelection(newEditor.state.doc.textBetween(from, to, " "));
      },
    },
    [extensions]
  );

  useEffect(() => {
    if (contentLength && editor) {
      const limit = characterLimit ? characterLimit : editor.getText().length;
      !editor.isDestroyed && // https://github.com/ueberdosis/tiptap/issues/1451
        editor.commands.setErrorHighlight(limit, editor.getText().length);
    }
  }, [contentLength, characterLimit, editor]);

  /**
   * This is a hack to allow ancestors to access the editor instance
   * to manipulate the editor's content directly. This is sometimes
   * necessary since we built out the editor to be an uncontrolled component.
   */
  useEffect(
    function giveAccessToEditorInstance() {
      if (!(editor && accessEditorInstance)) {
        return;
      }

      accessEditorInstance(editor);
    },
    [editor, accessEditorInstance]
  );

  useEffect(
    function updateVariables() {
      setVariables(initialValues?.variables || []);
    },
    [initialValues?.variables]
  );

  useEffect(
    function loadInitialEditorContent() {
      if (!editor || editor.isDestroyed) return;

      setErrorMsg(<></>);
      setAiDialog(null);

      if (!initialValues.rich_text) {
        editor.commands.setContent(variableTextToHtmlVariable(initialValues.text, initialValues.variables), false);
        setContentLength(editor.getText().length);
      } else {
        editor.commands.setContent(initialValues.rich_text, false);
        currEditorContent.current = initialValues.rich_text;
        setContentLength(calculateContentLength(initialValues.rich_text, 0));
      }
    },
    [editor, initialValues.text, initialValues.variables, initialValues.rich_text]
  );

  useEffect(
    function syncDisabledState() {
      if (!editor || editor.isDestroyed) return;
      editor.setEditable(!isDisabled);
    },
    [editor, isDisabled]
  );

  useEffect(
    function insertNewVariable() {
      if (!insertedVariable) {
        return;
      }

      if (!editor || editor.isDestroyed) {
        return;
      }

      /**
       * Update the ref right away so that the new variable
       * will instantly be accessible within it; required since
       * the `onUpdate` callback will execute right away from
       * the `commands.addVariable()` call before the queued `setVariables`
       * call causes a re-render.
       */
      variablesRef.current ??= [];
      variablesRef.current.push(insertedVariable);

      editor?.commands.addVariable(
        insertedVariable._id,
        insertedVariable.name,
        getVariablePlaceholder(insertedVariable || null),
        insertedVariable.type
      );

      setVariables((prev) => {
        if (!Array.isArray(prev)) {
          return [insertedVariable];
        }

        return [...prev, insertedVariable];
      });
    },
    [insertedVariable]
  );

  /**
   * - removes variables not used in the text
   * - removes duplicates
   */
  const getCurrentVariables = (text, variables) => {
    if (!(variables && variables.length)) {
      return [];
    }

    const variableNames = {};

    forEachVariable(text, ({ value: name }) => (variableNames[name] = true));

    return Object.keys(variableNames)
      .map((name) => variables.find((v) => v && v.name === name))
      .filter((variable) => !!variable);
  };

  const calculateContentLength = (node: any, sum: number) => {
    if (!node) {
      return sum;
    } else if (Array.isArray(node)) {
      return node.reduce((cur, item) => calculateContentLength(item, cur), sum);
    } else if (node.content) {
      return sum + calculateContentLength(node.content, 0);
    }

    if (node.text) return sum + node.text.length;
    else if (node.attrs?.text) return sum + node.attrs.text.length;
    else return sum;
  };

  useEffect(
    function reactToInputChanging() {
      if (!inputHasChanged || !editor || editor.isDestroyed) {
        return;
      }

      const updatedText =
        editor?.getText({
          /**
           * Paragraphs are the only "block" node supported by our editor. By default,
           * they get serialized to an empty string, but have a `blockSeparator` value
           * appended each time, which defaults to "\n\n".
           *
           * Therefore:
           * - when a user hits enter (i.e. "creates a new line"), a new paragraph is created inside
           * of the ProseMirror schema
           * - when that paragraph gets serialized into text, it is serialized into two new lines,
           * which is unexpected behavior
           *
           * To resolve this:
           * 1. we set the default `blockSeparator` to an empty string
           * 2. we add a custom text serializer for paragraph nodes to serialize them as a single "\n"
           * character, with the exception of the first paragraph in the ProseMirror document, which
           * should still get serialized as an empty string.
           *
           * See the source:
           * https://github.com/ueberdosis/tiptap/blob/8c6751f0c638effb22110b62b40a1632ea6867c9/packages/core/src/helpers/getTextBetween.ts#L21-L44
           */
          blockSeparator: "",
          textSerializers: {
            paragraph: ({ pos }) => (pos === 0 ? "" : "\n"),
          },
        }) || "";

      const updatedRichText = editor?.getJSON() as ITipTapRichText | undefined;
      const updatedVariables = getCurrentVariables(updatedText, variables);

      setIsBoldActive(editor.isActive("bold"));
      setIsItalicActive(editor.isActive("italic"));
      setIsUnderlineActive(editor.isActive("underline"));
      setIsSuperscriptActive(editor.isActive("superscript"));
      setIsSubscriptActive(editor.isActive("subscript"));

      handleTextChange(
        {
          text: updatedText,
          variables: updatedVariables,
        },
        updatedRichText
      );
      setInputHasChanged(false);
    },
    [variables, inputHasChanged]
  );

  const [aiDialog, setAiDialog] = useState<AiDialog>(null);
  const aiDialogElement = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<(PureEditorContent & HTMLDivElement) | null>(null);

  const [generationError, setGenerationError] = useState("");

  useEffect(
    function refocusEditorForAiDialog() {
      if (!editor || !aiDialog) return;

      const isThereSelection = (editor && editor.state.selection.to - editor.state.selection.from > 0) || false;

      const wasAiDialogLoadingSilently =
        aiDialog && !("isLoading" in aiDialog) && (!isThereSelection || !editor.isFocused);

      if (wasAiDialogLoadingSilently) {
        setAiDialog(null);
        return;
      }

      editor.commands.focus();
    },
    [editor, aiDialog]
  );

  useEffect(
    // close aiDialog if the users clicks somewhere else while it is active
    function setupDismissAiDialogMouseListener() {
      if (!aiDialogElement.current) return;

      const handleClickOutsideAiDialog = (event: MouseEvent) => {
        if (!aiDialogElement.current) return;

        const didNotClickOnAiDialog =
          !aiDialogElement.current.contains(event.target as Node) && aiDialogElement.current !== event.target;

        const aiDialogIsShowingAndNotLoading = aiDialog && !("isLoading" in aiDialog);

        if (didNotClickOnAiDialog) {
          setAiDialog(null);
          if (aiDialogIsShowingAndNotLoading) {
            segment.track({
              event: SegmentEvents.AI_EDITOR_REJECTED_GENERATION,
              properties: {
                action: aiDialog.action,
                generatedText: aiDialog.generatedText,
              },
            });
          }
        }
      };

      document.addEventListener("mousedown", handleClickOutsideAiDialog);

      return () => document.removeEventListener("mousedown", handleClickOutsideAiDialog);
    },
    [aiDialogElement.current, aiDialog]
  );

  useEffect(
    function positionAiDialog() {
      if (!editor || !aiDialogElement.current || !editorRef.current || !editorRef.current.editorContentRef?.current) {
        return;
      }

      const offset = getEndPositionOfSelection();
      aiDialogElement.current.style.width = window.getComputedStyle(editorRef.current.editorContentRef.current).width;

      aiDialogElement.current.style.top = offset?.y + "px";
    },
    [aiDialogElement.current]
  );

  const onAiAction = async (action: Action, editor: Editor, options?: { isRegeneration?: boolean }) => {
    setGenerationError("");
    setAiDialog({ isLoading: true, action });

    // Keep the BubbleMenu open
    editor.commands.focus();

    // TipTap indexes selection starting at 1 and is
    // exclusive at the end which is why we do -1 and -2
    const boundariesWithVariablesRemoved = findSentenceBoundaries(
      editor.view.state.selection.from - 1,
      editor.view.state.selection.to - 2,
      editor.getText().replace(/\{{2}.*?\}{2}/g, " ")
    );
    const boundariesWithoutVariablesRemoved = findSentenceBoundaries(
      editor.view.state.selection.from - 1,
      editor.view.state.selection.to - 2,
      editor.getText()
    );

    editor.commands.setTextSelection({
      from: boundariesWithVariablesRemoved.startSelection + 1,
      to: boundariesWithVariablesRemoved.endSelection + 2,
    });

    try {
      const response = await http.post<{ text: string }>("/writing/performai", {
        text: editor
          .getText()
          .substring(
            boundariesWithoutVariablesRemoved.startSelection,
            boundariesWithoutVariablesRemoved.endSelection + 1
          ),
        action,
      });
      let generatedText = response.data.text;

      const result = {
        action,
        withoutVariablesStart: boundariesWithoutVariablesRemoved.startSelection,
        withoutVariablesEnd: boundariesWithoutVariablesRemoved.endSelection,
        withVariablesStart: boundariesWithVariablesRemoved.startSelection,
        withVariablesEnd: boundariesWithVariablesRemoved.endSelection,
        generatedText,
      };

      setAiDialog(result);

      segment.track({
        event: `AI Editor: ${options?.isRegeneration ? "re" : ""}generated text`,
        properties: {
          action,
          generatedText: result.generatedText,
        },
      });
    } catch (e) {
      setAiDialog(null);
      setGenerationError("Servers are currently overloaded. Please try again.");
      logger.warn("Failed to generate ai text", { other: { action, text: editor.getText() } }, e);
    }
  };

  // This function gets the x, y of a selection by
  // introducing a dummy, invisible element, and getting
  // the position of it. To my knowledge, you cannot directly
  // get the position of highlighted text
  function getEndPositionOfSelection() {
    var sel = window.getSelection();
    if (!sel?.rangeCount) return null; // No selection

    var range = sel.getRangeAt(0).cloneRange(); // Get a copy of the first range of the selection
    var dummy = document.createElement("span"); // Create a dummy element

    if (!sel.focusNode) return;

    range.setStart(sel.focusNode, sel.focusOffset);
    range.setEnd(sel.focusNode, sel.focusOffset);
    range.insertNode(dummy); // Insert the dummy element at the end

    // Get the bounding rectangle of the dummy element
    var rect = dummy.getBoundingClientRect();

    // Find the closest great grand parent with a position of 'relative'
    // We need great grandparent instead of grandparent and parent because
    // the aiDialog does not have the same grandparent/parent of the editor
    // and the grandparent and parent is already relative
    var relativeGreatGrandParent = dummy.parentNode?.parentNode?.parentNode as HTMLElement;
    while (relativeGreatGrandParent && window.getComputedStyle(relativeGreatGrandParent).position !== "relative") {
      relativeGreatGrandParent = relativeGreatGrandParent.parentNode as HTMLElement;
    }

    let left = rect.left;
    let bottom = rect.bottom;

    if (relativeGreatGrandParent) {
      var parentRect = relativeGreatGrandParent.getBoundingClientRect();
      left -= parentRect.left;
      bottom -= parentRect.top;
    }

    // Remove the dummy element
    dummy.parentNode?.removeChild(dummy);

    // Return the relative x and y position
    return {
      x: left,
      y: bottom + 2, // 2 for a tiny buffer
    };
  }

  const acceptAiDialog = (editor: Editor) => {
    if (!aiDialog || "isLoading" in aiDialog) return;

    let generatedText = aiDialog.generatedText;

    const before = editor.getText().substring(0, aiDialog.withoutVariablesStart);
    const after = editor.getText().substring(aiDialog.withoutVariablesEnd + 1);

    if (before.length > 0) {
      generatedText = " " + aiDialog.generatedText;
    }

    const newText = before + generatedText + after;
    editor.commands.setContent(variableTextToHtmlVariable(newText, variables), true);

    setAiDialog(null);

    segment.track({
      event: "AI Editor: applied generation",
      properties: {
        action: aiDialog.action,
        generatedText: aiDialog.generatedText,
      },
    });
  };

  // Don't show ai dialog if there is no selection
  // Used when the user deselected while ai was generating
  const isThereSelection = (editor && editor.state.selection.to - editor.state.selection.from > 0) || false;

  const textWordCount = (editor && editor.getText().trim().split(" ").length) || 0;

  return (
    <>
      <div className={style.richTextVariableContainer}>
        {editor && Boolean(!disableRichText) && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <HighlightButtons
              textWordCount={textWordCount}
              isBoldActive={isBoldActive}
              isItalicActive={isItalicActive}
              isUnderlineActive={isUnderlineActive}
              isSuperscriptActive={isSuperscriptActive}
              isSubscriptActive={isSubscriptActive}
              onBoldClick={() => {
                // need to turn off suberscript first
                editor.chain().focus().unsetSuperscript().run();
                editor.chain().focus().unsetSubscript().run();
                editor.chain().focus().toggleBold().run();
              }}
              onItalicClick={() => {
                // need to turn off suberscript first
                editor.chain().focus().unsetSuperscript().run();
                editor.chain().focus().unsetSubscript().run();
                editor.chain().focus().toggleItalic().run();
              }}
              onUnderlineClick={() => {
                // need to turn off suberscript first
                editor.chain().focus().unsetSuperscript().run();
                editor.chain().focus().unsetSubscript().run();
                editor.chain().focus().toggleUnderline().run();
              }}
              onSuperscriptClick={() => {
                // need to turn off other marks for suberscript
                editor.chain().focus().unsetBold().run();
                editor.chain().focus().unsetItalic().run();
                editor.chain().focus().unsetUnderline().run();
                editor.chain().focus().unsetSubscript().run();
                editor.chain().focus().toggleSuperscript().run();
              }}
              onSubscriptClick={() => {
                // need to turn off other marks for suberscript
                editor.chain().focus().unsetBold().run();
                editor.chain().focus().unsetItalic().run();
                editor.chain().focus().unsetUnderline().run();
                editor.chain().focus().unsetSuperscript().run();
                editor.chain().focus().toggleSubscript().run();
              }}
              onInsertVariableClick={showVariableModal}
              onAiAction={
                workspaceInfo?.aiFeatures.aiEditor
                  ? (action) => {
                      onAiAction(action, editor);
                    }
                  : undefined
              }
              enableSuberscript={SUBERSCRIPT_ENABLED_WORKSPACES.includes(workspaceInfo?._id)}
            />
          </BubbleMenu>
        )}
        {workspaceInfo?.aiFeatures.aiEditor && editor && Boolean(disableRichText) && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <HighlightButtons
              textWordCount={textWordCount}
              onInsertVariableClick={showVariableModal}
              onAiAction={
                workspaceInfo?.aiFeatures.aiEditor
                  ? (action) => {
                      onAiAction(action, editor);
                    }
                  : undefined
              }
              enableSuberscript={SUBERSCRIPT_ENABLED_WORKSPACES.includes(workspaceInfo?._id)}
            />
          </BubbleMenu>
        )}
        <EditorContent
          ref={editorRef}
          className={textInputClassName}
          editor={editor}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
        />
        {errorMsg}
        {editor && aiDialog && isThereSelection && (
          <div ref={aiDialogElement} className={style.aiDialogContainer}>
            <AiDialogComponent
              action={aiDialog ? aiDialog.action : null}
              editor={editor}
              generatedText={aiDialog && !("isLoading" in aiDialog) ? aiDialog.generatedText : null}
              onRefresh={() => {
                if (!aiDialog || "isLoading" in aiDialog) return;

                onAiAction(aiDialog.action, editor, {
                  isRegeneration: true,
                });
              }}
              onAccept={() => {
                acceptAiDialog(editor);
              }}
            />
          </div>
        )}
      </div>
      {generationError && (
        <Alert variant="danger" className={style.generationError}>
          {generationError}
        </Alert>
      )}
    </>
  );
};
export default RichTextVariableInput;
