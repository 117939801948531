import { IChangeItem } from "@shared/types/ActualChange";
import { ICommentThread, ICommentType } from "@shared/types/CommentThread";
import {
  IDittoProject,
  IFDittoProjectBlock,
  IFDittoProjectBlockUpdate,
  ZTextItemsUpdate,
} from "@shared/types/DittoProject";
import { IFFigmaGroup } from "@shared/types/FigmaGroup";
import {
  ICreateBlocksProps,
  ICreateDittoProject,
  ICreateTextItemsProps,
  IFDittoProjectData,
  IGetFramePreviewsData,
  IMoveTextItemsAction,
  ISyncedTextNodesMapData,
  ITextItemsMapData,
} from "@shared/types/http/DittoProject";
import { IFTextItem, ITextItemPopulatedComments, ZTextItemStatus } from "@shared/types/TextItem";
import { z } from "zod";
import { createHttpRequest } from "./lib/createHttpRequest";

export const updateTextItems = createHttpRequest<{
  projectId: string;
  updates: z.infer<typeof ZTextItemsUpdate>[];
  variantId?: string;
  newVariant?: {
    name: string;
    variantId: string;
  };
}>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ updates, variantId, newVariant }) {
    return {
      data: { updates, variantId, newVariant },
    };
  },
});

export const getProject = createHttpRequest<
  { projectId: string; projectContentSearchQuery?: string; statuses?: string[]; tags?: string[]; assignees?: string[] },
  IFDittoProjectData
>({
  method: "get",
  getUrl({ projectId, projectContentSearchQuery, statuses, tags, assignees }) {
    let url = `/ditto-project/${projectId}`;
    const searchParams = new URLSearchParams();
    if (projectContentSearchQuery) {
      searchParams.append("search", projectContentSearchQuery);
    }

    if (statuses && statuses.length > 0) {
      // coerce the status filters from the params to match our ITextItemStatus enum
      statuses
        .filter((status) => ZTextItemStatus.safeParse(status).success)
        .forEach((status) => searchParams.append("statuses[]", status));
    }

    if (tags && tags.length > 0) {
      tags.forEach((tag) => searchParams.append("tags[]", tag));
    }

    if (assignees && assignees.length > 0) {
      assignees.forEach((assignee) => searchParams.append("assignees[]", assignee));
    }

    if (searchParams.toString()) {
      url += `?${searchParams.toString()}`;
    }

    return url;
  },
});

export const updateProject = createHttpRequest<
  { projectId: string; projectData: Partial<IFDittoProjectData> },
  IFDittoProjectData
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}`;
  },
  getConfig({ projectData }) {
    return {
      data: { projectData },
    };
  },
});

export const getTextItems = createHttpRequest<{ ids: string[]; projectId: string }, ITextItemPopulatedComments[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createTextItems = createHttpRequest<
  { projectId: string; textItems: ICreateTextItemsProps },
  { textItems: ITextItemPopulatedComments[]; block?: IFDittoProjectBlock }
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items`;
  },
  getConfig({ textItems }) {
    return {
      data: {
        textItems,
      },
    };
  },
});

export const deleteTextItem = createHttpRequest<{ projectId: string; textItemId: string }, IFTextItem[]>({
  method: "delete",
  getUrl({ projectId, textItemId }) {
    return `/ditto-project/${projectId}/text-items/${textItemId}`;
  },
});

export const getBlocks = createHttpRequest<{ ids: string[]; projectId: string }, IFDittoProjectBlock[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ ids }) {
    return {
      params: { ids },
    };
  },
});

export const createBlocks = createHttpRequest<
  { projectId: string; blocks: ICreateBlocksProps; atIndex?: number },
  IFDittoProjectBlock[]
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks, atIndex }) {
    return {
      data: {
        blocks,
        atIndex,
      },
    };
  },
});

export const deleteBlocks = createHttpRequest<{ projectId: string; blockIds: string[] }, void>({
  method: "delete",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blockIds }) {
    return {
      data: { blockIds },
    };
  },
});

export const getActivityByProjectId = createHttpRequest<
  { projectId: string; page: number; pageSize: number },
  IChangeItem[]
>({
  method: "get",
  getUrl({ projectId }) {
    return `/changes/project/${projectId}`;
  },
  getConfig({ page, pageSize }) {
    return {
      params: { skip: page * pageSize, limit: pageSize },
    };
  },
});

export const getActivityByItemIds = createHttpRequest<{ projectId?: string; itemIds: string[] }, IChangeItem[]>({
  method: "get",
  getUrl() {
    return `/changes`;
  },
  getConfig({ itemIds, projectId }) {
    return {
      params: { itemIds, projectId },
    };
  },
});

export const getActivityByTextItemId = createHttpRequest<
  { textItemId: string; page?: number; pageSize?: number },
  IChangeItem[]
>({
  method: "get",
  getUrl({ textItemId }) {
    return `/changes/text-item/${textItemId}`;
  },
  getConfig({ page, pageSize }) {
    if (page === undefined || pageSize === undefined) {
      return {};
    }
    return {
      params: { skip: page * pageSize, limit: pageSize },
    };
  },
});

export const reorderTextItems = createHttpRequest<
  { projectId: string; actions: IMoveTextItemsAction[] },
  Record<string, string>
>({
  method: "post",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-text-items`;
  },
  getConfig({ actions }) {
    return {
      data: { actions },
    };
  },
});

export const getAllTags = createHttpRequest<{ projectId: string }, string[]>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/tags`;
  },
  getConfig({ projectId }) {
    return {
      params: { projectId },
    };
  },
});

export const updateBlocks = createHttpRequest<
  { projectId: string; blocks: IFDittoProjectBlockUpdate[] },
  IFDittoProjectBlock[]
>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks`;
  },
  getConfig({ blocks }) {
    return {
      data: {
        blocks,
      },
    };
  },
});

export const reorderBlocks = createHttpRequest<{ projectId: string; blockIds: string[]; newIndex: number }>({
  method: "patch",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/blocks/reorder`;
  },
  getConfig({ blockIds, newIndex }) {
    return {
      data: {
        blockIds,
        newIndex,
      },
    };
  },
});

export const createPreviewsJob = createHttpRequest<{ projectId: string }, { jobId: string }>({
  method: "post",
  url: "/jobs/ditto-project-figma-previews",
  getConfig({ projectId }) {
    return {
      data: {
        projectId,
      },
    };
  },
});

export const getActivePreviewsJob = createHttpRequest<{ projectId: string }, { jobId: string | null }>({
  method: "get",
  getUrl({ projectId }) {
    return `/jobs/ditto-project-figma-previews/${projectId}`;
  },
});

export const getTopLevelFramesMap = createHttpRequest<{ projectId: string }, Record<string, IFFigmaGroup[]>>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/top-level-frames`;
  },
});

export const getFramePreviewsMap = createHttpRequest<{ projectId: string }, IGetFramePreviewsData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items/frame-previews`;
  },
});

export const getTextItemsMap = createHttpRequest<{ projectId: string }, ITextItemsMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/text-items-map`;
  },
});

export const getSyncedTextNodesMap = createHttpRequest<{ projectId: string }, ISyncedTextNodesMapData>({
  method: "get",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/synced-text-nodes-map`;
  },
});

export const createDittoProject = createHttpRequest<ICreateDittoProject, IDittoProject>({
  method: "post",
  url: "/ditto-project/create",
  getConfig: (args) => ({
    data: args,
  }),
});

export const moveProjectToFolder = createHttpRequest<{ projectId: string; folderId: string | null }, IDittoProject>({
  method: "put",
  getUrl({ projectId }) {
    return `/ditto-project/${projectId}/move-to-folder`;
  },
  getConfig({ folderId }) {
    return {
      data: { folderId },
    };
  },
});

export const getMostRecentCommentThreadId = createHttpRequest<
  { projectId: string; textItemId: string; type?: ICommentType },
  Pick<ICommentThread, "_id"> | null
>({
  method: "get",
  getUrl({ projectId, textItemId }) {
    return `/ditto-project/${projectId}/text-items/${textItemId}/comments/most-recent`;
  },
  getConfig({ type }) {
    return {
      params: { type },
    };
  },
});
