import ImageSearch from "@mui/icons-material/ImageSearch";
import NotesIcon from "@mui/icons-material/Notes";
import classNames from "classnames";
import React from "react";
import ReactTooltip from "react-tooltip";
import ImagePreviewSpinner from "../../../shared/ImagePreviewSpinner";

import style from "./style.module.css";

interface Props {
  value: "TEXT" | "DESIGN";
  onToggle: () => void;
  loading: boolean;
  disabled: boolean;
}
const TextPreviewToggle = (props: Props) => {
  const { value, onToggle, disabled, loading } = props;
  return (
    <div
      data-tip
      data-for="image-preview-spinner"
      className={classNames({
        [style.toggle]: true,
        [style.disabled]: disabled,
      })}
      onClick={() => !disabled && onToggle()}
    >
      <div className={style.background}>
        <NotesIcon className={classNames([style.icon, style.textIcon])} />
        {!loading && <ImageSearch className={classNames([style.icon, style.designIcon])} />}
        {loading && <ImagePreviewSpinner className={classNames([style.icon, style.spinner, style.designIcon])} />}
      </div>
      <div
        className={classNames({
          [style.innerToggle]: true,
          [style.design]: value === "DESIGN",
        })}
      >
        {value === "DESIGN" && (
          <>
            {loading && <ImagePreviewSpinner className={classNames([style.icon, style.spinner])} />}
            {!loading && <ImageSearch className={style.icon} />}
            <span>Design</span>
          </>
        )}
        {value === "TEXT" && (
          <>
            <NotesIcon className={style.icon} />
            <span>Text</span>
          </>
        )}
      </div>
      {loading && disabled && (
        <ReactTooltip id="image-preview-spinner" place="bottom" effect="solid">
          Fetching design previews..
        </ReactTooltip>
      )}
    </div>
  );
};

export default TextPreviewToggle;
