import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useWorkspace } from "@/store/workspaceContext";
import Add from "@mui/icons-material/Add";
import Lock from "@mui/icons-material/Lock";
import { DEFAULT_PERMISSION_GROUP_NAMES } from "@shared/lib/PermissionGroups";
import { IFPermissionGroupWithUsers, IFUser } from "@shared/types/User";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import http, { API } from "../../../http";
import LoadingState from "../../compresults/LoadingState";
import { IPermissionGroupState } from "../usePermissionGroups";
import CreateGroupModal from "./CreateGroupModal";
import { DeleteGroupModal } from "./DeleteGroupModal";
import { OptionsToggle, PermissionGroupDetailView } from "./PermissionGroupDetailView";
import style from "./style.module.css";
import { PermissionGroupRowProps } from "./types";

interface PermissionGroupsListProps {
  premissionGroupState: IPermissionGroupState;
}

const PermissionGroupsList = (props: PermissionGroupsListProps) => {
  const { users, fetchWorkspaceUsers } = useWorkspace();
  const { refreshUser } = useAuthenticatedAuth();
  const history = useHistory();
  const path = window.location.pathname.split("/")[1];

  const workspaceUsers: IFUser[] = users as IFUser[];
  const { permissionGroupId } = useParams<{ permissionGroupId?: string }>();

  const {
    loading,
    permissionGroups,
    setPermissionGroups,
    projectFolders,
    componentFolders,
    variantFolders,
    variableFolders,
    fetchWorkspacePermissionGroups,
  } = props.premissionGroupState;

  const [selectedGroup, setSelectedGroup] = useState<IFPermissionGroupWithUsers | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState<IFPermissionGroupWithUsers | null>(null);

  useEffect(
    function handleSelectedPermissionGroup() {
      if (permissionGroupId) {
        if (selectedGroup?._id === permissionGroupId) {
          return;
        } else {
          const group = permissionGroups.find((group) => group._id === permissionGroupId);
          if (group) {
            setSelectedGroup(group);
          }
        }
      } else {
        setSelectedGroup(null);
      }
    },
    [permissionGroupId, selectedGroup, permissionGroups]
  );

  const handleSelectGroup = (groupId: string) => {
    history.push(`/${path}/permissions/${groupId}`);
  };

  const handleCreateNewGroup = (name: string) => {
    http.post(`/workspace/permission-group`, { name }).then((res) => {
      const newGroup = { ...res.data, users: [], invitedUserEmails: [] };
      setPermissionGroups((prev) => [
        ...prev.slice(0, 3),
        ...[...prev.slice(3), newGroup].sort((a, b) => a.name.localeCompare(b.name)),
      ]);
      setShowCreateModal(false);
      history.push(`/${path}/permissions/${newGroup._id}`);
    });
  };

  const handleBack = () => {
    history.push(`/${path}/permissions`);
  };

  const handleSaveGroup = async (groupId: string, updatedGroup: IFPermissionGroupWithUsers) => {
    try {
      // Backend update has three parts:
      if (!DEFAULT_PERMISSION_GROUP_NAMES.includes(groupId)) {
        // 1. update the permissionGroup permissions & name if not a default group
        const updateRes = await http.put(`/workspace/permission-group/${groupId}`, {
          name: updatedGroup.name,
          permissions: updatedGroup.permissions,
        });
      }

      // 2. update users who were added to/removed from the group
      const usersRes = await http.put(`/workspace/permission-group/${groupId}/users`, {
        userIds: updatedGroup.users.map((u) => u._id),
      });

      // if we were adding users to a default group, we might have removed them from another group

      // 3. create user invites for new users invited to the group
      const { url } = API.invite.post.create;
      const invitesRes = await http.post(url, {
        emails: updatedGroup.invitedUserEmails,
        role: "editor",
        permissionGroups: [updatedGroup._id],
        type: "workspace",
      });

      // update the currently selected group
      setSelectedGroup(updatedGroup);

      // update the list of permission groups from the backend
      fetchWorkspacePermissionGroups();

      // if we updated any users, fetch the updated data for both the workspace users and the current user
      if (usersRes.data?.usersAdded.length > 0 || usersRes.data?.usersRemoved.length > 0) {
        fetchWorkspaceUsers();
        refreshUser();
      }
    } catch (error) {
      console.error("Error updating permission group:", error);
    }
  };

  const onDeleteGroupClick = (group: IFPermissionGroupWithUsers) => {
    setGroupToDelete(group);
  };

  const handleDeleteGroup = async (groupId: string) => {
    await http.delete(`/workspace/permission-group/${groupId}`);
    setPermissionGroups((prev) => {
      return prev.filter((group) => group._id !== groupId);
    });
    setSelectedGroup(null);
    setGroupToDelete(null);
    history.push(`/${path}/permissions`);
  };

  const handleRevokeInvite = async (email: string) => {
    const { url, body } = API.invite.delete.revoke;
    await http.delete(url, {
      data: body({
        email,
      }),
    });
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <div className={style.permissionGroupsList} data-testid="permission-groups-list">
      <div className={style.wrapper}>
        <div className={style.titleArea}>
          <h3>Permission Groups</h3>
          <p>Manage levels of access to resources for users in your workspace.</p>
          {selectedGroup === null && (
            <Button
              data-testid="create-permission-group-btn"
              className={style.createBtn}
              variant="outline-primary"
              onClick={() => setShowCreateModal(true)}
            >
              <Add className={style.icon} /> Create Permission Group
            </Button>
          )}
        </div>
        {selectedGroup === null && (
          <table>
            <tbody>
              <tr>
                <th>NAME</th>
                <th>RESOURCES</th>
                <th>LEVEL(S) OF ACCESS</th>
                <th>USERS</th>
                <th></th>
              </tr>
              {permissionGroups.map((group) => {
                let accessLevelText = "No Access";

                if (group.built_in) {
                  switch (group._id) {
                    case "admin":
                      accessLevelText = "Admin";
                      break;
                    case "editor":
                      accessLevelText = "Edit";
                      break;
                    case "commenter":
                      accessLevelText = "Comment";
                      break;
                  }
                } else {
                  const hasEditAccess =
                    group.permissions.resources.some((resource) =>
                      resource.access.some((a) => a.action.includes("edit"))
                    ) || ["admin", "editor"].includes(group._id);

                  const hasCommentAccess =
                    group.permissions.resources.some((resource) =>
                      resource.access.some((a) => a.action.includes("comment"))
                    ) || ["admin", "editor", "commenter"].includes(group._id);

                  if (hasEditAccess && hasCommentAccess) {
                    accessLevelText = "Edit, Comment";
                  } else if (hasCommentAccess) {
                    accessLevelText = "Comment";
                  } else if (hasEditAccess) {
                    accessLevelText = "Edit";
                  }
                }

                return (
                  <PermissionGroupRow
                    name={group.name}
                    builtIn={group.built_in}
                    accessLevelText={accessLevelText}
                    userCount={group.users.length}
                    resourceCount={Object.keys(group.permissions.resources).length}
                    onEdit={() => handleSelectGroup(group._id)}
                    onDeleteClick={() => onDeleteGroupClick(group)}
                    key={group._id}
                  />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {selectedGroup && (
        <PermissionGroupDetailView
          initialGroup={selectedGroup}
          handleBack={handleBack}
          handleSaveGroup={handleSaveGroup}
          handleDeleteGroup={handleDeleteGroup}
          handleRevokeInvite={handleRevokeInvite}
          setSelectedGroup={setSelectedGroup}
          projectFolders={projectFolders}
          componentFolders={componentFolders}
          variantFolders={variantFolders}
          variableFolders={variableFolders}
          workspaceUsers={workspaceUsers}
        />
      )}
      {showCreateModal && (
        <CreateGroupModal
          handleCreateNewGroup={handleCreateNewGroup}
          existingPermissionGroups={permissionGroups}
          setShowCreateModal={setShowCreateModal}
        />
      )}
      {groupToDelete && (
        <DeleteGroupModal
          handleDeleteGroup={handleDeleteGroup}
          onCancel={() => setGroupToDelete(null)}
          groupToDelete={groupToDelete}
        />
      )}
    </div>
  );
};

const PermissionGroupRow = (props: PermissionGroupRowProps) => {
  return (
    <tr>
      <td className={style.groupName}>
        <div className={style.groupNameFlex} data-testid="group-name">
          {props.name}
          {props.builtIn && <Lock data-tip data-for="default-tip" className={style.icon} />}
          <ReactTooltip id="default-tip" effect="solid">
            <span className={style.tooltipBody}>This is a default permission group, and cannot be deleted.</span>
          </ReactTooltip>
        </div>
      </td>
      <td data-testid="resource-count">{props.builtIn ? "All" : props.resourceCount}</td>
      <td data-testid="access-level">{props.accessLevelText}</td>
      <td data-testid="user-count">{props.userCount}</td>
      <td>
        <Dropdown>
          <Dropdown.Toggle as={OptionsToggle} />
          <Dropdown.Menu className={style.optionsMenu} alignRight>
            <Dropdown.Item className={style.dropdownItem} onClick={props.onEdit}>
              Edit group
            </Dropdown.Item>
            {!props.builtIn && (
              <Dropdown.Item className={classNames(style.dropdownItem, style.red)} onClick={props.onDeleteClick}>
                Delete group
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};
export default PermissionGroupsList;
