import { createHttpRequest } from "./lib/createHttpRequest";

export const authorizeSlackAccess = createHttpRequest<
  {
    code: string;
    redirectUri: string;
    projectId?: string;
    initializeConnection?: boolean;
  },
  void
>({
  method: "post",
  url: "/slack/authorize",
  getConfig: (args) => ({
    data: {
      ...args,
    },
  }),
});

export const revokeSlackAccess = createHttpRequest<{ projectId?: string; revokeConnection?: boolean }, void>({
  method: "post",
  url: "/slack/revoke",
  getConfig: (args) => ({
    data: {
      ...args,
    },
  }),
});
