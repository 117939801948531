import React from "react";
import { forEachBracketPair } from "../lib/text";
import { ActualComponentVariableSchema } from "../types/TextItem";
import { forEachVariable, getVariable, getVariablePlaceholder } from "../utils/variableInterpolation";

export interface IOptions {
  highlightBrackets?: boolean;
  classNameVariable?: string;
  classNameBracket?: string;
}

interface ITextEntityData {
  value: string;
  start: number;
  end: number;
}

type ITextEntity = ITextEntityData & {
  type: "variable" | "bracket";
};

/**
 * Takes information about a text item and returns JSX markup to render it,
 * wrapping special entities within the text in style-able spans.
 */
export function generateTextItemMarkup(
  text: string,
  variables: ActualComponentVariableSchema[],
  options: IOptions = {}
): JSX.Element[] {
  const textEntities = getTextEntities(text, options);
  return generateMarkupFromEntities(text, textEntities, variables, options);
}

export function getTextEntities(text: string, options: IOptions = {}): ITextEntity[] {
  if (!text) {
    return [];
  }

  const textEntities: ITextEntity[] = [];
  forEachVariable(text, (result) => textEntities.push({ ...result, type: "variable" }));
  if (options.highlightBrackets) {
    forEachBracketPair(text, (result) => {
      textEntities.push({ ...result, type: "bracket" });
    });
  }

  // Sort the results by the index that they start at;
  // this allows us to process them from left to right.
  textEntities.sort((a, b) => a.start - b.start);

  return textEntities;
}

export function generateMarkupFromEntities(
  text: string,
  textEntities: ITextEntity[],
  variables: ActualComponentVariableSchema[],
  options: IOptions = {}
): JSX.Element[] {
  if (!text) {
    return [];
  }

  let markup: JSX.Element[] = [];
  let lastIndex = 0;

  textEntities.forEach((entity, i) => {
    const { type, value, start, end } = entity;
    const preceding = text.substring(lastIndex, start);
    markup.push(<span key={`preceding-${type}-${value}-${i}`}>{preceding}</span>);

    if (type === "variable") {
      markup.push(
        getVariableMarkup({
          entity,
          lastIndex,
          options,
          variables,
        })
      );
    } else if (type === "bracket") {
      markup.push(
        getBracketMarkup({
          entity,
          lastIndex,
          options,
        })
      );
    }

    lastIndex = end;
  });

  const remainder = text.substring(lastIndex);
  if (remainder) {
    markup.push(<span key="remainder">{remainder}</span>);
  }

  return markup;
}

function getVariableMarkup(data: {
  entity: ITextEntityData;
  variables: ActualComponentVariableSchema[];
  lastIndex: number;
  options: IOptions;
}) {
  const {
    entity: { value: variableName },
    variables,
    lastIndex,
    options,
  } = data;

  const variable = getVariable(variableName, variables);
  const variableValue = getVariablePlaceholder(variable);

  const key = `variable-${variableName}-${lastIndex}`;

  if (variableValue) {
    return (
      <span className={options.classNameVariable} key={key}>
        {variableValue}
      </span>
    );
  }

  return <span key={key}>{`{{${variableName}}}`}</span>;
}

function getBracketMarkup(data: { entity: ITextEntityData; options: IOptions; lastIndex: number }) {
  const {
    entity: { value },
    lastIndex,
    options,
  } = data;

  const key = `bracket-${value}-${lastIndex}`;

  return (
    <span key={key} className={options.classNameBracket}>
      {value}
    </span>
  );
}
