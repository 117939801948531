import React from "react";
import BoltIcon from "../../icons/BoltIcon";
import style from "./style.module.css";

const QuickCategoriesTitle = (
  <div className={style.quickCategoriesTitle}>
    <BoltIcon className={style.icon} />
    Quick Categories
  </div>
);

export default QuickCategoriesTitle;
