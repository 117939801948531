import { Ditto, useDittoComponent } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface RoleInfo {
  name: string;
  renewals: { seats: number; date: Date; frequency: string }[];
  totalSeats: number;
  usedSeats: number;
}

export interface Props {
  roles: RoleInfo[];
  onClick?: () => void;
}
export default function SeatsPanel(props: Props) {
  const buttonText = useDittoComponent({
    componentId: "update-subscription-cta",
  });
  return (
    <Panel
      buttonType="secondary"
      buttonText={buttonText || ""}
      titleDittoComponentId="seats-panel-title"
      onClick={props.onClick}
    >
      <table className={style.table} data-testid="seats-panel">
        <tbody>
          <tr className={style.divider}>
            <td>
              <div className={style.header}>
                <Ditto componentId="role-header" />
              </div>
            </td>
            <td>
              <div className={style.headerValue}>
                <div className={style.flexRow}>
                  <span>
                    <Ditto componentId="quantity" />
                  </span>
                  <span>
                    <Ditto componentId="renewal-header" />
                  </span>
                </div>
              </div>
            </td>
          </tr>
          {props.roles.map((role, index) => (
            <tr key={index} className={style.divider} data-testid={`seats-row-${role.name}`}>
              <td className={style.title}>{role.name}</td>
              <td className={style.value}>
                <div className={style.flexRow}>
                  <div data-testid={`seats-row-${role.name}-quantity`}>
                    <span className={style.bold}>{role.totalSeats}&nbsp;&nbsp;</span>
                    <span>
                      <Ditto
                        componentId="seats-occupied"
                        variables={{
                          totalSeats: role.totalSeats,
                          usedSeats: role.usedSeats,
                        }}
                        count={role.totalSeats}
                      />
                    </span>
                  </div>
                  <div data-testid={`seats-row-${role.name}-renewal`}>
                    {role.renewals.map((renewal, index) => (
                      <div key={index}>
                        <Ditto
                          componentId="seats-renewal-message"
                          variables={{
                            numberOfSeats: renewal.seats,
                            frequency: renewal.frequency,
                            expandedDate: renewal.date.toLocaleDateString("en-US", {
                              month: "long",
                              year: "numeric",
                              day: "numeric",
                            }),
                          }}
                          count={renewal.seats}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  );
}
