import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import classNames from "classnames";
import React from "react";
import TimeAgo from "react-timeago";
import { IComment } from "../../../../shared/types/CommentThread";
import { IUser } from "../../../../shared/types/User";
import { getNoSecondsFormatter } from "../../../../shared/utils/timeAgoFormatters";
import Avatar from "../../atoms/Avatar";
import Button from "../../atoms/Button";
import Dot from "../../atoms/Dot";
import Text from "../../atoms/Text";
import style from "./index.module.css";

export type ICommentDisplayUser = Pick<IUser, "name" | "picture">;

interface IProps {
  className?: string;
  comment: IComment;
  user?: ICommentDisplayUser;
  isResolved: boolean; // Applies to the entire thread
  onResolve?: () => void; // Will only display resolve UI if this is provided
}

export function Comment({ className, comment, isResolved, user, onResolve }: IProps) {
  const userName = user?.name || comment.user_name;

  return (
    <div className={classNames(style.CommentWrapper, className)}>
      <div className={style.avatar}>
        <Avatar
          picture={user?.picture}
          userName={userName}
          style={isResolved ? { filter: "grayscale(100%)" } : undefined}
        />
      </div>
      <div className={style.commentHeader}>
        <div className={style.commentHeaderDetails}>
          <Text weight="medium" size="small" color={isResolved ? "secondary" : "primary"}>
            {userName}
          </Text>
          <Dot size={2} color="icon-secondary" />
          <TimeAgo
            className={style.date}
            date={comment.createdAt}
            minPeriod={30}
            formatter={getNoSecondsFormatter("< 1 min ago", true)}
          />
        </div>
        {!!onResolve && (
          <Button level="subtle" type="icon" onClick={onResolve}>
            {isResolved ? (
              <CheckCircleIcon className={style.resolveIcon} />
            ) : (
              <CheckCircleOutlineIcon className={style.resolveIcon} />
            )}
          </Button>
        )}
      </div>
      <Text
        size="base"
        weight="base"
        color={isResolved ? "secondary" : "primary"}
        className={style.commentTextContainer}
      >
        {comment.text}
      </Text>
    </div>
  );
}

export default Comment;
