import { useWorkspace } from "@/store/workspaceContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SelectInstance } from "react-select";
import UserSelect from "../UserSelect";
import style from "./style.module.css";

interface AssigneeDropdownProps {
  className?: string;
  onRemoveFilter?: () => void;
  setSelectedUserId: (userId: string | null) => void;
  selectedUserId: string | null;
}

const AssigneeDropdown = (props: AssigneeDropdownProps) => {
  const { setSelectedUserId, selectedUserId, className, onRemoveFilter } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const selectRef = useRef<SelectInstance | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { users } = useWorkspace();
  const formattedUserOptions = useMemo(() => {
    if (!users) return [];
    return users.map((user) => ({
      id: user._id,
      name: user.name,
    }));
  }, [users]);

  const selectedUser = useMemo(() => {
    if (!users) return null;
    return users.find((user) => user._id === selectedUserId);
  }, [users, selectedUserId]);

  useEffect(
    function deactiveDropdownOnClickOutside() {
      if (!showDropdown) {
        return;
      }

      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [showDropdown, setShowDropdown]
  );

  const toggleShowDropdown = () => {
    setShowDropdown((showDropdown) => !showDropdown);
  };

  useEffect(
    function focusSelectMenuOnDropdownOpen() {
      if (showDropdown) {
        selectRef.current?.focus();
      }
    },
    [showDropdown]
  );

  const handleSelectChange = (userId: string | null) => {
    setSelectedUserId(userId);

    // don't close the dropdown if the user is clearing the filter with backspace;
    // they probably want to select a new user
    if (userId !== null) setShowDropdown(false);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(style.assigneeDropdown, className, {
        [style.userSelected]: !!selectedUser,
      })}
    >
      <div className={style.dropdownToggle} onClick={toggleShowDropdown}>
        <AccountCircleIcon className={style.icon} />
        <span className={style.assigneeName}>{selectedUser ? selectedUser.name : "Assignee"}</span>
        <KeyboardArrowDownIcon className={style.downIcon} />
      </div>
      {showDropdown && (
        <div className={style.userSelectDropdown}>
          <UserSelect
            fontSize="12px"
            placeholder="Search for a user"
            ref={selectRef}
            setSelectedUserId={handleSelectChange}
            selectedUserId={selectedUserId}
            users={formattedUserOptions}
            menuIsOpen={showDropdown}
            onRemoveFilter={onRemoveFilter}
          />
        </div>
      )}
    </div>
  );
};

export default AssigneeDropdown;
