import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

import RouteIcon from "../../../../../assets/Route.svg";

import style from "./style.module.css";

interface EnableLinkingButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  show: boolean;
  enabling: boolean;
}

const EnableLinkingButton = (props: EnableLinkingButtonProps) => {
  const { show, enabling, ...otherProps } = props;

  useEffect(() => {
    // Prevents an intermittent bug from happening
    // where the tooltip sticks around after clicking the button
    // even though this component has unmounted.
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  if (!show) {
    return <React.Fragment />;
  }

  return (
    <>
      <button
        {...otherProps}
        data-html
        data-tip="Allow group to be connected to a <br/>Figma file or to development"
        data-offset="{'top': -8 }"
        data-place="top"
        data-effect="solid"
        data-class={style.tooltip}
        tabIndex={-1}
        disabled={enabling}
      >
        <img src={RouteIcon} />
        {enabling ? <span className={style.enablingText}>Enabling</span> : "Enable linking"}
      </button>
      <ReactTooltip />
    </>
  );
};

export default EnableLinkingButton;
