/**
 * Given a Figma node's parentNodePath, return the node ID of the top-level frame.
 * This is always the second entry in a slash-delimited path, *unless* we're in the (fairly uncommon) edge
 * case where a text node is directly placed on a page -- in that case, we return null.
 */
export function getTopLevelFrameId(parentNodePath: string) {
  const pathParts = parentNodePath.split("/");

  return pathParts[1] ?? undefined;
}
