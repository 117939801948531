import { ZWorkspaceWebhookEndpoint } from "@shared/types/Workspace";
import { z } from "zod";

export const WEBHOOK_SIGNING_KEY_MIN_LENGTH = 16;

export const ZCreateWebhookRequestBody = z.object({
  _id: z.string().optional(),
  name: ZWorkspaceWebhookEndpoint.shape.name,
  url: ZWorkspaceWebhookEndpoint.shape.url,
  filters: ZWorkspaceWebhookEndpoint.shape.filters,
  events: ZWorkspaceWebhookEndpoint.shape.events,
});

export const ZUpdateWebhookRequestBody = z.object({
  _id: z.string(),
  name: ZWorkspaceWebhookEndpoint.shape.name,
  url: ZWorkspaceWebhookEndpoint.shape.url,
  filters: ZWorkspaceWebhookEndpoint.shape.filters,
  events: ZWorkspaceWebhookEndpoint.shape.events,
});

type WebhookEventName =
  | "Component_TextChange"
  | "Component_StatusChange"
  | "Component_IdChange"
  | "Component_Creation"
  | "Component_Deletion"
  | "TestEvent";

export const webhookToggleLabels: Record<WebhookEventName, string> = {
  Component_TextChange: "Component text change",
  Component_StatusChange: "Component status change",
  Component_Creation: "Component creation",
  Component_IdChange: "Component developer ID change",
  Component_Deletion: "Component deletion",
  TestEvent: "Test event",
};

export const webhookInlineLabels: Record<WebhookEventName, string> = {
  Component_TextChange: "Text change",
  Component_StatusChange: "Status change",
  Component_Creation: "Creation",
  Component_IdChange: "Developer ID change",
  Component_Deletion: "Deletion",
  TestEvent: "Test event",
};

export const WEBHOOK_DOCUMENTATION_LINK = "https://developer.dittowords.com/ditto-webhooks";
