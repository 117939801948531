export enum BillingErrors {
  INVALID_WORKSPACE = 120,
  INVALID_SEATS = 130,
  UNABLE_TO_CREATE_STRIPE_CUSTOMER = 140,
  INVALID_PLAN_CHANGE = 150,
  INVALID_INPUTS = 160,
  MISSING_NEW_CUSTOMER_PARAMS = 170,
  TOO_MANY_SUBSCRIPTIONS = 180,
  ATTEMPTED_TO_MODIFY_YEARLY_SUBSCRIPTION = 190,
  MISSING_YEARLY_OR_MONTHLY_INPUTS = 200,
  UNABLE_TO_CREATE_NEW_ANNUAL_SUBSCRIPTION = 210,
  UNABLE_TO_CREATE_NEW_MONTHLY_SUBSCRIPTION = 220,
  MISSING_MONTHLY_EDITOR_SUBSCRIPTION = 230,
  STRIPE_CARD_ERROR = 402,
}
