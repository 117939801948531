import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import classnames from "classnames";
import React from "react";

import style from "./style.module.css";

interface Props {
  text: string;
  linkColor?: "primary" | "secondary";
}

const URLLink = ({ text, linkColor }: Props) => {
  const href = /https?:\/\//.test(text) ? text : `https://${text}`;

  return (
    <a
      className={classnames({
        [style.urlLink]: linkColor === "primary",
        [style.urlLinkSecondary]: linkColor !== "primary",
      })}
      href={href}
      target="_blank"
    >
      {text}
      <OpenInNewIcon
        className={classnames({
          [style.urlLinkIcon]: linkColor === "primary",
          [style.urlLinkIconSecondary]: linkColor !== "primary",
        })}
      ></OpenInNewIcon>
    </a>
  );
};

export default URLLink;
