import React from "react";
const ThumbnailBarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g id="thumbnail_bar">
        <mask id="mask0_5092_2036" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5092_2036)">
          <path
            id="thumbnail_bar_2"
            d="M2.66683 13.3333C2.30016 13.3333 1.98627 13.2027 1.72516 12.9416C1.46405 12.6805 1.3335 12.3666 1.3335 12V3.99996C1.3335 3.63329 1.46405 3.3194 1.72516 3.05829C1.98627 2.79718 2.30016 2.66663 2.66683 2.66663H13.3335C13.7002 2.66663 14.0141 2.79718 14.2752 3.05829C14.5363 3.3194 14.6668 3.63329 14.6668 3.99996V12C14.6668 12.3666 14.5363 12.6805 14.2752 12.9416C14.0141 13.2027 13.7002 13.3333 13.3335 13.3333H2.66683ZM6.66683 12H13.3335V3.99996H6.66683V12ZM5.3335 12V3.99996H2.66683V12H5.3335Z"
            fill="#737373"
          />
        </g>
      </g>
    </svg>
  );
};

export default ThumbnailBarIcon;
