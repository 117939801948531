import { useLayoutEffect, useMemo, useRef, useState } from "react";

const VARIANT_TAB_MAX_WIDTH = 80;
const BASE_TAB_WIDTH = 75;
const ADD_VARIANT_BTN_WIDTH = 100;

function useVariantTabs(props: { frameVariants: any[]; activeVariantIndex: number }) {
  const { frameVariants, activeVariantIndex } = props;

  const [hoveredVariantId, setHoveredVariantId] = useState<string | null>(null);

  const [maxVariantTabs, setMaxVariantTabs] = useState(0);

  // Calculate max tabs based on container width
  const groupContainerRef = useRef<HTMLDivElement | null>(null);
  function calculateMaxVariantTabs() {
    if (!groupContainerRef.current) return;

    const { width } = groupContainerRef.current.getBoundingClientRect();
    const maxTabs = Math.floor((width - BASE_TAB_WIDTH - ADD_VARIANT_BTN_WIDTH) / VARIANT_TAB_MAX_WIDTH);

    setMaxVariantTabs(maxTabs);
  }

  useLayoutEffect(function variantTabsOnMount() {
    calculateMaxVariantTabs();

    window.addEventListener("resize", calculateMaxVariantTabs);

    return () => {
      window.removeEventListener("resize", calculateMaxVariantTabs);
    };
  }, []);

  // The index of the active variant lives in the project state; we need to calculate which element
  // in frameVariants to use as our active variant based on this index.
  const activeVariant = useMemo(() => {
    if (!frameVariants || activeVariantIndex > frameVariants.length - 1) {
      return null;
    }

    if (activeVariantIndex === -1) {
      return { id: "__base__", name: "Base" };
    }

    return frameVariants[activeVariantIndex];
  }, [frameVariants, activeVariantIndex]);

  // Variant tabs array based on max tabs and active variant
  const variantTabs = useMemo(() => {
    // Don't show tabs at all if there are no variants on the frame
    if (!frameVariants?.length) return [];

    const actualMaxTabs = maxVariantTabs - 2; // -2 for collapsed tab and active variant after collapsed

    // If we've got room for everything, show everything
    if (frameVariants.length <= actualMaxTabs) {
      return [{ id: "__base__", name: "Base" }, ...frameVariants];
    }

    // Collapse all the variants that can't fit in the tab bar
    else if (frameVariants.length > actualMaxTabs) {
      const tabsToShow = frameVariants.slice(0, actualMaxTabs);
      const tabsToCollapse = frameVariants.slice(actualMaxTabs);

      const activeVariantIsHidden = tabsToCollapse.find((v) => v.id === activeVariant?.id);

      // If the hovered variant is currently hidden, we want to pop it out of the collapsed list
      // and show it after the collapsed tab; don't duplicate this work if it's already active, though.
      const hoveredVariantIsHidden = tabsToCollapse.find((v) => v.id === hoveredVariantId);
      const hoveredVariantIsActive = hoveredVariantId === activeVariant?.id;
      const hoveredVariantIndex = frameVariants.findIndex((v) => v.id === hoveredVariantId);

      const tabs = [
        { id: "__base__", name: "Base" },
        ...tabsToShow,
        {
          id: "__collapsed__",
          name: `${tabsToCollapse.length} other variant${tabsToCollapse.length != 1 ? "s" : ""}`,
        },
        // If the active variant is one of the hidden ones, show it after the collapsed tab
        ...(activeVariantIsHidden ? [frameVariants[activeVariantIndex]] : []),
        // same logic for the hovered variant
        ...(hoveredVariantIsHidden && !hoveredVariantIsActive ? [frameVariants[hoveredVariantIndex]] : []),
      ];

      return tabs;
    }
  }, [maxVariantTabs, activeVariant, frameVariants, activeVariantIndex, hoveredVariantId]);

  // The activeVariantIndex passed into this hook refers to the position of the active variant in the frameVariants array
  // We need to calculate the active tab index based on the variantTabs array
  const activeTabIndex = useMemo(() => {
    if (!variantTabs) return;

    const activeTabIndex = variantTabs.findIndex((v) => v.id === activeVariant?.id);
    return activeTabIndex < 0 ? 0 : activeTabIndex;
  }, [variantTabs, activeVariant, activeVariantIndex]);

  const getVariantIndexById = (variantId: string) => {
    const variantIndex = frameVariants?.findIndex((v) => v.id === variantId) ?? -1;

    return variantIndex;
  };

  return {
    activeVariant,
    variantTabs,
    groupContainerRef,
    activeTabIndex,
    getVariantIndexById,
    hoveredVariantId,
    setHoveredVariantId,
  };
}

export default useVariantTabs;
