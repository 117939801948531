import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import InsightsIcon from "../../icons/InsightsIcon";
import { ActualComponentInterface, ComponentInterface } from "../types";

import style from "./style.module.css";

interface Props {
  selectedComp: ComponentInterface;
  comp: ActualComponentInterface;
}

const MAX_PROJECT_INSTANCE_LINKS_TO_SHOW = 10;

const ComponentUsageMessage = (props: Props) => {
  const { selectedComp, comp } = props;

  const currentProjectId = comp?.doc_ID;

  const projectInstancesMap = useMemo(
    () =>
      (selectedComp?.instances || []).reduce((acc, curr) => {
        const projectInfo = curr?.doc_ID;
        if (projectInfo) {
          const projectId = projectInfo._id;
          if (!acc[projectId]) acc[projectId] = { name: projectInfo.doc_name, count: 0 };
          acc[projectId].count++;
        }

        return acc;
      }, {}),
    [selectedComp]
  );

  const numProjects = useMemo(() => Object.keys(projectInstancesMap).length, [projectInstancesMap]);
  const otherProjects = useMemo(() => {
    return projectInstancesMap[currentProjectId] ? numProjects - 1 : numProjects;
  }, [projectInstancesMap, currentProjectId, numProjects]);

  const otherProjectInstances = useMemo(() => {
    return selectedComp.instances.filter((instance) => instance.doc_ID && currentProjectId !== instance.doc_ID._id);
  }, [selectedComp, currentProjectId]);

  const otherProjectsCount = otherProjectInstances.length;
  const projectInstances = useMemo(
    () => projectInstancesMap[currentProjectId]?.count || 0,
    [projectInstancesMap, currentProjectId, selectedComp]
  );

  const isDraftComponent = selectedComp?.instances?.length === 1;
  const isUsedInOtherProjects = otherProjects > 0;
  const currentProjectMsg =
    projectInstances > 0
      ? `There ${projectInstances !== 1 ? "are" : "is"} ${projectInstances}${
          otherProjectsCount > 0 ? " other " : ""
        } instance${projectInstances !== 1 ? "s" : ""} in this project.`
      : "There are none in this project so far.";

  const projectLinks = useMemo(() => {
    const baseLinks = Object.keys(projectInstancesMap)
      .filter((projectId) => projectId !== currentProjectId)
      .map((projectId) => (
        <a key={projectId} className={style.projectLink} href={`/projects/${projectId}`} target="_blank">
          {projectInstancesMap[projectId].name}
        </a>
      ));
    if (baseLinks.length > MAX_PROJECT_INSTANCE_LINKS_TO_SHOW) {
      const numOtherProjects = baseLinks.length - MAX_PROJECT_INSTANCE_LINKS_TO_SHOW;
      const otherText = `...and ${numOtherProjects} other${numOtherProjects > 1 ? "s" : ""}`;
      return baseLinks.slice(0, MAX_PROJECT_INSTANCE_LINKS_TO_SHOW).concat(
        <a
          key="other-projects"
          className={style.projectLink}
          href={`/components/${selectedComp._id}#instances`}
          target="_blank"
        >
          {otherText}
        </a>
      );
    }
    return baseLinks;
  }, [projectInstancesMap, currentProjectId]);
  return (
    <div className={style.componentUsageInfo}>
      <InsightsIcon className={style.icon} />
      <div>
        {isDraftComponent && "You’re the first one to attach this component!"}
        {!isDraftComponent && (
          <div>
            {!isUsedInOtherProjects && "This component isn’t attached in any other projects. "}
            {isUsedInOtherProjects && (
              <>
                This component is used {otherProjectsCount} time
                {otherProjectsCount !== 1 ? "s" : ""} in{" "}
                <span
                  data-tip
                  data-for={otherProjectsCount > 0 ? "project-names" : "not_applicable"}
                  className={style.otherProjectNames}
                >
                  {otherProjects} other project{otherProjects !== 1 ? "s" : ""}.
                </span>
              </>
            )}{" "}
            {currentProjectMsg}
          </div>
        )}
        <ReactTooltip id="project-names" effect="solid" place="top" clickable={true} multiline={true} delayHide={1000}>
          <div className={style.projectNames}>{projectLinks}</div>
        </ReactTooltip>
      </div>
    </div>
  );
};

export default ComponentUsageMessage;
