import React from "react";

export default function (props: { fill: string; width?: string; height?: string }) {
  return (
    <svg
      width={props.width || "12"}
      height={props.height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_199_579)">
        <path
          d="M3.75 2.8L5 3.5L4.3 2.25L5 1L3.75 1.7L2.5 1L3.2 2.25L2.5 3.5L3.75 2.8ZM9.75 7.7L8.5 7L9.2 8.25L8.5 9.5L9.75 8.8L11 9.5L10.3 8.25L11 7L9.75 7.7ZM11 1L9.75 1.7L8.5 1L9.2 2.25L8.5 3.5L9.75 2.8L11 3.5L10.3 2.25L11 1ZM7.185 3.645C6.99 3.45 6.675 3.45 6.48 3.645L0.644999 9.48C0.449999 9.675 0.449999 9.99 0.644999 10.185L1.815 11.355C2.01 11.55 2.325 11.55 2.52 11.355L8.35 5.525C8.545 5.33 8.545 5.015 8.35 4.82L7.185 3.645ZM6.67 6.39L5.61 5.33L6.83 4.11L7.89 5.17L6.67 6.39Z"
          fill={props.fill || "#787878"}
        />
      </g>
      <defs>
        <clipPath id="clip0_199_579">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
