import classnames from "classnames";
import React, { useMemo, useState } from "react";
import KebabMenu from "../KebabMenu";
import style from "./style.module.css";

export interface IChangeItemWithContextMenuProps {
  key?: string;
  user?: string;
  timeAgo: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  contextMenuOptions: { value: string; displayName: string }[];
  onContextMenuOptionClick: (value: string) => void;
  borderedUi?: boolean;
}

export default function ChangeItemWithContextMenu(props: IChangeItemWithContextMenuProps) {
  const [showContextMenu, setShowContextMenu] = useState(false);

  const metaString = useMemo(() => {
    if (props.user && props.timeAgo) {
      return (
        <span>
          {props.user}, {props.timeAgo}
        </span>
      );
    } else if (props.user) {
      return props.user;
    } else if (props.timeAgo) {
      return props.timeAgo;
    } else {
      return "";
    }
  }, [props.user, props.timeAgo]);

  return (
    <div
      className={classnames({
        [style.kebabMenuContainer]: true,
        [style.container]: true,
        [style.bordered]: props.borderedUi,
      })}
      key={props.key}
    >
      {!props.borderedUi && (
        <div>
          <div className={style.meta}>{metaString}</div>
          {props.contextMenuOptions.length > 0 && (
            <div onClick={() => setShowContextMenu(!showContextMenu)}>
              <KebabMenu className={style.kebabMenu} />
            </div>
          )}
        </div>
      )}
      <div onClick={props.onClick}>{props.children}</div>
      {props.borderedUi && (
        <div>
          <div className={style.borderedMeta}>{metaString}</div>
          {props.contextMenuOptions.length > 0 && (
            <div onClick={() => setShowContextMenu(!showContextMenu)}>
              <KebabMenu className={style.kebabMenu} />
            </div>
          )}
        </div>
      )}
      {showContextMenu && (
        <div
          className={style.contextMenu}
          onClick={() => setShowContextMenu(false)}
          onMouseLeave={() => setShowContextMenu(false)}
        >
          {props.contextMenuOptions.map((option) => (
            <div className={style.contextItem} onClick={() => props.onContextMenuOptionClick(option.value)}>
              {option.displayName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
