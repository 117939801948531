import { userHasPermission } from "@shared/frontend/userPermissionContext";
import React, { useEffect, useState } from "react";
import ModalFooter from "react-bootstrap/ModalFooter";
import FolderSelectCreatable from "../../../../components/FolderSelect/Creatable";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ModalBase, { ModalBody } from "../../../../components/shared/ModalBase";
import http, { API } from "../../../../http";
import style from "./style.module.css";

interface FoldersLoading {
  loaded: false;
}

interface FoldersLoaded {
  loaded: true;
  folders: { _id: string; name: string }[];
}

type FolderState = FoldersLoading | FoldersLoaded;

type MoveData = { type: "none" } | { type: "new"; name: string } | { type: "existing"; folderId: string };

interface Props {
  numberOfComponents: number;
  selectedFolderId: string | null;
  onMove: (data: MoveData) => void;
  onHide: () => void;
}

const MoveToFolderModal = (props: Props) => {
  const { numberOfComponents, onMove, onHide } = props;

  const [selectedFolder, setSelectedFolder] = useState<{
    _id: string;
    name: string;
  } | null>(null);

  const [folders, setFolders] = useState<FolderState>({
    loaded: false,
  });

  useEffect(
    function loadFolders() {
      try {
        const { url } = API.componentFolder.get.folders;
        http.get<{ _id: string; name: string; isSample: boolean }[]>(url).then(({ data }) => {
          setFolders({
            loaded: true,
            folders: [
              {
                _id: "__none__",
                name: "None",
              },
              ...data.filter((f) => !f.isSample),
            ],
          });
        });
      } catch (error) {
        console.error("Error getting component folders:", error);
      }
    },
    [setFolders]
  );

  // disable move button if no folder is selected or if the selected folder is the same as the current folder
  const moveButtonDisabled =
    !selectedFolder || (props.selectedFolderId && selectedFolder._id === props.selectedFolderId);

  const onMoveClick = () => {
    if (!selectedFolder || selectedFolder._id === "__none__") {
      onMove({ type: "none" });
      return;
    }

    if (selectedFolder._id === "__new__") {
      onMove({ type: "new", name: selectedFolder.name });
      return;
    }

    onMove({ type: "existing", folderId: selectedFolder._id });
  };

  const onChange = (option: { label: string; value: string; __isNew__: boolean }) => {
    if (!option) {
      return;
    }

    if (option.__isNew__) {
      setSelectedFolder({
        _id: "__new__",
        name: option.label,
      });
      return;
    }

    setSelectedFolder({
      _id: option.value,
      name: option.label,
    });
  };

  const title = numberOfComponents > 1 ? "Move components" : "Move component";

  const modalText =
    numberOfComponents > 1 ? (
      <span>
        Please select the folder where you want to move these <b>{numberOfComponents}</b> components.
      </span>
    ) : (
      <span>Please select the folder where you want to move this component.</span>
    );

  const buttonText = numberOfComponents > 1 ? `Move ${numberOfComponents} Components` : "Move Component";

  const canCreateFolder = userHasPermission("component_folder:create");

  return (
    <ModalBase onHide={onHide} title={title}>
      <ModalBody>
        <div className={style.text}>{modalText}</div>
        <FolderSelectCreatable
          folders={folders.loaded ? folders.folders : []}
          onChange={onChange}
          currentFolderId={props.selectedFolderId || ""}
          value={selectedFolder ? { value: selectedFolder._id, label: selectedFolder.name } : null}
          canCreateFolder={canCreateFolder}
        />
      </ModalBody>
      <ModalFooter className={style.buttons}>
        <ButtonPrimary onClick={onMoveClick} text={buttonText} disabled={Boolean(moveButtonDisabled)} />
      </ModalFooter>
    </ModalBase>
  );
};

export default MoveToFolderModal;
