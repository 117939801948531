import React from "react";
const InstantMixIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M206.924-170.001v-282.308h-80v-59.998h219.998v59.998h-80v282.308h-59.998Zm0-437.692v-182.306h59.998v182.306h-59.998Zm163.077 0v-59.998h80v-122.308h59.998v122.308h80v59.998H370.001Zm80 437.692v-342.306h59.998v342.306h-59.998Zm243.077 0v-122.308h-80v-59.998h219.998v59.998h-80v122.308h-59.998Zm0-277.692v-342.306h59.998v342.306h-59.998Z" />
    </svg>
  );
};

export default InstantMixIcon;
