import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import style from "./style.module.css";

const RemoveUserModal = ({ user, onHide, handleRemoveUser }) => {
  return (
    <BootstrapModal show={true} onHide={onHide} centered>
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          Are you sure you want to remove <span className={style.greyText}>{user.name}</span>?
        </BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <div className={style.body}>{user.name} will lose access to this workspace in both the web-app and plugin.</div>
        <div className={style.btnWrapper}>
          <ButtonSecondary text="Cancel" onClick={onHide} />
          <ButtonPrimary text="Remove" onClick={() => handleRemoveUser(user)} />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default RemoveUserModal;
