import { atom, Getter } from "jotai";
import { unwrap } from "jotai/utils";
import isEqual from "lodash.isequal";
import { REFRESH_SILENTLY } from "./symbols";

interface IAsyncMutableDerivedAtomProps<T> {
  /**
   * The function to load the initial data for the atom. Also used to refresh the data.
   * @param get The jotai getter method. USe this to create a dependency on any atom that should trigger a refresh.
   * @returns The initial data for the atom.
   */
  loadData: (get: Getter) => T | Promise<T>;
  /**
   * The debug label for the atom.
   */
  debugLabel?: string;
}

/**
 * Creates an async mutable derived atom. What that means is that this atom will initially load data asynchronously and
 * then allow you to override that data with a new value. In practice, you'll want to use this atom type for any one off
 * data that you want to load asynchronously and then allow the user to modify (e.g. the current project).
 *
 * Notable behaviors:
 * -- This atom returns a Promise when it's uninitialized -- if you consume it in React, expect it to suspend.
 * -- This atom will *only* suspend on initial load:
 *    -- Synchronous mutations with set() will be reflected immediately
 *    -- Subsequent async calls to the loadData function (due to e.g. upstream state changes) will not suspend. The
 *       previous data is cached and returned immediately.
 *
 * @returns An object with two properties:
 * - valueAtom: The atom that holds the current value of the data. This atom is read/write.
 * - refreshAtom: The atom that triggers a refresh of the data.
 */
export default function asyncMutableDerivedAtom<T>(props: IAsyncMutableDerivedAtomProps<T>) {
  const debugPrefix = props.debugLabel ? `${props.debugLabel}` : "AsyncMutableDerivedAtom";
  const _versionAtom = atom(0);

  const _initialValueAtom = atom((get) => {
    get(_versionAtom);
    return { id: crypto.randomUUID().toString(), data: props.loadData(get) };
  });

  const initDataAtom = atom(async (get) => await get(_initialValueAtom).data);
  const cacheAtom = unwrap(initDataAtom, (prev) => prev);

  const _overrideAtom = atom<
    { override: T | Promise<T>; overriddenVersion: number; initialAtomId: string } | undefined
  >(undefined);

  const valueAtom = atom(
    (get) => {
      const initialValue = get(_initialValueAtom);
      const cachedInitialValue = get(cacheAtom);
      const version = get(_versionAtom);
      const override = get(_overrideAtom);

      if (override && override.overriddenVersion === version && initialValue.id === override.initialAtomId) {
        return override.override;
      }

      if (cachedInitialValue) {
        return cachedInitialValue;
      }

      return initialValue.data;
    },
    (get, set, newValue: T | Promise<T>) => {
      set(_overrideAtom, {
        override: newValue,
        overriddenVersion: get(_versionAtom),
        initialAtomId: get(_initialValueAtom).id,
      });
    }
  );

  const refreshAtom = atom(null, async (get, set, value: null | typeof REFRESH_SILENTLY = REFRESH_SILENTLY) => {
    if (value === REFRESH_SILENTLY) {
      const data = await props.loadData(get);
      set(_overrideAtom, {
        override: data,
        overriddenVersion: get(_versionAtom),
        initialAtomId: get(_initialValueAtom).id,
      });
    } else {
      set(_versionAtom, (v) => v + 1);
    }
  });

  const _hasChanged = atom(async (get) => {
    const initialValue = await get(_initialValueAtom).data;
    const currentValue = await get(valueAtom);
    return !isEqual(initialValue, currentValue);
  });

  const hasChanged = unwrap(_hasChanged, (prev) => prev ?? false);

  const resetAtom = atom(null, (_get, set) => {
    set(_overrideAtom, undefined);
  });

  // MARK: - Debug Labels
  _versionAtom.debugLabel = `${debugPrefix} Internal Version`;
  _initialValueAtom.debugLabel = `${debugPrefix} Internal Initial Value`;
  _overrideAtom.debugLabel = `${debugPrefix} Internal Override`;
  _hasChanged.debugLabel = `${debugPrefix} Internal Has Changed`;
  cacheAtom.debugLabel = `${debugPrefix} Internal Cache`;
  valueAtom.debugLabel = `${debugPrefix} Value`;
  refreshAtom.debugLabel = `${debugPrefix} Refresh`;
  hasChanged.debugLabel = `${debugPrefix} Has Changed`;

  return { valueAtom, refreshAtom, resetAtom, hasChanged };
}
