import { IFPopulatedNotification } from "@shared/types/Notification";
import http from ".";
import { FlagName, IFUser, UserFlag } from "../../shared/types/User";
import { createHttpRequest } from "./lib/createHttpRequest";

const ROOT = "/user";

export default {
  get: {
    notifsHasUnread: {
      url: (doc_ID) => `${ROOT}/notifs/unread/${doc_ID}`,
    },
    notifs: {
      url: `${ROOT}/notifs`,
    },
    apiTokens: {
      url: `${ROOT}/tokens`,
    },
  },
  post: {
    checkExists: {
      url: `${ROOT}/checkExists`,
      body: ({ email }) => ({ email }),
    },
  },
  delete: {
    tokens: {
      url: `${ROOT}/tokens`,
      body: ({ token }) => ({ token }),
    },
    figmaToken: {
      url: `${ROOT}/figmaToken`,
    },
  },
  put: {
    notifsMarkAllAsRead: {
      url: `${ROOT}/notifs/markAllAsRead`,
    },
    notifMarkAsRead: {
      url: (notificationId) => `${ROOT}/notifs/markAsRead/${notificationId}`,
    },
    notifMarkCommentAsRead: {
      url: (commentThreadId) => `${ROOT}/notifs/markCommentNotifsAsRead/${commentThreadId}`,
    },
    updateInfo: {
      url: `${ROOT}/updateInfo`,
      body: ({ finishedDittoOverview, job }) => ({
        finishedDittoOverview,
        job,
      }),
    },
  },
};

/**
 * Takes a single user flag in the form `{ name: value }` and updates it in the database.
 */
export async function putUserFlag(flag: UserFlag<FlagName>) {
  const request = http.put<IFUser>(`${ROOT}/setFlag`, flag);
  return request;
}

export const updateUserLoginMetrics = createHttpRequest({
  url: `${ROOT}/loginMetrics`,
  method: "put",
  config: { data: {} },
});

export const updateUserProfilePicture = createHttpRequest<{ picture: string | undefined | null }>({
  url: `${ROOT}/picture`,
  method: "put",
  getConfig: (d) => ({ data: { picture: d.picture } }),
});

export const getUnreadNotifications = createHttpRequest<void, IFPopulatedNotification[]>({
  url: `${ROOT}/notifs/unread`,
  method: "get",
});

export const markNotificationAsRead = createHttpRequest<{ notificationId: string }>({
  getUrl: ({ notificationId }) => `${ROOT}/notifs/markAsRead/${notificationId}`,
  method: "put",
});
