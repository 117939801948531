import { Action, actionToName } from "@shared/types/writing";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import AutoAwesomeIcon from "../../AutoAwesomeIcon";
import ArrowRightIcon from "./assets/arrow-right.png";
import AutoFixHighIcon from "./assets/auto-fix-high.png";
import BoldActiveIcon from "./assets/bold-active.png";
import BoldIcon from "./assets/bold.png";
import ExtendIcon from "./assets/extend.png";
import ItalicActiveIcon from "./assets/italic-active.png";
import ItalicIcon from "./assets/italic.png";
import MicIcon from "./assets/mic.png";
import ShortenIcon from "./assets/shorten.png";
import SpellcheckIcon from "./assets/spellcheck.png";
import SubscriptActiveIcon from "./assets/subscript-active.png";
import SubscriptIcon from "./assets/subscript.png";
import SuperscriptActiveIcon from "./assets/superscript-active.png";
import SuperscriptIcon from "./assets/superscript.png";
import UnderlineActiveIcon from "./assets/underline-active.png";
import UnderlineIcon from "./assets/underline.png";
import style from "./styles.module.css";

interface HighlightButtonsProps {
  onBoldClick?: () => void;
  onItalicClick?: () => void;
  onUnderlineClick?: () => void;
  onSuperscriptClick?: () => void;
  onSubscriptClick?: () => void;
  onInsertVariableClick?: () => void;
  onAiAction?: (action: Action) => void;
  isBoldActive?: boolean;
  isItalicActive?: boolean;
  isUnderlineActive?: boolean;
  isSuperscriptActive?: boolean;
  isSubscriptActive?: boolean;
  textWordCount: number;
  enableSuberscript?: boolean;
  containerClassname?: string;
}

function HighlightButtons({
  onBoldClick,
  onItalicClick,
  onUnderlineClick,
  onSuperscriptClick,
  onSubscriptClick,
  onInsertVariableClick,
  onAiAction,
  isBoldActive,
  isItalicActive,
  isUnderlineActive,
  isSuperscriptActive,
  isSubscriptActive,
  textWordCount,
  enableSuberscript = false,
  containerClassname,
}: HighlightButtonsProps) {
  const hoveringHighlightButtons = useRef(false);
  const [aiButtonEnabled, setAiButtonEnabled] = useState(false);
  const [showLevel2Menu, setShowLevel2Menu] = useState(false);

  const showBold = onBoldClick && isBoldActive !== undefined;
  const showItalic = onItalicClick && isItalicActive !== undefined;
  const showUnderline = onUnderlineClick && isUnderlineActive !== undefined;

  const showSuperscript = enableSuberscript && onSuperscriptClick && isSuperscriptActive !== undefined;

  const showSubscript = enableSuberscript && onSubscriptClick && isSubscriptActive !== undefined;

  const richTextModifiersActive = showBold || showItalic || showUnderline || showSubscript || showSuperscript;

  useEffect(
    function fixLevel2MenuOffScreen() {
      if (!showLevel2Menu) {
        return;
      }

      const el = document.getElementById("level2AiMenu");

      if (!el) {
        return;
      }

      // Get element dimensions
      const rect = el.getBoundingClientRect();

      // Check boundaries for horizontal overflow
      if (rect.right > window.innerWidth) {
        el.style.right = "138px";
      }
    },
    [showLevel2Menu]
  );

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [mouseIsDown, setMouseIsDown] = useState(false);
  useEffect(function hideWhenUserIsHighlighting() {
    const handleMouseDown = (e: MouseEvent) => {
      if (containerRef.current && containerRef.current.contains(e.target as Node)) {
        return;
      }
      setMouseIsDown(true);
    };

    const handleMouseUp = () => {
      setMouseIsDown(false);
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });

  return (
    <div
      ref={containerRef}
      className={classnames(
        {
          [style.highlightButtonsContainer]: true,
          [style.visible]: !mouseIsDown,
          [style.hidden]: mouseIsDown,
        },
        containerClassname
      )}
      onMouseLeave={() => {
        setAiButtonEnabled(false);
        setShowLevel2Menu(false);
        hoveringHighlightButtons.current = false;
      }}
      onMouseEnter={() => {
        hoveringHighlightButtons.current = true;
      }}
    >
      {showBold && (
        <button
          className={classnames({
            [style.richTextButton]: true,
            [style.active]: isBoldActive,
          })}
          onClick={() => {
            setAiButtonEnabled(false);
            onBoldClick();
          }}
        >
          <img src={isBoldActive ? BoldActiveIcon : BoldIcon} />
        </button>
      )}
      {showItalic && (
        <button
          className={classnames({
            [style.richTextButton]: true,
            [style.active]: isItalicActive,
          })}
          onClick={() => {
            setAiButtonEnabled(false);
            onItalicClick();
          }}
        >
          <img src={isItalicActive ? ItalicActiveIcon : ItalicIcon} />
        </button>
      )}
      {showUnderline && (
        <button
          className={classnames({
            [style.richTextButton]: true,
            [style.active]: isUnderlineActive,
          })}
          onClick={() => {
            setAiButtonEnabled(false);
            onUnderlineClick();
          }}
        >
          <img src={isUnderlineActive ? UnderlineActiveIcon : UnderlineIcon} />
        </button>
      )}
      {showSuperscript && showSubscript && (
        <>
          <button
            className={classnames({
              [style.richTextButton]: true,
              [style.active]: false,
            })}
            onClick={() => {
              setAiButtonEnabled(false);
              onSuperscriptClick();
            }}
          >
            <img src={isSuperscriptActive ? SuperscriptActiveIcon : SuperscriptIcon} />
          </button>
          <button
            className={classnames({
              [style.richTextButton]: true,
              [style.active]: false,
            })}
            onClick={() => {
              setAiButtonEnabled(false);
              onSubscriptClick();
            }}
          >
            <img src={isSubscriptActive ? SubscriptActiveIcon : SubscriptIcon} />
          </button>
        </>
      )}
      {onInsertVariableClick && (
        <>
          {richTextModifiersActive && <div className={style.veritcalSeparator}></div>}
          <button
            className={style.insertVariableButton}
            onClick={() => {
              setAiButtonEnabled(false);
              onInsertVariableClick();
            }}
          >
            + Insert Variable
          </button>
        </>
      )}
      {onAiAction && (
        <div className={style.aiSection}>
          <div className={style.veritcalSeparator}></div>
          <button
            disabled={textWordCount < 2}
            className={classnames({
              [style.aiButton]: true,
              [style.aiButtonEnabled]: aiButtonEnabled,
            })}
            onClick={() => {
              if (!aiButtonEnabled) {
                setShowLevel2Menu(false);
              }

              setAiButtonEnabled(!aiButtonEnabled);
            }}
          >
            <AutoAwesomeIcon fill={aiButtonEnabled ? "#D0AD2E" : "#DFBD43"} />
          </button>
          {aiButtonEnabled && (
            <>
              <div className={style.level1AiMenu}>
                <div
                  className={style.level1AiMenuElement}
                  onClick={() => {
                    onAiAction("improve-writing");
                    setAiButtonEnabled(false);
                  }}
                >
                  <img src={AutoFixHighIcon} />
                  <span>{actionToName["improve-writing"]}</span>
                </div>
                <div
                  onClick={() => {
                    onAiAction("shorten");
                    setAiButtonEnabled(false);
                  }}
                  className={style.level1AiMenuElement}
                >
                  <img src={ShortenIcon} />
                  <span>{actionToName["shorten"]}</span>
                </div>
                <div
                  onClick={() => {
                    onAiAction("extend");
                    setAiButtonEnabled(false);
                  }}
                  className={style.level1AiMenuElement}
                >
                  <img src={ExtendIcon} />
                  <span>{actionToName["extend"]}</span>
                </div>
                <div
                  onClick={() => {
                    onAiAction("spelling-and-grammar");
                    setAiButtonEnabled(false);
                  }}
                  className={style.level1AiMenuElement}
                >
                  <img src={SpellcheckIcon} />
                  <span>{actionToName["spelling-and-grammar"]}</span>
                </div>
                <hr />
                <div
                  className={style.level1AiMenuElement}
                  onClick={() => {
                    setShowLevel2Menu(!showLevel2Menu);
                  }}
                >
                  <img src={MicIcon} />
                  <span>Adjust Tone</span>
                  <img className={style.adjustToneArrowRight} src={ArrowRightIcon} />{" "}
                </div>
              </div>
              <div
                style={{ display: showLevel2Menu ? "block" : "none" }}
                className={style.level2AiMenu}
                id="level2AiMenu"
              >
                <div
                  className={style.level2AiMenuElement}
                  onClick={() => {
                    onAiAction("tone-friendly");
                    setAiButtonEnabled(false);
                  }}
                >
                  <span>😄</span>
                  <span className={style.toneAction}>{actionToName["tone-friendly"]}</span>
                </div>
                <div
                  onClick={() => {
                    onAiAction("tone-formal");
                    setAiButtonEnabled(false);
                  }}
                  className={style.level2AiMenuElement}
                >
                  <span>💼</span>
                  <span className={style.toneAction}>{actionToName["tone-formal"]}</span>
                </div>
                <div
                  className={style.level2AiMenuElement}
                  onClick={() => {
                    onAiAction("tone-assertive");
                    setAiButtonEnabled(false);
                  }}
                >
                  <span>☝️</span>
                  <span className={style.toneAction}>{actionToName["tone-assertive"]}</span>
                </div>
                <div
                  className={style.level2AiMenuElement}
                  onClick={() => {
                    onAiAction("tone-excited");
                    setAiButtonEnabled(false);
                  }}
                >
                  <span>🤩</span>
                  <span className={style.toneAction}>{actionToName["tone-excited"]}</span>
                </div>
              </div>
              {/* used to help with on onMouseLeave events*/}
              <div className={style.level1AiMenuElementBackground}></div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HighlightButtons;
