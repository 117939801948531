import classNames from "classnames";
import React from "react";

import style from "./style.module.css";

interface OutlineButtonProps {
  text: string;
  Icon: React.FC;
  onClick: () => void;
  disabled?: boolean;
}

const OutlineButton = (props: OutlineButtonProps) => {
  const { text, Icon, onClick, disabled } = props;
  return (
    <button
      className={classNames({
        [style.button]: true,
        [style.disabled]: disabled,
      })}
      onClick={onClick}
      data-testid={`outline-button-${text.toLowerCase()}`}
      disabled={disabled}
    >
      <div className={style.icon}>
        <Icon />
      </div>
      <span className={style.text}>{text}</span>
    </button>
  );
};

export default OutlineButton;
