import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import NavLink from "./NavLink";
import SimpleLink from "./SimpleLink";
import style from "./UserSection.module.css";

interface UserSectionProps {
  toggleNotificationsAreaOpen: () => void;
  notificationsAreaOpen: boolean;
  hasNotifications?: boolean;
  collapsed?: boolean;
}

function getInitials(name: string) {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0]}${lastName ? lastName[0] : ""}`;
}

const UserSection = forwardRef(function (props: UserSectionProps, ref: React.ForwardedRef<HTMLDivElement | null>) {
  const { user } = useAuthenticatedAuth();

  return (
    <div className={style.userSection}>
      <Link className={style.userLink} to="/account/user" data-testid="account-link">
        <div className={style.userIcon} data-testid="user-icon">
          {user.picture ? (
            <img src={user.picture} alt="user-icon" />
          ) : (
            <div className={style.userInitials}>{getInitials(user.name)}</div>
          )}
        </div>
      </Link>
      <div
        className={classNames(style.collapseSection, {
          [style.collapsed]: props.collapsed,
        })}
      >
        {/* 
            We wrap the name text in a div to allow the text to wrap, but not affect the height of 
            the user section when it's collapsed.
         */}
        <Link className={style.userLink} to="/account/user">
          <div className={style.nameWrapper}>
            <div className={style.userName}>{user.name}</div>
          </div>
        </Link>
        <div className={style.buttons}>
          <SimpleLink
            testId="toggle-notifications"
            ref={ref}
            className={style.iconButton}
            Icon={NotificationsOutlined}
            ActiveIcon={Notifications}
            onClick={props.toggleNotificationsAreaOpen}
            active={props.notificationsAreaOpen}
          >
            {props.hasNotifications && <div className={style.notificationDot} />}
          </SimpleLink>
          <NavLink className={style.iconButton} to="settings" collapsed />
        </div>
      </div>
    </div>
  );
});

export default UserSection;
