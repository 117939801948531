import React from "react";
import useThrottledCallback from "../../../../../util/useThrottledCallback";
import ButtonPrimary from "../../../button/buttonprimary";
import PageFrameSelection from "../../../page-frame-selection";
import { FileData, FilePage, FramesByPage, HandleSelectFrameArgs, SelectedPage } from "../../useImport";
import style from "./style.module.css";

const Title = () => <>Import Figma File</>;

interface BodyProps {
  originalFileData: FileData | null;
  filePages: FilePage[];
  selectedFramesByPage: FramesByPage;
  handleSelectFrame: (args: HandleSelectFrameArgs) => void;
  handleToggleAllSelectedForPage: (pageId: string, selected: boolean) => void;
}
const Body = ({
  originalFileData,
  filePages,
  selectedFramesByPage,
  handleSelectFrame,
  handleToggleAllSelectedForPage,
}: BodyProps) => (
  <>
    {filePages.length !== 0 && (
      <PageFrameSelection
        pages={originalFileData?.pages}
        groupsByPage={selectedFramesByPage}
        handleSelectFrame={handleSelectFrame}
        handleToggleAllSelectedForPage={handleToggleAllSelectedForPage}
        selectedFramesByPage={selectedFramesByPage}
        isImportModal={true}
        isLoading={false}
        isSaving={false}
      />
    )}
  </>
);

interface FooterProps {
  selectedFrames: any[];
  selectedFramesByPage: any;
  importFile: () => void;
  isImportLoading: boolean;
  progress: number;
}
const Footer = ({ selectedFrames, selectedFramesByPage, importFile, isImportLoading, progress }: FooterProps) => {
  const throttledImportFile = useThrottledCallback(() => {
    importFile();
  });

  return (
    <>
      {progress > 0 && (
        <progress id="import-progress" className={style.progressBar} value={progress} max={100}>
          {progress}
        </progress>
      )}
      <div className={style.frameSelection}>
        <p>
          {selectedFrames.length === 0
            ? "No frames selected"
            : `${selectedFrames.length} frames selected from ${
                Object.values(selectedFramesByPage).filter(
                  (page: SelectedPage[]) => page.filter((frame) => frame.isSelected).length
                ).length
              } pages`}
        </p>
        <div className={style.progressButtonContainer}>
          {progress > 0 && (
            <label htmlFor="import-progress" className={style.progressLabel}>
              {progress}%
            </label>
          )}
          <ButtonPrimary
            data-testid="finish-import-button"
            disabled={isImportLoading || selectedFrames.length === 0}
            text={isImportLoading ? "Importing..." : "Import"}
            onClick={() => throttledImportFile()}
          />
        </div>
      </div>
    </>
  );
};
const SelectFigmaFrames = ({ children }: { children: React.ReactNode }) => <>{children}</>;
SelectFigmaFrames.Title = Title;
SelectFigmaFrames.Body = Body;
SelectFigmaFrames.Footer = Footer;

export default SelectFigmaFrames;
