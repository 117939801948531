import React from "react";
import style from "./style.module.css";

export default function KebabMenu({ className }: { className?: string }) {
  return (
    <div className={`${style.kebabMenu} ${className ? className : ""}`}>
      <Dot />
      <Dot />
      <Dot />
    </div>
  );
}

function Dot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
      <path
        d="M2 4.5C1.63333 4.5 1.29848 4.41061 0.995455 4.23182C0.692424 4.05 0.45 3.80758 0.268182 3.50455C0.0893939 3.20151 0 2.86667 0 2.5C0 2.1303 0.0893939 1.79545 0.268182 1.49545C0.45 1.19242 0.692424 0.951515 0.995455 0.772727C1.29848 0.590909 1.63333 0.5 2 0.5C2.3697 0.5 2.70455 0.590909 3.00455 0.772727C3.30758 0.951515 3.54849 1.19242 3.72727 1.49545C3.90909 1.79545 4 2.1303 4 2.5C4 2.86667 3.90909 3.20151 3.72727 3.50455C3.54849 3.80758 3.30758 4.05 3.00455 4.23182C2.70455 4.41061 2.3697 4.5 2 4.5Z"
        fill="#B7B7B8"
      />
    </svg>
  );
}
