import React from "react";

type ElementProps<E> = React.DetailedHTMLProps<React.HTMLAttributes<E>, E>;

type ComponentProps<Props, ElementType> = Exclude<
  {
    [key in keyof Props]: key extends keyof ElementProps<ElementType> ? never : key;
  }[keyof Props],
  undefined
>;

/**
 * Helper hook to extract native props from a component's props object for spreading onto a native element.
 * Requires passing in type arguments for the prop interface and the native element type being spread onto.
 * @param props the props to be spread onto the native element
 * @param customPropMap a record of custom props which will not be included in the returned result - the compiler
 * will enforce that this record is comprehensive.
 * @returns
 */
export function useNativeProps<E, P extends object>(props: P, customPropMap: Record<ComponentProps<P, E>, true>) {
  return Object.keys(props).reduce((acc, key) => {
    if (!(key in customPropMap)) {
      acc[key as keyof ElementProps<E>] = props[key as keyof P];
    }
    return acc;
  }, {} as Partial<ElementProps<E>>);
}
