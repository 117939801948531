import { Atom, atom, getDefaultStore, Getter, Setter, WritableAtom } from "jotai";
import throttle from "lodash/throttle";
import atomFamilyWithDefault from "./atomFamilyWithDefault";
import { REFRESH, REFRESH_SILENTLY } from "./symbols";

interface IBatchAsyncAtomFamilyProps<AtomType> {
  /**
   * The jotai store to house the atoms created by this custom atom.
   */
  storeAtom?: Atom<ReturnType<typeof getDefaultStore>>;
  /**
   * The async fetch request function that will be called to fetch the data.
   * @param get {Getter} Jotai getter method.
   * @param ids {string[]} The ids of the items to fetch.
   * @returns {Promise<AtomType[]>} The fetched items.
   */
  asyncFetchRequest: (get: Getter, ids: string[]) => Promise<AtomType[]>;
  /**
   * The function to get the id of the item.
   * @param item {AtomType} The item to get the id of.
   * @returns {string} The id of the item.
   */
  getId: (item: AtomType) => string;
  /**
   * The throttle timeout for the batched requests.
   */
  throttleOptions?: {
    trailing?: boolean;
    leading?: boolean;
    timeout?: number;
  };
  /**
   * The batch size for the requests.
   */
  batchSize?: number;
  /**
   * The debug prefix for the atoms created by this custom atom.
   */
  debugPrefix?: string;
}

/**
 * This atom works similarly to the jotai `[atomFamily](https://jotai.org/docs/utilities/family)`, but allows passing in default values by using {@link atomFamilyWithDefault } under the hood as well creating utilities to batches async requests to some async source (e.g. an API).
 */
export default function batchedAsyncAtomFamily<AtomType>(props: IBatchAsyncAtomFamilyProps<AtomType>) {
  const BATCH_SIZE = props.batchSize || 100;
  const THROTTLE_TIMEOUT = props.throttleOptions?.timeout || 50;

  const storeAtom = props.storeAtom || atom(getDefaultStore());

  // This atom stores the promises that are waiting to be resolved
  const _promisesAtom = atom<Record<string, (value: AtomType) => void>>({});

  // This action atom is used to throttle the loading of the promises so we don't emit too many fetch requests at once
  const _loadPromisesAtom = atom(
    null,
    throttle(
      async (get: Getter, set: Setter) => {
        const promises = get(_promisesAtom);
        // Clear the promises so we don't fetch them again on the next load
        set(_promisesAtom, {});

        if (promises) {
          // Fetch promises in batches of 100 by default
          let fetchPromises: Promise<void>[] = [];
          for (let i = 0; i < Object.keys(promises).length; i += BATCH_SIZE) {
            const batchPromises = Object.keys(promises).slice(i, i + BATCH_SIZE);
            fetchPromises.push(
              props.asyncFetchRequest(get(storeAtom).get, batchPromises).then((response) => {
                response.forEach((responseItem) => {
                  promises[props.getId(responseItem)](responseItem);
                });
              })
            );
          }

          const _responses = await Promise.allSettled(fetchPromises);
          // TODO: Error Handling
          // https://linear.app/dittowords/issue/DIT-7883/batchedasyncatomfamily-implement-better-error-handling
        }
      },
      THROTTLE_TIMEOUT,
      { trailing: props.throttleOptions?.trailing ?? true, leading: props.throttleOptions?.leading ?? false }
    )
  );

  // This function is used to batch the items. It returns a promise that resolves when the item is loaded
  async function batchItem(id: string, store: ReturnType<typeof getDefaultStore>) {
    let resolveFn;

    const promise = new Promise<AtomType>((resolve) => {
      resolveFn = resolve;
    });

    store.set(_promisesAtom, (promises) => {
      promises[id] = resolveFn;
      return promises;
    });

    store.set(_loadPromisesAtom);

    return await promise;
  }

  type NewValueType =
    | AtomType
    | Promise<AtomType>
    | ((previousValue: AtomType) => AtomType)
    | typeof REFRESH
    | typeof REFRESH_SILENTLY;

  // This atom family is used to create the atoms that will be used to store the data. This is what is returned from this custom atom.
  const familyAtom = atomFamilyWithDefault<
    AtomType | Promise<AtomType>,
    Promise<AtomType>,
    [NewValueType],
    WritableAtom<AtomType | Promise<AtomType>, [NewValueType], Promise<AtomType>>
  >((id, initialValue) => {
    const _newAtom = atom<AtomType | Promise<AtomType>>();

    const newAtom = atom<Promise<AtomType> | NonNullable<AtomType>, [NewValueType], Promise<AtomType>>(
      (get) => {
        const _newAtomValue = get(_newAtom);
        if (_newAtomValue) {
          return _newAtomValue;
        }

        return initialValue ?? batchItem(id, get(storeAtom));
      },
      async (get, set, newValue: NewValueType) => {
        if (newValue === REFRESH) {
          const result = batchItem(id, get(storeAtom));
          set(_newAtom, result);
          return result;
        } else if (newValue === REFRESH_SILENTLY) {
          const result = await batchItem(id, get(storeAtom));
          set(_newAtom, result);
          return result;
        } else if (newValue instanceof Function) {
          const result = newValue(await get(newAtom));
          set(_newAtom, result);
          return result;
        } else {
          set(_newAtom, newValue);
          return newValue;
        }
      }
    );

    newAtom.debugLabel = `${props.debugPrefix || "atomFamilyNode"} ${id}`;
    return newAtom;
  });

  return familyAtom;
}
