import { useWorkspace } from "@/store/workspaceContext";
import React from "react";
import Connections from "../Connections";
import { CrowdinConnectionProvider } from "../Connections/store/CrowdinConnectionContext";
import { LaunchDarklyConnectionProvider } from "../Connections/store/LaunchDarklyConnectionContext";
import { LokaliseConnectionProvider } from "../Connections/store/LokaliseConnectionContext";
import { SplitConnectionProvider } from "../Connections/store/SplitConnectionContext";
import style from "./style.module.css";

interface IntegrationsPageProps {}

const Integrations = (props: IntegrationsPageProps) => {
  const { workspaceInfo } = useWorkspace();

  if (!workspaceInfo) return <></>;

  return (
    <div className={style.integrationsWrapper}>
      <div className={style.navbar}>
        <h1>Integrations</h1>
      </div>
      <div className={style.integrationsPage}>
        <LokaliseConnectionProvider>
          <CrowdinConnectionProvider>
            <LaunchDarklyConnectionProvider>
              <SplitConnectionProvider>
                <Connections />
              </SplitConnectionProvider>
            </LaunchDarklyConnectionProvider>
          </CrowdinConnectionProvider>
        </LokaliseConnectionProvider>
      </div>
    </div>
  );
};

export default Integrations;
