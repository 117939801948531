import classnames from "classnames";
import React from "react";
import style from "./style.module.css";

export interface OverlayToastProps {
  text: string | React.ReactNode;
  hidden: boolean;
  className?: string;
}
const OverlayToast = (props: OverlayToastProps) => {
  return (
    <div
      data-testid="overlay-toast"
      className={classnames(
        {
          [style.container]: true,
          [style.hidden]: props.hidden,
        },
        props.className
      )}
    >
      {props.text}
    </div>
  );
};

export default OverlayToast;
