import classNames from "classnames";
import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { DragMoveEvent, DragStartEvent, useDrag, useDrop } from "react-aria";
import { ZodTypeAny } from "zod";
import style from "./index.module.css";

type DragLocation = "above" | "below" | null;

interface IDraggableProps<DataType> {
  getDraggableItems: () => Record<string, DataType>[];
  isDragDisabled?: boolean;
  onDragStart?: (e: DragStartEvent) => void;
  onDragMove?: (e: DragMoveEvent) => void;
  onDragCancel?: () => void;
  onDragEnd?: () => void;
}

interface IDroppableProps<DataType> {
  // Droppable Props
  allowedItemKeys: Record<string, ZodTypeAny>;
  onDrop?: (items: DataType[], dragLocation: DragLocation) => void;
}

interface IDragAndDroppableProps<DraggableDataType, DroppableDataType>
  extends IDraggableProps<DraggableDataType>,
    IDroppableProps<DroppableDataType> {
  children: (props: { isDragging: boolean; isDropTarget: boolean; dragLocation: DragLocation }) => React.ReactNode;
  className?: string;
}

/**
 * Given an object, return a new object with each value stringified.
 */
function stringifyEachEntry(object: Record<string, unknown>): Record<string, string> {
  return Object.entries(object).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = JSON.stringify(value);
    return acc;
  }, {});
}

export const DragAndDroppable = forwardRef(function DragAndDroppable<DraggableDataType, DroppableDataType>(
  props: IDragAndDroppableProps<DraggableDataType, DroppableDataType>,
  ref: ForwardedRef<unknown>
) {
  let dropRef = useRef<HTMLDivElement | null>(null);

  // Merge internal ref with optional external ref
  function setRefs(node: HTMLDivElement | null) {
    dropRef.current = node;

    if (ref) {
      (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  }

  const [dragLocation, setDragLocation] = useState<DragLocation>(null);
  const [halfHeight, setHalfHeight] = useState(0);

  useEffect(
    // if this component is ever causing performance issues, it's possible that the calls
    // to setHalfHeight are the problem! It's possible this effect is running too frequently.
    function calculateContentHeight() {
      // We record the height of the nudge content so that we can animate it in and out.
      if (dropRef.current) {
        const clientHeight = dropRef.current.clientHeight;
        dropRef.current.style.setProperty("--content-height", `${clientHeight}px`);
        setHalfHeight((clientHeight ?? 0) / 2);
      }
    },
    [dropRef.current?.clientHeight]
  );

  let { dragProps, isDragging } = useDrag({
    getItems: () => props.getDraggableItems().map(stringifyEachEntry),
    isDisabled: props.isDragDisabled ?? false,
    onDragStart: props.onDragStart,
    onDragMove: props.onDragMove,
    onDragEnd: props.onDragEnd,
  });

  let { dropProps, isDropTarget } = useDrop({
    ref: dropRef,
    async onDrop(e) {
      let itemPromises: Promise<DroppableDataType | undefined>[] = [];
      const textMimeItems = e.items.filter((item) => item.kind === "text");
      for (const item of textMimeItems) {
        for (const [key, zodValidator] of Object.entries(props.allowedItemKeys)) {
          if (item.types.has(key)) {
            itemPromises.push(
              item.getText(key).then((text) => {
                const parsedJSON = JSON.parse(text);
                const validatedJSON = zodValidator.safeParse(parsedJSON);
                if (validatedJSON.success) {
                  return validatedJSON.data as DroppableDataType;
                }
              })
            );
          }
        }
      }

      const items: DroppableDataType[] = (await Promise.all(itemPromises)).filter(Boolean) as DroppableDataType[];
      if (items.length > 0) props.onDrop?.(items, dragLocation);
    },
    onDropEnter(e) {
      const positon = e.y >= halfHeight ? "below" : "above";
      setDragLocation(positon);
    },
    onDropMove(e) {
      const positon = e.y >= halfHeight ? "below" : "above";
      setDragLocation(positon);
    },
    onDropExit(e) {
      setDragLocation(null);
    },
  });

  return (
    <div
      className={classNames(props.className, style.draggableContent)}
      ref={setRefs}
      {...dragProps}
      {...dropProps}
      data-state={isDragging ? "dragging" : "idle"}
    >
      {props.children({ isDragging, isDropTarget, dragLocation })}
    </div>
  );
});

export default DragAndDroppable;
