import useInviteModal from "@/hooks/useInviteModal";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CloseIcon from "@mui/icons-material/Close";
import FileUpload from "@mui/icons-material/FileUpload";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FontDownloadOutlinedIcon from "@mui/icons-material/FontDownloadOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import KeyboardReturn from "@mui/icons-material/KeyboardReturn";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import * as SegmentEvents from "@shared/segment-event-names";
import { EMPTY_STATE_ITEMS, SearchResult } from "@shared/types/http/search";
import logger from "@shared/utils/logger";
import { CanceledError } from "axios";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import { flushSync } from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import useDebouncedCallback from "../../../util/useDebouncedCallback";
import useSegment from "../../hooks/useSegment";
import http from "../../http";
import { getBoldedMatchingText } from "../../utils/getBoldedMatchingText";
import style from "./CommandBar.module.css";

declare const window: any;

const SearchResultIcons = {
  component: <LanguageOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  create: <AddBoxOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  folder: <FolderOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  import: <FileUpload fontSize="small" className={style.searchResultIcon} />,
  invite: <PersonAddOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  project: <GridViewOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  search: <SearchOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  textItem: <FontDownloadOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  variable: <CategoryOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  variant: (
    <CallSplitOutlinedIcon fontSize="small" className={style.searchResultIcon} style={{ transform: "rotate(90deg)" }} />
  ),
  view: <ArrowForward fontSize="small" className={style.searchResultIcon} />,
  command: <SettingsOutlinedIcon fontSize="small" className={style.searchResultIcon} />,
  enter: <KeyboardReturn fontSize="small" className={style.searchResultIcon} />,
};

export const UNIVERSAL_SEARCH_QUERY_PARAM = "universalSearch";

const CommandBar = () => {
  const defaultActions: SearchResult[] = [
    { type: "section", title: "Quick actions", _id: "quick-actions-section" },
    ...EMPTY_STATE_ITEMS,
  ];
  const history = useHistory();
  const location = useLocation();
  const { toggleInviteModal } = useInviteModal();
  const [selectionIndex, setSelectionIndex] = React.useState(1);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<SearchResult[]>(defaultActions);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const resultsRef = React.useRef<HTMLDivElement>(null);
  const currentRequest = React.useRef<AbortController | null>(null);

  const show = useMemo(() => new URLSearchParams(location.search).has(UNIVERSAL_SEARCH_QUERY_PARAM), [location.search]);
  const segment = useSegment();
  useEffect(
    function handleTogglingCommandBar() {
      const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.key === "k" && event.metaKey) || (event.ctrlKey && event.key === "k")) {
          event.preventDefault();
          if (show) {
            handleClose();
          } else {
            handleOpen();
          }
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    },
    [show]
  );

  function scrollSelectionIntoView(selectionIndex: number) {
    const element = document.getElementById(`search-result-${selectionIndex}`);
    if (element && cardRef.current) {
      const rect = element.getBoundingClientRect();
      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= cardRef.current.clientHeight &&
        rect.right <= cardRef.current.clientWidth;
      if (!isVisible) {
        element.scrollIntoView({ block: "center" });
      }
    }
  }

  // Command bar keyboard inputs should only be registered when the bar is open
  useEffect(
    function handleCommandBarKeyEvents() {
      const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.key === "k" && event.metaKey) || (event.ctrlKey && event.key === "k")) {
          event.preventDefault();
          if (show) {
            handleClose();
          } else {
            handleOpen();
          }
        } else if (event.key === "Escape") {
          handleClose();
        } else if (event.key === "ArrowUp" || (event.key === "Tab" && event.shiftKey)) {
          event.preventDefault();
          let index = selectionIndex - 1;
          if (index < 0 || (index === 0 && searchResults[0].type === "section")) {
            return;
          } else if (searchResults[index].type === "section") {
            setSelectionIndex(index - 1);
          } else {
            setSelectionIndex(index);
          }
          scrollSelectionIntoView(selectionIndex);
        } else if (event.key === "ArrowDown" || event.key === "Tab") {
          event.preventDefault();
          let index = selectionIndex + 1;
          if (index > searchResults.length - 1) {
            return;
          } else if (searchResults[index].type === "section") {
            setSelectionIndex(index + 1);
          } else {
            setSelectionIndex(index);
          }
          scrollSelectionIntoView(selectionIndex);
        } else if (event.key === "Enter") {
          onClick(searchResults[selectionIndex]);
        }
      };
      if (show) window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    },
    [selectionIndex, show, searchResults]
  );

  useEffect(
    function addClickOutsideListener() {
      if (!show) return;
      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (cardRef.current && !cardRef.current.contains(target)) {
          handleClose();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [show]
  );

  async function fetchSearchResults(query: string) {
    currentRequest.current?.abort();
    let abortController = new AbortController();
    currentRequest.current = abortController;

    const url = "/search?" + new URLSearchParams({ query }).toString();
    const rawResponse = await http.get(url, {
      signal: abortController.signal,
    });
    if (!query) return defaultActions;
    return [
      // NOTE: We'll add this back in after we revamp the search page.
      // {
      //   _id: "search",
      //   type: "search",
      //   title: query,
      //   link: `/search?${new URLSearchParams({ query }).toString()}`,
      //   action: "Search",
      // },
      ...rawResponse.data,
    ];
  }

  const debouncedSearchRequest = useDebouncedCallback(
    (inputText: string) => {
      if (inputText === "") return;
      fetchSearchResults(inputText)
        .then((newSearchResults) => {
          setLoadingSearch(false);
          setSearchResults(newSearchResults);
          setSelectionIndex(newSearchResults[0]?.type === "section" ? 1 : 0);
        })
        .catch((err) => {
          if (!(err instanceof CanceledError)) {
            logger.error("Error fetching search results", { context: { inputText } }, err);
          }
        });
    },
    300,
    [search]
  );

  useEffect(
    function handleSearch() {
      if (search !== "") setLoadingSearch(true);
      debouncedSearchRequest(search);

      if (search === "") {
        setLoadingSearch(false);
        setSearchResults(defaultActions);
        setSelectionIndex(1);
      }
    },
    [search]
  );

  useEffect(
    function scrollToTopOnOpen() {
      if (show) {
        resultsRef.current?.scrollTo(0, 0);
      }
    },
    [show]
  );

  function onClick(item: SearchResult) {
    segment.track({
      event: SegmentEvents.UNIVERSAL_SEARCH_RESULT_CLICKED,
      properties: {
        item,
      },
    });
    if (item.type === "invite") {
      toggleInviteModal();
    } else if (item.link) {
      const currentUrl = window.location.href;
      history.push(item.link);
      if (item.type === "textItem") {
        if (currentUrl.includes("/projects")) history.go(0);
      } else if (item.type === "component") {
        if (currentUrl.includes("/components")) history.go(0);
      }
    }
    setLoadingSearch(false);
    setSearchResults(defaultActions);
    setSelectionIndex(1);
    handleClose();
  }

  function handleOpen() {
    const url = new URL(window.location.href);
    url.searchParams.set(UNIVERSAL_SEARCH_QUERY_PARAM, "true");

    history.push({
      pathname: url.pathname,
      search: url.search,
    });
    segment.track({ event: SegmentEvents.UNIVERSAL_SEARCH_OPENED });
    flushSync(() => {
      setImmediate(() => {
        inputRef.current?.focus();
      });
    });
  }

  function handleClose() {
    setLoadingSearch(false);
    const url = new URL(window.location.href);
    url.searchParams.delete(UNIVERSAL_SEARCH_QUERY_PARAM);
    history.push({
      pathname: url.pathname,
      search: url.search,
    });
    setSearch("");
    setSelectionIndex(1);
    setSearchResults(defaultActions);
  }
  return (
    <div
      className={classNames(style.searchDialog, {
        [style.show]: show,
      })}
    >
      <div className={style.searchCard} ref={cardRef}>
        <div className={style.searchInputWrapper}>
          <SearchOutlinedIcon className={style.searchInputIcon} fontSize="small" />
          <input
            data-testid="universal-search-input"
            ref={inputRef}
            className={style.searchInput}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Search for anything..."
          />
          <button className={style.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </button>
        </div>
        {loadingSearch && (
          <Box data-testid="loading-universal-search" sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <div className={style.searchResults} ref={resultsRef}>
          {searchResults.length === 0 && (
            <div className={style.searchResultNoHover}>
              <div className={style.noSearchResultsTitle}>No results found.</div>
            </div>
          )}
          {searchResults.map((item, index) => {
            if (item.type === "empty") {
              return (
                <div className={style.searchResultNoHover} key={item._id}>
                  <div className={style.noSearchResultsTitle}>{item.title}</div>
                </div>
              );
            }
            if (item.type === "section") {
              return (
                <div className={style.searchResultNoHover} key={item._id}>
                  <div className={style.sectionTitle}>{item.title}</div>
                </div>
              );
            }
            if (item.type === "search") {
              return (
                <div
                  id={`search-result-${index}`}
                  data-testid={`search-result-${index}`}
                  className={classNames(style.searchResult, {
                    [style.selected]: index === selectionIndex,
                  })}
                  key={item._id}
                  onClick={() => onClick(item)}
                >
                  <div>{SearchResultIcons[item.type]}</div>
                  <div className={style.searchResultTitleWrapper}>
                    <div className={style.searchResultTitleRow}>
                      <div>{item.title}</div>
                      <div className={style.searchResultActionText}>{item.action || "Go"}</div>
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div
                id={`search-result-${index}`}
                data-testid={`search-result-${index}`}
                className={classNames(style.searchResult, {
                  [style.selected]: index === selectionIndex,
                })}
                key={item._id}
                onClick={() => onClick(item)}
              >
                <div>{SearchResultIcons[item.type]}</div>
                <div className={style.searchResultTitleWrapper}>
                  <div className={style.searchResultTitleRow}>
                    <div>{getBoldedMatchingText(item.title, search)}</div>
                    <div className={style.searchResultActionText}>{item.action || "Go"}</div>
                  </div>
                  <div className={style.searchResultSubtitle}>{getBoldedMatchingText(item.subtitle ?? "", search)}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className={style.footer}>
          <button
            className={classNames(style.runButton)}
            onClick={() => {
              onClick(searchResults[selectionIndex]);
            }}
          >
            <div className={style.runIcon}>{SearchResultIcons["enter"]}</div>
            Run
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * The backend cuts off subtitle text at 80 characters; this is a hacky way to show that we're truncating something.
 */
function addEllipses(text: string) {
  return text.length === 80 ? `${text}...` : text;
}

export default CommandBar;
