import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import classnames from "classnames";
import React from "react";
import ReactTooltip from "react-tooltip";
import style from "./style.module.css";

interface Props {
  exists: boolean;
  variantName: string;
  isEnlarge: boolean;
  className?: string;
}
const FigmaFrameVariantWarning: React.FC<Props> = ({ exists, variantName, isEnlarge, className }) => {
  return (
    <>
      <div
        className={classnames({
          [style.variantMessage]: true,
          [style.enlargeMessage]: isEnlarge,
          ...(className ? { [className]: true } : {}),
        })}
      >
        {exists ? (
          <>
            Viewing the<strong>{variantName}</strong>variant of this frame.
          </>
        ) : (
          <>Viewing a deleted variant of this frame.</>
        )}
        <InfoOutlinedIcon data-tip data-for="open-variant-docs-tooltip" />
      </div>
      <ReactTooltip
        id="open-variant-docs-tooltip"
        place="bottom"
        effect="solid"
        className={style.toolTip}
        clickable={true}
        delayHide={1000}
      >
        {exists ? (
          <>
            A variant has been applied to this Figma frame using our plugin.{" "}
            <a target="_blank" href="https://dittowords.com/docs/applying-variants-to-designs">
              Learn more
            </a>
          </>
        ) : (
          <>
            Use Ditto’s Figma plugin to revert this frame to the Base variant.{" "}
            <a target="_blank" href="https://dittowords.com/docs/applying-variants-to-designs">
              Learn more
            </a>
          </>
        )}
      </ReactTooltip>
    </>
  );
};

export default FigmaFrameVariantWarning;
