import { Ditto } from "ditto-react";
import React from "react";
import { Link } from "react-router-dom";
import { OnboardingTaskEnum } from "../../../shared/types/onboardingChecklist";
import onboarding_add_comment from "../../assets/onboarding_add_comment.gif";
import onboarding_add_variant from "../../assets/onboarding_add_variant.gif";
import onboarding_attach_components from "../../assets/onboarding_attach_components.gif";
import onboarding_create_components from "../../assets/onboarding_create_components.gif";
import onboarding_create_project from "../../assets/onboarding_create_project.gif";
import onboarding_edit_project from "../../assets/onboarding_edit_project.gif";
import onboarding_enable_dev_integrations from "../../assets/onboarding_enable_dev_integrations.gif";
import onboarding_invite_team from "../../assets/onboarding_invite_team.png";
import onboarding_organize_components from "../../assets/onboarding_organize_components.gif";
import onboarding_sync_changes from "../../assets/onboarding_sync_changes.gif";
import { navRoutes } from "../../nav-routes";
import style from "./style.module.css";

export interface OnboardingChecklistTask {
  title: JSX.Element;
  description: JSX.Element;
  img?: JSX.Element;
}

export const OnboardingTaskMap: Record<OnboardingTaskEnum, OnboardingChecklistTask> = {
  [OnboardingTaskEnum.CREATE_PROJECT]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1f5"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e64c68243db3c458d86b7" />{" "}
        <Link to={navRoutes.projects.path}>
          <Ditto textId="text_630e64de8243db3c458d86b8" />
        </Link>{" "}
        <Ditto textId="text_630e64df8243db3c458d86b9" />{" "}
        <a
          href="https://www.figma.com/community/plugin/798826066406007173/%E2%9C%8D%EF%B8%8F-Ditto-%7C-collaborate-on-copy"
          target="_blank"
        >
          <Ditto textId="text_630e64e48243db3c458d86ba" />
        </a>{" "}
        <Ditto textId="text_630e64e68243db3c458d86bb" />
        <br />
        <br />
        <a href="https://www.dittowords.com/docs/creating-a-ditto-project" target="_blank" className={style.learnMore}>
          <Ditto textId="text_630e64e88243db3c458d86bc" />
        </a>
      </>
    ),
    img: <img src={onboarding_create_project} />,
  },
  [OnboardingTaskEnum.EDIT_PROJECT]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1f7"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e64f38243db3c458d86bd" />{" "}
        <a
          href="https://www.figma.com/community/plugin/798826066406007173/%E2%9C%8D%EF%B8%8F-Ditto-%7C-collaborate-on-copy"
          target="_blank"
        >
          <Ditto textId="text_630e65028243db3c458d86bf" />
        </a>{" "}
        <Ditto textId="text_630e65068243db3c458d86c0" />
        <br />
        <br />
        <a
          href="https://www.dittowords.com/docs/setting-up-with-your-ditto-project#add-metadata"
          target="_blank"
          className={style.learnMore}
        >
          <Ditto textId="text_630e65088243db3c458d86c1" />
        </a>
      </>
    ),
    img: <img src={onboarding_edit_project} />,
  },
  [OnboardingTaskEnum.SYNC_CHANGES]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1f9"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e650d8243db3c458d86c2" />
        <br />
        <br />
        <Ditto textId="text_630e65138243db3c458d86c3" />{" "}
        <a
          href="https://www.figma.com/community/plugin/798826066406007173/%E2%9C%8D%EF%B8%8F-Ditto-%7C-collaborate-on-copy"
          target="_blank"
        >
          <Ditto textId="text_630e6a638210d2e40a9d4bb8" />
        </a>{" "}
        <Ditto textId="text_630e6a6a3ba7d95a7a60205a" />
        <br />
        <br />
        <a
          href="https://www.dittowords.com/docs/connecting-ditto-projects-to-figma"
          target="_blank"
          className={style.learnMore}
        >
          <Ditto textId="text_630e65168243db3c458d86c4" />
        </a>
      </>
    ),
    img: <img src={onboarding_sync_changes} />,
  },
  [OnboardingTaskEnum.CREATE_COMPONENTS]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1fb"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e651c8243db3c458d86c5" />
        <br />
        <br />
        <a href="https://www.dittowords.com/docs/components-in-ditto" target="_blank" className={style.learnMore}>
          <Ditto textId="text_630e65248243db3c458d86c6" />
        </a>
      </>
    ),
    img: <img src={onboarding_create_components} />,
  },
  [OnboardingTaskEnum.ATTACH_COMPONENTS]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1fd"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e65398243db3c458d86c8" />
        <br />
        <br />
        <a
          href="https://www.dittowords.com/docs/components-in-ditto#attaching-components"
          target="_blank"
          className={style.learnMore}
        >
          <Ditto textId="text_630e65498243db3c458d86c9" />
        </a>
      </>
    ),
    img: <img src={onboarding_attach_components} />,
  },
  [OnboardingTaskEnum.INVITE_TEAM]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de1ff"></Ditto>,
    description: (
      <>
        <Ditto textId="text_631039664f7b64f1c5901c2b" />
        <br />
        <br />
        <Ditto textId="text_630e654a8243db3c458d86ca" />{" "}
        <Link to="/account/workspace">
          <Ditto textId="text_630e65648243db3c458d86cd" />
        </Link>
        <Ditto textId="text_630e65678243db3c458d86ce" />
      </>
    ),
    img: <img src={onboarding_invite_team} />,
  },
  [OnboardingTaskEnum.ADD_VARIANT]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de215"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e66f28243db3c458d86d0" />
        <br />
        <br />
        <a href="https://www.dittowords.com/docs/variants" target="_blank" className={style.learnMore}>
          <Ditto textId="text_630e67298243db3c458d86d8" />
        </a>
      </>
    ),
    img: <img src={onboarding_add_variant} />,
  },
  [OnboardingTaskEnum.ADD_COMMENT]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de217"></Ditto>,
    description: <Ditto textId="text_630e67018243db3c458d86d3"></Ditto>,
    img: <img src={onboarding_add_comment} />,
  },
  [OnboardingTaskEnum.ENABLE_DEV_INTEGRATIONS]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de219"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e67058243db3c458d86d4" />
        <br />
        <Link to="/developers">
          <Ditto textId="text_630e672c8243db3c458d86d9" />
        </Link>
      </>
    ),
    img: <img src={onboarding_enable_dev_integrations} />,
  },
  [OnboardingTaskEnum.ORGANIZE_COMPONENTS]: {
    title: <Ditto textId="text_630d31fcf35cc16b026de21b"></Ditto>,
    description: (
      <>
        <Ditto textId="text_630e670d8243db3c458d86d6" />{" "}
        <Link to="/components">
          <Ditto textId="text_630e68a28d3aa9fbcce48597" />
        </Link>{" "}
        <Ditto textId="text_630e68cba2d4c27295481749" />
        <br />
        <br />
        <a
          href="https://www.dittowords.com/docs/organizing-your-component-library"
          target="_blank"
          className={style.learnMore}
        >
          <Ditto textId="text_630e672e8243db3c458d86da" />
        </a>
      </>
    ),
    img: <img src={onboarding_organize_components} />,
  },
};
