import { Cacheable, ICache } from "@auth0/auth0-spa-js";

type EavesdropFunction<T> = (value: T) => void;

/**
 * Auth0 SDK cache implementation used to eavesdrop on access + refresh tokens
 * so that they can be ferried to the Figma plugin during Figma authentication sessions.
 */
export class EavesdropMemoryCache implements ICache {
  cache: Record<string, unknown> = {};
  eavesdropFunction: EavesdropFunction<Cacheable>;

  constructor(eavesdropFunction: EavesdropFunction<Cacheable>) {
    this.cache = {};
    this.eavesdropFunction = eavesdropFunction;
  }

  set<T = Cacheable>(key: string, entry: T) {
    this.cache[key] = entry;
    this.eavesdropFunction(this.cache[key] as Cacheable);
  }

  get<T = Cacheable>(key: string) {
    const cacheEntry = this.cache[key] as T;

    if (!cacheEntry) {
      return null;
    }

    return cacheEntry;
  }

  remove(key: string) {
    delete this.cache[key];
  }

  allKeys(): string[] {
    return Object.keys(this.cache);
  }
}
