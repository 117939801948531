// Meant for use alongside useFolderSelect.ts
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import classNames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import style from "./style.module.css";

interface IFolder {
  name: string;
  _id: string;
}

interface FolderSelectProps {
  folders: IFolder[];
  selectedFolder: IFolder;
  setSelectedFolder: (folder: IFolder) => void;
  text?: React.ReactNode;
  fontSize?: string;
  className?: string;
  dropdownClassName?: string;
  dropdownItemClassName?: string;
  defaultId?: string;
}

export const hasValidFolderLoaded = (folders: IFolder[]) => !(folders.length === 1 && folders[0]._id === "");

const FolderSelect = (props: FolderSelectProps) => {
  const { folders, selectedFolder, setSelectedFolder, text, fontSize } = props;

  // A default option is typically appended to the folders array that has an empty _id.
  // If this is the only item in the folders array, then return a Fragment since there
  // are no folders to select from.
  if (!hasValidFolderLoaded(props.folders)) {
    return <React.Fragment />;
  }

  // icon font size is fontSize plus 4, to make it look right
  const iconFontSize = `${parseInt(fontSize || "16px") + 4}px`;
  const defaultFolderId = props.defaultId || "";

  return (
    <div className={classNames(style.container, props.className)} style={{ fontSize: fontSize || "16px" }}>
      <strong className={style.prefixText}>{text || "Searching in"} </strong>
      <Dropdown
        className={classNames(style.dropdown, props.dropdownClassName, {
          [style.inFolder]: selectedFolder._id !== defaultFolderId,
        })}
      >
        <Dropdown.Toggle
          className={style.dropdownToggle}
          style={{ fontSize: fontSize || "16px" }}
          data-testid="folder-select-toggle"
        >
          <span className={style.dropdownToggleText}>
            {selectedFolder._id !== defaultFolderId && (
              <FolderOpenOutlined className={style.icon} style={{ fontSize: iconFontSize }} />
            )}
            <span className={classNames(style.selectedFolderName, "selected-folder-name")}>{selectedFolder.name}</span>
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {folders.map((folder) => {
            return (
              <Dropdown.Item
                className={classNames(style.dropDownItem, props.dropdownItemClassName)}
                key={folder._id}
                onClick={() => setSelectedFolder(folder)}
                data-testid={`folder-select-${folder.name}`}
              >
                {folder.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default FolderSelect;
