import CloseIcon from "@mui/icons-material/Close";
import * as SegmentEvents from "@shared/segment-event-names";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import figcomp from "../../assets/figcomp.svg";
import figComponentSetInstance from "../../assets/figma_component_set_instance.svg";
import figinstance from "../../assets/figma_instance.svg";
import figmaLogo from "../../assets/figma_logo.png";
import useSegment from "../../hooks/useSegment";
import FigmaFrameVariantWarning from "../FigmaFrameVariantWarning";
import HighlightAltIcon from "../HighlightAltIcon";
import style from "./style.module.css";

const EnlargeImageModal = ({
  imageUrl,
  imageInfo,
  artboardName,
  onHide,
  figmaFrameURL,
  docName,
  selectedFigmaIds,
  showHighlight,
  setShowHighlight,
  canHighlight,
  appliedVariant,
}) => {
  const segment = useSegment();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [imageRenderDimensions, setImageRenderDimensions] = useState(null);
  const [highlightBoxStyles, sethighlightBoxStyles] = useState([]);

  const imgElement = React.useRef(null);
  const handleImgLoaded = () => {
    if (imgElement.current) {
      setImageRenderDimensions({
        width: imgElement.current.width,
        height: imgElement.current.height,
      });
    }
    setImageLoaded(true);
  };
  const toggleShowHighlight = () => {
    segment.track({
      event: SegmentEvents.IMAGE_PREVIEW_HIGHLIGHT_CLICKED,
      properties: {
        location: "enlarged image modal",
        show_highlight: !showHighlight,
      },
    });
    setShowHighlight(!showHighlight);
  };
  const handleImgLoadingError = async (e) => {
    e.persist();
    try {
      const imgResponse = await fetch(imageUrl);
      if (!imgResponse.ok) {
        e.target.style.display = "none";
        setImageError({ exists: true, status: imgResponse.status });
      } else {
        setImageError({ exists: true });
      }
    } catch (e) {
      console.warn("Failed to fetch image preview: ", imageUrl, e);
      setImageError({ exists: true });
    }
  };
  const openInFigma = () => {
    window.open(figmaFrameURL, "_blank");
  };
  const openTutorial = () => {
    window.open(
      "https://www.notion.so/dittov3/Sync-Ditto-edits-back-to-your-Figma-file-90c87fb2e99141c380566a62e42e9019",
      "_blank"
    );
  };

  // const IMAGE_WIDTH = 830;
  const MODAL_PADDING = 16; // Modal padding, value comes the padding in style.css .relativeContainer

  const windowResize = (e) => {
    if (imgElement.current) {
      setImageRenderDimensions({
        width: imgElement.current.width,
        height: imgElement.current.height,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  useEffect(() => {
    if (canHighlight && imageRenderDimensions) {
      const { frameDimensions, textDimensions } = imageInfo;
      if (!frameDimensions || !textDimensions) {
        return;
      }
      const boxStyles = [];
      selectedFigmaIds.forEach((selectedFigmaId) => {
        let textNodeDimensions = textDimensions[selectedFigmaId];
        if (textNodeDimensions) {
          let tempScaleFactor = frameDimensions.width / imageRenderDimensions.width;
          const boxStyle = {
            wrapper: {
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: Math.abs(frameDimensions.x - textNodeDimensions.x) / tempScaleFactor + MODAL_PADDING + "px",
              top: Math.abs(frameDimensions.y - textNodeDimensions.y) / tempScaleFactor + MODAL_PADDING + "px",
              width: textNodeDimensions.width / tempScaleFactor + "px",
              height: textNodeDimensions.height / tempScaleFactor + "px",
              textAlign: "left",
              justifyContent: "flex-start",
            },
            box: {
              position: "absolute",
              borderRadius: "4px",
              width: textNodeDimensions.width / tempScaleFactor + 5 + "px",
              height: textNodeDimensions.height / tempScaleFactor + 5 + "px",
              border: "2px solid red",
            },
          };
          boxStyles.push(boxStyle);
        }
        sethighlightBoxStyles(boxStyles);
      });
    }
  }, [canHighlight, imageRenderDimensions, imageInfo]);

  const imageStyle = {
    width: "100%",
  };

  const FrameName = () => {
    return (
      <span data-tip data-for="open-frame-tooltip" className={style.frameTitle} onClick={openInFigma}>
        <ReactTooltip id="open-frame-tooltip" place="bottom" effect="solid" className={style.toolTip}>
          Open frame in Figma
        </ReactTooltip>
        {artboardName.startsWith("[[M]]") && (
          <span className={style.figmacomp}>
            {artboardName.substring(5)}
            <img src={figcomp} />
          </span>
        )}
        {artboardName.startsWith("[[I]]") && (
          <span className={style.figmacomp}>
            {artboardName.substring(5)}
            <img src={figinstance} />
          </span>
        )}
        {artboardName.startsWith("[[V]]") && (
          <span className={style.figmacomp}>
            {artboardName.substring(5)}
            <img src={figComponentSetInstance} />
          </span>
        )}
        {!artboardName.startsWith("[[M]]") &&
          !artboardName.startsWith("[[V]]") &&
          !artboardName.startsWith("[[I]]") && <>{artboardName}</>}
      </span>
    );
  };

  return (
    <div>
      {
        <BootstrapModal
          show={true}
          className={style.modal}
          dialogClassName={style.dialog}
          backdropClassName={style.backdrop}
          onHide={onHide}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>
              <img className={style.figmaLogo} src={figmaLogo} />
              <FrameName />
              <span className={style.fileName}> — {docName}</span>
              {canHighlight && (
                <span
                  className={classnames({
                    [style.highlightButton]: true,
                    [style.highlightSelected]: showHighlight,
                  })}
                  onClick={toggleShowHighlight}
                >
                  <HighlightAltIcon />
                  <span>Highlight</span>
                </span>
              )}
            </BootstrapModal.Title>
            <CloseIcon className={style.close} onClick={onHide} />
          </BootstrapModal.Header>
          <BootstrapModal.Body className={style.body}>
            {appliedVariant && (
              <div className={style.warningWrapper}>
                <FigmaFrameVariantWarning
                  exists={appliedVariant.exists}
                  variantName={appliedVariant.name}
                  isEnlarge={true}
                />
              </div>
            )}
            {imageError.exists ? (
              <div>
                {imageError.status === "no_fig_token" && (
                  <div className={style.imgPlaceholderText}>
                    Sorry, we couldn't fetch the image preview. Please check that you've{" "}
                    <a href={routes.nonNavRoutes.account.path + "/user"}>connected your Figma account.</a>
                  </div>
                )}
                {imageError.status === 500 && (
                  <div className={style.imgPlaceholderText}>
                    Sorry, this image was too large for Figma's servers to send over.
                  </div>
                )}
                {imageError.status === 404 && (
                  <div className={style.imgPlaceholderText}>Error loading image preview.</div>
                )}
                {imageError.status === 429 && (
                  <div className={style.imgPlaceholderText}>
                    Sorry, we couldn't fetch the image preview because we've hit Figma's server limitation.
                  </div>
                )}
                {imageError.status === 403 && (
                  <div className={style.imgPlaceholderText}>We&apos;re still fetching this image preview.</div>
                )}
                {imageError.status === 400 && (
                  <div className={style.imgPlaceholderText}>
                    Sorry, we couldn't fetch the image preview. Please check that you've connected your Figma account.
                  </div>
                )}
              </div>
            ) : (
              <div className={style.relativeContainer}>
                <img
                  ref={imgElement}
                  src={imageUrl}
                  onLoad={handleImgLoaded}
                  onError={handleImgLoadingError}
                  alt="figma frame preview"
                  style={imageStyle}
                />
                {canHighlight &&
                  showHighlight &&
                  highlightBoxStyles.map(({ wrapper, box }, index) => (
                    <div key={index} style={wrapper}>
                      <div style={box}></div>
                    </div>
                  ))}
              </div>
            )}
            {!imageLoaded && !imageError.exists && <div className={style.imgPlaceholderText}>Loading image...</div>}
          </BootstrapModal.Body>
        </BootstrapModal>
      }
    </div>
  );
};

export default EnlargeImageModal;
