import { getTags, getUsers } from "@/http/workspaceNew";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { IFUser } from "@shared/types/User";
import { atom } from "jotai";
import { derive } from "jotai-derive";
import { atomWithRefresh, unwrap } from "jotai/utils";

export const usersAtom = atomWithRefresh(() => getUsers()[0].then((r) => r.data));
export const deferredUsersAtom = derive([usersAtom], (users) => users);

export const usersByIdAtom = derive([usersAtom], (usersArr) => {
  return usersArr.reduce((acc, user) => {
    acc[user._id] = user;
    return acc;
  }, {} as Record<string, IFUser>);
});

export const { valueAtom: allTagsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const tags = (await getTags({ excludeWsComps: false, sortAlpha: true, excludeSampleData: true })[0]).data;
    return tags;
  },
  debugLabel: "All Tags",
});
export const deferredAllTagsAtom = derive([allTagsAtom], (tags) => tags);
export const unwrappedAllTagsAtom = unwrap(allTagsAtom, (prev) => prev ?? []);

export const createNewTagActionAtom = atom(null, async (get, set, newTag: string) => {
  const tags = await get(allTagsAtom);
  const newTags = [...tags];
  newTags.push({
    _id: newTag,
    total: 0,
  });

  set(allTagsAtom, newTags);
});
