import axios, { AxiosResponse } from "axios";
import http from "./lib/client";

const url = (path: string) => `/enterprise${path}`;

export const fetchEnterpriseConfigByKey = (key: string): [Promise<AxiosResponse<any>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<any>(url(`/config/${key}`), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export const fetchEnterpriseConfigByEmailAddress = (email: string): [Promise<AxiosResponse<any>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.get<any>(url(`/config?email=${email}`), {
    cancelToken: source.token,
  });

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};
