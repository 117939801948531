import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const LightbulbOutline = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="2.5 1 9 11"
    {...props}
  >
    <path
      d="M5.24984 12.2493C5.24984 12.5702 5.51234 12.8327 5.83317 12.8327H8.16651C8.48734 12.8327 8.74984 12.5702 8.74984 12.2493V11.666H5.24984V12.2493ZM6.99984 1.16602C4.74817 1.16602 2.9165 2.99768 2.9165 5.24935C2.9165 6.63768 3.61067 7.85685 4.6665 8.59768V9.91602C4.6665 10.2368 4.929 10.4993 5.24984 10.4993H8.74984C9.07067 10.4993 9.33317 10.2368 9.33317 9.91602V8.59768C10.389 7.85685 11.0832 6.63768 11.0832 5.24935C11.0832 2.99768 9.25151 1.16602 6.99984 1.16602ZM8.16651 7.99102V9.33268H5.83317V7.99102C4.9465 7.36685 4.08317 6.72518 4.08317 5.24935C4.08317 3.63935 5.38984 2.33268 6.99984 2.33268C8.60984 2.33268 9.91651 3.63935 9.91651 5.24935C9.91651 6.70185 9.03567 7.37852 8.16651 7.99102Z"
      fill="#7E8490"
      stroke="#7E8490"
      strokeWidth="0.2"
    />
  </svg>
);

export default LightbulbOutline;
