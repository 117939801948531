import classnames from "classnames";
import React from "react";
import partyEmoji from "../../assets/party_emoji.png";
import style from "./style.module.css";

interface Props {
  newProject: () => void;
  isFirstProject: boolean;
  hasSampleProject?: boolean;
  text?: string;
}

const NewCard = ({ newProject, isFirstProject, hasSampleProject, text }: Props) => {
  function getText() {
    let text = "Welcome to Ditto! Click here to start your first project";
    hasSampleProject ? (text += ", or check out the sample project to the right.") : (text += ".");
    return text;
  }

  return (
    <div className={style.responsiveCol}>
      <div
        className={classnames(style.card, style.newcard)}
        onClick={newProject}
        data-tip
        data-for="new-proj"
        data-testid="new-project-button"
      >
        {text || "Start a new project"}
      </div>
      {isFirstProject && (
        <div className={style.guideWrap}>
          <div className={style.arrow_up}></div>
          <div className={style.firstProjectGuide}>
            <img className={style.emoji} src={partyEmoji} />
            {getText()}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCard;
