import React, { useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import style from "./MultiSelectPopover.module.css";

const PopoverHighlight = ({ children }) => <span className={style.popoverHighlight}>{children}</span>;

const LEARN_MORE_HREF = "https://dittowords.com/docs/keyboard-shortcuts";

function MultiSelectPopover(props) {
  return (
    <Popover id="multselect-popover" {...props} className={style.popover}>
      <Popover.Content className={style.popoverContent}>
        Multi-select turns on when you <br />
        hold down <PopoverHighlight>shift</PopoverHighlight> or <PopoverHighlight>⌘</PopoverHighlight> /{" "}
        <PopoverHighlight>ctrl</PopoverHighlight>. <br />
        <a href={LEARN_MORE_HREF} target="_blank" className={style.popoverLink}>
          Learn more
        </a>
      </Popover.Content>
    </Popover>
  );
}

export const MultiSelectPopoverTrigger = ({ children, show }) => {
  return (
    <OverlayTrigger trigger="click" placement="bottom-end" overlay={MultiSelectPopover} show={show}>
      {children}
    </OverlayTrigger>
  );
};

const POPOVER_DISPLAY_COUNT_KEY = "multiselect-popover-display-count";
const POPOVER_DISPLAY_COUNT_LIMIT = 2;
const POPOVER_DISPLAY_TIME_MS = 6000;

export const useMultiSelectPopover = () => {
  const [showMultiSelectPopover, setShowMultiSelectPopover] = useState(false);

  const handleMultiSelectPopover = () => {
    if (showMultiSelectPopover) {
      setShowMultiSelectPopover(false);
      return;
    }

    const displayCountStr = localStorage.getItem(POPOVER_DISPLAY_COUNT_KEY) || "0";
    const displayCount = +displayCountStr;

    if (displayCount < POPOVER_DISPLAY_COUNT_LIMIT) {
      localStorage.setItem(POPOVER_DISPLAY_COUNT_KEY, displayCount + 1);
      setShowMultiSelectPopover(true);
      setTimeout(() => setShowMultiSelectPopover(false), POPOVER_DISPLAY_TIME_MS);
    }
  };

  return {
    showMultiSelectPopover,
    handleMultiSelectPopover,
  };
};
