import { useProjectContext } from "@/views/Project/state/useProjectState";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SearchIcon from "@mui/icons-material/Search";
import UndoIcon from "@mui/icons-material/Undo";
import classnames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import CableIcon from "../../../../../../assets/CableIcon";
import FigmaIcon from "../../../../../../components/FigmaLogoIcon";
import { PageSelected } from "../../../../state/usePageState";
import { ManageGroupsModalContext } from "../../ManageGroupsModalContext";
import { SelectedGroup } from "../CurrentGroupsModal";
import style from "./style.module.css";
const DRAFT_PAGE_ID = "__draft__";

interface PageItemProps {
  groups: SelectedGroup[];
}

const PageItem = ({ groups }: PageItemProps) => {
  const { groupsToDelete, groupsToUnlink } = useContext(ManageGroupsModalContext);

  const totalGroups = groups || [];
  const selectedGroups = new Set([...groupsToDelete, ...groupsToUnlink]);
  const numRemainingGroups = totalGroups.filter((g) => !selectedGroups.has(g._id)).length;

  if (numRemainingGroups === 0) return null;
  return (
    <div className={style.pageInfo}>
      <div className={style.groupCountPill}>{numRemainingGroups}</div>
      <ChevronRight className={style.icon} />
    </div>
  );
};

interface GroupRemoveButtonProps {
  pageId: string;
  groupId: string;
}
const GroupRemoveButton = ({ pageId, groupId }: GroupRemoveButtonProps) => {
  const { groupsToDelete, groupsToUnlink } = useContext(ManageGroupsModalContext);

  if (pageId === DRAFT_PAGE_ID) {
    if (groupsToDelete.has(groupId)) {
      return (
        <>
          <UndoIcon /> Cancel delete
        </>
      );
    }
    return (
      <>
        <DeleteIcon /> Delete
      </>
    );
  } else {
    if (groupsToUnlink.has(groupId)) {
      return (
        <>
          <UndoIcon /> Cancel unlink
        </>
      );
    }
    return (
      <>
        <LinkOffIcon /> Unlink frame
      </>
    );
  }
};

interface PageGroupSelectionProps {
  pages: PageSelected[];
  groupsByPage: {
    [pageId: string]: SelectedGroup[];
  };
  devModeEnabled: boolean;
  isSaving: boolean;
}
const PageGroupSelection = ({ pages, groupsByPage, devModeEnabled, isSaving }: PageGroupSelectionProps) => {
  const { groupsToDelete, setGroupsToDelete, groupsToUnlink, setGroupsToUnlink } = useContext(ManageGroupsModalContext);
  const {
    doc: [project],
  } = useProjectContext();
  const [selectedPage, setSelectedPage] = useState<PageSelected | undefined>(pages[0]);
  const [query, setQuery] = useState<string>("");

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const filteredGroups = useMemo(() => {
    if (!selectedPage?.id) {
      return [];
    }

    const groups = groupsByPage[selectedPage.id];
    if (!groups) {
      return [];
    }

    if (!query) {
      return groups;
    }

    const q = query.toLowerCase();
    return groups.filter((g) => g.name.toLowerCase().includes(q));
  }, [groupsByPage, selectedPage, query]);

  const autoImportFramesEnabledForPage = useMemo(
    function handleAutoImportFramesEnabledForPage() {
      return (
        project?.feature_flags?.autoImportFrames?.enabled &&
        !!project?.feature_flags?.autoImportFrames?.selectedPages &&
        selectedPage &&
        project?.feature_flags?.autoImportFrames?.selectedPages[selectedPage.id]
      );
    },
    [project, selectedPage?.id]
  );

  const onClickRemove = (group: SelectedGroup) => {
    if (isSaving) return;
    if (selectedPage?.id === DRAFT_PAGE_ID) {
      if (groupsToDelete.has(group._id)) {
        setGroupsToDelete((prev) => {
          const newGroupsToDelete = new Set(prev);
          newGroupsToDelete.delete(group._id);
          return newGroupsToDelete;
        });
      } else {
        setGroupsToDelete((prev) => {
          return new Set([...prev, group._id]);
        });
      }
    }
    if (selectedPage?.id !== DRAFT_PAGE_ID) {
      if (groupsToUnlink.has(group._id)) {
        setGroupsToUnlink((prev) => {
          const newGroupsToDelete = new Set(prev);
          newGroupsToDelete.delete(group._id);
          return newGroupsToDelete;
        });
      } else {
        setGroupsToUnlink((prev) => {
          return new Set([...prev, group._id]);
        });
      }
    }
  };

  return (
    <div className={style.wrapper}>
      <div className={style.pagesSection}>
        <div className={style.pageTitle}>Pages</div>
        <div className={style.pageList}>
          {pages.map((page) => (
            <div
              key={page.id}
              className={classnames({
                [style.pageItem]: true,
                [style.active]: selectedPage && page.id === selectedPage.id,
              })}
              onClick={() => setSelectedPage(page)}
            >
              <div className={style.pageNameWrapper}>
                {page.id === DRAFT_PAGE_ID ? <span>{"✍"}</span> : <FigmaIcon className={style.figmaIcon} />}
                <div className={style.pageName}>{page.name}</div>
              </div>
              <PageItem groups={groupsByPage[page.id]} />
            </div>
          ))}
        </div>
      </div>

      <div className={style.groupSection}>
        <div className={style.groupHeader}>
          {selectedPage && <div className={style.groupTitle}>{selectedPage.name}</div>}
          {devModeEnabled && selectedPage?.id === DRAFT_PAGE_ID && (
            <div className={style.warningMessage}>
              Heads up! Because developer mode is turned on in this project, some of these groups may be used in
              production.
            </div>
          )}
          <div className={style.searchBox}>
            <SearchIcon className={style.icon} />
            <input
              autoFocus
              className={classnames(style.input)}
              placeholder={"Search frames"}
              value={query}
              onChange={handleQueryChange}
            />
          </div>
        </div>
        <div className={style.frameList}>
          {filteredGroups.map((group) => (
            <div
              key={group._id}
              className={classnames({
                [style.groupItem]: true,
                [style.isSelected]: groupsToDelete.has(group._id) || groupsToUnlink.has(group._id),
                [style.isDisabled]: isSaving,
              })}
            >
              <span className={style.groupName}>
                {group.name}
                {selectedPage?.id !== DRAFT_PAGE_ID && (
                  <div className={style.frameName}>
                    <CableIcon className={style.cableIcon} />
                    {group.frameName}
                  </div>
                )}
              </span>
              <div
                data-tip
                data-for={`group-remove-${group._id}`}
                className={classnames({
                  [style.deleteGroupBtn]: !autoImportFramesEnabledForPage,
                  [style.deleteGroupBtnDisabled]: autoImportFramesEnabledForPage,
                })}
                onClick={() => {
                  if (!autoImportFramesEnabledForPage) onClickRemove(group);
                }}
              >
                {selectedPage && <GroupRemoveButton pageId={selectedPage.id} groupId={group._id} />}
              </div>
              {autoImportFramesEnabledForPage && (
                <ReactTooltip id={`group-remove-${group._id}`} place="bottom" effect="solid">
                  Disabled due to frame auto-import
                </ReactTooltip>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageGroupSelection;
