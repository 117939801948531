import { useWorkspace } from "@/store/workspaceContext";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import * as SegmentEvents from "@shared/segment-event-names";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Toggle from "react-toggle";
import useSegment from "../../hooks/useSegment";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import CardSection from "../cardsection/cardsection";
import CounterInput from "../counter-input/counter-input";
import style from "./style.module.css";

const PaymentModal = ({ onHide, users, name, email }) => {
  const segment = useSegment();

  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState("");
  const [nameInput, setNameInput] = useState(name);
  const [emailInput, setEmailInput] = useState(email);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [modalState, setModalState] = useState("SEATS"); //States: SEATS, PAY, SUCCESS

  //billing cycle
  const [annualBilling, setAnnualBilling] = useState(true);
  const toggleBillingCycle = () => setAnnualBilling(!annualBilling);

  //how many are in the workspace atm, can't go lower
  const [baseEditors, setBaseEditors] = useState(0);
  const [baseCommenters, setBaseCommenters] = useState(0);
  //seats purchased
  const [editors, setEditors] = useState(0);
  const [commenters, setCommenters] = useState(0);

  const { workspaceInfo } = useWorkspace();

  const pricing = {
    annual: {
      edit: 15,
      comment: 10,
    },
    monthly: {
      edit: 18,
      comment: 12,
    },
  };

  const editorsCost = () => {
    if (annualBilling) {
      return editors * 12 * pricing.annual.edit;
    } else {
      return editors * pricing.monthly.edit;
    }
  };

  const commentersCost = () => {
    if (annualBilling) {
      return commenters * 12 * pricing.annual.comment;
    } else {
      return commenters * pricing.monthly.comment;
    }
  };

  useEffect(() => {
    if (!users || users.length === 0) return;
    else {
      var editorUsers = 0;
      var commenterUsers = 0;
      for (var user of users) {
        if (user?.role === "commenter") {
          commenterUsers += 1;
        } else {
          editorUsers += 1;
        }
      }
      setBaseEditors(editorUsers);
      setEditors(editorUsers);
      setBaseCommenters(commenterUsers);
      setCommenters(commenterUsers);
    }
  }, [users]);

  const handleNameChange = (e) => {
    setNameInput(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleError = ({ message }) => {
    console.log(`An error occurred: ${message}`);
    setPaymentError(message);
    setPaymentLoading(false);
    setTimeout(() => setPaymentError(""), 5000);
  };

  const upgradeWorkspace = async (paymentMethod) => {
    // TODO: Update this to use tiers
    const { url, body } = API.workspace.put.upgrade;
    const { data } = await http.put(
      url,
      body({
        email: emailInput,
        name: nameInput,
        payment_method: paymentMethod.id,
        num_editors: editors,
        num_commenters: commenters,
        is_annual: annualBilling,
      })
    );
    if (data.error) {
      throw new Error(data.message);
    }

    return data;
  };

  const createPaymentMethod = async () => {
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        email: emailInput,
      },
    });

    return result.paymentMethod;
  };

  const handleSubmit = async (_event) => {
    // Stripe hasn't loaded; disable form submission until then
    if (!stripe || !elements) return;

    setPaymentLoading(true);

    try {
      const paymentMethod = await createPaymentMethod();
      await upgradeWorkspace(paymentMethod);

      setPaymentLoading(false);
      setPaymentSuccess(true);
      setModalState("SUCCESS");

      segment.track({
        event: SegmentEvents.WORKSPACE_UPGRADED,
        properties: {
          existing_plan: workspaceInfo.plan,
          num_editors: editors,
          num_commenters: commenters,
          new_plan: "team",
          subscription_type: annualBilling ? "yearly" : "monthly",
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const refreshPage = () => {
    location.reload();
  };

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            {paymentSuccess ? "Successfully upgraded! 🎉" : "Upgrading to the Team Plan"}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        {modalState === "SEATS" && (
          <div>
            <BootstrapModal.Body className={style.body}>
              <p>
                Thank you for choosing to upgrade Ditto! Upgrading helps us continue to work on Ditto and build out
                features. 💗
              </p>

              <table className={style.seats}>
                <tbody>
                  <tr>
                    <td></td>
                    <td className={style.label}>Seats</td>
                    <td className={style.label}>Cost</td>
                  </tr>
                  <tr>
                    <td>
                      Editors
                      <div className={style.small}>
                        ${annualBilling ? pricing.annual.edit : pricing.monthly.edit} / editor / month
                      </div>
                    </td>
                    <td>
                      <CounterInput min={baseEditors} count={editors} onCountChange={(count) => setEditors(count)} />
                    </td>
                    <td>${editorsCost().toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>
                      Commenters
                      <div className={style.small}>
                        ${annualBilling ? pricing.annual.comment : pricing.monthly.comment} / commenter / month
                      </div>
                    </td>
                    <td>
                      <CounterInput
                        min={baseCommenters}
                        count={commenters}
                        onCountChange={(count) => setCommenters(count)}
                      />
                    </td>
                    <td>${commentersCost().toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Billing cycle</td>
                    <td className={style.billingCycle}>
                      <div className={style.right}>
                        <div className={style.small}>Yearly</div>
                        <div className={style.discount}>16% discount</div>
                      </div>
                      <div className={style.toggle}>
                        <Toggle checked={annualBilling} icons={false} onChange={() => toggleBillingCycle()} />
                      </div>
                      <div className={style.small}>Monthly</div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className={style.totalRow}>
                <div className={style.left}>
                  Total
                  <div className={style.small}>You can always add more users later.</div>
                </div>
                <div className={style.right}>
                  ${(editorsCost() + commentersCost()).toLocaleString()}
                  <div className={style.small}>per {annualBilling ? "year" : "month"}</div>
                </div>
              </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className={style.footer}>
              <div className={style.footerText}>
                <a href="https://www.dittowords.com/pricing" target="_blank">
                  <span className={style.underline}>Full feature and pricing breakdown</span>
                  <OpenInNewIcon className={style.icon} />
                </a>
              </div>
              <ButtonPrimary onClick={() => setModalState("PAY")} text={"Continue"} />
            </BootstrapModal.Footer>
          </div>
        )}
        {modalState === "PAY" && (
          <div>
            <BootstrapModal.Body className={style.body}>
              <div className={style.totalRow}>
                <div className={style.left}>
                  Total
                  <div className={style.small}>
                    {editors} Editor{editors > 1 ? "s" : ""}, {commenters} Commenter
                    {commenters > 1 ? "s" : ""}
                  </div>
                </div>
                <div className={style.right}>
                  ${(editorsCost() + commentersCost()).toLocaleString()}
                  <div className={style.small}>per {annualBilling ? "year" : "month"}</div>
                </div>
              </div>
              <div className={style.paymentForm}>
                <h3>Payment</h3>
                <p>
                  Payment information is handled securely by{" "}
                  <a href="https://stripe.com/" target="_blank">
                    Stripe
                  </a>
                  . Ditto does not store any of your payment information.
                </p>
                <h4>Name</h4>
                <input
                  type="text"
                  placeholder="Full Name"
                  aria-label="Full Name"
                  value={nameInput}
                  onChange={handleNameChange}
                />
                <h4>Billing Email</h4>
                <input
                  type="text"
                  placeholder="Billing Email"
                  aria-label="Billing Email"
                  value={emailInput}
                  onChange={handleEmailChange}
                />
                <CardSection />
                <p className={style.paymentError}>{paymentError}</p>
                <p>
                  By upgrading, you agree to our{" "}
                  <a href="https://www.dittowords.com/legal/terms-of-service" target="_blank">
                    Terms of Service
                  </a>
                  . Your subscription will renew on {annualBilling ? "an annual" : "a monthly"} basis, and you may
                  cancel at any time.
                </p>
              </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className={style.footer}>
              <div className={style.backButton} onClick={() => setModalState("SEATS")}>
                <KeyboardArrowLeftIcon className={style.icon} />
                Back
              </div>
              <ButtonPrimary
                onClick={handleSubmit}
                text={paymentLoading ? "Processing..." : "Upgrade Plan"}
                disabled={paymentLoading}
              />
            </BootstrapModal.Footer>
          </div>
        )}
        {modalState === "SUCCESS" && (
          <div>
            <BootstrapModal.Body className={style.body}>
              <p>Your workspace has been upgraded to the Team Plan! You should receive a receipt via email shortly.</p>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className={style.footer}>
              <div className={style.footerText}></div>
              <ButtonPrimary onClick={refreshPage} text="Finish" />
            </BootstrapModal.Footer>
          </div>
        )}
      </BootstrapModal>
    </div>
  );
};

export default PaymentModal;
