import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import JSConfetti from "js-confetti";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { clearFigmaCorrelationKey, getFigmaCorrelationKey } from "../../figma/lib";
import CenteredAlert from "../CenteredAlert";
import WelcomeModal from "../WelcomeModal";
import Spinner from "../spinner/spinner";
import style from "./style.module.css";

const ReturnToWebApp = () => (
  // this needs to be a full refresh so that the app re-initializes
  // with the non-Figma Auth0 Client ID
  <a href={process.env.BASE_URL} className={style.returnToWebApp}>
    {"Or, continue in the web app ->"}
  </a>
);

const useRedirectOnce = () => {
  const hasRedirected = useRef<boolean>(false);

  return (url: string) => {
    if (hasRedirected.current) {
      return;
    }

    window.location.replace(url);
  };
};

const useConfettiCannon = () => {
  const confetti = useRef<JSConfetti>(new JSConfetti());
  const confettiHasFired = useRef(false);

  const fireOnce = (delay = 100) => {
    if (confettiHasFired.current) {
      return;
    }

    setTimeout(() => confetti.current.addConfetti(), delay);
    confettiHasFired.current = true;
  };

  return { fireOnce };
};

const FigmaAuthScreen = () => {
  const { user } = useAuthenticatedAuth();
  const confettiCannon = useConfettiCannon();
  const redirectOnce = useRedirectOnce();

  const { current: figmaCorrelationKey } = useRef(getFigmaCorrelationKey());

  useEffect(() => {
    clearFigmaCorrelationKey();
  }, []);

  const hasFigmaCorrelationKey = !!figmaCorrelationKey;

  if (hasFigmaCorrelationKey === false) {
    // this needs to be a full refresh so that the app re-initializes
    // with the non-Figma Auth0 Client ID
    redirectOnce(process.env.BASE_URL!);
    return <Spinner />;
  }

  confettiCannon.fireOnce();

  return (
    <CenteredAlert.Container classNames={{ container: style.modalWrapper }} floatingMessage={<ReturnToWebApp />}>
      <WelcomeModal />
      <CenteredAlert.Content
        title="You've successfully logged into Ditto's Figma plugin. 🎉"
        subtitle="Close this page and head back to Figma to continue using the plugin."
        footerText={
          <span>
            Logged in as {user.email}. Not right?{" "}
            <Link className={style.logoutLink} to="/logout">
              Log out here.
            </Link>
          </span>
        }
      />
    </CenteredAlert.Container>
  );
};

export default FigmaAuthScreen;
