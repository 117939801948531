import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MAX_NUMBER_OF_FREE_PLAN_USERS, TRIAL_PLAN_LENGTH_DAYS } from "../util/constants";
import http, { API } from "./http";
import { rolePermissionsMap, workspaceFeatureMap } from "./role-permissions-rules";
import { useAuthenticatedAuth } from "./store/AuthenticatedAuthContext";

export const FlaggedFeaturesContext = createContext();

export const FlaggedFeaturesProvider = ({ children }) => {
  const { user } = useAuthenticatedAuth();
  const [flags, setFlags] = useState({});
  const [numberOfUsers, setNumberOfUsers] = useState(null);
  const [workspaceInfo, setWorkspaceInfo] = useState({});
  const [userPermissions, setUserPermissions] = useState([]);
  const [, setLoadingFlags] = useState(false);
  const [isProjectLocked, setIsProjectLocked] = useState(false);

  const location = useLocation();

  const initGetFlags = async () => {
    setLoadingFlags(true);

    try {
      const { url: usersUrl } = API.workspace.get.users;
      const { url: workspaceUrl } = API.api.get.workspace;

      Promise.all([http.get(workspaceUrl("current")), http.get(usersUrl)]).then(
        async ([{ data: workspaceResponseResult }, { data: workspaceUsers }]) => {
          if (workspaceUsers) {
            setNumberOfUsers(workspaceUsers.length);
          }

          setWorkspaceInfo(workspaceResponseResult.workspaceInfo);
          if (!workspaceResponseResult.workspaceInfo.flags) {
            setFlags({});
          } else {
            setFlags(workspaceResponseResult.workspaceInfo.flags);
          }

          setLoadingFlags(false);
        }
      );
    } catch (e) {
      setFlags({});
      setLoadingFlags(false);
      console.log("error fetching feature flags: ", e.message);
    }
  };

  const checkIfProjectLocked = async () => {
    let isLocked = false;
    try {
      const match = location.pathname.match(/\/projects\/(\w+)\/*/);

      const projectId = match ? match[1] : null;
      // we don't want to make this api call on /projects/sample because sample isn't a real project id
      if (projectId && projectId !== "sample") {
        const { url } = API.project.get.isLocked;
        const { data } = await http.get(url(projectId));
        isLocked = data.isLocked;
      }
    } catch (e) {
      console.error("Error checking if project locked", e);
    }
    setIsProjectLocked(isLocked);
  };
  const initGetPermissions = () => {
    const perms = rolePermissionsMap[user.role].static;
    setUserPermissions(perms);
  };

  useEffect(() => {
    initGetFlags();
    initGetPermissions();
  }, []);

  useEffect(() => {
    checkIfProjectLocked();
  }, [user, location?.pathname]);

  return (
    <FlaggedFeaturesContext.Provider
      value={{
        flags,
        workspaceInfo,
        numberOfUsers,
        userPermissions,
        isProjectLocked,
        checkIfProjectLocked,
      }}
    >
      {children}
    </FlaggedFeaturesContext.Provider>
  );
};

export const useWorkspaceFeatureEnabled = (featureName) => {
  const {
    workspaceInfo: { plan },
  } = useContext(FlaggedFeaturesContext);

  return workspaceFeatureMap[plan]?.includes(featureName);
};

// permissions need to be separated by space: permissions="permission_1 permission_2"
export const FlaggedFeature = (props) => {
  const { name, permission, children } = props;
  const { flags, workspaceInfo, numberOfUsers, userPermissions, isProjectLocked } = useContext(FlaggedFeaturesContext);

  let isEnabled = flags[name] ? true : false;

  if (name === "export") {
    let isEnabled = true;
    let workspacePlan = workspaceInfo.plan;
    const wsTrial = workspaceInfo.ws_trial;
    if (workspacePlan === "trial") {
      const days_since = Math.floor((Date.now() - Date.parse(workspaceInfo.ws_trial)) / 86400000);
      if (days_since > TRIAL_PLAN_LENGTH_DAYS && numberOfUsers && numberOfUsers > MAX_NUMBER_OF_FREE_PLAN_USERS) {
        isEnabled = false;
      }
    }
    if (workspacePlan === "free") isEnabled = false;

    return <>{children({ workspacePlan, isEnabled, wsTrial })}</>;
  }
  if (name === "invite:commenter") {
    isEnabled = workspaceInfo.plan !== "free";
  }

  if (permission && userPermissions.includes(permission)) {
    isEnabled = true;
    return <>{children({ isEnabled })}</>;
  }
  return <>{children(isEnabled)}</>;
};
