const ROOT = "/comments";
export default {
  get: {
    unresolvedProjectComments: {
      url: (projectId) => `${ROOT}/unresolved/project/${projectId}`,
    },
    unresolvedComponentLibraryComments: {
      url: `${ROOT}/unresolved/workspace`,
    },
  },
  post: {
    postCompThread: {
      url: `${ROOT}/postCompThread`,
      body: ({
        comp_id,
        doc_id,
        doc_name,
        first_comment,
        figma_node_id,
        mentionedUserIds,
        from,
        ws_comp_id,
        suggestion,
      }) => ({
        comp_id,
        doc_id,
        doc_name,
        first_comment,
        figma_node_id,
        mentionedUserIds,
        from,
        ws_comp_id,
        suggestion,
      }),
    },
    postToThread: {
      url: (thread_id) => `${ROOT}/postToThread/${thread_id}`,
      body: ({ mentionedUserIds, from, text, doc_name }) => ({
        mentionedUserIds,
        from,
        text,
        doc_name,
      }),
    },
    resolveThread: {
      url: (thread_id) => `${ROOT}/resolveThread/${thread_id}`,
      body: ({ is_resolved, doc_name, suggestion_accepted, from }) => ({
        is_resolved,
        doc_name,
        suggestion_accepted,
        from,
      }),
    },
  },
};
