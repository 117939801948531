import { SetStateAction, createContext, useMemo, useState } from "react";
import { DRAFT_PAGE_ID } from ".";
import { removeFigmaArtifactsFromGroupName } from "../../../../../shared/lib/groups";
import { Group } from "../../state/types";

interface SelectedGroup {
  _id: string;
  name: string;
  frameName: string;
  isSelected: boolean;
  isLinked: boolean;
}

interface GroupsByPage {
  [pageId: string]: SelectedGroup[];
}

interface ManageGroupsModalContext {
  groups: Group[];
  groupsByPage: GroupsByPage;
  groupsToDelete: Set<string>;
  setGroupsToDelete: React.Dispatch<SetStateAction<Set<string>>>;
  groupsToUnlink: Set<string>;
  setGroupsToUnlink: React.Dispatch<SetStateAction<Set<string>>>;
}

export const ManageGroupsModalContext = createContext({} as ManageGroupsModalContext);

export const ManageGroupsProvider: React.FC<{ groups: Group[]; children: React.ReactNode }> = (props) => {
  const [groupsToUnlink, setGroupsToUnlink] = useState(new Set<string>());
  const [groupsToDelete, setGroupsToDelete] = useState(new Set<string>());

  const groupsByPage: GroupsByPage = useMemo(() => {
    return props.groups.reduce((groupsByPage, group) => {
      const frameName = removeFigmaArtifactsFromGroupName(group.integrations.figma["frame_name"]);
      const isLinked = Boolean(group.integrations.figma["frame_id"]);
      const pageId = group.integrations.figma["page_id"] || DRAFT_PAGE_ID;

      const isDraftPage = pageId === DRAFT_PAGE_ID;

      // don't add empty draft groups to group list
      const isEmptyDraftGroup =
        isDraftPage &&
        group.name === "" &&
        (group.comps.length === 0 || (group.comps.length === 1 && group.comps[0].text === ""));

      if (isEmptyDraftGroup) return groupsByPage;

      const groupName = group.name === "" ? "Untitled Frame" : removeFigmaArtifactsFromGroupName(group.name);
      groupsByPage[pageId] ??= [];

      groupsByPage[pageId].push({
        _id: group._id,
        name: groupName,
        frameName,
        isLinked,
      });
      return groupsByPage;
    }, {});
  }, [props.groups]);

  return (
    <ManageGroupsModalContext.Provider
      value={{
        groupsByPage,
        groups: props.groups,
        groupsToDelete,
        setGroupsToDelete,
        groupsToUnlink,
        setGroupsToUnlink,
      }}
    >
      {props.children}
    </ManageGroupsModalContext.Provider>
  );
};
