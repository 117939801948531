import { useMemo } from "react";
import ConfirmationModal from "../../shared/confirmation-modal";
import style from "./DeleteGroupModal.module.css";
import { DeleteModalProps } from "./types";

export const DeleteGroupModal = ({ groupToDelete, handleDeleteGroup, onCancel }: DeleteModalProps) => {
  const modalBody = useMemo(() => {
    // The User object has a list of permission group IDs that the user belongs to.
    const usersWithNoOtherGroups = groupToDelete.users.filter((user) => user.permissionGroups.length === 1);
    const defaultGroup = "editor"; // TODO: allow users to select a default group?

    const base = (
      <p>
        Are you sure you want to delete this permission group? This action cannot be undone. Users that are in no other
        permission groups will be given the default permission group, <b>Editor.</b>
      </p>
    );

    const deleteInvitesWarning = (
      <>
        <p>
          Your workspace has pending invitations for users to join this permission group. Deleting this group will
          cancel those invitations.
        </p>
        <ul className={style.reassignWarningList}>
          {groupToDelete.invitedUserEmails.map((email) => (
            <li key={email}>
              <strong>{email}</strong>
            </li>
          ))}
        </ul>
      </>
    );

    const reassignWarning = (
      <>
        <p>
          Some of the users in this group do not belong to any other permission group. They will be given the default
          permission group, {defaultGroup.charAt(0).toUpperCase() + defaultGroup.slice(1)}.
        </p>
        <ul className={style.reassignWarningList}>
          {usersWithNoOtherGroups.map((user) => (
            <li key={user._id.toString()}>
              <strong>{user.name}</strong> ({user.email})
            </li>
          ))}
        </ul>
      </>
    );

    return (
      <>
        {base}
        {groupToDelete.invitedUserEmails.length > 0 && deleteInvitesWarning}
      </>
    );
  }, []);

  return (
    <ConfirmationModal
      title="Delete Permission Group"
      body={modalBody}
      actionPrimary="Delete"
      onPrimary={() => handleDeleteGroup(groupToDelete._id)}
      actionSecondary="Cancel"
      onSecondary={onCancel}
    />
  );
};
