import Close from "@mui/icons-material/Close";
import { IFPermissionGroup } from "@shared/types/PermissionGroups";
import { useCallback, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ButtonPrimary from "../../button/buttonprimary";
import style from "./CreateGroupModal.module.css";

interface CreateGroupModalProps {
  existingPermissionGroups: IFPermissionGroup[];
  setShowCreateModal: (show: boolean) => void;
  handleCreateNewGroup: (newName: string) => void;
}

const CreateGroupModal = (props: CreateGroupModalProps) => {
  const { setShowCreateModal, handleCreateNewGroup } = props;

  const [name, setName] = useState("");
  const [error, setError] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  const existingPermissionGroupNames = useMemo(() => {
    return props.existingPermissionGroups.map((group) => group.name.toLowerCase());
  }, [props.existingPermissionGroups]);

  const onCreateButtonClick = useCallback(
    function handleOnCreate() {
      setIsSaving(true);
      handleCreateNewGroup(name);
    },
    [name]
  );

  const onPermissionGroupNameChange = useCallback(function handleOnPermissionGroupNameChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    setName(e.target.value);
    if (existingPermissionGroupNames.includes(e.target.value.toLowerCase())) {
      setError("A permission group with this name already exists");
    } else {
      setError(undefined);
    }
  },
  []);

  return (
    <Modal show centered onHide={() => setShowCreateModal(false)} className={style.createModal}>
      <Modal.Header className={style.header}>
        <h2>Create a new permission group</h2>
        <Close className={style.closeIcon} onClick={() => setShowCreateModal(false)} />
      </Modal.Header>
      <Modal.Body className={style.body}>
        <p>Group Name</p>
        <input
          data-testid="permission-group-name"
          value={name}
          onChange={onPermissionGroupNameChange}
          type="text"
          placeholder="Group Name"
        />
        {error && !isSaving && <div className={style.errorMsg}>{error}</div>}
        <ButtonPrimary
          data-testid="create-group-button"
          onClick={onCreateButtonClick}
          disabled={isSaving || !name || !!error}
          text="Create"
        />
      </Modal.Body>
    </Modal>
  );
};

export default CreateGroupModal;
