import EditableName from "@/components/EditableName";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import React, { useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";

interface Tag {
  name: string;
  count: number;
}

interface ManageTagsModalProps {
  onHide: () => void;
  onEditTag: (tag: string, newTag: string) => Promise<void>;
  onDeleteTag: (tag: string) => Promise<void>;
  tags: Tag[];
}

const ManageTagsModal = (props: ManageTagsModalProps) => {
  const { onHide, onEditTag, onDeleteTag } = props;

  const [tags, setTags] = useState<Tag[]>(props.tags);
  const [query, setQuery] = useState<string>("");

  const handleEditTag = (tag: string) => async (newTag: string) => {
    await onEditTag(tag, newTag);
    const newTags = tags.map((t) => {
      if (t.name === tag) {
        return { name: newTag, count: t.count };
      }
      return t;
    });
    setTags(newTags);
  };

  const handleDeleteTag = (tag: string) => async () => {
    await onDeleteTag(tag);
    const newTags = tags.filter((t) => t.name !== tag);
    setTags(newTags);
  };

  const onQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const filteredTags = useMemo(() => {
    if (!query) return tags;

    // make search case-insensitive
    const queryLower = query.toLowerCase();

    return tags.filter((tag) => {
      const tagLower = tag.name.toLowerCase();
      return tagLower.includes(queryLower);
    });
  }, [query, tags]);

  const noResults = useMemo(() => {
    return query.length !== 0 && filteredTags.length === 0;
  }, [query, filteredTags]);

  return (
    <BootstrapModal
      show={true}
      className={style.modal}
      dialogClassName={style.dialog}
      backdropClassName={style.backdrop}
      onHide={onHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>Manage Tags</BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div className={style.searchArea}>
          <input value={query} onChange={onQueryChange} className={style.search} placeholder="Search tags" />
          <SearchIcon className={style.searchIcon} />
        </div>
        <div className={style.tagsArea}>
          {filteredTags.map((tag) => (
            <TagRow
              allTags={tags}
              tag={tag}
              editTag={handleEditTag(tag.name)}
              deleteTag={handleDeleteTag(tag.name)}
              key={`tag-${tag.name}`}
            />
          ))}
          {noResults && (
            <div className={style.noResults}>
              <p>No tags found matching "{query}".</p>
            </div>
          )}
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

interface TagRowProps {
  tag: Tag;
  allTags: Tag[];
  editTag: (value: string) => Promise<void>;
  deleteTag: () => Promise<void>;
}

const TagRow = (props: TagRowProps) => {
  const { allTags, tag, editTag, deleteTag } = props;

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const onDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const handleEdit = async (value: string) => {
    try {
      await editTag(value);
    } catch (error) {
      console.error("Error editing tag:", error);
      setError("Error editing tag: " + error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteTag();
    } catch (error) {
      console.error("Error deleting tag:", error);
      setError("Error deleting tag: " + error);
    }
  };

  return (
    <div className={style.tagGroup}>
      <div className={style.tagRow}>
        <div className={style.name}>
          <EditableName
            isEditEnabled={true} // TODO: Determine who can edit tags.
            onSave={handleEdit}
            saveOnBlur={false}
            toUpper={true}
            uniqueNames={allTags.map((t) => t.name)}
            value={tag.name}
            errorPos="right"
          />
        </div>
        <div className={style.count}>Used {tag.count} times</div>
        <button className={style.deleteButton} onClick={onDeleteClick} data-testid="delete-tag">
          <DeleteIcon className={style.icon} /> Delete
        </button>
      </div>
      {showConfirmDelete && (
        <div className={style.deleteArea}>
          <span>
            Are you sure you want to delete this tag? It will be removed from {tag.count} text items. This action cannot
            be undone.{" "}
            <button className={style.deleteLinkBtn} onClick={handleDelete} data-testid="confirm-delete-tag">
              Delete
            </button>{" "}
            /{" "}
            <button className={style.deleteLinkBtn} onClick={() => setShowConfirmDelete(false)}>
              Cancel
            </button>
          </span>
        </div>
      )}
      {error && <p className={style.error}>{error}</p>}
    </div>
  );
};

export default ManageTagsModal;
