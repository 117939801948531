import { ITipTapRichText } from "@shared/types/TextItem";

/**
 * Given a plaintext string, formats the text in a rich text format with no embellishments.
 * @param text - The plaintext string to convert to rich text.
 * @returns The text in a rich text format.
 */
export function createBaseRichText(text: string): ITipTapRichText {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [{ type: "text", text }],
      },
    ],
  };
}

export function createEmptyRichText(): ITipTapRichText {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
      },
    ],
  };
}
