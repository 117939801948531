import { useAtomRef } from "@/hooks/useAtomRef";
import useAutoScroll, { composeCallbacks } from "@/hooks/useAutoScroll";
import { reorderTextItemsActionAtom } from "@/stores/Editing";
import {
  blockFamilyAtom,
  flattenedProjectItemsAtom,
  INavBlockItem,
  INavMessageItem,
  INavTextItem,
  isValidBlock,
  moveBlocksActionAtom,
  projectBlocksAtom,
  projectSidebarCollapsedAtom,
  textItemFamilyAtom,
} from "@/stores/Project";
import { designPreviewToggledAtom, isSearchingAtom } from "@/stores/ProjectFiltering";
import {
  draggableItemsForTextItemAtom,
  onClickBlockActionAtom,
  onTextItemClickActionAtomFamily,
  onTextItemKeyDownActionAtom,
  selectedBlockIdAtom,
  selectionTypeAtom,
  textItemIsSelectedAtom,
} from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import EditableTextArea from "@ds/atoms/EditableTextArea";
import Text from "@ds/atoms/Text";
import NavItem from "@ds/molecules/NavigatorRow";
import Scrollbar from "@ds/molecules/Scrollbar";
import TabGroup from "@ds/molecules/TabGroup";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import classNames from "classnames";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { createRef, forwardRef, Suspense, useCallback, useMemo } from "react";
import { DragStartEvent } from "react-aria";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";
import { textItemListScrollRefAtom } from "../TextItemList";
import style from "./style.module.css";

const sidebarTabs = [
  { id: "text", label: "Text", Content: () => <></> },
  { id: "library", label: "Library", Content: () => <></> },
];

export const leftSidebarScrollRefAtom = atom<HTMLDivElement | null>(null);

function LeftSidebar() {
  const onTextItemKeyDownAction = useSetAtom(onTextItemKeyDownActionAtom);
  const [sidebarCollapseState, setSidebarCollapseState] = useAtom(projectSidebarCollapsedAtom);
  const designPreviewToggled = useAtomValue(designPreviewToggledAtom);
  const scrollContentRef = useAtomRef(leftSidebarScrollRefAtom);

  // TODO: This sidebar should be resizeable by the user, and store that resize in localstorage. DIT-8138
  return (
    <div
      className={classNames(style.leftSidebarContainer, {
        [style.relPos]: !designPreviewToggled,
        [style.absPos]: designPreviewToggled,
      })}
      data-state={sidebarCollapseState}
      tabIndex={0}
      onKeyDown={onTextItemKeyDownAction}
    >
      <div className={style.tabs}>
        <TabGroup tabs={sidebarTabs} size="small" />

        <Button
          type="icon"
          level="subtle"
          className={style.sidebarCollapseBtn}
          onClick={() => setSidebarCollapseState("closed")}
        >
          <KeyboardDoubleArrowLeft />
        </Button>
      </div>
      <Scrollbar disableScrollX className={style.scrollWrapper} {...{ scrollContentRef }}>
        <div className={style.contentWrapper}>
          <Suspense fallback={<LeftSidebarContentSkeleton />}>
            <LeftSidebarContent />
          </Suspense>
        </div>
      </Scrollbar>
    </div>
  );
}

function LeftSidebarContent() {
  const { flattenedProjectItems } = useAtomValue(flattenedProjectItemsAtom);
  const isSearching = useAtomValue(isSearchingAtom);

  /**
   * Store a mapping from block ID to an array of refs to text items associated
   * with that block.
   */
  const { blockTextItemRefs } = useMemo(() => {
    const blockTextItemRefs = new Map<string, Array<{ id: string; ref: React.RefObject<HTMLDivElement> }>>();
    let currentBlockId: string | null = null;

    flattenedProjectItems.forEach((item) => {
      if (item.type === "block") {
        currentBlockId = item._id;
        blockTextItemRefs.set(currentBlockId, []);
      } else if (item.type === "text") {
        const ref = createRef<HTMLDivElement>();
        if (currentBlockId) {
          blockTextItemRefs.get(currentBlockId)?.push({ id: item._id, ref });
        }
      }
    });

    return { blockTextItemRefs };
  }, [flattenedProjectItems]);

  let currentBlockId: string | null = null;

  return (
    <>
      {flattenedProjectItems.length || isSearching ? (
        <>
          {flattenedProjectItems.map((item) => {
            if (item.type === "block") {
              currentBlockId = item._id;
              const textRefs = blockTextItemRefs.get(currentBlockId) || [];
              return (
                <Suspense key={item._id} fallback={<LoadingNavItem />}>
                  {/* Pass the array of refs to BlockNavItem */}
                  <BlockNavItem item={item} textRefs={textRefs.map((entry) => entry.ref)} />
                </Suspense>
              );
            } else if (item.type === "text") {
              const textRefEntry = blockTextItemRefs.get(currentBlockId!)?.find((entry) => entry.id === item._id);
              return (
                <Suspense key={item._id} fallback={<LoadingNavItem />}>
                  <TextNavItemWithDrag item={item} ref={textRefEntry?.ref} />
                </Suspense>
              );
            } else if (item.type === "message") {
              return (
                <Suspense key={item._id} fallback={<LoadingNavItem />}>
                  <MessageNavItem item={item} />
                </Suspense>
              );
            }
            return null;
          })}
        </>
      ) : (
        <Text color="tertiary" size="small" className={style.emptyStateMessage}>
          Blocks and text in your project will appear here
        </Text>
      )}
      <HiddenProjectItemsNavItem />
    </>
  );
}

function LeftSidebarContentSkeleton() {
  return (
    <>
      <div className={style.navItemSkeleton}>
        <Skeleton baseColor="var(--bg-minimal)" width={12} height={12} />
        <Skeleton baseColor="var(--bg-minimal)" width={160} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={60} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={110} height={17} />
      </div>
      <div className={style.navItemSkeleton}>
        <div className={style.icon} style={{ opacity: 0 }} />
        <Skeleton baseColor="var(--bg-minimal)" width={160} height={17} />
      </div>
    </>
  );
}

function BlockNavItem(props: { item: INavBlockItem; textRefs: React.RefObject<HTMLDivElement>[] }) {
  const block = useAtomValue(blockFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const selectionType = useAtomCallback((get) => get(selectionTypeAtom));
  const moveBlockItemsAction = useSetAtom(moveBlocksActionAtom);

  const projectBlocksAtoms = useAtomCallback((get) => get(projectBlocksAtom));

  const scrollProps = useAutoScroll(leftSidebarScrollContainer, textItemScrollContainer);

  async function handleDrop(itemIds: string[], dragLocation: "above" | "below" | null) {
    /**
     * When dropping text items onto a block, we always infer that we should insert them at
     * the top of the block, since this is in a navbar context.
     */
    if (selectionType() === "text") {
      const blocks = await projectBlocksAtoms();

      const currentBlock = blocks.find((b) => b._id === props.item._id)!;

      const referenceTextItem = currentBlock.allTextItems[0];

      reorderTextItemsAction([
        {
          textItemIds: itemIds,
          blockId: props.item._id,
          before: referenceTextItem?._id,
        },
      ]);
    } else {
      moveBlockItemsAction({
        blockIds: itemIds,
        destinationBlockId: block._id,
        direction: dragLocation,
      });
    }
  }

  if (!isValidBlock(block)) return <></>;

  function onDragStart(e: DragStartEvent) {
    if (props.item._id) {
      onClickBlockAction(props.item._id);

      // Set data-state="dragging" on associated text items
      props.textRefs.forEach((ref) => {
        if (ref && ref.current) {
          ref.current.setAttribute("data-state", "dragging");
        }
      });
    }
  }

  function resetDragState() {
    // Remove data-state from associated text items
    props.textRefs.forEach((ref) => {
      if (ref && ref.current) {
        ref.current.removeAttribute("data-state");
      }
    });
  }

  function onDragEnd() {
    resetDragState();
  }

  function onDragCancel() {
    resetDragState();
  }

  const dragProps = composeCallbacks([scrollProps, { onDragStart, onDragEnd, onDragCancel }]);

  const draggableItems = [
    {
      "ditto/blockItem": block._id,
      "plain/text": block._id,
    },
  ];

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      getDraggableItems={() => draggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string(), "ditto/blockItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={onDragStart}
      {...dragProps}
    >
      {(dragAndDropProps) => {
        const isSelected = selectedBlockId === props.item._id;

        /**
         * Text items only drop below the block, while blocks can be dropped
         * above or below the block.
         */
        const droppingLocation = dragAndDropProps.isDropTarget
          ? selectionType() === "text"
            ? "below"
            : dragAndDropProps.dragLocation
          : null;

        const droppingAbove = droppingLocation === "above";
        const droppingBelow = droppingLocation === "below";

        return (
          <>
            {droppingAbove && <div className={style.dropIndicator} />}
            <NavItem
              key={block._id}
              className={style.navItem}
              type="block"
              pressed={isSelected}
              onChange={(pressed) => {
                if (pressed) onClickBlockAction(block._id!);
              }}
            >
              {block.name || "Untitled Block"}
            </NavItem>
            {droppingBelow && <div className={style.dropIndicator} />}
          </>
        );
      }}
    </DragAndDroppable>
  );
}

function TextNavItem(props: { item: INavTextItem }) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.item._id));
  const isSelected = useAtomValue(textItemIsSelectedAtom(props.item._id));

  const itemType = useMemo(() => (textItem.blockId ? "block-child" : "default"), [textItem.blockId]);
  const parentBlockIsSelected = useMemo(
    () => Boolean(textItem.blockId && selectedBlockId === textItem.blockId),
    [textItem.blockId, selectedBlockId]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onTextItemClick({ richText: textItem.rich_text, e });
    },
    [onTextItemClick, textItem.rich_text]
  );

  return (
    <NavItem
      key={textItem._id}
      className={style.navItem}
      type={itemType}
      pressed={isSelected}
      parentSelected={parentBlockIsSelected}
      onClick={onClick}
    >
      <EditableTextArea className={style.textItemText} content={textItem.rich_text} editable={false}></EditableTextArea>
    </NavItem>
  );
}

function LoadingNavItem() {
  return <NavItem state="loading" pressed={false} />;
}

const TextNavItemDragWrapper = forwardRef(function TextNavItemDragWrapper(
  props: {
    children: React.ReactNode;
    textItemId: string;
  },
  ref
) {
  const getDraggableItems = useAtomCallback(
    useCallback((get) => get(draggableItemsForTextItemAtom(props.textItemId)), [props.textItemId])
  );
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const textItemScrollContainer = useAtomValue(textItemListScrollRefAtom);
  const leftSidebarScrollContainer = useAtomValue(leftSidebarScrollRefAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(props.textItemId));
  const selectedType = useAtomValue(selectionTypeAtom);

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItem.blockId,
        before: dragLocation === "above" ? props.textItemId : undefined,
        after: dragLocation === "below" ? props.textItemId : undefined,
      },
    ]);
  }

  const scrollProps = useAutoScroll(textItemScrollContainer, leftSidebarScrollContainer);

  function onDragStart(e: DragStartEvent) {
    if (props.textItemId) {
      onTextItemClick({ richText: textItem.rich_text, e });
    }
  }

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      ref={ref}
      getDraggableItems={getDraggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
      onDragStart={onDragStart}
      {...scrollProps}
    >
      {(dragAndDropProps) => {
        const isDropTarget = dragAndDropProps.isDropTarget && selectedType === "text";

        const isDroppingAbove = isDropTarget && dragAndDropProps.dragLocation === "above";
        const isDroppingBelow = isDropTarget && dragAndDropProps.dragLocation === "below";

        return (
          <>
            {isDroppingAbove && <div className={classNames(style.dropIndicator)} />}
            {props.children}
            {isDroppingBelow && <div className={classNames(style.dropIndicator, style.textItemDrop)} />}
          </>
        );
      }}
    </DragAndDroppable>
  );
});

const TextNavItemWithDrag = forwardRef(function TextNavItemWithDrag(props: { item: INavTextItem }, ref) {
  return (
    <TextNavItemDragWrapper textItemId={props.item._id} ref={ref}>
      <TextNavItem {...props} />
    </TextNavItemDragWrapper>
  );
});

function MessageNavItem(props: { item: INavMessageItem }) {
  return (
    <div className={classNames(style.hiddenResultsMessageNavItem, style.hiddenResultsChildMessageNavItem)}>
      {props.item.message}
    </div>
  );
}

function HiddenProjectItemsNavItem() {
  const { hiddenProjectItemsMessage } = useAtomValue(flattenedProjectItemsAtom);

  if (!hiddenProjectItemsMessage) {
    return null;
  }

  return <div className={style.hiddenResultsMessageNavItem}>{hiddenProjectItemsMessage}</div>;
}

export default LeftSidebar;
