import { FilterQuery, Types } from "mongoose";
import { z } from "zod";

type MongooseModelIsh = { _id: Types.ObjectId };

type CreateableObjectId<T extends Types.ObjectId | Types.ObjectId[]> = T extends Types.ObjectId
  ? CreateableId
  : T extends Types.ObjectId[]
  ? CreateableIds
  : never;

type CreateableKeys<T extends MongooseModelIsh, O extends keyof T> = {
  [key in keyof T]: key extends O ? never : key;
}[keyof T];

type CreateableObj<T extends MongooseModelIsh> = {
  [key in keyof T]: T[key] extends Types.ObjectId | Types.ObjectId[] ? CreateableObjectId<T[key]> : T[key];
};

export const CreateableId = z.union([z.string(), z.instanceof(Types.ObjectId)]);
export type CreateableId = z.infer<typeof CreateableId>;
export type CreateableIds = string[] | Types.ObjectId[];
/**
 * Given a Mongoose schema:
 * - omit the specified field(s) (defaults to "_id")
 * - accept strings in place of ObjectIds
 */
export type Createable<T extends MongooseModelIsh, O extends keyof T = "_id"> = Pick<
  CreateableObj<T>,
  CreateableKeys<T, O>
>;

/**
 * Mongoose does a very stupid thing with their FilterQuery type where they
 * any key is allowed, even if it's not a part of the schema of T.
 * This wrapper only allows fields that are defined on T, along with all the
 * top-level Mongo operators (RootKeys).
 */
type RootKeys = "$and" | "$nor" | "$or" | "$text" | "$where" | "$comment";
export type SafeFilterQuery<T> = Pick<FilterQuery<T>, keyof T | RootKeys>;
