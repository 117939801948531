import http from "@/http";
import { useWorkspace } from "@/store/workspaceContext";
import { AIUsage } from "@shared/types/http/changes";
import { useRef, useState } from "react";

export default function useAIFunctionalityPanel() {
  const workspace = useWorkspace();

  const [usageData, setUsageData] = useState<AIUsage | null>(null);
  const hasDataBeenFetched = useRef(false);

  if (!hasDataBeenFetched.current) {
    hasDataBeenFetched.current = true;
    const httpUsageResult = http.get<AIUsage>("/changes/aiUsage");
    httpUsageResult.then((r) => {
      setUsageData(r.data);
    });
  }

  const isAiEditorEnabled = workspace.workspaceInfo?.aiFeatures.aiEditor;
  const isAutomatedComponentNamingEnabled = workspace.workspaceInfo?.aiFeatures.automatedComponentNaming;

  const toggleIsAiEditorEnabled = () => {
    if (
      !workspace.workspaceInfo ||
      isAiEditorEnabled === undefined ||
      isAutomatedComponentNamingEnabled === undefined
    ) {
      return;
    }

    http.put("/workspace/updateAiFeatures", {
      aiEditor: !isAiEditorEnabled,
      automatedComponentNaming: isAutomatedComponentNamingEnabled,
    });

    workspace.updateWorkspaceInfo({
      ...workspace.workspaceInfo,
      aiFeatures: {
        aiEditor: !isAiEditorEnabled,
        automatedComponentNaming: isAutomatedComponentNamingEnabled,
      },
    });
  };

  const toggleIsAutomatedComponentNamingEnabled = () => {
    if (
      !workspace.workspaceInfo ||
      isAiEditorEnabled === undefined ||
      isAutomatedComponentNamingEnabled === undefined
    ) {
      return;
    }

    http.put("/workspace/updateAiFeatures", {
      aiEditor: isAiEditorEnabled,
      automatedComponentNaming: !isAutomatedComponentNamingEnabled,
    });

    workspace.updateWorkspaceInfo({
      ...workspace.workspaceInfo,
      aiFeatures: {
        aiEditor: isAiEditorEnabled,
        automatedComponentNaming: !isAutomatedComponentNamingEnabled,
      },
    });
  };

  return {
    usageData,
    isAiEditorEnabled,
    isAutomatedComponentNamingEnabled,
    toggleIsAiEditorEnabled,
    toggleIsAutomatedComponentNamingEnabled,
  };
}
