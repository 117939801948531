import DittoComponents from "@/ditto/components__root__base.json";
import { COPY_CLI_COMMAND } from "@shared/segment-event-names";
import React from "react";
import CodeGroup from "../../../../components/CodeGroup/CodeGroup";
import WarningOutlineIcon from "../../../../components/icons/WarningOutlineIcon";
import useSegment from "../../../../hooks/useSegment";
import style from "./UseCLIPanel.module.css";

interface UseCLIPanelProps {
  devToolsEnabled: boolean;
}

const UseCLIPanel = (props: UseCLIPanelProps) => {
  const segment = useSegment();

  function onCopy() {
    segment.track({ event: COPY_CLI_COMMAND });
  }

  return (
    <div className={style.useCLIPanel}>
      <div className={style.text}>
        <h1>{DittoComponents["use-our-cli"].text}</h1>
        <p>{DittoComponents["fetch-text"].text}</p>
      </div>
      <CodeGroup codeBlocks={codeBlocks} className={style.codeGroup} copyCallback={onCopy} />
      {!props.devToolsEnabled && (
        <div className={style.enableDevToolsWarning}>
          <WarningOutlineIcon className={style.icon} />
          {DittoComponents["onboarding.api-key-setup"].text}
        </div>
      )}
    </div>
  );
};

const codeBlocks = [
  {
    filename: "npm",
    children: (
      <span>
        <span style={{ color: "#a0770e" }}>npm</span> i --save-dev @dittowords/cli
      </span>
    ),
  },
  {
    filename: "yarn",
    children: (
      <span>
        <span style={{ color: "#a0770e" }}>yarn</span> add --dev @dittowords/cli
      </span>
    ),
  },
];

export default UseCLIPanel;
