import ErrorIcon from "@mui/icons-material/ErrorOutline";
import React from "react";

import style from "./style.module.css";

interface Props {}

const PreviewLoadError = (_props: Props) => (
  <div className={style.previewError}>
    <div>
      <ErrorIcon className={style.icon} />
      <div>
        We had trouble fetching this image. Resync this project to try again, or contact us at{" "}
        <a href="mailto:support@dittowords.com">support@dittowords.com</a> if this issue persists.
      </div>
    </div>
  </div>
);

export default PreviewLoadError;
