import React from "react";
import style from "./style.module.css";

const TagSuggestion = ({ item, query }) => {
  return (
    <span id={item.id} className={style.suggestions_tag}>
      {item.name}
    </span>
  );
};

export default TagSuggestion;
