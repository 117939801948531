import EmailIcon from "@mui/icons-material/EmailOutlined";
import React from "react";
import style from "./style.module.css";
import { UserSuggestionProps } from "./types";

const UserSuggestion = ({ item }: UserSuggestionProps) => {
  if (item.type === "existing") {
    return (
      <div id={item._id} className={style.userSuggestion}>
        <div className={style.userName}>{item.name}</div>
        <div className={style.userEmail}>{item.email}</div>
      </div>
    );
  }
  return (
    <div id={item._id} className={style.userSuggestion}>
      <EmailIcon className={style.emailIcon} />
      <span className={style.inviteText}>Invite</span>
      <span className={style.emailSuggestion}>{item.email}</span>
      <span className={style.inviteText}> to this workspace</span>
    </div>
  );
};
export default UserSuggestion;
