import { useReducer } from "react";

import logger from "@shared/utils/logger";
import { createDefaultDraftGroup } from "./getCategorizedGroups";
import { GROUP_REDUCER_TYPES, GroupReducerAction } from "./groupStateActions";
import { Group, isGroupLinked } from "./types";

export interface GroupState {
  groups: Group[];
  fetching: boolean;
  finished: boolean;
}

const reducer = (state: GroupState, action: GroupReducerAction): GroupState => {
  try {
    switch (action.type) {
      case GROUP_REDUCER_TYPES.STACK_GROUPS:
        if (state.finished) {
          // make sure pagination doesn't continue after resync finished
          return { ...state };
        }
        return {
          ...state,
          groups: state.groups.concat(action.groups),
        };

      case GROUP_REDUCER_TYPES.REPLACE_GROUPS:
        return { ...state, groups: action.groups };

      case GROUP_REDUCER_TYPES.FETCHING_GROUPS:
        return { ...state, fetching: action.fetching };

      case GROUP_REDUCER_TYPES.GROUP_FETCHING_FINISHED:
        return { ...state, finished: action.status };

      case GROUP_REDUCER_TYPES.UPDATE_COMPONENTS_IN_GROUPS: {
        const newState = { ...state };
        for (const newComp of action.comps) {
          // Using .find so we can break after finding and modifying
          // the target component
          newState.groups.find((group) => {
            const foundComp = group.comps.find((comp, compIdx) => {
              if (comp._id === newComp._id) {
                group.comps[compIdx] = newComp;
                return true;
              }

              return false;
            });

            if (foundComp) return true;

            group.blocks.find((block, blockIdx) => {
              block.comps.find((comp, compIdx) => {
                if (comp._id === newComp._id) {
                  group.blocks[blockIdx].comps[compIdx] = newComp;
                  return true;
                }
                return false;
              });
            });

            if (foundComp) return true;
          });
        }

        return newState;
      }

      case GROUP_REDUCER_TYPES.ADD_NEW_BLOCK_TO_GROUP: {
        const newState = { ...state };
        const targetGroup = newState.groups.find((group) => group._id === action.groupId);

        if (!targetGroup) return newState;

        targetGroup.blocks.push({
          _id: action.blockId,
          comps: [],
          name: action.name,
        });

        return newState;
      }

      case GROUP_REDUCER_TYPES.UPDATE_BLOCK_NAME: {
        const newState = { ...state };

        newState.groups.find((group, groupIdx) => {
          if (group._id === action.groupId) {
            newState.groups[groupIdx].blocks.find((block) => {
              if (block._id === action.blockId) {
                block.name = action.newName;
                return true;
              }
              return false;
            });
            return true;
          }
          return false;
        });

        return newState;
      }

      case GROUP_REDUCER_TYPES.ADD_TEXT_ITEM_TO_GROUP: {
        const newState = { ...state };

        const targetGroup = newState.groups.find((group) => group._id === action.groupId);

        if (!targetGroup) return newState;

        targetGroup.comps.push(action.comp);
        return newState;
      }
      case GROUP_REDUCER_TYPES.DELETE_TEXT_ITEM_FROM_GROUP: {
        const newState = { ...state, groups: [...state.groups] };

        const targetGroupIdx = newState.groups.findIndex((group) => group._id === action.groupId);
        const targetGroup = newState.groups[targetGroupIdx];

        if (!targetGroup) return newState;

        let targetTextItemIdx = targetGroup.comps.findIndex((textItem) => textItem._id === action.textItemId);

        if (targetTextItemIdx !== -1) {
          targetGroup.comps.splice(targetTextItemIdx, 1);
        }

        // Maybe comp is in a block?
        if (targetTextItemIdx === -1) {
          for (const block of targetGroup.blocks) {
            targetTextItemIdx = block.comps.findIndex((textItem) => textItem._id == action.textItemId);

            // Text item was hiding in a block!
            if (targetTextItemIdx !== -1) {
              block.comps.splice(targetTextItemIdx, 1);
            }
          }
        }

        if (targetTextItemIdx === -1) return newState;

        if (!targetGroup.comps.length && !targetGroup.blocks.length) {
          newState.groups.splice(targetTextItemIdx, 1);
        }

        return newState;
      }
      case GROUP_REDUCER_TYPES.UPDATE_DRAFT_TEXT_ITEM_COMMENT_THREADS: {
        const newState = { ...state };

        const group = newState.groups.find((group) => group._id.toString() === action.groupId);
        if (!group) {
          return newState;
        }

        const updatedTextItems = [...group.comps];

        updatedTextItems.forEach((textItem, textItemIndex) => {
          const id = textItem._id.toString();

          const commentThreads = action.commentThreadUpdateMap[id];
          if (!commentThreads) {
            return;
          }

          updatedTextItems[textItemIndex].comment_threads = commentThreads;
        });

        group.comps = updatedTextItems;

        return newState;
      }

      case GROUP_REDUCER_TYPES.UPDATE_GROUP_NAME: {
        const groups = [...state.groups];

        const group = groups.find((group) => group._id.toString() === action.groupId);
        if (!group) {
          console.error({ state, action });
          throw new Error("Group not found when updating group name");
        }

        group.name = action.groupName;
        group.unsaved = false;
        if (isGroupLinked(group)) {
          group.integrations.figma.frame_name_synced_with_group_name = false;
        }
        if (action.groupApiId) {
          group.apiID = action.groupApiId;
        }

        if (action.groupApiId !== undefined) {
          group.apiID = action.groupApiId;
        }

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.UPDATE_GROUP_TEXT_ITEMS: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);
        if (groupIndex === -1) {
          console.error({ state, action });
          throw new Error("Group not found when updating text items");
        }

        groups[groupIndex].comps = action.textItems;
        groups[groupIndex].unsaved = false;

        if (action.groupApiId !== undefined) {
          groups[groupIndex].apiID = action.groupApiId;
        }

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.UPDATE_GROUP_TEXT_ITEMS_CALLBACK: {
        const groups = [...state.groups];
        const group = groups.find((g) => g._id.toString() === action.groupId);
        if (!group) {
          console.error({ state, action });
          throw new Error("Group not found when updating text items");
        }

        if (action.compsCallback && group.comps) {
          group.comps = action.compsCallback(group.comps);
        }
        if (action.blocksCallback && group.blocks) {
          group.blocks = action.blocksCallback(group.blocks);
        }

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.UPDATE_GROUP_NON_BLOCKS_COLLAPSED: {
        return {
          ...state,
          groups: state.groups.map((g) => {
            if (g._id !== action.groupId) {
              return g;
            }

            g.nonblocks_collapsed = action.isCollapsed;
            return g;
          }),
        };
      }

      case GROUP_REDUCER_TYPES.ADD_GROUPS: {
        // Check for duplicates
        const existingGroupIds = state.groups.map((group) => group._id);
        const groupsToAdd = action.groups.filter((group) => {
          if (!existingGroupIds.includes(group._id)) {
            return true;
          } else {
            logger.error(
              "Duplicate group found",
              { context: { groupId: group._id } },
              new Error("Duplicate group found while adding groups on web")
            );
            return false;
          }
        });
        return {
          ...state,
          groups: [...state.groups, ...groupsToAdd],
        };
      }

      case GROUP_REDUCER_TYPES.REFRESH_GROUP: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);
        if (groupIndex === -1) {
          console.error("Group not found when refreshing group", action.groupId, "with index", groupIndex);
          return state;
        }

        groups.splice(groupIndex, 1, action.group);

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.UPDATE_GROUP: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);
        if (groupIndex === -1) {
          // new group that exists in the backend but not the frontend
          // eventually we will want to keep backend groups and frontend groups in sync via websockets
          return state;
        }

        const targetGroup = groups[groupIndex];

        // Boolean here to see whether the change is coming from a new draft group with the title being updated
        // This check is needed as new groups are created with a dummy comp and the backend responds with a different
        // text item list length (empty)
        // Fixes https://linear.app/dittowords/issue/DIT-1411/focus-bug-after-tabbing-from-group-title
        const isNewDraftGroupTitleChange =
          targetGroup.comps.length === 1 && targetGroup.comps[0].text === "" && action.textItems.length === 0;
        // Need to make sure something changed before updating state
        // This is to ignore changes coming in from websockets that were made by the current user's browser window
        // This has to be done in the frontend as the backend can't distinguish between browser windows of the same
        // user. We still want a secondary window for the same user to update.
        // More context: https://making-ditto.slack.com/archives/C031Q5V6RCG/p1649797818125299
        const hasCompsBeenUpdated =
          !isNewDraftGroupTitleChange &&
          (targetGroup.comps.length !== action.textItems.length ||
            targetGroup.comps.some((comp, idx) => comp.text !== action.textItems[idx].text));

        const isChangeComingFromCurrentUserWindow = !(
          targetGroup.name !== action.groupName ||
          targetGroup.linking_enabled !== action.linkingEnabled ||
          hasCompsBeenUpdated
        );

        if (isChangeComingFromCurrentUserWindow) return state;

        targetGroup.name = action.groupName;
        targetGroup.comps = action.textItems;
        targetGroup.linking_enabled = action.linkingEnabled;
        targetGroup.unsaved = false;

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.ENABLE_LINKING_FOR_GROUP: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);

        if (groupIndex === -1) {
          console.error({ state, action });
          throw new Error("Group not found when enabling linking");
        }

        groups[groupIndex].comps = [...action.textItems];
        groups[groupIndex].linking_enabled = true;
        groups[groupIndex].unsaved = false;

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.DISABLE_LINKING_FOR_GROUP: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);

        if (groupIndex === -1) {
          console.error({ state, action });
          throw new Error("Group not found when disabling linking");
        }

        groups[groupIndex].linking_enabled = false;
        groups[groupIndex].unsaved = false;

        return { ...state, groups };
      }

      case GROUP_REDUCER_TYPES.ADD_DEFAULT_UNLINKED_GROUP: {
        return {
          ...state,
          groups: [createDefaultDraftGroup(action.projectId, action.workspaceId), ...state.groups],
        };
      }

      case GROUP_REDUCER_TYPES.RESET_GROUPS:
        return { ...state, groups: [] };

      case GROUP_REDUCER_TYPES.UPDATE_GROUPS:
        return {
          ...state,
          groups: action.updater(state.groups),
        };

      case GROUP_REDUCER_TYPES.UPDATE_GROUP_APIID: {
        const groups = [...state.groups];
        const groupIndex = groups.findIndex((group) => group._id.toString() === action.groupId);
        if (groupIndex === -1) {
          console.error({ state, action });
          throw new Error("Group not found when updating group apiID");
        }

        groups[groupIndex].apiID = action.apiId;

        return { ...state, groups };
      }

      default:
        return state;
    }
  } catch (error) {
    console.error(error);
    return state;
  }
};

const useGroupState = (initialGroups: Group[] = []) =>
  useReducer(reducer, {
    groups: initialGroups,
    fetching: true,
    finished: false,
  });
export default useGroupState;
