import CloseIcon from "@mui/icons-material/Close";
import { IFVariable } from "@shared/types/Variable";
import classNames from "classnames";
import React, { ChangeEvent } from "react";
import style from "./EditMapVariable.module.css";

interface EditMapVariableProps {
  variable: IFVariable;
  headerStyle?: React.CSSProperties;
  disabled?: boolean;
  onEntryChange: (key: string, newEntry: { key: string; value: string }) => void;
  onAddEntry: () => void;
  onDeleteEntry: (key: string) => void;
}

const EditMapVariable = (props: EditMapVariableProps) => {
  const { variable } = props;

  const handleKeyChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newKey = e.target.value;
    const newEntry = { key: newKey, value: variable.data[key] };
    props.onEntryChange(key, newEntry);
  };

  const handleValueChange = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const newEntry = { key: key, value: newValue };
    props.onEntryChange(key, newEntry);
  };

  return (
    <div className={style.mapVariableArea}>
      <div className={style.header}>
        <span style={props.headerStyle}>Key-Value Pairs</span>
      </div>
      <div className={style.rows}>
        {Object.entries(variable.data).map(([key, value], index) => {
          let keyValue = key;
          if (keyValue.match(/__empty.*__/)) {
            keyValue = "";
          }

          if (keyValue.match(/__duplicate.*__/)) {
            keyValue = keyValue.replace(/__duplicate.*__/, "");
          }

          const keyIsDuplicate = keyValue.indexOf("__duplicate") !== -1;

          return (
            // need to use `index` for this key rather than `key` because
            // otherwise the component will re-mount every time you make an edit
            <div className={style.entryRow} key={index}>
              <input
                className={classNames(style.input, {
                  [style.duplicate]: keyIsDuplicate,
                })}
                type="text"
                placeholder="Key"
                onChange={handleKeyChange(key)}
                value={keyValue}
                disabled={props.disabled}
              />
              <input
                className={style.input}
                type="text"
                placeholder="Value"
                onChange={handleValueChange(key)}
                value={value}
                disabled={props.disabled}
              />
              {!props.disabled && (
                <CloseIcon
                  className={style.deleteEntryButton}
                  onClick={props.disabled ? () => {} : () => props.onDeleteEntry(key)}
                />
              )}
            </div>
          );
        })}
      </div>
      {!props.disabled && (
        <button className={style.addRowBtn} onClick={() => props.onAddEntry()}>
          +
        </button>
      )}
    </div>
  );
};

export default EditMapVariable;
