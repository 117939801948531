import { useState } from "react";

interface Tag {
  _id: string;
  total: number;
}
type UnselectedTags = Tag[] | null;
export type SelectedTags = Tag[];

export type Status = "Any" | "WIP" | "Review" | "Final";
const DEFAULT_STATUS: Status = "Any";

export type ComponentFilter = "Attached" | "Unattached";

export interface ITagState {
  selected: Set<string>;
  counts: { [tagName: string]: number };
}

export interface SearchState {
  variant: React.StatePair<{ id: string; name: string } | null>;
  assignee: React.StatePair<string | null>;
  query: React.StatePair<string>;
  devID: React.StatePair<string | null>;
  statusFilter: React.StatePair<Status>;
  unselectedTags: React.StatePair<UnselectedTags>;
  selectedTags: React.StatePair<SelectedTags>;
  searchFilterEnabled: boolean;
  componentFilter: React.StatePair<ComponentFilter | null>;
  tagState: React.StatePair<ITagState>;
  resetSearch: () => void;
}

const useSearchFilterEnabled = (
  args: Pick<SearchState, "query" | "selectedTags" | "statusFilter" | "assignee" | "devID" | "componentFilter">
) => {
  const {
    assignee: [assignee],
    query: [query],
    devID: [devID],
    selectedTags: [selectedTags],
    statusFilter: [statusFilter],
    componentFilter: [componentFilter],
  } = args;

  return (
    query !== "" ||
    selectedTags.length > 0 ||
    statusFilter !== "Any" ||
    assignee !== null ||
    devID !== null ||
    componentFilter !== null
  );
};

const useSearchState = (initialValues?: Partial<{ tagState: ITagState; assignee: string }>): SearchState => {
  const variant = useState<{ id: string; name: string } | null>(null);
  const assignee = useState<string | null>(initialValues?.assignee || null);
  const query = useState("");
  const statusFilter = useState<Status>(DEFAULT_STATUS);
  const devID = useState<string | null>(null);
  const componentFilter = useState<ComponentFilter | null>(null);
  const tagState = useState<ITagState>(
    initialValues?.tagState || {
      selected: new Set(),
      counts: {},
    }
  );

  // All of the tags that are used in the current project
  const unselectedTags = useState<UnselectedTags>(null);

  // The subset of `unselectedTags` that are currently selected
  // in the search filter
  const selectedTags = useState<SelectedTags>([]);

  const resetSearch = () => {
    variant[1](null);
    assignee[1](null);
    query[1]("");
    statusFilter[1](DEFAULT_STATUS);
    devID[1](null);
    componentFilter[1](null);
    tagState[1]({
      selected: new Set(),
      counts: {},
    });
  };

  const searchFilterEnabled = useSearchFilterEnabled({
    assignee,
    query,
    devID,
    selectedTags,
    statusFilter,
    componentFilter,
  });

  return {
    variant,
    assignee,
    query,
    devID,
    componentFilter,
    statusFilter,
    unselectedTags,
    selectedTags,
    searchFilterEnabled,
    tagState,
    resetSearch,
  };
};

export default useSearchState;
