import Check from "@mui/icons-material/Check";
import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

interface AvailableConnectionProps {
  isConnected: boolean;
  showModal: () => void;
}

interface WaitlistConnectionProps {
  isWaitlisted: boolean;
  toggleWaitlist: () => Promise<void>;
}

interface Props {
  name: string;
  logo: string;
  description: string;
  connectedText?: string;
}

function ConnectionTile(props: Props & (AvailableConnectionProps | WaitlistConnectionProps)) {
  const { name, logo, description, connectedText } = props;

  return (
    <div className={styles.container}>
      <img className={styles.logoContainer} src={logo} />
      <div className={styles.column}>
        <div className={styles.name}>
          <span>{name}</span>
        </div>
        <div className={styles.description}>{description}</div>
        {"isConnected" in props && (
          <ConnectButton isConnected={props.isConnected} showModal={props.showModal} connectedText={connectedText} />
        )}
        {"isWaitlisted" in props && (
          <WaitlistButton isWaitlisted={props.isWaitlisted} toggleWaitlist={props.toggleWaitlist} />
        )}
      </div>
    </div>
  );
}

const ConnectButton = ({
  isConnected,
  connectedText,
  showModal,
}: {
  isConnected: boolean;
  connectedText?: string;
  showModal: () => void;
}) => (
  <button
    className={classNames(styles.button, {
      [styles.connected]: isConnected,
      [styles.blueFillButton]: !isConnected,
    })}
    onClick={showModal}
  >
    {isConnected ? (
      <>
        <Check className={styles.icon} /> {connectedText || "Connected"}
      </>
    ) : (
      "Open"
    )}
  </button>
);

const WaitlistButton = ({ isWaitlisted, toggleWaitlist }: { isWaitlisted: boolean; toggleWaitlist: () => void }) => (
  <button
    className={classNames(styles.button, {
      [styles.waitlisted]: isWaitlisted,
      [styles.outlineButton]: !isWaitlisted,
    })}
    onClick={toggleWaitlist}
  >
    {isWaitlisted ? (
      <>
        <Check className={styles.icon} /> Waitlisted
      </>
    ) : (
      "Notify Me"
    )}
  </button>
);

export default ConnectionTile;
