import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import http from "..";

type HttpControllerArg<ArgType> = ({ url: string } | { getUrl: (a: ArgType) => string }) &
  (
    | { method: "get" | "post" | "put" | "patch" | "delete" }
    | { getMethod: (a: ArgType) => "get" | "post" | "put" | "patch" | "delete" }
  ) &
  ({ config: AxiosRequestConfig } | { getConfig: (a: ArgType) => AxiosRequestConfig } | {});

export const createHttpRequest = <ArgType, ReturnType = any>(c: HttpControllerArg<ArgType>) => {
  return (a: ArgType): [Promise<AxiosResponse<ReturnType>>, () => void] => {
    const source = axios.CancelToken.source();

    const url = "url" in c ? c.url : c.getUrl(a);
    const method = "method" in c ? c.method : c.getMethod(a);

    let config: AxiosRequestConfig;
    if ("config" in c) {
      config = c.config;
    } else if ("getConfig" in c) {
      config = c.getConfig(a);
    } else {
      config = {};
    }

    if (method === "get" || method === "delete") {
      const requestPromise = http[method]<ReturnType>(url, {
        ...config,
        cancelToken: source.token,
      });

      return [requestPromise, () => source.cancel()];
    }

    if (method === "post" || method === "put" || method === "patch") {
      const { data, ...rest } = config;
      return [
        http[method]<ReturnType>(url, data, {
          ...rest,
          cancelToken: source.token,
        }),
        () => source.cancel(),
      ];
    }

    throw new Error(`Unknown method ${method}`);
  };
};
