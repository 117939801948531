import NotificationToast, { ToastNotification } from "@/components/notification-toast";
import React, { useEffect, useRef, useState } from "react";
import style from "./toast.module.css";

interface Props {
  notification: ToastNotification;
}

export default function Toast(props: Props) {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [show, setShow] = useState(false);

  useEffect(
    function updateToast() {
      setShow(true);
      if (props.notification?.time) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => setShow(false), props.notification.time);
      }
    },
    [props.notification]
  );

  return (
    <div>
      {props.notification && show && <NotificationToast notification={props.notification} className={style.toast} />}
    </div>
  );
}
