import React from "react";

import style from "./ExportBetaTag.module.css";

const ExportBetaTag = () => (
  <div className={style.betaTagContainer}>
    <b>This format is currently in beta.</b> If you have feedback, please{" "}
    <b>
      <a href="mailto:dev@dittowords.com">let us know!</a>
    </b>
  </div>
);

export default ExportBetaTag;
