import { WEBSOCKET_URL } from "@shared/types/websocket";
import { useEffect, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { IDittoEvent, getWebsocketMessageType, getWebsocketTargets } from "./lib";

/**
 * Enables easily subscribing to DittoEvents on the frontend.
 * @param event the DittoEvent (that includes a `websocket` target) to listen to
 * @param callback the callback to execute with the event data
 * @param dependencies stateful dependencies relied upon by the callback
 */
export function useDittoEventListener<EmitData extends object>(
  event: IDittoEvent<EmitData>,
  callback: (data: EmitData) => void,
  dependencies?: any[]
) {
  const { lastMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const eventNames = useRef<string[]>(
    (() => {
      const websocketTargets = getWebsocketTargets(event.targets);
      if (!websocketTargets.length) {
        console.error(
          `Ditto event "${event.name}" has no websocket targets. At least one websocket target is required to use '.useListener()'`
        );
      }
      return websocketTargets.map((t) => getWebsocketMessageType(event.name, t));
    })()
  );

  useEffect(() => {
    if (!lastMessage) return;

    let data: EmitData & { messageType: string };
    try {
      data = JSON.parse(lastMessage.data);
    } catch {
      return;
    }

    if (!data.messageType) {
      console.error("Received websocket message without messageType", data);
      return;
    }

    if (eventNames.current.includes(data.messageType)) {
      // due to the complexity in reconciling backend types and frontend types,
      // we don't want to throw an error if a given event can't be perfectly reconciled
      // with the given Zod schema
      const parseResult = event.dataShape.safeParse(data);
      if (!parseResult.success) {
        console.warn(`Received websocket message with invalid data: `, data);
      }

      callback(data);
    }
  }, [lastMessage, ...(dependencies || [])]);
}
