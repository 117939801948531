import { useMemo } from "react";

import { insensitiveIncludes } from "@shared/lib/StringCompare";
import { IFProject } from "@shared/types/Project";
import { ComponentFilter, ITagState, SearchState, Status } from "../../../hooks/useSearchState";
import { VARIANT_STATUSES_ENABLED } from "../../../utils/featureFlags";
import { CategorizedGroupState } from "./getCategorizedGroups";
import { Group, TextItem, isGroupLinked } from "./types";
import { ALL_PAGE_ID, DRAFTED_GROUPS_PAGE, DRAFTED_GROUPS_PAGE_ID, PageState, SelectedPage } from "./usePageState";
import { ProjectVariantsState } from "./useProjectState";
import { getProjectHasFigmaConnection } from "./useResyncState";

type GroupsToRender = Group[];

const getGroupsOnPage = (groups: Group[], selectedPage: SelectedPage): GroupsToRender => {
  const pinnedGroups: Group[] = [];
  const otherGroups: Group[] = [];

  const groupsOnPage = groups.filter((group) => {
    if (!selectedPage) {
      return true;
    }

    return isGroupLinked(group)
      ? selectedPage?.id === group.integrations.figma.page_id
      : selectedPage?.id === DRAFTED_GROUPS_PAGE_ID;
  });

  groupsOnPage.forEach((group) => (group.pinned ? pinnedGroups.push(group) : otherGroups.push(group)));

  return [...pinnedGroups, ...otherGroups];
};

const isInFilters = (
  textItem: TextItem,
  query: string,
  assignee: string | null,
  devID: string | null,
  statusFilter: Status,
  tagState: ITagState,
  variantId: string | null,
  componentFilter: ComponentFilter | null
) => {
  if (!textItem) {
    return false;
  }

  const noQuery = query === "";
  const noAssignee = assignee === null;
  const noDevID = devID === null;
  const noStatus = statusFilter === "Any";
  const noTags = tagState.selected.size == 0;
  const noComponentFilter = componentFilter === null;

  const filtersDisabled = noQuery && noStatus && noTags && noAssignee && noDevID && noComponentFilter;
  if (filtersDisabled) {
    return true;
  }

  const isQueryMatch = getIsQueryMatch(textItem, query, variantId);

  const isAssigneeMatch = noAssignee || textItem.assignee === assignee;

  const isStatusMatch = getIsStatusMatch(textItem, statusFilter, variantId);

  const isTagMatch =
    noTags || Array.from(tagState.selected.values()).every((tag) => (textItem.tags || []).includes(tag));

  const isDevIDMatch = noDevID || textItem.apiID?.toLowerCase().includes(devID.toLowerCase());

  const isComponentMatch = getIsComponentMatch(textItem, componentFilter);

  return isQueryMatch && isStatusMatch && isTagMatch && isAssigneeMatch && isDevIDMatch && isComponentMatch;
};

export function getIsStatusMatch(textItem: TextItem, status: Status, variantId?: string | null) {
  if (status === "Any") {
    return true;
  }

  if (variantId && VARIANT_STATUSES_ENABLED) {
    const variant = textItem.variants.find((v) => v.variantId.toString() === variantId);
    if (!variant) return false;

    return variant.status === status;
  } else {
    return textItem.status === status;
  }
}

export function getIsComponentMatch(textItem: TextItem, componentFilter: ComponentFilter | null) {
  if (!componentFilter) {
    return true;
  }

  if (componentFilter === "Unattached") {
    return !textItem.ws_comp;
  }

  if (componentFilter === "Attached") {
    return !!textItem.ws_comp;
  }

  throw new Error(`Invalid component filter: ${componentFilter}`);
}

export function getIsQueryMatch(textItem: TextItem, query: string, variantId: string | null) {
  if (!query) return true;
  if (!variantId) return insensitiveIncludes(textItem.text, query);

  const variant = textItem.variants.find((v) => v.variantId.toString() === variantId);
  if (!variant) return false;

  return insensitiveIncludes(variant.text, query);
}

const getGroupsInCurrentFilter = (
  groups: GroupsToRender,
  args: {
    query: string;
    assignee: string | null;
    devID: string | null;
    tagState: ITagState;
    statusFilter: Status;
    variantId: string | null;
    frameVariants: Record<string, { id: string; name: string }[]>;
    componentFilter: ComponentFilter | null;
  }
) => {
  const { query, assignee, tagState, statusFilter, variantId, frameVariants, devID, componentFilter } = args;

  let searchFilteredGroups = groups;

  // Group-level filter
  if (variantId) {
    searchFilteredGroups = searchFilteredGroups.filter((group) => {
      const groupVariants = frameVariants[group._id] || [];
      return groupVariants.some((v) => v.id === variantId);
    });
  }

  const noQuery = query === "";
  const noAssignee = assignee === null;
  const noDevID = devID === null;
  const noStatus = statusFilter === "Any";
  const noTags = tagState.selected.size == 0;
  const noVariant = !variantId;
  const noComponentFilter = componentFilter === null;

  /**
   * If no filters are enabled, we can shortcut all of the filtering
   * logic and simply return the expected data
   */
  const filtersDisabled = noQuery && noStatus && noTags && noDevID && noAssignee && noVariant && noComponentFilter;

  if (filtersDisabled) {
    const filteredGroups = {};
    const filteredComps: string[] = [];

    groups.forEach((group) => {
      filteredGroups[group._id.toString()] = true;
      (group?.comps || []).forEach((textItem) => filteredComps.push(textItem._id));
      (group?.blocks || []).forEach((block) =>
        (block?.comps || []).forEach((textItem) => filteredComps.push(textItem._id))
      );
    });

    return {
      filteredGroups,
      filteredComps,
      searchFilteredGroups,
    };
  }

  const filteredGroups: { [key: string]: boolean } = {};
  const filteredComps: string[] = [];

  searchFilteredGroups = searchFilteredGroups.filter((group) => {
    let hasMatchingText = false;

    (group?.comps || []).forEach((textItem) => {
      const matchesFilter = isInFilters(
        textItem,
        query,
        assignee,
        devID,
        statusFilter,
        tagState,
        variantId,
        componentFilter
      );

      if (matchesFilter) {
        hasMatchingText = true;
        filteredGroups[group._id.toString()] = true;
        filteredComps.push(textItem._id);
      }
    });

    if (group.blocks?.length > 0) {
      (group?.blocks || []).forEach((block) => {
        (block?.comps || []).forEach((textItem) => {
          const matchesFilter = isInFilters(
            textItem,
            query,
            assignee,
            devID,
            statusFilter,
            tagState,
            variantId,
            componentFilter
          );

          if (matchesFilter) {
            hasMatchingText = true;
            filteredGroups[group._id.toString()] = true;
            filteredComps.push(textItem._id);
          }
        });
      });
    }

    return hasMatchingText;
  });

  return {
    filteredGroups,
    filteredComps,
    searchFilteredGroups,
  };
};

export interface GroupRenderState {
  status: "draft" | "linked";
  groups: ReturnType<typeof getGroupsInCurrentFilter>;
}

interface Args {
  search: SearchState;
  page: PageState;
  project: IFProject | null;
  variants: ProjectVariantsState;
  groupsCategorized: CategorizedGroupState;
}

const useGroupRenderState = (args: Args): GroupRenderState => {
  const { search, page, project, variants, groupsCategorized } = args;
  const frameVariants = variants.frameVariants;

  const {
    query: [query],
    assignee: [assignee],
    devID: [devID],
    tagState: [tagState],
    statusFilter: [statusFilter],
    variant: [variantFilter],
    componentFilter: [componentFilter],
  } = search;
  const {
    selectedPage: [selectedPage],
  } = page;

  const isOnDraftGroupsPage = selectedPage?.id === DRAFTED_GROUPS_PAGE.id;
  const projectIsUnlinked = !getProjectHasFigmaConnection(project);
  const showDraftGroups = projectIsUnlinked || isOnDraftGroupsPage;

  const groupsOnPage = useMemo(() => {
    if (selectedPage?.id === ALL_PAGE_ID) {
      return [...groupsCategorized.groupsLinked, ...groupsCategorized.groupsUnlinked];
    }

    return getGroupsOnPage(
      showDraftGroups ? groupsCategorized.groupsUnlinked : groupsCategorized.groupsLinked,
      selectedPage
    );
  }, [showDraftGroups, groupsCategorized, selectedPage]);

  const groupsInCurrentFilter = useMemo(
    () =>
      getGroupsInCurrentFilter(groupsOnPage, {
        query,
        assignee,
        devID,
        tagState,
        statusFilter,
        variantId: variantFilter ? variantFilter.id : null,
        frameVariants,
        componentFilter,
      }),
    [groupsOnPage, query, assignee, devID, tagState, statusFilter, variantFilter, frameVariants, componentFilter]
  );

  return useMemo(
    () => ({
      status: showDraftGroups ? "draft" : "linked",
      groups: groupsInCurrentFilter,
    }),
    [showDraftGroups, groupsInCurrentFilter]
  );
};

export default useGroupRenderState;
