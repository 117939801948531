import classnames from "classnames";
import React, { useEffect, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import google from "../../assets/google.png";
import spinner from "../../assets/small-spinner.gif";
import { ROLE_NAMES } from "../../defs";
import http, { API } from "../../http";

import style from "./style.module.css";

const JoinWorkspace = ({ authState }) => {
  const { role, ws_share_id } = useParams();
  const { loginWithRedirect } = authState;
  const [workspaceInfo, setWorkspaceInfo] = useState({ id: null, name: null });
  const [error, setError] = useState({ exists: false, msg: null });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    analytics.page("Join Workspace", {
      role,
    });
    fetchWorkspaceInfo();
  }, []);

  const fetchWorkspaceInfo = async () => {
    setLoading(true);
    try {
      const { url, body } = API.workspace.post.validateShareLinkId;
      const { data: response } = await http.post(
        url,
        body({
          wsShareId: ws_share_id,
          role,
        })
      );
      setWorkspaceInfo({ name: response.name, id: response._id });
      setLoading(false);
    } catch (e) {
      setError({ exists: true });
      setLoading(false);
      console.log("in join-workspace.jsx: ", e.message);
    }
    setLoading(false);
  };

  const onHide = () => {
    // you cant hide this
  };

  if (error.exists) {
    return (
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Body className={style.body}>
          <div className={style.title}>Invite link no longer active</div>
          <div className={style.error}>
            Please check with the person who invited you for a new link, or send us an email at{" "}
            <a href="mailto: support@dittowords.com">support@dittowords.com</a>.
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }
  if (loading) {
    return (
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Body className={style.body}>
          <div className={style.loading}>
            Loading...
            <br />
            <img src={spinner} />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }

  return (
    <div className={style.container}>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Body className={style.body}>
          <div className={style.title}>
            <span className={style.subheader}>Join the Ditto workspace</span>
            <br />
            <span className={style.accent}>{workspaceInfo.name}</span>
            <div>
              <span className={style.subheader}>
                as {role === "commenter" ? "a " : "an "}
                <span className={classnames([style.accent, style.role])}>{ROLE_NAMES[role]}</span>
              </span>
            </div>
          </div>
          {role === "commenter" && (
            <div className={style.hintText}>You'll be able to change status, view, and comment on all projects.</div>
          )}
          <button
            className={style.loginButton}
            onClick={() =>
              loginWithRedirect({
                connection: "google-oauth2",
                appState: {},
                workspaceID: workspaceInfo.id,
                invitedRole: role,
              })
            }
          >
            <img className={style.googleLogo} src={google} />
            Continue with Google
          </button>
          <a
            className={style.loginLink}
            onClick={() =>
              loginWithRedirect({
                connection: "azure-AD",
                appState: {},
                workspaceID: workspaceInfo.id,
                invitedRole: role,
              })
            }
          >
            Continue with Azure AD
          </a>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default JoinWorkspace;
