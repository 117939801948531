import LockIcon from "@mui/icons-material/Lock";
import classnames from "classnames";
import React from "react";
import style from "./style.module.css";

interface Props {
  isLocked: boolean;
  isSublabel: boolean;
}

const ProjectLockedLabel = ({ isLocked, isSublabel }: Props) => (
  <div
    className={classnames({
      [style.container]: !isSublabel,
      [style.sublabel]: isSublabel,
    })}
  >
    <LockIcon className={style.icon} />
    <span className={style.text}>{isLocked ? "Locked" : "Merged"}</span>
  </div>
);

export default ProjectLockedLabel;
