import classNames from "classnames";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";

import style from "./ModalBase.module.css";

interface ModalComponentProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalBody = (props: ModalComponentProps) => {
  const { children, className, ...otherProps } = props;

  return (
    <BootstrapModal.Body className={classNames([style.body, className])} {...otherProps}>
      {children}
    </BootstrapModal.Body>
  );
};

export const ModalFooter = (props: ModalComponentProps) => {
  const { children, className: _className, ...otherProps } = props;

  return (
    <BootstrapModal.Footer className={classNames([style.footer, _className])} {...otherProps}>
      {children}
    </BootstrapModal.Footer>
  );
};

interface ModalBaseProps {
  title?: React.ReactNode;
  titleClassName?: string;
  children: React.ReactNode;
  onHide: () => void;
  className?: string;
  isLayered?: boolean;
}

const ModalBase = (props: ModalBaseProps) => {
  const { title, titleClassName, children, onHide = () => null, className, isLayered } = props;

  return (
    <BootstrapModal
      show={true}
      className={classNames(style.modal, className, {
        [style.modalLayered]: isLayered,
      })}
      dialogClassName={style.dialog}
      backdropClassName={classNames(style.backdrop, {
        [style.backdropLayered]: isLayered,
      })}
      onHide={onHide}
      centered
    >
      {title && (
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={classNames([style.title, titleClassName])}>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
      )}
      {children}
    </BootstrapModal>
  );
};

export default ModalBase;
