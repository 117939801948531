import React from "react";
export default () => (
  <svg width="24" height="24" viewBox="0 0 90 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M85 24C85 35.0457 76.0457 44 65 44L45 44L45 4L65 4C76.0457 4 85 12.9543 85 24V24Z"
      stroke="black"
      strokeWidth="8"
    />
    <path
      d="M5 24C5 35.0457 13.9543 44 25 44L45 44L45 4L25 4C13.9543 4 5 12.9543 5 24V24Z"
      stroke="black"
      strokeWidth="8"
    />
    <path
      d="M5 64C5 75.0457 13.9543 84 25 84H45L45 44L25 44C13.9543 44 5 52.9543 5 64V64Z"
      stroke="black"
      strokeWidth="8"
    />
    <path
      d="M5 104C5 115.046 13.9543 124 25 124V124C36.0457 124 45 115.046 45 104L45 84H25C13.9543 84 5 92.9543 5 104V104Z"
      stroke="black"
      strokeWidth="8"
    />
    <path
      d="M45 64C45 75.0457 53.9543 84 65 84V84C76.0457 84 85 75.0457 85 64V64C85 52.9543 76.0457 44 65 44V44C53.9543 44 45 52.9543 45 64V64Z"
      stroke="black"
      strokeWidth="8"
    />
  </svg>
);
