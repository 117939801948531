import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../../../button/buttonprimary";
import style from "./style.module.css";

interface Props {
  onHide: () => void;
  handleEnableAdvancedBranching: () => void;
}
const AdvancedBranchingModal = ({ onHide, handleEnableAdvancedBranching }: Props) => {
  const [isLoading, setIsloading] = useState<boolean>(false);

  const onEnableAdvancedBranching = async () => {
    setIsloading(true);
    handleEnableAdvancedBranching();
    setIsloading(false);
    onHide();
  };
  const hideModal = () => (!isLoading ? onHide() : () => null);
  return (
    <BootstrapModal
      show
      className={style.modal}
      dialogClassName={classnames({
        [style.dialog]: true,
        [style.width45]: true,
      })}
      backdropClassName={style.backdrop}
      onHide={hideModal}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          Turn on support for advanced Figma branching for your workspace?
        </BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={hideModal} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div>
          Enabling this beta will allow anyone in your workspace to work on multiple Figma branches in Ditto at once.
        </div>
        <div>If your team has any feedback, please let us know.</div>
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <div>
          <a className={style.link} href="https://www.dittowords.com/docs/figma-branching" target="_blank">
            Learn More
          </a>
        </div>
        <div className={style.buttonWrapper}>
          <ButtonPrimary text="Go Back" onClick={onHide} disabled={isLoading} />
          <ButtonPrimary text="Turn on" onClick={onEnableAdvancedBranching} disabled={isLoading} />
        </div>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default AdvancedBranchingModal;
