import { ImportAction, isValidImportAction } from "@shared/types/http/Component";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import { useState } from "react";
import ButtonPrimary from "../../../button/buttonprimary";
import ButtonSecondary from "../../../button/buttonsecondary";
import { ModalBody, ModalFooter } from "../../../shared/ModalBase";
import VariantSelectInput from "../../../shared/VariantSelectInput";
import { IStepProps } from "../../useState";
import { useImportVariantRequest } from "../variant-select/importVariantRequest";
import style from "./style.module.css";
import { useImportCreateRequest } from "./useImportCreateRequest";
import { useImportUpdateRequest } from "./useImportUpdateRequest";

const ChooseAction = (props: IStepProps) => {
  const { state, dispatch } = props;

  const [action, setAction] = useState<ImportAction | null>(state.action ?? null);
  const [variantSelection, setVariantSelection] = useState<{ id: string; name: string } | null>(state.variant ?? null);
  const [localState, setLocalState] = useState<{ loading: boolean }>({
    loading: false,
  });

  const onActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const action = e.target.value;
    if (!isValidImportAction(action)) return;
    if (action !== "VARIANT") setVariantSelection(null);
    setAction(action);
  };

  const nextDisabled = localState.loading || !action || (action === "VARIANT" && !variantSelection);

  const keyCountTotal = state.totalComponentsCount ?? 0;
  const keyCountExisting = state.existingComponentsCount ?? 0;

  const onBack = async () => {
    dispatch({
      type: "SET_STEP",
      step: state.format === "csv" ? "csv-mapping" : "file-upload",
    });
  };

  const importCreateRequest = useImportCreateRequest();
  const importUpdateRequest = useImportUpdateRequest(state.backgroundJobEntryId!);
  const importVariantRequest = useImportVariantRequest({
    backgroundJobEntryId: state.backgroundJobEntryId,
    variantId: variantSelection ? variantSelection.id : null,
  });

  const onNext = async () => {
    if (nextDisabled) {
      return;
    }

    setLocalState((s) => ({ ...s, loading: true }));

    if (action === "CREATE") {
      const response = await importCreateRequest(state.backgroundJobEntryId!);
      if (!response.success) {
        return;
      }

      const { components, componentsIgnored, ignoredComponentsCount } = response.data;

      return dispatch({
        type: "IMPORT_CREATE_RESPONSE",
        step: "preview",
        data: {
          components,
          componentsIgnored,
          ignoredComponentsCount,
        },
      });
    }

    if (action === "UPDATE") {
      const response = await importUpdateRequest();
      if (!response.success) {
        return;
      }

      const { components, componentsIgnored, ignoredComponentsCount } = response.data;

      return dispatch({
        type: "IMPORT_UPDATE_RESPONSE",
        step: "preview",
        data: {
          components,
          componentsIgnored,
          ignoredComponentsCount,
        },
      });
    }

    if (action === "VARIANT") {
      const response = await importVariantRequest();
      if (!response.success) {
        return;
      }

      const { components, componentsIgnored, ignoredComponentsCount } = response.data;

      return dispatch({
        type: "IMPORT_VARIANT_RESPONSE",
        step: "preview",
        data: {
          variant: variantSelection,
          components,
          componentsIgnored,
          ignoredComponentsCount,
        },
      });
    }

    throw new Error("Invalid action: " + (action as any));
  };

  const buttonText = localState.loading ? "Loading..." : "Preview Import -->";

  return (
    <>
      <ModalBody>
        <p className={style.headerText}>
          <DittoComponent
            componentId="component-imports.x-of-y-matching-strings"
            richText
            variables={{
              matchingComponentCount: keyCountExisting,
              componentCount: keyCountTotal,
              filename: () => <span className={style.filename}>{state.file?.path ?? "" + " "}</span>,
            }}
            count={keyCountTotal}
          />
        </p>
        <div>
          {keyCountTotal > keyCountExisting && (
            <div className={style.alignOptions}>
              <input
                id="create"
                type="radio"
                name="action"
                value="CREATE"
                checked={action === "CREATE"}
                onChange={onActionChange}
                className={style.radioButton}
              />
              <label className={style.labelText} htmlFor="create">
                Create new components
              </label>
              <p className={style.description}>
                New components will be created for all strings that do not match any existing components in your
                library. Other strings will be ignored.
              </p>
            </div>
          )}
          <div className={style.alignOptions}>
            <input
              id="variant"
              type="radio"
              name="action"
              value="VARIANT"
              checked={action === "VARIANT"}
              onChange={onActionChange}
              className={style.radioButton}
            ></input>
            <label className={style.labelText} htmlFor="variant">
              Import strings as a variant on existing components
            </label>
            <p className={classNames(style.description, style.descriptionVariant)}>
              Strings with IDs that match existing components will be imported as a variant of those components. Other
              strings will be ignored.
            </p>
            {action === "VARIANT" && (
              <VariantSelectInput
                value={variantSelection}
                onSelect={(variant: { id: string; name: string }) => {
                  setVariantSelection(variant);
                }}
                className={style.selectWrapper}
              />
            )}
          </div>
          <div className={style.alignOptions}>
            <input
              id="update"
              type="radio"
              name="action"
              value="UPDATE"
              checked={action === "UPDATE"}
              onChange={onActionChange}
              className={style.radioButton}
            ></input>
            <label className={style.labelText} htmlFor="update">
              Update existing component text
            </label>
            <p className={style.description}>
              Strings with IDs that match existing components will replace existing component text. Other strings wil be
              ignored.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={style.modalFooter}>
        <ButtonSecondary text="Back" onClick={onBack} className={style.backButton} data-testid="back-choose-action" />
        <ButtonPrimary
          text={buttonText}
          onClick={onNext}
          disabled={nextDisabled}
          loading={localState.loading}
          data-testid="next-modal-screen"
        />
      </ModalFooter>
    </>
  );
};

export default ChooseAction;
