import classnames from "classnames";
import React from "react";

import style from "./Tag.module.css";

interface ITagProps {
  text: string;
  is_selected?: boolean;
  count?: number;
  onClick?: () => void;
  className?: string;
}

export function Tag(props: ITagProps) {
  const { text, is_selected, count, onClick, className } = props;

  return (
    <button
      data-testid="tag"
      onClick={onClick}
      className={classnames([
        {
          [style.container]: true,
          [style.selected]: is_selected,
          [style.pointer]: !!onClick,
        },
        className,
      ])}
    >
      {text}
      {count && <span className={style.count}>{count}</span>}
    </button>
  );
}
