import * as React from "react";
import DeveloperGuideIcon from "../../../../components/icons/DeveloperGuideIcon";
import Panel from "../panel";
import Card from "./card";
import style from "./style.module.css";

import imgCustomerStories from "../../../../assets/home-page-case-studies.png";
import imgHelpGuide from "../../../../assets/home-page-help-guides.png";
import imgSampleProject from "../../../../assets/home-page-sample-project.png";

export interface Props {
  onSampleProjectClicked?: () => void;
  onHelpGuidesClicked?: () => void;
  onCustomerStoriesClicked?: () => void;
}

export default function ResourcesPanel(props: Props) {
  return (
    <Panel titleDittoComponentId="resources" icon={DeveloperGuideIcon}>
      <div className={style.container}>
        <Card
          img={imgSampleProject}
          textDittoComponentId="sample-project-cta"
          onClick={props.onSampleProjectClicked}
          className={style.card}
        />
        <Card
          img={imgHelpGuide}
          textDittoComponentId="help-guide-cta"
          onClick={props.onHelpGuidesClicked}
          className={style.card}
        />
        <Card
          img={imgCustomerStories}
          textDittoComponentId="customer-stories-cta"
          onClick={props.onCustomerStoriesClicked}
          className={style.card}
        />
      </div>
    </Panel>
  );
}
