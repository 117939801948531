import UserBubble from "@/components/UserBubble";
import { useWorkspace } from "@/store/workspaceContext";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { ICommentThread } from "@shared/types/CommentThread";
import classnames from "classnames";
import React, { MouseEventHandler, useMemo } from "react";
import style from "./style.module.css";

interface ICardBubblesProps {
  // The _id of the assigned user
  cardIsSelected: boolean;
  assignee?: string | null;
  comment_threads?: string[] | ICommentThread[];
  onAssigneeClick?: MouseEventHandler;
  onCommentThreadClick?: () => void;
}

function CardBubbles(props: ICardBubblesProps) {
  const { users } = useWorkspace();

  const numComments = useMemo(
    () =>
      props.comment_threads?.reduce((acc, thread) => {
        if (typeof thread === "string") return acc;
        return acc + thread.comments?.length || 0;
      }, 0) || 0,
    [props.comment_threads]
  );

  const assignedUser = React.useMemo(() => {
    let user = users?.find((user) => user._id === props.assignee);
    if (!user) {
      return null;
    } else {
      let { name, picture } = user;
      if (name.includes(" ")) {
        const [first, last] = name.split(" ");
        return {
          fullName: name,
          abbreviation: first[0].toUpperCase() + last[0].toUpperCase(),
          picture,
        };
      }

      return { fullName: name, abbreviation: name[0].toUpperCase(), picture };
    }
  }, [users, props.assignee]);

  return (
    <div className={style.metaBubbleWrapper}>
      {props.assignee && assignedUser && (
        <UserBubble
          itemKey={props.assignee}
          fullName={assignedUser.fullName}
          picture={assignedUser.picture}
          backgroundImagePrefix={
            props.cardIsSelected ? "" : "linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), "
          }
          abbreviation={assignedUser.abbreviation}
          className={classnames(style.assigneeBubble, {
            [style.assigneeSelected]: props.cardIsSelected,
            [style.pictureOpacity]: assignedUser.picture && !props.cardIsSelected,
          })}
          hideTooltip
          onClick={props.onAssigneeClick}
        ></UserBubble>
      )}
      {numComments > 0 && (
        <div
          className={classnames(style.comments, { [style.commentsSelected]: props.cardIsSelected })}
          onClick={props.onCommentThreadClick}
        >
          <ModeCommentIcon className={style.icon} />
          {numComments}
        </div>
      )}
    </div>
  );
}

export default CardBubbles;
