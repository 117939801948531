import classNames from "classnames";
import { Types } from "mongoose";
import { VARIANT_STATUSES_ENABLED } from "../../utils/featureFlags";
import style from "./CompStatusBar.module.css";

type VariantInstance = {
  status?: string | null;
  variantId: string | Types.ObjectId;
};

type Variant = {
  id: string;
  name: string;
};

type VariantStatusToRender = {
  status: string | null | undefined;
  variantId: string | Types.ObjectId;
  name: string;
};

const CompStatusBar = (props: {
  // TextItem or Component
  component: { status: string; variants: VariantInstance[] };
  /**
   * In some cases, we only want to show a subset of the variants. This is a list of variantIds to
   * render statuses for; if not provided, all variants will be rendered.
   */
  frameVariants?: Variant[];
  activeVariantId?: string;
  hoveredVariantId?: string;
  className?: string;
  vertical?: boolean;
  showAllStatuses?: boolean;
  setHoveredVariantId?: (variantId: string | null) => void;
  handleSelectActiveVariant?: (variantId: string) => void;
}) => {
  // because of the number of insane places <Comp> gets used, these are occasionally undefined during
  // somne number of renders, so we gotta do a check.
  if (!props.component) return <></>;
  if (!props.component.variants) return <></>;

  // variant statuses array
  let variantStatuses: VariantStatusToRender[] = [];
  if (props.showAllStatuses) {
    variantStatuses = props.component.variants.map((variant: VariantInstance) => ({
      status: variant.status,
      variantId: variant.variantId,
      name: "",
    }));
  } else {
    // if we have a filter list, we want to show a list that exactly corresponds the filter list;
    //  1. if the variant is in the filter list, we show it
    //  2. if the variant is not in the filter list, we don't show it
    //  3. if the variant is in the filter list but doesn't exist on the component, we show it as
    //     the base status
    const frameVariants = props.frameVariants || [];
    variantStatuses = frameVariants.map((frameVariant) => {
      const variant = props.component.variants.find((v) => v.variantId.toString() === frameVariant.id);
      return variant
        ? { status: variant.status, variantId: variant.variantId, name: frameVariant.name }
        : { status: props.component.status, variantId: frameVariant.id, name: frameVariant.name };
    });
  }

  const statusesToRender = [
    { variantId: "__base__", status: props.component.status, name: "Base" },
    ...(VARIANT_STATUSES_ENABLED ? variantStatuses : []),
  ];

  function getStatusClass(status: string | null | undefined) {
    switch (status) {
      case "WIP":
        return style.statusWip;
      case "REVIEW":
        return style.statusReview;
      case "FINAL":
        return style.statusFinal;
      case "NONE":
      case null:
      case undefined:
        return style.statusNone;
      default:
        return "";
    }
  }

  const onMouseEnter = (statusToRender: VariantStatusToRender) => {
    if (props.setHoveredVariantId) {
      props.setHoveredVariantId(statusToRender.variantId.toString());
    }
  };

  const onMouseLeave = () => props.setHoveredVariantId && props.setHoveredVariantId(null);

  const onClickSegment = (event: React.MouseEvent, statusToRender: VariantStatusToRender) => {
    if (props.handleSelectActiveVariant) {
      // prevent the click event from bubbling up to the parent
      // this is to prevent <Comp>'s normal onClick handler from firing -- the `handleSelectActiveVariant`
      // callback will handle selecting the comp.
      event.stopPropagation();

      props.handleSelectActiveVariant(statusToRender.variantId.toString());
    }
  };

  return (
    <div
      className={classNames(style.statusLabel, props.className, {
        [style.vertical]: props.vertical,
      })}
    >
      {statusesToRender.map((statusObj, index) => {
        const isActive = VARIANT_STATUSES_ENABLED && props.activeVariantId === statusObj.variantId.toString();
        const isHovered = props.hoveredVariantId === statusObj.variantId.toString();

        return (
          <div
            className={classNames(style.statusSegment, {
              [getStatusClass(statusObj.status)]: true,
              [style.hovered]: isHovered,
              [style.muted]: !isActive && !isHovered,
              // if there's no frameVariants array being passed in at all, we're not doing any selective
              // highlighting -- just style everything as active.
              [style.active]: isActive || !props.frameVariants,
            })}
            key={index}
            onMouseEnter={() => onMouseEnter(statusObj)}
            onMouseLeave={onMouseLeave}
            onClick={(event) => onClickSegment(event, statusObj)}
          />
        );
      })}
    </div>
  );
};

export default CompStatusBar;
