import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import classNames from "classnames";
import React from "react";
import style from "./style.module.css";
import { EmailTagProps } from "./types";

const EmailTag = ({ tag, onDelete }: EmailTagProps) => {
  if (tag.type === "new" && !tag.isAlreadyInvited) {
    return (
      <div id={tag.id} className={style.emailTag} onClick={onDelete}>
        <div>
          <EmailIcon className={style.emailIcon} />
          <span className={style.userInviteEmail}>{`${tag.email} as`}</span>
          <span className={style.userRole}>{tag?.role}</span>
        </div>
        <CloseIcon className={style.removeTagIcon} />
      </div>
    );
  }
  if (!tag.type) {
    return (
      <div id={tag.id} className={classNames([style.emailTag, style.invalidEmail])} onClick={onDelete}>
        {tag.name}
        <CloseIcon className={style.removeTagIcon} />
      </div>
    );
  }

  return (
    <div
      id={tag.id}
      className={classNames([style.emailTag, { [style.invalidEmail]: !tag.canInvite || tag.isAlreadyInvited }])}
      onClick={onDelete}
    >
      {tag.name}
      <CloseIcon className={style.removeTagIcon} />
    </div>
  );
};

export default EmailTag;
