import React from "react";
const CategoryRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="m321.233-590.616 127.844-209.536q5.616-9.231 13.75-13.154 8.135-3.923 17.173-3.923 9.038 0 17.173 3.923 8.134 3.923 13.75 13.154l127.844 209.536q5.616 9.038 5.616 18.981 0 9.942-4.616 18.172-4.615 8.231-12.692 13.154t-18.846 4.923H351.771q-10.813 0-18.923-4.971-8.11-4.971-12.615-13.106-4.616-8.106-4.616-18.014t5.616-19.139Zm378.858 495.23q-68.552 0-116.629-47.986-48.076-47.986-48.076-116.537 0-68.552 47.986-116.629 47.986-48.076 116.537-48.076 68.552 0 116.629 47.986 48.076 47.986 48.076 116.537 0 68.552-47.986 116.629-47.986 48.076-116.537 48.076Zm-564.705-56.166v-216.999q0-15.371 10.398-25.717 10.397-10.346 25.768-10.346h216.999q15.371 0 25.717 10.398 10.346 10.397 10.346 25.768v216.999q0 15.371-10.398 25.717-10.397 10.346-25.768 10.346H171.449q-15.371 0-25.717-10.398-10.346-10.397-10.346-25.768Z" />
    </svg>
  );
};

export default CategoryRounded;
