import CloseIcon from "@mui/icons-material/Close";
import { Elements } from "@stripe/react-stripe-js";
import classnames from "classnames";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { TRIAL_PLAN_LENGTH_DAYS } from "../../../../util/constants";
import { PlanWillCancel } from "../../../store/billingContext";
import { loadStripe } from "../../../utils/stripe";
import BillingModal, { PlanType } from "./index";
import style from "./style.module.css";
import { GetBillingStatusState } from "./useBillingStatus";
import { GetSeatsState } from "./useSeats";

interface BillingModalWrapperProps {
  componentsInWorkspace: number;
  name: string;
  email: string;
  onHide: () => void;
  seatsState: GetSeatsState;
  planWillCancel: PlanWillCancel;
  billingStatus: GetBillingStatusState;
  devToolsEnabled: boolean;
}

// Wrapper component to handle getting customer data from the backend
// This keeps the BillingModal component cleaner and easier to test in Storybook.
export const BillingModalWrapper = ({
  componentsInWorkspace,
  name,
  email,
  onHide,
  seatsState,
  planWillCancel,
  billingStatus,
  devToolsEnabled,
}: BillingModalWrapperProps) => {
  const stripePromise = loadStripe();
  if (seatsState.loading || billingStatus.loading) {
    return (
      <BootstrapModal
        show
        className={classnames([style.modal, style.loadingModal])}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header>
          <BootstrapModal.Title className={style.title}>Upgrading your plan</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div className={style.spinnerContainer}>
            <Spinner animation="border" variant="secondary" />
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }

  if (!seatsState.success || !billingStatus.success) {
    return (
      <BootstrapModal
        show
        className={classnames([style.modal, style.errorModal])}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header>
          <BootstrapModal.Title className={style.title}>Error</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div className={style.errorWrapper}>
            <div className={style.errorSubtitle}>Whoops!</div>
            <div className={style.error}>Unable to fetch billing data. Please refresh the page or try again later.</div>
          </div>
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }

  const { data: seatsStateData } = seatsState;
  const initialUserBilling = {
    // for the purposes of the billing modal, if the current plan is "trial", we
    // should just pretend that the user is on the free plan.
    plan: seatsStateData.plan === "trial" ? ("free" as PlanType) : (seatsStateData.plan as PlanType),
    monthly: {
      editors: seatsStateData.numMonthlyEditorSeats,
      commenters: seatsStateData.numMonthlyCommenterSeats,
      devTools: seatsStateData.monthlyDevTools,
    },
    yearly: {
      editors: seatsStateData.numYearlyEditorSeats,
      commenters: seatsStateData.numYearlyCommenterSeats,
      devTools: seatsStateData.yearlyDevTools,
    },
    yearlySubscription: seatsStateData.yearlySubscription,
    monthlySubscription: seatsStateData.monthlySubscription,
  };

  const { data: billingStatusData } = billingStatus;

  return (
    <Elements stripe={stripePromise}>
      <BillingModal
        name={name}
        email={email}
        numEditorUsers={billingStatusData.numEditorUsers}
        numCommenterUsers={billingStatusData.numCommenterUsers}
        componentsInWorkspace={componentsInWorkspace}
        initialUserBilling={initialUserBilling}
        devToolsEnabled={devToolsEnabled}
        onDevToolsTrial={
          billingStatusData.daysOnDevToolsTrial !== undefined &&
          billingStatusData.daysOnDevToolsTrial < TRIAL_PLAN_LENGTH_DAYS
        }
        onHide={onHide}
        planWillCancel={planWillCancel}
      />
    </Elements>
  );
};
