import { useEffect, useState } from "react";
import billing, { GetSeatsResponse } from "../../../http/billing";

interface GetSeatsLoading {
  loading: true;
}

interface GetSeatsSuccess {
  loading: false;
  success: true;
  data: GetSeatsResponse;
}

interface GetSeatsFailure {
  loading: false;
  success: false;
  error: string;
}

export type GetSeatsState = GetSeatsLoading | GetSeatsSuccess | GetSeatsFailure;

// Gets updated seat data from the billing backend
// If the user is on a free/trial/enterprise plan, they won't have a customer ID,
// so we just return a response full of zeroes.
export const useSeats = (): [GetSeatsState, () => Promise<void>] => {
  const [seats, setSeats] = useState<GetSeatsState>({ loading: true });

  const fetchSeats = async () => {
    const [fetchSeatsRequest, cancelSubscriptionsRequest] = billing.getSeats();
    try {
      const { data } = await fetchSeatsRequest;
      setSeats({ loading: false, success: true, data });
    } catch (error) {
      console.error(error);
      setSeats({
        loading: false,
        success: false,
        error: "Unable to fetch seats.",
      });
    }
  };

  useEffect(() => {
    fetchSeats();
  }, [setSeats]);

  return [seats, fetchSeats];
};
