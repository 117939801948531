interface ComponentForNav {
  _id: string;
  name: string;
}

interface ComponentForContent {
  _id: string;
  name: string;
  text: string;
}

interface Block<T> {
  block_name: string;
  block_text: T[];
}

interface Group<T> {
  group_name: string;
  blocks: Block<T>[];
  other_text: T[];
}

type NestedComponents<T> = Group<T & { page: number | null }>[];

export const nestComponents = <T extends ComponentForNav | ComponentForContent>(
  components: T[],
  pageSize?: number
): NestedComponents<T> => {
  const ungrouped: Group<T & { page: number | null }> = {
    group_name: "_NOT_IN_GROUP",
    other_text: [],

    /**
     * Apparently this property isn't actually used anywhere, but I'm a little scared
     * to straight up remove it since there could still be some sort of legacy reference
     * that expects it to be defined.
     */
    blocks: [],
  };

  const groupMap: { [groupName: string]: Group<T & { page: number | null }> } = {};

  components.forEach((c, index) => {
    const component = {
      ...c,
      page: typeof pageSize === "number" ? Math.floor(index / pageSize) : null,
    };

    const parts = component.name.split("/");

    // Component is not in a group
    if (parts.length === 1) {
      ungrouped.other_text.push(component);
      return;
    }

    const groupName = parts[0];
    groupMap[groupName] ??= {
      group_name: groupName,
      blocks: [],
      other_text: [],
    };
    const group = groupMap[groupName];

    // Component is in a group but not in a block
    if (parts.length === 2) {
      group.other_text.push(component);
      return;
    }

    // Component is in a group in a block
    const blockName = parts[1];
    const existingBlock = group.blocks.find((b) => b.block_name === blockName);
    if (existingBlock) {
      existingBlock.block_text.push(component);
      return;
    }

    group.blocks.push({
      block_name: blockName,
      block_text: [component],
    });
  });

  const result = Object.values(groupMap);

  result.push(ungrouped);

  return result;
};
