import classnames from "classnames";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";

const CounterInput = ({ min, count, onCountChange, className = "" }) => {
  const [input, setInput] = useState(count);

  // input separated from the count in order to handle empty string state
  useEffect(() => {
    setInput(count);
  }, [count]);

  const decrement = () => {
    if (count !== min) return count - 1;
    return count;
  };

  const onInputChange = (e) => {
    if (!e.target.value) {
      setInput("");
      return;
    }
    if (isNaN(e.target.value)) {
      return;
    }
    const num = parseInt(e.target.value);
    setInput(e.target.value);
    if (num < min) {
      onCountChange(min);
    } else {
      onCountChange(num);
    }
  };
  const onInputBlur = (e) => {
    if (e.target.value.length === 0) {
      onCountChange(min);
    }
  };

  return (
    <div className={classnames([style.counter, className])}>
      <div
        className={classnames({
          [style.button]: true,
          [style.disabled]: count === min,
        })}
        onClick={() => onCountChange(decrement())}
      >
        -
      </div>
      <input className={style.input} type="text" value={input} onChange={onInputChange} onBlur={onInputBlur} />
      <div className={style.button} onClick={() => onCountChange(count + 1)}>
        +
      </div>
    </div>
  );
};

export default CounterInput;
