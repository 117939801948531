import Add from "@mui/icons-material/Add";
import { IFApiIdCasingAdjustment, IFApiIdReplacementCharacter } from "@shared/types/Workspace";
import classNames from "classnames";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import style from "./IDRuleComponents.module.css";

const replacementCharacters: {
  label: string;
  value: IFApiIdReplacementCharacter;
}[] = [
  {
    label: "-",
    value: "-",
  },
  {
    label: "_",
    value: "_",
  },
  {
    label: ".",
    value: ".",
  },
  {
    label: '"" (empty string)',
    value: "",
  },
];

const casingAdjustments: {
  label: string;
  value: IFApiIdCasingAdjustment;
}[] = [
  {
    label: "no change in casing",
    value: null,
  },
  {
    label: "camelCase",
    value: "camel",
  },
  {
    label: "PascalCase",
    value: "pascal",
  },
  {
    label: "lowercase",
    value: "lower",
  },
  {
    label: "UPPERCASE",
    value: "upper",
  },
];

interface CasingRuleProps {
  baseTabIndex: number;
  activeCasingValue: IFApiIdCasingAdjustment;
  onCasingClick: (casingAdjustment: IFApiIdCasingAdjustment) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const CasingRule = (props: CasingRuleProps) => {
  const { activeCasingValue, onCasingClick, baseTabIndex } = props;

  return (
    <section className={classNames(style.graySection, style.flexBaseline)}>
      <span>Casing </span>
      <div className={style.inlineButtons}>
        {casingAdjustments.map((option, index) => (
          <button
            key={`casing-adjustment-${option.value}`}
            className={classNames({
              [style.blueButton]: true,
              [style.active]: option.value === activeCasingValue,
            })}
            onClick={onCasingClick(option.value)}
            style={{ marginBottom: 4 }}
            tabIndex={baseTabIndex + index}
          >
            {option.label}
          </button>
        ))}
      </div>
    </section>
  );
};

interface AcceptedCharsRuleProps {
  baseTabIndex: number;
  pattern: string | null;
  patternIsValid: boolean;
  onPatternChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AcceptedCharsRule = (props: AcceptedCharsRuleProps) => {
  const { pattern, patternIsValid, onPatternChange } = props;

  return (
    <section className={classNames([style.flexSection, style.graySection])}>
      <span className={style.acceptedCharactersContainer}>
        Accepted characters{" "}
        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions" target="_blank">
          (regex format)
        </a>{" "}
      </span>
      <Form.Control
        type="text"
        placeholder="Enter a regular expression"
        value={pattern ?? ""}
        onChange={onPatternChange}
        className={classNames({
          [style.inputError]: !patternIsValid,
        })}
        tabIndex={1}
      />
    </section>
  );
};

interface FwdSlashReplaceRuleProps {
  baseTabIndex: number;
  activeReplacementCharacter: IFApiIdReplacementCharacter;
  onReplacementClick: (
    replacementCharacter: IFApiIdReplacementCharacter
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FwdSlashReplaceRule = (props: FwdSlashReplaceRuleProps) => {
  const { activeReplacementCharacter, onReplacementClick, baseTabIndex } = props;

  return (
    <section className={style.graySection}>
      Replace <span className={style.orangeButton}>/</span> with{" "}
      <span className={style.inlineButtons}>
        {replacementCharacters.map((option, index) => (
          <button
            key={`slash-replace-${option.value}`}
            className={classNames({
              [style.blueButton]: true,
              [style.active]: option.value === activeReplacementCharacter,
            })}
            onClick={onReplacementClick(option.value)}
            tabIndex={baseTabIndex + index}
          >
            {option.label}
          </button>
        ))}
      </span>
    </section>
  );
};

interface SpaceReplaceRuleProps {
  baseTabIndex: number;
  activeReplacementCharacter: IFApiIdReplacementCharacter;
  onReplacementClick: (
    replacementCharacter: IFApiIdReplacementCharacter
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SpaceReplacementRule = (props: SpaceReplaceRuleProps) => {
  const { activeReplacementCharacter, onReplacementClick, baseTabIndex } = props;

  return (
    <section className={style.graySection}>
      Replace <span className={style.orangeButton}>' ' (space)</span> with{" "}
      <span className={style.inlineButtons}>
        {replacementCharacters.map((option, index) => (
          <button
            key={`space-replace-${option.value}`}
            className={classNames({
              [style.blueButton]: true,
              [style.active]: option.value === activeReplacementCharacter,
            })}
            onClick={onReplacementClick(option.value)}
            tabIndex={baseTabIndex + index}
          >
            {option.label}
          </button>
        ))}
      </span>
    </section>
  );
};

interface IDTemplateProps {
  template: string;
  onTemplateChange: (newTemplate: string) => void;
}

const templatePartsMap = {
  pageName: "Page Name",
  groupName: "Group Name",
  blockName: "Block Name",
  text: "Text",
  projectName: "Project Name",
};

export const IDTemplate = (props: IDTemplateProps) => {
  const { template, onTemplateChange } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  // The template has a form like {{groupName}}{{blockName}}{{text}}. We strip out
  // any whitespace, then map the parts to their human-readable names.
  const parseTemplateParts = (template: string) =>
    template
      .replace(/\s/g, "")
      .split(/\{\{(.*?)\}\}/)
      .filter(Boolean)
      .map((part) => templatePartsMap[part] ?? part);

  const handleAddTemplatePart = (part: string) => {
    const newTemplate = template + `{{${part}}}`;
    onTemplateChange(newTemplate);
  };

  const handleRemoveTemplatePart = (part: string) => {
    const newTemplate = template.replace(`{{${part}}}`, "");
    onTemplateChange(newTemplate);
  };

  const longNameToShortName = (name: string) => {
    const firstChar = name.slice(0, 1);
    return firstChar.toLowerCase() + name.replace(" ", "").slice(1);
  };

  useEffect(
    function addClickOutsideListener() {
      if (!showDropdown) {
        return;
      }

      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [showDropdown, setShowDropdown]
  );

  // This is a list of all the parts that are not already used in the template
  const templateOptions = useMemo(() => {
    const templateParts = parseTemplateParts(template);
    const templatePartsSet = new Set(templateParts);

    return Object.values(templatePartsMap).filter((part) => !templatePartsSet.has(part));
  }, [template]);

  return (
    <div ref={containerRef} className={style.idTemplateWrapper}>
      <div className={style.idTemplateSelect}>
        {parseTemplateParts(template).map((part, index) => (
          <div
            key={`template-part-${index}`}
            className={style.templatePart}
            onClick={() => handleRemoveTemplatePart(longNameToShortName(part))}
          >
            {part}
          </div>
        ))}
        <button className={style.addButton} onClick={() => setShowDropdown((t) => !t)}>
          <Add className={style.addIcon} />
        </button>
      </div>

      {showDropdown && (
        <div className={style.dropdown}>
          <h3>Insert Placeholders</h3>
          <div className={style.partsArea}>
            {templateOptions.map((option, i) => (
              <div
                key={`template-part-${i}`}
                className={style.templatePart}
                onClick={() => handleAddTemplatePart(longNameToShortName(option))}
              >
                {option}
              </div>
            ))}
            {templateOptions.length === 0 && <div className={style.noOptions}>All template options used!</div>}
          </div>
        </div>
      )}
    </div>
  );
};

interface PlaceholderSeparatorRuleProps {
  baseTabIndex: number;
  activeSeparator: IFApiIdReplacementCharacter;
  onSeparatorClick: (separator: IFApiIdReplacementCharacter) => void;
}

export const PlaceholderSeparatorRule = (props: PlaceholderSeparatorRuleProps) => {
  const { activeSeparator, onSeparatorClick, baseTabIndex } = props;

  return (
    <section className={style.graySection}>
      Join placeholders with{" "}
      <span className={style.inlineButtons}>
        {replacementCharacters.map((option, index) => (
          <button
            key={`separator-${option.value}`}
            className={classNames({
              [style.blueButton]: true,
              [style.active]: option.value === activeSeparator,
            })}
            onClick={() => onSeparatorClick(option.value)}
            tabIndex={baseTabIndex + index}
          >
            {option.label}
          </button>
        ))}
      </span>
    </section>
  );
};

interface MaxIDLengthRuleProps {
  baseTabIndex: number;
  activeMaxLength: number;
  onMaxLengthChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const MaxIDLengthRule = (props: MaxIDLengthRuleProps) => {
  const { activeMaxLength, onMaxLengthChange, baseTabIndex } = props;

  return (
    <section className={classNames(style.graySection, style.flexSection)}>
      <span style={{ flexShrink: 0 }}>Maximum ID length (Max. 200)</span>
      <input type="number" className={style.maxLengthInput} value={activeMaxLength} onChange={onMaxLengthChange} />
    </section>
  );
};
