import { z } from "zod";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";

// MARK: - General Permissions

/**
 * All permissions actions should follow the following naming convention:
 * `scope:action`
 * where scope could be a resource name, feature name, or any other identifier
 * and action is the action that the user is allowed to perform on that scope.
 *
 * Examples:
 * Say you wanted to add a new permission to allow users to edit
 * their own user profile. I good name for this permission would be:
 * `profile:edit`
 *
 * If a permission already existed for editing profiles in another context, you
 * could name it something like:
 * `user_profile:edit`
 *
 * Another example would be if you wanted to add a permission to allow users to
 * comment on a variant. You could name it something like:
 * `variant:comment`
 *
 * If you wanted to add a permission that allowed users to edit all variants on a workspace,
 * you could name it something like:
 * `variant:edit_all`
 */

// MARK: - Permission Helpers

export const ZResourceType = z.enum(["project_folder", "component_folder", "variant_folder", "variable_folder"]);
export type IResourceType = z.infer<typeof ZResourceType>;
export type IFResourceType = FrontendSchema<IResourceType>;

function generatePermission<T extends IResourceType>(type: T) {
  let computedActions = [`${type}:edit`, `${type}:comment`] as const;

  const ZActionEnum = z.enum(computedActions);

  const ZPermissionAction = z.object({
    action: ZActionEnum,
  });

  const ZPermission = z.object({
    resource_id: z.string(),
    resource_type: z.literal(type),
    // access: z.array(ZPermissionAction),
  });
  return {
    actionEnum: ZActionEnum,
    permissionAction: ZPermissionAction,
    permission: ZPermission,
  };
}

// MARK: - Resource Permissions

export const {
  actionEnum: ZComponentFolderPermissionActionEnum,
  permissionAction: ZComponentFolderPermissionAction,
  permission: ZComponentFolderPermission,
} = generatePermission("component_folder");

export const {
  actionEnum: ZProjectFolderPermissionActionEnum,
  permissionAction: ZProjectFolderPermissionAction,
  permission: ZProjectFolderPermission,
} = generatePermission("project_folder");

export const {
  actionEnum: ZVariantFolderPermissionActionEnum,
  permissionAction: ZVariantFolderPermissionAction,
  permission: ZVariantFolderPermission,
} = generatePermission("variant_folder");

export const {
  actionEnum: ZVariableFolderPermissionActionEnum,
  permissionAction: ZVariableFolderPermissionAction,
  permission: ZVariableFolderPermission,
} = generatePermission("variable_folder");

export const ZResourcePermission = z.object({
  resource_id: z.string(),
  resource_type: z.union([
    ZProjectFolderPermission.shape.resource_type,
    ZComponentFolderPermission.shape.resource_type,
    ZVariantFolderPermission.shape.resource_type,
    ZVariableFolderPermission.shape.resource_type,
  ]),
  access: z.array(
    z.union([
      ZProjectFolderPermissionAction,
      ZComponentFolderPermissionAction,
      ZVariantFolderPermissionAction,
      ZVariableFolderPermissionAction,
    ])
  ),
});

// Export types for each resource permission

export type IProjectFolderPermissionActionEnum = z.infer<typeof ZProjectFolderPermissionActionEnum>;
export type IComponentFolderPermissionActionEnum = z.infer<typeof ZComponentFolderPermissionActionEnum>;
export type IVariantFolderPermissionActionEnum = z.infer<typeof ZVariantFolderPermissionActionEnum>;
export type IVariableFolderPermissionActionEnum = z.infer<typeof ZVariableFolderPermissionActionEnum>;

type IResourcePermission = z.infer<typeof ZResourcePermission>;
export type IFResourcePermission = FrontendSchema<IResourcePermission>;

const ZResourcePermissionActionEnum = z.union([
  ZProjectFolderPermissionActionEnum,
  ZComponentFolderPermissionActionEnum,
  ZVariantFolderPermissionActionEnum,
  ZVariableFolderPermissionActionEnum,
]);

export type IResourcePermissionActionEnum = z.infer<typeof ZResourcePermissionActionEnum>;

export const ZResourcePermissionAction = z.union([
  ZProjectFolderPermissionAction,
  ZComponentFolderPermissionAction,
  ZVariantFolderPermissionAction,
  ZVariableFolderPermissionAction,
]);
export type IResourcePermissionAction = z.infer<typeof ZResourcePermissionAction>;

// MARK: - General Permissions

export const ZGeneralPermissionsActionEnum = z.enum([
  "billing:edit",
  "users:delete",
  "users:edit:admin",
  "users:edit:editor",
  "users:edit:commenter",
  "project:comment:all",
  "project_folder:create",
  "project_folder:edit_all",
  "project_folder:comment_all",
  "component_folder:create",
  "component_folder:edit_all",
  "component_folder:comment_all",
  "variant_folder:create",
  "variant_folder:edit_all",
  "variant_folder:comment_all",
  "variable_folder:create",
  "variable_folder:edit_all",
  "variable_folder:comment_all",
  "permissions:edit",
]);
export type IGeneralPermissionsActionEnum = z.infer<typeof ZGeneralPermissionsActionEnum>;

export const ZGeneralPermission = z.object({
  action: ZGeneralPermissionsActionEnum,
});

// MARK: Permission Groups

export const ZPermissionGroup = z.object({
  _id: ZObjectId,
  built_in: z.boolean(),
  invite_only: z.boolean().optional(),
  name: z.string(),
  workspace_id: ZObjectId,
  permissions: z.object({
    resources: z.array(ZResourcePermission),
    general: z.array(ZGeneralPermission),
  }),
});

export type IPermissionGroup = z.infer<typeof ZPermissionGroup>;
export type IBPermissionGroup = BackendSchema<IPermissionGroup>;
export type IFPermissionGroup = FrontendSchema<IPermissionGroup>;
