import {
  IFApiIdCasingAdjustment,
  IFApiIdReplacementCharacter,
  IFComponentsApiIdGenerationConfig,
} from "@shared/types/Workspace";
import { RecursivePartial } from "@shared/types/lib";
import { createApiIdGenerator } from "@shared/utils/apiId";
import { tryToParseRegex } from "@shared/utils/apiId/lib";
import classNames from "classnames";
import React, { useMemo, useReducer, useState } from "react";
import Form from "react-bootstrap/Form";

import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import ModalBase, { ModalBody, ModalFooter } from "../shared/ModalBase";

import { regexExpressionWrapper } from "@shared/utils/apiId/nameToApiId";
import { ApiIdDumbComponent } from "../api-id/ApiIdDumbComponent";
import ConfirmationModal from "../shared/confirmation-modal";
import style from "./ComponentApiIdGenerationModal.module.css";
import { AcceptedCharsRule, CasingRule, FwdSlashReplaceRule, SpaceReplacementRule } from "./IDRuleComponents";

interface Props {
  initialConfig: IFComponentsApiIdGenerationConfig;
  onHide: () => void;
  onSave: (
    config: IFComponentsApiIdGenerationConfig,
    configHasChanged: boolean,
    updateExistingIds: boolean
  ) => void | Promise<void>;
}

interface State {
  loading: boolean;
  updateExistingIds: boolean;
  testComponentName: string;
  apiIdGeneration: IFComponentsApiIdGenerationConfig;
}

type StateAction = { type: "update"; state: RecursivePartial<State> } | { type: "toggleLoading" };

const useApiIdGenerationModalState = (props: Props) => {
  const [state, dispatch] = useReducer(
    (state: State, action: StateAction) => {
      switch (action.type) {
        case "update": {
          return {
            ...state,
            ...action.state,
            apiIdGeneration: {
              ...state.apiIdGeneration,
              ...action.state.apiIdGeneration,
            },
          };
        }
        case "toggleLoading":
          return { ...state, loading: !state.loading };
        default:
          throw new Error("Unsupported action");
      }
    },
    {
      loading: false,
      updateExistingIds: false,
      testComponentName: "Mobile/Bank Transfers/CTA",
      apiIdGeneration: props.initialConfig,
    }
  );

  return [state, dispatch] as const;
};

export const ComponentApiIdGenerationModal = (props: Props) => {
  const [state, dispatch] = useApiIdGenerationModalState(props);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const acceptedCharactersRegexIsValid = useMemo(
    () => tryToParseRegex(regexExpressionWrapper(state.apiIdGeneration.acceptedCharsPattern ?? "", [])) !== null,
    [state.apiIdGeneration.acceptedCharsPattern]
  );

  const configHasChanged = useMemo(
    () =>
      state.apiIdGeneration.acceptedCharsPattern !== props.initialConfig.acceptedCharsPattern ||
      state.apiIdGeneration.casingAdjustment !== props.initialConfig.casingAdjustment ||
      state.apiIdGeneration.slashReplacement !== props.initialConfig.slashReplacement ||
      state.apiIdGeneration.spaceReplacement !== props.initialConfig.spaceReplacement,
    [state.apiIdGeneration]
  );

  const canSave = (configHasChanged || state.updateExistingIds) && acceptedCharactersRegexIsValid;

  const apiIdGenerator = useMemo(
    () => createApiIdGenerator({ config: state.apiIdGeneration }),
    [state.apiIdGeneration]
  );

  const apiId = useMemo(
    () => apiIdGenerator.generate(state.testComponentName, "component_"),
    [state.testComponentName, apiIdGenerator]
  );

  const handleOnHide = () => {
    if (configHasChanged || state.updateExistingIds) {
      setShowConfirmationModal(true);
    } else props.onHide();
  };

  const onReplacementClick =
    (key: "spaceReplacement" | "slashReplacement") =>
    (replacementCharacter: IFApiIdReplacementCharacter) =>
    (e: React.MouseEvent<HTMLButtonElement>) =>
      dispatch({
        type: "update",
        state: {
          apiIdGeneration: {
            [key]: replacementCharacter,
          },
        },
      });

  const onCasingClick = (casingAdjustment: IFApiIdCasingAdjustment) => (e: React.MouseEvent<HTMLButtonElement>) =>
    dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          casingAdjustment,
        },
      },
    });

  const onAcceptedCharsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const acceptedCharsPattern = e.target.value;
    return dispatch({
      type: "update",
      state: {
        apiIdGeneration: {
          acceptedCharsPattern,
        },
      },
    });
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({
      type: "update",
      state: {
        updateExistingIds: e.target.checked,
      },
    });

  const onSaveClick = async () => {
    dispatch({ type: "toggleLoading" });
    await props.onSave(state.apiIdGeneration, configHasChanged, state.updateExistingIds);
  };

  const onTestComponentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const testComponentName = e.target.value;
    return dispatch({
      type: "update",
      state: {
        testComponentName,
      },
    });
  };

  return (
    <>
      {!showConfirmationModal && (
        <ModalBase
          onHide={handleOnHide}
          title="Component ID Rules"
          className={style.container}
          titleClassName={style.containerTitle}
        >
          <ModalBody className={style.body}>
            <div>
              <p>A component’s ID is generated based on the component’s name.</p>

              <SpaceReplacementRule
                activeReplacementCharacter={state.apiIdGeneration.spaceReplacement}
                baseTabIndex={0}
                onReplacementClick={onReplacementClick("spaceReplacement")}
              />
              <FwdSlashReplaceRule
                activeReplacementCharacter={state.apiIdGeneration.slashReplacement}
                baseTabIndex={0}
                onReplacementClick={onReplacementClick("slashReplacement")}
              />
              <AcceptedCharsRule
                baseTabIndex={0}
                pattern={state.apiIdGeneration.acceptedCharsPattern}
                patternIsValid={acceptedCharactersRegexIsValid}
                onPatternChange={onAcceptedCharsInputChange}
              />
              <CasingRule
                activeCasingValue={state.apiIdGeneration.casingAdjustment}
                baseTabIndex={0}
                onCasingClick={onCasingClick}
              />
            </div>
          </ModalBody>
          <div className={style.ruleTestingContainer}>
            <p>To test the rules you have selected, edit the example component name to see the resulting ID.</p>
            <section className={style.flexSection}>
              <label htmlFor="test-component-name">Component name</label>
              <Form.Control
                id="test-component-name"
                type="text"
                placeholder="Enter a component name"
                value={state.testComponentName}
                onChange={onTestComponentNameChange}
                tabIndex={0}
              />
            </section>
            <section className={style.flexSection}>
              <label>Resulting ID</label>
              <ApiIdDumbComponent value={apiId} className={style.apiId} />
            </section>
          </div>
          <ModalFooter className={style.footer}>
            <div className={style.footerInner}>
              <div className={style.footerCheckboxContainer}>
                <Form.Check
                  inline
                  type="checkbox"
                  id="custom-switch"
                  className={style.footerCheckbox}
                  checked={state.updateExistingIds}
                  onChange={onCheckboxChange}
                />
                <label htmlFor="custom-switch">Upon save, update all existing component IDs using updated rules</label>
              </div>
              <div className={style.footerBottom}>
                <span
                  className={classNames({
                    [style.errorMessage]: true,
                    [style.hidden]: acceptedCharactersRegexIsValid,
                  })}
                >
                  Please fix errors prior to saving.
                </span>
                <div className={style.footerButtons}>
                  <ButtonSecondary text="Cancel" onClick={handleOnHide} className={style.cancelButton} />
                  <ButtonPrimary text="Save" disabled={!canSave} onClick={onSaveClick} className={style.submitButton} />
                </div>
              </div>
            </div>
          </ModalFooter>
        </ModalBase>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Unsaved Changes"
          body="You have unsaved changes to your API ID configuration. Are you sure you want to close?"
          onPrimary={props.onHide}
          actionPrimary="Close"
          onSecondary={() => setShowConfirmationModal(false)}
          actionSecondary="Go Back"
        />
      )}
    </>
  );
};
