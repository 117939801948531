import React from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";
import Select, { SelectOption } from "../../molecules/Select";
import { StatusIcon } from "../TextItemMetadata/icons";

interface IStatusSelectProps {
  size?: "base" | "small";
  status: ActualComponentStatus | "MIXED";
  setStatus: (status: ActualComponentStatus) => void;
}

const StatusSelect = React.memo(function StatusSelect(props: IStatusSelectProps) {
  return (
    <Select
      size={props.size ?? "base"}
      value={props.status}
      iconSize="xxxs"
      onChange={(value) => props.setStatus(value as ActualComponentStatus)}
      leadingIcon={<StatusIcon status={props.status} />}
    >
      <SelectOption leadingIcon={<StatusIcon status="NONE" />} iconSize="xxxs" label="None" value="NONE" />
      <SelectOption leadingIcon={<StatusIcon status="WIP" />} iconSize="xxxs" label="Work in Progress" value="WIP" />
      <SelectOption
        leadingIcon={<StatusIcon status="REVIEW" />}
        iconSize="xxxs"
        label="Ready for Review"
        value="REVIEW"
      />
      <SelectOption leadingIcon={<StatusIcon status="FINAL" />} iconSize="xxxs" label="Final" value="FINAL" />
      <SelectOption hidden leadingIcon={<StatusIcon status="MIXED" />} iconSize="xxxs" label="Mixed" value="MIXED" />
    </Select>
  );
});

export default StatusSelect;
