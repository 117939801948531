import React from "react";

interface ResyncError {
  title: string;
  body: string | JSX.Element | JSX.Element[];
  autoHide?: boolean;
  displayAccountLink?: boolean;
}

export const projectDeleted: ResyncError = {
  title: "⚠️ Project has been deleted",
  body: "Looks like this project has been deleted from Ditto.",
  autoHide: true,
};

export const pageFailure = (
  pageNames: string[],
  opts: {
    ulStyle?: React.CSSProperties;
  } = {}
): ResyncError => ({
  title: "⚠️ Pages too large",
  body: (
    <div>
      These pages in your Figma file contain too much content to sync:
      <ul style={opts.ulStyle}>
        {pageNames.map((pageName, index) => (
          <li key={index}>{pageName}</li>
        ))}
      </ul>
      <div>Please divide them into smaller pages and try again.</div>
    </div>
  ),
  autoHide: false,
});

export const autoImportFailure = (
  pageNames: string[],
  opts: {
    ulStyle?: React.CSSProperties;
  } = {}
): ResyncError => ({
  title: "⚠️ Pages too large",
  body: (
    <div>
      These pages in your Figma file contain too much content to automatically import:
      <ul style={opts.ulStyle}>
        {pageNames.map((pageName, index) => (
          <li key={index}>{pageName}</li>
        ))}
      </ul>
      <div>
        We've disabled the automatic import for those pages. You can now try to resync again. If you'd like to enable
        auto import, please divide these pages into smaller pages and then reenable the setting.
      </div>
    </div>
  ),
  autoHide: false,
});

export const toFetchLatest: ResyncError = {
  title: "⚠️ Error resyncing project",
  body: "To fetch the latest updates to your project, please ",
  displayAccountLink: true,
  autoHide: false,
};

export const fileNotFound: ResyncError = {
  title: "⚠️ Error resyncing project",
  body: "We can't seem to find this Figma file. Make sure this file hasn't been deleted and that you have access to it.",
  autoHide: false,
};

export const errorResyncing: ResyncError = {
  title: "⚠️ Error resyncing project",
  body: "There was an error resyncing your Figma file. If you have a large Figma file, try resyncing from the plugin.",
  autoHide: false,
};

export const tooManyRequests: ResyncError = {
  title: "⚠️ Error resyncing project",
  body: "We've made too many requests to Figma in the last few minutes. Please wait 5 minutes and try resyncing again.",
  autoHide: false,
};

export const reduceFileSize: ResyncError = {
  title: "⚠️ Error resyncing project",
  body: "Please reduce the number or size of the images in your file and try resyncing again",
  autoHide: false,
};

export const noFigmaAccountConnected: ResyncError = {
  title: "⚠️ No Figma account connected",
  body: "To sync your edits to Figma, you'll need to ",
  displayAccountLink: true,
  autoHide: false,
};

export const generic: ResyncError = {
  title: "⚠️ Resync error",
  body: "We had issues resyncing this file. Please try again in 5 minutes.",
  autoHide: false,
};

export const errorByStatus = {
  403: toFetchLatest,
  404: fileNotFound,
  408: errorResyncing,
  429: tooManyRequests,
  500: reduceFileSize,
};
