import classnames from "classnames";
import React from "react";

import Spinner from "react-bootstrap/Spinner";
import style from "./style.module.css";

type NativeButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface ButtonPrimaryProps extends NativeButtonProps {
  buttonStyles?: React.CSSProperties;
  text: string | JSX.Element | null;
  onClick?: (e?: React.FormEvent) => void;
  loading?: boolean;
  loadingPosition?: "left" | "right";
  loadingStyle?: React.CSSProperties;
}

const ButtonPrimary = (props: ButtonPrimaryProps) => {
  const { buttonStyles, text, disabled, onClick, className, loading, loadingPosition, id, ...otherButtonProps } = props;

  return (
    <button
      className={classnames([style.primary, style.button, className], {
        [style.loadingLeft]: loadingPosition === "left",
      })}
      disabled={disabled}
      onClick={onClick}
      style={buttonStyles}
      {...otherButtonProps}
    >
      {loading && loadingPosition === "left" && (
        <Spinner animation="border" variant="secondary" className={style.loadingSpinner} style={props.loadingStyle} />
      )}
      {text}
      {loading && (loadingPosition === "right" || loadingPosition !== "left") && (
        <Spinner animation="border" variant="secondary" className={style.loadingSpinner} style={props.loadingStyle} />
      )}
    </button>
  );
};

export default ButtonPrimary;
