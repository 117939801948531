import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";

interface Props {
  onHide: () => void;
  title: React.ReactNode;
  fullWidth: boolean;
  body: React.ReactNode;
  footer: React.ReactNode;
}
const Modal = (props: Props) => {
  const { onHide, title, fullWidth, body, footer } = props;
  return (
    <BootstrapModal
      show
      className={style.modal}
      dialogClassName={classnames({
        [style.dialog]: true,
        [style.width45]: !fullWidth,
        [style.fullWidth]: fullWidth,
      })}
      backdropClassName={style.backdrop}
      onHide={onHide}
      centered
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>{title}</BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>{body}</BootstrapModal.Body>
      {footer && <BootstrapModal.Footer className={style.footer}>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

export default Modal;
