import React, { useEffect, useRef, useState } from "react";

const MIN_WIDTH = 244;
const DEFAULT_WIDTH = 335;
const MAX_WIDTH = 400;
const EDGE_WIDTH = 10;

interface IResizableWidthContainer
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: React.ReactNode;
  defaultWidth?: number;
  edgeToResize?: "left" | "right";
  edgeWidth?: number;
  highlight?: boolean;
  maxWidth?: number;
  minWidth?: number;
  onShouldShowCursorChange?: (shouldShowCursor: boolean) => void;
  persistKey?: string;
}

const ResizableWidthContainer = (props: IResizableWidthContainer) => {
  const {
    defaultWidth = DEFAULT_WIDTH,
    edgeToResize = "right",
    edgeWidth = EDGE_WIDTH,
    highlight = true,
    maxWidth = MAX_WIDTH,
    minWidth = MIN_WIDTH,
    onShouldShowCursorChange,
    persistKey,
  } = props;

  const [isResizing, setIsResizing] = useState(false);
  const [isHoveringOverEdge, setIsHoveringOverEdge] = useState(false);
  const [sidebarWidth, _setSidebarWidth] = useState<number>(defaultWidth);

  useEffect(() => {
    if (persistKey) {
      const width = localStorage.getItem(persistKey);
      const parsedWidth = parseInt(width || "");
      if (isNaN(parsedWidth) || parsedWidth < minWidth || parsedWidth > maxWidth) {
        localStorage.removeItem(persistKey);
      } else {
        _setSidebarWidth(parsedWidth);
      }
    }
  }, []);

  const setSidebarWidth = (width: number) => {
    _setSidebarWidth(width);
    if (persistKey) {
      localStorage.setItem(persistKey, width.toString());
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const shouldShowCursorRef = useRef(false);

  useEffect(
    function handleShowCursorChange() {
      const shouldShowCursor = isHoveringOverEdge || isResizing;
      if (shouldShowCursor !== shouldShowCursorRef.current) {
        shouldShowCursorRef.current = shouldShowCursor;
        onShouldShowCursorChange?.(shouldShowCursor);
      }
    },
    [shouldShowCursorRef.current, isHoveringOverEdge, isResizing, sidebarWidth]
  );

  useEffect(
    function trackMouseActions() {
      const onMouseDown = (e: MouseEvent) => {
        if (!containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const isOnEdge =
          e.clientX >= containerRect[edgeToResize] - edgeWidth && e.clientX <= containerRect[edgeToResize] + edgeWidth;

        if (isOnEdge) {
          setIsResizing(true);
        }
      };

      const onMouseMove = (e: MouseEvent) => {
        if (!containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();

        if (isResizing) {
          const mouseX = e.pageX;
          const newWidth = mouseX - containerRect.left;
          setSidebarWidth(Math.max(Math.min(newWidth, maxWidth), minWidth));
        } else {
          const isOnEdge =
            e.clientX >= containerRect[edgeToResize] - edgeWidth &&
            e.clientX <= containerRect[edgeToResize] + edgeWidth &&
            e.clientY <= containerRect.bottom &&
            e.clientY >= containerRect.top;
          if (isOnEdge && !isHoveringOverEdge) {
            setIsHoveringOverEdge(true);
            document.documentElement.classList.add("globalResizeCursor");
          } else if (!isOnEdge && isHoveringOverEdge) {
            setIsHoveringOverEdge(false);
            document.documentElement.classList.remove("globalResizeCursor");
          }
        }
      };

      const onMouseUp = () => {
        if (isResizing) setIsResizing(false);
      };

      document.addEventListener("mousedown", onMouseDown);
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);

      // Clean up the event listeners when the component unmounts or stops resizing
      return () => {
        document.removeEventListener("mousedown", onMouseDown);
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
    },
    [isHoveringOverEdge, isResizing, sidebarWidth]
  ); // This effect depends on the isResizing state

  let styles = {
    width: `${sidebarWidth}px`,
    transition: "border-right 0.1s ease-in-out, border-left 0.1s ease-in-out",
  };

  if (isHoveringOverEdge || isResizing) {
    styles["cursor"] = "ew-resize !important";
    if (highlight) {
      styles[`border${edgeToResize === "right" ? "Right" : "Left"}`] = "1px solid #959595";
    }
  }

  const divProps = {
    ...props,
  };

  delete divProps.children;
  delete divProps.defaultWidth;
  delete divProps.edgeToResize;
  delete divProps.edgeWidth;
  delete divProps.highlight;
  delete divProps.maxWidth;
  delete divProps.minWidth;
  delete divProps.onShouldShowCursorChange;
  delete divProps.persistKey;

  return (
    <div {...divProps} style={styles} ref={containerRef}>
      {props.children}
    </div>
  );
};

export default ResizableWidthContainer;
