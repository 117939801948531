import CheckIcon from "@mui/icons-material/Check";
import classNames from "classnames";
import React, { useState } from "react";
import ContentCopyIcon from "../icons/ContentCopyIcon";
import style from "./CopyButton.module.css";

interface CopyButtonProps {
  textToCopy: string;
  color?: string;
  className?: string;
  callback?: () => void;
}

const CopyButton = (props: CopyButtonProps) => {
  const [showCheckMark, setShowCheckMark] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(props.textToCopy);
    setShowCheckMark(true);
    setTimeout(() => {
      setShowCheckMark(false);
    }, 1000);
    props.callback && props.callback();
  }

  return (
    <button
      className={classNames(style.copyButton, props.className)}
      onClick={copyToClipboard}
      style={{ color: props.color || "#357fee" }}
    >
      {showCheckMark ? <CheckIcon className={style.icon} /> : <ContentCopyIcon className={style.icon} />}
    </button>
  );
};

export default CopyButton;
