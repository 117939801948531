import { IComponentActionPreviewResponse, ZComponentActionPreviewResponse } from "@shared/types/imports";
import http from "../../../../http";
import { timedPromise } from "../utils";

export const useImportCreateRequest = () => {
  return async (
    backgroundJobEntryId: string
  ): Promise<{ success: true; data: IComponentActionPreviewResponse } | { success: false; error: unknown }> => {
    const [response] = await Promise.all([
      http.post("/ws_comp/import/create", {
        backgroundJobEntryId,
      }),
      timedPromise(),
    ]);

    if (response.status === 200) {
      const data = ZComponentActionPreviewResponse.parse(response.data);
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        error: response.data,
      };
    }
  };
};
