import { generateNameSuggestion } from "@/http/ws_comp_typed";
import { useEffect, useRef } from "react";
import useSegment from "./useSegment";

interface Props {
  textsToAutoname: string[];
}

type NameSuggestionStatus = "accepted" | "edited" | "rejected" | "not generated";

interface NameSuggestion {
  status: NameSuggestionStatus;
  suggestedName: string;
}

export interface UseAutoNameSuggestionReturnValue {
  generateSuggestionFor: (text: string) => Promise<string>;
  textToNameSuggestion: React.MutableRefObject<{
    [text: string]: NameSuggestion;
  }>;
  setStatusFor: (text: string, status: NameSuggestionStatus) => void;
  saveAnalyticsFor: (text: string) => Promise<void>;
}

// Handles the state of multiple texts to auto suggest
// names for.
const useAutoNameSuggestion = (props: Props): UseAutoNameSuggestionReturnValue => {
  const segment = useSegment();
  const textToNameSuggestion: UseAutoNameSuggestionReturnValue["textToNameSuggestion"] = useRef({});

  useEffect(
    function initializeTextToNameSuggestion() {
      const result: UseAutoNameSuggestionReturnValue["textToNameSuggestion"]["current"] = {};
      props.textsToAutoname.forEach((text) => {
        result[text] = {
          suggestedName: "",
          status: "not generated",
        };
      });

      textToNameSuggestion.current = result;
    },
    [props.textsToAutoname]
  );

  /**
   * Will automatically set the status
   */
  async function generateSuggestionFor(text: string) {
    const [request] = generateNameSuggestion({ text });
    const suggestion: string = (await request).data.name;
    textToNameSuggestion.current[text] = {
      status: "accepted",
      suggestedName: suggestion,
    };
    segment.track({
      event: `generated component name`,
      properties: {
        text,
        suggestedName: suggestion,
      },
    });
    return suggestion;
  }

  async function setStatusFor(text: string, status: NameSuggestionStatus) {
    textToNameSuggestion.current[text].status = status;
  }

  async function saveAnalyticsFor(text: string) {
    const target = textToNameSuggestion.current[text];

    if (target.status === "not generated") return;

    segment.track({
      event: `generated component name ${target.status}`,
      properties: {
        text,
        suggestedName: target.suggestedName,
      },
    });
  }

  return {
    generateSuggestionFor,
    textToNameSuggestion,
    setStatusFor,
    saveAnalyticsFor,
  };
};

export { useAutoNameSuggestion };
