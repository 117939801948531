import { deleteTextItemActionAtom } from "@/stores/Editing";
import { modalAtom } from "@/stores/Modals";
import { projectIdAtom } from "@/stores/Project";
import { designPreviewToggledAtom } from "@/stores/ProjectFiltering";
import {
  derivedSelectedTextItemsAtom,
  selectedTextItemIdsAtom,
  setSelectedTextIdsActionAtom,
} from "@/stores/ProjectSelection";
import Comments from "@/views/NS/ProjectNS/components/DetailsPanel/Comments";
import DeleteTextItem from "@/views/NS/ProjectNS/components/DetailsPanel/DeleteTextItem";
import MetadataPanel from "@/views/NS/ProjectNS/components/DetailsPanel/Metadata";
import InstanceCount from "@ds/molecules/InstanceCount";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { Suspense } from "react";

interface TextItemDetailsProps {
  className?: string;
}

const TextItemDetails = (props: TextItemDetailsProps) => {
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(designPreviewToggledAtom);
  const [selectedTextItemIds] = useAtom(selectedTextItemIdsAtom);
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const [selectedTextItems] = useAtom(derivedSelectedTextItemsAtom);
  const textItem = selectedTextItems[0];

  const projectId = useAtomValue(projectIdAtom);
  const deleteTextItemAction = useSetAtom(deleteTextItemActionAtom);

  const setModal = useSetAtom(modalAtom);

  const instanceCount = textItem.integrations?.figmaV2?.instances?.length ?? 0;

  function showDeleteTextItemModal() {
    setModal({
      headline: "Delete text item?",
      content: `The text item and all of its data will be deleted, and linked text layers
        will be unlinked. This can't be undone.`,
      actionText: "Delete",
      onAction: deleteTextItem,
      onOpenChange: (open) => {
        if (open) return;
        // cleanup logic for cancel
        setModal(null);
      },
      type: "danger",
    });
  }

  const deleteTextItem = async () => {
    if (projectId === null) {
      setModal(null);
      return;
    }

    deleteTextItemAction(selectedTextItemIds[0]);

    // clear selection state
    setSelectedTextItemIds([]);
    setModal(null);
  };

  return (
    <>
      {Boolean(instanceCount) && (
        <Suspense fallback={<div>Loading Instances...</div>}>
          {!designPreviewToggled && (
            <InstanceCount
              content={`${instanceCount} instances in design`}
              actionText="Preview"
              onActionClick={() => setDesignPreviewToggled(true)}
            />
          )}
        </Suspense>
      )}
      <Suspense fallback={<div>Loading Metadata...</div>}>
        <MetadataPanel />
      </Suspense>
      <Suspense fallback={<div>Loading Comments...</div>}>
        <Comments />
      </Suspense>

      {selectedTextItemIds.length === 1 && <DeleteTextItem onClick={showDeleteTextItemModal} />}
    </>
  );
};

export default TextItemDetails;
