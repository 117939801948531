import React, { MouseEventHandler } from "react";

import Tooltip from "@shared/frontend/Tooltip";
import classNames from "classnames";
import style from "./style.module.css";

interface IUserBubbleProps {
  itemKey: string | number;
  fullName: React.ReactNode;
  picture: string | undefined | null;
  backgroundImagePrefix?: string;
  abbreviation: string;
  className?: string;
  hideTooltip?: boolean;
  onClick?: MouseEventHandler;
}

export default function UserBubble(props: IUserBubbleProps) {
  const hasPicture = Boolean(props.picture);

  const bubbleJSX = (
    <li
      onClick={props.onClick}
      className={classNames(
        {
          [style.listItem]: true,
          [style.hasPicture]: hasPicture,
        },
        props.className
      )}
      key={props.itemKey}
      style={{
        ...(props.picture
          ? {
              backgroundImage: `${props.backgroundImagePrefix}url("${props.picture}")`,
            }
          : {}),
      }}
    >
      {!hasPicture && <span>{props.abbreviation}</span>}
    </li>
  );

  if (props.hideTooltip) {
    return bubbleJSX;
  } else {
    return (
      <Tooltip
        disabled={false}
        className={style.tooltip}
        content={<div className={style.tooltipBody}>{props.fullName}</div>}
        theme="dark"
        placement="bottom"
      >
        {bubbleJSX}
      </Tooltip>
    );
  }
}
