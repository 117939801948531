import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createEmptyRichText } from "../../../../shared/frontend/richText/plainTextToRichText";
import { RichTextInputProps } from "../../../../shared/types/RichText";
import {
  ActualComponentStatus,
  IFTextItemVariant,
  ITextItemVariableRichValue,
  ITipTapRichText,
} from "../../../../shared/types/TextItem";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import BaseCombobox, { ComboboxOption } from "../../molecules/BaseCombobox";
import LabelWrapper from "../../molecules/LabelWrapper";
import StatusSelect from "../StatusSelect";
import { FlagIcon } from "../TextItemMetadata/icons";
import style from "./index.module.css";

export interface AddVariantData extends Partial<IFTextItemVariant> {
  name?: string;
  rich_text: ITipTapRichText;
  status: ActualComponentStatus;
}

// Type to help distinguish between an update that creates and attaches a variant to a text item,
// or just attaches an existing variant to a text item
export type AddVariantUpdateType = "CREATE" | "ATTACH";

interface IAddVariantFormProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The list of existing variants in the workspace as options for the combobox.
   */
  variantOptions: ComboboxOption[];

  /**
   * Optional default selected option in the combobox.
   */
  defaultOption?: ComboboxOption;

  /**
   * Placeholder for the variant text input.
   */
  placeholder: string;

  onCancel: () => void;
  onSave: (variant: AddVariantData, updateType: AddVariantUpdateType) => void;

  /**
   * Wrapper for our TipTap RichTextInput component.
   */
  RichTextInput: (richTextInputProps: RichTextInputProps) => React.ReactNode;
}

export function AddVariantForm(props: IAddVariantFormProps) {
  const [selectedVariant, setSelectedVariant] = useState<ComboboxOption | null>(null);
  const [variantOptions, setVariantOptions] = useState<ComboboxOption[]>(props.variantOptions);
  const [status, setStatus] = useState<ActualComponentStatus>("NONE");
  const [richText, setRichText] = useState<ITipTapRichText>(createEmptyRichText());

  // Sync initial selected variant option with default option prop
  useEffect(() => {
    if (!props.defaultOption) {
      setSelectedVariant(null);
      return;
    }
    const foundOption = props.variantOptions.find((option) => option.value === props.defaultOption!.value);
    setSelectedVariant(foundOption ?? null);

    return () => {
      setSelectedVariant(null);
    };
  }, [props.defaultOption, props.variantOptions]);

  const initialVariableRichValue: ITextItemVariableRichValue = useMemo(() => {
    return {
      rich_text: createEmptyRichText(),
      plurals: [],
      variables: [],
      text: "",
      characterLimit: null,
    };
  }, []);

  const setRichTextCallback = useCallback((newRichText: ITipTapRichText) => setRichText(newRichText), [setRichText]);

  /**
   * Creates a new variant option with the given name. Flags the new option as a draft item.
   * @param newVariantName - The name of the new variant.
   */
  function onCreateNewVariantOption(newVariantName: string) {
    setVariantOptions([...variantOptions, { value: newVariantName, label: newVariantName, isDraftItem: true }]);
  }

  /**
   * Handles adding the variant to the selected text item.
   *
   * If saving a new variant (flagged with 'isDraftItem'):
   * - set the name property so backend knows to create a new variant with that name
   * - set variantId to empty string; handle optimistically creating and saving ID elsewhere
   * Otherwise, we're attaching an existing variant to a text item
   * - set the name property to be empty (we don't want to try and make a duplicate variant!)
   * - set variantId to the selected ComboboxOption's value (which should be the existing variant's ID)
   */
  function handleAddVariant() {
    const variantToAdd = {
      name: selectedVariant!.isDraftItem ? selectedVariant!.label : "",
      variantId: selectedVariant!.isDraftItem ? "" : selectedVariant!.value,
      status,
      rich_text: richText,
    };

    props.onSave(variantToAdd, selectedVariant!.isDraftItem ? "CREATE" : "ATTACH");
  }

  return (
    <div
      style={props.style}
      className={classNames(style.AddVariantFormWrapper, props.className)}
      data-testid="add-variant-form"
    >
      <Text className={style.AddVariantHeader} weight="strong">
        Add variant
      </Text>
      <div className={style.AddVariantForm}>
        <BaseCombobox
          options={variantOptions}
          selectedItem={selectedVariant}
          setSelectedItem={setSelectedVariant}
          placeholder="Choose or create variant..."
          onCreateNew={onCreateNewVariantOption}
          createNewText="Create new variant: "
        />

        {/* Variant text and metadata */}
        <div className={style.AddVariantMetadata}>
          <Text size="small" weight="strong">
            Variant text
          </Text>
          <props.RichTextInput
            initialVariableRichValue={initialVariableRichValue}
            setBaseText={setRichTextCallback}
            setPlurals={() => {}}
            setCharacterLimit={() => {}}
            characterLimit={null}
            pluralsDisabled={true}
            characterLimitDisabled={true}
            placeholder={props.placeholder}
            emptyEditorClass="emptyEditor"
          />
        </div>

        {/* Status select */}
        <LabelWrapper
          className={style.labelWrapper}
          labelFlexStart
          labelHeight={32}
          label="Status"
          leadingIcon={<FlagIcon />}
        >
          <div className={style.inputWidthWrapper}>
            <StatusSelect status={status} setStatus={setStatus} />
          </div>
        </LabelWrapper>

        {/* CTA buttons */}
        <div className={style.CTAButtons}>
          <Button size="small" level="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button disabled={selectedVariant === null} size="small" level="primary" onClick={handleAddVariant}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddVariantForm;
