import * as httpDittoProject from "@/http/dittoProject";
import * as httpVariant from "@/http/variantTyped";
import { clearSelectionActionAtom } from "@/stores/ProjectSelection";
import { IProjectBlocksUpdated, ITextItemsUpdated } from "@shared/ditto-events";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { IFDittoProjectBlockUpdate } from "@shared/types/DittoProject";
import { ITextItemPopulatedComments } from "@shared/types/TextItem";
import { IFAction } from "@shared/types/figmaSync";
import { IFDittoBlockData, IFDittoProjectData } from "@shared/types/http/DittoProject";
import { assertUnreachable } from "@shared/utils/assertUnreachable";
import logger from "@shared/utils/logger";
import { atom, createStore, getDefaultStore } from "jotai";
import { derive, soon, soonAll } from "jotai-derive";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { userAtom } from "./Auth";
import { designPreviewToggledAtom, searchAtom, selectedFiltersAtomFamily } from "./ProjectFiltering";
// MARK: - Source Atoms

const _projectIdAtom = atom<string | null>(null);

export const projectStoreAtom = atom(getDefaultStore());

/**
 * The source of truth for the project Id. This atom should be set when the user
 * navigates to a project.
 */
export const projectIdAtom = atom(
  (get) => get(_projectIdAtom),
  (get, set, newProjectId: string | null) => {
    set(_projectIdAtom, newProjectId);

    if (newProjectId) {
      set(projectStoreAtom, createStore());
      return;
    }

    set(projectStoreAtom, getDefaultStore());
  }
);

export const initializeProjectIdActionAtom = atom(null, async (get, set, projectId: string) => {
  set(projectIdAtom, projectId);
  const store = get(projectStoreAtom);
  store.set(projectIdAtom, projectId);
});

export const { familyAtom: textItemFamilyAtom, resetAtom: resetTextItemFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ITextItemPopulatedComments>({
    storeAtom: projectStoreAtom,
    asyncFetchRequest: async (get, ids) => {
      const [request] = httpDittoProject.getTextItems({
        ids,
        projectId: get(projectIdAtom)!,
      });
      const response = await request;

      return response.data;
    },
    getId: (item) => item._id,
    debugPrefix: "Text Item",
  });

/**
 * The source of truth for block metadata. You should be fetching/updating blocks
 * through this atom to ensure that the UI updates correctly.
 *
 * Note: this is *not* the source of truth for which text items belong in which blocks! Project structure is solely
 * managed by the project atom, and each text item also has a blockId field that is used to determine which block it
 * belongs in, but that information does not live in this atom.
 *
 * This atom should be consumed like a mix between a Jotai [FamilyAtom](https://jotai.org/docs/utilities/family) and [AtomWithReset](https://jotai.org/docs/utilities/resettable#atomwithreset).
 */
export const { familyAtom: blockFamilyAtom, resetAtom: resetBlockFamilyAtomActionAtom } = batchedAsyncAtomFamily({
  storeAtom: projectStoreAtom,
  asyncFetchRequest: async (get, ids) => {
    const [request] = httpDittoProject.getBlocks({
      ids,
      projectId: get(projectIdAtom)!,
    });
    const response = await request;

    return response.data;
  },
  getId: (item) => item._id,
  debugPrefix: "Block",
});

/**
 * Fetches a project by its Id.
 */
async function fetchProjectById(args: {
  projectId: string;
  projectContentSearchQuery: string;
  statuses?: string[];
  tags?: string[];
  assignees?: string[];
}) {
  const { projectId, projectContentSearchQuery, statuses, tags, assignees } = args;
  const [request] = httpDittoProject.getProject({ projectId, projectContentSearchQuery, statuses, tags, assignees });
  const { data: project } = await request;
  return project;
}

/**
 * This is the source of truth for the project. You should be fetching/deriving/updating the project
 * through this atom to ensure that the UI updates correctly.
 */
export const { valueAtom: projectAtom, refreshAtom: refreshProjectActionAtom } = asyncMutableDerivedAtom({
  async loadData(get) {
    const projectId = get(projectIdAtom);
    const statusFilters = get(selectedFiltersAtomFamily("status"));
    const tagsFilters = get(selectedFiltersAtomFamily("tags"));
    const assigneeFilters = get(selectedFiltersAtomFamily("assignee"));
    const searchValue = get(searchAtom);

    if (!projectId) throw new Error("projectIdAtom is not set");

    return await fetchProjectById({
      projectId,
      projectContentSearchQuery: searchValue,
      statuses: statusFilters ?? undefined,
      tags: tagsFilters ?? undefined,
      assignees: assigneeFilters ?? undefined,
    });
  },
  debugLabel: "Project",
});

export const unwrappedProjectAtom = unwrap<IFDittoProjectData | Promise<IFDittoProjectData>, IFDittoProjectData>(
  projectAtom,
  (prev) =>
    prev ?? {
      _id: "",
      name: "",
      folderId: null,
      blocks: [],
      hiddenBlocksCount: 0,
      hiddenRootTextItemsCount: 0,
      hiddenTextItemsCount: 0,
      integrations: {},
      workspaceId: "",
      createdAt: new Date(),
      updatedAt: new Date(),
    }
);

export const projectFigmaFileLinkAtom = derive([projectAtom], (project) => {
  const fileId = project.integrations.figma?.fileId;
  if (!fileId) return null;
  return `https://www.figma.com/design/${fileId}`;
});

async function fetchAllProjectTags(projectId: string) {
  const [request] = httpDittoProject.getAllTags({ projectId });
  const response = await request;
  return response.data;
}

export const { valueAtom: allTagsInProject } = asyncMutableDerivedAtom({
  async loadData(get) {
    const projectId = get(projectIdAtom);

    if (!projectId) throw new Error("projectIdAtom is not set");

    return await fetchAllProjectTags(projectId);
  },
  debugLabel: "Project Tags",
});

// MARK: - Derived Atoms
export const projectNameAtom = atom((get) => get(unwrappedProjectAtom).name);
export const workspaceIdAtom = atom((get) => get(unwrappedProjectAtom).workspaceId);

function isNonEmptyArray<T>(value: T[]): value is [T, ...T[]] {
  return Array.isArray(value) && value.length > 0;
}

const allProjectTextItemIdsAtom = atom((get) =>
  get(unwrappedProjectAtom).blocks.flatMap((block) => block.allTextItems.map((textItem) => textItem._id))
);

/**
 * This atom returns a map from text item IDs to the full text item object. This atom is derived from our source of truth
 * for text items, and so it should be updated live with any changes to text items.
 *
 * This map can be either a Promise<MapType> or just a MapType, depending on whether any of the text items are still
 * being fetched.
 */
export const textItemsMapAtom = atom((get) => {
  const textItemIds = get(allProjectTextItemIdsAtom);

  // The atoms stored in `textItemFamilyAtom` could be holding either promises or the actual values. We use `soonAll`
  // to work with them as if they're the values.
  const values = textItemIds.map((id) => get(textItemFamilyAtom(id)));
  if (!isNonEmptyArray(values)) return {};
  const textItems = soonAll(values);

  // We use `soon` to work with the textItems array as if it's a synchronous value.
  return soon(textItems, (textItems) =>
    textItems.reduce<Record<string, ITextItemPopulatedComments>>((acc, textItem) => {
      acc[textItem._id] = textItem;
      return acc;
    }, {})
  );
});

export const projectBlocksAtom = atom(
  async (get) => (await get(projectAtom)).blocks,
  async (get, set, newBlocks: IFDittoBlockData[] | ((prev: IFDittoBlockData[]) => IFDittoBlockData[])) => {
    const project = await get(projectAtom);
    if (newBlocks instanceof Function) {
      const blocksUpdate = newBlocks(project.blocks);
      set(projectAtom, { ...project, blocks: blocksUpdate });
    } else {
      set(projectAtom, { ...project, blocks: newBlocks });
    }
  }
);

export const blockIdToTextItemIdsMapAtom = derive([projectBlocksAtom], (blocks) => {
  return blocks.reduce<Record<string, string[]>>((acc, block) => {
    acc[block._id ?? "root"] = block.allTextItems.map((textItem) => textItem._id);
    return acc;
  }, {});
});

/**
 * This atom is potentially a Promise -- when consuming it in React, you should handle that state with Suspense.
 */
export const deferredProjectBlocksAtom = derive([projectAtom], (proj) => proj.blocks);

/**
 * This atom will always be synchronously defined -- while `projectBlocksAtom` is a Promise, `unwrap` will resolve to
 * its previous value (or an empty array, if it's undefined). When consumed in state, this will never suspend.
 */
export const unwrappedProjectBlocksAtom = unwrap(projectBlocksAtom, (prev) => prev ?? []);

/**
 * Atom family that returns an atom for a specific block, given its id.
 * @param blockId - Id of the block to search for.
 */
export const projectBlocksFamilyAtom = atomFamily((blockId: string) => {
  return atom((get) => get(unwrappedProjectBlocksAtom).find((block) => block._id === blockId));
});

export const nonBlockTextItemsAtom = atom((get) => {
  const blocks = get(unwrappedProjectBlocksAtom);
  return blocks.filter((block) => !block._id).flatMap((block) => block.textItems);
});

async function reorderBlocks(args: { projectId: string; blockIds: string[]; newIndex: number }) {
  const [request] = httpDittoProject.reorderBlocks({
    projectId: args.projectId,
    blockIds: args.blockIds,
    newIndex: args.newIndex,
  });
  const response = await request;
  return response.data;
}

export const reorderBlockActionAtom = atom(null, async (get, set, update: { blockId: string; newIndex: number }) => {
  const blocks = get(unwrappedProjectBlocksAtom);
  const currentIndex = blocks.findIndex((b) => b._id === update.blockId);
  const newBlocks = [...blocks];
  const [blockToMove] = newBlocks.splice(currentIndex, 1);
  newBlocks.splice(update.newIndex, 0, blockToMove);

  set(projectBlocksAtom, newBlocks);

  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  await reorderBlocks({
    projectId,
    blockIds: [update.blockId],
    newIndex: update.newIndex,
  });
});

function clamp(val: number, min: number, max: number) {
  return Math.max(Math.min(val, max), min);
}

export const shiftBlockOrderActionAtom = atom(
  null,
  (get, set, update: { blockId: string | null; direction: "up" | "down" }) => {
    const blocks = get(unwrappedProjectBlocksAtom);
    const currentIndex = blocks.findIndex((b) => b._id === update.blockId);

    const newIndex = clamp(currentIndex + (update.direction === "up" ? -1 : 1), 0, blocks.length - 2); // -2 because we don't want to include the "null block" of un-blocked text items
    set(reorderBlockActionAtom, {
      blockId: update.blockId,
      newIndex,
    });
  }
);

export const moveBlocksActionAtom = atom(
  null,
  async (get, set, update: { blockIds: string[]; destinationBlockId: string; direction: "above" | "below" | null }) => {
    const blocks = get(unwrappedProjectAtom).blocks;
    const destinationBlockIndex = blocks.findIndex((b) => b._id === update.destinationBlockId);
    const currentBlockIndex = blocks.findIndex((b) => b._id === update.blockIds[0]);

    const newBlockIndex = (() => {
      if (currentBlockIndex < destinationBlockIndex) {
        return clamp(destinationBlockIndex + (update.direction === "above" ? -1 : 0), 0, blocks.length - 2);
      } else {
        return clamp(destinationBlockIndex + (update.direction === "above" ? 0 : 1), 0, blocks.length - 2);
      }
    })();

    set(reorderBlockActionAtom, {
      blockId: update.blockIds[0],
      newIndex: newBlockIndex,
    });
  }
);

export const projectBlocksSplitAtom = splitAtom(unwrappedProjectBlocksAtom);

export const projectTextItemsCountAtom = atom((get) => {
  return get(unwrappedProjectAtom).blocks.reduce((acc, block) => acc + block.textItems.length, 0);
});

export const projectBlocksCountAtom = atom((get) => {
  return get(unwrappedProjectAtom).blocks.length;
});

export const projectHiddenResultsTextItemsCountAtom = atom((get) => {
  const blocks = get(unwrap(projectBlocksAtom, (prev) => prev ?? []));
  return blocks.reduce((acc, block) => acc + block.allTextItems.length - block.textItems.length, 0);
});

export const projectHiddenResultsBlocksCountAtom = atom((get) => {
  const blocks = get(unwrappedProjectBlocksAtom);
  return blocks.length - blocks.filter((block) => block.textItems.length).length;
});

export interface INavBlockItem {
  _id: string;
  type: "block";
}

export interface INavTextItem {
  _id: string;
  type: "text";
  sortKey: string;
}

export interface INavMessageItem {
  _id: string;
  type: "message";
  message: string;
}

export function isValidBlock(block: any): block is INavBlockItem {
  return block && block._id;
}

// Atom family for storing whether or not to force show all text items in a block when filters are active
export const forceShowAllTextItemsInBlockAtomFamily = atomFamily((blockId: string) => atom(false));

export const flattenedProjectItemsAtom = atom((get) =>
  soon(get(projectAtom), (project) => {
    const flattenedProjectItems = project.blocks.reduce<(INavBlockItem | INavTextItem | INavMessageItem)[]>(
      (acc, block) => {
        if (isValidBlock(block)) {
          const forceShowAllTextItemsInBlock = get(forceShowAllTextItemsInBlockAtomFamily(block._id));
          acc.push({ _id: block._id, type: "block" });
          const textItems = forceShowAllTextItemsInBlock ? block.allTextItems : block.textItems;
          acc.push(...textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
          /**
           * Add a user-friendly message that summarizes # of text items hidden from this block due to search results,
           * if applicable
           */
          const hiddenTextItemsCount = block.allTextItems.length - block.textItems.length;
          if (hiddenTextItemsCount > 0 && !forceShowAllTextItemsInBlock) {
            acc.push({
              _id: `${block._id}_hidden_search_results`,
              type: "message",
              message: `${hiddenTextItemsCount} text ${hiddenTextItemsCount === 1 ? "item" : "items"} not shown`,
            });
          }
        } else {
          acc.push(...block.textItems.map((textItem) => ({ ...textItem, type: "text" as const })));
        }
        return acc;
      },
      []
    );

    /**
     * Build a user-friendly message that summarizes # of blocks / root text items hidden from search results,
     * if applicable
     */
    const hiddenProjectMessageStringComponents: string[] = [];

    if (project.hiddenBlocksCount > 0) {
      hiddenProjectMessageStringComponents.push(
        `${project.hiddenBlocksCount} ${project.hiddenBlocksCount === 1 ? "block" : "blocks"}`
      );
    }
    if (project.hiddenRootTextItemsCount > 0) {
      hiddenProjectMessageStringComponents.push(
        `${project.hiddenRootTextItemsCount} text ${project.hiddenRootTextItemsCount === 1 ? "item" : "items"}`
      );
    }

    const hiddenProjectItemsMessage = hiddenProjectMessageStringComponents.length
      ? `${hiddenProjectMessageStringComponents.join(" and ")} not shown`
      : "";

    return {
      flattenedProjectItems,
      hiddenProjectItemsMessage,
    };
  })
);

/**
 * A list of all the block ids in the project.
 */
export const allBlockIdsAtom = atom((get) => {
  const project = get(unwrappedProjectAtom);
  return project.blocks.map((block) => block._id).filter((id): id is string => id !== null);
});

/**
 * A list of all the text items in the project.
 */
export const allTextItemIdsAtom = atom((get) => {
  const project = get(unwrappedProjectAtom);
  return project.blocks.flatMap((block) => block.allTextItems.map((textItem) => textItem._id));
});

/**
 * A list of all the visible text items in the project, based on the current filter state.
 */
export const allVisibleItemIdsAtom = atom((get) => {
  const project = get(unwrappedProjectAtom);
  return project.blocks.flatMap((block) => block.textItems.map((textItem) => textItem._id));
});

export const variantsAtom = atom(async () => {
  const [request] = httpVariant.getForWorkspace();
  const response = await request;
  return response.data;
});

// we maintain separate state values for the sidebar collapse state based on whether or not Design Previews are toggled.
// TODO: this should reset when changing projects. DIT-8139
export type CollapseState = "open" | "closed" | "unset";
const nonDesignPreviewsCollapseState = atom<CollapseState>("unset");
const designPreviewsCollapseState = atom<CollapseState>("closed");

export const projectSidebarCollapsedAtom = atom(
  (get) => {
    const designPreviewsToggled = get(designPreviewToggledAtom);
    return designPreviewsToggled ? get(designPreviewsCollapseState) : get(nonDesignPreviewsCollapseState);
  },
  (get, set, value: CollapseState) => {
    const designPreviewsToggled = get(designPreviewToggledAtom);
    if (designPreviewsToggled) {
      set(designPreviewsCollapseState, value);
    } else {
      set(nonDesignPreviewsCollapseState, value);
    }
  }
);

export const toggleProjectSidebarCollapsedActionAtom = atom(null, (get, set) => {
  const currentCollapseState = get(projectSidebarCollapsedAtom);
  set(projectSidebarCollapsedAtom, currentCollapseState === "closed" ? "open" : "closed");
});

export const { valueAtom: editableProjectNameAtom, resetAtom: resetEditableProjectNameAtom } = asyncMutableDerivedAtom({
  loadData(get) {
    return soon(get(projectAtom), (project) => project.name);
  },
  debugLabel: "Project Name",
});

export const unwrappedEditableProjectNameAtom = unwrap(editableProjectNameAtom, (prev) => prev ?? "");
export const deferredProjectNameAtom = derive([editableProjectNameAtom], (name) => name);

export const saveProjectNameActionAtom = atom(null, async (get, set, name: string) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");
  const project = await get(projectAtom);
  const prevProjectName = project.name;

  // optimistic update
  await set(projectAtom, { ...project, name });

  try {
    const [request] = httpDittoProject.updateProject({ projectId, projectData: { name } });
    const response = await request;
    if (response.data.name !== name) {
      set(projectAtom, { ...project, name: response.data.name });
    }
  } catch (error) {
    console.error(error);
    // revert optimistic update
    set(projectAtom, { ...project, name: prevProjectName });
  }
});

// MARK: - Actions

function deleteBlocks(projectId: string, blockIds: string[]) {
  const [request] = httpDittoProject.deleteBlocks({ projectId, blockIds });
  return request;
}

export const deleteBlocksActionAtom = atom(null, async (get, set, blockIds: string[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  set(clearSelectionActionAtom);

  // Update the project structure atom
  const project = get(unwrappedProjectAtom);
  const blocksToDelete = project.blocks.filter((block) => blockIds.includes(block._id ?? ""));

  // each block has two lists of text items: allTextItems and textItems
  // - allTextItems is the list of text items that are in the block, regardless of whether they are visible in filter state
  // - textItems is the list of text items in the block that are currently visible in filter state
  // we need to get *both* of these lists from each deleted block, and merge them into the "not in block" block, which
  // is the one with a null _id

  const textItemsOfDeletedBlocks = blocksToDelete.flatMap((block) => block.textItems);
  const allTextItemsOfDeletedBlocks = blocksToDelete.flatMap((block) => block.allTextItems);

  const newProjectBlocks = project.blocks.reduce<IFDittoBlockData[]>((acc, block) => {
    // merge all the text items of our deleted blocks into the "not in block" block
    if (block._id === null) {
      acc.push({
        ...block,
        textItems: block.textItems.concat(textItemsOfDeletedBlocks).sort((a, b) => (a < b ? -1 : 1)),
        allTextItems: block.allTextItems
          .concat(allTextItemsOfDeletedBlocks)
          .sort((a, b) => a.sortKey.localeCompare(b.sortKey)),
      });
    } else if (!blockIds.includes(block._id)) {
      acc.push(block);
    }
    return acc;
  }, []);

  set(projectAtom, {
    ...project,
    blocks: newProjectBlocks,
  });

  // Update the block family atom -- remove the deleted blocks from the atom
  blockIds.map(blockFamilyAtom.remove);

  // Update all the text item family atoms -- each text item now needs a blockId of null
  for (const textItem of textItemsOfDeletedBlocks) {
    const textItemAtom = textItemFamilyAtom(textItem._id);
    const textItemValue = await get(textItemAtom);

    set(textItemAtom, {
      ...textItemValue,
      blockId: null,
    });
  }

  // Update on the backend
  // TODO: handle rollback/error state for updating blocks -- https://linear.app/dittowords/issue/DIT-8027/add-support-for-error-state-and-rollback-when-updating-blocks
  await deleteBlocks(projectId, blockIds);
});

async function updateBlocks(projectId: string, blocks: IFDittoProjectBlockUpdate[]) {
  const [request] = httpDittoProject.updateBlocks({ projectId, blocks });
  const response = await request;
  return response.data;
}

export const updateBlocksActionAtom = atom(null, async (get, set, blocks: IFDittoProjectBlockUpdate[]) => {
  const projectId = get(projectIdAtom);
  if (!projectId) throw new Error("projectIdAtom is not set");

  const blockUpdateMap = blocks
    .filter((block) => block._id)
    .reduce<Record<string, IFDittoProjectBlockUpdate>>((acc, block) => {
      acc[block._id] = block;
      return acc;
    }, {});

  // Update the blocks in state -- project atom
  const project = await get(projectAtom);
  const projectNew = { ...project };
  projectNew.blocks = project.blocks.map((block) => {
    if (block._id) {
      const blockUpdate = blockUpdateMap[block._id];
      if (blockUpdate) {
        return {
          ...block,
          ...blockUpdate,
        };
      }
    }

    return block;
  });
  await set(projectAtom, { ...projectNew });

  // Update the block in state -- block family atom
  blocks.forEach((block) => {
    const blockAtom = blockFamilyAtom(block._id);
    const blockValue = get(blockAtom);

    set(blockAtom, {
      ...blockValue,
      ...block,
    });
  });

  // Update the block in the backend
  // TODO: handle rollback/error state for updating blocks -- https://linear.app/dittowords/issue/DIT-8027/add-support-for-error-state-and-rollback-when-updating-blocks
  const updatedBlocks = await updateBlocks(projectId, blocks);

  return updatedBlocks;
});

export const renameBlockActionAtom = atom(null, async (get, set, blockId: string, newName: string) => {
  set(updateBlocksActionAtom, [{ _id: blockId, name: newName }]);
});

export const newTextItemsCreatedActionAtom = atom(null, async (get, set, data: { textItemIds: string[] }) => {
  // preload the text items.
  Promise.all(data.textItemIds.map((textItemId) => get(textItemFamilyAtom(textItemId))));
  // reload the project structure. This might be a bit overkill, but it works and it's fast and simple. We can optimize this later if needed.
  set(refreshProjectActionAtom);
});

/**
 * This action atom is used to update the text items in the project. Primarily used for real time updates.
 */
export const textItemsUpdatedActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      textItemIds: ITextItemsUpdated["textItemIds"];
      application: ITextItemsUpdated["application"];
      userObjectId?: ITextItemsUpdated["userObjectId"];
    }
  ) => {
    // Don't update the text items if the user is the one who made the change
    if (props.userObjectId === get(userAtom)?._id && props.application === "web_app") return;
    for (const textItemId of props.textItemIds) {
      set(textItemFamilyAtom(textItemId), REFRESH_SILENTLY);
    }
  }
);

export const updateTextItemsActionAtom = atom(null, (get, set, updatedTextItems: ITextItemPopulatedComments[]) => {
  updatedTextItems.forEach((item) => {
    set(textItemFamilyAtom(item._id), item);
  });
});

export const handleFigmaTextNodesUnlinkedActionAtom = atom(
  null,
  async (
    get,
    set,
    props: {
      data: {
        textItemId: string;
        figmaNodeId: string;
      }[];
    }
  ) => {
    for (const unlinkPair of props.data) {
      set(textItemFamilyAtom(unlinkPair.textItemId), (prev) => {
        return {
          ...prev,
          integrations: {
            ...prev.integrations,
            figmaV2: {
              ...prev.integrations.figmaV2,
              instances:
                prev.integrations.figmaV2?.instances?.filter(
                  (instance) => instance.figmaNodeId !== unlinkPair.figmaNodeId
                ) || [],
            },
          },
        };
      });
    }
  }
);

export const handleNewFigmaSyncActionsAtom = atom(null, async (get, set, actions: IFAction[]) => {
  const textItemIdsToUpdateSet: Set<string> = new Set();

  for (const action of actions) {
    try {
      switch (action.type) {
        case "updateDittoTextItem": {
          textItemIdsToUpdateSet.add(action.textItemId);
          break;
        }
        case "updateFigmaNode": {
          // noop, this action is handled exclusively in the Figma plugin
          break;
        }
        case "resolveTextConflict": {
          // noop, this action is handled exclusively in the Figma plugin
          break;
        }
        case "linkFigmaTextNode": {
          textItemIdsToUpdateSet.add(action.textItemId);
          break;
        }
        case "unlinkFigmaTextNode": {
          textItemIdsToUpdateSet.add(action.textItemId);
          break;
        }
        default:
          assertUnreachable(action, "Unsupported action type");
      }
    } catch (e) {
      logger.error("Failed to process action in Ditto backend", { context: { action } }, e);
    }
  }

  const textItemIdsToUpdate = Array.from(textItemIdsToUpdateSet);
  if (textItemIdsToUpdate.length) {
    set(textItemsUpdatedActionAtom, {
      application: "unknown",
      textItemIds: textItemIdsToUpdate,
    });
  }
});

export const handleUpdateBlocksActionAtom = atom(null, async (get, set, props: IProjectBlocksUpdated) => {
  for (const block of props.blocks) {
    set(blockFamilyAtom(block._id), REFRESH_SILENTLY);
  }
  set(refreshProjectActionAtom, REFRESH_SILENTLY);
});
