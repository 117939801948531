import { initializeBadge } from "@/utils/activity";
import ChangeItem from "@ds/molecules/ChangeItem";
import { ChangeItemBadgeData } from "@ds/molecules/ChangeItemCountBadge";
import { CustomChangeItemComponentProps } from "@ds/organisms/ChangeItemList";
import useAtomEffect from "@shared/frontend/stores/useAtomEffect";
import React, { useCallback, useState } from "react";

function TextItemActivityItem({ changeItem }: CustomChangeItemComponentProps) {
  const [badge, setBadge] = useState<ChangeItemBadgeData | undefined>();

  useAtomEffect(
    useCallback(
      (get) => {
        initializeBadge(get, setBadge, changeItem);
      },
      [changeItem]
    )
  );

  return <ChangeItem changeItem={changeItem} badge={badge} />;
}

export default TextItemActivityItem;
