import React from "react";
import ChangeItemWithContextMenu from "../ChangeItemWithContextMenu";
import style from "./style.module.css";

export interface IAutoAttachComponentsDisabledChangeItemProps {
  key: string;
  user?: string;
  timeAgo: React.ReactNode;
  onAutomationSettingsClick?: () => void;
  borderedUi?: boolean;
}

export default function AutoAttachComponentsDisabledChangeItem(props: IAutoAttachComponentsDisabledChangeItemProps) {
  return (
    <div>
      <ChangeItemWithContextMenu
        key={props.key}
        user={props.user}
        timeAgo={props.timeAgo}
        contextMenuOptions={
          props.onAutomationSettingsClick ? [{ displayName: "Automation Settings", value: "automation-settings" }] : []
        }
        onContextMenuOptionClick={(value: string) => {
          if (value === "automation-settings") {
            props.onAutomationSettingsClick && props.onAutomationSettingsClick();
          }
        }}
        borderedUi={props.borderedUi}
      >
        <div className={`${style.text} ${props.borderedUi ? style.borderedText : ""}`}>
          Turned off automatic attaching of exact component matches to text items in this project
        </div>
      </ChangeItemWithContextMenu>
    </div>
  );
}
