import * as DittoEvents from "@shared/ditto-events";
import { useDittoEventListener } from "@shared/ditto-events/frontend";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { ZComponentSuggestionJobResult } from "@shared/types/jobs/ComponentSuggestions";
import { JobNames } from "@shared/types/jobs/JobNames";
import logger from "@shared/utils/logger";
import { useEffect, useMemo, useRef, useState } from "react";
import { ComponentSuggestionsProps } from ".";
import {
  PopulatedAttachSuggestion,
  PopulatedComponentSuggestion,
  PopulatedCreateSuggestion,
  isPopulatedAttachSuggestion,
} from "../../../shared/types/ComponentSuggestions";
import { SuggestionTabs } from "../../defs";
import http, { API } from "../../http";
import { Group } from "../../views/Project/state/types";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import useFolderSelect, { Folder } from "../FolderSelect/useFolderSelect";

export interface CreateComp {
  name?: string;
  folder_id?: string | null;
  error?: string;
}

const useSuggestions = ({
  groups,
  doc_ID,
  resyncLoading,
  suggestedCompId,
  handleSuggestionSelectComp,
  handleDocUpdate,
  handleHistoryUpdate,
  setCustomToast,
}: ComponentSuggestionsProps) => {
  const [suggestionTab, setSuggestionTab] = useState(SuggestionTabs.create);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasComponents, setHasComponents] = useState(false);
  const [creatableSuggestions, setCreatableSuggestions] = useState<PopulatedCreateSuggestion<string>[]>([]);
  const [attachableSuggestions, setAttachableSuggestions] = useState<PopulatedAttachSuggestion<string>[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [multiSelectedSuggestions, setMultiSelectedSuggestions] = useState<PopulatedComponentSuggestion<string>[]>([]);
  const [createCompMap, setCreateCompMap] = useState<Record<string, CreateComp>>({});
  const componentSuggestionsJobId = useRef<string | null>(null);

  const onSelectComponentFolder = (folder: Folder) => {
    setMultiSelectedSuggestions((prev) => {
      if (folder._id === "") return prev;
      return prev.filter((suggestion) => folder?.component_ids.includes(suggestion.ws_comp || ""));
    });
    setSelectedIndex(-1);
  };

  const {
    doc: [doc],
  } = useProjectContext();

  const { selectedFolder, setSelectedFolder, folders } = useFolderSelect({
    onFolderSelectCallback: onSelectComponentFolder,
    useSampleData: doc?.isSample,
  });

  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  const getCompsFromArtboard = (groups: Group[]) => {
    const result = {};
    groups.forEach((group) => {
      group.comps.forEach((comp) => {
        result[comp._id] = comp;
      });
      group.blocks.forEach((block) => {
        block.comps.forEach((comp) => {
          result[comp._id] = comp;
        });
      });
    });
    return result;
  };

  const compMap = useMemo(() => getCompsFromArtboard(groups), [groups]);

  const showToast = ({ show, message, icon }) => {
    setCustomToast({ show, message, icon });

    setTimeout(() => {
      setCustomToast({ show: false, message: null, icon: null });
    }, 2000);
  };

  const changeSuggestionTab = (tab: string) => {
    setSuggestionTab(tab);
    setSelectedIndex(-1);
    setMultiSelectedSuggestions([]);
    setCreateCompMap({});
    handleSuggestionSelectComp(null);
  };

  const fetchSuggestions = async () => {
    handleSuggestionSelectComp(null);
    setLoadingSuggestions(true);
    try {
      const { url } = API.jobs.post.componentSuggestions;
      const { data } = await http.post(url, {
        projectId: doc_ID,
      });
      componentSuggestionsJobId.current = data.id;
    } catch (err) {
      setLoadingSuggestions(false);
      logger.error("Error creating component suggestions job", { other: { projectId: doc_ID } }, err);
    }
  };

  useDittoEventListener(
    DittoEvents.backgroundJobUpdated,
    async function handleBackgroundJobUpdated(event) {
      if (event.id === componentSuggestionsJobId.current && event.jobName === "component-suggestions") {
        if (event.status === "completed") {
          try {
            // actually fetch the result from the backend
            const jobResults = await http.get(`/jobs/response/${JobNames.ComponentSuggestionsJob}/${event.id}`);

            const { creatableSuggestions, attachableSuggestions, hasComponents } = ZComponentSuggestionJobResult.parse(
              jobResults.data.result
            );
            setHasComponents(hasComponents);
            // TODO: Fix this type
            setCreatableSuggestions(creatableSuggestions as any);
            setAttachableSuggestions(attachableSuggestions as any);
            setLoadingSuggestions(false);
          } catch (error) {
            logger.error(
              "Unalbe to fetch component suggestions",
              { tags: { jobId: event.id, jobName: event.jobName } },
              error
            );
          }
          componentSuggestionsJobId.current = null;
        } else if (event.status === "failed") {
          setLoadingSuggestions(false);
          logger.error("Component Suggestions Job Failed", { other: { event } }, new Error(event.failedReason));
          componentSuggestionsJobId.current = null;
        }
      }
    },
    []
  );

  async function validateSuggestion(suggestionId: string) {
    try {
      const response = await http.get(`ws_comp/suggestions/accept/${suggestionId}`);
      if (response.data.ok === false) {
        let message = "Error accepting suggestion";
        switch (response.data.error) {
          case "suggestion-not-found":
            message = "This suggestion has already been accepted!";
            break;
          default:
            break;
        }

        showToast({
          show: true,
          message,
          icon: "❌",
        });
        // remove the suggestion from the list
        setAttachableSuggestions((prev) => prev.filter((s) => s._id !== suggestionId));
        setCreatableSuggestions((prev) => prev.filter((s) => s._id !== suggestionId));

        return false;
      }

      return true;
    } catch (error) {
      console.error("error validating suggestion", error);
      return false;
    }
  }

  const onSuggestionSelected = (index) => {
    setSelectedIndex(index !== selectedIndex ? index : -1);
    window?.analytics?.track("Component Suggestion Selected");
  };

  const attachSuggestion = async (args: {
    wsComp: { _id: string; instances: string[] };
    comp_ids: string[];
    isCreate: boolean;
    suggestionId?: string;
  }) => {
    const { wsComp, comp_ids, isCreate, suggestionId } = args;

    if (suggestionId && !(await validateSuggestion(suggestionId))) return;

    try {
      let ws_comp_id = wsComp._id;
      let assigned_comp = wsComp.instances[0];

      const { url, body } = API.ws_comp.post.attachMultiComp;

      const reqBody = body({
        comp_ids,
        ws_comp_id,
        assigned_comp,
        from: "web_app",
        is_draft: false,
        shouldComponentizeDuplicates: false,
      });
      await http.post(url, reqBody);

      handleDocUpdate(comp_ids);
      handleHistoryUpdate();

      await fetchSuggestions();
      setSelectedIndex(-1);
      // update artboards with updated data

      if (isCreate) {
        showToast({
          show: true,
          message: `Component successfully created and attached to
          ${Object.values(comp_ids).length} instance${Object.values(comp_ids).length > 1 ? "s" : ""}! ⚡️`,
          icon: null,
        });
      } else {
        showToast({
          show: true,
          message: `Component${Object.values(comp_ids).length > 1 ? "s" : ""} successfully attached! ⚡️`,
          icon: null,
        });
      }
      window?.analytics?.track("Component Suggestion Accepted", {
        type: isCreate ? "creation" : "attachment",
        count: comp_ids.length,
      });
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error attaching component",
        icon: null,
      });
      console.error("error attaching component suggestion", error);
    }
  };

  const createAndAttachSuggestion = async (args: {
    name: string;
    comp_ids: string[];
    componentFolderId: string;
    suggestionId?: string;
  }) => {
    const { name, comp_ids, suggestionId, componentFolderId } = args;

    if (suggestionId && !(await validateSuggestion(suggestionId))) return;

    try {
      setIsSaving(true);
      const { url, body } = API.ws_comp.post.createComp;
      const reqBody = body({
        name,
        comp_id: comp_ids[0],
        isCreateAndAttach: true,
        folder_id: componentFolderId,
      });
      const { data } = await http.post(url, reqBody);
      const { ws_comp_id } = data;
      const newWsComp = {
        _id: ws_comp_id,
        instances: [comp_ids[0]],
      };

      await attachSuggestion({ wsComp: newWsComp, comp_ids, isCreate: true });
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error creating component",
        icon: null,
      });
      console.error("error creating and attaching new suggestion", error);
    }
    setIsSaving(false);
  };

  const fetchComponentCategories = async () => {
    const { url } = API.ws_comp.get.categories;
    const { data } = await http.get(url);
    return data;
  };

  const checkComponentNameExists = async (componentName: string) => {
    try {
      if (componentName.length === 0) return false;
      const { url, body } = API.ws_comp.post.validateNameExists;
      const {
        data: { exists },
      } = await http.post(url, body({ name: componentName }));

      // make sure the name doesn't exist in the current suggestions
      const namesToCreate = Object.values(createCompMap)
        .map((c) => c.name)
        .filter(Boolean);
      const nameSet = new Set(
        Object.values(createCompMap)
          .map((c) => c.name)
          .filter(Boolean)
      );
      const existsInSuggestions = namesToCreate.length != nameSet.size;

      return exists || existsInSuggestions;
    } catch (error) {
      // fallback on server-side validation
      console.error("error doing POST /ws_comp/validateNameExists", error);
      return true;
    }
  };

  const handleIgnoreSuggestion = async (
    suggestionId: string,
    suggestionType: "attachable" | "creatable",
    shouldIgnore: boolean
  ) => {
    try {
      setIsSaving(true);
      const { url, body } = API.ws_comp.put.ignoreSuggestion;
      await http.put(url(doc_ID, suggestionId), body({ ignore: shouldIgnore }));
      if (suggestionType === "attachable") {
        setAttachableSuggestions((prev) => {
          const newSuggestions = [...prev];
          const suggestionIndex = newSuggestions.findIndex((suggestion) => suggestion._id === suggestionId);
          newSuggestions[suggestionIndex].ignored = shouldIgnore;
          return newSuggestions;
        });
      }
      if (suggestionType === "creatable") {
        setCreatableSuggestions((prev) => {
          const newSuggestions = [...prev];
          const suggestionIndex = newSuggestions.findIndex((suggestion) => suggestion._id === suggestionId);
          newSuggestions[suggestionIndex].ignored = shouldIgnore;
          return newSuggestions;
        });
      }
      setSelectedIndex(-1);
      setIsSaving(false);
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error ignoring suggestion",
        icon: null,
      });
      setIsSaving(false);
      console.error("Error ignoring suggestion", error);
    }
  };

  // Ignored components cannot be multi-selected, so this should only be operating
  // on components that have not been ignored.
  const handleMultiIgnore = async (
    suggestionIds: string[],
    suggestionType: "attachable" | "creatable",
    shouldIgnore: boolean
  ) => {
    try {
      setIsSaving(true);
      const { url, body } = API.ws_comp.put.ignoreMultiSuggestions;
      await http.put(url(doc_ID), body({ suggestionIds, ignore: shouldIgnore }));

      if (suggestionType === "attachable") {
        setAttachableSuggestions((prev) => {
          const newSuggestions = [...prev];
          suggestionIds.forEach((suggestionId) => {
            const suggestionIndex = newSuggestions.findIndex((suggestion) => suggestion._id === suggestionId);
            newSuggestions[suggestionIndex].ignored = shouldIgnore;
          });
          return newSuggestions;
        });
      } else if (suggestionType === "creatable") {
        setCreatableSuggestions((prev) => {
          const newSuggestions = [...prev];
          suggestionIds.forEach((suggestionId) => {
            const suggestionIndex = newSuggestions.findIndex((suggestion) => suggestion._id === suggestionId);
            newSuggestions[suggestionIndex].ignored = shouldIgnore;
          });
          return newSuggestions;
        });
      }

      setIsSaving(false);
      setMultiSelectedSuggestions([]);
      setSelectedIndex(-1);
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error ignoring suggestions",
        icon: null,
      });
      setIsSaving(false);
      console.error("Error ignoring suggestions", error);
    }
  };

  const pageThroughDupes = (comp_id: string) => handleSuggestionSelectComp(comp_id);

  const handleMultiSelect = (suggestion_id: string) => {
    // gotta check both attachable and creatable suggestions
    let suggestionToSelect: PopulatedComponentSuggestion<string> | undefined;
    suggestionToSelect = attachableSuggestions.find((suggestion) => suggestion._id === suggestion_id);
    if (!suggestionToSelect) {
      suggestionToSelect = creatableSuggestions.find((suggestion) => suggestion._id === suggestion_id);
    }

    if (!suggestionToSelect) return;

    const alreadySelected = multiSelectedSuggestions.some((s) => s._id === suggestion_id);

    // add a new entry to createCompMap
    if (!alreadySelected && !createCompMap[suggestion_id]) {
      setCreateCompMap((prev) => ({
        ...prev,
        [suggestion_id]: {},
      }));
    }

    const newSelected = alreadySelected
      ? multiSelectedSuggestions.filter((s) => s._id !== suggestion_id)
      : [...multiSelectedSuggestions, suggestionToSelect];
    setMultiSelectedSuggestions(newSelected);
  };

  const handleMultiSelectAll = () => {
    // figure out whether to select all attachable or creatable suggestions
    let suggestionsToSelect: PopulatedComponentSuggestion<string>[] = [];
    if (multiSelectedSuggestions.length === 0) return;

    if (isPopulatedAttachSuggestion(multiSelectedSuggestions[0])) {
      suggestionsToSelect = filteredAttachSuggestions.notIgnored;
    } else {
      suggestionsToSelect = filteredCreateSuggestions.notIgnored;
    }

    // if all suggestions are already selected, deselect all
    if (multiSelectedSuggestions.length === suggestionsToSelect.length) {
      setMultiSelectedSuggestions([]);
    } else {
      setMultiSelectedSuggestions(suggestionsToSelect);
    }
  };

  const handleMultiAttach = async () => {
    try {
      setIsSaving(true);

      const compIdsToUpdate: string[] = [];
      const compsToAttach = (
        multiSelectedSuggestions.filter((suggestion) =>
          isPopulatedAttachSuggestion(suggestion)
        ) as PopulatedAttachSuggestion<string>[]
      ).map((suggestion) => {
        compIdsToUpdate.push(...suggestion.comp_ids);
        return {
          suggestionId: suggestion._id,
          ws_comp_id: suggestion.wsComp._id,
          actual_comp_ids: suggestion.comp_ids,
        };
      });

      const { url, body } = API.ws_comp.post.bulkAttachMultiComp;
      await http.post(url, body({ attach_data: compsToAttach }));

      handleDocUpdate(compIdsToUpdate);
      handleHistoryUpdate();
      await fetchSuggestions();
      setMultiSelectedSuggestions([]);
      setSelectedIndex(-1);
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error attaching components",
        icon: null,
      });
      console.error("error attaching multiple components", error);
    }
    setIsSaving(false);
  };

  const handleMultiCreate = async (): Promise<boolean> => {
    try {
      setIsSaving(true);

      // if any of the names are blank, add an error
      const tempMap = { ...createCompMap };
      multiSelectedSuggestions.forEach((suggestion) => {
        if (!tempMap[suggestion._id]) tempMap[suggestion._id] = {};
        if (!tempMap[suggestion._id].name) {
          tempMap[suggestion._id].error = "Please enter a component name.";
        }
      });

      // if any errors, don't create
      if (Object.values(tempMap).some((s) => s.error)) {
        setCreateCompMap(tempMap);
        showToast({
          show: true,
          message: "❌ Some of your suggestions have errors. Please fix them and try again.",
          icon: null,
        });
        setIsSaving(false);
        return false;
      }

      const compIdsToUpdate: string[] = [];
      const compCreationData = multiSelectedSuggestions.map((suggestion) => {
        compIdsToUpdate.push(...suggestion.comp_ids);

        return {
          suggestionId: suggestion._id,
          componentFields: {
            name: createCompMap[suggestion._id].name,
            folder_id: createCompMap[suggestion._id].folder_id || null,
          },
          comp_ids: suggestion.comp_ids,
        };
      });

      const { url, body } = API.ws_comp.post.bulkCreateComps;
      await http.post(url, body({ compCreationData, projectId: doc_ID }));

      handleDocUpdate(compIdsToUpdate);
      handleHistoryUpdate();
      await fetchSuggestions();
      setMultiSelectedSuggestions([]);
      setSelectedIndex(-1);
      return true;
    } catch (error) {
      showToast({
        show: true,
        message: "❌ Error attaching components",
        icon: null,
      });
      console.error("error attaching multiple components", error);
      return false;
    }
    setIsSaving(false);
  };

  const onChangeCompName = (name: string, suggestion_id: string) => {
    setCreateCompMap((prev) => {
      const newChanges = { ...prev };
      if (!newChanges[suggestion_id]) newChanges[suggestion_id] = {};
      newChanges[suggestion_id].name = name;
      return newChanges;
    });
  };

  const onChangeCompFolder = (folder_id: string | null, suggestion_id: string) => {
    setCreateCompMap((prev) => {
      const newChanges = { ...prev };
      if (!newChanges[suggestion_id]) newChanges[suggestion_id] = {};
      newChanges[suggestion_id].folder_id = folder_id;
      return newChanges;
    });
  };

  const setCompError = (error: string, suggestion_id: string) => {
    setCreateCompMap((prev) => {
      const newChanges = { ...prev };
      if (!newChanges[suggestion_id]) newChanges[suggestion_id] = {};
      newChanges[suggestion_id].error = error;
      return newChanges;
    });
  };

  // filter suggestions when folder changes
  const filteredAttachSuggestions = useMemo(() => {
    const suggestions = attachableSuggestions.filter((suggestion) => {
      if (!selectedFolder._id) return true;
      else return suggestion.wsComp.folder_id === selectedFolder._id;
    });
    return {
      notIgnored: suggestions.filter((suggestion) => !suggestion.ignored),
      ignored: suggestions.filter((suggestion) => suggestion.ignored),
    };
  }, [selectedFolder, attachableSuggestions]);

  const filteredCreateSuggestions = useMemo(() => {
    return {
      notIgnored: creatableSuggestions.filter((suggestion) => !suggestion.ignored),
      ignored: creatableSuggestions.filter((suggestion) => suggestion.ignored),
    };
  }, [creatableSuggestions]);

  useEffect(() => {
    if (!resyncLoading) {
      fetchSuggestions();
    }
  }, [resyncLoading]);

  useEffect(() => {
    if (!suggestedCompId) setSelectedIndex(-1);
  }, [suggestedCompId]);

  return {
    isSaving,
    loadingSuggestions,
    compMap,
    suggestionTab,
    hasComponents,
    attachableSuggestions,
    filteredCreateSuggestions,
    filteredAttachSuggestions,
    selectedIndex,
    isEditEnabled,
    changeSuggestionTab,
    onSuggestionSelected,
    attachSuggestion,
    createAndAttachSuggestion,
    pageThroughDupes,
    checkComponentNameExists,
    fetchComponentCategories,
    selectedFolder,
    setSelectedFolder,
    folders,
    handleIgnoreSuggestion,
    multiSelectedSuggestions,
    handleMultiSelect,
    handleMultiAttach,
    handleMultiSelectAll,
    handleMultiIgnore,
    handleMultiCreate,
    onChangeCompName,
    createCompMap,
    onChangeCompFolder,
    setCompError,
  };
};

export { useSuggestions };
