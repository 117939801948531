import { Extension } from "@tiptap/core";

// Disables rich text styling short cuts
// Used for feature gating rich_text
const DisableRichText = Extension.create({
  name: "DisableRichText",
  addKeyboardShortcuts() {
    return {
      // ↓ your new keyboard shortcut
      "Mod-b": () => true,
      "Mod-u": () => true,
      "Mod-i": () => true,
      "Mod-.": () => true,
      "Mod-,": () => true,
    };
  },
});

export { DisableRichText };
