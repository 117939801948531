import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import React, { createContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import { IFNotification } from "../../shared/types/Notification";
import http, { API } from "../http";

function NotificationWebsocketHandler() {
  const { setUnreadNotifs } = useNotificationContext();

  const { lastMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);

    if (data.messageType === WEBSOCKET_EVENTS.NOTIFICATION) {
      const { notifications } = data;
      setUnreadNotifs((prev) => [...notifications.unreadNotifications, ...prev]);
    }
  }, [lastMessage]);

  return <></>;
}

interface NotificationContext {
  unreadNotifs: IFNotification[];
  readNotifs: IFNotification[];
  setUnreadNotifs: React.Dispatch<React.SetStateAction<IFNotification[]>>;
  fetchNotifications: () => void;
}

export const NotificationContext = createContext({} as NotificationContext);

export const useNotificationContext = () => React.useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [unreadNotifs, setUnreadNotifs] = useState<IFNotification[]>([]);
  const [readNotifs, setReadNotifs] = useState<IFNotification[]>([]);

  const fetchNotifications = async () => {
    const { url } = API.user.get.notifs;
    const { data: notifs } = await http.get(url);
    if (notifs.unreads && notifs.reads) {
      setUnreadNotifs(notifs.unreads);
      setReadNotifs(notifs.reads);
    }
  };

  const store = {
    unreadNotifs,
    readNotifs,
    fetchNotifications,
    setUnreadNotifs,
  };

  return (
    <NotificationContext.Provider value={store}>
      {children}
      <NotificationWebsocketHandler />
    </NotificationContext.Provider>
  );
};
