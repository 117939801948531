/*
  This is for ActualComponents that are in a text Block (i.e. a group) -- always full width
*/
import DragIcon from "@mui/icons-material/DragIndicator";
import TemplateBadge from "@shared/frontend/templates/TemplateBadge";
import classnames from "classnames";
import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PANELS } from "../../defs";
import useRenderedLinks from "../../hooks/useRenderedLinks";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import CardBubbles from "../CardBubbles/CardBubbles";
import CompStatusBar from "../CompStatusBar/CompStatusBar";
import PluralsBadge from "../PluralsBadge";
import TextItem from "../TextItem";
import ApiID from "../api-id";
import { GroupContext } from "../compgroup";
import Tag from "../tag/tag";
import style from "./style.module.css";
// NOTE -- OPTIONAL component FIELDS:
// text, count, tags, notes, is_draft, is_error, comp_id, is_selected

const CompInBlock = ({
  component,
  is_selected,
  is_suggested,
  selectComp,
  index,
  dragDisabled,
  apiID,
  isLast,
  setPanelState,
  setCommentState,
  displayApiIds,
  frameVariant,
  frameVariants,
  groupId,
  ws_comp,
  hoveredVariantId,
  setHoveredVariantId,
  handleSelectActiveVariant,
}) => {
  const groupContext = useContext(GroupContext);
  const inUnlinkedGroup = groupContext && groupContext.isLinked === false;

  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const formattedNotes = useRenderedLinks(component.notes);

  const openComments = () => {
    checkDetailPanelChanges(() => {
      if (!is_selected) {
        selectComp(component, frameVariant);
      }
      setCommentState({ isSelected: true, thread_id: "" });
      setPanelState(PANELS.doc.activity);
    });
  };

  const currentVariantInstance = component.variants?.find((v) => v.variantId === frameVariant?.id);

  const emptyVariant =
    component.variants &&
    frameVariant &&
    frameVariant.id !== "__base__" &&
    // we consider a variant "empty" if it's never been saved at all, or if it has been saved with empty text.
    (!currentVariantInstance || !currentVariantInstance?.text || currentVariantInstance?.text === "");

  const showCompOther =
    (component.notes && component.notes.length > 0) ||
    (component.tags && component.tags.length > 0) ||
    (apiID && displayApiIds);

  const isTemplateComponent = ws_comp?.type === "template";

  return (
    <Draggable draggableId={component._id} key={component._id} index={index} isDragDisabled={dragDisabled}>
      {(provided) => (
        <div
          id={component._id}
          data-testid={`compInBlock-${(component.text || "").replaceAll(" ", "-")}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={classnames({
              [style.comp]: true,
              "single-component": true,
              [style.selected]: is_selected,
              [style.suggested]: is_suggested,
              [style.inUnlinkedGroup]: inUnlinkedGroup,
              [style.wsComp]: Boolean(ws_comp),
              [style.wsCompTemplate]: isTemplateComponent,
              [style.firstInBlock]: index === 0,
              [style.lastInBlock]: isLast,
              [style.onlyComp]: index === 0 && isLast,
            })}
            name={component._id}
          >
            {is_suggested && (
              <div
                className={style.suggestedOverlay}
                onClick={(e) => selectComp(component, frameVariant, e, groupId)}
              />
            )}
            <CardBubbles
              cardIsSelected={is_selected}
              assignee={component.assignee}
              comment_threads={component.comment_threads}
              onCommentThreadClick={openComments}
              onAssigneeClick={(e) => selectComp(component, frameVariant, e, groupId)}
            />
            <div className={style.flexSection} onClick={(e) => selectComp(component, frameVariant, e, groupId)}>
              <CompStatusBar
                component={component}
                vertical
                className={classnames(style.compStatusBar, {
                  [style.draft]: inUnlinkedGroup,
                })}
                activeVariantId={frameVariant?.id || "__base__"}
                hoveredVariantId={hoveredVariantId}
                setHoveredVariantId={setHoveredVariantId}
                handleSelectActiveVariant={handleSelectActiveVariant}
                frameVariants={frameVariants}
              />
              <div
                className={classnames({
                  [style.textContent]: !showCompOther,
                  [style.textContentWithOther]: showCompOther,
                })}
              >
                {ws_comp && <TemplateBadge selected={is_selected} type={ws_comp.type} />}
                <div
                  className={classnames({
                    [style.compText]: true,
                    [style.noRightBorder]: showCompOther,
                    [style.draft]: component.status === "DRAFT",
                  })}
                >
                  <div className={style.columnWrapper}>
                    <div
                      className={classnames({
                        [style.text]: true,
                        [style.emptyVariant]: emptyVariant,
                      })}
                    >
                      <TextItem
                        textItem={component}
                        frameVariant={frameVariant}
                        highlightBrackets={ws_comp?.type === "template"}
                      />
                    </div>
                    <PluralsBadge comp={component} variantId={frameVariant?.id} />
                  </div>

                  {component.count && component.count > 1 && <div className={style.count}>{component.count}</div>}
                  {!dragDisabled && !showCompOther && <DragIcon className={style.drag} />}
                </div>
              </div>
              {showCompOther && (
                <div className={style.compOther}>
                  <div className={style.compOtherWrap}>
                    {component.notes && component.notes.length > 0 && <div>{formattedNotes}</div>}
                    {component.tags && component.tags.length > 0 && (
                      <div className={style.tags}>
                        {component.tags.map((text, index) => (
                          <Tag text={text} is_selected={is_selected} key={index} />
                        ))}
                      </div>
                    )}
                    {displayApiIds && apiID && (
                      <div style={{ display: "flex" }}>
                        <ApiID id={apiID} />
                      </div>
                    )}
                  </div>
                  {!dragDisabled && <DragIcon className={style.drag} />}
                </div>
              )}
            </div>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

export default CompInBlock;
