export interface IState {
  // intentionally avoid discriminated unions to "remember" metadata
  // when switching between enabled/disabled states for each toggle
  autoAttachComponentMatches: {
    enabled: boolean;
    folderId: string;
  };
  autoImportFrames: { enabled: boolean; tooLarge?: boolean; pageIds: Set<string> };
}

export function getStateAutoAttachHasChanged(
  initialState: IState["autoAttachComponentMatches"],
  state: IState["autoAttachComponentMatches"]
) {
  const autoAttachEnabledChanged = state.enabled !== initialState.enabled;
  const autoAttachFolderChanged = state.folderId !== initialState.folderId;
  return autoAttachEnabledChanged || autoAttachFolderChanged;
}

export function getStateAutoSyncHasChanged(
  initialState: IState["autoImportFrames"],
  state: IState["autoImportFrames"]
) {
  const autoSyncEnabledChanged = state.enabled !== initialState.enabled;
  const autoSyncPageIdsChanged = !getSetsAreEqual(initialState.pageIds, state.pageIds);
  return autoSyncEnabledChanged || autoSyncPageIdsChanged;
}

export function getStateHasChanged(initialState: IState, state: IState) {
  return (
    getStateAutoAttachHasChanged(initialState.autoAttachComponentMatches, state.autoAttachComponentMatches) ||
    getStateAutoSyncHasChanged(initialState.autoImportFrames, state.autoImportFrames)
  );
}

export function getSetsAreEqual(_set1: Set<string>, _set2: Set<string>) {
  let set1 = new Set(_set1);
  let set2 = new Set(_set2);

  if (set1.size !== set2.size) {
    return false;
  }

  for (const key of set1) {
    set2.delete(key);
  }

  if (set2.size > 0) {
    return false;
  }

  return true;
}
