import DoneIcon from "@mui/icons-material/Done";
import { removeDiacritics } from "@shared/utils/removeDiacritics";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { API_ID_REGEX } from "../../../shared/utils/regexes";
import spinner from "../../assets/small-spinner.gif";
import { ApiIdDumbComponent } from "./ApiIdDumbComponent";
import style from "./style.module.css";
const PROJECT_DUP_WARNING = "This ID already exists in this project";
const WORKSPACE_DUP_WARNING = "This ID already exists in your workspace";

interface ApiIDProps {
  id: string;
  canEdit?: boolean;
  onEdit?: (id: string) => Promise<string>;
  className?: string;
  projectLevel?: boolean;
  isDuplicateId?: (id: string) => boolean;
  disableCopy?: boolean;
}

const ApiID = (props: ApiIDProps) => {
  const {
    id,
    canEdit = false,
    onEdit,
    className,
    projectLevel = true,
    isDuplicateId = () => false,
    disableCopy = false,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [idInput, setIdInput] = useState(id);
  const [error, setError] = useState<string | null>(null);

  const DUPLICATE_MESSAGE = projectLevel ? PROJECT_DUP_WARNING : WORKSPACE_DUP_WARNING;

  useEffect(() => {
    setIdInput(id);
    setError(null);
    setIsRenaming(false);
    return () => setIdInput(id);
  }, [id]);

  const handleIDChange = (e) => {
    let text = removeDiacritics(e.target.value).replace(API_ID_REGEX, "");
    if (text.length === 0) {
      setError("ID cannot be empty");
    } else if (text.toLowerCase() === "base") {
      setError("Invalid ID value");
    } else if (text !== id && isDuplicateId(text)) {
      setError(DUPLICATE_MESSAGE);
    } else {
      setError(null);
    }
    setIdInput(text);
  };

  const onBlur = () => {
    if (!isLoading) {
      setIdInput(id);
      setIsRenaming(false);
      setError(null);
    }
  };

  const saveIDRename = async () => {
    if (!canEdit || !onEdit || error || idInput === id) {
      setIdInput(id);
      setIsRenaming(false);
      setError(null);
      return;
    }

    setIsLoading(true);
    const msg = await onEdit(idInput);
    if (msg === "duplicate-api-id") {
      setError(DUPLICATE_MESSAGE);
    } else if (msg !== "success") {
      setError(msg === "Conflict" ? DUPLICATE_MESSAGE : "Unable to save edit");
      setTimeout(() => {
        setError(null);
        setIsRenaming(false);
        setIdInput(id);
      }, 5000);
    } else {
      setIsRenaming(false);
      setError(null);
    }
    setIsLoading(false);
  };

  const checkPressEnter = (e) => {
    if (e.key === "Enter") {
      saveIDRename();
    }
  };

  const onCopy = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 5000);
  };

  return (
    <div className={style.idWrap}>
      {isRenaming && canEdit ? (
        <div className={style.editId}>
          <input
            autoFocus
            className={error && style.errorInput}
            spellCheck="false"
            value={idInput}
            type="text"
            onChange={handleIDChange}
            onBlur={onBlur}
            onKeyPress={checkPressEnter}
            placeholder="String ID"
            disabled={isLoading}
          />
          {!isLoading ? (
            <DoneIcon
              onMouseDown={() => saveIDRename()}
              className={classnames({
                [style.icon]: true,
                [style.errorIcon]: Boolean(error),
                [style.disabled]: idInput?.length === 0,
              })}
            />
          ) : (
            <img className={style.loading} src={spinner} />
          )}
        </div>
      ) : (
        <ApiIdDumbComponent
          showHoverMessage
          value={idInput}
          className={className}
          showSuccess={showSuccess}
          onCopy={!disableCopy ? onCopy : undefined}
          onEdit={canEdit ? () => setIsRenaming(true) : undefined}
        />
      )}
      {error && <div className={style.errorMsg}>{error}</div>}
    </div>
  );
};

export default ApiID;
