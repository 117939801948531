import API_ENDPOINTS from "./api";
import CHANGES from "./changes";
import COMMENTS from "./comments";
import COMP from "./comp";
import COMPONENT_FOLDER from "./component_folder";
import DOC from "./doc";
import EXPORT from "./export";
import FIGMA_AUTH from "./figmaAuth";
import FOLDER from "./folder";
import INVITE from "./invite";
import JOBS from "./jobs";
import PROJECT from "./project";
import USER from "./user";
import VARIABLE from "./variable";
import VARIABLE_FOLDER from "./variableFolder";
import VARIANT from "./variant";
import VARIANT_FOLDER from "./variantFolder";
import WORKSPACE from "./workspace";
import WS_COMP from "./ws_comp";

export { useCancelToken } from "./lib/useCancelToken";

import http from "./lib/client";
export default http;

export const API = {
  api: API_ENDPOINTS,
  ws_comp: WS_COMP,
  changes: CHANGES,
  comp: COMP,
  comments: COMMENTS,
  doc: DOC,
  folder: FOLDER,
  invite: INVITE,
  jobs: JOBS,
  user: USER,
  variable: VARIABLE,
  variant: VARIANT,
  workspace: WORKSPACE,
  figmaAuth: FIGMA_AUTH,
  project: PROJECT,
  export: EXPORT,
  componentFolder: COMPONENT_FOLDER,
  variantFolder: VARIANT_FOLDER,
  variableFolder: VARIABLE_FOLDER,
};
