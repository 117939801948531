import * as httpDittoProject from "@/http/dittoProject";
import { getForWorkspace } from "@/http/variantTyped";
import { IVariantTab } from "@ds/molecules/VariantTabs";
import { AddVariantData, AddVariantUpdateType } from "@ds/organisms/AddVariantForm";
import { createEmptyRichText } from "@shared/frontend/richText/plainTextToRichText";
import { serializeTipTapRichText } from "@shared/frontend/richText/serializer";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import { ITextItemVariantUpdate } from "@shared/types/TextItem";
import { BASE_VARIANT_ID, IFVariant } from "@shared/types/Variant";
import logger from "@shared/utils/logger";
import ObjectID from "bson-objectid";
import { atom } from "jotai";
import { derive } from "jotai-derive";
import { atomFamily, unwrap } from "jotai/utils";
import { projectAtom, projectBlocksFamilyAtom, projectIdAtom, textItemFamilyAtom, workspaceIdAtom } from "./Project";
import { detailsPanelPropsAtom } from "./ProjectSelection";
import { showToastActionAtom } from "./Toast";

/**
 * Atom for all the variants in the workspace.
 */
export const { valueAtom: variantsAtom } = asyncMutableDerivedAtom({
  loadData: async () => {
    const variants = (await getForWorkspace()[0]).data;
    return variants;
  },
  debugLabel: "All Variants",
});

/**
 * Derived atom for all the variants in the workspace.
 */
export const deferredVariantsAtom = derive([variantsAtom], (variants) => variants);

/**
 * Atom family for getting variant tabs for a block by its variantIds.
 * @param variantIds - The set of variantIds that exist in the block.
 */
export const variantTabsForBlockFamilyAtom = atomFamily((variantIds: string[] | undefined) => {
  const baseAtom = derive([deferredVariantsAtom], (variants): IVariantTab[] => {
    const tabs: IVariantTab[] = [];

    if (!variants || !variantIds?.length) {
      return tabs;
    }

    tabs.push({ name: "Base", id: BASE_VARIANT_ID });

    for (const variantId of variantIds) {
      const variant = variants.find((v) => v._id === variantId);
      if (variant) {
        tabs.push({ name: variant.name, id: variant._id });
      }
    }

    return tabs;
  });

  return unwrap(baseAtom, (prev) => prev ?? []);
});

/**
 * Atom family that returns a variant's name given its ID.
 * Returns undefined if the variant is not found.
 * @param variantId - The ID of the variant to get the name for.
 */
export const variantNameFamilyAtom = atomFamily((variantId: string) => {
  const baseAtom = derive([deferredVariantsAtom], (variants): string | undefined => {
    if (variantId === BASE_VARIANT_ID) return "Base";
    return variants.find((v) => v._id === variantId)?.name;
  });

  return unwrap(baseAtom, (prev) => prev ?? "");
});

/**
 * Atom family that returns an atom containing the selected variantId for each block.
 * Defaults to the base variant (BASE_VARIANT_ID) if blockId is null (block with null id pointing to text items)
 * @param blockId - The id of the block to get the selected variant for.
 */
export const blockSelectedVariantIdFamilyAtom = atomFamily((blockId: string | null) => {
  // Create a base atom for this block's selected variant
  const variantIdAtom = atom(BASE_VARIANT_ID);

  // Create a derived atom that validates the selected variant exists in the block
  const derivedAtom = atom(
    (get) => {
      // For non-block text items, we always select the base variant
      if (blockId === null) return BASE_VARIANT_ID;
      const currentVariantId = get(variantIdAtom);

      const block = get(projectBlocksFamilyAtom(blockId));
      // Get the valid variants for this block
      const variantTabs = get(variantTabsForBlockFamilyAtom(block?.variantIds));

      // Check if the current variant is valid for this block
      const isValidVariant = variantTabs.some((tab) => tab.id === currentVariantId);

      // Return current variant if valid, otherwise return base variant
      return isValidVariant ? currentVariantId : BASE_VARIANT_ID;
    },
    (_get, set, newVariantId: string) => {
      if (blockId !== null) set(variantIdAtom, newVariantId);

      // Clear variants panel props state when we switch between variants
      set(detailsPanelPropsAtom, (props) => ({ ...props, variants: undefined }));
    }
  );

  return derivedAtom;
});

/**
 * Action atom for attaching a variant to a text item.
 * If the variant doesn't exist yet, we create an id for it optimistically and save it to the backend.
 * If the variant already exists, we attach its metadata to the text item.
 * @param variant - The metadata for the variant being attached.
 * @param updateType - The type of update to perform -- CREATE if we're creating and attaching a new variant, otherwise UPDATE for only attaching an existing variant.
 * @param textItemId - The id of the text item to attach the variant to.
 */
export const attachVariantActionAtom = atom(
  null,
  async (get, set, props: { variant: AddVariantData; updateType: AddVariantUpdateType; textItemId: string }) => {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");

    const workspaceId = get(workspaceIdAtom);
    if (!workspaceId) throw new Error("workspaceIdAtom is not set");

    // update text item optimistically
    const textItemAtom = textItemFamilyAtom(props.textItemId);
    const textItem = await get(textItemAtom);
    const textItemBlockId = textItem.blockId;
    const originalTextItemVariants = (await get(textItemAtom)).variants;
    const originalWorkspaceVariants = await get(variantsAtom);

    // If we're attaching an existing variant, use its ID
    // Otherwise, we're creating a new variant, so generate an optimistic ID for Jotai and to save to backend
    const variantId = props.variant.variantId ? props.variant.variantId : new ObjectID().toString();

    // If update type is CREATE, we're creating a new variant
    // Optimistically add variant to store of workspace variants
    if (props.updateType === "CREATE") {
      const newVariant = {
        name: props.variant.name,
        _id: variantId,
        workspace_ID: workspaceId,
        description: "",
        apiID: "",
        docs: [],
        components: [],
        folder_id: null,
        isSample: false,
      } as IFVariant;

      // Update the workspace variants to include the new variant
      set(variantsAtom, [...originalWorkspaceVariants, newVariant]);
    }

    // Update the text item to include the new variant in Jotai
    set(textItemAtom, (prev) => ({
      ...prev,
      variants: [
        // Push new variant to the front of the array
        {
          variantId: variantId,
          text: serializeTipTapRichText(props.variant.rich_text).text,
          rich_text: props.variant.rich_text,
          status: props.variant.status,
          lastSync: null,
          lastSyncRichText: null,
          variables: [],
          plurals: [],
          text_last_modified_at: new Date(),
        },
        ...prev.variants,
      ],
    }));

    // Update project atom to reflect the new variant
    // Find the block that contains the updated text item and update its variantIds field
    const project = await get(projectAtom);
    const variants = await get(variantsAtom);
    const updatedBlocks = project.blocks.map((block) => {
      if (block._id !== textItemBlockId) return block;

      // Spread existing IDs first, then add new one
      const existingVariantIds = block.variantIds ?? [];
      const uniqueVariantIds = [...new Set([...existingVariantIds, variantId])];

      // Sort variant IDs by their associated names
      const sortedVariantIds = uniqueVariantIds.sort((a, b) => {
        // Handle BASE_VARIANT_ID (should always be first)
        if (a === BASE_VARIANT_ID) return -1;
        if (b === BASE_VARIANT_ID) return 1;

        // Find variant names
        const variantA = variants.find((v) => v._id === a);
        const variantB = variants.find((v) => v._id === b);

        // Use case sensitive comparison (match mongo sort order)
        return (variantA?.name ?? "") < (variantB?.name ?? "") ? -1 : 1;
      });
      return {
        ...block,
        variantIds: sortedVariantIds,
      };
    });
    set(projectAtom, { ...project, blocks: updatedBlocks });

    try {
      const [request] = httpDittoProject.updateTextItems({
        projectId,
        updates: [
          {
            textItemIds: [props.textItemId],
            richText: props.variant.rich_text,
            status: props.variant.status,
          },
        ],
        ...(props.updateType === "CREATE"
          ? {
              newVariant: {
                name: props.variant.name ?? "",
                variantId,
              },
            }
          : {}),
        ...(props.updateType === "ATTACH" ? { variantId } : {}),
      });
      await request;
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong attaching the variant to this text item" });
      logger.error(`Error updating text item with id ${props.textItemId}}`, { context: { projectId } }, e);

      // revert optimistic update
      set(variantsAtom, originalWorkspaceVariants);
      set(textItemAtom, (prev) => ({ ...prev, variants: originalTextItemVariants }));
      set(projectAtom, project);
    }
  }
);

/**
 * Action atom for editing a text item's variant data.
 * Optimistically updates the variant data of the text item atom (from textItemFamilyAtom) for the given textItemId.
 * @param textItemId - The id of the text item to update.
 * @param update - The update object for the text item's variant, requires variantId as a property.
 */
export const updateTextItemVariantActionAtom = atom(
  null,
  async (get, set, props: { textItemId: string; update: ITextItemVariantUpdate }) => {
    const projectId = get(projectIdAtom);
    if (!projectId) throw new Error("projectIdAtom is not set");

    // Update the text item's variant data in Jotai
    const textItemAtom = textItemFamilyAtom(props.textItemId);
    const textItem = await get(textItemAtom);

    // Find the variant index to update (if index not found, attach the variant)
    const variantToUpdateIndex = textItem.variants.findIndex((v) => v.variantId === props.update.variantId);

    const originalTextItemVariants = [...textItem.variants];
    let textItemVariantsUpdated = [...textItem.variants];

    if (variantToUpdateIndex === -1) {
      // If the variant doesn't exist on the text item, create and push to front
      const newVariant = {
        variantId: props.update.variantId,
        text: props.update.richText ? serializeTipTapRichText(props.update.richText).text : "",
        rich_text: props.update.richText ?? createEmptyRichText(),
        status: props.update.status ?? "NONE",
        lastSync: null,
        lastSyncRichText: null,
        variables: [],
        plurals: [],
        text_last_modified_at: new Date(),
      };
      textItemVariantsUpdated = [newVariant, ...textItemVariantsUpdated];
    } else {
      // Create a deep copy of the variant to update, so we can revert if the update fails
      const updatedTextItemVariant = structuredClone(textItem.variants[variantToUpdateIndex]);
      textItemVariantsUpdated[variantToUpdateIndex] = {
        ...updatedTextItemVariant,
        ...(props.update.richText
          ? { rich_text: props.update.richText, text: serializeTipTapRichText(props.update.richText).text }
          : {}),
        ...(props.update.status ? { status: props.update.status } : {}),
      };
    }

    set(textItemAtom, (prev) => ({
      ...prev,
      variants: textItemVariantsUpdated,
    }));

    try {
      const [request] = httpDittoProject.updateTextItems({
        projectId,
        updates: [
          {
            textItemIds: [props.textItemId],
            ...(props.update.status ? { status: props.update.status } : {}),
            ...(props.update.richText ? { richText: props.update.richText } : {}),
          },
        ],
        variantId: props.update.variantId,
      });
      await request;
    } catch (e) {
      set(showToastActionAtom, { message: "Something went wrong updating the variant for this text item" });
      logger.error(`Error updating text item with id ${props.textItemId}`, { context: { projectId } }, e);

      // revert optimistic update
      set(textItemAtom, (prev) => ({ ...prev, variants: originalTextItemVariants }));
    }
  }
);
