const ROOT = "/workspace";

export default {
  get: {
    users: {
      url: `${ROOT}/users`,
    },
    tags: {
      url: `${ROOT}/tags`,
    },
    projectTags: {
      url: `${ROOT}/projectTags`,
    },
    apiTokens: {
      url: `${ROOT}/api-tokens`,
    },
    connectionApiTokens: {
      url: `${ROOT}/connection-api-tokens`,
    },
  },
  post: {
    enableDevMode: {
      url: `${ROOT}/enableDevMode`,
    },
    enableAdvancedBranching: {
      url: `${ROOT}/enableAdvancedBranching`,
    },
    updateShareLinkIds: {
      url: `${ROOT}/updateShareLinkIds`,
      body: ({ id, role }) => ({ id, role }),
    },
    validateShareLinkId: {
      url: `${ROOT}/validateShareLinkId`,
      body: ({ wsShareId, role }) => ({ wsShareId, role }),
    },
    apiToken: {
      url: `${ROOT}/api-token`,
      body: ({ name, connection }) => ({
        name,
        connection,
      }),
    },
  },
  put: {
    usersRemove: {
      url: `${ROOT}/users/remove`,
      body: ({ email, userId }) => ({ email, userId }),
    },
    usersRole: {
      url: `${ROOT}/users/role`,
      body: ({ userId, role }) => ({ userId, role }),
    },
    name: {
      url: `${ROOT}/name`,
      body: ({ name }) => ({ name }),
    },
    upgrade: {
      url: `${ROOT}/upgrade`,
      body: ({ email, name, payment_method, num_editors, num_commenters, is_annual }) => ({
        email,
        name,
        payment_method,
        num_editors,
        num_commenters,
        is_annual,
      }),
    },
    figma_token: {
      url: `${ROOT}/figma_token`,
      body: ({ figma_token }) => ({ figma_token }),
    },
    devTools: {
      url: `${ROOT}/updateDevTools`,
      body: ({ devToolsEnabled }) => ({ devToolsEnabled }),
    },
  },
  delete: {
    apiToken: {
      url: (apiKeyId) => `${ROOT}/api-token/${apiKeyId}`,
      body: ({ id }) => ({ id }),
    },
    sampleData: {
      url: `${ROOT}/deleteSampleData`,
    },
  },
};
