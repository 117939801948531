import classNames from "classnames";
import React from "react";
import { Tag } from "./Tag";

import style from "./Components.module.css";

interface IComponentStatusProps {
  status: string | null | undefined;
  className?: string;
}

export function ComponentStatus(props: IComponentStatusProps) {
  const { status, className } = props;
  return (
    <div
      className={classNames(
        [
          {
            [style.status]: true,
            [style.none]: !status || status === "NONE",
            [style.wip]: status === "WIP",
            [style.review]: status === "REVIEW",
            [style.final]: status === "FINAL",
          },
        ],
        className
      )}
    />
  );
}

interface INotesAndTagsProps {
  notes: string;
  tags: string[];
  className?: string;
  classNameTag?: string;
  classNameNotes?: string;
}

export function NotesAndTags(props: INotesAndTagsProps) {
  if (!(props.notes || props.tags?.length > 0)) {
    return <React.Fragment />;
  }

  return (
    <div className={classNames([style.compDiffNotes, props.className])}>
      <span className={props.classNameNotes}>{props.notes}</span>
      {props.tags && (
        <div>
          {props.tags.map((text, index) => (
            <Tag key={index} is_selected text={text} className={props.classNameTag} />
          ))}
        </div>
      )}
    </div>
  );
}
