import { atom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { z } from "zod";
import { projectIdAtom } from "./Project";

const ZHiddenField = z.object({
  value: z.string(),
  label: z.string(),
  isHidden: z.boolean(),
});
const ZHiddenFields = z.object({
  status: ZHiddenField,
  assigned: ZHiddenField,
  tags: ZHiddenField,
  notes: ZHiddenField,
  variants: ZHiddenField,
  comments: ZHiddenField,
  instances: ZHiddenField,
  plurals: ZHiddenField,
  developerID: ZHiddenField,
});

type HiddenFields = z.infer<typeof ZHiddenFields>;

const initialHiddenFields: HiddenFields = {
  status: {
    value: "status",
    label: "Status",
    isHidden: false,
  },
  assigned: {
    value: "assigned",
    label: "Assigned",
    isHidden: false,
  },
  tags: {
    value: "tags",
    label: "Tags",
    isHidden: false,
  },
  notes: {
    value: "notes",
    label: "Notes",
    isHidden: false,
  },
  variants: {
    value: "variants",
    label: "Variants",
    isHidden: false,
  },
  comments: {
    value: "comments",
    label: "Comments",
    isHidden: false,
  },
  instances: {
    value: "instances",
    label: "Instances",
    isHidden: false,
  },
  plurals: {
    value: "plurals",
    label: "Plurals",
    isHidden: false,
  },
  developerID: {
    value: "developerID",
    label: "Developer ID",
    isHidden: false,
  },
};

const STORAGE_KEY_PREFIX = "hidden-fields-map-";

// MARK: Storage Atom
const hiddenFieldsWithStorageAtomFamily = atomFamily((projectId: string) => {
  const storageAtom = atomWithStorage<HiddenFields>(`${STORAGE_KEY_PREFIX}${projectId}`, initialHiddenFields, {
    getItem(key, initialValue) {
      const storedValue = localStorage.getItem(key);

      try {
        return ZHiddenFields.parse(JSON.parse(storedValue ?? ""));
      } catch {
        return initialValue;
      }
    },
    setItem(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
    // subscribe to window storage event to persist state across tab switching
    subscribe(key, callback, initialValue) {
      if (typeof window === "undefined" || typeof window.addEventListener === "undefined") {
        return () => {};
      }

      const storageListener = (e: StorageEvent) => {
        if (e.storageArea === localStorage && e.key === key) {
          let newValue;
          try {
            newValue = ZHiddenFields.parse(JSON.parse(e.newValue ?? ""));
          } catch {
            newValue = initialValue;
          }
          callback(newValue);
        }
      };
      window.addEventListener("storage", storageListener);
      return () => window.removeEventListener("storage", storageListener);
    },
  });

  return storageAtom;
});

// MARK: Derived Atoms
// derived object map of hidden fields from local storage atom
export const hiddenFieldsAtom = atom((get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return null;
  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);
  const hiddenFields = get(hiddenFieldsWithStorageAtom);

  return hiddenFields;
});

// derived list of hidden fields from local storage atom
export const hiddenFieldsListAtom = atom((get) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return null;
  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);
  const hiddenFields = get(hiddenFieldsWithStorageAtom);

  return Object.values(hiddenFields);
});

// MARK: Actions
export const updateHiddenFieldsActionAtom = atom(
  null,
  (get, set, updatedFields: { value: string; label: string; isHidden: boolean }[]) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return null;

    const updatedFieldsMap = {};
    const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);

    updatedFields.forEach((field) => {
      updatedFieldsMap[field.value] = field;
    });
    set(hiddenFieldsWithStorageAtom, updatedFieldsMap as HiddenFields);
  }
);

export const resetHiddenFieldsActionAtom = atom(null, (get, set) => {
  const projectId = get(projectIdAtom);
  if (!projectId) return null;

  const hiddenFieldsWithStorageAtom = hiddenFieldsWithStorageAtomFamily(projectId);

  set(hiddenFieldsWithStorageAtom, initialHiddenFields);
});
