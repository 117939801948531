import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import styles from "./styles.module.css";

interface Props {
  connectionName: string;
  connectionDescription: React.ReactNode;
  learnMoreLink?: string;
  hasConnection: boolean;
  logoSrc: string;
  connectedText?: string;
  Connected: () => JSX.Element;
  NotConnected: () => JSX.Element;
  onHide: () => void;
}

function ConnectionModal(props: Props) {
  const {
    connectionName,
    connectionDescription,
    hasConnection,
    learnMoreLink,
    logoSrc,
    connectedText,
    Connected,
    NotConnected,
    onHide,
  } = props;

  return (
    <BootstrapModal show onHide={onHide} centered>
      <BootstrapModal.Header className={styles.modalHeader}>
        <BootstrapModal.Title className={styles.modalTitle}>
          {hasConnection ? "Manage Connection" : "Add Connection"}
        </BootstrapModal.Title>
        <CloseIcon className={styles.closeIcon} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={styles.modalBody}>
        <div className={styles.bodyLogo}>
          <img src={logoSrc} alt={`${connectionName} logo`} />
        </div>
        <div>
          <div className={styles.contentHeader}>
            <span className={styles.connectionName}>{connectionName}</span>
            {hasConnection && (
              <span className={styles.connectedBadge}>
                <CheckIcon className={styles.icon} />
                {connectedText || "Connected"}
              </span>
            )}
          </div>
          <div className={styles.contentText}>
            {connectionDescription}
            <br />
            {learnMoreLink && (
              <>
                <br />
                <a className={styles.link} target="_blank" href={learnMoreLink}>
                  Learn more
                </a>
              </>
            )}
          </div>
          {hasConnection ? <Connected /> : <NotConnected />}
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
}

export default ConnectionModal;
