import { ITagState } from "@/hooks/useSearchState";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { userHasBillingRole } from "@shared/frontend/userPermissionContext";
import { ActualComponentInterface } from "@shared/types/TextItem";
import classnames from "classnames";
import React, { useMemo } from "react";
import Masonry from "react-masonry-css";
import { useHistory } from "react-router-dom";
import spinner from "../../../../assets/small-spinner.gif";
import Comp from "../../../../components/comp";
import SearchAndFilters from "../../../../components/searchAndFilters/searchAndFilters";
import { CommentState } from "../../types";
import style from "./style.module.css";

interface Props {
  selectComp: (comp: ActualComponentInterface<string>) => void;
  selectedId: string | null;
  results: ActualComponentInterface<string>[];
  allComps: ActualComponentInterface<string>[];
  handleQueryChange: (query: string) => void;
  loadingSearch: boolean;
  loadingComps: boolean;
  tagState: ITagState;
  selectTag: (tagName: string) => void;
  clearSelectedTags: () => void;
  statusFilter: string;
  setStatusFilter: (filter: string) => void;
  allFetched: boolean;
  query: string;
  setQuery: (query: string) => void;
  assignee;
  setAssignee;
  devID: string | null;
  setDevID: (id: string) => void;
  setPanelState: (panelState: string) => void;
  setCommentState: (updatedState: CommentState) => void;
  loadMoreComps;
  numLoadedComps;
  debouncedQuery;
  noMoreSearchResults: boolean;
}

const SearchResults = ({
  selectComp,
  selectedId,
  results,
  allComps,
  handleQueryChange,
  loadingSearch,
  loadingComps,
  selectTag,
  clearSelectedTags,
  statusFilter,
  setStatusFilter,
  allFetched,
  query,
  setQuery,
  assignee,
  setAssignee,
  devID,
  setDevID,
  setPanelState,
  setCommentState,
  loadMoreComps,
  numLoadedComps,
  debouncedQuery,
  noMoreSearchResults,
  tagState,
}: Props) => {
  const isEditEnabled = userHasBillingRole("editor");

  const displayApiIds = useMemo(() => {
    const apiIdQueryMatch = query.match(/^(id)=(.*)/i);
    return Boolean(apiIdQueryMatch && apiIdQueryMatch[2]) || devID !== null;
  }, [query, devID]);

  const masonryBreakpoints = {
    default: 3,
    1100: 3,
    786: 2,
    650: 1,
  };
  const history = useHistory();

  const isSelected = (id: string) => {
    return id == selectedId;
  };
  const comps =
    debouncedQuery === "" &&
    tagState.selected.size === 0 &&
    statusFilter === "Any" &&
    assignee === null &&
    devID === null
      ? allComps
      : results;

  const goToProjectsPage = () => history.push("/projects");

  return (
    <div className={style.results}>
      <SearchAndFilters
        tagState={tagState}
        query={query}
        setQuery={setQuery}
        assignee={assignee}
        setAssignee={setAssignee}
        devID={devID}
        setDevID={setDevID}
        onChange={handleQueryChange}
        name={"your workspace"}
        loading={loadingSearch}
        docSearch={false}
        status={statusFilter}
        chooseStatus={setStatusFilter}
        clearSelectedTags={clearSelectedTags}
        selectTag={selectTag}
        fullWidth={!selectedId}
        searchDisabled={allComps.length === 0}
        onInitialChange={undefined}
        inheritWidth={undefined}
        filteredCompIds={undefined}
        multiSelectedIds={undefined}
        setMultiSelected={undefined}
        customInputs={undefined}
        scrollToId={undefined}
        normalFiltersHidden={undefined}
      />
      <div data-testid="search-results" className={style.searchResults}>
        <div className={classnames("container", style.container)}>
          <div className={classnames("row", style.row)}>
            <div className="col-12">
              <Masonry
                breakpointCols={masonryBreakpoints}
                className={style.masonryGrid}
                columnClassName={style.masonryGridCol}
              >
                {comps.slice(0, numLoadedComps).map(function (component, index) {
                  if (
                    !component.is_hidden &&
                    (statusFilter === "Any" || statusFilter.toUpperCase() === component.status)
                  ) {
                    return (
                      <div key={index}>
                        <Comp
                          component={component}
                          selectComp={selectComp}
                          is_selected={isSelected(component._id)}
                          key={component._id}
                          setPanelState={setPanelState}
                          setCommentState={setCommentState}
                          displayApiIds={displayApiIds}
                          apiID={component.apiID}
                          is_suggested={false}
                          is_ws_comp={false}
                          ws_comp_id={null}
                          compName={null}
                        />
                      </div>
                    );
                  }
                })}
              </Masonry>
              {(loadingComps || loadingSearch) && (
                <div className={style.loadMore}>
                  <div>Fetching</div>
                  <img className={style.loading} src={spinner} />
                </div>
              )}
              {!loadingComps &&
                !loadingSearch &&
                (query !== "" || tagState.selected.size !== 0) &&
                results.length === 0 &&
                !(loadingComps || loadingSearch) && (
                  <div className={style.loadMore}>
                    Sorry, we couldn't find any results for your search! Try adjusting your search or loosening your
                    filters.
                  </div>
                )}
              {!loadingComps && !loadingSearch && allComps.length === 0 && (
                <div className={style.empty}>
                  <div className={style.label}>No text in your workspace yet</div>
                  <div>
                    {`${
                      isEditEnabled ? `As you create projects` : `As your teammates add projects`
                    }, you’ll be able to view and search text
                        from all of ${isEditEnabled ? `your projects` : `them`} here `}
                  </div>
                  {isEditEnabled && (
                    <div className={style.cta}>
                      <a onClick={goToProjectsPage}>Create your first project</a>
                    </div>
                  )}
                </div>
              )}
              {(numLoadedComps < comps.length || !allFetched) &&
                !noMoreSearchResults &&
                comps.length !== 0 &&
                !loadingComps && (
                  <div className={style.loadMore} onClick={loadMoreComps}>
                    <div>Load more</div>
                    <ArrowDownwardIcon className={style.icon} />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
