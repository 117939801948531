import { DRAFTED_GROUPS_PAGE_NAME } from "@shared/types/ActualDocument";
import React, { useContext, useMemo, useState } from "react";
import { Page } from "../../state/types";
import { PageSelected } from "../../state/usePageState";
import { ManageGroupsModalContext } from "./ManageGroupsModalContext";
import CurrentGroupsModal from "./components/CurrentGroupsModal";
import SelectFramesModal from "./components/SelectFramesModal";

export const DRAFT_PAGE_ID = "__draft__";

interface Props {
  docId: string;
  devModeEnabled: boolean;
  isFigmaAuthenticated: boolean;
  figmaFileId: string;
  figmaBranchId: string;
  onHide: () => void;
  resync: () => void;
  handleUnlinkGroups: (input: {
    projectId: string;
    deletedGroupIds: string[];
    unlinkedGroupIds: string[];
  }) => Promise<void>;
  forceShowToast: (input: any) => void;
  pages: Page[];
}
const ManageGroupsModal = (props: Props) => {
  const {
    docId,
    devModeEnabled,
    isFigmaAuthenticated,
    figmaFileId,
    figmaBranchId,
    onHide,
    resync,
    handleUnlinkGroups,
    forceShowToast,
    pages,
  } = props;

  const { groupsByPage } = useContext(ManageGroupsModalContext);

  const [showSelectFramesModal, setShowSelectFramesModal] = useState<boolean>(false);

  const allPages: PageSelected[] = useMemo(() => {
    let formatedPages = pages.map((page) => ({
      id: page.figma_id,
      name: page.name,
    }));
    const isDraftPage = groupsByPage[DRAFT_PAGE_ID];
    if (isDraftPage) {
      return [{ id: DRAFT_PAGE_ID, name: DRAFTED_GROUPS_PAGE_NAME }].concat(formatedPages);
    }
    return formatedPages;
  }, [pages, groupsByPage]);

  const onShowSelectFrames = () => setShowSelectFramesModal(true);
  const handleGoBack = () => setShowSelectFramesModal(false);

  return (
    <>
      {showSelectFramesModal ? (
        <SelectFramesModal
          docId={docId}
          isFigmaAuthenticated={isFigmaAuthenticated}
          figmaFileId={figmaFileId}
          figmaBranchId={figmaBranchId}
          onHide={onHide}
          resync={resync}
          forceShowToast={forceShowToast}
          handleGoBack={handleGoBack}
        />
      ) : (
        <CurrentGroupsModal
          docId={docId}
          pages={allPages}
          devModeEnabled={devModeEnabled}
          isFigmaAuthenticated={isFigmaAuthenticated}
          figmaFileId={figmaFileId}
          onHide={onHide}
          handleUnlinkGroups={handleUnlinkGroups}
          onShowSelectFrames={onShowSelectFrames}
        />
      )}
    </>
  );
};

export default ManageGroupsModal;
