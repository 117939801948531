import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import style from "./index.module.css";

export type Status = "NONE" | "WIP" | "REVIEW" | "FINAL";

interface IOverlappingBubblesProps {
  className?: string;
  style?: React.CSSProperties;

  statuses: Status[];
  size?: "default" | "sm";
  noneStatusClassName?: string;

  // Flag for whether all status bubbles are filled (currently only NONE status defaults to outline)
  filledBubbles?: boolean;

  // Limit the number of bubbles to display
  bubbleLimit?: number;
}

const SIZE_MARGINS = {
  sm: -0.3,
  default: -2,
};

function OverlappingBubbles(props: IOverlappingBubblesProps) {
  const size = props.size ?? "default";
  return (
    <div className={style.overlappingBubbles}>
      {props.statuses.slice(0, props.bubbleLimit).map((status, index) => (
        <div
          key={index}
          className={classNames(style.bubble, style[`status-${status}`], style.overlap, style[`size-${size}`], {
            [style.filledBubbles]: props.filledBubbles,
          })}
          style={{
            zIndex: props.statuses.length - index,
            marginLeft: index === 0 ? 0 : SIZE_MARGINS[size],
          }}
        ></div>
      ))}
      {!!props.bubbleLimit && props.statuses.length > props.bubbleLimit && (
        <Icon className={style.overflowIcon} Icon={<AddIcon />} size="xxxs" color="secondary" />
      )}
    </div>
  );
}

export function Bubble({ status }: { status: Status }) {
  return <div className={classNames(style.bubble, style[`status-${status}`])}></div>;
}

export default OverlappingBubbles;
