import z from "zod";

const GroupUpdateAction = z.object({
  type: z.literal("group-update"),
  groupId: z.string(),
  newFrameId: z.string().optional().nullable(),
  oldFrameId: z.string().optional().nullable(),
  newPageId: z.string().optional().nullable(),
  newFrameName: z.string().optional().nullable(),
});
export type IGroupUpdateAction = z.infer<typeof GroupUpdateAction>;

const AddPageAction = z.object({
  type: z.literal("add-page"),
  page: z.object({
    name: z.string(),
    figma_id: z.string(),
  }),
});
export type IAddPageAction = z.infer<typeof AddPageAction>;

export const ConfirmFrameRemovalResult = z.object({
  projectId: z.string(),
  actions: z.array(z.discriminatedUnion("type", [GroupUpdateAction, AddPageAction])),
});

export type IConfirmFrameRemovalResult = z.infer<typeof ConfirmFrameRemovalResult>;
