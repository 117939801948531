import classnames from "classnames";
import React from "react";

import style from "./style.module.css";

const Tag = (props) => {
  const { text, is_selected, count, onClick } = props;

  return (
    <button
      data-testid="tag"
      className={classnames({
        [style.tag]: true,
        [style.selected]: is_selected,
        [style.pointer]: !!onClick,
      })}
      onClick={onClick}
    >
      {text}
      {count && <span className={style.count}>{count}</span>}
    </button>
  );
};

export default Tag;
