import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import TipTapText from "@tiptap/extension-text";
import { ReactRenderer, useEditor } from "@tiptap/react";
import tippy, { Instance } from "tippy.js";
import { IUser } from "../../../../shared/types/User";
import MentionList from "../../molecules/MentionList";
import style from "./index.module.css";

/**
 * Sets up the TipTap editor for writing comments
 * @param mentionableUsers Array of users that can be mentioned
 * @param placeholderText Placeholder text for when the input is empty
 * @returns an Editor instance
 */
export default function useCommentEditor(mentionableUsers: IUser[], placeholderText: string) {
  return useEditor({
    extensions: [
      Document,
      Paragraph,
      TipTapText,
      Placeholder.configure({
        placeholder: placeholderText,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: style.mention,
        },
        renderLabel: ({ options, node }) => {
          const mentionUserName = mentionableUsers.find((user) => user.userId === node.attrs.id)?.name;
          return `${options.suggestion.char}${mentionUserName}`;
        },
        suggestion: {
          allowSpaces: false,
          items: ({ query, editor }) => {
            const existingMentionIds =
              (editor.getJSON().content ?? [])[0].content?.flatMap((item) =>
                item.type === "mention" ? [item.attrs?.id] : []
              ) ?? [];

            return mentionableUsers
              .filter(
                (user) =>
                  !existingMentionIds.includes(user.userId) &&
                  user.name.toLowerCase().replace(/\s/g, "").includes(query.toLowerCase())
              )
              .slice(0, 10);
          },
          render: () => {
            let component: ReactRenderer<any>;
            let popup: Instance[];

            return {
              onStart: (props) => {
                component = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                if (!props.clientRect) {
                  return;
                }

                popup = tippy("body", {
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start",
                });
              },

              onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                  return;
                }

                popup[0].setProps({
                  // @ts-ignore TODO Fix this type error
                  getReferenceClientRect: props.clientRect,
                });
              },

              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return component.ref?.onKeyDown(props);
              },

              onExit() {
                popup[0].destroy();
                component.destroy();
              },
            };
          },
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: `commentEditorInnerContent ${style.commentEditorInnerContent}`,
        "data-testid": "comment-input",
      },
    },
    content: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [],
        },
      ],
    },
  });
}
