import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

/**
 * Given a `titleMap` object where the keys are strings to match
 * against the beginning of the current path, and the values are the
 * title that should be rendered, returns what the title of the current
 * page should be.
 * @param {*} titleMap
 * @param {*} titleDefault the title to render if no match for the
 * current path is found in `titleMap`
 * @returns
 */
const usePageTitle = (titleMap, titleDefault = "Ditto") => {
  const { pathname } = useLocation();

  return useMemo(() => {
    for (const [key, title] of Object.entries(titleMap)) {
      const basePathMatch = key.match(/\/\w+/);
      if (!basePathMatch) continue;

      const basePath = basePathMatch[0];
      const pattern = new RegExp("^" + basePath);
      if (pattern.test(pathname)) {
        return title;
      }
    }

    return titleDefault;
  }, [pathname, titleMap, titleDefault]);
};

/**
 * A component that uses react-helmet to determine what the current
 * page's title should be. Must be used inside of a <Router />
 * component. Can be overwritten by rendering additional instances
 * of <Helmet /> further down in the component hierarchy.
 */
export default function PageTitle({ titleMap, titleDefault }) {
  const title = usePageTitle(titleMap, titleDefault);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
