import classnames from "classnames";
import React from "react";
import ReactTooltip from "react-tooltip";

import style from "./style.module.css";

interface Props {
  text: string;
  action: () => void;
  className?: string;
  disabled: boolean;
}

const NewGroupCard = (props: Props) => {
  const { text, action, className, disabled } = props;

  const onClick = () => {
    if (!disabled) action();
  };
  return (
    <div
      data-tip
      data-for="new-group"
      data-testid={text.toLowerCase().replace(/\s/g, "-")}
      className={classnames([style.newGroupCard], [style.newFolderCard], { [style.disabled]: disabled }, className)}
      onClick={onClick}
    >
      {text}
      {disabled && (
        <ReactTooltip id={"new-group"} place="bottom" effect="solid">
          Please wait until resync has completed.
        </ReactTooltip>
      )}
    </div>
  );
};

export default NewGroupCard;
