import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import lightbulbEmoji from "../../assets/lightbulb_emoji.png";

import style from "./style.module.css";

interface IProps {
  text: string;
  linkUrl: string;
  className?: string;
}

const ModalInfoAlert = (props: IProps) => {
  const { text, linkUrl, className } = props;

  return (
    <div className={classNames([style.infoAlert, className])}>
      <img className={style.emoji} src={lightbulbEmoji} />
      <div>
        {text}{" "}
        <a href={linkUrl} target="_blank" rel="noreferrer">
          Learn more
        </a>
      </div>
    </div>
  );
};

ModalInfoAlert.propTypes = {
  text: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default ModalInfoAlert;
