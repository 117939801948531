import { useWorkspace } from "@/store/workspaceContext";
import React, { useEffect, useMemo, useState } from "react";
import { userHasSomeResourceAccess } from "../../../../../shared/frontend/userPermissionContext";
import { WEBHOOK_SIGNING_KEY_MIN_LENGTH } from "../../../../../shared/lib/webhook";
import { IFWorkspaceWebhooks, IWebhookConfig } from "../../../../../shared/types/Workspace";
import { useOverlayToast } from "../../../../components/OverlayToast/useOverlayToast";
import { useComponentFolders } from "../../../../components/webhooks/useComponentFolders";
import { useWebhookModal } from "../../../../components/webhooks/useWebhookModal";
import http, { API } from "../../../../http";
import * as httpWorkspace from "../../../../http/workspaceNew";

type ISigningKeyState = (
  | {
      type: "loading";
    }
  | {
      type: "unset";
      value: string;
    }
  | {
      type: "set";
      value: string;
      editable: boolean;
    }
) & { error?: string };
export function useWebhooksTab() {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const [signingKeyState, setSigningKeyState] = useState<ISigningKeyState>({
    type: "loading",
  });

  const { overlayToastProps, showToast } = useOverlayToast();

  const devToolsEnabled = workspaceInfo?.devTools;
  const webhooks = workspaceInfo?.config.webhooks;
  const canEditComponentsConfig = userHasSomeResourceAccess("component_folder", "edit");
  const canEditProjectsConfig = userHasSomeResourceAccess("project_folder", "edit");
  const webhooksEnabled = canEditComponentsConfig || canEditProjectsConfig;

  useEffect(
    function reconcileSigningKeyState() {
      if (!webhooks?.signingKeyPreview) {
        setSigningKeyState({ type: "unset", value: "" });
        return;
      }

      setSigningKeyState({
        type: "set",
        value: webhooks.signingKeyPreview,
        editable: false,
      });
    },
    [webhooks?.signingKeyPreview]
  );

  const saveSigningKeyRequest = async (signingKey: string) => {
    const [request] = httpWorkspace.updateWebhookSigningKey(signingKey);

    const {
      data: { signingKeyPreview },
    } = await request;

    return signingKeyPreview;
  };

  const onGenerateSigningKeyButtonClick = async () => {
    const signingKey = crypto.randomUUID();
    const hasClipboardSupport = !!window.navigator?.clipboard?.writeText;

    // if the browser doesn't have support for the clipboard API we're using,
    // just set the input's value to the generated key -- it's up to the user
    // to copy it and save it
    if (!hasClipboardSupport) {
      setSigningKeyState({
        type: "unset",
        value: signingKey,
        error: undefined,
      });
      return;
    }

    // if the browser DOES support the clipboard API
    // - save the signing key in the database
    // - copy the signing key to the clipboard
    // - set the input's value to the signing key preview
    // - show a success toast
    const signingKeyPreview = await saveSigningKeyRequest(signingKey);

    navigator.clipboard.writeText(signingKey);

    setSigningKeyState({
      type: "set",
      editable: false,
      value: signingKeyPreview,
      error: undefined,
    });

    showToast("A signing key has been generated and copied to your clipboard!", 3000);

    updateWorkspaceInfo((info) => ({
      ...info,
      config: {
        ...info.config,
        webhooks: {
          ...info.config.webhooks,
          signingKeyPreview,
        },
      },
    }));
  };

  const signingKeyInputDisabled =
    !webhooksEnabled ||
    signingKeyState.type === "loading" ||
    (signingKeyState.type === "set" && !signingKeyState.editable);
  const signingKeyInputPlaceholder =
    signingKeyState.type === "loading" ? "Loading..." : "A signing key with at least 16 characters";
  const signingKeyInputValue = signingKeyState.type === "loading" ? "" : signingKeyState.value;
  const signingKeyInputRef = React.createRef<HTMLInputElement>();

  const signingKeySaveButtonVisible =
    signingKeyState.type === "unset" || (signingKeyState.type === "set" && signingKeyState.editable);
  const signingKeySaveButtonText = signingKeyState.type === "unset" ? "Save" : "Save New Key";
  const signingKeySaveButtonDisabled = !devToolsEnabled || (!canEditComponentsConfig && !canEditProjectsConfig);

  const showCancelNewSigningKeyButton = signingKeyState.type !== "unset";

  const changeSigningKeyButtonVisible = signingKeyState.type === "set" && !signingKeyState.editable;
  const changeSigningKeyButtonDisabled = !devToolsEnabled || (!canEditComponentsConfig && !canEditProjectsConfig);

  const generateSigningKeyButtonVisible =
    webhooksEnabled &&
    (signingKeyState.type === "unset" || (signingKeyState.type === "set" && signingKeyState.editable));
  const generateSigningKeyButtonEnabled = generateSigningKeyButtonVisible && !signingKeyState.value.length;

  const onCancelNewSigningKeyButton = () => {
    setSigningKeyState((s) => {
      if (s.type !== "set") return s;
      return {
        ...s,
        value: webhooks?.signingKeyPreview || "",
        editable: false,
        error: undefined,
      };
    });
  };

  const onChangeSigningKeyClick = () => {
    setSigningKeyState((s) => {
      if (s.type !== "set") return s;
      return { ...s, value: "", editable: true };
    });
    setTimeout(() => signingKeyInputRef.current?.focus(), 1000);
  };

  const onSigningKeyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSigningKeyState((s) => {
      if (s.type === "loading") return s;
      return { ...s, value };
    });
  };

  const onSigningKeySaveButtonClick = async () => {
    if (signingKeyInputValue.length < WEBHOOK_SIGNING_KEY_MIN_LENGTH) {
      setSigningKeyState((s) => {
        return {
          ...s,
          error: `Please insert a key with at least ${WEBHOOK_SIGNING_KEY_MIN_LENGTH} characters.`,
        };
      });

      return;
    }

    const signingKeyPreview = await saveSigningKeyRequest(signingKeyInputValue);

    setSigningKeyState({
      type: "set",
      value: signingKeyPreview,
      editable: false,
    });

    updateWorkspaceInfo((info) => ({
      ...info,
      config: {
        ...info.config,
        webhooks: {
          ...info.config.webhooks,
          signingKeyPreview,
        },
      },
    }));
  };

  const [webhookModalState, setWebhookModalState] = useState<
    | { visible: false; webhook?: undefined }
    | {
        visible: true;
        webhook: IWebhookConfig | null;
        initialScreen: "main" | "deliveryHistory";
      }
  >({ visible: false });

  const webookModalProps = useWebhookModal({
    webhook: webhookModalState.webhook || null,
    onHide: () => setWebhookModalState({ visible: false, webhook: undefined }),
    onWebhookModified: async () => {
      const { url } = API.api.get.workspace;
      const { data } = await http.get<{
        workspaceInfo: {
          config: {
            webhooks: IFWorkspaceWebhooks;
          };
        };
      }>(url("current"));

      updateWorkspaceInfo((info) => ({
        ...info,
        config: {
          ...info.config,
          webhooks: data.workspaceInfo.config.webhooks,
        },
      }));
    },
  });

  const componentFolders = useComponentFolders();
  const componentFolderNameById = useMemo(
    () => (componentFolders.loading ? new Map() : new Map(componentFolders.data.map((c) => [c._id, c.name]))),
    [componentFolders]
  );

  const createWebhookButtonEnabled = devToolsEnabled && webhooksEnabled && signingKeyState.type === "set";

  // only show the tooltip if the reason that the button is disabled is
  // because the signing key is not set
  const showSigningKeyRequiredTooltip = devToolsEnabled && webhooksEnabled && signingKeyState.type !== "set";

  const onCreateWebhook = () => {
    if (!webhooksEnabled) return;
    setWebhookModalState({
      visible: true,
      webhook: null,
      initialScreen: "main",
    });
  };

  const onViewWebhook = (webhookId: string, initialScreen: "main" | "deliveryHistory") => {
    if (!webhooksEnabled) return;
    const webhook = webhooks?.endpoints.find((w) => w._id === webhookId);
    if (!webhook) return;
    setWebhookModalState({
      visible: true,
      initialScreen,
      webhook: {
        _id: webhook._id,
        url: webhook.url,
        name: webhook.name,
        events: webhook.events,
        enabled: webhook.enabled,
        filters: {
          componentFolderIds: webhook.filters.componentFolderIds || null,
        },
      },
    });
  };

  return {
    signingKeyState,
    signingKeyInputDisabled,
    signingKeyInputPlaceholder,
    signingKeyInputValue,
    signingKeyInputRef,
    signingKeySaveButtonVisible,
    signingKeySaveButtonText,
    signingKeySaveButtonDisabled,
    showCancelNewSigningKeyButton,
    changeSigningKeyButtonVisible,
    changeSigningKeyButtonDisabled,
    generateSigningKeyButtonVisible,
    generateSigningKeyButtonEnabled,
    onCancelNewSigningKeyButton,
    onChangeSigningKeyClick,
    onSigningKeyInputChange,
    onSigningKeySaveButtonClick,
    onGenerateSigningKeyButtonClick,
    onCreateWebhook,
    onViewWebhook,
    webhookModalState,
    webookModalProps,
    componentFolderNameById,
    webhooksEnabled,
    showSigningKeyRequiredTooltip,
    overlayToastProps,
    showToast,
    devToolsEnabled,
    createWebhookButtonEnabled,
    webhooks,
  };
}
