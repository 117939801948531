import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const QuickReplyIcon = (props: Props) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_11_707)">
        <path
          d="M13.75 2.5C13.75 1.8125 13.1875 1.25 12.5 1.25H2.5C1.8125 1.25 1.25625 1.8125 1.25625 2.5L1.25 13.75L3.75 11.25H9.375V6.25H13.75V2.5Z"
          fill="#19191A"
          fillOpacity="0.3"
        />
        <path
          d="M14.0625 10H12.6875L13.75 7.5H10.625V11.25H11.875V14.375L14.0625 10Z"
          fill="#19191A"
          fillOpacity="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_707">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuickReplyIcon;
