import { AuthenticatedAuthState, UnauthenticatedAuthState } from "@/hooks/auth";
import * as SegmentEvents from "@shared/segment-event-names";
import logger from "@shared/utils/logger";
import React, { createContext, useContext, useEffect, useState } from "react";
import useSegment from "../hooks/useSegment";
import http, { API } from "../http";

export const PROJECT_ID_KEY = "redirect_project_id";
export const FIGMA_AUTH_STATE_LOADING_KEY = "figma-auth-state-loading";
export const FIGMA_AUTH_STATE_KEY = "figma-auth-state";
export const FIGMA_AUTH_INFO_KEY = "figma-auth-info";

interface IFigmaAuthContextLoading {
  loading: true;
  isFigmaAuthenticated: false;
  handleFigmaAuthWithRedirect: () => Promise<void>;
  handleFigmaDisconnect: () => Promise<void>;
  refreshFigmaAuth: () => Promise<void>;
}

interface IFigmaAuthContextUnauthenticated {
  loading: false;
  isFigmaAuthenticated: false;
  handleFigmaAuthWithRedirect: () => Promise<void>;
  handleFigmaDisconnect: () => Promise<void>;
  refreshFigmaAuth: () => Promise<void>;
}

interface IFigmaAuthContextAuthenticated {
  loading: false;
  isFigmaAuthenticated: true;
  handleFigmaAuthWithRedirect: () => Promise<void>;
  handleFigmaDisconnect: () => Promise<void>;
  refreshFigmaAuth: () => Promise<void>;
}

type IFigmaAuthContext = IFigmaAuthContextLoading | IFigmaAuthContextUnauthenticated | IFigmaAuthContextAuthenticated;

const FigmaAuthContext = createContext({
  loading: true,
  isFigmaAuthenticated: false,
} as IFigmaAuthContext);

export function useFigmaAuth() {
  return useContext(FigmaAuthContext);
}

interface IFigmaAuthProvider {
  children: React.ReactNode;
  authState: AuthenticatedAuthState | UnauthenticatedAuthState;
}

export const FigmaAuthProvider = ({ children, authState }: IFigmaAuthProvider) => {
  // MARK: Hooks & State
  const segment = useSegment();
  const { loading, user, getTokenSilently, refreshUser } = authState;
  const [figmaAuthState, setFigmaAuthState] = useState<{
    loading: boolean;
    isFigmaAuthenticated: boolean;
  }>({
    loading: true,
    isFigmaAuthenticated: false,
  });

  // MARK: Effects

  useEffect(() => {
    if (!loading) {
      connectFigma();
    }
  }, [loading]);

  async function connectFigma() {
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=") &&
      localStorage.getItem(FIGMA_AUTH_STATE_LOADING_KEY)
    ) {
      const code = (window.location.search.match(/code=([^&]+)/) || [])[1];
      const state = (window.location.search.match(/state=([^&]+)/) || [])[1];
      fetchAccessToken(code, state);
    } else {
      // setLoadingFigmaAuth(false); // this was causing "no figma account connected" toast to show up too often
    }
  }

  async function fetchAccessToken(code: string, state: string) {
    const figmaAuthState = localStorage.getItem(FIGMA_AUTH_STATE_KEY);
    if (state !== figmaAuthState) {
      logger.error(
        "Error authenticating Figma because state did not match initial request",
        { context: { state, figmaAuthState } },
        new Error("Figma auth state mismatch")
      );
      setFigmaAuthState({ loading: false, isFigmaAuthenticated: false });
      localStorage.removeItem(FIGMA_AUTH_INFO_KEY);
      return;
    }
    localStorage.removeItem(FIGMA_AUTH_STATE_LOADING_KEY);
    localStorage.removeItem(FIGMA_AUTH_STATE_KEY);

    try {
      const token = await getTokenSilently();
      const redirectUri = window.location.href.split("?")[0];
      await fetch(`${process.env.BACKEND_URL}/user/figmaAuth`, {
        credentials: "include",
        method: "POST",
        headers: {
          "x-ditto-app": "web_app",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ code, redirectUri }),
      });

      refreshUser();
      setFigmaAuthState({ loading: false, isFigmaAuthenticated: true });

      if (localStorage.getItem(PROJECT_ID_KEY)) {
        const id = localStorage.getItem(PROJECT_ID_KEY);
        localStorage.removeItem(PROJECT_ID_KEY);
        window.location.href = "/projects/" + id;
      }
    } catch (err) {
      logger.error(err.message, { context: { code, state, figmaAuthState } }, err);
      setFigmaAuthState({ loading: false, isFigmaAuthenticated: false });
      localStorage.removeItem(FIGMA_AUTH_INFO_KEY);
    }
  }

  // Helper Methods
  async function validateToken() {
    const token = await getTokenSilently();
    try {
      const response = await fetch(`${process.env.BACKEND_URL}/user/figmaAuth`, {
        credentials: "include",
        method: "GET",
        headers: {
          "x-ditto-app": "web_app",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const { isTokenValid } = await response.json();
        if (isTokenValid) return true;
        else return false;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  // Methods Passed into context

  async function handleFigmaAuthWithRedirect() {
    localStorage.setItem(FIGMA_AUTH_STATE_LOADING_KEY, "true");

    segment.track({
      event: SegmentEvents.CLICKED_CONNECT_TO_FIGMA,
      properties: {
        figma_editor: user?.isFigmaEditor,
      },
    });
    const state = Math.random().toString();
    const redirectUri = window.location.href.split("?")[0];

    localStorage.setItem(FIGMA_AUTH_STATE_KEY, state);

    const queryParams = [
      `client_id=${process.env.FIGMA_CLIENT_ID}`,
      `redirect_uri=${redirectUri}`,
      `scope=files:read,file_variables:write,file_comments:write,file_variables:read,file_dev_resources:read,file_dev_resources:write,webhooks:write`,
      `state=${state}`,
      `response_type=code`,
    ].join("&");

    window.location.assign(`https://www.figma.com/oauth?${queryParams}`);

    return;
  }

  async function handleFigmaDisconnect() {
    const { url } = API.user.delete.figmaToken;
    await http.delete(url);
    localStorage.removeItem(FIGMA_AUTH_INFO_KEY);
    setFigmaAuthState({ loading: false, isFigmaAuthenticated: false });
  }

  const refreshFigmaAuth = async () => {
    if (user?.isFigmaAuthenticated) {
      const isTokenValid = await validateToken();
      setFigmaAuthState({ loading: false, isFigmaAuthenticated: isTokenValid });
    } else {
      setFigmaAuthState({ loading: false, isFigmaAuthenticated: false });
    }

    // In preparation for using figma refresh tokens, this code will pull the refresh token from local storage
    // and send it to the server for any users that authenticated before we deployed out the project in Jan of 2024
    const storedAuthInfo = localStorage.getItem(FIGMA_AUTH_INFO_KEY);

    if (storedAuthInfo) {
      const parsedAuthInfo = JSON.parse(storedAuthInfo);
      const token = await getTokenSilently();
      await fetch(`${process.env.BACKEND_URL}/user/figmaAuth`, {
        credentials: "include",
        method: "PUT",
        headers: {
          "x-ditto-app": "web_app",
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          figmaAuth: {
            token: parsedAuthInfo.access_token,
            refreshToken: parsedAuthInfo.refresh_token,
            expiresAt: 0, // force a refresh and set new expiration time on the backend
          },
        }),
      });
      localStorage.removeItem(FIGMA_AUTH_INFO_KEY);
    }
    // end block
  };

  useEffect(() => {
    refreshFigmaAuth();
  }, []);

  const state = {
    loading: figmaAuthState.loading,
    isFigmaAuthenticated: figmaAuthState.isFigmaAuthenticated,
    handleFigmaAuthWithRedirect,
    handleFigmaDisconnect,
    refreshFigmaAuth,
  } as IFigmaAuthContext;

  return <FigmaAuthContext.Provider value={state}>{children}</FigmaAuthContext.Provider>;
};
