import * as WorkspaceService from "@/http/workspaceNew";
import { useWorkspace } from "@/store/workspaceContext";
import { useEffect, useState } from "react";
import { Props as ResourceProps } from ".";

export default function useResourcesPanel() {
  const { workspaceInfo } = useWorkspace();
  const [resources, setResources] = useState<ResourceProps["resources"]>([]);

  const componentLimits = {
    free: 20,
    team: 200,
    growth: 750,
  };

  useEffect(
    function initialize() {
      fetchResourceCounts();
    },
    [workspaceInfo]
  );

  const fetchResourceCounts = async () => {
    const [request] = WorkspaceService.getResourceCounts();
    const { data } = await request;
    setResources([
      {
        resourceDittoComponentId: "components",
        value: data.components,
        limit: componentLimits[workspaceInfo?.plan],
      },
      {
        resourceDittoComponentId: "projects",
        value: data.projects,
      },
      {
        resourceDittoComponentId: "variants",
        value: data.variants,
      },
      {
        resourceDittoComponentId: "variables",
        value: data.variables,
      },
    ]);
  };

  return { resources };
}
