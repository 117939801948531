import * as Tabs from "@radix-ui/react-tabs";
import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import style from "./index.module.css";

export interface ITab {
  id: string;
  label: React.ReactNode;
  Content: React.FC;

  leadingIcon?: React.ReactNode | React.ReactSVGElement;
  trailingIcon?: React.ReactNode | React.ReactSVGElement;
  disabled?: boolean;
}

export interface ITabGroupProps {
  tabs: ITab[];

  /**
   * Index as string that identifies current selected tab.
   */
  value?: string;

  /**
   * Event handler when current selected tab changes.
   * @param value index of new selected tab
   * @returns
   */
  onChange?: (value: string) => void;

  /**
   * Optional element to render between each tab element in the tab list.
   */
  divider?: React.ReactNode | React.ReactSVGElement;
  className?: string;
  /**
   * Custom class name for styling tab list container.
   */
  listClassName?: string;
  /**
   * Custom class name for styling the content rendered below the selected tab.
   */
  contentClassName?: string;
  /**
   * Custom class name for styling the tab button element.
   */
  triggerClassName?: string;
  /**
   * Custom class name for styling the label content inside the tab.
   */
  labelClassName?: string;
  /**
   * Custom style for the tab group container.
   */
  style?: React.CSSProperties;
  /**
   * Override the default height of the tab list.
   */
  size?: "small" | "default";
  /**
   * Remove the lines under the active tab
   */
  noActiveTabLine?: boolean;
  /**
   * Tab styles
   */
  variant?: "Segmented" | "default";
}

export function TabGroup(props: ITabGroupProps) {
  const tabVariant = props.variant ?? "default";
  const { value, onChange, divider } = props;
  const tabIds = props.tabs.map((tab) => tab.id);

  const defaultTabId = tabIds[0];

  return (
    <Tabs.Root
      defaultValue={defaultTabId}
      value={value}
      onValueChange={(value) => onChange?.(value)}
      className={classNames(style.tabRoot, props.className)}
      data-testid="tab-group"
    >
      <Tabs.List
        className={classNames(
          style.tabList,
          style[`tabList${props.size ?? "default"}`],
          style[`tabList${tabVariant}`],
          props.listClassName
        )}
      >
        {props.tabs.map((tab, idx) => {
          return (
            <React.Fragment key={tab.id}>
              <Tabs.Trigger
                value={tab.id}
                className={classNames(
                  style.tabTrigger,
                  style[`tabTriggerSize${props.size ?? "default"}`],
                  style[`tabTriggerVariant${tabVariant}`],
                  {
                    [style.active]: value === tab.id,
                    [style.disabled]: tab.disabled,
                    [style.noActiveTabLine]: props.noActiveTabLine || props.variant === "Segmented",
                  },
                  props.triggerClassName
                )}
                disabled={tab.disabled}
                data-testid={`tab-group-tab-${tab.id}`}
              >
                {typeof tab.label === "string" && (
                  <Label
                    size={props.size === "small" ? "sm" : "base"}
                    leadingIcon={tab.leadingIcon}
                    trailingIcon={tab.trailingIcon}
                    className={classNames(style.tabLabel, props.labelClassName)}
                  >
                    {tab.label}
                  </Label>
                )}
                {typeof tab.label !== "string" && tab.label}
              </Tabs.Trigger>
              {divider &&
                idx !== props.tabs.length - 1 &&
                value !== idx.toString() &&
                value !== (idx + 1).toString() &&
                divider}
            </React.Fragment>
          );
        })}
        {props?.variant !== "Segmented" && <div className={style.spacer}></div>}
      </Tabs.List>

      {props.tabs.map((tab) => (
        <Tabs.Content
          value={tab.id}
          key={tab.id}
          className={classNames(style.tabContent, props.contentClassName)}
          data-testid={`tab-group-content-${tab.id}`}
        >
          <tab.Content />
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
}

export default TabGroup;
