import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

const MAX_FOLDER_NAME_LENGTH = 60;

interface Props {
  onHide: () => void;
  folderNames: string[];
  onCreateNewFolder: (folderName: string) => Promise<void>;
}
const NewComponentFolderModal = ({ onHide, folderNames, onCreateNewFolder }: Props) => {
  const [name, setName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const nameRef = useRef<HTMLInputElement>(null);

  const isValidFolderName = (input: string) => {
    return !folderNames.includes(input);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value.substring(0, MAX_FOLDER_NAME_LENGTH);
    if (!isValidFolderName(text)) {
      setError("This folder name already exists");
    } else {
      setError(null);
    }
    setName(text);
  };

  const checkPressEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !error) {
      createNewFolder();
    }
  };

  const createNewFolder = async () => {
    setIsSaving(true);
    await onCreateNewFolder(name);
    setIsSaving(false);
  };

  useEffect(() => {
    if (nameRef?.current) nameRef.current.focus();
  }, []);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Create a New Folder</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <div className={style.container}>
            <label className={style.label}>Folder name</label>
            <input
              data-testid="new-folder-name-input"
              type="text"
              ref={nameRef}
              placeholder="Enter a folder name"
              autoFocus
              className={error && style.errorInput}
              spellCheck="false"
              value={name}
              onChange={onChange}
              onKeyPress={checkPressEnter}
            />
            {error && <div className={style.errorMsg}>{error}</div>}
          </div>
          <span className={style.form}>
            <ButtonPrimary
              data-testid="new-folder-submit-button"
              text={!isSaving ? "Create" : "Creating..."}
              disabled={!name || Boolean(error) || isSaving}
              onClick={createNewFolder}
            />
          </span>
        </BootstrapModal.Body>
      </BootstrapModal>
    </div>
  );
};

export default NewComponentFolderModal;
