import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import style from "./style.module.css";

interface Props {
  showAdvancedBranchingModal: () => void;
}

const AdvancedBranchingToggleMessage = ({ showAdvancedBranchingModal }: Props) => {
  return (
    <div className={style.container}>
      <ErrorIcon className={style.icon} />
      <div className={style.message}>
        <p>The main branch of this Figma file branch is already in your workspace.</p>
        <p>
          Working on multiple branches of a Figma file at once in Ditto is <strong>currently in beta</strong>. If you’d
          like to continue, you can{" "}
          <span className={style.toggleLink} onClick={showAdvancedBranchingModal}>
            turn on the beta
          </span>{" "}
          for your workspace.
        </p>
        <p>
          To learn more, check out{" "}
          <a className={style.link} href="https://www.dittowords.com/docs/figma-branching" target="_blank">
            our help guide.
          </a>
        </p>
      </div>
    </div>
  );
};

export default AdvancedBranchingToggleMessage;
