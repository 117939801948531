import createAuth0Client from "@auth0/auth0-spa-js";
import { IFRole, IFUserWithPermissions } from "@shared/types/User";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie/cjs";
import { AuthState, LoginWithRedirectParams, PREFERRED_LOGIN_PAGE_KEY } from ".";
import {
  confirmFigmaAuthenticationRequest,
  getFigmaCorrelationKey,
  handleFigmaAuthRequestRedirect,
} from "../../figma/lib";
import history from "../../utils/history";
import { clearOnboardingRedirectValue, setOnboardingRedirectValue } from "../../views/Onboarding/lib";
import ResolvedAuthClient from "./ResolvedAuthClient";

import { userAtom } from "@/stores/Auth";
import { userTokenAtom } from "@/stores/User";
import logger from "@shared/utils/logger";
import { useSetAtom } from "jotai";
import { useFigmaCorrelationManager } from "../../figma/lib";
import http from "../../http";
import * as httpUser from "../../http/user";
import { clearDatadogUser, setDatadogUser } from "../../utils/datadog";
import { EavesdropMemoryCache } from "./EavesdropMemoryCache";
import getAuth0ClientId from "./getAuth0ClientId";
import { handleGetTokenError } from "./handleGetTokenError";

const fetchUserFromDitto = async (token: string) => {
  const response = await fetch(`${process.env.BACKEND_URL}/user/getInfo`, {
    credentials: "include",
    headers: {
      "x-ditto-app": "web_app",
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 401) {
    return {};
  }
  return response.json();
};

const useDeployedAuth = (): AuthState => {
  const setJotaiUser = useSetAtom(userAtom);
  const setUserToken = useSetAtom(userTokenAtom);
  const [user, _setUser] = useState<(IFUserWithPermissions & { billingRole: IFRole }) | null>(null);
  const setUser = (user: Parameters<typeof _setUser>[0]) => {
    _setUser(user);
    setJotaiUser(user);
  };
  const [loading, setLoading] = useState<boolean>(true);
  const auth0ClientRef = useRef(new ResolvedAuthClient());
  const auth0Client = auth0ClientRef.current;

  const [cookies] = useCookies(["impersonateUser"]);

  useFigmaCorrelationManager();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const figmaCorrelationKey = getFigmaCorrelationKey();
        let eavesdroppedRefreshToken: string | null = null;

        const auth0FromHook = await createAuth0Client({
          useRefreshTokens: true,
          domain: process.env.REACT_APP_AUTH0_DOMAIN!,
          client_id: getAuth0ClientId(),
          redirect_uri: process.env.BASE_URL + "/auth-callback",
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
          hasFigmaCorrelationKey: !!getFigmaCorrelationKey(),
          cache: new EavesdropMemoryCache((value) => {
            /**
             * The only purpose of this custom cache is to intercept the refresh
             * token to send to the Figma plugin, so if the app hasn't loaded within
             * the context of a Figma authentication request, don't bother eavesdropping.
             */
            if (!figmaCorrelationKey) {
              return;
            }

            if (!("body" in value) || !value.body) {
              return console.error(`Invalid cache value during Figma authentication request`);
            }

            eavesdroppedRefreshToken = value.body.refresh_token ?? null;
          }),
        });

        auth0Client.resolve(auth0FromHook);

        const handleAuthAndGetUserData = async (shouldRedirect: boolean) => {
          let token: string;
          try {
            token = await auth0FromHook.getTokenSilently();
            setUserToken(token);
          } catch (e) {
            handleGetTokenError(e, () => {
              setLoading(false);
              setUser(null);
            });
            return null;
          }

          const userFromDitto = await fetchUserFromDitto(token);
          const userFromAuth0 = await auth0FromHook.getUser();

          if (!userFromAuth0) {
            return handleAuthError("no_user_from_auth0", auth0FromHook);
          }

          const userPicture = userFromAuth0.picture;
          const userMetadata = userFromAuth0["https://saydit.to/user_metadata"];

          const workspaceIdFromDitto = userFromDitto.workspaceId;
          const workspaceIdFromAuth0 = userMetadata.workspaceID;

          if (!workspaceIdFromAuth0) {
            return handleAuthError("no_or_invalid_workspace_id", auth0FromHook);
          }

          if (workspaceIdFromDitto !== workspaceIdFromAuth0 && !cookies.impersonateUser) {
            return handleAuthError("workspace_id_mismatch", auth0FromHook);
          }

          // Store user profile picture in the database, but avoid overwriting a user's picture
          // with the impersonator's picture
          if (!cookies.impersonateUser) {
            httpUser.updateUserProfilePicture({ picture: userPicture });
          }

          if (shouldRedirect) {
            try {
              const resultPromise = auth0FromHook.handleRedirectCallback();

              // there appears to be a bug with auth0/auth0-spa-js where sometimes
              // handleRedirectCallback() will hang indefinitely -- this is to guard
              // against that.
              const timeout = setTimeout(() => {
                handleRedirect(userFromDitto.onboardingState, {
                  targetUrl: "/",
                });
              }, 10000);

              const result = await resultPromise;
              clearTimeout(timeout); // cancel the redirect that occurs on timeout

              const targetUrl = result?.appState?.targetUrl || null;

              handleRedirect(userFromDitto.onboardingState, { targetUrl });
            } catch (e) {
              if (e.message === "Invalid state") {
                auth0Client.get().then((c) => c.loginWithRedirect());
              }
            }
          }

          return { picture: userPicture, ...userFromDitto };
        };

        /**
         * The `code` and `state` parameters indicate indicate one of three possibilities:
         * - the user has been redirected by Auth0 after authentication to `/auth-callback`
         * - the user has been redirected by Figma after authentication (to account settings
         * page OR `/onboarding/user`)
         * - the user has been redirected by Slack after authentication (to the /account/connections page)
         *
         * Prior to triggering Figma authentication, we set `figma-auth-state-loading`
         * in local storage, so that flag is used to distinguish between the two options.
         */
        const isOnAuthCallbackPage =
          window.location.search.includes("code=") &&
          window.location.search.includes("state=") &&
          !window.location.pathname.includes("slack") && // don't run this logic for the Slack redirect
          !localStorage.getItem("figma-auth-state-loading");

        let user: (IFUserWithPermissions & { billingRole: IFRole }) | undefined = undefined;

        if (isOnAuthCallbackPage) {
          user = await handleAuthAndGetUserData(true);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();
        if (isAuthenticated && !user) {
          user = await handleAuthAndGetUserData(false);
        }

        if (!user) {
          setLoading(false);
          setUser(null);
          return;
        } else {
          setUser(user);
          setDatadogUser(user);
          setLoading(false);
        }

        if (figmaCorrelationKey) {
          logger.info(`Figma auth: Correlation key detected`, {
            context: {
              userId: user?._id,
              correlationKey: figmaCorrelationKey,
              refreshTokenLast4: (eavesdroppedRefreshToken || "").slice(-4),
            },
          });

          confirmFigmaAuthenticationRequest(figmaCorrelationKey, eavesdroppedRefreshToken);

          handleFigmaAuthRequestRedirect(isOnAuthCallbackPage, user.onboardingState, figmaCorrelationKey);
        }
      } catch (e) {
        handleGetTokenError(e, () => {
          setUser(null);
          clearDatadogUser();
          setLoading(false);
        });
      }
    };

    initAuth0();
  }, []);

  const handleAuthError = (error, auth0Hook) => {
    auth0Hook.logout({
      returnTo: `${process.env.BASE_URL}/login?error=${error}`,
    });
  };

  const handleRedirect = (onboardingState, { targetUrl }) => {
    // if the user isn't finished onboarding, we need to redirect them
    // there to finish
    if (onboardingState !== "finished") {
      targetUrl ? setOnboardingRedirectValue(targetUrl) : clearOnboardingRedirectValue();

      history.push(`/onboarding/${onboardingState}`);
      return;
    }

    // if the user IS finished onboarding, send them
    // directly to the final `targetUrl`
    if (targetUrl) {
      history.push(targetUrl);
      return;
    }

    // if the user IS finished onboarding but there is no
    // explicit `targetUrl` to redirect to, default to sending
    // them to the projects page
    history.push("/");
  };

  const refreshUser = async () => {
    try {
      const r = await http.get(`${process.env.BACKEND_URL}/user/getInfo`);
      const userInfo = r.data;
      setUser({ ...user, ...userInfo }); // to maintain picture from auth0
      setDatadogUser({ ...user, ...userInfo });
    } catch (e) {
      console.log("in react-auth0-spa.js refreshUser: ", e);
    }
  };

  const onDittoOverviewFinished = () =>
    setUser((u) => {
      if (u) {
        setDatadogUser({ ...u, finishedDittoOverview: true });
        return { ...u, finishedDittoOverview: true };
      } else {
        clearDatadogUser();
        return null;
      }
    });

  const logoutRan = useRef(false);

  // logs out the user and then redirects to the login page
  const logout = (...p) => {
    if (logoutRan.current) return;
    logoutRan.current = true;

    auth0Client.get().then((c) => {
      window?.analytics?.reset();
      return c.logout(...p);
    });

    setUserToken(null);
  };

  // Logs out the user without any visible action. This is primarily
  // useful for clearing server-only cookies in cases where they aren't
  // otherwise cleared automatically, such as when a user is denied access
  // from an Action
  const logoutSilently = async () => {
    if (logoutRan.current) return;
    logoutRan.current = true;

    const url = `${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
    await axios.get(url);
  };

  const loginWithRedirect = async (params: LoginWithRedirectParams) => {
    const { enterpriseKey } = params;
    if (!!enterpriseKey) localStorage.setItem(PREFERRED_LOGIN_PAGE_KEY, enterpriseKey);

    await auth0Client
      .get()
      .then((c) => {
        c.getTokenSilently()
          .then((token) => setUserToken(token))
          .catch((error) => {
            // don't do anything with the error, as we will still redirect.
          });
        return c.loginWithRedirect(params);
      })
      .catch((error) => {
        console.error(`Error accessing auth0 with params ${params}`, new Error(error));
        throw error;
      });
  };

  if (loading) {
    return {
      user: null,
      loading,
      isAuthenticated: false,
      getTokenSilently: (...p) =>
        auth0Client
          .get()
          .then((c) => c.getTokenSilently(...p))
          .catch((e) =>
            handleGetTokenError(
              e,
              () => {
                setLoading(false);
                setUser(null);
                clearDatadogUser();
              },
              () => {
                // This corresponds to a string in the "Limit Users to SAML Authentication" Auth0 Action
                if (e.message === "saml_connection_required") {
                  logoutSilently();
                }
              }
            )
          ),
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  } else if (!user) {
    return {
      user,
      loading,
      isAuthenticated: false,
      getTokenSilently: (...p) =>
        auth0Client
          .get()
          .then((c) => c.getTokenSilently(...p))
          .catch((e) =>
            handleGetTokenError(
              e,
              () => {
                setLoading(false);
                setUser(null);
                clearDatadogUser();
              },
              () => {
                // This corresponds to a string in the "Limit Users to SAML Authentication" Auth0 Action
                if (e.message === "saml_connection_required") {
                  logoutSilently();
                }
              }
            )
          ),
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  } else {
    return {
      user,
      loading,
      isAuthenticated: true,
      getTokenSilently: (...p) =>
        auth0Client
          .get()
          .then((c) => c.getTokenSilently(...p))
          .catch((e) =>
            handleGetTokenError(
              e,
              () => {
                setLoading(false);
                setUser(null);
                clearDatadogUser();
              },
              () => {
                // This corresponds to a string in the "Limit Users to SAML Authentication" Auth0 Action
                if (e.message === "saml_connection_required") {
                  logoutSilently();
                }
              }
            )
          ),
      refreshUser,
      onDittoOverviewFinished,
      loginWithRedirect,
      logout,
    };
  }
};

export default useDeployedAuth;
