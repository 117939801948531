import { IFFullComponentMergeSuggestion } from "@shared/types/Component";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";

import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import IgnoredSuggestionsDrawer from "../ComponentSuggestions/IgnoredSuggestionsDrawer";
import ComponentMergeSuggestion from "./ComponentMergeSuggestion";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import style from "./ComponentMergeSuggestionPanel.module.css";

interface Props {
  onSelectComponent?: (componentId: string) => void;
  onIgnoreSuggestion: (key: string) => void;
  onUnignoreSuggestion: (key: string) => void;
  onBulkMergeSuggestions: ([]) => Promise<void>;
  onBulkIgnoreSuggestions: (suggestionKeys: string[]) => void;
  onMergeSuggestion: (key: string, targetComponentId: string) => Promise<void>;
  onReviewSuggestion: (key: string) => void;
  selectedKey: string;
  suggestions: IFFullComponentMergeSuggestion[];
  suggestionsLoading?: boolean;
  className?: string;
  selectedFolder?: { isSample: boolean };
}

export const ComponentMergeSuggestionPanel = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [multiSelectedSuggestions, setMultiSelectedSuggestions] = useState<IFFullComponentMergeSuggestion[]>([]);
  // The ignored drawer can pop open below the fold, so we scroll to the bottom
  // to make sure it's within view
  const containerRef = React.useRef<HTMLDivElement>(null);
  const onOpenIgnoredDrawer = () => {
    setTimeout(() => {
      if (!containerRef.current) return;
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    });
  };

  const { suggestions, suggestionsIgnored } = useMemo(
    () =>
      props.suggestions.reduce(
        (acc, s) => {
          s.ignored ? acc.suggestionsIgnored.push(s) : acc.suggestions.push(s);
          s.targetComponentId = s.components[0]._id; // NOTE: we need this for bulk acccept
          return acc;
        },
        {
          suggestions: [],
          suggestionsIgnored: [],
        } as {
          suggestions: IFFullComponentMergeSuggestion[];
          suggestionsIgnored: IFFullComponentMergeSuggestion[];
        }
      ),
    [props.suggestions]
  );

  const onMergeSuggestion = async (key: string, targetComponentId: string) => {
    setIsLoading(true);
    await props.onMergeSuggestion(key, targetComponentId);
    setIsLoading(false);
  };

  const handleSelectAll = () => {
    const currentSuggestionLength = [...multiSelectedSuggestions].length;
    currentSuggestionLength === suggestions.length
      ? setMultiSelectedSuggestions([])
      : setMultiSelectedSuggestions(suggestions);
  };

  const handleMultiSelect = (suggestion) => {
    const previousMultiSuggestions = [...multiSelectedSuggestions];
    const suggestionIndex = previousMultiSuggestions.findIndex((sugg) => sugg.key === suggestion.key);
    if (suggestionIndex > -1) {
      previousMultiSuggestions.splice(suggestionIndex, 1);
      setMultiSelectedSuggestions([...previousMultiSuggestions]);
    } else {
      setMultiSelectedSuggestions([...previousMultiSuggestions, suggestion]);
    }
  };

  const handleBulkIgnore = () => {
    props.onBulkIgnoreSuggestions(multiSelectedSuggestions.map((s) => s.key));
    setMultiSelectedSuggestions([]);
  };

  const handleBulkAccept = () => {
    props.onBulkMergeSuggestions(
      multiSelectedSuggestions.map((s) => ({
        suggestionKey: s.key,
        targetComponentId: s.targetComponentId,
      }))
    );
    setMultiSelectedSuggestions([]);
  };

  return (
    <div className={classNames(style.container, props.className)} ref={containerRef}>
      <div
        className={classNames(style.scrollWrapper, {
          [style.disabledPanel]: props.selectedFolder?.isSample,
        })}
      >
        {props.selectedFolder?.isSample && (
          <div className={style.sampleInfo}>
            <div className={style.sampleInfoIcon}>
              <InfoOutlined fontSize="inherit" />
            </div>
            Merge suggestions are disabled for the sample project.
          </div>
        )}
        {suggestions.length > 0 && (
          <div
            className={classNames({
              [style.infoSection]: true,
              [style.disabledPanel]: props.selectedFolder?.isSample,
            })}
          >
            <div className={style.infoIconWrapper}>
              <AutoAwesomeIcon className={style.icon} />
            </div>
            <div>
              We’ve identified components with the same text and metadata that may be helpful to{" "}
              <b>merge into the same component.</b>
            </div>
          </div>
        )}
        {suggestions.length === 0 && (
          <div
            className={classNames({
              [style.emptySection]: true,
              [style.disabledPanel]: props.selectedFolder?.isSample,
            })}
          >
            <h2>No Merge Suggestions</h2>
            <p>We didn’t find any duplicated Ditto components in your workspace.</p>
          </div>
        )}{" "}
        <div className={style.suggestionsList}>
          {suggestions.map((s, index) => (
            <ComponentMergeSuggestion
              key={s.key}
              ignored={false}
              onReviewClick={() => props.onReviewSuggestion(s.key)}
              onIgnoreClick={() => props.onIgnoreSuggestion(s.key)}
              onUnignoreClick={() => null}
              onMergeClick={(t) => onMergeSuggestion(s.key, t)}
              onSelectComponent={props.onSelectComponent}
              suggestion={s}
              isSelected={s.key === props.selectedKey}
              isLoading={isLoading && s.key === props.selectedKey}
              isDisabled={isLoading}
              index={index}
              handleSelected={handleMultiSelect}
              multiSelectedSuggestions={multiSelectedSuggestions}
              setMultiSelectedSuggestions={setMultiSelectedSuggestions}
              className={classNames({
                [style.suggestion]: true,
                [style.suggestionSelected]: s.key === props.selectedKey,
              })}
            />
          ))}
        </div>
        <IgnoredSuggestionsDrawer onOpen={onOpenIgnoredDrawer}>
          {suggestionsIgnored.map((s, index) => (
            <ComponentMergeSuggestion
              key={s.key}
              ignored={true}
              onReviewClick={() => props.onReviewSuggestion(s.key)}
              onIgnoreClick={() => props.onIgnoreSuggestion(s.key)}
              onUnignoreClick={() => props.onUnignoreSuggestion(s.key)}
              onMergeClick={(t) => props.onMergeSuggestion(s.key, t)}
              onSelectComponent={props.onSelectComponent}
              suggestion={s}
              isSelected={s.key === props.selectedKey}
              isDisabled={isLoading}
              index={index}
              handleSelected={handleMultiSelect}
              multiSelectedSuggestions={multiSelectedSuggestions}
              setMultiSelectedSuggestions={setMultiSelectedSuggestions}
              className={classNames({ [style.suggestion]: true })}
            />
          ))}
        </IgnoredSuggestionsDrawer>
      </div>
      {multiSelectedSuggestions.length > 0 ? (
        <MultiSelectBanner
          multiSelectedSuggestions={multiSelectedSuggestions}
          handleSelectAll={handleSelectAll}
          allSelectedSuggestions={suggestions}
          handleBulkIgnore={handleBulkIgnore}
          handleBulkAccept={handleBulkAccept}
        />
      ) : null}
    </div>
  );
};

const MultiSelectBanner = ({
  multiSelectedSuggestions,
  allSelectedSuggestions,
  handleSelectAll,
  handleBulkIgnore,
  handleBulkAccept,
}) => {
  const selectAllText =
    multiSelectedSuggestions.length === allSelectedSuggestions.length ? "Unselect All" : "Select All";

  return (
    <div className={style.multiSelectBanner}>
      <div className={style.left}>
        <span className={style.selectedInfo}>{multiSelectedSuggestions.length} selected</span>
        <span className={style.selectAll} onClick={handleSelectAll}>
          {selectAllText}
        </span>
      </div>
      <div className={style.right}>
        <span className={style.ignore} onClick={handleBulkIgnore}>
          Ignore
        </span>
        <div className={style.tooltipWrapper} data-tip data-for="invite-only-folders">
          <Button className={style.accept} onClick={handleBulkAccept}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};
