import React, { ReactElement } from "react";
import useResizeObserver from "use-resize-observer";
import style from "./FillFlexParent.module.css";

type Props = {
  children: (dimens: { width: number; height: number }) => ReactElement;
};

type AnyRef = React.MutableRefObject<any> | React.RefCallback<any> | null;

function mergeRefs(...refs: AnyRef[]) {
  return (instance: any) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref != null) {
        ref.current = instance;
      }
    });
  };
}

export const FillFlexParent = React.forwardRef(function FillFlexParent(props: Props, forwardRef) {
  const { ref, width, height } = useResizeObserver();
  return (
    <div className={style.fillFlexParent} ref={mergeRefs(ref, forwardRef)}>
      {width && height ? props.children({ width, height }) : null}
    </div>
  );
});
