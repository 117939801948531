import z from "zod";
import { BlockSuggestion, Suggestion, WsComponentSuggestion } from "../SetupSuggestions";
import { SlackNotificationTypes } from "../SlackNotifications";
import { ZTipTapRichText } from "../TextItem";

export const CommitStructureSuggestionsRequestBody = z.object({
  suggestions: z.array(
    z.object({
      groupId: z.string(),
      frameId: z.string(),
      frameName: z.string(),
      blockSuggestions: z.array(BlockSuggestion),
      hideSuggestions: z.array(Suggestion),
      wsComponentSuggestions: z.array(WsComponentSuggestion),
    })
  ),
  docId: z.string(),
  fromFigma: z.boolean(),
});

export type CommitStructureSuggestionsRequestBody = z.infer<typeof CommitStructureSuggestionsRequestBody>;

export const CommitSuggestions = z.array(
  z.object({
    targetGroupId: z.string(),
    blockSuggestions: z.record(
      // block name
      z.string(),
      z.array(z.string())
    ),
    hideSuggestions: z.array(z.string()),
    wsComponentSuggestions: z.array(
      z.object({
        id: z.string(),
        wsComponentId: z.string(),
      })
    ),
  })
);

export type CommitSuggestions = z.infer<typeof CommitSuggestions>;

export const CommitStructureSuggestionsWIPRequestBody = z.object({
  suggestions: CommitSuggestions,
  docId: z.string(),
});

export type CommitStructureSuggestionsWIPRequestBody = z.infer<typeof CommitStructureSuggestionsRequestBody>;

export const TurnOffSetupSuggestionsForGroupBody = z.object({
  groupId: z.string(),
  projectId: z.string(),
});

export type TurnOffSetupSuggestionsForGroupBody = z.infer<typeof TurnOffSetupSuggestionsForGroupBody>;

export const UpdateSlackChannelRequestBody = z.object({
  channel_id: z.string().nullable(),
  channel_name: z.string().nullable(),
  webhook_url: z.string().nullable(),
  configuration_url: z.string().nullable(),
});

export type UpdateSlackChannelRequestBody = z.infer<typeof UpdateSlackChannelRequestBody>;

export const UpdateSlackChannelParams = z.object({
  doc_id: z.string(),
});

export type UpdateSlackChannelParams = z.infer<typeof UpdateSlackChannelParams>;

export const UpdateSlackNotifsRequestBody = z.object({
  notif_types: SlackNotificationTypes.partial(),
});

export type UpdateSlackNotifsRequestBody = z.infer<typeof UpdateSlackNotifsRequestBody>;

export const UpdateSlackNotifsParams = z.object({
  doc_id: z.string(),
});

export type UpdateSlackNotifsParams = z.infer<typeof UpdateSlackNotifsParams>;

const ZNewLinkedFigmaFrame = z.object({
  frame_id: z.string(),
  frame_name: z.string(),
  page_id: z.string(),
  page_name: z.string(),
  // TODO: This field should not be optional, but needs to be temporarily to not break backwards compatibility. We should do this in a couple of weeks. Linear ticket to remove the optional flag: https://linear.app/dittowords/issue/DIT-7184/remove-deprecated-optional-calls-for-web-websockets
  position: z
    .object({
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
    })
    .optional(),
  text_nodes: z.array(
    z.object({
      _id: z.string(),
      figma_id: z.string(),
      text: z.string(),
      // TODO: This field should not be optional, but needs to be temporarily to not break backwards compatibility. We should do this in a couple of weeks. Linear ticket to remove the optional flag: https://linear.app/dittowords/issue/DIT-7184/remove-deprecated-optional-calls-for-web-websockets
      rich_text: ZTipTapRichText.optional(),
    })
  ),
  unlinkedTextNodes: z.array(
    z.object({
      text: z.string(),
      figmaId: z.string(),
      // TODO: This field should not be optional, but needs to be temporarily to not break backwards compatibility. We should do this in a couple of weeks. Linear ticket to remove the optional flag: https://linear.app/dittowords/issue/DIT-7184/remove-deprecated-optional-calls-for-web-websockets
      rich_text: ZTipTapRichText.optional(),
    })
  ),
});

export type INewLinkedFigmaFrame = z.infer<typeof ZNewLinkedFigmaFrame>;

export const ZConnectGroupRequestBody = z.object({
  project_id: z.string(),
  group_id: z.string(),
  newLinkedFigmaFrame: ZNewLinkedFigmaFrame,
});

export type IConnectGroupRequestBody = z.infer<typeof ZConnectGroupRequestBody>;
