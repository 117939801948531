import { useUnauthenticatedAuth } from "@/store/UnauthenticatedAuthContext";
import * as SegmentEvents from "@shared/segment-event-names";
import React, { useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { getURLParams } from "../../../util/url";
import google from "../../assets/google.png";
import { getFigmaCorrelationKey } from "../../figma/lib";
import useSegment from "../../hooks/useSegment";
import LoginWrapper from "./LoginWrapper";
import { getAppState } from "./lib";
import style from "./style.module.css";

const useQueryStringError = (track) =>
  useMemo(() => {
    const params = getURLParams(window.location.href);
    const parsedError = params.get("error");

    if (parsedError) {
      track({
        event: SegmentEvents.LOGIN_ERROR,
        properties: {
          error: parsedError,
        },
      });

      return parsedError;
    }

    return null;
  }, []);

const Login = () => {
  const segment = useSegment();
  const { loginWithRedirect } = useUnauthenticatedAuth();

  const error = useQueryStringError(segment.track);
  const location = useLocation();

  const hasFigmaCorrelationKey = useMemo(() => !!getFigmaCorrelationKey(), []);

  const loginGoogle = () =>
    loginWithRedirect({
      connection: "google-oauth2",
      appState: getAppState(location),
      hasFigmaCorrelationKey,
    });

  const loginAzure = () =>
    loginWithRedirect({
      connection: "azure-AD",
      appState: getAppState(location),
      hasFigmaCorrelationKey,
    });

  if (process.env.IS_LOCAL === "true") {
    return <Redirect to="/trusted" />;
  }

  return (
    <LoginWrapper title="Sign in to Ditto" error={error} floatingMessage={<SamlLink />}>
      <p className={style.legal}>
        To set up or join a workspace for your team, please join with the email you use for work.
      </p>
      <button className={style.loginButton} onClick={loginGoogle}>
        <img className={style.googleLogo} src={google} />
        Sign in with Google
      </button>
      <a className={style.loginLink} onClick={loginAzure}>
        Sign in with Azure AD
      </a>
    </LoginWrapper>
  );
};

const SamlLink = () => {
  return (
    <p className={style.samlLink}>
      You can also <a href="/login/saml">sign in with SAML SSO.</a>
    </p>
  );
};

export default Login;
