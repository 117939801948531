import React from "react";

const BranchingIcon = (props) => (
  <svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.59259V2.04235L3.34644 2.04475C5.49055 2.047 5.74458 2.07608 6.29234 2.38207C7.19846 2.88836 7.46218 3.50364 7.46326 5.11334C7.46433 6.80341 7.64743 7.45631 8.32582 8.18793C9.10269 9.02569 9.89079 9.23858 12.2166 9.23858H14.1465L13.1552 10.2132L12.164 11.1877L12.5074 11.5225L12.851 11.8571L14.4255 10.323L16 8.78882L14.4255 7.25467L12.851 5.72052L12.5074 6.05515L12.164 6.38992L13.1552 7.36441L14.1465 8.33905L12.1508 8.33665C10.3696 8.3344 10.0905 8.29812 9.55551 7.99933C8.6494 7.49304 8.38568 6.87777 8.3846 5.26806C8.38352 3.578 8.20042 2.92509 7.52203 2.19347C6.71563 1.32378 5.98508 1.14282 3.28059 1.14282H1V1.59259ZM10.2319 1.59259V2.04235H13.0015H15.771V1.59259V1.14282H13.0015H10.2319V1.59259Z"
      stroke="#19191A"
      strokeWidth="0.5"
    />
  </svg>
);

export default BranchingIcon;
