import { userAtom } from "@/stores/Auth";
import { commentFamilyAtom, postCommentReplyActionAtom, toggleCommentResolvedActionAtom } from "@/stores/Comments";
import { selectTextItemsThatExistActionAtom } from "@/stores/ProjectSelection";
import { deferredUsersAtom, usersByIdAtom } from "@/stores/Workspace";
import CommentThread from "@ds/organisms/CommentThread";
import { IUser } from "@shared/types/User";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";

interface IProps {
  commentThreadId: string;
  showGoToText?: boolean;
  className?: string;
}

const DEFAULT_USER = { name: "User " };

export function CommentThreadWrapper({ commentThreadId, showGoToText = false, className }: IProps) {
  const toggleResolvedAction = useSetAtom(toggleCommentResolvedActionAtom);
  const postReplyAction = useSetAtom(postCommentReplyActionAtom);
  const commentThread = useAtomValue(commentFamilyAtom(commentThreadId));
  const currentUser = useAtomValue(userAtom, { store: getDefaultStore() });
  const users = useAtomValue(deferredUsersAtom);
  const usersById = useAtomValue(usersByIdAtom);
  const selectTextItemsActom = useSetAtom(selectTextItemsThatExistActionAtom);

  const selectTextItem = () => {
    const textItemIds = commentThread?.comp_id ? [commentThread.comp_id] : [];
    selectTextItemsActom(textItemIds);
  };

  const handlePostReply = async (commentText: string, mentionedUsers: IUser[], resetEditorState: () => void) => {
    await postReplyAction({
      commentThreadId,
      commentText,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
    });

    resetEditorState();
  };

  const handleResolve = async () => {
    toggleResolvedAction(commentThread);
  };

  if (!commentThread) {
    return <></>;
  }

  return (
    <CommentThread
      commentThread={commentThread}
      className={className}
      user={currentUser || DEFAULT_USER}
      mentionableUsers={users}
      mentionableUsersById={usersById}
      onResolve={handleResolve}
      postReply={handlePostReply}
      goToTextItem={showGoToText ? selectTextItem : undefined}
    />
  );
}

export function CommentThreadWrapperWithSuspense(props: IProps) {
  return (
    <Suspense fallback={<Skeleton height={61} baseColor="white" />}>
      <CommentThreadWrapper {...props} />
    </Suspense>
  );
}

export default CommentThreadWrapperWithSuspense;
