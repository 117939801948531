import { ZGeneralPermission, ZResourcePermission } from "@shared/types/PermissionGroups";
import { SlackNotificationTypes } from "@shared/types/SlackNotifications";
import {
  ZComponentsApiIdGenerationConfig,
  ZConnectionWaitlistInfo,
  ZProjectsApiIdGenerationConfig,
  ZWorkspaceConfiguration,
} from "@shared/types/Workspace";
import z from "zod";

export const UpdateWorkspaceConfigRequestBody = z.object({
  config: ZWorkspaceConfiguration.deepPartial(),
  updateExistingIds: z.boolean().optional(),
});
export type UpdateWorkspaceConfigRequestBody = z.infer<typeof UpdateWorkspaceConfigRequestBody>;

export const UpdateWorkspaceConfigResponse = z
  .object({
    "config.projects.apiIdGeneration": ZProjectsApiIdGenerationConfig,
    "config.projects.apiIdPreventManualEdits": z.boolean(),
    "config.projects.apiIdUpdateIdsWhenGroupBlockChange": z.boolean(),
    "config.components.apiIdGenerateOnRename": z.boolean(),
    "config.components.apiIdGeneration": ZComponentsApiIdGenerationConfig,
    "config.components.apiIdPreventManualEdits": z.boolean(),
    apiIdsRegenerated: z.boolean(),
  })
  .deepPartial();
export type UpdateWorkspaceConfigResponse = z.infer<typeof UpdateWorkspaceConfigResponse>;

export const UpdateSlackTokenRequestBody = z.object({
  token: z.string().nullable(),
});
export type UpdateSlackTokenRequestBody = z.infer<typeof UpdateSlackTokenRequestBody>;

export const UpdateSlackChannelRequestParams = z.object({});

export type UpdateSlackChannelRequestParams = z.infer<typeof UpdateSlackChannelRequestParams>;

export const UpdateSlackChannelRequestBody = z.object({
  channel_id: z.string().nullable(),
  channel_name: z.string().nullable(),
  webhook_url: z.string().nullable(),
  configuration_url: z.string().nullable(),
});

export type UpdateSlackChannelRequestBody = z.infer<typeof UpdateSlackChannelRequestBody>;

export const UpdateSlackNotifsRequestBody = z.object({
  notif_types: SlackNotificationTypes.partial(),
});

export type UpdateSlackNotifsRequestBody = z.infer<typeof UpdateSlackNotifsRequestBody>;

export const EditTagRequestBody = z.object({
  tag: z.string(),
  new_tag: z.string(),
});

export type EditTagRequestBody = z.infer<typeof EditTagRequestBody>;

export const DeleteTagRequestBody = z.object({
  tag: z.string(),
});

export type DeleteTagRequestBody = z.infer<typeof DeleteTagRequestBody>;

export const ApiTokenResponse = z.object({
  connection: z.string().nullable(),
  createdAt: z.string(),
  creatorId: z.string(),
  lastUsedAt: z.string().nullable(),
  name: z.string(),
  token: z.string(),
  workspaceId: z.string(),
  _id: z.string(),
});

export type ApiTokenResponse = z.infer<typeof ApiTokenResponse>;

// Permission Groups

export const CreateNewPermissionGroupRequestBody = z.object({
  name: z.string(),
});
export type CreateNewPermissionGroupRequestBody = z.infer<typeof CreateNewPermissionGroupRequestBody>;

export const UpdatePermissionGroupRequestBody = z.object({
  name: z.string(),
  permissions: z.object({
    resources: z.array(ZResourcePermission),
    general: z.array(ZGeneralPermission),
  }),
});

export type UpdatePermissionGroupRequestBody = z.infer<typeof UpdatePermissionGroupRequestBody>;

export const UpdateConnectionsWaitlistRequestBody = z.object({
  waitlist: ZConnectionWaitlistInfo,
});

export type UpdateConnectionsWaitlistRequestBody = z.infer<typeof UpdateConnectionsWaitlistRequestBody>;

export const UpdateConnectionsWaitlistResponse = z.object({
  waitlist: ZConnectionWaitlistInfo,
});

export type UpdateConnectionsWaitlistResponse = z.infer<typeof UpdateConnectionsWaitlistResponse>;
