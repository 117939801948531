import TollIcon from "@mui/icons-material/Toll";
import classNames from "classnames";
import React, { useMemo } from "react";
import style from "./style.module.css";

const BASE_VARIANT_ID = "__base__";

interface Props {
  comp: any;
  variantId: string | null;
  className?: string;
}
const PluralsBadge = ({ comp, variantId, className }: Props) => {
  const plurals = useMemo(() => {
    if (!variantId || variantId === BASE_VARIANT_ID) return comp?.plurals || [];

    const compVariant = (comp?.variants || []).find((variant) => variant.variantId === variantId);
    if (!compVariant || !compVariant?.plurals) return [];

    return compVariant.plurals;
  }, [comp, variantId]);

  if (!plurals || plurals.length === 0) return null;

  return (
    <div className={classNames(style.pluralsBadge, className)}>
      <TollIcon className={style.icon} />
      {`${plurals.length} Plural${plurals.length !== 1 ? "s" : ""}`}
    </div>
  );
};

export default PluralsBadge;
