import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import AutoAwesomeIcon from "../../../shared/frontend/AutoAwesomeIcon";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

interface Props {
  numDuplicates: number;
  handleComponentizeDuplicates: () => void;
}
const DuplicateComponentsNudge = ({ numDuplicates, handleComponentizeDuplicates }: Props) => {
  const [componentAttachNudgeOpen, setComponentAttachNudgeOpen] = useState<boolean>(true);

  const toggleAttachNudgeDescription = () => setComponentAttachNudgeOpen((prev) => !prev);

  useEffect(
    function toggleNudgeClosed() {
      if (numDuplicates <= 1) return;
      const nudgeViewed = localStorage.getItem("duplicate-component-nudge-viewed") === "true";
      if (nudgeViewed) setComponentAttachNudgeOpen(false);
      else localStorage.setItem("duplicate-component-nudge-viewed", "true");
    },
    [numDuplicates]
  );

  return (
    <div className={style.componentAttachNudge}>
      <div className={style.nudgeTile} onClick={toggleAttachNudgeDescription}>
        <AutoAwesomeIcon className={style.icon} />
        <span>Link these {numDuplicates} instances to save time!</span>
        {componentAttachNudgeOpen ? (
          <KeyboardArrowUpIcon className={style.toggleIcon} />
        ) : (
          <KeyboardArrowDownIcon className={style.toggleIcon} />
        )}
      </div>
      <div
        className={classnames({
          [style.content]: true,
          [style.open]: componentAttachNudgeOpen,
        })}
      >
        <div>Attach them to a Ditto Component to easily apply changes across all {numDuplicates} instances.</div>
        <div className={style.buttonWrapper}>
          <ButtonPrimary text="Componentize" onClick={handleComponentizeDuplicates} />
        </div>
      </div>
    </div>
  );
};

export default DuplicateComponentsNudge;
