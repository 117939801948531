import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HelpIcon from "@mui/icons-material/Help";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyIcon from "@mui/icons-material/VpnKey";
import Tooltip from "@shared/frontend/Tooltip";
import classNames from "classnames";
import React, { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { WEBHOOK_DOCUMENTATION_LINK, webhookInlineLabels } from "../../../../../shared/lib/webhook";
import OverlayToast from "../../../../components/OverlayToast";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import { WebhookModal } from "../../../../components/webhooks/WebhookModal";
import style from "./style.module.css";
import { useWebhooksTab } from "./useWebhooksTab";

const WebhooksTab = () => {
  const {
    signingKeyState,
    signingKeyInputDisabled,
    signingKeyInputPlaceholder,
    signingKeyInputValue,
    signingKeyInputRef,
    signingKeySaveButtonVisible,
    signingKeySaveButtonText,
    signingKeySaveButtonDisabled,
    showCancelNewSigningKeyButton,
    changeSigningKeyButtonVisible,
    changeSigningKeyButtonDisabled,
    generateSigningKeyButtonVisible,
    generateSigningKeyButtonEnabled,
    onCancelNewSigningKeyButton,
    onChangeSigningKeyClick,
    onSigningKeyInputChange,
    onSigningKeySaveButtonClick,
    onCreateWebhook,
    onViewWebhook,
    onGenerateSigningKeyButtonClick,
    webookModalProps,
    webhookModalState,
    componentFolderNameById,
    webhooksEnabled,
    showSigningKeyRequiredTooltip,
    overlayToastProps,
    devToolsEnabled,
    createWebhookButtonEnabled,
    webhooks,
  } = useWebhooksTab();

  return (
    <div className={style.webhooksTab}>
      <div
        className={classNames([style.container, style.apikeys], {
          [style.disabled]: !devToolsEnabled,
        })}
      >
        <div className={style.top}>
          <div className={style.title}>Webhooks</div>
          <div className={classNames([style.subtitle, style.subtitleConstrainedWidth])}>
            Webhooks HTTP payloads to be sent to an external service when events happen in Ditto.
          </div>
          <div className={style.createBtnContainer}>
            <Tooltip
              className={style.tooltip}
              content={<div className={style.tooltipBody}>Save a signing key to start creating webhooks</div>}
              placement="bottom"
              theme="dark"
              disabled={!showSigningKeyRequiredTooltip}
            >
              <div>
                <Button
                  data-testid="create-webhook-button"
                  variant="outline-primary"
                  disabled={!createWebhookButtonEnabled}
                  onClick={onCreateWebhook}
                >
                  <AddIcon className={style.icon} /> Create Webhook
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={style.signingKeyContainer}>
          <h3 className={style.signingKeyHeader}>
            Signing Key{" "}
            <a href={`${WEBHOOK_DOCUMENTATION_LINK}#signing-key`} target="_blank">
              <HelpIcon className={style.icon} />
            </a>
          </h3>
          <p className={style.signingKeyMessage}>
            Before creating any webhooks, provide a signing key to encrypt your payloads. Please keep this stored
            securely.
          </p>
          <div className={style.signingKeyInputContainer}>
            <input
              className={classNames({
                [style.signingKeyInput]: true,
                [style.signingKeyInputError]: !!signingKeyState.error,
              })}
              type="text"
              onChange={onSigningKeyInputChange}
              value={signingKeyInputValue}
              placeholder={signingKeyInputPlaceholder}
              disabled={signingKeyInputDisabled}
              ref={signingKeyInputRef}
            />
            {signingKeySaveButtonVisible && (
              <>
                <ButtonPrimary
                  className={style.signingKeySaveButton}
                  disabled={signingKeySaveButtonDisabled}
                  onClick={onSigningKeySaveButtonClick}
                  text={signingKeySaveButtonText}
                />
                {showCancelNewSigningKeyButton && (
                  <ButtonSecondary
                    text="Cancel"
                    onClick={onCancelNewSigningKeyButton}
                    className={style.cancelSigningKeyButton}
                  />
                )}
              </>
            )}
            {changeSigningKeyButtonVisible && (
              <ButtonSecondary
                text={
                  <span>
                    <AutorenewIcon className={style.icon} /> Change Key
                  </span>
                }
                onClick={onChangeSigningKeyClick}
                className={style.changeSigningKeyButton}
                disabled={changeSigningKeyButtonDisabled}
              />
            )}
          </div>
          {signingKeyState.type !== "loading" && !!signingKeyState.error && (
            <p className={style.signingKeyErrorMessage}>{signingKeyState.error}</p>
          )}
          {generateSigningKeyButtonVisible && (
            <ButtonSecondary
              data-testid="generate-key-button"
              className={style.generateSigningKeyButton}
              onClick={onGenerateSigningKeyButtonClick}
              disabled={!generateSigningKeyButtonEnabled}
              text={
                <span>
                  <KeyIcon className={style.icon} /> Generate a key for me
                </span>
              }
            />
          )}
        </div>
        <table className={style.webhookTable}>
          <tbody>
            <WebhookTableRowHeader />
            {webhooks?.endpoints.length === 0 && (
              <WebhookTableRow
                endpoint={{
                  url: "",
                  name: "No webhooks.",
                  events: [],
                  enabled: true,
                  filters: {
                    componentFolderIds: [],
                  },
                }}
                componentFolderNameByIdMap={componentFolderNameById}
                className={style.noWebhooksMessageRow}
              />
            )}
            {webhooks?.endpoints.map((endpoint, idx) => (
              <WebhookTableRow
                key={endpoint._id}
                endpoint={endpoint}
                componentFolderNameByIdMap={componentFolderNameById}
                menu={
                  webhooksEnabled
                    ? {
                        onEdit: () => onViewWebhook(endpoint._id, "main"),
                        onViewDeliveryHistory: () => onViewWebhook(endpoint._id, "deliveryHistory"),
                      }
                    : undefined
                }
              />
            ))}
          </tbody>
        </table>
      </div>
      {webhookModalState.visible && (
        <WebhookModal {...webookModalProps} initialScreen={webhookModalState.initialScreen} />
      )}
      <OverlayToast {...overlayToastProps} />
    </div>
  );
};

const WebhookTableRowHeader = () => {
  return (
    <tr>
      <th>NAME</th>
      <th className={style.webhookUrlCell}>URL</th>
      <th className={style.webhookTriggeredCell}>TRIGGERED BY</th>
      <th className={style.webhookActionCell}></th>
    </tr>
  );
};

interface WebhookTableRowProps {
  endpoint: {
    name: string;
    url: string;
    events: string[];
    enabled: boolean;
    filters: {
      componentFolderIds?: (string | null)[] | null;
    };
  };
  className?: string;
  menu?: {
    onEdit: () => void;
    onViewDeliveryHistory: () => void;
  };
  componentFolderNameByIdMap: Map<string, string>;
}

const WebhookTableRow = (props: WebhookTableRowProps) => {
  const folderNames = useMemo(() => {
    const folderIds = props.endpoint.filters?.componentFolderIds || [];
    return folderIds.map((id, i) => {
      if (!id) return id;
      return props.componentFolderNameByIdMap.get(id) || "";
    });
  }, [props.endpoint.filters.componentFolderIds, props.componentFolderNameByIdMap]);

  const editWebhookText = props.endpoint.enabled ? "Edit webhook" : "Re-enable webhook";

  return (
    <tr
      className={classNames(props.className, {
        [style.webhookTableRowDisabled]: !props.endpoint.enabled,
      })}
    >
      <td>
        <span className={style.webhookName}>{props.endpoint.name}</span>
        {!props.endpoint.enabled && <span className={style.webhookDisabledLabel}>Disabled</span>}
      </td>
      <td className={style.webhookUrlCell}>{props.endpoint.url}</td>
      <td className={style.webhookTriggeredCell}>
        <WebhookTriggeredByMessage folderNames={folderNames} events={props.endpoint.events} />
      </td>
      <td className={style.webhookActionCell}>
        {props.menu && (
          <Dropdown alignRight>
            <Dropdown.Toggle className={style.dropdownToggle}>
              <MoreHorizIcon className={style.moreIcon} data-testid="more-icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={props.menu.onEdit} data-testid="webhook-edit-button">
                {editWebhookText}
              </Dropdown.Item>
              <Dropdown.Item onClick={props.menu.onViewDeliveryHistory}>View delivery history</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

const WebhookTriggeredByMessage = (props: { events: string[]; folderNames: (string | null)[] }) => {
  const hasFolders = props.folderNames.length > 0;

  const list = (
    <ul
      className={classNames({
        [style.webhookTriggeredByList]: true,
        [style.hasFolders]: hasFolders,
      })}
    >
      {props.events.map((event) => (
        <li key={event}>{webhookInlineLabels[event]}</li>
      ))}
    </ul>
  );

  if (!hasFolders) {
    return (
      <>
        <p className={style.webhookTriggeredByFolders}>For all components:</p>
        {list}
      </>
    );
  }

  const offset = props.folderNames.includes(null) ? 1 : 0;

  return (
    <>
      <p className={style.webhookTriggeredByFolders}>
        For components{" "}
        {props.folderNames.includes(null) && (
          <span>
            <b>not in folders</b>
            {props.folderNames.length === 1 && ":"}
            {props.folderNames.length >= 2 && " and "}
          </span>
        )}
        {!(props.folderNames.length === 1 && props.folderNames[0] === null) && <>in </>}
        {props.folderNames.filter(Boolean).map((folderName, i) => (
          <span key={folderName}>
            <b>{folderName}</b>
            {i < props.folderNames.length - 1 - offset && ", "}
            {i === props.folderNames.length - 1 - offset && ":"}
          </span>
        ))}
      </p>
      {list}
    </>
  );
};

export default WebhooksTab;
