import { Ditto } from "ditto-react";
import React from "react";
import style from "./style.module.css";

import imgSplash from "../../../../assets/home-page-splash.png";

export interface Props {
  firstName: string;
}

export default function WelcomeHeader(props: Props) {
  return (
    <div>
      <div className={style.container}>
        <div className={style.content}>
          <div className={style.title}>
            <Ditto componentId="welcome-header-title" variables={{ firstName: props.firstName }} />
          </div>
          <div className={style.subtitle}>
            <Ditto componentId="welcome-header-subtitle" />
          </div>
        </div>
        <div className={style.imageContainer}>
          <img src={imgSplash} alt="splash" className={style.image} />
        </div>
      </div>
    </div>
  );
}
