import UserManagementDropdown from "@/components/account-workspace/UserManagementDropdown";
import { IFUser } from "@shared/types/User";
import classnames from "classnames";
import React, { useState } from "react";
import PermissionTags from "../../permissionTags";
import style from "./style.module.css";

interface Props {
  canEditUser: (userToEdit: IFUser) => boolean;
  canManagePermissions: boolean;
  canRemoveUsers: boolean;
  divider?: boolean;
  permissionGroupsEnabled: boolean;
  user: IFUser;
  onManagePermissions: (user: IFUser) => void;
  onSelectRole: (user: IFUser, option: any) => void;
  onRemoveUser: (user: IFUser) => void;
  permissionOptions: { name: string; value: string; isDisabled: boolean }[];
  permissionGroups: { _id: string; name: string }[];
}

export default function UserRow(props: Props) {
  const [show, setShow] = useState(true);

  return (
    <>
      <tr
        className={classnames({ [style.divider]: props.divider })}
        data-testid={`user-row-${props.user.name}`}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <td className={style.column} data-testid="user-name">
          <span className={style.name}>{props.user.name}</span>{" "}
          <span className={style.email}>({props.user.email})</span>
        </td>
        <td className={style.permissionsColumn}>
          <div className={style.lastColumn}>
            <PermissionTags permissions={props.user.permissionGroups} permissionGroups={props.permissionGroups} />
            {props.canEditUser(props.user) && (
              <div style={{ visibility: show ? "visible" : "hidden" }}>
                <UserManagementDropdown
                  className={style.optionsBtn}
                  optionClassName={style.option}
                  options={props.permissionOptions}
                  onSelectRole={(option: any) => props.onSelectRole(props.user, option.value)}
                  showManagePermissions={props.permissionGroupsEnabled}
                  canManagePermissions={props.canManagePermissions}
                  onManagePermissions={() => props.onManagePermissions(props.user)}
                  selected={""}
                  canRemoveUsers={props.canRemoveUsers}
                  onRemoveUser={() => props.onRemoveUser(props.user)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
}
