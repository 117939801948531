import { z } from "zod";

export const Suggestion = z.object({
  text: z.string(),
  nodeId: z.string(),
  confidence: z.number(),
});
export type Suggestion = z.infer<typeof Suggestion>;

export const BlockSuggestion = z.object({
  id: z.string(),
  nodes: z.array(Suggestion),
  name: z.string(),
});
export type BlockSuggestion = z.infer<typeof BlockSuggestion>;

export const WsComponentSuggestion = z.object({
  /**
   * figma_node_id (for now)
   */
  id: z.string(),
  /**
   * Component id, not ActualComponent id
   */
  wsComponentId: z.string(),
});

export type WsComponentSuggestion = z.infer<typeof WsComponentSuggestion>;
