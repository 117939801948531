import React from "react";
import figcomp from "../../assets/figcomp.svg";
import figinstance from "../../assets/figma_instance.svg";
import style from "./style.module.css";

interface Props {
  type?: "COMPONENT" | "INSTANCE" | "TEXT";
}
const FigmaComponentTextNodeLabel = ({ type }: Props) => {
  if (!type || type === "TEXT") return null;
  return (
    <div className={style.textNodeLabel}>
      <img src={type === "COMPONENT" ? figcomp : figinstance} />
      <span>This text item is in a nested Figma {type === "COMPONENT" ? "main component" : "component instance"}.</span>
    </div>
  );
};

export default FigmaComponentTextNodeLabel;
