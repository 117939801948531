import { useIsShiftPressed } from "@/hooks/useIsShiftPressed";
import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import classnames from "classnames";
import { DittoComponent } from "ditto-react";
import React, { useEffect, useState } from "react";
import ButtonSecondary from "../button/buttonsecondary";
import style from "./style.module.css";

const PageFrameSelection = ({
  pages,
  groupsByPage,
  handleSelectFrame,
  isSaving,
  isImportModal,
  isLoading,
  handleToggleAllSelectedForPage,
  selectedFramesByPage,
}) => {
  const [initialDeselectDetected, setInitialDeselectDetected] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const [selectedPage, setSelectedPage] = useState(null);
  const [query, setQuery] = useState("");

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };
  const showWarningMessage = () => {
    setInitialDeselectDetected(true);
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 3000);
  };

  useEffect(() => {
    if (!isImportModal && !selectedPage && pages.length) {
      setSelectedPage(pages[0]);
    }
  }, [pages]);

  const isShiftPressed = useIsShiftPressed();

  const PageItem = ({ pageId }) => {
    let selectedFrameCount = groupsByPage[pageId] ? groupsByPage[pageId].filter((frame) => frame.isSelected).length : 0;
    return (
      <div className={style.pageInfo}>
        {selectedFrameCount > 0 && <div className={style.pageFrameCount}>{selectedFrameCount}</div>}
        <ChevronRight className={style.icon} />
      </div>
    );
  };

  const anyFramesOnPageSelected =
    selectedPage &&
    selectedFramesByPage[selectedPage.id] &&
    selectedFramesByPage[selectedPage.id].some((frame) => frame.isSelected);

  return (
    <div className={style.wrapper}>
      <div className={style.pagesSection}>
        <div className={style.pageTitle}>Pages</div>
        {isLoading && <div className={style.loadingPageMessage}>Loading pages...</div>}
        {!isLoading && (
          <div className={style.pageList}>
            {pages.map((page) => (
              <div
                key={page.id}
                data-testid={`import-page-${page.id}`}
                className={classnames({
                  [style.pageItem]: true,
                  [style.active]: selectedPage && page.id === selectedPage.id,
                  [style.pageFramesSelected]:
                    selectedPage &&
                    page.id !== selectedPage.id &&
                    groupsByPage[page.id] &&
                    groupsByPage[page.id].filter((frame) => frame.isSelected).length > 0,
                })}
                onClick={() => setSelectedPage(page)}
              >
                <div className={style.pageName}>{page.name}</div>
                <PageItem pageId={page.id} />
              </div>
            ))}
          </div>
        )}
      </div>
      {isImportModal && !selectedPage ? (
        <div className={style.importMessage}>
          <div className={style.label}>
            <DittoComponent componentId="import-file.no-page-selected" />
          </div>
          <div className={style.message}>You can always change this or add new frames later! </div>
        </div>
      ) : (
        <div className={style.framesSection}>
          {isLoading && <div className={style.loadingFramesMessage}>Loading frames...</div>}
          {!isLoading && (
            <>
              <div className={style.framesHeader}>
                <div className={style.top}>
                  {selectedPage && <div className={style.frameName}>{selectedPage.name}</div>}
                  <ButtonSecondary
                    className={style.toggleSelectAllButton}
                    text={anyFramesOnPageSelected ? "Unselect All" : "Select All"}
                    onClick={() => handleToggleAllSelectedForPage(selectedPage.id, !anyFramesOnPageSelected)}
                  />
                </div>
                <div className={style.searchBox}>
                  <SearchIcon className={style.icon} />
                  <input
                    autoFocus
                    className={classnames(style.input)}
                    placeholder={"Search frames"}
                    value={query}
                    onChange={handleQueryChange}
                  />
                </div>
              </div>
              <div className={style.frameList}>
                {selectedPage &&
                  groupsByPage[selectedPage.id] &&
                  groupsByPage[selectedPage.id]
                    .filter((frame) => frame.name.toLowerCase().includes(query))
                    .map((frame) => (
                      <div
                        key={frame.id}
                        data-testid={`import-frame-${frame.id}`}
                        className={classnames({
                          [style.frameItem]: true,
                          [style.isSelected]: frame.isSelected,
                          [style.isDisabled]: isSaving,
                        })}
                        onClick={() => {
                          if (!isSaving) {
                            if (!initialDeselectDetected && !isImportModal && frame.isSelected) showWarningMessage();
                            handleSelectFrame({
                              pageId: selectedPage.id,
                              frameId: frame.id,
                              isShiftClick: isShiftPressed,
                            });
                          }
                        }}
                      >
                        <input type="checkbox" disabled={isSaving} checked={frame.isSelected} onChange={() => {}} />
                        <span className={style.frameNameText}>{frame.name}</span>
                      </div>
                    ))}
              </div>
              {showWarning && (
                <div className={style.warningMessage}>
                  Heads up, unselecting frames will remove them from your project and syncs!
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PageFrameSelection;
