import React from "react";
import ButtonPrimary from "../../../button/buttonprimary";

import style from "./style.module.css";

const Title = () => <>Continue importing Figma file branch?</>;

interface BodyProps {
  mainProject: { _id: string; name: string; figma_url: string } | null;
  branchImportConfirmed: boolean;
  toggleBranchImportConfirmed: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const Body = ({ branchImportConfirmed, mainProject, toggleBranchImportConfirmed }: BodyProps) => (
  <div className={style.body}>
    <h5>Figma file already linked to Ditto</h5>
    <p>
      This is a branch of a Figma file you’ve already imported into Ditto. If you import this branch, your existing
      project (
      <a href={`/projects/${mainProject?._id}`} target="_blank">
        {mainProject?.name}
      </a>
      ) will be locked from further editing in Ditto until this branch is merged in Figma.
    </p>
    <h5>Importing another branch</h5>
    <p>
      If you’d like to continue with importing this branch, we recommend heading{" "}
      <a href={mainProject?.figma_url} target="_blank">
        to the main branch Figma file
      </a>{" "}
      and resyncing via the Figma plugin before continuing here. This will ensure that no recent edits made in that
      project are lost.
    </p>
    <div className={style.inputWrapper}>
      <input type="checkbox" checked={branchImportConfirmed} onChange={toggleBranchImportConfirmed} />
      <span>I’ve resynced in the main branch Figma file using Ditto’s Figma plugin.</span>
    </div>
  </div>
);

interface FooterProps {
  onCancel: () => void;
  onContinue: (e: React.FormEvent) => void;
  isDisabled: boolean;
  isGetPagesLoading: boolean;
}
const Footer = ({ isDisabled, onCancel, onContinue, isGetPagesLoading }: FooterProps) => (
  <div className={style.footer}>
    <a href="https://www.dittowords.com/docs/figma-branching" target="_blank">
      Learn more
    </a>
    <div className={style.buttonWrapper}>
      <ButtonPrimary disabled={isGetPagesLoading} text="Cancel" className={style.cancelButton} onClick={onCancel} />
      <ButtonPrimary
        disabled={isDisabled || isGetPagesLoading}
        text={!isGetPagesLoading ? "Continue ->" : "Fetching pages..."}
        onClick={onContinue}
      />
    </div>
  </div>
);

const ImportBranchConfirmation = ({ children }: { children: React.ReactNode }) => <>{children}</>;
ImportBranchConfirmation.Title = Title;
ImportBranchConfirmation.Body = Body;
ImportBranchConfirmation.Footer = Footer;

export default ImportBranchConfirmation;
