import classNames from "classnames";
import React from "react";

import style from "./GrayWarning.module.css";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const GrayWarning = (props: Props) => {
  return <p className={classNames(style.container, props.className)}>{props.children}</p>;
};
