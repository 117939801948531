import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import LockIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import { IFRole } from "@shared/types/User";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ReactTooltip from "react-tooltip";
import ButtonPrimary from "../button/buttonprimary";
import InviteUsersInput from "../permissions/InviteUsersInput";
import style from "./style.module.css";
import { useShareProject } from "./useShareProject";

interface ShareProjectModalProps {
  projectId: string;
  projectName: string;
  folder: { _id: string; name: string; invite_only: boolean } | null;
  currentUser: {
    _id: string;
    email: string;
    role: IFRole;
  };
  setNotification: (input: { type: string; message: string }) => void;
  onHide: () => void;
}

const ShareProjModal = (props: ShareProjectModalProps) => {
  const { projectName, folder, currentUser, onHide } = props;

  const {
    isSending,
    invites,
    userList,
    messageInput,
    canSendInvite,
    inviteToProj,
    messageInputChange,
    onUserRemoved,
    onUserInvited,
    showExternalInviteMsg,
  } = useShareProject(props);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>
            Share<span className={style.projectName}>{projectName}</span>
          </BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          {folder?.invite_only && (
            <div className={style.folderWarning}>
              <LockIcon />
              Only members of the folder <span className={style.folderName}>{folder.name}</span> can access this
              project.
            </div>
          )}
          {(!folder || !folder.invite_only) && (
            <div className={style.folderWarning}>
              <PublicIcon />
              Anyone in this workspace can access this project.
              <InfoIcon className={style.folderInfoIcon} data-tip data-for="folder-info" />
              <ReactTooltip id="folder-info" place="bottom" effect="solid">
                To restrict access, move this project into an invite-only folder.
              </ReactTooltip>
            </div>
          )}
          <div className={style.subTitle}>Invite collaborators</div>
          <InviteUsersInput
            disabled={isSending}
            currentUserRole={currentUser.role}
            invitedUserList={invites}
            userList={userList}
            existingInvitedEmails={{}}
            onUserRemoved={onUserRemoved}
            onUserInvited={onUserInvited}
          />
          <textarea
            placeholder="Add a custom message (optional)"
            value={messageInput}
            disabled={isSending}
            className={style.message}
            onChange={messageInputChange}
          />
          {showExternalInviteMsg && (
            <p className={style.disclaim}>
              Heads up! New members invited to collaborate on this project will join your workspace
              {!folder ? (
                "."
              ) : (
                <>
                  {" "}
                  and the folder <strong>{folder.name}</strong>.
                </>
              )}
            </p>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <ButtonPrimary
            text={isSending ? "Sending..." : `Send Invite${invites.length > 1 ? "s" : ""}`}
            onClick={() => inviteToProj()}
            disabled={!canSendInvite}
            data-testid="send-invite-button"
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default ShareProjModal;
