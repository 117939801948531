import type { IntegrationMessagePayload } from "@shared/types/SlackNotifications";
import type { IBWebhookContext, IBWebhookFilters } from "@shared/types/Workspace";
import type { IObjectId, ZCreatableId } from "@shared/types/lib";
import type { WebSocketClient } from "@shared/types/websocket";

import { z } from "zod";

export interface IDittoEventTargetContext {
  workspaceId: string | IObjectId;
  componentId: string | IObjectId | null;
}

export interface IDittoEventSegmentTargetContext {
  workspaceId: string | IObjectId;
  userId: string;
}

export interface IDittoEventSlackTargetContext {
  projectId: string | IObjectId;
  workspaceId: string | IObjectId;
}

export interface IDittoEventTargetWebhook<EmitData extends object> {
  type: "webhook";
  /**
   * Callback that should return contextual information from the emit data.
   * @param data `data` passed to the event's `.emit()` function
   * @returns
   */
  getContext: (data: EmitData) => IDittoEventTargetContext;

  /**
   * Optional callback to filter the events that should be emitted to the webhook.
   * @param data `data` passed to the event's `.emit()` function
   * @returns `true` if the event should be emitted to the webhook
   */
  filterEvents?: (data: EmitData, filters: IBWebhookFilters, context: IBWebhookContext) => Promise<boolean>;

  /**
   * Optional callback to format the `.emit()` data into the shape that should be included in the webhook payload.
   * @param data `data` passed to the event's `.emit()` function
   * @param target The configuration for the webhook target
   * @returns
   */
  formatWebhookData?: (data: EmitData, target: IDittoEventTargetWebhook<EmitData>) => object;
}

export interface IDittoEventTargetSlack<EmitData extends object> {
  type: "slack";
  /**
   * Callback that should return contextual information from the emit data.
   * @param data `data` passed to the event's `.emit()` function
   * @returns
   */
  getContext: (data: EmitData) => IDittoEventSlackTargetContext;

  /**
   * Optional callback to filter the events that should be emitted to the webhook.
   * @param data `data` passed to the event's `.emit()` function
   * @returns `true` if the event should be emitted to the webhook
   */
  filterEvents?: (data: EmitData) => Promise<boolean>;

  /**
   * Optional callback to format the `.emit()` data into the shape that should be included in the slack payload.
   * @param data `data` passed to the event's `.emit()` function
   * @param target The configuration for the webhook target
   * @returns
   */
  formatSlackData: (data: EmitData, target: IDittoEventTargetSlack<EmitData>) => Promise<IntegrationMessagePayload>;
}

export interface IDittoEventTargetWebsocket<EmitData extends object> {
  type: "websocket";
  /**
   * Optionally override the name of the event emitted to websockets.
   * Defaults to the name of the Ditto event if not provided.
   */
  channel?: string;

  /**
   * Optional callback to format the `.emit()` data into the shape that should be included in the webhook payload.
   * @param data `data` passed to the event's `.emit()` function
   * @param target The configuration for the webhook target
   * @returns
   */
  formatWebsocketData?: (data: EmitData, target: IDittoEventTargetWebsocket<EmitData>) => object;

  /**
   * Callback executed for each connected websocket client. Should
   * return `true` for each client that should receive the websocket event.
   * @param client connected websocket client
   * @param emitData `data` passed to the event's `.emit()` function
   * @returns
   */
  identifyClient: (client: WebSocketClient, emitData: EmitData) => boolean;
}

export interface IDittoEventTargetSegment<EmitData extends object> {
  type: "segment";
  /**
   * Optionally override the name of the event emitted to Segment.
   * Defaults to the name of the Ditto event if not provided.
   */
  segmentEventName?: string;
  /**
   * Callback that should return contextual information from the emit data.
   * @param data `data` passed to the event's `.emit()` function
   * @returns
   */
  getContext: (data: EmitData) => IDittoEventSegmentTargetContext;

  /**
   * Optional callback to filter if an event should be emitted to segment.
   * @param data `data` passed to the event's `.emit()` function
   * @returns `true` if the event should be emitted to segment.
   */
  filterEvents?: (data: EmitData) => Promise<boolean>;

  /**
   * Optional callback to format the `.emit()` data into the shape that should be included in the segment payload.
   * @param data `data` passed to the event's `.emit()` function
   * @param target The configuration for the webhook target
   * @returns
   */
  formatSegmentData?: (data: EmitData, target: IDittoEventTargetSegment<EmitData>) => object;
}

export type IDittoEventTarget<EmitData extends object> =
  | IDittoEventTargetWebhook<EmitData>
  | IDittoEventTargetWebsocket<EmitData>
  | IDittoEventTargetSegment<EmitData>
  | IDittoEventTargetSlack<EmitData>;

export interface IDittoEvent<
  EmitData extends object,
  Z = z.AnyZodObject | z.ZodUnion<any> | z.ZodDiscriminatedUnion<string, z.ZodDiscriminatedUnionOption<string>[]>
> {
  name: string;
  targets: IDittoEventTarget<EmitData>[];
  dataShape: Z;
}

export const dittoEvents: IDittoEvent<any>[] = [];

export function createDittoEvent<
  ZData extends
    | z.AnyZodObject
    | z.ZodUnion<any>
    | z.ZodDiscriminatedUnion<string, z.ZodDiscriminatedUnionOption<string>[]>,
  EmitData extends z.infer<ZData>
>(args: {
  /**
   * The name of the event. Must be unique.
   */
  name: string;
  /**
   * A Zod object defining the shape of the event's data. The data shape will be
   * required as an argument when `.emit()` as called, and this Zod object will be
   * used as a runtime validator.
   */
  data: ZData;
  /**
   * Targets to emit events to when `.emit()` is called.
   */
  targets: IDittoEventTarget<EmitData>[];
}) {
  const event = {
    name: args.name,
    targets: args.targets,
    dataShape: args.data,
  };

  if (dittoEvents.some((e) => e.name === args.name)) {
    const msg = `Ditto event with name "${args.name}" already exists. Ditto events must be unique.`;
    throw new Error(msg);
  }

  dittoEvents.push(event);

  return event;
}

export const getWebsocketTargets = (targets: IDittoEventTarget<any>[]) =>
  targets.filter((w) => w.type === "websocket") as IDittoEventTargetWebsocket<any>[];

export const getRedisChannel = (eventName: string, _target: IDittoEventTargetWebsocket<any>) =>
  _target.channel || eventName;

export const getWebsocketMessageType = (eventName: string, _target: IDittoEventTargetWebsocket<any>) => eventName;

export const getWebhookName = (eventName: string, _target: IDittoEventTargetWebhook<any>) => eventName;

/**
 * @param channel the Redis channel a message was received through
 * @param message the Redis message that was received
 * @param webSocketClients all websocket clients held for process
 * @returns boolean that is `true` if the channel message was handled by a DittoEvent
 */
export function maybeHandleRedisChannelMessageWithDittoEvent(
  channel: string,
  message: string,
  webSocketClients: WebSocketClient[]
) {
  let data: object;
  try {
    data = JSON.parse(message);
  } catch {
    return false;
  }

  // Check registered Ditto events for an event that is configured
  // for this Redis channel.
  for (const dittoEvent of dittoEvents) {
    if (channel !== dittoEvent.name) {
      continue;
    }

    const websocketTargets = getWebsocketTargets(dittoEvent.targets);
    for (const websocketTarget of websocketTargets) {
      for (const client of webSocketClients) {
        // The websocketTarget's identifyClient method informs
        // if a given client should have a messaged emitted
        // to it.
        const isClient = websocketTarget.identifyClient(client, data);
        if (!isClient) continue;

        // If the websocketTarget has an explicit websocket event name
        // use that; o/w default to using the name of the Ditto Event.
        const messageType = getWebsocketMessageType(dittoEvent.name, websocketTarget);

        const messageStr = JSON.stringify({ messageType, ...data });

        client.ws.send(messageStr);
      }
    }

    // Indicate that a Ditto event handled this channel
    return true;
  }

  // Indicate that a Ditto event did not handle this channel
  return false;
}

/**
 * A reusable webhook filtering function for determining whether or not
 * a component event should be emitted to a webhook according to the
 * folder the corresponding component is in.
 * @param data `data` passed to the event's `.emit()` function
 * @param filters The `filters` object stored on a workspace's webhook endpoint
 * @returns
 */
export async function filterWebhookEventsByComponentFolder<EmitData extends { folderId: ZCreatableId | null }>(
  data: EmitData,
  filters: IBWebhookFilters,
  _: IBWebhookContext
) {
  // if no filter specified, return true for all components
  if (!filters.componentFolderIds) {
    return true;
  }

  // if the event has a folder id, return true if the webhook's filter
  // includes the folder id
  if (data.folderId) {
    return filters.componentFolderIds.some((id) => id?.toString() === data.folderId?.toString());
  }

  // if the event doesn't have a folder id, return true if the webhook's filter
  // include `null` for "no folder" components
  return filters.componentFolderIds.includes(null);
}

export async function filterOutSampleDataComponentEvents<EmitData extends { componentId: ZCreatableId }>(
  data: EmitData,
  _: IBWebhookFilters,
  context: IBWebhookContext
) {
  if (!context.component) return true;
  return Boolean(!context.component.isSample);
}
