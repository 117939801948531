import SvgIcon from "@mui/material/SvgIcon";
import classnames from "classnames";
import { Ditto } from "ditto-react";
import React from "react";
import style from "./card.module.css";

export interface Props {
  titleDittoComponentId: string;
  subtitleDittoComponentId: string;
  ctaDittoComponentId: string;
  secondaryCtaDittoComponentId?: string;
  secondaryCtaIcon?: typeof SvgIcon | ((props: any) => JSX.Element);
  onCtaClick?: () => void;
  onSecondaryCtaClick?: () => void;
  stacked: boolean;
  image: string;
  className?: string;
}

export default function Card(props: Props) {
  return (
    <div className={classnames(style.container, props.className, { [style.flex]: !props.stacked })}>
      <div className={style.image}>
        <img src={props.image} />
      </div>
      <div className={style.content}>
        <div className={style.text}>
          <div className={style.title}>
            <Ditto componentId={props.titleDittoComponentId} />
          </div>
          <div className={style.subtitle}>
            <Ditto componentId={props.subtitleDittoComponentId} />
          </div>
        </div>
        <div className={style.buttons}>
          <div className={style.cta} onClick={props.onCtaClick}>
            <Ditto componentId={props.ctaDittoComponentId} />
          </div>
          {props.secondaryCtaDittoComponentId && (
            <div className={style.secondaryCta} onClick={props.onSecondaryCtaClick}>
              {props.secondaryCtaIcon && <props.secondaryCtaIcon className={style.secondaryCtaIcon} />}
              <Ditto componentId={props.secondaryCtaDittoComponentId} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
