import classNames from "classnames";
import React from "react";

import style from "./style.module.css";

export interface CompactLabelProps {
  text: React.ReactNode;
  Icon: React.FC;
  className?: string;
}

const CompactLabel = (props: CompactLabelProps) => {
  const { text, Icon } = props;
  return (
    <span className={classNames(style.compactLabel, props.className || "")}>
      <div className={style.icon}>
        <Icon />
      </div>
      {text}
    </span>
  );
};

export default CompactLabel;
