type SearchType =
  | "component"
  | "command"
  | "create"
  | "empty"
  | "folder"
  | "import"
  | "invite"
  | "noMatches"
  | "project"
  | "search"
  | "section"
  | "textItem"
  | "variables"
  | "variant"
  | "view";

export interface SearchResult {
  _id: string;
  type: SearchType;
  title: string;
  subtitle?: string;
  link?: string;
  action?: string;
}

export const EMPTY_STATE_ID = "empty-state";

export const EMPTY_STATE_ITEMS: SearchResult[] = [
  {
    _id: "create-component-command",
    type: "create",
    title: "Create component",
    subtitle: "Create a new component in your library",
    link: "/components?openCreateModal=true",
  },
  {
    _id: "import-string-command",
    type: "import",
    title: "Import strings as components",
    subtitle: "Bring your product's existing strings into Ditto as text components",
    link: "/components?openImportModal=true",
  },
  {
    _id: "invite-user-command",
    type: "invite",
    title: "Invite team members",
    subtitle: "Invite your team to collaborate on your projects",
  },
  {
    _id: "view-webhooks-command",
    type: "view",
    title: "Go to webhooks",
    subtitle: "Webhooks HTTP payloads to be sent to an external service when events happen in Ditto.",
    link: "/developers/webhooks",
  },
  {
    _id: "view-api-keys-command",
    type: "view",
    title: "Go to api keys",
    subtitle: "API keys allow you to authenticate API requests, either directly or through our CLI.",
    link: "/developers/api-keys",
  },
  {
    _id: "view-developer-ids-command",
    type: "view",
    title: "Go to developer ID configuration",
    subtitle: "IDs allow developers to reference individual strings in Ditto.",
    link: "/developers/configure-dev-ids",
  },
];
