import { isValidMongoID } from "./mongodb";

// make URL with params
export function urlWithParams(url, data) {
  const urlObj = new URL(url);
  urlObj.search = new URLSearchParams(data);
  return urlObj;
}

export function getURLParams(url) {
  let params = new URL(url).searchParams;
  return params;
}

// parse doc ID from doc URL
export function parseDocID(path) {
  const keyword = "/doc/";
  const startIndex = keyword.length + path.indexOf(keyword);
  let id = path.substring(startIndex, startIndex + 24);
  if (isValidMongoID(id)) {
    return id;
  } else {
    return null;
  }
}
