import SvgIcon from "@mui/material/SvgIcon";
import classNames from "classnames";
import { Ditto } from "ditto-react";
import React from "react";
import style from "./panel.module.css";

export interface Props {
  titleDittoComponentId: string;
  count?: number;
  icon: typeof SvgIcon | ((props: any) => JSX.Element);
  children?: React.ReactNode;
  containerClassName?: string;
  headerClassName?: string;
  contentClassName?: string;
}

export default function Panel(props: Props) {
  const variables = {
    ...(props.count ? { count: props.count } : {}),
  };

  return (
    <div className={classNames(style.container, props.containerClassName)}>
      <div className={classNames(style.header, props.headerClassName)}>
        <props.icon className={style.icon} />
        <div className={style.title}>
          <Ditto componentId={props.titleDittoComponentId} variables={variables} />
        </div>
      </div>
      <div className={classNames(style.content, props.contentClassName)}>{props.children}</div>
    </div>
  );
}
