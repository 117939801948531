import CloseIcon from "@mui/icons-material/Close";
import React, { useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import ConfirmationModal from "../shared/confirmation-modal.tsx";
import style from "./style.module.css";

const DeleteProjModal = ({ doc_ID, doc_name, onHide, isMissingBranch, projectBranchInfo }) => {
  const [canDelete, setCanDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [delInput, setDelInput] = useState("");
  const history = useHistory();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const numArchivedBranchProjects = useMemo(() => {
    if (projectBranchInfo?.isBranch) return 0;
    if (projectBranchInfo?.mergedBranches) return projectBranchInfo.mergedBranches.length;
    return 0;
  }, [projectBranchInfo]);

  const delInputChange = (e) => {
    setDelInput(e.target.value);
    if (e.target.value === "DELETE") {
      setCanDelete(true);
    } else {
      setCanDelete(false);
    }
  };

  const deleteProj = async () => {
    setIsDeleting(true);
    try {
      const { url } = API.doc.delete.delete;
      await http.delete(url(doc_ID));

      setIsDeleting(false);
      setShowConfirmationModal(true);
    } catch (e) {
      console.log("in docdetail.jsx: ", e.message);
    }
  };

  return (
    <div>
      {!showConfirmationModal && (
        <BootstrapModal
          show={true}
          className={style.modal}
          dialogClassName={style.dialog}
          backdropClassName={style.backdrop}
          onHide={onHide}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>
              {isMissingBranch && "Can’t find main branch of Figma file. Delete Ditto project?"}
              {!isMissingBranch && `Delete ${doc_name}`}
            </BootstrapModal.Title>
            {!isMissingBranch && <CloseIcon className={style.close} onClick={onHide} />}
          </BootstrapModal.Header>
          <BootstrapModal.Body className={style.body}>
            {!isMissingBranch && (
              <>
                <p>
                  Heads up! If you delete this project, any text edit history, tags, and notes will also be deleted.{" "}
                </p>
                <p>This won't affect your Figma file and you will still be able to reimport the file in the future.</p>
              </>
            )}
            {isMissingBranch && (
              <p>
                We can’t find the main branch of this Figma file, so we can’t re-link this Ditto project. Would you like
                to delete this project instead?
              </p>
            )}
            {numArchivedBranchProjects > 0 && (
              <p>
                This project has {numArchivedBranchProjects} archived branch
                {numArchivedBranchProjects > 1 ? "es" : ""} in Ditto, which will also be deleted.
              </p>
            )}

            <p>
              To delete this project, type the word <span className={style.codeWord}>DELETE</span>
            </p>
            <input
              data-testid="delete-project-input"
              placeholder="Type DELETE here"
              value={delInput}
              onChange={delInputChange}
            />
            <div className={style.footer}>
              <ButtonPrimary
                data-testid="delete-project-confirm-button"
                text={isDeleting ? "Deleting..." : "Delete Ditto Project"}
                onClick={deleteProj}
                disabled={!canDelete || isDeleting}
              />
            </div>
          </BootstrapModal.Body>
        </BootstrapModal>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          title="Project deleted"
          actionPrimary="Continue"
          onPrimary={() => {
            setShowConfirmationModal(false);
            history.replace("/projects");
          }}
          onSecondary={() => {
            setShowConfirmationModal(false);
            history.replace("/projects");
          }}
        />
      )}
    </div>
  );
};
export default DeleteProjModal;
