import classnames from "classnames";
import React from "react";
import { GroupHeadingProps, components } from "react-select";
import style from "./style.module.css";

const ComponentGroupHeading = (props: GroupHeadingProps<any, any>) => {
  if (props.children === "category_menu") return null;
  return <components.GroupHeading {...props} className={classnames({ [style.group]: props.children === "ALL" })} />;
};

export default ComponentGroupHeading;
