import { createBrowserHistory } from "history";
export default createBrowserHistory();

export const pageLocationEnum = {
  project: "project",
  componentLibrary: "component_library",
  search: "search",
  workspaceSettings: "workspace_settings",
  userSettings: "user_settings",
};

/**
 * Takes a assigned useLocation() and returns an enum string
 * based on the current location. Used for analytics at the moment,
 * but could be expanded to other usages throughout the app
 *
 * @param {*} location assigned useLocation()
 * @returns {string} pageLocationEnum
 */
export const getCurrentPageLocation = function (location) {
  if (!location.pathname) {
    throw new Error("Location not defined");
  }
  if (location.pathname.startsWith("/account/user")) {
    return pageLocationEnum.userSettings;
  }
  if (location.pathname.startsWith("/account/workspace")) {
    return pageLocationEnum.workspaceSettings;
  }
  if (location.pathname.startsWith("/components")) {
    return pageLocationEnum.componentLibrary;
  }
  if (location.pathname.startsWith("/doc")) {
    return pageLocationEnum.project;
  }
  if (location.pathname.startsWith("/search")) {
    return pageLocationEnum.search;
  }
  return null;
};
