import classnames from "classnames";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IUser } from "../../../../shared/types/User";
import Avatar from "../../atoms/Avatar";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface MentionListProps {
  items: IUser[];
  command: (item: IUser & { id: string }) => void;
}

const MentionList = forwardRef((props: MentionListProps, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.userId, ...item });
    }
  };

  const upHandler = () => {
    setSelectedIndex((prevIndex) => (prevIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    if (props.items.length > 0) {
      selectItem(selectedIndex);
      return true;
    } else {
      return false;
    }
  };

  useEffect(
    function resetSelected() {
      setSelectedIndex(0);
    },
    [props.items]
  );

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter" || event.key === " ") {
        return enterHandler();
      }

      return false;
    },
  }));

  if (!props.items.length) {
    return <></>;
  }

  return (
    <div className={style.mentionListItems}>
      {props.items.map((user, index) => (
        <div
          key={user.userId}
          className={classnames(style.mentionListItem, {
            [style.mentionListItemIsSelected]: index === selectedIndex,
          })}
          onClick={() => selectItem(index)}
        >
          <Avatar picture={user.picture} userName={user.name} size="sm" className={style.avatar} />
          <Text color="primary" size="small" className={style.userName}>
            {user.name}
          </Text>
        </div>
      ))}
    </div>
  );
});

export default MentionList;
