import ApiID from "@/components/api-id";
import { duplicateProject } from "@/http/docNew";
import { useWorkspace } from "@/store/workspaceContext";
import { DUPLICATE_DRAFT_PROJECT_ENABLED_WORKSPACES } from "@/utils/featureFlags";
import ConcurrentUserListWrapper from "@/views/Project/components/ConcurrentUserListWrapper/ConcurrentUserListWrapper";
import CodeIcon from "@mui/icons-material/Code";
import HelpIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaletteIcon from "@mui/icons-material/Palette";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EyeSlashedIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@shared/frontend/Tooltip";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFProject } from "@shared/types/Project";
import classNames from "classnames";
import { DittoComponent } from "ditto-react";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import { useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import ReactTooltip from "react-tooltip";
import TestIDs from "../../../../shared/frontend/testIds";
import { SlackChannelInfo, SlackNotificationTypes } from "../../../../shared/types/SlackNotifications";
import { UpdateSlackNotifsRequestBody } from "../../../../shared/types/http/Doc";
import CableIcon from "../../../assets/CableIcon";
import BetaBadge from "../../../components/BetaBadge";
import BranchingIcon from "../../../components/BranchingIcon";
import CommentOnlyBadge from "../../../components/CommentOnlyBadge";
import EditableName from "../../../components/EditableName";
import InviteCollaboratorsModal from "../../../components/InviteCollaboratorsModal";
import RichTextModal from "../../../components/RichTextModal";
import SampleBadge from "../../../components/SampleBadge";
import ShareProjectModal from "../../../components/ShareProjectModal";
import SlackChannelModal from "../../../components/SlackChannelModal";
import ButtonPrimary from "../../../components/button/buttonprimary";
import DeleteProjModal from "../../../components/deleteprojmodal/deleteprojmodal";
import DevModeModal from "../../../components/dev-mode-modal";
import ExportModal from "../../../components/export-modal/export-modal";
import NotificationToast from "../../../components/notification-toast";
import ConfirmationModal from "../../../components/shared/confirmation-modal";
import { routes } from "../../../defs";
import useSegment from "../../../hooks/useSegment";
import http, { API } from "../../../http";
import { DRAFTED_GROUPS_PAGE_ID } from "../state/usePageState";
import { useProjectContext } from "../state/useProjectState";
import { getProjectHasFigmaConnection } from "../state/useResyncState";
import FigmaResyncButton from "./FigmaResyncButton";
import LinkingHelpModal from "./LinkingHelpModal";
import { MultiSelectPopoverTrigger, useMultiSelectPopover } from "./MultiSelectPopover";
import ProjectBranchLabel from "./ProjectBranchLabel";
import style from "./ProjectHeader.module.css";
import ProjectLockedLabel from "./ProjectLockedLabel";
import SyncSettingsModal from "./SyncSettingsModal";
import { stateToFeatureFlags, useSyncSettingsModal } from "./SyncSettingsModal/useSyncSettingsModal";
import "./react-toggle-doc.module.css";
import { BranchProjectInfo, Variants } from "./types";

const ProjectTitle = ({ children }) => <h1>{children}</h1>;

interface ProjectHeaderProps {
  title: string;
  docSlackInfo: SlackChannelInfo;
  setDoc: React.Dispatch<React.SetStateAction<IFProject>>;
  figma_file_ID?: string;
  figma_branch_id?: string;
  resync: () => void;
  resyncLoading: boolean;
  is_loading: boolean;
  isLocked: boolean;
  folder: { _id: string; name: string; invite_only: boolean } | null;
  user: {
    _id: string;
    email: string;
    name: string;
    role: "admin" | "editor" | "commenter";
    workspaceId: string;
  };
  doc_ID: string;
  multiOn: boolean;
  onMultiSelectButtonClick: () => void;
  handleDisplayApiIds: (newState: boolean) => void;
  dev_mode_enabled: boolean;
  richTextEnabled: boolean;
  displayApiIds: boolean;
  handleDevModeEnabled: () => void;
  handleRichTextEnabled: () => void;
  variants: Variants;
  setSuggestedCompId: (id: string | null) => void;
  projectBranchInfo: {
    mainProjectInfo: BranchProjectInfo;
    branchProjectInfo: BranchProjectInfo;
  } | null;
  isSampleProject?: boolean;
  syncSettingsModalVisible: boolean;
  setSyncSettingsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFrameModal: () => void;
}

const ProjectHeader = (props: ProjectHeaderProps) => {
  const {
    title,
    docSlackInfo,
    setDoc,
    figma_file_ID,
    figma_branch_id,
    resync,
    resyncLoading,
    is_loading,
    isLocked,
    folder,
    user,
    doc_ID,
    multiOn,
    onMultiSelectButtonClick,
    handleDisplayApiIds,
    dev_mode_enabled,
    richTextEnabled,
    displayApiIds,
    handleDevModeEnabled,
    handleRichTextEnabled,
    setSuggestedCompId,
    projectBranchInfo,
    isSampleProject,
    syncSettingsModalVisible,
    setSyncSettingsModalVisible,
    toggleFrameModal,
  } = props;
  const segment = useSegment();
  const history = useHistory();

  const { workspaceInfo } = useWorkspace();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [devModalOpen, setDevModalOpen] = useState<boolean>(false);
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [regenModalOpen, setRegenModalOpen] = useState<boolean>(false);
  const [regenerateLoading, setRegenerateLoading] = useState<boolean>(false);
  const [regenerateError, setRegenerateError] = useState<string | null>(null);
  const [isInviteSending, setIsInviteSending] = useState<boolean>(false);
  const [notification, setNotification] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const [showSlackModal, setShowSlackModal] = useState(false);

  const projectContext = useProjectContext();
  const {
    projectId,
    groupTypeCounts,
    doc: [project],
    selectedPage: [selectedPage],
  } = projectContext;

  const [richTextModalOpen, setRichTextModalOpen] = projectContext.richTextModalOpen;

  const projectHasLinkingEnabled = groupTypeCounts.linkable !== 0 || groupTypeCounts.linked !== 0;

  const projectHasFigmaConnection = getProjectHasFigmaConnection(project);

  const isDraftedGroupsPage = selectedPage?.id === DRAFTED_GROUPS_PAGE_ID;
  const isNotDraftedGroupsPage = !isDraftedGroupsPage;
  const isMergedBranch = figma_branch_id && isLocked;

  const linkableOrLinkedGroupsVisible =
    (isDraftedGroupsPage && groupTypeCounts.linkable > 0) || (isNotDraftedGroupsPage && groupTypeCounts.linked > 0);

  const [showLinkingHelpModal, setShowLinkingHelpModal] = useState<boolean>(false);

  const canEditProject = userHasResourcePermission("project_folder:edit");
  const canDeleteProject = canEditProject && !resyncLoading;

  const syncSettingsModalProps = useSyncSettingsModal({
    projectId: doc_ID,
    figmaFileId: project?.integrations.figma.file_id,
    featureFlags: project?.feature_flags || {
      autoAttachComponents: { enabled: false },
      autoImportFrames: { enabled: false },
    },
    pages:
      project?.integrations.figma.selected_pages?.map((p) => ({
        id: p.figma_id,
        name: p.name,
      })) || [],
    hooks: {
      postSave: (state) => {
        const featureFlags = stateToFeatureFlags(state);
        projectContext.updateProjectFeatureFlags(featureFlags);
        setSyncSettingsModalVisible(false);

        // check to see if auto import frames feature flag was changed from disabled to enabled
        // or if it was enabled to see if the selected pages changed if so then resync when the modal is saved
        const previousSelectedPages = new Set<string>(
          project?.feature_flags.autoImportFrames?.enabled && project.feature_flags.autoImportFrames.selectedPages
            ? Object.keys(project.feature_flags.autoImportFrames.selectedPages)
            : []
        );

        const autoImportFramesSettingChanged =
          (!project?.feature_flags.autoImportFrames?.enabled && state.autoImportFrames.enabled) ||
          (project?.feature_flags.autoImportFrames?.enabled &&
            (previousSelectedPages.size !== state.autoImportFrames.pageIds.size ||
              [...state.autoImportFrames.pageIds].some((pageId) => !previousSelectedPages.has(pageId))));

        if (autoImportFramesSettingChanged) resync();
      },
    },
  });
  const showSyncSettingsModalButton = canEditProject;

  const onLinkingHelpClick = () => setShowLinkingHelpModal(true);

  const onDesignButtonClick = () => {
    if (projectHasFigmaConnection) {
      return;
    }

    setShowLinkingHelpModal(true);
  };

  const onDevModeClick = () => {
    if (dev_mode_enabled || !canEditProject) {
      return;
    }
    toggleDevMode();
  };

  const onSaveProjectName = async (updatedName: string) => {
    projectContext.updateProjectName(updatedName);

    const { url, body } = API.project.put.updateProject;
    await http.put(url(projectId), body({ name: updatedName }));
  };

  const { showMultiSelectPopover, handleMultiSelectPopover } = useMultiSelectPopover();

  const openFigma = () => {
    let figmaURL = `https://www.figma.com/file/${figma_file_ID}`;
    if (figma_branch_id) {
      figmaURL = `https://www.figma.com/file/${figma_file_ID}/branch/${figma_branch_id}`;
    }
    window.open(figmaURL, "_blank");
  };

  const onDuplicateProject = async () => {
    setNotification({
      type: "success",
      message: "Duplicating project...",
    });
    const [request] = duplicateProject({ projectId });
    const {
      data: { _id: newProjectId },
    } = await request;
    setTimeout(() => {
      setNotification(null);
      window.location.href = `/projects/${newProjectId}`;
    }, 2000);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  const hideDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const openExportModal = () => setExportModalOpen(true);
  const hideExportModal = () => setExportModalOpen(false);

  const toggleShareOpen = () => {
    if (!shareOpen) {
      segment.track({ event: SegmentEvents.SHARE_BUTTON_IN_PROJECT_CLICKED });
    }
    setShareOpen(!shareOpen);
  };

  const openRichTextModal = () => setRichTextModalOpen(true);

  const toggleDevMode = () => {
    setDevModalOpen(!devModalOpen);
    if (!dev_mode_enabled) {
      setDevModalOpen(!devModalOpen);
    } else {
      segment.track({
        event: "Toggle View API IDs",
        properties: {
          enabled: !displayApiIds,
        },
      });
      handleDisplayApiIds(!displayApiIds);
    }
  };

  const toggleDisplayApiIds = () => {
    handleDisplayApiIds(!displayApiIds);
  };

  const goToProject = (id) => window.open(routes.nonNavRoutes.project.getPath(id), "_self");

  const handleAddInvite = async ({ emails, role, type, docID, docName, message }) => {
    try {
      setIsInviteSending(true);
      const { url, body } = API.invite.post.create;
      await http.post(
        url,
        body({
          emails,
          permissionGroups: [role],
          role,
          type,
          docID,
          docName,
          message,
        })
      );
      toggleShareOpen();
      setNotification({
        type: "success",
        message: emails.length === 1 ? "Invite sent! 🎉" : "Invites sent! 🎉",
      });
    } catch (error) {
      console.error("Error Inviting Users", error);
      setNotification({
        type: "success",
        message: "There was an error inviting users.",
      });
    }
    setIsInviteSending(false);
  };

  const onSaveSlackNotifType = async (flagName: keyof SlackNotificationTypes, value: boolean) => {
    const response = await http.put(`/doc/slackNotifs/${doc_ID}`, {
      notif_types: { [flagName]: value },
    } as UpdateSlackNotifsRequestBody);

    if (response.status !== 200) throw new Error(response.statusText);

    setDoc((doc) => ({
      ...doc,
      integrations: {
        ...doc.integrations,
        slack: {
          ...doc.integrations.slack,
          notif_types: {
            ...doc.integrations.slack.notif_types,
            [flagName]: value,
          },
        },
      },
    }));
  };

  const handleRegenerateApiIds = async () => {
    setRegenerateLoading(true);

    try {
      await http.put(`/doc/${doc_ID}/regenerateApiIds`);

      setRegenerateLoading(false);

      window.location.reload();
    } catch (error) {
      console.error("Error Regenerating Developer IDs", error);
      setRegenerateLoading(false);
      setRegenerateError("There was an error regenerating the project's text item IDs.");

      setTimeout(() => {
        setRegenerateError(null);
      }, 5000);
    }
  };

  // show notification toasts for 5s
  useEffect(() => {
    if (notification)
      setTimeout(() => {
        setNotification(null);
      }, 5000);
  }, [notification]);

  const showBranchBadgeDropdown =
    Boolean(projectBranchInfo?.branchProjectInfo) && Boolean(projectBranchInfo?.mainProjectInfo);
  const showBranchLabel = Boolean(projectBranchInfo?.branchProjectInfo) && !Boolean(projectBranchInfo?.mainProjectInfo);

  const isPermissionGroupsEnabled = workspaceInfo?.plan === "enterprise";

  return (
    <Navbar className={style.navbar} onClick={() => setSuggestedCompId(null)}>
      {notification && <NotificationToast notification={notification} noPadding={false} />}
      <div className={style.title}>
        {showBranchLabel && (
          <div className={style.branchLabel}>
            <BranchingIcon className={style.branchLabelIcon} />
          </div>
        )}
        {!showBranchBadgeDropdown && !resyncLoading && (
          <EditableName
            data-testid="project-title"
            isEditEnabled={canEditProject}
            hoverWrapperClassName={style.hoverWrapper}
            value={title}
            onSave={onSaveProjectName}
            TextComponent={ProjectTitle}
            className={style.projectTitle}
          />
        )}
        {!showBranchBadgeDropdown && resyncLoading && (
          <div className={style.projectTitle}>
            <ProjectTitle>{title}</ProjectTitle>
          </div>
        )}
        {showBranchBadgeDropdown && (
          <ProjectBranchLabel
            title={title}
            isLocked={isLocked}
            figma_branch_id={figma_branch_id}
            goToProject={goToProject}
            projectBranchInfo={projectBranchInfo}
          />
        )}
        {isLocked && <ProjectLockedLabel isLocked={!Boolean(figma_branch_id)} isSublabel={false} />}
        {!canEditProject && (
          <CommentOnlyBadge tooltipText="You have comment-only access to this project. This means you can view text, change status, and leave comments. To get full edit rights, ask an admin." />
        )}
        {isSampleProject && <SampleBadge className={style.sampleBadge}>Sample Project</SampleBadge>}
        {dev_mode_enabled && displayApiIds && <ApiID id={doc_ID} className={style.projectApiId} />}
      </div>
      {!is_loading && (
        <div className={style.docMenu}>
          <ConcurrentUserListWrapper />

          {!projectHasLinkingEnabled && (
            <div className={style.linking}>
              <CableIcon className={style.cableIcon} /> Linking Not Enabled{" "}
              <HelpIcon className={style.helpIcon} onClick={onLinkingHelpClick} />
            </div>
          )}
          {!isMergedBranch && (
            <>
              {projectHasLinkingEnabled && (
                <>
                  <div className={style.linking}>
                    <CableIcon className={style.cableIcon} /> Linking
                  </div>
                  <div
                    className={classNames({
                      [style.designButton]: true,
                      [style.hasDesignConnection]: projectHasFigmaConnection,
                    })}
                    onClick={onDesignButtonClick}
                    data-tip
                    data-for="design"
                  >
                    <PaletteIcon />
                  </div>
                </>
              )}
              {projectHasLinkingEnabled && !projectHasFigmaConnection && (
                <ReactTooltip id="design" place="bottom" effect="solid">
                  Design File
                </ReactTooltip>
              )}
              {projectHasFigmaConnection && (
                <FigmaResyncButton
                  loading={resyncLoading}
                  onClick={() => resync()}
                  resyncedAt={project?.integrations?.figma?.resynced_at || project?.edited_at}
                />
              )}
              {projectHasLinkingEnabled && (canEditProject || dev_mode_enabled) && (
                <>
                  <div
                    className={classNames({
                      [style.devModeButton]: true,
                      [style.devModeEnabled]: dev_mode_enabled,
                    })}
                    onClick={onDevModeClick}
                    data-tip
                    data-testid="dev-mode-button"
                    data-for="code"
                  >
                    <CodeIcon />
                  </div>
                  {dev_mode_enabled && (
                    <ViewApiIdsToggle
                      canEditProject={canEditProject}
                      displayApiIds={displayApiIds}
                      toggleDisplayApiIds={toggleDisplayApiIds}
                      showRegenerateModal={() => setRegenModalOpen(true)}
                    />
                  )}
                  <ReactTooltip id="code" place="bottom" effect="solid">
                    Developer Mode
                  </ReactTooltip>
                  <ReactTooltip id="rich_text" place="bottom" effect="solid">
                    Rich Text
                  </ReactTooltip>
                </>
              )}
            </>
          )}
          {linkableOrLinkedGroupsVisible && !isMergedBranch && <div className={style.divider}>&nbsp;</div>}
          {projectHasLinkingEnabled && canEditProject && (
            <>
              <div className={style.exportProjectButton} data-tip data-for="export">
                <SaveAltIcon data-testid="export-icon" onClick={openExportModal} />
              </div>
              <ReactTooltip id="export" place="bottom" effect="solid">
                Export Project
              </ReactTooltip>
            </>
          )}
          {linkableOrLinkedGroupsVisible && (
            <label className={style.multiSelectToggle}>
              <MultiSelectPopoverTrigger show={showMultiSelectPopover}>
                <Toggle
                  checked={multiOn}
                  icons={false}
                  onChange={() => {
                    if (!multiOn) {
                      handleMultiSelectPopover();
                    }

                    onMultiSelectButtonClick();
                  }}
                />
              </MultiSelectPopoverTrigger>
              <span>Multi-select</span>
            </label>
          )}
          <ButtonPrimary
            className={style.shareButton}
            text={
              <div data-testid={TestIDs.projectShareButton} className={style.shareBtn}>
                {!isPermissionGroupsEnabled && folder?.invite_only && (
                  <>
                    <LockIcon className={style.folderIcon} />{" "}
                  </>
                )}
                Share
              </div>
            }
            onClick={() => toggleShareOpen()}
          />
          <div className={style.more}>
            <Dropdown alignRight>
              <Dropdown.Toggle>
                <MoreVertIcon
                  data-testid="project-header-more-button"
                  className={classNames([style.icon], [style.more])}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {projectHasFigmaConnection && <Dropdown.Item onClick={openFigma}>Open in Figma</Dropdown.Item>}

                {docSlackInfo && ( // doc slack info is null when the project is loading
                  <Dropdown.Item onClick={() => setShowSlackModal(true)}>
                    {docSlackInfo.channel_name ? (
                      <div className={style.slackChannelOption}>
                        Sending updates to <div className={style.slackChannelName}>#{docSlackInfo.channel_name}</div>
                      </div>
                    ) : (
                      <div className={style.slackChannelOption}>Send updates to a Slack channel</div>
                    )}
                  </Dropdown.Item>
                )}
                {showSyncSettingsModalButton && (
                  <Dropdown.Item
                    data-testid="sync-settings-and-automation"
                    onClick={() => setSyncSettingsModalVisible(true)}
                  >
                    <div className={style.slackChannelOption}>
                      <DittoComponent componentId="project-page.sync-settings.title" />
                    </div>
                  </Dropdown.Item>
                )}

                {!isMergedBranch && (
                  <Dropdown.Item disabled={!canEditProject || resyncLoading} onClick={openRichTextModal}>
                    <div className={style.richTextButton}>
                      {richTextEnabled ? "Disable" : "Enable"} rich text
                      {!richTextEnabled && <BetaBadge text="Beta" />}
                    </div>
                  </Dropdown.Item>
                )}
                {!projectHasFigmaConnection &&
                  DUPLICATE_DRAFT_PROJECT_ENABLED_WORKSPACES.includes(workspaceInfo?._id) && (
                    <Dropdown.Item onClick={onDuplicateProject}>Duplicate project</Dropdown.Item>
                  )}
                <Tooltip
                  className={style.tooltip}
                  content={<div className={style.body}>The sample project can't be deleted.</div>}
                  placement="top"
                  theme="dark"
                  disabled={!project?.isSample}
                >
                  <div>
                    <Dropdown.Item disabled={!canDeleteProject || project?.isSample} onClick={openDeleteModal}>
                      <span
                        className={classNames({
                          [style.deleteProject]: true,
                          [style.disabled]: !canDeleteProject || project?.isSample,
                        })}
                        data-testid="delete-project-button"
                      >
                        Delete project
                      </span>
                    </Dropdown.Item>
                  </div>
                </Tooltip>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
      {deleteModalOpen && (
        <DeleteProjModal
          doc_ID={doc_ID}
          doc_name={title}
          onHide={hideDeleteModal}
          isMissingBranch={false}
          projectBranchInfo={projectBranchInfo}
        />
      )}
      {exportModalOpen && (
        <ExportModal
          doc_ID={doc_ID}
          doc_name={title}
          onHide={hideExportModal}
          developerModeEnabled={dev_mode_enabled}
          componentFolder={null}
        />
      )}
      {devModalOpen && (
        <DevModeModal
          isProject={true}
          onHide={() => setDevModalOpen(false)}
          doc_ID={doc_ID}
          dev_mode_enabled={dev_mode_enabled}
          handleDevModeEnabled={handleDevModeEnabled}
          turnOnTooltip={() => null}
        />
      )}
      {richTextModalOpen && (
        <RichTextModal
          richTextEnabled={richTextEnabled}
          projectId={doc_ID}
          onHide={() => setRichTextModalOpen(false)}
          handleRichTextEnabled={handleRichTextEnabled}
        />
      )}
      {isPermissionGroupsEnabled && shareOpen && (
        <InviteCollaboratorsModal
          inviteContext={{
            type: "project",
            project: {
              id: doc_ID,
              name: title,
            },
            folder,
          }}
          currentUser={user}
          onHide={toggleShareOpen}
          setNotification={setNotification}
        />
      )}
      {!isPermissionGroupsEnabled && shareOpen && (
        <ShareProjectModal
          projectId={doc_ID}
          projectName={title}
          folder={folder}
          currentUser={user}
          onHide={toggleShareOpen}
          setNotification={setNotification}
        />
      )}
      {showLinkingHelpModal && (
        <LinkingHelpModal linkingEnabled={projectHasLinkingEnabled} onHide={() => setShowLinkingHelpModal(false)} />
      )}
      {showSlackModal && (
        <SlackChannelModal
          doc_id={project?._id}
          connectedChannelInfo={docSlackInfo}
          isProject={true}
          onSaveNotifType={onSaveSlackNotifType}
          workspaceSlackAuthenticated={workspaceInfo?.integrations.slack.authenticated}
          onHide={() => setShowSlackModal(false)}
          isSampleProject={project?.isSample}
        />
      )}
      {syncSettingsModalVisible && (
        <SyncSettingsModal
          {...syncSettingsModalProps}
          onHide={() => setSyncSettingsModalVisible(false)}
          toggleFrameModal={() => {
            setSyncSettingsModalVisible(false);
            toggleFrameModal();
          }}
        />
      )}
      {regenModalOpen && (
        <ConfirmationModal
          onHide={() => setRegenModalOpen(false)}
          title="Regenerate Text Item IDs"
          body={
            <>
              <p>
                This operation will regenerate the ID for every text item in this project according to the rules in your{" "}
                <a href="/developers/configure-dev-ids">Developer Settings.</a> This might take a little while for
                larger projects; the page will refresh once it's done.
              </p>
              <p>
                Are you sure you want to regenerate all text item IDs for the project <strong>{title}</strong>? This
                operation cannot be undone.
              </p>
            </>
          }
          actionPrimary="Regenerate"
          actionSecondary="Cancel"
          onPrimary={handleRegenerateApiIds}
          onSecondary={() => setRegenModalOpen(false)}
          primaryLoading={regenerateLoading}
          error={regenerateError}
        />
      )}
    </Navbar>
  );
};

interface ViewApiIdsToggleProps {
  canEditProject: boolean;
  displayApiIds: boolean;
  toggleDisplayApiIds: () => void;
  showRegenerateModal: () => void;
}

const ViewApiIdsToggle = (props: ViewApiIdsToggleProps) => {
  const { canEditProject, displayApiIds, toggleDisplayApiIds, showRegenerateModal } = props;

  return (
    <div
      className={classNames({
        [style.apiIdToggleButton]: true,
        [style.apiIdsVisible]: displayApiIds,
      })}
    >
      <div className={style.visibilityToggle} onClick={toggleDisplayApiIds} data-tip data-for="visibility">
        {displayApiIds ? <EyeIcon className={style.eyeIcon} /> : <EyeSlashedIcon className={style.eyeIcon} />}
        <span>{displayApiIds ? "Viewing" : "Hiding"} Developer IDs</span>
      </div>
      <ReactTooltip id="visibility" place="bottom" effect="solid">
        {displayApiIds ? "Hide" : "Show"} Developer IDs
      </ReactTooltip>

      {canEditProject && (
        <Dropdown className={style.dropdown} alignRight>
          <Dropdown.Toggle>
            <KeyboardArrowDown className={style.downIcon} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={showRegenerateModal}>Regenerate IDs in this project</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};
export default ProjectHeader;
