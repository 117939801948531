import { ToastNotification } from "@/components/notification-toast";
import UpdatePayment from "@/components/updatepayment/updatepayment";
import http, { API } from "@/http";
import { BillingContext } from "@/store/billingContext";
import { useWorkspace } from "@/store/workspaceContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useContext, useEffect, useState } from "react";
import Stripe from "stripe";
import sharedStyles from "../style.module.css";
import Toast from "../toast";
import InvoicingPanel from "./Invoicing";
import PaymentPanel from "./Payment";
import SeatsPanel from "./Seats";
import useSeatsPanel from "./Seats/useSeatsPanel";

interface Props {}

export default function BillingTab(props: Props) {
  const { showBillingModal } = useContext(BillingContext);
  const { workspaceInfo } = useWorkspace();
  const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod>();
  const [invoices, setInvoices] = useState<Record<string, Stripe.Invoice>>({});
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!);
  const [notification, setNotification] = useState<ToastNotification | null>(null);
  const [invoicesLoading, setInvoicesLoading] = useState(true);

  const { loading, roles, error } = useSeatsPanel();

  useEffect(function initialize() {
    if (error) {
      setNotification({
        type: "error",
        time: 5000,
        message: "There was an error fetching billing information.",
      });
    }
    fetchPaymentMethod();
    Promise.all([fetchUpcomingInvoice(), fetchInvoiceHistory()]).then(() => setInvoicesLoading(false));
  }, []);

  const fetchPaymentMethod = async () => {
    const { url } = API.api.get.listPaymentMethods;

    return http
      .get(url)
      .then(({ data: payment_info }) => {
        if (payment_info.data && payment_info.data.length > 0) {
          setPaymentMethod(payment_info.data[0]);
        }
      })
      .catch((err) => {
        console.error(err.message, "error fetching payment methods");
      });
  };

  const fetchUpcomingInvoice = async () => {
    const { url } = API.api.get.upcomingInvoice;
    return http
      .get(url)
      .then(({ data: upcoming }) => {
        setInvoices((prev) => (prev = { ...prev, [upcoming.period_end]: upcoming }));
      })
      .catch((err) => {
        console.error(err.message, "error fetching upcoming invoice");
      });
  };

  const fetchInvoiceHistory = async () => {
    const { url } = API.api.get.listInvoices;

    return http
      .get(url)
      .then(({ data: past }) => {
        const newInvoices = past.data.reduce((acc, curr) => {
          acc[curr.period_end] = curr;
          return acc;
        }, {});
        setInvoices((prev) => ({ ...prev, ...newInvoices }));
      })
      .catch((err) => {
        console.error(err.message, "error fetching past invoices");
      });
  };

  return (
    <div>
      {notification && <Toast notification={notification} />}
      {workspaceInfo && workspaceInfo.plan && !loading && !invoicesLoading && (
        <div>
          <div className={sharedStyles.container}>
            <PaymentPanel
              billingEmail={paymentMethod?.billing_details.email || ""}
              lastFour={paymentMethod?.card?.last4 || ""}
              onClick={() => setShowUpdatePaymentModal(!showUpdatePaymentModal)}
              paymentMethod={paymentMethod?.card?.brand || ""}
              planName={workspaceInfo.plan}
            />
          </div>
          {showUpdatePaymentModal && (
            <Elements stripe={stripePromise}>
              <UpdatePayment
                onHide={() => setShowUpdatePaymentModal(false)}
                billing_email={paymentMethod?.billing_details?.email || ""}
                old_payment_method={paymentMethod?.id || ""}
              />
            </Elements>
          )}
          <div className={sharedStyles.container}>
            <InvoicingPanel invoices={Object.values(invoices).sort((a, b) => b.period_end - a.period_end)} />
          </div>
          {!loading && (
            <div className={sharedStyles.container}>
              <SeatsPanel roles={roles} onClick={() => showBillingModal()} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
