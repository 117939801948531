import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import ButtonPrimary from "../../../button/buttonprimary";
import { ModalBody, ModalFooter } from "../../../shared/ModalBase";
import VariantSelectInput from "../../../shared/VariantSelectInput";
import { useImportVariantRequest } from "./importVariantRequest";
import style from "./style.module.css";

export default function VariantSelect({ state, dispatch }) {
  const [{ loading, variant }, setState] = useState({
    loading: false,
    variant: null,
    error: null,
  });

  const nextDisabled = !(variant && !loading);

  const onSelect = useCallback((variant) => setState((s) => ({ ...s, variant })), []);

  const importVariantRequest = useImportVariantRequest({
    backgroundJobEntryId: state.backgroundJobEntryId,
    variantId: variant ? variant.id : null,
  });

  const onNext = async () => {
    if (nextDisabled) {
      return;
    }

    setState((s) => ({ ...s, loading: true }));

    const { success, data, error } = await importVariantRequest();
    if (!success) {
      setState((s) => ({ ...s, loading: false, error }));
      return;
    }

    const { components, totalComponentsCount } = data;

    dispatch({
      type: "IMPORT_VARIANT_RESPONSE",
      step: "preview",
      data: {
        variant,
        // don't overwrite the components in state if all of the components were ignored
        components: components.length ? components : state.components,
        totalComponentsCount,
      },
    });
  };

  const onBackClick = () => {
    dispatch({
      type: "SET_STEP",
      step: "file-upload",
    });
  };

  return (
    <>
      <ModalBody>
        <p>
          It looks like this file has matching keys to components in your library! This means the strings in this file
          will be imported as a variant for the components.
        </p>
        <p>Create or choose a variant: </p>
        <VariantSelectInput onSelect={onSelect} className={style.selectWrapper} testId="create-or-choose-variant" />
      </ModalBody>
      <ModalFooter className={style.footer}>
        {" "}
        <a href="#" className={style.footerLink} onClick={onBackClick}>
          <ArrowBackIcon className={classNames([style.icon, style.footerLinkIcon])} />
          <span>Back</span>
        </a>
        <ButtonPrimary text="Next -->" onClick={onNext} disabled={nextDisabled} data-testid="next-modal-screen" />
      </ModalFooter>
    </>
  );
}
