import { GenericError } from "@auth0/auth0-spa-js";

export const handleGetTokenError = <E extends GenericError>(
  error: E,
  onLoginRequired: () => void,
  onOtherError?: () => void
) => {
  if (error.error === "login_required") {
    onLoginRequired();

    const shouldRedirect = !(
      window.location.pathname.includes("/login") || window.location.pathname.includes("/signin")
    );

    if (shouldRedirect) {
      window.location.replace(`${process.env.BASE_URL}/login`);
    }

    return;
  }

  if (onOtherError) {
    return onOtherError();
  }

  throw error;
};
