import ButtonSecondary from "@/components/button/buttonsecondary";
import * as HttpConnections from "@/http/connections";
import { useWorkspace } from "@/store/workspaceContext";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import classNames from "classnames";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import spinner from "../../../../assets/small-spinner.gif";
import ConfirmationModal from "../../../../components/shared/confirmation-modal";
import { useSplitContext } from "../../store/SplitConnectionContext";
import { ABIntegrationModal } from "../ABIntegrationModal";
import styles from "./styles.module.css";

/**
 * Updates to this file should probably be mirrored in the LaunchDarkly.tsx file, as
 * they're mostly the same.
 */

interface ISplitConnectionState {
  token: string;
  tokenEditable: boolean;
  environmentName: string;
  workspaceId: string;
  error: { code: string; message: string } | null;
  saveLoading: boolean;
  configEditable: boolean;
  fetchLoading: boolean;
  numConnectedVariants: number;
  numSyncedFlags: number;
  showRemoveConfirmationModal: boolean;
}

const useSplitConnectionState = () => {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const context = useSplitContext();

  const originalState = useMemo(
    () => ({
      token: workspaceInfo.integrations.split?.tokenPreview || "",
      environmentName: workspaceInfo.integrations.split?.environmentName || "",
      workspaceId: workspaceInfo.integrations.split?.workspaceId || "",
    }),
    [workspaceInfo.integrations.split]
  );

  const [data, setData] = useState<ISplitConnectionState>({
    ...originalState,
    error: null,
    saveLoading: false,
    tokenEditable: !originalState.token,
    configEditable: false,
    numConnectedVariants: 0,
    numSyncedFlags: 0,
    fetchLoading: false,
    showRemoveConfirmationModal: false,
  });

  const hasUnsavedChanges =
    data.token !== originalState.token ||
    data.environmentName !== originalState.environmentName ||
    data.workspaceId !== originalState.workspaceId;

  const showChangeTokenButton = !!originalState.token && data.configEditable;

  const handleError = (code: string, message: string) => {
    context.dispatch({ type: "hideModal" });

    const messageCapitalized = message[0].toUpperCase() + message.slice(1);
    setData((prev) => ({
      ...prev,
      error: { code, message: messageCapitalized },
    }));
  };

  const saveCurrentConfiguration = async () => {
    setData((prev) => ({ ...prev, saveLoading: true }));

    const [request] = HttpConnections.updateSplitConfig({
      token: data.token,
      environmentName: data.environmentName || "Prod-Default",
      workspaceId: data.workspaceId,
    });
    try {
      const response = await request;

      updateWorkspaceInfo((info) => {
        info.integrations.split = {
          // token should never be used on the front-end, only stored encrypted in the database
          token: "",
          tokenPreview: response.data.tokenPreview,
          environmentName: data.environmentName || "Prod-Default",
          workspaceId: data.workspaceId,
        };
        return { ...info };
      });

      setData((prev) => ({ ...prev, saveLoading: false }));
    } catch (e: any) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      setData((prev) => ({
        ...prev,
        saveLoading: false,
        error: { code, message },
      }));
    }
  };

  const onSetupConnection = async () => {
    await saveCurrentConfiguration();
    context.dispatch({ type: "showModal", connected: false });
  };

  const onManageConnection = () => {
    setData((prev) => ({ ...prev, error: null }));
    context.dispatch({ type: "showModal", connected: true });
  };

  const closeManagementModal = () => context.dispatch({ type: "hideModal" });

  const onApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const token = e.target.value;
    setData((prev) => ({ ...prev, token, error: null }));
  };

  const onWorkspaceIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const workspaceId = e.target.value;
    setData((prev) => ({ ...prev, workspaceId, error: null }));
  };

  const onEnvironmentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const environmentName = e.target.value;
    setData((prev) => ({ ...prev, environmentName, error: null }));
  };

  const onRemoveConnection = async () => {
    const [request] = HttpConnections.removeSplitConfig();
    await request;

    updateWorkspaceInfo((info) => {
      info.integrations.split = undefined;
      return { ...info };
    });
  };

  const onSaveChanges = async () => {
    setData((prev) => ({ ...prev, saveLoading: true }));

    // only update the fields that have changed
    const splitDataToUpdate = {
      token: data.token !== originalState.token ? data.token : undefined,
      environmentName:
        data.environmentName !== originalState.environmentName ? data.environmentName || "Prod-Default" : undefined,
      workspaceId: data.workspaceId !== originalState.workspaceId ? data.workspaceId : undefined,
    };

    try {
      const [request] = HttpConnections.updateSplitConfig(splitDataToUpdate);
      const response = await request;

      updateWorkspaceInfo((info) => {
        info.integrations.split = {
          token: "",
          tokenPreview: response.data.tokenPreview,
          environmentName: data.environmentName,
          workspaceId: data.workspaceId,
        };
        return { ...info };
      });

      setData((prev) => ({
        ...prev,
        saveLoading: false,
        token: response.data.tokenPreview,
        tokenEditable: false,
      }));
    } catch (e) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      handleError(code, message);
      setData((prev) => ({ ...prev, saveLoading: false }));
    }
  };

  const onEditConfig = () => {
    setData((prev) => ({ ...prev, configEditable: true }));
  };

  const onCancelEditConfig = () => {
    setData((prev) => ({
      ...prev,
      configEditable: false,
      token: originalState.token,
      environmentName: originalState.environmentName,
      workspaceId: originalState.workspaceId,
      tokenEditable: false,
    }));
  };

  const tokenInputRef = React.useRef<HTMLInputElement>(null);

  const onCancelChangeTokenButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    setData((prev) => ({
      ...prev,
      tokenEditable: false,
      token: originalState.token,
      error: null,
    }));
  };

  const onChangeTokenButtonClick = (e: MouseEvent) => {
    e.preventDefault();
    setData((prev) => ({ ...prev, tokenEditable: true, token: "" }));
  };

  useEffect(
    // unfortunately have to use an effect here to ensure we only attempt
    // to focus the input after the component has re-rendered with tokenEditable
    // `true`, otherwise the focus call will occur while the input itself is
    // still disabled
    function focusTokenInput() {
      if (!data.tokenEditable) return;
      if (!tokenInputRef.current) return;
      tokenInputRef.current!.focus();
    },
    [data.tokenEditable]
  );

  return [
    data,
    {
      onSetupConnection,
      onManageConnection,
      onApiKeyChange,
      onWorkspaceIdChange,
      onEnvironmentNameChange,
      onRemoveConnection,
      closeManagementModal,
      handleError,
      onSaveChanges,
      onCancelChangeTokenButtonClick,
      onChangeTokenButtonClick,
      onEditConfig,
      onCancelEditConfig,
      showRemoveConfirmationModal: () =>
        setData((prev) => ({
          ...prev,
          showRemoveConfirmationModal: true,
        })),
      hideRemoveConfirmationModal: () => setData((prev) => ({ ...prev, showRemoveConfirmationModal: false })),
    },
    {
      hasUnsavedChanges,
      tokenInputRef,
      showChangeTokenButton,
      numConnectedVariants: 1,
      numSyncedFlags: 1,
    },
  ] as const;
};

interface ISplitConfigFormProps {
  isConnected: boolean;
  state: ReturnType<typeof useSplitConnectionState>;
}

const SplitConfigForm = (props: ISplitConfigFormProps) => {
  const [data, actions, computed] = props.state;
  const configEditable = data.configEditable || !props.isConnected;

  return (
    <div className={styles.section}>
      <label className={styles.inputLabel}>
        <div className={styles.inputLabelRow}>
          <span className={styles.text}>Split API Key</span>
          {computed.showChangeTokenButton && (
            <div className={styles.changeTokenContainer}>
              {data.tokenEditable ? (
                <ButtonSecondary
                  text="Cancel"
                  onClick={actions.onCancelChangeTokenButtonClick}
                  className={styles.cancelChangeTokenButton}
                />
              ) : (
                <ButtonSecondary
                  text={
                    <>
                      <AutorenewIcon className={styles.icon} /> Change Key
                    </>
                  }
                  onClick={actions.onChangeTokenButtonClick}
                  className={styles.changeTokenButton}
                />
              )}
            </div>
          )}
        </div>

        <input
          className={styles.input}
          type="text"
          value={data.token}
          onChange={actions.onApiKeyChange}
          placeholder="Insert API key"
          disabled={!data.tokenEditable}
          ref={computed.tokenInputRef}
        />
      </label>
      <label className={styles.inputLabel}>
        <span className={styles.inputLabelText}>Split Workspace ID</span>
        <input
          className={styles.input}
          type="text"
          value={data.workspaceId}
          onChange={actions.onWorkspaceIdChange}
          placeholder="Insert Workspace ID"
          disabled={!configEditable}
        />
      </label>
      <label className={styles.inputLabel}>
        <span className={styles.inputLabelText}>Split Environment Name</span>
        <input
          className={styles.input}
          type="text"
          value={data.environmentName}
          onChange={actions.onEnvironmentNameChange}
          placeholder="Prod-Default"
          disabled={!configEditable}
        />
      </label>
      {data.error?.code === "platform-error" && (
        <div className={styles.error}>
          We received an error message from the Split API:{" "}
          <div className={styles.errorFromPlatform}>{data.error.message}</div>
        </div>
      )}
      <div className={styles.spacer} />
    </div>
  );
};

const useSplitManagementModalState = (
  handleError: (code: string, message: string) => void,
  setNumConnectedVariants: (num: number) => void,
  setNumSyncedFlags: (num: number) => void
) => {
  const [data, setData] = useState<
    | {
        loading: true;
      }
    | {
        loading: false;
        response: HttpConnections.ILoadSplitDataResponse;
      }
  >({ loading: true });

  React.useEffect(function loadSplitData() {
    const [request] = HttpConnections.loadSplitData();
    request
      .then((response) => {
        setData({ loading: false, response: response.data });
      })
      .catch((e) => {
        const code = e.response?.data?.code || "unknown";
        const message = e.response?.data?.data || "An unknown error occurred";
        handleError(code, message);
      });
  }, []);

  const onUpdateConnection = async (
    updatedConnectionData: {
      flagId: string;
      flagName: string;
      syncedVariants: string[];
    }[]
  ) => {
    try {
      const [request] = HttpConnections.splitSync({
        data: updatedConnectionData,
      });
      await request;

      const syncedVariantsSet = new Set<string>();
      let numSyncedFlags = 0;
      updatedConnectionData.forEach((flag) => {
        const flagIsSynced = flag.syncedVariants.length > 0;
        if (!flagIsSynced) return;

        numSyncedFlags++;
        flag.syncedVariants.forEach((variant) => {
          syncedVariantsSet.add(variant);
        });
      });

      setNumSyncedFlags(numSyncedFlags);
      setNumConnectedVariants(syncedVariantsSet.size);
    } catch (e) {
      const code = e.response?.data?.code || "unknown";
      const message = e.response?.data?.data || "An unknown error occurred";
      handleError(code, message);
    }
  };

  return [data, { onUpdateConnection }] as const;
};

interface ISplitManagementModalProps {
  onClose: () => void;
  onBack: () => void;
  onRemoveConnection: () => void;
  handleError: (error: string, message: string) => void;
  isExistingConnection: boolean;
  setNumConnectedVariants: (num: number) => void;
  setNumSyncedFlags: (num: number) => void;
}

const SplitManagementModal = (props: ISplitManagementModalProps) => {
  const [data, actions] = useSplitManagementModalState(
    props.handleError,
    props.setNumConnectedVariants,
    props.setNumSyncedFlags
  );
  if (data.loading) {
    return <React.Fragment />;
  }

  return (
    <ABIntegrationModal
      platformName="Split"
      foreignVariantName="variation"
      data={data.response.data}
      variants={data.response.variants}
      onBack={props.onBack}
      onClose={props.onClose}
      onUpdateConnection={async (data) => {
        await actions.onUpdateConnection(data);
        props.onClose();
      }}
      onRemoveConnection={props.onRemoveConnection}
      isExistingConnection={props.isExistingConnection}
    />
  );
};

export function NotConnected() {
  const state = useSplitConnectionState();
  const [data, actions] = state;

  const saveConnectionButtonDisabled = !(data.token && data.workspaceId) || data.saveLoading;

  return (
    <>
      <div className={styles.contentSubheader}>Instructions</div>
      <div>
        <ol>
          <li className={styles.contentText}>
            Create a Split API key from <b>Admin Settings</b> {"->"} <b>API keys</b> in Split and insert it below.{" "}
          </li>
          <li className={styles.contentText}>
            Copy your Split workspace ID from <b>Admin Settings</b> {"->"} <b>Workspaces</b> in Split and insert it
            below.
          </li>
          <li className={styles.contentText}>
            Copy your Split environment name from <b>Admin Settings</b> {"->"} <b>All environments</b> in Split and
            insert it below. If no environment name is provided, Ditto will default to <b>Prod-Default</b>.
          </li>
          <li className={styles.contentText}>
            Click <b>Set Up Connection</b> to configure how Ditto variants should sync with Split treatments.
          </li>
        </ol>
        <a className={styles.link} href="https://www.dittowords.com/docs/a-b-testing-integrations" target="_blank">
          Detailed instructions
        </a>
        <SplitConfigForm state={state} isConnected={false} />
        <button
          className={classNames({
            [styles.blueFilledButton]: true,
            [styles.finalCta]: true,
            [styles.disabled]: saveConnectionButtonDisabled,
          })}
          disabled={saveConnectionButtonDisabled}
          onClick={actions.onSetupConnection}
        >
          {data.saveLoading ? "Setting Up..." : "Set Up Connection"}
        </button>
      </div>
    </>
  );
}

export function Connected() {
  const state = useSplitConnectionState();
  const context = useSplitContext();
  const [data, actions, computed] = state;

  const [numConnectedVariants, setNumConnectedVariants] = useState(0);
  const [numSyncedFlags, setNumSyncedFlags] = useState(0);
  const [fetchLoading, setFetchLoading] = useState(false);

  useEffect(function loadSplitData() {
    setFetchLoading(true);
    const [request] = HttpConnections.loadSplitData();
    request
      .then((response) => {
        const data = response.data;

        const syncedVariantsSet = new Set<string>();
        let numSyncedFlags = 0;
        data.data.forEach((flag) => {
          const flagIsSynced = flag.syncedVariants.length > 0;
          if (!flagIsSynced) return;

          numSyncedFlags++;
          flag.syncedVariants.forEach((variant) => {
            syncedVariantsSet.add(variant);
          });
        });

        setNumSyncedFlags(numSyncedFlags);
        setNumConnectedVariants(syncedVariantsSet.size);
        setFetchLoading(false);
      })
      .catch((e) => {
        const code = e.response?.data?.code || "unknown";
        const message = e.response?.data?.data || "An unknown error occurred";
        actions.handleError(code, message);
      });
  }, []);

  if (fetchLoading) {
    return (
      <div className={styles.loadingContainer}>
        <img src={spinner} alt="Loading..." />
      </div>
    );
  }

  return (
    <>
      <div className={styles.section}>
        <h3>Connected Variants</h3>
        <p>
          A total of{" "}
          <strong>
            {numConnectedVariants} variant
            {numConnectedVariants === 1 ? "" : "s"}
          </strong>{" "}
          {numConnectedVariants === 1 ? "is " : "are "}
          currently synced to{" "}
          <strong>
            {numSyncedFlags} flag{numSyncedFlags === 1 ? "" : "s"}
          </strong>{" "}
          in Split.
        </p>
        <button
          className={classNames({
            [styles.blueFilledButton]: true,
          })}
          onClick={actions.onManageConnection}
        >
          Manage
        </button>
      </div>
      <div className={styles.section}>
        <SplitConfigForm state={state} isConnected />

        {data.configEditable ? (
          <>
            <button
              className={classNames({
                [styles.blueFilledButton]: true,
                [styles.disabled]: !computed.hasUnsavedChanges || data.saveLoading,
              })}
              disabled={!computed.hasUnsavedChanges || data.saveLoading}
              onClick={actions.onSaveChanges}
            >
              {data.saveLoading ? "Saving..." : "Save Changes"}
            </button>
            <ButtonSecondary text="Cancel" onClick={actions.onCancelEditConfig} className={styles.cancelEditConfig} />
          </>
        ) : (
          <button className={styles.blueFilledButton} onClick={actions.onEditConfig}>
            Edit Properties
          </button>
        )}
      </div>
      <div className={styles.section}>
        <button className={styles.removeConnectionButton} onClick={actions.onRemoveConnection}>
          Remove Connection
        </button>
      </div>
      {context.showManagementModal && (
        <>
          <div className={styles.loadingContainer}>
            <img src={spinner} alt="Loading..." />
          </div>
          <SplitManagementModal
            handleError={actions.handleError}
            onClose={actions.closeManagementModal}
            onBack={actions.closeManagementModal}
            isExistingConnection={context.connected}
            onRemoveConnection={async () => {
              await actions.onRemoveConnection();
              context.dispatch({ type: "hideModal" });
            }}
            setNumConnectedVariants={setNumConnectedVariants}
            setNumSyncedFlags={setNumSyncedFlags}
          />
        </>
      )}
      {data.showRemoveConfirmationModal && (
        <ConfirmationModal
          title={"Remove Connection"}
          body={"Are you sure you want to remove this connection?"}
          actionPrimary={"Remove"}
          onPrimary={actions.onRemoveConnection}
          actionSecondary={"Cancel"}
          onSecondary={actions.hideRemoveConfirmationModal}
          isLayered={true}
        />
      )}
    </>
  );
}
