import React from "react";
import ImportBranchConfirmation from "./components/ImportBranchConfirmation";
import Modal from "./components/Modal";
import NewDittoProject from "./components/NewDittoProject";
import NewDraftProject from "./components/NewDraftProject";
import SelectFigmaFrames from "./components/SelectFigmaFrames";
import SelectFigmaProject from "./components/SelectFigmaProject";
import WouldYouLikeTo from "./components/WouldYouLikeTo";
import { ImportStep, useImport } from "./useImport";

export interface Props {
  hasFigmaConnected: boolean;
  onHide: () => void;
  isFirstProject: boolean;
  folder: {
    _id: string;
    name: string;
    invite_only: boolean;
  } | null;
  initialStep?: ImportStep;
}
const DraftingImportModal = (props: Props) => {
  const { onHide, isFirstProject, folder } = props;
  const {
    filePages,
    originalFileData,
    selectedFrames,
    selectedFramesByPage,
    handleSelectFrame,
    importFile,
    isImportLoading,
    importStep,
    onCreateNSProject,
    onDraftNewProject,
    onImportExistingFile,
    importError,
    linkInput,
    isGetPagesLoading,
    getPages,
    handleLinkChange,
    goToAccountUser,
    goToSelectedDoc,
    goBackToStart,
    projectName,
    setProjectName,
    createDittoProject,
    createDraftProject,
    draftName,
    handleDraftNameChange,
    branchImportConfirmed,
    toggleBranchImportConfirmed,
    onConfirmBranchImport,
    mainProject,
    handleEnableAdvancedBranching,
    showSampleImportMessage,
    importProgress,
    handleToggleAllSelectedForPage,
  } = useImport(props);

  if (importStep === ImportStep.PROJECT_TYPE) {
    return (
      <Modal
        onHide={onHide}
        title={<WouldYouLikeTo.Title folder={folder} />}
        body={
          <WouldYouLikeTo.Body
            isFirstProject={isFirstProject}
            onCreateNSProject={onCreateNSProject}
            onDraftNewProject={onDraftNewProject}
            onImportExistingFile={onImportExistingFile}
          />
        }
        footer={<WouldYouLikeTo.Footer />}
        fullWidth={false}
      />
    );
  } else if (importStep === ImportStep.EXISTING_PROJECT) {
    return (
      <Modal
        onHide={onHide}
        title={<SelectFigmaProject.Title folder={folder} />}
        fullWidth={false}
        body={
          <SelectFigmaProject.Body
            onHide={onHide}
            importError={importError}
            linkInput={linkInput}
            handleLinkChange={handleLinkChange}
            goToAccountUser={goToAccountUser}
            handleEnableAdvancedBranching={handleEnableAdvancedBranching}
            getPages={getPages}
            showSampleImportMessage={showSampleImportMessage}
          />
        }
        footer={
          <SelectFigmaProject.Footer
            importError={importError}
            linkInput={linkInput}
            isGetPagesLoading={isGetPagesLoading}
            goToSelectedDoc={goToSelectedDoc}
            getPages={getPages}
            goBackToStart={goBackToStart}
            showSampleImportMessage={showSampleImportMessage}
          />
        }
      />
    );
  } else if (importStep === ImportStep.BRANCH_CONFIRM) {
    return (
      <Modal
        onHide={onHide}
        title={<ImportBranchConfirmation.Title />}
        fullWidth={false}
        body={
          <ImportBranchConfirmation.Body
            mainProject={mainProject}
            branchImportConfirmed={branchImportConfirmed}
            toggleBranchImportConfirmed={toggleBranchImportConfirmed}
          />
        }
        footer={
          <ImportBranchConfirmation.Footer
            isDisabled={!branchImportConfirmed}
            onCancel={onHide}
            onContinue={onConfirmBranchImport}
            isGetPagesLoading={isGetPagesLoading}
          />
        }
      />
    );
  } else if (importStep === ImportStep.SELECT_FRAMES) {
    return (
      <Modal
        onHide={onHide}
        title={<SelectFigmaFrames.Title />}
        fullWidth={true}
        body={
          <SelectFigmaFrames.Body
            originalFileData={originalFileData}
            filePages={filePages}
            selectedFramesByPage={selectedFramesByPage}
            handleSelectFrame={handleSelectFrame}
            handleToggleAllSelectedForPage={handleToggleAllSelectedForPage}
          />
        }
        footer={
          <SelectFigmaFrames.Footer
            selectedFrames={selectedFrames}
            selectedFramesByPage={selectedFramesByPage}
            importFile={importFile}
            isImportLoading={isImportLoading}
            progress={importProgress}
          />
        }
      />
    );
  } else if (importStep === ImportStep.DRAFT_PROJECT) {
    return (
      <Modal
        onHide={onHide}
        title={<NewDraftProject.Title folder={folder} />}
        fullWidth={false}
        body={
          <NewDraftProject.Body
            draftName={draftName}
            handleDraftNameChange={handleDraftNameChange}
            importError={importError}
            onHide={onHide}
            goToAccountUser={goToAccountUser}
            createDraftProject={createDraftProject}
          />
        }
        footer={
          <NewDraftProject.Footer
            draftName={draftName}
            importError={importError}
            isLoading={isImportLoading}
            goBackToStart={goBackToStart}
            createDraftProject={createDraftProject}
          />
        }
      />
    );
  } else {
    return (
      <Modal
        onHide={onHide}
        title={<NewDittoProject.Title folder={folder} />}
        fullWidth={false}
        body={
          <NewDittoProject.Body
            projectName={projectName}
            importError={importError}
            setProjectName={setProjectName}
            onHide={onHide}
            goToAccountUser={goToAccountUser}
            createDittoProject={createDittoProject}
          />
        }
        footer={
          <NewDittoProject.Footer
            projectName={projectName}
            importError={importError}
            isLoading={isImportLoading}
            goBackToStart={goBackToStart}
            createDittoProject={createDittoProject}
          />
        }
      />
    );
  }
};
export default DraftingImportModal;
