import { putUserFlag } from "@/http/user";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { FlagName, UserFlag } from "@shared/types/User";

export default function useUserFlags() {
  const { user, refreshUser } = useAuthenticatedAuth();
  /**
   * Some user flags are set per-document, like a nudge that can be dismissed in
   * each project; to set these, you must provide both the flagName and flagId.
   */
  const setUserFlag = async (flagName, value) => {
    try {
      // update the backend user object
      await putUserFlag({ [flagName]: value } as UserFlag<FlagName>);

      // refresh the frontend user object
      refreshUser();
    } catch (err) {
      console.error("Error inside app.jsx setting user flag: ", err);
    }
  };

  // return the specified flag object, whatever it looks like
  const getUserFlag = (flagName) => user?.flags?.[flagName];

  return {
    setUserFlag,
    getUserFlag,
  };
}
