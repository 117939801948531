import http from "@/http";
import { IFPermissionGroupWithUsers } from "@shared/types/User";
import { useEffect, useState } from "react";
import { ResourceInfo } from "./PermissionGroupsList/types";

export interface IPermissionGroupState {
  permissionGroups: IFPermissionGroupWithUsers[];
  setPermissionGroups: React.Dispatch<React.SetStateAction<IFPermissionGroupWithUsers[]>>;
  projectFolders: ResourceInfo[];
  componentFolders: ResourceInfo[];
  variantFolders: ResourceInfo[];
  variableFolders: ResourceInfo[];
  loading: boolean;
  fetchWorkspacePermissionGroups: () => Promise<void>;
}

export default function usePermissionGroups(): IPermissionGroupState {
  const [loading, setLoading] = useState(true);
  const [permissionGroups, setPermissionGroups] = useState<IFPermissionGroupWithUsers[]>([]);
  const [projectFolders, setProjectFolders] = useState<ResourceInfo[]>([]);
  const [componentFolders, setComponentFolders] = useState<ResourceInfo[]>([]);
  const [variantFolders, setVariantFolders] = useState<ResourceInfo[]>([]);
  const [variableFolders, setVariableFolders] = useState<ResourceInfo[]>([]);

  useEffect(function initialize() {
    fetchWorkspacePermissionGroups();
  }, []);

  async function fetchWorkspacePermissionGroups() {
    const res = await http.get(`/workspace/permission-groups`);
    const { permissionGroupsWithUsers, projectFolders, componentFolders, variantFolders, variableFolders } = res.data;

    setPermissionGroups(permissionGroupsWithUsers);
    setProjectFolders(projectFolders);
    setComponentFolders(componentFolders);
    setVariantFolders(variantFolders);
    setVariableFolders(variableFolders);
    setLoading(false);
  }
  return {
    permissionGroups,
    setPermissionGroups,
    projectFolders,
    componentFolders,
    variantFolders,
    variableFolders,
    loading,
    fetchWorkspacePermissionGroups,
  };
}
