import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import ResizableWidthContainer from "@/components/ResizableWidthContainer/ResizableWidthContainer";
import SkeletonLoader from "@/components/SkeletonLoader/SkeletonLoader";
import { useScroll } from "@/hooks/useScroll";
import style from "./style.module.css";

interface IProps {
  selectedVariantId: string | null;
  loadingVariants: boolean;
  variants: { _id: string; name: string }[];
  variantFolders: { _id: string; name: string }[];
  selectedFolder: string | null;
  onVariantClick: (variantId: string) => void;
  onFolderClick: (folderId: string | null) => void;
  showFolders: boolean;
  isSearching: boolean;
}

const VariantNav = (props: IProps) => {
  const { selectedVariantId, variants, variantFolders, selectedFolder, onFolderClick, showFolders, isSearching } =
    props;

  const [shouldShowResizeCursor, setShouldShowResizeCursor] = useState(false);
  const { scrollToId } = useScroll({ containerId: "variantContainer" });

  const onShouldShowCursorChange = (shouldShowCursor: boolean) => {
    setShouldShowResizeCursor(shouldShowCursor);
  };

  function onSelectFolder(folderId: string) {
    if (shouldShowResizeCursor) return;
    history.push(`/variants/folder/${folderId}`);
  }
  function onVariantClick(variantId: string) {
    if (shouldShowResizeCursor) return;
    scrollToId(variantId);
    props.onVariantClick(variantId);
  }

  const history = useHistory();
  return (
    <ResizableWidthContainer
      className={style.sidebar}
      persistKey="ditto-VariantNavigationSidebar"
      onShouldShowCursorChange={onShouldShowCursorChange}
    >
      <div className={style.titleSection}>
        <div className={style.topRow}>
          <div className={style.option}>Navigation</div>
          {selectedFolder && (
            <span className={style.backLink} onClick={() => onFolderClick(null)}>
              {"<-"} Back to All Variants
            </span>
          )}
        </div>
      </div>
      <div className={style.frameList}>
        {showFolders &&
          variantFolders.map((folder) => (
            <FolderNavLink
              key={folder.name}
              name={folder.name}
              selectFolder={() => onSelectFolder(folder._id)}
              className={shouldShowResizeCursor ? style.showResizeCursor : ""}
            />
          ))}
        {props.loadingVariants && <SkeletonLoader format="nav" />}
        {!props.loadingVariants &&
          variants &&
          variants.map(({ _id, name }) => (
            <div
              key={_id}
              className={classNames({
                [style.navItem]: true,
                [style.selected]: _id === selectedVariantId,
                [style.showResizeCursor]: shouldShowResizeCursor,
              })}
              onClick={() => onVariantClick(_id)}
            >
              <div className={style.name}>{name}</div>
            </div>
          ))}
        {variants && variants.length === 0 && isSearching && <div className={style.noResults}>No variants found.</div>}
      </div>
    </ResizableWidthContainer>
  );
};

interface IFolderNavLinkProps {
  name: string;
  selectFolder: () => void;
  className?: string;
}

const FolderNavLink = (props: IFolderNavLinkProps) => {
  const { name, selectFolder } = props;
  return (
    <div className={classNames(style.navItem, props.className)} onClick={() => selectFolder()}>
      <div className={style.folderNavLink}>
        <FolderOpenOutlined className={style.folderIcon} />
        <span className={style.folderName}>{name}</span>
      </div>
    </div>
  );
};

export default VariantNav;
