import classNames from "classnames";
import hljs from "highlight.js/lib/core";
import actionscript from "highlight.js/lib/languages/actionscript";
import json from "highlight.js/lib/languages/json";
import xml from "highlight.js/lib/languages/xml";
import React, { CSSProperties, useMemo } from "react";
hljs.registerLanguage("actionscript", actionscript);
hljs.registerLanguage("json", json);
hljs.registerLanguage("xml", xml);

interface HighlighterProps {
  content: string;
  language?: string;
  className?: string;
  style?: CSSProperties;
}

export default function Highlighter(props: HighlighterProps): JSX.Element {
  const { content, language, className, style } = props;

  const truncatedContent = useMemo(
    function truncatedContentMemo() {
      return content.length > 10000 ? content.slice(0, 10000) + "\n... (Preview truncated)" : content;
    },
    [content]
  );

  const highlighted = useMemo(
    function getHighlightedContent() {
      switch (language) {
        case "xml":
          return hljs.highlight(truncatedContent, { language: "xml" });
        case "json":
          return hljs.highlight(truncatedContent, { language: "json" });
        case "actionscript":
          return hljs.highlight(truncatedContent, { language: "actionscript" });
        default:
          console.warn("Unknown language tried to highlight: " + language);
          return { value: truncatedContent };
      }
    },
    [truncatedContent, language]
  );

  return (
    <pre className={classNames(["hljs", className])} style={style}>
      <code dangerouslySetInnerHTML={{ __html: highlighted.value }} />
    </pre>
  );
}
