import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  spacePx?: number;
  spaceLeftPx?: number;
  spaceRightPx?: number;
  sizePx?: number;
  color?: string;
  height?: number;
}

export function SeparatorLine(props: IProps) {
  const { spacePx, spaceLeftPx, spaceRightPx, sizePx, ...rest } = props;

  const inlineStyles: React.CSSProperties | undefined = React.useMemo(() => {
    const styles: React.CSSProperties = props.style || {};

    if (spacePx) {
      styles.marginLeft = `${spacePx}px`;
      styles.marginRight = `${spacePx}px`;
    }

    if (spaceLeftPx) {
      styles.marginLeft = `${spaceLeftPx}px`;
    }

    if (spaceRightPx) {
      styles.marginRight = `${spaceRightPx}px`;
    }

    if (sizePx) {
      styles.width = `${sizePx}px`;
    }

    if (props.color) {
      styles.backgroundColor = props.color;
    }

    if (props.height) {
      styles.height = `${props.height}px`;
    }

    return styles;
  }, [props.color, props.height, props.style, sizePx, spaceLeftPx, spacePx, spaceRightPx]);

  return (
    <div
      {...rest}
      style={inlineStyles}
      className={classNames(style.SeparatorLineWrapper, props.className)}
      data-testid="separator-line"
    />
  );
}

export default SeparatorLine;
