import { Location } from "history";
import React, { useMemo } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";
import { getIsFigmaAuthSession } from "../../figma/lib";
import Spinner from "../spinner/spinner";

const getFigmaCorrelationIsActive = (location: Location) => {
  const onFigmaAuthPage = location.pathname.includes("figma-authentication");
  const active = getIsFigmaAuthSession();

  return active && !onFigmaAuthPage;
};

// There are one of two actions that can be taken in a private route:
// 1. A redirect can be rendered instead of the route
// 2. The route itself can be rendered
//
// This hook determines which action should be taken according to the
// current conditions of the application.
const useShowLoader = () => {
  const location = useLocation();

  const showLoader = useMemo(() => {
    const figmaCorrelationIsActive = getFigmaCorrelationIsActive(location);

    // if there is an ongoing Figma auth request, display a loader to
    // wait for an external redirect to take us to the Figma auth page,
    // UNLESS the user is on the onboarding page (the end of the onboarding
    // flow has its own logic for redirecting users in an ongoing Figma auth
    // request)
    const isInOnboarding = location.pathname.includes("onboarding");
    if (figmaCorrelationIsActive && !isInOnboarding) {
      return true;
    }

    return false;
  }, [location]);

  return showLoader;
};

const PrivateRoute = (props: RouteProps) => {
  const showLoader = useShowLoader();

  if (showLoader) {
    return <Spinner />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
