import ExpandMore from "@mui/icons-material/ExpandMore";
import Language from "@mui/icons-material/Language";
import { ITipTapRichText } from "@shared/types/TextItem";
import classNames from "classnames";
import React from "react";
import RichTextRender from "../components/RichTextRender";
import styles from "./styles.module.css";

export interface Suggestion {
  name: string;
  /** Rich Text */
  text: ITipTapRichText;
  matchPct: number;
  selected: boolean;
  onSelect: (idx: number) => void;
}

interface Props {
  suggestions: Suggestion[];
  selected: boolean;
  onSelect: () => void;
}

function ExpandableSuggestions(props: Props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className={styles.expandableSuggestionsContainer}>
      <div className={styles.expandableSuggestionsInnerContainer}>
        <div
          onClick={() => {
            setExpanded(!expanded);
          }}
          className={classNames({
            [styles.header]: true,
            [styles.expandedHeader]: expanded,
          })}
        >
          <input
            type="checkbox"
            checked={props.selected}
            className={styles.headerSelectCheckbox}
            onChange={props.onSelect}
            onClick={(e) => {
              // Stop header from closing
              e.stopPropagation();
            }}
          />
          <div className={styles.headerTitle}>
            <Language className={styles.icon} />
            Attach Suggestions
          </div>
          <div className={styles.suggestionCount}>
            <span>{props.suggestions.length}</span>
            <ExpandMore className={styles.icon} />
          </div>
        </div>
        {expanded && (
          <>
            {props.suggestions.map((suggestion, idx) => (
              <div className={styles.suggestionContainer} key={idx}>
                <input
                  type="checkbox"
                  checked={suggestion.selected}
                  className={styles.selectCheckbox}
                  onChange={() => {
                    suggestion.onSelect(idx);
                  }}
                />
                <div className={styles.suggestion} key={suggestion.name}>
                  <div className={styles.suggestionName}>{suggestion.name}</div>
                  <RichTextRender richText={suggestion.text} renderProps={{ className: styles.suggestionText }} />
                  <div className={styles.suggestionMatchPct}>{suggestion.matchPct}% Match</div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default ExpandableSuggestions;
