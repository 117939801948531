import { useEffect, useMemo, useState } from "react";
import http, { API } from "../../http";
import { UserWithAccess } from "../permissions/FolderAccessList";
import { User, UserInvitation } from "../permissions/InviteUsersInput/types";

interface UseShareProjectProps {
  projectId: string;
  projectName: string;
  folder: { _id: string; name: string; invite_only: boolean } | null;
  setNotification: (input: { type: string; message: string }) => void;
  onHide: () => void;
}
const useShareProject = (props: UseShareProjectProps) => {
  const { projectId, folder, setNotification, onHide } = props;

  const [isSending, setIsSending] = useState<boolean>(false);
  const [messageInput, setMessageInput] = useState<string>("");

  const [userList, setUserList] = useState<User[]>([]);
  const [invites, setInvites] = useState<UserInvitation[]>([]);
  const [usersWithAccess, setUsersWithAccess] = useState<UserWithAccess[]>([]);

  const invitedEmails = useMemo(() => {
    const temp = usersWithAccess.reduce((acc, curr) => {
      acc[curr.email] = true;
      return acc;
    }, {});
    return temp;
  }, [usersWithAccess]);

  const canSendInvite = useMemo(() => {
    if (isSending || invites.length === 0) return false;
    return invites.every(({ email }) => email);
  }, [invites, isSending]);

  const showExternalInviteMsg = useMemo(() => {
    const hasExternalInvite = invites.some(({ type }) => {
      return type === "new";
    });
    const hasInvalidInvites = invites.some((invite) => !invite.email || invite.isAlreadyInvited);
    return hasExternalInvite && !hasInvalidInvites;
  }, [invites]);

  const isEmailInUse = async (email: string) => {
    try {
      const { url, body } = API.user.post.checkExists;
      const { data: userExists } = await http.post(url, body({ email }));
      return userExists.exists && !userExists.sameWorkspace;
    } catch (error) {
      console.error("Error checking if email exists", error);
      return false;
    }
  };

  const onUserInvited = async (userInvite: UserInvitation) => {
    if (!userInvite.email && invitedEmails[userInvite.name]) return;
    const isAlreadyInvited = userInvite.type !== "new" ? false : await isEmailInUse(userInvite.email);
    setInvites((prev) => {
      if (userInvite?.email && prev.find((invite) => invite?.email === userInvite?.email)) return prev;
      const update = prev.concat({ ...userInvite, isAlreadyInvited });
      return update;
    });
  };

  const onUserRemoved = (index: number) => {
    setInvites((prev) => {
      let temp = prev;
      temp.splice(index, 1);
      return [...temp];
    });
  };

  const fetchFolderInvitedUsers = async () => {
    try {
      const { url } = API.invite.get.folder;
      const { data } = await http.get(url(folder?._id));
      setUsersWithAccess(data);
    } catch (error) {
      console.error("Error fetching invited folder users");
      console.error(error);
    }
  };
  const getWorkspaceUsers = async () => {
    try {
      const { url } = API.workspace.get.users;
      const { data } = await http.get(url);
      const formattedUserList = data.map((user) => ({
        _id: user._id,
        name: user.name,
        email: user.email,
      }));
      setUserList(formattedUserList);
    } catch (error) {
      console.error("Error fetching workspace users");
      console.error(error);
    }
  };

  const messageInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageInput(e.target.value);
  };

  const inviteToProj = async () => {
    try {
      setIsSending(true);
      const { url, body } = API.invite.post.project;
      await http.post(
        url(projectId),
        body({
          invitations: invites,
          message: messageInput,
        })
      );
      setIsSending(false);
      setNotification({
        type: "success",
        message: invites.length === 1 ? "Invite sent! 🎉" : "Invites sent! 🎉",
      });
      setMessageInput("");
      setInvites([]);
      onHide();
    } catch (error) {
      console.error("Error inviting users to project");
      console.error(error);
      setIsSending(false);
      setNotification({
        type: "success",
        message: "There was an error inviting users.",
      });
    }
  };

  useEffect(() => {
    getWorkspaceUsers();
  }, []);

  useEffect(() => {
    if (folder?._id) fetchFolderInvitedUsers();
  }, [folder]);
  return {
    isSending,
    invites,
    userList,
    messageInput,
    canSendInvite,
    showExternalInviteMsg,
    inviteToProj,
    messageInputChange,
    onUserRemoved,
    onUserInvited,
  };
};

export { useShareProject };
