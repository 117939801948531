const ROOT = "/component-folder";
import { IFComponentFolder } from "@shared/types/ComponentFolder";
import { createHttpRequest } from "./lib/createHttpRequest";

export default {
  get: {
    folders: {
      url: ROOT,
    },
  },
  post: {
    createFolder: {
      url: ROOT,
      body: (name: string, component_ids: string[]) => ({
        name,
        component_ids,
      }),
    },
    moveComponentsToFolder: {
      url: (folderId: string | null) => `${ROOT}/${folderId || "none"}/components`,
      body: (component_ids: string[]) => ({ component_ids }),
    },
  },
  put: {
    updateFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
      body: (name: string) => ({ name }),
    },
  },
  delete: {
    deleteFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
    },
  },
};

export const updateApiId = createHttpRequest<
  {
    folderId: string;
    apiId: string;
  },
  IFComponentFolder
>({
  method: "patch",
  getUrl: (args) => `${ROOT}/${args.folderId}/api-id`,
  getConfig: (args) => ({
    data: { apiId: args.apiId },
  }),
});
