import React, { useMemo } from "react";
import style from "./style.module.css";

interface Props {
  name?: string;
}

const getInitials = (name) => {
  if (!name) return;
  if (name?.includes(" ")) {
    const [first, last] = name.toUpperCase().split(" ");
    return first.charAt(0) + last.charAt(0);
  }

  return name.toUpperCase().substr(0, 2);
};
export default ({ name }: Props) => {
  const initials = useMemo(() => getInitials(name), [name]);

  if (name) {
    return (
      <div className={style.userImg}>
        <div className={style.avatar}>{initials}</div>
      </div>
    );
  }
  return (
    <div className={style.userImg}>
      <div className={style.emptyAvatar} />
    </div>
  );
};
