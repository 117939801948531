import { detailsPanelContextAtom } from "@/stores/ProjectSelection";
import EditDetailsPanel from "@/views/NS/ProjectNS/components/DetailsPanel/EditDetailsPanel";
import ProjectDetailsPanel from "@/views/NS/ProjectNS/components/DetailsPanel/ProjectDetailsPanel";
import { useAtomValue } from "jotai";
import style from "./style.module.css";

function DetailsPanel() {
  const detailsPanelContext = useAtomValue(detailsPanelContextAtom);

  return (
    <div className={style.detailsPanelWrapper}>
      <div className={style.detailsPanel}>
        {detailsPanelContext === "PROJECT" && <ProjectDetailsPanel />}
        {detailsPanelContext === "EDIT" && <EditDetailsPanel />}
      </div>
    </div>
  );
}

export default DetailsPanel;
