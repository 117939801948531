import style from "@/components/AppBouncer/AppBouncer.module.css";
import { NewWebAppVersion } from "@/components/NewWebAppVersion";
import AuthenticatedApp from "@/components/app/AuthenticatedApp";
import UnauthenticatedApp from "@/components/app/UnauthenticatedApp";
import ditto from "@/ditto";
import { useRootAuth } from "@/hooks/auth";
import { useDelayUnmount } from "@/hooks/auth/useDelayUnmount";
import http from "@/http";
import { AuthenticatedAuthProvider } from "@/store/AuthenticatedAuthContext";
import { FigmaAuthProvider } from "@/store/FigmaAuthContext";
import { UnauthenticatedAuthProvider } from "@/store/UnauthenticatedAuthContext";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import DittoProvider from "ditto-react";
import React, { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { CookiesProvider } from "react-cookie";

const SENTRY_DSN = process.env.SENTRY_DSN ? process.env.SENTRY_DSN : "";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.ENV,
  release: process.env.VERSION,
  beforeSend(event, hint) {
    event.tags;
    try {
      const eventTags: Record<string, any> = event.tags || {};
      const session = datadogRum.getInternalContext(Date.now());
      if (session && session.session_id) {
        eventTags.datadogRumSession = `https://us3.datadoghq.com/rum/replay/sessions/${session.session_id}?ts=${
          Date.now() - 5000
        }&from=${Date.now() - 5000}`;
        event.extra = {
          ...(event.extra || {}),
          datadogRumSession: `https://us3.datadoghq.com/rum/replay/sessions/${session.session_id}?ts=${
            Date.now() - 5000
          }&from=${Date.now() - 5000}`,
        };
      }

      var _fs = window[window._fs_namespace];

      if (typeof _fs === "function") {
        // getCurrentSessionURL isn't available until after the FullStory script is fully bootstrapped.
        // If an error occurs before getCurrentSessionURL is ready, make a note in Sentry and move on.
        // More on getCurrentSessionURL here: https://help.fullstory.com/develop-js/getcurrentsessionurl
        eventTags.fullstory =
          typeof _fs.getCurrentSessionURL === "function"
            ? _fs.getCurrentSessionURL(true)
            : "current session URL API not ready";
        event.extra = {
          ...(event.extra || {}),
          fullstory:
            typeof _fs.getCurrentSessionURL === "function"
              ? _fs.getCurrentSessionURL(true)
              : "current session URL API not ready",
        };

        // FS.event is immediately ready even if FullStory isn't fully bootstrapped
        let originalError = hint.originalException as any | undefined;
        _fs.event("Application error", {
          name: originalError?.message,
          message: originalError?.message,
          fileName: originalError?.fileName,
          sentryEventId: hint.event_id,
        });
      }

      event.tags = eventTags;
    } catch (e) {
      console.error("Error sending tags to Sentry", e);
    }
    return event;
  },
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
});

const AppBouncer = () => {
  const authState = useRootAuth();
  const loadingOverlayStyle = authState.loading ? { opacity: 1 } : { opacity: 0 };
  const showLoader = useDelayUnmount(authState.loading, 500);

  useEffect(() => {
    if (!authState.getTokenSilently) {
      return;
    }

    http.requestQueue.resolveAll(authState.getTokenSilently);
  }, [authState.getTokenSilently]);

  useEffect(() => {
    if (!authState.user) {
      Sentry.setUser(null);
      return;
    }
    const userProps: any = { ...authState.user, id: authState.user.userId };
    delete userProps.figmaAuth;
    delete userProps.__v;
    Sentry.setUser(userProps);
  }, [authState.user]);

  return (
    <>
      {showLoader && (
        <div className={style.globalLoadingOverlay} style={loadingOverlayStyle}>
          <Spinner animation="border" variant="secondary" />
        </div>
      )}
      {!authState.loading && (
        <CookiesProvider>
          <FigmaAuthProvider authState={authState}>
            <DittoProvider source={ditto}>
              <NewWebAppVersion />
              {!authState.loading && !authState.isAuthenticated && (
                <UnauthenticatedAuthProvider authState={authState}>
                  <UnauthenticatedApp />
                </UnauthenticatedAuthProvider>
              )}
              {!authState.loading && authState.isAuthenticated && (
                <AuthenticatedAuthProvider authState={authState}>
                  <AuthenticatedApp />
                </AuthenticatedAuthProvider>
              )}
            </DittoProvider>
          </FigmaAuthProvider>
        </CookiesProvider>
      )}
    </>
  );
};

export default AppBouncer;
