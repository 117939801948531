import { Extension } from "@tiptap/core";

const SuberscriptKeyboardShortcuts = Extension.create({
  name: "SuberscriptKeyboardShortcuts",
  addKeyboardShortcuts() {
    return {
      // ↓ your new keyboard shortcut
      "Mod-b": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleBold();
        return true;
      },
      "Mod-B": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleBold();
        return true;
      },
      "Mod-u": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleUnderline();
        return true;
      },
      "Mod-U": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleUnderline();
        return true;
      },
      "Mod-i": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleItalic();
        return true;
      },
      "Mod-I": () => {
        this.editor.commands.unsetSuperscript();
        this.editor.commands.unsetSubscript();
        this.editor.commands.toggleItalic();
        return true;
      },
      "Mod-.": () => true,
      "Mod-,": () => true,
    };
  },
});

export default SuberscriptKeyboardShortcuts;
