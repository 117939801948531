import Lock from "@mui/icons-material/Lock";
import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import style from "./style.module.css";

interface CommentOnlyBadgeProps {
  tooltipText: string;
}

const CommentOnlyBadge = (props: CommentOnlyBadgeProps) => {
  return (
    <NavDropdown
      id="comment-only-badge"
      className={style.navDropdown}
      title={
        <div className={style.commentOnlyBadge} data-testid="commenter-badge">
          <Lock className={style.lockIcon} />
          <span className={style.commentOnlyText}>Comment Only</span>
        </div>
      }
    >
      <div className={style.commenterBody}>
        <div className={style.commenterDescription}>{props.tooltipText}</div>
        <Link to="/account/workspace">View admins for this workspace</Link>
      </div>
    </NavDropdown>
  );
};

export default CommentOnlyBadge;
