import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";

import style from "./style.module.css";

interface Props {
  onHide: () => void;
  goToMainProject: () => void;
}
const MergeSuccessModal = ({ onHide, goToMainProject }: Props) => {
  return (
    <BootstrapModal show={true} centered onHide={() => {}}>
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          <span className={style.title}>🎉 Merge successful!</span>
        </BootstrapModal.Title>
        <CloseIcon className={style.closeIcon} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.modalBody}>
        This branch project has now been archived. View the merged changes in the main branch project.
      </BootstrapModal.Body>
      <BootstrapModal.Footer className={style.footer}>
        <ButtonPrimary onClick={goToMainProject} text="Go to main branch project ->" />
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};

export default MergeSuccessModal;
