import React, { useContext } from "react";
import Toast from "react-bootstrap/Toast";
import { Link } from "react-router-dom";
import { TOAST_TYPES } from "../../defs";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import style from "./style.module.css";

const ToastNotification = ({ showToast, toggleShowToast, toastObj, top, left }) => {
  const { unsavedDetailChangesExist, showUnsavedChangesModal } = useContext(UnsavedChangesContext);

  const openIntercomChat = () => {
    window.Intercom("show");
  };

  return (
    <Toast
      className={style.toast_notif}
      style={{ top: top + "%", left: left + "%" }}
      show={showToast}
      onClose={toggleShowToast}
      delay={6000}
      autohide={toastObj.autoHide}
    >
      {toastObj.title && (
        <Toast.Header>
          <strong className="mr-auto">{toastObj.title}</strong>
        </Toast.Header>
      )}
      {toastObj.body && (
        <Toast.Body>
          <span>
            {toastObj.body}
            {toastObj.displayAccountLink && (
              <Link
                onClick={(event) => {
                  if (unsavedDetailChangesExist) {
                    event.preventDefault();
                    showUnsavedChangesModal();
                  }
                }}
                to={`/account/user?reauthorize_figma=true`}
              >
                add a Figma connection.
              </Link>
            )}
            {toastObj.type === TOAST_TYPES.edit_error && (
              <span>
                Please try again or <Link onClick={() => openIntercomChat()}>contact us</Link>.
              </span>
            )}
          </span>
        </Toast.Body>
      )}
    </Toast>
  );
};

export default ToastNotification;
