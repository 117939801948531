import { useWorkspace } from "@/store/workspaceContext";
import ReorderIcon from "@mui/icons-material/Reorder";
import React from "react";
import spinner from "../../../../assets/small-spinner.gif";
import ActivityColumn from "../../../../components/activity-column/index";
import style from "./VariantActivity.module.css";

const VariantActivity = ({ loadingHistory, variantHistory, fetchVariantHistoryNewPage, refreshVariantHistory }) => {
  const { users: workspaceUsers } = useWorkspace();
  return (
    <div data-testid="variant-activity" className={style.sidebar}>
      <div>
        <div className={style.titleSection}>
          <ReorderIcon className={style.activityIcon} />
          Activity
        </div>
      </div>
      <div className={style.overflowContainer}>
        {loadingHistory ? (
          <div>
            <div className={style.loading}></div>
            <div className={style.loadMore}>
              <div>Loading</div>
              <img src={spinner} />
            </div>
          </div>
        ) : (
          <ActivityColumn
            onResolveSuggestion={() => {}}
            setPanelState={() => {}}
            changeHistory={variantHistory.items}
            workspaceUsers={workspaceUsers}
            setCommentState={() => {}}
            selectComp={() => {}}
            selectedId={null}
            fetchLibraryHistoryNewPage={fetchVariantHistoryNewPage}
            refreshLibraryHistory={refreshVariantHistory}
            updateCompResultComments={() => {}}
            setShowCompError={() => {}}
          />
        )}
      </div>
    </div>
  );
};
export default VariantActivity;
