import classNames from "classnames";
import React from "react";
import { ENTRY_TYPES, IChangeItem } from "../../../../shared/types/ActualChange";
import ChangeItemContent from "../../atoms/ChangeItemContent";
import ChangeItemHeader from "../../atoms/ChangeItemHeader";
import ChangeItemCountBadge, { ChangeItemBadgeData } from "../ChangeItemCountBadge";
import style from "./index.module.css";

interface IProps {
  changeItem: IChangeItem;
  onClick?: () => void | Promise<void>;
  badge?: ChangeItemBadgeData;
}

export function ChangeItem({ changeItem, badge, onClick }: IProps) {
  switch (changeItem.entry_type) {
    /**
     * TODO - Render the comment UI here
     * https://linear.app/dittowords/issue/DIT-8059/text-item-activity-comments
     */
    case ENTRY_TYPES.POST_COMMENT:
    case ENTRY_TYPES.POST_REPLY:
      return <></>;
  }

  return (
    <div
      className={classNames(style.ChangeItemWrapper, { [style.clickable]: !!onClick })}
      data-testid="change-item"
      onClick={onClick}
    >
      <ChangeItemHeader changeItem={changeItem} />
      <div className={style.ChangeItemContentArea}>
        <ChangeItemContent changeItem={changeItem} />
        {!!badge && <ChangeItemCountBadge type={badge.type} count={badge.count} />}
      </div>
    </div>
  );
}

export default ChangeItem;
