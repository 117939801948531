import { editableHasChangesAtom, unwrappedEditableBlockAtom } from "@/stores/Editing";
import { deleteBlocksActionAtom, renameBlockActionAtom } from "@/stores/Project";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import DialogueModal from "@ds/molecules/DialogueModal";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";

interface BlockDetailsProps {
  selectedBlockId: string;
}

const BlockDetails = (props: BlockDetailsProps) => {
  const editableBlock = useAtomValue(unwrappedEditableBlockAtom);
  const [newBlockName, setNewBlockName] = useState(editableBlock?.name ?? "");
  const [editableHasChanges, setEditableHasChanges] = useAtom(editableHasChangesAtom);
  const renameBlockAction = useSetAtom(renameBlockActionAtom);
  const [showDeleteBlockConfirmation, setShowDeleteBlockConfirmation] = useState(false);
  const deleteBlocksAction = useSetAtom(deleteBlocksActionAtom);

  function handleKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      saveChanges();
    } else if (e.key === "Escape") {
      cancelChanges();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  function handleDeleteBlock() {
    deleteBlocksAction([props.selectedBlockId]);
  }

  function cancelChanges() {
    setNewBlockName(editableBlock?.name ?? "");
    setEditableHasChanges(false);
  }

  function saveChanges() {
    renameBlockAction(props.selectedBlockId, newBlockName);
    setEditableHasChanges(false);
  }

  function onChangeName(name: string) {
    setNewBlockName(name);
    setEditableHasChanges(name !== editableBlock?.name);
  }

  useEffect(() => {
    setNewBlockName(editableBlock?.name ?? "");
  }, [editableBlock?.name]);

  if (!editableBlock) return <React.Fragment />;

  return (
    <>
      <div className={style.blockDetailsWrapper}>
        <div className={style.blockDetails}>
          <Text weight="strong" className={style.label}>
            Block name
          </Text>
          <TextInput
            placeholder="Edit block name..."
            value={newBlockName}
            onChange={onChangeName}
            onKeyDown={handleKeydown}
          />

          {editableHasChanges && (
            <div className={style.ctas}>
              <Button size="small" onClick={cancelChanges} level="secondary">
                Cancel
              </Button>
              <Button size="small" level="primary" onClick={saveChanges}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={style.deleteBlockArea}>
        <Button size="micro" level="subtle" onClick={() => setShowDeleteBlockConfirmation(true)}>
          Delete block
        </Button>
      </div>

      <DialogueModal
        open={showDeleteBlockConfirmation}
        onOpenChange={setShowDeleteBlockConfirmation}
        onAction={handleDeleteBlock}
        headline="Delete block?"
        content="The text items in this block will move to the bottom of the page, outside of any blocks."
        actionText="Delete"
        type="danger"
      />
    </>
  );
};

export default BlockDetails;
