import React, { useCallback } from "react";
import { FlagName, IUserFlags } from "../types/User";

export const useCompSuggestionNudge =
  (
    getUserFlag: <F extends FlagName>(flagName: F) => IUserFlags[F],
    setUserFlag: <F extends FlagName>(flagName: F, value: IUserFlags[F]) => Promise<void>
  ) =>
  (projectId: string | null) => {
    const flag = getUserFlag("projectsWithCompSuggestionNudgeDisabled");
    const [numDuplicateTexts, setNumDuplicateTexts] = React.useState(0);
    const [numSuggestions, setNumSuggestions] = React.useState(0);
    const [timerFinished, setTimerFinished] = React.useState(false);

    const dismissNudge = async () => {
      if (!projectId) return;
      const newFlag = flag.concat(projectId);
      await setUserFlag("projectsWithCompSuggestionNudgeDisabled", newFlag);
    };

    const startDelay = (delay = 3000) => {
      setTimeout(() => {
        setTimerFinished(true);
      }, delay);
    };

    let showNudge = false;
    if (projectId) {
      showNudge = !flag?.includes(projectId) && numSuggestions >= 3 && timerFinished;
    }

    const state = {
      showNudge,
      numDuplicateTexts,
    };

    /**
     * We only want to set the "dismiss" flag if the nudge is visible.
     *
     * For example, a project might not have any component suggestions yet,
     * but a user clicks on the Components tab, which would prevent them from
     * seeing it in the future.
     */
    const handleDismissNudge = useCallback(() => {
      if (state.showNudge) actions.dismissNudge();
    }, [state.showNudge]);

    const actions = {
      dismissNudge,
      setNumDuplicateTexts,
      setNumSuggestions,
      startDelay,
      handleDismissNudge,
    };

    return { state, actions };
  };
