import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import BranchingIcon from "../../../../components/BranchingIcon";
import ProjectLockedLabel from "../ProjectLockedLabel";
import { BranchProjectInfo } from "../types";

import style from "./style.module.css";

interface Props {
  title: string;
  isLocked: boolean;
  figma_branch_id: string | undefined;
  goToProject: (projectId: string) => void;
  projectBranchInfo: {
    mainProjectInfo: BranchProjectInfo;
    branchProjectInfo: BranchProjectInfo;
  } | null;
}
const ProjectBranchLabel = ({ title, isLocked, figma_branch_id, goToProject, projectBranchInfo }: Props) => {
  const isMergedBranchProject = figma_branch_id && projectBranchInfo?.branchProjectInfo?.isLocked;

  if (isMergedBranchProject) {
    return (
      <div className={style.mergedBranchLabel}>
        <span>{title}</span>
        <div className={style.branchName}>
          <BranchingIcon className={style.branchIcon} />
          <span>{projectBranchInfo?.branchProjectInfo?.name}</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        [style.dropdownWrapper]: true,
        [style.isLocked]: isLocked,
      })}
    >
      <h1 className={style.projectName}>{title}</h1>
      <div className={style.branchButton}>
        <Dropdown>
          <Dropdown.Toggle className={style.dropdownToggle}>
            <BranchingIcon className={style.branchIcon} />
            <span>{figma_branch_id ? projectBranchInfo?.branchProjectInfo?.name : "Main"}</span>
            <KeyboardArrowDownIcon className={style.arrowDownIcon} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={style.dropdownMenu}>
            <Dropdown.Item
              className={style.branchProjectOption}
              onClick={() => goToProject(projectBranchInfo?.branchProjectInfo?._id || "")}
            >
              {!figma_branch_id && <div className={style.emptyIcon}></div>}
              {figma_branch_id && <CheckIcon className={style.checkIcon} />}
              {projectBranchInfo?.branchProjectInfo?.name}
            </Dropdown.Item>
            <Dropdown.Item
              className={style.mainProjectOption}
              onClick={() => goToProject(projectBranchInfo?.mainProjectInfo?._id || "")}
            >
              {figma_branch_id && <div className={style.emptyIcon}></div>}
              {!figma_branch_id && <CheckIcon className={style.checkIcon} />}
              Main{" "}
              {projectBranchInfo?.mainProjectInfo?.isLocked && <ProjectLockedLabel isLocked={true} isSublabel={true} />}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default ProjectBranchLabel;
