import * as Dialog from "@radix-ui/react-dialog";
import React, { useCallback } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IDialogueModalProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The headline text of the modal.
   */
  headline: string;

  /**
   * The body text of the modal.
   */
  content: string;

  /**
   * Flag for whether modal is showing.
   */
  open: boolean;

  /**
   * Handler that's called when the open state changes.
   * @returns
   */
  onOpenChange: (open: boolean) => void;

  /**
   * Handler for when action button is clicked.
   * @returns
   */
  onAction: () => void;

  /**
   * Style variants.
   */
  type?: "default" | "danger";

  /**
   * Text for action button, defaults to "Submit".
   */
  actionText?: string;

  /**
   * Text for cancel button, defaults to "Cancel".
   */
  cancelText?: string;
}

export function DialogueModal(props: IDialogueModalProps) {
  const {
    headline,
    content,
    open,
    onOpenChange,
    onAction,
    type = "default",
    actionText = "Submit",
    cancelText = "Cancel",
  } = props;

  const onCancel = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const onEscapeKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onCancel();
    },
    [onCancel]
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={style.DialogOverlay} />
        <Dialog.Content className={style.DialogContent} onEscapeKeyDown={onEscapeKeyDown}>
          <div className={style.contentWrapper}>
            <Dialog.Title className={style.DialogTitle} asChild>
              <Text size="large" weight="strong">
                {headline}
              </Text>
            </Dialog.Title>
            <Dialog.Description className={style.DialogDescription} asChild>
              <Text color="secondary">{content}</Text>
            </Dialog.Description>
          </div>

          <div className={style.DialogFooter}>
            <Button level="outline" onClick={onCancel}>
              {cancelText}
            </Button>
            <Button level={type === "danger" ? "danger" : "primary"} onClick={onAction} autoFocus>
              {actionText}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default DialogueModal;
