import Spinner from "@/components/spinner/spinner";
import AutoAwesome from "@shared/frontend/AutoAwesomeIcon";
import React from "react";
import style from "./style.module.css";

function AutoNameButton(props: { onClick: () => void; isLoadingNameSuggestion: boolean }) {
  return (
    <div className={style.autoNameBtn} onClick={props.onClick}>
      {props.isLoadingNameSuggestion && <Spinner marginTop={0} size={18} />}
      {!props.isLoadingNameSuggestion && (
        <>
          <AutoAwesome fill="#564d2d" />
          <span>Auto-name</span>
        </>
      )}
    </div>
  );
}

export default AutoNameButton;
