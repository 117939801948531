import classNames from "classnames";
import React, { useState } from "react";
import { ICommentThread } from "../../../../shared/types/CommentThread";
import { IUser } from "../../../../shared/types/User";
import Avatar from "../../atoms/Avatar";
import Button from "../../atoms/Button";
import Comment, { ICommentDisplayUser } from "../../molecules/Comment";
import CommentEditor, { SaveCommentCallback } from "../CommentEditor";
import style from "./index.module.css";

interface IProps {
  commentThread: ICommentThread;
  user: ICommentDisplayUser;
  mentionableUsersById: Record<string, ICommentDisplayUser>;
  mentionableUsers: IUser[];
  postReply: SaveCommentCallback;
  onResolve: () => Promise<void>;
  goToTextItem?: () => void;
  className?: string;
}

export function CommentThread({
  commentThread,
  user,
  mentionableUsers,
  mentionableUsersById,
  postReply,
  onResolve,
  goToTextItem,
  className,
}: IProps) {
  const [showReplies, setShowReplies] = useState(false);
  const [showReplyEditor, setShowReplyEditor] = useState(false);

  const commentCount = commentThread.comments.length;

  const handleResolve = async () => {
    await onResolve();
    setShowReplies(false);
  };

  const handleShowReplyEditor = () => {
    setShowReplyEditor(true);
  };

  const handleHideReplyEditor = () => {
    setShowReplyEditor(false);
  };

  const handlePostReply: SaveCommentCallback = async (...args) => {
    await postReply(...args);
    setShowReplyEditor(false);
  };

  const handleGoToTextItem = () => {
    if (goToTextItem) {
      goToTextItem();
    }
  };

  return (
    <div className={classNames(style.CommentThreadWrapper, className)}>
      <div className={style.existingCommentWrapper} data-testid="comment-thread">
        <Comment comment={commentThread.comments[0]} isResolved={commentThread.is_resolved} onResolve={handleResolve} />
        {commentCount > 0 && (
          <>
            <div className={style.threadContainerAction}>
              {commentCount > 1 && (
                <Button level="subtle" size="micro" onClick={() => setShowReplies((v) => !v)}>
                  {showReplies ? "Hide" : "Show"} {commentCount - 1} {commentCount > 2 ? "replies" : "reply"}
                </Button>
              )}
            </div>
            <div style={{ display: showReplies ? "inline" : "none" }}>
              {commentThread.comments.slice(1).map((comment) => {
                return (
                  <Comment
                    user={mentionableUsersById[comment.user_id]}
                    comment={comment}
                    key={comment._id}
                    isResolved={commentThread.is_resolved}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
      {showReplyEditor ? (
        <div className={style.replyContainer}>
          <Avatar userName={user.name} picture={user.picture} />
          <CommentEditor
            mentionableUsers={mentionableUsers}
            placeholderText="Reply"
            saveContentCallback={handlePostReply}
            onCancel={handleHideReplyEditor}
            submitButtonText="Reply"
            shouldAutofocus
            alwaysShowSubmitButtons
          />
        </div>
      ) : (
        <div className={style.actionContainer}>
          {!!goToTextItem && (
            <div className={style.goToTextButton}>
              <Button size="micro" level="subtle" onClick={handleGoToTextItem}>
                Go to text item
              </Button>
            </div>
          )}
          <Button size="micro" level="secondary" onClick={handleShowReplyEditor}>
            Reply
          </Button>
        </div>
      )}
    </div>
  );
}

export default CommentThread;
