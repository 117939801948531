import { DEFAULT_RICH_TEXT } from "@shared/common/constants";
import { atom } from "jotai";
import { editableHasChangesAtom, inlineEditingAtom, newTextItemTextValueAtom } from "./Editing";
import { clearSelectionActionAtom } from "./ProjectSelection";

export const modalAtom = atom<IModalState | null>(null);

interface IModalState {
  headline: string;
  content: string;
  onAction: () => void;
  onOpenChange: (open: boolean) => void;
  actionText?: string;
  cancelText?: string;
  type?: "default" | "danger";
}

export const discardChangesActionAtom = atom(null, (_get, set) => {
  // clear edits after discarding changes to text item
  set(inlineEditingAtom, null);
  set(editableHasChangesAtom, false);
  set(modalAtom, null);
  set(newTextItemTextValueAtom, DEFAULT_RICH_TEXT);
});

export const discardNewTextItemActionAtom = atom(null, (_get, set) => {
  // clear selection after discarding new text item
  set(clearSelectionActionAtom);
  set(modalAtom, null);
  set(newTextItemTextValueAtom, DEFAULT_RICH_TEXT);
});
