import React from "react";
import ConfirmationModal from "../shared/confirmation-modal.tsx";

const UnsavedChangesModal = ({ modalText, saveFunction, discardFunction }) => {
  return (
    <ConfirmationModal
      isLayered={true}
      title={modalText.title}
      body={modalText.body}
      actionPrimary={modalText.actionPrimary}
      actionSecondary={modalText.actionSecondary}
      onPrimary={saveFunction}
      onSecondary={discardFunction}
    />
  );
};

export default UnsavedChangesModal;
