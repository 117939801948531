import React, { useContext } from "react";
import { BillingContext } from "../../store/billingContext";
import style from "./style.module.css";

const ComponentLimitText = () => {
  const { showBillingModal } = useContext(BillingContext);

  return (
    <div className={style.componentLimitError}>
      Your workspace has reached its component limit! To create more components, please{" "}
      <span className={style.componentLimitErrorLink} onClick={showBillingModal}>
        upgrade your workspace.
      </span>
    </div>
  );
};

export default ComponentLimitText;
