import EditIcon from "@mui/icons-material/Edit";
import classNames from "classnames";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

import style from "./style.module.css";

interface Props {
  value: string;
  className?: string;
  showSuccess?: boolean;
  onCopy?: () => void;
  onEdit?: () => void;
  showHoverMessage?: boolean;
}

export const ApiIdDumbComponent = (props: Props) => {
  const { value, className, showSuccess, onCopy, onEdit, showHoverMessage } = props;
  return (
    <>
      <div
        className={classNames(style.apiID, className)}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {onCopy && (
          <CopyToClipboard text={value} onCopy={() => onCopy()}>
            <div className={style.id} data-tip data-for={`api-id-hover-message-${value}`}>
              {value}
            </div>
          </CopyToClipboard>
        )}
        {!onCopy && <div className={style.id}>{value}</div>}
        {onEdit && <EditIcon onClick={onEdit} className={classNames([style.edit], [style.icon])} />}
      </div>
      {showHoverMessage && (
        <ReactTooltip
          id={`api-id-hover-message-${value}`}
          place="bottom"
          effect="solid"
          offset={{
            top: 9,
            left: 9,
          }}
        >
          {showSuccess ? "Copied to clipboard!" : "Click to copy"}
        </ReactTooltip>
      )}
    </>
  );
};
