// for Variants tab in doc details

import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import React, { useEffect, useState } from "react";
import http, { API } from "../../http";
import PluralsBadge from "../PluralsBadge";
import VariableRichTextArea from "../VariableTextArea/VariableRichTextArea";
import { TextItem } from "../doc/multiselect";
import style from "./style.module.css";

const CompVariants = ({ comp, handleSelectFrameVariant, frameVariants }) => {
  const [namedVariants, setNamedVariants] = useState([]);
  const isEditEnabled = userHasResourcePermission("project_folder:edit");

  useEffect(() => {
    if (comp) {
      getVariants();
    }
  }, [comp]);

  const getVariants = async () => {
    try {
      const { url } = API.variant.get.getVariantsForComp;
      const { data: response } = await http.get(url(comp._id));
      // Set namedVariants to be only the ones that are on the frame
      // itself, as components might have more variants than what
      // is present on the frame itself.
      setNamedVariants(response.variants.filter((v) => frameVariants.find((f) => v.variantId._id === f.id)));
    } catch (error) {
      console.error("Error fetching variants in comp-variants: ", error);
    }
  };

  // switch variant being viewed for frame and go to edit panel
  const goToVariant = (variant) => {
    handleSelectFrameVariant(variant, TextItem.getGroupId(comp));
  };

  return (
    <div className={style.list}>
      <div className={style.heading}>
        {namedVariants.length} Variant{namedVariants.length > 1 && "s"}
      </div>
      {namedVariants.map((variant) => {
        return (
          <div className={style.variant} key={variant.variantId._id}>
            <div className={style.variantTopBar}>
              <div className={style.variantTitle}>
                <div className={style.variantName}>{variant.variantId.name}</div>
                <PluralsBadge comp={comp} variantId={variant.variantId._id} className={style.pluralsBadge} />
              </div>
              <div
                className={style.goToVariant}
                onClick={() =>
                  goToVariant({
                    id: variant.variantId._id,
                    name: variant.variantId.name,
                  })
                }
              >
                {isEditEnabled ? "Edit" : "View"} {"variant ->"}
              </div>
            </div>
            <VariableRichTextArea
              disableRichText={false}
              placeholder="No variant text."
              isDisabled={true}
              isVariant={true}
              hideLabels={true}
              showContentLength={false}
              value={variant}
              handleTextChange={() => {}}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CompVariants;
