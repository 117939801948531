import ArrowUpward from "@mui/icons-material/ArrowUpward";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RenameIcon from "@mui/icons-material/Edit";
import * as SegmentEvents from "@shared/segment-event-names";
import React from "react";
import useSegment from "../../hooks/useSegment";
import BlueFigmaIcon from "../DraftingImportModal/components/BlueFigmaIcon";
import style from "./ComponentsEmptyState.module.css";

interface ComponentsEmptyStateProps {
  openWsCompImportModal: () => void;
  openDraftingModal: () => void;
  openExplainComponentsModal: () => void;
  openSampleComponentFolder: () => void;
}

const ComponentsEmptyState = (props: ComponentsEmptyStateProps) => {
  const segment = useSegment();

  const handleImportClick = () => {
    props.openWsCompImportModal();
    segment.track({
      event: SegmentEvents.COMP_LIBRARY_EMPTY_STATE_IMPORT_STRING_FILE_CLICKED,
    });
  };

  const handleDraftClick = () => {
    props.openDraftingModal();
    segment.track({
      event: "Comp Library Empty State -- Draft new component clicked",
    });
  };

  const handleExplainClick = () => {
    props.openExplainComponentsModal();
    segment.track({
      event: "Comp Library Empty State -- Create comps from design clicked",
    });
  };

  const handleSampleClick = () => {
    props.openSampleComponentFolder();
    segment.track({
      event: "Comp Library Empty State -- Explore sample components clicked",
    });
  };

  return (
    <div className={style.emptyLibraryContainer}>
      <h2>Create your first text components</h2>
      <p>
        Components allow your team to build a library of text that you can reuse across design and development. Work
        faster and ensure consistency at scale.{" "}
        <a href="https://www.dittowords.com/docs/components-in-ditto" target="_blank">
          Learn more
        </a>
      </p>

      <div className={style.buttons}>
        <button onClick={handleImportClick}>
          <CloudUploadIcon className={style.icon} />
          <div className={style.text}>
            <h3>
              Import a string file <span className={style.recommendedBadge}>Recommended</span>
            </h3>
            <p>Generate components from a spreadsheet or string file</p>
          </div>
        </button>

        <button onClick={handleDraftClick}>
          <RenameIcon className={style.icon} />
          <div className={style.text}>
            <h3>Draft a new component</h3>
            <p>Create a new text component from scratch</p>
          </div>
        </button>

        <button className={style.explainer} onClick={handleExplainClick}>
          <div className={style.iconWrapper}>
            <BlueFigmaIcon />
          </div>
          <div className={style.text}>
            <h3>Create components from designs</h3>
            <p>Componentize the text in your projects</p>
          </div>
          <ArrowUpward className={style.arrowIcon} />
        </button>
      </div>

      <button onClick={handleSampleClick} className={style.secondaryButton} data-testid="explore-sample-components">
        Or, explore sample components {"->"}
      </button>
    </div>
  );
};

export default ComponentsEmptyState;
