import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import * as SegmentEvents from "@shared/segment-event-names";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { useEffect, useMemo, useReducer, useRef } from "react";
import { hasValidFolderLoaded } from "../FolderSelect";

import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { ImportAction } from "@shared/types/http/Component";
import useWebSocket from "react-use-websocket";
import useSegment from "../../hooks/useSegment";
import { SelectedFolder } from "../../views/Components/components/ws-comps-title-bar";
import useFolderSelect, { IUseFolderSelect } from "../FolderSelect/useFolderSelect";

export const VALID_STEPS = [
  "file-upload",
  "choose-action",
  "variant-select",
  "preview",
  "success",
  "csv-mapping",
] as const;
export type Step = (typeof VALID_STEPS)[number];

interface IState {
  step: Step;
  loading?: boolean;
  file: { path: string } | null;

  // the action for this import; defaults to null when we
  // haven't determined which action should be taken yet
  action: ImportAction | null;

  format: string | null;

  // components to render
  components: any[] | null;

  // components to ignore - we can't determine this
  // until we've determined the appropriate action to take
  componentsIgnored: any[] | null;

  variant: { id: string; name: string } | null;

  totalComponentsCount: number | null;
  existingComponentsCount: number | null;
  ignoredComponentsCount: number | null;

  backgroundJobEntryId: string | null;
  error: { code: string; data?: { description?: string; position?: number } } | null;
  success: boolean | null;
  componentsInserted: number | null;
  folderSelectState?: IUseFolderSelect;
}

const initialState: IState = {
  step: "file-upload",

  // defaults to null when we haven't determined which action should be taken yet
  action: null,

  // only stored on the state so that the upload can persist
  // while the user navigates between steps
  file: null,

  // 'flat' or 'structured', returned from request to `/import`
  format: null,

  // an array of components, returned from request to `/import`
  components: null,
  componentsIgnored: null,

  // the total number of components in the file, returned from request to `/import`
  // we will only display up to 100 in the preview, and use this number to show the user
  // how many total would be imported
  totalComponentsCount: null,
  existingComponentsCount: null,
  ignoredComponentsCount: null,

  // returned from request to POST /import
  backgroundJobEntryId: null,

  // returned from request to `/import/variant`; { id: string; name: string; }
  variant: null,

  // set to a string if anything goes wrong
  error: null,

  // set to true if the import is successful
  success: null,

  // set to a number if the import is successful
  componentsInserted: null,
};

type Action =
  | { type: "RESET_UPLOAD" }
  | { type: "TOGGLE_LOADING" }
  | { type: "SET_STEP"; step: Step; error?: string }
  | { type: "ACTION_SELECTED"; data: { action: ImportAction } }
  | {
      type: "IMPORT_RESPONSE";
      data: {
        existingComponentsCount: number;
        // this is optional because component data will only be returned in the event
        // that there are no existing components in the import; if there are existing
        // components, the user will need to select an action
        components?: any[];
        file: { path: string };
        format: string;
        totalComponentsCount: number;
        backgroundJobEntryId: string | null;
      };
    }
  | {
      type: "IMPORT_CREATE_RESPONSE";
      step: Step;
      data: {
        components: any[];
        componentsIgnored: any[];
        ignoredComponentsCount: number;
      };
    }
  | {
      type: "IMPORT_VARIANT_RESPONSE";
      step: Step;
      data: {
        variant: { id: string; name: string } | null;
        components: any[];
        componentsIgnored: any[];
        ignoredComponentsCount: number;
      };
    }
  | {
      type: "IMPORT_UPDATE_RESPONSE";
      step: Step;
      data: {
        components: any[];
        componentsIgnored: any[];
        ignoredComponentsCount: number;
      };
    }
  | { type: "RESET_ERROR" }
  | { type: "SUCCESS"; componentsInserted: number };

const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case "RESET_UPLOAD":
      return {
        ...state,
        file: null,
        format: null,
        components: null,
        error: null,
      };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "SET_STEP":
      return {
        ...state,
        step: action.step,
        error: action.error ? { code: action.error } : null,
      };
    case "ACTION_SELECTED":
      return {
        ...state,
        action: action.data.action,
      };
    case "IMPORT_RESPONSE":
      return {
        ...state,
        file: action.data.file ?? state.file,
        format: action.data.format,
        components: action.data.components ?? null,
        totalComponentsCount: action.data.totalComponentsCount,
        existingComponentsCount: action.data.existingComponentsCount,
        backgroundJobEntryId: action.data.backgroundJobEntryId,
      };
    case "IMPORT_CREATE_RESPONSE":
      return {
        ...state,
        action: "CREATE",
        step: action.step,
        components: action.data.components,
        componentsIgnored: action.data.componentsIgnored,
        ignoredComponentsCount: action.data.ignoredComponentsCount,
      };
    case "IMPORT_VARIANT_RESPONSE":
      return {
        ...state,
        action: "VARIANT",
        step: action.step,
        variant: action.data.variant,
        components: action.data.components,
        componentsIgnored: action.data.componentsIgnored,
        ignoredComponentsCount: action.data.ignoredComponentsCount,
      };
    case "IMPORT_UPDATE_RESPONSE":
      return {
        ...state,
        action: "UPDATE",
        step: action.step,
        components: action.data.components,
        componentsIgnored: action.data.componentsIgnored,
        ignoredComponentsCount: action.data.ignoredComponentsCount,
      };
    case "RESET_ERROR":
      return {
        ...state,
        error: null,
      };
    case "SUCCESS":
      return {
        ...state,
        step: "success",
        componentsInserted: action.componentsInserted,
      };
    default:
      throw new Error("Unsupported action type: " + (action as any).type);
  }
};

interface IUseStateProps {
  onHide: () => void;
  selectedFolder: SelectedFolder;
}

export interface IStepProps {
  state: IState;
  dispatch: React.Dispatch<Action>;
}

export const useState = (props: IUseStateProps) => {
  const segment = useSegment();
  const [_state, dispatch] = useReducer(reducer, initialState);
  const { getTokenSilently } = useAuthenticatedAuth();

  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const hasEditAccessToDefaultComponentFolder = userHasResourcePermission(
    "component_folder:edit",
    "component_folder_no_id"
  );

  const folderSelectState = useFolderSelect({
    onlyAllowFoldersWithEditAccess: true,
    disableDefaultFolder: !hasEditAccessToDefaultComponentFolder,
  });

  const folderSelectedIdCache = useRef<string | undefined | null>(props?.selectedFolder?._id);

  useEffect(
    function syncSelectedFolderId() {
      if (!(hasValidFolderLoaded(folderSelectState.folders) && folderSelectedIdCache.current)) {
        return;
      }

      const folder = folderSelectState.folders.find((f) => f._id === folderSelectedIdCache.current);

      folderSelectedIdCache.current = null;
      if (folder) {
        folderSelectState.setSelectedFolder(folder);
      }
    },
    [folderSelectState.folders, props?.selectedFolder]
  );

  const state: IState = useMemo(() => ({ ..._state, folderSelectState }), [_state, folderSelectState]);

  useEffect(() => {
    async function sendWsCompsSubscribeMsg() {
      const subscribeToWsCompsMsg = {
        messageType: WEBSOCKET_EVENTS.COMPONENT_IMPORT,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeToWsCompsMsg));
    }
    if (readyState === 1) {
      sendWsCompsSubscribeMsg();
    }
  }, [readyState]);

  useEffect(() => {
    if (!lastMessage) return;
    const res = JSON.parse(lastMessage.data);

    if (res.messageType === WEBSOCKET_EVENTS.COMPONENT_IMPORT) {
      const { success, data, error } = res.data;
      if (success) {
        const { componentsInserted } = data;
        if (state.step === "preview") {
          dispatch({
            type: "SUCCESS",
            componentsInserted,
          });
          segment.track({
            event: SegmentEvents.JSON_IMPORT_SUCCESS,
            properties: {
              num_components: componentsInserted,
              format: state.format,
            },
          });
        } else {
          dispatch({
            type: "RESET_ERROR",
          });
        }
      } else {
        const errorCode = error?.code;
        if (errorCode === "duplicate-components") {
          dispatch({
            type: "SET_STEP",
            step: "file-upload",
            error: errorCode,
          });
        }
        console.error("Error importing components", error);
      }
    }
  }, [lastMessage]);

  const isValidStep = useMemo(() => VALID_STEPS.includes(state.step), [state.step]);

  return { state, dispatch, isValidStep };
};
