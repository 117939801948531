import CallSplitIcon from "@mui/icons-material/CallSplit";
import { ITipTapRichText } from "@shared/types/TextItem";
import classnames from "classnames";
import React from "react";
import PluralsBadge from "../PluralsBadge";
import RichTextRender from "../RichTextRender";
import style from "./style.module.css";

interface Props {
  name: string;
  text: React.ReactElement | string;
  richText?: ITipTapRichText;
  highlighted: boolean;
  selected: boolean;
  plurals?: any[];
  status?: string;
}
export default function VariantContainer(props: Props) {
  const { name, text, highlighted, selected, richText, plurals, status } = props;

  const classNameContainer = classnames(style.variantContainer, {
    [style.variantContainerSelected]: selected,
    [style.variantHighlighted]: highlighted,
  });

  return (
    <div className={classNameContainer}>
      <div
        className={classnames(style.statusBar, {
          [style.wip]: status === "WIP",
          [style.review]: status === "REVIEW",
          [style.final]: status === "FINAL",
        })}
      />
      <div className={style.main}>
        <div className={style.variantNameContainer}>
          <CallSplitIcon className={style.variantIcon} /> <span className={style.variantName}>{name}</span>
        </div>
        {!richText && <p className={style.variantText}>{text}</p>}
        {richText && <RichTextRender richText={richText} />}
        <PluralsBadge comp={{ plurals }} variantId={null} />
      </div>
    </div>
  );
}
