import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getURLParams } from "../../../util/url";

/**
 * This component is rendered during the process of Auth0
 * calling back to Ditto as a user is authenticating.
 *
 * We redirect from here if there's an error, but otherwise we let
 * the logic in `initAuth0` in `react-auth0-spa.js` take care
 * of logging the user in and directing them to the appropriate page.
 */
const AuthCallback = () => {
  const history = useHistory();

  useEffect(() => {
    const params = getURLParams(window.location.href);
    const error = params.get("error_description");
    if (error) {
      history.push(`/login?error=${error}`);
    }
  }, []);
  return <div></div>;
};

export default AuthCallback;
