import React from "react";
import { spinnerBase64 } from "./spinnerBase64";

import classnames from "classnames";
import style from "./style.module.css";

const Spinner = ({ size = 20, marginTop = 40, centered = true }) => {
  return (
    <div
      className={classnames({
        [style.spinner]: true,
        [style.centered]: centered,
      })}
    >
      <img
        src={spinnerBase64}
        style={{
          width: size + "px",
          height: size + "px",
          marginTop: marginTop + "px",
        }}
      />
    </div>
  );
};

export default Spinner;
