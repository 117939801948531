import OverlayToast from "@/components/OverlayToast";
import { useOverlayToast } from "@/components/OverlayToast/useOverlayToast";
import { NotificationContext } from "@/store/notificationContext";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ReplyIcon from "@mui/icons-material/Reply";
import AnimatedEllipsis from "@shared/frontend/AnimatedEllipsis";
import { AutomatedChangeItem } from "@shared/frontend/AutomatedChangeItem";
import AutoAttachComponentsDisabledChangeItem from "@shared/frontend/AutomatedSettingsChangeItems/AutoAttachComponentsDisabledChangeItem";
import AutoAttachComponentsEnabledChangeItem from "@shared/frontend/AutomatedSettingsChangeItems/AutoAttachComponentsEnabledChangeItem";
import AutoImportFramesDisabledChangeItem from "@shared/frontend/AutomatedSettingsChangeItems/AutoImportFramesDisabledChangeItem";
import AutoImportFramesEnabledChangeItem from "@shared/frontend/AutomatedSettingsChangeItems/AutoImportFramesEnabledChangeItem";
import { ENTRY_TYPES } from "@shared/types/ActualChange";
import classnames from "classnames";
import { DittoComponent, useDittoComponent } from "ditto-react";
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import spinner from "../../assets/small-spinner.gif";
import { routes } from "../../defs";
import { useHasProjectFeatureFlag } from "../../hooks/useHasProjectFeatureFlag";
import { useScroll } from "../../hooks/useScroll";
import http from "../../http";
import { useProjectContext } from "../../views/Project/state/useProjectState";
import { FolderChangeItem } from "../activity-column";
import { ResolvedSuggestionActivityHeader } from "../comment-editor/SuggestionResolution";
import EditDiff from "../editdiff/editdiff";
import InlineCommentThread from "../inlineCommentThread/inlineCommentThread";
import { GrayWarning } from "../shared/GrayWarning";
import style from "./style.module.css";

const DocHistory = ({
  devModeEnabled,
  docHistory,
  docHistoryLoading,
  setSingleSelected,
  setMultiSelected,
  docHistoryIndex,
  loadMoreDocHistory,
  allDocHistFetched,
  resetDocHistory,
  handleCommentChangeClick,
  prev_resync_time,
  curr_resync_time,
  showHighlightNew,
  setShowHighlightNew,
  handleHistoryUpdate,
  handleDocUpdate,
  fetchCompInfo,
  forceShowToast,
  workspaceUsers,
  commentState,
  handleOpenFramePage,
  handleOpenBlockPage,
  setScrollToId,
  isLockedProject,
  onResolveSuggestion,
  emptyText = "No changes.",
  commentHistoryScrollTopRef,
  docHistoryScrollTopRef,
  activityFilter,
  setSyncSettingsModalVisible,
  findTextItemsInProjectViaProps,
}) => {
  const { showToast, hideToast, overlayToastProps } = useOverlayToast();
  const [activeCommentThreadId, setActiveCommentThreadId] = useState(null);
  const { fetchNotifications } = useContext(NotificationContext);
  const { findTextItemsInProject: _findTextItemsInProject } = useProjectContext();
  const detailsContainerRef = useRef(null);
  const textCreatedChangeItemComponentText = useDittoComponent({
    componentId: "text-item-created",
  });

  const findTextItemsInProject = _findTextItemsInProject ?? findTextItemsInProjectViaProps;

  const commentThreadToComponentSet = useMemo(() => {
    /**
     * Due to complex upstream state interactions, it's apparently possible that `docHistory`
     * can be `null` or `undefined` in some cases :()
     */
    if (!docHistory) {
      return new Set();
    }

    const commentThreadTextItemCouplings = docHistory
      .filter((changeItem) => changeItem.comment_thread)
      .map(({ comment_thread }) => [comment_thread._id, comment_thread.comp_id]);

    const textItemToComponentSet = findTextItemsInProject(
      commentThreadTextItemCouplings.map(([, textItemId]) => textItemId)
    ).reduce((set, { _id, ws_comp }) => (ws_comp ? set.add(_id) : set), new Set());

    return commentThreadTextItemCouplings.reduce(
      (set, [threadId, textItemId]) => (textItemToComponentSet.has(textItemId) ? set.add(threadId) : set),
      new Set()
    );
  }, [findTextItemsInProject, docHistory]);

  const history = useHistory();

  const showRichText = useHasProjectFeatureFlag("rich_text");

  useEffect(() => {
    setTimeout(() => {
      setShowHighlightNew(false);
    }, 4000);
  }, [showHighlightNew]);

  useEffect(() => {
    if (commentState.isSelected) {
      setActiveCommentThreadId(commentState.thread_id);
    }
  }, [commentState]);

  const handleCommentThreadClicked = (id) => {
    if (activeCommentThreadId === id) {
      setActiveCommentThreadId(null);
    } else setActiveCommentThreadId(id);
  };

  useLayoutEffect(
    function handleScrollTopOnDocHistoryMount() {
      const refToUse = activityFilter === "Comments" ? commentHistoryScrollTopRef : docHistoryScrollTopRef;
      const container = detailsContainerRef.current;

      requestAnimationFrame(() => {
        container.scrollTop = refToUse.current;
      });

      const handleScroll = () => {
        requestAnimationFrame(() => {
          refToUse.current = container.scrollTop;
        });
      };

      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    },
    [activityFilter]
  );

  const { scrollToId: scrollTo } = useScroll({
    containerId: "projectContainer",
    duration: 500,
    offset: -100,
    smooth: true,
    delay: 50,
  });
  const handleScrollTo = (id) => {
    setScrollToId(id);
    setTimeout(() => {
      scrollTo(id);
    }, 0);
  };

  const goToDoc = (id) => history.push(routes.nonNavRoutes.project.getPath(id));

  return (
    <div className={style.all} ref={detailsContainerRef}>
      {!docHistoryLoading &&
        (!docHistory ||
          docHistory.length < 1 ||
          (docHistory.length === 1 && docHistory[0].entry_type === "import")) && (
          <div className={style.empty}>{emptyText}</div>
        )}
      {resetDocHistory && docHistoryLoading && (
        <div className={style.loading}>
          <img src={spinner} />
          {"   "}Loading new changes...
        </div>
      )}
      {docHistory && (
        <div className={style.docHistory}>
          {docHistory.map((changeItem, index) => {
            const {
              text_before,
              text_after,
              data = {},
              frame_name,
              status,
              entry_type,
              user,
              doc_name,
              date_time,
              dupe_comp_ids,
              block_id,
              block_ids,
              comp_id,
              frame_id,
              comment_thread_id,
              comment_thread,
              variantId,
              ws_comp,
              component_name,
              variant_name,
            } = changeItem;

            if ((entry_type == ENTRY_TYPES.EDIT || entry_type == ENTRY_TYPES.DUPES_EDIT) && text_after !== null) {
              const isTemplateComponent = data.componentType === "template";

              return (
                <div
                  key={index}
                  onClick={() => {
                    entry_type == ENTRY_TYPES.EDIT
                      ? setSingleSelected(comp_id)
                      : setMultiSelected(dupe_comp_ids, false);

                    handleScrollTo(comp_id || dupe_comp_ids[0]);
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    {user && user.includes("FIGMA EDIT") ? (
                      <div>
                        <div className={style.meta}>
                          Edited directly in Figma,{" "}
                          <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                        </div>
                        <div className={style.syncName}>
                          {"->"} Synced by {user.match(/\(([^)]+)\)/)[1]}
                        </div>
                      </div>
                    ) : (
                      <div className={style.meta}>
                        {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                      </div>
                    )}
                    <div className={style.statusWrapper}>
                      <div
                        className={classnames({
                          [style.flex]: true,
                          [style.statusComp]: Boolean(ws_comp),
                          [style.status]: Boolean(ws_comp),
                          [style.componentTemplate]: isTemplateComponent,
                        })}
                      >
                        {Boolean(ws_comp) && (
                          <div className={style.componentEditLabel}>
                            Edited a {isTemplateComponent ? "template component" : "component"}
                          </div>
                        )}
                        {(variantId || variant_name) && (
                          <span className={classnames(style.variantName, style.marginBottom)}>
                            <CallSplitIcon className={style.icon} />
                            {variantId
                              ? variantId.name ?? variant_name
                              : variant_name
                              ? variant_name
                              : "[DELETED VARIANT]"}
                          </span>
                        )}
                        <div className={style.changeAndCount}>
                          <div className={style.change}>
                            <EditDiff
                              text_before={text_before}
                              text_after={text_after}
                              rich_text_before={data?.rich_text_before}
                              rich_text_after={data?.rich_text_after}
                              showRichText={showRichText || Boolean(ws_comp)}
                              key={index}
                            />
                          </div>
                          {entry_type == ENTRY_TYPES.DUPES_EDIT && (
                            <span className={style.count}>{dupe_comp_ids.length}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "rich-text-enabled" || entry_type === "rich-text-disabled") {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    {entry_type === "rich-text-enabled" ? "Enabled" : "Disabled"} Rich Text
                  </div>
                </div>
              );
            } else if (
              entry_type === "frame-apiID-edit" ||
              entry_type === "text-apiID-edit" ||
              entry_type === "block-apiID-edit"
            ) {
              let targetLinkId;
              if (entry_type === "block-apiID-edit") targetLinkId = block_id ? block_id : frame_id;
              else if (entry_type === "frame-apiID-edit") targetLinkId = frame_id;
              else if (entry_type === "text-apiID-edit") targetLinkId = comp_id;

              return (
                <div key={index} onClick={() => handleScrollTo(targetLinkId)}>
                  <div
                    onClick={() => (entry_type == "text-apiID-edit" ? setSingleSelected(comp_id) : null)}
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.changeAndCount}>
                      <div className={style.change}>
                        <EditDiff text_before={text_before} text_after={text_after} key={index} isApiID={true} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type == "group-unlinked" || entry_type === "group-deleted") {
              const groupId = data.group_id;
              const groupname = data.group_name;
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (entry_type == "group-unlinked") {
                      handleOpenFramePage(groupId);
                      handleScrollTo(groupId);
                    }
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.noCursor]: entry_type === "group-deleted",
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={classnames([style.change], [style.frameChange])}>
                      {entry_type === "group-unlinked" ? "Unlinked" : "Deleted"} group
                      <span className={style.frameName}>{groupname}</span>{" "}
                      {entry_type === "group-unlinked" && "from a Figma frame"}
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type == "frame-added") {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleOpenFramePage(frame_id);
                    handleScrollTo(frame_id);
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={classnames([style.change], [style.frameChange])}>
                      Added frame
                      <span className={style.frameName}>{frame_name}</span>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "frame-removed") {
              const groupName = data?.group_name || frame_name;

              return (
                <div
                  key={index}
                  onClick={() => {
                    handleOpenFramePage(frame_id);
                    handleScrollTo(frame_id);
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={classnames([style.change], [style.frameChange])}>
                      Unlinked
                      <span className={style.frameName}>{groupName}</span> from Figma
                    </div>
                  </div>
                </div>
              );
            } else if (
              entry_type === "add-text-item-to-draft-group" ||
              entry_type === "delete-text-item-from-draft-group"
            ) {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (entry_type === "add-text-item-to-draft-group") {
                      handleOpenFramePage(data.groupId);
                      handleScrollTo(data.groupId);
                    }
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={classnames([style.change], [style.frameChange])}>
                      {entry_type === "add-text-item-to-draft-group" ? "Added" : "Deleted"} a text item{" "}
                      {entry_type === "add-text-item-to-draft-group" ? "to" : "in"}{" "}
                      <span className={style.frameName}>{data.groupName}</span>
                    </div>
                  </div>
                </div>
              );
            } else if (
              [
                "frame-add-variant",
                "frame-delete-variant",
                "frame-apply-variant",
                "frame-remove-applied-variant",
              ].includes(entry_type)
            ) {
              const numFrames = data?.dupe_frame_ids ? data.dupe_frame_ids.length : 1;
              const entryCopy = {
                "frame-apply-variant": {
                  action: "Applied",
                  object: numFrames > 1 ? `to ${numFrames} frames in Figma` : "to a frame in Figma",
                },
                "frame-remove-applied-variant": {
                  action: "Removed applied",
                  object: numFrames > 1 ? `from ${numFrames} frames in Figma` : `from a frame in Figma`,
                },
                "frame-add-variant": {
                  action: "Added",
                  object: numFrames > 1 ? `to ${numFrames} frames` : "to a frame",
                },
                "frame-delete-variant": {
                  action: "Deleted",
                  object: numFrames > 1 ? `from ${numFrames} frames` : `from a frame`,
                },
              };
              return (
                <div
                  key={index}
                  onClick={() => {
                    // need to think about this one
                    handleOpenFramePage(frame_id);
                    handleScrollTo(frame_id);
                  }}
                >
                  <div
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.changeAndCount}>
                      <div className={classnames([style.change], [style.variantChange])}>
                        {entryCopy[entry_type].action}{" "}
                        <span className={style.variantName}>
                          <CallSplitIcon className={style.icon} />
                          {variantId ? variantId.name : variant_name ? variant_name : "[DELETED VARIANT]"}
                        </span>{" "}
                        {entryCopy[entry_type].object}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (
              ((entry_type == ENTRY_TYPES.STATUS || entry_type == ENTRY_TYPES.DUPES_STATUS) && status !== null) ||
              entry_type == ENTRY_TYPES.VARIANT_STATUS_CHANGED
            ) {
              const isTemplateComponent = data.componentType === "template";

              let message = "Marked ";
              if (ws_comp && isTemplateComponent) {
                message += "template component ";
              } else if (ws_comp) {
                message += "component ";
              } else {
                message += "text ";
              }
              message += "as ";

              let statusToRender;
              if (entry_type === ENTRY_TYPES.STATUS || entry_type === ENTRY_TYPES.DUPES_STATUS) {
                statusToRender = status;
              } else if (ENTRY_TYPES.VARIANT_STATUS_CHANGED) {
                statusToRender = data.status_after;
              }

              if (!statusToRender || !["NONE", "WIP", "REVIEW", "FINAL"].includes(statusToRender)) {
                return <></>;
              }

              return (
                <div key={index} onClick={() => handleScrollTo(comp_id || dupe_comp_ids[0])}>
                  <div
                    onClick={() =>
                      entry_type == ENTRY_TYPES.STATUS || entry_type === ENTRY_TYPES.VARIANT_STATUS_CHANGED
                        ? setSingleSelected(comp_id)
                        : setMultiSelected(dupe_comp_ids, false)
                    }
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.statusWrapper}>
                      <div
                        className={classnames({
                          [style.flex]: true,
                          [style.statusComp]: Boolean(ws_comp),
                          [style.status]: Boolean(ws_comp),
                          [style.componentTemplate]: isTemplateComponent,
                        })}
                      >
                        {Boolean(ws_comp) && (
                          <div className={style.componentEditLabel}>
                            Edited a {isTemplateComponent ? "template component" : "component"}
                          </div>
                        )}
                        {entry_type == ENTRY_TYPES.VARIANT_STATUS_CHANGED && (
                          <span className={classnames(style.variantName, style.marginBottom)}>
                            <CallSplitIcon className={style.icon} />
                            {variantId ? variantId.name : variant_name ? variant_name : "[DELETED VARIANT]"}
                          </span>
                        )}
                        <div className={style.changeAndCount}>
                          <div
                            className={classnames({
                              [style.text]: true,
                              [style.status]: true,
                              [style.change]: true,
                              [style.statusNone]: statusToRender == "NONE",
                              [style.statusWip]: statusToRender == "WIP",
                              [style.statusReview]: statusToRender == "REVIEW",
                              [style.statusFinal]: statusToRender == "FINAL",
                              [style.statusDev]: statusToRender == "DEV",
                            })}
                          >
                            {message}
                            <strong>{statusToRender}</strong>
                          </div>
                          {dupe_comp_ids && dupe_comp_ids.length > 1 && (
                            <span className={style.count}>{dupe_comp_ids.length}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "inline-comment") {
              const isSuggestion = Boolean(comment_thread.suggestion);
              const isForComponent = commentThreadToComponentSet.has(comment_thread._id);
              const isResolved = comment_thread.is_resolved;
              const isResolvedSuggestion = isSuggestion && isResolved;
              const resolvedUser = comment_thread.resolved_by?.user_name || user;

              const showWarning = isSuggestion && isForComponent && !isResolved;

              return (
                <div key={index}>
                  {isResolvedSuggestion && (
                    <ResolvedSuggestionActivityHeader user={resolvedUser} date={comment_thread.updatedAt} />
                  )}
                  {showWarning && (
                    <GrayWarning className={style.componentSuggestionWarning}>
                      Accepting a suggestion for a component will also sync to all of the component's instances.
                    </GrayWarning>
                  )}
                  <InlineCommentThread
                    className={isResolvedSuggestion ? style.resolvedSuggestionContainer : undefined}
                    isDisabled={isLockedProject}
                    isActive={activeCommentThreadId === comment_thread._id}
                    comment_thread={comment_thread}
                    doc_name={doc_name}
                    comp_id={comp_id}
                    ws_comp_id={ws_comp}
                    onResolveSuggestion={onResolveSuggestion}
                    handleCommentChangeClick={handleCommentChangeClick}
                    handleHistoryUpdate={handleHistoryUpdate}
                    handleDocUpdate={handleDocUpdate}
                    handleCommentThreadClicked={handleCommentThreadClicked}
                    handleScrollTo={handleScrollTo}
                    fetchNotifications={fetchNotifications}
                    fetchCompInfo={fetchCompInfo}
                    forceShowToast={forceShowToast}
                    workspaceUsers={workspaceUsers}
                  />
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.POST_COMMENT) {
              return (
                <div key={index} onClick={() => handleScrollTo(comp_id)}>
                  <div
                    onClick={() => handleCommentChangeClick(comp_id, comment_thread_id)}
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.commentChange}>
                      <ModeCommentIcon className={style.icon} />
                      Posted a new comment
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.POST_REPLY) {
              return (
                <div key={index} onClick={() => handleScrollTo(comp_id)}>
                  <div
                    onClick={() => handleCommentChangeClick(comp_id, comment_thread_id)}
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.commentChange}>
                      <ReplyIcon className={style.icon} />
                      Replied to a comment
                    </div>
                  </div>
                </div>
              );
            } else if (
              entry_type === "ws-comp-attach" ||
              entry_type === "dupes-ws-comp-attach" ||
              entry_type === "ws-comp-detach" ||
              entry_type === "dupes-ws-comp-detach"
            ) {
              // we don't show attach/detach for variants
              if (variantId) return null;

              const { componentType } = data;
              const componentText = componentType === "template" ? "template component" : "component";

              const isAttachType = entry_type === "ws-comp-attach" || entry_type === "dupes-ws-comp-attach";

              return (
                <div key={index} onClick={() => handleScrollTo(comp_id || dupe_comp_ids[0])}>
                  <div
                    onClick={() => {
                      if (entry_type === "dupes-ws-comp-attach" || entry_type === "dupes-ws-comp-detach")
                        setMultiSelected(dupe_comp_ids, false);
                      else setSingleSelected(comp_id);
                    }}
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.statusWrapper}>
                      <div
                        className={classnames([
                          style.statusComp,
                          style.status,
                          {
                            [style.componentTemplate]: componentType === "template",
                          },
                        ])}
                      >
                        <div
                          className={classnames({
                            [style.compAttachDetachLabel]: true,
                            [style.compAttachDetachLabelPadding]: isAttachType,
                          })}
                        >
                          {isAttachType ? `Attached to a ${componentText}` : `Detached from a ${componentText}`}
                        </div>
                        {isAttachType && (
                          <EditDiff
                            text_before={text_before}
                            text_after={text_after}
                            rich_text_before={data?.rich_text_before}
                            rich_text_after={data?.rich_text_after}
                            showRichText={true}
                            key={index}
                          />
                        )}
                      </div>
                      {dupe_comp_ids?.length > 1 && <span className={style.count}>{dupe_comp_ids.length}</span>}
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "connect-project") {
              return (
                <div className={style.branchItem} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Connected project to figma file <span className={style.docName}>{data.fileName}</span>
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.DITTO_PROJECT_CREATED) {
              return (
                <div className={style.branchItem} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Created project <span className={style.docName}>{doc_name}</span>
                  </div>
                </div>
              );
            } else if (entry_type === "group-connected") {
              return (
                <div
                  className={style.draftItem}
                  key={index}
                  onClick={() => {
                    let groupId = data?.group_id;
                    handleOpenFramePage(groupId);
                    handleScrollTo(groupId);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.frameChange}>
                    Connected group <span className={style.groupName}>{data.group_name}</span> to a Figma frame
                  </div>
                </div>
              );
            } else if (entry_type === "draft-group-created") {
              return (
                <div
                  className={style.draftItem}
                  key={index}
                  onClick={() => {
                    let groupId = data?.group_id;
                    handleOpenFramePage(groupId);
                    handleScrollTo(groupId);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Created
                    <span className={style.frameName}>{data.group_name}</span>{" "}
                  </div>
                </div>
              );
            } else if (entry_type === "group-linking-enabled") {
              return (
                <div
                  className={style.draftItem}
                  key={index}
                  onClick={() => {
                    let groupId = data?.group_id;
                    handleOpenFramePage(groupId);
                    handleScrollTo(groupId);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Enabled linking for
                    <span className={style.frameName}>{data.group_name}</span>
                  </div>
                </div>
              );
            } else if (entry_type === "group-renamed") {
              return (
                <div
                  className={style.draftItem}
                  key={index}
                  onClick={() => {
                    let groupId = data?.group_id;
                    handleOpenFramePage(groupId);
                    handleScrollTo(groupId);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>

                  <div className={style.groupRenameLabel}>Edited a frame name</div>
                  <div className={style.text}>
                    <div className={style.change}>
                      <EditDiff text_before={data?.text_before} text_after={data?.text_after} />
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "group-reverted-to-draft") {
              return (
                <div
                  className={style.draftItem}
                  key={index}
                  onClick={() => {
                    let groupId = data?.group_id;
                    handleOpenFramePage(groupId);
                    handleScrollTo(groupId);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <span className={style.groupName}>{data.group_name}</span> reverted to draft
                  </div>
                </div>
              );
            } else if (entry_type === "branch-discarded") {
              return (
                <div className={style.branchItem} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Discarded changes from Figma branch <span className={style.docName}>{data.branch_name}</span>
                  </div>
                </div>
              );
            } else if (entry_type === "branch-updated") {
              return (
                <div className={style.branchItem} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Updated Figma syncing to main branch <span className={style.docName}>{data.file_name}</span>
                  </div>
                </div>
              );
            } else if (entry_type === "branch-archived") {
              return (
                <div className={style.branchItem} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>Archived this project</div>
                </div>
              );
            } else if (entry_type === "branch-merged") {
              if (!data.branch_name) {
                return (
                  <div
                    className={classnames([style.branchItem, style.pointer])}
                    key={index}
                    onClick={() => goToDoc(data.file_id)}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.text}>
                      Merged changes into main branch <span className={style.docName}>{data.file_name}</span>
                    </div>
                  </div>
                );
              }
              return (
                <div
                  className={classnames({
                    [style.branchItem]: true,
                    [style.pointer]: data.branch_archived,
                  })}
                  key={index}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Merged changes from Figma branch <span className={style.docName}>{data.branch_name}</span>
                  </div>
                </div>
              );
            } else if (entry_type === "dev-mode-on") {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.devModeOnChange}>
                    Enabled <span>Developer Mode</span>
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.PLURAL_EDITED) {
              let message;
              if (Boolean(ws_comp)) {
                if (data?.added?.length && data?.removed?.length) {
                  message = "Added and removed plural forms from a component";
                } else if (data?.added?.length) {
                  message = `Added plural form${data?.added?.length > 1 ? "s" : ""} to a component`;
                } else if (data?.removed?.length) {
                  message = `Removed plural form${data?.removed?.length > 1 ? "s" : ""} from a component`;
                }
              } else {
                if (data?.added?.length && data?.removed?.length) {
                  message = "Added and removed plural forms";
                } else if (data?.added?.length) {
                  message = `Added plural form${data?.added?.length > 1 ? "s" : ""}`;
                } else if (data?.removed?.length) {
                  message = `Removed plural form${data?.removed?.length > 1 ? "s" : ""}`;
                }
              }

              return (
                <div key={index} onClick={() => handleScrollTo(comp_id || dupe_comp_ids[0])}>
                  <div
                    onClick={() =>
                      dupe_comp_ids.length === 0 ? setSingleSelected(comp_id) : setMultiSelected(dupe_comp_ids, false)
                    }
                    className={classnames({
                      [style.version]: true,
                      [style.newHighlight]: date_time > prev_resync_time && showHighlightNew,
                    })}
                    key={index}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>

                    <div className={style.statusWrapper}>
                      <div
                        className={classnames(style.change, style.pluralItem, {
                          [style.statusComp]: Boolean(ws_comp),
                          [style.status]: Boolean(ws_comp),
                        })}
                      >
                        {message}
                      </div>

                      <div>
                        {dupe_comp_ids?.length > 1 && <span className={style.count}>{dupe_comp_ids.length}</span>}
                      </div>
                    </div>
                    {variantId && (
                      <div className={style.variantChange}>
                        <span className={style.variantName}>
                          <CallSplitIcon className={style.icon} />
                          {variantId ? variantId.name : variant_name ? variant_name : "[DELETED VARIANT]"}
                        </span>{" "}
                      </div>
                    )}
                  </div>
                </div>
              );
            } else if (
              entry_type == "hide-comp" ||
              entry_type == "unhide-comp" ||
              entry_type == "multi-hide-comp" ||
              entry_type == "multi-unhide-comp"
            ) {
              const isMultiHide = entry_type == "multi-hide-comp" || entry_type == "multi-unhide-comp";

              const didHide = entry_type == "hide-comp" || entry_type == "multi-hide-comp";

              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    if (isMultiHide) {
                      setMultiSelected(dupe_comp_ids);
                      handleScrollTo(dupe_comp_ids[0]);
                    } else {
                      setSingleSelected(comp_id);
                      handleScrollTo(comp_id);
                    }
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.compHiddenMessage}>
                    <span className={style.text}>
                      {didHide ? "Hid text" : "Unhid text"}
                      {frame_name && (
                        <>
                          {" "}
                          in <span className={style.frameName}>{frame_name}</span>
                        </>
                      )}
                    </span>
                    {isMultiHide && <span className={style.count}>{dupe_comp_ids.length}</span>}
                  </div>
                </div>
              );
            } else if (entry_type == "block-created") {
              const stripped_frame_name = frame_name.replace(/\[\[.\]\]/, "");

              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    handleOpenBlockPage(block_id);
                    handleScrollTo(block_id);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.compHiddenMessage}>
                    <span className={style.text}>
                      Block created in
                      <span className={style.frameName}>{stripped_frame_name}</span>
                    </span>
                  </div>
                </div>
              );
            } else if (entry_type == "blocks-created") {
              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.compHiddenMessage}>
                    <span className={style.text}>
                      Blocks created in
                      <span className={style.frameName}>{frame_name}</span>
                    </span>
                  </div>
                </div>
              );
            } else if (entry_type === "project-move-to-folder") {
              const user = changeItem.user;
              const projectName = changeItem.data.project_name;
              const folderName = changeItem.data.folder_name;
              const folderId = changeItem.data.folder_id;

              const preposition = folderId ? "to" : "out of";

              return (
                <FolderChangeItem
                  key={index}
                  user={user}
                  date={date_time}
                  text={
                    <span>
                      Moved <b>{projectName}</b> {preposition}
                    </span>
                  }
                  folderName={folderName}
                />
              );
            } else if (entry_type === ENTRY_TYPES.COMP_ASSIGNED || entry_type === ENTRY_TYPES.MULTI_COMP_ASSIGNED) {
              const isMulti = entry_type === ENTRY_TYPES.MULTI_COMP_ASSIGNED;
              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    if (isMulti) {
                      setMultiSelected(dupe_comp_ids);
                      handleScrollTo(dupe_comp_ids[0]);
                    } else {
                      // if we assigned a ws_comp with multiple instances in the
                      // same project, just select the first one
                      if (dupe_comp_ids) {
                        setSingleSelected(dupe_comp_ids[0]);
                        handleScrollTo(dupe_comp_ids[0]);
                      } else {
                        setSingleSelected(comp_id);
                        handleScrollTo(comp_id);
                      }
                    }
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.statusWrapper}>
                    <div
                      className={classnames({
                        [style.flex]: true,
                        [style.statusComp]: Boolean(ws_comp),
                        [style.status]: Boolean(ws_comp),
                      })}
                    >
                      <div className={style.compAssignedMessage}>
                        {data.assignee ? (
                          <span className={style.text}>
                            Assigned {Boolean(ws_comp) ? "a component" : "text"} to <span>@{data.assigneeName}</span>
                          </span>
                        ) : (
                          <span className={style.text}>Unassigned text</span>
                        )}
                        {isMulti && <span className={style.count}>{dupe_comp_ids.length}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (entry_type === "project-ids-regenerated") {
              if (!devModeEnabled) return <React.Fragment key={index} />;

              return (
                <div className={style.version} key={index}>
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    Regenerated all text item IDs.{" "}
                    <a href="/developers/configure-dev-ids" target="_blank" className={style.grayLink}>
                      Learn more
                    </a>
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.COMPONENT_AUTO_ATTACH) {
              const items = changeItem.children.map((child) => ({
                id: child._id,
                text: child?.ws_comp?.name || "[DELETED COMPONENT]",
                count: child?.children?.length || 0,
              }));

              return (
                <div className={style.automatedChangeItemWrapper} key={changeItem._id}>
                  <AutomatedChangeItem
                    type={"COMPONENTS"}
                    reverted={!!changeItem.data.undone}
                    components={{ DittoComponent }}
                    headText={
                      changeItem.data.inProgress ? (
                        <span>
                          <DittoComponent componentId="automatically-attaching-components" />
                          <AnimatedEllipsis />
                        </span>
                      ) : (
                        <DittoComponent
                          componentId="automatically-attached-components"
                          variables={{
                            componentCount: changeItem.children.length,
                          }}
                          count={changeItem.children.length}
                        />
                      )
                    }
                    timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                    items={items}
                    itemOnClick={(item) => {
                      const clicked = changeItem.children.find((c) => c._id === item.id);
                      const ids = clicked.children.map((child) => child.comp_id).filter(Boolean);
                      if (ids.length > 1) {
                        setMultiSelected(ids);
                        handleScrollTo(ids[0]);
                      } else {
                        setSingleSelected(ids[0]);
                        handleScrollTo(ids[0]);
                      }
                    }}
                    truncationLimit={40}
                    inProgress={changeItem.data.inProgress}
                    createdAt={date_time}
                    automatedSettingsOnClick={() => setSyncSettingsModalVisible(true)}
                    bulkRevertOnClick={async function () {
                      showToast(<>Reverting...</>);
                      await http.put("/ws_comp/attachComp/undo", {
                        changeItemId: changeItem._id,
                        projectId: changeItem.doc_id,
                      });
                      hideToast();
                      handleHistoryUpdate(false, true);
                    }}
                    bulkRevertUnavailableWhyOnClick={function () {
                      window.open("https://www.dittowords.com/docs/component-suggestions", "_blank");
                    }}
                  />
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.FRAME_AUTO_ATTACH) {
              const items = changeItem.children.map((child) => ({
                text: child?.frame_name || "",
              }));

              return (
                <div className={style.automatedChangeItemWrapper} key={changeItem._id}>
                  <AutomatedChangeItem
                    type={"FRAMES"}
                    reverted={!!changeItem.data.undone}
                    components={{ DittoComponent }}
                    headText={
                      changeItem.data.inProgress ? (
                        <DittoComponent componentId="automatically-attaching-frames" />
                      ) : (
                        <DittoComponent
                          componentId="automatically-added-new-frames"
                          variables={{
                            frameCount: changeItem.children.length,
                          }}
                          count={changeItem.children.length}
                        />
                      )
                    }
                    timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                    items={items}
                    itemOnClick={(item) => {
                      const clicked = changeItem.children.find((c) => c.frame_name === item.text);
                      handleOpenFramePage(clicked.frame_id);
                      handleScrollTo(clicked.frame_id);
                    }}
                    truncationLimit={40}
                    inProgress={changeItem.data.inProgress}
                    createdAt={date_time}
                    automatedSettingsOnClick={() => setSyncSettingsModalVisible(true)}
                  />
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.AUTO_ATTACH_COMPONENTS_DISABLED) {
              return (
                <AutoAttachComponentsDisabledChangeItem
                  key={index}
                  user={user}
                  timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                  onAutomationSettingsClick={() => setSyncSettingsModalVisible(true)}
                />
              );
            } else if (entry_type === ENTRY_TYPES.AUTO_ATTACH_COMPONENTS_ENABLED) {
              return (
                <AutoAttachComponentsEnabledChangeItem
                  key={index}
                  user={user}
                  timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                  data={data}
                  onAutomationSettingsClick={() => setSyncSettingsModalVisible(true)}
                  onClick={() => {
                    if (data.componentFolderId) {
                      history.push(`/components/folder/${data.componentFolderId}`);
                    } else {
                      history.push("/components");
                    }
                  }}
                />
              );
            } else if (entry_type === ENTRY_TYPES.AUTO_IMPORT_FRAMES_ENABLED) {
              return (
                <AutoImportFramesEnabledChangeItem
                  key={index}
                  user={user}
                  timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                  data={data}
                  onAutomationSettingsClick={() => setSyncSettingsModalVisible(true)}
                />
              );
            } else if (entry_type === ENTRY_TYPES.AUTO_IMPORT_FRAMES_DISABLED) {
              return (
                <AutoImportFramesDisabledChangeItem
                  key={index}
                  user={user}
                  timeAgo={<TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />}
                  onAutomationSettingsClick={() => setSyncSettingsModalVisible(true)}
                />
              );
            } else if (entry_type === ENTRY_TYPES.WS_COMP_CHARACTER_LIMIT_UPDATE) {
              if (data.wsCompIds.length === 1) {
                const wsCompId = data.wsCompIds[0];
                return (
                  <div
                    className={style.version}
                    key={index}
                    onClick={() => {
                      handleScrollTo(dupe_comp_ids, true);
                      setMultiSelected(dupe_comp_ids);
                    }}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.text}>
                      <DittoComponent
                        componentId="component-character-limit-change"
                        variables={{
                          characterLimit: data.newCharacterLimit,
                          count: 1,
                        }}
                        count={1}
                        richText
                      />
                    </div>
                  </div>
                );
              }

              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    handleScrollTo(dupe_comp_ids[0], true);
                    setMultiSelected(dupe_comp_ids);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <DittoComponent
                      componentId="component-character-limit-change"
                      variables={{
                        characterLimit: data.newCharacterLimit,
                        count: data.wsCompIds.length,
                      }}
                      count={data.wsCompIds.length}
                      richText
                    />
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE) {
              if (data.textItemIds.length === 1) {
                const textItemId = data.textItemIds[0];
                return (
                  <div
                    className={style.version}
                    key={index}
                    onClick={() => {
                      handleScrollTo(textItemId, true);
                      setSingleSelected(textItemId);
                    }}
                  >
                    <div className={style.meta}>
                      {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                    </div>
                    <div className={style.text}>
                      <DittoComponent
                        componentId="component-character-limit-change"
                        variables={{
                          characterLimit: data.newCharacterLimit,
                          count: 1,
                        }}
                        count={1}
                        richText
                      />
                    </div>
                  </div>
                );
              }

              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    handleScrollTo(data.textItemIds[0], true);
                    setMultiSelected(data.textItemIds);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>
                    <DittoComponent
                      componentId="component-character-limit-change"
                      variables={{
                        characterLimit: data.newCharacterLimit,
                        count: data.textItemIds.length,
                      }}
                      count={data.textItemIds.length}
                      richText
                    />
                  </div>
                </div>
              );
            } else if (entry_type === ENTRY_TYPES.TEXT_ITEM_CREATED) {
              return (
                <div
                  className={style.version}
                  key={index}
                  onClick={() => {
                    handleScrollTo(comp_id, true);
                  }}
                >
                  <div className={style.meta}>
                    {user}, <TimeAgo date={date_time} minPeriod={30} key={date_time.toString()} />
                  </div>
                  <div className={style.text}>{textCreatedChangeItemComponentText}</div>
                </div>
              );
            } else {
              console.error(`Unsupported entry type: ${entry_type}`, changeItem);
              return <React.Fragment key={index} />;
            }
          })}
        </div>
      )}
      {!allDocHistFetched && !docHistoryLoading && (
        <div className={style.loadMore} onClick={loadMoreDocHistory}>
          <div>Load more</div>
          <ArrowDownwardIcon className={style.icon} />
        </div>
      )}
      {docHistoryLoading &&
        (docHistoryIndex === 0 ? (
          resetDocHistory ? null : (
            <div className={style.initialLoading}>
              <img src={spinner} />
            </div>
          )
        ) : (
          <div className={style.loading}>
            <img src={spinner} />
            {"   "}Loading more changes...
          </div>
        ))}
      <OverlayToast {...overlayToastProps} />
    </div>
  );
};

export default DocHistory;
