import { useEffect, useState } from "react";
import * as workspaceRequests from "../../http/workspaceNew";

interface GetChecklistLoading {
  loading: true;
}

interface GetChecklistSuccess {
  loading: false;
  success: true;
  data: workspaceRequests.OnboardingChecklistResponse;
}

interface GetChecklistFailure {
  loading: false;
  success: false;
  error: string;
}

export type GetChecklistState = GetChecklistLoading | GetChecklistSuccess | GetChecklistFailure;

/**
 * Fetches the onboarding checklist for the current workspace
 * @returns {[GetChecklistState, Promise<AxiosResponse<OnboardingChecklistResponse>>]} The state of the fetched checklist and the request to refresh it.
 */
export const useChecklist = (): [GetChecklistState, () => Promise<void>] => {
  const [state, setState] = useState<GetChecklistState>({ loading: true });

  const fetchOnboardingChecklist = async () => {
    const [fetchSeatsRequest, cancelSubscriptionsRequest] = workspaceRequests.fetchOnboardingChecklist();
    try {
      const { data } = await fetchSeatsRequest;
      setState({ loading: false, success: true, data });
    } catch (error) {
      console.error(error);
      setState({
        loading: false,
        success: false,
        error: "Unable to fetch onboarding checklist.",
      });
    }
  };

  useEffect(() => {
    fetchOnboardingChecklist();
  }, [setState]);

  return [state, fetchOnboardingChecklist];
};
