import { isGroupLinked } from "../state/types";
import { ALL_PAGE_ID, DRAFTED_GROUPS_PAGE_ID } from "../state/usePageState";

/**
 * To facilitate ease of selecting multiple components, this function
 * takes an array of groups and returns a flattened array of components
 * with metadata to be used as a source of truth by the multi-select logic.
 *
 * Importantly: it mutates all of the components in `groups` AND in `componentMap`,
 * giving them a `_meta` property with the metadata described below.
 *
 * Metadata:
 * - index: an integer that indicates the position of the component relative
 *   to every other component in the document
 * - groupId: the group the indexed component is in
 * - groupIndex: the index of the group the indexed component is in
 * - blockId: the id of the block the indexed component is in (if any)
 * - disabled:
 *   - bySearch: true if the component doesn't match the current search query
 *   - byCollapsedSection: true if the component isn't in a block and is inside of
 *   a group with its "other text section" collapsed.
 */
export const createMultiSelectComponentIndex = ({ groups, page }) => {
  if (!groups) return [];

  let index = 0;
  const components = [];

  const indexComponent = (component, groupId, groupIndex, blockId = null, nonBlocksCollapsed = false) => {
    // Meta data to help keep track on components state locally.
    component._meta = {
      index,
      groupId,
      groupIndex,
      blockId,
      disabled: {
        bySearch: component?._meta?.bySearch || false,
        byCollapsedSection: !blockId && nonBlocksCollapsed,
      },
    };

    index++;
    components.push(component);
  };

  groups.forEach((group, groupIndex) => {
    const onDifferentPage =
      (isGroupLinked(group) && group.integrations.figma.page_id !== page.id) ||
      (!isGroupLinked(group) && page.id !== DRAFTED_GROUPS_PAGE_ID);

    if (page.id !== ALL_PAGE_ID && onDifferentPage) {
      // This should never happen because technically we pass in only groups on this page.
      return;
    }

    const index = (component, blockId, nonBlocksCollapsed) =>
      indexComponent(component, group._id, groupIndex, blockId, nonBlocksCollapsed);

    group.blocks.forEach((block) => block.comps.forEach((component) => index(component, block._id)));

    /*
     * We want to index visible components before hidden components,
     * since hidden components are grouped together at the bottom of the
     * frame when viewing a project in Ditto.
     */
    const visible = [];
    const hidden = [];

    group.comps.forEach((comp) => (comp.is_hidden ? hidden.push(comp) : visible.push(comp)));

    visible.forEach((component) => index(component, null, group.nonblocks_collapsed));
    hidden.forEach((component) => index(component, null, group.nonblocks_collapsed));
  });

  return components;
};

export const setComponentMetadata = (component, val) => {
  return {
    ...component,
    _meta: {
      ...component._meta,
      ...(typeof val === "function" ? val(component._meta || {}) : val),
    },
  };
};

/*
 * Multi-select can be disabled at the component level;
 * if one or more properties are set to true on the `disabled`
 * object, then we should skip selecting this component.
 */
export const multiSelectEnabledAtComponentLevel = (component) => {
  const hasDisabledMetakey = Object.keys(component._meta.disabled).some((key) => component._meta.disabled[key]);

  return !hasDisabledMetakey;
};

/*
 * Multi-select can be disabled at the group level (with group
 * rules applying conditionally to certain components)
 */
export const multiSelectEnabledAtGroupLevel = (component, groupMeta) => {
  /*
   * Hidden components have their own conditions under which they should
   * be skipped when selecting ranges:
   * - if they're in a hidden section that is visually collapsed
   * - if they're in a group that has a non-base variant selected
   */
  if (component.is_hidden) {
    const { hiddenOpen, activeVariant } = groupMeta[component._meta.groupId] || {};

    const baseVariantSelected = !activeVariant || activeVariant.id === "__base__";

    return hiddenOpen && baseVariantSelected;
  }

  return true;
};

/*
 * Returns true if the given component should be selectable in
 * multi-select range selections
 */
export const multiSelectEnabledForComponent = (component, groupMeta) =>
  multiSelectEnabledAtComponentLevel(component) && multiSelectEnabledAtGroupLevel(component, groupMeta);

export const TextItem = {
  getGroupIndex: (comp) => (comp._meta ? comp._meta.groupIndex : comp.frameIndex),
  getGroupId: (comp) => (comp._meta ? comp._meta.groupId : comp.frameId),
  isInBlock: (comp) =>
    comp._meta && comp.blockIndex === undefined ? !!comp._meta.blockId : typeof comp.blockIndex === "number",
};

export const TextItems = {
  allSameFrame: (comps) => {
    const frames = comps.map((comp) => [TextItem.getGroupIndex(comp), comp]);
    const framesUnique = [...new Map(frames).values()];
    return framesUnique.length === 1;
  },
  includesWsComp: (comps) => comps.some((comp) => !!comp.ws_comp),
  includeBlock: (comps) => comps.some(TextItem.isInBlock),
  includeHidden: (comps) => comps.some((comp) => comp.is_hidden),
  includeVariant: (comps) => comps.some((comp) => comp.variants && comp.variants.length > 0),
  allWsComps: (comps) => comps.every((comp) => !!comp.ws_comp),
  allHidden: (comps) => comps.every((comp) => comp.is_hidden),
  allVisible: (comps) => comps.every((comp) => !comp.is_hidden),
  includeNonBaseVariant: (comps) => {
    const variants = comps.map((comp) => (comp.selectedVariant ? comp.selectedVariant.id : "__base__"));
    const variantsUnique = new Set(variants);

    const includesMultipleVariants = variantsUnique.size > 1;
    const includesBaseVariant = variantsUnique.has("__base__");

    return !includesBaseVariant || includesMultipleVariants;
  },
};

export const computeComponentData = (comps) =>
  Object.keys(TextItems).reduce((acc, key) => ({ ...acc, [key]: TextItems[key](comps) }), {});
