import Tippy, { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

type TooltipProps = {
  children: React.ReactElement;
  theme?: "dark" | "light";
} & TippyProps;

const Tooltip = (props: TooltipProps) => {
  const { children, className, ...rest } = props;

  return (
    <Tippy
      className={classNames(style.tippyBox, className, {
        [style.light]: props.theme === "light" || !props.theme,
        [style.dark]: props.theme === "dark",
      })}
      {...rest}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
