export function chunkArray(array, size) {
  const result = [];
  let current = 0;
  while (current < array.length) {
    result.push(array.slice(current, current + size));
    current += size;
  }
  return result;
}

export function insertAt(array, index, ...elementsArray) {
  array.splice(index, 0, ...elementsArray);
}

export function removeFrom(array, index, numItems) {
  array.splice(index, numItems);
}

export function isSuperset(array, target) {
  return array.every((item) => target.includes(item));
}

// returns items in array that are missing from target
export const getMissingElements = (array, target) => {
  const targetHash = {};
  const missingElements = [];
  for (const item of target) {
    targetHash[item] = true;
  }
  for (const elem of array) {
    if (!targetHash[elem]) {
      missingElements.push(elem);
    }
  }
  return missingElements;
};
