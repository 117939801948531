import ChevronRight from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import FigmaIcon from "../../../../../../components/FigmaLogoIcon";
import { PageSelected } from "../../../../state/usePageState";

import { useIsShiftPressed } from "@/hooks/useIsShiftPressed";
import { SelectedGroupsByPage } from "../SelectFramesModal";
import style from "./style.module.css";

interface PageItemProps {
  groups: {
    id: string;
    name: string;
    isSelected: boolean;
  }[];
}
const PageItem = ({ groups }: PageItemProps) => {
  let selectedFrameCount = groups.filter((frame) => frame.isSelected).length;
  return (
    <div className={style.pageInfo}>
      {selectedFrameCount > 0 && <div className={style.pageFrameCount}>{selectedFrameCount}</div>}
      <ChevronRight className={style.icon} />
    </div>
  );
};

interface PageFrameSelectionProps {
  pages: PageSelected[];
  previouslySelectedFrames: string[];
  selectedGroupsByPage: SelectedGroupsByPage;
  groupsByPage: {
    [pageId: string]: {
      id: string;
      name: string;
      isSelected: boolean;
    }[];
  };
  handleSelectFrame: (args: { pageId: string; frameId: string; isShiftClick: boolean }) => void;
  isSaving: boolean;
  isLoading: boolean;
  handleToggleAllSelectedForPage: (pageId: string) => void;
}
const PageFrameSelection = ({
  pages,
  previouslySelectedFrames,
  groupsByPage,
  handleSelectFrame,
  isSaving,
  isLoading,
  handleToggleAllSelectedForPage,
  selectedGroupsByPage,
}: PageFrameSelectionProps) => {
  const [selectedPage, setSelectedPage] = useState<PageSelected | null>(null);
  const [query, setQuery] = useState<string>("");
  const isShiftPressed = useIsShiftPressed();

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const doesPageContainSelectedFrames = (pageId) => {
    return (
      selectedPage &&
      pageId !== selectedPage.id &&
      groupsByPage[pageId] &&
      groupsByPage[pageId].filter((frame) => frame.isSelected).length > 0
    );
  };

  useEffect(() => {
    if (!selectedPage && pages.length) {
      setSelectedPage(pages[0]);
    }
  }, [pages]);

  return (
    <div className={style.wrapper}>
      <div className={style.pagesSection}>
        <div className={style.pageTitle}>Pages</div>
        {isLoading && <div className={style.loadingPageMessage}>Loading pages...</div>}
        {!isLoading && (
          <>
            <div className={style.pageList}>
              {pages.map((page) => (
                <div
                  key={page.id}
                  className={classnames({
                    [style.pageItem]: true,
                    [style.active]: selectedPage && page.id === selectedPage.id,
                    [style.pageFramesSelected]: doesPageContainSelectedFrames(page.id),
                  })}
                  onClick={() => setSelectedPage(page)}
                >
                  <div className={style.pageName}>
                    <FigmaIcon className={style.figmaIcon} />
                    {page.name}
                  </div>
                  <PageItem groups={groupsByPage[page.id]} />
                </div>
              ))}
            </div>
            <div className={classnames([style.warningMessage, style.draftLinkingMessage])}>
              Heads up! To link Figma frames to drafted frames, you’ll have to use our{" "}
              <a
                href="https://www.figma.com/community/plugin/798826066406007173/%E2%9C%8D%EF%B8%8F-Ditto-%7C-collaborate-on-copy"
                target="_blank"
              >
                {" "}
                Figma plugin
              </a>
              .
            </div>
          </>
        )}
      </div>
      {!isLoading && !selectedPage ? (
        <div className={style.importMessage}>
          <div className={style.label}>
            Select a page to begin choosing up to 15 frames you want to work on in Ditto.
          </div>
          <div className={style.message}>You can always change this or add new frames later! </div>
        </div>
      ) : (
        <div className={style.framesSection}>
          {isLoading && <div className={style.loadingFramesMessage}>Loading frames...</div>}
          {!isLoading && (
            <>
              <div className={style.framesHeader}>
                <div className={style.pageNameRow}>
                  {selectedPage && <div className={style.frameName}>{selectedPage.name}</div>}
                  {selectedPage && (
                    <div
                      className={style.toggleSelectAllButton}
                      onClick={() => {
                        handleToggleAllSelectedForPage(selectedPage.id);
                      }}
                    >
                      {selectedGroupsByPage[selectedPage.id]
                        .filter((frame) => !previouslySelectedFrames.includes(frame.id))
                        .some((frame) => frame.isSelected)
                        ? "Unselect All"
                        : "Select All"}
                    </div>
                  )}
                </div>
                <div className={style.searchBox}>
                  <SearchIcon className={style.icon} />
                  <input
                    autoFocus
                    className={classnames(style.input)}
                    placeholder={"Search frames"}
                    value={query}
                    onChange={handleQueryChange}
                  />
                </div>
              </div>
              <div className={style.frameList}>
                {selectedPage &&
                  groupsByPage[selectedPage.id] &&
                  groupsByPage[selectedPage.id]
                    .filter((frame) => frame.name.toLowerCase().includes(query))
                    .map((frame) => (
                      <div
                        key={frame.id}
                        className={classnames({
                          [style.frameItem]: true,
                          [style.isSelected]: frame.isSelected,
                          [style.previouslySelected]: frame.isSelected && previouslySelectedFrames.includes(frame.id),
                          [style.isDisabled]: isSaving,
                        })}
                        onClick={() => {
                          if (!isSaving && !previouslySelectedFrames.includes(frame.id)) {
                            handleSelectFrame({
                              pageId: selectedPage.id,
                              frameId: frame.id,
                              isShiftClick: isShiftPressed,
                            });
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled={isSaving || previouslySelectedFrames.includes(frame.id)}
                          checked={frame.isSelected}
                          onChange={() => {}}
                        />
                        <span className={style.frameNameText}>{frame.name}</span>
                      </div>
                    ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default PageFrameSelection;
