import * as httpComments from "@/http/commentsTyped";
import * as httpDittoProject from "@/http/dittoProject";
import asyncMutableDerivedAtom from "@shared/frontend/stores/asyncMutableDerivedAtom";
import batchedAsyncAtomFamily from "@shared/frontend/stores/batchedAsyncAtomFamily";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { REFRESH_SILENTLY } from "@shared/frontend/stores/symbols";
import { ICommentThread } from "@shared/types/CommentThread";
import logger from "@shared/utils/logger";
import { atom } from "jotai";
import { atomFamily, splitAtom, unwrap } from "jotai/utils";
import { projectIdAtom, projectNameAtom, projectStoreAtom } from "./Project";

/**
 * A family atom that maps comment_thread_id to ICommentThread.
 */
export const { familyAtom: commentFamilyAtom, resetAtom: resetCommentFamilyAtomActionAtom } =
  batchedAsyncAtomFamily<ICommentThread>({
    storeAtom: projectStoreAtom,
    asyncFetchRequest: async (get, ids) => {
      const [request] = httpComments.getCommentsByCommentIds({
        commentIds: ids,
        projectId: get(projectIdAtom)!,
        type: "standard",
      });
      const response = await request;

      return response.data;
    },
    getId: (item) => item._id,
    debugPrefix: "Comment",
  });

/**
 * Stores the list of comment_thread_ids to render the Project Comments panel. This is a paginated atom.
 */
const {
  valueAtom: _projectCommentsAtom,
  fetchNextPageActionAtom: _fetchNextCommentsPageActionAtom,
  hasMoreAtom: _hasMoreCommentsAtom,
  loadingAtom: _commentsLoadingAtom,
} = paginatedAtom<string, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      const [request] = httpComments.getCommentsByProjectId({ projectId, page, pageSize, type: "standard" });
      const response = await request;

      return response.data;
    } catch (error) {
      logger.error("Failed to fetch project comments", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Comments",
});

export const projectCommentsSplitAtom = splitAtom(unwrap(_projectCommentsAtom, (prev) => prev ?? []));

export const projectCommentsAtom = _projectCommentsAtom;
export const fetchNextCommentsPageActionAtom = _fetchNextCommentsPageActionAtom;
export const hasMoreCommentsAtom = _hasMoreCommentsAtom;
export const commentsLoadingAtom = _commentsLoadingAtom;

/**
 * This atom represents the most recent comment for a given text item.
 * It is an atomFamily that maps textItemId to comment_thread_id.
 */
export const mostRecentTextItemCommentFamilyAtom = atomFamily((textItemId: string | null) => {
  const { valueAtom, refreshAtom } = asyncMutableDerivedAtom<string | null>({
    loadData: async (get) => {
      if (!textItemId) return null;
      const projectId = get(projectIdAtom);
      if (!projectId) return null;

      const [request] = httpDittoProject.getMostRecentCommentThreadId({ projectId, textItemId, type: "standard" });
      const result = await request;

      return result.data?._id ?? null;
    },
  });

  // This allows us to call set(atomFamily(id), REFRESH_SILENTLY) to force a re-fetch
  const familyAtom = atom(
    (get) => get(valueAtom),
    (get, set, newValue: string | null | typeof REFRESH_SILENTLY) => {
      if (newValue === REFRESH_SILENTLY) {
        set(refreshAtom);
      } else {
        set(valueAtom, newValue);
      }
    }
  );

  familyAtom.debugLabel = `Most Recent Comment Family ${textItemId}`;

  return familyAtom;
});

// MARK: Actions

/**
 * An action to add or update a comment in the commentFamilyAtom with the provided comment data.
 */
export const updateCommentActionAtom = atom(null, (_get, set, comment: ICommentThread) => {
  set(commentFamilyAtom(comment._id), comment);
});

/**
 * An action to resolve or unresolve a comment thread.
 * This will also trigger a refresh of the most recent comment for the text item, since it may have changed as a result of this action.
 */
export const toggleCommentResolvedActionAtom = atom(null, async (get, set, existingComment: ICommentThread) => {
  const { _id: commentThreadId, is_resolved, comp_id: textItemId } = existingComment;
  const isResolving = !is_resolved;

  const [request] = httpComments.resolveThread({
    commentThreadId: commentThreadId,
    is_resolved: isResolving,
    projectName: get(projectNameAtom),
    from: "web_app",
  });

  const response = await request;
  const updatedCommentThread = response.data;
  set(mostRecentTextItemCommentFamilyAtom(textItemId), REFRESH_SILENTLY);
  set(updateCommentActionAtom, updatedCommentThread);
});

/**
 * An action to post a new comment (start a new thread)
 */
export const postCommentActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      textItemId,
      commentText,
      mentionedUserIds,
    }: { textItemId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    const projectId = get(projectIdAtom);
    if (!projectId) return; // Should never happen

    const [request] = httpComments.createCommentThread({
      projectId,
      projectName: get(projectNameAtom),
      commentText,
      mentionedUserIds,
      textItemId,
      from: "web_app",
    });

    const response = await request;
    const newCommentThread = response.data;

    set(mostRecentTextItemCommentFamilyAtom(newCommentThread.comp_id), newCommentThread._id);
    set(updateCommentActionAtom, newCommentThread);
  }
);

/**
 *  An action for posting a reply to a comment thread.
 */
export const postCommentReplyActionAtom = atom(
  null,
  async (
    get,
    set,
    {
      commentThreadId,
      commentText,
      mentionedUserIds,
    }: { commentThreadId: string; commentText: string; mentionedUserIds: string[] }
  ) => {
    const [request] = httpComments.postToThread({
      commentThreadId,
      text: commentText,
      projectName: get(projectNameAtom),
      mentionedUserIds,
      from: "web_app",
    });

    const response = await request;
    const newReplyCommentThread = response.data;

    set(commentFamilyAtom(commentThreadId), newReplyCommentThread);
  }
);
