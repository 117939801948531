import React, { createContext, useContext, useEffect, useState } from "react";
import { ApiTokenResponse } from "../../../../shared/types/http/workspace";
import http, { API } from "../../../http";

interface LokaliseConnectionContextType {
  newLokaliseToken: string | null;
  currentLokaliseConnection: ApiTokenResponse | null;
  lokaliseTokenUsed: boolean;
  createNewLokaliseKey: () => Promise<void>;
  removeLokaliseKey: () => Promise<void>;
  checkForExistingLokaliseToken: () => void;
}

const LokaliseConnectionContext = createContext<LokaliseConnectionContextType | undefined>(undefined);

export const useLokaliseConnection = () => {
  const context = useContext(LokaliseConnectionContext);
  if (!context) {
    throw new Error("useLokaliseConnection must be used within a LokaliseConnectionProvider");
  }
  return context;
};

export function LokaliseConnectionProvider({ children }: { children: React.ReactNode }) {
  const [newLokaliseToken, setNewLokaliseToken] = useState<string | null>(null);
  const [currentLokaliseConnection, setCurrentLokaliseConnection] = useState<ApiTokenResponse | null>(null);

  const lokaliseTokenUsed = Boolean(currentLokaliseConnection && currentLokaliseConnection.lastUsedAt);

  const createNewLokaliseKey = async () => {
    const { url, body } = API.workspace.post.apiToken;
    try {
      const response = await http.post(url, body({ name: "lokalise", connection: "lokalise" }));
      setNewLokaliseToken(response.data.token);
      setCurrentLokaliseConnection(response.data);
    } catch (error) {
      console.error("Unable to create Lokalise key." + error);
    }
  };

  const removeLokaliseKey = async () => {
    if (!currentLokaliseConnection) {
      console.error("No Lokalise key to remove.");
      return;
    }

    const { url } = API.workspace.delete.apiToken;
    await http.delete(url(currentLokaliseConnection._id));
    setNewLokaliseToken(null);
    setCurrentLokaliseConnection(null);
  };

  const checkForExistingLokaliseToken = () => {
    const { url } = API.workspace.get.connectionApiTokens;
    http
      .get(url)
      .then((response) => {
        const lokaliseKeys: ApiTokenResponse[] = response.data.filter(function filterForLokaliseToken(t) {
          return t.connection === "lokalise";
        });
        if (lokaliseKeys.length === 0) {
          setCurrentLokaliseConnection(null);
          return;
        }
        setCurrentLokaliseConnection(lokaliseKeys[0]);
      })
      .catch((error) => {
        console.error("Something went wrong, try again later." + error);
      });
  };

  useEffect(checkForExistingLokaliseToken, []);

  const value = {
    newLokaliseToken,
    currentLokaliseConnection,
    lokaliseTokenUsed,
    createNewLokaliseKey,
    removeLokaliseKey,
    checkForExistingLokaliseToken,
  };

  return <LokaliseConnectionContext.Provider value={value}>{children}</LokaliseConnectionContext.Provider>;
}
