import classNames from "classnames";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";

import style from "./style.module.css";

export interface ContentProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  footerText?: React.ReactNode;
}

export const Content = (props: ContentProps) => {
  const { title, subtitle, footerText } = props;

  return (
    <>
      <h1 className={style.title}>{title}</h1>
      <h2 className={style.subtitle}>{subtitle}</h2>
      <p className={style.footerText}>{footerText}</p>
    </>
  );
};

export interface ContainerProps {
  children: React.ReactNode;
  title?: string;
  error?: string | null;
  classNames?: {
    container?: string;
    content?: string;
    body?: string;
  };
  floatingMessage?: React.ReactNode;
}

export const Container = (props: ContainerProps) => {
  const { title, children, error, floatingMessage } = props;

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  return (
    <div className={classNames([style.container, props.classNames?.container])}>
      <div ref={setContainerElement} className={style.modalWrapper}>
        {error && containerElement && <div className={style.error}>{error}</div>}
        {containerElement && (
          <BootstrapModal
            show
            className={style.modal}
            dialogClassName={style.dialog}
            backdropClassName={style.backdrop}
            contentClassName={props.classNames?.content}
            onHide={() => null} // can't be hidden
            container={containerElement}
            centered
          >
            {title && (
              <BootstrapModal.Header className={style.header}>
                <BootstrapModal.Title className={style.title}>{title}</BootstrapModal.Title>
              </BootstrapModal.Header>
            )}
            <BootstrapModal.Body className={props.classNames?.body || style.body}>
              {children}
              {floatingMessage && <div className={style.floatingMessage}>{floatingMessage}</div>}
            </BootstrapModal.Body>
          </BootstrapModal>
        )}
      </div>
    </div>
  );
};

export default { Content, Container };
