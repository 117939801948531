import { useWorkspace } from "@/store/workspaceContext";
import classnames from "classnames";
import { useDittoComponent } from "ditto-react";
import UpsellBanner from "../../upsellBanner";
import style from "./style.module.css";

interface ResourceRowProps {
  resourceDittoComponentId: string;
  value: number;
  divider?: boolean;
  limit?: number;
  onUpgradePlanClicked?: () => void;
}

export default function ResourceRow(props: ResourceRowProps) {
  const { workspaceInfo } = useWorkspace();
  const limitReached = props.limit && props.value >= props.limit;
  const showDivider = props.divider !== false;

  const resourceName = useDittoComponent({
    componentId: props.resourceDittoComponentId,
  });

  const upsellMessage = useDittoComponent({
    componentId: "more-components-upsell",
    variables: { resourceName: resourceName?.toLowerCase() || "" },
  });

  return (
    <>
      <tr
        className={classnames({
          [style.divider]: showDivider && (!limitReached || workspaceInfo.plan === "growth"),
        })}
      >
        <td className={style.title}>{resourceName}</td>
        {!props.limit && (
          <td className={style.value} data-testid={resourceName + "-value"}>
            {props.value}
          </td>
        )}
        {props.limit && (
          <td className={style.value} data-testid={resourceName + "-value"}>
            <span className={classnames({ [style.over]: limitReached })}>{props.value} </span>
            <span className={style.limit}>/ {props.limit} Available</span>
          </td>
        )}
      </tr>
      {limitReached && workspaceInfo.plan !== "growth" && (
        <tr>
          <td colSpan={2}>
            <div className={classnames([style.upsellContainer, style.divider])}>
              <UpsellBanner message={upsellMessage || ""} onClick={props.onUpgradePlanClicked} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
