import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import style from "./style.module.css";

export type RichTextInfoPopupMarkType = "bold" | "underline" | "italicize";

interface RichTextInfoPopupProps {
  onClose: () => void;
  onShowMe: () => void;
  markType: RichTextInfoPopupMarkType;
}

function RichTextInfoPopup(props: RichTextInfoPopupProps) {
  return (
    <div className={style.container}>
      <p className={style.title}>Trying to {props.markType} text?</p>
      <p className={style.content}>You can enable rich text in the top right menu of your project.</p>
      <button onClick={props.onShowMe} className={style.showMeBtn}>
        Show me
      </button>
      <CloseIcon onClick={props.onClose} className={style.closeIcon} />
    </div>
  );
}

export default RichTextInfoPopup;
