// This context allows children to check if a user has a given permission, as
// determined by the Permission Groups to which they belong.
import {
  IFResourceType,
  IGeneralPermissionsActionEnum,
  IResourcePermissionActionEnum,
  IResourceType,
  ZResourceType,
} from "@shared/types/PermissionGroups";
import { IUserPermissionGroups } from "@shared/types/User";
import React, { ReactNode, createContext, useContext } from "react";

type BillingRole = "admin" | "editor" | "commenter";

interface Resource {
  resourceId?: string | null;
  resourceType: IResourceType;
}

type ResourceScope = { [key in IResourceType]?: string | undefined | null };

interface UserPermissionContext {
  userHasPermission: (permission: IGeneralPermissionsActionEnum) => boolean;
  userHasResourcePermission: (permission: IResourcePermissionActionEnum, resourceId?: string) => boolean;
  userHasSomeResourceAccess: (resourceType: IFResourceType, access?: string) => boolean;
  userHasBillingRole: (role: BillingRole) => boolean;
  resourceScope: ResourceScope;
}

export const UserPermissionContext = createContext<UserPermissionContext>({
  userHasPermission: () => false,
  userHasResourcePermission: () => false,
  userHasSomeResourceAccess: () => false,
  userHasBillingRole: () => false,
  resourceScope: {},
});

interface BaseProviderProps {
  children: ReactNode;
}

interface ProviderPropsWithResourceId extends BaseProviderProps, Resource {}

export type PermissionProviderProps = ProviderPropsWithResourceId | BaseProviderProps;

export const UserPermissionProvider = (
  props: PermissionProviderProps & { userPermissions?: IUserPermissionGroups }
) => {
  const { children, userPermissions } = props;

  // We can nest UserPermissionProviders to define access to multiple types of
  // resources at once, e.g. a component folder and a project folder.
  // The ResourceScope can hold one resource of each type.
  let resourceScope: ResourceScope = {};
  let { resourceScope: parentResources } = useContext(UserPermissionContext);
  resourceScope = parentResources;

  if ("resourceType" in props) {
    resourceScope[props.resourceType] = props.resourceId;
  }

  // Check if a user has a given permission (from the list of general permissions)
  const userHasPermission = (permission: IGeneralPermissionsActionEnum) => {
    return !!userPermissions?.general.find((p) => p.action === permission);
  };

  const userHasResourcePermission = (permission: IResourcePermissionActionEnum, resourceId?: string) => {
    // First, check if the user has the permission through a general permission
    // E.g. if we're checking the "project_folder:edit" permission, and the user has
    // the "project_folder:edit_all" general permission, they can edit.
    if (userPermissions?.general.find((p) => p.action === `${permission}_all`)) {
      return true;
    }

    // Make sure we're using a valid resource type
    const permissionResourceType = permission.split(":")[0];
    const resourceType = ZResourceType.safeParse(permissionResourceType);
    if (!resourceType.success) {
      console.error(
        "invalid resource type used: ",
        permissionResourceType
        // this is commented out because the plugin tsconfig can't handle it
        // resourceType.error
      );
      return false;
    }

    // Get the currently-stored resourceId for the resource type
    let scopedResourceId = resourceScope[resourceType.data];
    if (!scopedResourceId) scopedResourceId = `${resourceType.data}_no_id`;

    // If we pass a specific resourceId, use that instead
    if (resourceId) {
      scopedResourceId = resourceId;
    }

    // Check if the user has the permission through a resource permission
    const resourcePermission = userPermissions?.resources[scopedResourceId];
    const hasPermission = resourcePermission?.access.find((p) => p.action === permission);

    return !!hasPermission;
  };

  const userHasSomeResourceAccess = (resourceType: IFResourceType, access?: string) => {
    // First, check if the user has the permission through a general permission
    // E.g. if we're checking the "project_folder" permission, and the user has
    // the "project_folder:edit_all" general permission, they have some access.
    //
    // If we're checking for a specific access, e.g. "edit", we need to add it
    // to the check string; otherwise, we'll match any access.
    if (userPermissions?.general.find((p) => p.action.startsWith(`${resourceType}:${access || ""}`))) {
      return true;
    }

    // Next check all resource permissions
    const hasPermission = Object.values(userPermissions?.resources || {}).some((resource) => {
      return resource.access.some((p) => p.action.startsWith(`${resourceType}:${access || ""}`));
    });

    return !!hasPermission;
  };

  const userHasBillingRole = (role: BillingRole) => {
    let billingRole = "commenter" as BillingRole;

    Object.entries(userPermissions?.resources || []).forEach(([resourceId, permissions]) => {
      if (permissions.access.some((p) => p.action.includes("edit"))) {
        billingRole = "editor";
      }
    });

    userPermissions?.general.forEach((permission) => {
      if (permission.permissionGroups.includes("admin")) {
        billingRole = "admin";
      } else if (permission.permissionGroups.includes("editor") && billingRole !== "admin") {
        billingRole = "editor";
      }
    });
    return billingRole === "admin" || billingRole === role;
  };

  return (
    <UserPermissionContext.Provider
      value={{
        userHasPermission,
        userHasResourcePermission,
        userHasSomeResourceAccess,
        userHasBillingRole,
        resourceScope,
      }}
    >
      {children}
    </UserPermissionContext.Provider>
  );
};

export const userHasPermission = (permission: IGeneralPermissionsActionEnum) => {
  const { userHasPermission } = useContext(UserPermissionContext);
  return userHasPermission(permission);
};

export const userHasResourcePermission = (permission: IResourcePermissionActionEnum, resourceId?: string) => {
  const { userHasResourcePermission } = useContext(UserPermissionContext);
  return userHasResourcePermission(permission, resourceId);
};

export const userHasSomeResourceAccess = (resourceType: IFResourceType, access?: string) => {
  const { userHasSomeResourceAccess } = useContext(UserPermissionContext);
  return userHasSomeResourceAccess(resourceType, access);
};

export const userHasBillingRole = (role: BillingRole) => {
  const { userHasBillingRole } = useContext(UserPermissionContext);
  return userHasBillingRole(role);
};

interface WithResourcePermissionCheckProps {
  children: ReactNode;
  permission: IResourcePermissionActionEnum;
  NoPermissionComponent: (props: any) => JSX.Element;
  override?: boolean;
}

// This component will render its children if the user has the given resource permission,
// and will render the NoPermissionComponent if they don't.
//
// This component allows us to check for a permission *at the same level* as the
// UserPermissionProvider, without having to nest another UserPermissionProvider.
//
// Motivation:
//    Sometimes, we need to check against a permission in a component that is already
//    responsible for mounting a UserPermissionProvider.
//
//    A good example is in the <ProjectPage> component: We don't want to render
//    the project if the user doesn't have access, but we mount the
//    UserPermissionProvider in the <ProjectPage> component itself.
export const RenderIfHasResourcePermission = (props: WithResourcePermissionCheckProps) => {
  const { userHasResourcePermission } = useContext(UserPermissionContext);

  return userHasResourcePermission(props.permission) || props.override ? (
    <>{props.children}</>
  ) : (
    <props.NoPermissionComponent />
  );
};

interface WithPermissionCheckProps {
  children: ReactNode;
  permission: IGeneralPermissionsActionEnum;
  NoPermissionComponent: (props: any) => JSX.Element;
  override?: boolean;
}

// This component will render its children if the user has the given permission,
// and will render the NoPermissionComponent if they don't.
//
// This component allows us to check for a permission *at the same level* as the
// UserPermissionProvider, without having to nest another UserPermissionProvider.
//
// Motivation:
//    Sometimes, we need to check against a permission in a component that is already
//    responsible for mounting a UserPermissionProvider.
//
//    A good example is in the <ProjectPage> component: We don't want to render
//    the project if the user doesn't have access, but we mount the
//    UserPermissionProvider in the <ProjectPage> component itself.
export const RenderIfHasPermission = (props: WithPermissionCheckProps) => {
  const { userHasPermission } = useContext(UserPermissionContext);

  return userHasPermission(props.permission) || props.override ? (
    <>{props.children}</>
  ) : (
    <props.NoPermissionComponent />
  );
};
