// from https://github.com/oxyno-zeta/react-editable-json-tree/blob/master/src/utils/styles.js
export const defaultJsonTreeStyles = {
  object: {
    minus: {
      color: "red",
    },
    plus: {
      color: "green",
    },
    collapsed: {
      color: "grey",
    },
    delimiter: {},
    ul: {
      padding: "0px",
      margin: "0 0 0 25px",
      listStyle: "none",
    },
    name: {
      color: "#2287CD",
    },
    addForm: {},
  },
  array: {
    minus: {
      color: "red",
    },
    plus: {
      color: "green",
    },
    collapsed: {
      color: "grey",
    },
    delimiter: {},
    ul: {
      padding: "0px",
      margin: "0 0 0 25px",
      listStyle: "none",
    },
    name: {
      color: "#2287CD",
    },
    addForm: {},
  },
  value: {
    minus: {
      color: "red",
    },
    editForm: {},
    value: {
      color: "#7bba3d",
    },
    li: {
      minHeight: "22px",
      lineHeight: "22px",
      outline: "0px",
    },
    name: {
      color: "#2287CD",
    },
  },
};
