import { useState } from "react";

interface Props {
  pageItemLimit: number;
}
const usePagination = ({ pageItemLimit }: Props) => {
  const [pageNumber, setPageNumber] = useState<number | undefined>(undefined);
  const [totalItems, setTotalItems] = useState<number | undefined>(undefined);
  const totalPages = typeof totalItems === "number" ? Math.ceil((totalItems || 1) / pageItemLimit) : 0;

  const getNewPage = (type: "previous" | "next") => {
    if (type === "previous" && (pageNumber || 0) > 0) {
      return pageNumber! - 1;
    }

    if (type === "next" && (pageNumber || 0) < totalPages - 1) {
      return pageNumber! + 1;
    }

    return pageNumber;
  };

  const paginationEnabled = (totalItems || 0) > pageItemLimit;

  return {
    totalItems,
    setTotalItems,
    totalPages,
    pageNumber,
    setPageNumber,
    paginationEnabled,
    getNewPage,
  };
};

export { usePagination };
