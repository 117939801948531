import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import http from "../../http";
import ButtonPrimary from "../button/buttonprimary";

import { useUnauthenticatedAuth } from "@/store/UnauthenticatedAuthContext";
import Spinner from "react-bootstrap/Spinner";
import style from "./style.module.css";

export default function TrustedSignIn() {
  const [email, setEmail] = useState("");
  const userData = useTrustedUserData();
  const { loginWithRedirect, isAuthenticated } = useUnauthenticatedAuth();
  const queryParams = new URLSearchParams(window.location.search);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const login = async (email: string) => {
    const plan = queryParams.get("plan") || "free";
    const isTest = queryParams.get("test") === "true";
    const devTools = queryParams.get("devTools") === "true";

    await loginWithRedirect({ email, plan, isTest, devTools });

    window.location.reload();
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className={style.container}>
      <h1 className={style.title}>Trusted Authentication</h1>
      <p className={style.subtitle}>Enter a new or existing email address below:</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          login(email);
        }}
      >
        <div className={style.inputWrapper}>
          <input
            type="text"
            value={email}
            onChange={onEmailChange}
            className={style.input}
            data-testid="trusted-auth-email-input"
            ref={inputRef}
          />
          <ButtonPrimary data-testid="trusted-submit-button" text="Submit" />
        </div>
      </form>
      <div className={style.trustedUserDataContainer}>
        <p className={style.subtitle}>Or select from a list of existing trusted users:</p>
        {userData.loading && (
          <div className={style.trustedUserDataLoading}>
            <Spinner animation="border" variant="secondary" /> <span>Loading trusted user data..</span>
          </div>
        )}
        {!userData.loading && (
          <div className={style.trustedUserDataList}>
            {userData.users.map((datum, i) => (
              <div key={`${datum.email}-${i}`} className={style.trustedUserDatum} onClick={() => login(datum.email)}>
                <span>{datum.email}</span>
                <div>
                  <span>{datum.componentCount} components, </span>
                  <span>
                    {datum.projectCount} project{datum.projectCount === 1 ? "" : "s"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

interface ITrustedUserDatum {
  email: string;
  componentCount: number;
  projectCount: number;
}

function useTrustedUserData() {
  const [data, setData] = useState<{ loading: true } | { loading: false; users: ITrustedUserDatum[] }>({
    loading: true,
  });

  useEffect(() => {
    http.get<ITrustedUserDatum[]>("/trusted/user-data").then(({ data: users }) =>
      setData({
        loading: false,
        users,
      })
    );
  }, []);

  return data;
}
