import React from "react";
import Skeleton from "react-loading-skeleton";
import style from "./style.module.css";

interface ISkeletonPageLoaderProps {
  format: "page" | "nav" | "recentsCard";
}

const SkeletonLoader = (props: ISkeletonPageLoaderProps) => {
  if (props.format === "page") {
    return (
      <div className={style.loadingWrapper}>
        <div>
          <Skeleton width={`100%`} height={150} />
        </div>
        {Array(10)
          .fill(0)
          .map((_item, index) => (
            <div key={index}>
              <Skeleton width={"100%"} height={80} />
            </div>
          ))}
      </div>
    );
  } else if (props.format === "nav") {
    return (
      <div className={style.loadingWrapperNav}>
        {Array(40)
          .fill(0)
          .map((_item, index) => (
            <div key={index}>
              <Skeleton width={`100%`} height={"21px"} />
            </div>
          ))}
      </div>
    );
  } else if (props.format === "recentsCard") {
    return (
      <div className={style.recentsCard}>
        <Skeleton className={style.folderName} />
        <div className={style.card}>
          <Skeleton className={style.top} />
          <div className={style.bottom}>
            <Skeleton circle={true} className={style.progressCircle} />
            <Skeleton width={40} className={style.progress} />
            <Skeleton circle={true} className={style.dot} />
            <Skeleton circle={true} className={style.progressCircle} />
            <Skeleton width={40} className={style.progress} />
          </div>
        </div>
        <div className={style.editedByWrapper}>
          <Skeleton className={style.editedBy} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={style.loadingWrapper}>
        <div>
          <Skeleton width={`100%`} height={150} />
        </div>
        <div>
          <Skeleton width={"100%"} height={80} />
        </div>
        <div>
          <Skeleton width={"100%"} height={80} />
        </div>
        <div>
          <Skeleton width={"100%"} height={80} />
        </div>
        <div>
          <Skeleton width={"100%"} height={80} />
        </div>
        <div>
          <Skeleton width={"100%"} height={80} />
        </div>
      </div>
    );
  }
};

export default SkeletonLoader;
