import { useEffect, useState } from "react";

export function useIsShiftPressed() {
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  useEffect(function trackShiftButtonPressed() {
    function handleShiftPressed() {
      setIsShiftPressed(true);
    }

    function handleShiftReleased() {
      setIsShiftPressed(false);
    }

    window.addEventListener("keydown", handleShiftPressed);
    window.addEventListener("keyup", handleShiftReleased);

    // Remove listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleShiftPressed);
      window.removeEventListener("keyup", handleShiftReleased);
    };
  }, []);

  return isShiftPressed;
}
