import { defaults, last } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { DragMoveEvent } from "react-aria";

type AutoScrollOptions = {
  scrollThreshold?: number;
  maxSpeed?: number;
  minSpeed?: number;
};

/**
 * Compose multiple callback functions into a single callback function.
 * This is useful for composing event handlers from multiple event sources.
 */
export function composeCallbacks<T extends Record<string, (...args: any[]) => void>>(callbackSets: T[]): T {
  const result: Record<string, (...args: any[]) => void> = {};

  // Collect all callbacks for each key
  const callbackMap: Record<string, ((...args: any[]) => void)[]> = {};

  for (const callbackSet of callbackSets) {
    for (const key in callbackSet) {
      if (!callbackMap[key]) {
        callbackMap[key] = [];
      }
      callbackMap[key].push(callbackSet[key]);
    }
  }

  // Create a composed callback for each key
  for (const key in callbackMap) {
    const callbacks = callbackMap[key];

    result[key] = function (...args: any[]) {
      for (const cb of callbacks) {
        cb(...args);
      }
    };
  }

  return result as T;
}

/**
 * Given a target container to scroll, return the necessary props to handle auto-scrolling
 * based on the drag movement.
 */
function __useAutoScroll(scrollContainerElement: HTMLElement | null, _options: AutoScrollOptions = {}) {
  const scrollDirectionRef = useRef(0);
  const scrollAnimationFrameIdRef = useRef<number | null>(null);
  const pointerYRef = useRef(0);
  const pointerXRef = useRef(0);
  const isScrollingRef = useRef(false);

  const options = defaults({}, _options, { scrollThreshold: 100, maxSpeed: 20, minSpeed: 2 });

  const calculateScrollSpeed = useCallback(
    function calculateScrollSpeed() {
      if (!scrollContainerElement) return 0;

      const rect = scrollContainerElement.getBoundingClientRect();
      let distanceToEdge: number;

      if (scrollDirectionRef.current === -1) {
        distanceToEdge = pointerYRef.current - rect.top;
      } else if (scrollDirectionRef.current === 1) {
        distanceToEdge = rect.bottom - pointerYRef.current;
      } else {
        return 0;
      }

      // Calculate speed based on linterp to edge
      let speed = ((options.scrollThreshold - distanceToEdge) / options.scrollThreshold) * options.maxSpeed;
      speed = Math.max(options.minSpeed, Math.min(options.maxSpeed, speed)); // Clamp speed

      return speed;
    },
    [options.maxSpeed, options.minSpeed, options.scrollThreshold, scrollContainerElement]
  );

  const scrollContainer = useCallback(
    function scrollContainer() {
      if (scrollDirectionRef.current !== 0 && isScrollingRef.current && scrollContainerElement) {
        const scrollAmount = scrollDirectionRef.current * calculateScrollSpeed();
        scrollContainerElement.scrollTop += scrollAmount;

        const maxScrollTop = scrollContainerElement.scrollHeight - scrollContainerElement.clientHeight;
        const rect = scrollContainerElement.getBoundingClientRect();

        // Stop scrolling if we've reached the limits
        if (
          (scrollDirectionRef.current === -1 && scrollContainerElement.scrollTop <= 0) ||
          (scrollDirectionRef.current === 1 && scrollContainerElement.scrollTop >= maxScrollTop)
        ) {
          isScrollingRef.current = false;
          scrollDirectionRef.current = 0;
        } else if (pointerXRef.current < rect.left || pointerXRef.current > rect.right) {
          // If the pointer is not in the bounding box, stop scrolling
          isScrollingRef.current = false;
          scrollDirectionRef.current = 0;
        } else {
          // Continue scrolling
          scrollAnimationFrameIdRef.current = requestAnimationFrame(scrollContainer);
        }
      } else {
        isScrollingRef.current = false;
      }
    },
    [calculateScrollSpeed, scrollContainerElement]
  );

  const onDragMove = useCallback(
    function onDragMove(e: DragMoveEvent) {
      if (scrollContainerElement) {
        const rect = scrollContainerElement.getBoundingClientRect();
        const pointerY = e.y;
        const pointerX = e.x;
        pointerYRef.current = pointerY;
        pointerXRef.current = pointerX;

        // Do nothing if the pointer x position is not in bounding box
        if (pointerX < rect.left || pointerX > rect.right) {
          return;
        }

        const distanceToTopEdge = pointerY - rect.top;
        const distanceToBottomEdge = rect.bottom - pointerY;

        let newScrollDirection = 0;

        // Determine if we should scroll up or down
        if (distanceToTopEdge < options.scrollThreshold) {
          newScrollDirection = -1; // Scroll up
        } else if (distanceToBottomEdge < options.scrollThreshold) {
          newScrollDirection = 1; // Scroll down
        }

        // Update scroll direction if it has changed
        if (newScrollDirection !== scrollDirectionRef.current) {
          scrollDirectionRef.current = newScrollDirection;
          if (newScrollDirection !== 0 && !isScrollingRef.current) {
            isScrollingRef.current = true;
            scrollAnimationFrameIdRef.current = requestAnimationFrame(scrollContainer);
          }
        }
      }
    },
    [options.scrollThreshold, scrollContainer, scrollContainerElement]
  );

  const onDragEnd = useCallback(function onDragEnd() {
    scrollDirectionRef.current = 0;
    isScrollingRef.current = false;
    if (scrollAnimationFrameIdRef.current !== null) {
      cancelAnimationFrame(scrollAnimationFrameIdRef.current);
      scrollAnimationFrameIdRef.current = null;
    }
  }, []);

  const onDragCancel = useCallback(function onDragCancel() {
    scrollDirectionRef.current = 0;
    isScrollingRef.current = false;
    if (scrollAnimationFrameIdRef.current !== null) {
      cancelAnimationFrame(scrollAnimationFrameIdRef.current);
      scrollAnimationFrameIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      // Cleanup function to cancel the animation frame
      if (scrollAnimationFrameIdRef.current !== null) {
        cancelAnimationFrame(scrollAnimationFrameIdRef.current);
        scrollAnimationFrameIdRef.current = null;
      }
    };
  }, []);

  return {
    onDragMove,
    onDragEnd,
    onDragCancel,
  };
}

/**
 * Set up auto-scrolling for all provided containers. It's important that call-sites
 * only call with a finite, constant number of containers, otherwise this function will
 * break the rules of hooks.
 */
export function useAutoScroll<T extends (HTMLElement | null)[] | [...(HTMLElement | null)[], AutoScrollOptions]>(
  ...containerElements: T
):
  | {
      onDragMove: (e: DragMoveEvent) => void;
      onDragEnd: () => void;
      onDragCancel: () => void;
    }
  | {} {
  let elements: HTMLElement[] = [];
  let options: AutoScrollOptions = {};

  if (containerElements.length === 0) {
    return {};
  } else if (last(containerElements) === null || "id" in last(containerElements)!) {
    // If the last element has an id, it's a scroll container and not options
    elements = containerElements as HTMLElement[];
  } else {
    // Otherwise the last element is options
    elements = [...containerElements.slice(0, -1)] as HTMLElement[];
    options = last(containerElements) as AutoScrollOptions;
  }

  const callbackSets = elements.map((containerElement) => __useAutoScroll(containerElement, options));

  return composeCallbacks(callbackSets);
}

export default useAutoScroll;
