import OpenInNew from "@mui/icons-material/OpenInNew";
import React from "react";
import style from "./style.module.css";

interface IProps {
  title: React.ReactNode;
  headline: React.ReactNode;
  description: React.ReactNode;
  learnMoreLink: string;
  loomEmbed: React.ReactNode;
}

const EmptyState = (props: IProps) => {
  const { title, headline, description, learnMoreLink, loomEmbed } = props;
  return (
    <div className={style.emptyState}>
      <p className={style.emptyStateTitle}>{title}</p>
      <a className={style.learnMore} href={learnMoreLink} target="_blank" rel="noopener noreferrer">
        <OpenInNew className={style.learnMoreIcon} />
        Learn More
      </a>
      <div className={style.content}>
        {loomEmbed}
        <div className={style.contentText}>
          <p className={style.emptyStateHeadline}>{headline}</p>
          <hr className={style.horizontalLine} />
          {description}
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
