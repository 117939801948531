import TemplateBadge from "@shared/frontend/templates/TemplateBadge";
import { ComponentType } from "@shared/types/Component";
import { ITipTapRichText } from "@shared/types/TextItem";
import { IFVariable } from "@shared/types/Variable";
import classnames from "classnames";
import React from "react";
import RichTextRender from "../../RichTextRender";
import VariableSpan from "../../variable-interpolations/VariableSpan";
import style from "./style.module.css";

interface Props {
  data: {
    label: string;
    type: ComponentType;
    suggestionInfo: {
      _id: string;
      match: number;
    } | null;
    data: {
      _id: string;
      text: string;
      status: string;
      variables: IFVariable[];
      rich_text: ITipTapRichText;
      notes: string | null;
      tags: string[];
    };
  };
  searchValue: string;
  innerProps: {
    onClick: (e: React.SyntheticEvent) => void;
    onMouseOver: (e: React.MouseEvent) => void;
  };
}
export default function ComponentModalOption(props: Props) {
  const {
    data,
    searchValue,
    innerProps: { onClick, onMouseOver },
  } = props;
  const { label, suggestionInfo, data: compInfo, type } = data;

  const suggestionsMatch = suggestionInfo ? `${suggestionInfo.match}% Text Match` : "";
  const isCreateNewOption = label === `Create "${searchValue}"`;
  const showComponentInfo = Boolean(compInfo?.tags?.length > 0 || compInfo?.notes);

  const isStandardComponent = type !== "template";
  const isTemplateComponent = type === "template";

  if (isCreateNewOption) {
    return (
      <div
        className={classnames({
          [style.option]: true,
          [style.createNewOption]: isCreateNewOption,
        })}
        onClick={onClick}
        onMouseOver={onMouseOver}
      >
        {label}
      </div>
    );
  }

  return (
    <div
      data-testid="component-modal-option"
      className={classnames({
        [style.option]: true,
        [style.optionStandard]: isStandardComponent,
        [style.optionTemplate]: isTemplateComponent,
      })}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <div
        className={classnames({
          [style.statusIndicator]: true,
          [style.statusNone]: compInfo?.status === "NONE",
          [style.statusWIP]: compInfo?.status === "WIP",
          [style.statusInReview]: compInfo?.status === "IN_REVIEW",
          [style.statusFinal]: compInfo?.status === "FINAL",
        })}
      />
      <div className={style.componentDetails}>
        <div className={style.wrapper}>
          <div className={style.header}>
            <div className={style.componentName}>{label}</div>
            <div className={style.text}>
              {compInfo?.rich_text ? (
                <RichTextRender richText={compInfo.rich_text} highlightBrackets={isTemplateComponent} />
              ) : (
                <VariableSpan
                  variables={compInfo.variables}
                  text={compInfo.text}
                  highlightBrackets={isTemplateComponent}
                />
              )}
            </div>
          </div>
          {suggestionsMatch && <div className={style.suggestionInfo}>{suggestionsMatch}</div>}
          {!suggestionsMatch && isTemplateComponent && <TemplateBadge type={type} selected />}
        </div>

        {showComponentInfo && (
          <div className={style.componentInfo}>
            {compInfo?.notes && <div className={style.notes}>{compInfo.notes}</div>}
            {compInfo?.tags.length > 0 && (
              <div className={style.tags}>
                {compInfo.tags.map((tag) => (
                  <div key={tag} className={style.tag}>
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
