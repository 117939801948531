import { Extension } from "@tiptap/core";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    highlighter: {
      setHighlight: (patternToHighlight: RegExp | null) => ReturnType;
    };
  }
}

function generateHighlights(doc: ProsemirrorNode, highlight: RegExp | null) {
  if (!highlight) {
    return DecorationSet.empty;
  }

  const decorations: Decoration[] = [];

  doc.descendants((node: any, position: number) => {
    if (!node.isText) {
      return;
    }

    let matches;
    while ((matches = highlight.exec(node.text))) {
      decorations.push(
        Decoration.inline(position + matches.index, position + matches.index + matches[0].length, {
          class: "highlight",
        })
      );
    }
  });

  return DecorationSet.create(doc, decorations);
}

interface HighlighterState {
  highlight?: RegExp | null;
  decorations: DecorationSet;
}

const HighlighterName = "highlighter";
const Highlighter = Extension.create({
  name: HighlighterName,

  addCommands() {
    return {
      setHighlight: (patternToHighlight: RegExp | null) => {
        return (props: any) => {
          props.tr.setMeta(HighlighterName, { highlight: patternToHighlight });
          props.dispatch(props.tr);
          return true;
        };
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin<HighlighterState>({
        key: new PluginKey(HighlighterName),
        state: {
          init() {
            return {
              highlight: null,
              decorations: DecorationSet.empty,
            };
          },
          apply(transaction, oldState) {
            const highlightMetaProperty = transaction.getMeta(HighlighterName)?.highlight;

            let highlight: RegExp | null | undefined;
            if (highlightMetaProperty !== undefined) {
              highlight = highlightMetaProperty;
            } else {
              highlight = oldState?.highlight || undefined;
            }

            if (highlight === undefined) {
              return oldState;
            }

            if (highlight && !highlight.flags.includes("g")) {
              throw new Error(
                "Regular expressions passed to the highlighter extension MUST include the 'g' flag to avoid infinite loops"
              );
            }

            return {
              highlight,
              decorations: generateHighlights(transaction.doc, highlight),
            };
          },
        },
        props: {
          decorations(state) {
            return (this as any).getState(state).decorations;
          },
        },
      }),
    ];
  },
});

export default Highlighter;
