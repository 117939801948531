import React from "react";

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="currentColor" {...props}>
    <mask
      id="mask0_1283_1118"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="17"
    >
      <rect y="0.5" width="16" height="16" />
    </mask>
    <g mask="url(#mask0_1283_1118)">
      <path d="M11.45 9.83301H5C4.16667 9.83301 3.45833 9.54134 2.875 8.95801C2.29167 8.37467 2 7.66634 2 6.83301C2 5.99967 2.29167 5.29134 2.875 4.70801C3.45833 4.12467 4.16667 3.83301 5 3.83301C5.18889 3.83301 5.34722 3.8969 5.475 4.02467C5.60278 4.15245 5.66667 4.31079 5.66667 4.49967C5.66667 4.68856 5.60278 4.8469 5.475 4.97467C5.34722 5.10245 5.18889 5.16634 5 5.16634C4.53333 5.16634 4.13889 5.32745 3.81667 5.64967C3.49444 5.9719 3.33333 6.36634 3.33333 6.83301C3.33333 7.29967 3.49444 7.69412 3.81667 8.01634C4.13889 8.33856 4.53333 8.49967 5 8.49967H11.45L9.53333 6.56634C9.41111 6.44412 9.34722 6.29134 9.34167 6.10801C9.33611 5.92467 9.4 5.76634 9.53333 5.63301C9.65556 5.51079 9.81111 5.44967 10 5.44967C10.1889 5.44967 10.3444 5.51079 10.4667 5.63301L13.5333 8.69967C13.6667 8.83301 13.7333 8.98856 13.7333 9.16634C13.7333 9.34412 13.6667 9.49967 13.5333 9.63301L10.4667 12.6997C10.3333 12.833 10.1778 12.8969 10 12.8913C9.82222 12.8858 9.66667 12.8163 9.53333 12.683C9.41111 12.5497 9.34722 12.3941 9.34167 12.2163C9.33611 12.0386 9.4 11.883 9.53333 11.7497L11.45 9.83301Z" />
    </g>
  </svg>
);
