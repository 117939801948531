import Tooltip from "@shared/frontend/Tooltip";
import classnames from "classnames";
import React, { useContext } from "react";
import { BillingContext } from "../../store/billingContext";
import style from "./style.module.css";

interface Props {
  openModal: () => void;
  disabled?: boolean;
  learnMoreLink?: string;
  onUpsellClicked?: () => void;
  responsiveSize?: "normal" | "large";
}

const NewFolderCard = (props: Props) => {
  const { showBillingModal } = useContext(BillingContext);

  const handleClick = () => {
    if (props.disabled) {
      return;
    }
    props.openModal();
  };

  const handleUpsellClicked = () => {
    if (props.onUpsellClicked) {
      props.onUpsellClicked();
    }
    showBillingModal();
  };

  return (
    <div className={props.responsiveSize === "large" ? style.largeResponsiveCol : style.responsiveCol}>
      <Tooltip
        theme="dark"
        hideOnClick={false}
        disabled={!props.disabled}
        className={style.tippyTooltip}
        interactive={true}
        content={
          <div className={style.body}>
            <span onClick={handleUpsellClicked} className={style.upgradeLink}>
              Upgrade
            </span>{" "}
            to Growth to create folders.{" "}
            <a href={props.learnMoreLink} target="_blank" className={style.learnMoreLink}>
              Learn more
            </a>
          </div>
        }
      >
        <div
          className={classnames([style.folderCard], [style.newFolderCard], {
            [style.disabled]: props.disabled,
          })}
          onClick={handleClick}
          data-tip
          data-for="new-proj"
          data-testid="new-folder-button"
        >
          <div>Add a new folder</div>
        </div>
      </Tooltip>
    </div>
  );
};

export default NewFolderCard;
