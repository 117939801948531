import { TabData } from "@/components/TabbedMenu/TabbedMenu";
import InstantMixIcon from "@/components/icons/InstantMixIcon";
import KeyIcon from "@/components/icons/KeyIcon";
import WebhookIcon from "@/components/icons/WebhookIcon";
import { useWorkspace } from "@/store/workspaceContext";
import OpenInNew from "@mui/icons-material/OpenInNew";
import StarOutlined from "@mui/icons-material/StarOutline";
import { Ditto, useDittoComponent } from "ditto-react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import OverlayToast from "../../components/OverlayToast";
import TabbedMenu from "../../components/TabbedMenu/TabbedMenu";
import DevToolsModal from "../../components/account-billing/DevToolsModal/DevToolsModal_NEW";
import ButtonSecondary from "../../components/button/buttonsecondary";
import Spinner from "../../components/spinner/spinner";
import APIKeysTab from "./components/APIKeysTab";
import ConfigureDevIDsTab from "./components/ConfigureDevIDsTab";
import GettingStartedTab from "./components/GettingStartedTab";
import IntegrationsInfoPanel from "./components/IntegrationsInfoPanel/IntegrationsInfoPanel";
import WebhooksTab from "./components/WebhooksTab";
import style from "./style.module.css";
import { useDevelopersPage } from "./useDevelopersPage";

const BASE_PATH = "/developers";

const Developers = () => {
  const { workspaceInfo } = useWorkspace();

  const {
    devToolsModalOpen,
    setDevToolsModalOpen,
    devToolsEnabled,
    onClickEnable,
    openInviteModal,
    openDevDocs,
    handleEnableDevTools,
    handleStartTrial,
    devTrialStartDate,
    overlayToastProps,
  } = useDevelopersPage();

  if (!workspaceInfo) {
    return (
      <div className={style.loading}>
        <Spinner centered />
      </div>
    );
  }

  const tabs = [
    {
      path: `${BASE_PATH}/getting-started`,
      alsoMatch: [BASE_PATH],
      title: useDittoComponent({ componentId: "getting-started" }),
      icon: StarOutlined,
      content: GettingStartedTab,
    },
    {
      path: `${BASE_PATH}/api-keys`,
      title: useDittoComponent({ componentId: "api-keys" }),
      icon: KeyIcon,
      content: APIKeysTab,
      disabled: !devToolsEnabled,
      disabledMessage: useDittoComponent({ componentId: "enable-developer-integrations-1" }),
    },
    {
      path: `${BASE_PATH}/webhooks`,
      title: useDittoComponent({ componentId: "webhooks" }),
      icon: WebhookIcon,
      content: WebhooksTab,
      disabled: !devToolsEnabled,
      disabledMessage: useDittoComponent({ componentId: "enable-developer-integrations-1" }),
    },
    {
      path: `${BASE_PATH}/configure-dev-ids`,
      title: useDittoComponent({ componentId: "configure-dev-ids" }),
      icon: InstantMixIcon,
      content: ConfigureDevIDsTab,
    },
  ] as TabData[];

  return (
    <div className={style.body}>
      <IntegrationsInfoPanel openDevToolsModal={onClickEnable} openInviteModal={openInviteModal} />

      <TabbedMenu
        tabs={tabs}
        otherContent={
          <ButtonSecondary
            data-testid="developer-docs-button"
            onClick={openDevDocs}
            className={style.devDocsButton}
            text={
              <>
                <Ditto componentId="developer-docs" />
                <OpenInNew className={style.icon} />
              </>
            }
          />
        }
      />

      <Switch>
        {tabs.map((tab, i) => {
          let pathsToMatch = [tab.path];
          if (tab.alsoMatch) pathsToMatch = pathsToMatch.concat(tab.alsoMatch);

          return (
            <Route path={pathsToMatch} key={tab.path} exact>
              <tab.content />
            </Route>
          );
        })}
      </Switch>

      {devToolsModalOpen && (
        <DevToolsModal
          plan={workspaceInfo.plan}
          devTrialStartDate={devTrialStartDate}
          onEnableDevTools={handleEnableDevTools}
          onHide={() => setDevToolsModalOpen(false)}
          handleStartTrial={handleStartTrial}
        />
      )}
      <OverlayToast {...overlayToastProps} />
    </div>
  );
};

export default Developers;
