/**
 * Props that should be applied to elements to make it so that,
 * when clicked, the `unselectAll` function is called.
 */
export const UNSELECT_ALL_PROPS = {
  "data-unselect-all": true,
};

/**
 * camelCased version of the applied data prop that must be used
 * when checking an element's dataset.
 */
export const UNSELECT_ALL_ATTRIBUTE = "unselectAll";
