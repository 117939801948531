import { IFComponentFolder } from "@shared/types/ComponentFolder";
import { useEffect, useState } from "react";
import http, { API } from "../../http";

export function useComponentFolders() {
  const [state, setState] = useState<
    | {
        loading: true;
      }
    | {
        loading: false;
        data: IFComponentFolder[];
      }
  >({ loading: true });

  useEffect(function loadComponentFolders() {
    const { url } = API.componentFolder.get.folders;
    http.get<IFComponentFolder[]>(url).then(({ data }) => setState({ loading: false, data }));
  }, []);

  return state;
}
