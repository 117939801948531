import React, { useEffect, useMemo, useState } from "react";

import { ITipTapRichText } from "@shared/types/TextItem";
import classnames from "classnames";
import { Change, diffSentences, diffWords } from "diff";
import isEqual from "lodash.isequal";
import RichTextRender from "../../components/RichTextRender";
import { isRichTextStyled } from "../../utils/rich_text";
import style from "./style.module.css";

interface Props {
  text_before?: string | null;
  text_after?: string | null;
  rich_text_before?: ITipTapRichText | null;
  rich_text_after?: ITipTapRichText | null;
  isApiID?: boolean;
  showRichText?: boolean;
}
const EditDiff = ({ text_before, text_after, rich_text_before, rich_text_after, isApiID, showRichText }: Props) => {
  const [diff, setDiff] = useState<Change[]>([]);

  const getDiff = () => {
    //empty string is fine but undefined and null are not
    if (text_before !== undefined && text_after !== undefined && text_before !== null && text_after !== null) {
      const diff = isApiID ? diffSentences(text_before, text_after) : diffWords(text_before, text_after);
      setDiff(diff);
    }
  };

  useEffect(() => {
    getDiff();
  }, [text_before, text_after]);

  const diffCyKey = useMemo(() => generateDiffCyKey(text_before, text_after), [text_before, text_after]);

  const shouldShowRichTextDiff =
    showRichText && rich_text_after && (isRichTextStyled(rich_text_before || {}) || isRichTextStyled(rich_text_after));

  const isDiffRichText = !isEqual(rich_text_before, rich_text_after);

  if (shouldShowRichTextDiff) {
    if (!isDiffRichText) {
      return (
        <div className={classnames(style.diff, style.richDiff)}>
          <div className={style.richtext}>
            <RichTextRender richText={rich_text_after} />
          </div>
        </div>
      );
    }
    return (
      <div className={classnames(style.diff, style.richDiff)}>
        <div className={classnames(style.removed)}>
          <RichTextRender
            richText={rich_text_before || ({} as ITipTapRichText)}
            renderProps={{ className: style.inline }}
          />
        </div>
        <div className={classnames(style.added)}>
          <RichTextRender richText={rich_text_after} renderProps={{ className: style.inline }} />
        </div>
      </div>
    );
  }
  return (
    <div
      data-testid={diffCyKey}
      className={classnames({
        [style.diff]: true,
        [style.apiID]: isApiID,
      })}
    >
      {diff &&
        diff.map((section, key) => {
          return (
            <span
              key={key}
              className={classnames({
                [style.added]: section.added,
                [style.removed]: section.removed,
              })}
            >
              {section.value}
            </span>
          );
        })}
    </div>
  );
};

function generateDiffCyKey(textBefore, textAfter) {
  const keyWithSpaces = `compActivityDiff${textBefore}${textAfter}`;
  return keyWithSpaces.replace(/\s/g, "-");
}

export default EditDiff;
