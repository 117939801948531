import classNames from "classnames";
import React from "react";

import style from "./style.module.css";

interface SampleBadgeProps {
  children: React.ReactNode;
  className?: string;
}

const SampleBadge = (props: SampleBadgeProps) => {
  return <div className={classNames(style.sampleBadge, props.className)}>{props.children}</div>;
};

export default SampleBadge;
