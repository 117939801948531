import { Extension } from "@tiptap/core";
import applyDevTools from "prosemirror-dev-tools";
import { Plugin, PluginKey } from "prosemirror-state";

const pluginKey = new PluginKey("devTools");

let isInitialized = false;

const DevTools = Extension.create<FocusOptions>({
  name: "devTools",
  key: pluginKey,
  addProseMirrorPlugins() {
    return [
      new Plugin<{}>({
        key: pluginKey,
        view: (editorState) => ({
          update: () => {
            if (!isInitialized) {
              isInitialized = true;
              applyDevTools(editorState);
            }
          },
        }),
      }),
    ];
  },
});

export default DevTools;
