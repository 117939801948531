import ChartDataIcon from "@/components/icons/ChartDataIcon";
import { Ditto } from "ditto-react";
import React from "react";
import style from "./upsellBanner.module.css";

export interface Props {
  message: string;
  onClick?: () => void;
}

export default function UpsellBanner(props: Props) {
  return (
    <div className={style.container} data-testid="upsell-banner" onClick={props.onClick}>
      <div className={style.content}>
        <div className={style.text}>{props.message}</div>
        <div className={style.action}>
          <ChartDataIcon className={style.icon} />
          <div className={style.text}>
            <Ditto componentId="upgrade-plan" />
          </div>
        </div>
      </div>
    </div>
  );
}
