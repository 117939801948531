type SelectionResult = {
  startSelection: number;
  endSelection: number;
};

/**
 * Adjusts the provided start and end selection indices to encompass complete sentences.
 *
 * - If a complete sentence is selected, the original values are returned.
 * - If a partial sentence is selected, the start and end positions of the entire surrounding sentence are returned.
 * - If the selection spans more than one sentence, the adjusted selection will encompass all surrounding sentences.
 * - If the full string isn't a sentence, the function will return a selection starting from `0` to the end of the string.
 */
const findSentenceBoundaries = (startSelection: number, endSelection: number, fullString: string): SelectionResult => {
  const sentenceEndings = /[.!?]/;
  let adjustedStart = startSelection;
  let adjustedEnd = endSelection;

  // If the startSelection isn't at the start of the fullString, move back until we find a sentence ending
  while (adjustedStart > 0 && !sentenceEndings.test(fullString[adjustedStart - 1])) {
    adjustedStart--;
  }

  // If the endSelection isn't at the end of the fullString, move forward until we find a sentence ending
  while (adjustedEnd < fullString.length && !sentenceEndings.test(fullString[adjustedEnd])) {
    adjustedEnd++;
  }

  // Handle the case where the full string doesn't contain a sentence
  if (adjustedStart === 0 && adjustedEnd === fullString.length && !sentenceEndings.test(fullString)) {
    adjustedEnd = fullString.length;
  }

  return {
    startSelection: adjustedStart,
    endSelection: adjustedEnd,
  };
};

export default findSentenceBoundaries;
