import React from "react";
const GroupsOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M54.617-248.462q-14.712 0-24.664-9.952-9.951-9.952-9.951-24.663v-14.153q0-39.923 41.692-65.577 41.692-25.654 108.691-25.654 11.077 0 22.308.693 11.231.692 22.615 2.692-11.692 18.692-17.346 38.807-5.653 20.115-5.653 41.269v56.538H54.617Zm241.697 0q-15.62 0-25.966-10.394-10.347-10.394-10.347-25.76v-18.845q0-28.091 15.77-51.353 15.769-23.262 45.461-40.57 29.692-17.307 70.153-25.961 40.461-8.654 88.461-8.654 48.923 0 89.384 8.654t70.154 25.961q29.692 17.308 45.153 40.57 15.462 23.262 15.462 51.353v18.845q0 15.366-10.395 25.76-10.394 10.394-25.759 10.394H296.314Zm471.377 0v-56.483q0-22.593-5.346-42.516t-16.038-37.615q11.769-2 22.5-2.692 10.731-.693 21.192-.693 67 0 108.5 25.346t41.5 65.885v14.153q0 14.711-9.952 24.663t-24.663 9.952H767.691Zm-444.614-59.999h314.462v-4.231q-6.154-24.231-51.077-40.769Q541.539-370 480-370t-106.462 16.539q-44.923 16.538-50.461 40.769v4.231ZM170.283-427.307q-28.282 0-48.32-20.11t-20.038-48.35q0-28.615 20.11-48.346 20.11-19.731 48.35-19.731 28.615 0 48.538 19.731t19.923 48.46q0 27.885-19.712 48.116-19.712 20.23-48.851 20.23Zm619.716 0q-27.999 0-48.23-20.23-20.23-20.231-20.23-48.116 0-28.729 20.23-48.46 20.231-19.731 48.305-19.731 28.925 0 48.655 19.731 19.731 19.731 19.731 48.346 0 28.24-19.682 48.35-19.683 20.11-48.779 20.11Zm-309.863-32.692q-43.212 0-73.597-30.288-30.384-30.288-30.384-73.557 0-44.134 30.288-73.989 30.288-29.855 73.557-29.855 44.134 0 73.99 29.816 29.855 29.816 29.855 73.893 0 43.212-29.816 73.596-29.817 30.384-73.893 30.384Zm.056-59.998q18.347 0 31.001-12.846 12.653-12.847 12.653-31.193 0-18.346-12.605-31Q498.635-607.69 480-607.69q-18.154 0-31 12.606t-12.846 31.24q0 18.154 12.846 31.001 12.846 12.846 31.192 12.846Zm.423 211.536ZM480-563.844Z" />
    </svg>
  );
};

export default GroupsOutlineIcon;
