import { IFRecentCard } from "../../shared/types/http/Homepage";
import { createHttpRequest } from "./lib/createHttpRequest";

const url = (path: string) => `/homepage${path}`;

export const getAssignments = createHttpRequest<
  void,
  {
    type: "project" | "components";
    projectId?: string;
    projectName?: string;
    statusCounts: { count: number; status: string }[];
    lastAssignedAt: Date;
  }[]
>({
  method: "get",
  url: url("/assigned"),
});

export const getRecents = createHttpRequest<void, IFRecentCard[]>({
  method: "get",
  url: url("/recents"),
});
