import { removeFigmaArtifactsFromGroupName } from "@shared/lib/groups";
import React from "react";
import CableIcon from "../../../../assets/CableIcon";

import style from "./style.module.css";

interface Props {
  frameName: string | null;
}

const FigmaFrameName = (props: Props) => {
  const { frameName } = props;

  if (!frameName) {
    return <React.Fragment />;
  }

  return (
    <div className={style.container}>
      <CableIcon className={style.icon} />{" "}
      <span className={style.text}>{removeFigmaArtifactsFromGroupName(frameName)}</span>
    </div>
  );
};

export default FigmaFrameName;
