// data-tour attributes for the Intercom tour
export const INTERCOM_ATTR = {
  FIGMA_FILE_LINK: "figma-file-link",
  EDIT_PANEL_COMPONENT_NAME: "edit-panel-component-name",
  COMPONENTS_NAV_LINK: "components-nav-link",
  COMPONENTS_INSTANCES_TAB_LINK: "components-instances-tab-link",
  COMPONENT_ID: "component-id",
  RESYNC_BUTTON: "resync-button",
  WS_COMP_EDIT_PANEL: "ws-comp-edit-panel",
  SINGLE_TEXT_ITEM: "single-text-item",
  EDIT_COMP: "edit-comp",
};
