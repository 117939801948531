import { Extensions, Node } from "@tiptap/core";
import { Bold } from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import { Italic } from "@tiptap/extension-italic";
import Paragraph from "@tiptap/extension-paragraph";
import { Subscript } from "@tiptap/extension-subscript";
import { Superscript } from "@tiptap/extension-superscript";
import Text from "@tiptap/extension-text";
import { Underline } from "@tiptap/extension-underline";
import { createVariableNode } from "./VariableNodeCreate";

// Does not include all extensions as this function is used by the backend
// to generate HTML from Tiptap data
function getBaseEditorExtensions(variableNode?: Node): Extensions {
  return [
    Document,
    Paragraph,
    Text,
    variableNode || createVariableNode({}, ""),
    Bold,
    Italic,
    Underline,
    Superscript,
    Subscript,
  ];
}

export default getBaseEditorExtensions;
