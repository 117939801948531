import { Extension } from "@tiptap/core";
import { Node as ProsemirrorNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { forEachBracketPair } from "../../../lib/text";

const PLUGIN_NAME = "TemplateComponentHighlighter";
const PLUGIN_KEY = "templateComponentHighlighter";

// the CSS class that will be applied to the highlighted text
export const CLASS = "template_highlight";

//
//
/**
 * Heavily adapted and simplified from the experiment here:
 * https://tiptap.dev/experiments/linter
 *
 * This extension is used to add lightweight decorations to text between
 * bracket pairs while making edits to template components. Decorations
 * allow us to keep the editing experience extremely natural and loose since
 * the underlying schema of the editor is not being modified.
 *
 * Since this extension uses decorations for the highlighting, bracket pairs
 * will not be highlighted when the editor is exported to JSON. For that,
 * we use the `TemplateHighlightNode` custom Mark and accompanying function instead.
 */
export const TemplateComponentHighlighter = Extension.create({
  name: PLUGIN_NAME,

  addOptions() {
    return {
      plugins: [],
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(PLUGIN_KEY),
        state: {
          init(_, { doc }) {
            return computeHighlightDecorations(doc);
          },
          apply(transaction, oldState) {
            return transaction.docChanged ? computeHighlightDecorations(transaction.doc) : oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});

// iterate over all text nodes in the document and add an inline decoration to
// all nodes that match REGEX
function computeHighlightDecorations(doc: ProsemirrorNode) {
  const decorations: [any?] = [];

  doc.descendants((node: any, position: number) => {
    if (!node.isText) {
      return;
    }

    forEachBracketPair(node.text, ({ start, end }) => {
      decorations.push(Decoration.inline(position + start, position + end, { class: CLASS }));
    });
  });

  return DecorationSet.create(doc, decorations);
}
