import { useRef, useState } from "react";

export function useOverlayToast() {
  const [text, setText] = useState<string>("");
  const [hidden, setHidden] = useState<boolean>(true);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function showToast(text: string, time?: number) {
    // if we try to show a toast while another is already showing, clear the timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setText(text);
    setHidden(false);

    if (time) {
      timeoutRef.current = setTimeout(() => {
        setHidden(true);
      }, time);
    }
  }

  function hideToast() {
    setHidden(true);
  }

  return {
    overlayToastProps: { text, hidden },
    showToast,
    hideToast,
  };
}
