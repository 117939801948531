import AssistantIcon from "@mui/icons-material/Assistant";
import { ActualComponentVariableSchema } from "@shared/types/TextItem";
import { generateVariableText } from "@shared/utils/variableInterpolation";
import classNames from "classnames";
import React, { useMemo } from "react";
import { Element as ScrollElement } from "react-scroll";

import TextMismatchTooltip from "../TextMismatchTooltip";

import style from "./style.module.css";

export type HighlightBoxStyle = {
  compId: string;
  scaleFactor: number;
  wrapper: React.CSSProperties;
  box: React.CSSProperties;
  indicator: React.CSSProperties;
};

interface Props {
  highlight: HighlightBoxStyle;
  textItem: {
    text: string;
    variables: ActualComponentVariableSchema[];
    lastSync: string | null;
    is_hidden: boolean;
    updatedAt: Date;
    text_last_modified_at: Date;
  };
  searchFiltersOn: boolean;
  suggestedCompId: string | null;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}

const HighlightBox = (props: Props) => {
  const textItem = props.textItem;
  if (!textItem) {
    return <React.Fragment key={props.highlight.compId} />;
  }

  const { text, variables, lastSync } = textItem;
  const variableInterpolatedText = useMemo(() => generateVariableText(text, variables), [text, variables]);

  const textHasChanged = variableInterpolatedText !== lastSync;

  const getTooltipPosition = (
    position: { left: number; top: number },
    _currentEvent: Event,
    currentTarget: EventTarget
  ): { left: number; top: number } => {
    const left = (currentTarget as Element).getBoundingClientRect().left + 2;
    const top = position.top + 10;
    return { left, top };
  };

  return (
    <ScrollElement
      id={`text-box-${props.highlight.compId}`}
      name={`text-box-${props.highlight.compId}`}
      key={`text-box-${props.highlight.compId}`}
      className={classNames({
        [style.textBox]: true,
        [style.inSearchFilter]: props.searchFiltersOn,
        [style.active]: props.isSelected,
      })}
      style={props.highlight.wrapper}
      onClick={props.onClick}
    >
      <div
        className={classNames({
          [style.highlightBox]: true,
          [style.suggested]: props.suggestedCompId === props.highlight.compId,
        })}
        style={props.highlight.box}
      />
      <AssistantIcon
        data-tip
        data-for={`text-box-${props.highlight.compId}`}
        style={props.highlight.indicator}
        className={classNames({
          [style.highlightTextChangedIndicator]: true,
          [style.textHasChanged]: textHasChanged,
          [style.isSelected]: props.isSelected,
        })}
      />
      {textHasChanged && (
        <TextMismatchTooltip
          id={props.highlight.compId}
          text={variableInterpolatedText}
          isHidden={textItem.is_hidden ?? false}
          updatedAt={textItem.text_last_modified_at}
          getTooltipPosition={getTooltipPosition}
        />
      )}
    </ScrollElement>
  );
};

export default HighlightBox;
