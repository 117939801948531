import NumbersIcon from "@mui/icons-material/Numbers";
import CompactLabel from "../CompactLabel";
import style from "./style.module.css";

export type CharLimitInputType = "" | number | "MIXED";

interface CharacterLimitRowProps {
  charLimitInput: CharLimitInputType;
  setCharLimitInput: React.Dispatch<React.SetStateAction<CharLimitInputType>>;
}

const MixedStatus = ({ onClick }) => (
  <button className={style.mixedCharLimitLabel} onClick={onClick}>
    Mixed character limits
  </button>
);

export const CharacterLimitRow = ({ charLimitInput, setCharLimitInput }: CharacterLimitRowProps) => {
  const handleClick = () => {
    setCharLimitInput("");
  };

  const handleChange = (e) => {
    setCharLimitInput(parseInt(e.target.value));
  };

  return (
    <div className={style.characterLimitRow}>
      <CompactLabel Icon={NumbersIcon} text="Limit" />

      <div className={style.charLimitInputField}>
        <span className={style.label}>X /</span>
        {charLimitInput === "MIXED" ? (
          <MixedStatus onClick={handleClick} />
        ) : (
          <input
            type="number"
            className={style.inputField}
            placeholder="Add character limit..."
            value={charLimitInput || ""}
            min="1"
            onChange={handleChange}
            autoFocus={charLimitInput === ""}
          />
        )}
      </div>
    </div>
  );
};
