import { useCallback, useEffect, useRef, useState } from "react";

import exportApi, { RequestParams } from "../../http/export";

interface ExportDataArgs {
  selectedVariant: { id: string };
  projectId: string;
  disabled?: boolean;
  componentFolderId: string | null;
}

type ExportDataReturnValue = [string, { loading: boolean }];

/**
 * Given an export key and relevant details about the type of export,
 * loads the export data and returns it along with metadata about the
 * ongoing request.
 */
export function useExportData(key: keyof typeof exportApi, args: ExportDataArgs): ExportDataReturnValue {
  const { projectId, selectedVariant, disabled } = args;
  const { getCacheValue, setCacheValue } = useOutputCache();

  const [data, setData] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (disabled) {
      return;
    }

    const variantId = selectedVariant.id === "base" ? null : selectedVariant.id;

    // `projectId` being set to "component_library" is a quirk
    // of how the component library page currently implements the
    // export modal
    const params: RequestParams =
      projectId === "component_library"
        ? {
            type: "components",
            variantId,
            folderId: args.componentFolderId,
          }
        : {
            type: "project",
            projectId: projectId!,
            variantId,
          };

    // If the data already exists in the cache,
    // don't bother making a request - simply set
    // it in state.
    const cacheValue = getCacheValue(params);
    if (cacheValue) {
      setData(cacheValue);
      return;
    }

    setDataLoading(true);

    const [request, cancelRequest] = exportApi[key](params);

    // Ensure we are in the loading state for at least 600ms
    // to avoid the awkwardness of a flashing loader.
    Promise.all([request, new Promise((resolve) => setTimeout(resolve, 600))])
      .then(([response]) => {
        const { data } = response.data;
        setData(data);
        setCacheValue(params, data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setDataLoading(false));

    // Cancel the request if the user closes the modal
    // or navigates to another tab while it is ongoing.
    return () => {
      cancelRequest();
    };
  }, [projectId, selectedVariant, setDataLoading, disabled]);

  return [data, { loading: dataLoading }];
}

/**
 * Hook for creating a cache structure for storing export requests.
 * Eases load on the backend and responsiveness for users switching between
 * the same variant multiple times.
 */
export function useOutputCache() {
  const cache = useRef<{
    project: {
      [projectId: string]: {
        [variantId: string]: string;
      };
    };
    components: {
      [variantId: string]: string;
    };
  }>({
    project: {},
    components: {},
  });

  const getCacheValue = useCallback((params: RequestParams) => {
    if (params.type === "project") {
      return cache.current.project[params.projectId || "__NONE__"]?.[params.variantId || "base"] || null;
    }

    if (params.type === "components") {
      return cache.current.components[params.variantId || "base"] || null;
    }

    throw new Error("Invalid params.type used to get variant cache value: " + JSON.stringify(params));
  }, []);

  const setCacheValue = useCallback((params: RequestParams, output: string) => {
    if (params.type === "project") {
      cache.current.project[params.projectId || "__NONE__"] ??= {};
      cache.current.project[params.projectId || "__NONE__"][params.variantId || "base"] = output;
      return;
    }

    if (params.type === "components") {
      cache.current.components[params.variantId || "base"] = output;
      return;
    }

    throw new Error("Invalid params.type used to set variant cache value: " + JSON.stringify(params));
  }, []);

  return {
    getCacheValue,
    setCacheValue,
  };
}
