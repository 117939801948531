import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
// @ts-ignore
import applyDevTools from "prosemirror-dev-tools";

const pluginKey = new PluginKey("devTools");

let isInitialized = false;

const DevTools = Extension.create({
  name: "devTools",
  key: pluginKey,
  addProseMirrorPlugins() {
    return [
      new Plugin<any>({
        key: pluginKey,
        view: (editorState) => ({
          update: () => {
            if (!isInitialized) {
              isInitialized = true;
              applyDevTools(editorState as any);
            }
          },
        }),
      }),
    ];
  },
});

export default DevTools;
