import Check from "@mui/icons-material/Check";
import Refresh from "@mui/icons-material/Refresh";
import { Editor } from "@tiptap/react";
import React from "react";
import { Action, actionToName } from "../../types/writing";
import AutoAwesomeIcon from "../AutoAwesomeIcon";
import AutoFixHighIcon from "../Editor/HighlightButtons/assets/auto-fix-high";
import ExtendIcon from "../Editor/HighlightButtons/assets/extend";
import MicIcon from "../Editor/HighlightButtons/assets/mic";
import ShortenIcon from "../Editor/HighlightButtons/assets/shorten";
import SpellcheckIcon from "../Editor/HighlightButtons/assets/spellcheck";
import style from "./AiDialog.module.css";

interface AiDialogProps {
  action: Action | null;
  editor: Editor;
  generatedText: string | null;
  onRefresh: () => void;
  onAccept: () => void;
}

function AiDialog(props: AiDialogProps) {
  if (!props.action) return <></>;

  let promptIcon: React.ReactElement | null = null;
  let promptName = "";

  switch (props.action) {
    case "improve-writing":
      promptIcon = <AutoFixHighIcon fill="#e1c97d" />;
      promptName = actionToName["improve-writing"];
      break;
    case "shorten":
      promptIcon = <ShortenIcon fill="#e1c97d" />;
      promptName = actionToName["shorten"];
      break;
    case "extend":
      promptIcon = <ExtendIcon fill="#e1c97d" />;
      promptName = actionToName["extend"];
      break;
    case "spelling-and-grammar":
      promptIcon = <SpellcheckIcon fill="#e1c97d" />;
      promptName = actionToName["spelling-and-grammar"];
      break;
    case "tone-friendly":
      promptName = "Adjust tone to " + actionToName["tone-friendly"] + "  😄";
      promptIcon = <MicIcon fill="#e1c97d" />;
      break;
    case "tone-formal":
      promptName = "Adjust tone to " + actionToName["tone-formal"] + "  💼";
      promptIcon = <MicIcon fill="#e1c97d" />;
      break;
    case "tone-assertive":
      promptName = "Adjust tone to " + actionToName["tone-assertive"] + "  ☝️";
      promptIcon = <MicIcon fill="#e1c97d" />;
      break;
    case "tone-excited":
      promptName = "Adjust tone to " + actionToName["tone-excited"] + "  🤩";
      promptIcon = <MicIcon fill="#e1c97d" />;
      break;
    default:
      break;
  }

  if (props.generatedText === null) {
    return (
      <div className={style.aiDialog}>
        <div className={style.aiDialogPromptHeading}>
          {promptIcon}
          <span>{promptName}</span>
        </div>
        <span className={style.aiDialogLoading}>
          <AutoAwesomeIcon fill="#e6d082" />
        </span>
      </div>
    );
  }

  return (
    <div className={style.aiDialog}>
      <div className={style.aiDialogContent}>
        <div className={style.aiDialogPromptHeading}>
          {promptIcon}
          <span>{promptName}</span>
        </div>
        <div className={style.aiDialogText}>{props.generatedText}</div>
        <div className={style.aiDialogActions}>
          <Refresh onClick={props.onRefresh} />
          <Check onClick={props.onAccept} />
        </div>
      </div>
    </div>
  );
}

export default AiDialog;
