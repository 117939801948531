import classNames from "classnames";
import React from "react";
import ReactTags from "react-tag-autocomplete";
import TagSuggestion from "../tag-suggestion/tag-suggestion";
import style from "./style.module.css";

/**
 *
 * @param {*} param0
 * @returns
 */
const TagInput = ({ tags, placeholder, disabled, inputAttributes, onDeleteTag, onAddTag, tagSuggestions }) => {
  const reactTagClassNames = {
    root: "react-tags",
    rootFocused: "is-focused",
    selected: "react-tags__selected",
    selectedTag: "react-tags__selected-tag",
    selectedTagName: "react-tags__selected-tag-name",
    search: "react-tags__search",
    searchWrapper: "react-tags__search-wrapper",
    searchInput: "react-tags__search-input",
    suggestions: "react-tags__suggestions",
    suggestionActive: "is-active",
    suggestionDisabled: "is-disabled",
    ...(inputAttributes.disabled ? { search: tags?.length === 0 ? "" : style.hideSearchFromView } : {}),
  };
  const placeholderText = inputAttributes.disabled ? "" : placeholder ? placeholder : "Add new tag";

  return (
    <div
      className={classNames({
        [style.tagInput]: true,
        [style.disableTagInput]: disabled,
      })}
    >
      <ReactTags
        classNames={reactTagClassNames}
        tags={tags}
        onDelete={onDeleteTag}
        onAddition={onAddTag}
        addOnBlur={true}
        allowNew={true}
        placeholderText={placeholderText}
        delimiters={["Enter", "Tab", " "]}
        suggestions={tagSuggestions}
        inputAttributes={inputAttributes}
        minQueryLength={1}
        maxSuggestionsLength={1000}
        suggestionComponent={TagSuggestion}
      />
    </div>
  );
};

export default TagInput;
