import React, { useEffect, useRef, useState } from "react";

/**
 * Computes if a component is visible in the viewport.
 * @param ref A ref to the component to check visibility for.
 * @param threshold Optionally, a threshold for visibility (between 0 and 1, defaults to 0.5).
 * @returns
 * - `isVisible`: A boolean indicating if the component is visible. Use this value if you need to trigger a re-render on changes.
 * - `isVisibleRef`: A ref to the `isVisible` value. Use this value if you need to access the current visibility state without triggering a re-render.
 */
export default function useIsComponentVisible(ref: React.RefObject<HTMLElement>, threshold?: number) {
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleRef = useRef(isVisible);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        isVisibleRef.current = entry.isIntersecting;
      },
      {
        threshold: threshold ?? 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [ref]);

  return { isVisible, isVisibleRef };
}
