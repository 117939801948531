import classNames from "classnames";
import React, { useState } from "react";
import CompactLabel, { CompactLabelProps } from ".";
import Select from "../Select";

import { AuthenticatedAuthState } from "@/hooks/auth";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import style from "./CompactLabelWithEditMode.module.css";

type EditMode = "editing" | "suggesting";

interface CompactLabelWithEditModeContextProps {
  user: AuthenticatedAuthState["user"];
}

interface CompactLabelWithEditModeProps extends CompactLabelProps {
  mode: EditMode;
  onModeChange: (mode: EditMode) => void;
  disabled: boolean;
}

const options: { [key in EditMode]: React.ReactNode } = {
  editing: <div>Editing</div>,
  suggesting: <div>Suggesting</div>,
};

/**
 * A compact label, but has a select dropdown next to it that allows switching between
 * "editing" and "suggesting".
 */
export const CompactLabelWithEditMode = (
  props: CompactLabelWithEditModeProps & CompactLabelWithEditModeContextProps
) => {
  const { mode, user, onModeChange, disabled } = props;

  return (
    <>
      <CompactLabel {...props} className={style.label} />
      <Select
        disabled={disabled}
        value={mode}
        onValueChange={onModeChange}
        options={options}
        optionSelectedClassName={classNames(style.optionSelected, {
          [style.optionSuggesting]: mode === "suggesting",
          [style.optionEditing]: mode === "editing",
        })}
        selectorDropdownClassName={style.selectorDropdown}
        className={style.selector}
        getOptionClassName={(value) => {
          return value === "suggesting" ? style.optionSuggesting : style.optionEditing;
        }}
      />
    </>
  );
};

export default (props: CompactLabelWithEditModeProps) => {
  const { user } = useAuthenticatedAuth();
  return <CompactLabelWithEditMode {...props} user={user} />;
};

export const useEditModeState = () => {
  const [editMode, setEditMode] = useState<EditMode>("editing");

  return [editMode, setEditMode] as const;
};
