import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Close from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LockIcon from "@mui/icons-material/Lock";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { userHasBillingRole } from "@shared/frontend/userPermissionContext";
import * as SegmentEvents from "@shared/segment-event-names";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { default as classNames, default as classnames } from "classnames";
import DittoProvider, { Ditto } from "ditto-react";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useWebSocket from "react-use-websocket";
import { routes } from "../../defs";
import dittoSource from "../../ditto";
import useSegment, { Track } from "../../hooks/useSegment";
import { OnboardingTaskGroup } from "../../http/workspaceNew";
import history from "../../utils/history";
import style from "./HelpCenter_NEW.module.css";
import CircularProgress from "./circularProgress";
import { OnboardingTaskMap } from "./helpers";
import { GetChecklistState, useChecklist } from "./useChecklist";
// MARK: - Constants

const ONBOARDING_CHECKLIST_PROJECT_ID = "project_630d31fb22f3d9e524d780b9";
const INTERCOM_PROJECT_TOUR_DRAFTING = 338677;
const INTERCOM_PROJECT_TOUR_ID_EDIT = 105981;
const INTERCOM_PROJECT_TOUR_ID_COMMENTER = 195614;

// MARK: - Utility Methods

const openChat = () => {
  window.Intercom("show");
};

const openHelpAndDocs = (track: Track) => {
  track({ event: SegmentEvents.FAQ_OPENED });
  window.open(routes.links.tutorials, "_blank");
};

const calculateTaskGroupCompletion = (task: OnboardingTaskGroup) => {
  const completedTasks = task.tasks.filter((task) => !!task.completed_at);
  return Math.round((completedTasks.length / task.tasks.length) * 100);
};

const taskGroupIsCompleted = (taskGroup: OnboardingTaskGroup) => {
  return taskGroup.tasks.every((task) => !!task.completed_at);
};

const allTasksCompleted = (taskGroups: OnboardingTaskGroup[]) => {
  return taskGroups.every((taskGroup) => taskGroupIsCompleted(taskGroup));
};

const getCurrentTaskGroup = (taskGroups: OnboardingTaskGroup[]) => {
  return taskGroups.find((taskGroup) => !taskGroupIsCompleted(taskGroup));
};

const calculateCurrentTaskGroupProgress = (taskGroups: OnboardingTaskGroup[]) => {
  const currentTaskGroup = getCurrentTaskGroup(taskGroups);
  if (!currentTaskGroup) {
    return 100;
  }
  return calculateTaskGroupCompletion(currentTaskGroup);
};

const openTour = (id) => {
  window.Intercom("startTour", id);
};

/**
 * Generates a checklist item row for a given task
 * @param task The task to generate the row for
 * @returns A JSX element representing the task
 */
const ChecklistItem = ({ title, taskKey, completed_at }: { title: string; taskKey: string; completed_at?: Date }) => {
  return (
    <div className={style.helpCenterTaskRow} data-tip data-for={`checklist-item-${taskKey}`}>
      {completed_at && <CheckCircleIcon className={style.successIcon}></CheckCircleIcon>}
      {!completed_at && <RadioButtonUncheckedIcon className={style.pendingIcon}></RadioButtonUncheckedIcon>}
      <span
        className={classnames(style.helpCenterSubtitle, {
          [style.helpCenterTaskSuccessText]: !!completed_at,
        })}
      >
        {title}
      </span>
      <div className={style.spacer}></div>
      <ReactTooltip
        id={`checklist-item-${taskKey}`}
        place="right"
        effect="solid"
        type="light"
        border={true}
        borderColor="#d4d6d9"
        multiline={true}
        className={style.tooltip}
        clickable={true}
        delayHide={25}
      >
        {OnboardingTaskMap[taskKey]?.img}
        {OnboardingTaskMap[taskKey]?.description}
      </ReactTooltip>
    </div>
  );
};

const TaskList = ({
  taskGroups,
  index,
  taskGroupExpandedState,
  setTaskGroupExpandedState,
}: {
  taskGroups: OnboardingTaskGroup[];
  index: number;
  taskGroupExpandedState: Record<string, boolean>;
  setTaskGroupExpandedState: (state: Record<string, boolean>) => void;
}) => {
  const InProgressTaskGroupTitle = () => (
    <>
      <span className={style.helpCenterTitle}>{taskGroups[index].name}</span>
      <span className={style.spacer}></span>
      <CircularProgress
        className={style.progressBarPadding}
        progress={calculateTaskGroupCompletion(taskGroups[index])}
        size={16}
        thickness={3.5}
      ></CircularProgress>
      <span className={style.helpCenenterTitleSecondary}>
        <Ditto
          componentId="in-progress-percentage"
          variables={{
            percentComplete: `${calculateTaskGroupCompletion(taskGroups[index])}`,
          }}
        ></Ditto>
      </span>
    </>
  );

  if (calculateTaskGroupCompletion(taskGroups[index]) === 100) {
    return (
      <div
        key={taskGroups[index].name}
        className={classnames({
          [style.checklistGroup]: taskGroupExpandedState[taskGroups[index].name],
        })}
      >
        <div className={style.divider}></div>
        <div
          className={classnames(style.helpCenterRow, style.pointerHover)}
          onClick={() =>
            setTaskGroupExpandedState({
              ...taskGroupExpandedState,
              [taskGroups[index].name]: !taskGroupExpandedState[taskGroups[index].name],
            })
          }
        >
          <span className={style.helpCenterTitleDisabled}>{taskGroups[index].name}</span>
          <span className={style.spacer}></span>
          <span className={style.helpCenterSubtitleSuccess}>
            <Ditto textId="text_630d31fcf35cc16b026de20f" variables={{ percentComplete: 100 }}></Ditto>
          </span>
        </div>
        {taskGroupExpandedState[taskGroups[index].name] &&
          taskGroups[index].tasks.map((task) => (
            <ChecklistItem
              {...{
                taskKey: task.key,
                completed_at: task.completed_at,
                ...OnboardingTaskMap[task.key],
              }}
              key={task.key}
            ></ChecklistItem>
          ))}
      </div>
    );
  } else if (index !== 0 && calculateTaskGroupCompletion(taskGroups[index - 1]) !== 100) {
    return (
      <div
        key={taskGroups[index].name}
        className={classnames({
          [style.checklistGroup]: taskGroupExpandedState[taskGroups[index].name],
        })}
      >
        <div className={style.divider}></div>
        <div
          className={classnames(style.helpCenterRow, style.pointerHover)}
          onClick={() =>
            setTaskGroupExpandedState({
              ...taskGroupExpandedState,
              [taskGroups[index].name]: !taskGroupExpandedState[taskGroups[index].name],
            })
          }
        >
          {calculateTaskGroupCompletion(taskGroups[index]) !== 0 ? (
            <InProgressTaskGroupTitle></InProgressTaskGroupTitle>
          ) : (
            <>
              <span
                className={classnames({
                  [style.helpCenterTitleDisabled]: !taskGroupExpandedState[taskGroups[index].name],

                  [style.helpCenterTitle]: taskGroupExpandedState[taskGroups[index].name],
                })}
              >
                {taskGroups[index].name}
              </span>
              <LockIcon className={style.lockIcon}></LockIcon>
              <span className={style.spacer}></span>
              <span className={style.helpCenterSubtitleLighter}>
                <Ditto
                  componentId="additional-tips"
                  variables={{
                    additionaltipscount: taskGroups[index].tasks.length,
                  }}
                ></Ditto>
              </span>
            </>
          )}
        </div>
        {taskGroupExpandedState[taskGroups[index].name] &&
          taskGroups[index].tasks.map((task) => (
            <ChecklistItem
              {...{
                taskKey: task.key,
                completed_at: task.completed_at,
                ...OnboardingTaskMap[task.key],
              }}
              key={task.key}
            ></ChecklistItem>
          ))}
      </div>
    );
  } else {
    return (
      <div key={taskGroups[index].name} className={style.checklistGroup}>
        <div className={style.divider}></div>
        <div className={style.helpCenterRow}>
          <InProgressTaskGroupTitle></InProgressTaskGroupTitle>
        </div>
        {taskGroupExpandedState[taskGroups[index].name] &&
          taskGroups[index].tasks.map((task) => (
            <ChecklistItem
              {...{
                taskKey: task.key,
                completed_at: task.completed_at,
                ...OnboardingTaskMap[task.key],
              }}
              key={task.key}
            ></ChecklistItem>
          ))}
      </div>
    );
  }
};

interface HelpCenterProps {
  checklistState: GetChecklistState;
  className?: string;
  setHelpCenterOpen: (open: boolean) => void;
}

export const HelpCenter = forwardRef((props: HelpCenterProps, ref: React.ForwardedRef<HTMLDivElement | null>) => {
  const { className } = props;
  const segment = useSegment();
  const location = useLocation();
  const isEditEnabled = userHasBillingRole("editor");

  let taskGroups: OnboardingTaskGroup[] = [];
  if (!props.checklistState.loading && props.checklistState.success) {
    taskGroups = props.checklistState.data.task_groups;
  }

  const [taskGroupExpandedState, setTaskGroupExpandedState] = useState(
    taskGroups.reduce<Record<string, boolean>>((acc, taskGroup) => {
      acc[taskGroup.name] = getCurrentTaskGroup(taskGroups) === taskGroup;
      return acc;
    }, {})
  );

  const { user } = useAuthenticatedAuth();

  const openProductUpdates = (track: Track) => {
    track({ event: "Product Updates Opened" });
    window.open(routes.links.whatsNew, "_blank");
  };

  const onProjectPage = useMemo(
    () => location.pathname.includes(routes.nonNavRoutes.project.path.replace(":id", "")),
    [location]
  );

  const isOnDraftingPage = useMemo(() => location.pathname.includes("/__DRAFT__"), [location]);

  const getJob = () => {
    switch (user?.job) {
      case "WRITER":
        return "writers";
      case "DESIGN":
        return "designers";
      case "ENGINEER":
        return "designers";
      case "PRODUCT":
        return "designers";
      case "MARKETING":
        return "marketers";
      default:
        return "you";
    }
  };

  if (props.checklistState.loading) return <></>;

  return (
    <div className={classNames(style.helpCenterWrapper, className)} ref={ref}>
      {!allTasksCompleted(taskGroups) && (
        <div className={style.helpCenter}>
          <div className={style.helpCenterToggleRow}>
            <span className={style.helpCenterTitle}>Getting Started</span>
            <Close className={style.closeIcon} onClick={() => props.setHelpCenterOpen(false)} />
          </div>
          <div className={style.helpCenterRow}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de1ef"></Ditto>
            </span>
          </div>
          {!allTasksCompleted(taskGroups) &&
            taskGroups.map((taskGroup, index) => (
              <TaskList
                key={index}
                taskGroups={taskGroups}
                index={index}
                taskGroupExpandedState={taskGroupExpandedState}
                setTaskGroupExpandedState={setTaskGroupExpandedState}
              ></TaskList>
            ))}
          <div className={style.divider}></div>
          <div className={style.helpCenterLinkRow} onClick={() => openHelpAndDocs(segment.track)}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de21d"></Ditto>
            </span>
            <div className={style.spacer}></div>
            <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
          </div>
          <div className={style.divider}></div>
          <div id="onboarding-launch-chat" className={style.helpCenterLinkRow} onClick={() => openChat()}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de207"></Ditto>
            </span>
            <div className={style.spacer}></div>
            <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
          </div>
        </div>
      )}
      {allTasksCompleted(taskGroups) && (
        <div className={classNames(style.helpCenter, style.completed)}>
          <div className={style.helpCenterLinkRow} onClick={() => openHelpAndDocs(segment.track)}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de21d"></Ditto>
            </span>
            <div className={style.spacer}></div>
            <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
          </div>
          <div className={style.divider}></div>
          <div className={style.helpCenterLinkRow} onClick={() => openChat()}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de207"></Ditto>
            </span>
            <div className={style.spacer}></div>
            <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
          </div>
          {onProjectPage && (
            <>
              <div className={style.divider}></div>
              <div
                className={style.helpCenterLinkRow}
                onClick={() => {
                  console.log("about to start tour");
                  let tourId = INTERCOM_PROJECT_TOUR_DRAFTING;
                  if (isOnDraftingPage) {
                    tourId = INTERCOM_PROJECT_TOUR_DRAFTING;
                  } else if (isEditEnabled) {
                    tourId = INTERCOM_PROJECT_TOUR_ID_EDIT;
                  } else {
                    tourId = INTERCOM_PROJECT_TOUR_ID_COMMENTER;
                  }
                  console.log("starting tour", tourId);
                  openTour(tourId);
                }}
              >
                <span className={style.helpCenterSubtitle}>✨ Tour the project page</span>
                <div className={style.spacer}></div>
                <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
              </div>
            </>
          )}
          <div className={style.divider}></div>
          <div className={style.helpCenterLinkRow} onClick={() => openProductUpdates(segment.track)}>
            <span className={style.helpCenterSubtitle}>
              <Ditto textId="text_630d31fcf35cc16b026de259"></Ditto>
            </span>
            <div className={style.spacer}></div>
            <KeyboardArrowRightIcon className={style.rightChevron}></KeyboardArrowRightIcon>
          </div>
        </div>
      )}
    </div>
  );
});

const HelpCenterWrapper = () => {
  const [checklistState, fetchOnboardingChecklist] = useChecklist();
  const { getTokenSilently, user } = useAuthenticatedAuth();
  const variant = "base";

  const helpCenterShouldShow = () => {
    return (
      ![
        routes.nonNavRoutes.login.path,
        routes.nonNavRoutes.loginSaml.path,
        routes.nonNavRoutes.loginFigma.path,
        routes.nonNavRoutes.authCallback.path,
        routes.nonNavRoutes.logout.path,
        "/trusted",
      ].includes(history.location.pathname) &&
      !history.location.pathname.startsWith("/onboarding") &&
      !history.location.pathname.startsWith("/signin") &&
      !history.location.pathname.startsWith("/workspace/join")
    );
  };

  // Websocket setup
  const { sendMessage, lastMessage, readyState } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    async function sendWsCompsSubscribeMsg() {
      const subscribeToWsCompsMsg = {
        messageType: WEBSOCKET_EVENTS.NEW_WS_COMPS_SUBSCRIPTION,
        token: await getTokenSilently(),
      };
      sendMessage(JSON.stringify(subscribeToWsCompsMsg));
    }

    if (readyState === 1) {
      sendWsCompsSubscribeMsg();
      // Keep the websocket alive
    }
  }, [readyState]);

  // Websocket message handler
  useEffect(() => {
    if (!lastMessage) return;
    const data = JSON.parse(lastMessage.data);
    if (data.messageType === WEBSOCKET_EVENTS.ONBOARDING_WORKSPACE_CHECKLIST_UPDATED) {
      const { key, workspaceId } = data;
      if (workspaceId === user.workspaceId) {
        fetchOnboardingChecklist();
      }
    }
  }, [lastMessage, user]);

  if (!helpCenterShouldShow()) {
    return <></>;
  }

  if (checklistState.loading) {
    return <></>;
  } else if (checklistState.success) {
    return (
      <DittoProvider source={dittoSource} projectId={ONBOARDING_CHECKLIST_PROJECT_ID} variant={variant}>
        <HelpCenter checklistState={checklistState} setHelpCenterOpen={() => {}}></HelpCenter>
      </DittoProvider>
    );
  } else {
    return <></>;
  }
};

export default HelpCenterWrapper;
