import * as httpVariant from "@/http/variantTyped";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Select, { MenuProps, components } from "react-select";

import style from "./VariantFilterDropdown.module.css";

interface IVariantFilterOption {
  id: string | null;
  label: string;
  isSample: boolean;
}

interface VariantFilterDropdownProps {
  className?: string;
  value: IVariantFilterOption | null;
  config?:
    | { type: "workspace" }
    | { type: "components"; isSampleFolder: boolean }
    | { type: "project"; projectId: string };
  onChange: (value: IVariantFilterOption | null) => void;
  onRemoveFilter: () => void;
}

export const VariantFilterDropdown = (props: VariantFilterDropdownProps) => {
  const config = props.config || { type: "workspace" };
  const containerRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleShowDropdown = () => {
    setShowDropdown((v) => !v);
  };

  const variantsLoaded = useRef(false);
  const [variants, setVariants] = useState<{ loaded: false } | { loaded: true; data: IVariantFilterOption[] }>({
    loaded: false,
  });

  const inSampleComponentsFolder = props.config?.type === "components" && props.config.isSampleFolder;

  const filteredVariants = useMemo(() => {
    if (!variants.loaded) {
      return [];
    }

    if (!inSampleComponentsFolder) {
      return variants.data.filter((v) => !v.isSample);
    }
    return variants.data;
  }, [variants, inSampleComponentsFolder]);

  useEffect(
    function loadVariants() {
      // only want to load the variants a single time
      if (variantsLoaded.current) return;

      const getRequest = () => {
        if (config.type === "project") {
          return httpVariant.getForProject(config);
        }

        if (config.type === "components") {
          return httpVariant.getForComponents();
        }

        return httpVariant.getForWorkspace();
      };

      const [request, cancelRequest] = getRequest();

      request.then(({ data: variants }) => {
        setVariants({
          loaded: true,
          data: variants.map((v) => ({ id: v._id, label: v.name, isSample: v.isSample })),
        });
      });
      variantsLoaded.current = true;
      return cancelRequest;
    },
    [config]
  );

  function onChange(option: IVariantFilterOption | null) {
    setShowDropdown(false);
    props.onChange(option);
  }

  useEffect(
    function deactiveDropdownOnClickOutside() {
      if (!showDropdown) {
        return;
      }

      function handleClickOutside(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [showDropdown, setShowDropdown]
  );

  return (
    <div ref={containerRef} className={classNames(style.tagsFilterDropdown, props.className)}>
      <div
        className={classNames({
          [style.dropdownToggle]: true,
          [style.hasValue]: props.value !== null,
        })}
        onClick={toggleShowDropdown}
      >
        <CallSplitIcon className={style.variantIcon} /> {props.value?.label || "Variant"}
        <KeyboardArrowDownIcon className={style.downIcon} />
      </div>
      {showDropdown && (
        <div className={style.tagSelectMenu}>
          <Select
            onRemoveFilter={props.onRemoveFilter}
            menuIsOpen={true}
            options={variants.loaded ? filteredVariants : []}
            styles={customStyles}
            value={props.value}
            onChange={onChange}
            components={{ Menu }}
          />
        </div>
      )}
    </div>
  );
};

const textStyles = {
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "500",
};

const customStyles = {
  clearIndicator: () => ({
    cursor: "pointer",
    "& svg": {
      height: "14px",
      marginRight: "6px",
      width: "14px",
      color: "#aaa",
    },
    "&:hover svg": {
      color: "#666",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0" : "4px",
    borderBottomRightRadius: state.selectProps.menuIsOpen ? "0" : "4px",
    borderColor: "#D9DBDE",
    boxShadow: "none",
    cursor: "cursor",
    minHeight: "0",
    ":hover": {
      borderColor: "#D9DBDE",
    },
  }),
  dropdownIndicator: () => ({ display: "none" }),
  indicatorsContainer: () => ({
    display: "flex",
    paddingBottom: "2px",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  input: (provided: any) => ({
    ...provided,
    ...textStyles,
    fontWeight: "normal",
    margin: "2px 0",
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: "0 4px 11px hsl(0deg 0% 0% / 10%)",
    margin: "0",
  }),
  menuList: (provided: any) => ({
    ...provided,
    border: "1px solid #D9DBDE",
    borderTop: "none",
    maxHeight: "90px",
    padding: "0",
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    ...textStyles,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    ...textStyles,
    backgroundColor: state.isFocused ? "#F9F9F9" : "white",
    borderBottom: "1px solid #D9DBDE",
    borderTop: "none",
    color: "#19191A",
    cursor: "pointer",
    fontWeight: "600",
    overflow: "hidden",
    padding: "4px 10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ":hover": {
      backgroundColor: "#F5F5F5",
    },
    ":active": {
      backgroundColor: "#F5F5F5",
    },
    ":focus": {
      backgroundColor: "#F5F5F5",
    },
    ":last-child": {
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    ...textStyles,
    color: "#19191A33",
    margin: "0",
    transform: "translateY(calc(-50% - 1px))",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...textStyles,
    fontWeight: "600",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "2px 10px",
    cursor: "text",
  }),
};

const Menu = (props: MenuProps) => {
  return (
    <components.Menu {...props}>
      {props.children}
      <div className={style.removeFilter} onClick={props.selectProps?.onRemoveFilter}>
        <DeleteIcon className={style.removeFilterIcon} />
        Remove Filter
      </div>
    </components.Menu>
  );
};
