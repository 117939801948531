import { clearSelectionActionAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import CloseIcon from "@mui/icons-material/Close";
import { useAtomValue, useSetAtom } from "jotai";
import style from "./style.module.css";

function TitleRow() {
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);
  const selectionType = useAtomValue(selectionTypeAtom);

  function close() {
    clearSelectionAction();
  }

  return (
    <div className={style.titleRowWrapper}>
      <Text weight="strong" color="primary">
        Selected {selectionType}
      </Text>
      <Button onClick={close} level="subtle" type="icon" size="small">
        <CloseIcon className={style.closeButton} />
      </Button>
    </div>
  );
}

export default TitleRow;
