export default {
  get: {
    all: {
      url: "/variable",
    },
    instances: {
      url: (variable_id) => `/variable/${variable_id}/instances`,
    },
  },
  post: {
    create: {
      url: "/variable",
      body: ({ variable }) => ({ variable }),
    },
    deleteVariables: {
      url: `/variable/deleteVariables`,
      body: ({ variableIds }) => ({ variableIds }),
    },
  },
  put: {
    update: {
      url: (variable_id) => `/variable/${variable_id}`,
      body: ({ variable }) => ({ variable }),
    },
  },
  delete: {
    delete: {
      url: (variable_id) => `/variable/${variable_id}`,
    },
  },
};
