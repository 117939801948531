import { useFigmaAuth } from "@/store/FigmaAuthContext";
import * as resyncErrors from "@shared/frontend/resyncErrors";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFProject } from "@shared/types/Project";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import type { FrameIdsByPageId } from "../../../../shared/types/figma";
import useSegment from "../../../hooks/useSegment";
import http, { API } from "../../../http";
import { GROUP_REDUCER_TYPES, GroupReducerAction } from "./groupStateActions";
import { TextItem } from "./types";

export interface ResyncToast {
  title: string;
  body: string | JSX.Element | JSX.Element[];
  autoHide?: boolean;
  displayAccountLink?: boolean;
}

export type ProjectWithFigmaConnection<ProjectType extends IFProject> = ProjectType & {
  integrations: {
    figma: {
      file_id: string;
      resynced_at: Date;
    };
  };
};

export const getProjectHasFigmaConnection = <ProjectType extends IFProject>(
  p: ProjectType | null
): p is ProjectWithFigmaConnection<ProjectType> => "file_id" in (p?.integrations?.figma || {});

export interface ResyncState {
  RESYNC_WARNING_KEY: string;
  resyncLoading: boolean;
  resyncToast?: ResyncToast;
  handleInitialResync: () => void;
  resync: (newVisibleFrames?: FrameIdsByPageId | null, newHiddenFrames?: FrameIdsByPageId | null) => Promise<void>;
  showResyncWarning: boolean;
  setShowResyncWarning: React.Dispatch<React.SetStateAction<boolean>>;
  showResyncSucessToast: boolean;
  showResyncDeletionToast: boolean;
  showHighlightNew: boolean;
  setShowHighlightNew: React.Dispatch<React.SetStateAction<boolean>>;
  queuePostResyncExecution: (func: () => void, timeout?: number) => void;
  isFigmaAuthenticated: boolean;
}

interface UseResyncStateProps {
  projectId: string;
  doc: React.StatePair<IFProject | null>;
  setSuggestedCompId: React.Dispatch<React.SetStateAction<string | null>>;
  groupStateDispatch: React.Dispatch<GroupReducerAction>;
  setUpdateDocHistory: React.Dispatch<React.SetStateAction<boolean>>;
  figmaFileId?: string;
  selectedComp: TextItem | null;
  multiSelectedIds: string[];
  multiSelectOn: boolean;
  setPreviewsJobId: React.Dispatch<React.SetStateAction<string | null>>;
  updateFramePreviewsMap: (LinkedFullProject) => void;
  getDocVariants: () => Promise<void>;
  fetchProjectBranchInfo: () => Promise<void>;
  unselectAll: () => void;
  showToast: boolean;
}

const useResyncState = ({
  projectId,
  setSuggestedCompId,
  groupStateDispatch,
  doc: [doc, setDoc],
  setUpdateDocHistory,
  figmaFileId,
  selectedComp,
  multiSelectedIds,
  multiSelectOn,
  setPreviewsJobId,
  updateFramePreviewsMap,
  getDocVariants,
  fetchProjectBranchInfo,
  unselectAll,
  showToast,
}: UseResyncStateProps): ResyncState => {
  const postResyncExecutionQueue = useRef<(() => any)[]>([]);

  const queuePostResyncExecution = (
    func: () => any,
    /**
     * Maximum amount of time to wait for a resync to finish before executing the function
     * anyway; useful for race conditions when the defer call may or may happen
     * before resync finishes.
     */
    timeoutInterval?: number
  ) => {
    let timer: NodeJS.Timeout | null = null;
    if (timeoutInterval !== undefined) {
      timer = setTimeout(() => {
        func();
      }, timeoutInterval);
    }

    postResyncExecutionQueue.current.push(() => {
      if (timer) clearTimeout(timer);
      func();
    });
  };

  const segment = useSegment();
  // Internal
  const POLL_TIME_LIMIT = 10 * 60 * 1000; //10 minutes
  const isInitialResync = useRef(false);
  const { loading: loadingFigmaAuth, isFigmaAuthenticated } = useFigmaAuth();
  // Public
  const RESYNC_WARNING_KEY = "resync-warning-dismissed";
  const [resyncLoading, setResyncLoading] = useState(false);
  const [resyncToast, setResyncToast] = useState<ResyncToast | undefined>();
  const [showResyncSucessToast, setShowResyncSuccessToast] = useState(false);
  const [showResyncDeletionToast, setShowResyncDeletionToast] = useState(false);
  const [showResyncWarning, setShowResyncWarning] = useState(false);
  const [showHighlightNew, setShowHighlightNew] = useState(true);
  const initialResync = useRef(true);
  const [cookies] = useCookies(["impersonateUser"]);

  function handleInitialResync() {
    isInitialResync.current = true;
  }

  useEffect(
    function handleInitialResync() {
      const isBranch = Boolean(
        // @ts-ignore
        doc?.integrations?.figma?.branch_id
      );
      const isMergedBranch = isBranch && doc?.is_locked;
      const shouldSkipResync = isMergedBranch || !doc;
      // If the current user is a Ditto team member debugging
      // customer issues, we disable automatic resync to avoid
      // scenarios where actions from an automatic resync get
      // attributed to the customer.
      if (cookies.impersonateUser) {
        initialResync.current = false;
        return;
      }

      const hasRequiredData = isFigmaAuthenticated && getProjectHasFigmaConnection(doc);

      if (!(hasRequiredData && initialResync.current) || shouldSkipResync) {
        return;
      }

      resync();
      initialResync.current = false;
    },
    [isFigmaAuthenticated, initialResync.current, doc, cookies]
  );

  const showFigmaAuthErrorToast = (figToken) => {
    if (!showToast) {
      if (figToken.message === "not_authenticated") {
        setResyncToast({
          title: "⚠️ No Figma account connected",
          body: "To sync your edits to Figma, you'll need to ",
          displayAccountLink: true,
          autoHide: false,
        });
      } else if (figToken.message === "token_expired") {
        setResyncToast({
          title: "⚠️ Figma account connection has expired",
          body: "Please ",
          displayAccountLink: true,
          autoHide: false,
        });
      } else if (figToken.message === "rate_limit") {
        setResyncToast({
          title: "⚠️ Figma request limit hit",
          body: "Please wait 2 minutes and reload the page.",
          displayAccountLink: false,
          autoHide: false,
        });
      } else {
        setResyncToast({
          title: "⚠️ Error with Figma connection",
          body: "Please ",
          displayAccountLink: true,
          autoHide: false,
        });
      }
    }
  };

  // poll for response from /job/:id
  const pollForResyncResponse = async function (id: string, interval: number, startedAt: Date) {
    try {
      const { url } = API.jobs.get.pollResync;
      let { data: result } = await http.get(url(id));
      let { state } = result;
      if (state === "active" || state === "stuck" || state === "waiting") {
        await new Promise((res) => setTimeout(res, interval));
        // stop polling after timelimit reached
        if (new Date().getTime() - startedAt.getTime() > POLL_TIME_LIMIT) {
          console.error("Resync polling limit reached");
          return { data: { errorStatus: 400 } };
        }
        result = await pollForResyncResponse(id, interval, startedAt);
      }
      return result;
    } catch (e) {
      console.error("Error while polling for resync response is: ", e.message);
    }
  };

  function checkIfSelectedIdStillExists(deletions: any[]) {
    for (const elem of deletions) {
      if ((selectedComp && elem.comp_ID === selectedComp._id) || multiSelectedIds.includes(elem.comp_ID)) {
        unselectAll();
        setTimeout(function () {
          setShowResyncDeletionToast(true);
        }, 3100);
        setTimeout(function () {
          setShowResyncDeletionToast(false);
        }, 8100);
        return;
      }
    }
  }

  const resync = async (
    newVisibleFramesByPageId: FrameIdsByPageId | null | undefined = null,
    newHiddenFramesByPageId: FrameIdsByPageId | null | undefined = null,
    updatedFileId = null
  ) => {
    const file_id = updatedFileId || figmaFileId;

    // don't try to resync sample project before it's been activated with a fileId
    if (file_id === "sampleProject") {
      return;
    }
    setResyncLoading(true);
    setSuggestedCompId(null);
    if (!loadingFigmaAuth && !isFigmaAuthenticated) {
      setResyncLoading(false);
      setResyncToast(resyncErrors.noFigmaAccountConnected);
      return;
    }

    const { url, body } = API.api.post.resync;
    try {
      const longResyncTimeout = setTimeout(() => {
        segment.track({
          event: SegmentEvents.LONG_RESYNC_TIME,
          properties: { doc_id: projectId },
        });
        const projectResyncWarningDismissed = localStorage.getItem(`${RESYNC_WARNING_KEY}-${projectId}`) === "true";
        if (!projectResyncWarningDismissed) {
          setShowResyncWarning(true);
          // only show for 5s
          setTimeout(() => {
            setShowResyncWarning(false);
          }, 5000);
        }
      }, 10000);

      if (!file_id) {
        throw new Error("Unable to resync without a figma file id");
      }

      if (doc?.integrations.figma.branch_id) {
        fetchProjectBranchInfo();
      }

      const {
        data: { id: resyncJobId },
      } = await http.post<{ id: string }>(
        url(projectId),
        body({
          figma_file_ID: file_id,
          newVisibleFramesByPageId,
          newHiddenFramesByPageId,
          fromFigma: false,
        })
      );

      const { data: resyncResult } = await pollForResyncResponse(resyncJobId, 1000, new Date());
      clearTimeout(longResyncTimeout);

      if (resyncResult.isError) {
        const wasDeleted = resyncResult.type === "deleted";
        if (wasDeleted) {
          setResyncToast(resyncErrors.projectDeleted);
          return;
        }

        const wasPageFailure = resyncResult.type === "pages";
        if (wasPageFailure) {
          const pageNameByPageId = new Map(
            (doc as any).integrations.figma.selected_pages.map((page) => [page.figma_id, page.name])
          );
          const pageNames = resyncResult.failedPages.map((page) => pageNameByPageId.get(page.pageId) || page.pageId);
          setResyncToast(resyncErrors.pageFailure(pageNames));
          return;
        }

        const wasAutoImportFailure = resyncResult.type === "auto-import";
        if (wasAutoImportFailure) {
          const pageNameByPageId = new Map(
            (doc as any).integrations.figma.selected_pages.map((page) => [page.figma_id, page.name])
          );
          const pageNames = resyncResult.failedAutoImports.map(
            (page) => pageNameByPageId.get(page.pageId) || page.pageId
          );
          setResyncToast(resyncErrors.autoImportFailure(pageNames));
          setDoc((prev) => {
            if (prev?.feature_flags?.autoImportFrames?.enabled) {
              const selectedPages = prev?.feature_flags.autoImportFrames.selectedPages || {};
              resyncResult.failedAutoImports.forEach((page) => {
                delete selectedPages[page.pageId];
              });
              prev.feature_flags.autoImportFrames.selectedPages = selectedPages;
            }
            return prev;
          });
          return;
        }

        const errorStatus = resyncResult.data?.status;
        if (errorStatus && resyncErrors.errorByStatus[errorStatus]) {
          setResyncToast(resyncErrors.errorByStatus[errorStatus]);
          return;
        }

        return;
      }

      postResyncExecutionQueue.current.forEach((func) => func());
      postResyncExecutionQueue.current = [];

      getDocVariants();

      if (isInitialResync.current) {
        isInitialResync.current = false;
      }
      // reset and replace all artboards when finished resyncing
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.GROUP_FETCHING_FINISHED,
        status: true,
      }); // stop pagination
      groupStateDispatch({
        type: GROUP_REDUCER_TYPES.REPLACE_GROUPS,
        groups: resyncResult.document.groups,
      });

      // show resync success toast
      setShowResyncSuccessToast(true);
      setTimeout(function () {
        setShowResyncSuccessToast(false);
      }, 3000);

      setDoc(resyncResult.document); // updates title, time_last_resync, etc. (relied upon by highlighting new changes)
      updateFramePreviewsMap(resyncResult.document);
      // later on: only update history if have edits from Figma
      setUpdateDocHistory(true);
      setShowHighlightNew(true);

      if (resyncResult.deletions.length > 0 && (selectedComp || (multiSelectOn && multiSelectedIds.length > 0))) {
        checkIfSelectedIdStillExists(resyncResult.deletions);
      }

      setPreviewsJobId(resyncResult.previewsJobId);
    } catch (error) {
      console.error("in doc.jsx, error resyncing: ", error.message);
      setResyncToast(resyncErrors.generic);
      segment.track({
        event: "Resync error",
        properties: {
          message: error.message,
          error: JSON.stringify(error),
        },
      });
    } finally {
      setResyncLoading(false);
    }
  };

  return {
    RESYNC_WARNING_KEY,
    resyncLoading,
    resyncToast,
    showResyncWarning,
    setShowResyncWarning,
    showResyncSucessToast,
    showHighlightNew,
    setShowHighlightNew,
    showResyncDeletionToast,
    handleInitialResync,
    resync,
    queuePostResyncExecution,
    isFigmaAuthenticated,
  };
};

export default useResyncState;
