type MoveAction = {
  /**
   * The IDs of the items to be moved. These do not need to necessarily need to be present in the
   * collection, but will be present in the `allItemIds` array.
   */
  itemIds: string[];
  /**
   * Where the items should be moved to.
   */
  referenceItemId: string | undefined;
  /**
   * Whether the items should be moved before or after the reference item.
   */
  direction: "before" | "after";
  /**
   * The IDs of all items in the collection.
   */
  itemIdsInCollection: string[];
  /**
   * All items in all collections, in order.
   */
  allItemIds: string[];
};

export function ensureContiguousMoveAction(moveAction: MoveAction) {
  const itemIdIndexMap = new Map(moveAction.allItemIds.map((itemId, index) => [itemId, index]));

  const sortedItemIdsToMove = moveAction.itemIds.sort((a, b) => {
    const aIndex = itemIdIndexMap.get(a);
    const bIndex = itemIdIndexMap.get(b);

    if (aIndex === undefined || bIndex === undefined) {
      throw new Error("Item ID not found in allItemIds");
    }

    return aIndex - bIndex;
  });

  return {
    ...moveAction,
    itemIds: sortedItemIdsToMove,
  };
}

/**
 * Normalizes a move action to ensure that it doesn't self-reference.
 *
 * If our original reference item is present in the items we're moving, we need to change it to
 * a different item in the same collection. This new item should be above or below the original,
 * and should be 'contiguous' with the set of items we're moving.
 *
 * Namely, we should iterate up until we find an item that is not in the set of items we're moving,
 * and if that doesn't exist, we should iterate down until an item is found. If no such item exists,
 * we should unset the reference item.
 */
export function preventSelfReference(action: MoveAction) {
  const { itemIds, referenceItemId, direction, itemIdsInCollection } = action;

  if (referenceItemId === undefined) {
    return action;
  }

  const itemIdIndexMap = new Map(itemIdsInCollection.map((itemId, index) => [itemId, index]));
  const itemIdSet = new Set(itemIds);

  let seek = itemIdIndexMap.get(referenceItemId);
  let seekReferenceId = referenceItemId;

  if (seek === undefined) {
    throw new Error("Reference item ID not found in collection");
  }

  let hadToSeek = false;

  while (seek >= 0) {
    seekReferenceId = itemIdsInCollection[seek];
    if (!itemIdSet.has(seekReferenceId)) {
      const newDirection = hadToSeek ? "after" : direction;
      return { ...action, referenceItemId: seekReferenceId, direction: newDirection };
    }
    hadToSeek;
    seek--;
  }

  seek = itemIdIndexMap.get(referenceItemId)!;
  seekReferenceId = referenceItemId;

  while (seek < itemIdsInCollection.length) {
    seekReferenceId = itemIdsInCollection[seek];
    if (!itemIdSet.has(seekReferenceId)) {
      return { ...action, referenceItemId: seekReferenceId, direction: "before" as const };
    }
    seek++;
  }

  return { ...action, referenceItemId: undefined };
}

export function normalizeMoveAction(action: MoveAction) {
  return ensureContiguousMoveAction(preventSelfReference(action));
}

export function executeMoveAction(action: MoveAction) {
  const { itemIds, referenceItemId, direction, itemIdsInCollection } = action;

  // Create a copy of the allItemIds to avoid mutating the original array
  let newCollection = [...itemIdsInCollection];

  // If we're inserting after a reference item, we need to do it in reverse order since we are
  // inserting them one at a time.
  //
  // e.g. if our array is ["a", "b", "c"] and we want to insert ["X", "Y"] after "b",
  // -- we first insert "Y": ["a", "b", "Y", "c"]
  // -- then "X": ["a", "b", "X", "Y", "c"]
  const idsToMove = [...itemIds];
  if ((action.direction === "after" && referenceItemId) || (action.direction === "before" && !referenceItemId))
    idsToMove.reverse();

  for (const itemId of idsToMove) {
    // Remove the item from the array if it exists.
    const index = newCollection.indexOf(itemId);

    if (index !== -1) {
      newCollection.splice(index, 1);
    }

    // Find the index of the reference item. If there is none, we insert at the end.
    const targetIndex = (() => {
      if (referenceItemId === undefined) {
        return direction === "before" ? 0 : newCollection.length;
      }

      const referenceIndex = newCollection.indexOf(referenceItemId);

      if (referenceIndex === -1) {
        throw new Error("Reference item ID not found in allItemIds");
      }

      // Determine the target index based on the direction
      const targetIndex = direction === "before" ? referenceIndex : referenceIndex + 1;

      return targetIndex;
    })();

    // Insert the item at the target index
    newCollection.splice(targetIndex, 0, itemId);
  }

  return newCollection;
}
