import classnames from "classnames";
import React from "react";

import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { IFVariable } from "@shared/types/Variable";
import classNames from "classnames";
import style from "./style.module.css";

export interface VariableItemProps {
  variable: IFVariable;
  isSelected: boolean;
  isMultiSelected: boolean;
  toggleSelected: () => void;
  handleSelectVariable: (variable: IFVariable) => void;
}

const VariableItem = ({
  variable,
  isSelected,
  isMultiSelected,
  toggleSelected,
  handleSelectVariable,
}: VariableItemProps) => {
  const canEdit = userHasResourcePermission("variable_folder:edit");

  return (
    <div
      id={variable._id}
      className={classnames([style.variableItem], {
        [style.selected]: isSelected,
        [style.multiSelected]: isMultiSelected,
      })}
      onClick={() => handleSelectVariable(variable)}
    >
      <div
        data-testid={`variable-titlesection-${variable.name}`}
        className={classnames([style.titleSection], {
          [style.draft]: variable.instances.length === 0,
        })}
      >
        <div className={style.variableSpanWrapper}>
          <span className={style.variableSpan}>{variable.name}</span>
        </div>
        <div className={style.type}>{variable.type}</div>
      </div>
      <div className={style.infoSectionContainer}>
        <div data-testid={`variable-infosection-${variable.name}`} className={style.infoSection}>
          {(variable.type === "string" || variable.type === "number") && (
            <>
              <div className={style.example}>
                Example{" "}
                <div className={style.variableSpanWrapper}>
                  <span className={style.variableSpan}>{variable.data.example}</span>
                </div>
              </div>
              {variable.data.fallback && (
                <div className={style.example}>
                  Fallback{" "}
                  <div className={style.variableSpanWrapper}>
                    <span className={style.variableSpan}>{variable.data.fallback}</span>
                  </div>
                </div>
              )}
            </>
          )}
          {variable.type === "hyperlink" && (
            <>
              <div className={style.example}>
                Text{" "}
                <div className={style.variableSpanWrapper}>
                  <span className={style.variableSpan}>{variable.data.text}</span>
                </div>
              </div>
              <div className={style.example}>
                URL{" "}
                <div className={style.variableSpanWrapper}>
                  <span className={style.variableSpan}>{variable.data.url}</span>
                </div>
              </div>
            </>
          )}
          {variable.type === "list" && (
            <>
              <div className={classNames(style.example, style.list)}>
                Values{" "}
                {variable.data.map((e) => (
                  <div className={style.variableSpanWrapper} key={e}>
                    <span className={style.variableSpan}>{e}</span>{" "}
                  </div>
                ))}
              </div>
            </>
          )}
          {variable.type === "map" && (
            <>
              <div className={classNames(style.example, style.map)}>
                Key-Value Pairs{" "}
                {Object.entries(variable.data).map(([key, value]) => (
                  <div className={style.row} key={key}>
                    <div className={style.variableSpanWrapper}>
                      <span className={style.variableSpan}>{key}</span>
                    </div>
                    <span className={style.arrow}>{"->"}</span>
                    <div className={style.variableSpanWrapper}>
                      <span className={style.variableSpan}>{value}</span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {canEdit && (
          <div
            className={classNames(style.checkboxContainer, {
              [style.checked]: isMultiSelected,
            })}
          >
            <input
              type="checkbox"
              className={style.checkbox}
              checked={isMultiSelected}
              onChange={toggleSelected}
              onClick={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default VariableItem;
