import { addToMapSet, treeItemSort } from "@/views/Components/components/comp-library-nav/lib";
import { LibraryNavState } from "@/views/Components/components/comp-library-nav/libraryNavState";
import { ComponentNames, getBlockTreeItem, getComponentTreeItem, getGroupTreeItem } from "@shared/lib/components";

function getCreationMaps(componentMap: Record<string, ComponentNames>) {
  const componentsToAddByGroupName = new Map<string, Set<string>>();
  const componentsToAddByGroupBlockName = new Map<string, Set<string>>();

  Object.entries(componentMap).forEach(([componentId, component]) => {
    const groupName = component.groupName || "__root__";
    if (component.blockName) {
      const key = `${groupName}/${component.blockName}`;
      addToMapSet(componentsToAddByGroupBlockName, key, componentId);
    } else {
      addToMapSet(componentsToAddByGroupName, groupName, componentId);
    }
  });

  return {
    componentsToAddByGroupName,
    componentsToAddByGroupBlockName,
  };
}

export function componentsCreatedAction(treeState: LibraryNavState, componentMap: Record<string, ComponentNames>) {
  const { treeItems } = treeState;

  const { componentsToAddByGroupName, componentsToAddByGroupBlockName } = getCreationMaps(componentMap);

  const rootAddSet = componentsToAddByGroupName.get("__root__");
  componentsToAddByGroupName.delete("__root__");

  let newTreeItems = treeItems.map((item) => {
    if (item.type === "group") {
      const groupAddSet = componentsToAddByGroupName.get(item.name);
      const newGroupChildren =
        item.children?.map((child) => {
          // in this loop we're adding any components to blocks in this group
          if (child.type === "block") {
            const groupBlockNameKey = `${item.name}/${child.name}`;
            const blockAddSet = componentsToAddByGroupBlockName.get(groupBlockNameKey);

            const newBlockChildren = [
              ...(child.children || []),
              ...Array.from(blockAddSet || []).map((componentId) =>
                getComponentTreeItem(componentId, componentMap[componentId].componentName)
              ),
            ].sort(treeItemSort);

            componentsToAddByGroupBlockName.delete(groupBlockNameKey);

            return { ...child, children: newBlockChildren };
          }
          return child;
        }) || [];

      // this is where we add components that just live at the group level
      const groupComponentsToAdd = Array.from(groupAddSet || []).map((componentId) =>
        getComponentTreeItem(componentId, componentMap[componentId].componentName)
      );

      newGroupChildren.push(...groupComponentsToAdd);

      componentsToAddByGroupName.delete(item.name);

      return { ...item, children: newGroupChildren.sort(treeItemSort) };
    }
    return item;
  });

  // Add new root-level components
  const rootChildrenToAdd = Array.from(rootAddSet ?? []).map((componentId) =>
    getComponentTreeItem(componentId, componentMap[componentId].componentName)
  );

  newTreeItems?.push(...rootChildrenToAdd);

  // Add new groups and blocks
  componentsToAddByGroupName.forEach((componentIds, groupName) => {
    const componentChildren = Array.from(componentIds)
      .map((componentId) => getComponentTreeItem(componentId, componentMap[componentId].componentName))
      .sort(treeItemSort);

    newTreeItems.push(getGroupTreeItem(groupName, componentChildren));
  });

  componentsToAddByGroupBlockName.forEach((componentIds, groupBlockName) => {
    const [groupName, blockName] = groupBlockName.split("/");
    const newBlock = getBlockTreeItem(
      blockName,
      groupName,
      Array.from(componentIds).map((componentId) =>
        getComponentTreeItem(componentId, componentMap[componentId].componentName)
      )
    );

    let foundGroup = false;
    newTreeItems = newTreeItems.map((item) => {
      if (item.type === "group" && item.name === groupName) {
        foundGroup = true;
        return {
          ...item,
          children: [...(item.children || []), newBlock].sort(treeItemSort),
        };
      }
      return item;
    });

    if (!foundGroup) {
      newTreeItems.push(getGroupTreeItem(groupName, [newBlock]));
    }
  });

  return { ...treeState, treeItems: newTreeItems.sort(treeItemSort) };
}
