import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";

import ButtonPrimary from "@/components/button/buttonprimary";
import * as httpVariant from "@/http/variantTyped";

import style from "./EditVariantNameModal.module.css";

interface IProps {
  variantName: string;
  onHide: () => void;
  onUpdateName: (updatedName: string) => void | Promise<void>;
}

const EditVariantNameModal = (props: IProps) => {
  const [updatedName, setUpdatedName] = useState(props.variantName);
  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUpdatedName(value);
  };

  const [existingVariants, setExistingVariants] = useState<{ name: string; apiID: string }[]>([]);
  useEffect(function loadVariants() {
    const [request, cancelRequest] = httpVariant.getForWorkspace();
    request.then(({ data: variants }) => setExistingVariants(variants));
    return cancelRequest;
  }, []);

  const variantNameSet = useMemo(() => new Set(existingVariants.map((v) => v.name.toLowerCase())), [existingVariants]);
  const variantNameExists =
    updatedName.toLowerCase() !== props.variantName.toLowerCase() && variantNameSet.has(updatedName.toLowerCase());

  const [isRenaming, setIsRenaming] = useState(false);
  const renameButtonDisabled = variantNameExists || updatedName.trim().length == 0 || isRenaming;
  const onRenameClick = async () => {
    if (renameButtonDisabled) return;
    setIsRenaming(true);
    props.onUpdateName(updatedName);
    // we never set isRenaming to false because the component should unmount at this point
    // and we don't want memory leaks
  };

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={props.onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Variant Name</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={props.onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.body}>
          <input value={updatedName} onChange={onNameChange} />
          {variantNameExists && (
            <div className={style.error}>
              This variant already exists in your workspace! Variant names must be unique.
            </div>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <ButtonPrimary
            text={isRenaming ? "Renaming..." : "Rename"}
            onClick={onRenameClick}
            disabled={renameButtonDisabled}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default EditVariantNameModal;
