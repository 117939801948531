import {
  derivedSelectedTextItemsAtom,
  detailsPanelEditStateAtom,
  detailsPanelPropsAtom,
  selectedItemsVariantsAtom,
  selectedTextItemsCountAtom,
} from "@/stores/ProjectSelection";
import {
  attachVariantActionAtom,
  blockSelectedVariantIdFamilyAtom,
  deferredVariantsAtom,
  updateTextItemVariantActionAtom,
} from "@/stores/Variants";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import { ComboboxOption } from "@ds/molecules/BaseCombobox";
import AddVariantsForm, { AddVariantData, AddVariantUpdateType } from "@ds/organisms/AddVariantForm";
import TextItemVariant from "@ds/organisms/TextItemVariant";
import { RichTextInputProps } from "@shared/types/RichText";
import { ITextItemVariantUpdate } from "@shared/types/TextItem";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RichTextInput } from "../Metadata";
import style from "./style.module.css";

function VariantsPanel() {
  const selectedTextItems = useAtomValue(derivedSelectedTextItemsAtom);
  const selectedTextItemsCount = useAtomValue(selectedTextItemsCountAtom);
  const selectedTextItemVariants = useAtomValue(selectedItemsVariantsAtom);
  const variants = useAtomValue(deferredVariantsAtom);
  const blockSelectedVariantId = useAtomValue(blockSelectedVariantIdFamilyAtom(selectedTextItems[0]?.blockId ?? null));
  const detailsPanelProps = useAtomValue(detailsPanelPropsAtom);

  const setAttachVariantAction = useSetAtom(attachVariantActionAtom);
  const setDetailsPanelEditState = useSetAtom(detailsPanelEditStateAtom);
  const setUpdateTextItemVariantAction = useSetAtom(updateTextItemVariantActionAtom);

  const [editingVariants, setEditingVariants] = useState(false);
  const [variantsFilter, setVariantsFilter] = useState("");

  const richTextInput = useCallback((richTextInputProps: RichTextInputProps) => {
    return <RichTextInput {...richTextInputProps} />;
  }, []);

  // Variant options for add variant form
  const variantOptions: ComboboxOption[] = useMemo(
    () =>
      variants
        // Remove the currently selected variant from the options in the combobox for adding a new variant
        .filter(
          (variant) =>
            !Object.values(selectedTextItemVariants).some(
              (selectedVariant) => selectedVariant.variantId === variant._id
            )
        )
        .map((variant) => ({ value: variant._id, label: variant.name })),
    [variants, selectedTextItemVariants]
  );
  const selectedTextItem = selectedTextItems[0];

  // Filter that's available when there are 3 or more variants on a text item
  const filteredAndSortedSelectedTextItemVariants = useMemo(() => {
    if (selectedTextItemsCount === 1) {
      return (
        Object.values(selectedTextItemVariants)
          .filter((variant) => variant.name.toLowerCase().includes(variantsFilter.toLowerCase()))
          .sort((a, b) => {
            // Put matching variant first
            if (a.variantId === blockSelectedVariantId) return -1;
            if (b.variantId === blockSelectedVariantId) return 1;
            return 0;
          }) ?? []
      );
    }
    return [];
  }, [blockSelectedVariantId, selectedTextItemsCount, selectedTextItemVariants, variantsFilter]);

  const defaultAddVariantOption: ComboboxOption | undefined = useMemo(() => {
    if (!detailsPanelProps.variants?.defaultVariant) return;
    return {
      value: detailsPanelProps.variants.defaultVariant.id,
      label: detailsPanelProps.variants.defaultVariant.name,
    };
  }, [detailsPanelProps.variants?.defaultVariant]);

  useEffect(() => {
    setEditingVariants(false);
    setVariantsFilter("");
  }, [selectedTextItem, blockSelectedVariantId]);

  // Sync editingVariants state with panel props
  useEffect(() => {
    setEditingVariants(detailsPanelProps.variants?.showAddForm ?? false);
  }, [detailsPanelProps.variants?.showAddForm]);

  // Multiselection not supported for variants, redirect to edit panel
  if (selectedTextItems.length !== 1) {
    setDetailsPanelEditState("EDIT");
    return;
  }

  function addVariant() {
    setEditingVariants(true);
  }

  function onCancelVariantAdd() {
    setEditingVariants(false);
  }

  function onSaveVariant(variant: AddVariantData, updateType: AddVariantUpdateType) {
    setAttachVariantAction({ variant, updateType, textItemId: selectedTextItem._id });
    setEditingVariants(false);
  }

  function onUpdateTextItemVariant(update: ITextItemVariantUpdate) {
    setUpdateTextItemVariantAction({ textItemId: selectedTextItem._id, update });
  }

  return (
    <div className={style.VariantsPanel}>
      <div className={style.baseVariantSection}>
        <Text size="small" weight="strong">
          Base text
        </Text>
        <TextInput value={selectedTextItem.text} disabled />
      </div>
      {!editingVariants && (
        <div className={style.addVariantButtonWrapper}>
          <Button className={style.addVariantButton} expansion="block" level="outline" onClick={() => addVariant()}>
            Add variant
          </Button>
        </div>
      )}
      {editingVariants && (
        <AddVariantsForm
          variantOptions={variantOptions}
          placeholder={selectedTextItem.text}
          defaultOption={defaultAddVariantOption}
          onCancel={onCancelVariantAdd}
          onSave={onSaveVariant}
          RichTextInput={richTextInput}
        />
      )}
      {/* Show filter when 3 or more variants on a text item */}
      {selectedTextItem.variants.length >= 3 && (
        <div className={style.filterVariantsInputWrapper}>
          <TextInput value={variantsFilter} onChange={setVariantsFilter} placeholder="Filter variants..." />
        </div>
      )}
      {filteredAndSortedSelectedTextItemVariants.map((wsVariant, idx) => {
        // hide top border on first variant when filter is visible
        const borderTopHidden = idx === 0 && selectedTextItem.variants.length >= 3;
        const textItemVariant = {
          ...selectedTextItemVariants[wsVariant.variantId],
          textItemId: selectedTextItem._id,
        };
        return (
          <TextItemVariant
            className={borderTopHidden ? style.borderTopHidden : ""}
            key={`${wsVariant.variantId}-${textItemVariant.textItemId}`}
            variantId={textItemVariant.variantId}
            variantStatus={textItemVariant.status}
            variantRichText={textItemVariant.rich_text}
            variantText={textItemVariant.text}
            variantVariables={textItemVariant.variables}
            variantName={wsVariant.name}
            placeholder={selectedTextItem.text}
            onSave={onUpdateTextItemVariant}
            RichTextInput={richTextInput}
          />
        );
      })}
    </div>
  );
}

export default VariantsPanel;
