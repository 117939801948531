import { useWorkspace } from "@/store/workspaceContext";
import { DEFAULT_PERMISSION_GROUPS } from "@shared/lib/PermissionGroups";
import React from "react";
import style from "./permissionTags.module.css";

interface Props {
  permissions: string[];
  permissionGroups: { _id: string; name: string }[];
}

export default function PermissionTags(props: Props) {
  const { workspaceInfo } = useWorkspace();

  const defaultPermissionGroups = DEFAULT_PERMISSION_GROUPS.reduce((acc, group) => {
    acc[group._id] = group;
    return acc;
  }, {});

  function capitalize(str: string | undefined) {
    if (!str) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div>
      {props.permissionGroups.length > 0 && (
        <div data-testid="permissions">
          {props.permissions
            .filter((permission) => {
              // for non-enterprise workspaces, only show default permission groups
              if (workspaceInfo?.plan !== "enterprise") {
                return defaultPermissionGroups[permission];
              } else return props.permissionGroups?.find((g) => g._id === permission);
            })
            .map((group) => {
              return (
                <div key={group} className={style.permissions}>
                  {capitalize(props.permissionGroups?.find((g) => g._id === group)?.name)}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
