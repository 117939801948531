const ROOT = "/variable-folder";

export default {
  get: {
    folders: {
      url: ROOT,
    },
  },
  post: {
    createFolder: {
      url: ROOT,
      body: (name: string, variable_ids: string[]) => ({
        name,
        variable_ids,
      }),
    },
    moveVariablesToFolder: {
      url: (folderId: string | null) => `${ROOT}/${folderId || "none"}/variables`,
      body: (variable_ids: string[]) => ({ variable_ids }),
    },
  },
  put: {
    updateFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
      body: (name: string) => ({ name }),
    },
  },
  delete: {
    deleteFolder: {
      url: (folderId: string) => `${ROOT}/${folderId}`,
    },
  },
};
