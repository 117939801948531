import {
  addNewTextItemActionAtom,
  cancelNewBlockActionAtom,
  newBlockAtom,
  saveNewBlockActionAtom,
} from "@/stores/Editing";
import TextItemBlock from "@ds/molecules/TextItemBlock";
import { useAtom, useSetAtom } from "jotai";
import React from "react";

interface NewBlockWrapperProps {
  className?: string;
}

const NewBlockWrapper = (props: NewBlockWrapperProps) => {
  const [newBlock, setNewBlock] = useAtom(newBlockAtom);
  const saveNewBlockAction = useSetAtom(saveNewBlockActionAtom);
  const cancelNewBlockAction = useSetAtom(cancelNewBlockActionAtom);
  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);

  function onChangeNewBlockName(name: string) {
    setNewBlock((prev) => (prev ? { ...prev, name } : prev));
  }

  function onSaveNewBlockName(_name: string) {
    saveNewBlockAction();
  }

  function onAddTextItemToNewBlock() {
    addNewTextItemAction(newBlock!.optimisticId);
  }

  return (
    <>
      {newBlock ? (
        <TextItemBlock
          key={"newBlock"}
          isEmpty
          isEditingName
          isNewBlock
          name={newBlock.name}
          autofocus
          onChangeName={onChangeNewBlockName}
          onSaveName={onSaveNewBlockName}
          onResetName={cancelNewBlockAction}
          onAddNewTextItem={onAddTextItemToNewBlock}
          // Need to pass in a function here to prevent the block from saving by default. The internal logic of the component saves on blur by default unless this is defined.
          onBlurName={() => {}}
          className={props.className}
        />
      ) : (
        <React.Fragment />
      )}
    </>
  );
};

export default NewBlockWrapper;
