import http from "./client";
interface PollingBackgroundJobRequestProps<Payload> {
  url: "/jobs/figmaGetPages" | "/jobs/setupSuggestions" | "/jobs/figmaGetBranchInfo";
  requestBody: Payload;
  timeout?: number;
}

interface JobError {
  state: "waiting" | "active" | "delayed" | "failed" | "paused" | "stuck";
  data?: any;
}

interface JobResponse<ResponseType> {
  state: "completed";
  data: ResponseType;
}

export async function pollingBackgroundJobRequest<ResponseType, T = any>(props: PollingBackgroundJobRequestProps<T>) {
  const {
    data: { id: JobId },
  } = await http.post(props.url, props.requestBody);

  const POLL_TIME_LIMIT = props.timeout || 1000 * 60 * 5; // 5 minutes

  // poll for response from /job/:id
  async function pollForBackgroundJobResponse<ResponseType>(
    id: string,
    interval: number,
    startedAt: Date
  ): Promise<JobResponse<ResponseType> | JobError> {
    try {
      let { data: result } = await http.get(`${props.url}/${id}`);
      let { state } = result;
      if (state === "active" || state === "stuck" || state === "waiting") {
        await new Promise((res) => setTimeout(res, interval));
        // stop polling after timelimit reached
        if (new Date().getTime() - startedAt.getTime() > POLL_TIME_LIMIT) {
          console.error("Background job timed out");
          return { state: "failed" };
        }
        result = await pollForBackgroundJobResponse<ResponseType>(id, interval, startedAt);
      }
      return result;
    } catch (e) {
      console.error("Error while polling for bakcground job response is: ", e.message);
      return { state: "failed" };
    }
  }

  const result = await pollForBackgroundJobResponse<ResponseType>(JobId, 1000, new Date());

  if (result.state !== "completed") {
    throw new Error("Background job failed");
  }

  return result.data;
}
