import { atom, WritableAtom } from "jotai";

/**
 * This atom works similarly to the jotai `[atomFamily](https://jotai.org/docs/utilities/family)` but allows passing in a default value when fetching a new atom. This allows you to effectively `insert` an atom into the family that has already been fetched, preventing a loading state from showing.
 * @param initializeAtom
 * @returns
 */
export default function atomFamilyWithDefault<
  Value extends unknown,
  ResultValue extends unknown,
  Args extends unknown[],
  AtomType extends WritableAtom<Value, Args, ResultValue>
>(initializeAtom: (id: string, initialValue?: Value) => AtomType) {
  const atomsMap = new Map<string, AtomType>();

  function familyAtom(id: string, initialValue?: Value) {
    const item = atomsMap.get(id);

    if (item !== undefined) {
      return item;
    }
    var newAtom = initializeAtom(id, initialValue);
    atomsMap.set(id, newAtom);
    return newAtom;
  }

  /**
   * This action atom is used to remove an atom from the family.
   * @param id The id of the atom to remove.
   */
  familyAtom.remove = (id: string) => {
    atomsMap.delete(id);
  };

  familyAtom.values = () => Array.from(atomsMap.values());

  const resetAtom = atom(null, () => {
    atomsMap.clear();
  });

  return {
    familyAtom,
    resetAtom,
  };
}
