import { Ditto } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import style from "./style.module.css";

export interface Props {
  showManageButton?: boolean;
  tagCount: number;
  onClick: () => void;
}

export default function TagsPanel(props: Props) {
  return (
    <Panel titleDittoComponentId="tags">
      <div className={style.container}>
        <div className={style.text} data-testid="tags-count">
          <Ditto componentId="tags-count" variables={{ tagCount: props.tagCount }} count={props.tagCount} />
        </div>
        {props.showManageButton && (
          <div className={style.action} onClick={props.onClick} data-testid="manage-tags">
            <Ditto componentId="manage" />
          </div>
        )}
      </div>
    </Panel>
  );
}
