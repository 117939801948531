const TestIDs = {
  projectCard: (docName: string) => `project-card-${docName.replace(/\s/g, "-")}`,
  projectShareButton: "project-share-button",
  addMembersInput: "add-members-input",
  e2eProjectTextItem2: "comp-text-item-2",
  e2eProjectTextItem3: "comp-text-item-3",
  commentBox: "comment-box",
  loginEmailBox: "trusted-auth-email-input",
  toggleNotifications: "toggle-notifications",
  textItemInput: "text-item-input",
  editCompArea: "edit-comp-area",
  userIcon: "user-icon",
  commentThread: "comment-thread",
};

export default TestIDs;
