import { Editor } from "@tiptap/core";
import { useRef } from "react";

import { TextItem } from "../../../state/types";
import { getEditorHasContent, useEditorJsonToTextItems } from "../editor/lib";

import { EditorJSON } from "../editor/types";

interface Args {
  onSaveTextItems: (arg: TextItem[]) => void;
  setEditorHasContent: (editorHasContent: boolean) => void;
  initialTextItemCount: number;
  developerModeEnabled: boolean;
  setHasUnsavedChanges: () => void;
}

/**
 * Returns a function that should be executed each time the text
 * in the draft editor changes. It handles:
 * - saving draft data on the backend at a debounced interval
 * - updating state when the raw text of the editor changes
 */
const useOnDraftTextChange = (args: Args) => {
  const { onSaveTextItems, setEditorHasContent, initialTextItemCount, developerModeEnabled, setHasUnsavedChanges } =
    args;

  const textItemCountCache = useRef<number>(initialTextItemCount);

  const editorJsonToTextItems = useEditorJsonToTextItems(developerModeEnabled);
  const onDraftTextChange = ({ editor }: { editor: Editor }) => {
    const json = editor.getJSON() as EditorJSON;
    const hasContent = getEditorHasContent(json);

    setEditorHasContent(hasContent);

    let textItems = editorJsonToTextItems(json);

    setHasUnsavedChanges();

    onSaveTextItems([...textItems]);

    textItemCountCache.current = textItems.length;
  };

  return onDraftTextChange;
};

export default useOnDraftTextChange;
