import React from "react";

export default function (props: { fill?: string }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_199_584)">
        <path d="M2 4.5H10V5.5H2V4.5ZM2 6.5H7V7.5H2V6.5Z" fill={props.fill || "#787878"} />
      </g>
      <defs>
        <clipPath id="clip0_199_584">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
