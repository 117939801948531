import Code from "@mui/icons-material/Code";
import MailOutline from "@mui/icons-material/MailOutline";
import StarOutline from "@mui/icons-material/StarOutline";
import React from "react";
import GridViewOutlineRounded from "../../../../components/icons/GridViewOutlineRounded";
import Panel from "../panel";
import Card from "./card";

import styleCard from "./card.module.css";
import style from "./style.module.css";

import classNames from "classnames";
import imgConnectDesignFile from "../../../../assets/link-a-project.png";
import imgConnectStringFile from "../../../../assets/link-a-string-file.png";

export interface Props {
  displayDesignFile?: boolean;
  displayStringFile?: boolean;
  displayViewAllProjectsButton?: boolean;
  onViewAllProjects?: () => void;
  onImportFigmaFile?: () => void;
  onImportStringFile?: () => void;
  onInviteDeveloper?: () => void;
  onViewDevIntegrations?: () => void;
  developerView?: boolean;
}

export default function GettingStartedPanel(props: Props) {
  const viewAllProjectsProps = props.displayViewAllProjectsButton
    ? {
        secondaryCtaDittoComponentId: "view-all-projects",
        secondaryCtaIcon: GridViewOutlineRounded,
        onSecondaryCtaClick: props.onViewAllProjects,
      }
    : {};

  const developerSecondaryProps = props.developerView
    ? {
        secondaryCtaDittoComponentId: "view-developer-integrations",
        secondaryCtaIcon: Code,
        onSecondaryCtaClick: props.onViewDevIntegrations,
      }
    : {
        secondaryCtaDittoComponentId: "invite-a-developer-cta",
        secondaryCtaIcon: MailOutline,
        onSecondaryCtaClick: props.onInviteDeveloper,
      };

  const displayDesignFile = props.displayDesignFile;
  const displayStringFile = props.displayStringFile;

  let cardCount = 0;
  if (displayDesignFile) cardCount++;
  if (displayStringFile) cardCount++;

  return (
    <Panel titleDittoComponentId="home-getting-started-title" icon={StarOutline}>
      <div className={style.container}>
        {displayDesignFile && (
          <div className={style.card}>
            <Card
              image={imgConnectDesignFile}
              titleDittoComponentId="connect-design-file-title"
              subtitleDittoComponentId="connect-design-file-subtitle"
              ctaDittoComponentId="import-figma-file-cta"
              stacked={displayStringFile || false}
              onCtaClick={props.onImportFigmaFile}
              className={classNames({
                [styleCard.singleCard]: cardCount === 1,
                [styleCard.doubleCard]: cardCount === 2,
              })}
              {...viewAllProjectsProps}
            />
          </div>
        )}
        {displayStringFile && (
          <div className={style.card}>
            <Card
              image={imgConnectStringFile}
              titleDittoComponentId={
                props.developerView ? "connect-string-file-title-devs" : "connect-string-file-title"
              }
              subtitleDittoComponentId="connect-string-file-subtitle"
              ctaDittoComponentId="import-strings-cta"
              stacked={displayDesignFile || false}
              onCtaClick={props.onImportStringFile}
              className={classNames({
                [styleCard.singleCard]: cardCount === 1,
                [styleCard.doubleCard]: cardCount === 2,
              })}
              {...developerSecondaryProps}
            />
          </div>
        )}
      </div>
    </Panel>
  );
}
