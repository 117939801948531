import ChevronRight from "@mui/icons-material/ChevronRight";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Label from "../../atoms/Label";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import style from "./index.module.css";

interface IProps {
  type: "loading" | "component" | "group" | "block";
  label: string;

  indentWidth?: number;
  rowHeight?: number;
  indentLevel?: number;
  isOpen?: boolean;
  isExpandable?: boolean;
  isSelected?: boolean;
  isClickable?: boolean;
  loading?: boolean;
  trailingIcon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onToggleExpand?: () => void;

  className?: string;
  style?: React.CSSProperties;
}

/**
 * NOTE: The initial implementation of this component was tightly coupled to the `react-arborist` library.
 * See comp-library-nav/index.tsx for the implementation of the `Tree` and `TreeNode` component. This
 * is especially relevant for the indendation padding, which is explicitly handled by `react-arborist`
 * and not by this component.
 */
export function ExpandableListItem({
  type,
  label,
  indentWidth = 28,
  rowHeight = 28,
  indentLevel = 0,
  isExpandable = false,
  isSelected = false,
  isClickable = false,
  isOpen = false,
  onClick,
  onToggleExpand,
  ...props
}: IProps) {
  function handleToggleExpand() {
    onToggleExpand?.();
  }

  function leftLines() {
    return Array(indentLevel ?? 0)
      .fill(0)
      .map((_, level) => (
        <div key={level} className={style.indentLine} style={{ left: `-${indentWidth * (level + 0.5)}px` }} />
      ));
  }

  return (
    // We expect a consumer to pass in an appropriate leftPadding to style for indentation.
    <div style={props.style} className={classNames(style.nodeWrapper)}>
      <div
        className={classNames(style.node, {
          [style.expandable]: isExpandable,
          [style.clickable]: isClickable,
          [style.selected]: isSelected,
        })}
        // It's important for this `height` to match the `rowHeight` prop passed to the Tree component.
        style={{ height: `${rowHeight}px` }}
        onClick={onClick}
      >
        {type === "loading" ? (
          <div className={style.loading}>
            <LoadingSpinner />
            Loading...
          </div>
        ) : (
          <>
            {isExpandable && (
              <Button type="icon" level="secondary" onClick={handleToggleExpand} className={style.collapseExpandButton}>
                <Icon Icon={isOpen ? <ChevronRight style={{ transform: "rotate(90deg)" }} /> : <ChevronRight />} />
              </Button>
            )}
            <Label className={style.label}>{label}</Label>
            {props.trailingIcon && (
              <Icon Icon={props.trailingIcon} color="secondary" size="xs" className={style.trailingIcon} />
            )}
          </>
        )}
        {leftLines()}
      </div>
    </div>
  );
}

export default ExpandableListItem;
