import useSegment from "@/hooks/useSegment";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import * as SegmentEvents from "@shared/segment-event-names";
import { IFUser } from "@shared/types/User";
import classnames from "classnames";
import { useDittoComponent } from "ditto-react";
import React from "react";
import Panel from "../../panel";
import UpsellBanner from "../../upsellBanner";
import InviteRow from "./inviteRow";
import style from "./style.module.css";
import UserRow from "./userRow";

export interface InviteProps {
  email: string;
  permissions: string[];
}

export interface Props {
  activeUserCount: number;
  canEditUser: (userToEdit: IFUser) => boolean;
  canEditInvites: boolean;
  canManagePermissions: boolean;
  canRemoveUsers: boolean;
  invites: InviteProps[];
  onInviteClick: () => void;
  onManagePermissions: (user: IFUser) => void;
  onRemoveUser: (user: IFUser) => void;
  onResendInviteClick: (invite: InviteProps) => void;
  onRevokeInviteClick: (invite: InviteProps) => void;
  onSelectRole: (user: IFUser, option: any) => void;
  onUpgradePlanClicked?: () => void;
  pendingInviteCount: number;
  permissionGroupsEnabled: boolean;
  permissionGroups: { _id: string; name: string }[];
  permissionOptions: { name: string; value: string; isDisabled: boolean }[];
  userLimitReached?: boolean;
  users: IFUser[];
}

export default function TeamMembersPanel(props: Props) {
  const segment = useSegment();
  const buttonText = useDittoComponent({
    componentId: "invite-teams-cta",
  });

  const badgeText = useDittoComponent({
    componentId: "team-members-active-pending",
    variables: {
      activeCount: props.activeUserCount,
      pendingCount: props.pendingInviteCount,
    },
    count: props.pendingInviteCount,
  });

  const upsellText = useDittoComponent({
    componentId: "more-teammates-upsell",
  });

  const onUpgradePlanClicked = () => {
    segment.track({
      event: SegmentEvents.UPGRADE_PLAN_LINK_CLICKED,
      properties: { location: "team members panel" },
    });
    props.onUpgradePlanClicked?.();
  };

  const onInviteClick = () => {
    segment.track({
      event: SegmentEvents.INVITE_MODAL_OPENED,
      properties: { location: "team members panel" },
    });
    props.onInviteClick();
  };

  const sortUsersFn = (a: IFUser, b: IFUser) => {
    const createdFirst =
      new Date(a.createdAt as unknown as string).getTime() - new Date(b.createdAt as unknown as string).getTime();
    const aPerms = a.permissionGroups.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
    const bPerms = b.permissionGroups.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    const ordering = ["admin", "editor", "commenter"];
    for (const permission of ordering) {
      if (aPerms[permission]) {
        if (bPerms[permission]) {
          return createdFirst;
        } else {
          return -1;
        }
      } else if (bPerms[permission]) {
        return 1;
      }
    }
    return createdFirst;
  };

  return (
    <Panel
      badgeText={badgeText || ""}
      buttonDisabled={props.userLimitReached}
      buttonIcon={PersonAddOutlined}
      buttonText={buttonText || ""}
      titleDittoComponentId="team-members"
      onClick={onInviteClick}
    >
      <div className={style.container} data-testid="team-members-panel">
        <table className={style.table}>
          <tbody>
            <tr className={style.divider}>
              <td className={classnames([style.column, style.header])}>Name</td>
              <td className={style.header}>Permissions</td>
            </tr>
            {props.users.sort(sortUsersFn).map((user, index) => (
              <UserRow
                canEditUser={props.canEditUser}
                canManagePermissions={props.canManagePermissions}
                canRemoveUsers={props.canRemoveUsers}
                permissionGroupsEnabled={props.permissionGroupsEnabled}
                key={user.email}
                user={user}
                divider={index < props.users.length - 1 || props.invites.length > 0}
                onManagePermissions={props.onManagePermissions}
                onSelectRole={props.onSelectRole}
                onRemoveUser={props.onRemoveUser}
                permissionOptions={props.permissionOptions}
                permissionGroups={props.permissionGroups}
              />
            ))}
            {props.invites.map((invite, index) => (
              <InviteRow
                key={invite.email}
                invite={invite}
                divider={index < props.invites.length - 1}
                canEditInvites={props.canEditInvites}
                onResendInviteClick={props.onResendInviteClick}
                onRevokeInviteClick={props.onRevokeInviteClick}
                permissionGroups={props.permissionGroups}
              />
            ))}
            {props.userLimitReached && (
              <tr>
                <td colSpan={2} className={style.upsellContainer}>
                  <UpsellBanner message={upsellText || ""} onClick={onUpgradePlanClicked} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Panel>
  );
}
