import { VARIANT_STATUSES_ENABLED } from "@/utils/featureFlags";
import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React, { useMemo, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { useHasTextChanges } from "../../hooks/useHasTextChanges";
import http, { API } from "../../http/index";
import { parsePluralInput } from "../../utils/pluralization";
import StatusSelect from "../StatusSelect";
import TextItemTextInputs from "../TextItemTextInputs/TextItemTextInputs";
import ButtonPrimary from "../button/buttonprimary";
import ConfirmationModal from "../shared/confirmation-modal.tsx";
import style from "./style.module.css";

const ComponentEditVariantModal = ({ ws_comp, variant, onHide, handleHistoryUpdate, setSelectedId, fetchAllComps }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [deletingState, setDeletingState] = useState(false);
  const [textChanged, setTextChanged] = useState(false);
  const [status, setStatus] = useState(variant.status ?? "NONE");

  const valueBeingEdited = useRef();

  const wsCompDraftInstance = ws_comp?.instances?.[0];
  const baseCompValue = useMemo(
    () => ({
      text: wsCompDraftInstance?.text,
      rich_text: wsCompDraftInstance?.rich_text,
      variables: wsCompDraftInstance?.variables,
      plurals: wsCompDraftInstance?.plurals,
    }),
    [ws_comp]
  );

  const variantCompInstance = useMemo(() => {
    const variantInstance = wsCompDraftInstance?.variants.find((v) => v.variantId === variant.variantId);
    if (!variantInstance) {
      return {
        text: "",
        rich_text: null,
        variables: [],
        plurals: [],
      };
    }
    return {
      text: variantInstance.text,
      rich_text: variantInstance.rich_text,
      variables: variantInstance.variables,
      plurals: variantInstance.plurals,
    };
  }, [ws_comp, variant]);

  const compName = useMemo(() => ws_comp?.name?.substr(ws_comp.name.lastIndexOf("/") + 1).trim() || "", [ws_comp]);

  const saveCompVariantText = async () => {
    try {
      setLoadingState(true);

      const { allVariables, baseText, baseRichText, plurals } = parsePluralInput(
        valueBeingEdited,
        variantCompInstance?.text || ""
      );

      const { url, body } = API.ws_comp.patch.variant;
      const { data: comp } = await http.patch(
        url(ws_comp._id),
        body({
          variantId: variant.variantId,
          updatedVariantInfo: {
            text: baseText,
            rich_text: baseRichText,
            variables: allVariables,
            plurals,
            status: VARIANT_STATUSES_ENABLED ? status : undefined,
          },
        })
      );
      onHide();
      setSelectedId(comp._id);
      fetchAllComps();
      handleHistoryUpdate();
    } catch (e) {
      setLoadingState(false);
    }
  };

  const handleDeleteVariant = async () => {
    setDeletingState(true);
    try {
      const variantId = wsCompDraftInstance?.variants.find((v) => v.variantId === variant.variantId).variantId;

      const { url } = API.ws_comp.delete.variant;
      const { data: comp } = await http.delete(url(ws_comp._id, variantId));

      onHide();
      setSelectedId(comp._id);
      fetchAllComps();
    } catch (e) {
      console.error(e);
      setDeletingState(false);
    }
  };

  const getTextHasChanged = useHasTextChanges(wsCompDraftInstance, true);

  const handleVariantTextChange = (value) => {
    if (!value.length) return;
    setTextChanged(getTextHasChanged(value, variant.variantId));
    valueBeingEdited.current = value;
  };

  function handleStatusChange(status) {
    setStatus(status);
  }

  const hasUnsavedChanges = status !== variant.status || textChanged;

  return (
    <div>
      {!showConfirmationModal && (
        <BootstrapModal
          show={true}
          className={style.modal}
          dialogClassName={style.dialog}
          backdropClassName={style.backdrop}
          onHide={onHide}
          centered
        >
          <BootstrapModal.Header className={style.header}>
            <BootstrapModal.Title className={style.title}>
              Edit variant for <span>{compName}</span>
            </BootstrapModal.Title>
            <CloseIcon className={style.close} onClick={onHide} />
          </BootstrapModal.Header>
          <BootstrapModal.Body className={style.body}>
            <p className={[style.label]}>
              Variant: <strong className={classnames([style.textBlack])}>{variant.variantName}</strong>
            </p>
            <div className="form-group">
              <div className={[style.marginTopSmall]}>
                <TextItemTextInputs
                  shouldShowRichText={true}
                  placeholder={"No text."}
                  isBaseText={true}
                  isVariant={true}
                  readonly={true}
                  textItem={baseCompValue}
                  legacyHandleTextChange={() => {}}
                  inSampleProject={ws_comp?.isSample}
                />
              </div>
              <div className={[style.marginTopSmall]}>
                <TextItemTextInputs
                  shouldShowRichText={true}
                  placeholder={"No variant text."}
                  isBaseText={false}
                  isVariant={true}
                  readonly={false}
                  textItem={variantCompInstance}
                  legacyHandleTextChange={handleVariantTextChange}
                  inSampleProject={ws_comp?.isSample}
                />

                {VARIANT_STATUSES_ENABLED && <StatusSelect status={status} handleStatusChange={handleStatusChange} />}
              </div>
              <span className={style.form}>
                <ButtonPrimary
                  data-testid="component-edit-variant-save-button"
                  text={loadingState ? "Saving..." : "Save Variant"}
                  disabled={!hasUnsavedChanges || loadingState}
                  onClick={async () => {
                    await saveCompVariantText();
                  }}
                  type="button"
                />
              </span>
            </div>
          </BootstrapModal.Body>
          <BootstrapModal.Footer className={style.footer}>
            <div>
              <a
                className={style.link}
                onClick={() => {
                  setShowConfirmationModal(true);
                }}
              >
                Delete variant for component
              </a>
            </div>
          </BootstrapModal.Footer>
        </BootstrapModal>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          title="Are you sure you want to delete this variant?"
          body={
            <span>
              Deleting this variant for the component will also remove the variant text from the instances where it's
              attached.
            </span>
          }
          actionPrimary="Confirm"
          actionSecondary="Cancel"
          onPrimary={handleDeleteVariant}
          onSecondary={() => setShowConfirmationModal(false)}
          primaryDisabled={deletingState}
        />
      )}
    </div>
  );
};

export default ComponentEditVariantModal;
