import { useWorkspace } from "@/store/workspaceContext";
import { useEffect, useState } from "react";
import billing, { ValidateBillingStatusResponse } from "../../../http/billing";

interface GetBillingStatusLoading {
  loading: true;
}

interface GetBillingStatusSuccess {
  loading: false;
  success: true;
  data: ValidateBillingStatusResponse;
}

interface GetBillingStatusFailure {
  loading: false;
  success: false;
  error: string;
}

export type GetBillingStatusState = GetBillingStatusLoading | GetBillingStatusSuccess | GetBillingStatusFailure;

// Gets updated seat data from the billing backend
// If the user is on a free/trial/enterprise plan, they won't have a customer ID,
// so we just return a response full of zeroes.
export const useBillingStatus = (): [GetBillingStatusState, () => Promise<void>] => {
  const { reloadWorkspace } = useWorkspace();
  const [billingStatus, setBillingStatus] = useState<GetBillingStatusState>({
    loading: true,
  });

  const fetchBillingStatus = async () => {
    const [validateBillingStatusRequest, cancelValidateBillingStatusRequest] = billing.validateBillingStatus();
    try {
      const { data } = await validateBillingStatusRequest;
      setBillingStatus({ loading: false, success: true, data });
    } catch (error) {
      console.error(error);
      setBillingStatus({
        loading: false,
        success: false,
        error: "Unable to fetch billing status.",
      });
    }
    await reloadWorkspace();
  };

  useEffect(() => {
    fetchBillingStatus();
  }, [setBillingStatus]);

  return [billingStatus, fetchBillingStatus];
};
