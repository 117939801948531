import z from "zod";
import { CreateableComponentWithBaseText, CreateableComponentWithText } from "./Component";
import { ZCreatableId } from "./lib";

export const ZFormat = z.enum(["flat", "structured", "nested", "xml", "ios-strings", "ios-stringsdict", "csv"]);

export type Format = z.infer<typeof ZFormat>;

export const ZComponentImportJobData = z.object({
  // honestly hate having these two separate properties, would
  // love change this in the future
  //
  // presently, if adding support for an action that requires additional data
  // (e.g. variant text), will neeed to add a new property akin to variantComponents
  components: z.array(CreateableComponentWithText),
  variantComponents: z.array(CreateableComponentWithBaseText).optional().nullable(),

  // need to update this field after the action is chosen if (only ignored component ids
  // are relevant to a given action)
  ignoredComponentApiIds: z.array(z.string()).optional().nullable(),

  // optional and nullable because action is not known when the import job data is initially created
  // subsequent parts of the import flow should verify that this is set, as it is required to
  // actually take action on the imported data
  action: z.enum(["CREATE", "VARIANT", "UPDATE"]).optional().nullable(),

  format: ZFormat,
  fileName: z.string().optional(),
  fileType: z.string().optional(),
  user: z.object({
    _id: z.string(),
    name: z.string(),
    userId: z.string(),
    workspaceId: z.string(),
  }),

  // only set if the import is for a specific variant
  variant: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional()
    .nullable(),

  workspaceId: ZCreatableId,
});
export type IComponentImportJobData = z.infer<typeof ZComponentImportJobData>;

export const ZComponentActionPreviewResponse = z.object({
  components: z.array(z.any()),
  componentsIgnored: z.array(z.any()),
  ignoredComponentsCount: z.number(),
});
export type IComponentActionPreviewResponse = z.infer<typeof ZComponentActionPreviewResponse>;
