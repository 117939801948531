export default {
  get: {
    ws_comp: {
      url: (ws_comp_id) => `/changes/ws_comp/${ws_comp_id}`,
    },
    ws_comp_library: {
      /**
       * @param {number | string} skip Amount to skip
       * @param {number | string} limit Total to return
       * @returns {string} Ready to use URL string
       */
      url: (folderId, skip, limit) => {
        const params = new URLSearchParams({
          folderId,
          skip,
          limit,
        });
        return `/changes/ws_comp_library?${params}`;
      },
    },
    doc: {
      url: (doc_id) => `/changes/doc/${doc_id}`,
    },
    docPage: {
      /**
       * @param {string} doc_id
       * @param {number | string} skip
       * @param {number | string} limit
       * @returns {string} Ready to use URL string
       */
      url: (doc_id, skip, limit) => {
        const params = new URLSearchParams({
          skip,
          limit,
        });
        return `/changes/doc/page/${doc_id}?${params}`;
      },
    },
    docCommentsPage: {
      /**
       * @param {string} doc_id
       * @param {number | string} skip
       * @param {number | string} limit
       * @returns {string} Ready to use URL string
       */
      url: (doc_id, skip, limit) => {
        const params = new URLSearchParams({
          skip,
          limit,
        });
        return `/changes/comments/${doc_id}?${params}`;
      },
    },
    variablePage: {
      url: (skip, limit, folderId) =>
        `/changes/variable/all?skip=${skip}&limit=${limit}${folderId ? `&folderId=${folderId}` : ""}`,
    },
    variable: {
      url: (variable_id) => `/changes/variable/${variable_id}`,
    },
    variantAll: {
      url: (skip, limit, folderId) =>
        `/changes/variant/all?skip=${skip}&limit=${limit}${folderId ? `&folderId=${folderId}` : ""}`,
    },
  },
};
