import CodeIcon from "@mui/icons-material/Code";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import style from "./style.module.css";

interface DevIDFilterDropdownProps {
  className?: string;
  filterValue: string;
  setFilter: (id: string) => void;
  onRemoveFilter: () => void;
}

const DevIDFilterDropdown = (props: DevIDFilterDropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const toggleShowDropdown = () => {
    setShowDropdown((showDropdown) => !showDropdown);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSubmitFilter = (val: string) => {
    props.setFilter(val);
    setShowDropdown(false);
  };

  useEffect(
    function deactiveDropdownOnClickOutside() {
      function handleClickOutside(event: MouseEvent) {
        if (!showDropdown) {
          return;
        }

        const target = event.target as HTMLElement;
        if (containerRef.current && !containerRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    },
    [showDropdown, setShowDropdown]
  );

  return (
    <div ref={containerRef} className={classNames(style.wrapper, props.className)}>
      <div className={style.dropdownToggle} onClick={toggleShowDropdown}>
        <CodeIcon className={style.icon} />
        {props.filterValue ? (
          <span className={style.text}>
            ID: <span className={style.apiID}>{props.filterValue}</span>
          </span>
        ) : (
          <span className={style.placeholder}>Developer ID</span>
        )}
        <KeyboardArrowDownIcon className={style.downIcon} />
      </div>
      {showDropdown && (
        <Dropdown
          inputValue={inputValue}
          onChange={onChange}
          onRemoveFilter={props.onRemoveFilter}
          onSubmitFilter={onSubmitFilter}
        />
      )}
    </div>
  );
};

const Dropdown = ({
  inputValue,
  onChange,
  onRemoveFilter,
  onSubmitFilter,
}: {
  inputValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFilter: () => void;
  onSubmitFilter: (val: string) => void;
}) => {
  useEffect(
    function mountEnterListener() {
      const listener = (e: KeyboardEvent) => {
        if (e.key === "Enter") onSubmitFilter(inputValue);
      };

      document.addEventListener("keydown", listener);
      return () => document.removeEventListener("keydown", listener);
    },
    [inputValue]
  );

  return (
    <div className={style.dropdown}>
      <div className={style.idFilterInput}>
        <input type="text" placeholder="Developer ID" value={inputValue} onChange={onChange} />
      </div>
      <div className={style.removeFilter} onClick={onRemoveFilter}>
        <DeleteIcon className={style.removeFilterIcon} />
        Remove Filter
      </div>
    </div>
  );
};

export default DevIDFilterDropdown;
