import z from "zod";

export const SlackNotificationTypes = z.object({
  textEdited: z.boolean(),
  textAssigned: z.boolean(),
  statusChanged: z.boolean(),
  commentActivity: z.boolean(),
  componentAttached: z.boolean(),
  draftItems: z.boolean(),
});

export type SlackNotificationTypes = z.infer<typeof SlackNotificationTypes>;

export const SlackChannelInfo = z.object({
  channel_id: z.string().nullable(),
  channel_name: z.string().nullable(),
  webhook_url: z.string().nullable(),
  configuration_url: z.string().nullable(),
  notif_types: SlackNotificationTypes,
});

export type SlackChannelInfo = z.infer<typeof SlackChannelInfo>;

export type MessagePayload = {
  text?: string;
  blocks?: string;
  attachments?: string;
};

export type IntegrationMessagePayload = MessagePayload & {
  type: keyof SlackNotificationTypes;
};
