import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import React, { useMemo } from "react";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  hiddenFieldsList: { value: string; label: string; isHidden: boolean }[];
  setSelectedOptions: (options: IOption[]) => void;
  removeFilter?: () => void;

  className?: string;
  style?: React.CSSProperties;
}

export function HideFieldsDropdown(props: IProps) {
  const { hiddenFieldsList } = props;

  const hiddenFieldsOptionsList = useMemo(
    () =>
      hiddenFieldsList?.map((field) => ({
        value: field.value,
        label: field.label,
      })),
    [hiddenFieldsList]
  );

  const hiddenFieldsSelectedOptions = useMemo(
    () =>
      hiddenFieldsList
        ?.filter((field) => field.isHidden)
        .map((field) => ({
          value: field.value,
          label: field.label,
        })),
    [hiddenFieldsList]
  );

  return (
    <DropdownCombobox
      asToggle
      forceToggledDisplay={hiddenFieldsSelectedOptions.length > 0}
      multiple
      showTextInput={false}
      options={hiddenFieldsOptionsList}
      selectedOptions={hiddenFieldsSelectedOptions}
      onChange={props.setSelectedOptions}
      OptionComponent={OptionComponent}
      TriggerComponent={TriggerComponent}
      onRemoveFilter={props.removeFilter}
      triggerLeadingIcon={<VisibilityOffOutlined />}
      removeFilterText="Reset"
    />
  );
}

interface ITriggerProps {
  selectedOptions: IOption[];
}
function TriggerComponent(props: ITriggerProps) {
  return (
    <Text size="small" color="primary" weight="light">
      {props.selectedOptions.length > 0
        ? `${props.selectedOptions.length} field${props.selectedOptions.length === 1 ? "" : "s"} hidden`
        : "Hide fields"}
    </Text>
  );
}

interface IOptionProps {
  option: IOption;
  query: string;
  selected: boolean;
}
function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{props.option.label}</span>
    </Text>
  );
}

export default HideFieldsDropdown;
