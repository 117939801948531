import { useEffect, useMemo, useRef, useState } from "react";
import logger from "../../../../../shared/utils/logger";
import { useScroll } from "../../../../hooks/useScroll";
import { Group, TextItem, isGroupLinked } from "../../../../views/Project/state/types";
import { HighlightBoxStyle } from "./HighlightBox";
export type FrameVariant = {
  id: string;
  name: string;
};
type Position = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type TextItemWithIndexes = TextItem & {
  frameIndex: number | null;
  blockIndex: number | null;
};

type ImageInfo = {
  previews: {
    fullsize: string;
  };
  previewsLastUpdatedAt: Date;
  frameDimensions: Position;
  textDimensions: {
    [compId: string]: Position;
  };
  compIdMap: {
    [compId: string]: TextItemWithIndexes;
  };
} | null;
type ImageError = {
  exists: boolean;
  status?: string | number | null;
};
interface UsePreviewProps {
  group: Group;
  groupIndex: number;
  textItems: TextItem[];
  previewsLastUpdatedAt: Date;
  scrollToId: string | null;
  selectedId: string | null;
  selectComp: (
    comp: string | TextItem,
    frameVariant?: FrameVariant,
    e?: React.SyntheticEvent,
    groupId?: string
  ) => void;
}
interface UsePreviewOutput {
  imgElement: any;
  imageInfo: ImageInfo;
  imageLoaded: boolean;
  imgError: ImageError;
  syncMoreThanOneDayOld: boolean;
  highlightBoxStyles: HighlightBoxStyle[];
  handleImgLoadingError: (e: any) => void;
  handleImgLoaded: () => void;
  onSelectComp: (compId: string, e: React.SyntheticEvent) => void;
  imageRenderDimensions: { width: number; height: number } | null;
}

const HIGHLIGHT_BOX = {
  padding: {
    vertical: 10,
    horizontal: 15,
  },
  borderRadius: 2,
  indicatorSize: 24,
};

const px = (num: number) => `${num}px`;

export const usePreview = (props: UsePreviewProps): UsePreviewOutput => {
  const { group, groupIndex, textItems, previewsLastUpdatedAt, selectedId, scrollToId, selectComp } = props;

  const [imageLoaded, setImageLoaded] = useState<boolean>(true);
  const [imgError, setImgError] = useState<ImageError>({
    exists: false,
    status: null,
  });

  const url = isGroupLinked(group) ? group.integrations.figma.previews?.fullsize : "";
  useEffect(
    // whenever the url for the preview changes, we need to clear the error state
    // if the image fails to load again, the error state will be set once more by
    // handleImgLoadingError
    function clearErrorStateAfterNewUrl() {
      setImgError({ exists: false, status: null });
    },
    [url, setImgError]
  );

  const [highlightBoxStyles, setHighlightBoxStyles] = useState<HighlightBoxStyle[]>([]);
  const [imageRenderDimensions, setImageRenderDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const imgElement = useRef<HTMLImageElement | null>(null);

  const extractGroupTextPositions = (group: Group) => {
    const textItemIdToTextPosition: Record<string, { x: number; y: number; width: number; height: number }> = {};

    const textItemIdToTextWithIndexes: Record<
      string,
      TextItem & {
        frameIndex: number | null;
        blockIndex: number | null;
      }
    > = {};

    (group?.blocks || []).forEach((block, blockIndex) => {
      (block?.comps || []).forEach((textItem) => {
        if (textItem.figma_node_ID && textItem.integrations?.figma?.position) {
          textItemIdToTextPosition[textItem._id] = textItem.integrations.figma.position;
        }

        textItemIdToTextWithIndexes[textItem._id] = {
          ...textItem,
          blockIndex,
          frameIndex: groupIndex,
        };
      });
    });

    group?.comps.forEach((textItem) => {
      if (textItem.figma_node_ID && textItem.integrations?.figma?.position) {
        textItemIdToTextPosition[textItem._id] = textItem.integrations.figma.position;
      }

      textItemIdToTextWithIndexes[textItem._id] = {
        ...textItem,
        blockIndex: null,
        frameIndex: groupIndex,
      };
    });

    return {
      textItemIdToTextWithIndexes,
      textItemIdToTextPosition,
    };
  };

  const imageInfo: ImageInfo = useMemo(() => {
    if (!isGroupLinked(group)) {
      throw new Error("Cannot render image preview for unlinked group");
    }

    // where does this image info come from?
    const { previews, frame_id, position } = group.integrations.figma;
    if (!(previews && frame_id)) {
      return null;
    }

    const { textItemIdToTextWithIndexes, textItemIdToTextPosition: frameTextPositionMap } =
      extractGroupTextPositions(group);

    return {
      previews,
      previewsLastUpdatedAt,
      frameDimensions: position,
      textDimensions: frameTextPositionMap,
      compIdMap: textItemIdToTextWithIndexes,
    };
  }, [group]);

  const generateHighlightBoxStyles = () => {
    const validPreview = imageInfo && imageInfo.frameDimensions && imageInfo.textDimensions;
    if (validPreview) {
      const { frameDimensions, textDimensions } = imageInfo;

      let boxStyles: HighlightBoxStyle[] = [];

      const textItemSet = new Set(textItems.map((textItem) => textItem._id));
      const textItemsToHighlight = Object.keys(imageInfo.textDimensions).filter((compId) => textItemSet.has(compId));

      textItemsToHighlight.forEach((compId) => {
        let textNodeDimensions = textDimensions[compId];
        if (frameDimensions && textNodeDimensions && imageRenderDimensions) {
          // all styles should have this factor applied in order to scale with the image
          const scaleFactor = frameDimensions.width / imageRenderDimensions.width;

          const indicatorSize = HIGHLIGHT_BOX.indicatorSize / scaleFactor;

          // be sure to apply padding BEFORE the scale factor so that the padding scales
          // alongside the width and height
          const boxStyle: HighlightBoxStyle = {
            compId,
            scaleFactor,
            wrapper: {
              left: px(
                (Math.abs(frameDimensions.x - textNodeDimensions.x) - HIGHLIGHT_BOX.padding.horizontal / 2) /
                  scaleFactor
              ),
              top: px(
                (Math.abs(frameDimensions.y - textNodeDimensions.y) - HIGHLIGHT_BOX.padding.vertical / 2) / scaleFactor
              ),
              width: px((textNodeDimensions.width + HIGHLIGHT_BOX.padding.horizontal) / scaleFactor),
              height: px((textNodeDimensions.height + HIGHLIGHT_BOX.padding.vertical) / scaleFactor),
            },
            box: {
              width: px((textNodeDimensions.width + HIGHLIGHT_BOX.padding.horizontal) / scaleFactor),
              height: px((textNodeDimensions.height + HIGHLIGHT_BOX.padding.vertical) / scaleFactor),
              borderRadius: px(HIGHLIGHT_BOX.borderRadius / scaleFactor),
            },
            indicator: {
              width: px(indicatorSize),
              height: px(indicatorSize),
              top: px((indicatorSize / 2) * -1),
              left: px((indicatorSize / 2) * -0.8),
            },
          };
          boxStyles.push(boxStyle);
        }
        setHighlightBoxStyles(boxStyles);
      });
    }
  };

  const onWindowResize = (_e: Event) => {
    if (imgElement?.current) {
      setImageRenderDimensions({
        width: imgElement.current.clientWidth,
        height: imgElement.current.clientHeight,
      });
    }
  };

  // Custom scrolling logic to scroll to image preview, then within the preview

  const { scrollToId: scrollToGroupPreview } = useScroll({
    containerId: "projectContainer",
    duration: 500,
    timeout: 100,
  });
  const { scrollToId: scrollToTextBox } = useScroll({
    containerId: `image-wrapper-${group._id}`,
    duration: 500,
    timeout: 100,
  });

  useEffect(
    function scrollToElement() {
      const scrollId = scrollToId || selectedId;
      const compNotInGroup = !scrollId || !imageInfo?.compIdMap[scrollId];
      const highlightBoxesDontExist = highlightBoxStyles?.length === 0;
      if (compNotInGroup || highlightBoxesDontExist) return;

      scrollToGroupPreview(`image-wrapper-${group._id}`);
      scrollToTextBox(`text-box-${scrollId}`);
    },
    [selectedId, scrollToId, imageInfo, highlightBoxStyles]
  );

  useEffect(
    function loadImage() {
      if (!imageInfo || !imgElement?.current) return;

      if (imgElement?.current?.src !== imageInfo.previews.fullsize) setImageLoaded(false);

      const downloadingImage = new Image();

      downloadingImage.onload = function (this: HTMLImageElement) {
        if (imgElement.current) {
          imgElement.current.src = this.src;
          // image dimension momentarily go to 0,0 on load
          setTimeout(() => onWindowResize({} as Event), 0);
        }

        setImageLoaded(true);
        setImgError({ exists: false, status: null });
      };

      downloadingImage.src = imageInfo.previews.fullsize;
    },
    [imageInfo?.previewsLastUpdatedAt]
  );

  useEffect(function updatePreviewDimensions() {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  useEffect(
    function updateHighlightBoxesOnViewportUpdates() {
      generateHighlightBoxStyles();
    },
    [imageInfo, imageRenderDimensions]
  );

  const handleImgLoaded = () => {
    onWindowResize({} as Event);
    setImageLoaded(true);
    setImgError({ exists: false, status: null });
  };

  const handleImgLoadingError = async (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.persist();
    if (!imageInfo) return;
    try {
      const imgResponse = await fetch(imageInfo.previews.fullsize);
      if (!imgResponse.ok) {
        setImgError({ exists: true, status: imgResponse.status });
      } else {
        setImgError({ exists: true });
      }
    } catch (e) {
      logger.error("Failed to fetch image preview", { context: { url: imageInfo.previews.fullsize } }, e);
      setImgError({ exists: true });
    }
  };

  const getnumberOfDays = (start: Date, end: Date) => {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const onSelectComp = (compId: string, e: React.SyntheticEvent) => {
    const comp = imageInfo?.compIdMap[compId];
    if (comp) selectComp(comp, undefined, e, group._id);
  };
  const syncMoreThanOneDayOld = getnumberOfDays(imageInfo?.previewsLastUpdatedAt || new Date(), new Date()) > 0;

  return {
    imgElement,
    imageInfo,
    imageLoaded,
    imgError,
    highlightBoxStyles,
    syncMoreThanOneDayOld,
    onSelectComp,
    handleImgLoadingError,
    handleImgLoaded,
    imageRenderDimensions,
  };
};
