import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ReactTags from "react-tag-autocomplete";
import { EMAIL_REGEX } from "../../../shared/utils/email";
import { ROLE_OPTIONS } from "../../defs";
import http, { API } from "../../http";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

const CustomTag = ({ tag, removeButtonText, onDelete }) => {
  return (
    <button
      type="button"
      className={classnames(
        {
          [style.invalidInput]: !tag.isValid || tag.isDuplicate || tag.isAlreadyInUse,
        },
        [style.inputTag]
      )}
      title={removeButtonText}
      onClick={onDelete}
    >
      {tag.name}
      <CloseIcon className={style.closeIcon} />
    </button>
  );
};

const InviteModal = ({
  title,
  inviteType,
  docID,
  docName,
  showCustomMessage,
  isInviteSending,
  onHide,
  workspaceInfo,
  handleAddInvite,
  workspaceUsers,
}) => {
  const [role, setRole] = useState("editor");
  const { user } = useAuthenticatedAuth();

  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState("");
  const [inviteLimitReached, setInviteLimitReached] = useState(false);

  const currentWorkspaceEmails = workspaceUsers.map((member) => member.email);
  const canInviteCommenter = workspaceInfo?.plan !== "free";

  const emailRef = useRef(null);

  const onDeleteEmail = (i) => {
    const curr_tags = emails.slice(0);
    curr_tags.splice(i, 1);
    setEmails(curr_tags);
  };

  const getInvitePermissions = () => {
    const filterOptions = (allowed, canInviteCommenter) =>
      Object.keys(ROLE_OPTIONS)
        .filter((key) => {
          if (canInviteCommenter === false && key === ROLE_OPTIONS.commenter) {
            return false;
          }
          return allowed.includes(key);
        })
        .reduce((obj, key) => {
          obj[key] = ROLE_OPTIONS[key];
          return obj;
        }, {});
    const userPermissionGroups = user.permissionGroups.groups.map((g) => g._id);

    if (userPermissionGroups.includes("admin"))
      return filterOptions(["admin", "editor", "commenter"], canInviteCommenter);
    else if (userPermissionGroups.includes("editor")) return filterOptions(["editor", "commenter"], canInviteCommenter);
    else return filterOptions(["commenter"], canInviteCommenter);
  };

  const onAddEmail = async (tag) => {
    try {
      if (!inviteLimitReached) {
        const email = tag.name.toLowerCase().trim();
        const isEmail = EMAIL_REGEX.test(email);
        const isDuplicate = currentWorkspaceEmails.indexOf(email) !== -1;

        let isAlreadyInUse = false;

        if (isEmail && !isDuplicate) {
          const { url, body } = API.user.post.checkExists;
          const { data: userExists } = await http.post(url, body({ email }));

          if (userExists.exists && !userExists.sameWorkspace) {
            isAlreadyInUse = true;
          }
        }
        setEmails([...emails, { name: email, isValid: isEmail, isDuplicate, isAlreadyInUse }]);
      }
    } catch (err) {}
  };

  const onRoleChange = (e) => {
    setRole(e.target.value);
  };

  const onInviteClick = async () => {
    handleAddInvite({
      emails: emails.map((email) => email.name),
      role,
      type: inviteType,
      docID,
      docName,
      message,
    });
  };

  const emailTagsValid = emails.filter((email) => !email.isValid).length > 0;
  const duplicatesDetected = emails.filter((email) => email.isDuplicate).length > 0;
  const emailAlreadyInUseDetected = emails.filter((email) => email.isAlreadyInUse).length > 0;

  useEffect(() => {
    if (workspaceInfo && workspaceInfo.plan === "free") {
      const limitReached = emails.length + workspaceInfo.invitedUserEmails.length >= 10;
      setInviteLimitReached(limitReached);
    } else {
      setInviteLimitReached(false);
    }
  }, [emails, workspaceInfo]);

  useEffect(() => {
    if (emailRef) {
      emailRef.current.input.current.input.current.focus();
    }
  }, []);

  return (
    <BootstrapModal show={true} onHide={onHide} className={style.container} centered>
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.modalTitle}>{title}</BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        <div className={style.inputSection}>
          <label>Email</label>
          <ReactTags
            ref={emailRef}
            tags={emails}
            onDelete={onDeleteEmail}
            onAddition={onAddEmail}
            addOnBlur={true}
            allowNew={true}
            allowUnique={true}
            delimiters={["Enter", "Tab", ","]}
            minQueryLength={3}
            maxSuggestionsLength={100}
            placeholderText="Enter email addresses separated by a comma"
            tagComponent={CustomTag}
          />
          {emailTagsValid && (
            <div className={style.errorMessage}>
              <ErrorIcon className={style.errorIcon} />
              Please enter valid email addresses
            </div>
          )}
          {inviteLimitReached && (
            <div className={style.errorMessage}>
              <ErrorIcon className={style.errorIcon} />
              You have reached the limit of 10 total pending invites on the Starter plan
            </div>
          )}
          {duplicatesDetected && (
            <div className={style.errorMessage}>
              <ErrorIcon className={style.errorIcon} />
              Workspace member emails cannot be invited again
            </div>
          )}
          {emailAlreadyInUseDetected && (
            <div className={style.errorMessage}>
              <ErrorIcon className={style.errorIcon} />
              User has already joined a different Ditto workspace. Please try a different email.
            </div>
          )}
        </div>
        <div className={style.inputSection}>
          <label>Permissions</label>
          <select value={role} onChange={onRoleChange}>
            {Object.keys(getInvitePermissions()).map((option) =>
              option === "commenter" && !canInviteCommenter ? (
                <option key={option} value={option} disabled={option === "commenter" && !canInviteCommenter}>
                  &#128274;{` ${ROLE_OPTIONS[option]}`}
                </option>
              ) : (
                <option key={option} value={option}>
                  {`${ROLE_OPTIONS[option]}`}
                </option>
              )
            )}
          </select>
        </div>
        {showCustomMessage && (
          <div className={style.inputSection}>
            <label>
              Message <span className={style.optionalText}>(optional)</span>
            </label>
            <div>
              <textarea
                placeholder="Add a custom message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
              />
            </div>
          </div>
        )}
        {inviteType === "project" && (
          <div className={style.disclaim}>
            Heads up! People invited to collaborate on this project will join your workspace.
          </div>
        )}
        <div className={style.inviteButton}>
          <ButtonPrimary
            data-testid="invite-button"
            text={isInviteSending ? "Sending..." : "Invite"}
            onClick={onInviteClick}
            disabled={emails.length === 0 || emailTagsValid || duplicatesDetected || emailAlreadyInUseDetected}
          />
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default InviteModal;
