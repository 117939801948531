import classnames from "classnames";
import { diffWords } from "diff";
import React, { useMemo } from "react";
import Text, { TextSize } from "../Text";
import style from "./index.module.css";

interface IProps {
  size?: TextSize;
  className?: string;
  textBefore: string;
  textAfter: string;
}

/**
 * This component is used to render a diff between two strings, to show what has been edited.
 * This component only supports plaintext diff right now.
 * TODO: Add rich text support. See implementation in EditDiff for a guide. DIT-8478
 */
export function EditedText({ size, className, textBefore, textAfter }: IProps) {
  const diff = useMemo(() => {
    if (textBefore != undefined && textAfter != undefined) {
      return diffWords(textBefore, textAfter);
    }
  }, [textBefore, textAfter]);

  if (!diff) {
    return <></>;
  }

  return (
    <div className={className} data-testid="edited-text">
      {diff.map((section, key) => {
        return (
          <Text
            key={key}
            className={classnames({
              [style.textAfter]: section.added,
              [style.textBefore]: section.removed,
            })}
            color={section.removed ? "secondary" : undefined}
            size={size}
            inline
          >
            {section.value}
          </Text>
        );
      })}
    </div>
  );
}

export default EditedText;
