import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

interface SuggestionPaginationProps {
  index: number;
  isSelected: boolean;
  numItems: number;
  decrementIndex: (e: any) => void;
  incrementIndex: (e: any) => void;
  itemName?: {
    singular: string;
    plural: string;
  };
  className?: string;
}

const SuggestionPagination = ({
  index,
  isSelected,
  numItems,
  decrementIndex,
  incrementIndex,
  itemName,
  className,
}: SuggestionPaginationProps) => {
  const renderedItemName = itemName ? (numItems === 1 ? itemName.singular : itemName.plural) : "";

  return (
    <div
      className={classNames(
        {
          [style.wrapper]: true,
          [style.isSelected]: isSelected,
        },
        className
      )}
    >
      <ChevronLeftIcon
        className={classNames({
          [style.icon]: true,
          [style.btnDisabled]: index === 0,
        })}
        onClick={decrementIndex}
      />
      <div>
        {index + 1} of {numItems} {renderedItemName}
      </div>
      <ChevronRightIcon
        className={classNames({
          [style.icon]: true,
          [style.btnDisabled]: index === numItems - 1,
        })}
        onClick={incrementIndex}
      />
    </div>
  );
};

export default SuggestionPagination;
