import classNames from "classnames";
import React, { useMemo } from "react";
import getBoldedMatchingText from "../../../helpers/getBoldedMatchingText";
import Badge from "../../atoms/Badge";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import style from "./index.module.css";

// Right now, tags are really just strings. At some point I expect this to change -- adding this type as a reminder
// of where that will need to be accounted for.
type Tag = string;

interface IProps {
  selectedTags: Tag[];
  setSelectedTags: (value: Tag[]) => void;
  allTags: Tag[];

  onRemoveFilter?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export function TagsFilterDropdown(props: IProps) {
  function onChange(selectedOptions: { value: Tag; label: string }[]) {
    props.setSelectedTags(selectedOptions.map((v) => v.value));
  }

  const formattedSelectedOptions = useMemo(
    () =>
      props.selectedTags?.map((tag) => ({
        value: tag,
        label: tag,
      })),
    [props.selectedTags]
  );

  const formattedAllTags = useMemo(
    () =>
      props.allTags
        ?.map((tag) => ({
          value: tag,
          label: tag,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [props.allTags]
  );

  return (
    <DropdownCombobox
      className={classNames(style.tagsFilterDropdown, props.className)}
      style={props.style}
      options={formattedAllTags}
      selectedOptions={formattedSelectedOptions}
      onChange={onChange}
      multiple
      TriggerComponent={TriggerComponent}
      OptionComponent={OptionComponent}
      onRemoveFilter={props.onRemoveFilter}
      inputPlaceholder="Choose tags..."
    />
  );
}

interface ITriggerProps {
  selectedOptions: { value: Tag; label: string }[];
}

function TriggerComponent(props: ITriggerProps) {
  return (
    <div className={style.trigger}>
      {props.selectedOptions.length === 0 && (
        <Text color="secondary" weight="light" size="micro">
          Add tags...
        </Text>
      )}
      {props.selectedOptions.length > 0 && (
        <div className={style.selected}>
          {props.selectedOptions.map((tag) => (
            <Badge key={tag.value} color="black" size="xs" borderRadius="xs" className={style.tag}>
              {tag.label}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
}

interface IOptionProps {
  option: { value: Tag; label: string };
  query: string;
  selected: boolean;
}

function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <span>{getBoldedMatchingText(props.option.label, props.query)}</span>
    </Text>
  );
}

export default TagsFilterDropdown;
