import CloseIcon from "@mui/icons-material/Close";
import classnames from "classnames";
import React, { useContext, useMemo, useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ButtonPrimary from "../../../../../../components/button/buttonprimary";
import ConfirmationModal from "../../../../../../components/shared/confirmation-modal";
import http from "../../../../../../http";
import { PageSelected } from "../../../../state/usePageState";
import PageGroupSelection from "../PageGroupSelection";

import { UnsavedChangesContext } from "../../../../../../store/unsavedChangesContext";
import { ManageGroupsModalContext } from "../../ManageGroupsModalContext";
import style from "./style.module.css";

const DRAFT_PAGE_ID = "__draft__";

export type SelectedGroup = {
  _id: string;
  name: string;
  frameName: string;
  isLinked: boolean;
};
type SelectedGroupByPages = {
  [pageId: string]: SelectedGroup[];
};
interface Props {
  docId: string;
  devModeEnabled: boolean;
  isFigmaAuthenticated: boolean;
  figmaFileId: string;
  pages: PageSelected[];
  onHide: () => void;
  onShowSelectFrames: () => void;
  handleUnlinkGroups: (input: {
    projectId: string;
    deletedGroupIds: string[];
    unlinkedGroupIds: string[];
  }) => Promise<void>;
}
const CurrentGroupsModal = ({
  docId,
  devModeEnabled,
  isFigmaAuthenticated,
  figmaFileId,
  pages,
  onHide,
  onShowSelectFrames,
  handleUnlinkGroups,
}: Props) => {
  const { unsavedDetailChangesExist, showUnsavedChangesModal: showModal } = useContext(UnsavedChangesContext);
  const { groupsByPage, groupsToDelete, groupsToUnlink } = useContext(ManageGroupsModalContext);

  const [savingChanges, setSavingChanges] = useState<boolean>(false);
  const [discardAction, setDiscardAction] = useState<any>({});
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);

  const groupsHaveChanges = groupsToDelete.size > 0 || groupsToUnlink.size > 0;

  const deleteMsg = useMemo(() => {
    if (!groupsHaveChanges) return null;

    let deleteMsg = "";
    if (groupsToDelete.size > 0) {
      deleteMsg += `${groupsToDelete.size} deleted`;
    }
    if (groupsToDelete.size && groupsToUnlink.size) {
      deleteMsg += ` and `;
    }
    if (groupsToUnlink.size) {
      deleteMsg += `${groupsToUnlink.size} unlinked`;
    }

    return deleteMsg;
  }, [groupsToUnlink, groupsToDelete]);

  const onSave = async () => {
    try {
      setSavingChanges(true);
      setShowUnsavedChangesModal(false);

      const selectedPageIds: string[] = [];
      const selectedPageInfo: { figma_id: string; name: string }[] = [];
      const groupsToRemove: string[] = Object.keys(groupsByPage).filter((pageId) => pageId !== DRAFT_PAGE_ID);

      Object.keys(groupsByPage).forEach((pageId) => {
        if (pageId !== DRAFT_PAGE_ID && groupsByPage[pageId].some((group) => !groupsToUnlink.has(group._id))) {
          selectedPageIds.push(pageId);
          groupsToRemove.splice(groupsToRemove.indexOf(pageId), 1);
        }
      });

      pages.forEach((page) => {
        if (selectedPageIds.includes(page.id)) {
          selectedPageInfo.push({ figma_id: page.id, name: page.name });
        }
      });

      await http.put(`/doc/updateSelectedPages/${docId}`, {
        pagesToAdd: selectedPageInfo,
        pagesToRemove: groupsToRemove,
      });
      await handleUnlinkGroups({
        projectId: docId,
        deletedGroupIds: [...groupsToDelete],
        unlinkedGroupIds: [...groupsToUnlink],
      });
      // wait for response before closing modal
      onHide();
    } catch (error) {
      console.error("Error saving changes in ManageGroupsModal: ", error);
    }
    setSavingChanges(false);
  };

  const onHideModal = () => {
    if (groupsHaveChanges) {
      setShowUnsavedChangesModal(true);
      setDiscardAction({ callback: onHide });
    } else onHide();
  };

  const onClickAddFrames = () => {
    if (!isFigmaAuthenticated) return;
    if (groupsHaveChanges) {
      setShowUnsavedChangesModal(true);
      setDiscardAction({ callback: onShowSelectFrames });
    } else onShowSelectFrames();
  };

  const onDiscard = () => {
    setShowUnsavedChangesModal(false);
    discardAction.callback();
  };

  const figmaFileLinked = Boolean(figmaFileId);

  return (
    <>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={classnames([style.dialog, style.width60])}
        backdropClassName={style.backdrop}
        onHide={onHideModal}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>Add or remove frames</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHideModal} />
        </BootstrapModal.Header>
        <BootstrapModal.Body className={style.selectFramesBody}>
          <PageGroupSelection
            pages={pages}
            groupsByPage={groupsByPage}
            devModeEnabled={devModeEnabled}
            isSaving={savingChanges}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={classnames([style.footer], [style.frameSelection])}>
          {figmaFileLinked ? (
            <p
              data-tip
              data-for="add-frames-tooltip"
              className={classnames({
                [style.description]: true,
                [style.disabled]: !isFigmaAuthenticated,
              })}
              onClick={onClickAddFrames}
            >
              Add frames from Figma file
            </p>
          ) : (
            <div />
          )}
          {!figmaFileLinked && <div />}
          {figmaFileLinked && !isFigmaAuthenticated && (
            <ReactTooltip
              id="add-frames-tooltip"
              place="bottom"
              effect="solid"
              clickable={true}
              delayHide={1000}
              className={style.toolTip}
            >
              To add Figma frames, you'll need to{" "}
              <Link
                onClick={(event) => {
                  if (unsavedDetailChangesExist) {
                    event.preventDefault();
                    showModal();
                  }
                }}
                to={`/account/user?reauthorize_figma=true`}
              >
                add a Figma connection.
              </Link>
              .
            </ReactTooltip>
          )}
          <div className={style.saveWrapper}>
            <p>{deleteMsg}</p>
            <ButtonPrimary
              disabled={savingChanges || !groupsHaveChanges || !deleteMsg}
              text={savingChanges ? "Saving..." : "Save"}
              onClick={onSave}
            />
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
      {showUnsavedChangesModal && (
        <ConfirmationModal
          title="You have unsaved changes!"
          body="Before navigating away, would you like to save your changes?"
          actionPrimary="Save"
          actionSecondary="Discard"
          onPrimary={onSave}
          onSecondary={onDiscard}
          isLayered={true}
        />
      )}
    </>
  );
};

export default CurrentGroupsModal;
