import { ConcurrentUserList } from "@/components/ConcurrentUserList";
import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { useConcurrentUserState } from "@shared/frontend/useConcurrentUserState";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import useWebSocket from "react-use-websocket";
import { useProjectContext } from "../../state/useProjectState";
import style from "./style.module.css";

function ConcurrentUserListWrapper() {
  const { getTokenSilently, user } = useAuthenticatedAuth();
  const { projectId } = useProjectContext();
  const { sendJsonMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  const concurrentUserState = useConcurrentUserState(`projects:${projectId}`, user, {
    getToken: getTokenSilently,
    sendWebsocketMessage: sendJsonMessage,
  });

  return <ConcurrentUserList users={concurrentUserState.users} className={style.concurrentUserList} />;
}
export default ConcurrentUserListWrapper;
