import MoreVert from "@mui/icons-material/MoreVert";
import classNames from "classnames";
import React from "react";
import SelectMenu from "../select-menu/select-menu";
import style from "./UserManagementDropdown.module.css";

interface Option {
  name: string;
  value: string;
  isDisabled?: boolean;
}

interface UserManagementDropdownProps {
  options: Option[];
  selected: string;
  showManagePermissions: boolean;
  onSelectRole: (option: Option) => void;
  canManagePermissions: boolean;
  onManagePermissions: () => void;
  onRemoveUser: () => void;
  canRemoveUsers: boolean;
  className?: string;
  optionClassName?: string;
}

const UserManagementDropdown = (props: UserManagementDropdownProps) => {
  const {
    options,
    selected,
    showManagePermissions,
    onSelectRole,
    onManagePermissions,
    canRemoveUsers,
    onRemoveUser,
    className,
    optionClassName,
  } = props;

  return showManagePermissions ? (
    <SelectMenu
      className={classNames(className, {
        [style.managePermissions]: showManagePermissions,
      })}
      options={[
        {
          name: "Manage permissions",
          value: "manage-permissions",
          isDisabled: !props.canManagePermissions,
        },
      ]}
      icon={
        <button className={style.optionsBtn}>
          <MoreVert className={style.dotsIcon} />
        </button>
      }
      selected={selected}
      onSelect={onManagePermissions}
      rootOption={canRemoveUsers ? "Remove user" : undefined}
      onRootSelect={canRemoveUsers ? onRemoveUser : () => {}}
      optionClassName={optionClassName}
    />
  ) : (
    <SelectMenu
      className={className}
      options={options}
      icon={
        <button className={style.optionsBtn}>
          <MoreVert className={style.dotsIcon} />
        </button>
      }
      selected={selected}
      onSelect={onSelectRole}
      rootOption={canRemoveUsers ? "Remove user" : undefined}
      onRootSelect={canRemoveUsers ? onRemoveUser : () => {}}
      optionClassName={optionClassName}
    />
  );
};

export default UserManagementDropdown;
