import useSearchState from "@/hooks/useSearchState";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ActualComponentInterface, ActualComponentSchema } from "@shared/types/TextItem";
import debounce from "lodash.debounce";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { PANELS } from "../../defs";
import http, { API } from "../../http";
import { UnsavedChangesContext } from "../../store/unsavedChangesContext";
import SearchDetail from "./components/searchdetail";
import SearchResults from "./components/searchresults";
import style from "./style.module.css";
import { CommentState } from "./types";

const Search = () => {
  const history = useHistory();
  const { checkDetailPanelChanges } = useContext(UnsavedChangesContext);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedDocId, setSelectedDocId] = useState<string | null>(null);
  const [selectedComp, setSelectedComp] = useState<ActualComponentInterface<string> | null>(null);
  const [panelState, setPanelState] = useState<string>(PANELS.doc.edit); //edit, hist, dev
  const [commentState, setCommentState] = useState<CommentState>({
    isSelected: false,
    thread_id: null,
  });
  const [results, setResults] = useState<ActualComponentInterface<string>[]>([]);
  const {
    tagState: [tagState, setTagState],
  } = useSearchState();
  const [allComps, setAllComps] = useState<ActualComponentInterface<string>[]>([]);
  const allTags = Array.from(tagState.selected.values());
  const [statusFilter, setStatusFilter] = useState<string>("Any");
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingComps, setLoadingComps] = useState<boolean>(true);
  const [allFetched, setAllFetched] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [devID, setDevID] = useState<string | null>(null);
  const [assigneeFilter, setAssigneeFilter] = useState<string | null>(null);
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

  const SWAP_OUT_METHOD_LIMIT = 100; // max number of changed items for using "swap-out" method of updating search setResults (as opposed to just fetching all)
  const LOAD_COMP_INTERVAL = 250;
  // const page_size = 50;
  const page_size = 5; // number of documents...
  const [index, setIndex] = useState<number>(0); //used to paginate results
  const [numLoadedComps, setNumLoadedComps] = useState<number>(LOAD_COMP_INTERVAL);

  useEffect(() => {
    window?.analytics?.page("Search");
  }, []);

  const selectComp = (comp: ActualComponentInterface<string>) => {
    // if want to reimplement multi-select, look at selectComp in Doc
    checkDetailPanelChanges(() => {
      if (comp._id === selectedId) {
        setSelectedId(null);
        setSelectedComp(null);
        setSelectedDocId(null);
      } else {
        setSelectedId(comp._id);
        setSelectedDocId(comp.doc_ID);
        setSelectedComp(comp);
        if (comp.doc_ID === undefined) {
          window?.analytics?.track("Comp selected has undefined doc_ID", {
            comp: JSON.stringify(comp),
          });
        }
      }
    });
  };

  const fetchAllPage = async () => {
    if (allFetched) {
      return;
    }
    setLoadingComps(true);
    try {
      const { url, body } = API.comp.post.page;
      const { data: new_all_comps } = await http.post(
        // @ts-ignore http file is not typed
        url({ applySearchFilter: true }),
        body({
          page_size,
          index,
        })
      );
      if (new_all_comps.index < page_size) {
        setAllFetched(true);
      }
      setIndex(index + 1);
      let new_results = [...allComps];
      new_results.push(...new_all_comps.results);
      setAllComps(new_results);
      setLoadingComps(false);
    } catch (error) {
      console.error(error);
      setLoadingComps(false);
    }
  };

  const updateOneComp = async (changedCompId: string) => {
    setLoadingComps(true);
    try {
      const { url } = API.comp.get.info;
      const { data: comp } = await http.get(url(changedCompId));
      var foundIndex = allComps.findIndex((obj) => obj._id == changedCompId);
      allComps[foundIndex] = comp;
      setAllComps(allComps); // maybe don't set all comps?
      setLoadingComps(false);
    } catch (e) {
      setLoadingComps(false);
      console.error("inside search.jsx updateOneComp: ", e);
    }
  };

  const updateMultipleComps = async (compIdsArray: string[]) => {
    try {
      const { url, body } = API.comp.post.infoBatch;
      const { data: comps } = await http.post(url, body({ ids: compIdsArray }));

      const textItemById = comps.reduce((acc, textItem) => {
        acc[textItem._id] = textItem;
        return acc;
      }, {} as Record<string, ActualComponentSchema>);

      for (const changedCompId of compIdsArray) {
        var foundIndex = allComps.findIndex((obj) => obj._id == changedCompId);
        allComps[foundIndex] = textItemById[changedCompId];
      }
      setAllComps(allComps); // maybe don't set all comps?
      setLoadingComps(false);
    } catch (e) {
      setLoadingComps(false);
      console.error("inside search.jsx updateMultipleComps: ", e);
    }
  };

  const fetchAll = async () => {
    setLoadingComps(true);
    try {
      const { url } = API.comp.get.all;
      const { data: new_all_comps } = await http.get(url);
      setAllFetched(true);
      setAllComps(new_all_comps);
      setLoadingComps(false);
    } catch (error) {
      console.error(error);
      setLoadingComps(false);
    }
  };

  const fetchWorkspaceTags = async () => {
    try {
      const { url } = API.comp.get.allTags;
      const { data: tags } = await http.get<{ _id: string; total: number }[]>(
        url({
          excludeWsComps: true,
          sortAlpha: false,
          excludeSampleData: false,
        })
      );
      tags.sort((a, b) => a._id.localeCompare(b._id));

      const counts: Record<string, number> = {};
      tags.forEach(({ _id, total }) => {
        counts[_id] = total;
      });
      setTagState((s) => ({ ...s, counts }));
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetch = useCallback(
    debounce(async (query) => {
      setDebouncedQuery(query);
      if (query.length > 0) {
        await refetchSearchResults({ queryArg: query });
      } else {
        setLoadingSearch(false);
      }
    }, 500),
    [tagState.selected, devID, statusFilter, assigneeFilter]
  );

  const handleQueryChange = (value: string) => {
    setQuery(value);
    setResults([]);
    debouncedFetch(value);
    // unselect if anything is selected
    if (selectedId) {
      setSelectedComp(null);
    }
  };

  const handleAssigneeChange = (assignee: string) => {
    setAssigneeFilter(assignee);
    refetchSearchResults({ assigneeArg: assignee });
  };

  const handleStatusChange = (status: string) => {
    setStatusFilter(status);
    refetchSearchResults({ statusArg: status });
  };

  const handleDevIDChange = (id: string) => {
    setDevID(id);
    refetchSearchResults({ devIDArg: id });
  };

  const SEARCH_PAGE_SIZE = 30;
  const searchIndex = useRef(0);
  const noMoreSearchResults = useRef(false);

  useEffect(
    function resetSearchVariablesOnQueryChange() {
      searchIndex.current = 0;
      noMoreSearchResults.current = false;
      setResults([]);
    },
    [query]
  );

  // We use a ref to store noMoreSearchResults because we want to
  // use the value synchronously, but we still want to trigger a re-render
  // when it changes.
  useEffect(
    function updateLoadMore() {
      setCanLoadMore(!noMoreSearchResults.current);
    },
    [noMoreSearchResults.current]
  );

  /**
   * This function is purely responsible for hitting the /search endpoint.
   * It should not interact with the state of the component, other than to set
   * the `searchLoading` flag.
   */
  const getSearchResults = async (
    query: string,
    assignee: string | null,
    tags: string[],
    status: string,
    devID: string | null,
    index: number,
    pageSize: number
  ) => {
    setLoadingSearch(true);
    try {
      const { url, body } = API.comp.post.search;
      const { data: search_results } = await http.post(
        url,
        body({
          query,
          assignee,
          tags,
          status,
          index,
          devID,
          pageSize,
        })
      );
      setLoadingSearch(false);

      return search_results;
    } catch (error) {
      console.error(error);
      setLoadingSearch(false);
    }
  };

  /**
   * This function searches based on existing filters, and adds new results to
   * the existing results based on current pagination. Used only by "Load More".
   */
  const fetchSearchNextPage = async () => {
    searchIndex.current += 1;

    const search_results = await getSearchResults(
      query,
      assigneeFilter,
      Array.from(tagState.selected.values()),
      statusFilter,
      devID,
      searchIndex.current,
      SEARCH_PAGE_SIZE
    );

    if (search_results.length < SEARCH_PAGE_SIZE) {
      noMoreSearchResults.current = true;
    }
    setResults([...results, ...search_results]);
  };

  /**
   * This function searches based on the filters in state, and resets the pagination
   * info back to its initial state. Used whenever we need to do a new search.
   */
  const refetchSearchResults = async (
    args: {
      queryArg?: string;
      selectedTagsArg?: string[];
      assigneeArg?: string | null;
      devIDArg?: string | null;
      statusArg?: string;
    } = {}
  ) => {
    // default to current state, but allow overriding
    const {
      queryArg = query,
      selectedTagsArg = Array.from(tagState.selected.values()),
      assigneeArg = assigneeFilter,
      statusArg = statusFilter,
      devIDArg = devID,
    } = args;

    searchIndex.current = 0;
    noMoreSearchResults.current = false;

    const search_results = await getSearchResults(
      queryArg,
      assigneeArg || assigneeFilter,
      selectedTagsArg,
      statusArg,
      devIDArg,
      searchIndex.current,
      SEARCH_PAGE_SIZE
    );

    if (search_results.length < SEARCH_PAGE_SIZE) {
      noMoreSearchResults.current = true;
    }

    setResults(search_results);
  };

  const selectTag = async (tagName: string) => {
    const selected = new Set(tagState.selected);
    selected.has(tagName) ? selected.delete(tagName) : selected.add(tagName);
    setTagState((s) => ({ ...s, selected }));

    if (selected.size !== 0) {
      refetchSearchResults({ selectedTagsArg: Array.from(selected.values()) });
    }

    if (selectedId) {
      setSelectedComp(null);
    }
  };

  const clearSelectedTags = () => {
    setTagState((s) => ({ ...s, selected: new Set() }));
  };

  const updateResults = async (compIdsArray) => {
    if (query === "" && tagState.selected.size === 0) {
      if (compIdsArray.length === 1) {
        // if just changed one thing, just update that in array
        updateOneComp(compIdsArray[0]);
      } else if (compIdsArray.length <= SWAP_OUT_METHOD_LIMIT) {
        updateMultipleComps(compIdsArray);
      } else {
        fetchAll();
      }
    } else {
      refetchSearchResults();
    }
  };

  const loadMoreComps = async () => {
    if (query !== "" || tagState.selected.size !== 0 || statusFilter !== "Any" || assigneeFilter !== null) {
      fetchSearchNextPage();
      return;
    }
    fetchAllPage();
    if (numLoadedComps < allComps.length) {
      setNumLoadedComps(numLoadedComps + LOAD_COMP_INTERVAL);
    }
  };

  useEffect(() => {
    setNumLoadedComps(LOAD_COMP_INTERVAL);
  }, [results]);

  useEffect(() => {
    fetchWorkspaceTags();
    let searchParams = new URLSearchParams(history.location.search);
    let initialQuery = searchParams.get("query")?.toString();
    if (initialQuery) {
      setLoadingSearch(true);
      setDebouncedQuery(initialQuery);
      handleQueryChange(initialQuery);
      // remove initial query from url for now so it doesn't break things
      searchParams.delete("query");
      history.push({
        search: searchParams.toString(),
      });
    }
    fetchAllPage();
  }, [location]);

  return (
    <div className={style.docContainer}>
      <ReactTooltip id="info" place="bottom" effect="solid" className={style.tooltip}>
        This page will search through all text items in your workspace. For universal search, use the{" "}
        {navigator.userAgent.includes("Mac") ? "⌘ + K" : "Ctrl + K"} keyboard shortcut.
      </ReactTooltip>
      <div>
        <h1 className={style.pageTitle}>
          Search Text Items
          <InfoOutlinedIcon data-tip data-for="info" className={style.infoIcon} />
        </h1>
      </div>
      <div className={style.resultsAndDetail}>
        <SearchResults
          selectComp={selectComp}
          selectedId={selectedId}
          handleQueryChange={handleQueryChange}
          loadingSearch={loadingSearch}
          loadingComps={loadingComps}
          results={results}
          allComps={allComps}
          selectTag={selectTag}
          clearSelectedTags={clearSelectedTags}
          tagState={tagState}
          statusFilter={statusFilter}
          setStatusFilter={handleStatusChange}
          allFetched={allFetched}
          query={query}
          setQuery={setQuery}
          assignee={assigneeFilter}
          setAssignee={handleAssigneeChange}
          devID={devID}
          setDevID={handleDevIDChange}
          setPanelState={setPanelState}
          setCommentState={setCommentState}
          loadMoreComps={loadMoreComps}
          numLoadedComps={numLoadedComps}
          debouncedQuery={debouncedQuery}
          noMoreSearchResults={!canLoadMore}
        />
        {selectedId && (
          <SearchDetail
            selectedId={selectedId}
            selectedComp={selectedComp}
            selectedDocId={selectedDocId}
            updateResults={updateResults}
            panelState={panelState}
            setPanelState={setPanelState}
            commentState={commentState}
            setCommentState={setCommentState}
            fetchWorkspaceTags={fetchWorkspaceTags}
            workspaceTags={allTags}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
