import { createHttpRequest } from "./lib/createHttpRequest";

export const removeLaunchDarklyConfig = createHttpRequest<void, void>({
  method: "delete",
  url: "/connections/launchDarkly",
});

export const updateLaunchDarklyConfig = createHttpRequest<
  {
    token?: string;
    projectId?: string;
  },
  {
    tokenPreview: string;
    projectId: string;
  }
>({
  method: "put",
  url: "/connections/launchDarkly",
  getConfig: (arg) => ({
    data: {
      token: arg.token,
      projectId: arg.projectId,
    },
  }),
});

export interface ILoadLaunchDarklyDataResponse {
  data: {
    flagId: string;
    flagName: string;
    syncedVariants: string[];
    lockedVariants: { _id: string; reason: string }[];
  }[];
  variants: {
    variants: {
      variantId: string;
      variantName: string;
    }[];
    folderId: string | null;
    folderName: string;
  }[];
}

export const loadLaunchDarklyData = createHttpRequest<void, ILoadLaunchDarklyDataResponse>({
  method: "get",
  url: "/connections/launchDarkly",
});

export const launchDarklySync = createHttpRequest<
  {
    data: { flagId: string; syncedVariants: string[] }[];
  },
  void
>({
  method: "post",
  url: "/connections/launchDarkly/sync",
  getConfig: (arg) => ({ data: { data: arg.data } }),
});

export const removeSplitConfig = createHttpRequest<void, void>({
  method: "delete",
  url: "/connections/split",
});

export const updateSplitConfig = createHttpRequest<
  {
    token?: string;
    environmentName?: string;
    workspaceId?: string;
  },
  {
    tokenPreview: string;
    environmentName: string;
    workspaceId: string;
  }
>({
  method: "put",
  url: "/connections/split",
  getConfig: (arg) => ({
    data: {
      token: arg.token,
      environmentName: arg.environmentName,
      workspaceId: arg.workspaceId,
    },
  }),
});

export interface ILoadSplitDataResponse {
  data: {
    flagId: string;
    flagName: string;
    syncedVariants: string[];
    lockedVariants: { _id: string; reason: string }[];
  }[];
  variants: {
    variants: {
      variantId: string;
      variantName: string;
    }[];
    folderId: string | null;
    folderName: string;
  }[];
}

export const loadSplitData = createHttpRequest<void, ILoadSplitDataResponse>({
  method: "get",
  url: "/connections/split",
});

export const splitSync = createHttpRequest<
  {
    data: { flagId: string; syncedVariants: string[] }[];
  },
  void
>({
  method: "post",
  url: "/connections/split/sync",
  getConfig: (arg) => ({ data: { data: arg.data } }),
});
