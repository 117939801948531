import axios, { AxiosResponse } from "axios";
import http from "./lib/client";

const url = (path: string) => `/util${path}`;

export interface SendSlackMessageResponse {}

const sendFeedbackMessage = (messages: string[]): [Promise<AxiosResponse<SendSlackMessageResponse>>, () => void] => {
  const source = axios.CancelToken.source();

  const request = http.post<SendSlackMessageResponse>(
    url("/feedback"),
    {
      messages,
    },
    {
      cancelToken: source.token,
    }
  );

  const cancelRequest = () => source.cancel();

  return [request, cancelRequest];
};

export default {
  sendFeedbackMessage,
};
