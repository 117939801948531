import React from "react";

const WebhookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M280-130.001q-78.769 0-134.384-55.615Q90.001-241.231 90.001-320q0-66.076 39.538-116.345 39.538-50.269 100.46-66.192v62.998q-35 14.308-57.5 46.846-22.5 32.539-22.5 72.693 0 53.846 38.077 91.924 38.078 38.077 91.924 38.077 53.846 0 91.924-38.077 38.077-38.078 38.077-91.924v-29.999h225.384q6.846-10.923 18.538-17.77 11.692-6.846 26.077-6.846 22.692 0 38.654 15.961 15.961 15.962 15.961 38.654 0 22.692-15.961 38.654-15.962 15.961-38.654 15.961-14.769 0-26.269-6.654-11.5-6.654-18.346-17.962H467.538q-11.692 71.308-65.038 115.654-53.346 44.346-122.5 44.346Zm400 0q-49.076 0-89.961-22.884-40.884-22.884-67.269-61.73h81.614q15.154 11.539 34.846 18.077 19.693 6.539 40.77 6.539 53.846 0 91.924-38.077 38.077-38.078 38.077-91.924 0-53.846-38.077-91.924-38.078-38.077-91.924-38.077-18.846 0-34.884 4.731-16.039 4.731-30.654 13.807l-117-194.922q-21-.538-36.538-15.577Q445.385-657 445.385-680q0-22.692 15.961-38.654 15.962-15.961 38.654-15.961 22.692 0 38.654 15.961 15.961 15.962 15.961 38.654 0 6.154-1.154 11.385t-4.308 10.231L640-505.845q9.154-2 19.115-3.077 9.962-1.077 20.885-1.077 78.769 0 134.384 55.615Q869.999-398.769 869.999-320q0 78.769-55.615 134.384Q758.769-130.001 680-130.001ZM280-265.385q-22.692 0-38.654-15.961-15.961-15.962-15.961-38.654 0-21.231 14.577-36.846 14.577-15.615 36.885-16.769l98.231-163.308q-31.308-26.616-48.193-63.731-16.884-37.115-16.884-79.346 0-78.769 55.615-134.384Q421.231-869.999 500-869.999q78.769 0 134.384 55.615Q689.999-758.769 689.999-680h-59.998q0-53.846-38.077-91.924-38.078-38.077-91.924-38.077-53.846 0-91.924 38.077-38.077 38.078-38.077 91.924 0 42.615 24.077 75.5t63.308 46.885L328.538-342.616q3.154 5.385 4.615 10.923 1.462 5.539 1.462 11.693 0 22.692-15.961 38.654-15.962 15.961-38.654 15.961Z" />
    </svg>
  );
};

export default WebhookIcon;
