import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
// import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import ResizableWidthContainer from "@/components/ResizableWidthContainer/ResizableWidthContainer";
import { ExpandableListItem } from "@ds/molecules/ExpandableListItem";
import { IFComponent } from "@shared/types/Component";
import { IFTreeItem } from "@shared/types/http/Component";
import classnames from "classnames";
import React, { useContext } from "react";
import { NodeApi as INode, Tree } from "react-arborist";
import { ComponentFolder } from "../../../../components/compresults/useComponentFolder";
import { FillFlexParent } from "../../../../components/FillFlexParent/FillFlexParent";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { LibraryNavContext } from "./libraryNavState";
import style from "./style.module.css";

interface CompLibraryNavProps {
  isLoading: boolean;
  selectedFolder: ComponentFolder | null;
  componentFolders: ComponentFolder[];
  componentsByFolder: Record<string, IFComponent[]>;
  onFolderClick: (folder_id: string | null) => void;
  totalComponents: number;
  items: IFTreeItem[];
}

const TREE_INDENT = 28;

const CompLibraryNav = (props: CompLibraryNavProps) => {
  const { isLoading, selectedFolder, onFolderClick } = props;
  const {
    treeState,
    treeRef,
    rootLoading: [rootLoading],
    onTreeSelectCallback,
  } = useContext(LibraryNavContext);

  return (
    <ResizableWidthContainer persistKey="compLibraryNav">
      <div className={classnames([style.sidebar, { [style.inFolder]: selectedFolder }])}>
        <div className={style.titleSection}>
          <div className={style.topRow}>
            <div className={style.option}>Navigation</div>
            {selectedFolder && (
              <span className={style.backLink} onClick={() => onFolderClick(null)}>
                {"<-"} Back to All
              </span>
            )}
          </div>
        </div>
        {props.componentFolders.length > 0 && !props.selectedFolder && (
          <div className={style.folders}>
            {props.componentFolders.map((folder) => (
              <FolderNavLink key={folder._id} name={folder.name} selectFolder={() => onFolderClick(folder._id)} />
            ))}
          </div>
        )}
        {!isLoading && (
          <div className={style.treeContainer}>
            <div className={style.componentsRow}>
              <div className={style.componentsLabel}>Components</div>
              <div className={style.componentsCount}>{numberToCommaString(props.totalComponents || 0)}</div>
            </div>
            {rootLoading && <LoadingSpinner className={style.loading} text="Loading..." />}
            {!rootLoading && (
              <FillFlexParent>
                {(dimens) => (
                  <Tree
                    disableDrag={true}
                    disableDrop={true}
                    dndRootElement={null}
                    dndManager={undefined}
                    {...dimens}
                    className={style.tree}
                    rowHeight={28}
                    indent={28}
                    data={treeState.treeItems}
                    openByDefault={false}
                    ref={treeRef}
                    onSelect={onTreeSelectCallback}
                  >
                    {TreeNode}
                  </Tree>
                )}
              </FillFlexParent>
            )}
          </div>
        )}
      </div>
    </ResizableWidthContainer>
  );
};

function numberToCommaString(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function TreeNode(props: { node: INode<IFTreeItem>; style; dragHandle?: React.Ref<HTMLDivElement> }) {
  const { fetchGroupChildren, fetchBlockChildren, goToGroupPage, goToGroupBlockPage, selectedNodeIdSet } =
    useContext(LibraryNavContext);

  async function handleExpand() {
    let fetchPromise: Promise<IFTreeItem[]> | Promise<void> | undefined;

    if (props.node.data.type === "group") {
      fetchPromise = fetchGroupChildren(props.node.data.name, props.node.data.id);
    } else if (props.node.data.type === "block") {
      if (props.node.parent && props.node.parent.data.type === "group") {
        fetchPromise = fetchBlockChildren(
          props.node.parent.data.name,
          props.node.parent.data.id,
          props.node.data.name,
          props.node.data.id
        );
      } else {
        throw new Error("Expected parent to be a group");
      }
    }

    // We want to delay expanding the parent node until *either*:
    // - the fetch has completed, or
    // - the fetch takes longer than 200ms
    // This way, we don't show a "Loading..." node in the tree for really fast fetches.
    await Promise.race([
      new Promise((resolve) => setTimeout(resolve, 200)), // once we open the node
      fetchPromise ?? Promise.resolve(), // resolves immediately if fetchPromise is undefined
    ]);

    props.node.open();
  }

  function handleToggle() {
    if (props.node.isClosed) {
      handleExpand();
    } else {
      props.node.close();
    }
  }

  function handleNodeClick(e: React.MouseEvent) {
    if (props.node.data.type === "group") {
      e.stopPropagation();
      goToGroupPage(props.node.data.name);
    } else if (props.node.data.type === "block") {
      e.stopPropagation();
      goToGroupBlockPage(props.node.parent!.data.name, props.node.data.name);
    }
  }

  if (props.node.data.type === "to-be-loaded") return null;

  const isExpandable = props.node.isInternal;
  const isClickable = true;
  const isSelected = selectedNodeIdSet.has(props.node.data.id);
  const isOpen = props.node.isOpen;
  const indentLevel = props.node.level;

  return (
    <ExpandableListItem
      style={props.style}
      label={props.node.data.name}
      type={props.node.data.type}
      indentLevel={indentLevel}
      isExpandable={isExpandable}
      isSelected={isSelected}
      isClickable={isClickable}
      isOpen={isOpen}
      onClick={handleNodeClick}
      onToggleExpand={handleToggle}
    />
  );
}

const FolderNavLink = ({ name, selectFolder }) => {
  return (
    <div className={style.folderNavLink} onClick={() => selectFolder()}>
      <FolderOpenOutlined className={style.folderIcon} />
      <span className={style.folderName}>{name}</span>
    </div>
  );
};

const ExpandableFolderNavLink = ({ name, selectFolder }) => {
  return (
    <div className={style.expandedFolderNavLink} onClick={() => selectFolder()}>
      <FolderOpenOutlined className={style.folderIcon} />
      <span className={style.folderName}>{name}</span>
    </div>
  );
};
export default React.memo(CompLibraryNav);
