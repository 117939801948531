/**
 * Make sure to keep this in sync with the onboardinChecklistMeta.ts file
 */
export enum OnboardingTaskEnum {
  CREATE_PROJECT = "create_project",
  EDIT_PROJECT = "edit_project",
  SYNC_CHANGES = "sync_changes",
  CREATE_COMPONENTS = "create_components",
  ATTACH_COMPONENTS = "attach_components",
  INVITE_TEAM = "invite_team",
  ADD_VARIANT = "add_variant",
  ADD_COMMENT = "add_comment",
  ENABLE_DEV_INTEGRATIONS = "enable_dev_integrations",
  ORGANIZE_COMPONENTS = "organize_components",
}
