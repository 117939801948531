import React from "react";

export default function (props: { fill?: string }) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_199_478)">
        <path
          d="M6.225 8H7.27L4.715 1.5H3.785L1.23 8H2.275L2.835 6.5H5.655L6.225 8ZM3.215 5.5L4.25 2.74L5.285 5.5H3.215ZM10.795 5.795L6.75 9.84L4.915 8L4.21 8.705L6.755 11.25L11.5 6.5L10.795 5.795Z"
          fill={props.fill || "#787878"}
        />
      </g>
      <defs>
        <clipPath id="clip0_199_478">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
