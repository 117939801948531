import { useWorkspace } from "@/store/workspaceContext";
import { COMPONENTS_MULTI_SELECT_QUERY_PARAM } from "@/views/Components/lib";
import { ACTIVATION_PROGRESS_SEGMENT_COLORS } from "@shared/types/http/project";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { IFRecentCard, IFRecentComponentCard } from "../../../../../shared/types/http/Homepage";
import useSegment from "../../../../hooks/useSegment";
import { getRecents } from "../../../../http/homepage";

export default function useRecentsPanel() {
  const { users } = useWorkspace();
  const segment = useSegment();
  const history = useHistory();
  const [cards, setCards] = useState<IFRecentCard[]>([]);
  const [loading, setLoading] = useState(true);

  async function fetchRecentsData() {
    const [request] = getRecents();
    const { data } = await request;

    // when coming from the backend, the project and project-folder invitedBy fields are userIds
    // we need to replace them with the actual user names
    for (const card of data) {
      if (card.type === "project") {
        const user = users.find((user) => user.userId === card.project.invitedBy);
        card.project.invitedBy = user?.name || "";
        card.project.statusProgress.totalPercentageColor = ACTIVATION_PROGRESS_SEGMENT_COLORS.FINAL_PERCENTAGE;
      }
      if (card.type === "project-folder") {
        const user = users.find((user) => user.userId === card.folder.invitedBy);
        card.folder.invitedBy = user?.name || "";
      }
    }

    setCards(data);
    setLoading(false);
  }

  // project card handles navigation -- just pass segment event
  function onClickProjectCard(projectId: string) {
    segment.track({ event: "Homepage - Recent Project Clicked", properties: { projectId } });
  }

  function getComponentQueryString(card: IFRecentComponentCard) {
    const search = new URLSearchParams();
    if (card.component.multiEditedIds) {
      search.set(COMPONENTS_MULTI_SELECT_QUERY_PARAM, card.component.multiEditedIds.join(","));
    }
    return search.size ? `?${search.toString()}` : "";
  }

  function onClickComponentCard(card: IFRecentComponentCard) {
    segment.track({
      event: "Recent Component Clicked",
      properties: { componentId: card.component._id },
    });

    const qs = getComponentQueryString(card);

    if (card.component.folder) {
      return history.push(
        card.component.multiEditedIds
          ? `/components/folder/${card.component.folder._id}${qs}`
          : `/components/folder/${card.component.folder._id}/${card.component._id}`
      );
    }

    return history.push(card.component.multiEditedIds ? `/components${qs}` : `/components/${card.component._id}`);
  }

  function onClickProjectFolderCard(folderId: string) {
    segment.track({ event: "Homepage - Recent Project Folder Clicked", properties: { folderId } });
    history.push(`/folders/${folderId}`);
  }

  const fetchedOnMount = useRef(false);
  useEffect(
    function fetchRecentsOnMount() {
      if (!users.length || fetchedOnMount.current) return;

      fetchRecentsData();
      fetchedOnMount.current = true;
    },
    [users]
  );

  return {
    loading,
    cards,
    onClickProjectCard,
    onClickComponentCard,
    onClickProjectFolderCard,
  };
}
