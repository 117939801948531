import { useMemo, useRef } from "react";

const DEFAULT_THROTTLE_INTERVAL_MS = 800;

export interface ThrottledCallback<T> {
  (arg?: T, interval?: number): void;
}

/**
 * Returns a function that when called, sets a timer to execute
 * `callback` only once per `interval` ms. By default, If the function is called again
 * before the timer finishes, the new function is skipped. If you pass the option 'queue'
 * set to true, then additional calls after the first per internal will be cached to be
 * executed at the start of the next interval. Only the latest call will be executed.
 * This ensures that `callback` is only ever called every `interval` ms at a maximum.
 *
 * To avoid unexpected results, the reference to `callback` should be cached
 * using `useMemo` or `useCallback`.
 */
const useThrottledCallback = <T>(
  callback: (arg?: T) => void,
  defaultInterval: number = DEFAULT_THROTTLE_INTERVAL_MS,
  additionalDependencies: unknown[] = [],
  queue: boolean = false
): ThrottledCallback<T> => {
  const shouldWait = useRef(false);
  const waitingArgs = useRef<T | null>(null);
  const throttleTimeout = useRef<NodeJS.Timer | null>(null);

  return useMemo(() => {
    const timeoutFunc = () => {
      if (waitingArgs.current === null) {
        shouldWait.current = false;
      } else {
        callback(waitingArgs.current);
        waitingArgs.current = null;
        throttleTimeout.current = setTimeout(timeoutFunc, defaultInterval);
      }
    };

    const fn = (arg?: T, interval = defaultInterval) => {
      if (shouldWait.current) {
        if (queue && arg) {
          waitingArgs.current = arg;
        }
        return;
      }
      callback(arg);
      shouldWait.current = true;
      throttleTimeout.current = setTimeout(timeoutFunc, interval);
    };
    return fn;
  }, [callback, defaultInterval, ...additionalDependencies]);
};

export default useThrottledCallback;
