import CallSplitIcon from "@mui/icons-material/CallSplit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { default as classNames } from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ApiID from "../../../../components/api-id";
import { routes } from "../../../../defs";
import VariantDeleteModal from "../VariantDeleteModal";
import style from "./style.module.css";

const OptionsToggle = React.forwardRef(({ onClick }, ref) => (
  <button ref={ref} onClick={onClick} className={style.moreButton}>
    <MoreVertIcon className={style.moreIcon} />
  </button>
));
OptionsToggle.displayName = "OptionsToggle";

const VariantItem = ({
  onClick,
  variant,
  canMoveFolders,
  uniqueApiIDs,
  uniqueVariantNames,
  handleDeleteVariant,
  handleRenameVariant,
  handleRenameVariantApiID,
  handleMoveOption,
  toggleSelected,
  isSingleSelected,
  isMultiSelected,
}) => {
  const [expandProjects, setExpandProjects] = useState(false);
  const [expandComponents, setExpandComponents] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const openProject = (doc_id) => history.push(routes.nonNavRoutes.project.getPath(doc_id));

  const openComponent = (component_id) => history.push(`/components/${component_id}`);

  const onEditApiID = (newId) => handleRenameVariantApiID(variant._id, newId);
  const openDeleteModal = () => setShowModal(true);
  const onDelete = () => handleDeleteVariant();
  const onHideModal = () => setShowModal(false);

  const canEdit = userHasResourcePermission("variant_folder:edit");

  const canDeleteVariant =
    canEdit && variant.docs.length === 0 && (!variant.components || variant.components.length === 0);

  const projectCount = variant.docs.length;
  const projectLabel = projectCount === 1 ? "project" : "projects";

  const componentCount = variant.components.length;
  const componentLabel = componentCount === 1 ? "component" : "components";

  const usedInText = (
    <span>
      <b>{variant.docs.length}</b> {projectLabel}, <b>{variant.components.length}</b> {componentLabel}
    </span>
  );

  return (
    <div
      data-testid={`variant-item-${variant.name}`}
      className={classNames(style.variantItem, {
        [style.isSingleSelected]: isSingleSelected,
        [style.isMultiSelected]: isMultiSelected,
        [style.editPanelFeatureFlagEnabled]: true,
      })}
      id={variant._id}
      onClick={onClick}
    >
      <div className={style.header}>
        <div className={style.title}>
          <>
            <CallSplitIcon className={style.splitIconRotate} /> <span>{variant.name}</span>{" "}
            <ApiID className={style.variantNameApiId} id={variant.apiID} />
          </>
        </div>

        {canEdit && (
          <input
            type="checkbox"
            className={classNames(style.checkbox, {
              [style.checked]: isMultiSelected,
            })}
            checked={isMultiSelected}
            onChange={() => toggleSelected()}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
          />
        )}
      </div>
      <div className={style.table}>
        {variant.description && (
          <div className={style.row}>
            <div className={style.rowTitle}>Description</div>
            <div className={style.expand}>{variant.description}</div>
          </div>
        )}
        <div className={style.row}>
          <div className={style.rowTitle}>Used In</div>
          <div>{usedInText}</div>
        </div>
      </div>
      {showModal && <VariantDeleteModal name={variant.name} onHide={onHideModal} onDelete={onDelete} />}
    </div>
  );
};
export default VariantItem;
