import { Location } from "history";
import { getFigmaCorrelationKey } from "../../figma/lib";

/**
 * If this function returns a value, the user will be redirected
 * to the path after authentication or after onboarding if the user
 * is not fully onboarded at the time of authentication.
 *
 */
export const getRedirectUrl = (location) => {
  const figmaCorrelationKey = getFigmaCorrelationKey();
  if (figmaCorrelationKey) {
    return "/figma-authentication";
  }

  // this will be set if the user is redirected to `/login`
  // from a <PrivateRoute />
  if (location?.state?.redirectUrl) {
    return location.state.redirectUrl;
  }

  return null;
};

interface AppState {
  targetUrl?: string;
}

export const getAppState = (location: Location): AppState | undefined => {
  const appState: AppState = {};

  const redirectUrl = getRedirectUrl(location);
  if (redirectUrl) appState.targetUrl = redirectUrl;

  // don't return any appState if it doesn't contain
  // any values
  if (!Object.values(appState).some(Boolean)) {
    return undefined;
  }

  return appState;
};
