import Lock from "@mui/icons-material/Lock";
import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import style from "./style.module.css";

interface ViewOnlyBadgeProps {
  tooltipText: string;
}

const ViewOnlyBadge = (props: ViewOnlyBadgeProps) => {
  return (
    <NavDropdown
      id="view-only-badge"
      className={style.navDropdown}
      title={
        <div className={style.viewOnlyBadge} data-testid="viewer-badge">
          <Lock className={style.lockIcon} />
          <span className={style.viewOnlyText}>View Only</span>
        </div>
      }
    >
      <div className={style.viewerBody}>
        <div className={style.viewerDescription}>{props.tooltipText}</div>
        <Link to="/account/workspace">View admins for this workspace</Link>
      </div>
    </NavDropdown>
  );
};

export default ViewOnlyBadge;
