import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";

import http, { API } from "../../http";
import style from "./VariantSelectInput.module.css";

const styles = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#B7B7B8",
    };
  },
};

async function getVariants(projectId) {
  let { url } = API.variant.get.listAll;
  const { data } = await http.get(url(projectId));
  return data;
}

const useVariants = (shouldFetch, projectId) => {
  const [variants, setVariants] = useState(null);

  useEffect(() => {
    if (shouldFetch) {
      getVariants(projectId).then((variants) => setVariants(variants));
    }
  }, [shouldFetch, setVariants, projectId]);

  return variants;
};

const useOptions = (passedOptions = null, appendToPassedOptions = false, projectId = null) => {
  const shouldLoadVariants = !passedOptions || appendToPassedOptions;

  const variants = useVariants(shouldLoadVariants, projectId);

  return useMemo(() => {
    if (passedOptions && !appendToPassedOptions) {
      return passedOptions;
    }

    if (variants) {
      const loadedVariants = variants.map(({ _id, name, description }) => ({
        value: _id,
        label: name,
        description,
      }));

      if (passedOptions && appendToPassedOptions) {
        return [...passedOptions, ...loadedVariants];
      }

      return loadedVariants;
    }

    return [];
  }, [passedOptions, appendToPassedOptions, variants]);
};

const sanitizeVariantName = (name) => name.trim();
const sanitizeVariantNameForComparison = (name) => name.toLowerCase().trim();

export default function VariantSelectInput(props) {
  const {
    onSelect,
    appendToPassedOptions,
    placeholder = "Create or add an existing variant...",
    disableCreateNew = false,
    projectId,
    isClearable = true,
    dataTestId = undefined,
  } = props;

  const options = useOptions(props.options, appendToPassedOptions, projectId);

  const [_selectedVariant, setSelectedVariant] = useState(null);

  // can optionally be controlled by passing in a selectedVariant value
  // (similar to default inputs)
  const selectedVariant = props.value || _selectedVariant;

  const isValidNewOption = (value) => {
    const uniqueVariants = options.map((variant) => sanitizeVariantNameForComparison(variant.label));

    const overlapsWithExisting = uniqueVariants.includes(sanitizeVariantNameForComparison(value));

    const hasValue = value && value.length > 0;

    return hasValue && !overlapsWithExisting;
  };

  const handleChange = (e) => {
    let variant = null;

    if (e?.__isNew__) {
      variant = {
        id: "__new__",
        name: sanitizeVariantName(e.label),
      };
    } else if (e) {
      variant = {
        id: e.value,
        name: sanitizeVariantName(e.label),
      };
    }

    setSelectedVariant(variant);
    onSelect(variant);
  };

  const value = useMemo(() => {
    if (props.value !== undefined) {
      if (selectedVariant) {
        return {
          value: selectedVariant.id,
          label: selectedVariant.name,
        };
      }
      return null;
    }

    return undefined;
  }, [props.value, selectedVariant]);

  return (
    <div className={classNames([props.className])}>
      <CreatableSelect
        data-testid={dataTestId}
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={handleChange}
        onInputChange={() => {}}
        options={options}
        className={style.dropdown}
        isValidNewOption={disableCreateNew ? () => false : isValidNewOption}
        styles={styles}
        value={value}
        components={{
          Option: VariantOption,
        }}
      />
    </div>
  );
}

export function VariantOption(props) {
  const {
    data: { label, description },
    innerProps: { onClick, onMouseOver },
  } = props;

  return (
    <div onClick={onClick} onMouseOver={onMouseOver} className={style.variantOption}>
      <div className={style.name}>{label}</div>
      <span className={style.description}>{description}</span>
    </div>
  );
}
