import React from "react";
const HomeOutlineRounded = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      fill="currentColor"
      {...props}
    >
      <path d="M240-200h133.847v-201.539q0-15.365 10.394-25.759t25.76-10.394h139.998q15.366 0 25.76 10.394 10.394 10.394 10.394 25.759V-200H720v-353.846q0-3.077-1.346-5.577-1.347-2.5-3.654-4.423L487.308-735q-3.077-2.692-7.308-2.692T472.692-735L245-563.846q-2.307 1.923-3.654 4.423-1.346 2.5-1.346 5.577V-200Zm-59.999 0v-353.846q0-17.173 7.683-32.538 7.683-15.365 21.24-25.307l227.692-171.538q18.953-14.462 43.322-14.462 24.369 0 43.446 14.462l227.692 171.538q13.557 9.942 21.24 25.307 7.683 15.365 7.683 32.538V-200q0 24.538-17.731 42.268-17.73 17.731-42.268 17.731H562.308q-15.365 0-25.76-10.395-10.394-10.394-10.394-25.759v-201.539h-92.308v201.539q0 15.365-10.394 25.759-10.395 10.395-25.76 10.395H240q-24.538 0-42.268-17.731-17.731-17.73-17.731-42.268ZM480-469.231Z" />
    </svg>
  );
};

export default HomeOutlineRounded;
