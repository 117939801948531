const DAYS_IN_MS = 1000 * 60 * 60 * 24;
function getFirstWeekOrMonthMetrics(createdAt: Date) {
  const timeSinceCreation = Date.now() - createdAt.getTime();
  const inFirstWeek = timeSinceCreation <= 7 * DAYS_IN_MS;
  const inFirstMonth = timeSinceCreation <= 30 * DAYS_IN_MS;
  return { inFirstWeek, inFirstMonth };
}

export function updateUserLoginMetrics(makeRequest: (url: string) => void, createdAt: string | Date) {
  const date = new Date(createdAt);
  const { inFirstWeek, inFirstMonth } = getFirstWeekOrMonthMetrics(date);
  if (inFirstWeek || inFirstMonth) {
    makeRequest("/user/loginMetrics");
  }
}
