import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../../atoms/Icon";
import Label from "../../atoms/Label";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface ICalloutProps {
  className?: string;
  style?: React.CSSProperties;

  /**
   * The content of the callout. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content: string | React.ReactNode;

  /**
   * Optional clickable action text. Used in conjunction with onActionClick prop.
   */
  actionText?: string;

  /**
   * Handler for when the action text is clicked.
   */
  onActionClick?: () => void;

  /**
   * Style variants that affect the presentation of the callout.
   */
  variant?: "primary" | "info" | "warning" | "danger" | "template" | "secondary";

  /**
   * Header text for your callout.
   */
  header?: string;

  /**
   * Optional flag to render the callout with non-rounded bottom border.
   */
  withBorder?: boolean;

  /**
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   */
  leadingIcon?: React.ReactNode;

  iconColor?: "primary" | "secondary" | "minimal" | "positive" | "warning" | "danger" | "inherit";
  spacing?: "default" | "spacing-lg";

  /**
   * The content of the callout. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  nudgeContent?: string | React.ReactNode;
}

export function Callout({
  content,
  variant = "primary",
  spacing = "default",
  header,
  leadingIcon,
  iconColor,
  nudgeContent,
  ...props
}: ICalloutProps) {
  const [nudgeState, setNudgeState] = useState<"open" | "closed" | "unset">("unset");
  const contentRef = useRef<HTMLDivElement>(null);
  const calloutRef = useRef<HTMLDivElement>(null);

  function handleExpandToggle() {
    if (nudgeContent) {
      if (nudgeState === "open") {
        setNudgeState("closed");
      } else {
        setNudgeState("open");
      }
    }
  }

  useEffect(
    function calculateNudgeContentHeight() {
      // We record the height of the nudge content so that we can animate it in and out.
      if (nudgeContent && contentRef.current) {
        const nudgeContentHeight = contentRef.current.getBoundingClientRect().height;
        document.documentElement.style.setProperty("--nudge-content-height", `${nudgeContentHeight}px`);
      }

      // We record the height of the callout *before* the nudge gets expanded, so that we can have a clickable
      // region that's only the height of the un-expanded callout.
      if (calloutRef.current) {
        const calloutHeight = calloutRef.current.getBoundingClientRect().height;
        document.documentElement.style.setProperty("--callout-container-height", `${calloutHeight}px`);
      }
    },
    [nudgeContent]
  );

  return (
    <div
      style={props.style}
      className={classNames(
        style.CalloutWrapper,
        {
          [style[variant]]: true,
          [style.withBorder]: props.withBorder ?? false,
        },
        props.className
      )}
      data-testid="callout"
      ref={calloutRef}
    >
      <div
        className={classNames({
          [style.clickWrapper]: !!nudgeContent,
          [style.clickable]: !!nudgeContent,
        })}
        onClick={handleExpandToggle}
      />

      {leadingIcon && !header && (
        <Icon
          className={style.leadingIcon}
          Icon={leadingIcon}
          color={iconColor}
          size="xs"
          data-testid="content-leading-icon"
        />
      )}

      <div className={style.textWrapper}>
        {header && (
          <Label size="sm" className={style.header}>
            {header}
          </Label>
        )}
        {typeof content === "string" ? (
          <>
            <Text size="small" className={style.content}>
              {content}
            </Text>
            {props.actionText && (
              <Text size="small" className={style.actionText} color="action" fitContent onClick={props.onActionClick}>
                {props.actionText}
              </Text>
            )}
          </>
        ) : (
          content
        )}

        <div className={style.nudgeContentWrapper} data-state={nudgeState}>
          <div className={style.nudgeContent} ref={contentRef}>
            {typeof nudgeContent === "string" ? <Text color={variant}>{nudgeContent}</Text> : nudgeContent}
          </div>
        </div>
      </div>

      {nudgeContent && (
        <Icon
          className={classNames(style.expandIcon, { [style.open]: nudgeState === "open" })}
          Icon={<ExpandMoreIcon />}
          size="xs"
        />
      )}
    </div>
  );
}

export default Callout;
