import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { RESET } from "@shared/frontend/stores/symbols";
import { isDiffRichText } from "@shared/lib/text";
import { IFTextItemVariant, ITextItemPopulatedComments, ITipTapRichText } from "@shared/types/TextItem";
import { Atom, atom, WritableAtom } from "jotai";
import { derive, soonAll } from "jotai-derive";
import { atomWithLocation } from "jotai-location";
import { focusAtom } from "jotai-optics";
import { atomFamily, atomWithDefault } from "jotai/utils";
import { SetStateAction } from "react";
import { DragStartEvent } from "react-aria";
import { editableHasChangesAtom, inlineEditingAtom } from "./Editing";
import { discardChangesActionAtom, modalAtom } from "./Modals";
import { allTextItemIdsAtom, blockFamilyAtom, projectBlocksSplitAtom, textItemFamilyAtom } from "./Project";
import { showToastActionAtom } from "./Toast";
import { deferredVariantsAtom } from "./Variants";

export const locationAtom = atomWithLocation();

const DETAILS_PANEL_PARAM = "detailsPanel";

// MARK: - Source Atoms

// Most selection and filtering states should keep their source of truth in the URL.

export type Selection =
  | {
      type: "none";
    }
  | {
      type: "text";
      ids: string[];
    }
  | {
      type: "block";
      id: string;
    };

const TEXT_SELECTION_PARAM = "selectedTextItemIds";
const BLOCK_SELECTION_PARAM = "selectedBlockIds";

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function pageSelectionAtom(): WritableAtom<Selection, [SetStateAction<Selection>], void> {
  const textItemIdsAtom = atomWithURLStorage(TEXT_SELECTION_PARAM, locationAtom);
  // atomWithURLStorage will return an array of strings if the URL param is present, and null if it's not
  // we should probably allow it to just store a string, but for now the code in this atom takes
  // care of string <> array conversion
  const blockIdsAtom = atomWithURLStorage(BLOCK_SELECTION_PARAM, locationAtom);

  const selectionAtom = atom(
    (get) => {
      const textItemIds = get(textItemIdsAtom);
      const blockIds = get(blockIdsAtom);
      // Intentionally throwing a hard error here -- this should never happen, and if it does we should figure out why.
      if (textItemIds && blockIds) {
        throw new Error("Page selection should be mutually exclusive");
      }

      if (textItemIds) {
        return { type: "text", ids: textItemIds } as Selection;
      } else if (blockIds && blockIds.length > 0) {
        return { type: "block", id: blockIds[0] } as Selection;
      } else {
        return { type: "none" } as Selection;
      }
    },
    (get, set, newSelection: Selection) => {
      const clearSelectedTextItems = () =>
        get(textItemIdsAtom)?.forEach((id) => set(textItemIsSelectedAtom(id), false));
      const clearSelectedBlocks = () => get(blockIdsAtom)?.forEach((id) => set(blockIsSelectedAtom(id), false));

      function _handleSelectionChange() {
        if (newSelection.type === "none") {
          clearSelectedTextItems();
          clearSelectedBlocks();

          set(textItemIdsAtom, null);
          set(blockIdsAtom, null);
        } else if (newSelection.type === "text") {
          const newSelectionIds = new Set(newSelection.ids);
          get(textItemIdsAtom)?.forEach((id) => {
            if (!newSelectionIds.has(id)) {
              set(textItemIsSelectedAtom(id), false);
            }
          });
          newSelectionIds.forEach((id) => set(textItemIsSelectedAtom(id), true));
          clearSelectedBlocks();

          set(textItemIdsAtom, newSelection.ids);
          set(blockIdsAtom, null);
        } else if (newSelection.type === "block") {
          clearSelectedTextItems();
          clearSelectedBlocks();

          set(blockIsSelectedAtom(newSelection.id), true);
          set(textItemIdsAtom, null);
          set(blockIdsAtom, [newSelection.id]);
        }
      }
      // check for changes
      const hasChanges = get(editableHasChangesAtom);
      if (hasChanges) {
        set(modalAtom, {
          headline: "Discard unsaved text item changes?",
          content: "Your changes will be discarded. This can't be undone.",
          actionText: "Discard",
          onAction: () => {
            set(discardChangesActionAtom);

            _handleSelectionChange();
          },
          onOpenChange: (open) => {
            if (open) return;
            set(modalAtom, null);
          },
        });
      } else {
        _handleSelectionChange();
      }
    }
  );

  return selectionAtom;
}
export const selectedItemsAtom = pageSelectionAtom();

// MARK: - Derived Atoms

export const selectedTextItemIdsAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "text") {
    return selection.ids;
  } else {
    return [];
  }
});

// Note: A selected text item may appear in this list more than once to support multi-selection, so using Set to dedupe
export const selectedTextItemsCountAtom = derive([selectedTextItemIdsAtom], (ids) => new Set(ids).size);
export const onlySelectedTextItemIdAtom = derive([selectedTextItemIdsAtom], (ids) =>
  new Set(ids).size === 1 ? ids[0] : null
);

function isNonEmptyArray<T>(value: T[]): value is [T, ...T[]] {
  return Array.isArray(value) && value.length > 0;
}

const _selectedTextItemsAtom: Atom<ITextItemPopulatedComments[] | Promise<ITextItemPopulatedComments[]>> = atom(
  (get) => {
    const ids = get(selectedTextItemIdsAtom);
    const valuesArray = ids.map((id) => get(textItemFamilyAtom(id)));
    if (!isNonEmptyArray(valuesArray)) return [];
    const textItems = soonAll(valuesArray);
    return textItems;
  }
);

export const derivedSelectedTextItemsAtom = derive([_selectedTextItemsAtom], (items) => items);

/**
 * Derived atom that returns the mapping of variant IDs in the selection
 * to their metadata, including the variant's name.
 */
export const selectedItemsVariantsAtom = derive(
  [_selectedTextItemsAtom, deferredVariantsAtom],
  (textItems, variants) => {
    // Multiselection not currently supported for variants
    if (textItems.length !== 1) return {};

    const textItem = textItems[0];
    const variantIdsToNameMap = new Map<string, string>();
    variants?.forEach((v) => variantIdsToNameMap.set(v._id, v.name));

    const variantIdsToData: Record<string, IFTextItemVariant & { name: string }> = {};

    for (const variant of textItem.variants) {
      variantIdsToData[variant.variantId] = { ...variant, name: variantIdsToNameMap.get(variant.variantId) ?? "" };
    }

    return variantIdsToData;
  }
);

export const selectedBlockIdAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "block") {
    return selection.id;
  } else {
    return null;
  }
});

export const selectedBlockAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "block") {
    return get(blockFamilyAtom(selection.id));
  } else {
    return null;
  }
});

export const selectionTypeAtom = focusAtom(selectedItemsAtom, (optic) => optic.prop("type"));

// MARK: - Actions

/**
 * An action for selecting the text item(s) with the provided IDs, only if they are still present in the project.
 * Use this when working with things like comments or activity which may reference text items that have since been removed.
 */
export const selectTextItemsThatExistActionAtom = atom(null, (get, set, textItemIds: string[]) => {
  const existingTextItemIds = get(allTextItemIdsAtom);
  const validTextItemIds = textItemIds.filter((id) => existingTextItemIds.includes(id));

  if (validTextItemIds.length) {
    set(setSelectedTextIdsActionAtom, validTextItemIds);
  } else {
    const message = textItemIds.length === 1 ? "That text item was deleted" : "Those text items were deleted";
    set(showToastActionAtom, { message });
  }
});

/**
 * When performing a shift-click or an arrow-shift selection, we need to determine the existing selections that are not
 * contiguous with the new selections. This function takes care of that logic.
 *
 * @param selectedTextItemIds The set of selected text item IDs.
 * @param allItems All item IDs in the project.
 * @param currentIndex The clicked element index, or the 'pointer' index in case of an arrow-shift selection.
 * @param mostRecentlySelectedIndex The 'pivot' index of the shift range selection.
 * @param startIndex Start index of the shift range selection.
 * @param endIndex End index of the shift range selection.
 *
 * Note: `currentIndex` and `endIndex`, as well as `mostRecentlySelectedIndex` and `startIndex`, are designed to account for
 * both mouse-based shift-click selection and keyboard-driven arrow-shift selection. In pure shift-click, these values will
 * typically be equal, as the selection forms a contiguous range. However, if command-click is used to
 * select non-contiguous items, these parameters may differ, since `currentIndex` can point to individual selections and
 * the notion of a continuous range may not apply.
 *
 * @return The elements which are not contiguous with the new selections.
 */
function getPriorSelections(
  selectedTextItemIds: string[],
  allItems: { _id: string; sortKey: string }[],
  currentIndex: number,
  mostRecentlySelectedIndex: number,
  startIndex: number,
  endIndex: number
) {
  /**
   * Contiguous prior selections are selections in the opposite direction of the current selection that are contiguous
   * with the pivot, i.e. the most recently selected item. This is needed to effectively unselect the prior pivot-based
   * selections.
   */
  const contiguousPriorSelectionsFromStart = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? 1 : -1;

    for (let i = mostRecentlySelectedIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedTextItemIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const contiguousPriorSelectionsFromEnd = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? -1 : 1;

    for (let i = currentIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedTextItemIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const allItemsIdToIndexMap = new Map(allItems.map((item, index) => [item._id, index]));

  // If there are selections present that are not between the start and end indices, include them in the new selections,
  // unless they are contiguous with the start or end indices.
  const alreadyPresentSelections = selectedTextItemIds
    .filter((id) => {
      const index = allItemsIdToIndexMap.get(id);
      return index !== undefined && (index < startIndex || index >= endIndex);
    })
    .filter((id) => !contiguousPriorSelectionsFromStart.has(id) && !contiguousPriorSelectionsFromEnd.has(id));

  return alreadyPresentSelections;
}

/**
 * This atom is used to determine which items are draggable for a given text item.
 *
 * If the text item primarily being dragged is selected, then only that text item
 * is draggable. Otherwise, the entire set of selected text items is draggable.
 */
export const draggableItemsForTextItemAtom = atomFamily((id: string) =>
  atom((get) => {
    const selectedTextItemIds = new Set(get(selectedTextItemIdsAtom));
    const isSelected = get(textItemIsSelectedAtom(id));

    return Array.from(isSelected ? selectedTextItemIds : [id]).map((item) => ({
      "ditto/textItem": item,
      "plain/text": item,
    }));
  })
);

export const textItemIsSelectedAtom = atomFamily((id: string) =>
  atomWithDefault((get) => get(selectedTextItemIdsAtom).includes(id))
);

export const blockIsSelectedAtom = atomFamily((id: string | null) =>
  atomWithDefault((get) => get(selectedBlockIdAtom) === id)
);

export const onTextItemClickActionAtomFamily = atomFamily((textItemId: string) =>
  atom(
    null,
    async (
      get,
      set,
      {
        e,
        skipInlineEdit,
        ...textItem
      }: { richText: ITipTapRichText; e: React.MouseEvent<HTMLElement> | DragStartEvent; skipInlineEdit?: boolean }
    ) => {
      const isSelected = get(textItemIsSelectedAtom(textItemId));
      const isInlineEditing = !!get(inlineEditingAtom);
      const selectedTextItemIds = get(selectedTextItemIdsAtom);

      if ("shiftKey" in e && e.shiftKey) {
        // We need the ordered list of all text items to determine the range of elements to select.
        const allItems = get(projectBlocksSplitAtom).flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

        // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
        const mostRecentlySelectedIndex = allItems.findIndex(
          (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
        );

        // If the last selected element is not found (should never happen), select the current element.
        if (mostRecentlySelectedIndex === -1) {
          set(setSelectedTextIdsActionAtom, [textItemId]);
          set(inlineEditingAtom, null);
          return;
        }

        const currentIndex = allItems.findIndex((item) => item._id === textItemId);
        const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
        const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);

        const alreadyPresentSelections = getPriorSelections(
          selectedTextItemIds,
          allItems,
          currentIndex,
          mostRecentlySelectedIndex,
          startIndex,
          endIndex
        );

        // Create the new selections array and ensure the most recently selected element is preserved at the end.
        const newSelections = [
          ...alreadyPresentSelections,
          ...allItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
          ...allItems
            .slice(mostRecentlySelectedIndex, endIndex + 1)
            .map((item) => item._id)
            .reverse(),
        ];

        set(setSelectedTextIdsActionAtom, newSelections);
        set(inlineEditingAtom, null);
      } else if ("metaKey" in e && e.metaKey) {
        // If we press CMD+click, toggle selection of the element, without affecting other selections.
        if (isSelected) {
          set(
            setSelectedTextIdsActionAtom,
            selectedTextItemIds.filter((id) => id !== textItemId)
          );
        } else {
          // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
          set(setSelectedTextIdsActionAtom, [textItemId, ...selectedTextItemIds, textItemId]);
        }
        set(inlineEditingAtom, null);
      } else {
        // If we plain-select a selected element, turn on inline editing and remove other selections.
        if (isSelected && !isInlineEditing) {
          if (!skipInlineEdit) {
            set(inlineEditingAtom, { type: "existing", id: textItemId, richText: textItem.richText });
          }
          set(setSelectedTextIdsActionAtom, [textItemId]);
          return;
        }

        // If we plain-select a new element, remove all other selections.
        if (!isSelected) {
          set(setSelectedTextIdsActionAtom, [textItemId]);
          set(inlineEditingAtom, null);
          return;
        }
      }
    }
  )
);

export const onTextItemKeyDownActionAtom = atom(null, (get, set, event: React.KeyboardEvent<HTMLDivElement>) => {
  const inlineEditing = get(inlineEditingAtom);

  // Ignore so user can go up and down in the text editor
  if (inlineEditing !== null) {
    return;
  }

  if (event.key !== "ArrowUp" && event.key !== "ArrowDown") {
    return;
  }

  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }

  const selectedTextItemIds = get(selectedTextItemIdsAtom);
  const allItems = get(projectBlocksSplitAtom).flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

  // If no items are selected, do nothing.
  if (selectedTextItemIds.length === 0) {
    return;
  }

  // If we press a plain-arrow key, select the next/previous item in the list and discard all other selections.
  if (!event.shiftKey) {
    const currentIndex = allItems.findIndex((item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]);
    const newIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

    // If the new index is out of bounds, set the selection to only the most recently selected item.
    if (newIndex < 0 || newIndex >= allItems.length) {
      set(setSelectedTextIdsActionAtom, [selectedTextItemIds[selectedTextItemIds.length - 1]]);
      return;
    }

    set(setSelectedTextIdsActionAtom, [allItems[newIndex]._id]);
  } else {
    // If we press SHIFT+arrow-up/down, select the range of elements between the most recently selected element and the current one.
    const mostRecentlySelectedIndex = allItems.findIndex(
      (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
    );

    // Always store the "pointer" index as the first element in the selection array. The resultant array will be between the
    // new pointer index and the most recently selected index.
    const currentIndex = allItems.findIndex((item) => item._id === selectedTextItemIds[0]);

    // The new current index should be the index of the item that is currently selected, plus or minus the direction of the arrow key,
    // excluding bounds, and excluding the most recently selected index.
    const newCurrentIndex = (() => {
      const initialNewIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

      if (initialNewIndex < 0 || initialNewIndex >= allItems.length) {
        return currentIndex;
      }

      if (initialNewIndex === mostRecentlySelectedIndex) {
        const skippedIndex = event.key === "ArrowUp" ? currentIndex + 1 : currentIndex - 1;

        if (skippedIndex < 0 || skippedIndex >= allItems.length) {
          return currentIndex;
        }
      }

      return initialNewIndex;
    })();

    const startIndex = Math.min(mostRecentlySelectedIndex, newCurrentIndex);
    const endIndex = Math.max(mostRecentlySelectedIndex, newCurrentIndex);

    const alreadyPresentSelections = getPriorSelections(
      selectedTextItemIds,
      allItems,
      currentIndex,
      mostRecentlySelectedIndex,
      startIndex,
      endIndex
    );

    const newPointerId = allItems[newCurrentIndex]._id;

    // Create the new selections array and ensure the most recently selected element is preserved at the end.
    set(setSelectedTextIdsActionAtom, [
      newPointerId,
      ...alreadyPresentSelections,
      ...allItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
      ...allItems
        .slice(mostRecentlySelectedIndex, endIndex + 1)
        .map((item) => item._id)
        .reverse(),
    ]);
  }
});

export const onTextItemTextChangeActionAtom = atom(
  null,
  (get, set, textItem: { id: string; richText: ITipTapRichText }) => {
    const inlineEditingState = get(inlineEditingAtom);
    if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItem.id) {
      throw new Error("Invalid state: cannot update text for a text item that is not being edited");
    }

    set(inlineEditingAtom, { type: "existing", id: textItem.id, richText: textItem.richText });
  }
);

export const onTextItemCancelEditActionAtom = atom(null, async (get, set, textItemId: string) => {
  const inlineEditingState = get(inlineEditingAtom);
  if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItemId) {
    throw new Error("Invalid state: cannot cancel edit for a text item that is not being edited");
  }
  const textItem = await get(textItemFamilyAtom(textItemId));
  if (isDiffRichText(inlineEditingState.richText, textItem.rich_text)) {
    set(inlineEditingAtom, null);
  } else {
    set(inlineEditingAtom, RESET);
  }
});

export const clearSelectionActionAtom = atom(null, (_, set) => {
  set(selectedItemsAtom, { type: "none" });
  set(inlineEditingAtom, null);
});

export const setSelectedTextIdsActionAtom = atom(null, (_, set, textItemIds: string[]) => {
  set(selectedItemsAtom, { type: "text", ids: textItemIds });

  // Clear variants panel props state when we switch between text items
  set(detailsPanelPropsAtom, (props) => ({ ...props, variants: undefined }));
});

export const setSelectedBlockIdsActionAtom = atom(null, (_, set, blockId: string) => {
  set(selectedItemsAtom, { type: "block", id: blockId });
});

export const onClickBlockActionAtom = atom(null, (_, set, blockId: string) => {
  set(inlineEditingAtom, null);
  set(selectedItemsAtom, { type: "block", id: blockId });
});

// MARK: - Details Panel

const DetailsPanelContexts = ["PROJECT", "EDIT"] as const;
export type DetailsPanelContext = (typeof DetailsPanelContexts)[number];

export const detailsPanelContextAtom = atom<DetailsPanelContext>((get) => {
  const block = get(selectedBlockIdAtom);
  const textItems = get(selectedTextItemIdsAtom);

  if (textItems.length > 0) {
    return "EDIT";
  }

  if (block) {
    return "EDIT";
  }

  return "PROJECT";
});

const DetailsPanelEditStates = ["EDIT", "ACTIVITY", "VARIANTS"] as const;
export type DetailsPanelEditStates = (typeof DetailsPanelEditStates)[number];

type DetailsPanelProps = {
  variants?: {
    showAddForm?: boolean;
    defaultVariant?: {
      id: string;
      name: string;
    };
  };
};

// Atom to store props to pass to the current details panel
export const detailsPanelPropsAtom = atom<DetailsPanelProps>({});

export const detailsPanelEditStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelEditStates;

    if (state === null || !DetailsPanelEditStates.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelEditStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);

      // Clear panel props state for variants when we're navigating between tabs normally
      set(detailsPanelPropsAtom, (props) => ({ ...props, variants: undefined }));
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

const DetailsPanelProjectStates = ["ACTIVITY", "COMMENTS"] as const;
export type DetailsPanelProjectStates = (typeof DetailsPanelProjectStates)[number];

export const detailsPanelProjectStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelProjectStates;

    if (state === null || !DetailsPanelProjectStates.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelProjectStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);
