import { CreateableComponent } from "@shared/types/Component";
import z from "zod";
import { ZActualComponentStatus } from "../TextItem";
import { ZFormat } from "../imports";

const ZComponentImportPreviewData = z.object({
  components: z.array(CreateableComponent),
  totalComponentsCount: z.number(),
  existingComponentsCount: z.number(),
  format: ZFormat,
  backgroundJobEntryId: z.string(),
});

export type IComponentImportPreviewData = z.infer<typeof ZComponentImportPreviewData>;

export type IComponentImportPreviewSuccess = {
  ok: true;
  data: IComponentImportPreviewData;
};

export type IComponentImportPreviewError = {
  ok: false;
  error: string;
  code?: string;
  data?: any;
};

export type IComponentImportPreviewResponse = IComponentImportPreviewSuccess | IComponentImportPreviewError;

export const ZImportAction = z.enum(["CREATE", "VARIANT", "UPDATE"]);
export type ImportAction = z.infer<typeof ZImportAction>;
export const isValidImportAction = (action: string): action is ImportAction => {
  try {
    ZImportAction.parse(action);
    return true;
  } catch {
    return false;
  }
};

export const ZImportConfirmBody = z.object({
  variant: z.object({ id: z.string(), name: z.string() }).nullable().optional(),
  backgroundJobEntryId: z.string(),
  action: ZImportAction,
});

export type ImportConfirmBody = z.infer<typeof ZImportConfirmBody>;

type ImportConfirmSuccess = { ok: true; id: string };
type ImportConfirmError = { ok: false; error: string; code?: string };
export type ImportConfirmResponse = ImportConfirmSuccess | ImportConfirmError;

export const ZUpdateTextItemsBody = z.object({
  textItemIds: z.array(z.string()),
  updates: z.object({
    assignee: z.string().nullable().optional(),
    tags: z
      .object({
        tagsAdded: z.array(z.string()),
        tagsDeleted: z.array(z.string()),
      })
      .optional(),
    status: ZActualComponentStatus.optional(),
    characterLimit: z.number().nullable().optional(),
  }),
  projectId: z.string(),
});

export type IUpdateTextItemsBody = z.infer<typeof ZUpdateTextItemsBody>;

export const ZUpdateTextItemsResponse = z.object({});
export type IUpdateTextItemsResponse = z.infer<typeof ZUpdateTextItemsResponse>;

export const ZCreateTestComponentsArgs = z.object({
  count: z.number(),
  workspaceId: z.string(),
  projectId: z.string().optional(),
  groupsCount: z.number().optional(),
  blocksCount: z.number().optional(),
  distributeBetweenFolders: z.number().optional(),
});

export type ICreateTestComponentsArgs = z.infer<typeof ZCreateTestComponentsArgs>;

export interface IComponentTreeItem {
  id: string;
  name: string;
  type: "component";
  selected: boolean;
}

export interface IBlockTreeItem {
  id: string;
  name: string;
  type: "block";
  children: IFTreeItem[];
}

export interface IGroupTreeItem {
  id: string;
  name: string;
  type: "group";
  children: IFTreeItem[];
}

export interface ILoadingTreeItem {
  id: string;
  name: string;
  type: "loading";
}

export interface IToBeLoadedTreeItem {
  id: string;
  name: string;
  type: "to-be-loaded";
}

export type IFTreeItem = ILoadingTreeItem | IGroupTreeItem | IBlockTreeItem | IComponentTreeItem | IToBeLoadedTreeItem;

// GET /ws_comp/libraryRoot
export const ZGetComponentLibraryRootQuery = z.object({
  folderId: z.string().optional(),
});
export type IGetComponentLibraryRootQuery = z.infer<typeof ZGetComponentLibraryRootQuery>;

// GET /ws_comp/group/:groupName
export const ZGetComponentGroupQuery = z.object({
  folderId: z.string().optional(),
});
export type IGetComponentGroupQuery = z.infer<typeof ZGetComponentGroupQuery>;

export const ZGetComponentGroupParams = z.object({
  groupName: z.string(),
});
export type IGetComponentGroupParams = z.infer<typeof ZGetComponentGroupParams>;

// GET /ws_comp/group/:groupName/block/:blockName
export const ZGetComponentBlockQuery = z.object({
  folderId: z.string().optional(),
});
export type IGetComponentBlockQuery = z.infer<typeof ZGetComponentBlockQuery>;

export const ZGetComponentBlockParams = z.object({
  groupName: z.string(),
  blockName: z.string(),
});
export type IGetComponentBlockParams = z.infer<typeof ZGetComponentBlockParams>;
