import classnames from "classnames";
import React from "react";

import ExportModalLabel from "./ExportModalLabel";
import style from "./style.module.css";

export default function JsonSection(props) {
  const { title, developerModeEnabled, tooltip, tooltipId, tooltipOnClick, children } = props;

  return (
    <div className={style.section}>
      <ExportModalLabel
        title={title}
        tooltip={{
          id: tooltipId,
          text: tooltip,
          onClick: tooltipOnClick,
        }}
      />
      <div
        className={classnames({
          [style.jsonFormatSection]: !developerModeEnabled,
        })}
      >
        {children}
      </div>
    </div>
  );
}
