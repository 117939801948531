import TextItemTextInputs from "@/components/TextItemTextInputs/TextItemTextInputs";
import { updateTextItems } from "@/http/dittoProject";
import { editableHasChangesAtom } from "@/stores/Editing";
import { projectIdAtom, updateTextItemsActionAtom } from "@/stores/Project";
import {
  derivedSelectedTextItemsAtom,
  detailsPanelEditStateAtom,
  detailsPanelPropsAtom,
  selectedTextItemIdsAtom,
  selectedTextItemsCountAtom,
} from "@/stores/ProjectSelection";
import {
  blockSelectedVariantIdFamilyAtom,
  updateTextItemVariantActionAtom,
  variantNameFamilyAtom,
} from "@/stores/Variants";
import { createNewTagActionAtom, unwrappedAllTagsAtom, usersByIdAtom } from "@/stores/Workspace";
import { TextItemMetaData } from "@ds/organisms/TextItemMetadata";
import { isDiffRichText } from "@shared/lib/text";
import { ZTextItemsUpdate } from "@shared/types/DittoProject";
import { RichTextInputProps } from "@shared/types/RichText";
import {
  IFTextItem,
  ITextItemPopulatedComments,
  ITextItemStatus,
  ITextItemVariant,
  ITextItemVariantUpdate,
  ITipTapRichText,
  ZTextItemPluralType,
} from "@shared/types/TextItem";
import { IFUser } from "@shared/types/User";
import { BASE_VARIANT_ID } from "@shared/types/Variant";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { z } from "zod";
import style from "./style.module.css";

interface SkinnyPlural {
  rich_text: ITipTapRichText;
  form: z.infer<typeof ZTextItemPluralType>;
}

/**
 * If all statuses are the same, return that status. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged status.
 */
function getMergedStatus(textItems: ITextItemPopulatedComments[]): ITextItemStatus | "MIXED" {
  const statuses = textItems.map((textItem) => textItem.status);

  let status: ITextItemStatus | "MIXED" = "MIXED";

  if (statuses.every((status) => status === statuses[0])) {
    status = statuses[0];
  }

  return status;
}

/**
 * If all assignees are the same, return that assignee. Otherwise, return "MIXED".
 *
 * @param textItems The text items to check.
 * @returns The merged assignee.
 */
function getMergedAssignee(textItems: ITextItemPopulatedComments[], usersById: Record<string, IFUser>) {
  const assignees = textItems.map((textItem) => textItem.assignee);

  if (assignees.every((assignee) => assignee === assignees[0])) {
    // If the common assignee does not exist in the workspace, return null.
    if (!assignees[0] || !usersById[assignees[0]]) return null;

    return { value: assignees[0], label: usersById[assignees[0]].name };
  }

  return { value: "MIXED", label: "Mixed Assignees" };
}

/**
 * Return only the tags common to all text items. Tags are case insensitive.
 *
 * @param textItems The text items to check.
 * @returns The merged tags.
 */
function getMergedTags(textItems: ITextItemPopulatedComments[]) {
  const tags = textItems.map((textItem) => textItem.tags);

  const intersect = (a: string[], b: string[]) =>
    a.filter((x) => b.map((y) => y.toLowerCase()).includes(x.toLowerCase()));

  const mergedTags = tags.reduce((acc, curr) => intersect(acc, curr));

  return mergedTags;
}

/**
 * Returns a list of tags to write and list of tags to delete based on diff between original and current tags.
 *
 * @param baseTags The original base tags in selection (intersection of tags in multiselect).
 * @param tags The current tags in state.
 * @returns The lists of tags to write and delete.
 */
function getTagsChanges(baseTags: string[], tags: string[]) {
  const baseTagsSet = new Set(baseTags);
  const tagsSet = new Set(tags);

  // write tags that are new, not in base set
  const tagsToWrite = tags.reduce((tagsToWrite, tag) => {
    if (!baseTagsSet.has(tag)) tagsToWrite.push(tag);
    return tagsToWrite;
  }, [] as string[]);

  // delete tags that are in base set and not in current tags set
  const tagsToDelete = baseTags.reduce((tagsToDelete, baseTag) => {
    if (!tagsSet.has(baseTag)) tagsToDelete.push(baseTag);
    return tagsToDelete;
  }, [] as string[]);

  return {
    tagsToWrite,
    tagsToDelete,
  };
}

/**
 * If all texts are the same, return that text. Otherwise, return null.
 */
function getMergedText(textItems: ITextItemPopulatedComments[]) {
  const baseTexts = textItems.map((textItem) => textItem.rich_text);

  if (baseTexts.every((text) => text === baseTexts[0])) {
    return baseTexts[0];
  }

  return null;
}

/**
 * Only display the text field if all text items have the same text, and all
 * selected text items do not have plural forms.
 */
function shouldDisplayTextField(textItems: ITextItemPopulatedComments[]) {
  const hasPlurals = textItems.some((textItem) => textItem.plurals?.length > 0);
  const hasRichTextDiff = textItems.some((textItem) => isDiffRichText(textItem.rich_text, textItems[0].rich_text));

  return textItems.length === 1 || (!hasPlurals && !hasRichTextDiff);
}

/**
 * If there is only one text item, return its notes. Otherwise, return null.
 */
function getMergedNotes(textItems: ITextItemPopulatedComments[]) {
  return textItems.length === 1 ? textItems[0].notes ?? "" : null;
}

/**
 * The merged character limit is the minimum of all character limits.
 */
function getMergedCharacterLimit(textItems: ITextItemPopulatedComments[]) {
  const characterLimits = textItems.map((textItem) => textItem.characterLimit ?? null);

  if (characterLimits.every((value) => value === characterLimits[0])) {
    return characterLimits[0];
  }

  return "MIXED";
}

/**
 * The merged plurals only exist if only one item is selected and it has plurals.
 */
function getMergedPlurals(textItems: ITextItemPopulatedComments[]) {
  return textItems.length === 1 ? textItems[0].plurals : null;
}

export type CharacterLimit = number | "MIXED" | null;

function MetadataPanel() {
  const projectId = useAtomValue(projectIdAtom);
  const usersById = useAtomValue(usersByIdAtom);
  const allTags = useAtomValue(unwrappedAllTagsAtom);
  const onCreateNewTag = useSetAtom(createNewTagActionAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const updateTextItemsAction = useSetAtom(updateTextItemsActionAtom);
  const selectedTextItemsCount = useAtomValue(selectedTextItemsCountAtom);
  const [selectedTextItems] = useAtom(derivedSelectedTextItemsAtom);
  const [editableHasChanges, setEditableHasChanges] = useAtom(editableHasChangesAtom);

  // Variant atoms
  const activeVariantId = useAtomValue(blockSelectedVariantIdFamilyAtom(selectedTextItems[0]?.blockId ?? null));
  const activeVariantName = useAtomValue(variantNameFamilyAtom(activeVariantId));
  const setUpdateTextItemVariantAction = useSetAtom(updateTextItemVariantActionAtom);

  // Details panel atom state
  const setDetailsPanelEditState = useSetAtom(detailsPanelEditStateAtom);
  const setDetailsPanelProps = useSetAtom(detailsPanelPropsAtom);

  const [baseText, setBaseText] = useState(getMergedText(selectedTextItems));
  const [status, setStatus] = useState(getMergedStatus(selectedTextItems));
  const [assignee, setAssignee] = useState(getMergedAssignee(selectedTextItems, usersById));
  const [tags, setTags] = useState(getMergedTags(selectedTextItems));
  const [notes, setNotes] = useState(getMergedNotes(selectedTextItems));
  const [characterLimit, setCharacterLimit] = useState<CharacterLimit>(getMergedCharacterLimit(selectedTextItems));

  const memoizedSelectedTextItem = useMemo(() => selectedTextItems[0], [selectedTextItems]);
  const setBaseTextCallback = useCallback((richText: ITipTapRichText) => setBaseText(richText), [setBaseText]);

  function resetState() {
    setBaseText(getMergedText(selectedTextItems));
    setStatus(getMergedStatus(selectedTextItems));
    setAssignee(getMergedAssignee(selectedTextItems, usersById));
    setTags(getMergedTags(selectedTextItems));
    setNotes(getMergedNotes(selectedTextItems));
    setCharacterLimit(getMergedCharacterLimit(selectedTextItems));
  }

  useLayoutEffect(resetState, [selectedTextItemIds, selectedTextItems, usersById]);

  const [plurals, setPlurals] = useState<SkinnyPlural[] | null>(getMergedPlurals(selectedTextItems));

  // TextItemMetaData takes a lot of props, so make sure we are memoizing everything we can!
  const setAssigneeProp = useCallback(
    (value: { id: string; name: string }) => setAssignee(value ? { value: value.id, label: value.name } : null),
    [setAssignee]
  );
  const usersProp = useMemo(() => usersById, [usersById]);
  const assigneeProp = useMemo(() => (assignee ? { id: assignee.value, name: assignee.label } : null), [assignee]);
  const allTagsProp = useMemo(() => allTags.map((tag) => ({ value: tag._id, label: tag._id })), [allTags]);
  const tagsProp = useMemo(() => tags.map((tag) => ({ value: tag, label: tag })), [tags]);

  // Show variant metadata only if there is a single selection and the active variant on the block is not the base variant
  // (If the text item is non-block, its active variant id is always "BASE_VARIANT_ID")
  const showVariantMetadata = useMemo(
    () => selectedTextItemsCount === 1 && activeVariantId !== BASE_VARIANT_ID,
    [selectedTextItemsCount, activeVariantId]
  );
  const activeVariantProp = useMemo(
    () => ({ id: activeVariantId, name: activeVariantName ?? "" }),
    [activeVariantId, activeVariantName]
  );
  const textItemVariantProp: (ITextItemVariant & { name: string; textItemId: string }) | undefined = useMemo(() => {
    if (selectedTextItems.length !== 1) return undefined;
    // Find the variant on the text item that matches the active variant on the block
    const variant = selectedTextItems[0].variants.find((v) => v.variantId === activeVariantId);
    return variant ? { ...variant, name: activeVariantName ?? "", textItemId: selectedTextItems[0]._id } : undefined;
  }, [selectedTextItems, activeVariantId, activeVariantName]);

  const calculateDiffFromState = useCallback(
    (textItem: ITextItemPopulatedComments) => {
      const mergedTags = getMergedTags(selectedTextItems);
      const tagsChanged =
        JSON.stringify(mergedTags.map((tag) => tag.toLowerCase())) !==
        JSON.stringify(tags.map((tag) => tag.toLowerCase()));
      return {
        baseTextChanged: Boolean(baseText) && isDiffRichText(baseText, textItem.rich_text),
        statusChanged: status !== "MIXED" && status !== textItem.status,
        assigneeChanged: assignee?.value !== "MIXED" && (assignee?.value || null) !== textItem.assignee,
        tagsChanged,
        notesChanged: notes !== null && notes !== (textItem.notes ?? ""),
        characterLimitChanged: characterLimit !== (textItem.characterLimit ?? null),
        pluralsChanged:
          plurals !== null &&
          JSON.stringify(plurals) !==
            JSON.stringify(
              textItem.plurals?.map((p) => ({
                rich_text: p.rich_text,
                form: p.form,
              })) || []
            ),
      };
    },
    [selectedTextItems, tags, status, baseText, assignee?.value, notes, characterLimit, plurals]
  );

  const richTextInput = useCallback(({ props }: { props: RichTextInputProps }) => <RichTextInput {...props} />, []);

  // Check if changes have been made.
  useEffect(
    function checkForChanges() {
      const anyTextItemChanged = Object.values(selectedTextItems).some((textItem) =>
        Object.values(calculateDiffFromState(textItem)).some((value) => value)
      );

      setEditableHasChanges(anyTextItemChanged);
    },
    [selectedTextItems, calculateDiffFromState, setEditableHasChanges]
  );

  if (!projectId) {
    return <div className={style.wrapper}>No project selected</div>;
  }

  const onCancel = resetState;

  const onSave = async () => {
    // If any of the selected text items have been changed, that attribute will be set to true.
    const changes = Object.values(selectedTextItems)
      .map((textItem) => calculateDiffFromState(textItem))
      .reduce((acc, change) => {
        Object.keys(change).forEach((key) => {
          acc[key] = acc[key] || change[key];
        });
        return acc;
      }, {} as ReturnType<typeof calculateDiffFromState>);

    const [request] = updateTextItems({
      projectId,
      updates: selectedTextItems.map((textItem) => {
        const { tagsToWrite, tagsToDelete } = getTagsChanges(getMergedTags(selectedTextItems), tags);

        const update: z.infer<typeof ZTextItemsUpdate> = {
          textItemIds: [textItem._id],
          ...(changes.baseTextChanged ? { richText: baseText! } : {}),
          ...(changes.statusChanged ? { status: status as ITextItemStatus } : {}),
          ...(changes.assigneeChanged ? { assignee: assignee?.value } : {}),
          ...(changes.tagsChanged && tagsToWrite.length ? { tags: tagsToWrite } : {}),
          ...(changes.tagsChanged && tagsToDelete.length ? { tagsToDelete } : {}),
          ...(changes.notesChanged ? { notes: notes! } : {}),
          ...(changes.characterLimitChanged && characterLimit !== "MIXED"
            ? { characterLimit: characterLimit as number }
            : {}),
          ...(changes.pluralsChanged ? { plurals } : {}),
        };

        return update;
      }),
    });

    const result = await request;
    const updatedTextItems = selectedTextItems.map((textItem) => result.data[textItem._id.toString()]);

    // Update text item to changed values
    updateTextItemsAction(updatedTextItems);
  };

  function onSetTags(newTags: { value: string; label: string }[]) {
    setTags(newTags.map((tag) => tag.value));
  }

  // Update handler for text item's variant in Edit panel
  function onUpdateVariant(update: ITextItemVariantUpdate) {
    if (selectedTextItemsCount !== 1) return;
    setUpdateTextItemVariantAction({ textItemId: selectedTextItems[0]._id, update });
  }

  // When the user clicks "Add variant" in the Edit panel, we switch the to the Variants tab with the addVariantsForm open
  // and the selected option as the active variant
  function onAddVariant() {
    setDetailsPanelEditState("VARIANTS");
    setDetailsPanelProps({
      variants: {
        showAddForm: true,
        defaultVariant: {
          id: activeVariantId,
          name: activeVariantName ?? "",
        },
      },
    });
  }

  return (
    <div className={style.wrapper}>
      <TextItemMetaData
        status={status}
        setStatus={setStatus}
        users={usersProp}
        assignee={assigneeProp}
        setAssignee={setAssigneeProp}
        allTags={allTagsProp}
        tags={tagsProp}
        setTags={onSetTags}
        onCreateNewTag={onCreateNewTag}
        notes={notes}
        setNotes={setNotes}
        onCancel={onCancel}
        onSave={onSave}
        onInsertVariable={() => {}}
        showCTAButtons={editableHasChanges}
        displayTextField={shouldDisplayTextField(selectedTextItems)}
        richTextInput={
          <RichTextInput
            initialVariableRichValue={memoizedSelectedTextItem}
            setBaseText={setBaseTextCallback}
            setPlurals={setPlurals}
            setCharacterLimit={setCharacterLimit}
            characterLimit={characterLimit}
            // Disable text item rich text inputs (including plurals, variables, character limit) if variant is selected
            richTextInputDisabled={showVariantMetadata}
            pluralsDisabled={showVariantMetadata}
            variablesDisabled={showVariantMetadata}
            characterLimitDisabled={showVariantMetadata}
          />
        }
        RichTextInputWithProps={richTextInput}
        showVariantMetadata={showVariantMetadata}
        activeVariant={activeVariantProp}
        textItemVariant={textItemVariantProp}
        onUpdateVariant={onUpdateVariant}
        onAddVariant={onAddVariant}
        variantPlaceholderText={selectedTextItems[0].text}
      />
    </div>
  );
}

/**
 * Wrapper for our legacy TextItemTextInput component that wraps TipTap's rich text editor.
 * @param props Wrapper props:
 * - initialVariableRichValue: The value to pass down to the legacy TextItemTextInput component.
 *
 * @important
 * The initialVariableRichValue prop gets passed down to our legacy TextItemTextInput component, and passes down many layers of prop drilling
 * before being used to initialize the TipTap editor. It's important that the value here does *not* change on edit --
 * it's used for initializing editor state, and thus should only change when we're re-initializing the selected page
 * (e.g. changing our selection).
 */
export const RichTextInput = memo(function RichTextInput(props: RichTextInputProps) {
  const { initialVariableRichValue, setBaseText, setPlurals, setCharacterLimit, characterLimit } = props;

  const handleCharacterLimitChange = useCallback(
    (newCharacterLimit: number | null) => {
      setCharacterLimit(newCharacterLimit);
    },
    [setCharacterLimit]
  );

  const legacyHandleTextChange = useCallback(
    function _legacyHandleTextChange(
      fieldStates: {
        label: string | undefined;
        form: string | undefined;
        value: { text: string; richText: ITipTapRichText; variables: IFTextItem["variables"] };
      }[]
    ): void {
      const base = fieldStates.find((fieldState) => fieldState.form === undefined);
      const nonBases = fieldStates.filter((fieldState) => fieldState.form !== undefined);

      if (nonBases.length === 0 && base) {
        setBaseText(base.value.richText);
      } else if (nonBases[0]) {
        setBaseText(nonBases[0].value.richText);
      }

      setPlurals(
        nonBases.map((fieldState) => ({
          rich_text: fieldState.value.richText,
          form: fieldState.form as z.infer<typeof ZTextItemPluralType>,
        }))
      );
    },
    [setBaseText, setPlurals]
  );
  const textLabelLeft = useMemo(() => <div></div>, []);

  return (
    <TextItemTextInputs
      overrideClassname={style.richTextInput}
      hideTopLabels={true}
      useNSLabels
      textItem={initialVariableRichValue}
      legacyHandleTextChange={legacyHandleTextChange}
      readonly={false}
      isBaseText={true}
      isVariant={false}
      shouldShowRichText={true}
      handleCharacterLimitChange={handleCharacterLimitChange}
      characterLimit={characterLimit}
      textLabelLeft={textLabelLeft}
      disabled={props.richTextInputDisabled}
      pluralInputsDisabled={props.pluralsDisabled}
      variablesDisabled={props.variablesDisabled}
      characterLimitDisabled={props.characterLimitDisabled}
      placeholder={props.placeholder}
      emptyEditorClass={props.emptyEditorClass}
    />
  );
});

export default MetadataPanel;
