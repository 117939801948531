import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import ButtonSecondary from "../../button/buttonsecondary";
import InviteUsersInput from "../InviteUsersInput";
import style from "./style.module.css";
import { InviteFormProps, useInviteForm } from "./useInviteForm";

const InviteForm = (props: InviteFormProps) => {
  const { title, currentUser, invitedEmails } = props;

  const {
    isSaving,
    canSave,
    invitedUserList,
    userList,
    errorMsg,
    successMsg,
    showExternalInviteMsg,
    onUserInvited,
    onUserRemoved,
    onSendInvite,
  } = useInviteForm(props);
  return (
    <div className={style.container}>
      {title && <div className={style.title}>{title}</div>}
      <div className={style.form}>
        <div className={style.inputWrapper}>
          <InviteUsersInput
            disabled={isSaving}
            currentUserRole={currentUser.role}
            invitedUserList={invitedUserList}
            existingInvitedEmails={invitedEmails}
            userList={userList}
            onUserInvited={onUserInvited}
            onUserRemoved={onUserRemoved}
          />
          {showExternalInviteMsg && (
            <div className={style.diffEmailDomainMsg}>
              Heads up! New members invited to this folder will join your workspace.
            </div>
          )}
          {successMsg && (
            <div className={style.successMsg}>
              <CheckIcon className={style.checkIcon} />
              {successMsg}
            </div>
          )}
          {errorMsg && (
            <div className={style.errorMsg}>
              <ErrorIcon className={style.errorIcon} />
              {errorMsg}
            </div>
          )}
        </div>

        <ButtonSecondary
          onClick={onSendInvite}
          disabled={!canSave}
          text={`Send Invite${invitedUserList.length > 1 ? "s" : ""}`}
        />
      </div>
    </div>
  );
};

export default InviteForm;
