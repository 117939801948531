import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import classnames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import style from "./style.module.css";

const LabelLeft = ({
  children,
  index,
  numPlurals,
  form,
  showLabel,
  isDisabled,
  pluralsEnabled,
  pluralsDropdownDisabled,
  options,
  onEnablePlurals,
  onSelectPluralForm,
  onDisablePlurals,
  textLabelLeft,
}) => (
  <div className={style.labelLeft}>
    {showLabel && (
      <div className={style.flexContainer}>
        {textLabelLeft && <>{textLabelLeft}</>}
        {!textLabelLeft && (
          <>
            <h4 className={style.headerLabel}>{children}</h4>
            {!pluralsEnabled && !isDisabled && (
              <div className={style.enablePluralBtn} onMouseDown={onEnablePlurals}>
                + Plurals
              </div>
            )}
          </>
        )}
      </div>
    )}
    {pluralsEnabled && (
      <div className={style.flexContainer} style={{ marginBottom: "3px" }}>
        <span className={style.dropdownLabel}>Plural:</span>
        <Dropdown className={style.dropdownContainer}>
          <Dropdown.Toggle
            disabled={isDisabled || options.length === 0 || pluralsDropdownDisabled}
            id={index === 0 ? "first-plural-dropdown" : undefined}
          >
            <div
              className={classnames(style.pluralDropdownText, {
                [style.noHoverEffect]: numPlurals === 6 || isDisabled,
              })}
            >
              {form}
              {!isDisabled && options.length > 0 && <KeyboardArrowDownIcon className={style.icon} />}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {options.map((option) => (
              <Dropdown.Item key={option.label} onClick={() => onSelectPluralForm(option, index)}>
                {option.label}
              </Dropdown.Item>
            ))}

            {index === 0 && numPlurals === 1 && (
              <>
                <Dropdown.Item onClick={onDisablePlurals} className={style.removePluralItem}>
                  Remove pluralization
                </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )}
  </div>
);

const LabelRight = ({ children, onRemovePlural, index, isDisabled, numPlurals }) => (
  <div className={style.labelRight}>
    {/* only show close button if variable button is not being shown and not first input */}
    {!isDisabled && (
      <>{!children[0] && index !== 0 && <CloseIcon className={style.close} onClick={() => onRemovePlural(index)} />}</>
    )}
    {!isDisabled && (
      <div
        className={classnames([style.flexWrapper], {
          [style.hide]: numPlurals === 1 && !children[0] && index === 0,
        })}
      >
        {children}
      </div>
    )}
  </div>
);
const labelComponents = (props) => {
  return {
    label: ({ children }) => <LabelLeft {...props}>{children}</LabelLeft>,
    labelRight: ({ children }) => <LabelRight {...props}>{children}</LabelRight>,
  };
};
export { labelComponents };
