import classNames from "classnames";
import React, { useMemo } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";

import Close from "@mui/icons-material/Close";
import style from "./confirmation-modal.module.css";

interface ConfirmationModalProps {
  isLayered?: boolean;
  title: string | JSX.Element;
  body?: string | JSX.Element;
  actionPrimary: string;
  actionSecondary?: string;
  onPrimary: () => void;
  onSecondary?: () => void;
  onHide?: () => void;
  primaryDisabled?: boolean;
  primaryLoading?: boolean;
  error?: string | null;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    isLayered,
    title,
    body,
    actionPrimary,
    actionSecondary,
    onPrimary,
    onSecondary,
    onHide,
    primaryDisabled = false,
    primaryLoading = false,
    error,
  } = props;

  const content = useMemo(() => {
    if (!body) {
      return null;
    }

    if (["string", "number"].includes(typeof body)) {
      return <p>{body}</p>;
    }

    return <>{body}</>;
  }, [body]);

  return (
    <div>
      <BootstrapModal
        show={true}
        className={classNames(style.modal, {
          [style.modalLayered]: isLayered,
        })}
        dialogClassName={style.dialog}
        backdropClassName={classNames(style.backdrop, {
          [style.backdropLayered]: isLayered,
        })}
        onHide={onHide || (() => {})}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>{title}</BootstrapModal.Title>
          {onHide && <Close className={style.close} onClick={onHide} />}
        </BootstrapModal.Header>
        {content && (
          <BootstrapModal.Body className={style.body}>
            {content}
            {error && <span className={style.error}>{error}</span>}
          </BootstrapModal.Body>
        )}
        <BootstrapModal.Footer className={style.footer} style={{ paddingTop: body ? 0 : 20 }}>
          {actionSecondary && onSecondary && <ButtonSecondary text={actionSecondary} onClick={onSecondary} />}
          <ButtonPrimary
            data-testid="confirm"
            text={actionPrimary}
            onClick={onPrimary}
            disabled={primaryDisabled || primaryLoading}
            loading={primaryLoading}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default ConfirmationModal;
