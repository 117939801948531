import slackConnectionImage from "@/assets/slack_connection.png";
import { revokeSlackAccess } from "@/http/slack";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from "@mui/icons-material/Flag";
import ForumIcon from "@mui/icons-material/ForumOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import BootstrapModal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Toggle from "react-toggle";
import { SlackNotificationTypes } from "../../../shared/types/SlackNotifications";
import slackLogo from "../../assets/slack.png";
import ButtonPrimary from "../button/buttonprimary";
import ButtonSecondary from "../button/buttonsecondary";
import style from "./style.module.css";

export interface SlackChannelModalProps {
  connectedChannelInfo: {
    channel_id: string | null;
    channel_name: string | null;
    notif_types: SlackNotificationTypes;
  };
  isProject: boolean;
  doc_id?: string;
  workspaceSlackAuthenticated: boolean | null;
  onSaveNotifType: (type: keyof SlackNotificationTypes, value: boolean) => void | Promise<void>;
  onHide: () => void;
  isSampleProject?: boolean;
}

enum MODAL_STATE {
  CONNECT_TO_SLACK,
  CONNECT_TO_CHANNEL,
  CHANNEL_CONNECTED,
}

type ToggleLoadingStatus = "loading" | "success" | null;

type ToggleLoadingMap = Map<keyof SlackNotificationTypes, ToggleLoadingStatus>;

const toggleText = (toggle: keyof SlackNotificationTypes, isProject: boolean) => {
  switch (toggle) {
    case "textEdited":
      return isProject ? "Text Edited" : "Component Text Edited";
    case "textAssigned":
      return isProject ? "Text Assigned" : "Component Assigned";
    case "statusChanged":
      return "Status Changed";
    case "commentActivity":
      return "Comment Activity";
    case "componentAttached":
      return "Component Attached";
    case "draftItems":
      return "Draft Items Created/Deleted";
  }
};

const toggleIcon = (toggle: keyof SlackNotificationTypes) => {
  switch (toggle) {
    case "textEdited":
      return <EditIcon className={style.icon} />;
    case "textAssigned":
      return <PersonIcon className={style.icon} />;
    case "statusChanged":
      return <FlagIcon className={style.icon} />;
    case "commentActivity":
      return <ForumIcon className={style.icon} />;
    case "componentAttached":
      return <LinkIcon className={style.icon} />;
    case "draftItems":
      return <DescriptionIcon className={style.icon} />;
  }
};

// This is the Client ID for our Slack app, found on the Basic Information page
const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID;

const SlackChannelModal = (props: SlackChannelModalProps) => {
  const { connectedChannelInfo, isProject, workspaceSlackAuthenticated, onHide, doc_id, onSaveNotifType } = props;

  const redirectUri = `https://${window.location.host}/slackAuth/${doc_id ?? ""}`;

  const oAuthUrl =
    `https://slack.com/oauth/v2/authorize?scope=incoming-webhook` +
    `&client_id=` +
    SLACK_CLIENT_ID +
    `&redirect_uri=` +
    redirectUri;

  const [toggles, setToggles] = useState<SlackNotificationTypes>(connectedChannelInfo.notif_types);
  // this looks weird, but all it does is create a map where keys are the same as
  // the notifTypes object, and the values are all null | "loading" | "success"
  const [toggleLoadingMap, setToggleLoading] = useState<ToggleLoadingMap>(() => {
    return Object.keys(toggles).reduce((map, key) => {
      map.set(key as keyof SlackNotificationTypes, null);
      return map;
    }, new Map() as ToggleLoadingMap);
  });
  const [loading, setLoading] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const removeIntegration = async () => {
    setLoading(true);
    const params: { projectId?: string } = {};
    if (doc_id) params.projectId = doc_id;
    const [request] = revokeSlackAccess(params);
    await request;
    setLoading(false);
    onHide();
  };

  const handleToggleNotif = async (toggleName: keyof SlackNotificationTypes, value: boolean) => {
    try {
      setToggleLoading((toggles) => new Map(toggles).set(toggleName, "loading"));

      // optimistically handle frontend
      // should this write to frontend project state instead?
      setToggles((toggles) => ({
        ...toggles,
        [toggleName]: value,
      }));

      // update on backend
      // we enforce a minimum delay so that the loading state is visible
      await Promise.all([onSaveNotifType(toggleName, value), new Promise((resolve) => setTimeout(resolve, 200))]);

      // if we get something else back, we need to update the frontend
      setToggleLoading((toggles) => new Map(toggles).set(toggleName, "success"));

      setTimeout(() => {
        setToggleLoading((toggles) => new Map(toggles).set(toggleName, null));
      }, 1500);
    } catch (error) {
      setToggles((toggles) => ({
        ...toggles,
        [toggleName]: !value,
      }));

      setToggleLoading((toggles) => new Map(toggles).set(toggleName, null));

      console.error("Error setting Slack toggle", error);
    }
  };

  const connected = connectedChannelInfo.channel_id !== null;

  const modalState =
    workspaceSlackAuthenticated !== null
      ? connected
        ? MODAL_STATE.CHANNEL_CONNECTED
        : MODAL_STATE.CONNECT_TO_CHANNEL
      : MODAL_STATE.CONNECT_TO_SLACK;

  return (
    <BootstrapModal
      backdropClassName={style.backdrop}
      centered
      className={style.modal}
      dialogClassName={style.dialog}
      onHide={onHide}
      show
    >
      <BootstrapModal.Header className={style.header}>
        <BootstrapModal.Title className={style.title}>
          <img src={slackLogo} className={style.slackIcon} />
          {connected ? "Slack notifications" : "Send updates to a Slack channel"}
        </BootstrapModal.Title>
        <CloseIcon className={style.close} onClick={onHide} />
      </BootstrapModal.Header>
      <BootstrapModal.Body className={style.body}>
        {props.isSampleProject && props.isProject && (
          <p>
            In any (non-sample) Ditto project, you’ll be able to connect the project to a dedicated channel in Slack so
            that teammates can stay up-to-date on the latest activity. You’ll be able to configure notifications around
            edits, comments, and other actions.
          </p>
        )}
        {props.isSampleProject && !props.isProject && (
          <p>
            With non-sample data, you'll be able to connect your team's component library to a dedicated channel in
            Slack so that teammates can stay up-to-date on the latest activity. You'll be able to configure
            notifications around edits, comments, and other actions.
          </p>
        )}
        {props.isSampleProject && (
          <div>
            <div className={style.slackConnectionImage}>
              <img src={slackConnectionImage} />
            </div>

            <div className={style.sampleFooter}>
              <div className={style.sampleFooterHelpLink}>
                <a href="http://www.dittowords.com/docs/slack-integration" target="_blank">
                  Learn more
                </a>
              </div>
              <ButtonPrimary text="Got it" className={style.doneButton} onClick={onHide} />
            </div>
          </div>
        )}
        {!props.isSampleProject && modalState === MODAL_STATE.CONNECT_TO_SLACK && (
          <p>
            This integration allows activity from this project to be sent to a channel in your Slack workspace. In order
            to enable this integration, you'll have to add Slack as a connection in your{" "}
            <a target="_blank" href="/account/connections">
              Account Settings.
            </a>
          </p>
        )}
        {!props.isSampleProject && modalState === MODAL_STATE.CONNECT_TO_CHANNEL && (
          <div className={style.connectChannel}>
            <p>
              This integration allows activity from {isProject ? "this project" : "your Component Library"} (new
              comments, edits, and more) to be sent to a channel in your Slack workspace.{" "}
              <a target="_blank" href="http://www.dittowords.com/docs/slack-integration">
                Learn more
              </a>
            </p>

            <div className={style.connectChannelArea}>
              <ButtonPrimary
                text="Connect to Slack"
                disabled={loading}
                loading={loading}
                onClick={() => {
                  window.open(oAuthUrl, "_blank", "popup,width=700,height=800");
                }}
              />
            </div>
          </div>
        )}
        {modalState === MODAL_STATE.CHANNEL_CONNECTED && (
          <div className={style.channelConnected}>
            {showSuccessToast && (
              <span className={style.successToast}>Successfully connected to Slack channel! 🎉</span>
            )}
            <p>
              Updates currently being sent to:{" "}
              <span className={style.connectedChannelName}>#{connectedChannelInfo.channel_name}</span>
            </p>
            <div className={style.toggleArea}>
              <h2>Notification Settings</h2>
              <p>When should Ditto send notifications to your Slack channel?</p>
              <div className={style.toggleGroups}>
                {Object.keys(toggles).map((notifType: keyof SlackNotificationTypes, index) => {
                  // componentAttached or draftItems aren't relevant in the component library
                  if (!isProject && ["componentAttached", "draftItems"].includes(notifType)) {
                    return;
                  }

                  const value = toggles[notifType];

                  return (
                    <ToggleGroup
                      key={index}
                      labelText={toggleText(notifType, isProject)}
                      checked={value}
                      loading={toggleLoadingMap.get(notifType) === "loading"}
                      completed={toggleLoadingMap.get(notifType) === "success"}
                      setToggle={(checked) => handleToggleNotif(notifType, checked)}
                    />
                  );
                })}
              </div>
            </div>

            <div className={style.footer}>
              <div className={style.channelConnectedButtons}>
                <ButtonSecondary text="Remove" className={style.removeConnectionButton} onClick={removeIntegration} />
                <ButtonSecondary
                  text="Switch channel"
                  className={style.switchChannelButton}
                  disabled={loading}
                  onClick={() => window.open(oAuthUrl, "_blank", "popup,width=700,height=800")}
                />
              </div>
              <ButtonPrimary text="Done" className={style.doneButton} onClick={onHide} />
            </div>
          </div>
        )}
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

const ToggleGroup = ({
  checked,
  labelText,
  loading,
  completed,
  setToggle,
}: {
  checked: boolean;
  labelText: string;
  loading: boolean;
  completed: boolean;
  setToggle: (value: boolean) => void;
}) => {
  return (
    <div className={style.toggleGroup}>
      <div className={style.toggleContainer}>
        <Toggle
          className={style.toggle}
          checked={checked}
          icons={false}
          onChange={(event) => setToggle(event.target.checked)}
          disabled={false}
        />
      </div>
      <span className={style.toggleLabel}>{labelText}</span>
      {loading && <Spinner animation="border" variant="secondary" className={style.spinner} />}
      {completed && <CheckIcon className={style.checkIcon} />}
    </div>
  );
};

export default SlackChannelModal;
