import { useParams } from "react-router-dom";
import { WebappPermissionProvider as UserPermissionProvider } from "../../store/webappPermissionContext";

function FolderPermissionFromParams({ children }) {
  const params = useParams<{ id: string }>();
  return (
    <UserPermissionProvider resourceType={"project_folder"} resourceId={params.id}>
      {children}
    </UserPermissionProvider>
  );
}

export default FolderPermissionFromParams;
