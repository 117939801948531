/**
 * This function takes a list of variables that are either strings or nullish,
 * and returns an Oxford-comma-separated list of the non-nullish values.
 * @param values the list of values to join
 * @param allEmpty the string to return if all values are nullish
 */

export function commaList(values: (string | null | undefined)[], allEmpty: string) {
  const nonNullishArgs = values.filter(Boolean);
  if (!nonNullishArgs.length) return allEmpty;
  if (nonNullishArgs.length === 1) return nonNullishArgs[0];
  if (nonNullishArgs.length === 2) return nonNullishArgs.join(" and ");
  else return `${nonNullishArgs.slice(0, -1).join(", ")}, and ${nonNullishArgs.slice(-1)}`;
}
