import classnames from "classnames";
import React from "react";
import { PlanWillCancel } from "../../../store/billingContext";
import style from "./style.module.css";

interface PlanWillCancelParams {
  plan: string;
  planWillCancel: PlanWillCancel;
}
const PlanWillCancelComponent = ({ plan, planWillCancel }: PlanWillCancelParams) => {
  const cancelDate = new Date(planWillCancel ? planWillCancel[1] * 1000 : 0);
  if (!planWillCancel) {
    return <></>;
  }

  return (
    <div className={classnames([style.badge, style.red, style.cancelWarning])}>
      Your workspace's {plan === "team" ? "Team" : "Growth"} Plan is set to downgrade to the{" "}
      {planWillCancel[0] === "free" ? "Starter" : "Team"} plan at the end of your current billing cycle on{" "}
      {cancelDate.toLocaleDateString("en-us", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
      . If you have any questions or believe this is a mistake, please{" "}
      <a className={style.link} href="mailto:support@dittowords.com">
        contact support.
      </a>{" "}
    </div>
  );
};

export default PlanWillCancelComponent;
