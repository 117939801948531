import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import TimeAgo from "react-timeago";
import ReactTooltip from "react-tooltip";

import spinner from "../../../assets/small-spinner.gif";

import { INTERCOM_ATTR } from "../../../utils/constants";
import styleRoot from "../style.module.css";
import style from "./FigmaResyncButton.module.css";

interface Props {
  loading: boolean;
  resyncedAt: Date;
  onClick: () => void;
}

const FigmaResyncButton = (props: Props) => {
  const { resyncedAt, loading, onClick } = props;

  return (
    <div className={style.wrapper} data-tip data-for="resync" data-tour={INTERCOM_ATTR.RESYNC_BUTTON}>
      {loading && (
        <>
          <img src={spinner} className={style.spinner} /> <span className={style.text}>Resyncing...</span>
        </>
      )}
      {!loading && (
        <div onClick={onClick}>
          <RefreshIcon className={styleRoot.icon} />
          <span className={style.text}>
            <TimeAgo date={resyncedAt} minPeriod={60} key={resyncedAt?.toString()} />
          </span>
        </div>
      )}
      <ReactTooltip id="resync" place="bottom" effect="solid">
        Resync
      </ReactTooltip>
    </div>
  );
};

export default FigmaResyncButton;
