import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonPrimary from "../button/buttonprimary";
import style from "./style.module.css";

interface APIKeyModalProps {
  hideModal: () => void;
  createNewKey: (keyName: string) => void;
  newToken: { token: string };
}

const APIKeyModal = ({ hideModal, createNewKey, newToken }: APIKeyModalProps) => {
  const [keyName, setKeyName] = useState("");
  const [showCopyWarning, setShowCopyWarning] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const [copyBtnPressed, setCopyBtnPressed] = useState(false);
  const newApiKeyRef = useRef<HTMLDivElement>(null);

  // Warn user if they try to exit the modal without copying the API key
  useEffect(() => {
    if (newToken && newApiKeyRef.current) {
      const copyListener = () => {
        setHasCopied(true);
      };
      newApiKeyRef.current.addEventListener("copy", copyListener);
      return () => {
        newApiKeyRef.current?.removeEventListener("copy", copyListener);
      };
    }
  }, [newToken]);

  const handleKeyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyName(e.target.value);
  };

  const onHide = () => {
    if (newToken && !hasCopied) {
      setShowCopyWarning(true);
      setHasCopied(true);
    } else {
      hideModal();
    }
  };

  return (
    <Modal className={style.modal} dialogClassName={style.dialog} onHide={onHide} centered show>
      <Modal.Header className={style.header}>
        <Modal.Title className={style.modalTitle}>{newToken ? "Create a New API Key" : "Your New API Key"}</Modal.Title>
        <CloseIcon data-testid="close-button" className={style.closeIcon} onClick={onHide} />
      </Modal.Header>
      <Modal.Body className={style.body}>
        {!newToken && (
          <div>
            <div className={style.label}>Key Name</div>
            <input
              data-testid="key-name"
              type="text"
              value={keyName}
              onChange={handleKeyNameChange}
              placeholder="Enter a name for your API key"
            />
            <div className={style.btnWrapper}>
              <ButtonPrimary
                data-testid="create-key-button"
                className={style.createButton}
                onClick={() => createNewKey(keyName)}
                disabled={keyName.length === 0}
                text="Create"
              />
            </div>
          </div>
        )}
        {newToken && (
          <div>
            <div className={style.label}>Keeping your key safe</div>
            <div className={style.subtitle}>
              Save and store this key in a secure place. You won't be able to see it again.
            </div>
            <div className={style.newApiKey} ref={newApiKeyRef}>
              {newToken.token}
            </div>
            <div className={style.copyWrapper}>
              <Button
                data-testid="copy-key-button"
                variant="outline-primary"
                className={style.copyBtn}
                onClick={() => {
                  setHasCopied(true);
                  setCopyBtnPressed(true);
                  navigator.clipboard.writeText(newToken.token);
                }}
              >
                <FileCopyIcon className={style.icon} /> {copyBtnPressed ? "Copied!" : "Copy"}
              </Button>
              {showCopyWarning && <div className={style.error}>Make sure you copy the API key before you go!</div>}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default APIKeyModal;
