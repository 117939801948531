import HelpOutline from "@mui/icons-material/HelpOutline";
import React from "react";
import ButtonPrimary from "../../../button/buttonprimary";
import { ModalBody } from "../../../shared/ModalBase";
import { getNumberFormatted } from "../utils";

import { DittoComponent } from "ditto-react";
import { IStepProps } from "../../useState";
import style from "./style.module.css";

export default function Success(props: IStepProps & { onHide: () => void }) {
  const { state, onHide } = props;
  const { componentsInserted } = state;
  const componentsInsertedFormatted = getNumberFormatted(componentsInserted);

  return (
    <>
      <ModalBody className={style.body}>
        <div className={style.successText}>
          <p>
            <DittoComponent
              richText
              componentId="component-imports.successful-import"
              count={componentsInserted ?? 0}
              variables={{
                componentCount: componentsInserted ?? 0,
              }}
            />
          </p>
          <ButtonPrimary
            className={style.goButton}
            text="Go to components ->"
            onClick={onHide}
            data-testid="go-to-components-button"
          />
        </div>
        <div className={style.bottomSection}>
          <h2>✨ Use these components</h2>

          <div className={style.tiles}>
            <div className={style.tile}>
              <div className={style.tileHeader}>Connect to design</div>
              <div className={style.tileBody}>Attach these string components to text in your Figma mockups.</div>

              <div className={style.row}>
                <a href="/projects">Open a project {"->"}</a>
                <HelpOutline
                  className={style.helpIcon}
                  onClick={() => window.open("https://www.dittowords.com/docs/components-in-ditto", "_blank")}
                />
              </div>
            </div>

            <div className={style.tile}>
              <div className={style.tileHeader}>Fetch in development</div>
              <div className={style.tileBody}>
                Switch to pulling these strings into your code from Ditto (API or CLI){" "}
              </div>
              <div className={style.row}>
                <a href="/developers">Try it out {"->"}</a>

                <HelpOutline
                  className={style.helpIcon}
                  onClick={() => window.open("https://developer.dittowords.com/introduction", "_blank")}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </>
  );
}
