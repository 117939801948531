import { useAutoNameSuggestion } from "@/hooks/useAutoNameSuggestion";
import { useWorkspace } from "@/store/workspaceContext";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ComponentStatus, NotesAndTags } from "@shared/frontend/Components";
import TemplateBadge from "@shared/frontend/templates/TemplateBadge";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import classNames from "classnames";
import React, { useContext, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import BootstrapModal from "react-bootstrap/Modal";
import { BillingContext } from "../../store/billingContext";
import AutoNameButton from "../AutoNameButton";
import TextItem from "../TextItem";
import ComponentLimitText from "../account-billing/ComponentLimitText";
import ButtonPrimary from "../button/buttonprimary";
import EditDiff from "../editdiff/editdiff";
import ComponentCreatableInput from "./ComponentCreatableInput";
import ComponentModalPagination from "./ComponentModalPagination/index";
import ComponentUsageMessage from "./ComponentUsageMessage";
import { useComponentInput } from "./hooks/useComponentInput";
import { useComponentModal } from "./hooks/useComponentModal";
import style from "./style.module.css";
import { ActualComponentInterface, ComponentInterface, FrameVariants } from "./types";

interface Props {
  comp?: ActualComponentInterface;
  onHide: () => void;
  handleDocUpdate: (compIds: string[]) => void;
  fetchCompInfo: () => void;
  multiSelectedIds: string[];
  multiSelectedComps: ActualComponentInterface[];
  unselectAll: () => void;
  setShowMultiAttachCompToast: (show: boolean) => void;
  onSubmit: (comp: ComponentInterface | null) => void;
  isSwap: boolean;
  handleHistoryUpdate: () => void;
  frameVariants: FrameVariants;
  selectedGroupId: string;
  multiSelectGroupIds: string[];
  shouldComponentizeDuplicates: boolean;
  duplicateComps: string[];
  isSampleProject?: boolean;
  // optional prop to notify when a component is attached to a single text item
  onSingleAttach?: () => void;
  // optional prop to notify when a component is attached to multiple text items
  onMultiAttach?: () => void;
}

const ComponentModal = ({
  comp,
  onHide,
  handleDocUpdate,
  fetchCompInfo,
  multiSelectedIds,
  multiSelectedComps,
  unselectAll,
  setShowMultiAttachCompToast,
  onSubmit,
  isSwap,
  handleHistoryUpdate,
  frameVariants,
  selectedGroupId,
  multiSelectGroupIds,
  shouldComponentizeDuplicates,
  duplicateComps,
  isSampleProject,
  onSingleAttach,
  onMultiAttach,
}: Props) => {
  const {
    componentLimits: { isOverComponentLimit },
  } = useContext(BillingContext);

  const workspace = useWorkspace();

  const inputProps = useComponentInput({
    multiSelectedIds,
    currentComp: comp,
    isSwap,
  });

  const {
    searchValue,
    controlledKey,
    selectedComp,
    selectedCompId,
    onCategoryClick,
    newCompName,
    folders,
    selectedFolder,
    setSelectedFolder,
    handleChange,
    handleInputChange,
    validateAbilityCreateComp,
    compVariants,
    selectedCompVariants,
    loadingSelectedCompData,
    options,
    value,
  } = inputProps;

  const {
    loadingState,
    instanceIndex,
    newVariants,
    title,
    placeholder,
    isEdited,
    frameVariantsIds,
    incrementIndex,
    decrementIndex,
    attachComp,
    onCreate,
    clearError,
  } = useComponentModal({
    onSingleAttach,
    onMultiAttach,
    isSwap,
    newCompName,
    selectedFolder,
    selectedGroupId,
    multiSelectGroupIds,
    multiSelectedIds,
    duplicateComps,
    frameVariants,
    selectedComp,
    selectedCompId,
    currentComp: comp,
    selectedCompVariants,
    shouldComponentizeDuplicates,
    handleDocUpdate,
    setShowMultiAttachCompToast,
    fetchCompInfo,
    onHide,
    unselectAll,
  });

  // set to array so reference is stable
  const compTextArr = useRef([comp?.text || ""]);
  const autoName = useAutoNameSuggestion({
    textsToAutoname: compTextArr.current,
  });

  const showAutoNameButton =
    workspace.workspaceInfo?.aiFeatures.automatedComponentNaming &&
    comp &&
    newCompName.length === 0 &&
    !selectedComp &&
    searchValue.length === 0;

  const [isLoadingNameSuggestion, setIsLoadingNameSuggestion] = useState(false);

  const [autoNameError, setAutoNameError] = useState("");
  const onAutoNameClick = async () => {
    if (!comp) return;

    setAutoNameError("");
    setIsLoadingNameSuggestion(true);
    const suggestedGroupAndName = await autoName.generateSuggestionFor(comp.text);
    setIsLoadingNameSuggestion(false);

    const splitGroupAndName = suggestedGroupAndName.split("/");
    const suggestedName = splitGroupAndName.length > 1 ? splitGroupAndName[1] : suggestedGroupAndName;
    // something went wrong
    if (suggestedName === "") {
      setAutoNameError("Servers are currently overloaded. Please try again.");
      return;
    }

    handleInputChange(suggestedGroupAndName);
    // Open the menu again
    window?.document?.getElementById("select-input")?.focus();
  };

  const handleInputChangeWithAutoNameTracking = (inputValue: string) => {
    if (!comp) {
      handleInputChange(inputValue);
      return;
    }

    if (autoName.textToNameSuggestion.current[comp.text].status === "accepted" && inputValue !== "") {
      autoName.setStatusFor(comp.text, "edited");
    } else if (autoName.textToNameSuggestion.current[comp.text].status !== "not generated" && inputValue.length <= 1) {
      autoName.setStatusFor(comp.text, "rejected");
    }

    handleInputChange(inputValue);
  };

  const selectedCompIsTemplate = selectedComp?.type === "template";

  const getAttachCopy = () => {
    if (selectedCompIsTemplate) {
      return loadingState.isLoading ? "Attaching Template..." : "Attach Template";
    }

    return loadingState.isLoading ? "Attaching..." : "Attach";
  };

  const userHasEditAccessToFolder = userHasResourcePermission(
    "component_folder:edit",
    selectedFolder._id === "" ? "component_folder_no_id" : selectedFolder._id
  );

  const ctaButton = isSwap ? (
    <ButtonPrimary
      text={loadingState.isLoading ? "Swapping..." : "Swap"}
      onClick={() => onSubmit(selectedComp)}
      disabled={loadingState.isLoading || !isEdited}
    />
  ) : selectedCompId ? (
    <ButtonPrimary
      data-testid="attach-component-button"
      text={getAttachCopy()}
      onClick={async () => {
        await attachComp();
        await handleHistoryUpdate();
      }}
      disabled={loadingState.isLoading || loadingSelectedCompData}
    />
  ) : (
    <ButtonPrimary
      text={loadingState.isLoading ? "Creating..." : "Create"}
      onClick={() => {
        onCreate();
        if (comp) {
          autoName.saveAnalyticsFor(comp.text);
        }
      }}
      disabled={
        !userHasEditAccessToFolder ||
        loadingState.isLoading ||
        newCompName.length == 0 ||
        isOverComponentLimit ||
        (isSampleProject && value?.__isNew__)
      }
      data-testid="create-component-button"
    />
  );

  const createComponentDisplay = !isSwap && !selectedCompId && newCompName.length !== 0 && !loadingState.error && (
    <div>
      <div className={style.warning}>
        {shouldComponentizeDuplicates ? (
          <>
            You're creating a new component and attaching it to <strong>{duplicateComps.length}</strong> text items in
            this project.
          </>
        ) : (
          <>
            This will create a component <b>{newCompName}</b> with the following text:
          </>
        )}
      </div>
      <div className={style.compDiff}>
        <div className={style.baseDiffWrapper}>
          <div>
            {compVariants.length > 0 && (
              <div className={style.variantLabel}>
                <CallSplitIcon className={style.icon} />
                <div className={style.name}>Base</div>
              </div>
            )}
            <div className={style.editDiffContainer}>
              <TextItem textItem={comp} frameVariant={null} />
            </div>
          </div>
          {shouldComponentizeDuplicates && <div className={style.dupesCount}>{duplicateComps.length}</div>}
        </div>
      </div>
      {compVariants.map((variant) => (
        <div key={variant.variantId._id} className={style.compDiff}>
          <div className={style.variantLabel}>
            <CallSplitIcon className={style.icon} />
            <div className={style.name}>{variant?.variantId?.name || ""}</div>
          </div>
          <div className={style.editDiffContainer}>
            <TextItem textItem={variant} frameVariant={null} />
          </div>
        </div>
      ))}
    </div>
  );

  // For multi select, delete variants if they don't exist on component being attached
  const removeVariants =
    multiSelectedIds && multiSelectedIds.length > 1 && selectedCompVariants.notInUse.length > 0
      ? multiSelectedComps.map((comp) =>
          comp.variants.filter((v) => selectedCompVariants.notInUse.some((variant) => variant._id === v.variantId))
        )
      : [];

  const getVariantName = (variant) => {
    const selectedVariant = selectedCompVariants.inUse.find((v) => v._id === variant.variantId);
    return selectedVariant ? selectedVariant.name : null;
  };

  const attachComponentEditDiffs = (textItem) => {
    if (!selectedComp) {
      return <React.Fragment />;
    }
    const [{ status, tags, notes }] = selectedComp.instances;

    const variantsOnComponent = selectedComp?.instances[0].variants;

    const hasVariants =
      frameVariantsIds.length > 0 ||
      newVariants.length > 0 ||
      (removeVariants.length > 0 && removeVariants[instanceIndex].length > 0);

    return (
      <>
        {isEdited && (
          <div>
            <div className={style.compDiff}>
              <ComponentStatus status={status} />
              {hasVariants && (
                <div className={style.variantLabel}>
                  <CallSplitIcon className={style.icon} />
                  <div className={style.name}>Base</div>
                </div>
              )}
              <TemplateBadge
                type={selectedComp.type}
                selected
                className={classNames({
                  [style.templateBadge]: true,
                  [style.paddingTop8Px]: hasVariants,
                })}
              />
              <div
                className={classNames({
                  [style.baseDiffWrapper]: true,
                  [style.paddingTop8Px]: selectedComp.type === "template" || hasVariants,
                })}
              >
                <div className={style.editDiffContainer}>
                  <EditDiff
                    text_before={textItem.text}
                    text_after={selectedComp?.instances[0].text}
                    rich_text_before={textItem.rich_text}
                    rich_text_after={selectedComp?.instances[0].rich_text}
                    showRichText={true}
                  />
                </div>
                {shouldComponentizeDuplicates && <div className={style.dupesCount}>{duplicateComps.length}</div>}
              </div>
              <NotesAndTags notes={notes || ""} tags={tags || []} />
            </div>
            {variantsOnComponent
              .filter((variant) => {
                if (textItem._meta) {
                  return (
                    frameVariantsIds.includes(variant.variantId) &&
                    frameVariants[textItem._meta.groupId] &&
                    frameVariants[textItem._meta.groupId].map((variant) => variant.id).includes(variant.variantId)
                  );
                }
                return frameVariantsIds.includes(variant.variantId);
              })
              .map((variant) => {
                const compVariant = textItem.variants.find((v) => v.variantId === variant.variantId);

                return (
                  <div key={variant.variantId} className={classNames([style.compDiff, style.compDiffVariant])}>
                    <div className={style.variantLabel}>
                      <CallSplitIcon className={style.icon} />
                      <div className={style.name}>{getVariantName(variant)}</div>
                    </div>
                    <div className={style.compDiffVariantDiffWrapper}>
                      <div className={style.editDiffContainer}>
                        <EditDiff
                          text_before={compVariant?.text || ""}
                          text_after={variant.text}
                          rich_text_before={compVariant?.rich_text}
                          rich_text_after={variant.rich_text}
                          showRichText={true}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {!multiSelectedIds &&
              newVariants.map((variant) => (
                <div key={variant.variantId} className={classNames([style.compDiff, style.compDiffVariant])}>
                  <CallSplitIcon className={style.icon} />
                  <div className={style.name}>
                    {
                      // @ts-ignore - this is a bug in the typescript compiler
                      selectedCompVariants.notInUse.find((v) => v._id === variant.variantId).name
                    }
                  </div>
                  {!isSwap && <div className={style.newComponentVariant}>New Component Variant</div>}
                  <div className={style.editDiffContainer}>
                    <EditDiff
                      text_before={isSwap ? variant.text : ""}
                      text_after={isSwap ? "" : variant.text}
                      rich_text_before={isSwap ? variant.rich_text : undefined}
                      rich_text_after={isSwap ? undefined : variant.rich_text}
                      showRichText={true}
                    />
                  </div>
                </div>
              ))}
            {removeVariants.length > 0 &&
              removeVariants[instanceIndex].map((variant) => (
                <div key={variant.variantId} className={style.compDiff}>
                  <CallSplitIcon className={style.icon} />
                  <div className={style.name}>
                    {selectedCompVariants.notInUse.find((v) => v._id === variant.variantId)?.name}
                  </div>
                  <EditDiff
                    text_before={variant.text}
                    text_after={""}
                    rich_text_before={variant.rich_text}
                    rich_text_after={{ content: [], type: "doc" }}
                    showRichText={true}
                  />
                </div>
              ))}
            {selectedComp && !selectedCompIsTemplate && !shouldComponentizeDuplicates && (
              <ComponentUsageMessage selectedComp={selectedComp} comp={textItem} />
            )}
          </div>
        )}
      </>
    );
  };
  const getAttachWarningMessage = () => {
    if (shouldComponentizeDuplicates) {
      return (
        <>
          You’re attaching <strong>{duplicateComps.length}</strong> text items in this project to this{" "}
          {selectedCompIsTemplate && "template "}
          component.
        </>
      );
    }

    if (isEdited) {
      const trailingText = selectedComp?.type === "template" ? "until you edit it." : "unless unlinked.";

      const componentText = selectedComp?.type === "template" ? "a template component" : "an existing component";

      return `Heads up! By attaching this to ${componentText}, its text will reflect the component ${trailingText}`;
    }

    return "Heads up! It looks like this is the same component!";
  };

  const attachComponentDisplay = selectedCompId &&
    selectedComp &&
    selectedComp.instances &&
    !loadingSelectedCompData && (
      <div>
        <div className={style.warning}>
          <div>{getAttachWarningMessage()}</div>
          {isEdited && !isSwap && newVariants.length > 0 && (
            <div className={style.newVariants}>
              Attaching will add{" "}
              <b>
                {newVariants.length} new variant
                {newVariants.length === 1 ? "" : "s"}
              </b>{" "}
              to the {selectedComp?.type === "template" && "template "}
              text.
            </div>
          )}
        </div>
        {multiSelectedIds && multiSelectedIds.length > 1 && (
          <div className={style.multiSelectedIds}>
            <div className={style.selectedTextsInfo}>
              <div className={style.numberBadge}>{multiSelectedIds.length}</div>
              {`selected text${multiSelectedIds.length > 1 ? "s" : ""}`}
            </div>
            <div className={style.pagination}>
              <ComponentModalPagination
                index={instanceIndex}
                numItems={multiSelectedIds.length}
                incrementIndex={incrementIndex}
                decrementIndex={decrementIndex}
              />
            </div>
          </div>
        )}
        {attachComponentEditDiffs(
          multiSelectedIds && multiSelectedIds.length > 1 ? multiSelectedComps[instanceIndex] : comp
        )}
      </div>
    );

  return (
    <div>
      <BootstrapModal
        show={true}
        className={style.modal}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>{title}</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body data-testid="attach-component-modal-body" className={style.body}>
          {shouldComponentizeDuplicates && (
            <div className={style.duplicateNudgeDescription}>
              Create a new component out of the repeated instances in this project, or attach them to one from your
              team's library:
            </div>
          )}
          {isOverComponentLimit && !isSampleProject && <ComponentLimitText />}
          <ComponentCreatableInput
            folders={folders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            searchValue={searchValue}
            controlledKey={controlledKey}
            placeholder={placeholder}
            onCategoryClick={onCategoryClick}
            validateAbilityCreateComp={validateAbilityCreateComp}
            handleInputChange={handleInputChangeWithAutoNameTracking}
            handleChange={(e) => {
              handleChange(e);
              clearError();
            }}
            options={options}
            value={value}
            autoNameButton={
              !isSwap &&
              showAutoNameButton && (
                <AutoNameButton onClick={onAutoNameClick} isLoadingNameSuggestion={isLoadingNameSuggestion} />
              )
            }
          />
          {autoNameError !== "" && (
            <Alert variant="danger" className={style.autoNameError}>
              {autoNameError}
            </Alert>
          )}
          {loadingState.error && <p className={style.error}>{loadingState.error}</p>}
          {createComponentDisplay}
          {attachComponentDisplay}
          {/*<p className={style.disclaim}>Heads up! People invited to collaborate on this project will join your workspace. </p>*/}

          {isSampleProject && value?.__isNew__ && (
            <div className={style.sampleInfo}>
              <div className={style.sampleInfoIcon}>
                <InfoOutlined fontSize="inherit" />
              </div>
              &nbsp;Creating new components is disabled in the sample project.
            </div>
          )}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>{ctaButton}</BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
};

export default ComponentModal;
