import React from "react";
import { IChangeItem } from "../../../../shared/types/ActualChange";
import LoadMore from "../../atoms/LoadMore";
import PanelEmptyState from "../../molecules/PanelEmptyState";
import style from "./index.module.css";

export interface CustomChangeItemComponentProps {
  changeItem: IChangeItem;
}

interface IProps {
  changeItems: IChangeItem[];
  ChangeItemComponent: React.FC<CustomChangeItemComponentProps>;
  hasMore?: boolean;
  isLoadingMore?: boolean;
  fetchMore?: () => Promise<void>;
}

export function ChangeItemList({ changeItems, hasMore, isLoadingMore, fetchMore, ChangeItemComponent }: IProps) {
  if (!changeItems.length) {
    return (
      <PanelEmptyState
        data-testid="change-item-list"
        header="No activity to show yet"
        description="Changes and updates will appear here"
      />
    );
  }

  return (
    <div data-testid="change-item-list">
      {changeItems.map((changeItem, index) => (
        <React.Fragment key={changeItem._id}>
          {index !== 0 && <hr className={style.divider} />}
          <ChangeItemComponent key={changeItem._id} changeItem={changeItem} />
        </React.Fragment>
      ))}
      <LoadMore hasMore={hasMore} isLoadingMore={isLoadingMore} fetchMore={fetchMore}>
        <hr className={style.divider} />
      </LoadMore>
    </div>
  );
}

export default ChangeItemList;
