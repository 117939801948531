const PAGE_ARRAY_SIZE = 5;

export function calculatePageArray(page: number, pageCount: number, pageArraySize = PAGE_ARRAY_SIZE) {
  if (pageCount <= 1 || page >= pageCount) {
    return [];
  }

  const arr = Array.from({ length: pageCount }, (_, i) => String(i + 1));
  if (pageCount <= PAGE_ARRAY_SIZE) {
    return arr;
  }

  const arrLowerBound = Math.max(page - 2, 0);
  if (arrLowerBound === 0) {
    return arr.slice(0, pageArraySize - 1).concat("...", arr[arr.length - 1]);
  }

  const arrUpperBound = Math.min(page + 2, pageCount - 1);
  if (arrUpperBound === pageCount - 1) {
    return ["1", "..."].concat(arr.slice(-(pageArraySize - 1)));
  }

  return ["1", "...", arr[page - 1], arr[page], arr[page + 1], "...", arr[arr.length - 1]];
}

export const _test = { calculatePageArray };
