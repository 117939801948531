import React, { useEffect, useRef } from "react";
import { ValueType, components } from "react-select";
import CreatableSelect from "react-select/creatable";
import FolderSelect from "../../FolderSelect";
import { Folder } from "../../FolderSelect/useFolderSelect";
import ComponentCategoreisMenu from "../ComponentCategoriesMenu";
import ComponentGroupHeading from "../ComponentGroupHeading";
import ComponentModalOption from "../ComponentModalOption";
import { ComponentInputOptions } from "../hooks/useComponentInput";
import style from "./style.module.css";

// I hate react-select
// https://github.com/JedWatson/react-select/issues/3068
const Input = (props) => <components.Input {...props} isHidden={false} />;

interface Props {
  folders: Folder[];
  selectedFolder: Folder;
  setSelectedFolder: (update: Folder) => void;
  controlledKey: string;
  searchValue: string;
  placeholder: string;
  onCategoryClick: (category: string) => void;
  handleInputChange: (q: string) => void;
  handleChange: (e) => void;
  validateAbilityCreateComp: (q: string) => boolean;
  value: ValueType;
  options: ComponentInputOptions;
  autoNameButton?: any;
}
const ComponentCreatableInput = (props: Props) => {
  const {
    folders,
    selectedFolder,
    setSelectedFolder,
    controlledKey,
    searchValue,
    placeholder,
    onCategoryClick,
    handleChange,
    handleInputChange,
    validateAbilityCreateComp,
    value,
    options,
  } = props;

  const selectRef = useRef<HTMLInputElement>(null);

  const customDropdownStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#B7B7B8",
      };
    },
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: "4px",
    }),
    group: (provided) => ({
      ...provided,
      padding: 0,
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: 700,
      marginBottom: "0px",
      paddingLeft: "12px",
      backgroundColor: "#E9E9EC",
      paddingTop: "5px",
      paddingBottom: "5px",
      color: "#8F8F8F",
    }),
  };

  useEffect(() => {
    if (selectRef?.current) selectRef?.current?.focus();
  }, []);

  return (
    <div>
      <FolderSelect setSelectedFolder={setSelectedFolder} folders={folders} selectedFolder={selectedFolder} />
      <div data-testid="component-modal-input-wrapper" className={style.inputWrapper}>
        <div className={style.autoNameButtonWrapper}>{props.autoNameButton}</div>
        <CreatableSelect
          key={controlledKey}
          inputId="select-input"
          ref={selectRef}
          inputValue={searchValue}
          value={value}
          options={options}
          defaultMenuIsOpen={true}
          openMenuOnFocus={true}
          isClearable
          placeholder={placeholder}
          onChange={handleChange}
          // Don't send input change event when input gets defocused
          onInputChange={(value: string, actionMeta: { action: string }) => {
            if (actionMeta.action === "input-change") {
              handleInputChange(value);
            }
          }}
          className={style.dropdown}
          isValidNewOption={validateAbilityCreateComp}
          styles={customDropdownStyles}
          filterOption={null}
          // Don't send input change event when input gets defocused
          onBlur={() => {}}
          components={{
            Option: (props) => {
              if (props.data.isMenu) {
                return <ComponentCategoreisMenu categories={props.data.categories} onClick={onCategoryClick} />;
              }
              return <ComponentModalOption {...props} searchValue={searchValue} />;
            },
            GroupHeading: (props) => <ComponentGroupHeading {...props} />,
            Input,
          }}
        />
      </div>
    </div>
  );
};

export default ComponentCreatableInput;
