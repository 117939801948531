import React from "react";

interface Props extends React.SVGProps<SVGSVGElement> {}

const FileDownload = (props: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_80_895)">
      <path
        d="M12.6666 6H9.99992V2H5.99992V6H3.33325L7.99992 10.6667L12.6666 6ZM3.33325 12V13.3333H12.6666V12H3.33325Z"
        fill="#357FEE"
      />
    </g>
    <defs>
      <clipPath id="clip0_80_895">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileDownload;
