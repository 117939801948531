import React from "react";

import classNames from "classnames";
import UserBubble from "../UserBubble";
import style from "./style.module.css";

const MAX_SIZE_BEFORE_COLLAPSE = 3;

export interface ConcurrentUserListProps {
  className?: string;
  users: { name: string; picture?: string | undefined | null }[];
}

export function ConcurrentUserList(props: ConcurrentUserListProps) {
  const usersTransformed = React.useMemo(
    () =>
      props.users.map(({ name, picture }) => {
        if (name.includes(" ")) {
          const [first, last] = name.split(" ");
          return {
            fullName: name,
            abbreviation: first[0].toUpperCase() + last[0].toUpperCase(),
            picture,
          };
        }

        return { fullName: name, abbreviation: name[0].toUpperCase(), picture };
      }),
    [props.users]
  );

  const usersToRender = usersTransformed.slice(0, MAX_SIZE_BEFORE_COLLAPSE);
  const usersExtra = usersTransformed.slice(MAX_SIZE_BEFORE_COLLAPSE);

  return (
    <div className={classNames(style.listWrapper, props.className)}>
      <ul className={style.list}>
        {usersToRender.map((user, i) => (
          <UserBubble {...user} key={i} itemKey={i} />
        ))}
        {usersExtra.length > 0 && (
          <UserBubble
            className={style.extraUserBubble}
            abbreviation={`+${usersExtra.length}`}
            fullName={usersExtra.map((u) => (
              <>
                <span>{u.fullName}</span>
                <br />
              </>
            ))}
            itemKey="extra-users"
            picture={null}
          />
        )}
      </ul>
    </div>
  );
}
