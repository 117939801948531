import classnames from "classnames";
import React from "react";

import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SearchIcon from "@mui/icons-material/Search";
import spinner from "../../assets/small-spinner.gif";
import style from "./style.module.css";

const SearchInput = ({
  value,
  onChange,
  name,
  multiOn = false,
  toggleMultiSelect = () => {},
  docSearch,
  loading,
  className = "",
}) => {
  return (
    <div className={classnames(style.searchAndFilters, className)}>
      <div className={classnames(style.searchWrap, "search-bar")}>
        <SearchIcon className={style.searchIcon} />
        <input
          className={classnames(style.input, "search-input-proj")}
          placeholder={"Search " + name}
          aria-label="Search"
          value={value}
          onChange={onChange}
        />
        {loading && <img className={style.loading} src={spinner} />}
      </div>
      {docSearch && (
        <div>
          <div
            onClick={() => toggleMultiSelect()}
            data-tip
            data-for="multi"
            className={classnames({
              [style.multiSelect]: true,
              [style.multiOn]: multiOn,
            })}
          >
            <PlaylistAddCheckIcon className={style.icon} />
            Select Multiple
          </div>
          {/*<ReactTooltip
          id='multi'
          place='bottom'
          effect='solid'
        >
          Select Multiple
        </ReactTooltip>*/}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
