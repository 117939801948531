import { Editor } from "@tiptap/core";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { EditorView } from "prosemirror-view";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import style from "./style.module.css";

import { useAuthenticatedAuth } from "@/store/AuthenticatedAuthContext";
import { HocuspocusProvider } from "@hocuspocus/provider";
import Collaboration from "@tiptap/extension-collaboration";
import { Doc } from "yjs";
import HighlightButtons from "../../../../../../shared/frontend/Editor/HighlightButtons";
import { EditorExtensions, EditorNodes } from "./RichTextDraftEditorNodesAndExtensions";
import { getSelectedTextItems } from "./getSelectedTextItems";

interface Props {
  isDisabled: boolean;
  onUpdate: ({ editor }: { editor: Editor }) => void;
  onBlur: () => void;
  onFocus: () => void;
  onCreate: ({ editor }: { editor: Editor }) => void;
  onDestroy: () => void;
  onSelectionUpdate: (textItemIds: string[]) => void;
  projectId: string;
  groupId: string;
}

export const getSelectionHasChanged = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return true;
  }

  if (arr1.length === 1) {
    return arr1[0] !== arr2[0];
  }

  let arr1Map = {};

  for (let i = 0; i < arr1.length; i++) {
    arr1Map[arr1[i]] = true;
  }

  for (let i = 0; i < arr2.length; i++) {
    if (!arr1Map[arr2[i]]) {
      return true;
    }
  }

  return false;
};

export default (props: Props) => {
  const { onCreate, onDestroy, onUpdate, onBlur, isDisabled, projectId, groupId } = props;

  const { getTokenSilently } = useAuthenticatedAuth();
  const [cookies] = useCookies(["impersonateUser"]);

  const [isBoldActive, setIsBoldActive] = useState<boolean>(false);
  const [isItalicActive, setIsItalicActive] = useState<boolean>(false);
  const [isUnderlineActive, setIsUnderlineActive] = useState<boolean>(false);
  const [isSuperscriptActive, setIsSuperscriptActive] = useState<boolean>(false);
  const [isSubscriptActive, setIsSubscriptActive] = useState<boolean>(false);

  /**
   * Since `useEditor` doesn't automatically refresh its arguments on every render,
   * we need to cache `isDisabled` in a ref for it to function as expected in
   * `handleTextInput` when the value changes.
   * See https://github.com/ueberdosis/tiptap/issues/2403 for more details.
   */
  const isDisabledReference = useRef(isDisabled);
  useEffect(() => {
    isDisabledReference.current = isDisabled;
  }, [isDisabled]);

  const handleTextInput = (_view: EditorView, _from: number, _to: number, _text: string) => {
    return isDisabledReference.current;
  };

  const selectedTextItemIdsCache = useRef<string[]>([]);

  const hocuspocusProvider = useMemo(() => {
    let document: Doc | undefined = undefined;

    const hpProvider = new HocuspocusProvider({
      url: process.env.HOCUSPOCUS_URL || `ws://0.0.0.0:1234`,
      name: groupId,
      parameters: {
        projectId: projectId,
        impersonateUser: cookies.impersonateUser,
      },
      document,
      token: getTokenSilently(),
    });

    return hpProvider;
  }, []);

  const editor = useEditor(
    {
      extensions: [
        ...EditorNodes,
        ...EditorExtensions,
        Collaboration.configure({
          document: hocuspocusProvider.document,
        }),
      ],
      onCreate,
      onUpdate,
      onBlur,
      editorProps: { handleTextInput },
      onDestroy,
      // The selected ids need to be re-emitted on focus to avoid an edge case
      // where clicking into the draft group doesn't update the selected
      // text items if the spot clicked in the group is the same as was clicked
      // previously. This occurs because ProseMirror maintains its internal selection
      // state even between losing and regaining focus.
      onFocus: (args) => {
        props.onFocus();

        const textItemIds = getSelectedTextItems(args);
        const selectionIsSame = !getSelectionHasChanged(textItemIds, selectedTextItemIdsCache.current);

        if (selectionIsSame) {
          props.onSelectionUpdate(textItemIds);
        }
      },

      // Calling props.onSelectionUpdate is expensive due to the state changes
      // that propagate across the entire project page, and this callback executes
      // every time the user types a character into the editor. To avoid severe
      // performance drops:
      // - cache the selected text items in a local ref
      // - only call back if the text items have changed
      onSelectionUpdate: (args) => {
        setIsBoldActive(args.editor.isActive("bold"));
        setIsItalicActive(args.editor.isActive("italic"));
        setIsUnderlineActive(args.editor.isActive("underline"));
        setIsSuperscriptActive(args.editor.isActive("superscript"));
        setIsSubscriptActive(args.editor.isActive("subscript"));

        if (!args.editor.isFocused) {
          return;
        }

        const textItemIds = getSelectedTextItems(args);

        const arr1 = textItemIds;
        const arr2 = selectedTextItemIdsCache.current;

        if (getSelectionHasChanged(arr1, arr2)) {
          props.onSelectionUpdate(textItemIds);

          selectedTextItemIdsCache.current = textItemIds;
        }
      },
    },
    []
  );

  const textWordCount = (editor && editor.getText().trim().split(" ").length) || 0;

  return (
    <div className={style.richTextDraftEditorContainer}>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <HighlightButtons
            textWordCount={textWordCount}
            isBoldActive={isBoldActive}
            isItalicActive={isItalicActive}
            isUnderlineActive={isUnderlineActive}
            isSuperscriptActive={isSuperscriptActive}
            isSubscriptActive={isSubscriptActive}
            onBoldClick={() => editor.chain().focus().toggleBold().run()}
            onItalicClick={() => editor.chain().focus().toggleItalic().run()}
            onSuperscriptClick={() => editor.chain().focus().toggleSuperscript().run()}
            onSubscriptClick={() => editor.chain().focus().toggleSubscript().run()}
            onUnderlineClick={() => editor.chain().focus().toggleUnderline().run()}
          />
        </BubbleMenu>
      )}
      <EditorContent editor={editor} />
    </div>
  );
};
