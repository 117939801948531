import React from "react";
const MarkEmailUnreadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M1.66671 12.333C1.30004 12.333 0.986152 12.2025 0.725041 11.9413C0.46393 11.6802 0.333374 11.3663 0.333374 10.9997V2.99967C0.333374 2.63301 0.46393 2.31912 0.725041 2.05801C0.986152 1.7969 1.30004 1.66634 1.66671 1.66634H8.40004C8.3556 1.88856 8.33337 2.11079 8.33337 2.33301C8.33337 2.55523 8.3556 2.77745 8.40004 2.99967H1.66671L7.00004 6.33301L9.43337 4.81634C9.58893 4.96079 9.75837 5.08579 9.94171 5.19134C10.125 5.2969 10.3167 5.38856 10.5167 5.46634L7.00004 7.66634L1.66671 4.33301V10.9997H12.3334V5.59967C12.5889 5.54412 12.8278 5.46634 13.05 5.36634C13.2723 5.26634 13.4778 5.14412 13.6667 4.99967V10.9997C13.6667 11.3663 13.5362 11.6802 13.275 11.9413C13.0139 12.2025 12.7 12.333 12.3334 12.333H1.66671ZM11.6667 4.33301C11.1112 4.33301 10.6389 4.13856 10.25 3.74967C9.86115 3.36079 9.66671 2.88856 9.66671 2.33301C9.66671 1.77745 9.86115 1.30523 10.25 0.916341C10.6389 0.527452 11.1112 0.333008 11.6667 0.333008C12.2223 0.333008 12.6945 0.527452 13.0834 0.916341C13.4723 1.30523 13.6667 1.77745 13.6667 2.33301C13.6667 2.88856 13.4723 3.36079 13.0834 3.74967C12.6945 4.13856 12.2223 4.33301 11.6667 4.33301Z"
        fill="#357FEE"
      />
    </svg>
  );
};

export default MarkEmailUnreadIcon;
