// Meant for use alongside useVariantSelect.ts
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import classNames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import style from "./VariantSelect.module.css";
import { ALL_VARIANTS_FOLDER_ID, VariantFolder } from "./useVariantSelect";

interface Option {
  id: string;
  name: string;
}

interface VariantSelectProps {
  fontSize?: string;
  className?: string;
  classNameDropdownToggle?: string;
  selectedVariantFolder: VariantFolder;
  options: Option[];
  onChange: (id: string) => void;
  text?: string;
}

const isInPlugin = window.location.search.includes("pluginType");

export const VariantSelect = (props: VariantSelectProps) => {
  const inFolder =
    ![ALL_VARIANTS_FOLDER_ID, "in-this-project"].includes(props.selectedVariantFolder._id) &&
    props.options[0]?.name?.includes("Sample");

  // icon font size is fontSize plus 4, to make it look right
  const iconFontSize = `${parseInt(props.fontSize || "16px") + 4}px`;

  return (
    <div className={classNames([style.container, props.className])} style={{ fontSize: props.fontSize || "16px" }}>
      <strong>{props.text ?? "Searching in"}</strong>
      <Dropdown
        className={classNames(style.dropdown, {
          [style.inFolder]: inFolder,
        })}
      >
        <Dropdown.Toggle
          className={classNames([style.dropdownToggle, props.classNameDropdownToggle], {
            [style.forceCaret]: isInPlugin,
          })}
          style={{ fontSize: props.fontSize || "16px" }}
        >
          {inFolder && <FolderOpenOutlined className={style.folderIcon} style={{ fontSize: iconFontSize }} />}
          <span className={classNames(style.dropdownToggleText)}>{props.selectedVariantFolder.name}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.options.map((option) => (
            <Dropdown.Item onClick={() => props.onChange(option.id)} key={option.id}>
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
