import { projectHiddenResultsBlocksCountAtom, projectHiddenResultsTextItemsCountAtom } from "@/stores/Project";
import { searchAtom } from "@/stores/ProjectFiltering";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import { useAtom, useAtomValue } from "jotai";
import style from "./style.module.css";

function NoSearchResults() {
  const [search, setSearch] = useAtom(searchAtom);
  const projectHiddenResultsTextItemsCount = useAtomValue(projectHiddenResultsTextItemsCountAtom);
  const projectHiddenResultsBlocksCount = useAtomValue(projectHiddenResultsBlocksCountAtom);

  function clearSearch() {
    setSearch("");
  }

  function handleKeyboardEvent(event: React.KeyboardEvent) {
    if (event.key === "Escape") {
      clearSearch();
    }
  }

  return (
    <div className={style.noSearchResultsContainer} onKeyDown={handleKeyboardEvent}>
      <div className={style.noSearchResults}>
        <Text>Nothing found for "{search}"</Text>
        <Text color="secondary">
          There are {projectHiddenResultsBlocksCount} block
          {projectHiddenResultsBlocksCount > 1 ? "s" : ""} and {projectHiddenResultsTextItemsCount} text item
          {projectHiddenResultsTextItemsCount > 1 ? "s" : ""} in this project, but none of them match your search.
        </Text>
        <div className={style.clearSearchContainer}>
          <Button onClick={clearSearch} className={style.buttonWithNoPadding} size="micro" level="secondary">
            Clear search
          </Button>
          <Button onClick={clearSearch} size="micro" level="outline">
            ESC
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoSearchResults;
