import { useHistory } from "react-router-dom";

const INVITE_MODAL_QUERY_PARAM = "inviteModalOpen";

export default function useInviteModal() {
  const history = useHistory();

  function openInviteModal() {
    const url = new URL(window.location.href);
    url.searchParams.set(INVITE_MODAL_QUERY_PARAM, "true");

    history.push({
      pathname: url.pathname,
      search: url.search,
    });
  }

  function closeInviteModal() {
    const url = new URL(window.location.href);
    url.searchParams.delete(INVITE_MODAL_QUERY_PARAM);

    history.push({
      pathname: url.pathname,
      search: url.search,
    });
  }

  /**
   *
   * @returns {boolean} Whether the invite modal was opened or not
   */
  function toggleInviteModal(): boolean {
    const url = new URL(window.location.href);
    const inviteModalOpen = url.searchParams.get(INVITE_MODAL_QUERY_PARAM) === "true";

    if (inviteModalOpen) {
      closeInviteModal();
      return false;
    } else {
      openInviteModal();
      return true;
    }
  }

  return {
    isInviteModalOpen: new URL(window.location.href).searchParams.get(INVITE_MODAL_QUERY_PARAM) === "true",
    openInviteModal,
    closeInviteModal,
    toggleInviteModal,
  };
}
