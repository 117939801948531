import ButtonPrimary from "@/components/button/buttonprimary";
import Comp from "@/components/comp";
import ComponentCreatableInput from "@/components/componentmodal/ComponentCreatableInput";
import { useComponentInput } from "@/components/componentmodal/hooks/useComponentInput";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import BootstrapModal from "react-bootstrap/Modal";
import style from "./style.module.css";

export interface IComponentSelectionModalProps {
  action: string;
  onHide: () => void;
  onSubmit: (selectedCompId: string | null) => void;
  title: string;
  subtitle?: string;
  placeholder: string;
  warning?: string;
  error?: string | null;
}

export default function ComponentSelectionModal(props: IComponentSelectionModalProps) {
  const inputProps = useComponentInput({ isSwap: false, multiSelectedIds: [], isSample: false });
  const {
    searchValue,
    controlledKey,
    selectedComp,
    selectedCompId,
    onCategoryClick,
    folders,
    selectedFolder,
    setSelectedFolder,
    handleChange,
    handleInputChange,
    loadingSelectedCompData,
    options,
    value,
  } = inputProps;

  return (
    <div>
      <BootstrapModal
        show={true}
        dialogClassName={style.dialog}
        backdropClassName={style.backdrop}
        onHide={props.onHide}
        centered
      >
        <BootstrapModal.Header className={style.header}>
          <BootstrapModal.Title className={style.title}>{props.title}</BootstrapModal.Title>
          <CloseIcon className={style.close} onClick={props.onHide} />
        </BootstrapModal.Header>
        <BootstrapModal.Body data-testid="attach-component-modal-body" className={style.body}>
          {props.subtitle && <div className={style.subtitle}>{props.subtitle}</div>}
          <ComponentCreatableInput
            folders={folders}
            selectedFolder={selectedFolder}
            setSelectedFolder={setSelectedFolder}
            searchValue={searchValue}
            controlledKey={controlledKey}
            placeholder={props.placeholder}
            onCategoryClick={onCategoryClick}
            validateAbilityCreateComp={(_value: string) => false}
            handleInputChange={handleInputChange}
            handleChange={handleChange}
            options={options}
            value={value}
          />
          {selectedCompId && (
            <div>
              <div className={style.warning}>{props.warning || ""}</div>
            </div>
          )}
          {selectedComp && (
            <div>
              <Comp
                className={style.selectedComponent}
                component={selectedComp as any} // this is actually a full actualcomponent
                is_selected={false}
                is_suggested={false}
                is_ws_comp={true}
                apiID={"apiID" in selectedComp ? (selectedComp.apiID as string) : null}
                ws_comp_id={selectedCompId}
                compName={selectedComp.name}
                displayApiIds={true}
                showAllStatuses={true}
                selectComp={() => null}
                onClick={() => null}
                setCommentState={() => null}
                setPanelState={() => null}
              />
            </div>
          )}
          {props.error && <div className={style.error}>{props.error}</div>}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className={style.footer}>
          <ButtonPrimary
            text={props.action}
            onClick={() => props.onSubmit(selectedCompId)}
            disabled={loadingSelectedCompData && !selectedCompId}
          />
        </BootstrapModal.Footer>
      </BootstrapModal>
    </div>
  );
}
