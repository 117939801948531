import { DraggableWSComp } from "@/components/DragAndDrop";
import { useWorkspace } from "@/store/workspaceContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import NotesIcon from "@mui/icons-material/Notes";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { ActualComponentInterface } from "@shared/types/TextItem";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { Element } from "react-scroll";
import { useComponentGroupDroppable } from "../../../../components/DragAndDrop/lib";
import Comp from "../../../../components/comp";
import ConfirmationModal from "../../../../components/shared/confirmation-modal";
import { Component } from "../../types";
import CompLibraryBlocks from "../comp-library-blocks";
import style from "./style.module.css";

const masonryBreakpoints = {
  default: 4,
  2250: 3,
  1650: 2,
  1250: 1,
};

type Group = {
  new_group?: boolean;
  group_name: string;
  blocks: { block_name: string; block_text: Component[] }[];
  comment_threads: ActualComponentInterface["comment_threads"];
  other_text: Component[];
};

interface Props {
  index: number;
  group: Group;
  displayApiIds: boolean;
  onComponentClick: () => void;
  selectComp: (input: string | Component) => void;
  isSelected: (compId: string) => boolean;
  handleCreateBlock: (blockName: string) => void;
  handleRenameBlock: (input: { groupName: string; blockNameOld: string; blockNameNew: string }) => void;
  handleDeleteBlock: (dragName: string, compIdsToDelete: string[]) => void;
  query: string;
  setCommentState: (input: { isSelected: boolean; thread_id: string }) => void;
  setPanelState: (updatedState: string) => void;
  updateNewComponentGroup: (name: string) => void;
  updateEmptyComponentGroup: (prevName: string, newName: string) => void;
  allGroupNames: string[];
  updateExistingComponentGroup: (updatedGroup: Group) => void;
  selectedComps: { _id: string; name: string }[];
  hasTagsSelected: boolean;
}
const CompLibraryGroup = ({
  index,
  group,
  displayApiIds,
  onComponentClick,
  selectComp,
  isSelected,
  handleCreateBlock,
  handleRenameBlock,
  handleDeleteBlock,
  query,
  setCommentState,
  setPanelState,
  updateNewComponentGroup,
  updateEmptyComponentGroup,
  allGroupNames,
  updateExistingComponentGroup,
  selectedComps,
  hasTagsSelected,
}: Props) => {
  const defaultGroupName = "Untitled Frame";

  const [newGroupName, setNewGroupName] = useState<string>(group.group_name);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [isNewGroupCreating, setIsNewGroupCreating] = useState<boolean>(false);
  const [isDuplicateName, setIsDuplicateName] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  const { workspaceInfo } = useWorkspace();

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  useEffect(() => {
    if (group) {
      setNewGroupName(group.group_name);
      if (group.group_name === defaultGroupName && group.new_group) {
        setIsRenaming(true);
        setIsNewGroupCreating(true);
      } else {
        setIsRenaming(false);
      }
    }
  }, [group]);

  const checkIfDuplicate = () => {
    return allGroupNames.includes(group.group_name);
  };

  const discardChanges = () => {
    setNewGroupName(group.group_name);
    if (checkIfDuplicate() && group.new_group) {
      setNewGroupName(group.group_name + " 2");
      updateNewComponentGroup(group.group_name + " 2");
      setIsRenaming(false);
    } else {
      setIsRenaming(false);
    }
    setShowConfirmationModal(false);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (showConfirmationModal) {
      return;
    }
    if (allGroupNames.includes(group.group_name) && group.new_group) {
      event.preventDefault();
      event.stopPropagation();

      let newGroupNameAttempt = group.group_name + " 1";
      do {
        if (!isNaN(Number(newGroupNameAttempt.slice(-1)))) {
          const newGroupNameAttemptRoot = newGroupNameAttempt.substring(0, newGroupNameAttempt.length - 1);
          const number = parseInt(newGroupNameAttempt.slice(-1)) + 1;
          newGroupNameAttempt = newGroupNameAttemptRoot + number;
        }
      } while (allGroupNames.includes(newGroupNameAttempt));

      setNewGroupName(newGroupNameAttempt);
      updateNewComponentGroup(newGroupNameAttempt);

      setIsRenaming(false);
      return;
    }
    discardChanges();
  };

  useEffect(() => {
    setIsDuplicateName(allGroupNames.includes(newGroupName) && newGroupName !== group.group_name);
  }, [newGroupName]);

  const attemptSave = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (isDuplicateName) {
      return;
    }
    if (newGroupName.length === 0) return;
    if (newGroupName === group.group_name && group.new_group) {
      if (allGroupNames.includes(newGroupName)) {
        setIsDuplicateName(true);
        return;
      }
      setIsRenaming(false);
      return;
    }

    if (newGroupName === group.group_name && !group.new_group) {
      setIsRenaming(false);
      return;
    }

    if (workspaceInfo.config.components.apiIdGenerateOnRename && group.new_group === undefined) {
      setShowConfirmationModal(true);
    } else {
      saveRename();
    }
  };

  const checkPressEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      attemptSave(event);
    }
  };

  const saveRename = () => {
    if (allGroupNames.includes(newGroupName) && isNewGroupCreating) {
      // Check if we're saving group with default group name
      if (newGroupName === defaultGroupName) {
        // get the count of the groups with the default group name
        const defaultGroupNameCount = allGroupNames.filter((groupName) => groupName === defaultGroupName).length;

        // if there is more than one, set duplicate status
        if (defaultGroupNameCount > 1) {
          setIsDuplicateName(true);
          return;
        }
      } else {
        setIsDuplicateName(true);
        return;
      }
    }
    const groupHasNoComps =
      group.blocks.find((block) => block.block_text.length > 0) === undefined && group.other_text.length === 0;
    if (isNewGroupCreating) {
      updateNewComponentGroup(newGroupName);
    } else if (groupHasNoComps) {
      updateEmptyComponentGroup(group.group_name, newGroupName);
    } else {
      if (group.group_name !== newGroupName) {
        // clone the group with new name
        const newGroup = Object.assign(
          {},
          {
            ...group,
            group_name: newGroupName,
          }
        );
        updateExistingComponentGroup(newGroup);
      }
    }
    setIsDuplicateName(false);
    setIsRenaming(false);
    setShowConfirmationModal(false);
  };

  const createNewBlock = () => {
    window?.analytics?.track("New Block Button in Component Library Clicked");
    handleCreateBlock(group.group_name);
  };

  const handleGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setNewGroupName(newName);
  };

  const { isOver, setNodeRef } = useComponentGroupDroppable(group.group_name);

  return (
    <div
      id={group.group_name}
      className={classnames({
        [style.compgroup]: true,
        [style.isOver]: isOver,
      })}
      ref={setNodeRef}
    >
      <Element name={group.group_name} id={group.group_name}>
        <FrameName
          isRenaming={isRenaming}
          setIsRenaming={setIsRenaming}
          isDuplicateName={isDuplicateName}
          newGroupName={newGroupName}
          isEditEnabled={isEditEnabled}
          handleOnBlur={handleOnBlur}
          handleGroupNameChange={handleGroupNameChange}
          checkPressEnter={checkPressEnter}
          attemptSave={attemptSave}
          isNewGroupCreating={isNewGroupCreating}
          createNewBlock={createNewBlock}
        />
      </Element>
      {group.blocks && group.blocks.length > 0 && (
        <div>
          <CompLibraryBlocks
            blockName={group.group_name}
            displayApiIds={displayApiIds}
            isSelected={isSelected}
            onComponentClick={onComponentClick}
            selectComp={selectComp}
            blocks={group.blocks}
            handleRenameBlock={handleRenameBlock}
            handleDeleteBlock={handleDeleteBlock}
            setCommentState={setCommentState}
            setPanelState={setPanelState}
            selectedComps={selectedComps}
          />
        </div>
      )}
      {group.blocks &&
        group.blocks.length > 0 &&
        ((query.length === 0 && !hasTagsSelected) || group.other_text.length > 0) && (
          <div className={style.compsHeader}>
            <div className={style.numTexts}>
              <NotesIcon className={style.icon} />
              {group.other_text.length} other text item
              {group.other_text.length !== 1 && <span>s</span>}
            </div>
          </div>
        )}

      {group.other_text.length > 0 ? (
        <Masonry
          breakpointCols={masonryBreakpoints}
          className={style.masonryGrid}
          columnClassName={style.masonryGridCol}
        >
          {group.other_text.map(function (component, compIndex) {
            let compName = component.name.split("/")[1];
            if (component.instances.length > 0 && component.instances[0]) {
              component.instances[0].comment_threads = component.comment_threads;
              return (
                <DraggableWSComp
                  id={component._id}
                  key={component._id}
                  name={component.name}
                  selectedComps={selectedComps}
                >
                  <Comp
                    apiID={component.apiID}
                    displayApiIds={displayApiIds}
                    isDraft={component.isDraft}
                    component={component.instances[0]}
                    onClick={onComponentClick}
                    selectComp={() => selectComp(component)}
                    ws_comp_id={component._id}
                    is_selected={isSelected(component._id)}
                    is_ws_comp={true}
                    compName={compName}
                    compType={component.type}
                    setCommentState={setCommentState}
                    setPanelState={setPanelState}
                    is_suggested={false}
                    showAllStatuses
                  />
                </DraggableWSComp>
              );
            }
          })}
        </Masonry>
      ) : (
        <div>
          {query.length === 0 && !hasTagsSelected ? (
            <div className={style.emptyCompsSection}>
              {isNewGroupCreating
                ? "Drag and drop components into empty group."
                : "Currently no components are in blocks."}
            </div>
          ) : (
            <span></span>
          )}
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          title="Are you sure you want to rename this group?"
          body={
            <span>
              Developer mode is turned on, and renaming this group will regenerate the Developer IDs of all components
              inside it according to the rules defined in{" "}
              <a href="/developers/configure-dev-ids" target="_blank">
                your settings
              </a>
              . Are you sure you want to do this?
            </span>
          }
          actionPrimary="Confirm"
          actionSecondary="Cancel"
          onPrimary={saveRename}
          onSecondary={() => {
            setIsRenaming(false);
            setShowConfirmationModal(false);
            setNewGroupName(group.group_name);
          }}
          primaryDisabled={false}
        />
      )}
    </div>
  );
};

const FrameName = ({
  isRenaming,
  setIsRenaming,
  isDuplicateName,
  newGroupName,
  isEditEnabled,
  handleOnBlur,
  handleGroupNameChange,
  checkPressEnter,
  attemptSave,
  isNewGroupCreating,
  createNewBlock,
}: {
  isRenaming: boolean;
  setIsRenaming: (isRenaming: boolean) => void;
  isDuplicateName: boolean;
  newGroupName: string;
  isEditEnabled: boolean;
  handleOnBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleGroupNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkPressEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  attemptSave: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isNewGroupCreating: boolean;
  createNewBlock: () => void;
}) => {
  return (
    <div className={classnames("col-12", style.groupname)}>
      {!isRenaming && (
        <div className={style.frameName}>
          {newGroupName}{" "}
          {isEditEnabled && (
            <EditIcon onClick={() => setIsRenaming(true)} className={classnames([style.edit], [style.icon])} />
          )}
        </div>
      )}
      {isRenaming && (
        <div>
          <div className={style.editGroupName}>
            <input
              autoFocus
              value={newGroupName}
              className={isDuplicateName && style.errorInput}
              onBlur={handleOnBlur}
              onChange={handleGroupNameChange}
              onKeyPress={checkPressEnter}
              placeholder="New Frame Name"
            />
            <DoneIcon
              onMouseDown={attemptSave}
              className={classnames({
                [style.icon]: true,
                [style.disabled]: newGroupName.length == 0,
              })}
            />
          </div>
          {isDuplicateName && (
            <div className={classnames([style.duplicateName])}>This group already exists in your library</div>
          )}
        </div>
      )}

      {isEditEnabled && !isNewGroupCreating && (
        <div className={style.createNewBlock} onClick={() => createNewBlock()}>
          <AddCircleIcon className={style.icon} /> New Block
        </div>
      )}
    </div>
  );
};

export default CompLibraryGroup;
