import { IComponentImportPreviewResponse } from "@shared/types/http/Component";
import logger from "@shared/utils/logger";
import { useEffect, useState } from "react";
import http from "../../../../http";
import { timedPromise } from "../utils";

const importRequest = async ({ file, folderId }) => {
  const body = new FormData();
  body.append("import", file);
  body.append("folderId", folderId);

  try {
    // fetch doesn't properly generate content headers
    // for file uploads with express-fileupload
    const [response] = await Promise.all([
      http.post<IComponentImportPreviewResponse>("/ws_comp/import", body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        // never throw an error because of the status code
        validateStatus: () => true,
      }),
      timedPromise(),
    ]);

    return response.data;
  } catch (error) {
    return {
      ok: false,
      error,
    } as IComponentImportPreviewResponse;
  }
};

export const useImportRequest = ({ file, folderId }) => {
  const [importData, setImportData] = useState<IComponentImportPreviewResponse | null>(null);

  async function fetchImportData() {
    if (!file || file.type === "text/csv") {
      return;
    }

    try {
      const data = await importRequest({ file, folderId });
      setImportData(data);
    } catch (error) {
      logger.error(
        error.message,
        {
          context: { folderId },
        },
        error
      );
      setImportData({ ok: false, error: "An unknown error occurred while importing the file." });
    }
  }

  useEffect(() => {
    if (!file || file.type === "text/csv") {
      return;
    }
    fetchImportData();
  }, [file]);

  return importData;
};
