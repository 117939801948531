import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { AllGroupsSuggestionsResponse } from "../../../../services/setupSuggestions/types";
import { SetupSuggestionsForGroup } from "../../../http/setupSuggestions";
import { Group, TextItem } from "./types";

interface PreviewGroup {
  isSetupPreview: boolean;
  comps: any[];
  blocks: {
    // _id: string;
    name: string;
    comps: TextItem[];
  }[];
}
export interface SetupSuggestionsState {
  setupSuggestions?: SetupSuggestionsForGroup;
  setSetupSuggestions: Dispatch<SetStateAction<SetupSuggestionsForGroup>>;
  setupSuggestionsForAllGroups: AllGroupsSuggestionsResponse;
  setSetupSuggestionsForAllGroups: Dispatch<SetStateAction<AllGroupsSuggestionsResponse>>;
  showSetupSuggestionsPreview: boolean;
  setShowSetupSuggestionsPreview: Dispatch<SetStateAction<boolean>>;
  isShowingSetupSuggestionsFlow: boolean;
  setIsShowingSetupSuggestionsFlow: Dispatch<SetStateAction<boolean>>;
  groupWithSetupSuggestionsApplied?: PreviewGroup;
  noSuggestionsSelected: boolean;
}

const useSetupSuggestionsState = (groups: Group[]): SetupSuggestionsState => {
  const [setupSuggestionsForAllGroups, setSetupSuggestionsForAllGroups] = useState<AllGroupsSuggestionsResponse>({});

  // TODO: Rename this to something like setupSuggestionsForSelectedGroup
  const [setupSuggestions, setSetupSuggestions] = useState<SetupSuggestionsForGroup>({
    groupId: "",
    baseGroupId: "",
    projectId: "",
    blockSuggestions: {},
    hideSuggestions: [],
    hideSuggestionsSelected: true,
    wsComponentSuggestions: [],
  });
  const [showSetupSuggestionsPreview, setShowSetupSuggestionsPreview] = useState<boolean>(true);
  const [isShowingSetupSuggestionsFlow, setIsShowingSetupSuggestionsFlow] = useState(false);

  const groupWithSetupSuggestionsApplied = useMemo(() => {
    if (!setupSuggestions) return;
    const group = groups.find((group) => group._id === setupSuggestions.groupId);
    if (!group) return;
    let allCompsById = group.comps.reduce<Record<string, any>>((acc, curr) => {
      if (!curr._id) return acc;
      acc[curr._id] = curr;
      return acc;
    }, {});

    allCompsById = group.blocks.reduce((acc, curr) => {
      curr.comps.forEach((comp) => {
        if (!comp._id) return;
        acc[comp._id] = comp;
      });
      return acc;
    }, allCompsById);

    const wsCompSuggestionsById = setupSuggestions.wsComponentSuggestions.reduce((acc, wsSuggestion) => {
      acc[wsSuggestion.id] = wsSuggestion;

      return acc;
    }, {} as Record<string, SetupSuggestionsForGroup["wsComponentSuggestions"][number]>);

    // Need to merge duplicate blocks
    const blockSuggestionsByBlockName = Object.values(
      // Need this to prevent mutation of the original object
      structuredClone(setupSuggestions.blockSuggestions)
    )
      .filter((b) => b.selected)
      .reduce((acc, blockSuggestion) => {
        if (acc[blockSuggestion.blockName]) {
          let newBlockName = blockSuggestion.blockName;
          while (acc[newBlockName]) {
            const lastChar = newBlockName.match(/-(\d+)$/);
            if (lastChar) {
              newBlockName = newBlockName.replace(/-(\d+)$/, (_match, number) => {
                return `-${parseInt(number, 10) + 1}`;
              });
            } else {
              newBlockName = `${newBlockName}-1`;
            }
          }

          blockSuggestion.blockName = newBlockName;
          acc[newBlockName] = blockSuggestion;
        } else {
          acc[blockSuggestion.blockName] = blockSuggestion;
        }
        return acc;
      }, {} as Record<string, SetupSuggestionsForGroup["blockSuggestions"][string]>);

    const blocks = Object.values(blockSuggestionsByBlockName).map((blockSuggestion) => {
      return {
        name: blockSuggestion.blockName,
        comps: blockSuggestion.textItems.flatMap((textItemId) => {
          const textItem = allCompsById[textItemId];
          if (!textItem) return [];
          delete allCompsById[textItemId];
          const wsCompSuggestion = wsCompSuggestionsById[textItemId];
          if (wsCompSuggestion && wsCompSuggestion.selected) {
            return {
              ...textItem,
              ws_comp: wsCompSuggestion.wsComponent.wsComp,
              status: wsCompSuggestion.wsComponent.status,
              text: wsCompSuggestion.wsComponent.text,
              rich_text: wsCompSuggestion.wsComponent.rich_text,
              tags: wsCompSuggestion.wsComponent.tags,
              notes: wsCompSuggestion.wsComponent.notes,
              plurals: wsCompSuggestion.wsComponent.plurals,
              is_hidden: false,
            };
          }

          return {
            ...textItem,
            is_hidden: false,
          };
        }),
      };
    });

    if (setupSuggestions.hideSuggestionsSelected) {
      setupSuggestions.hideSuggestions.forEach((hideSuggestion) => {
        const node = allCompsById[hideSuggestion.toString()];
        if (node) {
          allCompsById[hideSuggestion.toString()] = {
            ...node,
            is_hidden: true,
          };
        }
      });
    }

    // Now get the existing suggestions showing

    let index = 0;
    group.blocks.forEach((existingBlock) => {
      const filteredBlockCompsWithWsCompSuggestions: TextItem[] = [];
      existingBlock.comps.forEach((textItem) => {
        const id = textItem._id;
        if (!id) return false;
        const node = allCompsById[id];
        if (!node) return false;
        if (!node.is_hidden) {
          delete allCompsById[id];
          const wsCompSuggestion = wsCompSuggestionsById[id];
          if (wsCompSuggestion && wsCompSuggestion.selected) {
            filteredBlockCompsWithWsCompSuggestions.push({
              ...textItem,
              ws_comp: wsCompSuggestion.wsComponent.wsComp || null,
              status: wsCompSuggestion.wsComponent.status,
              text: wsCompSuggestion.wsComponent.text,
              rich_text: wsCompSuggestion.wsComponent.rich_text,
              tags: wsCompSuggestion.wsComponent.tags,
              notes: wsCompSuggestion.wsComponent.notes,
              plurals: wsCompSuggestion.wsComponent.plurals,
              is_hidden: false,
            });
          } else {
            filteredBlockCompsWithWsCompSuggestions.push(textItem);
          }
        }
      });
      if (filteredBlockCompsWithWsCompSuggestions.length > 0) {
        blocks.splice(index++, 0, {
          ...existingBlock,
          comps: filteredBlockCompsWithWsCompSuggestions,
        });
      }
    });

    group.comps
      .filter((comp) => comp.is_hidden)
      .forEach((hiddenComp) => {
        const id = hiddenComp._id;
        if (!id) return;
        const node = allCompsById[id];
        if (node) {
          allCompsById[id] = {
            ...node,
            is_hidden: true,
          };
        }
      });

    // Apply component suggestions
    Object.entries(allCompsById).forEach(([textItemId, comp]) => {
      const wsCompSuggestion = wsCompSuggestionsById[textItemId];

      if (wsCompSuggestion && wsCompSuggestion.selected) {
        allCompsById[textItemId] = {
          ...comp,
          ws_comp: wsCompSuggestion.wsComponent.wsComp,
          status: wsCompSuggestion.wsComponent.status,
          text: wsCompSuggestion.wsComponent.text,
          rich_text: wsCompSuggestion.wsComponent.rich_text,
          tags: wsCompSuggestion.wsComponent.tags,
          notes: wsCompSuggestion.wsComponent.notes,
          plurals: wsCompSuggestion.wsComponent.plurals,
        };
      }
    });

    return {
      ...group,
      isSetupPreview: true,
      comps: Object.values(allCompsById),
      blocks,
    };
  }, [showSetupSuggestionsPreview, isShowingSetupSuggestionsFlow, setupSuggestions, groups]);

  const noSuggestionsSelected = useMemo<boolean>(() => {
    if (!setupSuggestions) {
      return true;
    }

    const hiddenSuggestionsNotSelected = !setupSuggestions.hideSuggestionsSelected;
    const allBlockSuggestionsNotSelected = Object.values(setupSuggestions.blockSuggestions).every((b) => !b.selected);
    const allWsCompSuggestionsNotSelected = setupSuggestions.wsComponentSuggestions.every((w) => !w.selected);
    const allNotSelected =
      hiddenSuggestionsNotSelected && allBlockSuggestionsNotSelected && allWsCompSuggestionsNotSelected;

    return allNotSelected;
  }, [setupSuggestions]);

  useEffect(
    function togglePreviewsOnSelectState() {
      if (!setupSuggestions) {
        return;
      }

      if (showSetupSuggestionsPreview && noSuggestionsSelected) {
        setShowSetupSuggestionsPreview(false);
      }
    },
    [noSuggestionsSelected]
  );

  useEffect(
    function turnOnPreviewAtStart() {
      if (isShowingSetupSuggestionsFlow && !showSetupSuggestionsPreview) {
        setShowSetupSuggestionsPreview(true);
      }
    },
    [isShowingSetupSuggestionsFlow]
  );

  return {
    setupSuggestions,
    setSetupSuggestions,
    setupSuggestionsForAllGroups,
    setSetupSuggestionsForAllGroups,
    showSetupSuggestionsPreview,
    setShowSetupSuggestionsPreview,
    isShowingSetupSuggestionsFlow,
    setIsShowingSetupSuggestionsFlow,
    groupWithSetupSuggestionsApplied,
    noSuggestionsSelected,
  };
};

export default useSetupSuggestionsState;
