import { useWorkspace } from "@/store/workspaceContext";
import merge from "lodash/merge";
import { useEffect, useState } from "react";
import { userHasSomeResourceAccess } from "../../../../../shared/frontend/userPermissionContext";
import {
  IFComponentsApiIdGenerationConfig,
  IFProjectsApiIdGenerationConfig,
} from "../../../../../shared/types/Workspace";
import { UpdateWorkspaceConfigRequestBody } from "../../../../../shared/types/http/workspace";
import useDebouncedCallback from "../../../../../util/useDebouncedCallback";
import * as httpWorkspace from "../../../../http/workspaceNew";

export function useConfigureDevIDsTab() {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const [showComponentConfigModal, setShowComponentConfigModal] = useState(false);
  const [showProjectConfigModal, setShowProjectConfigModal] = useState(false);

  const canEditComponentsConfig = userHasSomeResourceAccess("component_folder", "edit");
  const canEditProjectsConfig = userHasSomeResourceAccess("project_folder", "edit");
  const componentApiIdGenerationConfig = workspaceInfo?.config?.components?.apiIdGeneration;
  const projectApiIdGenerationConfig = workspaceInfo?.config?.projects?.apiIdGeneration;
  const devToolsEnabled = workspaceInfo?.devTools;

  function applyWorkspaceConfigUpdate(update: UpdateWorkspaceConfigRequestBody["config"]) {
    httpWorkspace.updateWorkspaceConfig(update);
    updateWorkspaceInfo((info) => {
      return {
        ...info,
        // using `merge` here becomes problematic if we introduce an array field into
        // the config, but otherwise is fine
        config: merge(info.config, update),
      };
    });
  }

  const onEditComponentsConfigClick = () => {
    if (!canEditComponentsConfig) return;
    setShowComponentConfigModal(true);
  };

  const onEditProjectsConfigClick = () => {
    if (!canEditProjectsConfig) return;
    setShowProjectConfigModal(true);
  };

  const onConfigModalSave =
    (configType: "projects" | "components") =>
    async (
      config: IFComponentsApiIdGenerationConfig | IFProjectsApiIdGenerationConfig,
      configHasChanged: boolean,
      updateExistingIds: boolean
    ) => {
      const apiIdGeneration = configHasChanged ? config : undefined;

      await httpWorkspace.updateWorkspaceConfig({ [configType]: { apiIdGeneration } }, updateExistingIds);

      updateWorkspaceInfo((info) => ({
        ...info,
        config: {
          ...info.config,
          [configType]: {
            ...info.config[configType],
            apiIdGeneration: config,
          },
        },
      }));

      setShowComponentConfigModal(false);
    };

  const [componentApiIdGenerateOnRename, onChangeComponentApiIdGenerateOnRename] = useToggleState({
    defaultValue: workspaceInfo?.config?.components?.apiIdGenerateOnRename ?? false,
    canEdit: canEditComponentsConfig,
    onChange: (value) =>
      applyWorkspaceConfigUpdate({
        components: {
          apiIdGenerateOnRename: value,
        },
      }),
  });

  const [componentApiIdPreventManualEdits, onChangeComponentApiIdPreventManualEdits] = useToggleState({
    defaultValue: workspaceInfo?.config?.components?.apiIdPreventManualEdits ?? false,
    canEdit: canEditComponentsConfig,
    onChange: (value) =>
      applyWorkspaceConfigUpdate({
        components: {
          apiIdPreventManualEdits: value,
        },
      }),
  });

  const [projectApiIdOptOutHumanReadable, onChangeProjectApiIdOptOutHumanReadable] = useToggleState({
    defaultValue: workspaceInfo?.config?.projects?.apiIdGeneration.optOutHumanReadable ?? false,
    canEdit: canEditProjectsConfig,
    onChange: (value) =>
      applyWorkspaceConfigUpdate({
        projects: {
          apiIdGeneration: {
            optOutHumanReadable: value,
          },
        },
      }),
  });

  const [projectApiIdUpdateIdsWhenGroupBlockChange, onChangeProjectApiIdUpdateIdsWhenGroupBlockChange] = useToggleState(
    {
      defaultValue: workspaceInfo?.config?.projects?.apiIdUpdateIdsWhenGroupBlockChange ?? false,
      canEdit: canEditProjectsConfig,
      onChange: (value) =>
        applyWorkspaceConfigUpdate({
          projects: {
            apiIdUpdateIdsWhenGroupBlockChange: value,
          },
        }),
    }
  );

  const [projectApiIdPreventManualEdits, onChangeProjectApiIdPreventManualEdits] = useToggleState({
    defaultValue: workspaceInfo?.config?.projects?.apiIdPreventManualEdits ?? false,
    canEdit: canEditProjectsConfig,
    onChange: (value) =>
      applyWorkspaceConfigUpdate({
        projects: {
          apiIdPreventManualEdits: value,
        },
      }),
  });

  return {
    componentApiIdGenerateOnRename,
    onChangeComponentApiIdGenerateOnRename,
    componentApiIdPreventManualEdits,
    onChangeComponentApiIdPreventManualEdits,
    projectApiIdOptOutHumanReadable,
    onChangeProjectApiIdOptOutHumanReadable,
    projectApiIdUpdateIdsWhenGroupBlockChange,
    onChangeProjectApiIdUpdateIdsWhenGroupBlockChange,
    projectApiIdPreventManualEdits,
    onChangeProjectApiIdPreventManualEdits,
    showComponentConfigModal,
    showProjectConfigModal,
    onEditComponentsConfigClick,
    onEditProjectsConfigClick,
    onConfigModalSave,
    componentApiIdGenerationConfig,
    projectApiIdGenerationConfig,
    canEditComponentsConfig,
    canEditProjectsConfig,
    devToolsEnabled,
    setShowComponentConfigModal,
    setShowProjectConfigModal,
  };
}

function useToggleState(args: { defaultValue: boolean; canEdit: boolean; onChange: (value: boolean) => void }) {
  const { defaultValue, canEdit } = args;

  const [value, setValue] = useState(defaultValue);
  useEffect(() => setValue(defaultValue), [defaultValue, setValue]);

  const persistValue = useDebouncedCallback((value: boolean) => args.onChange(value), 500);

  const onChange = () => {
    if (!canEdit) return;
    const updatedValue = !value;
    setValue(updatedValue);
    return persistValue(updatedValue);
  };

  return [value, onChange] as const;
}
