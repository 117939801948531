export const SYNC_SETTINGS_SPECIAL_FOLDER_IDS = {
  ALL_COMPONENTS: "__ALL__",
  ROOT: "__ROOT__",
  LOADING: "__LOADING__",
};

export const serializeFolderId = (folderId: string) => {
  if (folderId === SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ALL_COMPONENTS) {
    return undefined;
  }

  if (folderId === SYNC_SETTINGS_SPECIAL_FOLDER_IDS.ROOT) {
    return null;
  }

  return folderId;
};
