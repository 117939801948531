import classnames from "classnames";
import React, { useState } from "react";
import Toast from "react-bootstrap/Toast";
import { Link } from "react-router-dom";
import style from "./style.module.css";

export interface ToastNotification {
  type: string;
  title?: string;
  message: string;
  link?: {
    page: string;
    text: string;
  };
  time?: number;
}

const Notification = ({
  notification,
  noPadding,
  className,
}: {
  notification: ToastNotification;
  noPadding?: boolean;
  className?: string;
}) => {
  const [show, setShow] = useState(true);
  const isSuccess = notification.type === "success";
  return (
    <Toast
      show={show}
      onClose={() => setShow(false)}
      className={classnames(
        {
          [style.notification_no_padding]: noPadding,
          [style.notification]: !noPadding,
          [style.success]: isSuccess,
          [style.error]: !isSuccess,
        },
        className
      )}
    >
      {notification.message}
      {notification.link && (
        <span>
          <Link to={notification.link.page}>{notification.link.text}</Link>.
        </span>
      )}
    </Toast>
  );
};

export default Notification;
