import { removeFigmaArtifactsFromGroupName } from "@shared/lib/groups";
import { IFApiIdCasingAdjustment, IFProjectsApiIdGenerationConfig } from "@shared/types/Workspace";

import { removeDiacritics } from "../removeDiacritics";
import { DEFAULT_PROJECTS_CONFIG, camelCaseString } from "./lib";
import { createApiIdGenerator, getApplyAcceptedCharsFunction } from "./nameToApiId";

const getApplyCasingAdjustmentTextItemFunction = (
  adjustment: IFApiIdCasingAdjustment | null
): ((value: string, isCamelCaseDelimiter: (c: string, i: number) => boolean) => string) => {
  if (!adjustment) {
    return (v) => v;
  }

  if (adjustment === "lower") {
    return (v) => v.toLocaleLowerCase();
  }

  if (adjustment === "upper") {
    return (v) => v.toLocaleUpperCase();
  }

  return (v, isCamelCaseDelimiter) => {
    if (!v?.length) return v;
    return camelCaseString(v, isCamelCaseDelimiter, adjustment === "pascal");
  };
};

const DEFAULT_PROJECT_API_ID_MAX_LENGTH = 60;

export interface TextItemMetadata {
  _id: string;
  text: string;
  pageName?: string | null;
  projectName?: string | null;
  groupName?: string | null;
  blockName?: string | null;
}

type TextItemMetadataProperty = keyof TextItemMetadata;

/**
 * A map of standarizd transformations to apply to each supported text item metadata property.
 */
export const textItemMetadataProcessors: {
  [key in keyof TextItemMetadata]: (value: TextItemMetadata[key]) => string;
} = {
  _id: (value) => value.toString(),
  text: (value) => value,
  pageName: (value) => value ?? "",
  projectName: (value) => value ?? "",
  groupName: (value) => removeFigmaArtifactsFromGroupName(value) ?? "",
  blockName: (value) => value ?? "",
};

type textItemNameGenerator = (data: TextItemMetadata) => string;

/**
 * Creates a function for generating a text item's "name", which is a string representation of its text and metadata.
 * @param template template string of the form `{{groupName}}{{blockName}}{{text}}`
 * @param separator used to join each part of the template string into the final text item name
 */
const createTextItemNameGenerator = (config: IFProjectsApiIdGenerationConfig): textItemNameGenerator => {
  // if the user has opted out of human readable API IDs, we should always generate an empty string
  // for the text item names, as this will force a fallback ID to _always_ be generated
  // instead of using the a name that is derived from the text item's metadata
  if (config.optOutHumanReadable) {
    return () => "";
  }

  const { template, separator, acceptedCharsPattern } = config;
  const templateParts = template
    ? template
        .replace(/\s/g, "") // remove whitespace
        .split(/\{\{(.*?)\}\}/) // create an array of all the parts between {{}}
        .filter(Boolean) // remove empty strings
    : ["groupName", "blockName", "text"];

  const validateTemplateParts = (parts: string[]): parts is TextItemMetadataProperty[] =>
    parts.every((p) => p in textItemMetadataProcessors);

  if (!validateTemplateParts(templateParts)) {
    throw new Error(`Invalid template: ${template}`);
  }

  const applyAcceptedCharsFn = getApplyAcceptedCharsFunction(
    acceptedCharsPattern,
    // we need to allow whitespace to remain in the name to be replaced with the space replacement char
    // as part of the API ID generation process
    ["\\s"]
  );

  const applyCasingAdjustmentFn = getApplyCasingAdjustmentTextItemFunction(config.casingAdjustment);

  return (data) => {
    let parts = templateParts
      .map((part) => {
        const valueRaw = data[part] ?? "";
        const valueProcessor = textItemMetadataProcessors[part];
        const value = valueProcessor ? valueProcessor(valueRaw) : valueRaw;
        const valueDiacriticsReplaced = removeDiacritics(value);
        return applyAcceptedCharsFn(valueDiacriticsReplaced).trim();
      })
      .filter(Boolean);

    // if there are no parts, return an empty string for the name
    // so that the generator can generate a fallback ID instead of
    // joining the empty parts using the separator
    if (!parts.length) {
      return "";
    }

    // if doing a camelCase casing adjustment with an empty separator,
    // compute the camelCase boundaries using the length of each of the parts
    if (config.separator === "" && (config.casingAdjustment === "camel" || config.casingAdjustment === "pascal")) {
      const camelCaseIndices = new Set();

      let runningLength = 0;
      parts.forEach((p) => {
        runningLength += p.length;
        camelCaseIndices.add(runningLength - 1);
      });

      return applyCasingAdjustmentFn(parts.join(separator ?? ""), (c, i) => c === " " || camelCaseIndices.has(i));
    }

    // if doing any other type of casing adjustment or if a separator exists,
    // apply the casing adjustment on a per-part basis before joining
    return parts.map((p) => applyCasingAdjustmentFn(p, (c) => c === " ")).join(separator ?? "");
  };
};

interface createTextItemApiIdGeneratorArgs {
  config?: IFProjectsApiIdGenerationConfig;
  initialApiIds?: string[];
}

export const createTextItemApiIdGenerator = (
  { config = DEFAULT_PROJECTS_CONFIG, initialApiIds = [] }: createTextItemApiIdGeneratorArgs = {
    config: DEFAULT_PROJECTS_CONFIG,
    initialApiIds: [],
  }
) => {
  const generateTextItemName = createTextItemNameGenerator(config);
  const generator = createApiIdGenerator({
    config: {
      casingAdjustment: config.casingAdjustment,
      acceptedCharsPattern: config.acceptedCharsPattern,
      spaceReplacement: config.spaceReplacement,
      maxLength: config.maxLength ?? DEFAULT_PROJECT_API_ID_MAX_LENGTH,
    },

    // both of these are special cases for text item api id generation
    // that are handled within `createTextItemNameGenerator`
    skipSteps: new Set(["acceptedChars", "casingAdjustment"]),

    initialApiIds,
  });

  return {
    /**
     * @param apiId the API ID to add to the generator's internal set of API IDs for deduping against.
     */
    add: (apiId: string) => {
      generator.add(apiId);
    },
    /**
     * Generates an API ID and adds it to the generator's internal set of API IDs for deduping against.
     * @param name
     * @param prefix
     * @returns
     */
    generate: (data: TextItemMetadata) => {
      const name = generateTextItemName(data);
      return generator.generate(name, "text_", data._id);
    },
  };
};
