import z from "zod";

export const ACTIONS = [
  "improve-writing",
  "shorten",
  "extend",
  "spelling-and-grammar",
  "tone-friendly",
  "tone-formal",
  "tone-assertive",
  "tone-excited",
] as const;

export type Action = (typeof ACTIONS)[number];

export const actionToName: Record<Action, string> = {
  "improve-writing": "Improve Writing",
  shorten: "Shorten",
  extend: "Extend",
  "spelling-and-grammar": "Spelling & Grammar",
  "tone-friendly": "Friendly",
  "tone-formal": "Formal",
  "tone-assertive": "Assertive",
  "tone-excited": "Excited",
};

export const ZPerformAIPost = z.object({
  action: z.enum(ACTIONS),
  text: z.string(),
});
