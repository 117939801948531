import React, { useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import http, { API } from "../../http";
import { ComponentCategories } from "../ComponentSuggestions/CreateComponentModal";
import style from "./CategoryDropdownMenu.module.css";

interface Props {
  showCategoryMenu: boolean;
  categories: string[];
  offsetWidth: number;
  onCategorySelect: (category: string) => void;
  categoryType: "Groups" | "Blocks" | "";
}
/**
 * Renders a dropdown list of component categories relative to an input.
 * ---
 * Usage example:
 *
 * const [name, setName] = useState("");
 * const state = useCategoryDropdownMenu({ name, setName });
 *
 * return (
 *  <div>
 *    <input
 *      value={name}
 *      onChange={(e) => setName(e.target.value)}
 *      ref={state.inputRef}
 *    />
 *    <CategoryDropdownMenu {...state} />
 *  </div>
 * )
 */
const CategoryDropdownMenu = (props: Props) => {
  const { showCategoryMenu, categories, offsetWidth, onCategorySelect, categoryType } = props;

  return (
    <Dropdown className={style.categoryMenu} show={showCategoryMenu} style={{ left: `${offsetWidth}px` }}>
      <Dropdown.Toggle className={style.customToggle}>test</Dropdown.Toggle>
      <Dropdown.Menu className={style.menuList} show>
        {categoryType && <div className={style.menuHeader}>{categoryType}</div>}
        <div className={style.wrapper}>
          {categories.map((category) => {
            return (
              <Dropdown.Item className={style.menuItem} key={category} onClick={() => onCategorySelect(category)}>
                {category}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CategoryDropdownMenu;

export const useCategoryDropdownMenu = (props: {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [offsetWidth, setOffsetWidth] = useState<number>(0);
  const [componentCategories, setComponentCategories] = useState<ComponentCategories>({});
  const [showCategories, setShowCategories] = useState(false);

  const fetchComponentCategories = async () => {
    const { url } = API.ws_comp.get.categories;
    const { data } = await http.get(url);
    return data;
  };

  // if we haven't typed any slashes yet, this should be all the groups in
  // our Component Library; if we've typed a valid group followed by a slash,
  // this should be all the blocks in that group.
  const categoriesToUse = useMemo(() => {
    const result = props.name.split("/");
    const categories = result.slice(0, -1);
    const componentName = result.slice(-1)[0].toLowerCase();

    // recurse down into the componentCategories map to find the categories we
    // want to display
    let currentCategoryMap = componentCategories;
    let categoryDepth = 0;
    categories.forEach((category) => {
      // if the last category didn't exist, don't keep going
      if (!currentCategoryMap) return;
      categoryDepth++;
      currentCategoryMap = currentCategoryMap[category];
    });

    return {
      depth: categoryDepth,
      categories: Object.keys(currentCategoryMap || {}).filter((category) =>
        category.toLowerCase().includes(componentName)
      ),
    };
  }, [props.name, componentCategories]);

  const onCategorySelect = (category: string) => {
    props.setName((prev) => {
      // want to remove any text to the left of the previous category
      // and replace it with the selected category
      const currentCategoriers = prev.split("/").slice(0, -1);
      if (currentCategoriers.length === 0) {
        return `${category}/`;
      }
      return `${currentCategoriers.join("/")}/${category}/`;
    });
    updateCategoryMenuOffsetWidth();
    setShowCategories(false);
    inputRef.current?.focus();
  };

  const updateCategoryMenuOffsetWidth = () => {
    setTimeout(() => {
      const OFFSET_WIDTH = 60;
      const categoryTextLength = document.getElementById("hidden-input-value")?.offsetWidth || 0;
      const inputWidth = Math.max((inputRef?.current?.offsetWidth || 0) - OFFSET_WIDTH, 0);
      setOffsetWidth(Math.min(categoryTextLength, inputWidth));
    }, 0);
  };

  useEffect(function updatePreviewDimensions() {
    window.addEventListener("resize", updateCategoryMenuOffsetWidth);
    return () => {
      window.removeEventListener("resize", updateCategoryMenuOffsetWidth);
    };
  }, []);

  useEffect(function mountFocusListener() {
    const focusListener = (e: FocusEvent) => {
      setShowCategories(true);
    };

    inputRef.current?.addEventListener("focus", focusListener);
    return () => {
      inputRef.current?.removeEventListener("focus", focusListener);
    };
  }, []);

  useEffect(function initializeComponentCategories() {
    async function loadComponentCategories() {
      const data = await fetchComponentCategories();
      setComponentCategories(data);
    }
    loadComponentCategories();
  }, []);

  let categoryType: "Groups" | "Blocks" | "" = "";
  if (categoriesToUse.depth === 0) {
    categoryType = "Groups";
  } else if (categoriesToUse.depth === 1) {
    categoryType = "Blocks";
  }

  const showCategoryMenu = categoriesToUse.categories.length > 0 && showCategories;
  const categories = categoriesToUse.categories;

  const categoryDisplay = useMemo(() => props.name.split("/").slice(0, -1).join("/"), [props.name]);

  return {
    inputRef,
    showCategoryMenu,
    categories,
    categoryDisplay,
    offsetWidth,
    onCategorySelect,
    categoryType,
    updateCategoryMenuOffsetWidth,
  };
};
