import React from "react";

export function BlockIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="4.25" width="12" height="3" rx="1.25" fill="#737373" />
      <rect x="2" y="8.75" width="12" height="3" rx="1.25" fill="#737373" />
    </svg>
  );
}
