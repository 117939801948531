import { IFWebhookLog } from "@shared/types/WebhookLog";
import { createHttpRequest } from "./lib/createHttpRequest";

export const fetchWebhookLogs = createHttpRequest<{ webhookId: string }, IFWebhookLog[]>({
  method: "get",
  url: "/webhook-logs",
  getConfig: (data) => ({
    params: {
      webhookId: data.webhookId,
    },
  }),
});

export const exportWebhookLogsCsv = createHttpRequest<{ webhookId: string }, string>({
  method: "get",
  url: "/webhook-logs",
  getConfig: (data) => ({
    params: {
      webhookId: data.webhookId,
      format: "csv",
    },
  }),
});
