/**
 * Use the CSS tab above to style your Element's container.
 */
import { CardElement } from "@stripe/react-stripe-js";
import React from "react";
import style from "./style.module.css";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontSize: "16px",
      "::placeholder": {
        color: "#CED0D5",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {
  return (
    <div>
      <h4>Card Details</h4>
      <div className={style.cardInput}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
    </div>
  );
}

export default CardSection;
