import { useMemo } from "react";
import { computeComponentData } from "../doc/multiselect";

export const useSectionVisibility = ({ multiSelectedComps }) =>
  useMemo(() => {
    const components = computeComponentData(multiSelectedComps);

    const visibility = {
      showMultiSelectedEditsApplyToBase: components.includeNonBaseVariant,
      showSelectedText: !components.includesWsComp,
      showAttachToSelectedComponent: !(components.includeHidden || components.includesWsComp),

      showCreateBlockFromSelected: components.allSameFrame && !(components.includeBlock || components.includeHidden),

      showUnhideSelectedTextFromView:
        components.includeHidden &&
        !components.includeBlock &&
        !components.includesWsComp &&
        !components.includeVariant,

      showHideSelectedTextFromView:
        components.allVisible && !components.includeBlock && !components.includesWsComp && !components.includeVariant,

      showEditingUnavailable: showEditingUnavailable(multiSelectedComps),

      showVariablesPanel: true,
    };

    return components.includeNonBaseVariant
      ? setValuesToFalse(visibility, {
          exclude: ["showEditComponent", "showMultiSelectedEditsApplyToBase"],
        })
      : visibility;
  }, [multiSelectedComps]);

const showEditingUnavailable = (multiSelectedComps) => {
  let hasHidden = false;
  let hasVisible = false;

  multiSelectedComps.forEach((comp) => {
    if (comp.is_hidden) {
      hasHidden = true;
    } else {
      hasVisible = true;
    }
  });

  return hasHidden && hasVisible;
};

const setValuesToFalse = (obj, { exclude = [] } = { exclude: [] }) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: exclude.includes(key) ? obj[key] : false,
    }),
    {}
  );
