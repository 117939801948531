import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import classNames from "classnames";
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import style from "./style.module.css";

export const UploadLoader = () => {
  return (
    <div className={style.uploadLoaderContainer}>
      <Spinner animation="border" role="status" className={style.uploadLoaderSpinner} />
      <ArrowUpwardIcon className={classNames([style.icon, style.uploadLoaderIcon])} />
    </div>
  );
};
