import { PrimitiveAtom, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";

/**
 * Given an atom, return a ref connected to that atom.
 */
export function useAtomRef<T extends HTMLElement>(atom: PrimitiveAtom<T | null>) {
  const ref = useRef<T>(null);
  const setAtom = useSetAtom(atom);

  useEffect(() => {
    if (ref.current) {
      setAtom(ref.current);
    }
  }, [ref.current, setAtom]);

  return ref;
}
