import {
  activityLoadingAtom,
  fetchNextActivityPageActionAtom,
  hasMoreActivityAtom,
  projectActivityAtom,
} from "@/stores/Activity";
import Scrollbar from "@ds/molecules/Scrollbar";
import ChangeItemList from "@ds/organisms/ChangeItemList";
import { useAtomValue, useSetAtom } from "jotai";
import ProjectActivityItem from "./ProjectActivityItem";
import style from "./style.module.css";

function ActivityPanel() {
  const allActivity = useAtomValue(projectActivityAtom);
  const activityLoading = useAtomValue(activityLoadingAtom);
  const hasMoreActivity = useAtomValue(hasMoreActivityAtom);
  const fetchNextActivityPageAction = useSetAtom(fetchNextActivityPageActionAtom);

  return (
    <Scrollbar className={style.scrollArea}>
      <ChangeItemList
        changeItems={allActivity}
        ChangeItemComponent={ProjectActivityItem}
        hasMore={hasMoreActivity}
        isLoadingMore={activityLoading}
        fetchMore={fetchNextActivityPageAction}
      />
    </Scrollbar>
  );
}

export default ActivityPanel;
