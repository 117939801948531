import React, { useEffect, useState } from "react";

import VariantSelectInput from "../shared/VariantSelectInput";

import ExportBetaTag from "./ExportBetaTag";
import ExportModalLabel from "./ExportModalLabel";
import ExportPreview from "./ExportPreview";
import { useExportData } from "./lib";

import DeveloperModeDisabled from "./DeveloperModeDisabled";

import style from "./style.module.css";

interface Props {
  type: "iosStrings" | "iosStringsdict";
  projectId: string;
  storeVariantName: (name: string) => void;
  storeExportDataReference: (exportType: string, data: string) => void;
  developerModeEnabled: boolean;
  componentFolderId: string | null;
}

const PaneliOS = (props: Props) => {
  const { type, projectId, storeExportDataReference, storeVariantName, developerModeEnabled, componentFolderId } =
    props;

  const [selectedVariant, setSelectedVariant] = useState({
    id: "base",
    name: "Base",
  });

  const onVariantSelect = (variant: { id: string; name: string }) => {
    setSelectedVariant(variant);
    storeVariantName(variant.name);
  };

  const [content, { loading }] = useExportData(type, {
    selectedVariant,
    projectId,
    disabled: !developerModeEnabled,
    componentFolderId,
  });

  // Store a reference to the export data in the parent's state
  // any time it changes so that it can be used when the "Export"
  // button is clicked in the modal.
  useEffect(
    function storeReferenceToExportData() {
      if (!content) {
        return;
      }

      storeExportDataReference(type, content);
    },
    [content, type]
  );

  return (
    <div className={style.exportPanel}>
      <ExportBetaTag />
      <ExportModalLabel
        title="Variant"
        tooltip={{
          id: "variant-select",
          text: "Learn more about variants",
          onClick: () => window.open("https://www.dittowords.com/docs/variants", "_blank"),
        }}
      />
      <VariantSelectInput
        disableCreateNew
        isClearable={false}
        placeholder="Select or search for a variant..."
        onSelect={onVariantSelect}
        projectId={projectId}
        value={selectedVariant}
        options={[{ label: "Base", value: "base" }]}
        appendToPassedOptions
      />
      <ExportPreview
        content={content}
        loading={loading}
        className={style.exportPreviewContainer}
        disabled={!developerModeEnabled}
        language={getLanguage(type)}
      />
      {!developerModeEnabled && <DeveloperModeDisabled />}
    </div>
  );
};

export default PaneliOS;

function getLanguage(type: string) {
  if (type === "iosStrings") {
    return "actionscript";
  }

  if (type === "iosStringsdict") {
    return "xml";
  }

  throw new Error("Invalid iOS type");
}
