import { z } from "zod";
import { ZDittoProjectBlock } from "./DittoProject";
import { IObjectId, ZDate, ZObjectId } from "./lib";
import {
  ZActualComponentVariableSchema,
  ZPluralForm,
  ZPluralSchema,
  ZTextItem,
  ZTextItemStatus,
  ZTipTapRichText,
} from "./TextItem";

export const VARIABLE_ACTUAL_CHANGE_TYPES = {
  VARIABLE_CREATION: "variable-creation",
  VARIABLE_UPDATE: "variable-update",
  VARIABLE_DELETION: "variable-deletion",
  VARIABLE_NAME_UPDATE: "variable-name-update",
  VARIABLE_FOLDER_CREATION: "variable-folder-creation",
  VARIABLE_FOLDER_DELETION: "variable-folder-deletion",
  VARIABLE_FOLDER_UPDATE: "variable-folder-update",
  VARIABLE_MOVED_TO_FOLDER_IN_BULK_OPERATION: "variable-move-to-folder-in-bulk-operation",
  VARIABLE_MOVED_TO_FOLDER: "variable-move-to-folder",
} as const;

export const PLURAL_CHANGE_TYPES = {
  PLURAL_ADDED: "plural-added",
  PLURAL_REMOVED: "plural-removed",
  PLURAL_EDITED: "plurals-edited",
} as const;

export const BRANCH_CHANGE_TYPES = {
  BRANCH_UPDATED: "branch-updated",
  BRANCH_ARCHIVED: "branch-archived",
  BRANCH_MERGED: "branch-merged",
  BRANCH_DISCARDED: "branch-discarded",
} as const;

export const DRAFTING_CHANGE_TYPES = {
  CONNECT_PROJECT: "connect-project",
  DRAFT_GROUP_CREATED: "draft-group-created",
  GROUP_CONNECTED: "group-connected",
  GROUP_UNLINKED: "group-unlinked",
  GROUP_DELETED: "group-deleted",
  GROUP_LINKING_ENABLED: "group-linking-enabled",
  GROUP_REVERTED_TO_DRAFT: "group-reverted-to-draft",
  ADD_TEXT_ITEM_TO_DRAFT_GROUP: "add-text-item-to-draft-group",
  DELETE_TEXT_ITEM_FROM_DRAFT_GROUP: "delete-text-item-from-draft-group",
} as const;

export const AI_USAGE_TYPES = {
  AUTOMATED_COMPONENT_NAMING: "automated-component-naming",
  AI_EDITOR: "ai-editor",
} as const;

export const SYNC_CONFLICT_TYPES = {
  SYNC_CONFLICT_RESOLVED: "sync-conflict-resolved",
} as const;

export const ZComponentCreationType = z.enum([
  "draft-modal",
  "figma-variable",
  "bulk-import",
  "bulk-import-csv",
  "bulk-import-api",
  "text-item",
  "create-suggestion",
]);
export type ComponentCreationType = z.infer<typeof ZComponentCreationType>;

export const getDittoComponentIdByComponentCreationType = (type: ComponentCreationType) => {
  if (type === "figma-variable") {
    return "change-items.component-creation.via-figma-variables";
  }

  if (type === "bulk-import") {
    return "change-items.component-creation.via-string-import";
  }

  if (type === "bulk-import-csv") {
    return "change-items.component-creation.via-csv-import";
  }

  if (type === "bulk-import-api") {
    return "change-items.component-creation.via-api-import";
  }

  return "change-items.component-creation.default";
};

/**
 * These entry types are used in North Star.
 * They may or may not also be used in the legacy system.
 * Add new types here if they relate to North Star.
 */
const GENERAL_NORTH_STAR_ENTRY_TYPES = {
  DITTO_PROJECT_CREATED: "ditto-project-created",
  DITTO_PROJECT_RENAMED: "ditto-project-renamed",
  DITTO_PROJECT_MOVED_TO_FOLDER: "ditto-project-moved-to-folder",
  DITTO_BLOCK_CREATED: "ditto-blocks-created",
  DITTO_BLOCK_UPDATED: "ditto-block-updated",
  DITTO_BLOCK_DELETED: "ditto-block-deleted",
  TEXT_ITEM_CREATED: "text-item-created",
  TEXT_ITEM_DELETED: "text-item-deleted",
  EDIT: "edit",
  STATUS: "status",
  DUPES_STATUS: "dupes-status",
  COMP_ASSIGNED: "comp-assigned",
  MULTI_COMP_ASSIGNED: "multi-comp-assigned",
  TEXT_ITEM_CHARACTER_LIMIT_UPDATE: "text-item-character-limit-update",
  POST_COMMENT: "post-comment",
  POST_REPLY: "post-reply",
  SYNC_CONFLICT_RESOLVED: "sync-conflict-resolved",
} as const;

export const NORTH_STAR_ENTRY_TYPES = {
  ...GENERAL_NORTH_STAR_ENTRY_TYPES,
  ...PLURAL_CHANGE_TYPES,
} as const;

/**
 * These entry types are only used in the legacy system.
 * Any types that remain in here at the end of North Star implementation can be deleted.
 */
const GENERAL_LEGACY_ENTRY_TYPES = {
  DELETE: "delete",
  IMPORT: "import",
  DUPES_EDIT: "dupes-edit",
  EDIT_FROM_SUGGESTION: "edit-from-suggestion",
  HIDE_COMP: "hide-comp",
  UNHIDE_COMP: "unhide-comp",
  MULTI_HIDE: "multi-hide-comp",
  MULTI_UNHIDE: "multi-unhide-comp",
  GROUP_RENAMED: "group-renamed",
  FRAME_ADDED: "frame-added",
  FRAME_REMOVED: "frame-removed",
  DEV_MODE_ON: "dev-mode-on",
  BLOCK_CREATED: "block-created",
  BLOCKS_CREATED: "blocks-created",
  BLOCK_APIID_EDIT: "block-apiID-edit",
  WS_APIID_EDIT: "ws-apiID-edit",
  // edits done in bulk should not show up at the comp library level,
  // but should show up for individual components
  WS_APIID_EDIT_BULK: "ws-apiID-edit-bulk",
  TEXT_APIID_EDIT: "text-apiID-edit",
  FRAME_APIID_EDIT: "frame-apiID-edit",
  SYNC_FROM_API: "sync-from-api",
  WS_COMP_ASSIGNED: "ws-comp-assigned",
  MULTI_WS_COMP_ASSIGNED: "multi-ws-comp-assigned",
  SYNC: "sync", // Now deprecated in favor of lastSync field in ActualComponent
  WS_COMP_STATUS: "ws-comp-status",
  WS_COMP_EDIT: "ws-comp-edit",
  WS_COMP_EDIT_BULK: "ws-comp-edit-bulk",
  WS_COMP_EDIT_NOTES: "ws-comp-edit-notes",
  WS_COMP_EDIT_TAGS: "ws-comp-edit-tags",
  WS_COMP_RENAME: "ws-comp-rename",
  WS_COMP_MULTI_RENAME: "ws-comp-multi_rename",
  WS_COMP_ATTACH: "ws-comp-attach",
  WS_COMP_DETACH: "ws-comp-detach",
  DUPES_WS_COMP_DETACH: "dupes-ws-comp-detach",
  WS_COMP_SWAP: "ws-comp-swap",
  WS_COMP_ADD_VARIANT: "ws-comp-add-variant",
  WS_COMP_ADD_VARIANT_IN_BULK_IMPORT: "ws-comp-add-variant-in-bulk-import",
  WS_COMP_DELETE_VARIANT: "ws-comp-delete-variant",
  WS_COMP_CREATION: "ws-comp-creation",
  WS_COMP_CREATION_IN_BULK_IMPORT: "ws-comp-creation-in-bulk-import",
  WS_COMP_CHARACTER_LIMIT_UPDATE: "ws-comp-character-limit-update",
  WS_COMP_DELETION: "ws-comp-deletion",
  // This is generated for a component that is moved to a folder as
  // part of a single-select "move to folder" action taken in the component
  // library.
  WS_COMP_MOVED_TO_FOLDER: "ws-comp-move-to-folder",
  // This is generated for each component that is moved to a folder as
  // part of a multi-select "move to folder" action taken in the component
  // library.
  WS_COMP_MOVED_TO_FOLDER_IN_BULK_OPERATION: "ws-comp-move-to-folder-in-bulk-operation",
  DUPES_WS_COMP_ATTACH: "dupes-ws-comp-attach",
  DUPES_WS_COMP_CREATION: "dupes-ws-comp-creation",
  DUPES_WS_COMP_VARIANT_UPDATE: "dupes-ws-comp-variant-update",
  DUPES_WS_COMP_BASE_TEXT_UPDATE: "dupes-ws-comp-base-text-update",
  DUPES_WS_COMP_EDIT: "dupes-ws-comp-edit", // generated by component updates via the API
  DUPES_WS_COMP_EDIT_NOTES: "dupes-ws-comp-edit-notes",
  DUPES_WS_COMP_EDIT_TAGS: "dupes-ws-comp-edit-tags",
  DUPES_WS_COMP_LIBRARY_EDIT: "dupes-ws-comp-library-edit", // generated by multi-select updates in the component library
  DUPES_WS_COMP_DETACH_AND_DELETE: "dupes-ws-comp-detach-and-delete",
  // This is generated once when a multi-select "move to folder" action is taken
  // in the component library.
  DUPES_WS_COMP_MOVED_TO_FOLDER: "dupes-ws-comp-move-to-folder",
  FOLDER_CREATION: "folder-creation",
  FOLDER_DELETION: "folder-deletion",
  FOLDER_UPDATE: "folder-update",
  PROJECT_MOVED_TO_FOLDER: "project-move-to-folder",
  COMPONENT_FOLDER_CREATION: "component-folder-creation",
  COMPONENT_FOLDER_DELETION: "component-folder-deletion",
  COMPONENT_FOLDER_UPDATE: "component-folder-update",
  COMPONENT_FOLDER_API_ID_EDIT: "component-folder-apiID-edit",
  COMPONENTS_IN_WORKSPACE_REGENERATED: "components-in-workspace-regenerated",
  FRAME_APPLY_VARIANT: "frame-apply-variant",
  FRAME_REMOVE_APPLIED_VARIANT: "frame-remove-applied-variant",
  FRAME_ADD_VARIANT: "frame-add-variant",
  FRAME_DELETE_VARIANT: "frame-delete-variant",
  VARIANT_STATUS_CHANGED: "variant-status-changed",
  MULTI_VARIANT_STATUS_CHANGED: "multi-variant-status-changed",
  WS_COMP_VARIANT_STATUS_CHANGED: "ws-comp-variant-status-changed",
  VARIANT_CREATED: "variant-created",
  VARIANT_DELETED: "variant-deleted",
  VARIANT_NAME_EDIT: "variant-name-edit",
  VARIANT_DESCRIPTION_EDIT: "variant-description-edit",
  VARIANT_APIID_EDIT: "variant-apiID-edit",
  VARIANT_FOLDER_CREATION: "variant-folder-creation",
  VARIANT_FOLDER_DELETION: "variant-folder-deletion",
  VARIANT_FOLDER_UPDATE: "variant-folder-update",
  VARIANT_MOVED_TO_FOLDER_IN_BULK_OPERATION: "variant-move-to-folder-in-bulk-operation",
  VARIANT_MOVED_TO_FOLDER: "variant-move-to-folder",
  RICH_TEXT_ENABLED: "rich-text-enabled",
  RICH_TEXT_DISABLED: "rich-text-disabled",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_PREVENT_MANUAL_EDITS: "workspace-setting-component-api-id-prevent-manual-edits",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATE_ON_RENAME: "workspace-setting-component-api-id-regenerate-on-rename",
  WORKSPACE_SETTINGS_COMPONENT_API_ID_GENERATION_CONFIG: "workspace-setting-component-api-id-generation-config",
  WORKSPACE_SETTINGS_PROJECT_API_ID_GENERATION_CONFIG: "workspace-setting-project-api-id-generation-config",
  WORKSPACE_SETTINGS_PROJECT_API_ID_UPDATE_IDS_WHEN_GROUP_BLOCK_CHANGE:
    "workspace-setting-project-api-id-update-ids-when-group-block-change",
  WORKSPACE_SETTINGS_PROJECT_API_ID_PREVENT_MANUAL_EDITS: "workspace-setting-project-api-id-prevent-manual-edits",
  PROJECT_API_IDS_REGENERATED: "project-ids-regenerated",
  ALL_PROJECT_API_IDS_REGENERATED: "all-project-ids-regenerated",
  WEBHOOK_CREATED: "webhook-created",
  WEBHOOK_UPDATED: "webhook-updated",
  WEBHOOK_DELETED: "webhook-deleted",
  COMPONENTS_MERGED: "components_merged",
  COMPONENT_MERGED: "component_merged",
  COMPONENT_AUTO_ATTACH: "component-auto-attached",
  AB_INTEGRATION_ENABLED: "ab-integration-enabled",
  AB_INTEGRATION_DISABLED: "ab-integration-disabled",
  FRAME_AUTO_ATTACH: "frame-auto-imported",
  AUTO_ATTACH_COMPONENTS_ENABLED: "auto-attach-components-enabled",
  AUTO_ATTACH_COMPONENTS_DISABLED: "auto-attach-components-disabled",
  AUTO_IMPORT_FRAMES_ENABLED: "auto-import-frames-enabled",
  AUTO_IMPORT_FRAMES_DISABLED: "auto-import-frames-disabled",
} as const;

const LEGACY_ENTRY_TYPES = {
  ...GENERAL_LEGACY_ENTRY_TYPES,
  ...VARIABLE_ACTUAL_CHANGE_TYPES,
  ...BRANCH_CHANGE_TYPES,
  ...DRAFTING_CHANGE_TYPES,
  ...AI_USAGE_TYPES,
};

export const ENTRY_TYPES = {
  ...NORTH_STAR_ENTRY_TYPES,
  ...LEGACY_ENTRY_TYPES,
};

// ---- MARK: Change Items ----

// ---- Base Change Items ----

/**
 * The base schema for all change items.
 */
const ZBaseChangeItem = z.object({
  _id: ZObjectId,
  user: z.string(), // The name of the user who made the change
  userObjectId: ZObjectId.nullable().optional(), // The _id of the user who made the change
  entry_type: z.nativeEnum(NORTH_STAR_ENTRY_TYPES),
  workspace_ID: ZObjectId,
  doc_id: ZObjectId.nullable(), // The projectId - required but nullable if not a project-related change
  createdAt: ZDate, // This gets set automatically, no need to explicitly populate it
  updatedAt: ZDate, // This gets set automatically, no need to explicitly populate it
});

/**
 * The base schema for all project-related change items.
 */
const ZBaseProjectChangeItem = ZBaseChangeItem.extend({
  doc_id: ZObjectId, // The projectId - for all project-related changes this must be populated
});

/**
 * The base schema for all change items relating any number of text items
 * Do not extend this directly, use one of the three schemas that extend this
 */
const ZBaseTextItemChangeItem = ZBaseProjectChangeItem.extend({
  variantId: ZObjectId.nullable().optional(),
});

/**
 * The base schema for change items relating to a single text item
 */
const ZBaseSingleTextItemChangeItem = ZBaseTextItemChangeItem.extend({
  comp_id: ZObjectId, // The text item id
});

/**
 * The base schema for change items relating to multiple text items
 */
const ZBaseMultiTextItemChangeItem = ZBaseTextItemChangeItem.extend({
  dupe_comp_ids: ZObjectId.array().nonempty(), // An array of text item ids
});

/**
 * The base schema for change items relating to one or more text items.
 * Note: Either comp_id or dupe_comp_ids must be populated,
 * but this can't be validated here and still included in a discriminated union.
 * Further validation takes place in the superRefine method of ZChangeItem.
 */
const ZBaseAnyCountTextItemChangeItem = ZBaseTextItemChangeItem.extend({
  comp_id: ZObjectId.nullable().optional(), // The text item id
  dupe_comp_ids: ZObjectId.array().nullable().optional(), // An array of text item ids
});

type BaseShapes =
  | typeof ZBaseChangeItem.shape
  | typeof ZBaseProjectChangeItem.shape
  | typeof ZBaseSingleTextItemChangeItem.shape
  | typeof ZBaseMultiTextItemChangeItem.shape
  | typeof ZBaseAnyCountTextItemChangeItem.shape;

type Values<T> = { [P in keyof T]: T[P] }[keyof T];

/**
 * A map of change item types to their base schemas.
 * If you add a new value to NORTH_STAR_ENTRY_TYPES, you must also add an entry here.
 * This allows us to look up the base schema that was used for a given change item.
 */
const BaseItemMap: {
  [K in Values<typeof NORTH_STAR_ENTRY_TYPES>]: z.ZodObject<BaseShapes>;
} = {
  [ENTRY_TYPES.DITTO_PROJECT_CREATED]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.DITTO_PROJECT_RENAMED]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.DITTO_PROJECT_MOVED_TO_FOLDER]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.DITTO_BLOCK_CREATED]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.DITTO_BLOCK_UPDATED]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.DITTO_BLOCK_DELETED]: ZBaseProjectChangeItem,
  [ENTRY_TYPES.TEXT_ITEM_CREATED]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.TEXT_ITEM_DELETED]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.EDIT]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.STATUS]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.DUPES_STATUS]: ZBaseMultiTextItemChangeItem,
  [ENTRY_TYPES.COMP_ASSIGNED]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.MULTI_COMP_ASSIGNED]: ZBaseMultiTextItemChangeItem,
  [ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE]: ZBaseTextItemChangeItem,
  [ENTRY_TYPES.POST_COMMENT]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.POST_REPLY]: ZBaseSingleTextItemChangeItem,
  [ENTRY_TYPES.PLURAL_ADDED]: ZBaseAnyCountTextItemChangeItem,
  [ENTRY_TYPES.PLURAL_REMOVED]: ZBaseAnyCountTextItemChangeItem,
  [ENTRY_TYPES.PLURAL_EDITED]: ZBaseAnyCountTextItemChangeItem,
  [ENTRY_TYPES.SYNC_CONFLICT_RESOLVED]: ZBaseChangeItem,
} as const;

// ---- Individual Change Items ----

/**
 * A new Ditto Project was created
 */
const ZDittoProjectCreatedChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_PROJECT_CREATED),
  doc_name: z.string(),
});

export type IDittoProjectCreatedChangeItem = z.infer<typeof ZDittoProjectCreatedChangeItem>;

/**
 * A Ditto Project was renamed
 */
const ZDittoProjectRenamedChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_PROJECT_RENAMED),
  data: z.object({
    previousName: z.string(),
    newName: z.string(),
  }),
});

export type IDittoProjectRenamedChangeItem = z.infer<typeof ZDittoProjectRenamedChangeItem>;

/**
 * A new text item block was created
 */
const ZBlockCreatedChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_BLOCK_CREATED),
  block_id: ZObjectId, // Id of the block
  data: z.object({
    _id: ZObjectId, // Id of the block
    name: z.string(), // Block name
  }),
});

export type IBlockCreatedChangeItem = z.infer<typeof ZBlockCreatedChangeItem>;

/**
 * Data relating to a text item block, such as the block's name, was changed
 */
const ZBlockUpdatedChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_BLOCK_UPDATED),
  block_id: ZObjectId, // Id of the block
  data: z.object({
    _id: ZObjectId, // Id of the block
    before: ZDittoProjectBlock, // Value of the block data before the change
    after: ZDittoProjectBlock, // Value of the block data after the change
  }),
});

export type IBlockUpdatedChangeItem = z.infer<typeof ZBlockUpdatedChangeItem>;

/**
 * A text item block was deleted
 */
const ZBlockDeletedChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_BLOCK_DELETED),
  block_id: ZObjectId, // Id of the block
  data: z.object({
    _id: ZObjectId, // Id of the block
    name: z.string(), // Block name
    textItemIds: z.array(ZObjectId), // Ids of the text items that were in the block
  }),
});

export type IBlockDeletedChangeItem = z.infer<typeof ZBlockDeletedChangeItem>;

/**
 * A new text item was created
 */
const ZTextItemCreatedChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.TEXT_ITEM_CREATED),
  data: ZTextItem.pick({ text: true, rich_text: true, blockId: true, status: true }),
});

export type ITextItemCreatedChangeItem = z.infer<typeof ZTextItemCreatedChangeItem>;

/**
 * A text item was deleted
 */
const ZTextItemDeletedChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.TEXT_ITEM_DELETED),
  data: ZTextItem.pick({ text: true, rich_text: true }),
});

export type ITextItemDeletedChangeItem = z.infer<typeof ZTextItemDeletedChangeItem>;

/**
 * The text content of a single text item was edited
 */
const ZTextItemEditChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.EDIT),
  text_before: z.string(),
  text_after: z.string(),
  data: z.object({
    variables_before: ZActualComponentVariableSchema.array().nullable().optional(),
    variables_after: ZActualComponentVariableSchema.array().nullable().optional(),
    rich_text_before: ZTipTapRichText,
    rich_text_after: ZTipTapRichText,
  }),
});

export type ITextItemEditChangeItem = z.infer<typeof ZTextItemEditChangeItem>;

/**
 * The status of a single text item was changed
 */
const ZTextItemStatusChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.STATUS),
  status: ZTextItemStatus, // The new status
});

export type ITextItemStatusChangeItem = z.infer<typeof ZTextItemStatusChangeItem>;

/**
 * The status of multiple text items were changed in bulk (to the same status)
 */
const ZMultiTextItemStatusChangeItem = ZBaseMultiTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DUPES_STATUS),
  status: ZTextItemStatus, // The new status
});

export type IMultiTextItemStatusChangeItem = z.infer<typeof ZMultiTextItemStatusChangeItem>;

/**
 * A single text item was assigned to a user
 */
const ZTextItemAssignedChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.COMP_ASSIGNED),
  data: z.object({
    assignee: ZObjectId.nullable(), // _id of the user it was assigned to
    assigneeName: z.string().nullable().optional(), // name of the user it was assigned to
    assigneeUserId: z.string().nullable().optional(), // userId of the user it was assigned to
  }),
});

export type ITextItemAssignedChangeItem = z.infer<typeof ZTextItemAssignedChangeItem>;

/**
 * Multiple text items were assigned to the same user in bulk
 */
const ZMultiTextItemAssignedChangeItem = ZBaseMultiTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.MULTI_COMP_ASSIGNED),
  data: z.object({
    assignee: ZObjectId.nullable(), // _id of the user it was assigned to
    assigneeUserId: z.string().nullable().optional(), // userId of the user it was assigned to
    assigneeName: z.string().nullable().optional(), // name of the user it was assigned to
  }),
});

export type IMultiTextItemAssignedChangeItem = z.infer<typeof ZMultiTextItemAssignedChangeItem>;

/**
 * The character limit of one or more text items was updated.
 * Note: This change item does not match the schema of other text item changes.
 * Rether than use the comp_id or dupe_comp_ids fields, the affected text items ids are stored in data.textItemIds.
 */
const ZTextItemCharacterLimitUpdateChangeItem = ZBaseTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE),
  data: z.object({
    oldCharacterLimit: z.number().nullable().optional(), // The previous limit
    newCharacterLimit: z.number().nullable(), // The new limit
    textItemIds: z.array(ZObjectId).nonempty(), // The id(s) of the text items whose character limit was updated
  }),
});

export type ITextItemCharacterLimitUpdateChangeItem = z.infer<typeof ZTextItemCharacterLimitUpdateChangeItem>;

/**
 * A comment was added to a specific text item
 */
const ZPostCommentChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.POST_COMMENT),
  comment_thread_id: ZObjectId, // The of the new comment thread
  data: z.object({
    isSuggestion: z.boolean(),
  }),
});

export type IPostCommentChangeItem = z.infer<typeof ZPostCommentChangeItem>;

/**
 * A reply was added to a comment on a specific text item
 */
const ZPostReplyChangeItem = ZBaseSingleTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.POST_REPLY),
  comment_thread_id: ZObjectId, // The id of the comment the reply was added to
});

export type IPostReplyChangeItem = z.infer<typeof ZPostReplyChangeItem>;

/**
 * A new plural form was added to one or more text items
 */
const ZPluralAddedChangeItem = ZBaseAnyCountTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.PLURAL_ADDED),
  data: z.object({
    plural_form: ZPluralForm,
    plural_text: z.string(),
    plural_variables: ZObjectId.array().nullable(),
  }),
});

export type IPluralAddedChangeItem = z.infer<typeof ZPluralAddedChangeItem>;

/**
 * A plural form was removed from one or more text items
 */
const ZPluralRemovedChangeItem = ZBaseAnyCountTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.PLURAL_REMOVED),
  data: z.object({
    plural_form: ZPluralForm,
    plural_text: z.string(),
    plural_variables: ZObjectId.array().nullable(),
  }),
});

export type IPluralRemovedChangeItem = z.infer<typeof ZPluralRemovedChangeItem>;

/**
 * A plural associated with one or more text items was edited
 */
const ZPluralEditedChangeItem = ZBaseAnyCountTextItemChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.PLURAL_EDITED),
  data: z.object({
    added: ZPluralSchema.array(),
    removed: ZPluralSchema.array(),
  }),
});

export type IPluralEditedChangeItem = z.infer<typeof ZPluralEditedChangeItem>;

/**
 * A sync conflict was resolved
 */
export const ZSyncConflictResolvedChangeItem = ZBaseChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.SYNC_CONFLICT_RESOLVED),
  comp_id: ZObjectId,
  text_before: z.string(),
  text_after: z.string(),
  data: z.object({
    selection: z.union([z.literal("ditto"), z.literal("figma")]),
    selectedFigmaNodeId: z.string().nullable(),
    rich_text_before: ZTipTapRichText,
    rich_text_after: ZTipTapRichText,
  }),
});

export type ISyncConflictResolvedChangeItem = z.infer<typeof ZSyncConflictResolvedChangeItem>;

/**
 * A project was moved to a folder
 */
export const ZDittoProjectMovedToFolderChangeItem = ZBaseProjectChangeItem.extend({
  entry_type: z.literal(ENTRY_TYPES.DITTO_PROJECT_MOVED_TO_FOLDER),
  data: z.object({
    folderIdBefore: ZObjectId.nullable(),
    folderNameBefore: z.string().nullable(),
    folderIdAfter: ZObjectId.nullable(),
    folderNameAfter: z.string().nullable(),
  }),
});

export type IDittoProjectMovedToFolderChangeItem = z.infer<typeof ZDittoProjectMovedToFolderChangeItem>;

/**
 * The discriminated union of all North Star change items.
 * In general, this (and associated inferred types) is typically what you'll want to use.
 */
export const ZChangeItem = z
  .discriminatedUnion("entry_type", [
    ZDittoProjectCreatedChangeItem,
    ZDittoProjectRenamedChangeItem,
    ZDittoProjectMovedToFolderChangeItem,
    ZBlockCreatedChangeItem,
    ZBlockUpdatedChangeItem,
    ZBlockDeletedChangeItem,
    ZTextItemCreatedChangeItem,
    ZTextItemDeletedChangeItem,
    ZTextItemEditChangeItem,
    ZTextItemStatusChangeItem,
    ZMultiTextItemStatusChangeItem,
    ZTextItemAssignedChangeItem,
    ZMultiTextItemAssignedChangeItem,
    ZTextItemCharacterLimitUpdateChangeItem,
    ZPostCommentChangeItem,
    ZPostReplyChangeItem,
    ZPluralAddedChangeItem,
    ZPluralRemovedChangeItem,
    ZPluralEditedChangeItem,
    ZSyncConflictResolvedChangeItem,
  ])
  // Add additional validation for more complicated change items
  .superRefine((data, ctx) => {
    // Validate that all items that extend ZBaseAnyCountTextItemChangeItem
    // correctly contain either comp_id or dupe_comp_ids
    if (BaseItemMap[data.entry_type] === ZBaseAnyCountTextItemChangeItem) {
      const hasCompId = "comp_id" in data && !!data.comp_id;
      const hasDupeCompIds =
        "dupe_comp_ids" in data && Array.isArray(data.dupe_comp_ids) && data.dupe_comp_ids.length > 0;
      if (!hasCompId && !hasDupeCompIds) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Either comp_id or dupe_comp_ids must be populated for ${data.entry_type}`,
        });
        return;
      }
    }

    return true;
  });

/**
 * This is a discriminated union of all individual North Star change item types.
 */
export type IChangeItem = z.infer<typeof ZChangeItem>;

/**
 * Use this generic when building up a new change item - it omits the fields that are automatically generated.
 */
export type CreateChangeItem<T extends IChangeItem> = Omit<T, "_id" | "createdAt" | "updatedAt">;

// ---- Type Guards & Helper Functions ----

type WithCompId<T extends IChangeItem> = T & { comp_id: IObjectId };
type WithDupeCompIds<T extends IChangeItem> = T & { dupe_comp_ids: IObjectId[] };

function useDupeCompIds(item: IChangeItem): item is WithDupeCompIds<IChangeItem> {
  return (
    BaseItemMap[item.entry_type] === ZBaseMultiTextItemChangeItem ||
    (BaseItemMap[item.entry_type] === ZBaseAnyCountTextItemChangeItem &&
      "dupe_comp_ids" in item &&
      Array.isArray(item.dupe_comp_ids) &&
      item.dupe_comp_ids.length > 0)
  );
}

function useCompId(item: IChangeItem): item is WithCompId<IChangeItem> {
  return (
    BaseItemMap[item.entry_type] === ZBaseSingleTextItemChangeItem ||
    (BaseItemMap[item.entry_type] === ZBaseAnyCountTextItemChangeItem && !useDupeCompIds(item))
  );
}

/**
 * @param item A change item of any type
 * @returns An array of text item ids related to this change item. Empty array if none.
 */
export function extractTextItemIds(item: IChangeItem): IObjectId[] {
  if (item.entry_type === ENTRY_TYPES.TEXT_ITEM_CHARACTER_LIMIT_UPDATE) {
    return item.data.textItemIds;
  }

  if (useDupeCompIds(item)) {
    return item.dupe_comp_ids;
  }

  if (useCompId(item)) {
    return [item.comp_id];
  }

  return [];
}

/**
 * Pulls out the block id from a change item if it exists.
 */
export function extractBlockId(item: IChangeItem): IObjectId | null {
  if ("block_id" in item && item.block_id) {
    return item.block_id;
  }
  return null;
}

// ---- MARK: Actual Change types - DEPRECATED ----

// TODO: Remove this when removing ZActualChange
const ZActualChangeData = z.record(z.string(), z.any());

// TODO: Remove this, replaced by ZChangeItem
export const ZActualChange = z.object({
  _id: ZObjectId,
  parent: ZObjectId.nullable().optional(),
  children: z.array(ZObjectId).optional(),
  user: z.string(),
  userObjectId: ZObjectId.nullable().optional(),
  userId: z.string().nullable().optional(),
  doc_name: z.string().nullable().optional(),
  doc_id: ZObjectId.nullable().optional(),
  workspace_ID: ZObjectId,
  variantId: ZObjectId.nullable().optional(),
  entry_type: z.string(),
  data: ZActualChangeData.optional(),
  dupe_comp_ids: z.array(ZObjectId).nullable().optional(),
  dupe_figma_node_ids: z.array(z.string()).nullable().optional(),
  block_id: ZObjectId.nullable().optional(),
  comp_id: ZObjectId.nullable().optional(),
  frame_id: ZObjectId.nullable().optional(),
  frame_name: z.string().nullable().optional(),
  figma_node_id: z.string().nullable().optional(),
  text_before: z.string().nullable().optional(),
  text_after: z.string().nullable().optional(),
  component_name: z.string().nullable().optional(),
  variant_name: z.string().nullable().optional(),
  date_time: z.date(),
  status: ZTextItemStatus.nullable().optional(),
  pr_num: z.number().nullable().optional(),
  ws_comp: ZObjectId.nullable().optional(),
  comment_thread_id: ZObjectId.nullable().optional(),
  dupe_ws_comp_ids: z.array(ZObjectId).nullable().optional(),
  isSample: z.boolean(),
});

/**
 * @deprecated Use IChangeItem instead for any North Star work
 */
export type IActualChange = z.infer<typeof ZActualChange>;
