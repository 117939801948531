import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  className?: string;
  size: number; // Diamater & viewbox size
  color: "icon-secondary"; // TODO: Add more colors as needed
}

export function Dot(props: IProps) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox={`0 0 ${props.size} ${props.size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(style.dot, props.className, { [style[`color-${props.color}`]]: props.color })}
    >
      <circle cx={props.size / 2} cy={props.size / 2} r={props.size / 2} />
    </svg>
  );
}

export default Dot;
