import React, { useState } from "react";
import CommentThread from "../commentthread/commentthread";

/**
 *
 * @param {*} props
 * @returns
 */
const InlineCommentThread = ({
  className,
  comp_id,
  ws_comp_id,
  comment_thread,
  isDisabled,
  isActive,
  doc_name,
  handleCommentChangeClick,
  handleHistoryUpdate,
  handleDocUpdate,
  handleCommentThreadClicked,
  handleScrollTo,
  fetchCompInfo,
  forceShowToast,
  workspaceUsers,
  updateCompResultComments,
  isWsComp,
  onResolveSuggestion,
}) => {
  const [threadOpen, setThreadOpen] = useState(isActive);

  const handleDocUpdateParent = () => {
    handleDocUpdate([comp_id]);
  };

  const updateCommentThread = (threadObject, doUpdate = true) => {
    if (threadObject.is_resolved) {
      setThreadOpen(false);

      // Updated to support real time update of comment counts on ws_comps
      if (isWsComp && doUpdate) {
        updateCompResultComments(comp_id, threadObject._id, "remove");
      }
      return;
    }
    if (isWsComp && doUpdate) {
      updateCompResultComments(comp_id, threadObject._id, "add");
    }
  };

  return (
    <CommentThread
      className={className}
      comp_id={comp_id}
      ws_comp_id={ws_comp_id}
      handleInlineReplyClick={handleCommentThreadClicked}
      handleCommentChangeClick={handleCommentChangeClick}
      isActiveComment={isActive}
      isInlineComment={true}
      isDisabled={isDisabled}
      suggestion={comment_thread.suggestion}
      onAcceptSuggestion={(...args) => onResolveSuggestion(true, ...args)}
      onRejectSuggestion={(...args) => onResolveSuggestion(false, ...args)}
      thread_id={comment_thread._id}
      is_resolved={comment_thread.is_resolved}
      comments={comment_thread.comments}
      updateCommentThread={updateCommentThread}
      should_autofocus={isActive}
      default_replies_open={threadOpen && isActive}
      handleHistoryUpdate={handleHistoryUpdate}
      doc_name={doc_name}
      handleDocUpdateParent={handleDocUpdateParent}
      fetchCompInfo={fetchCompInfo}
      forceShowToast={forceShowToast}
      workspaceUsers={workspaceUsers}
      handleScrollTo={handleScrollTo}
    />
  );
};

export default InlineCommentThread;
